import clsx from 'clsx'
import React, { memo, ReactNode } from 'react'
import { Button } from '../../kit'
import { Popup } from '../../molecules'
import style from './style.module.scss'
import { SVG } from '../../../assets/svg'
import { t } from '@lingui/macro'
import { useMobileSizeDetect } from '@/utils'

export type AlertProps = {
  icon?: ReactNode

  /** custom style **/
  className?: string

  /** to display own header instead of typical */
  customHeader?: string

  /** to display own text instead of typical */
  customText?: ReactNode

  /** to display something original */
  customComponent?: ReactNode

  /** text for closeWindowButton **/
  closeButtonText?: string

  /**
   * Callback for onclose action
   */
  onClose?: () => void

  isButtonHidden?: boolean
}

/** Base Error view to extend for concrete errors. Shouldn't be used directly */
export const Alert = memo<AlertProps>(
  ({
    className,
    customHeader,
    customText,
    customComponent,
    closeButtonText = t({
      id: 'core.close',
      message: 'Close',
    }),
    onClose,
    icon,
    isButtonHidden,
  }) => {
    const [isMobile] = useMobileSizeDetect()

    return (
      <Popup isOpen>
        <div className={clsx(isMobile && style.mobile, style.root, className)}>
          <div>{icon || <SVG.OtherIcons.Check className={style.checkmark} />}</div>
          <div className={style.header}>
            {customHeader ||
              t({
                id: 'core.success',
                message: `Success`,
              })}
          </div>
          <div className={style.text}>{customText}</div>
          {customComponent}

          {!isButtonHidden && <Button.White onClick={onClose} label={closeButtonText} className={style.button} />}
        </div>
      </Popup>
    )
  }
)
