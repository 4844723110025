import { FieldErrorType } from '@/types'
import { useEffect } from 'react'

type TSetErrorFunction<T extends string> = (field: T, error: { type: string; message: string }) => void
type TGetErrors = Partial<Record<string, { message: string; fields: string[] }>>

export const useErrorsParser = <T extends string>(
  sources: FieldErrorType[],
  setError: TSetErrorFunction<T>,
  getErrors: TGetErrors
) => {
  useEffect(() => {
    sources.forEach(({ code, message }) => {
      const findCode = getErrors[code]
      if (findCode) {
        if (!findCode.fields.length) {
          setError('globalError' as T, {
            type: 'random',
            message: findCode.message,
          })
        } else {
          findCode.fields.forEach(field => {
            setError(field as T, {
              type: 'string',
              message: message,
            })
          })
        }
      }
    })
  }, [sources])
}
