import { addressAsyncActions } from './address.actions'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import initialState from './address.state'
import { AssetsAddresses } from './address.types'
import { FieldErrorType } from '@/types'
import { WithdrawAndDepositAssets } from '@/backend/api'

export const slice = createSlice({
  name: `Address`,
  initialState,
  reducers: {
    setErrors(state, action: PayloadAction<FieldErrorType[]>) {
      state.errors = action.payload
    },
    setCleanCreateStatus(state) {
      state.createdAddressStatus = 'idle'
    },
    setCleanUpdateStatus(state) {
      state.updatedAddressStatus = 'idle'
    },
    setCleanRemoveStatus(state) {
      state.addressRemoveStatus = 'idle'
    },
    clearWhiteListToggleStatus(state) {
      state.whiteListToggleStatus = 'idle'
    },
    cleanAddressById(state) {
      state.addressById = {}
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addressAsyncActions.getAddressesTC.pending, (state, { payload }) => {
        state.status = 'loading'
      })
      .addCase(addressAsyncActions.getAddressesTC.fulfilled, (state, { payload }) => {
        state.status = 'succeeded'
        state.addresses = payload
      })
      .addCase(addressAsyncActions.getAddressesTC.rejected, (state, { payload }) => {
        state.status = 'failed'
      })
      .addCase(addressAsyncActions.getAddressByIdTC.fulfilled, (state, { payload }) => {
        state.addressById = payload
        state.addressByIdStatus = 'succeeded'
      })
      .addCase(addressAsyncActions.getAddressByIdTC.rejected, (state, { payload }) => {
        state.addressByIdStatus = 'failed'
      })
      .addCase(addressAsyncActions.deleteAddressTC.fulfilled, (state, { payload }) => {
        state.addressRemoveStatus = 'succeeded'
      })
      .addCase(addressAsyncActions.deleteAddressTC.rejected, (state, { payload }) => {
        state.addressRemoveStatus = 'failed'
        state.errors = payload?.fieldsErrors! || []
      })
      .addCase(addressAsyncActions.deleteAddressTC.pending, (state, { payload }) => {
        state.addressRemoveStatus = 'loading'
      })
      .addCase(addressAsyncActions.editAddressTC.fulfilled, (state, { payload }) => {
        state.updatedAddressStatus = 'succeeded'
      })
      .addCase(addressAsyncActions.editAddressTC.rejected, (state, { payload }) => {
        state.updatedAddressStatus = 'failed'
        state.errors = payload?.fieldsErrors! || []
      })
      .addCase(addressAsyncActions.createAddressTC.fulfilled, (state, { payload }) => {
        state.createdAddressStatus = 'succeeded'
      })
      .addCase(addressAsyncActions.createAddressTC.rejected, (state, { payload }) => {
        state.createdAddressStatus = 'failed'
        state.errors = payload?.fieldsErrors! || []
      })
      .addCase(addressAsyncActions.getAssetsForAddressTC.pending, state => {
        state.assetsForAddresses.assetsStatus = 'loading'
      })
      .addCase(addressAsyncActions.getAssetsForAddressTC.fulfilled, (state, action) => {
        state.assetsForAddresses.assets = convertAssetToObject(action.payload)
        state.assetsForAddresses.assetsStatus = 'succeeded'
      })
      .addCase(addressAsyncActions.getWhitelistTC.fulfilled, (state, action) => {
        state.whitelistEnabled = action.payload.enabled
        state.whitelistGetStatus = 'succeeded'
      })
      .addCase(addressAsyncActions.getWhitelistTC.pending, (state, action) => {
        state.whitelistGetStatus = 'loading'
      })
      .addCase(addressAsyncActions.getWhitelistTC.rejected, (state, action) => {
        state.whitelistGetStatus = 'failed'
      })
      .addCase(addressAsyncActions.postToggleWhitelistPermissionTC.fulfilled, (state, action) => {
        state.whiteListToggleStatus = 'succeeded'
      })
      .addCase(addressAsyncActions.postToggleWhitelistPermissionTC.rejected, (state, { payload }) => {
        state.whiteListToggleStatus = 'failed'
        state.errors = payload?.fieldsErrors!
      })
  },
})

export const addressActions = {
  ...slice.actions,
  ...addressAsyncActions,
}

export const addressReducer = slice.reducer

const convertAssetToObject = (assets: WithdrawAndDepositAssets) => {
  const result = assets.reduce<AssetsAddresses['assets']>((accumulator, { coin, networks }) => {
    accumulator[coin] = networks

    return accumulator
  }, {})

  return result
}
