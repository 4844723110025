import { Deposit } from './Deposit'
import { Share } from './Share'
import { Transfer } from './Transfer'
import { Withdraw } from './Withdraw'

export const Actions = {
  Withdraw,
  Deposit,
  Transfer,
  Share,
}
