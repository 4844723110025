import { FC } from 'react'
import { t } from '@lingui/macro'
import { TitleH2 } from '@/ui/atoms/TitleH2'
import { VoucherItem } from '@/ui/molecules/VoucherItem'
import { useAppSelector } from '@/utils'
import s from './style.module.scss'

interface IVoucherHistoryListProps {}

const VoucherHistoryList: FC<IVoucherHistoryListProps> = props => {
  const { disabled } = useAppSelector(state => state.voucher)

  if (!disabled.length) return null

  return (
    <div className={s.root}>
      <TitleH2 label={t({ message: 'History', id: 'voucher.historyHeading' })} />
      <div className={s.list}>
        {disabled.map(voucher => (
          <VoucherItem key={voucher.id} voucher={voucher} />
        ))}
      </div>
    </div>
  )
}

export { VoucherHistoryList }
