import { OverviewOpenOrder } from '@/backend/models/OverviewDTO'
import { FC, MouseEvent, useMemo } from 'react'
import s from './style.module.scss'
import { useOrder } from '@/ui/organisms/Order/hooks/useOrder'
import { t } from '@lingui/macro'
import { MobileDealCard } from '../MobileDealCard/MobileDealCard'
import { useAppSelector } from '@/utils'
import { MobileSkeletonDeal } from '../MobileSkeletonDeal/MobileSkeletonDeal'

interface OrdersProps {
  orders: OverviewOpenOrder[]
}

interface CardProps {
  order: OverviewOpenOrder
}

const OrderCard: FC<CardProps> = ({ order }) => {
  const { amount, closeOrderHandler, deleting, typeToName } = useOrder(order)

  const text = useMemo(() => {
    return {
      closeBtn: t({ message: 'Close this order', id: 'orders.closeOrder' }),
    }
  }, [])

  const closeHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    closeOrderHandler()
  }

  return (
    <>
      <MobileDealCard
        symbol={order.symbol}
        side={order.side}
        date={order.time}
        volume={amount}
        price={order.priceOutput}
        type={typeToName}
        isDeleting={deleting}
        closeHandler={closeHandler}
        closeBtnText={text.closeBtn}
      />
    </>
  )
}

export const MobileOrders: FC<OrdersProps> = ({ orders }) => {
  const { statusOrders } = useAppSelector(state => state.overview)

  if (statusOrders !== 'succeeded') {
    return (
      <ol className={s.list}>
        {Array.from({ length: 3 }).map((_: unknown, id: number) => (
          <MobileSkeletonDeal key={id} />
        ))}
      </ol>
    )
  }

  if (!orders?.length) {
    return <div className={s.empty}>{t({ message: 'You have no submitted orders', id: 'orders.noOrders' })}</div>
  }

  return (
    <ul className={s.list}>
      {orders.map(order => (
        <OrderCard key={order.orderId} order={order} />
      ))}
    </ul>
  )
}
