interface ITagProps {
  className?: string
}

export const Tag = (props: ITagProps) => {
  const { className } = props

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.4393 13.6349L13.6415 18.4326C13.5173 18.557 13.3697 18.6557 13.2072 18.723C13.0447 18.7904 12.8706 18.8251 12.6947 18.8251C12.5188 18.8251 12.3447 18.7904 12.1822 18.723C12.0198 18.6557 11.8722 18.557 11.7479 18.4326L6.78504 13.4755C6.28242 12.9735 6 12.2922 6 11.5818V7.33827C6 6.59916 6.59917 6 7.33827 6H11.5827C12.2926 6 12.9734 6.28199 13.4753 6.78394L18.4393 11.7479C18.6885 11.9986 18.8284 12.3378 18.8284 12.6914C18.8284 13.0449 18.6885 13.3841 18.4393 13.6349Z"
        stroke="#676573"
        stroke-width="1.33827"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.3457 9.3457H9.35239"
        stroke="#676573"
        stroke-width="1.33827"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
