import { FC, ReactNode, useEffect, useRef } from 'react'
import styles from './style.module.scss'
import clsx from 'clsx'

type Props = {
  className?: string
  scrollCallback: () => void
  children: ReactNode
  maxHeight?: string
}

export const InfiniteScrollCustom: FC<Props> = ({ className, scrollCallback, children, maxHeight = '60vh' }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current

      if (container) {
        const isEndReached = container.scrollTop + container.clientHeight >= container.scrollHeight - 10

        if (isEndReached) {
          if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current) // Очищаем предыдущий таймер
          }

          scrollTimeoutRef.current = setTimeout(() => {
            scrollCallback()
          }, 100) // Задержка в 100 мс для предотвращения множественных вызовов
        }
      }
    }

    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }

      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current) // Очищаем таймер при размонтировании компонента
      }
    }
  }, [scrollCallback])

  return (
    <div
      ref={containerRef}
      className={clsx(styles.wrapper, className)}
      style={{ maxHeight: `calc(${maxHeight} - 26px)` }}
    >
      {children}
    </div>
  )
}
