import { useMemo } from 'react'
import { useAppSelector } from '@/utils'

export const usePageState = () => {
  const { status, active, disabled } = useAppSelector(state => state.voucher)

  const isLoading = useMemo(() => {
    return status === 'loading'
  }, [status])

  const isError = useMemo(() => {
    return status === 'failed'
  }, [status])

  const isEmpty = useMemo(() => {
    return !isLoading && !active.length && !disabled.length
  }, [isLoading, active, disabled])

  return {
    isLoading,
    isError,
    isEmpty,
  }
}
