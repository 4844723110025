import { t } from '@lingui/macro'

export const errorTranslations = () => {
  return {
    googleCode: t({
      id: 'core.error.googleCode',
      comment: 'Google code invalid',
    }),
    invalidField: t({
      id: 'core.error.invalidField',
      comment: 'invalid field',
    }),
  }
}
