import { t } from '@lingui/macro'
import { FC, memo } from 'react'
import { AccountOverviewPositionsView, TOpenOrder } from '@/backend/models/OverviewDTO'
import EmptyList from '@/ui/molecules/EmptyList'
import { Order } from '@/ui/organisms/Order'
import { Position } from '@/ui/organisms/Position'
import { useOverview } from '@/utils/hooks/useOverview'
import style from './style.module.scss'

const ContentListItems: FC = () => {
  const {
    instrumentType,
    tabId,
    openOrdersByAccount = [],
    overviewByAccount: { positions = [] },
    shownOrdersOrPositionsLength,
  } = useOverview()

  const emptyListLabel =
    tabId === 1
      ? t({
          message: 'Here you’ll see information about your active orders and will be able to manage them',
          id: 'core.infoAboutOpenOrders',
        })
      : t({
          message: 'Here you’ll see information about your open positions and will be able to manage them',
          id: 'core.infoAboutOpenPositions',
        })

  return (
    <div className={style.contentListItemsWrap}>
      <div className={style.contentListItems}>
        {shownOrdersOrPositionsLength ? (
          <>
            {tabId === 1 &&
              openOrdersByAccount?.map((item: TOpenOrder) => <Order.Tab key={item.orderId} order={item} />)}
            {tabId === 2 &&
              instrumentType !== undefined &&
              positions
                ?.map((item: AccountOverviewPositionsView) => (
                  <Position.Tab key={item.uid + item.updateTime} position={item} />
                ))
                .reverse()}
          </>
        ) : (
          <EmptyList label={emptyListLabel} />
        )}
      </div>
      {shownOrdersOrPositionsLength > 0 && (
        <div className={style.bottomGradientWrap}>
          <div className={style.bottomGradient} />
        </div>
      )}
    </div>
  )
}

export default memo(ContentListItems)
