import { TAccountType } from '@/core/types/overview'
import { TSetLeverageResponseOkx } from '@/redux/leverage/leverage.types'

export enum Margin {
  Crossed = 'crossed',
  Isolated = 'isolated',
  Other = 'other',
}

export enum EMarginBybit {
  ISOLATED_MARGIN = 'ISOLATED_MARGIN',
  REGULAR_MARGIN = 'REGULAR_MARGIN',
  PORTFOLIO_MARGIN = 'PORTFOLIO_MARGIN',
}

export type PositionRiskUsdMRequestDTO = {
  timestamp?: number
  symbol?: string
  recvWindow?: number
}

export type PositionRiskCoinMRequestDTO = {
  timestamp?: number
  marginAsset?: string
  pair?: string
  recvWindow?: number
}

export type PositionRiskUsdMDTO = {
  symbol: string
  leverage: string
  maxNotionalValue: string
  marginType: Margin
}

export type PositionRiskCoinMDTO = {
  symbol: string
  leverage: string
  maxQty: string
  marginType: Margin
}

export type PositionRiskUsdMResponseDTO = PositionRiskUsdMDTO[]
export type PositionRiskCoinMResponseDTO = PositionRiskCoinMDTO[]

export type LeverageBracketRequestDTO = {
  timestamp?: number
  symbol?: string
  recvWindow?: number
}

export type LeverageBracket = {
  initialLeverage: number
  notionalCap?: number
  qtyCap?: number
}

export type LeverageBracketItem = {
  symbol: string
  brackets: LeverageBracket[]
}

export type LeverageBracketResponseDTO = LeverageBracketItem[]

export type ExchangeInfoResponseDTO = {
  symbols: {
    symbol: string
    status?: string
    contractStatus?: string
  }[]
}

export type TSetMarginPayloadBinance = {
  accountType: TAccountType
  symbol: string
  marginType: Margin
}

export type TSetMarginPayloadBybit = {
  accountType: TAccountType
  setMarginMode: EMarginBybit
}

export type TSetMarginPayloadUnion = TSetMarginPayloadBinance | TSetMarginPayloadBybit

export type TSetMarginResponseBinance = {
  accountType: TAccountType
  code: number
  msg: string
}
export type TSetMarginResponseOkx = TSetLeverageResponseOkx
export type TSetMarginResponseBybit = {
  accountType: TAccountType
  retCode: number
  resMsg: string
}

export type TSetMarginResponse = {
  accountType: TAccountType
  symbol?: string
  marginType: Margin
}
