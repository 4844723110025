import { FC } from 'react'
import clsx from 'clsx'
import { Button } from '@/ui/kit'
import { SVG } from '@/assets/svg'
import { useOrder } from '@/ui/organisms/Order/hooks/useOrder'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import s from './style.module.scss'
import { TOpenOrder } from '@/backend/models/OverviewDTO'
import { ServicesOrderSide } from '@/utils/types/contracts.types'
import { SideIndicator } from '@/ui/kit/SideIndicator'
import { FormatNumberDotWithTooltip } from '@/ui/molecules/FormatNumberDotWithTooltip/FormatNumberWithTooltip'

const OrdersTable: FC<TOpenOrder> = order => {
  const { executedQty, price, priceOutput = price, side, symbol } = order
  const { amount, typeToName, closeOrderHandler, deleting, accountTypeName } = useOrder(order)

  const futuresTypes = ['Futures', 'Swap', 'linear']

  return (
    <div className={clsx(s.order, s.table, { [s.deleted]: deleting })} data-testid={DataTestIds.OrderListItem}>
      <div className={s.itemWrapper}>
        <div>{futuresTypes.includes(accountTypeName) ? <SVG.Tmm.Futures /> : <SVG.Tmm.Spot />}</div>
        <div className={s.currency}>
          <div className={s.noWrapContainer}>
            <div data-testid={`${DataTestIds.OrderSymbol}-${symbol}`}>{symbol}</div>
            <SideIndicator profit={side === ServicesOrderSide.OrderBuy} label={side} className={s.orderSide} />
          </div>

          <div className={s.quote} data-testid={`${DataTestIds.OrderType}-${symbol}`}>
            {typeToName}
          </div>
        </div>
      </div>

      <div className={s.item}>
        <div data-testid={`${DataTestIds.OrderPrice}-${symbol}`}>
          <FormatNumberDotWithTooltip value={priceOutput} symbol={symbol} qtyChars={5} />
        </div>
      </div>

      <div className={s.item}>
        <div data-testid={`${DataTestIds.OrderAmount}-${symbol}`}>
          <FormatNumberDotWithTooltip value={amount} symbol={symbol} qtyChars={5} />
        </div>
      </div>

      <div className={s.item} data-testid={`${DataTestIds.OrderStatus}-${symbol}`}>
        <div className={s.wrapper}>
          {executedQty}/<FormatNumberDotWithTooltip value={amount} qtyChars={5} />
        </div>
      </div>

      <Button.Primary
        onClick={closeOrderHandler}
        className={s.closeButton}
        leftIcon={<SVG.Additional.Close />}
        dataTestId={`${DataTestIds.CloseOrderButton}-${symbol}`}
      />
    </div>
  )
}

export { OrdersTable }
