import { RefObject, useEffect, useRef } from 'react'
import type { ClickOutsideOptions } from './@types/outsideClick.type'
import { isContainsElement } from './helpers/isContainsElement'
import { getOwnerDocument } from './helpers/getOwnerDocument'
import { useConstant } from '../useConstant'
import { isValidEvent } from './helpers/isValidEvent'

export function useOutsideClick<T extends HTMLElement>(
  handler: (event: Event) => void,
  options: ClickOutsideOptions = {}
): RefObject<T> {
  const { exclude = [], isDisabled = false } = options

  const targetRef = useRef<T>(null)

  const state = useConstant(() => ({
    /**
     * Partial props refs
     */
    outsideHandler: handler,
    exclude: exclude,

    /**
     * Pointer state
     */
    isPointerDown: false,

    /**
     * Refs to event handlers
     */
    onPointerDown(event: PointerEvent): void {
      if (
        event.target !== targetRef.current &&
        !isContainsElement([targetRef, ...this.exclude], event.target as HTMLElement) &&
        isValidEvent(event)
      ) {
        this.isPointerDown = true
      }
    },
    onPointerUp(event: PointerEvent): void {
      if (
        this.isPointerDown &&
        isValidEvent(event) &&
        targetRef.current !== event.target &&
        !isContainsElement([targetRef, ...this.exclude], event.target as HTMLElement)
      ) {
        this.outsideHandler(event)
      }

      this.isPointerDown = false
    },
  }))

  state.outsideHandler = handler
  state.exclude = exclude

  useEffect(() => {
    if (isDisabled) return

    const target = targetRef.current
    const doc = getOwnerDocument(target)

    const onPointerDown = state.onPointerDown.bind(state)
    const onPointerUp = state.onPointerUp.bind(state)

    doc.addEventListener('pointerdown', onPointerDown, true)
    doc.addEventListener('pointerup', onPointerUp, true)

    return () => {
      doc.removeEventListener('pointerdown', onPointerDown, true)
      doc.removeEventListener('pointerup', onPointerUp, true)
    }
  }, [isDisabled])

  return targetRef
}
