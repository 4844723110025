import { DiaryWidgetResult } from './components/DiaryWidgetResult/DiaryWidgetResult'
import style from './style.module.scss'

const DiaryLeftBar = () => {
  return (
    <div className={style.root}>
      <DiaryWidgetResult />
    </div>
  )
}
export { DiaryLeftBar }
