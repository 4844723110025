import { DiaryWeekListQuery, E_TableFilterExchangeType } from '@/redux/trades/trades.types'
import {
  binanceTableFilterExchangeIds,
  getOwnApiKeyIdsWithType,
} from '../../HistoryTrades/components/TableFilters/utils'
import { useAppSelector } from '@/utils'
import { getIdsByApiKeyList } from '../../HistoryTrades/helpers/getIdsByApiKeyList'

type UseExchangeTypeHandlerArgs = {
  filterValue: E_TableFilterExchangeType
  setExchangeType: (value: E_TableFilterExchangeType) => void
  setFilter: (value: DiaryWeekListQuery) => void
  filters: DiaryWeekListQuery | null
}
const useExchangeTypeHandler = ({ filterValue, setExchangeType, setFilter, filters }: UseExchangeTypeHandlerArgs) => {
  const { me } = useAppSelector(state => state.trades)
  const exchangeTypeHandler = (type: E_TableFilterExchangeType) => {
    if (filterValue === E_TableFilterExchangeType.All && type === E_TableFilterExchangeType.All) return
    if (filterValue === type || type === E_TableFilterExchangeType.All) {
      setExchangeType(E_TableFilterExchangeType.All)
      setFilter({ ...filters, api_key_id: undefined })
    } else {
      setExchangeType(type)
      const exchangeFilteredIds = getOwnApiKeyIdsWithType(type as E_TableFilterExchangeType, me?.api_keys_list)
      if (type === E_TableFilterExchangeType.Binance || type === E_TableFilterExchangeType.BinanceVip) {
        const binanceExchangeIds = binanceTableFilterExchangeIds(me, type)
        setFilter({
          ...filters,
          api_key_id: binanceExchangeIds,
        })
      } else {
        setFilter({
          ...filters,
          api_key_id: getIdsByApiKeyList(me ?? undefined, exchangeFilteredIds),
        })
      }
    }
  }

  return { exchangeTypeHandler }
}

export default useExchangeTypeHandler
