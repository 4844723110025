function testPlatform(reg: RegExp): boolean {
  if ((window.navigator as any)['userAgentData']) {
    return reg.test((window.navigator as any)['userAgentData'].platform)
  }

  /** marked as deprecated, may be removed from "navigator API" */
  if (window.navigator.platform) {
    return reg.test(window.navigator.platform)
  }

  return false
}

interface Brand {
  brand: string
  version: string
}

function testUserAgent(reg: RegExp): boolean {
  if ((window.navigator as any)['userAgentData']) {
    return (window.navigator as any)['userAgentData'].brands.some((brand: Brand) => reg.test(brand.brand))
  }

  if (window.navigator.userAgent) {
    return reg.test(window.navigator.userAgent)
  }

  return false
}

export function isMac(): boolean {
  return testPlatform(/Mac/i)
}

export function isIPhone(): boolean {
  return testPlatform(/iPhone/i)
}

export function isIPod(): boolean {
  return testPlatform(/iPod/i)
}

export function isIPad(): boolean {
  if (testPlatform(/iPad/i)) {
    return true
  }

  // iPadOS 13 lies and says it's a Mac, but we can distinguish by detecting touch support.
  if (isMac() && navigator.maxTouchPoints > 1) {
    return true
  }

  return false
}

export function isIOS(): boolean {
  return isIPhone() || isIPad() || isIPod()
}

export function isAppleDevice(): boolean {
  return isMac() || isIOS()
}

export function isChrome(): boolean {
  return testUserAgent(/Chrome/i)
}

export function isWebKit(): boolean {
  return testUserAgent(/AppleWebKit/i) && !isChrome()
}

export function isAndroid(): boolean {
  return testUserAgent(/Android/i) || testPlatform(/Android/i)
}

export function isFirefox(): boolean {
  return testUserAgent(/Firefox/i)
}

export function isMobileOpera(): boolean {
  return testUserAgent(/Opera Mini/i)
}

export function isMobileIE(): boolean {
  return testUserAgent(/IEMobile/i)
}

export function isMobile(): boolean {
  return isAndroid() || isIOS() || isMobileOpera() || isMobileIE()
}
