import { FC } from 'react'

type TmmHintIconProps = {
  className?: string
}

export const TmmHintIcon: FC<TmmHintIconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={14} fill="none" className={className}>
    <rect width={14} height={14} x={0.939} fill="#1C1A24" rx={4.942} />
    <path
      fill="#FAFAFA"
      d="M8.02 8.046c.222 0 .333-.141.365-.368.027-.314.13-.48.558-.741.444-.28.692-.642.692-1.169 0-.779-.607-1.31-1.491-1.31-.672 0-1.181.29-1.383.759a.913.913 0 0 0-.092.406c0 .231.134.376.36.376.179 0 .3-.086.371-.3.105-.348.346-.537.708-.537.401 0 .679.262.679.637 0 .331-.137.52-.542.78-.408.254-.61.537-.61.964v.066c0 .251.137.437.385.437Zm.007 1.496a.478.478 0 0 0 .473-.49.475.475 0 0 0-.473-.485.478.478 0 0 0-.477.486c0 .272.212.49.477.49Z"
    />
  </svg>
)
