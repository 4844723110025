import { Key, ReactNode } from 'react'

export enum SelectSize {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum SelectVariant {
  Lined = 'lined',
  Raw = 'raw',
}

export type SelectOption<T extends string = string> = {
  /** unique ID to check if option is selected */
  value: T

  /** dropdown view */
  label: ReactNode

  /** selected view */
  selected?: ReactNode

  /** options will be divided into groups */
  group?: string

  /** search field **/
  search?: string

  /** dropdown view for initial label value  **/
  initialShowedLabel?: string

  /** options will be divided into groups  and can has element */
  element?: string | ReactNode

  /** icon that will be rendered on the right */
  rightIcon?: ReactNode

  /** if option is disabled */
  disabled?: boolean
}

export type SelectProps<T extends string> = {
  /** networks as options for SelectList */
  options: SelectOption<T>[]

  /** selected value from above */
  value: T | null | undefined

  /** visible text if nothing is selected */
  placeholder?: ReactNode

  /** label **/
  label?: ReactNode

  /** if SelectList is disabled */
  disabled?: boolean

  /** size */
  size?: SelectSize

  /** variant **/
  variant?: SelectVariant

  /** description of the error */
  errorMessage?: ReactNode

  /** description under wrapper **/
  description?: ReactNode

  /** show search input **/
  withSearch?: boolean

  /** className for wrapper */
  className?: string

  /** className for dropdown */
  dropdownClassName?: string

  /** onChange handler (value) */
  onChange: (value: T) => void

  /** onSelect handler (item) */
  onSelect?: (value: any) => void

  dataTestId?: string

  toggleClassname?: string

  labelClassname?: string

  /** only the button is displayed (used for inputs) */
  buttonMode?: boolean

  /** function to get the correct value for the "key" property */
  trackBy?: (value: any) => Key | null | undefined
}

export interface GroupedOptions<T extends string = string> {
  title?: string
  options: SelectOption<T>[]
}
