import { InteractiveModal } from '@/ui/kit'
import styles from './style.module.scss'
import { RiskManagerRiskSubscription } from '@/ui/molecules/RiskManager/RiskManager.Components/RiskManager.RiskSubscription'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'
import { useActions } from '@/utils'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const PopupRiskManager = (props: Props) => {
  const { GetRiskManagerTC } = useActions(riskManagerActions)

  function handleActivateSuccess() {
    GetRiskManagerTC()
    props.onClose()
  }

  return (
    <InteractiveModal className={styles.modal} isOpen={props.isOpen}>
      <RiskManagerRiskSubscription
        handleBackSuccess={handleActivateSuccess}
        handleClose={props.onClose}
        showCloseControl={true}
      />
    </InteractiveModal>
  )
}
