import { Button, InteractiveModal } from '@/ui/kit'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import { useTeamData } from '@/utils/hooks/useTeamData'
import clsx from 'clsx'
import { E_TEAM_USER_VIEWS } from '../../TeamWidget.Types'
import { useActions, useAppSelector } from '@/utils'
import { teamAsyncActions } from '@/redux/team/team.actions'
import { TeamFormSubscription } from '@/ui/molecules/TeamForm'
import { teamActions } from '@/redux/team/team.slice'
import { EInteractiveModalVariant } from '@/ui/kit/Modals/InteractiveModal/InteractiveModal'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'

import styles from './style.module.scss'

export const TeamWidgetForm: FC<{ className?: string }> = props => {
  const { className } = props
  const { userData, userView } = useTeamData()
  const { teamCode } = userData.teamUserInfo
  const [isModalShown, setIsModalShown] = useState(false)

  const { GetRequestedTeamTC, GetUserTeamInfoTC, TeamCancelJoinTC } = useActions(teamAsyncActions)
  const { setRequestedTeamReset, setTeamCancelJoinReset } = useActions(teamActions)
  const { teamRequestedRequestStatus, teamCancelJoinRequestStatus } = useAppSelector(state => state.team)

  const isCancelBtnDisabled = useMemo(() => {
    return teamCancelJoinRequestStatus === 'loading'
  }, [teamCancelJoinRequestStatus])

  const handleShowJoinForm = useCallback(() => {
    GetRequestedTeamTC(teamCode)
  }, [GetRequestedTeamTC, teamCode])

  const handleCloseModal = useCallback(() => {
    setRequestedTeamReset()
    setIsModalShown(false)
  }, [setRequestedTeamReset])

  const handleCancelRequest = useCallback(() => {
    TeamCancelJoinTC()
  }, [TeamCancelJoinTC])

  const handleSuccess = useCallback(() => {
    GetUserTeamInfoTC()
    setIsModalShown(false)
  }, [])

  // show submit modal on team request
  useEffect(() => {
    if (teamRequestedRequestStatus !== 'succeeded') return
    setIsModalShown(true)
  }, [teamRequestedRequestStatus])

  // refetch data after submit cancellation
  useEffect(() => {
    if (isStatusFinal(teamCancelJoinRequestStatus)) {
      setTeamCancelJoinReset()
      GetUserTeamInfoTC()
    }
  }, [teamCancelJoinRequestStatus, GetUserTeamInfoTC, setTeamCancelJoinReset])

  return (
    <form className={clsx(styles.root, className)}>
      {userView === E_TEAM_USER_VIEWS.DEFAULT && (
        <>
          <Button.Accent
            label={t({ id: 'teamWidgetForm.codeApplyBtn', message: 'Send request' })}
            className={styles.button}
            onClick={handleShowJoinForm}
          />
        </>
      )}
      {userView === E_TEAM_USER_VIEWS.SUBMITTED && (
        <Button.AccentDark
          label={t({ id: 'teamWidgetForm.codeCancelBtn', message: 'Cancel request' })}
          className={styles.button}
          onClick={handleCancelRequest}
          disabled={isCancelBtnDisabled}
        />
      )}

      <InteractiveModal isOpen={isModalShown} onClose={handleCloseModal} variant={EInteractiveModalVariant.MEDIUM}>
        <TeamFormSubscription
          handleBack={handleCloseModal}
          handleBackSuccess={handleSuccess}
          handleClose={handleCloseModal}
          showCloseControl={true}
        />
      </InteractiveModal>
    </form>
  )
}
