import { memo } from 'react'
import AssetsAmount from '@/ui/molecules/AssetsAmount'
import { useOverview } from '@/utils/hooks/useOverview'
import { useActions } from '@/utils'
import { overviewActions } from '@/redux/overview/overview.slice'
import style from './style.module.scss'
import { TAccountType } from '@/core/types/overview'

const AssetsAmountList = () => {
  const { balancesArray, accountType } = useOverview()
  const { setAccountType } = useActions(overviewActions)

  const handleSelect = (id: TAccountType) => () => {
    setAccountType(id)
  }

  return (
    <div className={style.assetsAmountList}>
      {balancesArray.map(item => (
        <AssetsAmount key={item.id} active={accountType === item.id} onClick={handleSelect(item.id)} {...item} />
      ))}
    </div>
  )
}

export default memo(AssetsAmountList)
