import { t } from '@lingui/macro'
import styles from './style.module.scss'
import { SVG } from '@/assets/svg'

type Props = {
  position: number
  title: string
  amount: string
  currency: string
}

export const ResultCard = (props: Props) => {
  const getMedalIcon = (position: number) => {
    switch (position) {
      case 1:
        return <SVG.LeaderBoard.FirstPlace />
      case 2:
        return <SVG.LeaderBoard.SecondPlace />
      case 3:
        return <SVG.LeaderBoard.ThirdPlace />
      default:
        return <div className={styles.position}>#{position}</div>
    }
  }

  return (
    <div className={styles.resultCard}>
      <div className={styles.medal}>{getMedalIcon(props.position)}</div>
      <div className={styles.content}>
        <div className={styles.titleWrapp}>
          <span className={styles.title}>{props.title}</span>
          <span className={styles.description}>
            {t({ id: 'leaderBoard.resultCard.leaderboard', comment: 'Leaderboard' })}
          </span>
        </div>
        <div className={styles.amountWrapp}>
          <span className={styles.amount}>{props.amount}</span>
          <span className={styles.currency}>{props.currency}</span>
        </div>
      </div>
    </div>
  )
}
