import { APIResponseV3WithTime, WalletBalanceV5 } from 'bybit-api'
import { bybitRestService } from './rest'

// fallback for the time while account is not inited
export const getWalletBalanceWrap = async (params: {
  accountType: string
}): Promise<{ response: APIResponseV3WithTime<{ list?: WalletBalanceV5[] }>; status: number }> => {
  try {
    const response = await bybitRestService.getWalletBalance(params)
    return { response: response.data, status: response.status }
  } catch {
    return {
      response: {
        result: {
          list: [],
        },
        retCode: 0,
        retMsg: '',
        time: 0,
      },
      status: 404,
    }
  }
}
