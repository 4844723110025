import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'
import { storage } from '../lib/storage'

export const useLogout = () => {
  const { logout } = useAuth0()

  const logoutWrapper = useCallback(
    (logoutParams: any = {}) => {
      storage.clearUserSpecificLocalStorageItems()
      storage.cleanAll()
      sessionStorage.clear()

      logout(logoutParams)
    },
    [logout]
  )

  return logoutWrapper
}
