import { FC } from 'react'
import { Hint, usePopperRef } from '@/ui/kit'
import { Portal } from '@/ui/utils/Portal'
import s from './style.module.scss'
import { SVG } from '@/assets/svg'
import { useFlag } from '@/utils'
import clsx from 'clsx'

type Props = {
  profit: boolean
  label?: string
  className?: string
}

const SideIndicator: FC<Props> = props => {
  const [ref, setRef] = usePopperRef<HTMLDivElement>()
  const [isVisible, , enableHint, disableHint] = useFlag()

  return (
    <div
      className={clsx(s.wrapper, props.className)}
      ref={setRef}
      onMouseOver={enableHint}
      onFocus={enableHint}
      onMouseOut={disableHint}
      onBlur={disableHint}
    >
      {props.profit ? <SVG.Tmm.HighSide /> : <SVG.Tmm.LowSide />}
      {isVisible && props.label && (
        <Portal>
          <Hint targetRef={ref} placement="bottom" text={props.label} className={s.hintWrapper} hitSizeSmall />
        </Portal>
      )}
    </div>
  )
}

export { SideIndicator }
