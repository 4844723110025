import { ScrollToErrorContext, ScrollableItemsMap } from '@/providers/ScrollToErrorProvider'
import { RefObject, useCallback, useContext, useLayoutEffect } from 'react'

interface ScrollToErrorOptions<T extends Element> {
  nodeRef?: RefObject<T>
  isValid?: boolean
}

function scrollToFirstInvalidNode(itemsMap: ScrollableItemsMap) {
  const items = Array.from(itemsMap.entries())

  for (let i = 0; i < items.length; i++) {
    const [nodeRef, isValid] = items[i]

    if (!isValid) {
      nodeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      break
    }
  }
}

export function useScrollToError<T extends Element = any>(options: ScrollToErrorOptions<T> = {}): () => void {
  const { nodeRef, isValid = true } = options
  const context = useContext(ScrollToErrorContext)

  if (!context) {
    throw new Error('useScrollToError must be used within ScrollToErrorProvider')
  }

  useLayoutEffect(() => {
    if (!nodeRef) {
      return
    }

    context.set(nodeRef, isValid)
  }, [nodeRef, isValid])

  useLayoutEffect(() => {
    if (!nodeRef) {
      return
    }

    return () => {
      context.delete(nodeRef)
    }
  }, [nodeRef])

  return useCallback(() => {
    /** give time to register and update all components */
    setTimeout(scrollToFirstInvalidNode, 100, context)
  }, [context])
}
