import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'
import { Radio } from '@/ui/kit'
import { useActions, useAppSelector } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'
import style from '../../style.module.scss'
import s from './style.module.scss'

interface Props {
  disabled?: boolean
}

const MultiAssetMode: FC<Props> = ({ disabled }) => {
  const { GetMultiAssetMarginTC, UpdateMultiAssetMarginTC } = useActions(profileActions)
  const { multiAssetsMargin, multiAssetsMarginStatus } = useAppSelector(state => state.profile)
  const { isExchangeProviderInitialized } = useAppSelector(state => state.app)

  const [active, setActive] = useState(multiAssetsMargin)

  useEffect(() => {
    if (isExchangeProviderInitialized && multiAssetsMarginStatus === 'idle') GetMultiAssetMarginTC()
  }, [isExchangeProviderInitialized, multiAssetsMarginStatus])

  useEffect(() => {
    if (typeof active === 'undefined' && typeof multiAssetsMargin !== 'undefined') {
      setActive(multiAssetsMargin)
    }
  }, [active, multiAssetsMargin])

  const handleChange = async (value: string | number) => {
    const active = Boolean(+value)

    setActive(active)

    const response = await UpdateMultiAssetMarginTC({ multiAssetsMargin: active })

    if ('error' in response) {
      // @ts-ignore
      let errorCode = response?.payload?.codes?.[0]
      let errorMessage = ''

      switch (Number(errorCode)) {
        case 40020:
          errorMessage = t({ id: 'profile.multiAssetError-40020', message: '(isolated margin enabled).' })
          break
        case 40021:
          errorMessage = t({ id: 'profile.multiAssetError-40021', message: '(already set).' })
      }

      enqueueSnackbar(
        `${t({ id: 'profile.multiAssetError', message: 'Unable to adjust to Multi-Assets mode' })} ${errorMessage}`,
        {
          variant: 'error',
        }
      )

      setActive(!active)
    } else {
      enqueueSnackbar(t({ id: 'profile.multiAssetSuccess', message: 'Multi Asset setting successfully saved ' }), {
        variant: 'success',
      })
    }
  }

  return (
    <section className={clsx(style.section, { [s.loaded]: active !== undefined })}>
      {disabled && <div className={style.disabledAssetMode} />}
      <h6 className={style.sectionHeading}>{t({ id: 'profile.settings.assetHeading', message: `Asset mode` })}</h6>

      <Radio
        name="assetMode"
        value="0"
        checked={active === false}
        className={clsx(style.setting, s.radio)}
        onChange={handleChange}
      >
        <SVG.Settings.SingleAsset className={style.sectionIcon} />
        <div>
          <div className={style.settingTitle}>{t({ id: 'profile.singleAsset', message: `Single asset mode` })}</div>
          <div className={style.settingStatus}>
            {t({
              id: 'profile.singleAssetDescription',
              message:
                'Supports USDⓈ-M Futures trading by only using the single margin asset of the symbol. · PNL of the same margin asset positions can be offset. · Supports Cross Margin Mode and Isolated Margin Mode.',
            })}
          </div>
        </div>
      </Radio>

      <Radio
        name="assetMode"
        value="1"
        checked={active === true}
        className={clsx(style.setting, s.radio)}
        onChange={handleChange}
      >
        <SVG.Settings.MultiAsset className={style.sectionIcon} />
        <div>
          <div className={style.settingTitle}>{t({ id: 'profile.multiAsset', message: `Multi asset mode` })}</div>
          <div className={style.settingStatus}>
            {t({
              id: 'profile.multiAssetDescription',
              message:
                'USDⓈ-M Futures trading across multiple margin assets. · PNL can be offset among the different margin asset positions. · Only supports Cross Margin Mode.',
            })}
          </div>
        </div>
      </Radio>
    </section>
  )
}

export { MultiAssetMode }
