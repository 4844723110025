import { FC, memo } from 'react'
import { t } from '@lingui/macro'
import { formatNumber, useAppSelector } from '@/utils'
import LabelWithQuestion from '../LabelWithQuestion'
import { useOverview } from '@/utils/hooks/useOverview'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { OVERVIEW_SHOW_TOP_LABELS, OVERVIEW_SHOW_TOTAL_MARGIN } from '@/core/config/overview'

const TopBarLabels: FC = () => {
  const { balanceOutputConfig } = useAppSelector(state => state.overview)
  const { instrumentType, overviewByAccount, balanceByInstrument } = useOverview()
  const { totalMarginBalance, unrealizedPnl } = overviewByAccount
  const { symbol } = balanceByInstrument

  if (instrumentType === undefined || !OVERVIEW_SHOW_TOP_LABELS.includes(instrumentType)) return null

  return (
    <div className={style.topBarLabels}>
      {OVERVIEW_SHOW_TOTAL_MARGIN.includes(instrumentType) && (
        <>
          {balanceOutputConfig.showMargin && (
            <div className={style.labelWrap}>
              <LabelWithQuestion
                hintText={t({
                  message: 'This is account balance plus your Unrealized PnL',
                  id: 'mainPage.topBarLabels-1.hint',
                })}
                label={t({ message: 'Margin balance', id: 'mainPage.topBarLabels-1.label' })}
                dataTestId={DataTestIds.WalletMarginBalanceText}
              />
              <span className={style.amount} data-testid={DataTestIds.WalletMarginBalance}>
                {formatNumber(totalMarginBalance)}&nbsp;
                <span className={style.symbol}>{symbol}</span>
              </span>
            </div>
          )}
          <div className={style.labelWrap}>
            <LabelWithQuestion
              hintText={t({
                message: 'This is current Profit and Loss on open positions.',
                id: 'mainPage.topBarLabels-2.hint',
              })}
              label={t({ message: 'Unrealized PnL', id: 'mainPage.topBarLabels-2.label' })}
              dataTestId={DataTestIds.WalletUnrealizedPnlText}
            />
            <span className={style.amount} data-testid={DataTestIds.WalletUnrealizedPnl}>
              {formatNumber(unrealizedPnl)}&nbsp;
              <span className={style.symbol}>{symbol}</span>
            </span>
          </div>
        </>
      )}
      {!OVERVIEW_SHOW_TOTAL_MARGIN.includes(instrumentType) && (
        <>
          <div className={style.labelWrap}>
            <LabelWithQuestion
              hintText={t({
                message: 'This is current Profit and Loss on open positions.',
                id: 'mainPage.topBarLabels-3.hint',
              })}
              label={t({ message: 'Unrealized PnL', id: 'mainPage.topBarLabels-3.label' })}
              dataTestId={DataTestIds.WalletUnrealizedPnlText}
            />
            <div className={style.amount} data-testid={DataTestIds.WalletUnrealizedPnl}>
              {formatNumber(unrealizedPnl)}&nbsp;
              <span className={style.symbol}>{symbol}</span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default memo(TopBarLabels)
