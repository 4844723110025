import React from 'react'

export const Person = () => {
  return (
    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_iii_6255_314345)">
        <rect
          x="8.11328"
          width="39.1243"
          height="39.1243"
          rx="15"
          transform="rotate(11.9669 8.11328 0)"
          fill="url(#paint0_radial_6255_314345)"
        />
        <g filter="url(#filter1_iii_6255_314345)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.0318 14.0585C22.7513 13.5751 20.5029 15.0371 20.0195 17.3176C19.5481 19.542 20.9149 21.7542 23.1226 22.2962C23.1337 22.299 23.1448 22.3015 23.156 22.3039L23.3838 22.3522C23.395 22.3545 23.4062 22.3567 23.4175 22.3588C25.6532 22.7586 27.7916 21.3121 28.2887 19.0817L28.291 19.0708C28.7743 16.7902 27.3124 14.5418 25.0318 14.0585ZM18.6788 24.4808L18.6767 24.4816C17.1817 25.0773 15.5321 27.5291 16.28 29.182C17.151 31.107 19.3536 31.907 21.2615 32.3114C23.1689 32.7156 25.5232 32.881 27.0947 31.463C28.4361 30.2526 27.9234 27.3542 26.8028 26.2059C25.811 25.1793 24.4125 24.5102 22.9925 24.2092C21.5705 23.9078 20.0148 23.9517 18.6788 24.4808Z"
            fill="#FAFAFA"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_iii_6255_314345"
          x="-0.998047"
          y="-2.07088"
          width="47.3848"
          height="50.5285"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-3.10632" />
          <feGaussianBlur stdDeviation="1.03544" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.791819 0 0 0 0 0.109609 0 0 0 0 0.109609 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6255_314345" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1.03544" />
          <feGaussianBlur stdDeviation="0.51772" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.847121 0 0 0 0 0.305093 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_6255_314345" result="effect2_innerShadow_6255_314345" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="2.07088" />
          <feGaussianBlur stdDeviation="1.03544" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.89676 0 0 0 0 0.95251 0 0 0 0.49 0" />
          <feBlend mode="normal" in2="effect2_innerShadow_6255_314345" result="effect3_innerShadow_6255_314345" />
        </filter>
        <filter
          id="filter1_iii_6255_314345"
          x="15.0938"
          y="12.3879"
          width="13.2891"
          height="21.8017"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-2" />
          <feGaussianBlur stdDeviation="0.788447" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541052 0 0 0 0 0.501537 0 0 0 0 0.483941 0 0 0 0.37 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6255_314345" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_6255_314345" result="effect2_innerShadow_6255_314345" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.59202" />
          <feGaussianBlur stdDeviation="0.79601" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.89676 0 0 0 0 0.95251 0 0 0 0.49 0" />
          <feBlend mode="normal" in2="effect2_innerShadow_6255_314345" result="effect3_innerShadow_6255_314345" />
        </filter>
        <radialGradient
          id="paint0_radial_6255_314345"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.11328) rotate(40.8151) scale(51.6955 683.162)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
