import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'
import {
  ApiKeysAPI,
  GetApiKeyByIdPayload,
  RemoveApiKeysPayload,
  TCreateApiKeyPayloadUnion,
  UpdateApiKeysPayload,
} from '@/backend/api/apiKeyAPI'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { ApiKeyType } from './apiKey.types'
import { ApiKeyCreationDTO, IApiKeyStatisticsDTO } from '@/backend/models/ApiKeyDTO'
import { InternalApi } from '@/services/rest/tigerGateway'
import { TReadOnlyApiKeyDTO, exchangeApi } from '@/backend/api/exchange'

export const GetApiKeysTC = createAsyncThunk<ApiKeyType[], void, ThunkError>(
  nameof(ApiKeysAPI.getApiKeys),
  async (_, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.getApiKeys }, thunkAPI)
  }
)

export const GetApiKeysStatisticsTC = createAsyncThunk<IApiKeyStatisticsDTO, void, ThunkError>(
  nameof(ApiKeysAPI.getApiKeysStatistics),
  async (_, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.getApiKeysStatistics }, thunkAPI)
  }
)

export const GetApiKeyByIdTC = createAsyncThunk<ApiKeyType, GetApiKeyByIdPayload, ThunkError>(
  nameof(ApiKeysAPI.getApiKeyById),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.getApiKeyById, payload }, thunkAPI)
  }
)

export const CreateApiKeyTC = createAsyncThunk<ApiKeyCreationDTO, TCreateApiKeyPayloadUnion, ThunkError>(
  nameof(InternalApi.methods.apiKey.createApiKey),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: InternalApi.methods.apiKey.createApiKey, payload }, thunkAPI)
  }
)

export const UpdateApiKeyTC = createAsyncThunk<unknown, UpdateApiKeysPayload, ThunkError>(
  nameof(ApiKeysAPI.updateApiKey),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.updateApiKey, payload }, thunkAPI)
  }
)

export const CreateMasterApiKeysTC = createAsyncThunk<unknown, void, ThunkError>(
  nameof(ApiKeysAPI.createMasterApiKeys),
  async (_, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.createMasterApiKeys }, thunkAPI)
  }
)

export const GetReadOnlyKeyStatusTC = createAsyncThunk<TReadOnlyApiKeyDTO, void, ThunkError>(
  nameof(exchangeApi.getReadOnlyKey),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: exchangeApi.getReadOnlyKey, payload }, thunkAPI)
  }
)

export const CleanGetApiByIdKeysTC = createAsyncThunk(nameof('CleanGetById/ApiKey'), async () => Promise.resolve())

export const CleanCreatedApiKeysTC = createAsyncThunk(nameof('CleanCreated/ApiKey'), async () => Promise.resolve())

export const CleanUpdateApiKeyTC = createAsyncThunk(nameof('CleanUpdated/ApiKey'), async () => Promise.resolve())

export const RemoveApiKeyTC = createAsyncThunk<any, RemoveApiKeysPayload, ThunkError>(
  nameof(ApiKeysAPI.removeApiKey),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.removeApiKey, payload }, thunkAPI)
  }
)
