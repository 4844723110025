import clsx from 'clsx'
import { FC } from 'react'
import avatar from '@/assets/png/avatar.png'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

interface IProps {
  size: number
  bordered?: boolean
  className?: string
}

const UserAvatar: FC<IProps> = props => {
  const { size, bordered, className } = props

  return (
    <div className={clsx({ [style.bordered]: bordered }, className)} data-testid={DataTestIds.UserAvatar}>
      <img src={avatar} width={size} height={size} alt="Avatar." className={style.image} />
    </div>
  )
}

export { UserAvatar }
