import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { t } from '@lingui/macro'
import { Status } from '@/backend/types'
import { Loader } from '@/ui/molecules/Loader'
import { ErrorBase, ErrorBaseProps } from '@/ui/kit/Errors/ErrorBase'
import { kycActions } from '@/redux/kyc/kyc.slice'
import { apiKeyActions } from '@/redux/apiKey/apiKey.slice'
import { cashbackActions } from '@/redux/cashback/cashback.slice'
import { useActions, useAppSelector } from '@/utils'
import { useKYC } from '@/utils/hooks'
import { parseBooleanEnv } from '@/utils/lib/parseEnvVariable'
import { partnerActions } from '@/redux/partner/partner.slice'
import { teamActions } from '@/redux/team/team.slice'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { isExchangeBinance } from '@/utils/lib/exchange'
import { tradesActions } from '@/redux/trades/trades.slice'
import { defineMetadata } from '@/utils/lib/metadata/mapper'
import { profileAsyncActions } from '@/redux/profile/profile.actions'
import { featureFlagChecker } from '@/utils/lib/featureFlagChecker'
import { EXCHANGES_TYPE } from '@/core/constants'

interface IDataProviderProps {
  children: ReactNode
}

const DataProvider: FC<IDataProviderProps> = props => {
  const { GetApiKeysStatisticsTC } = useActions(apiKeyActions)
  const { GetWorkflowTC, GetLimitsTC, GetAvailableLimitsTC, GetUserKycLinkStatusTC, InitWorkflowTC } =
    useActions(kycActions)
  const { GetCashbackTC } = useActions(cashbackActions)
  const { GetPartnersListTC } = useActions(partnerActions)
  const { GetUserTeamInfoTC, GetTeamRiskManagerTradingStatusTC } = useActions(teamActions)
  const { UpdateMetadata } = useActions(profileAsyncActions)

  const {
    updateFormKycStatus,
    availableLimitsStatus,
    limitsStatus,
    userKycLinkState,
    userKycLinkStatusRequestStatus,
    workflowStatusCode,
  } = useAppSelector(state => state.kyc)
  const { statusStatistics: apiKeyStatusStatistics, createdApiReadOnlyStatusInitial } = useAppSelector(
    state => state.apiKeys
  )
  const { isExchangeProviderInitialized } = useAppSelector(state => state.app)
  const { status: cashbackStatus } = useAppSelector(state => state.cashback)
  const { listStatus } = useAppSelector(state => state.partner)
  const { teamInfoRequestStatus, tradingStatusRequestStatus } = useAppSelector(state => state.team)
  const { exchangeType } = useExchageType()
  const {
    vipAccess,
    metadata: { isStatisticsInit, flags },
  } = useAppSelector(state => state.profile)
  const { TradesUserInitTC, TradesAvailableUsersTC } = useActions(tradesActions)
  const { tradesUserInitStatus, availableUsersTradingStatus, availableUsersTrading } = useAppSelector(
    state => state.trades
  )
  const { kycLoadStatus, isKycNeedWorkflow } = useKYC()

  useEffect(() => {
    // load secondary data
    if (listStatus === 'idle') GetPartnersListTC()
    if (availableUsersTradingStatus === 'idle') TradesAvailableUsersTC()
    if (availableUsersTrading?.isStatisticAvailable) {
      if (tradesUserInitStatus === 'idle' && !isStatisticsInit) TradesUserInitTC()
    }
    if (teamInfoRequestStatus === 'idle') GetUserTeamInfoTC()
    if (!isExchangeProviderInitialized) return
    if (
      isExchangeBinance(exchangeType) &&
      userKycLinkState.status === undefined &&
      userKycLinkStatusRequestStatus === 'idle'
    )
      GetUserKycLinkStatusTC()
    if (cashbackStatus === 'idle' && exchangeType !== 'BINANCE_VIP') GetCashbackTC() // needed for popup sequesnce

    if (tradingStatusRequestStatus === 'idle' && isExchangeBinance(exchangeType)) GetTeamRiskManagerTradingStatusTC()
  }, [
    isExchangeProviderInitialized,
    cashbackStatus,
    GetCashbackTC,
    listStatus,
    teamInfoRequestStatus,
    tradingStatusRequestStatus,
    exchangeType,
    isStatisticsInit,
    userKycLinkState.status,
    availableUsersTradingStatus,
    tradesUserInitStatus,
    availableUsersTrading,
    flags,
  ])

  useEffect(() => {
    if (tradesUserInitStatus === 'succeeded') {
      UpdateMetadata(defineMetadata({ isStatisticsInit: true }))
    }
  }, [tradesUserInitStatus, UpdateMetadata])

  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const isLoaded = useRef(false)

  const status: Status = useMemo(() => {
    const statuses = [kycLoadStatus]

    if (statuses.some(s => s === 'failed')) {
      return 'failed'
    }

    if (statuses.every(s => s === 'idle') || statuses.some(s => s === 'loading')) {
      return 'loading'
    }

    isLoaded.current = true
    return 'succeeded'
  }, [kycLoadStatus])

  useEffect(() => {
    window.onoffline = () => {
      setIsOnline(false)
    }
  }, [])

  useEffect(() => {
    if (isKycNeedWorkflow && updateFormKycStatus === 'idle') GetWorkflowTC()
  }, [isKycNeedWorkflow, GetWorkflowTC, updateFormKycStatus])

  useEffect(() => {
    if (workflowStatusCode === 404) {
      InitWorkflowTC()
    }
  }, [workflowStatusCode])

  useEffect(() => {
    // Handle default REST for brokers
    if (exchangeType === EXCHANGES_TYPE.OKX_AFFILIATE) {
      limitsStatus === 'idle' && GetLimitsTC()
      availableLimitsStatus === 'idle' && GetAvailableLimitsTC()
      return
    }

    limitsStatus === 'idle' && GetLimitsTC()
    apiKeyStatusStatistics === 'idle' && GetApiKeysStatisticsTC()
    availableLimitsStatus === 'idle' && GetAvailableLimitsTC()
  }, [apiKeyStatusStatistics, availableLimitsStatus, limitsStatus])

  const errorBaseProps = useMemo<ErrorBaseProps>(() => {
    return parseBooleanEnv(process.env.REACT_APP_MAINTENANCE)
      ? {
          customHeader: t({ message: `Service maintenance`, id: 'core.maintenance.heading' }),
          customText: (
            <>
              {t({
                message: `Tiger.Trade Broker is currently undergoing a routine maintenance to ensure you get the best trading experience. The maintenance is expected to continue for a hew hours at most.`,
                id: 'core.maintenance.msg-0',
              })}
              <br />
              {t({ message: `Please check back later.`, id: 'core.maintenance.msg-1' })}
              <br />
              <br />
              {t({ message: `Thank you for understanding!`, id: 'core.maintenance.msg-2' })}
            </>
          ),
        }
      : {
          customHeader: t({ message: `Couldn’t load data`, id: 'core.notLoadData' }),
          isRefreshButtonVisible: true,
          isSupportMessage: true,
        }
  }, [])

  if (!isOnline)
    return (
      <Loader.Overlay>
        <ErrorBase
          customHeader={t({ message: `Connection broken`, id: 'core.offline.heading' })}
          customText={
            <>
              {t({ message: `Seems your browser went offline`, id: 'core.offline.text.msg-0' })}
              <br />
              {t({ message: `Please try again when connection is restored`, id: 'core.offline.text.msg-1' })}
            </>
          }
          isRefreshButtonVisible
        />
      </Loader.Overlay>
    )

  if (!isLoaded.current && status === 'loading') {
    return (
      <Loader.Overlay>
        <Loader.Content />
      </Loader.Overlay>
    )
  }

  if (status === 'failed') {
    return (
      <Loader.Overlay>
        <ErrorBase {...errorBaseProps} />
      </Loader.Overlay>
    )
  }

  return <>{props.children}</>
}

export { DataProvider }
