import { FC } from 'react'
import { t } from '@lingui/macro'
import { Voucher } from '@/backend/models/VoucherDTO'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { formatDate } from '@/utils/lib/formatDate'
import s from './style.module.scss'

interface IVoucherAddSuccessProps {
  voucher?: Voucher
  isOpen: boolean
  onClose: () => void
}

const VoucherAddSuccess: FC<IVoucherAddSuccessProps> = props => {
  const { voucher = {} as Voucher, isOpen, onClose } = props

  return (
    <InteractiveModal isOpen={isOpen} className={s.root}>
      <InteractiveModalParts.Icon icon="success" className={s.icon} />
      <InteractiveModalParts.SubHeader text={t({ id: 'voucher.add.success.heading', message: 'Congratulations!' })} />
      <InteractiveModalParts.Explanation
        text={
          <>
            {t({ id: 'voucher.add.success.text-1', message: 'You’ve received' })}
            <span className={s.accent}>&nbsp;{voucher?.amount || 0}%&nbsp;</span>
            {t({ id: 'voucher.add.success.text-2', message: 'to spend on bonuses.' })}
          </>
        }
        className={s.text}
      />
      <InteractiveModalParts.Button
        text={t({ id: 'button.close', message: 'Close' })}
        variant={ButtonVariant.Primary}
        onClick={onClose}
      />
    </InteractiveModal>
  )
}

export { VoucherAddSuccess }
