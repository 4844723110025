import { SVG } from '@/assets/svg'
import style from '../style.module.scss'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import { Button, ButtonVariant } from '@/ui/kit'
import { FC } from 'react'

type KycStatusNewUserPendingProps = {
  handleNavigateToChooseExchange: () => void
}

const KycStatusNewUserPending: FC<KycStatusNewUserPendingProps> = ({ handleNavigateToChooseExchange }) => {
  return (
    <div className={style.container}>
      <div className={style.title}>{t({ id: 'binanceLink.heading', message: 'Верификация Binance' })}</div>
      <div className={style.infoText}>
        <SVG.OtherIcons.Pending />
        <Trans id="binanceLink.binanceStillCheckingYourAccount" message="Binance еще проверяет ваш аккаунт" />
      </div>
      <div className={style.chooseExchangeBlock}>
        <div>
          <SVG.OtherIcons.Lightning />
          <div className={style.textWhite}>
            <Trans
              id="binanceLink.youCanTradeWithoutBinanceVerify"
              message="Вы уже можете торговать на OKX и ByBit независимо от верификации Binance!"
            />
          </div>
        </div>
        <Button
          className={style.chooseExchangeButton}
          variant={ButtonVariant.AccentDark}
          onClick={handleNavigateToChooseExchange}
        >
          {t({ id: 'binanceLink.chooseExchange', message: 'Выбрать ByBit или OKX' })}
        </Button>
      </div>
    </div>
  )
}
export { KycStatusNewUserPending }
