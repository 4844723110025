import { useActions, useAppSelector } from '@/utils'
import { DayCard } from './components/DayCard/DayCard'
import style from './style.module.scss'
import { tradesActions } from '@/redux/trades/trades.slice'
import { FC, useEffect } from 'react'
import { Loader } from '@/ui/kit/Loader'
import { DiaryHeaderResult } from './components/WeekResult/DiaryHeaderResult'
import { EmptyList } from '@/ui/molecules'
import { t } from '@lingui/macro'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { Trans } from '@lingui/react'
import { urls } from '@/router/urls'
import { useNavigate } from 'react-router-dom'

type DiaryWeekInfoProps = {
  openBetween?: string
}
const DiaryWeekInfo: FC<DiaryWeekInfoProps> = ({ openBetween }) => {
  const { GetAnalyzerTC, resetDiaryAnalyzer } = useActions(tradesActions)
  const { diaryAnalyzerList, diaryAnalyzerStatus, diaryWeekFilter, isApiKeyExistWithEmptyArray } = useAppSelector(
    state => state.trades
  )
  const isCurrentDayOrBig =
    new Date(diaryAnalyzerList?.firstDayOfWeek ?? '').setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ||
    new Date(diaryAnalyzerList?.lastDayOfWeek ?? '').setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
  const isIncorrectUrl = openBetween?.includes('undefined')
  const navigate = useNavigate()
  useEffect(() => {
    if (isIncorrectUrl) return
    const dataAnalyze = {
      openBetween,
    }
    GetAnalyzerTC({
      ...diaryWeekFilter,
      openBetween: dataAnalyze.openBetween,
    })
    return () => {
      resetDiaryAnalyzer()
    }
  }, [openBetween, diaryWeekFilter])

  const weekListPlusSevenDays = () => {
    if (isCurrentDayOrBig) return
    const formattedStartWeek = new Date(diaryAnalyzerList?.firstDayOfWeek ?? '')
    const formattedEndWeek = new Date(diaryAnalyzerList?.lastDayOfWeek ?? '')
    formattedStartWeek.setDate(formattedStartWeek.getDate() + 7)
    formattedEndWeek.setDate(formattedEndWeek.getDate() + 7)
    navigate(
      `${urls.summary}/${formattedStartWeek.toISOString().split('T')[0]}-${
        formattedEndWeek.toISOString().split('T')[0]
      }`
    )
  }

  const weekListMinusSevenDays = () => {
    const formattedStartWeek = new Date(diaryAnalyzerList?.firstDayOfWeek ?? '')
    const formattedEndWeek = new Date(diaryAnalyzerList?.lastDayOfWeek ?? '')
    formattedStartWeek.setDate(formattedStartWeek.getDate() - 7)
    formattedEndWeek.setDate(formattedEndWeek.getDate() - 7)
    navigate(
      `${urls.summary}/${formattedStartWeek.toISOString().split('T')[0]}-${
        formattedEndWeek.toISOString().split('T')[0]
      }`
    )
  }
  const closeHandler = () => {
    navigate(urls.summary)
  }

  if (diaryAnalyzerStatus === 'loading') return <Loader className={style.loader} />

  if (diaryAnalyzerStatus === 'failed') {
    return (
      <ErrorBase
        className={style.errorBlock}
        customHeader={t({
          id: 'trades.summary.errorHeaderText',
        })}
        customText={
          <Trans
            id="trades.summary.errorDescription"
            components={{
              br: <br />,
            }}
          />
        }
        isRefreshButtonVisible
      />
    )
  }

  if (isIncorrectUrl || isApiKeyExistWithEmptyArray)
    return (
      <EmptyList
        className={style.emptyList}
        label={t({
          id: 'historyTrades.emptyList',
          comment: 'emptyList',
        })}
      />
    )

  return (
    <div className={style.root}>
      {diaryAnalyzerList?.weekResult && (
        <DiaryHeaderResult
          weekData={diaryAnalyzerList.weekResult}
          firstDayOfWeek={diaryAnalyzerList.firstDayOfWeek}
          lastDayOfWeek={diaryAnalyzerList.lastDayOfWeek}
          prevDataHandler={weekListMinusSevenDays}
          nextDataHandler={weekListPlusSevenDays}
          currentDateOrBig={isCurrentDayOrBig}
          closeHandler={closeHandler}
          nextIconHintText={t({
            id: 'trades.summary.nextWeek',
          })}
          prevIconHintText={t({
            id: 'trades.summary.previousWeek',
          })}
          closeIconHintText={t({
            id: 'trades.summary.backTo',
          })}
          title={t({
            id: 'trades.summary.weeklySummary',
          })}
        />
      )}
      <div className={style.line} />
      <div className={style.container}>
        {diaryAnalyzerList?.days?.map((item, index) => (
          <DayCard key={index} data={item.data} date={item.date} lastDayOfWeek={item.date} />
        ))}
      </div>
    </div>
  )
}
export { DiaryWeekInfo }
