import { Button, ButtonSize, ButtonVariant } from '@/ui/kit'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import style from './style.module.scss'
import { FC, ReactNode, useMemo } from 'react'
import { ErrorWithRefreshPage } from './ErrorWithRefreshPage'

type TableErrorProps = {
  requestTimeStep?: number | null
  technicalError?: boolean
}

type ErrorConfig = {
  heading: ReactNode
  description: ReactNode
  button?: ReactNode
}

export const TableError: FC<TableErrorProps> = ({ requestTimeStep, technicalError = false }) => {
  const refreshHandler = () => {
    window.location.reload()
  }

  const errorsContent = useMemo<ErrorConfig>(() => {
    return technicalError
      ? {
          heading: t({
            id: 'history.trades.technicalErrorTitle',
          }),
          description: t({
            id: 'history.trades.technicalErrorDescription',
          }),
        }
      : {
          heading: t({
            id: 'history.trades.errorTitle',
            comment: 'Could`nt load Orders',
          }),
          description: (
            <Trans
              id="history.trades.errorDescription"
              components={{
                br: <br />,
              }}
            />
          ),
          button: (
            <Button
              variant={ButtonVariant.Accent}
              size={ButtonSize.Small}
              label={t({
                id: 'history.trades.errorButton',
                comment: 'Refresh page',
              })}
              onClick={refreshHandler}
              className={style.btn}
            />
          ),
        }
  }, [])

  return (
    <div className={style.errorContainer}>
      {!requestTimeStep ? (
        <>
          <div className={style.title}>{errorsContent.heading}</div>
          <div className={style.description}>{errorsContent.description}</div>
          {errorsContent.button && errorsContent.button}
        </>
      ) : (
        <ErrorWithRefreshPage
          title={t({
            id: 'history.trades.requestErrorTitle',
            comment: 'title',
          })}
          description={t({
            id: 'history.trades.requestErrorDescription',
            comment: 'description',
          })}
          initialTime={requestTimeStep}
          refreshHandler={refreshHandler}
        />
      )}
    </div>
  )
}
