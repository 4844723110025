import { addEvent } from '../utils/addEvent'
import { chainFns } from '../utils/chainFns'
import { getScrollParent } from '../utils/getScrollParent'
import { scrollIntoView } from '../utils/scrollIntoView'
import { setStyle } from '../utils/setStyle'
import { willOpenKeyboard } from '../utils/willOpenKeyboard'

interface EmptyCallback extends Function {
  (): void
}

export function preventScrollMobileSafari(): EmptyCallback {
  let scrollable: Element
  let restoreScrollableStyles: EmptyCallback

  const onTouchStart = (e: TouchEvent) => {
    scrollable = getScrollParent(e.target as Element, true)

    if (scrollable === document.documentElement && scrollable === document.body) {
      return
    }

    if (scrollable instanceof HTMLElement && window.getComputedStyle(scrollable).overscrollBehavior === 'auto') {
      restoreScrollableStyles = setStyle(scrollable, 'overscrollBehavior', 'contain')
    }
  }

  const onTouchMove = (e: TouchEvent) => {
    if (!scrollable || scrollable === document.documentElement || scrollable === document.body) {
      e.preventDefault()
      return
    }

    if (scrollable.scrollHeight === scrollable.clientHeight && scrollable.scrollWidth === scrollable.clientWidth) {
      e.preventDefault()
    }
  }

  const onTouchEnd = (e: TouchEvent) => {
    let target = e.target as HTMLElement

    if (willOpenKeyboard(target) && target !== document.activeElement) {
      e.preventDefault()
      setupStyles()

      target.style.transform = 'translateY(-2000px)'
      target.focus()
      requestAnimationFrame(() => {
        target.style.transform = ''
      })
    }

    if (restoreScrollableStyles) {
      restoreScrollableStyles()
    }
  }

  const onFocus = (e: FocusEvent) => {
    let target = e.target as HTMLElement

    if (willOpenKeyboard(target)) {
      setupStyles()

      target.style.transform = 'translateY(-2000px)'

      requestAnimationFrame(() => {
        target.style.transform = ''

        if (visualViewport) {
          if (visualViewport.height < window.innerHeight) {
            requestAnimationFrame(() => {
              scrollIntoView(target)
            })
          } else {
            visualViewport.addEventListener('resize', () => scrollIntoView(target), { once: true })
          }
        }
      })
    }
  }

  let restoreStyles: EmptyCallback | null = null

  const setupStyles = () => {
    if (restoreStyles) {
      return
    }

    const onWindowScroll = () => {
      window.scrollTo(0, 0)
    }

    const scrollX = window.pageXOffset || window.scrollX || 0
    const scrollY = window.pageYOffset || window.scrollY || 0

    restoreStyles = chainFns(
      addEvent(window, 'scroll', onWindowScroll),
      setStyle(
        document.documentElement,
        'paddingRight',
        `${window.innerWidth - document.documentElement.clientWidth}px`
      ),
      setStyle(document.documentElement, 'overflow', 'hidden'),
      setStyle(document.body, 'marginTop', `-${scrollY}px`),
      () => {
        window.scrollTo(scrollX, scrollY)
      }
    )

    window.scrollTo(0, 0)
  }

  const removeEvents = chainFns(
    addEvent(document, 'touchstart', onTouchStart, {
      passive: false,
      capture: true,
    }),
    addEvent(document, 'touchmove', onTouchMove, {
      passive: false,
      capture: true,
    }),
    addEvent(document, 'touchend', onTouchEnd, {
      passive: false,
      capture: true,
    }),
    addEvent(document, 'focus', onFocus, true)
  )

  return () => {
    restoreScrollableStyles?.()
    restoreStyles?.()
    removeEvents()
  }
}
