import { isFunction } from 'lodash-es'
import { ForwardedRef } from 'react'

/**
 * Merges multiple refs into one. Works with either callback or object refs.
 */
export function mergeRefs<T>(...refs: ForwardedRef<T>[]): ForwardedRef<T> {
  if (refs.length === 1) {
    return refs[0]
  }

  return value => {
    for (let ref of refs) {
      if (isFunction(ref)) {
        ref(value)
      } else if (ref != null) {
        ref.current = value
      }
    }
  }
}
