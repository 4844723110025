import { t } from '@lingui/macro'
import { enqueueSnackbar } from 'notistack'
import { referralsActions } from '@/redux/referrals/referrals.slice'
import { useActions } from '@/utils'

const useApi = () => {
  const { DeleteReferralCodeTC } = useActions(referralsActions)

  const deleteCode = async (code: string) => {
    const response = await DeleteReferralCodeTC(code)

    if ('error' in response) {
      // @ts-ignore
      let errorCode = response?.payload?.codes?.[0]
      let errorMessage = ''

      switch (Number(errorCode)) {
        case 40008:
          errorMessage = t({
            id: 'referralPage.codeDeleteError-40008',
            message: 'Invalid referral code (does not exist or is not custom)',
          })
      }

      enqueueSnackbar({
        message: `${t({ id: 'referralPage.codeDeleteError', message: 'Error removing code' })}. ${errorMessage}`,
        variant: 'error',
      })
    } else {
      enqueueSnackbar({
        message: t({ id: 'referralPage.codeDeleted', message: 'Referral code deleted' }),
        variant: 'success',
      })
    }
  }

  return {
    deleteCode,
  }
}

export { useApi }
