import { t } from '@lingui/macro'
import { ApiKeyAccess } from './index'

export const formatAccessList = (access: ApiKeyAccess[]) =>
  access
    .map(element => {
      return formatAccess(element)
    })
    .join(', ')

export const formatAccess = (access: ApiKeyAccess) => {
  switch (access) {
    case 'read':
      return t({
        id: 'apiPage.access.read',
        message: `Read access`,
      })
    case 'futures':
      return t({
        id: 'apiPage.access.futures',
        message: 'Futures',
      })
    case 'spot':
      return t({
        id: 'apiPage.access.trade',
        message: 'Trade',
      })
  }
}

export const buildAccessArray = (marginEnabled: boolean, futuresEnabled: boolean): ApiKeyAccess[] => {
  const res: ApiKeyAccess[] = ['read']
  marginEnabled && res.push('spot')
  futuresEnabled && res.push('futures')
  return res
}

export const wildcardApiKey = (str: string) => str.substr(0, 10) + '****'
