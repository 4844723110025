import {
  TakeUserInit,
  TradesAPI,
  TradesAvailableExchange,
  TradesAvailableUsers,
  AddTradesTagsParams,
  EditTradeDescriptionParams,
  GetTradesExchangeAvailableType,
} from '@/backend/api/trades.api'
import { TradesQueryParams } from '@/backend/types'
import { type ThunkError } from '@/types'
import {
  AddTagsResponse,
  ControllersCategoriesListResponse,
  ControllersMeSuccessResponse,
  ControllersTagsResponse,
  ControllersTradeChartDrawingsResponse,
  ControllersTickersListResponse,
  type ServicesTradesListPagination,
  DiaryData,
  UIDataResponse,
} from '@/utils/types/contracts.types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'
import { UIDataContent } from './trades.utils'
import { TradesDayOrdersQuery } from './trades.types'
import { TExchangesAvailable } from '@/core/constants'

export const GetTradesTC = createAsyncThunk<ServicesTradesListPagination, TradesQueryParams, ThunkError>(
  nameof(TradesAPI.getTrades),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.getTrades, payload }, thunkAPI)
  }
)

export const GetTradeByIdTC = createAsyncThunk<ServicesTradesListPagination, number, ThunkError>(
  nameof(TradesAPI.GetTradeById),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.GetTradeById, payload }, thunkAPI)
  }
)

export const PostTradesAvailableExchangeTC = createAsyncThunk<TradesAvailableExchange, void, ThunkError>(
  nameof(TradesAPI.tradesAvailableExchange),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.tradesAvailableExchange, payload }, thunkAPI)
  }
)
export const GetTradesAvailableExchangeTC = createAsyncThunk<GetTradesExchangeAvailableType, void, ThunkError>(
  nameof(TradesAPI.getTradesAvailableExchange),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.getTradesAvailableExchange, payload }, thunkAPI)
  }
)
export const RepairApiKeyTC = createAsyncThunk<any, TExchangesAvailable, ThunkError>(
  nameof(TradesAPI.repairApiKey),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.repairApiKey, payload }, thunkAPI)
  }
)
export const TradesAvailableUsersTC = createAsyncThunk<TradesAvailableUsers, void, ThunkError>(
  nameof(TradesAPI.tradesAvailableUsers),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.tradesAvailableUsers, payload }, thunkAPI)
  }
)

export const TradesUserInitTC = createAsyncThunk<TakeUserInit, void, ThunkError>(
  nameof(TradesAPI.tradesUserInit),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.tradesUserInit, payload }, thunkAPI)
  }
)

export const GetCategoriesTC = createAsyncThunk<ControllersCategoriesListResponse, void, ThunkError>(
  nameof(TradesAPI.getCategories),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.getCategories, payload }, thunkAPI)
  }
)

export const GetTagsTC = createAsyncThunk<ControllersTagsResponse, void, ThunkError>(
  nameof(TradesAPI.getTags),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.getTags, payload }, thunkAPI)
  }
)

export const EditTagsTC = createAsyncThunk<AddTagsResponse, AddTradesTagsParams, ThunkError>(
  nameof(TradesAPI.addTag),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.addTag, payload }, thunkAPI)
  }
)

export const DeleteTagTC = createAsyncThunk<ControllersTradeChartDrawingsResponse, { tagId: number }, ThunkError>(
  nameof(TradesAPI.deleteTag),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.deleteTag, payload }, thunkAPI)
  }
)

export const EditDescriptionTC = createAsyncThunk<
  ControllersTradeChartDrawingsResponse,
  EditTradeDescriptionParams,
  ThunkError
>(nameof(TradesAPI.editDescription), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: TradesAPI.editDescription, payload }, thunkAPI)
})

export const GetMeTC = createAsyncThunk<ControllersMeSuccessResponse, void, ThunkError>(
  nameof(TradesAPI.getMe),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.getMe, payload }, thunkAPI)
  }
)

export const GetTickers = createAsyncThunk<ControllersTickersListResponse, void, ThunkError>(
  nameof(TradesAPI.getTickers),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.getTickers, payload }, thunkAPI)
  }
)

export const GetAnalyzerWeek = createAsyncThunk<{ data: DiaryData[] }, any, ThunkError>(
  nameof(TradesAPI.getAnalyzerWeek),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.getAnalyzerWeek, payload }, thunkAPI)
  }
)
export const GetAnalyzerTC = createAsyncThunk<any, any, ThunkError>(
  nameof(TradesAPI.getAnalyzer),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.getAnalyzer, payload }, thunkAPI)
  }
)

export const GetTradesDayOrdersTC = createAsyncThunk<any, TradesDayOrdersQuery, ThunkError>(
  nameof(TradesAPI.getTradesOrders),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.getTradesOrders, payload }, thunkAPI)
  }
)

export const SetUIDataTC = createAsyncThunk<void, UIDataContent, ThunkError>(
  nameof(TradesAPI.putUIData),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.putUIData, payload }, thunkAPI)
  }
)

export const GetUIDataTC = createAsyncThunk<UIDataResponse, void, ThunkError>(
  nameof(TradesAPI.getUIData),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.getUIData, payload }, thunkAPI)
  }
)

export const RepairKeyTC = createAsyncThunk<object, number, ThunkError>(
  nameof(TradesAPI.postRepair),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TradesAPI.postRepair, payload }, thunkAPI)
  }
)
