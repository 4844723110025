import { InitialState } from './kyc.types'
import { KYCLimitPeriod, KYCTier, UserKYC, WorkflowKYC } from '@/backend/models/KycDTO'

export const initialState: InitialState = {
  isVerificationFailedPopupShown: false,
  userKYC: {} as UserKYC,
  kycTierOnLoad: undefined,
  responseFormKYC: {
    beneficialOwnerStatus: true,
    birthDate: '',
    canUpdate: true,
    createdAt: '',
    contactEmail: '',
    firstName: '',
    lastName: '',
    id: '',
    nationalities: [''],
    residentialAddress: '',
    residentialAddressFull: {
      buildingName: '',
      buildingNumber: '',
      city: '',
      country: '',
      flatNumber: '',
      lineFirst: '',
      postalCode: '',
      stateOrProvince: '',
      street: '',
    },
    updatedAt: '',
    userId: '',
    version: 0,
  },
  userKycLinkState: {
    status: undefined,
  },
  userKycLinkRequestStatus: 'idle',
  userKycLinkStatusRequestStatus: 'idle',
  countries: {
    entries: [
      {
        callingCode: '',
        id: '',
        iso3: '',
        name: '',
      },
    ],
    total: 0,
  },
  accessTokenForIdentityVerification: {
    token: '',
  },
  workflow: {} as WorkflowKYC,
  userKycStatus: 'idle',
  updateFormKycStatus: 'idle',
  initWorkflowStatus: 'idle',
  workflowStatus: 'idle',
  workflowStatusCode: undefined,
  responseFormKycStatus: 'idle',
  limits: [],
  limitsStatus: 'idle',
  availableLimits: {
    depositLimit: {
      available: 0,
      availableRounded: 0,
      availableFormatted: '',
      limitRounded: 0,
      limitFormatted: '',
      currency: '',
      side: 'DEPOSIT',
      spent: 0,
      limit: {
        currency: '',
        period: KYCLimitPeriod.Day,
        side: 'DEPOSIT',
        tier: KYCTier.Advanced,
        value: 0,
      },
    },
    tier: KYCTier.Advanced,
    userId: '',
    withdrawalLimit: {
      available: 0,
      availableRounded: 0,
      availableFormatted: '',
      limitRounded: 0,
      limitFormatted: '',
      currency: '',
      side: 'DEPOSIT',
      spent: 0,
      limit: {
        currency: '',
        period: KYCLimitPeriod.Day,
        side: 'DEPOSIT',
        tier: KYCTier.Advanced,
        value: 0,
      },
    },
  },
  availableLimitsStatus: 'idle',
}
