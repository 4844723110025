import { DoublyLinkedList, LinkedListNode } from '@/utils/entities/DoublyLinkedList'
import { KYCTier } from '@/backend/models/KycDTO'

export type KYCLevelPosition = 'next' | 'prev' | 'same' | null

/**
 * todo: add to util
 * Функция  поиска позиции ноды относительно исходной ноды
 * @param node {LinkedListNode} - исходная нода
 * @param searchValue {unknown} - искомое значение ноды
 */
const searchPosition = (node: LinkedListNode, searchValue: unknown): KYCLevelPosition => {
  let searchPosition: KYCLevelPosition = null
  let targetNext = node
  let targetPrev = node

  while (targetNext.next) {
    if (targetNext.next.value === searchValue) {
      searchPosition = 'next'
      break
    }
    targetNext = targetNext.next
  }

  while (targetPrev.prev) {
    if (targetPrev.prev.value === searchValue) {
      searchPosition = 'prev'
      break
    }
    targetPrev = targetPrev.prev
  }

  return searchPosition
}

/**
 * Сервис для работы с уровнями KYC
 */
export class KYCLevelService {
  static levels = new DoublyLinkedList<KYCTier>(Object.values(KYCTier))

  /**
   * Получить последний сохраненный тир из localStorage
   * @param userEmail {string} - email/ключ
   */
  static getLastSavedTier = (userEmail: string) => {
    const lastTier = localStorage.getItem(`lastTier-${userEmail}`)
    return lastTier as KYCTier
  }

  /**
   * Установка последнего тира в localStorage
   * @param userEmail {string} - email/ключ
   * @param tier {KYCTier} - устанвливаемый тир
   */
  static setLastSavedTier = (userEmail: string, tier: KYCTier) => {
    localStorage.setItem(`lastTier-${userEmail}`, tier)
  }

  /**
   * Находит нужный уровень ноды по kycValue
   * @param level {KYCTier} - значение которое нужно найти
   */
  static getLevelByValue = (level: KYCTier) => {
    return KYCLevelService.levels.findNodeByValue(level)
  }

  /**
   * Сравнение позиции тиров
   * @param target {KYCTier} - исходный тир
   * @param compared {KYCTier} - Искомый тир
   * @return {KYCLevelPosition} - Расположение тира относительно исходного
   */
  static compareTier = (target: KYCTier, compared: KYCTier): KYCLevelPosition => {
    const targetNode = KYCLevelService.levels.findNodeByValue(target)
    if (!targetNode) return null
    if (targetNode?.value === compared) {
      return 'same'
    }
    return searchPosition(targetNode, compared)
  }
}
