import { DiaryLeftBar } from '@/ui/organisms/DiaryLeftBar'
import style from './style.module.scss'
import { DiaryRightBar } from '@/ui/organisms/DiaryRightBar'
import { TableError } from '@/ui/organisms/HistoryTrades/components/TableError/TableError'
import { useAppSelector } from '@/utils'

export const DiaryPage = () => {
  const { availableUsersTradingStatus, availableUsersTrading } = useAppSelector(state => state.trades)

  if (availableUsersTradingStatus === 'succeeded' && !availableUsersTrading?.isStatisticAvailable)
    return <TableError technicalError={true} />

  return (
    <div className={style.root}>
      <DiaryLeftBar />
      <DiaryRightBar />
    </div>
  )
}
