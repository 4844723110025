import { IconFactory } from '@/assets/svg/cryptoIcons/IconFactory'
import { Button } from '@/ui/kit'
import { formatNumber } from '@/utils'
import { formatDate } from '@/utils/lib/formatDate'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, MouseEvent, useState } from 'react'
import s from './style.module.scss'

interface Props {
  icon?: string
  symbol: string
  side: string
  date: number
  unrealizedPnl?: {
    unPnlNumber: number
    unPnlPercentNumber: number
    unPnlNumberFormatted: string
    unPnlPercentFromatted: string
  }
  volume: string
  openPrice?: string
  liquidationPrice?: string
  isDeleting?: boolean
  type?: string
  price?: string
  closeHandler: (event: MouseEvent<HTMLButtonElement>) => any
  closeBtnText: string
}

export const MobileDealCard: FC<Props> = ({
  icon,
  symbol,
  side,
  date,
  unrealizedPnl,
  volume,
  openPrice,
  liquidationPrice,
  isDeleting,
  closeHandler,
  closeBtnText,
  price,
  type,
}) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <li className={s.card} onClick={() => setOpen(prev => !prev)}>
      <div className={s.topLine}>
        {!!icon && (
          <div className={s.iconWrapper}>
            <IconFactory type={icon} width="30px" height="30px" />
          </div>
        )}

        <div className={s.topDescription}>
          <div className={s.wrapper}>
            <div className={s.currency}>{symbol}</div>

            <div className={s.side}>{side}</div>
          </div>

          <div className={s.timestamp}>{formatDate(date)}</div>
        </div>
      </div>

      <div>
        <table className={s.table}>
          <tbody>
            <tr>
              {!!unrealizedPnl && (
                <td>
                  <div className={s.cell}>
                    <div>{t({ message: 'PNL', id: 'core.pnl' })}</div>
                    <div
                      className={clsx(s.value, s.title, {
                        [s.green]: unrealizedPnl.unPnlNumber > 0,
                        [s.red]: unrealizedPnl.unPnlNumber < 0,
                      })}
                    >
                      {unrealizedPnl.unPnlNumberFormatted} ({unrealizedPnl.unPnlPercentFromatted})
                    </div>
                  </div>
                </td>
              )}

              {!!type && (
                <td>
                  <div className={s.cell}>
                    <div>{t({ message: 'Type', id: 'orders.table.type' })}</div>
                    <div className={clsx(s.value, s.white)}>{type}</div>
                  </div>
                </td>
              )}

              <td>
                <div className={s.cell}>
                  <div className={s.title}>{t({ message: 'Size', id: 'core.size' })}</div>
                  <div className={clsx(s.value, s.white)}>{!!type ? volume : formatNumber(volume)}</div>
                </div>
              </td>
            </tr>

            {isOpen && (
              <tr>
                {!!openPrice && (
                  <td>
                    <div className={s.cell}>
                      <div className={s.title}>{t({ message: 'Open price', id: 'core.openPrice' })}</div>
                      <div className={clsx(s.value, s.white)}>{openPrice}</div>
                    </div>
                  </td>
                )}

                {!!price && (
                  <td>
                    <div className={s.cell}>
                      <div className={s.title}>{t({ message: 'Price', id: 'core.price' })}</div>
                      <div className={clsx(s.value, s.white)}>{price}</div>
                    </div>
                  </td>
                )}

                {!!liquidationPrice && (
                  <td>
                    <div className={s.cell}>
                      <div className={s.title}>{t({ message: 'Liquidation price', id: 'core.liquidationPrice' })}</div>
                      <div className={clsx(s.value, s.white)}>{liquidationPrice}</div>
                    </div>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isOpen && <Button.White loading={isDeleting} label={closeBtnText} onClick={closeHandler} />}

      <div className={clsx(s.expand, { [s.active]: isOpen })}>
        <div className={s.circle} />
        <div className={s.circle} />
        <div className={s.circle} />
      </div>
    </li>
  )
}
