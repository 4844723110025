import React, { FC } from 'react'
import clsx from 'clsx'
import { SVG } from '@/assets/svg'
import s from './style.module.scss'

interface ILoaderProps {
  size?: number
  className?: string
  dataTestId?: string
}

const Loader: FC<ILoaderProps> = props => {
  const { size = 29, className } = props

  return (
    <div className={clsx(s.root, className)} data-testid={props.dataTestId}>
      <SVG.Loaders.Default width={size} height={size} />
    </div>
  )
}

export { Loader }
