import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { AddKeyType, GoogleCodeType, HistoryPayload, OKXAffiliateAPI } from '@/backend/api/okxAffiliateAPI'
import { historyOperationsType, userStatus } from './okxAffiliate.types'

export const GetUserStatus = createAsyncThunk<userStatus, void, ThunkError>(
  nameof(OKXAffiliateAPI.getUserStatus),
  async (_, thunkAPI) => {
    return await ThunkCreator({ apiMethod: OKXAffiliateAPI.getUserStatus }, thunkAPI)
  }
)

export const GetHistoryOperations = createAsyncThunk<historyOperationsType, HistoryPayload, ThunkError>(
  nameof(OKXAffiliateAPI.getOKXHistoryCashback),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: OKXAffiliateAPI.getOKXHistoryCashback, payload }, thunkAPI)
  }
)

export const PostAddKey = createAsyncThunk<void, AddKeyType, ThunkError>(
  nameof(OKXAffiliateAPI.postAddKey),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: OKXAffiliateAPI.postAddKey, payload }, thunkAPI)
  }
)

export const DeleteKey = createAsyncThunk<void, GoogleCodeType, ThunkError>(
  nameof(OKXAffiliateAPI.deleteKey),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: OKXAffiliateAPI.deleteKey, payload }, thunkAPI)
  }
)

export const PostWithdrawal = createAsyncThunk<void, GoogleCodeType, ThunkError>(
  nameof(OKXAffiliateAPI.postWithdrawal),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: OKXAffiliateAPI.postWithdrawal, payload }, thunkAPI)
  }
)
