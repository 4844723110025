import { FC, useState } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { Button, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { getCompoundTransactionAmount, useAppSelector } from '@/utils'
import { FormWithdraw } from './FormWithdraw'
import { OKX_AFFILIATE_CURRENCY } from '@/pages/OKXAfiliate/consts'

export const Withdraw: FC = () => {
  const { userStatus } = useAppSelector(state => state.okxAffiliate)
  const [isWithdrawPopup, setIsWithdrawPopup] = useState(false)

  function openWithdrawPopup() {
    setIsWithdrawPopup(true)
  }

  function closeWithdrawPopup() {
    setIsWithdrawPopup(false)
  }

  const isDisableButton = userStatus?.cashbackBalance && userStatus?.cashbackBalance >= 10
  const userBalance = userStatus?.cashbackBalance ? userStatus?.cashbackBalance : 0

  return (
    <>
      <div className={style.withdraw}>
        <div className={style.wrapper}>
          <div>
            {t({
              id: 'okxAffiliate.withdrawal.title',
              comment: 'Available for output',
            })}
          </div>
          <div className={style.asset}>
            <span className={style.amount}>{userBalance}</span>
            <span> {OKX_AFFILIATE_CURRENCY}</span>
          </div>
        </div>
        <Button.Primary
          onClick={openWithdrawPopup}
          label={t({ id: 'okxAffiliate.withdrawal.button', comment: 'Withdrawal' })}
          disabled={!isDisableButton}
        />
      </div>
      <InteractiveModal isOpen={isWithdrawPopup}>
        <InteractiveModalParts.Icon icon="withdrawal" />
        <InteractiveModalParts.Header text={t({ id: 'core.withdrawal', comment: 'Withdrawal' })} />
        <InteractiveModalParts.MainInfo text={getCompoundTransactionAmount(`${userBalance}`, OKX_AFFILIATE_CURRENCY)} />
        <FormWithdraw onClose={closeWithdrawPopup} />
      </InteractiveModal>
    </>
  )
}
