import { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as amplitude from '@amplitude/analytics-browser'
// import { useLocation } from 'react-router-dom'
// import { IntercomProvider } from 'react-use-intercom'
import { AuthProvider } from '@/providers/AuthProvider'
import { SnackBarProvider } from '@/providers/SnackBarProvider'
import { LanguageProvider } from '@/providers/LanguageProvider'
import { ReduxStoreProvider } from '@/providers/ReduxStoreProvider'
import { useFirstRender } from './utils/hooks'
import { tagManager } from './utils/tagManager'
import AppRouter from './router'
import { storage } from '@/utils/lib/storage'
import { KEITARO_SEARCH_PARAM, REFERRAL_SEARCH_PARAM } from '@/router/urls'
import { ScrollToErrorProvider } from './providers/ScrollToErrorProvider'
import MigrateProvider from './providers/MigrateProvider'

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_KEY

export const App: FC = () => {
  // analytics()
  const { firstRender } = useFirstRender()
  const [searchParams] = useSearchParams()
  const referralCode = searchParams.get(REFERRAL_SEARCH_PARAM)
  const keitaroCode = searchParams.get(KEITARO_SEARCH_PARAM)

  useEffect(() => {
    tagManager()
  }, [])

  useEffect(() => {
    if (referralCode) {
      storage.setReferralCode(referralCode)
    }

    if (keitaroCode) {
      storage.setKeitaroCode(keitaroCode)
    }
  }, [referralCode, keitaroCode])

  useEffect(() => {
    amplitude.init(AMPLITUDE_API_KEY)
  }, [])

  return (
    <ReduxStoreProvider>
      <LanguageProvider>
        <AuthProvider>
          <ScrollToErrorProvider>
            {/*Интерком интегрирован, но скрыт, нужен актуальный appId и инфа залогиненного пользователя*/}
            {/*<IntercomProvider key={JSON.stringify(pathname)} appId={'v3g3691o'}>*/}
            <SnackBarProvider>
              <MigrateProvider>
                <AppRouter isFirstRender={firstRender} />
              </MigrateProvider>
            </SnackBarProvider>
            {/*</IntercomProvider>*/}
          </ScrollToErrorProvider>
        </AuthProvider>
      </LanguageProvider>
    </ReduxStoreProvider>
  )
}
