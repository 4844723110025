import { FC } from 'react'

interface FullScreenProps {
  className?: string
  size?: number
}

export const FullScreen: FC<FullScreenProps> = ({ className, size = 14 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.57284 6.06394C1.90734 6.06394 2.14718 5.81149 2.14718 5.47698V4.94682L2.02095 2.74414L3.68085 4.4924L5.62476 6.44893C5.73205 6.56254 5.87091 6.61303 6.02238 6.61303C6.38213 6.61303 6.64721 6.37951 6.64721 6.02607C6.64721 5.85566 6.59041 5.70419 6.47681 5.59058L4.52658 3.64667L2.77832 1.98677L4.98731 2.113H5.51116C5.84567 2.113 6.10443 1.87947 6.10443 1.53866C6.10443 1.19784 5.85197 0.958008 5.51116 0.958008H2.00833C1.36456 0.958008 0.992188 1.33038 0.992188 1.97415V5.47698C0.992188 5.80517 1.23833 6.06394 1.57284 6.06394ZM8.49644 13.0002H11.9993C12.643 13.0002 13.0217 12.6278 13.0217 11.984V8.48119C13.0217 8.15301 12.7756 7.89423 12.4348 7.89423C12.1066 7.89423 11.8604 8.14668 11.8604 8.48119V9.01134L11.993 11.214L10.3268 9.46581L8.38915 7.50924C8.28187 7.39565 8.13671 7.34515 7.98522 7.34515C7.63177 7.34515 7.3604 7.57865 7.3604 7.93211C7.3604 8.10253 7.42351 8.25402 7.53715 8.36758L9.48106 10.3115L11.2356 11.9714L9.02659 11.8452H8.49644C8.16198 11.8452 7.90321 12.0787 7.90321 12.4195C7.90321 12.7603 8.16198 13.0002 8.49644 13.0002Z"
        fill="currentColor"
      />
    </svg>
  )
}
