import { FC } from 'react'

export const LongArrowRight: FC = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5914 7.9991L1.06262 7.9991C0.510337 7.9991 0.0626224 7.55139 0.0626224 6.9991C0.0626224 6.44682 0.510337 5.9991 1.06262 5.9991L12.5896 5.9991L8.63347 1.88028C8.25089 1.48197 8.26364 0.848933 8.66195 0.466352C9.06026 0.0837713 9.6933 0.0965219 10.0759 0.494831L15.6588 6.3073C16.0305 6.69432 16.0305 7.30574 15.6588 7.69275L10.0759 13.5052C9.6933 13.9035 9.06026 13.9163 8.66195 13.5337C8.26364 13.1511 8.25089 12.5181 8.63347 12.1198L12.5914 7.9991Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
