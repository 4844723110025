import { t } from '@lingui/macro'
import style from './style.module.scss'
import { Button, ButtonVariant, InteractiveModalParts } from '@/ui/kit'
import { SVG } from '@/assets/svg'
import { FC, useEffect, useState } from 'react'
import { urls } from '@/router/urls'
import { useNavigate } from 'react-router-dom'
import { useActions, useAppSelector } from '@/utils'
import { kycActions } from '@/redux/kyc/kyc.slice'
import clsx from 'clsx'
import { Loader } from '@/ui/kit/Loader'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'
import { Trans } from '@lingui/react'
import { MessageWithIcon } from '@/ui/molecules/MessageWithIcon'

enum E_BINANCE_LINK_VIEWS {
  'INIT' = 'INIT',
  'FINAL_REJECT' = 'FINAL_REJECT',
  'REJECT' = 'REJECT',
}

type BinanceLinkModalProps = {
  onClose: () => void
}

export const BinanceLinkModal: FC<BinanceLinkModalProps> = ({ onClose }) => {
  const { GetUserKycLinkStatusTC, setUserKycLinkStatusRequestStatus } = useActions(kycActions)
  const { userKycLinkState, userKycLinkStatusRequestStatus } = useAppSelector(state => state.kyc)
  const navigate = useNavigate()

  const [modalView, setModalView] = useState<E_BINANCE_LINK_VIEWS>(E_BINANCE_LINK_VIEWS.INIT)

  useEffect(() => {
    if (userKycLinkState.status === 'INIT') {
      setModalView(E_BINANCE_LINK_VIEWS.INIT)
    }
    if (userKycLinkState.status === 'FINAL_REJECT') {
      setModalView(E_BINANCE_LINK_VIEWS.FINAL_REJECT)
    }

    if (userKycLinkState.status === 'REJECT') {
      setModalView(E_BINANCE_LINK_VIEWS.REJECT)
    }
  }, [userKycLinkState.status])

  const navigateChooseExchangePage = () => {
    navigate(`${urls.chooseExchange}?binance=false`)
    onClose()
  }

  const handleKycPage = () => {
    onClose()
    navigate(urls.kyc)
  }

  useEffect(() => {
    GetUserKycLinkStatusTC()
    return () => {
      setUserKycLinkStatusRequestStatus()
    }
  }, [GetUserKycLinkStatusTC, setUserKycLinkStatusRequestStatus])

  if (!isStatusFinal(userKycLinkStatusRequestStatus)) return <Loader />

  return (
    <div className={style.root}>
      <div className={style.binanceHeading}>
        {modalView === E_BINANCE_LINK_VIEWS.INIT && (
          <div>{t({ id: 'binanceLink.reachedLimitHeading', message: 'Торговля на Binance будет недоступна' })}</div>
        )}

        {modalView === E_BINANCE_LINK_VIEWS.FINAL_REJECT && (
          <div>{t({ id: 'binanceLink.headingReject', message: 'Торговля на Binance недоступна' })}</div>
        )}

        {modalView === E_BINANCE_LINK_VIEWS.REJECT && (
          <div>{t({ id: 'binanceLink.heading', message: 'Верификация Binance' })}</div>
        )}

        <div>
          <Button.Primary
            className={style.closeButton}
            leftIcon={<SVG.Additional.Close width="30px" height="10px" />}
            onClick={onClose}
          />
        </div>
      </div>

      {modalView === E_BINANCE_LINK_VIEWS.INIT && (
        <>
          <div className={style.message}>
            <MessageWithIcon
              icon={<SVG.OtherIcons.Mark />}
              message={
                <Trans
                  id="binanceLink.regainAccessToTrading"
                  message="Чтобы вернуть доступ к торговле на бирже со сниженными комиссиями, пройдите верификацию Binance. Выполните всего лишь одно действие – подготовьте ваш ID для фотографии и селфи. Проверка займет всего несколько минут."
                  components={{
                    colorWhite: <span className={style.whiteText} />,
                  }}
                />
              }
            />
          </div>
          <div className={style.title}>
            {t({
              id: 'binanceLink.whyNneedVerification',
              message: 'Зачем нужна верификация?',
            })}
          </div>
          <div className={style.text}>
            <Trans
              id="binanceLink.accordingToTheNewBinanceRequirements"
              message="Согласно новым требованиям Binance к официальным брокерам, ваш аккаунт должен пройти дополнительную верификацию на соответствие стандартам Binance."
              components={{
                colorGradient: <span className={style.color} />,
              }}
            />
          </div>
        </>
      )}

      {modalView === E_BINANCE_LINK_VIEWS.REJECT && (
        <>
          <div className={style.message}>
            <MessageWithIcon
              icon={<SVG.OtherIcons.HourglassAccent />}
              message={
                <Trans
                  id="binanceLink.binanceIsStillCheckingYourAccount"
                  message="Binance еще проверяет ваш аккаунт. Ожидайте результатов."
                  components={{
                    br: <br />,
                  }}
                />
              }
            />
          </div>
          <div className={style.title}>
            {t({
              id: 'binanceLink.whyNneedVerification',
              message: 'Зачем нужна верификация?',
            })}
          </div>
          <div className={style.text}>
            <Trans
              id="binanceLink.accordingToTheNewBinanceRequirements"
              message="Согласно новым требованиям Binance к официальным брокерам, ваш аккаунт должен пройти дополнительную верификацию на соответствие стандартам Binance."
              components={{
                colorGradient: <span className={style.color} />,
              }}
            />
          </div>
          <div className={clsx(style.warning, style.mt30)}>
            <SVG.Status.Pending className={style.pendingIcon} />
            <div className={style.text}>
              {t({
                id: 'binanceLink.duringTheBinanceVerificationProcess',
                message: 'Во время прохождения верификации Binance торговл на бирже недостпуна для вашего аккаунта.',
              })}
            </div>
          </div>
        </>
      )}

      {modalView === E_BINANCE_LINK_VIEWS.FINAL_REJECT && (
        <>
          <div className={style.message}>
            <MessageWithIcon
              icon={<SVG.OtherIcons.Mark />}
              message={
                <Trans
                  id="binanceLink.tradingOnBinanceIsNotAvailableForYourAccount"
                  message="Торговля на Binance недоступна для вашего аккаунта."
                />
              }
            />
          </div>
          <div className={style.title}>
            {t({
              id: 'binanceLink.whatItMeanTitle',
              message: 'What does it mean?',
            })}
          </div>
          <div className={style.text}>
            {t({
              id: 'binanceLink.newRequirementsForAllAccounts',
              message:
                'Согласно новым требованиям Binance к официальным брокерам, все аккаунты должны пройти дополнительную проверку на соответствие стандартам Binance.',
            })}
          </div>

          <div className={style.warningBlock}>
            <div className={style.warning}>
              <SVG.Status.Failed className={style.warningIcon} />
              <div className={style.warningText}>
                {t({
                  id: 'binanceLink.accountNotBeenVerified',
                  message: 'К сожалению, ваш аккаунт не прошел проверку',
                })}
              </div>
            </div>
            <div className={clsx(style.text, style.mt5)}>
              {t({
                id: 'binanceLink.notBeAbleToTrade',
                message: 'Вам будет недоступна торговля на бирже, но вы можете вывести средства.',
              })}
            </div>
          </div>
        </>
      )}

      {modalView === E_BINANCE_LINK_VIEWS.INIT && (
        <InteractiveModalParts.Button
          className={style.startVerifyButton}
          text={t({ id: 'binanceLink.startVerification', comment: 'Начать верификацию' })}
          variant={ButtonVariant.Accent}
          onClick={handleKycPage}
        />
      )}

      <div className={style.chooseExchangeBlock}>
        <div>
          <SVG.OtherIcons.Lightning />
          <div className={style.textWhite}>
            <Trans
              id="binanceLink.okxBybitTrading"
              message="Торговля на OKX и Bybit остается доступна в полном объеме!"
            />
          </div>
        </div>

        {modalView !== E_BINANCE_LINK_VIEWS.INIT && (
          <Button
            className={style.chooseExchangeButton}
            variant={ButtonVariant.AccentDark}
            onClick={navigateChooseExchangePage}
          >
            {t({ id: 'binanceLink.chooseExchange', message: 'Выбрать ByBit или OKX' })}
          </Button>
        )}
      </div>
    </div>
  )
}
