import { FC } from 'react'

type SuccessDotProps = {
  className?: string
}
export const SuccessDot: FC<SuccessDotProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={8} height={9} fill="none" className={className}>
    <circle cx={4} cy={4.5} r={4} fill="#1ACC81" />
  </svg>
)
