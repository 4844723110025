import clsx from 'clsx'
import { FC, memo, ReactNode } from 'react'
import { QuestionButton } from '../../atoms/QuestionButton/QuestionButton'
import style from './style.module.scss'

type LabelWithQuestionProps = {
  label?: string
  className?: string
  hintText?: ReactNode
  isLighter?: boolean
  dataTestId?: string
}

const LabelWithQuestion: FC<LabelWithQuestionProps> = ({ label, isLighter, className, hintText, dataTestId }) => {
  return (
    <div className={clsx(style.labelWithQuestion, className)}>
      <span className={clsx(style.label, { [style.labelLighter]: isLighter })} data-testid={dataTestId}>
        {label}
      </span>
      {hintText && <QuestionButton text={hintText} hintClassName={style.hint} dataTestId={dataTestId} />}
    </div>
  )
}

export default memo(LabelWithQuestion)
