import { FC, useEffect } from 'react'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { TwoColumnPage } from '@/layouts/TwoColumnPage'
import { ReferralLeftBar } from '@/ui/organisms/ReferralLeftBar'
import { ReferralRightBar } from '@/ui/organisms/ReferralRightBar'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { referralsActions } from '@/redux/referrals/referrals.slice'
import { useActions, useAppSelector } from '@/utils'
import s from './style.module.scss'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const ReferralPage: FC = () => {
  const { GetReferralOverviewTC, GetReferralBenefitsTC } = useActions(referralsActions)
  const { exchangeType } = useExchageType()
  const { status } = useAppSelector(state => state.referrals)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (exchangeType === undefined) return
    GetReferralOverviewTC()
    GetReferralBenefitsTC(exchangeType)
  }, [GetReferralOverviewTC, GetReferralBenefitsTC, exchangeType])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const exchangeTypeParam = params.get('exchangeType')

    if (exchangeType === EXCHANGES_TYPE.OKX_BROKER || exchangeTypeParam === EXCHANGES_TYPE.OKX_BROKER) {
      navigate('/orders')
    }
  }, [])

  if (status === 'loading') {
    return (
      <div className={s.loader}>
        <SVG.Loaders.Default width={29} height={29} />
      </div>
    )
  }

  if (status === 'failed') {
    return (
      <ErrorBase
        customHeader={t({
          id: 'referralPage.error.unload',
          message: `Couldn't load referral info`,
        })}
        className={s.error}
        isRefreshButtonVisible
      />
    )
  }

  return (
    <TwoColumnPage className={s.root}>
      <ReferralLeftBar />
      <ReferralRightBar />
    </TwoColumnPage>
  )
}

export { ReferralPage }
