import { useOverview } from '@/utils/hooks/useOverview'
import { FC, useCallback, useState } from 'react'
import { MobilePositions } from '../MobilePositions/MobilePositions'
import clsx from 'clsx'
import { t } from '@lingui/macro'
import { Button, ButtonSize } from '@/ui/kit'
import s from './style.module.scss'
import { MobileOrders } from '../MobileOrders/MobileOrders'
import { useUnmountedRef } from '@/utils/hooks/useUnmountedRef'

enum DealsType {
  Positions = 'positions',
  Orders = 'orders',
}

export const MobileDeals: FC = () => {
  const [dealsType, setDealsType] = useState(DealsType.Positions)
  const isUnmountedRef = useUnmountedRef()

  const { allOpenOrders, allPositions, isClosingOrders, isClosingPositions, cancelAllOrdersAndPositions } =
    useOverview()

  const scrollTopAfterSuccess = useCallback(
    async (request: () => Promise<any>) => {
      try {
        await request()
      } catch (error) {
        console.warn(error)
        return
      }

      if (!isUnmountedRef.current) {
        document.body.scrollTo(0, 0)
      }
    },
    [isUnmountedRef]
  )

  const changeDealsTypeHandler = (dealType: DealsType) => {
    scrollTopAfterSuccess(async () => setDealsType(dealType))
  }

  const countOrders = allOpenOrders?.length ?? 0
  const countPositions = allPositions?.length ?? 0

  return (
    <>
      <section className={s.stickySection}>
        <Button.White
          size={ButtonSize.Medium}
          label={t({ message: 'Close all', id: 'core.button.closeAll' })}
          disabled={!countPositions && !countOrders}
          loading={isClosingOrders || isClosingPositions}
          onClick={() => scrollTopAfterSuccess(cancelAllOrdersAndPositions)}
        />

        <div className={s.mobileTabs}>
          <div
            className={clsx(s.tab, { [s.active]: dealsType === DealsType.Positions })}
            data-amount={countPositions}
            onClick={() => changeDealsTypeHandler(DealsType.Positions)}
          >
            {t({ message: 'Positions', id: 'core.positions' })}
          </div>

          <div
            className={clsx(s.tab, { [s.active]: dealsType === DealsType.Orders })}
            data-amount={countOrders}
            onClick={() => changeDealsTypeHandler(DealsType.Orders)}
          >
            {t({ message: 'Orders', id: 'core.orders' })}
          </div>
        </div>
      </section>

      {dealsType === DealsType.Positions && <MobilePositions positions={allPositions} />}
      {dealsType === DealsType.Orders && <MobileOrders orders={allOpenOrders} />}
    </>
  )
}
