import clsx from 'clsx'
import { FC, MouseEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import style from './style.module.scss'

type TLink = {
  id: string
  title: string
}

interface ILinksProps {
  links: TLink[]
  active: string
}

const Links: FC<ILinksProps> = props => {
  const { links, active } = props

  const navigate = useNavigate()
  const location = useLocation()

  const handleNavigate = (id: string) => (event: MouseEvent) => {
    event.preventDefault()
    navigate(`${location.pathname}#${id}`, { replace: true })
  }

  return (
    <div className={style.links}>
      {links.map(({ id, title }) => (
        <a
          key={id}
          href={`#${id}`}
          className={clsx(style.link, { [style.active]: active === id })}
          onClick={handleNavigate(id)}
        >
          {title}
        </a>
      ))}
    </div>
  )
}

export { Links }
