import { useMemo, useRef } from 'react'
import { ILevelItemProps } from '@/pages/KYCLevels/components/Level.item'
import { useAppSelector } from '@/utils'
import { t } from '@lingui/macro'
import { useKYC } from '@/utils/hooks'
import { LevelUpgradeButton } from '@/ui/molecules/LevelUpgradeButton'
import { KYCTier } from '@/backend/models/KycDTO'
import { KYCLevelService } from '@/services/KYCLevelService/KYCLevelService'

export const useKYCLevelsVM = (handleKycUpgradeCustom?: (...args: any[]) => any) => {
  const { userKYC } = useAppSelector(state => state.kyc)
  const { isShowKycVerified, handleKycUpgrade: handleKycUpgradeDefault, getKYCLimitsByTier } = useKYC()

  const handleKycUpgrade = useRef(handleKycUpgradeCustom || handleKycUpgradeDefault)

  const levels: ILevelItemProps[] = useMemo(() => {
    return [
      {
        tierId: KYCTier.Unverified,
        isActive: !isShowKycVerified,
        tierName: t({ message: 'Unverifed', id: 'kycLevels.unverified.title' }),
        showStatus: false,
        button:
          userKYC.kycTier === KYCTier.Unverified ? (
            <LevelUpgradeButton handleUpgrade={handleKycUpgrade.current} />
          ) : undefined,
        description: {
          funding: t({ message: 'Unavailable', id: 'kycLevels.unverified.unvaliable' }),
          withdrawals: t({ message: 'Unavailable', id: 'kycLevels.unverified.unvaliable' }),
        },
      },
      {
        tierId: KYCTier.Standard,
        isActive: userKYC.kycTier === 'STANDARD',
        isNextTier: userKYC.nextTier === 'STANDARD',
        tierName: t({ message: 'Standard', id: 'kycLevels.standard.title' }),
        button:
          userKYC.kycTier === KYCTier.Basic ? (
            <LevelUpgradeButton handleUpgrade={handleKycUpgrade.current} />
          ) : undefined,
        description: {
          reqDocs: t({
            message: 'Personally identifiable info, proof of identity, selfie',
            id: 'kycLevels.standard.reqDocs',
          }),
          funding: `${t({ id: 'kycLevels.upTo', comment: 'up to' })} ${
            getKYCLimitsByTier('STANDARD' as KYCTier).deposit.formatted
          }`,
          withdrawals: `${t({ id: 'kycLevels.upTo', comment: 'up to' })} ${
            getKYCLimitsByTier('STANDARD' as KYCTier).withdraw.formatted
          }`,
        },
      },
      {
        tierId: KYCTier.StandardPlus,
        isActive: userKYC.kycTier === 'STANDARD_PLUS',
        tierName: t({ message: 'Standard+', id: 'kycLevels.standardPlus.title' }),
        button:
          userKYC.kycTier === KYCTier.Standard ? (
            <LevelUpgradeButton handleUpgrade={handleKycUpgrade.current} />
          ) : undefined,
        isNextTier: userKYC.nextTier === 'STANDARD_PLUS',
        description: {
          reqDocs: t({ message: 'Proof of address', id: 'kycLevels.standardPlus.reqDocs' }),
          funding: `${t({ id: 'kycLevels.upTo', comment: 'up to' })} ${
            getKYCLimitsByTier('STANDARD_PLUS' as KYCTier).deposit.formatted
          }`,
          withdrawals: `${t({ id: 'kycLevels.upTo', comment: 'up to' })} ${
            getKYCLimitsByTier('STANDARD_PLUS' as KYCTier).withdraw.formatted
          }`,
          upgradeText:
            KYCLevelService.compareTier(userKYC.kycTier, KYCTier.Standard) === 'next'
              ? t({ message: 'Upgrade to Standard first', id: 'kycLevels.standardPlus.upgradeText' })
              : undefined,
        },
      },
      {
        tierId: KYCTier.Advanced,
        isActive: userKYC.kycTier === 'ADVANCED',
        isNextTier: userKYC.nextTier === 'ADVANCED',
        tierName: t({ message: 'Advanced', id: 'kycLevels.advanced.title' }),
        button:
          userKYC.kycTier === KYCTier.StandardPlus ? (
            <LevelUpgradeButton handleUpgrade={handleKycUpgrade.current} />
          ) : undefined,
        description: {
          reqDocs: t({ message: 'Source of funds details', id: 'kycLevels.advanced.reqDocs' }),
          funding: t({ message: 'Unlimited', id: 'kycLevels.advanced.funding' }),
          withdrawals: t({ message: 'Unlimited', id: 'kycLevels.advanced.withdrawals' }),
          upgradeText:
            KYCLevelService.compareTier(userKYC.kycTier, KYCTier.StandardPlus) === 'next'
              ? t({ message: 'Upgrade to Standard+ first', id: 'kycLevels.advanced.upgradeText' })
              : undefined,
        },
      },
    ]
  }, [userKYC.kycTier, userKYC.nextTier, isShowKycVerified, handleKycUpgrade])

  return { levels }
}
