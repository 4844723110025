import React from 'react'

interface IProps {
  className?: string
}

export const Offer = (props: IProps) => {
  const { className } = props

  return (
    <svg
      width="28"
      height="23"
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M27.02 9.86917C27.566 9.86917 28 9.43517 28 8.88917V7.58717C28 1.84717 26.25 0.097168 20.51 0.097168H7.49C1.75 0.097168 0 1.84717 0 7.58717V8.24517C0 8.79117 0.434 9.22517 0.98 9.22517C2.24 9.22517 3.262 10.2472 3.262 11.5072C3.262 12.7672 2.24 13.7752 0.98 13.7752C0.434 13.7752 0 14.2092 0 14.7552V15.4132C0 21.1532 1.75 22.9032 7.49 22.9032H20.51C26.25 22.9032 28 21.1532 28 15.4132C28 14.8672 27.566 14.4332 27.02 14.4332C25.76 14.4332 24.738 13.4112 24.738 12.1512C24.738 10.8912 25.76 9.86917 27.02 9.86917ZM9.8 7.12517C10.57 7.12517 11.2 7.75517 11.2 8.52517C11.2 9.29517 10.584 9.92517 9.8 9.92517C9.03 9.92517 8.4 9.29517 8.4 8.52517C8.4 7.75517 9.016 7.12517 9.8 7.12517ZM18.2 16.9252C17.416 16.9252 16.786 16.2952 16.786 15.5252C16.786 14.7552 17.416 14.1252 18.186 14.1252C18.956 14.1252 19.586 14.7552 19.586 15.5252C19.586 16.2952 18.984 16.9252 18.2 16.9252ZM19.46 7.96517L10.038 17.3872C9.828 17.5972 9.562 17.6952 9.296 17.6952C9.03 17.6952 8.764 17.5972 8.554 17.3872C8.148 16.9812 8.148 16.3092 8.554 15.9032L17.976 6.48117C18.382 6.07517 19.054 6.07517 19.46 6.48117C19.866 6.88717 19.866 7.55917 19.46 7.96517Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
