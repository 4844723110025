import { FC, useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'
import { Toggle } from '@/ui/kit'
import { useActions, useAppSelector } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'
import style from '../../style.module.scss'

interface Props {
  disabled?: boolean
}

const BNBBurn: FC<Props> = ({ disabled }) => {
  const { GetBNBBurnStatusTC, UpdateBNBBurnStatusTC } = useActions(profileActions)
  const { spotBNBBurn } = useAppSelector(state => state.profile)

  const [active, setActive] = useState(spotBNBBurn)

  useEffect(() => {
    GetBNBBurnStatusTC()
  }, [GetBNBBurnStatusTC])

  useEffect(() => {
    if (typeof active === 'undefined' && typeof spotBNBBurn !== 'undefined') {
      setActive(spotBNBBurn)
    }
  }, [active, spotBNBBurn])

  const handleBNBBurnChange = async (active: boolean) => {
    setActive(active)

    const response = await UpdateBNBBurnStatusTC({ spotBNBBurn: active })

    if ('error' in response) {
      setActive(!active)

      enqueueSnackbar(t({ id: 'profile.bnbBurnError', message: 'Error saving BNB Burn setting' }), { variant: 'error' })
    } else {
      enqueueSnackbar(t({ id: 'profile.bnbBurnSuccess', message: 'BNB Burn setting successfully saved' }), {
        variant: 'success',
      })
    }
  }

  return (
    <div className={style.setting}>
      {disabled && <div className={style.disabled} />}
      <SVG.Settings.Burn className={style.sectionIcon} />
      <div>
        <div className={style.settingTitle}>{t({ id: 'profile.bnbBurn', message: `BNB Burn` })}</div>
        <div className={style.settingStatus}>
          {t({ id: 'profile.bnbDescription', message: 'Enable BNB commission offer to save money' })}
        </div>
      </div>
      <div className={style.settingActions}>
        {typeof active !== 'undefined' && <Toggle active={active} onChange={handleBNBBurnChange} />}
      </div>
    </div>
  )
}

export { BNBBurn }
