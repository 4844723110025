import { createSlice } from '@reduxjs/toolkit'
import initialState from './okxAffiliate.state'
import { okxCashbackAsyncActions } from './okxAffiliate.actions'

export const slice = createSlice({
  name: `[Tiger Trade OKX Affiliate]`,
  initialState,
  reducers: {
    clearErrors(state) {
      state.errors = []
    },
    clearResponseDetails(state) {
      state.responseDetails = {
        isCompleted: false,
        isSuccess: false,
      }
    },
    openOfferPopup(state) {
      state.isOfferPopup = true
    },
    closeOfferPopup(state) {
      state.isOfferPopup = false
    },
    openAddKeyPopup(state) {
      state.isOpenAddKeyPopup = true
    },
    closeAddKeyPopup(state) {
      state.isOpenAddKeyPopup = false
    },
  },
  extraReducers: builder => {
    // GetUserStatus
    builder.addCase(okxCashbackAsyncActions.GetUserStatus.fulfilled, (state, action) => {
      state.userStatus = action?.payload

      if (!action?.payload.isAffiliate) {
        state.isOfferPopup = true
      }
    })

    // PostAddKey
    builder.addCase(okxCashbackAsyncActions.PostAddKey.fulfilled, (state, action) => {
      state.responseDetails = {
        isCompleted: true,
        isSuccess: true,
      }
    })

    builder.addCase(okxCashbackAsyncActions.PostAddKey.rejected, (state, action) => {
      const errorsForm: string[] = ['50111', '40009', '50113', '50105']

      if (action.payload?.fieldsErrors) {
        state.errors = action.payload.fieldsErrors

        const isFormError = action.payload.fieldsErrors.find(error => errorsForm.includes(error.code as string))

        if (isFormError) {
          return
        }
      }

      state.responseDetails = {
        isCompleted: true,
        isSuccess: false,
        errorCode: action.payload?.fieldsErrors ? Number(action.payload.fieldsErrors[0].code) : null,
      }
    })

    // DeleteKey
    builder.addCase(okxCashbackAsyncActions.DeleteKey.fulfilled, (state, action) => {
      state.responseDetails = {
        isCompleted: true,
        isSuccess: true,
      }
    })
    builder.addCase(okxCashbackAsyncActions.DeleteKey.rejected, (state, action) => {
      state.errors = action.payload?.fieldsErrors || []

      if (action.payload?.fieldsErrors) {
        const googleCodeError = action.payload?.fieldsErrors.find(error => error.field === 'googleCode')

        if (googleCodeError) {
          return
        }
      }

      state.responseDetails = {
        isCompleted: true,
        isSuccess: false,
        errorCode: action.payload?.fieldsErrors ? Number(action.payload?.fieldsErrors[0].code) : null,
      }
    })

    // GetHistoryCashback
    builder.addCase(okxCashbackAsyncActions.GetHistoryOperations.pending, (state, action) => {
      state.isLoadingHistory = true
    })
    builder.addCase(okxCashbackAsyncActions.GetHistoryOperations.fulfilled, (state, action) => {
      const prevData = action.payload.page === 0 ? [] : state.historyOperations.data

      state.historyOperations.data = [...prevData, ...action.payload.data]
      state.historyOperations.page = action.payload.page
      state.historyOperations.total = action.payload.total
      state.isLoadingHistory = false
    })
    builder.addCase(okxCashbackAsyncActions.GetHistoryOperations.rejected, (state, action) => {
      state.isLoadingHistory = false
    })

    // PostWithdraw
    builder.addCase(okxCashbackAsyncActions.PostWithdrawal.pending, (state, action) => {})
    builder.addCase(okxCashbackAsyncActions.PostWithdrawal.fulfilled, (state, action) => {
      state.responseDetails = {
        isCompleted: true,
        isSuccess: true,
      }
    })
    builder.addCase(okxCashbackAsyncActions.PostWithdrawal.rejected, (state, action) => {
      state.errors = action.payload?.fieldsErrors || []

      if (action.payload?.fieldsErrors) {
        const googleCodeError = action.payload?.fieldsErrors.find(error => error.field === 'googleCode')

        if (googleCodeError) {
          return
        }
      }

      state.responseDetails = {
        isCompleted: true,
        isSuccess: false,
        errorCode: action.payload?.fieldsErrors ? Number(action.payload?.fieldsErrors[0].code) : null,
      }
    })
  },
})

export const okxAffiliateActions = {
  ...slice.actions,
  ...okxCashbackAsyncActions,
}

export const okxAffiliateReducer = slice.reducer
