import React, { useCallback, useEffect } from 'react'
import { RemoveApiKeysPayload } from '@/backend/api/apiKeyAPI'
import { apiKeyAsyncActions } from '@/redux/apiKey/apiKey.actions'
import { appActions } from '@/redux/app/app.slice'
import ApiLeftBar from '@/ui/organisms/ApiLeftBar'
import ApiRightBar from '@/ui/organisms/ApiRightBar'
import { useActions, useAppSelector } from '@/utils'
import style from './style.module.scss'
import * as amplitude from '@amplitude/analytics-browser'
import { E_AmplitudeEventName } from '@/redux/profile/profile.types'
import { useExchageType } from '@/utils/hooks/useExchangeType'

export const ApiPage = () => {
  const { apiKeys, status, apiKeyStatusRemove } = useAppSelector(state => state.apiKeys)
  const { GetApiKeysTC, RemoveApiKeyTC } = useActions(apiKeyAsyncActions)
  const { setRequestStatus } = useActions(appActions)
  const { exchangeType } = useExchageType()

  useEffect(() => {
    setRequestStatus(status)
  }, [setRequestStatus, status])

  const handleClickRemoveConfirm = useCallback(
    (payload: RemoveApiKeysPayload) => {
      RemoveApiKeyTC(payload)
    },
    [RemoveApiKeyTC]
  )

  useEffect(() => {
    if (status === 'idle') {
      GetApiKeysTC()
    }
  }, [status, GetApiKeysTC])

  useEffect(() => {
    if (apiKeyStatusRemove === 'succeeded') {
      GetApiKeysTC()
    }
  }, [apiKeyStatusRemove, GetApiKeysTC])

  useEffect(() => {
    const event = {
      event_type: E_AmplitudeEventName.APIPageViewed,
      event_properties: {
        exchangeType,
      },
    }
    amplitude.track(event)
  }, [])

  return (
    <div className={style.api}>
      <div className={style.content}>
        <ApiLeftBar />
        <ApiRightBar
          data={apiKeys}
          status={status}
          onRemove={handleClickRemoveConfirm}
          apiKeyStatusRemove={apiKeyStatusRemove}
        />
      </div>
    </div>
  )
}
