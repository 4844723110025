import React, { useEffect } from 'react'
import { KYCLeftBar } from '@/ui/organisms/KYCLeftBar'
import { KYCRightBar } from '@/ui/organisms/KYCRightBar'
import style from './style.module.scss'

export const KYCPage = () => {
  return (
    <div className={style.wrap}>
      <div className={style.content}>
        <KYCLeftBar />
        <KYCRightBar />
      </div>
    </div>
  )
}
