import { FC, memo } from 'react'
import { floorNumber } from '@/utils'
import { TitleH2 } from '@/ui/atoms/TitleH2'
import { useOverview } from '@/utils/hooks/useOverview'
import s from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

const MainTabBalance: FC = () => {
  const {
    balanceByInstrument: { accountBalance, symbol },
  } = useOverview()

  return (
    <div className={s.root} data-testid={DataTestIds.WalletEstimatedBalance}>
      <TitleH2 label={floorNumber(accountBalance)} />
      <div className={s.currency}>{symbol}</div>
    </div>
  )
}

export default memo(MainTabBalance)
