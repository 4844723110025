import React from 'react'

interface OkxProps {
  className?: string
}

const Okx: React.FC<OkxProps> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="40" height="40" rx="20" fill="#FAFAFA" />
      <rect x="11.6" y="11.6" width="5.6" height="5.59998" fill="#12101A" />
      <rect x="17.2" y="17.2" width="5.6" height="5.59998" fill="#12101A" />
      <rect x="22.7999" y="11.6" width="5.6" height="5.59998" fill="#12101A" />
      <rect x="11.6" y="22.8" width="5.6" height="5.59998" fill="#12101A" />
      <rect x="22.7999" y="22.8" width="5.6" height="5.59998" fill="#12101A" />
    </svg>
  )
}

export { Okx }
