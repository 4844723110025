import { FieldErrorType } from '@/types'

export enum NotificationType {
  ALL = 'ALL',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  UPDATE = 'UPDATE',
}

export enum NotificationStatus {
  READ = 'READ',
  NEW = 'NEW',
}

export type InitialState = {
  summary: SummaryType
  notificationData: NotificationData
  isLoading: boolean
  errors: FieldErrorType[]
}

export type NotificationData = {
  notifications: TypeNotification[]
  filters: FiltersType
}

export type FiltersType = {
  unreadOnly: boolean
  page: number
  size: number
  total: number
  type: NotificationType.ANNOUNCEMENT | NotificationType.UPDATE | null
}

export type SummaryReqType = {
  firstLoginTime: string | null
}

export type TypeNotification = {
  id: string
  type: NotificationType.ANNOUNCEMENT | NotificationType.UPDATE
  status: NotificationStatus.READ | NotificationStatus.NEW
  title: string
  description: string
  createdAt: string
}

export type SummaryType = {
  announcements: number
  updates: number
}

export type NotificationDataResponse = {
  notifications: TypeNotification[]
  page: number
  size: number
  total: number
}
