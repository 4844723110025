import { TTableAccessor } from '@/ui/molecules/Table/Table'
import { PositionColumnsKey } from '../models'
import { t } from '@lingui/macro'
import style from '../style.module.scss'

export const EVENT_POSITION_COLUMNS: PositionColumnsKey[] = ['ticker', 'openingPrice', 'volume', 'price', 'pnl']

const getTranslations = (): Record<PositionColumnsKey, string> => ({
  ticker: t({ id: 'riskManager.event.ticker', comment: 'Ticker' }),
  openingPrice: t({ id: 'riskManager.event.openingPrice', comment: 'Opening price' }),
  volume: t({ id: 'riskManager.event.volume', comment: 'Volume' }),
  price: t({ id: 'riskManager.event.price', comment: 'Price' }),
  pnl: t({ id: 'riskManager.event.pnl', comment: 'PnL' }),
})

export function useEventPositionColumns(): TTableAccessor[] {
  const translations = getTranslations()

  return EVENT_POSITION_COLUMNS.map((columnKey, idx) => {
    const accessor: TTableAccessor = {
      accessor: columnKey,
      header: <>{translations[columnKey]}</>,
      cell: ({ getValue }) => {
        const data = getValue()
        return data ? data : '-'
      },
      meta: {},
    }

    if (idx === EVENT_POSITION_COLUMNS.length - 1 && accessor.meta) {
      accessor.meta.className = [style.alignRight]
    }

    return accessor
  })
}
