import { FC } from 'react'

interface AlertProps {
  className?: string
}

export const Alert: FC<AlertProps> = props => {
  const { className } = props

  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="0.5" width="30" height="30" rx="15" fill="url(#paint0_radial_17251_140857)" />
      <path d="M15.5 7.5V17.5" stroke="#FAFAFA" strokeWidth="2" strokeLinecap="round" />
      <path d="M15.5 22.5V22.6667" stroke="#FAFAFA" strokeWidth="2" strokeLinecap="round" />
      <defs>
        <radialGradient
          id="paint0_radial_17251_140857"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0.5) rotate(40.8151) scale(39.6394 523.839)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
