import { FC } from 'react'

export const Deal: FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="url(#paint0_radial_15434_3685)" />
      <path
        d="M15.2666 19.3334C15.2666 19.3334 12.8694 16.8955 11.3333 15.3334M11.3333 15.3334C12.8694 13.7713 15.2666 11.3334 15.2666 11.3334M11.3333 15.3334C15.7221 15.3334 27.3333 15.3334 27.3333 15.3334"
        stroke="#FAFAFA"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M24.7334 28.6666C24.7334 28.6666 27.1306 26.2287 28.6667 24.6666M28.6667 24.6666C27.1306 23.1045 24.7334 20.6666 24.7334 20.6666M28.6667 24.6666C24.2779 24.6666 12.6667 24.6666 12.6667 24.6666"
        stroke="#FAFAFA"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_15434_3685"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(40.8151) scale(52.8525 698.452)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
