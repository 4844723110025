import { useMemo } from 'react'
import { useAppSelector } from './useAppSelector'
import { Status } from '@/backend/types'

export const useStatuses = (): {
  termsLimitsStatus: Status
  termsLimitsCashbackStatus: Status
} => {
  const { cashbackTermsStatus, status: cashbackStatus } = useAppSelector(state => state.cashback)
  const { availableLimitsStatus } = useAppSelector(state => state.kyc)

  const termsLimitsStatus = useMemo<Status>(() => {
    return cashbackTermsStatus !== 'succeeded' || availableLimitsStatus !== 'succeeded' ? 'loading' : 'succeeded'
  }, [cashbackTermsStatus, availableLimitsStatus])

  const termsLimitsCashbackStatus = useMemo(() => {
    return termsLimitsStatus !== 'succeeded' || cashbackStatus !== 'succeeded' ? 'loading' : 'succeeded'
  }, [termsLimitsStatus, cashbackStatus])

  return {
    termsLimitsStatus,
    termsLimitsCashbackStatus,
  }
}
