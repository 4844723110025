import clsx from 'clsx'
import { memo } from 'react'
import style from './style.module.scss'

type SkeletonListPropsType = {
  className?: string
}

export const SkeletonButton = memo(() => {
  return <div className={style.skeletonButton} />
})

export const SkeletonList = memo<SkeletonListPropsType>(({ className }) => {
  return (
    <div className={clsx(style.skeletonList, className)}>
      <div className={style.skeletonListRightBar}>
        <div className={style.bottom}>
          <div className={style.bottomContent}>
            <div className={style.blockWrap}>
              <div className={style.left}>
                <div className={style.circle} />
                <div className={style.itemWrapper}>
                  <div className={style.item} />
                  <div className={style.item} />
                </div>
              </div>
              <div className={style.itemWrap}>
                <div className={style.item} />
                <div className={style.item} />
              </div>
              <div className={style.itemWrap}>
                <div className={style.item} />
                <div className={style.item} />
              </div>
              <div className={style.itemWrap}>
                <div className={style.item} />
                <div className={style.item} />
              </div>
            </div>
          </div>
          <div className={style.bottomContent}>
            <div className={style.blockWrap}>
              <div className={style.left}>
                <div className={style.circle} />
                <div className={style.itemWrapper}>
                  <div className={style.item} />
                  <div className={style.item} />
                </div>
              </div>
              <div className={style.itemWrap}>
                <div className={style.item} />
                <div className={style.item} />
              </div>
              <div className={style.itemWrap}>
                <div className={style.item} />
                <div className={style.item} />
              </div>
              <div className={style.itemWrap}>
                <div className={style.item} />
                <div className={style.item} />
              </div>
            </div>
          </div>
          <div className={style.bottomContent}>
            <div className={style.blockWrap}>
              <div className={style.left}>
                <div className={style.circle} />
                <div className={style.itemWrapper}>
                  <div className={style.item} />
                  <div className={style.item} />
                </div>
              </div>
              <div className={style.itemWrap}>
                <div className={style.item} />
                <div className={style.item} />
              </div>
              <div className={style.itemWrap}>
                <div className={style.item} />
                <div className={style.item} />
              </div>
              <div className={style.itemWrap}>
                <div className={style.item} />
                <div className={style.item} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
