interface TValidationMap extends Record<string, unknown> {
  regexp: RegExp
  max: number
  min: number
}

export const VALIDATION_RULES: Record<string, TValidationMap> = {
  telegram: {
    regexp: /^@(?=(?:[0-9_]*[A-Za-z]){3})[A-Za-z0-9_]{5,}$/,
    max: 32,
    min: 6, // means 5 significant symbolt after "@at" symbol.
  },
  drawdownPercentage: {
    max: 30,
    min: 0,
    regexp: /$/,
  },
  cashbackPercent: {
    max: 45,
    min: 5,
    regexp: /$/,
  },
  drawdownFixed: {
    max: 100000,
    min: 0,
    regexp: /$/,
  },
}
