import { FC, SetStateAction, useState } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { InputSearch, InputSize } from '@/ui/kit'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { MarginItem } from './MarginItem/MarginItem'
import { SVG } from '@/assets/svg'
import { useOverview } from '@/utils/hooks/useOverview'
import { HandleForm } from './HandleForm/HandleForm'
import { AccountOverviewPositionsView } from '@/backend/models/OverviewDTO'
import { ResultForm } from '@/pages/OKXAfiliate/components/LeftBar/components/ResultForm'
import { useActions, useAppSelector } from '@/utils'
import { overviewActions } from '@/redux/overview/overview.slice'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'

type Props = {
  onClose: () => void
}

export const IsolatedMargin: FC<Props> = props => {
  const { allPositions } = useOverview()
  const [search, setSearch] = useState('')
  const [activePostion, setActivePosition] = useState<AccountOverviewPositionsView | null>(null)
  const { isolatedMargin } = useAppSelector(state => state.overview)
  const { resetIsolatedMargin } = useActions(overviewActions)
  const { exchangeType } = useExchageType()

  function handleChangeSearch(value: SetStateAction<string>) {
    setSearch(value)
  }

  function close() {
    resetIsolatedMargin()
    props.onClose()
  }

  function closeSuccessResultForm() {
    resetIsolatedMargin()
    window.location.reload()
    props.onClose()
  }

  function handleChange(position: AccountOverviewPositionsView) {
    setActivePosition(position)
  }

  function previousStep() {
    setActivePosition(null)
  }

  function goBack() {
    resetIsolatedMargin()
  }

  const marginPosition =
    exchangeType === EXCHANGES_TYPE.BYBIT_BROKER
      ? allPositions
      : allPositions.filter(item => item.marginType === 'isolated')
  const filteredPositions = marginPosition.filter(item => item.symbol.toLowerCase().includes(search.toLowerCase()))

  const title = t({
    id: 'isolatedMargin.popup.title',
    comment: 'Adjusting Margin',
  })

  const symbol = activePostion ? ` - ${activePostion.symbol}` : ''

  if (isolatedMargin.isMarginSet) {
    return (
      <>
        {isolatedMargin.isMarginSuccess ? (
          <ResultForm
            title={t({
              id: 'isolatedMargin.resultPopup.successTitle',
              comment: 'Set margin success',
            })}
            description={t({
              id: 'isolatedMargin.resultPopup.successDescription',
              comment: 'Margin success changed',
            })}
            onClose={closeSuccessResultForm}
            isSuccess
          />
        ) : (
          <ResultForm
            title={t({
              id: 'isolatedMargin.resultPopup.errorTitle',
              comment: 'Set margin error',
            })}
            description={t({
              id: 'isolatedMargin.resultPopup.error.unknown',
              comment: 'Margin not changed',
            })}
            onClose={close}
            onClickBackButton={goBack}
            titleBackButton={t({ id: 'isolatedMargin.resultPopup.backToForm', comment: 'Back' })}
            isSuccess={false}
            isShowBackButton
          />
        )}
      </>
    )
  }

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <TitleH3 className={style.title} label={`${title}${symbol}`} />
        <div className={style.closeBtn} onClick={close}>
          <SVG.Additional.Close />
        </div>
      </div>
      {!activePostion ? (
        <div className={style.contentWrapper}>
          <div className={style.contentHeader}>
            <InputSearch
              value={search}
              setValue={handleChangeSearch}
              size={InputSize.Small}
              placeholder={t({
                id: 'isolatedMargin.popup.search.symbol',
                comment: 'Symbol',
              })}
              containerClassName={style.search}
            />
            <div className={style.size}>
              {t({
                id: 'isolatedMargin.popup.size',
                comment: 'Size',
              })}
            </div>
            <div className={style.margin}>
              {t({
                id: 'isolatedMargin.popup.margin',
                comment: 'Margin',
              })}
            </div>
            <div></div>
          </div>
          <div className={style.content}>
            {filteredPositions.map((item, index) => {
              return <MarginItem data={item} key={item.symbol + index} onChangeItem={handleChange} />
            })}
          </div>
        </div>
      ) : (
        <HandleForm onPreviousStep={previousStep} position={activePostion} />
      )}
    </div>
  )
}
