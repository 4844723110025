import { KYCAvailableLimitsDTO } from '@/backend/models/KycDTO'
import { formatNumber } from '@/utils'
import BigNumber from 'bignumber.js'
import { TKYCAvailableLimits } from './kyc.types'

const AVAILABLE_PRECISION = 100

export const kycLimitsReducer = (availableLimits: KYCAvailableLimitsDTO): TKYCAvailableLimits => {
  const availableDeposit = new BigNumber(availableLimits.depositLimit.available).dividedBy(AVAILABLE_PRECISION)
  const availableWithdraw = new BigNumber(availableLimits.withdrawalLimit.available).dividedBy(AVAILABLE_PRECISION)
  const availableDepositFormatted = `${formatNumber(availableDeposit.toString())} ${
    availableLimits.depositLimit.currency
  }`
  const availableWithdrawFormatted = `${formatNumber(availableWithdraw.toString())} ${
    availableLimits.withdrawalLimit.currency
  }`
  const limitDeposit = new BigNumber(availableLimits.depositLimit.limit.value).dividedBy(AVAILABLE_PRECISION)
  const limitWithdraw = new BigNumber(availableLimits.withdrawalLimit.limit.value).dividedBy(AVAILABLE_PRECISION)
  const limitDepositFormatted = `${formatNumber(availableDeposit.toString())} ${
    availableLimits.depositLimit.limit.currency
  }`
  const limitWithdrawFormatted = `${formatNumber(availableWithdraw.toString())} ${
    availableLimits.withdrawalLimit.limit.currency
  }`

  return {
    ...availableLimits,
    depositLimit: {
      ...availableLimits.depositLimit,
      availableRounded: availableDeposit.toNumber(),
      availableFormatted: availableDepositFormatted,
      limitRounded: limitDeposit.toNumber(),
      limitFormatted: limitDepositFormatted,
    },
    withdrawalLimit: {
      ...availableLimits.withdrawalLimit,
      availableRounded: availableWithdraw.toNumber(),
      availableFormatted: availableWithdrawFormatted,
      limitRounded: limitWithdraw.toNumber(),
      limitFormatted: limitWithdrawFormatted,
    },
  }
}
