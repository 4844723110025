import * as yup from 'yup'
import { VALIDATION_RULES } from '@/core/config/validation'
import { t } from '@lingui/macro'

type TValidation = {
  okx: number
  binance: number
  bybit: number
}

export const VALIDATION_SCHEMA = (): yup.ObjectSchema<TValidation> =>
  yup.object({
    okx: yup
      .number()
      .max(
        VALIDATION_RULES.cashbackPercent.max,
        t({
          id: 'CashbackForm.errors.okx.max',
          message: 'okx must be less than or equal to 45',
        })
      )
      .min(
        VALIDATION_RULES.cashbackPercent.min,
        t({
          id: 'CashbackForm.errors.okx.min',
          message: 'okx must be greater than or equal to 5',
        })
      )
      .typeError(
        t({
          id: 'CashbackForm.errors.okx.required',
          message: 'okx is required',
        })
      )
      .required(
        t({
          id: 'CashbackForm.errors.okx.required',
          message: 'okx is required',
        })
      ),
    binance: yup
      .number()
      .max(
        VALIDATION_RULES.cashbackPercent.max,
        t({
          id: 'CashbackForm.errors.binance.max',
          message: 'binance must be less than or equal to 45',
        })
      )
      .min(
        VALIDATION_RULES.cashbackPercent.min,
        t({
          id: 'CashbackForm.errors.binance.min',
          message: 'binance must be greater than or equal to 5',
        })
      )
      .typeError(
        t({
          id: 'CashbackForm.errors.binance.required',
          message: 'binance is required',
        })
      )
      .required(
        t({
          id: 'CashbackForm.errors.binance.required',
          message: 'binance is required',
        })
      ),
    bybit: yup
      .number()
      .max(
        VALIDATION_RULES.cashbackPercent.max,
        t({
          id: 'CashbackForm.errors.bybit.max',
          message: 'bybit must be less than or equal to 45',
        })
      )
      .min(
        VALIDATION_RULES.cashbackPercent.min,
        t({
          id: 'CashbackForm.errors.bybit.min',
          message: 'bybit must be greater than or equal to 5',
        })
      )
      .typeError(
        t({
          id: 'CashbackForm.errors.bybit.required',
          message: 'bybit is required',
        })
      )
      .required(
        t({
          id: 'CashbackForm.errors.bybit.required',
          message: 'bybit is required',
        })
      ),
  })
