import { PartnerLeftBar } from '@/ui/organisms/PartnerLeftBar'
import { PartnerRightBar } from '@/ui/organisms/PartnerRightBar'
import { useEffect } from 'react'
import { useActions, useAppSelector } from '@/utils'
import { partnerActions } from '@/redux/partner/partner.slice'
import { useNavigate, useParams } from 'react-router-dom'
import { urls } from '@/router/urls'
import { EPartners } from '@/redux/partner/partner.types'

import styles from './style.module.scss'
import { checkHasPartnerAccess } from '@/redux/partner/partner.utils'

export const PartnerPage = () => {
  const { hasPartnerAccess, partners, listStatus } = useAppSelector(state => state.partner)
  const { resetPartnerData, GetPartnerDataTC } = useActions(partnerActions)
  const navigate = useNavigate()
  const { partnerId } = useParams()

  useEffect(() => {
    if (partnerId === undefined || listStatus !== 'succeeded') return
    if (!checkHasPartnerAccess(partners, partnerId as EPartners)) navigate(urls.root)

    GetPartnerDataTC(partnerId as EPartners)
  }, [partnerId, partners, listStatus])

  useEffect(() => {
    if (hasPartnerAccess === false) navigate(urls.root)
  }, [hasPartnerAccess])

  useEffect(() => {
    return () => {
      resetPartnerData()
    }
  }, [])

  if (!hasPartnerAccess) return null

  return (
    <>
      <div className={styles.partner}>
        <div className={styles.content}>
          <PartnerLeftBar />
          <PartnerRightBar />
        </div>
      </div>
    </>
  )
}
