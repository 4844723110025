import React, { SVGProps } from 'react'

interface GearProps extends SVGProps<SVGSVGElement> {
  width?: string
  height?: string
  className?: string
}

export const Gear = (props: GearProps) => {
  const { height = '16', width = '15', ...other } = props

  return (
    <svg width={width} height={height} {...other} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 9.875C8.53553 9.875 9.375 9.03553 9.375 8C9.375 6.96447 8.53553 6.125 7.5 6.125C6.46447 6.125 5.625 6.96447 5.625 8C5.625 9.03553 6.46447 9.875 7.5 9.875Z"
        stroke="currentColor"
        strokeWidth="0.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 8.5499V7.4499C1.25 6.7999 1.78125 6.2624 2.4375 6.2624C3.56875 6.2624 4.03125 5.4624 3.4625 4.48115C3.1375 3.91865 3.33125 3.1874 3.9 2.8624L4.98125 2.24365C5.475 1.9499 6.1125 2.1249 6.40625 2.61865L6.475 2.7374C7.0375 3.71865 7.9625 3.71865 8.53125 2.7374L8.6 2.61865C8.89375 2.1249 9.53125 1.9499 10.025 2.24365L11.1063 2.8624C11.675 3.1874 11.8687 3.91865 11.5437 4.48115C10.975 5.4624 11.4375 6.2624 12.5688 6.2624C13.2188 6.2624 13.7563 6.79365 13.7563 7.4499V8.5499C13.7563 9.1999 13.225 9.7374 12.5688 9.7374C11.4375 9.7374 10.975 10.5374 11.5437 11.5186C11.8687 12.0874 11.675 12.8124 11.1063 13.1374L10.025 13.7561C9.53125 14.0499 8.89375 13.8749 8.6 13.3812L8.53125 13.2624C7.96875 12.2811 7.04375 12.2811 6.475 13.2624L6.40625 13.3812C6.1125 13.8749 5.475 14.0499 4.98125 13.7561L3.9 13.1374C3.33125 12.8124 3.1375 12.0811 3.4625 11.5186C4.03125 10.5374 3.56875 9.7374 2.4375 9.7374C1.78125 9.7374 1.25 9.1999 1.25 8.5499Z"
        stroke="currentColor"
        strokeWidth="0.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
