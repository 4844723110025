import { AddressDTO } from '@/backend/models/AddressDTO'
import { Status } from '@/backend/types'
import { AssetsAddresses } from './address.types'
import { FieldErrorType } from '@/types'

export type InitialState = {
  addresses: AddressDTO[]
  addressById: AddressDTO | {}
  status: Status
  assetsForAddresses: AssetsAddresses
  errors: FieldErrorType[]
  createdAddressStatus: Status
  updatedAddressStatus: Status
  addressRemoveStatus: Status
  addressByIdStatus: Status
  whitelistEnabled?: boolean
  whitelistGetStatus: Status
  whiteListToggleStatus: Status
}

const initialState: InitialState = {
  addresses: [],
  status: 'idle',
  assetsForAddresses: {
    assetsStatus: 'idle',
    assets: {},
  },
  addressById: {},
  errors: [],
  createdAddressStatus: 'idle',
  updatedAddressStatus: 'idle',
  addressRemoveStatus: 'idle',
  addressByIdStatus: 'idle',
  whitelistEnabled: undefined,
  whitelistGetStatus: 'idle',
  whiteListToggleStatus: 'idle',
}

export default initialState
