import { FC, ReactNode, useMemo } from 'react'
import { t } from '@lingui/macro'
import { TExchangesAvailable } from '@/core/constants'
import { SVG } from '@/assets/svg'
import { Trans } from '@lingui/react'
import { useSearchParams } from 'react-router-dom'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'
import { useAppSelector } from '@/utils'

interface IButtonActive {
  title: string
  data: TExchangesAvailable | undefined
}

export interface ICard {
  name?: string
  isDisabled?: boolean
  isAvailable?: boolean
  isAccent?: boolean
  header: { icon: FC; exchange: string }
  commission: { percent?: number | string; title: string | JSX.Element }
  columns?: [string, string]
  rows?: Array<[string, ReactNode]>
  button: IButtonActive
  description?: JSX.Element
}

type TExchangeVisibleCards = Partial<Record<TExchangesAvailable, ICard>>

const EXCHANGES_ORDER: TExchangesAvailable[] = ['BINANCE_BROKER_FUTURE', 'BYBIT_BROKER', 'OKX_BROKER', 'OKX_AFFILIATE']

export function useExchageCardsData(): ICard[] {
  const { config, userStatus } = useAppSelector(state => state.okxAffiliate)
  const [searchParams] = useSearchParams()
  const binanceDisabled = searchParams.get('binance')
  const { isOkxDisabled } = useExchangesDisabled()

  const columns = {
    maker: t({
      id: 'core.commissions.maker',
      message: 'Maker',
    }),
    taker: t({
      id: 'core.commissions.taker',
      message: 'Taker',
    }),
  }

  const commissionBinance = {
    // spot
    spotMaker: t({
      id: 'commissions.spot.makerValue',
      message: '0.1%',
    }),
    spotTaker: t({
      id: 'commissions.spot.takerValue',
      message: '0.1%',
    }),

    // Futures COIN-M
    futCoinMaker: t({
      id: 'commissions.coinm.makerValue',
      message: '0.1%',
    }),
    futCoinTaker: t({
      id: 'commissions.coinm.takerValue',
      message: '0.1%',
    }),

    // Futures USDⓈ-M
    futUsdmMaker: t({
      id: 'commissions.usdm.makerValue',
      message: '0.1%',
    }),
    futUsdmTaker: t({
      id: 'commissions.usdm.takerValue',
      message: '0.1%',
    }),
  }

  const commissionOkx = {
    // Spot
    spotMaker: t({
      id: 'commissions.okx.spot.makerValue',
      message: '0.08%',
    }),
    spotTaker: t({
      id: 'commissions.okx.spot.takerValue',
      message: '0.1%',
    }),

    // Swap
    swapMaker: t({
      id: 'commissions.okx.swap.makerValue',
      message: '0.02%',
    }),
    swapTaker: t({
      id: 'commissions.okx.swap.takerValue',
      message: '0.05%',
    }),
  }

  const commissionBybit = {
    // Spot
    spotMaker: (
      <Trans
        id="commissions.bybit.spot.makerValue"
        message={'0.03%'}
        components={{
          colored: <></>,
        }}
      />
    ),
    spotTaker: (
      <Trans
        id="commissions.bybit.spot.takerValue"
        message={'0.045%'}
        components={{
          colored: <></>,
        }}
      />
    ),

    // Swap
    swapMaker: (
      <Trans
        id="commissions.bybit.derivatives.makerValue"
        message={'0.03%'}
        components={{
          colored: <></>,
        }}
      />
    ),
    swapTaker: (
      <Trans
        id="commissions.bybit.derivatives.takerValue"
        message={'0.045%'}
        components={{
          colored: <></>,
        }}
      />
    ),
  }

  const comissionOkxAffiliate = {
    title: (
      <Trans id="chooseExchange.okxAffiliate.benefits" values={{ okxPercent: config.okxPercent, tigerPercent: 25 }} />
    ),
    description: (
      <Trans
        id="chooseExchange.okxAffiliate.description"
        values={{ okxPercent: config.okxPercent, tigerPercent: 25 }}
      />
    ),
  }

  const exchangeSource = useMemo<ICard[]>(() => {
    const source: TExchangeVisibleCards = {
      BINANCE_BROKER_FUTURE: {
        header: { icon: SVG.Settings.Binance, exchange: 'Binance' },
        commission: { percent: 35, title: t({ id: 'chooseExchange.commission', message: `Saved commission` }) },
        columns: [t({ id: 'chooseExchange.fees', message: `Fees` }), `${columns.maker} / ${columns.taker}`],
        rows: [
          [
            t({
              id: 'core.accounts.spot',
              message: 'Spot',
            }),
            `${commissionBinance.spotMaker} / ${commissionBinance.spotTaker}`,
          ],
          [
            t({
              id: 'core.accounts.coinm',
              message: 'Futures COIN-M',
            }),
            `${commissionBinance.futCoinMaker} / ${commissionBinance.futCoinTaker}`,
          ],
          [
            t({
              id: 'core.accounts.usdm',
              message: 'Futures USDM',
            }),
            `${commissionBinance.futUsdmMaker} / ${commissionBinance.futUsdmTaker}`,
          ],
        ],
        button: {
          title: t({ id: 'chooseExchange.defaultButton', message: 'Make default' }),
          data: 'BINANCE_BROKER_FUTURE',
        },
        isAvailable: binanceDisabled === null,
      },
      OKX_BROKER: {
        header: { icon: SVG.Settings.Okx, exchange: 'OKX' },
        commission: { percent: 30, title: t({ id: 'chooseExchange.commission', message: `Saved commission` }) },
        columns: [t({ id: 'chooseExchange.fees', message: `Fees` }), `${columns.maker} / ${columns.taker}`],
        rows: [
          [
            t({
              id: 'core.accounts.spot',
              message: 'Spot',
            }),
            `${commissionOkx.spotMaker} / ${commissionOkx.spotTaker}`,
          ],
          [
            t({
              id: 'core.accounts.swap',
              message: 'Swap',
            }),
            `${commissionOkx.swapMaker} / ${commissionOkx.swapTaker}`,
          ],
        ],
        button: {
          title: t({ id: 'chooseExchange.defaultButton', message: 'Make default' }),
          data: 'OKX_BROKER',
        },
        isAvailable: !isOkxDisabled,
      },
      BYBIT_BROKER: {
        isAccent: true,
        header: { icon: SVG.Settings.Bybit, exchange: 'Bybit' },
        commission: {
          percent: t({ id: 'chooseExchange.percentBybit', comment: 'Up to 82%' }),
          title: t({ id: 'chooseExchange.exclusiveFees', message: `Best trading fees` }),
        },
        columns: [t({ id: 'chooseExchange.fees', message: `Fees` }), `${columns.maker} / ${columns.taker}`],
        rows: [
          [
            t({
              id: 'core.accounts.spot',
              message: 'Spot',
            }),
            <>
              {commissionBybit.spotMaker} / {commissionBybit.spotTaker}
            </>,
          ],
          [
            t({
              id: 'core.accounts.futures',
              message: 'Futures',
            }),
            <>
              {commissionBybit.swapMaker} / {commissionBybit.swapTaker}
            </>,
          ],
        ],
        button: {
          title: t({ id: 'chooseExchange.defaultButton', message: 'Make default' }),
          data: 'BYBIT_BROKER',
        },
      },
      OKX_AFFILIATE: {
        name: 'OKX_AFFILIATE',
        isAccent: true,
        header: { icon: SVG.Settings.Okx, exchange: 'OKX' },
        commission: {
          percent: 45,
          title: comissionOkxAffiliate.title,
        },
        description: comissionOkxAffiliate.description,
        button: {
          title: t({ id: 'chooseExchange.defaultButton', comment: 'Make default' }),
          data: 'OKX_AFFILIATE',
        },
        isAvailable: true,
      },
    }

    return EXCHANGES_ORDER.reduce((acc, exchange) => {
      return [...acc, ...(source[exchange]?.isAvailable !== false ? ([source[exchange]] as ICard[]) : ([] as ICard[]))]
    }, [] as ICard[])
  }, [])

  return exchangeSource
}
