import {
  GetApiKeysTC,
  GetApiKeysStatisticsTC,
  GetApiKeyByIdTC,
  CleanGetApiByIdKeysTC,
  CreateApiKeyTC,
  CreateMasterApiKeysTC,
  RemoveApiKeyTC,
  CleanCreatedApiKeysTC,
  UpdateApiKeyTC,
  CleanUpdateApiKeyTC,
  GetReadOnlyKeyStatusTC,
} from './apiKeys.thunks'

export const apiKeyAsyncActions = {
  GetApiKeysTC,
  GetApiKeysStatisticsTC,
  GetApiKeyByIdTC,
  CleanGetApiByIdKeysTC,
  CreateApiKeyTC,
  CreateMasterApiKeysTC,
  UpdateApiKeyTC,
  CleanCreatedApiKeysTC,
  CleanUpdateApiKeyTC,
  RemoveApiKeyTC,
  GetReadOnlyKeyStatus: GetReadOnlyKeyStatusTC,
}
