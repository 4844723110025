import clsx from 'clsx'
import { FC, useCallback, useState, ChangeEvent, MouseEvent } from 'react'
import { SVG } from '@/assets/svg'
import { useOutsideClick } from '@/utils'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

interface IInputNumberProps {
  value?: number | string
  step?: number
  min?: number
  max?: number
  className?: string
  variant?: 'dark' | 'light'
  onChange: (value: number | string) => void
  onBlur?: (value: number | string) => void
  dataTestId?: string
  fallbackToEdges?: boolean
}

const InputNumber: FC<IInputNumberProps> = props => {
  const {
    value = 0,
    step = 1,
    min = 0,
    max,
    onChange,
    onBlur,
    className,
    variant = 'dark',
    dataTestId,
    fallbackToEdges = false,
  } = props

  const [active, setActive] = useState(false)

  const handleActivateClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()

      setActive(true)
    },
    [setActive]
  )

  const handleDeactivate = useCallback(() => {
    setActive(false)
  }, [])

  const ref = useOutsideClick<HTMLDivElement>(handleDeactivate)

  const format = (value: string | number) => {
    if (value === '') return value
    if (min && Number(value) < min) return min
    if (max && Number(value) > max) return max
    return Number(value)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    onChange(isNaN(value) ? '' : format(value))
  }

  const handleBlur = () => {
    onBlur?.(value)
  }

  const increaseValue = useCallback(() => {
    const nextValue = Number(value) + step

    if (max !== undefined && nextValue > max) {
      if (fallbackToEdges) onChange(max)
      return
    }

    const rounded = Math.ceil(Number(value))
    if (rounded < nextValue && rounded !== Number(value)) {
      onChange(rounded)
      return
    }

    onChange(nextValue)
  }, [value, max, step, onChange])

  const decreaseValue = useCallback(() => {
    const nextValue = Number(value) - step

    if (min !== undefined && nextValue < min) {
      if (fallbackToEdges) onChange(min)
      return
    }

    const rounded = Math.floor(Number(value))
    if (rounded > nextValue && rounded !== Number(value)) {
      onChange(rounded)
      return
    }

    onChange(nextValue)
  }, [value, min, step, onChange])

  return (
    <div
      ref={ref}
      className={clsx(
        style.root,
        { [style.active]: active, [style.dark]: variant === 'dark', [style.light]: variant === 'light' },
        className
      )}
      onClick={handleActivateClick}
    >
      <div className={style.content}>
        <button
          className={style.btn}
          onClick={decreaseValue}
          data-testid={`${dataTestId}${DataTestIds.DecreaseSuffix}`}
        >
          <SVG.Arrows.ArrowSmallLeft className={style.arrow} />
        </button>
        <input
          type="tel"
          value={value.toString()}
          step={step}
          min={min}
          max={max}
          onChange={handleChange}
          onBlur={handleBlur}
          className={style.input}
          data-testid={dataTestId}
        />
        <button
          className={style.btn}
          onClick={increaseValue}
          data-testid={`${dataTestId}${DataTestIds.IncreaseSuffix}`}
        >
          <SVG.Arrows.ArrowSmallLeft className={style.arrow} />
        </button>
      </div>
    </div>
  )
}

export { InputNumber }
