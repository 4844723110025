import { EXCHANGES_TYPE } from '@/core/constants'
import { urls } from '@/router/urls'
import { useAppSelector } from '@/utils'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const Root: FC = () => {
  const {
    metadata: { redirectTo },
  } = useAppSelector(state => state.profile)
  const navigate = useNavigate()
  const { exchangeType } = useExchageType()

  useEffect(() => {
    if (exchangeType === EXCHANGES_TYPE.OKX_AFFILIATE) {
      navigate(urls.okxAffiliate)
      return
    }

    navigate(redirectTo)
  }, [redirectTo])

  return null
}
