import { urls } from '@/router/urls'
export const BINANCE_REQUEST_TIMEOUT = 4000

export const NEED_POLING_ROUTES = [urls.wallet, urls.orders, urls.assets]

export const OKX_SOCKET_RECONNECT_LIMIT = 3

export const TRADING_HISTORY_REFRESH_TIMEOUT = 6000

export const TEMPORARILY_BINANCE_LIMIT_DATE = new Date('2024-04-30')
