import { TitleH2 } from '@/ui/atoms/TitleH2'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { CashbackPercentageAndProgressBar } from '../CashbackPercentageAndProgressBar'
import { useAppSelector } from '@/utils'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { ECashbackCalculationRule } from '@/backend/api/cashbackAPI'
import { useMemo } from 'react'

export const HowItWorksRightBar = () => {
  const { cashbackTerms } = useAppSelector(state => state.cashback)
  const { cashback } = useAppSelector(state => state.cashback)

  const isVolume = useMemo(() => {
    return cashback.calculationRule === ECashbackCalculationRule.tradeVolume
  }, [cashback.calculationRule])

  return (
    <div className={style.rightBar}>
      <TitleH2
        className={style.title}
        label={t({
          id: 'howitworks.title.msg-1',
          message: `Your commission reduction percentage is based on the trading volume in the last 30 days: the more you trade, the lower your commissions.`,
        })}
      />
      {!isVolume && (
        <TitleH3
          label={t({
            id: 'howitworks.subtitle.priorityRefcode',
            message: `Your referral code may affect the reduction value`,
          })}
          className={style.subtitle}
        />
      )}
      <div className={style.text}>
        {isVolume && <CashbackPercentageAndProgressBar terms={cashbackTerms.tradeVolume} />}
      </div>
    </div>
  )
}
