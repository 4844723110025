import { DEFAULT_VALUE_WITH_ZERO, handleRound } from '@/ui/organisms/DiaryRightBar/components/WeekCard/WeekCard'
import style from './style.module.scss'
import clsx from 'clsx'
import { t } from '@lingui/macro'
import { FC } from 'react'
import { SVG } from '@/assets/svg'

type WidgetDistributionProps = {
  short: number
  long: number
  className?: string
}

const getCoordFromDegrees = (angle: number, radius: number, svgSize: number) => {
  const x = svgSize + radius * Math.sin((angle * Math.PI) / 180)
  const y = svgSize + -radius * Math.cos((angle * Math.PI) / 180)
  return [x, y]
}

const HUNDRED_PERCENT_FULL = '100.00'
const HUNDRED_PERCENT = '100'
export const WidgetDistribution: FC<WidgetDistributionProps> = ({ short, long, className }) => {
  const svgSize = 38
  const radius = 38
  const radius2 = 26
  const angle = short * 3.6
  const roundShort = handleRound(short) === HUNDRED_PERCENT_FULL ? HUNDRED_PERCENT : handleRound(short)
  const roundLong = handleRound(long) === HUNDRED_PERCENT_FULL ? HUNDRED_PERCENT : handleRound(long)
  const coord1 = getCoordFromDegrees(angle, radius, svgSize)
  const coord2 = getCoordFromDegrees(angle, radius2, svgSize)

  return (
    <div className={clsx(style.tigerWidgetDistribution, className)}>
      <div>{<SVG.Dashboard.DistributionWidget short={short} coord1={coord1} coord2={coord2} />}</div>
      <div
        className={clsx(
          style.tigerWidgetDistributionValue,
          style.tigerWidgetDistributionValueLeft,
          style.tigerWidgetDistributionValueGreen,
          {
            [style.withoutValue]: long === 0,
          }
        )}
      >
        {long <= 0 ? DEFAULT_VALUE_WITH_ZERO + ' %' : roundLong + ' %'}
      </div>
      <div
        className={clsx(
          style.tigerWidgetDistributionValue,
          style.tigerWidgetDistributionValueRight,
          style.tigerWidgetDistributionValueRed,
          {
            [style.withoutValue]: short === 0,
          }
        )}
      >
        {short <= 0 ? DEFAULT_VALUE_WITH_ZERO + ' %' : roundShort + ' %'}
      </div>
      <div className={style.text}>
        {t({
          id: 'trades.dashboard.widgetDistTitle',
        })}
      </div>
      <div className={style.tigerWidgetDistributionLegend}>
        <span
          className={clsx(style.tigerWidgetDistributionLegendDot, style.tigerWidgetDistributionLegendDotGreen)}
        ></span>
        <span className={style.dotText}>
          {t({
            id: 'trades.dashboard.widgetDistLong',
          })}
        </span>
        <span className={style.tigerWidgetDistributionLegendLine}></span>
        <span
          className={clsx(style.tigerWidgetDistributionLegendDot, style.tigerWidgetDistributionLegendDotRed)}
        ></span>
        <span className={style.dotText}>
          {t({
            id: 'trades.dashboard.widgetDistShort',
          })}
        </span>
      </div>
    </div>
  )
}
