import clsx from 'clsx'
import { FC, memo } from 'react'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

interface AssetProgressBarPropsType {
  label?: string
  className?: string
  progressBarClassName?: string
  percent?: string
  showPercentText?: boolean
}

const AssetProgressBar: FC<AssetProgressBarPropsType> = ({
  label,
  className,
  progressBarClassName,
  percent,
  showPercentText = true,
}) => {
  return (
    <div className={style.progressBar}>
      <div className={clsx(style.progressWrap, progressBarClassName)} data-testid={DataTestIds.AssetItemProgressBar}>
        <div style={{ width: percent ? `${percent}%` : '0%' }} className={clsx(style.barLine, className)} />
      </div>
      {showPercentText && (
        <div className={style.percent} data-testid={DataTestIds.AssetItemPercent}>
          {percent || '—'}%
        </div>
      )}
    </div>
  )
}

export default memo(AssetProgressBar)
