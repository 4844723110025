import { FC, memo, ReactNode } from 'react'
import { formatNumber, floorNumber } from '@/utils'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { formatDate } from '@/utils/lib/formatDate'
import clsx from 'clsx'
import { useTradingHistory } from '@/utils/hooks/useTradingHistory'
import { DataTestIds } from '@/utils/lib/dataTestIds'

type BalanceSheetItem = {
  label: ReactNode
  amount: string | number
  symbol: string
  id?: string
  profit?: string
}

interface IBalanceSheet {
  balances: BalanceSheetItem[]
}

const BalanceSheet: FC<IBalanceSheet> = props => {
  const { balances } = props
  const { resetStatistics, statisticsMoment, statisticsStartMoment } = useTradingHistory(true)

  return (
    <div className={style.balanceSheet}>
      {balances.map((item, index) => (
        <div key={index} className={style.itemWrap}>
          <div className={style.item}>
            <div>{item.label}</div>
            <div className={style.value} data-testid={`${DataTestIds.AccountInfoBalance}-${item.id}`}>
              {item.symbol === 'BTC' ? formatNumber(item.amount || 0) : floorNumber(item.amount || 0)}
              <span className={style.currency}>{item.symbol}</span>
            </div>
          </div>
          {item.profit && (
            <div
              className={clsx(style.profit, {
                [style._reset]: statisticsStartMoment,
              })}
            >
              <span>
                {statisticsStartMoment ? (
                  <>
                    {t({
                      id: 'statistics.afterReset',
                      comment: 'Changes after',
                    })}{' '}
                    <br />
                    {formatDate(Number(statisticsMoment))}
                  </>
                ) : (
                  t({
                    id: 'statistics.today',
                    comment: 'Changes for today',
                  })
                )}{' '}
                <span className={style.reset} onClick={resetStatistics}>
                  {t({
                    id: 'statistics.reset',
                    comment: 'Reset statistic',
                  })}
                </span>
              </span>
              <span
                className={Number(item.profit) > 0 ? style.colorGreen : Number(item.profit) < 0 ? style.colorRed : ''}
              >
                {item.profit} USDT
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default memo(BalanceSheet)
