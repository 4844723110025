import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'

export const SignInPage = () => {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated) return
    navigate(urls.root)
  }, [isAuthenticated, navigate])

  return null
}
