import { TLanguage } from '@/core/constants'
import { ServicesTradeGroupBy } from '@/utils/types/contracts.types'

type DateTimeFormatters = {
  [key in TLanguage]: {
    [key in ServicesTradeGroupBy]: Intl.DateTimeFormat
  }
}

const dateTimeFormatters: DateTimeFormatters = {
  en: {
    date: new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    week: new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    month: new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' }),
    hour: new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    minute: new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      month: 'short',
      day: '2-digit',
    }),
  },
  uk: {
    date: new Intl.DateTimeFormat('uk-UA', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    week: new Intl.DateTimeFormat('uk-UA', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    month: new Intl.DateTimeFormat('uk-UA', { year: 'numeric', month: 'long' }),
    hour: new Intl.DateTimeFormat('uk-UA', {
      hour: '2-digit',
      minute: '2-digit',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    minute: new Intl.DateTimeFormat('uk-UA', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
  },
  ru: {
    date: new Intl.DateTimeFormat('ru-RU', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    week: new Intl.DateTimeFormat('ru-RU', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    month: new Intl.DateTimeFormat('ru-RU', { year: 'numeric', month: 'long' }),
    hour: new Intl.DateTimeFormat('ru-RU', {
      hour: '2-digit',
      minute: '2-digit',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    minute: new Intl.DateTimeFormat('ru-RU', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      month: 'short',
      day: '2-digit',
    }),
  },
}

export { dateTimeFormatters }
