import { FC } from 'react'
import style from './style.module.scss'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { Trans, t } from '@lingui/macro'
import HistoryContent from './HistoryContent'
import { PageSetupGoogleAuth } from '@/ui/organisms/WalletSetupGoogleAuth'
import { useAppSelector, useGoogleAuthCode, useGoogleAuthSettings } from '@/utils/hooks'
import { urls } from '@/router/urls'

export const RightBar: FC = () => {
  const { google } = useAppSelector(state => state.profile)

  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()

  return (
    <div className={style.cashbackRightBar}>
      <TitleH1
        label={t({
          id: 'okxAffiliate.historyHeader.title',
          comment: 'Saved commission',
        })}
      />
      <div className={style.description}>
        <Trans id="okxAffiliate.historyHeader.description" values={{ tigerPercent: '25' }} />
      </div>
      {!google.enabled ? (
        <PageSetupGoogleAuth
          googleAuthSettings={googleAuthSettings}
          googleAuthCode={googleAuthCode}
          backRoute={urls.okxAffiliate}
        />
      ) : (
        <HistoryContent />
      )}
    </div>
  )
}
