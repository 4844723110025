import React from 'react'

interface OkProp {
  className?: string
}

export const Ok = (props: OkProp) => {
  const { className } = props

  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M2.26822 2.35982C1.91466 1.93554 1.28409 1.87821 0.859816 2.23178C0.435539 2.58534 0.378215 3.21591 0.731779 3.64018L2.26822 2.35982ZM4.83333 7L4.06511 7.64018C4.24816 7.85985 4.51649 7.99064 4.80229 7.99952C5.08809 8.00839 5.36402 7.8945 5.56035 7.68662L4.83333 7ZM11.227 1.68662C11.6062 1.28511 11.5881 0.652199 11.1866 0.272987C10.7851 -0.106225 10.1522 -0.0881418 9.77299 0.313376L11.227 1.68662ZM0.731779 3.64018L4.06511 7.64018L5.60155 6.35982L2.26822 2.35982L0.731779 3.64018ZM5.56035 7.68662L11.227 1.68662L9.77299 0.313376L4.10632 6.31338L5.56035 7.68662Z"
        fill="white"
      />
    </svg>
  )
}
