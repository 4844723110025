import { FC } from 'react'
import style from './style.module.scss'
import clsx from 'clsx'
import { useLanguageContext } from '@/providers/LanguageProvider'
import { useMobileSizeDetect } from '@/utils'
import { Trans } from '@lingui/react'
import { getTtLinksByLocale } from '@/router/links'
import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'

const BinanceTechnicalWorks: FC = () => {
  const { locale } = useLanguageContext()
  const [isMobile] = useMobileSizeDetect()

  return (
    <div className={clsx(isMobile && style.mobile, style.wrapper)}>
      <div className={style.popup}>
        <div className={clsx(style.gradient, style.title)}>
          {t({ id: 'overlay.binance.title', message: 'This page is currently unavailable' })}
        </div>

        <div className={style.description}>
          <div>
            <Trans
              id="overlay.binance.description"
              message="Description... <gradient>gradient text</gradient>."
              components={{
                gradient: <span className={style.gradient} />,
                br: <br />,
              }}
            />
          </div>

          <div>
            <Trans
              id="overlay.binance.watch-out"
              message="Watch out... <link>Telegram</link>."
              components={{
                link: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a className={clsx(style.gradient, style.link)} href={getTtLinksByLocale('telegram', locale)} />
                ),
              }}
            />
          </div>
        </div>

        <div className={style.info}>{t({ id: 'overlay.binance.info', message: 'Info...' })}</div>

        <div className={style.additional}>
          <div className={style.additionalImage}>
            <SVG.OtherIcons.Smiley />
          </div>

          <div>
            {t({
              id: 'overlay.binance.additional',
              message: 'OKX and ByBit forever',
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BinanceTechnicalWorks
