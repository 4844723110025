import { AxiosResponse } from 'axios'
import { ApiKeysAPI, IRestRequestGeneric, TCreateApiKeyPayloadBinance } from '@/backend/api/apiKeyAPI'
import { LeverageApi, OverviewAPI } from '@/backend/api'
import {
  TCancelOpenOrderResponseBinanceDTO,
  TCancelOpenOrderResponse,
  TCancelAllOpenOrdersPayloadBinanceDTO,
  TCancelAllOpenOrdersResponseBinanceDTO,
  TCancelAllOpenOrdersResponse,
  TClosePositionPayloadBinanceDTO,
  TClosePositionResponseDTO,
  TClosePositionResponse,
  TCloseAllPositionsPayloadBinanceDTO,
  TCloseAllPositionsResponseDTO,
  TCloseAllPositionsResponse,
  TCancelOpenOrderPayloadUnion,
  TCancelOpenOrderPayloadBinanceDTO,
  TCancelAllOpenOrdersPayload,
  TClosePositionPayload,
  TCloseAllPositionsPayload,
  TSetPositionModePayloadDTO,
  TSetPositionModeResponseDTO,
  TSetPositionMarginPayloadDTO,
  TSetPositionMarginResponseDTO,
} from '@/backend/models/OverviewDTO'
import { ApiKeyCreationDTO } from '@/backend/models/ApiKeyDTO'
import { IApiWrapper } from '../types'
import { ERROR_API_UNCOMPATIBLE_PAYLOAD } from '@/core/constants/errors/rest'
import { errorAsyncCreator, voidFunction } from '../utils'
import { OVERVIEW_ACCOUNTS } from '@/core/constants/overview'
import { TSellAssetPayload, TSellAssetPayloadUnion } from '@/redux/overview/overview.types'
import {
  TSetLeveragePayloadUnion,
  TSetLeveragePayloadBinance,
  TSetLeverageResponse,
  TSetLeverageResponseBinance,
} from '@/redux/leverage/leverage.types'
import { TAccountType } from '@/core/types/overview'
import {
  TSetMarginPayloadBinance,
  TSetMarginPayloadUnion,
  TSetMarginResponseBinance,
  TSetMarginResponse,
} from '@/backend/models/LeverageDTO'

export const BinanceApiWrapper: IApiWrapper = {
  methods: {
    apiKey: {
      createApiKey: ApiKeysAPI.createApiKey as IRestRequestGeneric<
        TCreateApiKeyPayloadBinance,
        AxiosResponse<ApiKeyCreationDTO>
      >,
    },
    overview: {
      cancelOpenOrder: OverviewAPI.cancelOpenOrder as IRestRequestGeneric<
        TCancelOpenOrderPayloadUnion,
        AxiosResponse<TCancelOpenOrderResponseBinanceDTO>
      >,
      cancelAllOpenOrders: OverviewAPI.cancelAllOpenOrders as IRestRequestGeneric<
        TCancelAllOpenOrdersPayloadBinanceDTO,
        AxiosResponse<TCancelAllOpenOrdersResponseBinanceDTO>
      >,
      closePosition: OverviewAPI.closePosition as IRestRequestGeneric<
        TClosePositionPayloadBinanceDTO,
        AxiosResponse<TClosePositionResponseDTO>
      >,
      closeAllPositions: OverviewAPI.closeAllPositions as IRestRequestGeneric<
        TCloseAllPositionsPayloadBinanceDTO,
        AxiosResponse<TCloseAllPositionsResponseDTO>
      >,
      setPositionMode: OverviewAPI.setPositionMode as IRestRequestGeneric<
        TSetPositionModePayloadDTO,
        AxiosResponse<TSetPositionModeResponseDTO>
      >,
      setPositionMargin: OverviewAPI.setPositionMargin as IRestRequestGeneric<
        TSetPositionMarginPayloadDTO,
        AxiosResponse<TSetPositionMarginResponseDTO>
      >,
      sellAsset: OverviewAPI.sellAsset as IRestRequestGeneric<
        TSellAssetPayloadUnion,
        AxiosResponse<TSetPositionModeResponseDTO>
      >,
    },
    leverage: {
      setLeverage: LeverageApi.setLeverage as IRestRequestGeneric<
        TSetLeveragePayloadBinance,
        AxiosResponse<TSetPositionModeResponseDTO>
      >,
      setMargin: LeverageApi.setMargin as IRestRequestGeneric<
        TSetMarginPayloadBinance,
        AxiosResponse<TSetMarginResponseBinance>
      >,
    },
  },
  preformatters: {
    apiKey: {
      createApiKey: (payload: TCreateApiKeyPayloadBinance) => payload,
    },
    overview: {
      cancelOpenOrder: (payload: TCancelOpenOrderPayloadUnion): TCancelOpenOrderPayloadBinanceDTO => ({
        accountType: payload.accountType,
        orderId: Number(payload.orderId),
        symbol: payload.symbol,
      }),
      cancelAllOpenOrders: (payload: TCancelAllOpenOrdersPayload): TCancelAllOpenOrdersPayloadBinanceDTO => ({
        accountType: payload.accountType,
        symbols: payload.orders.map(({ symbol }) => symbol),
      }),
      closePosition: (payload: TClosePositionPayload): TClosePositionPayloadBinanceDTO => ({
        accountType: payload.accountType,
        symbol: payload.symbol,
        positionAmt: payload.positionAmt,
        positionSide: payload.positionSide,
      }),
      closeAllPositions: (payload: TCloseAllPositionsPayload): TCloseAllPositionsPayloadBinanceDTO => ({
        accountType: payload.accountType,
        positions: payload.positions.map(p => ({
          symbol: p.symbol,
          positionAmt: p.positionAmt,
          positionSide: p.positionSide,
        })),
      }),
      setPositionMode: voidFunction,
      setPositionMargin: voidFunction,
      sellAsset: voidFunction,
    },
    leverage: {
      setLeverage: async () => {},
      setMargin: async () => {},
    },
  },
  normalizers: {
    apiKey: {
      createApiKey: (source: ApiKeyCreationDTO): ApiKeyCreationDTO => {
        return {
          ...source,
        }
      },
    },
    overview: {
      cancelOpenOrder: (
        source: TCancelOpenOrderResponseBinanceDTO,
        payload: TCancelOpenOrderPayloadUnion
      ): TCancelOpenOrderResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          orderId: payload.orderId,
          success: source.code === 200,
          msg: source.msg,
        }
      },
      cancelAllOpenOrders: (
        source: TCancelAllOpenOrdersResponseBinanceDTO,
        payload: TCancelAllOpenOrdersPayload
      ): TCancelAllOpenOrdersResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          success: source.code === 200,
          errors: [source.msg],
        }
      },
      closePosition: (source: TClosePositionResponseDTO, payload: TClosePositionPayload): TClosePositionResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          positionId: payload.positionId,
          success: source.code === 200,
          error: source.msg,
        }
      },
      closeAllPositions: (
        source: TCloseAllPositionsResponseDTO,
        payload: TCloseAllPositionsPayload
      ): TCloseAllPositionsResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          success: source.code === 200,
          error: source.msg,
        }
      },
      setPositionMode: voidFunction,
      setPositionMargin: voidFunction,
      sellAsset: voidFunction,
    },
    leverage: {
      setLeverage: (source: TSetLeverageResponseBinance, payload: TSetLeveragePayloadBinance): TSetLeverageResponse => {
        return {
          accountType: source.accountType,
          leverage: payload.leverage,
          symbol: payload.symbol,
          maxNotionalValue: source.maxNotionalValue,
          maxQty: source.maxQty,
        }
      },
      setMargin: (source: TSetMarginResponseBinance, payload: TSetMarginPayloadBinance): TSetMarginResponse => {
        return {
          accountType: source.accountType,
          symbol: payload.symbol,
          marginType: payload.marginType,
        }
      },
    },
  },
  checkers: {
    apiKey: {
      createApiKey: (payload: any): payload is TCreateApiKeyPayloadBinance => {
        return 'futuresEnabled' in payload
      },
    },
    overview: {
      cancelOpenOrder: (payload: TCancelOpenOrderPayloadBinanceDTO) => {
        return 'orderId' in payload
      },
      cancelAllOpenOrders: (payload: TCancelAllOpenOrdersPayloadBinanceDTO) => {
        return 'symbols' in payload
      },
      closePosition: (payload: TClosePositionPayloadBinanceDTO) => {
        return 'symbol' in payload
      },
      closeAllPositions: (payload: TCloseAllPositionsPayloadBinanceDTO) => {
        return 'positions' in payload
      },
      setPositionMode: (payload: any): payload is TSetPositionModePayloadDTO => {
        return OVERVIEW_ACCOUNTS.includes(payload?.accountType)
      },
      setPositionMargin: (payload: any): payload is TSetPositionModePayloadDTO => {
        return OVERVIEW_ACCOUNTS.includes(payload?.accountType)
      },
      sellAsset: (payload: any): payload is TSellAssetPayload => {
        return 'quantity' in payload
      },
    },
    leverage: {
      setLeverage: (payload: TSetLeveragePayloadUnion): payload is TSetLeveragePayloadBinance => {
        const BINANCE_ACCOUNTS_ARRAY: TAccountType[] = ['BINANCE_FUTURE', 'BINANCE_FUTURE_COIN']
        return BINANCE_ACCOUNTS_ARRAY.includes(payload.accountType)
      },
      setMargin: (payload: TSetMarginPayloadUnion): payload is TSetMarginPayloadBinance => {
        const BINANCE_ACCOUNTS_ARRAY: TAccountType[] = ['BINANCE_FUTURE', 'BINANCE_FUTURE_COIN']
        return BINANCE_ACCOUNTS_ARRAY.includes(payload.accountType)
      },
    },
  },
  caller: {
    apiKey: {
      createApiKey: async payload => {
        if (BinanceApiWrapper.checkers.apiKey.createApiKey(payload)) {
          const res = await BinanceApiWrapper.methods.apiKey.createApiKey(payload)
          return {
            ...res,
            data: BinanceApiWrapper.normalizers.apiKey.createApiKey(res.data),
          }
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
    overview: {
      cancelOpenOrder: async data => {
        const payload = BinanceApiWrapper.preformatters.overview.cancelOpenOrder(data)

        if (BinanceApiWrapper.checkers.overview.cancelOpenOrder(payload)) {
          const res = await BinanceApiWrapper.methods.overview.cancelOpenOrder(payload)
          return {
            ...res,
            data: BinanceApiWrapper.normalizers.overview.cancelOpenOrder(res.data, data),
          }
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      cancelAllOpenOrders: async data => {
        const payload = BinanceApiWrapper.preformatters.overview.cancelAllOpenOrders(data)

        if (BinanceApiWrapper.checkers.overview.cancelAllOpenOrders(payload)) {
          const res = await BinanceApiWrapper.methods.overview.cancelAllOpenOrders(payload)
          return {
            ...res,
            data: BinanceApiWrapper.normalizers.overview.cancelAllOpenOrders(res.data),
          }
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      closePosition: async data => {
        const payload = BinanceApiWrapper.preformatters.overview.closePosition(data)

        if (BinanceApiWrapper.checkers.overview.closePosition(payload)) {
          const res = await BinanceApiWrapper.methods.overview.closePosition(payload)
          return {
            ...res,
            data: BinanceApiWrapper.normalizers.overview.closePosition(res.data, data),
          }
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      closeAllPositions: async data => {
        const payload = BinanceApiWrapper.preformatters.overview.closeAllPositions(data)

        if (BinanceApiWrapper.checkers.overview.closeAllPositions(payload)) {
          const res = await BinanceApiWrapper.methods.overview.closeAllPositions(payload)
          return {
            ...res,
            data: BinanceApiWrapper.normalizers.overview.closeAllPositions(res.data, data),
          }
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      setPositionMode: async payload => {
        if (BinanceApiWrapper.checkers.overview.setPositionMode(payload)) {
          const res = await BinanceApiWrapper.methods.overview.setPositionMode(payload)
          return res
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      setPositionMargin: async payload => {
        if (BinanceApiWrapper.checkers.overview.setPositionMargin(payload)) {
          const res = await BinanceApiWrapper.methods.overview.setPositionMargin(payload)
          return res
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      sellAsset: async payload => {
        if (BinanceApiWrapper.checkers.overview.sellAsset(payload)) {
          const res = await BinanceApiWrapper.methods.overview.sellAsset(payload)
          return res
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
    leverage: {
      setLeverage: async data => {
        if (BinanceApiWrapper.checkers.leverage.setLeverage(data)) {
          const res = await BinanceApiWrapper.methods.leverage.setLeverage(data)
          return {
            ...res,
            data: BinanceApiWrapper.normalizers.leverage.setLeverage(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      setMargin: async data => {
        if (BinanceApiWrapper.checkers.leverage.setMargin(data)) {
          const res = await BinanceApiWrapper.methods.leverage.setMargin(data)
          return {
            ...res,
            data: BinanceApiWrapper.normalizers.leverage.setMargin(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
  },
}
