import { FC } from 'react'
import clsx from 'clsx'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { Button } from '@/ui/kit'
import { formatDate } from '@/utils/lib/formatDate'
import { useOrder } from '@/ui/organisms/Order/hooks/useOrder'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import s from './style.module.scss'
import { TOpenOrder } from '@/backend/models/OverviewDTO'
import { SideIndicator } from '@/ui/kit/SideIndicator'
import { ServicesOrderSide } from '@/utils/types/contracts.types'
import { FormatNumberDotWithTooltip } from '@/ui/molecules/FormatNumberDotWithTooltip/FormatNumberWithTooltip'

const OrdersTab: FC<Record<'order', TOpenOrder>> = ({ order }) => {
  const { executedQty, price, priceOutput = price, side, symbol, updateTime } = order
  const { amount, typeToName, closeOrderHandler, deleting } = useOrder(order)

  return (
    <div className={clsx(s.order, s.tab, { [s.deleted]: deleting })} data-testid={DataTestIds.OrderListItem}>
      <div className={s.orderTabWrapper}>
        <div className={s.noWrapContainer}>
          <div data-testid={`${DataTestIds.OrderSymbol}-${symbol}`}>{symbol}</div>
          <SideIndicator profit={side === ServicesOrderSide.OrderBuy} label={side} className={s.orderSideTab} />
        </div>
        <div className={s.quote} data-testid={`${DataTestIds.OrderType}-${symbol}`}>
          {typeToName}
        </div>
        <div className={s.description} data-testid={`${DataTestIds.OrderUpdateTime}-${symbol}`}>
          {formatDate(updateTime)}
        </div>
      </div>
      <div className={s.item}>
        <div data-testid={`${DataTestIds.OrderPrice}-${symbol}`}>
          <FormatNumberDotWithTooltip value={priceOutput} symbol={symbol} qtyChars={5} />
        </div>
        <div className={s.description}>{t({ message: 'Price', id: 'core.price' })}</div>
      </div>
      <div className={s.item}>
        <div data-testid={`${DataTestIds.OrderAmount}-${symbol}`}>
          <FormatNumberDotWithTooltip value={amount} symbol={symbol} qtyChars={5} />
        </div>
        <div className={s.description}>{t({ message: 'Size', id: 'core.size' })}</div>
      </div>
      <div className={s.item} data-testid={`${DataTestIds.OrderStatus}-${symbol}`}>
        <div className={s.wrapper}>
          {executedQty}/<FormatNumberDotWithTooltip value={amount} qtyChars={5} />
        </div>
        <div className={s.description}>{t({ message: 'Status', id: 'core.status' })}</div>
      </div>
      <Button.Primary
        onClick={closeOrderHandler}
        className={s.closeButton}
        leftIcon={<SVG.Additional.Close />}
        dataTestId={`${DataTestIds.CloseOrderButton}-${symbol}`}
      />
    </div>
  )
}

export { OrdersTab }
