import React from 'react'

interface Props {
  className?: string
}

export const ArrowDown = ({ className }: Props) => {
  return (
    <svg className={className} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00837 5.43795L10.2311 0.360588C10.5843 -0.0640313 11.2148 -0.121969 11.6394 0.23118C12.064 0.58433 12.122 1.21484 11.7688 1.63945L6.77873 7.63943C6.3794 8.11957 5.64255 8.12029 5.24229 7.64092L0.232425 1.64095C-0.12155 1.21702 -0.064839 0.586401 0.359092 0.232426C0.783023 -0.121549 1.41364 -0.064838 1.76762 0.359093L6.00837 5.43795Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
