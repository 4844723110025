import { SVG } from '@/assets/svg'
import { FC } from 'react'

import styles from './style.module.scss'

export const PlayBtn: FC<{ onClick?: Function; title: string }> = props => {
  const { onClick, title } = props
  return (
    <span
      onClick={() => {
        onClick?.()
      }}
      className={styles.playBtn}
    >
      <span className={styles.icon}>
        <SVG.OtherIcons.VideoPlay />
      </span>
      {title}
    </span>
  )
}
