import { FC } from 'react'

export const Lightning: FC = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="30" fill="url(#paint0_radial_15260_53010)" />
      <path
        d="M31.0346 15.3573C30.9158 13.7201 28.7856 13.17 27.8889 14.5449L17.1573 31C16.4137 32.1403 17.2319 33.6507 18.5932 33.6507H24.8594C25.758 33.6507 26.5041 34.3447 26.5692 35.241L27.2512 44.6425C27.3699 46.2798 29.5001 46.8299 30.3969 45.4549L41.1284 28.9999C41.8721 27.8596 41.0539 26.3491 39.6925 26.3491H33.4264C32.5277 26.3491 31.7816 25.6552 31.7166 24.7589L31.0346 15.3573Z"
        fill="#FAFAFA"
      />
      <defs>
        <radialGradient
          id="paint0_radial_15260_53010"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(40.8151) scale(79.2788 1047.68)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export const LightningSquare: FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="15" fill="url(#paint0_radial_20295_206351)" />
      <path
        d="M20.4845 11.8299C20.4182 10.9163 19.2296 10.6094 18.7293 11.3766L12.7414 20.558C12.3264 21.1942 12.783 22.037 13.5426 22.037H17.0389C17.5403 22.037 17.9566 22.4242 17.9929 22.9244L18.3734 28.1701C18.4397 29.0837 19.6283 29.3906 20.1287 28.6234L26.1166 19.442C26.5315 18.8058 26.075 17.963 25.3154 17.963H21.819C21.3176 17.963 20.9013 17.5758 20.865 17.0756L20.4845 11.8299Z"
        fill="#FAFAFA"
      />
      <defs>
        <radialGradient
          id="paint0_radial_20295_206351"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(40.8151) scale(52.8525 698.452)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
