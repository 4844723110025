import { ReactElement } from 'react'
import { SVG } from '../../../assets/svg'
import { Status } from '../../../backend/types'
import { t } from '@lingui/macro'

/** mapped object to get icon and text for Status */
export const statusVariants: Record<Status, { icon: ReactElement; name: string }> = {
  loading: {
    icon: <SVG.Status.Pending />,
    name: t({
      id: 'core.status.pending',
      message: `Pending`,
    }),
  },
  succeeded: {
    icon: <SVG.Status.Completed />,
    name: t({
      id: 'core.status.completed',
      message: `Completed`,
    }),
  },
  failed: {
    icon: <SVG.Status.Failed />,
    name: t({
      id: 'core.status.failed',
      message: `Failed`,
    }),
  },
  idle: {
    icon: <>add icon if sometimes needed</>,
    name: t({
      id: 'core.status.idle',
      message: `Idle`,
    }),
  },
}
