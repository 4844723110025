export const GooglePlay = () => (
  <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.169922 0.372559V9.33994V18.6276L9.77783 9.33994L0.169922 0.372559Z" fill="#5CDADD" />
    <path d="M12.2759 6.80984L8.46472 4.63207L0.169922 0.372559L9.77783 9.33994L12.2759 6.80984Z" fill="#BDECC4" />
    <path d="M0.169922 18.6276L8.46472 14.0478L12.2759 11.87L9.77783 9.33997L0.169922 18.6276Z" fill="#DC68A1" />
    <path
      d="M12.2754 6.80981L9.77734 9.33988L12.2754 11.8699L16.1506 9.62811C16.3748 9.5 16.3748 9.21177 16.1506 9.08367L12.2754 6.80981Z"
      fill="#FFCA96"
    />
  </svg>
)
