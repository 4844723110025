import { FC, useState } from 'react'
import styles from './style.module.scss'
import { Toggle } from '@/ui/kit'

type ReasonType = {
  name: string
  active: boolean
}

type Props = {
  reason: ReasonType
  onChange: (item: ReasonType) => void
}

export const ReasonItem: FC<Props> = props => {
  const [active, setActive] = useState(props.reason.active)

  function handleChange() {
    const newStatus = !active

    setActive(newStatus)
    props.onChange({
      name: props.reason.name,
      active: newStatus,
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{props.reason.name}</div>
      <div>
        <Toggle active={active} onChange={handleChange} />
      </div>
    </div>
  )
}
