import React from 'react'

interface BurnProps {
  className?: string
}

const Burn: React.FC<BurnProps> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_12884_10892)" />
      <path
        d="M23.0066 30.1269C25.9349 29.0743 28 26.4309 28 22.6343C28 19.8187 26.1656 18.0514 24.3749 16.3262C22.6676 14.6813 21 13.0746 21 10.6343C18.4056 13.2287 19.1352 16.3217 19.6434 18.476C20.0166 20.0583 20.2704 21.1342 19 21.1342C16 21.1342 17 16.1038 17.5 15.6038C15.5 15.6038 12 19.1343 12 22.6343C12 26.6047 14.2585 29.3139 17.4009 30.2614C16.8412 29.7213 16.5 29.0035 16.5 28.2158C16.5 26.7616 17.6627 25.5459 19.2164 25.2463C20.7105 24.8396 21.7957 23.6166 21.7957 22.171C21.7957 21.9409 21.7682 21.7164 21.7159 21.5C23.1641 22.6271 24.5 24.2727 24.5 26.1055C24.5 27.0274 24.2488 28.911 23.0066 30.1269Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_12884_10892" x1="5" y1="3.5" x2="34" y2="38" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CD52F9" />
          <stop offset="1" stopColor="#FF656E" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { Burn }
