export const Pending = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
    <rect width={61.5} height={61.5} x={1.25} y={1.25} stroke="#676573" strokeWidth={1.5} rx={20.75} />
    <path
      stroke="#676573"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M22 32s3.636-7 10-7 10 7 10 7-3.636 7-10 7-10-7-10-7Z"
    />
    <path
      stroke="#676573"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M32 34.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
    />
  </svg>
)
