import clsx from 'clsx'
import { FC, memo } from 'react'
import { t } from '@lingui/macro'
import empty from '@/assets/png/empty-list-v2.png'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

export type EmptyListPropsType = {
  title?: string
  label: string
  className?: string
  image?: string
}

const EmptyList: FC<EmptyListPropsType> = props => {
  const { title, label, className, image } = props
  const imageClassname = image ? '' : style.image

  return (
    <div className={clsx(style.root, className)} data-testid={DataTestIds.EmptySearchResult}>
      {title && <div className={style.title}>{title}</div>}
      <div className={style.label}>{label}</div>
      <img src={image || empty} alt={t({ message: 'Empty List', id: 'core.emptyList' })} className={imageClassname} />
    </div>
  )
}

export default memo(EmptyList)
