import { FC } from 'react'

type LowSideProps = {
  className?: string
}

export const LowSide: FC<LowSideProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={20} fill="none" className={className}>
    <path
      fill="#FB5E5E"
      fillRule="evenodd"
      d="M9.687 8.769a.75.75 0 1 0-1.5 0v2.319L3.685 6.586a.75.75 0 0 0-1.06 1.061l4.5 4.501H4.808a.75.75 0 1 0 0 1.5h4.13a.746.746 0 0 0 .75-.75v-4.13Z"
      clipRule="evenodd"
    />
  </svg>
)
