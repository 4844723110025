import clsx from 'clsx'
import { memo } from 'react'
import { TabsType } from '../../../pages/Transactions'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useMobileSizeDetect } from '@/utils'

type TransactionsTabProps = {
  /** tab text */
  text: string

  /** tab id */
  tabId: number

  /** active tab id setter */
  setActiveTab: (type: TabsType) => void

  /** active tab id */
  activeTab: TabsType | string

  /** tab onClick handler */
  onClick?: () => void

  /** one of predefined strings */
  type?: TabsType

  /** className */
  className?: string
}

/** FIXME: what is the name of this component? */
export const TransactionsTab = memo<TransactionsTabProps>(
  ({ text, setActiveTab, activeTab, onClick, type, className }) => {
    const [isMobile] = useMobileSizeDetect()

    const choiceTabHandler = () => {
      setActiveTab(type!)
      onClick?.()
    }

    const isActive = activeTab === type

    return (
      <div
        onClick={choiceTabHandler}
        className={clsx(isMobile && style.mobile, style.transactionsTab, className)}
        data-testid={DataTestIds.TransactionTab}
      >
        <span
          className={clsx(style.text, {
            [style.activeText]: isActive,
          })}
        >
          {text}
        </span>
      </div>
    )
  }
)
