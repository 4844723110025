import { FC } from 'react'
import clsx from 'clsx'
import s from './style.module.scss'

interface ITwoColumnPageProps {
  className?: string
}

const TwoColumnPage: FC<ITwoColumnPageProps> = props => {
  const { children, className } = props

  return <div className={clsx(s.root, className)}>{children}</div>
}

export { TwoColumnPage }
