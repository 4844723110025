import { useCallback } from 'react'
import { getNameWithExchangeId } from '../../helpers/getNameWithExchangeId'
import { E_TableRowExchange, E_TableRowMarketType, TableExchangeRow } from '@/redux/trades/trades.types'
import { isPresent } from '../../helpers/isPresent'
import { ServicesApiKey, ServicesUserWithRelations } from '@/utils/types/contracts.types'

type ExchangeWithoutID = Omit<ServicesApiKey, 'id'>

type FormattedExchanges = Record<number, ExchangeWithoutID>

export const useExchangeById = (
  me: ServicesUserWithRelations | null,
  tableFilterExchangeIds: any,
  tableFilterMarketTypeIds: any
) => {
  const getExchangeById = useCallback(
    (id?: number) => {
      const exchanges: FormattedExchanges = {}

      me?.api_keys_list?.forEach((exchange: ServicesApiKey) => {
        if (isPresent(exchange.id)) {
          const { id, ...rest } = exchange
          exchanges[id] = rest
        }
      })
      if (!isPresent(id)) {
        return null
      }

      const exchange = exchanges[id]

      if (!exchange?.exchange_id) {
        return null
      }
      const exchangeName = getNameWithExchangeId(
        exchange.exchange_id,
        tableFilterExchangeIds
      ) as unknown as E_TableRowExchange

      const marketType = getNameWithExchangeId(
        exchange.exchange_id,
        tableFilterMarketTypeIds
      ) as unknown as E_TableRowMarketType

      const filteredExchange: TableExchangeRow = {
        exchangeName,
        marketType,
        exchangeId: exchange.exchange_id,
      }

      return filteredExchange
    },
    [me]
  )

  return getExchangeById
}
