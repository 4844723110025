import { t } from '@lingui/macro'
import { FC, memo, useMemo } from 'react'
import { SVG } from '@/assets/svg'
import { formatNumber, useToggle } from '@/utils'
import { formatDate } from '@/utils/lib/formatDate'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import style from './style.module.scss'
import clsx from 'clsx'
import { Status } from './Status'
import { formatText } from '@/ui/molecules/FormatNumberDotWithTooltip/utils'
import { HistoryItemType, OperationType } from '@/redux/okxAffiliate/okxAffiliate.types'
import { OKX_AFFILIATE_CURRENCY } from '@/pages/OKXAfiliate/consts'

type Props = {
  data: HistoryItemType
}

const HistoryItem: FC<Props> = ({ data }) => {
  const [isModalOpen, toggleModalOpen] = useToggle(false)

  const quantity = formatNumber(data.amount)
  const sign = data.type === OperationType.cashback ? '+' : '-'

  const dateOutputTransaction = useMemo(() => {
    return formatDate(data.time)
  }, [data.time])

  const cardTitle =
    data.type === OperationType.cashback
      ? t({
          id: 'okxAffiliate.historyName.savedComission',
          comment: 'Saved commission',
        })
      : t({
          id: 'okxAffiliate.historyName.withdrawal',
          comment: 'Withdrawal',
        })

  return (
    <>
      <div onClick={() => toggleModalOpen()} className={style.history}>
        <div className={style.left}>
          {data.type === OperationType.cashback ? <SVG.CryptoIcons.TT /> : <SVG.CryptoIcons.Withdraw />}
          <div className={style.titleWrap}>
            <div className={style.title}>{cardTitle}</div>
            <div className={style.date}>{dateOutputTransaction}</div>
          </div>
        </div>
        {data.type === OperationType.payment && data?.status && (
          <div className={style.statusWrap}>
            <Status status={data.status} />
          </div>
        )}
        <div className={style.item}>
          <span
            className={clsx(style.new, {
              [style.newMinus]: data.type === OperationType.payment,
            })}
          >
            {sign} {quantity}&nbsp; {OKX_AFFILIATE_CURRENCY}
          </span>
        </div>
      </div>

      <InteractiveModal isOpen={isModalOpen}>
        {data.type === OperationType.cashback ? (
          <>
            <InteractiveModalParts.HeaderIcon>
              <SVG.CryptoIcons.TT />
            </InteractiveModalParts.HeaderIcon>
            <InteractiveModalParts.Header
              text={t({
                id: 'okxAffiliate.popupHistory.headerCashback',
                comment: 'cashback',
              })}
            />
            <div className={style.popupAmount}>
              {data.amount} {OKX_AFFILIATE_CURRENCY}
            </div>
            <InteractiveModalParts.Table
              left={t({
                id: 'exchangeHistoryBybit.transactionTime',
                comment: 'Transaction time',
              })}
              right={dateOutputTransaction}
            />
            <InteractiveModalParts.Table
              left={t({
                id: 'okxAffiliate.popupHistory.percentCashback',
                comment: 'Процент кешбэка',
              })}
              right={data.details?.cashbackPercent ? data.details.cashbackPercent : '-'}
            />
            <InteractiveModalParts.Table
              left={t({
                id: 'okxAffiliate.popupHistory.fee',
                comment: 'Торговая комиссия',
              })}
              right={data.details?.fee ? data.details.fee : '-'}
            />
          </>
        ) : (
          <>
            <InteractiveModalParts.HeaderIcon>
              <SVG.CryptoIcons.Withdraw />
            </InteractiveModalParts.HeaderIcon>
            <InteractiveModalParts.Header
              text={t({
                id: 'okxAffiliate.popupHistory.headerWithdrawal',
                comment: 'withdrawal',
              })}
            />
            <div className={style.popupAmount}>
              {data.amount} {OKX_AFFILIATE_CURRENCY}
            </div>
            <div className={style.popupFee}>
              {t({
                id: 'okxAffiliate.general.tigerFee',
                comment: ' Tiger.com fee:',
              })}
              {data.details.fee ? data.details.fee : ' - '}
              {OKX_AFFILIATE_CURRENCY}
            </div>
            {data.status && (
              <div className={style.popupStatus}>
                <Status status={data.status} />
              </div>
            )}
            <InteractiveModalParts.Table
              left={t({
                id: 'exchangeHistoryBybit.transactionTime',
                comment: 'Transaction time',
              })}
              right={dateOutputTransaction}
            />
            <InteractiveModalParts.TableCopy
              left={t({
                id: 'okxAffiliate.popupHistory.account',
                comment: 'Account',
              })}
              right={data?.details.address ? data?.details.address : '-'}
              isShortenView={true}
            />
            <InteractiveModalParts.Table
              left={t({
                id: 'okxAffiliate.popupHistory.network',
                comment: 'Network',
              })}
              right={data?.details.network ? data?.details.network : '-'}
            />
          </>
        )}
        <InteractiveModalParts.Button
          text={t({
            id: 'core.close',
            comment: 'Close',
          })}
          variant={ButtonVariant.White}
          onClick={() => toggleModalOpen()}
        />
      </InteractiveModal>
    </>
  )
}

export default memo(HistoryItem)
