import React, { FC, memo } from 'react'

interface PropsType {
  marginRight?: string
  fill?: string
}

export const Dot: FC<PropsType> = memo(({ marginRight, fill }) => {
  return (
    <svg
      style={{ marginRight: marginRight }}
      width="5"
      height="6"
      viewBox="0 0 5 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="3" r="2.5" fill={fill ? fill : '#676573'} />
    </svg>
  )
})
