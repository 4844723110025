import { isIOS } from '../platform'
import { preventScrollMobileSafari } from './helpers/preventScrollMobileSafari'
import { preventScrollStandard } from './helpers/preventScrollStandart'

export interface PreventScrollOptions {
  isDisabled?: boolean
}

let preventScrollCount = 0
let restore: () => void

export function preventScroll(options: PreventScrollOptions = {}): () => void {
  const { isDisabled } = options

  if (!isDisabled) {
    return () => {}
  }

  preventScrollCount++

  if (preventScrollCount === 1) {
    if (isIOS()) {
      restore = preventScrollMobileSafari()
    } else {
      restore = preventScrollStandard()
    }
  }

  return () => {
    preventScrollCount--

    if (preventScrollCount === 0) {
      restore()
    }
  }
}
