import { t } from '@lingui/macro'

export const getRiskManagerErrors = (): Partial<Record<string, { message: string; fields: string[] }>> => {
  const errors = {
    '9003': {
      message: t({
        id: 'TeamErrors.permissions',
        comment: 'Permissions insufficient.',
      }),
      fields: ['permissions'],
    },
    '9006': {
      message: t({
        id: 'TeamErrors.userHandle',
        comment: 'User handle already in use.',
      }),
      fields: ['userHandle'],
    },
    '9004': {
      message: t({
        id: 'TeamErrors.riskManager',
        comment: 'Risk manager is disabled.',
      }),
      fields: [],
    },
    '9001': {
      message: t({
        id: 'TeamErrors.riskManagerNotAvailable',
        comment: 'risk manager is not available',
      }),
      fields: [],
    },
    '9012': {
      message: t({
        id: 'TeamErrors.userNotMember',
        comment: 'user is not a team member',
      }),
      fields: [],
    },
    '9013': {
      message: t({
        id: 'TeamErrors.risMangerNotAvailableForUserTeam',
        comment: 'risk manager is not available for users team',
      }),
      fields: [],
    },
  }

  return errors
}

export const getMemberCashbackError = (): Partial<Record<string, { message: string; fields: string[] }>> => {
  const errors = {
    '40208': {
      message: t({
        id: 'teamMembers.Cashback.errorMsg',
        comment: 'Invalid cashback percent for team member',
      }),
      fields: [],
    },
  }

  return errors
}
