import { FC, memo } from 'react'
import LabelWithQuestion from '../../../molecules/LabelWithQuestion'
import style from './style.module.scss'
import { t } from '@lingui/macro'

export const SkeletonWallet = memo(() => {
  return (
    <div className={style.skeletonWallet}>
      <SkeletonWalletLeftBar />
      <div className={style.between} />
      <SkeletonWalletRightBar />
    </div>
  )
})

export const SkeletonWalletLeftBar: FC = () => {
  return (
    <div className={style.skeletonWalletLeftBar}>
      <span className={style.label}>{t({ id: 'mainPage.accountBalance.title', message: 'Account balance' })}</span>
      <div className={style.wrap}>
        <div className={style.itemWrap}>
          <div className={style.item} />
        </div>
        <div className={style.buttonWrap}>
          <div className={style.button}>{t({ id: 'core.history', message: 'History' })}</div>
        </div>
        <div className={style.items}>
          <div className={style.itemWrap}>
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
          </div>
        </div>
      </div>
      <div className={style.bottomBlock} />
    </div>
  )
}

export const SkeletonWalletRightBar: FC = () => {
  return (
    <div className={style.skeletonWalletRightBar}>
      <SkeletonWalletTop />
      <SkeletonWalletBottom />
    </div>
  )
}

export const SkeletonWalletTop: FC = () => {
  return (
    <div className={style.top}>
      <LabelWithQuestion
        hintText={t({
          message: 'This is an approximate balance of all assets',
          id: 'mainPage.topBar.approximateTitle',
        })}
        className={style.labelWithQ}
        label={t({ message: 'Estimated balance', id: 'mainPage.topBar.balanceTitle' })}
      />
      <div className={style.topItems}>
        <div className={style.leftPart}>
          <div className={style.itemWrapper}>
            <div className={style.item} />
          </div>
          <div className={style.circles}>
            <div className={style.circleWrap}>
              <div className={style.circle} />
            </div>
            <div className={style.circleWrap}>
              <div className={style.circle} />
            </div>
            <div className={style.circleWrap}>
              <div className={style.circle} />
            </div>
          </div>
        </div>
        <div className={style.center} />
        <div className={style.rightPart}>
          <div className={style.itemWrap}>
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const SkeletonWalletBottom: FC = () => {
  return (
    <div className={style.bottom}>
      <div className={style.title}>Orders</div>
      <div className={style.bottomContent}>
        <div className={style.blockWrap}>
          <div className={style.left}>
            <div className={style.circle} />
            <div className={style.itemWrapper}>
              <div className={style.item} />
              <div className={style.item} />
            </div>
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
            <div className={style.item} />
          </div>
        </div>
      </div>
      <div className={style.bottomContent}>
        <div className={style.blockWrap}>
          <div className={style.left}>
            <div className={style.circle} />
            <div className={style.itemWrapper}>
              <div className={style.item} />
              <div className={style.item} />
            </div>
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
            <div className={style.item} />
          </div>
        </div>
      </div>
      <div className={style.bottomContent}>
        <div className={style.blockWrap}>
          <div className={style.left}>
            <div className={style.circle} />
            <div className={style.itemWrapper}>
              <div className={style.item} />
              <div className={style.item} />
            </div>
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
            <div className={style.item} />
          </div>
          <div className={style.itemWrap}>
            <div className={style.item} />
            <div className={style.item} />
          </div>
        </div>
      </div>
    </div>
  )
}
