import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { leverageAsyncActions } from './leverage.actions'
import { LeverageType, TOkxLeverageRecord, TSetLeveragePayloadBinance } from '@/redux/leverage/leverage.types'
import { initialState } from './leverage.state'
import {
  normalizeBrackets,
  normalizeLeverages,
  findMaxPosition,
  findMaxLeverage,
  toggleActiveTickers,
} from './leverage.utils'
import { EOkxPositionMode, EOkxPositionSide, TOkxMargin } from '@/services/okx'
import { MARGIN_OKX_TO_MARGIN, MARGIN_TO_MARGIN_BYBIT } from '@/core/mappers/values'
import { RiskLimitV5 } from 'bybit-api'
import { EMarginBybit } from '@/backend/models/LeverageDTO'

export const slice = createSlice({
  name: `[Tiger Trade Account Leverage]`,
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload
    },
    setType(state, action: PayloadAction<LeverageType>) {
      state.leverageType = action.payload
      state.groupEditing.active = []
    },
    toggleTickers(state, action: PayloadAction<string[]>) {
      state.groupEditing.active = toggleActiveTickers(state.groupEditing.active, action.payload)
      const tickers = Object.values(state.tickers[state.leverageType]).filter(t =>
        state.groupEditing.active.includes(t.symbol)
      )

      state.groupEditing.maxLeverage = findMaxLeverage(tickers)
    },
    clearTickers(state) {
      state.groupEditing.active = []
      state.groupEditing.leverage = 1
      state.groupEditing.margin = undefined
    },
    setBusy(state, action: PayloadAction<boolean>) {
      state.groupEditing.busy = action.payload
    },
    setPositionBusy(state, action: PayloadAction<boolean>) {
      state.groupEditing.positionBusy = action.payload
    },
    setLeverageBusy(state, action: PayloadAction<boolean>) {
      state.groupEditing.leverageBusy = action.payload
    },
    setLeverageListOkx(state, action: PayloadAction<string[]>) {
      const instruments = action.payload

      state.tickersOkx.swap = instruments.reduce<Record<string, TOkxLeverageRecord>>((acc, inst) => {
        return {
          ...acc,
          [inst]: {
            cross: {},
            isolated: {},
            net: {},
            symbol: inst,
          },
        }
      }, {})
    },
    setBybitMarginType(state, action: PayloadAction<EMarginBybit>) {
      state.bybitMarginType = action.payload
    },
  },
  extraReducers: builder => {
    // const messageLeverageOn = t({ id: 'leveragePage.message.leverageOn', message: 'Leverage on' })
    // const messageHasBeenChanged = t({ id: 'leveragePage.message.hasBeenChanged', message: 'has been changed to' })
    // const messageErrorChangingLeverage = t({
    //   id: 'leveragePage.errorChangingLeverage',
    //   message: 'Error changing leverage on',
    // })
    // const messageTo = t({ id: 'leveragePage.message.to', message: 'to' })
    // const messageMarginChanged = t({ id: 'leveragePage.message.marginChanged', message: 'Margin type on' })
    // const messageErrorMarginChanged = t({
    //   id: 'leveragePage.message.marginChangedError',
    //   message: 'Error changing margin type on',
    // })

    builder
      // GetUsdMLeveragesTC
      .addCase(leverageAsyncActions.GetUsdMLeveragesTC.fulfilled, (state, action) => {
        const [risks, leverages, exchangeInfo] = action.payload
        const brackets = normalizeBrackets(leverages)
        state.brackets.usdM = brackets
        state.tickers.usdM = normalizeLeverages(risks, leverages, brackets, exchangeInfo)
        state.usdMStatus = 'succeeded'
      })
      .addCase(leverageAsyncActions.GetUsdMLeveragesTC.pending, (state, action) => {
        state.usdMStatus = 'loading'
      })
      .addCase(leverageAsyncActions.GetUsdMLeveragesTC.rejected, (state, action) => {
        state.usdMStatus = 'failed'
      })

      // GetCoinMLeveragesTC
      .addCase(leverageAsyncActions.GetCoinMLeveragesTC.fulfilled, (state, action) => {
        const [risks, leverages, exchangeInfo] = action.payload
        const brackets = normalizeBrackets(leverages)
        state.brackets.coinM = brackets
        state.tickers.coinM = normalizeLeverages(risks, leverages, brackets, exchangeInfo)
        state.coinMStatus = 'succeeded'
      })
      .addCase(leverageAsyncActions.GetCoinMLeveragesTC.pending, (state, action) => {
        state.coinMStatus = 'loading'
      })
      .addCase(leverageAsyncActions.GetCoinMLeveragesTC.rejected, (state, action) => {
        state.coinMStatus = 'failed'
      })
      .addCase(leverageAsyncActions.GetOkxAccountConfigurationTC.fulfilled, (state, action) => {
        state.positionMode = action.payload[0].posMode as EOkxPositionMode
      })
      .addCase(leverageAsyncActions.GetOkxLeverageForInstrumentTC.fulfilled, (state, action) => {
        const leverageGroups = action.payload
        leverageGroups.forEach(leverageArray => {
          leverageArray.forEach(leverage => {
            const instId = leverage.instId
            const mgnMode = leverage.mgnMode as TOkxMargin
            const posSide = leverage.posSide as EOkxPositionSide

            const stateLeverage = state.tickersOkx.swap[instId][mgnMode][posSide]

            state.tickersOkx.swap[instId][mgnMode][posSide] = {
              symbol: instId,
              ...(!!stateLeverage ? stateLeverage : {}),
              leverage: Number(leverage.lever),
              margin: MARGIN_OKX_TO_MARGIN[mgnMode],
              side: posSide,
            }
          })
        })
      })
      .addCase(leverageAsyncActions.GetOkxLeverageEstimatedInfoTC.fulfilled, (state, action) => {
        const leveragesInfoArray = action.payload
        const paramsArray = action.meta.arg
        paramsArray.forEach((params, index) => {
          const instId = params.instId
          const mgnMode = params.mgnMode as TOkxMargin
          const posSide = params.posSide as EOkxPositionSide

          const response = leveragesInfoArray[index][0]
          const stateLeverage = state.tickersOkx.swap[instId][mgnMode][posSide]

          if (!response) {
            state.tickersOkx.swap[instId][mgnMode][posSide] = {
              ...stateLeverage,
              symbol: instId,
              maxLeverage: 0,
              maxPosition: 0,
            }

            return
          }

          state.tickersOkx.swap[instId][mgnMode][posSide] = {
            symbol: instId,
            ...(!!stateLeverage ? stateLeverage : {}),
            maxLeverage: Number(response.maxLever),
            maxPosition: Number(response.estMaxAmt),
          }
        })
      })
      .addCase(leverageAsyncActions.SetLeverageTC.fulfilled, (state, action) => {
        const response = action.payload
        const instId = response.symbol
        if (response.accountType === 'BINANCE_FUTURE' || response.accountType === 'BINANCE_FUTURE_COIN') {
          const args: TSetLeveragePayloadBinance = action.meta?.arg as TSetLeveragePayloadBinance

          const { symbol, leverage } = args
          const { maxNotionalValue, maxQty } = action.payload

          if (response.accountType === 'BINANCE_FUTURE' && state.tickers.usdM[symbol]) {
            state.tickers.usdM[symbol].leverage = leverage
            state.tickers.usdM[symbol].maxPosition = findMaxPosition(
              maxNotionalValue,
              leverage,
              state.brackets.usdM[symbol]
            )
          } else if (response.accountType === 'BINANCE_FUTURE_COIN' && state.tickers.coinM[symbol]) {
            state.tickers.coinM[symbol].leverage = leverage
            state.tickers.coinM[symbol].maxPosition = findMaxPosition(maxQty, leverage, state.brackets.coinM[symbol])
          }
        } else if (response.accountType === 'OKX_TRADING') {
          const mgnMode = response.mgnMode as TOkxMargin
          const posSide = response.posSide as EOkxPositionSide

          const stateLeverage = state.tickersOkx.swap[instId][mgnMode][posSide]

          if (posSide) {
            state.tickersOkx.swap[instId][mgnMode][posSide] = {
              symbol: instId,
              ...(!!stateLeverage ? stateLeverage : {}),
              leverage: response.leverage,
            }
          } else {
            Object.entries(state.tickersOkx.swap[instId][mgnMode]).forEach(([key, ticker]) => {
              const positionSide = key as EOkxPositionSide
              const stateLeverageCurrent = state.tickersOkx.swap[instId][mgnMode][positionSide]
              state.tickersOkx.swap[instId][mgnMode][positionSide] = {
                ...(!!stateLeverageCurrent ? stateLeverageCurrent : {}),
                symbol: instId,
                leverage: response.leverage,
              }
            })
          }
        } else if (response.accountType === 'BYBIT_UNIFIED') {
          const savedTicker = state.tickersBybit.linear[response.symbol]
          const riskLimitArray = state.bybitRiskLimitBySymbol.linear[response.symbol]

          // @ts-ignore
          const leverage = action.meta.arg.buyLeverage as number
          const positionWithMaxLimit = riskLimitArray
            .filter(riskLimit => {
              return Number(riskLimit.maxLeverage) >= leverage
            })
            .sort((a, b) => {
              return Number(a.maxLeverage) < Number(b.maxLeverage) ? -1 : 1
            })[0]

          state.tickersBybit.linear[response.symbol] = {
            ...savedTicker,
            leverage: leverage,
            maxPosition: Number(positionWithMaxLimit.riskLimitValue),
          }
        }
      })
      .addCase(leverageAsyncActions.SetMarginTypeTC.fulfilled, (state, action) => {
        state.bybitMarginType = MARGIN_TO_MARGIN_BYBIT[action.payload.marginType]
      })
      .addCase(leverageAsyncActions.GetBybitMarketTickers.fulfilled, (state, action) => {
        const data = action.payload.result.list.reduce(
          (
            acc: {
              [key: string]: {
                symbol: string
              }
            },
            item: { symbol: string }
          ) => {
            acc[item.symbol] = {
              symbol: item.symbol,
            }
            return acc
          },
          {}
        )

        state.tickersBybit.linear = data
      })
      .addCase(leverageAsyncActions.GetBybitAccountInfoTC.fulfilled, (state, action) => {
        state.bybitMarginType = action.payload.result.marginMode as EMarginBybit
      })
      .addCase(leverageAsyncActions.getBybitLeverageInfo.fulfilled, (state, action) => {
        const [leverageResult, riskLimitResult] = action.payload

        const riskData = riskLimitResult.reduce((acc, listItem) => {
          const riskLimitBySymbol = acc[listItem.symbol] || []
          return {
            ...acc,
            [listItem.symbol]: [...riskLimitBySymbol, listItem],
          }
        }, {} as Record<string, RiskLimitV5[]>)

        state.bybitRiskLimitBySymbol.linear = riskData

        const position = leverageResult.result.list[0]
        const riskIsLowestRisk = riskData[position.symbol].find(riskLimit => riskLimit.isLowestRisk === 1)

        state.tickersBybit.linear[position.symbol] = {
          symbol: position.symbol,
          leverage: Number(position.leverage),
          maxPosition: Number(position.riskLimitValue),
          maxLeverage: Number(riskIsLowestRisk?.maxLeverage),
        }
      })
  },
})

export const leverageActions = {
  ...slice.actions,
  ...leverageAsyncActions,
}

export const leverageReducer = slice.reducer
