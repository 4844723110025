import { FC, useMemo } from 'react'
import { t } from '@lingui/macro'
import { useOverview } from '@/utils/hooks/useOverview'
import s from './style.module.scss'

const WalletOrderAndPositionCounter: FC = () => {
  const { allOpenOrders, allPositions } = useOverview()

  const hasOrders = useMemo(() => allOpenOrders.length > 0, [allOpenOrders])
  const hasPositions = useMemo(() => allPositions.length > 0, [allPositions])

  if (!hasOrders && !hasPositions) {
    return null
  }

  return (
    <div className={s.root}>
      {hasOrders && (
        <div>
          {t({ message: 'Orders', id: 'wallet.orders' })}: {allOpenOrders.length}
        </div>
      )}
      {hasPositions && (
        <div>
          {t({ message: 'Positions', id: 'wallet.positions' })}: {allPositions.length}
        </div>
      )}
    </div>
  )
}

export { WalletOrderAndPositionCounter }
