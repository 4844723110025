import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Voucher } from '@/backend/models/VoucherDTO'
import { voucherAsyncActions } from './voucher.actions'
import { initialState } from './voucher.state'

export const slice = createSlice({
  name: `[Tiger Trade Account voucher]`,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(voucherAsyncActions.GetVoucherOverviewTC.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const now = new Date()

        const vouchers = action.payload.reduce(
          (res, voucher) => {
            if (new Date(voucher.endDate) > now) {
              res.active.push(voucher)
            } else {
              res.disabled.push(voucher)
            }

            return res
          },
          { active: [] as Voucher[], disabled: [] as Voucher[] }
        )

        state.active = vouchers.active
        state.disabled = vouchers.disabled
      })
      .addCase(voucherAsyncActions.GetVoucherOverviewTC.rejected, state => {
        state.status = 'failed'
      })
  },
})

export const voucherActions = {
  ...slice.actions,
  ...voucherAsyncActions,
}

export const voucherReducer = slice.reducer
