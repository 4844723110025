interface IWindowsProps {
  round?: boolean
  className?: string
}

export const Windows = (props: IWindowsProps) => {
  const { round, className } = props

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath={round ? 'url(#clip0_10340_102947)' : undefined}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 0C0.447715 0 0 0.447715 0 1V7.46614C0 8.01843 0.447715 8.46614 1 8.46614H7.46614C8.01843 8.46614 8.46614 8.01843 8.46614 7.46614V1C8.46614 0.447715 8.01843 0 7.46614 0H1ZM10.5345 0C9.9822 0 9.53449 0.447715 9.53449 1V7.46614C9.53449 8.01843 9.9822 8.46614 10.5345 8.46614H17.0006C17.5529 8.46614 18.0006 8.01843 18.0006 7.46614V1C18.0006 0.447715 17.5529 0 17.0006 0H10.5345ZM0 10.5339C0 9.98162 0.447715 9.5339 1 9.5339H7.46614C8.01843 9.5339 8.46614 9.98162 8.46614 10.5339V17C8.46614 17.5523 8.01843 18 7.46614 18H1C0.447715 18 0 17.5523 0 17V10.5339ZM10.5345 9.5339C9.9822 9.5339 9.53449 9.98162 9.53449 10.5339V17C9.53449 17.5523 9.9822 18 10.5345 18H17.0006C17.5529 18 18.0006 17.5523 18.0006 17V10.5339C18.0006 9.98162 17.5529 9.5339 17.0006 9.5339H10.5345Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10340_102947">
          <rect width="18" height="18" rx="3" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  )
}
