import { FC, Fragment } from 'react'
import style from './style.module.scss'
import { DiaryCalendarCard, DiaryDayCardItem } from '@/ui/molecules'
import { DayDiary } from '@/utils/types/contracts.types'
import { DATE_FORMAT, formatDate, getMonthName } from '@/utils/lib/formatDate'
import {
  DEFAULT_VALUE_PERCENT_SYMBOL,
  DEFAULT_VALUE_WITH_SYMBOL,
  DEFAULT_VALUE_WITHOUT_SYMBOL,
  DEFAULT_LEVERAGE_VALUE,
  handleRound,
} from '@/ui/organisms/DiaryRightBar/components/WeekCard/WeekCard'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import { removeTrailingPeriod } from '@/ui/organisms/DiaryRightBar/utils/removeTrailingPeriod'
import {
  calculateInversePercentage,
  calculateLongPercentage,
  calculateWinPercentage,
  formatLeverage,
} from '../WeekResult/utils'

type DayCardProps = {
  data: DayDiary[]
  date: string
  lastDayOfWeek: string
}

export const DayCard: FC<DayCardProps> = ({ data, date, lastDayOfWeek }) => {
  const month = removeTrailingPeriod(getMonthName(lastDayOfWeek, DATE_FORMAT.MONTH_SHORT))

  const day = new Date(date).getDate().toString()
  const dayOfWeek = formatDate(date, DATE_FORMAT.DAY_OF_WEEK)

  const showData = () => {
    if (data.length) {
      return (
        <div className={style.cardItemsContainer}>
          {data.map((cardItem, index) => (
            <Fragment key={index}>
              <DiaryDayCardItem
                text={t({
                  id: 'trades.summary.profit',
                  comment: 'netProfitTitle',
                })}
                isCountPercent
                sourceValue={Number(cardItem.net_profit)}
                value={cardItem.net_profit ? handleRound(cardItem.net_profit) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
              />
              <DiaryDayCardItem
                text={t({
                  id: 'trades.summary.commissions',
                  comment: 'commissions',
                })}
                value={cardItem.commission ? handleRound(cardItem.commission) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
              />
              <DiaryDayCardItem
                text={t({
                  id: 'trades.summary.volume',
                  comment: 'volume',
                })}
                value={cardItem.volume ? handleRound(cardItem.volume) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
              />
              <DiaryDayCardItem
                text={t({
                  id: 'trades.summary.trans',
                  comment: 'transactions',
                })}
                value={cardItem.count ? cardItem.count : DEFAULT_VALUE_WITHOUT_SYMBOL}
              />
              <DiaryDayCardItem
                text={t({
                  id: 'trades.summary.percentage',
                  comment: 'winPercentage',
                })}
                value={calculateWinPercentage(cardItem.count, cardItem.win_count)}
              />
              <DiaryDayCardItem
                text={
                  <Trans
                    id="trades.summary.short"
                    values={{
                      value: calculateInversePercentage(cardItem.count, cardItem.long_count),
                    }}
                  />
                }
                value={calculateLongPercentage(cardItem.long_count, cardItem.count)}
              />
              <DiaryDayCardItem
                text={t({
                  id: 'trades.summary.leverage',
                  comment: 'leverage',
                })}
                value={formatLeverage(cardItem.leverage)}
              />
            </Fragment>
          ))}
        </div>
      )
    } else {
      return (
        <div className={style.cardItemsContainer}>
          <DiaryDayCardItem
            text={t({
              id: 'trades.summary.profit',
              comment: 'netProfitTitle',
            })}
            value={DEFAULT_VALUE_WITH_SYMBOL}
          />
          <DiaryDayCardItem
            text={t({
              id: 'trades.summary.commissions',
              comment: 'commissions',
            })}
            value={DEFAULT_VALUE_WITH_SYMBOL}
          />
          <DiaryDayCardItem
            text={t({
              id: 'trades.summary.volume',
              comment: 'volume',
            })}
            value={DEFAULT_VALUE_WITH_SYMBOL}
          />
          <DiaryDayCardItem
            text={t({
              id: 'trades.summary.trans',
              comment: 'transactions',
            })}
            value={DEFAULT_VALUE_WITHOUT_SYMBOL}
          />
          <DiaryDayCardItem
            text={t({
              id: 'trades.summary.percentage',
              comment: 'winPercentage',
            })}
            value={DEFAULT_VALUE_PERCENT_SYMBOL}
          />
          <DiaryDayCardItem
            text={
              <Trans
                id="trades.summary.short"
                values={{
                  value: DEFAULT_VALUE_PERCENT_SYMBOL,
                }}
              />
            }
            value={DEFAULT_VALUE_PERCENT_SYMBOL}
          />
          <DiaryDayCardItem
            text={t({
              id: 'trades.summary.leverage',
              comment: 'leverage',
            })}
            value={DEFAULT_LEVERAGE_VALUE}
          />
        </div>
      )
    }
  }

  return (
    <div className={style.card}>
      <div className={style.calendarContainer}>
        <DiaryCalendarCard day={day} month={month} />
        <div className={style.day}>{dayOfWeek}</div>
      </div>
      {showData()}
    </div>
  )
}
