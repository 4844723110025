import { t } from '@lingui/macro'
import clsx from 'clsx'
import React, { FC, Suspense } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { EROUTER_HISTORY_PARAMS, urls } from '@/router/urls'
import { Loader } from '@/ui/kit/Loader'
import styles from './styles.module.scss'
import { ConvertHistory } from '../BinanceConvert/ConvertHistory/ConvertHistory'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'

export type HistoryStateType = {
  isProfileRedirect?: boolean
}

const HistoryTransactions = React.lazy(() => import('@/ui/organisms/HistoryTransactions'))
const HistoryTrade = React.lazy(() => import('@/ui/organisms/HistoryTrade'))

export const History: FC = () => {
  const { historyType } = useParams()
  const { exchangeType } = useExchageType()

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <>
          <NavLink
            className={({ isActive }) => {
              return clsx(styles.title, {
                [styles.active]: isActive,
              })
            }}
            to={urls.historyTrade}
          >
            {t({
              id: 'historyTrade.title',
              comment: 'Exchange trading history',
            })}
          </NavLink>

          <NavLink
            className={({ isActive }) => {
              return clsx(styles.title, {
                [styles.active]: isActive,
              })
            }}
            to={urls.historyTransactions}
          >
            {t({
              id: 'historyTransactions.title',
              comment: 'Transactions history',
            })}
          </NavLink>
          {(exchangeType === EXCHANGES_TYPE.BINANCE_BROKER_FUTURE || exchangeType === EXCHANGES_TYPE.BINANCE_VIP) && (
            <NavLink
              className={({ isActive }) => {
                return clsx(styles.title, {
                  [styles.active]: isActive,
                })
              }}
              to={urls.historyConvert}
            >
              {t({
                id: 'historyConvert.title',
                comment: 'Convert history',
              })}
            </NavLink>
          )}
        </>
      </div>

      {historyType === EROUTER_HISTORY_PARAMS.transactions && (
        <Suspense fallback={<Loader />}>
          <HistoryTransactions />
        </Suspense>
      )}

      {historyType === EROUTER_HISTORY_PARAMS.trade && (
        <Suspense fallback={<Loader />}>
          <HistoryTrade />
        </Suspense>
      )}

      {historyType === EROUTER_HISTORY_PARAMS.convert && (
        <Suspense fallback={<Loader />}>
          <ConvertHistory />
        </Suspense>
      )}
    </div>
  )
}
