import { IFuturesCoinmOrdersControllerPublicParams } from '@tigertrade/binance-ts'
import {
  BINANCE_QUEUE_INTERVAL,
  binanceProxyRestUrls,
  binanceRestUrls,
  binanceWsProxyUrls,
  binanceWsUrls,
} from './shared'
import { ESecurityType, exchangeApi } from '@/backend/api/exchange'
import { cacheManager } from '@/services/cacheManager/cacheManager'

export const getCoinmParamsSource = (
  isBinanceActive: boolean,
  accessToken: string,
  isVip?: boolean
): IFuturesCoinmOrdersControllerPublicParams => {
  return {
    wsQueueInterval: BINANCE_QUEUE_INTERVAL,
    ordersListParams: {
      config: {
        removeInactive: true,
      },
    },
    restClientParams: {
      axiosPublicConfig: {
        baseURL: isBinanceActive ? binanceRestUrls.COIN_FUTURE : binanceProxyRestUrls.COIN_FUTURE,
        ...(!isBinanceActive && {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }),
      },
      axiosPrivateConfig: {
        baseURL: isBinanceActive ? binanceRestUrls.COIN_FUTURE : binanceProxyRestUrls.COIN_FUTURE,
        headers: {
          'X-Exchange-Type': isVip ? 'BINANCE_VIP' : 'BINANCE_BROKER_FUTURE',
          Authorization: `Bearer ${accessToken}`,
        },
      },
      interceptorsConfig: {
        getApiKey: async () => {
          const res = await exchangeApi.getReadOnlyKey()
          return res.data
        },
        signRequest: exchangeApi.postSignWithReadOnly,
      },
    },
    wsParams: {
      baseUrl: isBinanceActive ? binanceWsUrls.COIN_FUTURE : `${binanceWsProxyUrls.COIN_FUTURE}`, //?access_token=${accessToken}
      refreshFunction: async (lk: string) => {
        const res = await exchangeApi.binanceProxyPut<{ status: number }>(
          '/coin/dapi/v1/listenKey',
          { listenKey: lk },
          ESecurityType.USER_STREAM
        )
        return { status: res.status }
      },
      getListenKeyFunction: async () => {
        const res = await exchangeApi.binanceProxyPost<{ listenKey: string }>(
          `/coin/dapi/v1/listenKey`,
          {},
          ESecurityType.USER_STREAM
        )
        return { listenKey: res.listenKey }
      },
      _autoReconnect: false,
      cacheInstanceName: isVip ? '_COIN_FUTURE-u-vip' : '_COIN_FUTURE-u',
    },
    cacheManager: cacheManager,
  }
}
