import { t } from '@lingui/macro'
import { enqueueSnackbar } from 'notistack'
import { referralsActions } from '@/redux/referrals/referrals.slice'
import { useActions } from '@/utils'

type BonusRates = {
  bonusBinanceCashbackPercent: string
  bonusBybitCashbackPercent: string
  bonusPeriodDays?: string
  isPermanent: boolean
}

const useApi = () => {
  const { CreateReferralCodeTC } = useActions(referralsActions)

  const createCode = async (
    { bonusBinanceCashbackPercent, bonusBybitCashbackPercent, bonusPeriodDays, isPermanent }: BonusRates,
    onSuccess: () => void
  ) => {
    const response = await CreateReferralCodeTC({
      bonusPeriodDays: bonusPeriodDays ? Number(bonusPeriodDays) : undefined,
      bonusBinanceCashbackPercent: Number(bonusBinanceCashbackPercent) / 100,
      bonusBybitCashbackPercent: Number(bonusBybitCashbackPercent) / 100,
      isPermanent: isPermanent,
    })

    if ('error' in response) {
      // @ts-ignore
      let errorCode = response?.payload?.codes?.[0]
      let errorMessage = ''

      switch (Number(errorCode)) {
        case 40005:
          errorMessage = t({
            id: 'referralPage.codeAddError-40005',
            message: 'Custom codes numer limit reached (max 100)',
          })
          break
        case 40006:
          errorMessage = t({ id: 'referralPage.codeAddError-40006', message: 'Invalid period (min 1, max 30)' })
          break
        case 40007:
          errorMessage = t({
            id: 'referralPage.codeAddError-40007',
            message: 'Invalid reduction rate (min 0.25, max 0.30)',
          })
      }

      enqueueSnackbar({
        message: `${t({ id: 'referralPage.codeAddError', message: 'Error creating code' })}. ${errorMessage}`,
        variant: 'error',
      })
    } else {
      onSuccess()

      enqueueSnackbar({
        message: t({ id: 'referralPage.codeCreated', message: 'Custom code created' }),
        variant: 'success',
      })
    }
  }

  return {
    createCode,
  }
}

export { useApi }
