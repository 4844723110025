export const Key = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1819 7.75145C9.94734 8.986 8.17745 9.36838 6.61514 8.87675L5.2797 10.207L4.35168 9.26809C4.09718 9.0106 3.68214 9.00818 3.42465 9.26267C3.16717 9.51716 3.16474 9.9322 3.41923 10.1897L4.34788 11.1293L4.35087 11.1323L3.78551 11.6955C3.58885 11.903 3.18462 12.0341 2.88964 11.9904L1.57861 11.8156C1.1416 11.761 0.748295 11.3459 0.682743 10.9198L0.50794 9.60874C0.464239 9.32468 0.606267 8.92045 0.802921 8.71287L3.62163 5.89416C3.14092 4.33185 3.51238 2.56197 4.74693 1.32742C6.51682 -0.442472 9.40108 -0.442472 11.1819 1.32742C12.9518 3.08638 12.9518 5.97064 11.1819 7.75145ZM8.13745 2.73264C7.24435 2.73264 6.52034 3.45665 6.52034 4.34976C6.52034 5.24286 7.24435 5.96687 8.13745 5.96687H8.16166C9.05477 5.96687 9.77877 5.24286 9.77877 4.34976C9.77877 3.45665 9.05477 2.73264 8.16166 2.73264H8.13745Z"
      fill="#FAFAFA"
    />
  </svg>
)
