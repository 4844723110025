import clsx from 'clsx'
import { memo, useCallback } from 'react'
import style from './style.module.scss'

type TabProps = {
  /** text */
  text: string

  /** tab id */
  id: string

  /** activeTab Id  setter*/
  setActiveTab: (id: string) => void

  /** active tab Id */
  isActive: boolean

  /** tab onClick handler */
  onClick?: () => void

  /** className */
  className?: string

  dataTestId?: string
}

/** Simple Tab */
export const Tab = memo<TabProps>(({ text, setActiveTab, isActive, onClick: action, className, dataTestId, id }) => {
  const choiceTabHandler = useCallback(() => {
    setActiveTab(id)
    action?.()
  }, [id])

  return (
    <div onClick={choiceTabHandler} className={clsx(style.tab, className)} data-testid={dataTestId}>
      <span className={clsx(style.text, isActive && style.activeText)}>{text}</span>
    </div>
  )
})
