import { FC } from 'react'

export const VideoPlay: FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64506 6.53536C7.56721 6.58261 7.5 6.67733 7.5 6.81489V11.1853C7.5 11.3224 7.56703 11.4172 7.64495 11.4646C7.71995 11.5102 7.80382 11.5142 7.88628 11.4623L11.3587 9.27691C11.445 9.22263 11.5 9.12239 11.5 9.00012C11.5 8.87785 11.445 8.77761 11.3587 8.72332L7.88649 6.5381C7.80471 6.48645 7.72023 6.48973 7.64506 6.53536ZM8.41945 5.69196C7.52965 5.13034 6.5 5.84896 6.5 6.81489V11.1853C6.5 12.1494 7.5277 12.8699 8.41913 12.3085L11.8913 10.1233C12.7029 9.61252 12.7029 8.38772 11.8913 7.87698L8.41945 5.69196Z"
        fill="url(#paint0_radial_15605_665)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        fill="url(#paint1_radial_15605_665)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_15605_665"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(40.8151) scale(23.7836 314.304)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_15605_665"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(40.8151) scale(23.7836 314.304)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
