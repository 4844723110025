import { FC, useMemo } from 'react'
import { t } from '@lingui/macro'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import s from './style.module.scss'

interface IVoucherAddErrorProps {
  errorCode?: string
  isOpen: boolean
  onClose: () => void
}

const VoucherAddError: FC<IVoucherAddErrorProps> = props => {
  const { errorCode, isOpen, onClose } = props

  const message = useMemo(() => {
    switch (Number(errorCode)) {
      case 10003:
        return t({ id: 'voucher.add.error.notFount', message: 'Your voucher not found' })
      case 50004:
        return t({ id: 'voucher.add.error.exist', message: 'Your voucher code already exist' })
      case 50007:
        return t({
          id: 'voucher.add.error.notActivated',
          message: 'Your voucher for extra commission cannot be activated',
        })
      case 50008:
        return t({ id: 'voucher.add.error.expired', message: 'Your voucher for extra commission has expired' })
      case 50009:
        return t({ id: 'voucher.add.error.notStarted', message: 'Your voucher for extra commission is not started' })
      case 500010:
        return t({ id: 'voucher.add.error.bigger', message: 'Your have bigger voucher' })
      case 500012:
        return t({ id: 'voucher.add.error.noMoreLeft', message: 'No more activations left for voucher' })
      case 500016:
        return t({ id: 'voucher.add.error.alreadyActive', message: 'Voucher already activated' })
      case 500017:
        return t({ id: 'voucher.add.error.disabled', message: 'Non activalable voucher' })
      default:
        return t({ id: 'voucher.add.error.default', message: 'Can`t add voucher' })
    }
  }, [errorCode])

  return (
    <InteractiveModal isOpen={isOpen} className={s.root}>
      <InteractiveModalParts.Icon icon="expired" className={s.icon} />
      <InteractiveModalParts.SubHeader text={t({ id: 'voucher.add.error.heading', message: 'Voucher error' })} />
      <InteractiveModalParts.Explanation text={message} className={s.text} />
      <InteractiveModalParts.Button
        text={t({ id: 'button.close', message: 'Close' })}
        variant={ButtonVariant.Primary}
        onClick={onClose}
      />
    </InteractiveModal>
  )
}

export { VoucherAddError }
