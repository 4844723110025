import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import nameof from 'ts-nameof.macro'
import { PartnersApi } from '@/backend/api/partners.api'
import { EPartners, TPartnerDataDTO, TPartnerEntityDTO } from './partner.types'

export const GetPartnerDataTC = createAsyncThunk<TPartnerDataDTO, EPartners, ThunkError>(
  'GetPartnerDataTC',
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: PartnersApi.getPartnerData,
        payload,
      },
      thunkAPI
    )
  }
)

export const GetPartnersListTC = createAsyncThunk<TPartnerEntityDTO, void, ThunkError>(
  nameof(PartnersApi.getPartnersList),
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: PartnersApi.getPartnersList,
        payload,
      },
      thunkAPI
    )
  }
)

export const ClaimPartnerRewardTC = createAsyncThunk<void, EPartners, ThunkError>(
  nameof(PartnersApi.postClaimPartnerReward),
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: PartnersApi.postClaimPartnerReward,
        payload,
      },
      thunkAPI
    )
  }
)
