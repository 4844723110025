import { useCallback, useEffect, useRef, useState } from 'react'

export type TPollingConditionalFunc = (...args: any[]) => boolean

export const usePolling = (
  requestFn: Function,
  conditionalFn: TPollingConditionalFunc,
  pollingIntervalTime: number
) => {
  const pollingInterval = useRef<ReturnType<typeof setInterval>>()
  const [isPollingActive, setIsPollingActive] = useState<boolean>(false)
  const conditionalFnInner = useRef(conditionalFn)

  const stopPolling = useCallback(() => {
    clearInterval(pollingInterval.current)
    setIsPollingActive(false)
  }, [])

  const startPolling = useCallback(() => {
    if (isPollingActive) return
    stopPolling()
    setIsPollingActive(true)
    pollingInterval.current = setInterval(() => {
      if (conditionalFnInner.current()) requestFn()
    }, pollingIntervalTime)
  }, [requestFn, pollingIntervalTime, isPollingActive])

  useEffect(() => {
    return () => {
      stopPolling()
    }
  }, [])

  return { startPolling, stopPolling, isPollingActive }
}
