import { FC } from 'react'

export const Key: FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="url(#paint0_linear_15433_3598)" />
      <g filter="url(#filter0_d_15433_3598)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.0165 22.6283C25.1645 24.4803 22.5094 25.0539 20.1657 24.3164L18.1625 26.3119L16.7703 24.9033C16.3885 24.517 15.7659 24.5134 15.3796 24.8952C14.9933 25.2769 14.9897 25.8996 15.3715 26.2858L16.7646 27.6953L16.7691 27.6999L15.9209 28.5449C15.6259 28.8563 15.0194 29.053 14.5769 28.9874L12.6102 28.7252C11.9546 28.6432 11.3646 28.0204 11.2663 27.3812L11.004 25.4145C10.9385 24.9884 11.1515 24.382 11.4466 24.0706L15.675 19.8421C14.9539 17.4984 15.5111 14.8433 17.3631 12.9913C20.0182 10.3362 24.345 10.3362 27.0165 12.9913C29.6716 15.63 29.6716 19.9568 27.0165 22.6283ZM22.4493 15.099C21.1095 15.099 20.0234 16.1851 20.0234 17.5249C20.0234 18.8647 21.1095 19.9508 22.4493 19.9508H22.4856C23.8254 19.9508 24.9115 18.8647 24.9115 17.5249C24.9115 16.1851 23.8254 15.099 22.4856 15.099H22.4493Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_15433_3598"
          x="2.99213"
          y="5"
          width="34.0157"
          height="34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.733333 0 0 0 0 0.619608 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15433_3598" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15433_3598" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_15433_3598"
          x1="5.27786e-07"
          y1="40"
          x2="35.084"
          y2="-3.92643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A175FF" />
          <stop offset="0.471642" stopColor="#AC90EA" />
          <stop offset="1" stopColor="#FFCB9A" />
        </linearGradient>
      </defs>
    </svg>
  )
}
