import clsx from 'clsx'
import { t } from '@lingui/macro'
import { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { SVG } from '@/assets/svg'
import { InputSearch, InputSize } from '@/ui/kit'
import { typedMemo, useMobileSizeDetect, useOutsideClick } from '@/utils'
import { GroupedOptions, SelectOption, SelectProps, SelectSize, SelectVariant } from './Select.types'
import { convertOptionsToObject } from './Select.utils'
import s from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { SheetDialog } from '../SheetDialog'
import { useScrollToError } from '@/utils/hooks'

const EMPTY_CALLBACK = () => {}

const SelectComponent = typedMemo(<T extends string>(props: SelectProps<T>) => {
  const defaultPlaceholder = t({ message: 'Select', id: 'core.select' })

  const {
    label,
    size = SelectSize.Medium,
    variant = SelectVariant.Lined,
    placeholder = defaultPlaceholder,
    options: allOptions,
    value,
    disabled,
    description,
    withSearch,
    errorMessage: error,
    className,
    dropdownClassName: classNameDropdown,
    onChange,
    onSelect,
    toggleClassname,
    labelClassname,
    buttonMode = false,
    trackBy,
  } = props

  const optionsAsObject = useMemo(() => convertOptionsToObject(allOptions), [allOptions])

  const [isMobile] = useMobileSizeDetect()
  const [groupedOptions, setGroupedOptions] = useState<GroupedOptions<T>[]>([])
  const [selectedOption, setSelectedOption] = useState<SelectOption<T> | null>(value ? optionsAsObject[value] : null)
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (selectedOption?.value !== value) {
      setSelectedOption(value ? optionsAsObject[value] : null)
    }
  }, [selectedOption, optionsAsObject, value])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])
  useLayoutEffect(() => {
    // for search
    const filteredOptions = allOptions.filter(option =>
      (option.search || option.value || '').toLowerCase().includes(search.toLowerCase())
    )

    //TODO: separate group keys and titles to handle ReactNode as group
    // groups
    const groups: Record<string, SelectOption<T>[]> = {}
    const withoutGroups: SelectOption<T>[] = []
    let groupedTitle: Record<string, string | ReactNode> = {}
    filteredOptions.forEach(option => {
      if (option.group) {
        if (!groups[option.group as string]) {
          groups[option.group as string] = []
        }
        groups[option.group as string].push(option)
        groupedTitle[option.group as string] = option.element
      } else {
        withoutGroups.push(option)
      }
    })
    setGroupedOptions([
      ...Object.keys(groups).map(key => ({ title: groupedTitle[key] as string, options: groups[key] })),
      ...(withoutGroups.length ? [{ options: withoutGroups }] : []),
    ])
  }, [search, allOptions])

  // TODO: Это очень плохо
  const ref = useOutsideClick<HTMLDivElement>(isMobile ? EMPTY_CALLBACK : handleClose)

  useScrollToError({ nodeRef: ref, isValid: !error })

  const handleToggle = useCallback(() => {
    if (disabled) return
    setIsOpen(!isOpen)
  }, [isOpen, disabled])

  const handleChange = useCallback(
    (item: SelectOption<T>) => () => {
      if (item.disabled) return

      setSelectedOption(item)
      handleClose()
      onChange(item.value)
      onSelect?.(item)
    },
    [onChange, handleClose, onSelect]
  )

  const previewJSX = useMemo(() => {
    if (buttonMode) {
      return null
    }
    if (selectedOption) {
      return (
        <div className={s.selectedOption}>
          {selectedOption.initialShowedLabel || selectedOption.selected || selectedOption.label}
        </div>
      )
    }
    return <div className={s.placeholder}>{placeholder}</div>
  }, [selectedOption, buttonMode, placeholder])

  return (
    <div
      ref={ref}
      className={clsx(
        isMobile && s.mobile,
        buttonMode && s.likeButton,
        s.root,
        s[size],
        s[variant],
        { [s.open]: isOpen, [s.error]: error, [s.disabled]: disabled },
        className
      )}
      data-testid={props.dataTestId}
    >
      <div
        className={clsx(isMobile && s.mobile, s.toggle, toggleClassname, {
          [s.customized]: toggleClassname,
        })}
        onClick={handleToggle}
      >
        {label && <div className={clsx(s.label, labelClassname)}>{label}</div>}

        <div className={s.toggleInner}>
          {previewJSX}
          <SVG.Arrows.ArrowDown className={s.toggleChevron} />
        </div>
      </div>

      {!isMobile && isOpen && (
        <div className={clsx(s.dropdown, classNameDropdown)} data-testid={DataTestIds.SelectorDropdown}>
          {withSearch && (
            <InputSearch
              value={search}
              setValue={setSearch}
              sizeSearch="small"
              size={InputSize.Small}
              placeholder={t({ message: 'Search', id: 'core.search' })}
              containerClassName={s.search}
              dataTestId={props.dataTestId && `${props.dataTestId}-search`}
            />
          )}

          {!groupedOptions.length && (
            <div className={s.noOptions}>{t({ message: 'No options', id: 'core.noOptions' })}</div>
          )}

          {groupedOptions.map(group => {
            return (
              <div className={s.group} key={group.title ?? 'default'}>
                {!!group.title && <div className={s.groupTitle}>{group.title}</div>}

                {group.options.map(option => {
                  const chosenOption = trackBy && selectedOption ? trackBy(selectedOption) : selectedOption?.value
                  const currentOption = trackBy ? trackBy(option) : option.value
                  const active = chosenOption === currentOption

                  return (
                    <div
                      key={option.value}
                      onClick={handleChange(option)}
                      className={clsx(s.option, { [s.active]: active, [s.disabled]: option.disabled })}
                      data-testid={DataTestIds.SelectorDropdownCell}
                    >
                      <div className={clsx(s.optionContent, { [s.disabled]: option.disabled })}>
                        <div>{option.label}</div>
                      </div>
                      <div className={s.rightIcon}>{option.rightIcon}</div>
                      {!option.disabled && <SVG.OtherIcons.Ok className={s.optionChevron} />}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      )}

      {isMobile && (
        <SheetDialog
          label={placeholder}
          isOpened={isOpen}
          onClose={handleClose}
          className={s.mobile}
          staticContent={
            withSearch && (
              <InputSearch
                value={search}
                setValue={setSearch}
                sizeSearch="small"
                size={InputSize.Small}
                placeholder={t({ message: 'Search', id: 'core.search' })}
                containerClassName={s.search}
                dataTestId={props.dataTestId && `${props.dataTestId}-search`}
              />
            )
          }
        >
          {!groupedOptions.length && (
            <div className={s.noOptions}>{t({ message: 'No options', id: 'core.noOptions' })}</div>
          )}

          {groupedOptions.map(group => {
            return (
              <div className={s.group} key={group.title ?? 'default'}>
                {!!group.title && <div className={s.groupTitle}>{group.title}</div>}

                {group.options.map(option => {
                  const chosenOption = trackBy && selectedOption ? trackBy(selectedOption) : selectedOption?.value
                  const currentOption = trackBy ? trackBy(option) : option.value
                  const active = chosenOption === currentOption

                  return (
                    <div
                      key={currentOption}
                      onClick={handleChange(option)}
                      className={clsx(s.option, { [s.active]: active })}
                      data-testid={DataTestIds.SelectorDropdownCell}
                    >
                      <div className={clsx(s.optionContent, { [s.disabledMobile]: option.disabled })}>
                        <div>{option.label}</div>
                      </div>
                      <div className={s.rightIcon}>{option.rightIcon}</div>
                      {!option.disabled && <SVG.OtherIcons.Ok className={s.optionChevron} />}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </SheetDialog>
      )}

      {(error || description) && (
        <div className={s.info}>
          {error && (
            <div className={s.errorMessage} data-testid={`${props.dataTestId}-error`}>
              {error}
            </div>
          )}
          {description && (
            <div className={s.description} data-testid={`${props.dataTestId}-dsc`}>
              {description}
            </div>
          )}
        </div>
      )}
    </div>
  )
})

const Select = Object.assign(SelectComponent, {
  Size: SelectSize,
  Variant: SelectVariant,
})

export { Select }
