import { useEffect, useState } from 'react'
import { ViewportSize, getViewportSize } from '../lib/viewportSize/helpers/getViewportSize'
import subscribeToViewprotSize from '../lib/viewportSize'

interface ViewportSizeProps {
  isDisabled?: boolean
}

export function useViewportSize({ isDisabled = false }: ViewportSizeProps = {}): ViewportSize {
  const [size, setSize] = useState(() => getViewportSize())

  useEffect(() => {
    if (isDisabled) {
      return
    }

    const unsub = subscribeToViewprotSize(newSize => {
      setSize(prevSize => {
        if (newSize.width === prevSize.width && newSize.height === prevSize.height) {
          return prevSize
        }

        return newSize
      })
    })

    return () => unsub()
  }, [])

  return size
}
