import { EROUTER_TEAM_PARAMS, urls } from '@/router/urls'
import React, { FC, Suspense, memo, useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import styles from './style.module.scss'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { Loader } from '@/ui/kit/Loader'
import { useActions, useAppSelector } from '@/utils'
import { teamAsyncActions } from '@/redux/team/team.actions'

const TeamMembers = React.lazy(() => import('@/ui/organisms/TeamMembers'))
const TeamRequest = React.lazy(() => import('@/ui/organisms/TeamRequests'))

const TeamRightBar: FC = () => {
  const { teamType } = useParams()
  const { GetManagerRequestsTC, GetManagerMembersTC } = useActions(teamAsyncActions)

  const {
    managerRequestStatus,
    changeCashbackRequestStatus,
    deleteMemberStatus,
    managerMembersListSearch,
    changeManagerDefaultCashbackStatus,
  } = useAppSelector(state => state.team)

  useEffect(() => {
    if (teamType === 'requests' && managerRequestStatus === 'succeeded') {
      GetManagerRequestsTC({
        page: 0,
        size: 20,
      })
    } else if (
      teamType === 'members' &&
      (changeCashbackRequestStatus === 'succeeded' ||
        deleteMemberStatus === 'succeeded' ||
        changeManagerDefaultCashbackStatus === 'succeeded')
    ) {
      GetManagerMembersTC({
        page: 0,
        size: 20,
        search: encodeURIComponent(managerMembersListSearch),
      })
    }
  }, [
    GetManagerRequestsTC,
    teamType,
    managerRequestStatus,
    GetManagerMembersTC,
    changeCashbackRequestStatus,
    deleteMemberStatus,
    managerMembersListSearch,
    changeManagerDefaultCashbackStatus,
  ])

  return (
    <div className={styles.rightBar}>
      <div className={styles.header}>
        <NavLink
          className={({ isActive }) => {
            return clsx(styles.title, {
              [styles.active]: isActive,
            })
          }}
          to={urls.teamMembers}
        >
          {t({
            id: 'teamMembers.title',
            message: 'Members',
          })}
        </NavLink>
        <NavLink
          className={({ isActive }) => {
            return clsx(styles.title, {
              [styles.active]: isActive,
            })
          }}
          to={urls.teamRequest}
        >
          {t({
            id: 'teamRequests.title',
            message: 'Requests',
          })}
        </NavLink>
      </div>

      {teamType === EROUTER_TEAM_PARAMS.members && (
        <Suspense fallback={<Loader />}>
          <TeamMembers />
        </Suspense>
      )}
      {teamType === EROUTER_TEAM_PARAMS.requests && (
        <Suspense fallback={<Loader />}>
          <TeamRequest />
        </Suspense>
      )}
    </div>
  )
}

export default memo(TeamRightBar)
