import React from 'react'

export const TTSquare = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="35" height="35" rx="10" fill="white" />
      <rect width="35" height="35" rx="10" fill="url(#paint0_radial_9615_2268)" />
      <path
        d="M17.0393 13.525L11.2826 9.62476H23.6543L17.8973 13.525C17.6379 13.7007 17.2986 13.7007 17.0393 13.525Z"
        fill="white"
      />
      <path
        d="M27.0686 19.1704V15.8019L24.4778 17.5577C24.0543 17.8448 23.8004 18.3247 23.8004 18.8383V22.2067L26.3912 20.4509C26.8147 20.1639 27.0686 19.6839 27.0686 19.1704Z"
        fill="white"
      />
      <path
        d="M18.0142 23.424L21.6941 27.1248H13.2485L16.9284 23.424C17.2282 23.1225 17.7144 23.1225 18.0142 23.424Z"
        fill="white"
      />
      <path
        d="M11.1356 22.2027V18.8343C11.1356 18.3207 10.8817 17.8407 10.4582 17.5537L7.87402 15.8023V19.1708C7.87402 19.6844 8.12792 20.1643 8.55143 20.4514L11.1356 22.2027Z"
        fill="white"
      />
      <path
        d="M27.0686 12.9936V9.62513L19.5315 14.7332C18.8433 15.1996 18.4307 15.9796 18.4307 16.8142V20.1146C18.4307 20.5242 18.5925 20.917 18.8805 21.2066L21.8857 24.2288V23.8012H21.8845V18.3512C21.8845 17.7104 22.2023 17.1114 22.7329 16.752L26.3912 14.2742C26.8147 13.9871 27.0686 13.5071 27.0686 12.9936Z"
        fill="white"
      />
      <path
        d="M16.5113 20.1152C16.5113 20.5248 16.3495 20.9176 16.0615 21.2072L13.0562 24.2294V19.9414L13.0515 19.9381V19.3112L13.0562 19.3145V18.3519C13.0562 17.7113 12.7386 17.1123 12.2083 16.7529L8.55143 14.2746C8.12792 13.9875 7.87402 13.5076 7.87402 12.994V9.62554L15.4105 14.7332C16.0987 15.1996 16.5113 15.9796 16.5113 16.8142V20.1152Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_9615_2268"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(40.8151) scale(46.246 611.146)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
