import { FC, memo, useState } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { Button, InteractiveModal } from '@/ui/kit'
import { FormAddKey } from './components/FormAddKey'
import { FormRemoveKey } from './components/FormRemoveKey'
import { useActions, useAppSelector } from '@/utils'
import { SVG } from '@/assets/svg'
import { FormatNumberDotWithTooltip } from '@/ui/molecules/FormatNumberDotWithTooltip/FormatNumberWithTooltip'
import clsx from 'clsx'
import { okxAffiliateActions } from '@/redux/okxAffiliate/okxAffiliate.slice'

const KeyContent: FC = () => {
  const { google } = useAppSelector(state => state.profile)
  const { userStatus, isOpenAddKeyPopup } = useAppSelector(state => state.okxAffiliate)
  const { openAddKeyPopup, closeAddKeyPopup } = useActions(okxAffiliateActions)
  const [isRemoveKeyPopup, setIsRemoveKeyPopup] = useState(false)

  function openRemoveKeyPopup() {
    setIsRemoveKeyPopup(true)
  }

  function closeRemoveKeyPopup() {
    setIsRemoveKeyPopup(false)
  }

  function openAddKeyModal() {
    openAddKeyPopup()
  }

  function closeAddKeyModal() {
    closeAddKeyPopup()
  }

  const isKeyActive = userStatus?.isAffiliate

  return (
    <>
      <div className={style.keys}>
        <span className={style.title}>
          {t({
            id: 'okxAffiliate.keys.title',
            comment: 'OKХ Keys',
          })}
        </span>
        <div className={style.statusWrap}>
          <span className={style.statusTitle}>
            {t({
              id: 'okxAffiliate.keys.status',
              comment: 'Status',
            })}
          </span>
          <div className={style.status}>
            <span className={style.statusInfo}>
              {isKeyActive
                ? t({
                    id: 'okxAffiliate.keys.connected',
                    comment: 'Connected',
                  })
                : t({
                    id: 'okxAffiliate.keys.notConnected',
                    comment: 'Not connected',
                  })}
            </span>
            <span className={style.statuSeparator}>&bull;</span>
            {isKeyActive && <FormatNumberDotWithTooltip value={userStatus?.apiKey?.key || ''} qtyChars={4} />}
            <span
              className={clsx({
                [style.statusIcon]: isKeyActive,
              })}
            >
              <SVG.OtherIcons.Link />
            </span>
          </div>
        </div>
        {!isKeyActive ? (
          <Button.Primary
            onClick={openAddKeyModal}
            label={t({
              id: 'okxAffiliate.keys.buttonAddKeys',
              comment: 'Add keys',
            })}
            disabled={!google.enabled}
          />
        ) : (
          <Button.Primary
            onClick={openRemoveKeyPopup}
            label={t({
              id: 'okxAffiliate.keys.buttonDeleteKeys',
              comment: 'Delete keys',
            })}
            disabled={!google.enabled}
          />
        )}
      </div>
      <InteractiveModal isOpen={isOpenAddKeyPopup}>
        <FormAddKey onClose={closeAddKeyModal} />
      </InteractiveModal>
      <InteractiveModal isOpen={isRemoveKeyPopup}>
        <FormRemoveKey onClose={closeRemoveKeyPopup} />
      </InteractiveModal>
    </>
  )
}

export default memo(KeyContent)
