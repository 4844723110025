import { FC, useMemo } from 'react'
import { CashbackTermsType } from '@/backend/api'
import { CashbackPercentageList } from '@/ui/organisms'
import { percentFromString } from '@/utils/lib/numbers'
import { useAppSelector } from '@/utils'
import style from './style.module.scss'
import { ECashbackCalculationRule } from '@/backend/api/cashbackAPI'

export const CashbackPercentageAndProgressBar: FC<{ terms: CashbackTermsType[] }> = ({ terms }) => {
  const { cashback } = useAppSelector(state => state.cashback)

  const todayCashbackIndex = useMemo<number>(() => {
    if (cashback.calculationRule === ECashbackCalculationRule.customReferralCode) return -1
    return terms.findIndex(item => {
      const binanceCashbackPercent = percentFromString(item.binanceCashbackPercent)

      return Number(cashback.cashbackPercentToday) === binanceCashbackPercent
    })
  }, [terms, cashback])

  return (
    <div className={style.wrap}>
      <CashbackPercentageList cashbackTerms={terms} todayCashbackIndex={todayCashbackIndex} />
    </div>
  )
}
