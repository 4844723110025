import React from 'react'

const Warning = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="14" height="14" rx="7" fill="url(#paint0_linear_11793_23970)" />
      <path d="M7 3.5V8.16667" stroke="black" strokeWidth="1.4" strokeLinecap="round" />
      <path d="M7 10.5V10.5778" stroke="black" strokeWidth="1.4" strokeLinecap="round" />
      <defs>
        <linearGradient
          id="paint0_linear_11793_23970"
          x1="0"
          y1="0"
          x2="14.4772"
          y2="0.512214"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F37E9A" />
          <stop offset="1" stopColor="#FF8933" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { Warning }
