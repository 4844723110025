import { FC } from 'react'

type ApiRepairProps = {
  className?: string
}
export const ApiRepair: FC<ApiRepairProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={41} fill="none" className={className}>
    <rect width={40} height={40} y={0.5} fill="#676573" rx={20} />
    <path fill="#fff" fillRule="evenodd" d="M16 18a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" clipRule="evenodd" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M20 20.53c.599 0 1.085.485 1.085 1.084v5.424a1.085 1.085 0 0 1-2.17 0v-5.424c0-.599.486-1.085 1.085-1.085Z"
      clipRule="evenodd"
    />
  </svg>
)
