import { useGoogleAuthCode, useGoogleAuthSettings } from '@/utils/hooks'
import { PopupSequence } from '@/ui/organisms/PopupSequence'
import style from './style.module.scss'
import TeamLeftBar from '@/ui/organisms/TeamLeftBar'
import TeamRightBar from '@/ui/organisms/TeamRightBar'
import { PageSetupGoogleAuth } from '@/ui/organisms/WalletSetupGoogleAuth'
import { urls } from '@/router/urls'

export const Team = () => {
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()

  if (!googleAuthSettings.settings.enabled) {
    return (
      <div className={style.authModal}>
        <PageSetupGoogleAuth
          googleAuthSettings={googleAuthSettings}
          googleAuthCode={googleAuthCode}
          backRoute={urls.teamMembers}
        />
      </div>
    )
  }
  return (
    <>
      <div className={style.team}>
        <div className={style.content}>
          <TeamLeftBar />
          <TeamRightBar />
        </div>
      </div>
    </>
  )
}
