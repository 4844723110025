import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { ButtonVariant, InteractiveModalParts } from '@/ui/kit'
import { UseGoogleAuthCodeResult, UseGoogleAuthSettingsResult, useToggle } from '@/utils/hooks'
import { Popup2FAWithIntro } from '../Popup2FAWithIntro'
import { urls } from '@/router/urls'
import style from './style.module.scss'
import clsx from 'clsx'

interface WalletSetupGoogleAuthProps {
  googleAuthSettings: UseGoogleAuthSettingsResult
  googleAuthCode: UseGoogleAuthCodeResult
  backRoute?: string
  className?: string
  isDisabledNavigate?: boolean
}

export const PageSetupGoogleAuth = (props: WalletSetupGoogleAuthProps) => {
  const navigate = useNavigate()
  const [isOpen, toggle] = useToggle()

  function handleClose() {
    if (props.isDisabledNavigate) {
      toggle()
      return
    }

    toggle()
    navigate(props.backRoute || urls.root)
  }

  return (
    <div className={clsx(style.container, props.className)}>
      <div className={style.header}>{t({ message: 'Secure your transactions', id: 'core.2fa.title' })}</div>
      <div className={style.text}>
        {t({ message: 'Turn on two-factor authentication using Google Authenticator app:', id: 'core.2fa.subTitle' })}
      </div>
      <InteractiveModalParts.Button
        text={t({ id: 'core.googleAuth.name', message: 'Google Authenticator' })}
        variant={ButtonVariant.Primary}
        icon={<SVG.OtherIcons.Google className={style.icon} />}
        onClick={toggle}
      />
      <Popup2FAWithIntro
        isOpen={isOpen}
        isIgnoreIntro
        {...props}
        header={
          <Fragment>
            {t({ message: 'Confirm your account', id: 'core.2fa.confirm.message-1' })}
            <br />
            {t({ message: 'to make the transaction', id: 'core.2fa.confirm.message-2' })}
          </Fragment>
        }
        onSuccess={toggle}
        onClose={handleClose}
      />
    </div>
  )
}
