import { GLOBAL_REQUEST_TIMEOUT, GLOBAL_REQUEST_TIMEOUT_EXTENDED } from '@/core/config/exchange'
import { EXCHANGES_TYPE } from '@/core/constants'
import { axiosMainService } from '@/services/axios'
import { axiosOKXMainService } from '@/services/axios/instances/okx'
import { AxiosResponse } from 'axios'

export enum ESecurityType {
  USER_STREAM = 'USER_STREAM',
  USER_DATA = 'USER_DATA',
}

type TSignWithReadOnlyPayload = {
  queryString: string
  requestBody: string
  requestMethod: string
  requestPath: string
  timestampFormat?: 'epochMilliseconds' | 'epochSeconds' | 'isoMilliseconds'
}

type TSignWithReadOnlyResponse = {
  headers: Record<string, string>
  parameters: Record<string, string>
}

interface ISignWithReadOnly<T = undefined> {
  (payload: TSignWithReadOnlyPayload): T extends AxiosResponse
    ? Promise<AxiosResponse<TSignWithReadOnlyResponse>>
    : Promise<TSignWithReadOnlyResponse>
}

export type TReadOnlyApiKeyDTO = {
  apiKey: string
}

export const exchangeApi = {
  async binanceProxyPost<T>(
    url: string,
    requestParamsSource: Record<string, string>,
    securityType: ESecurityType
  ): Promise<T> {
    const requestParams = new URLSearchParams(requestParamsSource)
    const result = await axiosMainService.post(
      `${process.env.REACT_APP_BACKEND_IP}exchange-gtw/protected/api/v1/binance${url}?${requestParams.toString()}`,
      {},
      {
        headers: {
          'X-SECURITY-TYPE': securityType,
        },
        timeout: GLOBAL_REQUEST_TIMEOUT,
      }
    )
    return {
      ...result.data,
      status: result.status,
    }
  },
  async binanceProxyPut<T>(
    url: string,
    requestParamsSource: Record<string, string>,
    securityType: ESecurityType
  ): Promise<T> {
    const requestParams = new URLSearchParams(requestParamsSource)
    const result = await axiosMainService.put(
      `${process.env.REACT_APP_BACKEND_IP}exchange-gtw/protected/api/v1/binance${url}?${requestParams.toString()}`,
      {},
      {
        headers: {
          'X-SECURITY-TYPE': securityType,
        },
        timeout: GLOBAL_REQUEST_TIMEOUT,
      }
    )
    return {
      ...result.data,
      status: result.status,
    }
  },
  async okxProxyGet<T>(url: string, requestParamsSource: Record<string, string>): Promise<T> {
    const requestParams = new URLSearchParams(requestParamsSource)
    const result = await axiosMainService.get(
      `${process.env.REACT_APP_BACKEND_IP}exchange-gtw/protected/api/v1/okx${url}?${requestParams.toString()}`,
      {
        timeout: GLOBAL_REQUEST_TIMEOUT,
      }
    )
    return {
      ...result.data,
      status: result.status,
    }
  },
  async bybitProxyGet<T>(url: string, requestParamsSource: Record<string, string>): Promise<T> {
    const requestParams = new URLSearchParams(requestParamsSource)
    const result = await axiosMainService.get(
      `${process.env.REACT_APP_BACKEND_IP}exchange-gtw/protected/api/v1/bybit/${url}?${requestParams.toString()}`,
      {
        timeout: GLOBAL_REQUEST_TIMEOUT,
      }
    )
    return {
      ...result.data,
      status: result.status,
    }
  },
  async getReadOnlyKey() {
    return await axiosMainService.get<TReadOnlyApiKeyDTO>('/exchange-gtw/protected/api/v2/apiKey/readOnly', {
      timeout: GLOBAL_REQUEST_TIMEOUT_EXTENDED,
    })
  },
  async checkReadOnlyApiKeyByType(exchangeType: EXCHANGES_TYPE) {
    try {
      const apiKey = await axiosMainService.get<TReadOnlyApiKeyDTO>('/exchange-gtw/protected/api/v2/apiKey/readOnly', {
        headers: {
          'X-Exchange-Type': exchangeType,
        },
        timeout: GLOBAL_REQUEST_TIMEOUT_EXTENDED,
      })

      if (apiKey.status === 200 && apiKey.data.apiKey) {
        return true
      }

      return false
    } catch (error: any) {
      // код ошибки 3200 = Api key not found
      if (error.response?.data?.code === 3200) {
        console.error('API ключ не найден:', error.response.data.message)
        return false
      }

      throw error
    }
  },
  async postSignWithReadOnly(payload: TSignWithReadOnlyPayload) {
    return await axiosMainService.post<TSignWithReadOnlyResponse>(
      '/exchange-gtw/protected/api/v2/apiKey/readOnly/sign',
      payload,
      {
        timeout: GLOBAL_REQUEST_TIMEOUT,
      }
    )
  },

  async postSignWithReadOnlyOKX(payload: TSignWithReadOnlyPayload) {
    return await axiosOKXMainService.post<TSignWithReadOnlyResponse>(
      '/exchange-gtw/protected/api/v2/apiKey/readOnly/sign',
      payload,
      {
        timeout: GLOBAL_REQUEST_TIMEOUT,
      }
    )
  },
}
