export const Dots = () => {
  return (
    <svg width="27" height="5" viewBox="0 0 27 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.25 4.5C0.99 4.5 0 3.48 0 2.25C0 0.99 0.99 0 2.25 0C3.48 0 4.5 0.99 4.5 2.25C4.5 3.48 3.48 4.5 2.25 4.5Z"
        fill="currentColor"
      />
      <path
        d="M2.25 4.5C0.99 4.5 0 3.48 0 2.25C0 0.99 0.99 0 2.25 0C3.48 0 4.5 0.99 4.5 2.25C4.5 3.48 3.48 4.5 2.25 4.5Z"
        fill="currentColor"
      />
      <path
        d="M9.75 4.5C8.49 4.5 7.5 3.48 7.5 2.25C7.5 0.99 8.49 0 9.75 0C10.98 0 12 0.99 12 2.25C12 3.48 10.98 4.5 9.75 4.5Z"
        fill="currentColor"
      />
      <path
        d="M9.75 4.5C8.49 4.5 7.5 3.48 7.5 2.25C7.5 0.99 8.49 0 9.75 0C10.98 0 12 0.99 12 2.25C12 3.48 10.98 4.5 9.75 4.5Z"
        fill="currentColor"
      />
      <path
        d="M17.25 4.5C15.99 4.5 15 3.48 15 2.25C15 0.99 15.99 0 17.25 0C18.48 0 19.5 0.99 19.5 2.25C19.5 3.48 18.48 4.5 17.25 4.5Z"
        fill="currentColor"
      />
      <path
        d="M17.25 4.5C15.99 4.5 15 3.48 15 2.25C15 0.99 15.99 0 17.25 0C18.48 0 19.5 0.99 19.5 2.25C19.5 3.48 18.48 4.5 17.25 4.5Z"
        fill="currentColor"
      />
      <path
        d="M24.75 4.5C23.49 4.5 22.5 3.48 22.5 2.25C22.5 0.99 23.49 0 24.75 0C25.98 0 27 0.99 27 2.25C27 3.48 25.98 4.5 24.75 4.5Z"
        fill="currentColor"
      />
      <path
        d="M24.75 4.5C23.49 4.5 22.5 3.48 22.5 2.25C22.5 0.99 23.49 0 24.75 0C25.98 0 27 0.99 27 2.25C27 3.48 25.98 4.5 24.75 4.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
