import { ComponentMeta, ComponentStory } from '@storybook/react'
import { ErrorBase as ErrorBaseComponent } from './ErrorBase'

const Meta: ComponentMeta<typeof ErrorBaseComponent> = {
  title: 'UI-Kit/Errors',
  component: ErrorBaseComponent,
}
export default Meta

const StoryComponent: ComponentStory<typeof ErrorBaseComponent> = args => <ErrorBaseComponent {...args} />

export const ErrorBase = StoryComponent.bind({})
ErrorBase.args = {
  account: 'Spot',
  customHeader: '',
  customText: '',
  isContactSupportButtonVisible: false,
  isFullScreen: false,
  isRefreshButtonVisible: true,
  anotherAttemptButtonText: 'Try one more time',
  isAnotherAttemptButtonVisible: false,
  isCloseWindowVisible: false,
}

ErrorBase.parameters = {
  controls: {
    include: [
      'account',
      'customHeader',
      'customText',
      'isContactSupportButtonVisible',
      'isFullScreen',
      'isRefreshButtonVisible',
      'anotherAttemptButtonText',
      'isAnotherAttemptButtonVisible',
      'isCloseWindowVisible',
    ],
  },
}
