import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { ANIMATION_TIME } from './constants/animationTime'
import { useOutsideClick } from '@/utils'
import style from './styles/style.module.scss'
import backgroundAnimation from './styles/background.module.scss'
import sheetAnimation from './styles/sheet.module.scss'
import { SVG } from '@/assets/svg'
import { Button } from '../Button'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import clsx from 'clsx'
import { usePreventScroll } from '@/utils/hooks'

interface Props {
  isOpened: boolean
  onClose: () => void
  children?: ReactNode
  label?: ReactNode
  className?: string
  staticContent?: ReactNode
}

export const DialogLayout: FC<Props> = ({ onClose, children, label, isOpened, className, staticContent }) => {
  usePreventScroll()

  const [animationIn, setAnimationIn] = useState(false)
  const backgroundRef = useRef<HTMLDivElement>(null)
  const sheetRef = useOutsideClick<HTMLDivElement>(onClose)

  useEffect(() => {
    setAnimationIn(isOpened)
  }, [isOpened])

  return (
    <div className={style.container}>
      <CSSTransition
        in={animationIn}
        nodeRef={backgroundRef}
        timeout={ANIMATION_TIME}
        classNames={backgroundAnimation}
        unmountOnExit
        mountOnEnter
      >
        <div ref={backgroundRef} className={style.background} />
      </CSSTransition>

      <CSSTransition
        in={animationIn}
        nodeRef={sheetRef}
        timeout={ANIMATION_TIME}
        classNames={sheetAnimation}
        unmountOnExit
        mountOnEnter
      >
        <div ref={sheetRef} className={style.sheet}>
          <div className={style.top}>
            {!!label && <span className={style.label}>{label}</span>}
            <Button.Primary
              onClick={onClose}
              leftIcon={<SVG.Additional.Close />}
              className={style.closeButton}
              dataTestId={DataTestIds.CloseSheet}
            />
          </div>

          {!!staticContent && <div>{staticContent}</div>}

          <div className={clsx(style.content, className)}>{children}</div>
        </div>
      </CSSTransition>
    </div>
  )
}
