import React, { FC } from 'react'

interface CheckProp {
  className?: string
}

export const Check: FC<CheckProp> = props => {
  const { className } = props

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.0742 8.54771C5.75695 8.16507 5.18957 8.11205 4.80692 8.42929C4.42428 8.74654 4.37125 9.31391 4.6885 9.69656L6.0742 8.54771ZM7.51843 11.6998L6.82558 12.2742C6.99008 12.4726 7.23175 12.591 7.48936 12.5993C7.74696 12.6077 7.99577 12.5051 8.17274 12.3178L7.51843 11.6998ZM13.2727 6.91777C13.614 6.5564 13.5977 5.98678 13.2364 5.64549C12.875 5.3042 12.3054 5.32048 11.9641 5.68184L13.2727 6.91777ZM4.6885 9.69656L6.82558 12.2742L8.21128 11.1254L6.0742 8.54771L4.6885 9.69656ZM8.17274 12.3178L13.2727 6.91777L11.9641 5.68184L6.86411 11.0818L8.17274 12.3178Z"
        fill="currentColor"
      />
    </svg>
  )
}
