import React from 'react'
import { SVG } from '@/assets/svg'
import styles from './style.module.scss'

export const tableHeadersConvert = [
  {
    accessor: 'date',
    header: 'Date',
    meta: { className: ['date-column'] },
  },
  {
    accessor: 'pair',
    header: 'Pair',
    meta: { className: ['pair-column'] },
  },
  {
    accessor: 'from',
    header: 'From',
    meta: { className: ['from-column'] },
  },
  {
    accessor: 'to',
    header: 'To',
    meta: { className: ['to-column'] },
  },
  {
    accessor: 'price',
    header: 'Price',
    meta: { className: ['price-column'] },
  },
  {
    accessor: 'status',
    header: 'Status',
    meta: { className: ['status-column'] },
    cell: ({ row }: { row: { original: { status?: { icon: React.ReactNode; name: string; style: string } } } }) => {
      if (!row.original?.status) {
        return (
          <div className="status statusUnknown">
            <span>Unknown</span>
          </div>
        )
      }

      const { icon, name, style } = row.original.status

      return (
        <div className={`${styles.status} ${styles[style]}`}>
          {icon}
          <span>{name}</span>
        </div>
      )
    },
  },
]

export type StatusKey = 'SUCCESS' | 'ACCEPT_SUCCESS' | 'FAIL' | 'PROCESS' | 'UNKNOWN'

export const statuses: Record<StatusKey, { icon: React.ReactNode; style: string }> = {
  SUCCESS: {
    icon: <SVG.Status.Completed />,
    style: 'statusCompleted',
  },
  ACCEPT_SUCCESS: {
    icon: <SVG.Status.Completed />,
    style: 'statusCompleted',
  },
  FAIL: {
    icon: <SVG.Status.Failed />,
    style: 'statusFailed',
  },
  PROCESS: {
    icon: <SVG.Status.Pending />,
    style: 'statusPending',
  },
  UNKNOWN: {
    icon: <span></span>,
    style: 'statusUnknown',
  },
}
