import clsx from 'clsx'
import style from './style.module.scss'
import { floorNumber, formatNumber } from '@/utils/lib/formatNumber'
import { useOrdersPage } from './hooks/useOrdersPage'
import { FC } from 'react'

const OrdersPageWidget: FC = () => {
  const { balanceInfo, options } = useOrdersPage()

  return (
    <div className={style.balanceInfo}>
      <div className={style.headers}>
        {balanceInfo.map((item, idx) => {
          return (
            <div key={idx} className={style.account}>
              {item?.id && item?.amount && (
                <>
                  <div className={style.head}>
                    <span>{item.label + ', '}</span>
                    <span className={style.symbol}>{item.symbol}</span>
                  </div>
                  <div className={style.amountBlock}>
                    <span className={clsx(style.price, { [style.balance]: idx === 0 })}>
                      {item.symbol === 'BTC' ? formatNumber(item.amount || 0, options) : floorNumber(item.amount || 0)}
                    </span>
                  </div>
                </>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export { OrdersPageWidget }
