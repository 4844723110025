import { FC, useCallback, useMemo, useState } from 'react'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { E_TEAM_MEMBER_VIEWS, E_TEAM_USER_VIEWS } from '../../TeamWidget.Types'
import { CardWrapper } from '@/ui/kit/CardWrapper'
import { t } from '@lingui/macro'
import { Button, ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { SVG } from '@/assets/svg'
import { useActions, useAppSelector } from '@/utils'
import { EInteractiveModalVariant } from '@/ui/kit/Modals/InteractiveModal/InteractiveModal'
import { TeamFormRiskSubscription } from '@/ui/molecules/TeamForm'
import { TeamFormRiskSettings } from '@/ui/molecules/TeamForm/TeamForm.Components/TeamForm.RiskSettings'
import { teamActions } from '@/redux/team/team.slice'
import { externalLinks } from '@/utils/lib/linksCreator'
import { TeamFormRiskDisable } from '@/ui/molecules/TeamForm/TeamForm.Components/TeamForm.RiskDisable'
import { TeamFormLeave } from '@/ui/molecules/TeamForm/TeamForm.Components/TeamForm.Leave'
import { TeamWidgetForm } from './TeamWidget.Form'

import styles from './style.module.scss'

const ViewDefault: FC = () => {
  const { userView, userData } = useTeamData()
  return (
    <>
      <div className={styles.heading}>
        {t({ id: 'teamWidgetUser.heading.join' })} {userData.teamUserInfo.teamTitle}
      </div>
      {userView !== E_TEAM_USER_VIEWS.SUBMITTED && (
        <div className={styles.info}>{t({ id: 'teamWidgetUser.info.join' })}</div>
      )}
      {userView === E_TEAM_USER_VIEWS.SUBMITTED && (
        <div className={styles.info}>{t({ id: 'teamWidgetUser.info.submitted' })}</div>
      )}
      <TeamWidgetForm className={styles.form} />
    </>
  )
}

const ViewRmControl: FC = () => {
  const { userData, riskManagerSettings } = useTeamData()
  const { GetTeamRiskManagerTC, GetUserTeamInfoTC } = useActions(teamActions)
  const { isRmAvailable: isInternalRmAvailable } = useAppSelector(state => state.riskManager)
  const [widgetView, setWidgetView] = useState(E_TEAM_MEMBER_VIEWS.NONE)
  const handleActivateCancel = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleActivateSuccess = useCallback(() => {
    GetTeamRiskManagerTC()
    GetUserTeamInfoTC()
    setWidgetView(E_TEAM_MEMBER_VIEWS.RM_AFTER_ACTIVATION)
  }, [])

  const handleAfterActivationCancel = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleAfterActivationSuccess = useCallback(() => {
    window.open(externalLinks.telegram(riskManagerSettings.botHandle))
  }, [riskManagerSettings.botHandle])

  const handleSettingsClose = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleSettingsCancel = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.RM_DISABLE)
  }, [])

  const handleSettingsSuccess = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
    GetUserTeamInfoTC()
  }, [])

  const handleDisableCancel = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleDisableSuccess = useCallback(() => {
    GetTeamRiskManagerTC()
    GetUserTeamInfoTC()
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleLeaveCancel = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleLeaveSuccess = useCallback(() => {
    GetUserTeamInfoTC()
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const modalVariant = useMemo<EInteractiveModalVariant | undefined>(() => {
    return widgetView !== E_TEAM_MEMBER_VIEWS.ERROR && widgetView !== E_TEAM_MEMBER_VIEWS.RM_DISABLE
      ? EInteractiveModalVariant.MEDIUM
      : undefined
  }, [widgetView])

  return (
    <>
      <div className={styles.heading}>
        {t({ id: 'teamWidgetUser.heading.joined-pre' })} {userData.teamUserInfo.teamTitle}{' '}
        {t({ id: 'teamWidgetUser.heading.joined-post' })}
      </div>
      {userData.teamUserInfo.cashbackPercent && (
        <div className={styles.info}>
          {t({ id: 'teamWidgetUser.info.cashback' })} {userData.teamUserInfo.cashbackPercent}%.
        </div>
      )}
      <div className={styles.info}>{t({ id: 'teamWidgetUser.info.joined' })}</div>

      <div className={styles.controls}>
        {!isInternalRmAvailable &&
          !userData.teamUserInfo.riskManagerEnabled &&
          userData.teamUserInfo.riskManagerAvailable && (
            <Button.AccentDark
              className={styles.button}
              label={t({ id: 'teamWidgetUser.action.rmActivate' })}
              onClick={() => {
                setWidgetView(E_TEAM_MEMBER_VIEWS.RM_ACTIVATE)
              }}
            />
          )}

        {!isInternalRmAvailable &&
          userData.teamUserInfo.riskManagerEnabled &&
          userData.teamUserInfo.riskManagerAvailable && (
            <>
              <div className={styles.rmMessage}>
                <SVG.OtherIcons.Shield className={styles.rmIcon} />
                {t({ id: 'teamWidgetUser.info.rmActivated' })}
              </div>
              <Button.Primary
                className={styles.button}
                label={t({ id: 'teamWidgetUser.action.rmSettings' })}
                onClick={() => {
                  setWidgetView(E_TEAM_MEMBER_VIEWS.RM_SETTINGS)
                }}
              />{' '}
            </>
          )}
        <Button.Primary
          className={styles.button}
          label={t({ id: 'teamWidgetUser.action.leave', message: 'Leave the team' })}
          onClick={() => {
            setWidgetView(E_TEAM_MEMBER_VIEWS.T_LEAVE)
          }}
        />
      </div>

      <InteractiveModal isOpen={widgetView !== E_TEAM_MEMBER_VIEWS.NONE} variant={modalVariant}>
        {widgetView === E_TEAM_MEMBER_VIEWS.RM_ACTIVATE && (
          <TeamFormRiskSubscription
            handleBack={handleActivateCancel}
            handleBackSuccess={handleActivateSuccess}
            handleClose={handleActivateCancel}
            showCloseControl={true}
          />
        )}

        {widgetView === E_TEAM_MEMBER_VIEWS.RM_AFTER_ACTIVATION && (
          <>
            <InteractiveModalParts.SubHeader
              text={t({
                id: 'teamRiskSubscriptionForm.success.heading',
              })}
            />
            <InteractiveModalParts.Explanation
              text={t({
                id: 'teamRiskSubscriptionForm.success.text',
              })}
            />
            <InteractiveModalParts.Button
              variant={ButtonVariant.Accent}
              text={t({
                id: 'teamRiskSubscriptionForm.success.action.telegram',
              })}
              onClick={handleAfterActivationSuccess}
            />
            <InteractiveModalParts.Button
              variant={ButtonVariant.Primary}
              text={t({
                id: 'teamRiskSubscriptionForm.success.action.cancel',
              })}
              onClick={handleAfterActivationCancel}
            />
          </>
        )}
        {widgetView === E_TEAM_MEMBER_VIEWS.RM_SETTINGS && (
          <TeamFormRiskSettings
            handleBack={handleSettingsCancel}
            handleBackSuccess={handleSettingsSuccess}
            handleClose={handleSettingsClose}
            showCloseControl={true}
          />
        )}
        {widgetView === E_TEAM_MEMBER_VIEWS.RM_DISABLE && (
          <>
            <InteractiveModalParts.SubHeader
              text={t({
                id: 'teamRiskDisableForm.heading',
                message: 'Отключить риск-менеджер?',
              })}
            />
            <div className={styles.subtitle}>
              {t({
                id: 'teamRiskDisableForm.description',
                message: 'Торговля станет доступна через 24 часа. Следить за статусом можно в Telegram боте.',
              })}
            </div>
            <TeamFormRiskDisable handleBack={handleDisableCancel} handleBackSuccess={handleDisableSuccess} />
          </>
        )}
        {widgetView === E_TEAM_MEMBER_VIEWS.T_LEAVE && (
          <>
            <InteractiveModalParts.SubHeader
              text={t({
                id: 'teamLeaveForm.heading',
                message: 'Leave the team?',
              })}
            />
            <InteractiveModalParts.Explanation
              text={t({
                id: 'teamLeaveForm.description',
                message:
                  "You'll lose all the benefits of team membership. You can reapply the request to join the team.",
              })}
            />
            <TeamFormLeave handleBack={handleLeaveCancel} handleBackSuccess={handleLeaveSuccess} />
          </>
        )}
      </InteractiveModal>
    </>
  )
}

export const TeamWidgetUser: FC = () => {
  const { userView } = useTeamData()
  if (userView === E_TEAM_USER_VIEWS.DISABLED) return <></>

  return (
    <CardWrapper className={styles.root}>
      {(userView === E_TEAM_USER_VIEWS.DEFAULT || userView === E_TEAM_USER_VIEWS.SUBMITTED) && <ViewDefault />}
      {userView === E_TEAM_USER_VIEWS.APPROVED && <ViewRmControl />}
    </CardWrapper>
  )
}
