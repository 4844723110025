import { FC } from 'react'
import style from './style.module.scss'

export type MiniHintProps = {
  /** text to show inside hint */
  text: string
}

/** Small Hint. Use only with «miniHintWrapperClassName» */
export const MiniHint: FC<MiniHintProps> = ({ text }) => {
  return (
    <div className={style.hint}>
      <span className={style.hintText}>{text}</span>
    </div>
  )
}

/** Should be applied to the parent MiniHint component */
export const miniHintWrapperClassName = style.hintWrapper
