import { Select } from '@/ui/kit'
import style from './style.module.scss'
import clsx from 'clsx'
import { useState } from 'react'
import { ServicesTradeDurationType } from '@/utils/types/contracts.types'
import { useActions, useAppSelector } from '@/utils'
import { dashboardActions } from '@/redux/dashboard/dashboard.slice'
import { dateRangeOption } from '@/ui/organisms/HistoryTrades/components/TableFilters/options/dateRangeOption'

export const DashboardFilter = () => {
  const { dashboardFilter } = useAppSelector(state => state.dashboard)

  const [dateRange, setDateRange] = useState<ServicesTradeDurationType>(
    (dashboardFilter?.durationType
      ? dashboardFilter.durationType
      : ServicesTradeDurationType.TradeDurationType30D) as ServicesTradeDurationType
  )
  const { setBoardFilters } = useActions(dashboardActions)

  const dateRangeHandler = (range: ServicesTradeDurationType) => {
    if (
      dateRange === ServicesTradeDurationType.TradeDurationType30D &&
      range === ServicesTradeDurationType.TradeDurationType30D
    )
      return
    if (range === ServicesTradeDurationType.TradeWithoutDuration && range !== dateRange) {
      setBoardFilters({
        durationType: range,
      })
      setDateRange(range)
      return
    }
    if (dateRange === range) {
      setDateRange(ServicesTradeDurationType.TradeDurationType30D)
      setBoardFilters({
        durationType: ServicesTradeDurationType.TradeDurationType30D,
      })
    } else {
      setDateRange(range)
      setBoardFilters({
        durationType: range,
      })
    }
  }

  return (
    <Select
      onChange={dateRangeHandler}
      size={Select.Size.Small}
      value={dateRange ? dateRange : dateRangeOption(true)[0].value}
      options={dateRangeOption(true) ?? []}
      variant={Select.Variant.Raw}
      className={clsx(style.select, style.dateRange)}
    />
  )
}
