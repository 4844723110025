import { useCallback, useRef } from 'react'

export function useEventEffect<T extends (...args: any[]) => void>(callback: T): T {
  const handler = useRef(null as unknown as T)

  handler.current = callback

  return useCallback<T>(
    ((...args) => {
      handler.current(...args)
    }) as T,
    []
  )
}
