const nonTextInputTypes = new Map([
  ['checkbox', true],
  ['radio', true],
  ['range', true],
  ['color', true],
  ['file', true],
  ['image', true],
  ['button', true],
  ['submit', true],
  ['reset', true],
])

export function willOpenKeyboard(target: Element) {
  if (target instanceof HTMLInputElement && !nonTextInputTypes.has(target.type)) {
    return true
  }

  if (target instanceof HTMLTextAreaElement) {
    return true
  }

  if (target instanceof HTMLElement && target.isContentEditable) {
    return true
  }

  return false
}
