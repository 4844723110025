import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useActions } from './useActions'
import { overviewActions } from '@/redux/overview/overview.slice'
import { useAppSelector } from './useAppSelector'
import { getHistoryStatisticsFromCache, inValidateStatisticsCache } from '@/redux/overview/overview.utils'
import { profileActions } from '@/redux/profile/profile.slice'
import { NEED_POLING_ROUTES, TRADING_HISTORY_REFRESH_TIMEOUT } from '@/core/config/common'
import { useLocation } from 'react-router-dom'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { TExchangesAvailable } from '@/core/constants'

export const useTradingHistory = (init: boolean) => {
  const { incomeStatistics } = useAppSelector(state => state.overview)
  const { GetStatisticsSinceMomentTC, setIncomeStatistics, resetIncomeStatistics } = useActions(overviewActions)
  const location = useLocation()
  const { exchangeType } = useExchageType()

  const pollingInterval = useRef<ReturnType<typeof setInterval>>()

  const {
    metadata: { statisticsStartMoment },
  } = useAppSelector(state => state.profile)

  const { UpdateMetadata } = useActions(profileActions)

  const shouldPausePoling = useMemo(() => {
    return !NEED_POLING_ROUTES.includes(location.pathname)
  }, [location.pathname])

  const [statisticsMoment, setStatisticsMoment] = useState(statisticsStartMoment)

  const startMoment = useMemo(() => {
    return Number(incomeStatistics[exchangeType as TExchangesAvailable].BINANCE_FUTURE.startMoment)
  }, [incomeStatistics])

  const [passedInitialLoad, setPassedInitialLoad] = useState(false)

  const resetStatistics = useCallback(() => {
    const resetMoment = new Date().getTime().toString()
    setStatisticsMoment(resetMoment)
    resetIncomeStatistics([exchangeType as TExchangesAvailable, resetMoment])
    UpdateMetadata({
      statisticsStartMoment: resetMoment,
    })
  }, [UpdateMetadata, resetIncomeStatistics])

  const getActualData = useCallback(() => {
    if (incomeStatistics[exchangeType as TExchangesAvailable].BINANCE_FUTURE.status === 'loading') return

    const lastHistory =
      incomeStatistics[exchangeType as TExchangesAvailable].BINANCE_FUTURE?.dailyIncome === undefined
        ? undefined
        : incomeStatistics[exchangeType as TExchangesAvailable].BINANCE_FUTURE?.history[
            incomeStatistics[exchangeType as TExchangesAvailable].BINANCE_FUTURE.history.length - 1
          ]

    const moment = lastHistory === undefined ? startMoment : Number(lastHistory.time)

    GetStatisticsSinceMomentTC({
      moment,
      page: 1,
      ...(lastHistory === undefined
        ? {}
        : {
            uid: incomeStatistics[exchangeType as TExchangesAvailable].BINANCE_FUTURE.history.map(historyItem => {
              return historyItem.uid
            }),
          }),
    })
  }, [incomeStatistics, startMoment])

  useEffect(() => {
    inValidateStatisticsCache(exchangeType as TExchangesAvailable)
  }, [])

  useEffect(() => {
    if (
      incomeStatistics[exchangeType as TExchangesAvailable].BINANCE_FUTURE.status !== 'idle' ||
      !init ||
      passedInitialLoad
    )
      return
    setPassedInitialLoad(true)

    const cachedData = getHistoryStatisticsFromCache(startMoment, exchangeType as TExchangesAvailable)
    setIncomeStatistics([exchangeType as TExchangesAvailable, 'BINANCE_FUTURE', cachedData])

    if (cachedData.dailyIncome !== '0' && cachedData.dailyIncome !== undefined) return

    getActualData()
  }, [incomeStatistics, init, getActualData, startMoment, passedInitialLoad])

  const start = () => {
    pollingInterval.current = setInterval(() => {
      getActualData()
    }, TRADING_HISTORY_REFRESH_TIMEOUT)
  }

  const pause = () => {
    clearInterval(pollingInterval.current)
  }

  useEffect(() => {
    if (shouldPausePoling) {
      pause()
      return
    }
    if (pollingInterval.current === undefined || !shouldPausePoling) {
      start()
    }

    return () => {
      clearInterval(pollingInterval.current)
    }
  }, [getActualData, shouldPausePoling])

  return {
    statisticsMoment,
    resetStatistics,
    statisticsStartMoment,
  }
}
