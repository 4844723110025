import { transactionsAsyncActions } from '@/redux/transactions/transactions.actions'
import { useActions } from './useActions'
import { useEffect } from 'react'

function useAssetsForTransactionsPolling(interval: number = 3000) {
  const { GetAssetsForTransactionsTC } = useActions(transactionsAsyncActions)

  useEffect(() => {
    const id = setInterval(() => {
      GetAssetsForTransactionsTC()
    }, interval) as unknown as number

    return () => {
      clearInterval(id)
    }
  }, [GetAssetsForTransactionsTC, interval])

  useEffect(() => {
    GetAssetsForTransactionsTC()
  }, [GetAssetsForTransactionsTC])
}

export { useAssetsForTransactionsPolling }
