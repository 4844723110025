import React from 'react'

export const Deposit = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <linearGradient id="linear-gradient">
        <stop offset="0%" stopColor="#E571FF" />
        <stop offset="100%" stopColor="#FF8933" />
      </linearGradient>
      <path
        d="M9.78761 12.7459C9.78761 12.7459 4.58668 12.7459 1.25417 12.7459M1.25417 12.7459C1.25417 9.41333 1.25417 4.21241 1.25417 4.21241M1.25417 12.7459C6.01491 7.98511 12.7458 1.25418 12.7458 1.25418"
        stroke={'url(#linear-gradient)'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
