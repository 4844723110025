import {
  getAddressesTC,
  deleteAddressTC,
  editAddressTC,
  createAddressTC,
  getAssetsForAddressTC,
  getAddressByIdTC,
  getWhitelistTC,
  postToggleWhitelistPermissionTC,
} from './address.thunks'

export const addressAsyncActions = {
  getAddressesTC,
  deleteAddressTC,
  editAddressTC,
  createAddressTC,
  getAssetsForAddressTC,
  getAddressByIdTC,
  getWhitelistTC,
  postToggleWhitelistPermissionTC,
}
