import { FC } from 'react'

type BinanceIconType = {
  className?: string
  size?: number
}
export const BinanceIcon: FC<BinanceIconType> = ({ className, size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <rect width={24} height={24} fill="#F3BA2F" rx={6.72} />
    <path
      fill="#011119"
      fillRule="evenodd"
      d="m11.999 8.837-2.33 2.23L8.311 9.77 12 6.24l3.687 3.53-1.356 1.299L12 8.837Zm1.377 3.147h.001l-1.376-1.317-1.017.973-.117.112-.24.231-.003.002.002.002 1.375 1.316 1.376-1.318Zm3.268 1.299L18 11.985l-1.356-1.298-1.356 1.298 1.356 1.298ZM12 15.133 9.67 12.9l-1.36 1.298.002.002L12 17.729l3.687-3.53v-.001L14.332 12.9 12 15.132Zm-3.288-3.148-1.356-1.299L6 11.985l1.356 1.298 1.356-1.298Z"
      clipRule="evenodd"
    />
  </svg>
)
