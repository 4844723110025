import React, { FC } from 'react'
import { Status } from '../../../backend/types'
import { ApiKeyType } from '../../../redux/apiKey/apiKey.types'
import { TitleH3 } from '../../atoms/TitleH3'
import { SkeletonList } from '../../kit'
import EmptyList from '../../molecules/EmptyList'
import { ApiKeyListItem } from '../ApiKeyListItem'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { useAppSelector } from '@/utils'
import { voidFunction } from '@/utils/lib/voidFunction'

type ApiKeyListItemsProps = {
  data: ApiKeyType[]
  status: Status
  onRemove: (original: ApiKeyType) => void
  onEdit: (original: ApiKeyType) => void
  disabled?: boolean
}

export const ApiKeyListItems: FC<ApiKeyListItemsProps> = props => {
  const { data, status, onRemove, onEdit, disabled } = props
  const { tradingStatus } = useAppSelector(state => state.team)

  return (
    <div className={style.component}>
      <div className={style.listItems}>
        {(() => {
          if (status !== 'succeeded') {
            return <SkeletonList />
          }
          if (!data.length) {
            return (
              <EmptyList
                label={t({
                  id: 'apiPage.empty.title',
                  message: `Your API Keys will appear here`,
                })}
              />
            )
          }

          return (
            <>
              <TitleH3
                label={t({
                  id: 'apiPage.keysList.title',
                  message: `Keys`,
                })}
                className={style.title}
              />
              {data.map(item => (
                <ApiKeyListItem
                  originalApiKeyDTO={item}
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  date={item.createdAt}
                  apiKeyValue={item.key}
                  futuresEnabled={item.futuresEnabled}
                  spotEnabled={item.spotEnabled}
                  onRemove={onRemove}
                  onEdit={tradingStatus?.tradingEnabled ? onEdit : undefined}
                  disabled={disabled}
                />
              ))}
            </>
          )
        })()}
      </div>
    </div>
  )
}
