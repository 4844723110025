import { FC, useCallback, useEffect, useMemo } from 'react'
import { t } from '@lingui/macro'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Button, Input, InputSize, Select, SelectOption } from '@/ui/kit'
import { E_RISK_MANAGER_DRAWDOWN_TYPE } from '@/redux/team/team.types'
import { useActions, useAppSelector } from '@/utils'
import { Loader } from '@/ui/kit/Loader'
import { yupResolver } from '@hookform/resolvers/yup'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'
import styles from '../style.module.scss'
import { Control } from '@/ui/atoms/Control'
import { getDrawdownTypesText } from '@/translations/team'
import { truncateNumber } from '@/utils/lib/truncateNumber'
import { getRiskManagerErrors } from '@/backend/services/team'
import { useErrorsParser } from '@/utils/hooks/useErrorsParser'
import { VALIDATION_SCHEMA } from '../RiskManager.Utils/validation/riskSettings.schema'
import { IRiskManagerUpdate, ITeamFormUserSubscriptionProps } from '@/ui/molecules/TeamForm/TeamForm.Types'
import { HorizontalControls } from '@/ui/molecules/TeamForm/TeamForm.Components/TeamForm.Utils'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'
import clsx from 'clsx'

type TRiskManagerUpdateForm = IRiskManagerUpdate & {
  globalError: any
}

export const RiskManagerRiskSettings: FC<ITeamFormUserSubscriptionProps> = props => {
  const { rmGetRequestStatus } = useAppSelector(state => state.riskManager)

  const isLoadingState = useMemo(() => {
    return !isStatusFinal(rmGetRequestStatus)
  }, [rmGetRequestStatus])

  if (isLoadingState) return <Loader />

  return <Form {...props} />
}
const Form: FC<ITeamFormUserSubscriptionProps> = props => {
  const { UpdateRiskManagerTC, GetRiskManagerTC, setUpdateTeamRiskManagerReset, setErrors } =
    useActions(riskManagerActions)
  const { rmUpdateRequestStatus, errors: teamErrors, riskManagerSettings } = useAppSelector(state => state.riskManager)
  const { handleBackSuccess, showCloseControl, handleClose } = props

  const {
    handleSubmit,
    control,
    formState,
    setError,
    clearErrors,
    formState: { errors },
    trigger,
  } = useForm<TRiskManagerUpdateForm>({
    mode: 'all',
    resolver: yupResolver(VALIDATION_SCHEMA()),
    defaultValues: {
      drawdownType: riskManagerSettings?.drawdownType,
      drawdownValue: riskManagerSettings?.drawdownValue,
    },
  })
  const formValues = useWatch({ control })
  const getErrors = getRiskManagerErrors()
  useErrorsParser(teamErrors, setError, getErrors)

  const onSubmit = useCallback<SubmitHandler<IRiskManagerUpdate>>(
    (data: IRiskManagerUpdate) => {
      setErrors()
      clearErrors()
      UpdateRiskManagerTC(data)
    },
    [UpdateRiskManagerTC, clearErrors, setErrors]
  )

  const globalErrorMessage = errors?.globalError?.message
  useEffect(() => {
    return () => {
      setErrors()
    }
  }, [setErrors])

  const isSubmitEnabled = useMemo(() => {
    return formState.isValid //|| !formState.isSubmitted
  }, [formState])

  useEffect(() => {
    if (isStatusFinal(rmUpdateRequestStatus)) setUpdateTeamRiskManagerReset()
    if (rmUpdateRequestStatus === 'succeeded') {
      handleBackSuccess && handleBackSuccess()
    }
  }, [handleBackSuccess, rmUpdateRequestStatus, GetRiskManagerTC, setUpdateTeamRiskManagerReset])

  const optionsDrawdown = useMemo<SelectOption<E_RISK_MANAGER_DRAWDOWN_TYPE>[]>(() => {
    const drawdownNames = getDrawdownTypesText()
    return Object.values(E_RISK_MANAGER_DRAWDOWN_TYPE).map(drawdown => {
      return {
        label: drawdownNames[drawdown],
        value: drawdown,
      }
    })
  }, [])

  const translations = useMemo(() => {
    return {
      drawdownValueLabel:
        formValues.drawdownType === E_RISK_MANAGER_DRAWDOWN_TYPE.percent
          ? t({
              id: 'teamRiskSubscriptionForm.input.drawdownValue.label.percent',
            })
          : t({
              id: 'teamRiskSubscriptionForm.input.drawdownValue.label.usdt',
            }),
    }
  }, [formValues.drawdownType])

  const isLoadingState = useMemo(() => {
    return rmUpdateRequestStatus === 'loading'
  }, [rmUpdateRequestStatus])

  if (isLoadingState) return <Loader />

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        {t({
          id: 'teamWidgetUser.action.rmSettings',
        })}
        {showCloseControl && <Control.Close onClick={handleClose} className={styles.closeControl} />}
      </div>

      <form className={clsx(styles.form)} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formItem}></div>
        <div className={styles.formItem}>
          <Controller
            control={control}
            name={'drawdownType'}
            render={({ field }) => (
              <Select
                label={t({
                  id: 'teamRiskSubscriptionForm.input.drawdownType.label',
                  comment: 'Drawdown type',
                })}
                options={optionsDrawdown}
                value={field.value}
                size={Select.Size.Small}
                onChange={value => {
                  field.onChange(value as string)
                  trigger('drawdownValue')
                }}
                toggleClassname={styles.selectToggle}
                labelClassname={styles.selectLabel}
                dropdownClassName={styles.selectDropdown}
                errorMessage={errors.drawdownType?.message}
              />
            )}
          />
        </div>

        <div className={styles.formItem}>
          <Controller
            control={control}
            name={'drawdownValue'}
            render={({ field }) => (
              <Input
                size={InputSize.Small}
                placeholder={t({
                  id: 'teamRiskSubscriptionForm.input.drawdownValue.placeholder',
                })}
                value={field.value}
                setValue={value => {
                  // TODO: move to normalizers
                  const valueFormatted = value ? truncateNumber(Number(value), 0) : value
                  field.onChange(valueFormatted)
                }}
                inputAttrs={{ type: 'number' }}
                label={translations.drawdownValueLabel}
                errorMessage={errors.drawdownValue?.message}
              />
            )}
          />
        </div>
        <p className={styles.globalError}>{globalErrorMessage}</p>
        <HorizontalControls className={styles.submitLine}>
          <Button.Accent
            label={t({
              id: 'teamRiskSettingsForm.submit.apply',
              message: 'Apply',
            })}
            className={styles.btnWide}
            type={'submit'}
            disabled={!isSubmitEnabled}
          />
        </HorizontalControls>
      </form>
    </div>
  )
}
