export const RenderPath = (short: number, coord1: number[], coord2: number[]) => {
  if (short === 0) {
    return (
      <path
        fill="url(#tiger-widget-distribution-gradient1)"
        d="M38, 0 A38,38 0 1 1 37.99336774887612,5.787730970041594e-7 L37.99546214396787,12.000000396002644 A26,26 0 1 0 38,12 Z"
      />
    )
  } else if (short === 100) {
    return (
      <path
        fill="url(#tiger-widget-distribution-gradient2)"
        d="M38, 0 A38,38 0 1 1 37.99336774887612,5.787730970041594e-7 L37.99546214396787,12.000000396002644 A26,26 0 1 0 38,12 Z"
      />
    )
  } else if (short >= 50) {
    return (
      <>
        <path
          fill="url(#tiger-widget-distribution-gradient2)"
          d={`M38,0 A38,38 0 1 1 ${coord1} L${coord2} A26,26 0 1 0 38,12 Z`}
        />
        <path
          fill="url(#tiger-widget-distribution-gradient1)"
          d={`M${coord1} A38,38 0 0 1 38,0 L38,12 A26,26 0 0 0 ${coord2} Z`}
        />
      </>
    )
  } else {
    return (
      <>
        <path
          fill="url(#tiger-widget-distribution-gradient2)"
          d={`M38, 0 A38,38 0 0 1 ${coord1} L${coord2} A26,26 0 0 0 38,12 Z`}
        />
        <path
          fill="url(#tiger-widget-distribution-gradient1)"
          d={`M${coord1} A38,38 0 1 1 38,0 L38,12 A26,26 0 1 0 ${coord2} Z`}
        />
      </>
    )
  }
}
