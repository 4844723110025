import { FC } from 'react'
import { Link } from 'react-router-dom'
import { t } from '@lingui/macro'
import { useKYC } from '@/utils/hooks'
import { urls } from '@/router/urls'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

const HeaderKysStatus: FC = () => {
  const { kycLevelTitle } = useKYC()

  return (
    <Link to={urls.profile} className={style.root}>
      <div className={style.title} data-testid={DataTestIds.KycStatus}>
        {t({ id: 'header.kycStatus', message: `KYC Status` })}
      </div>
      <div className={style.status} data-testid={DataTestIds.KycLevel}>
        {kycLevelTitle}
      </div>
    </Link>
  )
}

export { HeaderKysStatus }
