import { ReactNode } from 'react'

export enum InputDropdownSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum InputDropdownVariant {
  Lined = 'lined',
  Raw = 'raw',
}

export type InputDropdownOption<T extends string> = {
  /** value of option selected */
  value: T

  /** unique ID to check if option is selected */
  id: string

  /** dropdown view */
  label: ReactNode

  /** selected view */
  selected?: ReactNode

  /** search field **/
  search?: string

  /** for grouping  **/
  group?: boolean

  /** redirection click **/
  onClick?: () => void

  /** icon that will be rendered on the right */
  rightIcon?: ReactNode

  /** if option is disabled */
  disabled?: boolean
}

type GroupElement = {
  id: string
  element: string | ReactNode
}

export type InputDropdownProps<T extends string> = {
  /** networks as options for SelectList */
  options: InputDropdownOption<T>[]

  /** selected value from above */
  value?: string

  /** visible text if nothing is selected */
  placeholder?: string

  /** label **/
  label?: ReactNode

  /** size */
  size?: InputDropdownSize

  /** variant **/
  variant?: InputDropdownVariant

  /** description of the error */
  error?: string

  /** description under wrapper **/
  description?: ReactNode

  /** className for wrapper */
  className?: string

  /** className for dropdown */
  classNameDropdown?: string

  /** onChange handler */
  onChange: (value: string) => void

  /** option element click  */
  optionClick: (value: InputDropdownOption<T>) => void

  /** handleButtonClick for button click */
  handleButtonClick: () => void

  /** iconClick for icon click function  */
  iconClick?: () => void

  /** handleCleanChange if there needed clean some values */
  handleCleanChange?: () => void

  /** text for label hint */
  labelHintText?: ReactNode

  /** dataTestId for input */
  dataTestId: string

  /** icon for input */
  Icon?: ReactNode

  /** for disabled input */
  disabled?: boolean

  /**group for grouped options */
  group?: boolean

  /**groupTitle if group is true */
  groupTitles: GroupElement[]
}
