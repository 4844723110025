import cashbackAsyncActions from '@/redux/cashback/cashback.actions'
import { HowItWorksLeftBar, HowItWorksRightBar } from '@/ui/organisms'
import { useActions } from '@/utils'
import { useEffect } from 'react'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'
import style from './style.module.scss'

export const HowItWorksPage = () => {
  const { GetCashbackTermsTC } = useActions(cashbackAsyncActions)

  const { isCommissionEnabled } = useExchangesDisabled()

  useEffect(() => {
    GetCashbackTermsTC()
  }, [GetCashbackTermsTC])

  return (
    <div className={style.wrap}>
      <div className={style.content}>
        <HowItWorksLeftBar />
        {isCommissionEnabled && <HowItWorksRightBar />}
      </div>
    </div>
  )
}
