import { FC } from 'react'

interface ShareProps {
  width?: number
  height?: number
  className?: string
}

export const Share: FC<ShareProps> = ({ className, height = 14, width = 14 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50835 0.957031H12.3483C12.6513 0.957031 12.8969 1.19903 12.8969 1.49755V5.28117C12.8969 5.57969 12.6513 5.82169 12.3483 5.82169C12.0454 5.82169 11.7998 5.57969 11.7998 5.28117V2.80247L5.60483 8.90648C5.3906 9.11756 5.04326 9.11756 4.82903 8.90648C4.6148 8.69539 4.6148 8.35316 4.82903 8.14207L11.024 2.03807H8.50835C8.20539 2.03807 7.95978 1.79607 7.95978 1.49755C7.95978 1.19903 8.20539 0.957031 8.50835 0.957031ZM4.56168 1.03278L4.52939 1.03278H4.52938C4.27735 1.03276 4.11141 1.03274 3.96557 1.0455C2.34948 1.18689 1.06818 2.46819 0.926788 4.08429C0.914031 4.23011 0.914044 4.39603 0.914064 4.64803V4.6481L0.914068 4.6804V7.03796L0.914067 7.08106C0.914054 8.29833 0.914043 9.27104 1.01402 10.0362C1.11729 10.8267 1.33636 11.4822 1.83929 12.0089C1.87478 12.0461 1.91113 12.0824 1.9483 12.1179C2.47498 12.6208 3.13051 12.8399 3.92096 12.9432C4.68616 13.0432 5.65889 13.0431 6.87617 13.0431H6.91924H9.44507L9.47659 13.0431C9.72268 13.0432 9.8847 13.0432 10.0271 13.031C11.6487 12.8925 12.9349 11.6063 13.0734 9.98475C13.0855 9.84234 13.0855 9.68032 13.0855 9.43424L13.0855 9.40272C13.0855 9.07135 12.8169 8.80272 12.4855 8.80272C12.1541 8.80272 11.8855 9.07135 11.8855 9.40272C11.8855 9.69142 11.8849 9.79804 11.8777 9.88263C11.7889 10.9221 10.9645 11.7466 9.92499 11.8354C9.8404 11.8426 9.73377 11.8431 9.44507 11.8431H6.91924C5.64896 11.8431 4.75482 11.8419 4.07642 11.7533C3.41426 11.6668 3.04536 11.5063 2.77704 11.25L2.36267 11.684L2.77704 11.25C2.75321 11.2273 2.72991 11.204 2.70716 11.1802C2.45093 10.9118 2.29042 10.5429 2.20391 9.88078C2.11527 9.20238 2.11407 8.30824 2.11407 7.03796V4.6804C2.11407 4.3847 2.11464 4.2755 2.12222 4.18887C2.21286 3.15292 3.0342 2.33157 4.07016 2.24094C4.15678 2.23336 4.26599 2.23278 4.56168 2.23278C4.89305 2.23278 5.16168 1.96415 5.16168 1.63278C5.16168 1.30141 4.89305 1.03278 4.56168 1.03278Z"
        fill="currentColor"
      />
    </svg>
  )
}
