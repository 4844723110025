import { useContext } from 'react'
import { CarouselContext } from '../context/CarouselContext'
import type { CarouselContextProps } from '../types/carousel.types'

export function useCarousel(): CarouselContextProps {
  const context = useContext(CarouselContext)

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />')
  }

  return context
}
