import * as React from 'react'

type SpotProps = {
  className?: string
}

export const Spot: React.FC<SpotProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={20} fill="none" className={className}>
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M8.645 14.16c-1.596 0-2.736-.804-3.18-2.064l.96-.552c.3.924 1.02 1.524 2.244 1.524 1.188 0 1.776-.54 1.776-1.296 0-.864-.744-1.152-1.98-1.536-1.344-.432-2.64-.852-2.64-2.412 0-1.512 1.236-2.376 2.64-2.376 1.38 0 2.376.78 2.844 1.836l-.936.54c-.3-.72-.888-1.296-1.908-1.296-.9 0-1.524.48-1.524 1.248 0 .78.588 1.068 1.764 1.44 1.464.48 2.844.912 2.844 2.532 0 1.476-1.188 2.412-2.904 2.412Z"
      />
    </g>
    <rect width={16} height={19} x={0.5} y={0.503} stroke="#37363E" rx={3.5} />
    <defs>
      <clipPath id="a">
        <rect width={17} height={20} y={0.003} fill="#fff" rx={4} />
      </clipPath>
    </defs>
  </svg>
)
