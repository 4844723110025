import { FC } from 'react'
import { PartnerWidget } from './PartnerLeftBar.components'

import styles from './style.module.scss'

export const PartnerLeftBar: FC = () => {
  return (
    <div className={styles.leftBar}>
      <PartnerWidget />
    </div>
  )
}
