import { t } from '@lingui/macro'

export const getProfileRightBarTranslations = () => {
  return {
    setPositionModeErrors: {
      '40022': t({ id: 'setPositionMode.error.40022', comment: 'Unable to change position mode (no need).' }),
      '40023': t({ id: 'setPositionMode.error.40023', comment: 'Unable to change position mode (open orders).' }),
      '40024': t({ id: 'setPositionMode.error.40024', comment: 'Unable to change position mode (open positions).' }),
    },
  }
}
