import { TUsdmIncomeHistory } from '@tigertrade/binance-ts'
import { t } from '@lingui/macro'

export const getBinanceHistoryKeysTranslations = (): Record<keyof TUsdmIncomeHistory, string> => {
  return {
    asset: t({
      id: 'exchangeHistoryBinance.asset',
      comment: 'Asset',
    }),
    income: t({
      id: 'exchangeHistoryBinance.income',
      comment: 'Income',
    }),
    incomeType: t({
      id: 'exchangeHistoryBinance.incomeType',
      comment: 'Income type',
    }),
    info: t({
      id: 'exchangeHistoryBinance.info',
      comment: 'Info',
    }),
    symbol: t({
      id: 'exchangeHistoryBinance.symbol',
      comment: 'Symbol',
    }),
    time: t({
      id: 'exchangeHistoryBinance.time',
      comment: 'Time',
    }),
    tradeId: t({
      id: 'exchangeHistoryBinance.tradeId',
      comment: 'Trade ID',
    }),
    tranId: t({
      id: 'exchangeHistoryBinance.tranId',
      comment: 'Tx ID',
    }),
  }
}
export const getBinanceIncomeTypes = (): Record<string, string> => {
  return {
    TRANSFER: t({
      id: 'exchangeHistoryBinance.transfer',
      comment: 'TRANSFER',
    }),
    WELCOME_BONUS: t({
      id: 'exchangeHistoryBinance.welcomeBonus',
      comment: 'WELCOME_BONUS',
    }),
    REALIZED_PNL: t({
      id: 'exchangeHistoryBinance.realizedPnl',
      comment: 'REALIZED_PNL',
    }),
    FUNDING_FEE: t({
      id: 'exchangeHistoryBinance.fundingFee',
      comment: 'FUNDING_FEE',
    }),
    COMMISSION: t({
      id: 'exchangeHistoryBinance.commission',
      comment: 'COMMISSION',
    }),
    INSURANCE_CLEAR: t({
      id: 'exchangeHistoryBinance.insuranceClear',
      comment: 'INSURANCE_CLEAR',
    }),
  }
}

export const getConvertHistoryKeysTranslations = (): Record<string, string> => {
  return {
    date: t({
      id: 'convertHistory.table.date',
      comment: 'Creation date',
    }),
    pair: t({
      id: 'convertHistory.table.pair',
      comment: 'Trading pair',
    }),
    from: t({
      id: 'convertHistory.table.from',
      comment: 'From',
    }),
    to: t({
      id: 'convertHistory.table.to',
      comment: 'To',
    }),
    price: t({
      id: 'convertHistory.table.price',
      comment: 'Conversion price',
    }),
    status: t({
      id: 'convertHistory.table.status',
      comment: 'Order status',
    }),
  }
}
