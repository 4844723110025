interface IDescriptionProps {
  className?: string
}

export const Description = (props: IDescriptionProps) => {
  const { className } = props

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.1284 17.2441C19.1284 17.5713 18.8606 17.8391 18.5333 17.8391H7.80859L9.01071 16.6416H18.5333C18.8606 16.6416 19.1284 16.9168 19.1284 17.2441Z"
        fill="#676573"
      />
      <path
        d="M7.05698 17.1552L15.1791 9.04787L13.8701 7.7388L5.75535 15.8461L5.04131 17.5048C4.97437 17.6832 5.16032 17.8915 5.33882 17.8171L7.05698 17.1552ZM15.8262 8.40821L16.5775 7.67186C16.9643 7.29252 16.9866 6.88344 16.637 6.54129L16.3915 6.28841C16.0494 5.9537 15.6403 5.98345 15.2684 6.35535L14.5097 7.09914L15.8262 8.40821Z"
        fill="#676573"
      />
    </svg>
  )
}
