import { useMobileSizeDetect } from '@/utils'
import { FC } from 'react'
import { OrdersAndPositionsMobile } from './OrdersAndPositionsMobile'
import { OrdersAndPositionsPC } from './OrdersAndPositionsPC'

export const OrdersAndPositions: FC = () => {
  const [isMobile] = useMobileSizeDetect()

  return isMobile ? <OrdersAndPositionsMobile /> : <OrdersAndPositionsPC />
}
