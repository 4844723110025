import { Select } from '@/ui/kit'
import { ExchangeOptions } from '@/ui/organisms/HistoryTrades/components/TableFilters/options/ExchangeOptions'
import style from './style.module.scss'

import { useActions, useAppSelector } from '@/utils'
import { tradesActions } from '@/redux/trades/trades.slice'
import clsx from 'clsx'
import useExchangeTypeHandler from '@/ui/organisms/DiaryRightBar/hooks/useExchangeFilter'
import { useEffect } from 'react'
import { useExchageType } from '@/utils/hooks/useExchangeType'

export const WeekExchangeFilter = () => {
  const { exchangeType } = useExchageType()

  const {
    diaryWeekFilter: filters,
    me,
    diaryWeekExchangeFilterValue,
    availableExchangeKeys,
  } = useAppSelector(state => state.trades)

  const { setDiaryWeekFilter, setDiaryWeekExchangeType, GetMeTC } = useActions(tradesActions)
  const { exchangeTypeHandler } = useExchangeTypeHandler({
    filterValue: diaryWeekExchangeFilterValue,
    setExchangeType: setDiaryWeekExchangeType,
    setFilter: setDiaryWeekFilter,
    filters: filters,
  })

  useEffect(() => {
    GetMeTC()
  }, [exchangeType])

  return (
    <>
      <Select
        onChange={exchangeTypeHandler}
        size={Select.Size.Small}
        value={
          diaryWeekExchangeFilterValue
            ? diaryWeekExchangeFilterValue
            : ExchangeOptions(availableExchangeKeys?.activeExchangeAccounts.map(record => record.exchangeType))[0].value
        }
        options={
          me?.api_keys_list
            ? ExchangeOptions(availableExchangeKeys?.activeExchangeAccounts.map(record => record.exchangeType))
            : []
        }
        variant={Select.Variant.Raw}
        className={clsx(style.select, style.selectContainer, style.exchange)}
      />
    </>
  )
}
