import { useOverview } from '@/utils/hooks/useOverview'

import { useMemo } from 'react'
import { useTradingHistory } from '@/utils/hooks/useTradingHistory'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { TInstrumentType } from '@/core/types/overview'
import { TExchangesAvailable } from '@/core/constants'
import { EAccountType } from '@tigertrade/binance-ts'
import { EBybitInstruments } from '@/services/bybit'
import { EOkxInstruments } from '@/services/okx'
import { useAppSelector } from '@/utils'
import { getAccountTypeForInstrument } from '@/redux/overview/overview.utils'

const instrumentMap: Record<TExchangesAvailable, TInstrumentType> = {
  BINANCE_BROKER_FUTURE: EAccountType.USDT_FUTURE,
  BYBIT_BROKER: EBybitInstruments.linear,
  OKX_BROKER: EOkxInstruments.SWAP,
  BINANCE_VIP: EAccountType.USDT_FUTURE,
  OKX_AFFILIATE: EOkxInstruments.SWAP,
}

const DEFAULT_OVERVIEW = {
  totalMarginBalance: '0',
  unrealizedPnl: '0',
}

export const useStatisticsWidget = () => {
  const { resetStatistics, statisticsMoment, statisticsStartMoment } = useTradingHistory(true)

  const { statusBalances, balances } = useAppSelector(state => state.overview)
  const { positions, balancesArray } = useOverview()
  const { exchangeType } = useExchageType()

  const isLoading = statusBalances !== 'succeeded'
  const instrument = exchangeType ? instrumentMap[exchangeType] : null

  const overviewByAccount = useMemo(() => {
    return { ...DEFAULT_OVERVIEW, ...(instrument && positions[instrument]) }
  }, [positions, instrument])

  const { symbol } = useMemo(() => {
    const acountType = instrument && exchangeType ? getAccountTypeForInstrument(instrument, exchangeType) : null
    return acountType ? balances[acountType] : balances.BINANCE_FUTURE
  }, [balances, instrument, exchangeType])

  return {
    ...overviewByAccount,
    exchangeType,
    symbol,
    isLoading,
    balancesArray,
    statisticsStartMoment,
    statisticsMoment,
    resetStatistics,
  }
}
