import React from 'react'

interface AddressProps {
  className?: string
}

const Block: React.FC<AddressProps> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.5" width="40" height="40" rx="20" fill="#1C1A24" />
      <path
        d="M22.6582 11.3333H17.3416C16.7183 11.3333 15.8382 11.6999 15.3982 12.1399L11.6399 15.8983C11.1999 16.3383 10.8333 17.2183 10.8333 17.8416V23.1582C10.8333 23.7816 11.1999 24.6616 11.6399 25.1016L15.3982 28.8599C15.8382 29.2999 16.7183 29.6666 17.3416 29.6666H22.6582C23.2816 29.6666 24.1616 29.2999 24.6016 28.8599L28.3599 25.1016C28.7999 24.6616 29.1666 23.7816 29.1666 23.1582V17.8416C29.1666 17.2183 28.7999 16.3383 28.3599 15.8983L24.6016 12.1399C24.1616 11.6999 23.2816 11.3333 22.6582 11.3333Z"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5283 26.99L26.49 14.0283"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { Block }
