import {
  GetAssetsForTransactionsTC,
  GetAssetsForWithdrawAndDepositTC,
  GetCoinsBalanceTC,
  GetDepositWalletAddressTC,
  TransferAssetTC,
  WithdrawAssetTC,
  GetExchangeHistoryTC,
  GetWithdrawAddressTC,
  GetHistoryConvertInfoTC,
} from './transactions.thunks'

export const transactionsAsyncActions = {
  TransferAssetTC,
  GetAssetsForTransactionsTC,
  GetDepositWalletAddressTC,
  GetAssetsForWithdrawAndDepositTC,
  GetCoinsBalanceTC,
  WithdrawAssetTC,
  GetWithdrawAddressTC,
  GetExchangeHistoryTC,
  GetHistoryConvertInfoTC,
}
