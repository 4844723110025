import React from 'react'

export const Deposit = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#1C1A24"
      />
      <path
        d="M22.7876 25.7459C22.7876 25.7459 17.5867 25.7459 14.2542 25.7459M14.2542 25.7459C14.2542 22.4133 14.2542 17.2124 14.2542 17.2124M14.2542 25.7459C19.0149 20.9851 25.7458 14.2542 25.7458 14.2542"
        stroke="url(#paint0_radial_1010_3990)"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1010_3990"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.5209 30.0126) rotate(-85.6898) scale(18.5104 244.458)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
