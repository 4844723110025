import clsx from 'clsx'
import { FC } from 'react'
import style from './style.module.scss'

interface ColorizedValueProps {
  value: number
  symbol?: string
}

export const ColorizedValue: FC<ColorizedValueProps> = ({ value, symbol }) => (
  <div
    className={clsx({
      [style.positive]: value > 0,
      [style.neutral]: value === 0,
      [style.negative]: value < 0,
    })}
  >
    {value}
    {symbol ? ` ${symbol}` : ''}
  </div>
)
