export const customLocalStorage = {
  get: (key: string) => {
    const localStorageItem = localStorage.getItem(key)
    try {
      return localStorageItem && JSON.parse(localStorageItem)
    } catch (e) {
      return undefined
    }
  },

  set: (key: string, data?: unknown) => {
    localStorage.setItem(key, JSON.stringify(data))
  },

  remove: (key: string) => {
    localStorage.removeItem(key)
  },
}
