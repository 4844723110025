import { FC, MouseEventHandler } from 'react'
import s from './style.module.scss'
import clsx from 'clsx'
import { useMobileSizeDetect } from '@/utils'

export type HeaderMenuButtonProps = {
  /** text */
  text: string

  /** url */
  onClick: MouseEventHandler<HTMLButtonElement>

  dataTestId?: string
}

export const HeaderActionButton: FC<HeaderMenuButtonProps> = ({ text, onClick, dataTestId }) => {
  const [isMobile] = useMobileSizeDetect()

  return (
    <button className={s.button} onClick={onClick} data-testid={dataTestId}>
      <span
        className={clsx({
          [s.text]: !isMobile,
          [s.textMobile]: isMobile,
        })}
      >
        {text}
      </span>
    </button>
  )
}
