import { Status } from '@/backend/types'
import { ApiKeyCreationType, ApiKeyType } from './apiKey.types'
import { FieldErrorType } from '@/types'
import { IApiKeyStatisticsDTO } from '@/backend/models/ApiKeyDTO'

export type InitialState = {
  apiKeys: ApiKeyType[]
  status: Status
  statusStatistics: Status
  statistics: IApiKeyStatisticsDTO
  apiKeyById?: ApiKeyType
  apiKeyByIdStatus: Status
  createdApiKeyResult?: ApiKeyCreationType
  createdApiKeyStatus: Status
  updatedApiKeyStatus: Status
  createdMasterApiKeyStatus: Status
  createdApiReadOnlyStatus: Status
  createdApiReadOnlyStatusInitial: Status
  apiKeyStatusRemove: Status
  errors: FieldErrorType[]
}
export const initialState: InitialState = {
  apiKeys: [],
  status: 'idle',
  statusStatistics: 'idle',
  statistics: {
    apiKeyCount: 0,
  },
  apiKeyByIdStatus: 'idle',
  createdApiKeyStatus: 'idle',
  updatedApiKeyStatus: 'idle',
  createdMasterApiKeyStatus: 'idle',
  createdApiReadOnlyStatus: 'idle',
  createdApiReadOnlyStatusInitial: 'idle',
  apiKeyStatusRemove: 'idle',
  errors: [],
}
