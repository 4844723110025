export const Notify = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1167 12.075L15.2833 10.6917C15.1083 10.3833 14.95 9.79999 14.95 9.45832V7.34999C14.95 5.39166 13.8 3.69999 12.1417 2.90832C11.7083 2.14166 10.9083 1.66666 9.99166 1.66666C9.08333 1.66666 8.26666 2.15832 7.83333 2.93332C6.20833 3.74166 5.08333 5.41666 5.08333 7.34999V9.45832C5.08333 9.79999 4.92499 10.3833 4.74999 10.6833L3.90833 12.075C3.57499 12.6333 3.49999 13.25 3.70833 13.8167C3.90833 14.375 4.38333 14.8083 4.99999 15.0167C6.61666 15.5667 8.31666 15.8333 10.0167 15.8333C11.7167 15.8333 13.4167 15.5667 15.0333 15.025C15.6167 14.8333 16.0667 14.3917 16.2833 13.8167C16.5 13.2417 16.4417 12.6083 16.1167 12.075Z"
        fill="#FAFAFA"
      />
      <path
        d="M12.3584 16.675C12.0084 17.6417 11.0834 18.3333 10 18.3333C9.34169 18.3333 8.69169 18.0667 8.23336 17.5917C7.96669 17.3417 7.76669 17.0083 7.65002 16.6667C7.75836 16.6833 7.86669 16.6917 7.98336 16.7083C8.17502 16.7333 8.37502 16.7583 8.57502 16.775C9.05002 16.8167 9.53336 16.8417 10.0167 16.8417C10.4917 16.8417 10.9667 16.8167 11.4334 16.775C11.6084 16.7583 11.7834 16.75 11.95 16.725C12.0834 16.7083 12.2167 16.6917 12.3584 16.675Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
