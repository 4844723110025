import { Links } from './Links'
import { useHashNavigation } from '@/utils/hooks/useHashNavigation'
import style from './style.module.scss'
import { PolicyTermsWrapper } from './components/PolicyTermsWrapper'
import { useMobileSizeDetect } from '@/utils'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { Loader } from '@/ui/kit/Loader'
import { useLoadCallback } from '@/utils/hooks/useLoadCallback'

const MobileTermsOfUse = React.lazy(() => import('./components/tabs/MobileTermsOfUse'))
const DesktopTermsOfUse = React.lazy(() => import('./components/pages/DesktopTermsOfUse'))

const links = [
  { id: 'terms', title: 'Terms and conditions' },
  { id: 'general', title: 'General provisions' },
  { id: 'scope', title: 'Scope of exchange API provider service functionality' },
  { id: 'assets', title: 'Digital assets wallet' },
  { id: 'disclaimer', title: 'Disclaimer and related provisions' },
  { id: 'signin', title: 'Sign in and use of the account' },
  { id: 'termination', title: 'Termination of the user agreement' },
  { id: 'cashback', title: 'Terms of use of the exchange API provider service' },
  { id: 'rights', title: 'Exclusive rights to the exchange API provider service content' },
  { id: 'third', title: 'Third party sites and content' },
  { id: 'confidentiality', title: 'Confidentiality' },
  { id: 'limitation', title: 'No warranties, limitation of liability' },
  { id: 'miscellaneous', title: 'Miscellaneous' },
  { id: 'annex', title: 'Annex 1 – Prohibited Jurisdictions' },
]

export const TermsAndConditions = () => {
  const { isContentLoaded, setIsContentLoaded } = useLoadCallback()
  const [isMobile] = useMobileSizeDetect()
  const { active } = useHashNavigation(links, isContentLoaded)

  if (isMobile) {
    return (
      <PolicyTermsWrapper>
        <Suspense fallback={<Loader />}>
          <MobileTermsOfUse />
        </Suspense>
      </PolicyTermsWrapper>
    )
  }

  return (
    <div className={style.root}>
      <div className={style.left}>
        <div className={style.line}>
          <div className={style.activeLine} />
        </div>
        <Links links={links} active={active} />
      </div>
      <Suspense fallback={<Loader />}>
        <DesktopTermsOfUse loadCallback={setIsContentLoaded} />
      </Suspense>
    </div>
  )
}
