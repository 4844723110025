import React from 'react'

interface OkxSmallProps {
  className?: string
}

const OkxSmall: React.FC<OkxSmallProps> = props => {
  const { className } = props

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_14678_146992)">
        <path
          d="M14.1331 7.3916H7.89663C7.6319 7.3916 7.41489 7.60862 7.41489 7.87336V14.11C7.41489 14.3748 7.6319 14.5918 7.89663 14.5918H14.1331C14.3978 14.5918 14.6149 14.3748 14.6149 14.11V7.87336C14.6149 7.60862 14.3978 7.3916 14.1331 7.3916Z"
          fill="#F4F4F4"
        />
        <path
          d="M6.91826 0.199951H0.681785C0.417058 0.199951 0.200043 0.416973 0.200043 0.681708V6.91838C0.200043 7.18311 0.417058 7.40013 0.681785 7.40013H6.91826C7.18299 7.40013 7.40001 7.18311 7.40001 6.91838V0.681708C7.39847 0.416973 7.18299 0.199951 6.91826 0.199951Z"
          fill="#F4F4F4"
        />
        <path
          d="M21.318 0.199951H15.0815C14.8168 0.199951 14.5998 0.416973 14.5998 0.681708V6.91838C14.5998 7.18311 14.8168 7.40013 15.0815 7.40013H21.318C21.5827 7.40013 21.7998 7.18311 21.7998 6.91838V0.681708C21.7998 0.416973 21.5827 0.199951 21.318 0.199951Z"
          fill="#F4F4F4"
        />
        <path
          d="M6.91826 14.5999H0.681785C0.417058 14.5999 0.200043 14.8169 0.200043 15.0816V21.3183C0.200043 21.583 0.417058 21.8 0.681785 21.8H6.91826C7.18299 21.8 7.40001 21.583 7.40001 21.3183V15.0816C7.39847 14.8169 7.18299 14.5999 6.91826 14.5999Z"
          fill="#F4F4F4"
        />
        <path
          d="M21.318 14.5999H15.0815C14.8168 14.5999 14.5998 14.8169 14.5998 15.0816V21.3183C14.5998 21.583 14.8168 21.8 15.0815 21.8H21.318C21.5827 21.8 21.7998 21.583 21.7998 21.3183V15.0816C21.7998 14.8169 21.5827 14.5999 21.318 14.5999Z"
          fill="#F4F4F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_14678_146992">
          <rect width="21.5999" height="21.6001" fill="white" transform="translate(0.200043 0.199951)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export { OkxSmall }
