import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { UserAvatar } from '@/ui/atoms/UserAvatar'
import { floorNumber, formatNumber, useAppSelector } from '@/utils'
import { useOverview } from '@/utils/hooks/useOverview'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import s from './style.module.scss'

export const MobileProfile: FC = () => {
  const { balancesArray } = useOverview()
  const { totalBalance, statusBalances } = useAppSelector(state => state.overview)
  const accountBalance = Number(totalBalance)?.toFixed(0)

  const text = useMemo(() => {
    return {
      balance: t({ id: 'mainPage.accountBalance.title', message: 'Account balance' }),
      noCoins: t({ id: 'mainPage.accountBalance.noCoins', message: 'No coins' }),
    }
  }, [])

  return (
    <section className={s.container}>
      <div className={s.profile}>
        <div className={s.avatarWrapper}>
          <UserAvatar size={36} bordered className={s.avatar} />
          <span className={s.commonBalance}>{text.balance}</span>
        </div>

        {statusBalances !== 'succeeded' && <div className={s.skeletonCoins} />}

        {statusBalances === 'succeeded' && (
          <div className={s.amount}>
            {Number(accountBalance) === 0 ? (
              <span data-testid={DataTestIds.AccountBalanceNoCoins}>{text.noCoins}</span>
            ) : (
              <>
                <span data-testid={DataTestIds.AccountBalanceValue}>≈ {formatNumber(accountBalance)} </span>
                <span>USDT</span>
              </>
            )}
          </div>
        )}
      </div>

      {statusBalances !== 'succeeded' && (
        <table className={s.table}>
          <tbody>
            <tr className={s.row}>
              {balancesArray.map((_, index) => (
                <td className={s.skeletonColumns} key={index}>
                  <div
                    className={clsx(s.skeletonContentWrapper, {
                      [s.first]: index === 0,
                      [s.middle]: index > 0 && index < balancesArray.length - 1,
                      [s.last]: index === balancesArray.length - 1,
                    })}
                  >
                    <div className={s.skeletonTitle} />
                    <div className={s.skeletonCurrency} />
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      )}

      {statusBalances === 'succeeded' && (
        <table className={s.table}>
          <tbody>
            <tr className={s.row}>
              {balancesArray.map((item, index) => {
                const amount = item.symbol === 'BTC' ? formatNumber(item.amount || 0) : floorNumber(item.amount || 0)

                return (
                  <td
                    key={index}
                    className={clsx({
                      [s.first]: index === 0,
                      [s.middle]: index > 0 && index < balancesArray.length - 1,
                      [s.last]: index === balancesArray.length - 1,
                    })}
                  >
                    <div className={s.columnInfo} data-testid={`${DataTestIds.AccountInfoBalance}-${item.id}`}>
                      <span className={s.label}>{item.label}</span>

                      <div className={s.currency}>
                        <span>{amount} </span>
                        <span>{item.symbol}</span>
                      </div>
                    </div>
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>
      )}
    </section>
  )
}
