import style from './style.module.scss'
import { ButtonVariant, InteractiveModalParts } from '../../kit'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../../router/urls'
import { t } from '@lingui/macro'

export const WalletUnderReviewAfterStep1KYC = () => {
  const navigate = useNavigate()
  return (
    <div className={style.container}>
      <div className={style.header}>
        {t({ id: 'mainPage.underReview1.title', message: 'Verification in progress' })}
      </div>
      <div className={style.text}>
        {t({
          id: 'mainPage.underReview1.subTitle',
          message: 'Provide the required documents to finish the verification process',
        })}
      </div>
      <InteractiveModalParts.Button
        text={t({ message: 'Add data', id: 'core.addData' })}
        variant={ButtonVariant.Accent}
        onClick={() => navigate(urls.kyc)}
      />
    </div>
  )
}
