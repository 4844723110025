import { FC } from 'react'

interface CurrencyVolumeProps {
  baseCurrencyVolume: string
  baseCurrencySymbol: string
  quoteCurrencyVolume: string
  quoteCurrencySymbol: string
  className?: string
  baseClassName?: string
  quoteClassName?: string
}

export const CurrencyVolume: FC<CurrencyVolumeProps> = ({
  baseCurrencyVolume,
  baseCurrencySymbol,
  quoteCurrencySymbol,
  quoteCurrencyVolume,
  className,
  baseClassName,
  quoteClassName,
}) => (
  <div className={className}>
    <div className={baseClassName}>
      {baseCurrencyVolume} {baseCurrencySymbol}
    </div>
    <div className={quoteClassName}>
      {quoteCurrencyVolume} {quoteCurrencySymbol}
    </div>
  </div>
)
