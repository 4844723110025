import { DiaryData, MonthInfo, WeekInfo } from '@/utils/types/contracts.types'

const generateWeeksInRange = (from: string, to: string): WeekInfo[] => {
  const weeks: WeekInfo[] = []
  const fromDate = new Date(from)
  const toDate = new Date(to)

  const adjustedFromDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1)

  const adjustedToDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0)

  let currentMonday = new Date(adjustedFromDate)
  currentMonday.setDate(currentMonday.getDate() - ((currentMonday.getDay() + 6) % 7))

  let currentSunday = new Date(currentMonday)
  currentSunday.setDate(currentMonday.getDate() + 6)

  while (currentMonday <= adjustedToDate) {
    weeks.push({
      month: currentMonday.getMonth() + 1,
      firstDayOfWeek: formatDate(currentMonday),
      lastDayOfWeek: formatDate(currentSunday),
      data: [],
    })

    currentMonday.setDate(currentMonday.getDate() + 7)
    currentSunday.setDate(currentSunday.getDate() + 7)
  }

  return weeks
}

export const mapBackendDataToWeeks = (from: string, to: string, backendData: DiaryData[]): WeekInfo[] => {
  const weeks = generateWeeksInRange(from, to)
  backendData.forEach(data => {
    for (const week of weeks) {
      if (data.from === week.firstDayOfWeek && data.to === week.lastDayOfWeek) {
        week.data.push(data)
      }
    }
  })
  return weeks
}

export const extractDates = (openBetween: string): { from: string; to: string } => {
  const [from, to] = openBetween.split(',')
  return { from, to }
}

export const formatDate = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export function groupWeeksByMonth(weeks: WeekInfo[]): MonthInfo[] {
  const sortedWeeks = weeks.slice().sort((a, b) => {
    const dateA = new Date(a.firstDayOfWeek)
    const dateB = new Date(b.firstDayOfWeek)
    return dateA.getFullYear() - dateB.getFullYear() || dateA.getMonth() - dateB.getMonth()
  })

  const groupedWeeks = sortedWeeks.reduce((acc, week) => {
    const firstDate = new Date(week.firstDayOfWeek)
    const lastDate = new Date(week.lastDayOfWeek)
    const year = firstDate.getFullYear()
    const startMonth = firstDate.getMonth() + 1
    const endMonth = lastDate.getMonth() + 1

    const startMonthKey = `${year}-${String(startMonth).padStart(2, '0')}`
    const endMonthKey = `${year}-${String(endMonth).padStart(2, '0')}`

    if (!acc[startMonthKey]) {
      acc[startMonthKey] = []
    }
    if (
      !acc[startMonthKey].some(
        existingWeek =>
          existingWeek.firstDayOfWeek === week.firstDayOfWeek && existingWeek.lastDayOfWeek === week.lastDayOfWeek
      )
    ) {
      acc[startMonthKey].push(week)
    }

    if (startMonth !== endMonth) {
      if (!acc[endMonthKey]) {
        acc[endMonthKey] = []
      }
      if (
        !acc[endMonthKey].some(
          existingWeek =>
            existingWeek.firstDayOfWeek === week.firstDayOfWeek && existingWeek.lastDayOfWeek === week.lastDayOfWeek
        )
      ) {
        acc[endMonthKey].push(week)
      }
    }

    return acc
  }, {} as { [monthKey: string]: WeekInfo[] })

  const filteredGroupedWeeks = Object.entries(groupedWeeks)
    .filter(([_, weeks]) => weeks.some(week => week.data.length > 0))
    .map(([monthKey, weeks]) => ({ monthKey, weeks }))
  return filteredGroupedWeeks.reverse()
}

export const clubArray = (arr: MonthInfo[]) => {
  return arr.reduce((acc: MonthInfo[], val) => {
    const existingIndex = acc.findIndex((el: MonthInfo) => el.monthKey === val.monthKey)
    if (existingIndex !== -1) {
      // Merge weeks data when monthKey matches
      const mergedWeeks = [...acc[existingIndex].weeks, ...val.weeks]
      // Remove duplicates from merged weeks array
      const uniqueWeeks = mergedWeeks.filter((week, index, array) => {
        return (
          index ===
          array.findIndex(w => w.lastDayOfWeek === week.lastDayOfWeek && w.firstDayOfWeek === week.firstDayOfWeek)
        )
      })
      // Sort unique weeks array in ascending order based on firstDayOfWeek
      const sortedUniqueWeeks = uniqueWeeks.sort(
        (a, b) => new Date(a.firstDayOfWeek).getTime() - new Date(b.firstDayOfWeek).getTime()
      )
      acc[existingIndex].weeks = sortedUniqueWeeks
    } else {
      // Add new object to the accumulator array
      acc.push(val)
    }
    return acc
  }, [])
}
