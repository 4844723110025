import { ReactNode, useCallback, useState } from 'react'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { Alert } from '@/ui/kit/Alert'
import { Button, InteractiveModal } from '@/ui/kit'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { SetupGoogleAuth } from '../SetupGoogleAuth'
import { UseGoogleAuthCodeResult, UseGoogleAuthSettingsResult, useMobileSizeDetect } from '@/utils/hooks'
import style from './style.module.scss'
import clsx from 'clsx'

type FlowStep = 'intro' | 'google_auth' | 'google_auth_success'

interface Popup2FAWithIntroProps {
  header: ReactNode
  isOpen?: boolean
  isIgnoreIntro?: boolean
  onClose: () => void
  onSuccess?: () => void
  googleAuthSettings: UseGoogleAuthSettingsResult
  googleAuthCode: UseGoogleAuthCodeResult
}

export const Popup2FAWithIntro = (props: Popup2FAWithIntroProps) => {
  const { header, onClose, onSuccess, googleAuthSettings, googleAuthCode, isOpen, isIgnoreIntro } = props

  const [isMobile] = useMobileSizeDetect()
  const [flowStep, setFlowStep] = useState<FlowStep>(isIgnoreIntro ? 'google_auth' : 'intro')

  const handleClickBackCodeVerification = useCallback(() => {
    if (isIgnoreIntro) return onClose()
    setFlowStep('intro')
  }, [isIgnoreIntro, onClose])

  const handleSuccess = () => {
    googleAuthSettings.fetchGoogleAuthSettings()
    onSuccess?.()
  }

  if (!isOpen) return null

  return (
    <>
      <InteractiveModal isOpen={flowStep === 'intro'}>
        <ErrorBase
          customHeader={t({
            id: 'popupSequence.2fa.title',
            message: `Secure your transactions`,
          })}
          customText={t({
            id: 'popupSequence.2fa.subtitle',
            message: `Before you can deposit, confirm your account with the Google Authenticator app`,
          })}
          className={style.container}
          isCloseWindowVisible
          customComponent={
            <div className={style.buttonsWrapper}>
              <Button.Primary
                leftIcon={<SVG.OtherIcons.Google className={style.buttonIcon} />}
                onClick={() => setFlowStep('google_auth')}
                label={t({
                  id: 'services.gAuth.title',
                  message: `Google Authenticator`,
                })}
                className={clsx(isMobile && style.mobile, style.button)}
              />
            </div>
          }
          closeWindowText={t({
            id: 'core.back',
            message: `Back`,
          })}
          onClickCloseCallback={onClose}
        />
      </InteractiveModal>

      {flowStep === 'google_auth' && (
        <SetupGoogleAuth
          header={header}
          googleAuthSettings={googleAuthSettings}
          googleAuthCode={googleAuthCode}
          onSuccess={() => {
            setFlowStep('google_auth_success')
          }}
          onBackClick={handleClickBackCodeVerification}
        />
      )}

      {flowStep === 'google_auth_success' && (
        <Alert
          customText={t({
            id: 'services.gAuth.alert',
            message: `You’ve enabled Google Authenticator verification`,
          })}
          onClose={handleSuccess}
        />
      )}
    </>
  )
}
