import { FC, useEffect, useCallback } from 'react'
import { Input, InputSize } from '../Input'
import styles from './style.module.scss'
import { useForm, Controller } from 'react-hook-form'

type InputTimePickerProps = {
  onChange: (time: string) => void
  initialValue: string
}

const InputTimePicker: FC<InputTimePickerProps> = ({ onChange, initialValue }) => {
  const [initHours, initMinutes, initSeconds] = initialValue.split(':').map(Number)

  const formatTimeValue = useCallback((value: number): string => {
    return value ? value.toString().padStart(2, '0') : '00'
  }, [])

  const { control, setValue, getValues } = useForm<any>({
    defaultValues: {
      hours: '',
      minutes: '',
      seconds: '',
    },
  })
  useEffect(() => {
    if (initialValue) {
      setValue('hours', formatTimeValue(initHours))
      setValue('minutes', formatTimeValue(initMinutes))
      setValue('seconds', formatTimeValue(initSeconds))
    }
  }, [initHours, initMinutes, initSeconds])

  const handleChange = useCallback(
    (value: string, field: 'hours' | 'minutes' | 'seconds') => {
      const truncatedValue = value.slice(-2)
      const numericValue = parseInt(truncatedValue, 10)

      let newValue = Math.min(field === 'hours' ? 23 : 59, numericValue)
      newValue = Math.max(0, newValue)

      const paddedValue = formatTimeValue(newValue)

      setValue(field, paddedValue)

      const hours = formatTimeValue(getValues('hours'))
      const minutes = formatTimeValue(getValues('minutes'))
      const seconds = formatTimeValue(getValues('seconds'))

      const formattedTime = `${hours}:${minutes}:${seconds}`
      onChange(formattedTime)
    },
    [setValue, getValues, onChange, formatTimeValue]
  )

  return (
    <form>
      <div className={styles.inputContainer}>
        <Controller
          control={control}
          name="hours"
          render={({ field }) => (
            <Input
              containerClassName={styles.input}
              inputAttrs={{
                type: 'number',
                min: 0,
                max: 23,
                maxLength: 2,
              }}
              value={field.value}
              setValue={value => handleChange(value, field.name)}
              placeholder="HH"
              size={InputSize.ExtraSmall}
            />
          )}
        />
        <span className={styles.dots}>:</span>
        <Controller
          control={control}
          name="minutes"
          render={({ field }) => (
            <Input
              containerClassName={styles.input}
              inputAttrs={{
                type: 'number',
                min: 0,
                max: 59,
                maxLength: 2,
              }}
              value={field.value}
              setValue={value => handleChange(value, field.name)}
              placeholder="MM"
              size={InputSize.ExtraSmall}
            />
          )}
        />
        <span className={styles.dots}>:</span>
        <Controller
          control={control}
          name="seconds"
          render={({ field }) => (
            <Input
              containerClassName={styles.input}
              inputAttrs={{
                type: 'number',
                min: 0,
                max: 59,
                maxLength: 2,
              }}
              value={field.value}
              setValue={value => handleChange(value, field.name)}
              placeholder="SS"
              size={InputSize.ExtraSmall}
            />
          )}
        />
      </div>
    </form>
  )
}

export default InputTimePicker
