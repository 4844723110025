import { FC, Fragment, memo } from 'react'
import { TitleH2 } from '@/ui/atoms/TitleH2'
import BalanceSheet from '@/ui/molecules/BalanceSheet'
import { CashbackWidget } from '@/ui/organisms/CashbackWidget'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { useCashbackData } from '@/utils/hooks/data/useCashbackData'
import { ECashbackCalculationRule } from '@/backend/api/cashbackAPI'
import { Button, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { Table } from '@/ui/atoms/Table'
import { useToggle } from '@/utils'
import { useContent } from './CashbackLeftBar.hooks'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'

const CashbackLeftBar: FC = () => {
  const [isModalShown, toggleModalShown] = useToggle()
  const cashbackData = useCashbackData()

  const { balanceSheetData, commissionTableData, feesHeading, feesSubheading } = useContent()

  const { isCommissionEnabled } = useExchangesDisabled()

  if (!isCommissionEnabled) return null

  return (
    <div className={style.cashbackLeftBar}>
      <TitleH2
        className={style.title}
        label={t({
          id: 'cashbackPage.leftBar.title',
          message: 'Summary',
        })}
      />
      {balanceSheetData.length > 0 && <BalanceSheet balances={balanceSheetData} />}
      {/* TODO: вернуть, когда пропадут повышенные кэшбеки TTS-2430 */}
      {/* {cashbackData.calculationRule !== ECashbackCalculationRule.customReferralCode &&
        cashbackData.calculationRule !== ECashbackCalculationRule.allocationBalance && (
          <ProgressBar
            cashbackData={cashbackData}
            className={style.progress}
            label={
              t({
                id: 'cashbackPage.leftBar.tradingVolumeMonthly',
                message: 'Traded volume during the',
              }) +
              ' ' +
              t({
                id: 'cashbackPage.last30Days',
                message: 'last 30 days',
              })
            }
          />
        )} */}
      <CashbackWidget />
      <div className={style.btnWrap}>
        <Button.Primary onClick={toggleModalShown} label={feesHeading} className={style.btn} />
      </div>
      <InteractiveModal isOpen={isModalShown} className={style._flexAlignStart}>
        <InteractiveModalParts.SubHeader text={feesSubheading} />
        {commissionTableData.map((dataItem, index) => {
          return (
            <Fragment key={index}>
              <InteractiveModalParts.SubHeaderL3 text={dataItem.heading} />
              <Table
                {...dataItem.table}
                // isGridRow={cashbackData.calculationRule === ECashbackCalculationRule.bybitPro}
              />
            </Fragment>
          )
        })}
        <InteractiveModalParts.Button
          text={t({ id: 'core.close', message: `Close` })}
          variant={Button.Variant.Primary}
          onClick={toggleModalShown}
        />
      </InteractiveModal>
    </div>
  )
}

export default memo(CashbackLeftBar)
