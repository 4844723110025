import { memo } from 'react'
import { CopyIcon } from '../../kit'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { QRCodeIcon } from '@/ui/kit/QRCodeIcon'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useMobileSizeDetect } from '@/utils'
import clsx from 'clsx'

type DepositWalletAddressProps = {
  address: string
}

export const DepositWalletAddress = memo<DepositWalletAddressProps>(({ address }) => {
  const [isMobile] = useMobileSizeDetect()

  return (
    <div className={clsx(isMobile && style.mobile, style.root)}>
      <div className={style.info} data-testid={DataTestIds.DepositAddressTitle}>
        {t({ message: 'Your Spot wallet address', id: 'depositWalletAddress.title' })}
      </div>

      <div className={style.addressWrap} data-testid={DataTestIds.DepositAddress}>
        <span className={style.address}>{address}</span>
        <div className={style.addressButtons}>
          <QRCodeIcon text={address} className={clsx(isMobile && style.button)} />
          <CopyIcon text={address} className={clsx(isMobile && style.button, style.copyButton)} />
        </div>
      </div>
    </div>
  )
})
