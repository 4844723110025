import { FC, memo } from 'react'
import { t } from '@lingui/macro'
import ContentMainTab from '../../organisms/ContentMainTab'
import LabelWithQuestion from '../LabelWithQuestion'
import TopBarLabels from '../TopBarLabels'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

const TopBar: FC = () => {
  return (
    <div className={style.topBar}>
      <LabelWithQuestion
        hintText={t({
          message: 'This is an approximate balance of all assets',
          id: 'mainPage.topBar.approximateTitle',
        })}
        label={t({ message: 'Estimated balance', id: 'mainPage.topBar.balanceTitle' })}
        dataTestId={DataTestIds.WalletEstimatedBalanceText}
      />
      <ContentMainTab />
      <TopBarLabels />
    </div>
  )
}

export default memo(TopBar)
