import { t } from '@lingui/macro'
import { FC } from 'react'
import { SVG } from '@/assets/svg'
import { Button } from '@/ui/kit'
import style from './style.module.scss'
import { PopupRiskManager } from './PopupRiskManager/PopupRiskManager'
import { useActions, useAppSelector, useToggle } from '@/utils'
import { E_RISK_MANAGER_DRAWDOWN_TYPE } from '@/redux/riskManager/riskManager.types'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'
import { PopupRiskManagerSettings } from './PopupRiskManagerSettings/PopupRiskManagerSettings'

export const RiskManagerSettings: FC = () => {
  const [riskManagerPopup, toggleRiskManagerPopup] = useToggle(false)
  const [settingsPopup, toggleSettingsPopup] = useToggle(false)
  const { riskManagerSettings, tradingStatus } = useAppSelector(state => state.riskManager)
  const { DeleteRiskManagerTC, GetRiskManagerTC } = useActions(riskManagerActions)

  const isRMEnabled = riskManagerSettings?.enabled

  async function disabledRiskManager() {
    try {
      await DeleteRiskManagerTC()
      await GetRiskManagerTC
    } catch (err) {
      console.log('error disabledRiskManager', err)
    }
  }

  const isDisabledRM = riskManagerSettings?.canEdit ? tradingStatus?.tradingEnabled : false

  return (
    <>
      <div className={style.setting}>
        <SVG.Settings.Shield className={style.sectionIcon} />
        {!isRMEnabled && (
          <div>
            <div className={style.settingTitle}>
              {t({ id: 'riskManager.settings.riskSettings.title', comment: 'Block Trading' })}
            </div>
            <div className={style.settingStatus}>
              {t({
                id: 'riskManager.settings.riskSettings.description',
                comment: 'Block Trading description.',
              })}
            </div>
          </div>
        )}
        {isRMEnabled && (
          <>
            <div className={style.section}>
              <div className={style.value}>
                {riskManagerSettings?.drawdownType === E_RISK_MANAGER_DRAWDOWN_TYPE.percent
                  ? t({ id: 'riskManager.valuePercent', comment: 'Процентная' })
                  : t({ id: 'riskManager.drawdownType.usdt', comment: 'Фиксированная' })}
              </div>
              <div className={style.sectionTitle}>{t({ id: 'riskManager.typeStatus', comment: 'Тип просадки' })}</div>
            </div>
            <div className={style.section}>
              <div className={style.value}>
                {riskManagerSettings.drawdownValue}
                {riskManagerSettings.drawdownType === E_RISK_MANAGER_DRAWDOWN_TYPE.percent ? '%' : ''}
              </div>
              <div className={style.sectionTitle}>
                {t({ id: 'riskManager.valueStatus', comment: 'Значение просадки' })}
              </div>
            </div>
            <div className={style.section}>
              <div className={style.status}>
                {riskManagerSettings?.enabled ? (
                  <>
                    {t({ id: 'riskManager.connected', comment: 'Enabled' })}
                    <SVG.OtherIcons.Shield className={style.rmIcon} />
                  </>
                ) : (
                  <>
                    {t({ id: 'riskManager.disabled', comment: 'Disabled' })}
                    <SVG.OtherIcons.ShieldEmpty className={style.rmIcon} />
                  </>
                )}
              </div>
              <div className={style.sectionTitle}>{t({ id: 'riskManager.status', comment: 'Status' })}</div>
            </div>
          </>
        )}
        <div className={style.settingActions}>
          {isRMEnabled && (
            <Button.Primary
              label={t({ id: 'riskManager.settings.riskSettings.buttonSettings', comment: 'Settings' })}
              disabled={!riskManagerSettings?.canEdit}
              onClick={toggleSettingsPopup}
            />
          )}
          {isRMEnabled ? (
            <Button.Primary
              label={t({ id: 'riskManager.settings.riskSettings.buttonDisable', comment: 'Disabled' })}
              onClick={disabledRiskManager}
              disabled={!isDisabledRM}
            />
          ) : (
            <Button.Primary
              label={t({ id: 'riskManager.settings.riskSettings.button', comment: 'Enabled' })}
              onClick={toggleRiskManagerPopup}
            />
          )}
        </div>
      </div>
      <PopupRiskManager isOpen={riskManagerPopup} onClose={toggleRiskManagerPopup} />
      <PopupRiskManagerSettings isOpen={settingsPopup} onClose={toggleSettingsPopup} />
    </>
  )
}
