import style from './style.module.scss'
import { SVG } from '@/assets/svg'
import { urls } from '@/router/urls'
import { LabelWithQuestion } from '@/ui/molecules'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  className?: string
  whitelistEnabled: boolean | undefined
}

export const Whitelist: FC<Props> = ({ whitelistEnabled, className }) => {
  const navigate = useNavigate()
  const profilePageHandler = () => navigate(urls.profile)

  return (
    <>
      <div className={clsx(style.whiteListBlock, className, style.text)} onClick={profilePageHandler}>
        {whitelistEnabled ? (
          <div className={style.whitelistContent}>
            <SVG.Status.Completed className={style.statusIcon} />
            <div>
              {t({
                id: 'whitelist.enabled.title',
                message: 'Whitelist enabled',
              })}
            </div>
            <LabelWithQuestion
              hintText={t({
                message: 'enabled',
                id: 'whitelist.enabled',
              })}
              className={style.whitelistContentLabel}
            />
          </div>
        ) : (
          <div className={style.whitelistContent}>
            <SVG.Status.Failed className={style.statusIcon} />
            <div>
              {t({
                id: 'whitelist.disabled.title',
                message: 'Whitelist disabled',
              })}
            </div>
            <LabelWithQuestion
              hintText={t({
                message: 'disabled',
                id: 'whitelist.disabled',
              })}
              className={style.whitelistContentLabel}
            />
          </div>
        )}
      </div>
    </>
  )
}
