import React from 'react'

export const Arrow = () => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.81339 12.4128L5.81339 1.08611C5.81339 0.486269 6.29965 0 6.8995 0C7.49934 0 7.98561 0.486269 7.98561 1.08611L7.98561 12.411L11.9587 8.59486C12.3913 8.17933 13.0788 8.19318 13.4944 8.62579C13.9099 9.0584 13.896 9.74594 13.4634 10.1615L7.65096 15.7444C7.23062 16.1482 6.56655 16.1482 6.14621 15.7444L0.333738 10.1615C-0.0988706 9.74595 -0.112719 9.0584 0.302806 8.62579C0.718331 8.19318 1.40588 8.17933 1.83849 8.59486L5.81339 12.4128Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
