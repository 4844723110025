import React from 'react'

export const Scan = () => {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.925 25.4976H7.99823C7.13045 25.4976 6.36064 25.4696 5.67482 25.3717C1.99375 24.9658 1 23.2302 1 18.4994V11.5012C1 6.77043 1.99375 5.03483 5.67482 4.62893C6.36064 4.53093 7.13045 4.50293 7.99823 4.50293H18.841"
        fill="url(#paint0_linear_3401_114)"
      />
      <path
        d="M18.925 25.4976H7.99823C7.13045 25.4976 6.36064 25.4696 5.67482 25.3717C1.99375 24.9658 1 23.2302 1 18.4994V11.5012C1 6.77043 1.99375 5.03483 5.67482 4.62893C6.36064 4.53093 7.13045 4.50293 7.99823 4.50293H18.841"
        stroke="url(#paint1_linear_3401_114)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5234 4.50293H20.5949C21.4627 4.50293 22.2325 4.53093 22.9183 4.62893C26.5994 5.03483 27.5931 6.77043 27.5931 11.5012V18.4994C27.5931 23.2302 26.5994 24.9658 22.9183 25.3717C22.2325 25.4696 21.4627 25.4976 20.5949 25.4976H18.5234"
        fill="url(#paint2_linear_3401_114)"
      />
      <path
        d="M18.5234 4.50293H20.5949C21.4627 4.50293 22.2325 4.53093 22.9183 4.62893C26.5994 5.03483 27.5931 6.77043 27.5931 11.5012V18.4994C27.5931 23.2302 26.5994 24.9658 22.9183 25.3717C22.2325 25.4696 21.4627 25.4976 20.5949 25.4976H18.5234"
        stroke="url(#paint3_linear_3401_114)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.545 0.84906C18.545 0.38014 18.1649 0 17.696 0C17.2271 0 16.8469 0.38014 16.8469 0.84906V29.1509C16.8469 29.6199 17.2271 30 17.696 30C18.1649 30 18.545 29.6199 18.545 29.1509V0.84906ZM9.7195 16.9653C10.8633 16.9653 11.7905 16.0381 11.7905 14.8943C11.7905 13.7505 10.8633 12.8232 9.7195 12.8232C8.57567 12.8232 7.64844 13.7505 7.64844 14.8943C7.64844 16.0381 8.57567 16.9653 9.7195 16.9653Z"
        fill="url(#paint4_linear_3401_114)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3401_114"
          x1="21.1319"
          y1="0.26612"
          x2="0.396099"
          y2="21.2964"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#252137" />
          <stop offset="0.522549" stopColor="#1B1828" stopOpacity="0.477451" />
          <stop offset="1" stopColor="#12101A" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3401_114"
          x1="-1.521"
          y1="26.4675"
          x2="34.1214"
          y2="-6.30915"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12101A" />
          <stop offset="0.20722" stopColor="#12101A" />
          <stop offset="0.390043" stopColor="#37063F" />
          <stop offset="0.599175" stopColor="#191624" />
          <stop offset="0.900469" stopColor="#100E17" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3401_114"
          x1="28.7098"
          y1="0.266119"
          x2="11.8624"
          y2="8.91163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#252137" />
          <stop offset="0.522549" stopColor="#1B1828" stopOpacity="0.477451" />
          <stop offset="1" stopColor="#12101A" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3401_114"
          x1="17.2479"
          y1="26.4675"
          x2="44.6093"
          y2="13.7362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12101A" />
          <stop offset="0.20722" stopColor="#12101A" />
          <stop offset="0.390043" stopColor="#37063F" />
          <stop offset="0.599175" stopColor="#191624" />
          <stop offset="0.900469" stopColor="#100E17" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3401_114"
          x1="7.64844"
          y1="30"
          x2="28.7048"
          y2="18.3274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C8CAFB" />
          <stop offset="1" stopColor="#E7569A" />
        </linearGradient>
      </defs>
    </svg>
  )
}
