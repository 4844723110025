import { GetTradesExchangeAvailableType } from '@/backend/api/trades.api'
import { TExchangesAvailable } from '@/core/constants'

export const formatDateLineToDot = (dateString: string) => {
  return dateString.replace(/-/g, '.')
}

export const checkExchangeStatuses = (
  selectedExchangeValues: TExchangesAvailable[],

  exchangeRepairStatuses?: GetTradesExchangeAvailableType['exchangeRepairStatuses']
) => {
  for (const value of selectedExchangeValues) {
    const exchange = exchangeRepairStatuses?.find(status => status.exchangeType === value)
    if (!exchange || !exchange.enabled) {
      return false
    }
  }
  return true
}
