import React, { memo } from 'react'
import { SVG } from '../../../assets/svg'
import style from './style.module.scss'
import { t } from '@lingui/macro'

type SupportButtonProps = {
  /** text */
  label: string
}

/** SupportButton at the bottom of the page*/
export const SupportButton = memo<SupportButtonProps>(({ label }) => {
  return (
    <a href={`mailto:${label}`} className={style.supportButton}>
      <span>{t({ id: 'footer.help', message: `Help at` })}</span>
      <SVG.OtherIcons.Mail />
      <span>{label}</span>
    </a>
  )
})
