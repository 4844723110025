import { EMarginBybit, Margin } from '@/backend/models/LeverageDTO'
import { TOkxMargin } from '@/services/okx'

export const MARGIN_OKX_TO_MARGIN: Record<TOkxMargin, Margin> = {
  cross: Margin.Crossed,
  isolated: Margin.Isolated,
}

export const MARGIN_TO_OKX_MARGIN: Record<Margin, TOkxMargin> = {
  crossed: 'cross',
  isolated: 'isolated',
  other: 'isolated',
}

export const MARGIN_BYBIT_TO_MARGIN: Record<EMarginBybit, Margin> = {
  REGULAR_MARGIN: Margin.Crossed,
  ISOLATED_MARGIN: Margin.Isolated,
  PORTFOLIO_MARGIN: Margin.Other,
}

export const MARGIN_TO_MARGIN_BYBIT: Record<Margin, EMarginBybit> = {
  crossed: EMarginBybit.REGULAR_MARGIN,
  isolated: EMarginBybit.ISOLATED_MARGIN,
  other: EMarginBybit.PORTFOLIO_MARGIN,
}
