import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiKeyAsyncActions } from '@/redux/apiKey/apiKey.actions'
import { ApiKeyCreate } from '@/ui/organisms/ApiKeyCreate'
import { ApiKeyCreateDone } from '@/ui/organisms/ApiKeyCreateDone'
import { useActions, useAppSelector } from '@/utils'
import { urls } from '@/router/urls'
import style from './style.module.scss'
import { TCreateApiKeyPayloadUnion } from '@/backend/api/apiKeyAPI'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'
import { Navigate } from 'react-router-dom'

export const ApiCreateKeyPage = () => {
  const navigate = useNavigate()
  const { createdApiKeyStatus, createdApiKeyResult } = useAppSelector(state => state.apiKeys)
  const { tradingStatus } = useAppSelector(state => state.team)
  const { GetApiKeysTC, CreateApiKeyTC, CleanCreatedApiKeysTC } = useActions(apiKeyAsyncActions)

  const isCreationFinished = createdApiKeyStatus === 'succeeded'

  const { isApiKeyEnabled } = useExchangesDisabled()

  useEffect(() => {
    if (tradingStatus?.tradingEnabled === false) navigate(urls.api)
  }, [tradingStatus])

  useEffect(() => {
    return () => {
      if (isCreationFinished) {
        CleanCreatedApiKeysTC()
      }
    }
  }, [isCreationFinished, CleanCreatedApiKeysTC])

  const handleSubmit = useCallback(
    async (params: TCreateApiKeyPayloadUnion) => {
      await CreateApiKeyTC(params)
      GetApiKeysTC()
    },
    [CreateApiKeyTC, GetApiKeysTC]
  )

  const gotoApiListPage = useCallback(() => {
    navigate(urls.api)
  }, [navigate])

  if (!isApiKeyEnabled) return <Navigate to={urls.api} />

  return (
    <div className={style.component}>
      <div className={style.content}>
        {isCreationFinished && createdApiKeyResult ? (
          <ApiKeyCreateDone apiKeyData={createdApiKeyResult} onSubmit={gotoApiListPage} />
        ) : (
          <ApiKeyCreate
            onSubmit={handleSubmit}
            onCancel={gotoApiListPage}
            isSubmitDisabled={createdApiKeyStatus === 'loading'}
          />
        )}
      </div>
    </div>
  )
}
