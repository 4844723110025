import { FC } from 'react'

type FeaturesProps = {
  className?: string
}

export const Futures: FC<FeaturesProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={20} fill="none" className={className}>
    <g clipPath="url(#a)">
      <path fill="#fff" d="M11.144 6.66h-3.72v2.724h3.564v1.056H7.424v3.564H6.308v-8.4h4.836V6.66Z" />
    </g>
    <rect width={16} height={19} x={0.5} y={0.503} stroke="#37363E" rx={3.5} />
    <defs>
      <clipPath id="a">
        <rect width={17} height={20} y={0.003} fill="#fff" rx={4} />
      </clipPath>
    </defs>
  </svg>
)
