import { FC, ReactElement } from 'react'
import { EPartnerAchievements } from '@/redux/partner/partner.types'
import { Button } from '@/ui/kit'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { TPartnerAchievementTranslationRecord } from '@/ui/organisms/PartnerRightBar/PartnerRightBar.types'
import { t } from '@lingui/macro'

import styles from './style.module.scss'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'
import { useActions } from '@/utils'
import { appActions } from '@/redux/app/app.slice'

export type TAchievementSouce = {
  id: EPartnerAchievements
  text: Record<keyof TPartnerAchievementTranslationRecord, string | ReactElement>
  icon: ReactElement
  link: string
  unlocked: boolean
  pending: boolean
}

interface IAchievementsProps {
  achievementsSource: TAchievementSouce[]
}

const Achievement: FC<{ achievement: TAchievementSouce; index: number }> = props => {
  const navigate = useNavigate()
  const { setExchangeType } = useActions(appActions)
  const { exchangeType } = useExchageType()
  const { achievement, index } = props

  function handleClick(link: string, id: string) {
    navigate(link)

    if (exchangeType !== EXCHANGES_TYPE.BINANCE_BROKER_FUTURE && id === EPartnerAchievements.kycPassed) {
      setExchangeType(EXCHANGES_TYPE.BINANCE_BROKER_FUTURE)
    }
  }

  return (
    <div
      className={clsx(styles.achievement, {
        [styles.disabled]: !achievement.unlocked && !achievement.pending,
        [styles.active]: achievement.unlocked,
      })}
    >
      <div className={styles.iconWrap}>
        {index}
        {achievement.unlocked && <div className={styles.icon}>{achievement.icon}</div>}
      </div>
      <div className={styles.text}>
        <div className={styles.title}>{achievement.text.title}</div>
        <div className={styles.description}>{achievement.text.description}</div>
        {!achievement.unlocked && achievement.text.disclaimer && (
          <div className={styles.disclaimer}>{achievement.text.disclaimer}</div>
        )}
      </div>
      <div className={styles.controls}>
        {achievement.unlocked && (
          <Button.PrimaryGreen label={t({ id: 'core.done' })} disabled={true} className={styles.btn} />
        )}

        {achievement.pending && (
          <Button.AccentDark
            label={achievement.text.todo}
            onClick={() => handleClick(achievement.link, achievement.id)}
            className={styles.btn}
          />
        )}
        {!achievement.unlocked && !achievement.pending && (
          <Button.AccentDark label={achievement.text.todo} className={styles.btn} />
        )}
      </div>
    </div>
  )
}

const Achievements: FC<IAchievementsProps> = props => {
  const { achievementsSource } = props
  return (
    <div className={styles.root}>
      {achievementsSource.map((achievement, index) => {
        return <Achievement achievement={achievement} key={achievement.id} index={index + 1} />
      })}
    </div>
  )
}

export { Achievements }
