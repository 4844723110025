import { useEffect, useMemo, useState } from 'react'

interface ICommonHoverEffect {
  isHovered: boolean
  disabled: boolean
}

interface IMouseEvents {
  onMouseEnter: () => void
  onMouseLeave: () => void
}

interface IState {
  hoveredItemID: symbol | null
  hoveredItem: IMouseEvents | null
  [key: symbol]: IMouseEvents
}

const state: IState = {
  hoveredItemID: null,
  get hoveredItem() {
    const id = this.hoveredItemID

    if (!id || !this[id]) {
      return null
    }

    return this[id]
  },
}

export function useCommonHoverEffect({ isHovered, disabled }: ICommonHoverEffect) {
  const uniqID = useMemo(() => Symbol('id'), [])
  const [hovered, setHovered] = useState(isHovered && !disabled)

  const onMouseEnter = () => {
    if (disabled) {
      return
    }

    if (state.hoveredItemID && state.hoveredItemID !== uniqID) {
      const hoveredItem = state.hoveredItem

      if (hoveredItem) {
        hoveredItem.onMouseLeave()
      }
    }

    state.hoveredItemID = uniqID
    setHovered(true)
  }

  const onMouseLeave = () => {
    if (disabled) {
      return
    }

    if (state.hoveredItemID === uniqID) {
      state.hoveredItemID = null
    }

    setHovered(false)
  }

  useEffect(() => {
    state[uniqID] = { onMouseEnter, onMouseLeave }

    if (!disabled && isHovered && state.hoveredItemID !== uniqID) {
      const hoveredItem = state.hoveredItem

      if (hoveredItem) {
        hoveredItem.onMouseLeave()
      }

      state.hoveredItemID = uniqID
    }

    return () => {
      delete state[uniqID]
    }
  }, [disabled])

  return { isHovered: hovered, onMouseEnter, onMouseLeave }
}
