import React, { FC, ReactElement } from 'react'
import clsx from 'clsx'
import style from './style.module.scss'

type TTableEl = ReactElement | string

export interface ITableProps {
  content: {
    columns: Array<{
      id: string
      element: TTableEl
    }>
    rows: Record<string, TTableEl>[]
  }
  theme?: any
  isGridRow?: boolean
}

export const Table: FC<ITableProps> = props => {
  const { content, theme, isGridRow } = props

  return (
    <div
      className={clsx(style.table, {
        [style._themeDefault]: !theme,
      })}
    >
      <div className={clsx(style.row, isGridRow && style.rowGrid)}>
        {content.columns.map(col => {
          return (
            <div className={style.th} key={col.id}>
              {col.element}
            </div>
          )
        })}
      </div>
      {content.rows.map((row, index) => {
        return (
          <div className={clsx(style.row, isGridRow && style.rowGrid)} key={index}>
            {content.columns.map(col => {
              return (
                <div className={style.td} key={col.id}>
                  {row[col.id]}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
