import React from 'react'

export const Lock = () => {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.35641 7.99898V4.66746C9.35641 2.82751 7.86483 1.33594 6.02488 1.33594V1.33594C4.18493 1.33594 2.69336 2.82751 2.69336 4.66746V7.13273"
        stroke="#37363E"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.82598 5.6875C1.24036 5.6875 0.765625 6.16224 0.765625 6.74785V9.52561C0.765625 12.4162 3.10887 14.7594 5.99942 14.7594C8.88996 14.7594 11.2332 12.4162 11.2332 9.52562V6.74785C11.2332 6.16224 10.7585 5.6875 10.1729 5.6875H1.82598ZM7.30604 9.04844C7.30604 9.49267 7.07979 9.88405 6.73624 10.1137V11.6211C6.73624 12.0287 6.4058 12.3591 5.99818 12.3591C5.59056 12.3591 5.26012 12.0287 5.26012 11.6211V10.0746C4.94772 9.84118 4.74549 9.46843 4.74549 9.04844C4.74549 8.34136 5.31869 7.76816 6.02576 7.76816C6.73284 7.76816 7.30604 8.34136 7.30604 9.04844Z"
        fill="#37363E"
      />
    </svg>
  )
}
