export class Normalizers {
  static toSum(value: string): string {
    let buffer = ''
    let integer = ''

    for (let i = 0; i < value.length; i++) {
      const char = value[i]

      if ((char === ',' || char === '.') && !integer) {
        integer = `${buffer}.`
        buffer = ''
      } else if (/[0-9]/.test(char)) {
        buffer += char
      }
    }

    return !integer ? buffer : `${integer}${buffer}`
  }
}
