import { FC, useRef } from 'react'
import useHorizontalScroll, { E_ScrollDirection } from '@/ui/organisms/HistoryTrades/hooks/useHorizontalScroll'
import { WeekCard } from '../WeekCard/WeekCard'
import style from './style.module.scss'
import { SVG } from '@/assets/svg'
import clsx from 'clsx'
import { WeekInfo } from '@/utils/types/contracts.types'
import { DATE_FORMAT, formatDate, getMonthName } from '@/utils/lib/formatDate'
import { useIntersectionObserver } from '@/utils/hooks/useIntersectionObserver'
import { t } from '@lingui/macro'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'

type WeekCardContainerProps = {
  month: string
  data: WeekInfo[]
  onMonthVisibilityChange: (isVisible: boolean, year: number) => void
}

export const WeekCardContainer: FC<WeekCardContainerProps> = ({ month, data, onMonthVisibilityChange }) => {
  const { tableRef, isScrollableLeft, isScrollableRight, handleHorizontalScroll } = useHorizontalScroll()

  const monthRef = useRef<HTMLDivElement>(null)
  const getMonth = getMonthName(month)
  const navigate = useNavigate()

  const formateStartMonth = new Date(formatDate(month, DATE_FORMAT.FORMATTED))
  const startMonth = formatDate(month, DATE_FORMAT.FORMATTED)
  const endMonth = formatDate(
    new Date(formateStartMonth.getFullYear(), formateStartMonth.getMonth() + 1, 0),
    DATE_FORMAT.FORMATTED
  )

  const weekListPageHandler = () => {
    navigate(`${urls.summaryAnalyze}/${startMonth}-${endMonth}`)
  }

  useIntersectionObserver(
    monthRef,
    ([entry]) => {
      const visibilityRatio = entry.intersectionRatio

      const shouldChangeYearToNext = visibilityRatio >= 0.9
      const shouldChangeYearToPrevious = visibilityRatio <= 0.3

      if (shouldChangeYearToPrevious || shouldChangeYearToNext) {
        const getVisibleYear = month.substring(0, month.indexOf('-'))
        onMonthVisibilityChange(entry.isIntersecting, +getVisibleYear)
      }
    },
    { threshold: [0.3, 0.9] }
  )
  return (
    <div className={style.root} ref={monthRef}>
      <div>
        <div className={style.month} onClick={weekListPageHandler}>
          <div>{getMonth}</div>
          <div className={style.analyzeMonth}>
            {t({
              id: 'trades.summary.month.analyze',
            })}
            <SVG.Arrows.ArrowRight className={style.linkArrow} />
          </div>
        </div>
        <div className={style.btnContainer}>
          {/* for scroll  right to left container*/}
          {isScrollableLeft && (
            <div
              className={clsx(style.btn, style.arrowLeft)}
              onClick={() => handleHorizontalScroll(E_ScrollDirection.LEFT)}
            >
              <SVG.Arrows.ArrowLeft />
            </div>
          )}
          {isScrollableRight && (
            <div
              className={clsx(style.btn, style.arrowRight)}
              onClick={() => handleHorizontalScroll(E_ScrollDirection.RIGHT)}
            >
              <SVG.Arrows.ArrowRight />
            </div>
          )}
        </div>
        <div className={style.cardBlock} ref={tableRef}>
          {data.map((el, index) => (
            <WeekCard key={index} data={el} />
          ))}
        </div>
      </div>
    </div>
  )
}
