import { axiosMainService } from '@/services/axios'

const googleAuthAPIURL = '/two-fa/protected/api'

export const googleAuthAPI = {
  async verifyCode(payload: CodeVerification) {
    return await axiosMainService.post(`${googleAuthAPIURL}/v2/profile/google`, payload)
  },
}

export type CodeVerification = {
  codes: {
    google: string
    sms?: string
  }
  enabled: boolean
}
