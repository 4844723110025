import { FC } from 'react'

interface SmileyProps {
  className?: string
}

export const Smiley: FC<SmileyProps> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="40" height="40" rx="15" fill="url(#paint0_radial_19532_247186)" />
      <path
        d="M17 23C17 23 18.125 24 20 24C21.875 24 23 23 23 23M25 18C25 18.5523 24.5523 19 24 19C23.4477 19 23 18.5523 23 18C23 17.4477 23.4477 17 24 17C24.5523 17 25 17.4477 25 18ZM30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20ZM17 18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18C15 17.4477 15.4477 17 16 17C16.5523 17 17 17.4477 17 18Z"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_19532_247186"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(40.8151) scale(52.8525 698.452)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export const SmileyGrayscale: FC<SmileyProps> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="40" height="40" rx="12" fill="#676573" />
      <path
        d="M17 23C17 23 18.125 24 20 24C21.875 24 23 23 23 23M25 18C25 18.5523 24.5523 19 24 19C23.4477 19 23 18.5523 23 18C23 17.4477 23.4477 17 24 17C24.5523 17 25 17.4477 25 18ZM30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20ZM17 18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18C15 17.4477 15.4477 17 16 17C16.5523 17 17 17.4477 17 18Z"
        stroke="#FAFAFA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
