import { dashboardActions } from '@/redux/dashboard/dashboard.slice'
import { Loader } from '@/ui/kit/Loader'
import { useActions, useAppSelector } from '@/utils'
import { FC, useEffect } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { DashboardWidget } from './components/DashboardWidget/DashboardWidget'
import { DashboardFilter } from './components/DashboardFilter/DashboradFilter'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { Trans } from '@lingui/react'
import { useTMMAccountInfo } from '../HistoryTrades/hooks/useTMMTable/useTMMAccountInfo'
import { EmptyList } from '@/ui/molecules'

interface DashboardProps {
  dashboardId: number
}

export const Dashboard: FC<DashboardProps> = ({ dashboardId }) => {
  const { isMeLoading, me } = useTMMAccountInfo({ isStatisticsAvailable: true })

  const { dashboardStatus, dashboardFilter } = useAppSelector(state => state.dashboard)
  const { GetBoardTC } = useActions(dashboardActions)

  const isLoading = dashboardStatus === 'loading' || dashboardStatus === 'idle'
  const isError = dashboardStatus === 'failed'

  useEffect(() => {
    GetBoardTC({
      ...dashboardFilter,
      id: dashboardId,
    })
  }, [dashboardFilter, dashboardId, GetBoardTC])

  if (isLoading || isMeLoading) {
    return <Loader />
  }

  if (!me?.api_keys_list?.length) {
    return (
      <div className={style.empty}>
        <EmptyList
          label={t({
            id: 'trades.dashboard.emptyDashboard',
            comment: 'Dashboard will display here',
          })}
        />
      </div>
    )
  }

  if (isError) {
    return (
      <div className={style.errorContainer}>
        <ErrorBase
          className={style.error}
          customHeader={t({
            id: 'trades.summary.errorHeaderText',
          })}
          customText={
            <Trans
              id="trades.summary.errorDescription"
              components={{
                br: <br />,
              }}
            />
          }
          isRefreshButtonVisible
        />
      </div>
    )
  }

  return (
    <div className={style.root}>
      <div className={style.headerBlock}>
        <div className={style.headerText}>
          {t({
            id: 'trades.dashboard.title',
          })}
        </div>
        <div>
          <DashboardFilter />
        </div>
      </div>

      <DashboardWidget />
    </div>
  )
}
