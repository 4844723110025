import { PeriodUNION } from '@/redux/leaderboard/leaderboard.types'
import { axiosMainService } from '@/services/axios'
import { toQueryString } from '@/utils/lib/objectToQueryString'

const LEADERBOARD_API_URL = 'statistics-gtw/protected/api/v1/statistics'
const TMM_URL = '/proxy/api/v2'
const PREFIX = LEADERBOARD_API_URL + TMM_URL

type TypeUNION = 'pnl' | 'percent' | 'volume'
type StatusUNION = 'success' | 'error' | 'warning'

export const LeaderBoardAPI = {
  async getSpotLeaders({ teamId, period, type = 'pnl' }: LeadersRequestPayload) {
    const query = toQueryString({
      period,
      type,
      exchange_id: [4, 6, 9],
    })

    return await axiosMainService.get<ILeaderBoardDTO>(`${PREFIX}/team/${teamId}/top?${query}`)
  },
  async getFuturesLeaders({ teamId, period, type = 'pnl' }: LeadersRequestPayload) {
    const query = toQueryString({
      period,
      type,
      exchange_id: [1, 2, 8],
    })

    return await axiosMainService.get<ILeaderBoardDTO>(`${PREFIX}/team/${teamId}/top?${query}`)
  },
  async getPnlLeaders({ teamId, period, type = 'pnl' }: LeadersRequestPayload) {
    const query = toQueryString({
      period,
      type,
      exchange_id: [1, 2, 4, 6, 8, 9],
    })

    return await axiosMainService.get<ILeaderBoardDTO>(`${PREFIX}/team/${teamId}/top?${query}`)
  },
}

export type IUserInfo = {
  name: string
  user_id: number
  value: string
  position: number
}

type ILeaderBoardDTO = {
  data: IUserInfo[]
  status: StatusUNION
}

export type LeadersRequestPayload = {
  teamId: number
  period?: PeriodUNION
  type?: TypeUNION
}
