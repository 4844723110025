import { ReactNode, useEffect } from 'react'
import { t } from '@lingui/macro'
import { GoogleAuth } from '../GoogleAuth'
import { UseGoogleAuthCodeResult, UseGoogleAuthSettingsResult, useGoogleAuthSettings } from '@/utils/hooks'

interface SetupGoogleAuthProps {
  header: ReactNode
  onBackClick: () => void
  onSuccess?: () => void
  googleAuthSettings: UseGoogleAuthSettingsResult
  googleAuthCode: UseGoogleAuthCodeResult
}

export const SetupGoogleAuth = (props: SetupGoogleAuthProps) => {
  const { header, googleAuthCode, onBackClick, onSuccess } = props
  const { settings } = useGoogleAuthSettings()

  const { isCorrectCodeLength, verify, cleanCode, isError, isSuccess } = googleAuthCode

  const errorMessage = isError
    ? t({
        id: 'services.gAuth.wrong',
        message: `Wrong code`,
      })
    : undefined
  const successMessage = isSuccess
    ? t({
        id: 'services.gAuth.correct',
        message: `Correct code`,
      })
    : undefined

  useEffect(() => {
    return cleanCode
  }, [cleanCode])

  useEffect(() => {
    if (isCorrectCodeLength) {
      verify()
    }
  }, [verify, isCorrectCodeLength])

  useEffect(() => {
    if (isSuccess) onSuccess?.()
  }, [isSuccess, onSuccess])

  return (
    <GoogleAuth
      isOpen
      header={header}
      secret={settings.secret || ''}
      code={googleAuthCode.code}
      setCode={googleAuthCode.setCode}
      isDisabled={false}
      errorMessage={errorMessage}
      successMessage={successMessage}
      onCloseClick={onBackClick}
    />
  )
}
