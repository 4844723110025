import React from 'react'

interface BinanceProps {
  className?: string
}

const Binance: React.FC<BinanceProps> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="40" height="40" rx="20" fill="#FFCE51" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3981 14.7274L16.514 18.4456L14.254 16.282L20.3981 10.4L26.5442 16.284L24.2842 18.4476L20.3981 14.7274ZM22.6944 19.9733H22.6953L20.402 17.7778L18.7072 19.4003H18.707L18.5124 19.5867L18.1107 19.9713L18.1075 19.9743L18.1107 19.9775L20.402 22.171L22.6953 19.9755L22.6964 19.9743L22.6944 19.9733ZM28.1402 22.1378L30.4003 19.9742L28.1403 17.8106L25.8802 19.9743L28.1402 22.1378ZM20.3995 25.2199L16.5155 21.5017L14.2522 23.6624L14.2553 23.6655L20.3995 29.5474L26.5457 23.6635L26.5468 23.6623L24.2855 21.4999L20.3995 25.2199ZM14.9203 19.974L12.6604 17.8104L10.4003 19.9741L12.6602 22.1376L14.9203 19.974Z"
        fill="#011119"
      />
    </svg>
  )
}

export { Binance }
