import clsx from 'clsx'
import {
  Dispatch,
  KeyboardEvent,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SVG } from '@/assets/svg'
import { CODE_LENGTH } from '@/core/config/googleAuth'
import { Hint, InputCode, usePopperRef } from '@/ui/kit'
import { useMobileSizeDetect } from '@/utils'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import styles from './style.module.scss'

export type GoogleCodeVerificationProps = {
  /** Main text */
  header?: ReactNode

  /** Additional text */
  text?: string

  /** Hint to show the code is correct */
  successMessage?: string

  /** Hint to show the code is incorrect */
  errorMessage?: string

  /** controlled code incoming from parent */
  code: string[]

  /** controlled setCode handler incoming from parent */
  setCode: Dispatch<SetStateAction<string[]>> | ((value: string[]) => any)

  isValid?: boolean

  /** if inputs should not be active */
  isDisabled?: boolean

  isDarken?: boolean
}

const initialCodeArray = new Array(CODE_LENGTH).fill('')
const onlyDigitsAfterReplacementRegex = /[^\d]/gi

/** View component for checking google authenticator code */
const GoogleCodeVerification = memo<GoogleCodeVerificationProps>(props => {
  const { header, text, isValid, errorMessage, successMessage, code, setCode, isDisabled, isDarken } = props

  const [isMobile] = useMobileSizeDetect()
  const [hintRef, setHintRef] = usePopperRef()
  const inputRefs = useRef<HTMLInputElement[]>([])
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    inputRefs.current?.[activeIndex]?.focus?.()
  }, [inputRefs, activeIndex])

  const getNewCode = useCallback((value: string[]): string[] => {
    return value
      .join('')
      .replace(onlyDigitsAfterReplacementRegex, '')
      .split('')
      .reduce(
        (arr, digit, index) => {
          arr[index] = digit
          return arr
        },
        [...initialCodeArray]
      )
      .slice(0, CODE_LENGTH)
  }, [])

  const getNewActiveIndexFromCode = useCallback((oldCode: string[], newCode: string[]): number => {
    const oldCodeLength = oldCode.filter(Boolean).length
    const newCodeLength = newCode.filter(Boolean).length

    return oldCodeLength > newCodeLength
      ? newCodeLength > 0 // on clearing
        ? newCodeLength - 1
        : 0
      : newCodeLength // on adding
  }, [])

  const handleChange = (index: number) => (value: string) => {
    const codeCopy = [...code]
    codeCopy[index] = value

    const newCode = getNewCode(codeCopy)
    const newActiveIndex = getNewActiveIndexFromCode([...code], newCode)

    setCode(newCode)
    setActiveIndex(newActiveIndex)
  }

  const handleFocus = (index: number) => () => {
    if (inputRefs.current?.[index] !== document.activeElement) {
      setActiveIndex(index)
    }
  }

  const handleKeypress = (index: number) => (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      setActiveIndex(index === 0 ? 0 : index - 1)
    }
  }

  return (
    <div className={clsx(isMobile && styles.mobile, styles.root)}>
      <div className={styles.header} data-testid={DataTestIds.ModalConfirmTitle}>
        {header}
      </div>
      <div className={styles.text} data-testid={DataTestIds.ModalConfirmInfo}>
        {text}
      </div>
      <div className={styles.codesWrapper}>
        <div className={styles.codes} ref={setHintRef}>
          {initialCodeArray.map((_, index) => (
            <InputCode
              key={index}
              ref={el => {
                inputRefs.current[index] = el
              }}
              value={code[index] || ''}
              valid={isValid}
              disabled={isDisabled}
              darken={isDarken}
              onChange={handleChange(index)}
              onFocus={handleFocus(index)}
              onKeyDown={handleKeypress(index)}
            />
          ))}
        </div>

        {!isMobile && successMessage && (
          <Hint placement="right" text={successMessage} variant="success" targetRef={hintRef} />
        )}

        {!isMobile && errorMessage && (
          <Hint
            placement="right"
            text={errorMessage}
            variant="error"
            targetRef={hintRef}
            dataTestId={DataTestIds.ModalTwoFAAlert}
          />
        )}

        {isMobile && successMessage && (
          <div className={styles.hint}>
            <SVG.Status.Completed />
            {successMessage}
          </div>
        )}

        {isMobile && errorMessage && (
          <div className={styles.hint}>
            <SVG.Status.Failed />
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  )
})

export { GoogleCodeVerification }
