import { TAccountType } from '@/core/types/overview'

export type ReferralCustomCode = {
  code: string
  bonusPeriodDays: number
  bonusCommissionPercent: number
  bonusBinanceCashbackPercent: number
  bonusBybitCashbackPercent: number
}

export type ReferralOverviewResponseDTO = {
  userCode: string
  referrerCode: string
  referrerLocked: boolean
  invitedTotal: number
  invitedMonth: number
  referralVolumeMonth: number
  referralVolumeTotal: number
  profitMonth: number
  profitTotal: number
  customCodes: ReferralCustomCode[]
}

export type ApplyReferralCodeRequestDTO = {
  referrerCode: string
}

export type UpdateReferralCodeRequestDTO = {
  code: string
}

export type UpdateReferralCustomCodeRequestDTO = {
  oldCode: string
  newCode: string
}

export type GetReferralCodeInfoDTO = {
  id: string
  userId: string
  locked: boolean
}

export type CreateReferralCodeRequestDTO = {
  bonusPeriodDays?: number
  bonusBinanceCashbackPercent: number
  bonusBybitCashbackPercent: number
  isPermanent: boolean
}

export type CreateReferralCodeResponseDTO = ReferralCustomCode

export type TGetReferralEarningsPayload = {
  filterParams: {
    dateFrom: string //2023-08-23
    dateTo: string //2023-08-23
    userId?: string //ceaa8167-594b-4ede-b2fd-0ce79a875bbd, nullable
  }
  page: number //0
  size: number //10
}

export type TGetReferralEarnings = {
  userId: string //07551431-5c0f-4413-af26-d63ed0aa91d4
  userJoined: string //2023-08-23
  tradingDay: string //2023-08-24
  exchangeSection: TAccountType //BINANCE_SPOT | BINANCE_FUTURE | BINANCE_FUTURE_COIN | OKX_TRADING | BYBIT_UNIFIED
  userVolume: number //123456.789
  userCommission: number //543.21
  partnerEarnings: number //123.21
}

export type TGetReferralEarningsDTO = {
  data: TGetReferralEarnings[]
}

export type TGetBenefitsDTO = {
  maxCommissionPercent: number
  maxReferrerCommissionPercent: number
}

export enum ReferralCodePercent {
  Binance = 'binance',
  Bybit = 'bybit',
  OKX = 'okx',
}
