export const BigLock = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="40" height="40" rx="20" fill="#1C1A24" />
      <path
        d="M20 25.3149C20.8102 25.3149 21.467 24.6581 21.467 23.8479C21.467 23.0377 20.8102 22.3809 20 22.3809C19.1898 22.3809 18.533 23.0377 18.533 23.8479C18.533 24.6581 19.1898 25.3149 20 25.3149Z"
        fill="#FAFAFA"
      />
      <path
        d="M24.185 18.196H15.815C15.743 18.196 15.68 18.196 15.608 18.196V17.152C15.608 14.515 16.355 12.76 20 12.76C23.897 12.76 24.392 14.659 24.392 16.315C24.392 16.666 24.671 16.945 25.022 16.945C25.373 16.945 25.652 16.666 25.652 16.315C25.652 13.12 23.753 11.5 20 11.5C14.933 11.5 14.348 14.722 14.348 17.152V18.277C11.828 18.592 11 19.87 11 23.011V24.685C11 28.375 12.125 29.5 15.815 29.5H24.185C27.875 29.5 29 28.375 29 24.685V23.011C29 19.321 27.875 18.196 24.185 18.196ZM20 26.566C18.497 26.566 17.282 25.342 17.282 23.848C17.282 22.345 18.506 21.13 20 21.13C21.494 21.13 22.718 22.354 22.718 23.848C22.718 25.351 21.503 26.566 20 26.566Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
