import clsx from 'clsx'
import { FC } from 'react'
import style from './style.module.scss'

interface ToggleProps {
  active?: boolean
  onChange?: (value: boolean) => void
  text?: string
  className?: string
  disabled?: boolean
}

export const Toggle: FC<ToggleProps> = props => {
  const { active = false, onChange, text, className = '', disabled = false } = props

  const handleChange = () => {
    if (disabled) return
    onChange?.(!active)
  }

  return (
    <div className={className}>
      <div
        onClick={handleChange}
        className={clsx(style.toggle, {
          [style.active]: active,
          [style.disabled]: disabled,
        })}
      >
        <div className={style.round} />
      </div>
      {text && <div className={style.text}>{text}</div>}
    </div>
  )
}
