import clsx from 'clsx'
import React, { Fragment, memo, ReactNode, useState } from 'react'
import { t } from '@lingui/macro'
import { Button, ButtonVariant } from '@/ui/kit'
import { Popup } from '@/ui/molecules'
import style from './style.module.scss'
import { useMobileSizeDetect } from '@/utils'

export type ErrorBaseProps = {
  /** should content be hidden by half-opacity mask. @default = false */
  isFullScreen?: boolean

  /** custom style **/
  className?: string

  /** to insert concrete name in the typical header */
  account?: string

  /** to display own header instead of typical */
  customHeader?: ReactNode

  /** to display own text instead of typical */
  customText?: ReactNode

  /** to display something original */
  customComponent?: ReactNode

  /** if refresh button is visible. @default = false */
  isRefreshButtonVisible?: boolean

  /** if contact support button is visible. @default = false */
  isContactSupportButtonVisible?: boolean

  /** if button to try one more time is visible. @default = false */
  isAnotherAttemptButtonVisible?: boolean

  /** if button to try one more time is visible */
  anotherAttemptButtonText?: string

  /** callback to emit on button click */
  onClickAnotherAttemptButton?: () => void

  /** style for AnotherAttemptButton */
  anotherAttemptButtonVariant?: ButtonVariant

  /** if fully customized is visible. @default = false */
  isCustomButtonVisible?: boolean

  /** text on custom button */
  customButtonText?: string

  /** callback to emit on custom button click */
  onClickCustomButton?: () => void

  /** style fo custom button */
  customButtonVariant?: ButtonVariant

  /** text for closeWindowButton **/
  closeWindowText?: string

  /**
   * if button to close Error is visible. @default = false
   * should be used with «isAnotherAttemptButtonVisible = true»
   * */
  isCloseWindowVisible?: boolean

  /**
   * Callback for onclose action
   */
  onClickCloseCallback?: () => void

  /** if support message is visible. @default = false **/
  isSupportMessage?: boolean
}

/** Base Error view to extend for concrete errors. Shouldn't be used directly */
export const ErrorBase = memo<ErrorBaseProps>(
  ({
    className,
    account,
    customHeader,
    customText,
    isContactSupportButtonVisible,
    isFullScreen,
    isRefreshButtonVisible,
    customComponent,
    isAnotherAttemptButtonVisible,
    anotherAttemptButtonText,
    onClickAnotherAttemptButton,
    anotherAttemptButtonVariant,
    isCloseWindowVisible,
    isSupportMessage,
    isCustomButtonVisible,
    customButtonText,
    onClickCustomButton,
    customButtonVariant,
    closeWindowText = t({ message: 'Close window', id: 'core.closeWindow' }),
    onClickCloseCallback,
  }) => {
    const [isMobile] = useMobileSizeDetect()
    const [isPopupVisible, setIsPopupVisible] = useState(true)

    const errorComponent = (
      <div className={clsx(isMobile && style.mobile, style.root, className)}>
        <div className={style.header}>
          {customHeader ||
            `${t({ message: "Couldn't retrieve", id: 'core.errorBase.head-1' })} ${account || ''} ${t({
              message: 'data',
              id: 'core.errorBase.head-2',
            })}`}
        </div>

        <div className={style.text}>
          {customText || (
            <Fragment>
              {t({
                id: 'core.errorBase.issue.message-1',
                message: 'There has been an issue when loading data.',
              })}
              <br />
              {t({
                id: 'core.errorBase.issue.message-2',
                message: 'Try refreshing the page or reach out to Support.',
              })}
            </Fragment>
          )}
        </div>
        {customComponent}
        {isRefreshButtonVisible && (
          <Button.Accent
            onClick={() => document.location.reload()}
            label={t({
              id: 'core.refreshPage',
              message: `Refresh page`,
            })}
            className={style.button}
          />
        )}

        {isContactSupportButtonVisible && (
          <Button.Secondary
            onClick={() => '' /** FIXME: provide real handler */}
            label={t({
              id: 'core.contactSupport',
              message: `Contact Support`,
            })}
            className={style.button}
          />
        )}

        {isAnotherAttemptButtonVisible && (
          <Button
            onClick={onClickAnotherAttemptButton}
            label={
              anotherAttemptButtonText ||
              t({
                id: 'core.retry',
                message: `Retry`,
              })
            }
            variant={anotherAttemptButtonVariant!}
            className={style.button}
          />
        )}

        {isCustomButtonVisible && (
          <Button
            onClick={onClickCustomButton}
            label={
              customButtonText ||
              t({
                id: 'core.retry',
                message: `Retry`,
              })
            }
            variant={customButtonVariant!}
            className={style.button}
          />
        )}

        {isCloseWindowVisible && (
          <Button.Primary
            onClick={() => {
              setIsPopupVisible(false)
              if (onClickCloseCallback) {
                onClickCloseCallback()
              }
            }}
            label={closeWindowText}
            className={style.button}
          />
        )}

        {isSupportMessage && (
          <a
            className={style.supportMessage}
            href={`mailto:${t({
              id: 'core.supportEmail',
              message: 'broker.support@tiger.trade',
            })}`}
          >
            <span>
              {t({
                id: 'core.orContactSupport',
                message: `or contact support`,
              })}
            </span>
            <span>
              {t({
                id: 'core.supportEmail',
                message: 'broker.support@tiger.trade',
              })}
            </span>
          </a>
        )}
      </div>
    )

    return isFullScreen ? <Popup isOpen={isPopupVisible}>{errorComponent}</Popup> : <>{errorComponent}</>
  }
)
