import { percentFromString } from '@/utils/lib/numbers'
import clsx from 'clsx'
import { FC } from 'react'
import { CashbackTermsType } from '../../../backend/api'
import { formatNumber } from '../../../utils'
import style from './style.module.scss'

type CashbackPercentageListProps = {
  cashbackTerms: CashbackTermsType[]
  todayCashbackIndex: number
}

export const CashbackPercentageList: FC<CashbackPercentageListProps> = ({ cashbackTerms, todayCashbackIndex }) => {
  return (
    <div className={style.wrap}>
      {cashbackTerms.map((item, index) => {
        return (
          <div
            key={item.fromInclusive}
            className={clsx(style.percentageItem, todayCashbackIndex === index && style.activePercentageItem)}
          >
            <span>{percentFromString(item.binanceCashbackPercent, [2, 1])}%</span>
            <span>
              {formatNumber(String(item.fromInclusive))} USDT –{' '}
              {item.toExclusive ? formatNumber(String(Number(item.toExclusive) - 1)) + ' USDT' : '∞'}
            </span>
          </div>
        )
      })}
    </div>
  )
}
