import { createSlice } from '@reduxjs/toolkit'
import historyAsyncActions from './history.actions'
import initialState from './history.state'

export const slice = createSlice({
  name: `[Tiger Trade Account History]`,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(historyAsyncActions.GetHistoryCashbackTC.pending, (state, action) => {
        state.historyCashbackStatus = 'loading'
      })
      .addCase(historyAsyncActions.GetHistoryCashbackTC.rejected, (state, action) => {
        state.historyCashbackStatus = 'failed'
      })
      .addCase(historyAsyncActions.GetHistoryCashbackTC.fulfilled, (state, action) => {
        if (action.payload.page === 0) state.historyCashback = action.payload
        else
          state.historyCashback = {
            ...action.payload,
            cashback: [...state.historyCashback.cashback, ...action.payload.cashback],
          }

        if (action.payload.total / action.payload.size < action.payload.page) state.historyCashbackReachedEnd = true
        else state.historyCashbackReachedEnd = false

        state.historyCashbackStatus = 'succeeded'
      })
      .addCase(historyAsyncActions.GetHistoryTC.fulfilled, (state, action) => {
        if (action.payload.page === 0) state.historyData = action.payload
        else
          state.historyData = {
            ...action.payload,
            history: [...state.historyData.history, ...action.payload.history],
          }

        if (action.payload.total / action.payload.size < action.payload.page) state.historyDataReachedEnd = true
        else state.historyDataReachedEnd = false

        state.historyDataStatus = 'succeeded'
      })
      .addCase(historyAsyncActions.GetHistoryTC.pending, (state, action) => {
        state.historyDataStatus = 'loading'
      })
      .addCase(historyAsyncActions.GetHistoryTC.rejected, (state, action) => {
        state.historyDataStatus = 'failed'
      })
  },
})

export const historyActions = {
  ...slice.actions,
  ...historyAsyncActions,
}

export const historyReducer = slice.reducer
