import { FC, memo } from 'react'
import MainTabBalanceAndCrypto from '../../molecules/MainTabBalanceAndCrypto'
import MainTabButtons from '../../molecules/MainTabButtons'
import style from './style.module.scss'

const ContentMainTab: FC = () => {
  return (
    <div className={style.contentMainTab}>
      <MainTabBalanceAndCrypto />
      <MainTabButtons />
    </div>
  )
}

export default memo(ContentMainTab)
