import clsx from 'clsx'
import { memo, ReactNode } from 'react'
import style from './style.module.scss'

type TitleH1Props = {
  /** text */
  label: ReactNode

  /** Style modification **/
  className?: string

  dataTestId?: string
}

/** H1 text header */
export const TitleH1 = memo<TitleH1Props>(({ label, className, dataTestId }) => {
  return (
    <h1 className={clsx(style.title, className)} data-testid={dataTestId}>
      {label}
    </h1>
  )
})
