import { Select } from '@/ui/kit'
import { ExchangeOptions } from '@/ui/organisms/HistoryTrades/components/TableFilters/options/ExchangeOptions'
import style from './style.module.scss'
import { useActions, useAppSelector } from '@/utils'
import { tradesActions } from '@/redux/trades/trades.slice'
import clsx from 'clsx'
import useExchangeTypeHandler from '../../hooks/useExchangeFilter'

export const DiaryExchangeFilter = () => {
  const {
    diaryWeekListFilters: filters,
    me,
    diaryExchangeFilterValue,
    availableExchangeKeys,
  } = useAppSelector(state => state.trades)

  const { setDiaryFilter, setDiaryExchangeType } = useActions(tradesActions)

  const { exchangeTypeHandler } = useExchangeTypeHandler({
    filterValue: diaryExchangeFilterValue,
    setExchangeType: setDiaryExchangeType,
    setFilter: setDiaryFilter,
    filters: filters,
  })

  return (
    <>
      <Select
        onChange={exchangeTypeHandler}
        size={Select.Size.Small}
        value={
          diaryExchangeFilterValue
            ? diaryExchangeFilterValue
            : ExchangeOptions(availableExchangeKeys?.activeExchangeAccounts.map(record => record.exchangeType))[0].value
        }
        options={
          me?.api_keys_list
            ? ExchangeOptions(availableExchangeKeys?.activeExchangeAccounts.map(record => record.exchangeType))
            : []
        }
        variant={Select.Variant.Raw}
        className={clsx(style.select, style.selectContainer, style.exchange)}
      />
    </>
  )
}
