import React, { useContext } from 'react'
import { TLanguage } from '@/core/constants'

type LanguageContextProps = {
  locale: TLanguage
  setLocale: (language: TLanguage) => void
}

const LanguageContext = React.createContext<LanguageContextProps>({
  locale: 'en',
  setLocale: () => undefined,
})

const useLanguageContext = () => {
  return useContext<LanguageContextProps>(LanguageContext)
}

export { LanguageContext, useLanguageContext }
