import { FC } from 'react'

type ArrowRightProps = {
  className?: string
}

export const ArrowRight: FC<ArrowRightProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={6} height={8} fill="none" className={className}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M3.82 3.8.928 6.466a.68.68 0 0 0-.074.89c.202.268.56.305.803.081l3.417-3.152a.677.677 0 0 0 .001-.97L1.657.15a.532.532 0 0 0-.802.08.68.68 0 0 0 .072.89L3.82 3.799Z"
      clipRule="evenodd"
    />
  </svg>
)
