import { FC, useCallback, useEffect, useMemo } from 'react'
import { t } from '@lingui/macro'
import { ReadMore } from '../../ReadMore'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { IRiskManagerEnable, ITeamFormUserSubscriptionProps } from '../TeamForm.Types'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { Button, Checkbox, Input, InputSize, Select, SelectOption } from '@/ui/kit'
import { E_RISK_MANAGER_DRAWDOWN_TYPE, E_RISK_MANAGER_PERMISSIONS } from '@/redux/team/team.types'
import { ArrayedInputsGroup, TArrayedOption } from '@/ui/kit/Checkbox/Checkbox.Components/ArrayedInputsGroup'
import { HorizontalControls } from './TeamForm.Utils'
import { useActions, useAppSelector } from '@/utils'
import { teamAsyncActions } from '@/redux/team/team.actions'
import { Loader } from '@/ui/kit/Loader'
import { CheckboxProps, ECheckboxStyleVariant } from '@/ui/kit/Checkbox/Checkbox'
import { getDrawdownTypesText, getRiskManagerPermissionTranslations } from '@/translations/team'
import { yupResolver } from '@hookform/resolvers/yup'
import { VALIDATION_SCHEMA } from '../TeamForm.Utils/validation/riskSubscription.schema'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'

import styles from '../style.module.scss'
import { useFirstRender } from '@/utils/hooks'
import { teamActions } from '@/redux/team/team.slice'
import { Control } from '@/ui/atoms/Control'
import { truncateNumber } from '@/utils/lib/truncateNumber'
import { formatRiskSubscriptionPayload } from '../TeamForm.Utils/formatters/riskSubscription.formatter'
import { getRiskManagerErrors } from '@/backend/services/team'
import { useErrorsParser } from '@/utils/hooks/useErrorsParser'
import { Trans } from '@lingui/react'

type TRiskManagerEnableForm = IRiskManagerEnable & {
  globalError: any
}

export const TeamFormRiskSubscription: FC<ITeamFormUserSubscriptionProps> = props => {
  const { rmGetRequestStatus } = useAppSelector(state => state.team)
  const { GetTeamRiskManagerTC } = useActions(teamAsyncActions)
  const { firstRender } = useFirstRender()

  // initial data load
  useEffect(() => {
    GetTeamRiskManagerTC()
  }, [GetTeamRiskManagerTC])

  const isLoadingState = useMemo(() => {
    return !isStatusFinal(rmGetRequestStatus) || firstRender
  }, [rmGetRequestStatus, firstRender])

  if (isLoadingState) return <Loader />

  return <Form {...props} />
}
const Form: FC<ITeamFormUserSubscriptionProps> = props => {
  const { SetTeamRiskManagerTC, GetTeamRiskManagerTC, GetUserTeamInfoTC, setSetTeamRiskManagerReset, setErrors } =
    useActions(teamActions)
  const { rmSetRequestStatus, errors: teamErrors } = useAppSelector(state => state.team)

  const { handleBack, handleBackSuccess, handleClose, showCloseControl } = props

  const {
    riskManagerSettings: { permissions, type: riskManagerType },
  } = useTeamData()

  const getErrors = getRiskManagerErrors()

  const {
    handleSubmit,
    control,
    formState,
    clearErrors,
    formState: { errors },
    setError,
    trigger,
  } = useForm<TRiskManagerEnableForm>({
    mode: 'all',
    resolver: yupResolver(VALIDATION_SCHEMA()),
    defaultValues: {
      drawdownType: E_RISK_MANAGER_DRAWDOWN_TYPE.usdt,
      drawdownValue: 0,
      permissions: [],
      userHandle: '',
      type: riskManagerType,
    },
  })

  useErrorsParser(teamErrors, setError, getErrors)
  const globalErrorMessage = errors?.globalError?.message

  useEffect(() => {
    return () => {
      setErrors()
    }
  }, [setErrors])

  const formValues = useWatch({ control })

  const onSubmit = useCallback<SubmitHandler<IRiskManagerEnable>>(
    (data: IRiskManagerEnable) => {
      setErrors()
      clearErrors()
      SetTeamRiskManagerTC(formatRiskSubscriptionPayload(data))
    },
    [SetTeamRiskManagerTC, setErrors]
  )

  const optionsPermissions = useMemo<TArrayedOption<E_RISK_MANAGER_PERMISSIONS>[]>(() => {
    const permissionTranslations = getRiskManagerPermissionTranslations()
    return permissions.map(permission => {
      return {
        description: permissionTranslations[permission],
        value: permission,
      }
    })
  }, [permissions])

  const isSubmitEnabled = useMemo(() => {
    return formState.isValid
  }, [formState])

  useEffect(() => {
    if (isStatusFinal(rmSetRequestStatus)) setSetTeamRiskManagerReset()
    if (rmSetRequestStatus === 'succeeded') {
      handleBackSuccess && handleBackSuccess()
    }
  }, [handleBackSuccess, rmSetRequestStatus, GetTeamRiskManagerTC, GetUserTeamInfoTC, setSetTeamRiskManagerReset])

  const optionsDrawdown = useMemo<SelectOption<E_RISK_MANAGER_DRAWDOWN_TYPE>[]>(() => {
    const drawdownNames = getDrawdownTypesText()
    return Object.values(E_RISK_MANAGER_DRAWDOWN_TYPE).map(drawdown => {
      return {
        label: drawdownNames[drawdown],
        value: drawdown,
      }
    })
  }, [])

  const checkboxProperties = useMemo<Partial<CheckboxProps>>(() => {
    return {
      className: styles.checkboxItem,
      styleVariant: ECheckboxStyleVariant.DARK,
    }
  }, [])

  const translations = useMemo(() => {
    return {
      drawdownValueLabel:
        formValues.drawdownType === E_RISK_MANAGER_DRAWDOWN_TYPE.percent
          ? t({
              id: 'teamRiskSubscriptionForm.input.drawdownValue.label.percent',
            })
          : t({
              id: 'teamRiskSubscriptionForm.input.drawdownValue.label.usdt',
            }),
    }
  }, [formValues.drawdownType])

  const isLoadingState = useMemo(() => {
    return rmSetRequestStatus === 'loading'
  }, [rmSetRequestStatus])

  if (isLoadingState) return <Loader />

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        {t({
          id: 'teamRiskSubscriptionForm.heading',
        })}
        {showCloseControl && <Control.Close onClick={handleClose} className={styles.closeControl} />}
      </div>

      <ReadMore
        heading={t({ id: 'teamRiskSubscriptionForm.readMore.heading' })}
        content={
          <Trans
            id="teamRiskSubscriptionForm.readMore.content"
            components={{
              br: <br />,
            }}
          />
        }
      />

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.subheading}>
          {t({
            id: 'teamRiskSubscriptionForm.permissions.heading',
          })}
        </div>
        <ArrayedInputsGroup
          component={Checkbox}
          control={control}
          name="permissions"
          options={optionsPermissions}
          componentProps={checkboxProperties}
          className={styles.formItem}
          wrapClassname={styles.formItemSmall}
          errorMessage={formState.isSubmitted && errors.permissions?.message}
        />

        <div className={styles.formItem}>
          <Controller
            control={control}
            name={'userHandle'}
            render={({ field }) => (
              <Input
                size={InputSize.Small}
                placeholder={t({
                  id: 'teamRiskSubscriptionForm.input.userHandle.placeholder',
                })}
                value={field.value}
                setValue={value => {
                  field.onChange(value)
                }}
                inputAttrs={{ type: 'text' }}
                label={t({
                  id: 'teamRiskSubscriptionForm.input.userHandle.label',
                  message: 'Telegram user`s name',
                })}
                errorMessage={errors.userHandle?.message}
              />
            )}
          />
        </div>

        <div className={styles.formItem}>
          <Controller
            control={control}
            name={'drawdownType'}
            render={({ field }) => (
              <Select
                label={t({
                  id: 'teamRiskSubscriptionForm.input.drawdownType.label',
                  message: 'Drawdown type',
                })}
                options={optionsDrawdown}
                value={field.value}
                size={Select.Size.Small}
                onChange={value => {
                  field.onChange(value as string)
                  trigger('drawdownValue')
                }}
                toggleClassname={styles.selectToggle}
                labelClassname={styles.selectLabel}
                dropdownClassName={styles.selectDropdown}
                errorMessage={errors.drawdownType?.message}
              />
            )}
          />
        </div>

        <div className={styles.formItem}>
          <Controller
            control={control}
            name={'drawdownValue'}
            render={({ field }) => (
              <Input
                size={InputSize.Small}
                placeholder={t({
                  id: 'teamRiskSubscriptionForm.input.drawdownValue.placeholder',
                })}
                value={field.value}
                setValue={value => {
                  // TODO: move to normalizers
                  const valueFormatted = value ? truncateNumber(Number(value), 0) : value
                  field.onChange(valueFormatted)
                }}
                inputAttrs={{ type: 'number' }}
                label={translations.drawdownValueLabel}
                errorMessage={errors.drawdownValue?.message}
              />
            )}
          />
        </div>
        <p className={styles.globalError}>{globalErrorMessage}</p>
        <HorizontalControls className={styles.submitLine}>
          {handleBack && (
            <Button.Primary
              label={t({ id: 'core.close', message: 'Close' })}
              className={styles.submitBtn}
              onClick={handleBack}
            />
          )}
          <Button.Accent
            label={t({ id: 'teamRiskSubscriptionForm.submit.apply', message: 'Activate risk-manager' })}
            className={styles.submitBtn}
            type={'submit'}
            disabled={!isSubmitEnabled}
          />
        </HorizontalControls>
      </form>
    </div>
  )
}
