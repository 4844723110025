import clsx from 'clsx'
import React, { FC } from 'react'
import style from './style.module.scss'
import { ApiKeyEditForm, ApiKeyEditFormProps } from '../ApiKeyEditForm'
import { ApiKeyDTO } from '../../../backend/models/ApiKeyDTO'
import { SVG } from '../../../assets/svg'
import { PageSetupGoogleAuth } from '../WalletSetupGoogleAuth'
import { useGoogleAuthCode, useGoogleAuthSettings } from '@/utils/hooks'
import { urls } from '@/router/urls'

interface ApiKeyEditProps extends Omit<ApiKeyEditFormProps, 'apiKeyDTO'> {
  apiKeyDTO?: ApiKeyDTO
}

export const ApiKeyEdit: FC<ApiKeyEditProps> = props => {
  const { onSubmit, onCancel, isSubmitDisabled, apiKeyDTO } = props
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()

  if (!googleAuthSettings.settings.enabled) {
    return (
      <div className={style.content}>
        <PageSetupGoogleAuth
          googleAuthSettings={googleAuthSettings}
          googleAuthCode={googleAuthCode}
          backRoute={urls.api}
        />
      </div>
    )
  }

  return (
    <div className={clsx(style.content)}>
      {!apiKeyDTO ? (
        <div className={style.loaderWrapper}>
          <SVG.Loaders.Default className={style.loader} width={29} height={29} />
        </div>
      ) : (
        <ApiKeyEditForm
          onSubmit={onSubmit}
          onCancel={onCancel}
          isSubmitDisabled={isSubmitDisabled}
          apiKeyDTO={apiKeyDTO}
        />
      )}
    </div>
  )
}
