import { ECashbackCalculationRule } from '@/backend/api/cashbackAPI'
import { createSlice } from '@reduxjs/toolkit'
import cashbackAsyncActions from './cashback.actions'
import initialState from './cashback.state'

export const slice = createSlice({
  name: `[Tiger Trade Account Cashback]`,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(cashbackAsyncActions.GetCashbackTC.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(cashbackAsyncActions.GetCashbackTC.fulfilled, (state, action) => {
        state.cashback = action.payload
        state.status = 'succeeded'
      })
      .addCase(cashbackAsyncActions.GetCashbackTC.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(cashbackAsyncActions.GetCashbackTermsTC.pending, (state, action) => {
        state.cashbackTermsStatus = 'loading'
      })
      .addCase(cashbackAsyncActions.GetCashbackTermsTC.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(cashbackAsyncActions.GetCashbackTermsTC.fulfilled, (state, action) => {
        state.cashbackTermsStatus = 'succeeded'

        state.cashbackTerms = {
          ...state.cashbackTerms,
          allocationBalance: action.payload.filter(
            term => term.calculationRule === ECashbackCalculationRule.allocationBalance
          ),
          tradeVolume: action.payload.filter(term => term.calculationRule === ECashbackCalculationRule.tradeVolume),
        }
      })
      .addCase(cashbackAsyncActions.GetCashbackCalculatedTimeTC.fulfilled, (state, action) => {
        state.prevCalculationTime = action.payload.prevCalculationTime
        state.nextCalculationTime = action.payload.nextCalculationTime
      })
  },
})

export const cashbackActions = {
  ...slice.actions,
  ...cashbackAsyncActions,
}

export const cashbackReducer = slice.reducer
