import { FC, useCallback, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { Toggle } from '@/ui/kit'
import { profileActions } from '@/redux/profile/profile.slice'
import { useActions, useAppSelector } from '@/utils'
import { urls } from '@/router/urls'
import style from '../../style.module.scss'

const DefaultPage: FC = () => {
  const {
    metadata: { redirectTo },
  } = useAppSelector(state => state.profile)

  const { UpdateMetadata } = useActions(profileActions)
  const [active, setActive] = useState(redirectTo === urls.orders)

  const handleChange = useCallback((active: boolean) => {
    setActive(active)

    const response = UpdateMetadata({
      rootPage: active ? 'orders' : 'wallet',
    })

    if ('error' in response) {
      setActive(!active)
      enqueueSnackbar(t({ id: 'profile.rootPageError', message: 'Error setting default page' }), { variant: 'error' })
    } else {
      enqueueSnackbar(t({ id: 'profile.rootPageSuccess', message: 'Default page successfully saved' }), {
        variant: 'success',
      })
    }
  }, [])

  return (
    <div className={style.setting}>
      <SVG.Profile.Home className={style.sectionIcon} />
      <div>
        <div className={style.settingTitle}>
          {t({ id: 'profile.rootPage', message: `Set Orders & Positions as default page` })}
        </div>
        <div className={style.settingStatus}>
          {t({
            id: 'profile.rootPageDescription',
            message: 'When enabled, Orders & Positions page will open first after logging in to the service',
          })}
        </div>
      </div>
      <div className={style.settingActions}>
        <Toggle active={active} onChange={handleChange} />
      </div>
    </div>
  )
}

export { DefaultPage }
