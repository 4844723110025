import { FC } from 'react'

interface Props {
  className?: string
}

export const Draggable: FC<Props> = ({ className }) => (
  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <ellipse cx="8.97816" cy="2.02697" rx="2.0221" ry="2.02697" fill="white" fillOpacity="0.2" />
    <ellipse cx="2.0221" cy="2.02697" rx="2.0221" ry="2.02697" fill="white" fillOpacity="0.2" />
    <ellipse cx="2.0221" cy="9.00012" rx="2.0221" ry="2.02697" fill="white" fillOpacity="0.2" />
    <ellipse cx="8.97816" cy="9.00012" rx="2.0221" ry="2.02697" fill="white" fillOpacity="0.2" />
    <ellipse cx="8.97816" cy="15.973" rx="2.0221" ry="2.02697" fill="white" fillOpacity="0.2" />
    <ellipse cx="2.0221" cy="15.973" rx="2.0221" ry="2.02697" fill="white" fillOpacity="0.2" />
  </svg>
)
