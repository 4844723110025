import clsx from 'clsx'
import { MouseEvent, useCallback } from 'react'
import { DropDownItem } from '@/ui/kit/Dropdown/Dropdown'
import { typedMemo } from '@/utils'
import s from './style.module.scss'

type TabsProps<T extends string = string> = {
  /** initially selectedOption */
  selectedOption: DropDownItem<T> | null

  /** items to display */
  options: DropDownItem<T>[]

  /** onchange handler */
  setSelectedOption: (option: Omit<DropDownItem<T>, 'icon'>) => void
}

export const MobileTabs = typedMemo(
  <T extends string = string>({ selectedOption, options, setSelectedOption }: TabsProps<T>) => {
    const handleItemClick = useCallback(
      (item: Omit<DropDownItem<T>, 'icon'>) => {
        return (e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          e.stopPropagation()
          setSelectedOption(item)
        }
      },
      [setSelectedOption]
    )

    return (
      <table className={s.table}>
        <tbody>
          <tr>
            {options.map(option => (
              <th key={option.label}>
                <button
                  className={clsx(s.tab, { [s.active]: selectedOption?.label === option.label })}
                  onClick={handleItemClick(option)}
                >
                  {option.label}
                </button>
              </th>
            ))}
          </tr>
        </tbody>
      </table>
    )
  }
)
