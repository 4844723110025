import React from 'react'

interface AddressProps {
  className?: string
}

const Address: React.FC<AddressProps> = props => {
  const { className } = props

  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <rect width="40" height="40" rx="20" fill="url(#a)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10a2 2 0 1 0 0 4h4a2 2 0 1 0 0-4h-4Zm-7 6a4 4 0 0 1 3.5-3.969 3.5 3.5 0 0 0 3.5 3.47h4a3.5 3.5 0 0 0 3.5-3.47 4 4 0 0 1 3.5 3.97v10a4 4 0 0 1-4 4H15a4 4 0 0 1-4-4V16Zm8.25 2a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Zm.75 3.25a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4ZM19.25 26a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Zm-1.75-8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="#FAFAFA"
      />
      <defs>
        <linearGradient id="a" x1="0" y1="40" x2="30.351" y2="-6.322" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BFA8FF" />
          <stop offset=".472" stopColor="#FF51BC" />
          <stop offset="1" stopColor="#FC4F49" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { Address }
