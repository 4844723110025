import type { ThunkError } from '@/types'
import type { BoardChunkedResponse } from '@/utils/types/contracts.types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'
import { DashboardAPI } from '@/backend/api/dashboard.api'
import { DashboardQueryType } from './dashboard.types'

export const GetBoardTC = createAsyncThunk<BoardChunkedResponse, DashboardQueryType, ThunkError>(
  nameof(DashboardAPI.loadBoards),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: DashboardAPI.loadBoards, payload }, thunkAPI)
  }
)
