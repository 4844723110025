import { FC } from 'react'
import { IconFactory } from '@/assets/svg/cryptoIcons/IconFactory'
import s from './style.module.scss'

interface ICoinSelectOptionProps {
  asset: string
  balance?: string | number
  large?: boolean
}

const CoinSelectOption: FC<ICoinSelectOptionProps> = props => {
  const { asset, balance, large } = props

  return (
    <div className={s.root}>
      <IconFactory type={asset} width={large ? '19px' : '15px'} height={large ? '19px' : '15px'} />
      {balance && <div>{balance}</div>}
      <div>{asset}</div>
    </div>
  )
}

export { CoinSelectOption }
