import { APIResponseV3WithTime, WalletBalanceV5 } from 'bybit-api'

export const emptyWalletBalance: { response: APIResponseV3WithTime<{ list?: WalletBalanceV5[] }>; status: number } = {
  response: {
    retCode: 0,
    retMsg: 'OK',
    result: {
      list: [],
    },
    time: new Date().getTime(),
  },
  status: 200,
}
