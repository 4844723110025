import { AccountBill } from '@/services/okx'
import { ExchangeHistoryItemInterface, InitialState } from './transactions.types'
import { TUsdmIncomeHistory } from '@tigertrade/binance-ts'

export const handleBinanceExchange = (state: InitialState, action: any) => {
  const limit = action.meta.arg.params.limit
  if (limit !== undefined && Number(limit) > action.payload.length) {
    state.exchangeHistory.historyReachedEnd = true
  } else {
    state.exchangeHistory.historyReachedEnd = false
  }

  if (action.payload.length > 0) {
    state.exchangeHistory.historyCurrentLast = (action.meta.arg.params.page as string) || '0'
    const filterObj = {
      startTime: action.meta.arg.params.startTime,
      endTime: action.meta.arg.params.endTime,
      incomeType: action.meta.arg.params.incomeType,
    }
    const hasTruthyValue = Object.values(filterObj).some(value => value !== '')
    const payload = !hasTruthyValue
      ? (action.payload as TUsdmIncomeHistory[]).sort((a, b) => (a.time > b.time ? -1 : 1))
      : action.payload
    state.exchangeHistory.history = [...state.exchangeHistory.history, ...(payload as ExchangeHistoryItemInterface[])]
  } else {
    if (action.meta.arg.params.after === undefined && action.meta.arg.params.page === undefined) {
      state.exchangeHistory.history = []
      state.exchangeHistory.historyCurrentLast = ''
    }
  }
}

export const handleOkxExchange = (state: InitialState, action: any) => {
  const limit = action.meta.arg.params.limit
  if (limit !== undefined && Number(limit) > action.payload.length) {
    state.exchangeHistory.historyReachedEnd = true
  } else {
    state.exchangeHistory.historyReachedEnd = false
  }

  if (action.payload.length > 0) {
    state.exchangeHistory.historyCurrentLast = (action.payload as AccountBill[])[action.payload.length - 1]
      .billId as string

    state.exchangeHistory.history = [
      ...state.exchangeHistory.history,
      ...(action.payload as ExchangeHistoryItemInterface[]),
    ]
  } else {
    if (action.meta.arg.params.after === undefined && action.meta.arg.params.page === undefined) {
      state.exchangeHistory.history = []
      state.exchangeHistory.historyCurrentLast = ''
    }
  }
}

export const handleBybitExchange = (state: InitialState, action: any) => {
  const cursor = action.payload.result.nextPageCursor
  state.exchangeHistory.bybitNextCursor = cursor
  const limit = action.meta.arg.params.limit
  if (limit !== undefined && Number(limit) > action.payload.result.list.length) {
    state.exchangeHistory.historyReachedEnd = true
  } else {
    state.exchangeHistory.historyReachedEnd = false
  }

  if (action.payload.result.list.length > 0) {
    state.exchangeHistory.historyCurrentLast = action.payload.result.nextPageCursor as string
    const payload = action.payload.result.list
    state.exchangeHistory.history = [...state.exchangeHistory.history, ...(payload as ExchangeHistoryItemInterface[])]
  } else {
    if (action.meta.arg.params.cursor === undefined || action.meta.arg.params.limit === undefined) {
      state.exchangeHistory.history = []
      state.exchangeHistory.historyCurrentLast = ''
    }
  }
}
