import { FC, ReactNode } from 'react'
import style from './style.module.scss'
import clsx from 'clsx'

type MessageWithIconProps = {
  icon: ReactNode
  message: ReactNode
  className?: string
  classNameRoot?: string
}

const MessageWithIcon: FC<MessageWithIconProps> = ({ icon, message, className, classNameRoot }) => {
  return (
    <div className={clsx(style.root, classNameRoot)}>
      <div className={style.icon}>{icon}</div>
      <div className={clsx(style.message, className)}>{message}</div>
    </div>
  )
}

export { MessageWithIcon }
