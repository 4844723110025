import React from 'react'

export const Transfer = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" fill="#1C1A24" />
      <path
        d="M20.5625 24.375C20.5625 24.375 18.3151 22.0895 16.875 20.625M16.875 20.625C18.3151 19.1605 20.5625 16.875 20.5625 16.875M16.875 20.625C20.9895 20.625 31.875 20.625 31.875 20.625"
        stroke="url(#paint0_radial_15315_12029)"
        strokeWidth="1.875"
        strokeLinecap="round"
      />
      <path
        d="M29.4375 33.125C29.4375 33.125 31.6849 30.8395 33.125 29.375M33.125 29.375C31.6849 27.9105 29.4375 25.625 29.4375 25.625M33.125 29.375C29.0105 29.375 18.125 29.375 18.125 29.375"
        stroke="url(#paint1_radial_15315_12029)"
        strokeWidth="1.875"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_15315_12029"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.875 24.375) rotate(-30.0686) scale(14.969 173.397)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_15315_12029"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.125 33.125) rotate(-149.931) scale(14.969 173.397)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
