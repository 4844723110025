import { DATE_FORMAT, formatDate } from './formatDate'

const SECONDS_IN_MINUTE = 60

export const getFormattedUTCDate = (date: Date, format?: DATE_FORMAT) => {
  const offset = date.getTimezoneOffset() / SECONDS_IN_MINUTE

  date.setHours(date.getHours() + offset)
  if (format) {
    return formatDate(date, format)
  }
  return formatDate(date, DATE_FORMAT.DAY_AND_YEAR)
}
