import React, { FC, memo } from 'react'

interface PropsType {}

export const ExpiredFill: FC<PropsType> = memo(() => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="49.9999" height="49.9999" rx="24.9999" fill="url(#paint0_linear_12944_139827)" />
      <path
        d="M24.1963 16.9641V24.9998C24.1963 25.8874 24.9158 26.6069 25.8034 26.6069H30.6248"
        stroke="white"
        strokeWidth="3.21428"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12944_139827"
          x1="-6.59731e-07"
          y1="49.9999"
          x2="43.8549"
          y2="-4.90803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC4ED" />
          <stop offset="0.482059" stopColor="#FEA471" />
          <stop offset="1" stopColor="#FF627E" />
        </linearGradient>
      </defs>
    </svg>
  )
})
