import { isFunction } from 'lodash-es'

export function chainFns(...callbacks: unknown[]): (...args: unknown[]) => unknown {
  return (...args: unknown[]) => {
    for (let callback of callbacks) {
      if (isFunction(callback)) {
        callback(...args)
      }
    }
  }
}
