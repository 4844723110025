import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { ReadNotificationType, notificationAPI } from '@/backend/api/notificationAPI'
import { FiltersType, NotificationDataResponse, SummaryReqType, SummaryType } from './notification.types'

export const GetNotifySummary = createAsyncThunk<SummaryType, SummaryReqType, ThunkError>(
  nameof(notificationAPI.getNotifySummary),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: notificationAPI.getNotifySummary, payload }, thunkAPI)
  }
)

export const GetUserNotifications = createAsyncThunk<NotificationDataResponse, FiltersType, ThunkError>(
  nameof(notificationAPI.getUserNotifications),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: notificationAPI.getUserNotifications, payload }, thunkAPI)
  }
)

export const PostReadNotifications = createAsyncThunk<void, void, ThunkError>(
  nameof(notificationAPI.postReadNotifications),
  async (_, thunkAPI) => {
    return await ThunkCreator({ apiMethod: notificationAPI.postReadNotifications }, thunkAPI)
  }
)

export const PostReadNotificationById = createAsyncThunk<any, ReadNotificationType, ThunkError>(
  nameof(notificationAPI.postReadNotification),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: notificationAPI.postReadNotification, payload }, thunkAPI)
  }
)
