import { InitialState } from './notification.types'

export const initialState: InitialState = {
  summary: {
    announcements: 0,
    updates: 0,
  },
  notificationData: {
    notifications: [],
    filters: {
      unreadOnly: false,
      page: 0,
      size: 10,
      total: 0,
      type: null,
    },
  },
  isLoading: false,
  errors: [],
}

export default initialState
