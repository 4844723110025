import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { t } from '@lingui/macro'
import { Voucher } from '@/backend/models/VoucherDTO'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import s from './style.module.scss'
import { EXCHANGE_ICON } from '@/core/constants'

interface IVoucherItemProps {
  voucher: Voucher
}

const VoucherItem: FC<IVoucherItemProps> = props => {
  const { voucher } = props

  const expired = useMemo(() => {
    return new Date() > new Date(voucher.endDate)
  }, [voucher])

  const ExchangeIcon = useMemo(() => {
    return voucher.exchangeType ? EXCHANGE_ICON[voucher.exchangeType] : null
  }, [voucher.exchangeType])

  return (
    <div className={clsx(s.root, { [s.expired]: expired })}>
      <div>
        <div>
          {voucher.amount}% {t({ message: 'commission reduce', id: 'voucher.commissionReduce' })}
        </div>
        <div className={s.info}>{formatDate(voucher.createdAt)}</div>
      </div>
      <div>
        {t({ message: 'Applied to', id: 'voucher.applied' })}
        <div className={s.iconWrap}>
          {voucher.exchangeType ? (
            <ExchangeIcon className={s.icon} />
          ) : (
            Object.entries(EXCHANGE_ICON).map(([exchange, Icon]) => {
              return <Icon key={exchange} className={s.icon} />
            })
          )}
        </div>
      </div>
      <div>
        <div>
          {voucher.endDate
            ? formatDate(voucher.endDate, DATE_FORMAT.DAY_AND_YEAR)
            : t({ message: 'Never', id: 'voucher.never' })}
        </div>
        <div className={s.info}>{t({ message: 'Expiry date', id: 'voucher.expiredDate' })}</div>
      </div>
      <div className={s.status}>
        {expired ? t({ message: 'Expired', id: 'voucher.expired' }) : t({ message: 'Active', id: 'voucher.active' })}
      </div>
    </div>
  )
}

export { VoucherItem }
