import { SVG } from '@/assets/svg'
import { RiskEvent } from '@/redux/riskManager/riskManager.types'
import { Button, InteractiveModal } from '@/ui/kit'
import { t } from '@lingui/macro'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import style from './style.module.scss'
import { formatNumber, useActions, useAppSelector } from '@/utils'
import clsx from 'clsx'
import { useEventPositionsTable } from './hooks/useEventPostionsTable'
import { Table } from '@/ui/molecules/Table'
import { useEventPositionColumns } from './hooks/useEventPositionColumns'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'
import { Loader } from '@/ui/kit/Loader'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'
import { useReasonMessage } from './hooks/useReasonMessage'

interface ModalContentProps {
  onClose: () => void
  event: RiskEvent
}

const ModalContent: FC<ModalContentProps> = ({ event, onClose }) => {
  const { eventById, eventByIdRequestStatus } = useAppSelector(state => state.riskManager)
  const { GetRiskManagerEventByIdTC, setEventByIdReset } = useActions(riskManagerActions)

  const data = useEventPositionsTable({ event: eventById })
  const columns = useEventPositionColumns()

  const isEventFailed = eventByIdRequestStatus === 'failed'
  const isEventLoading = !isStatusFinal(eventByIdRequestStatus) && eventById === null
  const isEventLoaded = eventByIdRequestStatus === 'succeeded'

  const reasonMessage = useReasonMessage(event)

  useEffect(() => {
    if (eventByIdRequestStatus === 'idle') GetRiskManagerEventByIdTC(event.eventId)
  }, [event.eventId, eventByIdRequestStatus])

  useEffect(() => {
    return () => {
      setEventByIdReset()
    }
  }, [])

  return (
    <div className={style.container}>
      <section className={style.header}>
        <div className={style.title}>{t({ id: 'riskManager.details', comment: 'Details' })}</div>

        <Button.Primary
          onClick={onClose}
          className={style.closeBtn}
          leftIcon={<SVG.Additional.Close width={'10px'} height={'10px'} />}
        />
      </section>

      <section className={style.info}>
        <div className={clsx(style.infoColumn, style.infoColumnLeft)}>
          <div className={style.infoTitle}>
            {t({
              id: 'riskManager.eventDate',
              comment: 'Дата срабатывания',
            })}
          </div>

          <div className={style.infoValue}>{event.eventTimeFormatted}</div>
        </div>

        <div className={clsx(style.infoColumn, style.infoColumnLeft)}>
          <div className={style.infoTitle}>
            {reasonMessage &&
              t({
                id: 'riskManager.eventReason',
                comment: 'Причина',
              })}
          </div>

          <div className={style.infoValue}>{reasonMessage}</div>
        </div>

        <div className={clsx(style.infoColumn, style.infoColumnRight)}>
          <div className={style.infoTitle}>
            {t({
              id: 'riskManager.eventBalanceStart',
              comment: 'Баланс на начало дня',
            })}
          </div>

          <div className={style.infoValue}>
            {event.actualBalance.formattedValue} {event.actualBalance.symbol}
          </div>
        </div>

        <div className={clsx(style.infoColumn, style.infoColumnRight)}>
          <div className={style.infoTitle}>
            {t({
              id: 'riskManager.eventBalanceMoment',
              comment: 'Баланс в момент срабатывания',
            })}
          </div>

          <div className={style.infoValue}>
            {event.targetBalance.formattedValue} {event.targetBalance.symbol}
          </div>
        </div>
      </section>

      {/* TODO: Нужен дизайн */}
      {/* isEventFailed && (
        <section className={clsx(style.table, style.flexCenter, style.flexColumn)}>
          <div style={{ marginBottom: '10px' }}>Something wrong...</div>

          <Button.Primary onClick={refresh} size={Button.Size.Medium}>
            Try again
          </Button.Primary>
        </section>
      ) */}

      {/* TODO: Нужен дизайн */}
      {isEventLoading && (
        <section className={clsx(style.table, style.flexCenter)}>
          <Loader />
        </section>
      )}

      {/* TODO: Нужен дизайн */}
      {/* isEventLoaded && !data.length && (
        <section className={clsx(style.table, style.flexCenter)}>
          <div>No positions</div>
        </section>
      ) */}

      {isEventLoaded && !!data.length && (
        <section className={style.table}>
          <Table
            tableClassName={style.tableSpacing}
            rowClassname={style.row}
            tbodyClassName={style.tbody}
            thClassName={style.th}
            data={data}
            accessors={columns}
          />
        </section>
      )}
    </div>
  )
}

interface EventModalProps extends ModalContentProps {
  isOpen: boolean
}

export const EventModal: FC<EventModalProps> = memo(({ event, onClose, isOpen }) => (
  <InteractiveModal onClose={onClose} isOpen={isOpen} className={style.popup}>
    <ModalContent onClose={onClose} event={event} />
  </InteractiveModal>
))
