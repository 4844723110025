import { FC } from 'react'

interface ITransferProps {
  className?: string
}

export const Transfer: FC<ITransferProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.45 7.5C4.45 7.5 2.65205 5.67157 1.5 4.5M1.5 4.5C2.65205 3.32843 4.45 1.5 4.45 1.5M1.5 4.5C4.79156 4.5 13.5 4.5 13.5 4.5"
        stroke="var(--transfer-color, url(#paint0_radial_13233_14739))"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M11.55 14.5C11.55 14.5 13.348 12.6716 14.5 11.5M14.5 11.5C13.348 10.3284 11.55 8.5 11.55 8.5M14.5 11.5C11.2084 11.5 2.5 11.5 2.5 11.5"
        stroke="var(--transfer-color, url(#paint0_radial_13233_14739))"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_13233_14739"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1.5 7.5) rotate(-30.0686) scale(11.9752 138.718)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_13233_14739"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.5 14.5) rotate(-149.931) scale(11.9752 138.718)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
