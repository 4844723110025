import { FC } from 'react'
import { t } from '@lingui/macro'
import { HowItWorksQuestions } from '@/ui/molecules'
import style from './style.module.scss'

export const HowItWorksLeftBar: FC = () => {
  const HOW_IT_WORKS_QUESTIONS_DATA = [
    {
      question: t({
        id: 'howitworks.left.question1',
        message: `When will my saved funds become available?`,
      }),
      text: t({
        id: 'core.cashbackTimeTerms',
        message: `On the following day after 05:00 (UTC+0) for Binance and OKX, on the following day after 08:00 (UTC+0) for Bybit`,
      }),
    },
    {
      question: t({
        id: 'howitworks.left.question2',
        message: `Where can I find them?`,
      }),
      text: t({
        id: 'howitworks.left.answer2',
        message: `Your Spot wallet balance`,
      }),
    },
    {
      question: t({
        id: 'howitworks.left.question3',
        message: `How can I use my saved funds?`,
      }),
      text: t({
        id: 'howitworks.left.answer3',
        message: `Withdraw once received or use them for trading`,
      }),
    },
  ]

  return (
    <div className={style.leftBar}>
      <HowItWorksQuestions questions={HOW_IT_WORKS_QUESTIONS_DATA} />
    </div>
  )
}
