import { TPosition } from '@tigertrade/binance-ts'
import { AccountBill as AccountBillSource } from 'okx-api'

export enum EOkxInstruments {
  SPOT = 'SPOT',
  SWAP = 'SWAP',
}

export enum EOkxPositionSide {
  'long' = 'long',
  'short' = 'short',
  'net' = 'net',
}

export type TOkxPosition = TPosition & {
  ccy?: string
  adl?: number
}
export type TOkxMargin = 'cross' | 'isolated'

export enum EOkxPositionMode {
  'long_short_mode' = 'long_short_mode',
  'net_mode' = 'net_mode',
}

export enum EOkxOrderType {
  'market' = 'market',
  'limit' = 'limit',
  'post_only' = 'post_only',
  'fok' = 'fok',
  'ioc' = 'ioc',
  'optimal_limit_ioc' = 'optimal_limit_ioc',
  'mmp' = 'mmp',
  'mmp_and_post_only' = 'mmp_and_post_only',
  'conditional' = 'conditional',
  'oco' = 'oco',
  'trigger' = 'trigger',
  'move_order_stop' = 'move_order_stop',
  'iceberg' = 'iceberg',
  'twap' = 'twap',
}

export type TOkxLeverageEstimatedInfo = {
  estAvailQuoteTrans: string
  estAvailTrans: string
  estLiqPx: string
  estMaxAmt: string
  estMgn: string
  estQuoteMaxAmt: string
  estQuoteMgn: string
  existOrd: boolean
  maxLever: string
  minLever: string
}

export type TGetOkxMarkPricePayload = {
  instType: EOkxInstruments
  uly?: string
  instFamily?: string
  instId?: string
}

export type TGetOkxMarkPriceResponse = {
  instType: string
  instId: string
  markPx: string
  ts: string
}

export type AccountBill = AccountBillSource & {
  interest: string
  notes: string
  fillTime: string
  tradeId: string
  clOrdId: string
  fillIdxPx: string
  fillMarkPx: string
  fillPxVol: string
  fillPxUsd: string
  fillMarkVol: string
  fillFwdPx: string
  px: string
  tag: string
}
