import { t } from '@lingui/macro'
import { i18nWidgetList } from './index'
import { memoize } from 'lodash-es'

export const getENLocale = memoize(
  (): i18nWidgetList => ({
    income_and_profit: 'Income and profit',
    math_and_statistics: 'Math and statistics',
    risk_management: 'Risk management',
    volume_and_turnover: 'Volume and turnover',
    commissions_and_fees: 'Commissions and fees',
    balance_and_portfolio: 'Balance and portfolio',
    trade_metrics: 'Trade metrics',
    time_based_analysis: 'Time based analysis',
    tagging_and_categories: 'Tagging and categories',
    custom_and_derived_metrics: 'Custom and derived metrics',

    new: 'New',
    popular: 'Popular',
    complex: 'Complex',
    simple: 'Simple',
    premium: 'Premium',
    gem: 'Gem',

    example: t({ id: 'trades.dashboard.example', comment: 'example' }),
    trades_count: 'Trades count',

    profit: 'Profit',
    commission: 'Commission',
    loss: 'Loss',

    others: 'Others',

    hourly_intervals_0: '0:00am - 2:00am',
    hourly_intervals_1: '2:00am - 4:00am',
    hourly_intervals_2: '4:00am - 6:00am',
    hourly_intervals_3: '6:00am - 8:00am',
    hourly_intervals_4: '8:00am - 10:00am',
    hourly_intervals_5: '10:00am - 12:00pm',
    hourly_intervals_6: '12:00pm - 2:00pm',
    hourly_intervals_7: '2:00pm - 4:00pm',
    hourly_intervals_8: '4:00pm - 6:00pm',
    hourly_intervals_9: '6:00pm - 8:00pm',
    hourly_intervals_10: '8:00pm - 10:00pm',
    hourly_intervals_11: '10:00pm - 11:59pm',
    weekdays_Monday: 'Monday',
    weekdays_Tuesday: 'Tuesday',
    weekdays_Wednesday: 'Wednesday',
    weekdays_Thursday: 'Thursday',
    weekdays_Friday: 'Friday',
    weekdays_Sunday: 'Sunday',
    weekdays_Saturday: 'Saturday',

    income_usdt_stacked_by_api_keys: 'Stacked Profit by API Key ($)',
    income_usdt_stacked_by_api_keys_desc: `This widget displays the profit for each selected time interval (e.g., daily, weekly, monthly) combined from all API keys. It provides a comprehensive view of the total profit for each interval, highlighting the contribution of each API key, and helps traders assess performance across all their strategies.`,
    income_usdt_accumulative_by_api_key: t({
      id: 'trades.dashboard.income_usdt_accumulative_by_api_key',
      comment: 'Accumulative',
    }),
    income_usdt_accumulative_by_api_key_desc: `This widget displays the accumulative profit over time, broken down by individual API keys. It allows traders to track the performance of different API keys, providing insights into which strategies or bots are yielding the most profit. The accumulative view helps in understanding long-term profitability trends and the overall impact of different trading activities.`,
    income_usdt_stacked: 'Profit & Commission ($)',
    income_usdt_stacked_desc:
      'profit_commission_desc: `This widget visualizes the net profit and the corresponding commission fees over a specified period. It provides a clear view of the profit trends alongside the transaction costs, allowing traders to analyze their overall performance and cost efficiency. By understanding the impact of commissions on their net profits, traders can optimize their strategies for maximum profitability.',
    std_dev_profit_over_volume: `Profit Distribution by Trade Volume`,
    std_dev_mae_over_duration: `MAE Distribution Across Trade Durations`,
    std_dev_mfe_over_duration: `MFE Distribution Across Trade Durations`,
    std_dev_profit_over_duration: `Profit Distribution Across Trade Durations`,
    std_dev_mfe: `Trade Distribution by MFE`,
    std_dev_mae: `Trade Distribution by MAE`,
    std_dev_profit: `Trade Distribution by Profit`,
    volume_desc: `Turnover includes both the opening and closing orders. For example, a completed transaction of buying a coin for $100 will count as both a $100 purchase and a $100 sale, totaling a turnover of $200.`,
    std_dev_profit_over_volume_desc: `This widget displays the standard profit distribution relative to trade volume. It provides insights into the profitability correlation with trade sizes, helping traders identify whether larger or smaller trades are more profitable.`,
    std_dev_mae_over_duration_desc: `This widget illustrates the standard MAE distribution over various trade durations, showcasing the typical periods within a trade that experience the largest negative price movements. This is crucial for refining risk management strategies.`,
    std_dev_mfe_over_duration_desc: `This widget presents the standard MFE distribution over different trade durations, identifying periods when the highest potential gains typically occur, aiding in optimal trade closure for profit maximization.`,
    std_dev_profit_over_duration_desc: `This widget shows the standard profit distribution over different time frames within a trade, helping to identify the most and least profitable periods for better timing of trade entries and exits.`,
    std_dev_mfe_desc: `This widget plots the standard MFE distribution for a set of trades, allowing traders to analyze common favorable price excursions to improve strategy for capitalizing on positive movements.`,
    std_dev_mae_desc: `This widget displays the standard MAE distribution for a series of trades, offering insights into the frequency of negative price movements, essential for setting effective stop-loss orders.`,
    std_dev_profit_desc: `This widget illustrates the standard profit distribution for a collection of trades, providing a basis for strategy evaluation and refinement by highlighting typical profit or loss trends.`,
    balance_per_api_key: `Balance by API Key`,
    pay_off_ratio: `Payoff Ratio`,
    income_usdt_api_keys: `Profit by API Key`,
    balance: `Balance`,
    profit_factor: `Profit Factor`,
    profit_factor_per_symbol: `Profit Factor by Symbol`,
    pie_symbol_income_profit: `Profit Distribution by Coin`,
    pie_symbol_income_loose: `Loss Distribution by Coin`,
    funding: `Funding ($)`,
    avg_volume: `Average Trade Volume ($)`,
    avg_volume_per_symbol: `Average Volume by Symbol ($)`,
    average_max_win_percent: `Average Maximum Profit (%)`,
    max_max_win_percent: `Maximum Maximum Profit (%)`,
    average_max_loose_percent: `Average Maximum Loss (%)`,
    min_max_loose_percent: `Minimum Maximum Loss (%)`,
    max_max_loose_percent: `Maximum Maximum Loss (%)`,
    average_leverage: `Average Leverage`,
    max_leverage: `Maximum Leverage`,
    min_leverage: `Minimum Leverage`,
    pie_symbol: `Coin Distribution`,
    pie_side: `Long/Short Distribution`,
    symbol_income: `Profit by Ticker ($)`,
    category_profit: `Profit by Category ($)`,
    volume_usdt_accumulative: `Accumulative turnover ($)`,
    category_win_percentage: `Win rate by Category (%)`,
    income_usdt: `Profit ($)`,
    income_usdt_bar: `Profit 1($)`,
    income_usdt_bar_desc: `Profit 1($)`,
    average_return: `Average return of portfolio (%)`,
    risk_values_usd: `Loss limits per day ($)`,
    allowable_loss: `Allowable loss`,
    ticker_price: `Asset Price Chart`,
    ticker_price_desc: `Asset Price Chart - a linear or candlestick chart showing the asset price changes for visual comparison with your trading results. Supports BTCUSDT and ETHUSDT tickers. You can select the desired ticker in the widget settings (pencil icon in the corner). To change the period, adjust the settings in the "group data" section by minutes, hours, etc.`,
    stop_trading_loss: `Stop trading`,
    over_trading: `Over trading`,
    profit_if_stop_trading: `Profit difference`,
    recovery_factor: `Recovery factor`,
    average_loss_deviation_percent: `Deviation from the average loss (%)`,
    trading_session_math_expectation: `Math expectation per trading session ($)`,
    'session-none': `None`,
    'session-asia': `Asia`,
    'session-europe': `Europe`,
    'session-usa': `USA`,
    'session-asia-europe': `Asia/Europe`,
    'session-europe-usa': `Europe/USA`,
    volume_usdt_accumulative_desc: `Cumulative turnover from transactions. Turnover includes both opening and closing orders (for example: a closed transaction for buying a coin for $100 will be counted as a purchase of $100 and a sale of ~$100, turnover $200)`,
    recovery_factor_desc: `The Recovery Factor is a financial metric that measures the risk-adjusted performance of an investment or trading strategy. It is calculated as the Total Profit divided by the Maximum Drawdown. The Recovery Factor provides insight into how efficiently an investment has converted risk into return.

High (> 2): Efficient risk-to-reward, good strategy.
Moderate (1 to 2): Average efficiency, caution needed.
Low (< 1): Poor efficiency, high risk for low reward.
Negative: Losing money, high risk.`,
    trading_session_math_expectation_desc: `This widget provides a breakdown of trade performance segmented by the major global trading sessions. By grouping trades from the Asian, European, and American markets, as well as their overlap periods, it calculates the mathematical expectation for each session, helping investors understand the expected return based on historical data.`,
    average_loss_deviation_percent_desc: `This widget reads the ratio of average loss to its standard deviation for the selected period. Values higher than 100% mean that there are losses much larger than your average loss. Such value indicate non-compliance with risk management.

  The formula: standard deviation of the loss divided by the average loss multiplied by 100`,
    risk_values_usd_desc: `This widget counts your loss limits after which you should stop trading or start watching trading especially carefully. The widget calculates three indicators: 1. Stop trades - when you reach this loss for the day you should stop. The percentages shown on the left, for example 70%, means that on 70% of the days when you reach the figures on the right, you have closed the day at a loss. 2. Level of allowable drawdown - this figure shows your standard drawdown, before which you with the probability specified on the left have a chance to repay losses and come out in the black. 3. Over-trade - this index shows how much profit you give from your maximum daily profit. 4. Profit Difference - shows the difference in your cumulative income if you had followed the stop trading rule. Values of loss for the day, which are between the second index and the first - are a gray zone, in which continuation of trading is possible, but with special attention to each transaction. What percentage to choose? Everything depends on your risk level: the lower the percentage on the left, the higher the drawdown is, but the less chance that you will get out of it. That's why it's recommended to aim at 60-70% for high-risk traders and 80-90% for conservative traders.`,
    average_return_desc: `Average return of portfolio is a measure of the performance of a portfolio of investments, expressed as a percentage. It is calculated by dividing the total return of the portfolio by the total amount of capital invested, and multiplying the result by 100%. In short, it tells you the average rate of return on your investments in the portfolio. If the average return of a portfolio is positive, it means the portfolio has made a profit. If it's negative, the portfolio has incurred losses. The formula to calculate the average return of portfolio is: Average Return of Portfolio = (Total Return of Portfolio / Total Amount of Capital Invested) x 100%`,
    income_usdt_accumulative: `Accumulative Profit ($)`,
    income_usdt_candlestick: `Candlestick accumulative profit ($)`,
    income_usdt_anonymous_accumulative: `Private accumulative Profit ($)`,
    commission_usdt: `Commission ($)`,
    commission_usdt_accumulative: `Accumulative Commission ($)`,
    income_percent: `Sum of percents (%)`,
    income_percent_balance: t({
      id: 'trades.dashboard.income_percent_balance',
      comment: 'Percent balance',
    }),
    avg_income_percent_balance: `Avg. Profit to deposit (%)`,
    income_percent_balance_cumulative: t({
      id: 'trades.dashboard.income_percent_balance_cumulative',
      comment: 'Accumulative profit %',
    }),
    best_usdt: `Best trade ($)`,
    worst_usdt: `Worst trade ($)`,
    best_percent: `Best trade (%)`,
    worst_percent: `Worst trade (%)`,
    counter: `Trade counter`,
    volume: `Turnover ($)`,
    volume_per_symbol: `Volume per symbol ($)`,
    average_usdt: `Average Profit per Trade ($)`,
    average_usdt_sum: `Average Profit per Day ($)`,
    average_percent: `Average Percent per Trade (%)`,
    average_winning_usdt: `Average Winning Trade ($)`,
    average_winning_percent: `Average Winning Trade (%)`,
    average_loosing_usdt: `Average Loosing Trade ($)`,
    average_loosing_percent: `Average Loosing Trade (%)`,
    holding_time_average: `Average holding time`,
    holding_time_min: `Minimum holding time`,
    holding_time_max: `Maximum holding time`,
    loosing_percent: `Percentage of Losing Trades`,
    winning_percent: `Percentage of Winning Trades`,
    profit_loss: `Net profit/Net loss`,
    tag_profit: `Profit by entry reasons`,
    tag_percent: `Percentage of winnings by entry reasons`,
    tag_percent_combinations: `Profit by combinations of entry reasons`,
    tag_win_rate_combinations: `Win rate by combinations of entry reasons`,
    weekday_profit: `Profit by days of the week`,
    weekday_percent: `Win rate by days of the week`,
    hourly_profit: `Profit by time of day`,
    hourly_percent: `Win rate by time of day`,
    math_expectation: `Expected value`,
    max_drawdown: `Deposit Maximum Drawdown (%)`,
    counter_api_keys: `Trade counter by API key`,
    symbol_duration_average: `Average duration per symbol (min)`,
    symbol_duration_average_desc: `How long it takes on average for a specific coin to close trade in minutes.`,
    avg_volume_desc: `Average volume per trade. The calculation takes the peak volume value and multiplies it by the average entry price.`,
    avg_volume_per_symbol_desc: `Average volume per trade. The calculation takes the peak volume value and multiplies it by the average entry price.`,
    income_usdt_desc: `The widget shows your net profit minus all exchange commissions. The data is grouped by default trade open date. The widget is available as a line or bar chart. `,
    income_usdt_accumulative_desc: `The amount of profit for the entire previous period including the current date. This widget shows the growth or loss of the deposit without even knowing your balance. Negative values indicate that the trade system is unprofitable. The widget is available as a line or bar graph video. `,
    income_usdt_candlestick_desc: `The amount of profit for the entire previous period including the current date. This widget shows the growth or loss of the deposit without even knowing your balance. Negative values indicate that the trade system is unprofitable. The widget is available as a сandlestick chart`,
    income_usdt_anonymous_accumulative_desc: `The amount of profit for the entire previous period including the current date but absolute numbers converted to percents for privacy. This widget shows the growth or loss of the deposit without even knowing your balance. Negative values indicate that the trade system is unprofitable. The widget is available as a line or bar graph video. `,
    counter_desc: `The amount of trades over a period of time. The widget is available as a line or bar graph video. `,
    commission_usdt_desc: `The amount of commission paid in USD(T) for a period of time. The widget is available as a line or bar graph video. `,
    commission_usdt_accumulative_desc: `The amount of commissions for the entire previous period including the current date. The widget is available as a line or bar graph video. `,
    profit_factor_desc: `This is the result of the ratio of the total number of all trades that brought profit to the sum of unprofitable trades over a certain time interval. (total profit divided by total loss). Values above one (1.6 for more confidence) indicate the profitability of the trading strategy.`,
    profit_factor_per_symbol_desc: `This is the result of the ratio of the total number of all trades that brought profit to the sum of unprofitable trades over a certain time interval. (total profit divided by total loss). Values above one (1.6 for more confidence) indicate the profitability of the trading strategy.`,
    max_drawdown_desc: `The percentage ratio between the lowest value of the deposit balance to the highest. Too large losses (more than 30%) indicate non-compliance with risk management. `,
    income_usdt_api_keys_desc: `The amount of net profit broken down for each of your api keys. The number of trades is indicated in brackets. `,
    balance_per_api_key_desc: `Balance split for each of your api keys.`,
    counter_api_keys_desc: `The number of trades broken down for each of your api keys.`,
    pie_symbol_income_profit_desc: `Pie chart showing the distribution of profit by coin. Helps to identify your favorite coins that generate the most profit. `,
    pie_symbol_income_loose_desc: `Pie chart showing the distribution of losses by coin. Helps to identify the coins that bring the most losses and require a change in strategy or exclusion from trading or changing strategy.`,
    weekday_percent_desc: `Percentage of successful trades completed on a specific day of the week. It helps to understand the days when trading is a priority and when it is worth taking a day off. `,
    weekday_profit_desc: `Profit distribution by day of the week. Helps to identify the best day to take a break from trading `,
    hourly_profit_desc: `Profit distribution by time of day. A trading day consists of several sessions (Asia, Europe, America) and the market behavior can change at different times of the day. Find your “work” hours with this widget. If the profit does not correspond to the percentage of winning, then this is a reason for a more detailed analysis. `,
    hourly_percent_desc: `Percentage of successful trades completed at a specific time of the day. A trading day consists of several sessions (Asia, Europe, America) and the market behavior can change at different times of the day. Find your “work” hours with this widget. If the profit does not correspond to the percentage of winning, then this is a reason for a more detailed analysis. `,
    average_usdt_desc: `Average profit value over a period of time.`,
    average_usdt_sum_desc: `Average sum profit value over a period of time.`,
    average_percent_desc: `Average percentage over time. The percentage is calculated as the difference between average entry point and average exit. `,
    winning_percent_desc: `This widget calculates the percentage of trades that result in a net positive profit, identifying these as “winning trades.” It displays the proportion of successful trades relative to the total number executed, providing valuable insights into the effectiveness of your trading strategy.`,
    holding_time_average_desc: `Displays the average holding time of trades on the chart. An excellent indicator for those who are in a hurry to exit and do not allow their profit to grow. `,
    avg_income_percent_balance_desc: `The average growth rate to the deposit on trades. The increase in the deposit is calculated from the balance at the time of opening the trade.`,
    income_percent_desc: `Amount of interest taken on trade for a period of time. The percentage from average entry point to average exit. `,
    volume_per_symbol_desc: `Approximate volume of money in a trade. It is counted as the sum of contracts multiplied by the average entry point. `,
    income_percent_balance_desc: `Reflects your deposit growth over the previous date. Can be grouped both by day and by hour and month. The most reliable indicator of profit for a trader. `,
    income_percent_balance_cumulative_desc: `Reflects your deposit growth over the previous date. Can be grouped both by day and by hour and month. The most reliable indicator of profit for a trader. `,
    average_max_loose_percent_desc: `Maximum Adverse Excursion (MAE) measures how far price went against you for the period of the trade. Reflects how much the price went in the opposite direction from your entry point. Shows how inclined you are to “sit out” stop losses. The average value is suitable for selecting the most optimal stop loss position. `,
    average_max_win_percent_desc: `Maximum Favorable Excursion (MFE) measures the largest observed profit during a trade. This is the value of the peak profit for the trade. If your take is much higher or much lower than this value, then you will not receive full profit. `,
    profit_loss_desc: `Efficiently monitor your trading performance with this calendar-based widget that visually distinguishes daily profits (in green) and losses (in red). It provides a quick overview with total income, total losses, net profit, and average daily profit displayed at the top. Ideal for traders who need a clear, at-a-glance understanding of their daily financial outcomes.`,
    pie_symbol_desc: `Distribution of the number of trades by coins. The widget itself is not very useful. But if you add in the settings, for example, “only profitable trades” and compare with “only unprofitable trades”, you can find useful patterns. `,
    pie_side_desc: `Distribution of trades between long and short as a percentage. The widget itself is not very useful. But if you add in the settings, for example, “only profitable trades” and compare with “only unprofitable trades”, you can find useful patterns. `,
    tag_profit_desc: `A very important widget that allows you to find working setups and exclude entry reasons that only bring losses. You must fill in the reasons for entering in the “my trades” section. `,
    tag_percent_desc: `Widget that allows you to cut off unprofitable reasons for entering. When grouped with the “Profit by Entry Reasons” widget, it can show where the setup is successful but does not bring the required profit and can be optimized. You must fill in the reasons for entering in the “my trades” section. `,
    tag_percent_combinations_desc: `Bubble chart of all entry reasons combinations. X-axis is profit, Y-axis is count of trades. Right top corner is the most profitable combination. Left bottom corner is the most unprofitable combination. `,
    tag_win_rate_combinations_desc: `Win rate percentage for each combination of reasons for entering a trade in the journal. Find out in which combinations you win most often and in which you lose.`,
    category_profit_desc: `The amount of profit by trades grouped by category. To transfer a trade to a category, select the checkbox in the "my trades" section `,
    category_win_percentage_desc: `Percentage of winning trades grouped by category. To transfer a trade to a category, select the checkbox in the "my trades" section `,
    funding_desc: `Funding rate - this is an additional commission designed to remove distortions in the market. If too many people stand in long, then they pay a commission to the shorts and vice versa. On the Binance exchange, funding is paid every 8 hours. On the FTX exchange once an hour but less percent.`,
    balance_desc: `Your balance, for the widget to work, after adding it, you need to click the "pencil" icon in the corner of the widget and select the api key in the filters.`,
    symbol_income_desc: `Widget showing your most profitable coins and your most losing ones.`,
    best_usdt_desc: `The most profitable trade. In the corner of the widget there is a “chain” icon, which can be used to navigate to the trade. `,
    worst_usdt_desc: `The trade for which you received the maximum loss. In the corner of the widget there is a “chain” icon, which can be used to navigate to the trade. `,
    average_winning_usdt_desc: `Average profit value for trades closed with a profit.`,
    average_loosing_usdt_desc: `Average loss value for trades closed in red.`,
    best_percent_desc: `The most profitable trade for a given percentage of movement, excluding leverage. In the corner of the widget there is a “chain” icon, which can be used to navigate to the trade. `,
    worst_percent_desc: `Worst trade for a given percentage of movement, excluding leverage. In the corner of the widget there is a “chain” icon, which can be used to navigate to the trade. `,
    loosing_percent_desc: `Your percentage of losing trades. With 1 to 3 risk management, even 30% of successful trades can be profitable. `,
    average_loosing_percent_desc: `Average value of the percentage for trades that suffered a loss. The percentage from the  average entry to average exit is calculated. `,
    average_winning_percent_desc: `Average value of the percentage for trades closed in plus. The percentage from the  average entry to average exit is calculated. `,
    math_expectation_desc: `The mathematical expectation shows the value of your potential profit per 100 trades ahead. If it is negative, then your trading strategy needs to be changed. Do not take this number as a guaranteed income in the future. The expected value works more like a “profit factor” and is considered a plus / minus value. `,
    pay_off_ratio_desc: `it is calculated as the average winning trade divided by the average losing trade. It is very similar to the profit factor but differs in a large averaging. In our opinion, the profit factor, together with the expected value, works more reliably than this widget. `,
    average_leverage_desc: `Leverage is calculated relative to the deposit and is suitable primarily for assessing the risk taken per trade.`,
    min_leverage_desc: `The minimum leverage over a period of time. Leverage is considered relative to the deposit and is primarily suitable for assessing the risk taken per trade. `,
    max_leverage_desc: `Maximum leverage over a period of time. Leverage is considered relative to the deposit and is primarily suitable for assessing the risk taken per trade. `,
    max_max_loose_percent_desc: `Maximum Adverse Excursion (MAE) measures how far price went against you for the period of the trade. Reflects how much the price went in the opposite direction from your entry point. Shows how inclined you are to “sit out” stop losses. The average value is suitable for selecting the most optimal stop loss position. `,
    min_max_loose_percent_desc: `LPU - maximum floating loss received for the period of the transaction. Reflects how much the price went in the opposite direction from your entry point. Shows how inclined you are to “sit out” stop losses. The average value is suitable for selecting the most optimal stop loss size. `,
    max_max_win_percent_desc: `Maximum Favorable Excursion (MFE) measures the largest observed profit during a trade. This is the value of the peak profit for the trade. If your take is much higher or much lower than this value, then you will not receive full profit. `,
    holding_time_max_desc: `Maximum holding time in a trade over a period of time`,
    holding_time_min_desc: `The minimum holding time in a trade over a period of time`,
  })
)
