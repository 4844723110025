import { SVG } from '@/assets/svg'
import style from '../style.module.scss'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import clsx from 'clsx'
const KycStatusRejected = () => {
  const supportEmailHandler = () => (window.location.href = `mailto:broker.support@tiger.trade`)

  return (
    <div className={style.container}>
      <SVG.Kyc.Rejected />
      <div className={style.title}>
        {t({
          id: 'kyc.rejectedTitle',
          comment: 'Verification rejected',
        })}
      </div>
      <div className={style.text}>
        <Trans
          id="kyc.rejectedDescription"
          components={{
            br: <br />,
            span: <span className={style.textPosition} />,
          }}
        />
      </div>
      <div className={clsx(style.text, style.rejectedInfo)}>
        <Trans
          id="kyc.rejectedInfo"
          components={{
            br: <br />,
            textWrapper: <span className={style.textPosition} />,
            colorGradient: (
              <span className={clsx(style.colorGradient, style.supportMail)} onClick={supportEmailHandler} />
            ),
          }}
        />
      </div>
    </div>
  )
}
export { KycStatusRejected }
