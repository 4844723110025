import { memo } from 'react'
import { TabsType, TransactionsTabType } from '../../../pages/Transactions'
import { TransactionsTab } from '../../atoms/TransactionsTab'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useMobileSizeDetect } from '@/utils'
import { Carousel } from '@/ui/kit/Carousel'
import clsx from 'clsx'

type TabBarPropsType = {
  activeRightBarTab: TabsType | string
  activeRightBarTabHandler: (type: TabsType) => void
  tabs: TransactionsTabType[]
}

export const TransactionsTabBar = memo<TabBarPropsType>(({ activeRightBarTab, activeRightBarTabHandler, tabs }) => {
  const [isMobile] = useMobileSizeDetect()

  if (isMobile) {
    return (
      <Carousel className={clsx(style.gain, style.carousel)}>
        <Carousel.Content wrapperClassName={style.wrapperContent} className={style.content}>
          {tabs.map(({ text, id, action, type, visible }) => {
            if (!visible) {
              return null
            }

            return (
              <Carousel.Item key={id} className={style.item}>
                <TransactionsTab
                  className={clsx(style.tab)}
                  type={type}
                  onClick={action}
                  text={text}
                  tabId={id}
                  activeTab={activeRightBarTab}
                  setActiveTab={activeRightBarTabHandler}
                  data-testid={DataTestIds.TransactionTab}
                />
              </Carousel.Item>
            )
          })}
        </Carousel.Content>
      </Carousel>
    )
  }

  return (
    <div className={style.tabs}>
      {tabs.map(({ text, id, action, type, visible }) => {
        if (!visible) return null
        return (
          <TransactionsTab
            className={style.tab}
            key={id}
            type={type}
            onClick={action}
            text={text}
            tabId={id}
            activeTab={activeRightBarTab}
            setActiveTab={activeRightBarTabHandler}
            data-testid={DataTestIds.TransactionTab}
          />
        )
      })}
    </div>
  )
})
