export enum Size {
  S = 3,
  M = 8,
  L = 16,
  XL = 32,
  XXL = 64,
  XXXL = 128,
}
/**
 * Makes short string, displays «N fist symbols + three dots + 3 last symbols»
 * example: «12345555555556789» →  «123...789»
 * */
export const makeShortStringBase = (value: string, count: number = 3, tail: number = 3) => {
  const tailString = tail ? value.slice(-1 * tail) : ''
  const isVisibleDots = value.length > count
  return value.slice(0, count) + (isVisibleDots ? '...' + tailString : '')
}

/**
 * Keep only 3 first symbols
 * @param value
 */
export const makeShortString = (value: string) => makeShortStringBase(value, Size.S)

/**
 * Keep only 32 first symbols
 * @param value
 * @param tail
 */
export const makeXLString = (value: string, tail: number = 0) => makeShortStringBase(value, Size.XL, tail)

/**
 * Keep only 128 first symbols
 * @param value
 * @param tail
 */
export const makeXXXLString = (value: string, tail: number = 0) => makeShortStringBase(value, Size.XXXL, tail)
