import { FC } from 'react'
import style from './skeleton.module.scss'
import clsx from 'clsx'

export const Skeleton: FC = () => {
  return (
    <div className={style.root}>
      <div className={clsx(style.heading, style.animatable)}></div>
      <div className={clsx(style.item, style.animatable)}></div>
      <div className={clsx(style.item, style.animatable)}></div>
    </div>
  )
}
