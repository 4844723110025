import { AccountOverviewAssets } from '@/backend/models/OverviewDTO'
import { AssetItem } from '@/ui/molecules'
import { TopUpAccount } from '@/ui/organisms'
import { FC } from 'react'
import s from './style.module.scss'

interface Props {
  balances: AccountOverviewAssets[]
}

export const MobileAssetsList: FC<Props> = ({ balances }) => {
  if (!balances.length) {
    return <TopUpAccount />
  }

  return (
    <div className={s.list}>
      {balances.map(balance => (
        <AssetItem key={balance.asset} asset={balance} />
      ))}
    </div>
  )
}
