import clsx from 'clsx'
import { FC, useCallback, useState, useMemo, useEffect, useRef, MouseEvent } from 'react'
import { Spinner } from '@/ui/atoms/Spinner'
import { ButtonSize, ButtonVariant, IButtonProps, TButtonVariants, TButtonResult } from './Button.types'
import s from './style.module.scss'

const ButtonComponent: FC<IButtonProps> = props => {
  const {
    type = 'button',
    size = ButtonSize.Small,
    as: Element = 'button' as any,
    variant,
    title,
    label,
    description,
    leftIcon,
    rightIcon,
    disabled,
    loading,
    children,
    className,
    dataTestId,
    onClick,
    ...otherProps
  } = props

  const unmounted = useRef<boolean>(false)

  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, [])

  const [busy, setBusy] = useState(false)

  const spinnerSize = useMemo(() => {
    switch (size) {
      case ButtonSize.ExtraSmall:
        return 17
      case ButtonSize.Small:
        return 20
      case ButtonSize.Medium:
        return 22
      case ButtonSize.Large:
        return 40
    }
  }, [size])

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const result = onClick?.(event)

      if (result instanceof Promise) {
        setBusy(true)
        result.finally(() => {
          if (unmounted.current) return
          setBusy(false)
        })
      }
    },
    [onClick, setBusy]
  )

  return (
    <Element
      type={type}
      title={title}
      disabled={disabled || loading || busy}
      className={clsx(s.root, s[size], s[variant], { [s.disabled]: disabled, [s.loading]: busy || loading }, className)}
      onClick={handleClick}
      data-testid={dataTestId}
      {...otherProps}
    >
      {leftIcon}
      {(label || children) && (
        <div className={s.text}>
          {label || children}
          {description && <div className={s.description}>{description}</div>}
        </div>
      )}
      {rightIcon}

      {(busy || loading) && (
        <div className={s.spinner}>
          <Spinner size={spinnerSize} />
        </div>
      )}
    </Element>
  )
}

// Add possibility to use variant components
const variantButton = Object.entries(ButtonVariant).reduce((Button, [key, value]) => {
  Object.assign(Button, {
    [key as keyof typeof ButtonVariant]: (props: Omit<IButtonProps, 'variant'>) => (
      <Button variant={value as ButtonVariant} {...props} />
    ),
  })

  return Button
}, ButtonComponent) as TButtonVariants

// Add sizes and variant to main component
const Button: TButtonResult = Object.assign(variantButton, {
  Variant: ButtonVariant,
  Size: ButtonSize,
})

export { Button }
