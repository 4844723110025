import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './trades.state'
import { tradesAsyncActions } from './trades.actions'
import { TradesQueryParams } from '@/backend/types'
import { DiaryWeekListQuery, E_TableFilterExchangeType, E_TableFilterMarketValue } from './trades.types'
import { isEmpty, uniqBy } from 'lodash-es'
import { enqueueSnackbar } from 'notistack'
import { t } from '@lingui/macro'
import {
  clubArray,
  extractDates,
  groupWeeksByMonth,
  mapBackendDataToWeeks,
} from '@/ui/organisms/DiaryRightBar/utils/formatWeek'
import { generateWeekDataWithDays } from '@/ui/organisms/DiaryRightBar/utils/formatDay'
import { DayDiary, ServicesApiKeyWebsocketStatus, UIDataResponse } from '@/utils/types/contracts.types'
import { UIDataContent } from './trades.utils'

// TODO: обработать кейсы с ошибками (уточнить какое поведение при ошибках)
const slice = createSlice({
  name: '[Tiger Trade TMM Trades]',
  initialState,
  reducers: {
    resetTrades(state) {
      state.trades = null
      state.tradesStatus = 'idle'
      if (!state.isApiKeyExistWithEmptyArray) state.isTradesLoading = true
    },
    resetTradesById(state) {
      state.tradesById = null
      state.tradesByIdStatus = 'idle'
      state.isTradesByIdLoading = true
    },
    patchFilters(state, action: PayloadAction<Partial<TradesQueryParams>>) {
      state.trades = null
      state.tradesFilters = {
        ...state.tradesFilters,
        ...action.payload,
      }
    },
    setIsTradesLoading(state, action: PayloadAction<boolean>) {
      state.isTradesLoading = action.payload
    },
    setIsApiKeyExistWithEmptyArray(state, action: PayloadAction<boolean>) {
      state.isApiKeyExistWithEmptyArray = action.payload
    },
    setFilters(state, action: PayloadAction<TradesQueryParams>) {
      state.tradesFilters = action.payload

      if (
        action.payload.hasOwnProperty('api_key_id') &&
        (action.payload.api_key_id === undefined || action.payload.api_key_id.length === 0) &&
        (state.exchangeFilterValue !== E_TableFilterExchangeType.All ||
          state.marketTypeFilterValue !== E_TableFilterMarketValue.All)
      ) {
        state.isApiKeyExistWithEmptyArray = true
      } else {
        state.isApiKeyExistWithEmptyArray = false
      }
    },
    setDiaryFilter(state, action: PayloadAction<DiaryWeekListQuery>) {
      state.diaryWeekListFilters = action.payload
      if (
        action.payload.hasOwnProperty('api_key_id') &&
        (action.payload.api_key_id === undefined || action.payload.api_key_id.length === 0) &&
        state.diaryExchangeFilterValue !== E_TableFilterExchangeType.All
      ) {
        state.isApiKeyExistWithEmptyArray = true
      } else {
        state.isApiKeyExistWithEmptyArray = false
      }
    },
    setDiaryWeekFilter(state, action: PayloadAction<DiaryWeekListQuery>) {
      state.diaryWeekFilter = action.payload
      if (
        action.payload.hasOwnProperty('api_key_id') &&
        (action.payload.api_key_id === undefined || action.payload.api_key_id.length === 0) &&
        state.diaryWeekExchangeFilterValue !== E_TableFilterExchangeType.All
      ) {
        state.isApiKeyExistWithEmptyArray = true
      } else {
        state.isApiKeyExistWithEmptyArray = false
      }
    },
    setSortByFilters(state) {
      state.tradesFilters = {
        ...state.tradesFilters,
        sortBy: '',
      }
    },
    setResetWeekList(state) {
      state.diaryWeekList = []
      state.consecutiveEmptyResponses = 0
      state.diaryWeekListDataReachedEnd = false
      state.isMonthAnalyzerDataFetched = false
      state.isDayAnalyzerDataFetched = false
    },
    setExchangeType(state, action: PayloadAction<E_TableFilterExchangeType>) {
      state.exchangeFilterValue = action.payload
    },
    setDiaryExchangeType(state, action: PayloadAction<E_TableFilterExchangeType>) {
      state.diaryExchangeFilterValue = action.payload
    },
    setDiaryWeekExchangeType(state, action: PayloadAction<E_TableFilterExchangeType>) {
      state.diaryWeekExchangeFilterValue = action.payload
    },
    setMarketType(state, action: PayloadAction<E_TableFilterMarketValue>) {
      state.marketTypeFilterValue = action.payload
    },
    setRequestTradesLimit(state, action: PayloadAction<number>) {
      state.requestTimeStep = action.payload
    },
    setActiveTagEditorID(state, action: PayloadAction<number | null>) {
      state.activeTagEditorID = action.payload
    },
    setTagEditorPosition(state, action: PayloadAction<'top' | 'bottom' | null>) {
      state.tagEditorPosition = action.payload
    },
    setEditDescriptionStatus(state) {
      state.editDescriptionStatus = 'idle'
    },
    resetDiaryAnalyzer(state) {
      state.diaryAnalyzerStatus = 'idle'
      state.diaryAnalyzerList = null
      state.isDayAnalyzerDataFetched = false
      state.isMonthAnalyzerDataFetched = false
      state.analyzeDayOrders = null
      state.analyzerMonthTradeTicker = null
    },
    resetAnalyzerData(state) {
      state.analyzeDayOrders = null
    },
    setAnalyzerMonthTradeTicker(state, action: PayloadAction<DayDiary>) {
      state.analyzerMonthTradeTicker = action.payload
    },
  },
  extraReducers: builder => {
    // Trades
    builder.addCase(tradesAsyncActions.GetTradesTC.pending, (state, action) => {
      state.isTradesLoading = true
    })
    builder.addCase(tradesAsyncActions.GetTradesTC.fulfilled, (state, action) => {
      state.requestTimeStep = null
      state.isTradesLoading = false
      if (
        Object.keys(action.meta.arg).includes('api_key_id') &&
        (action.meta.arg.api_key_id === undefined || state.tradesFilters.api_key_id?.length === 0) &&
        (state.exchangeFilterValue !== E_TableFilterExchangeType.All ||
          state.marketTypeFilterValue !== E_TableFilterMarketValue.All)
      ) {
        state.isApiKeyExistWithEmptyArray = true
      } else {
        state.isApiKeyExistWithEmptyArray = false
      }

      state.trades = action.payload
      state.tradesStatistic = action.payload
      state.tradesStatus = 'succeeded'
    })
    builder.addCase(tradesAsyncActions.GetTradesTC.rejected, (state, action) => {
      state.isTradesLoading = false
      state.tradesStatus = 'failed'
    })

    // Trades by id
    builder.addCase(tradesAsyncActions.GetTradeByIdTC.pending, (state, action) => {
      state.isTradesByIdLoading = true
    })
    builder.addCase(tradesAsyncActions.GetTradeByIdTC.fulfilled, (state, action) => {
      state.isTradesByIdLoading = false
      state.tradesById = action.payload
      state.tradesByIdStatus = 'succeeded'
    })
    builder.addCase(tradesAsyncActions.GetTradeByIdTC.rejected, (state, action) => {
      state.isTradesByIdLoading = false
      state.tradesByIdStatus = 'failed'
    })

    /**
     * [GET] UIData
     * - Filters
     */
    builder.addCase(tradesActions.GetUIDataTC.pending, (state, action) => {
      state.uiDataStatus = 'loading'
    })
    builder.addCase(tradesActions.GetUIDataTC.fulfilled, (state, action: PayloadAction<UIDataResponse>) => {
      state.uiDataStatus = 'succeeded'
      const response = action.payload.data

      if (!response || !response.data) return

      let parsedData: UIDataContent = {}

      try {
        parsedData = JSON.parse(response.data)
      } catch (e) {
        console.warn(e)
      }

      if (isEmpty(parsedData)) return

      // - Filters
      if (parsedData.filters) {
        const normalizedFilters = {
          ...parsedData.filters,
          api_key_id: state.tradesFilters.api_key_id,
          page: 1,
        }

        if (normalizedFilters.api_key_id === undefined) {
          delete normalizedFilters.api_key_id
        }

        state.tradesFilters = normalizedFilters
      }
    })
    builder.addCase(tradesActions.GetUIDataTC.rejected, (state, action) => {
      state.uiDataStatus = 'failed'
    })

    // Categories
    builder.addCase(tradesAsyncActions.GetCategoriesTC.fulfilled, (state, action) => {
      state.categories = action.payload.data ?? null
    })

    // Tags
    builder.addCase(tradesAsyncActions.GetTagsTC.fulfilled, (state, action) => {
      state.tags = action.payload.data ?? []
    })

    builder.addCase(tradesAsyncActions.EditTagsTC.fulfilled, (state, action) => {
      const uniqueTagsFromResponse = uniqBy([...(action.payload.tags || [])], e => e.id)
      if (action.payload.status === 'success') {
        state.tags = uniqBy([...state.tags, ...(uniqueTagsFromResponse || [])], e => e.id)
      }

      const tradeId = action.meta.arg.trade_id

      if (state?.tradesById?.data && state.tradesById.data[0]) {
        state.tradesById.data[0].tags = [...(uniqueTagsFromResponse || [])]
      }

      state.tradesById = { ...state.tradesById, data: [...(state.tradesById?.data || [])] }

      if (state?.trades && state.trades.data?.length) {
        const updatedTradesData = state.trades.data.map(item => {
          if (item.id === tradeId && action.payload.status === 'success') {
            return {
              ...item,
              tags: [...(uniqueTagsFromResponse || [])],
            }
          } else {
            return item
          }
        })

        state.trades = { ...state.trades, data: updatedTradesData }
      }
    })

    builder.addCase(tradesAsyncActions.EditTagsTC.rejected, (state, action) => {
      enqueueSnackbar({
        message: `${t({ id: 'tradesPage.error', message: 'Возникла ошибка' })}`,
        variant: 'error',
      })
    })

    builder.addCase(tradesAsyncActions.DeleteTagTC.fulfilled, (state, action) => {
      const deletedTagId = action.meta.arg.tagId
      state.deleteTagStatus = 'succeeded'

      if (state?.tradesById?.data && state.tradesById.data[0]?.tags) {
        state.tradesById.data[0].tags = state.tradesById.data[0].tags.filter(tag => tag.id !== deletedTagId)
      }

      state.tags = state.tags.filter(tag => tag.id !== deletedTagId)

      if (state?.trades && state.trades.data) {
        const updatedTradesData = state.trades.data.map(item => {
          if (item.tags) {
            return {
              ...item,
              tags: item.tags.filter(tag => tag.id !== deletedTagId),
            }
          } else {
            return item
          }
        })

        state.trades = { ...state.trades, data: updatedTradesData }
      }
    })

    builder.addCase(tradesAsyncActions.DeleteTagTC.pending, (state, action) => {
      state.deleteTagStatus = 'loading'
    })

    builder.addCase(tradesAsyncActions.DeleteTagTC.rejected, (state, action) => {
      state.deleteTagStatus = 'failed'
      enqueueSnackbar({
        message: `${t({ id: 'tradesPage.error', message: 'Возникла ошибка' })}`,
        variant: 'error',
      })
    })

    builder.addCase(tradesAsyncActions.EditDescriptionTC.fulfilled, (state, action) => {
      if (state?.tradesById?.data?.[0]) {
        state.tradesById.data[0].description = action.meta.arg.description
        state.tradesById.data[0].conclusion = action.meta.arg.conclusion
        state.tradesById.data[0].video_link = action.meta.arg.video_link
      }
      state.editDescriptionStatus = 'succeeded'
    })

    builder.addCase(tradesAsyncActions.EditDescriptionTC.rejected, (state, action) => {
      state.editDescriptionStatus = 'failed'
    })

    // Me
    builder.addCase(tradesAsyncActions.GetMeTC.fulfilled, (state, action) => {
      state.me = action.payload.data
        ? {
            ...action.payload.data,
            api_keys_list: action.payload.data.api_keys_list?.filter(
              key => key.websocket_status === ServicesApiKeyWebsocketStatus.API_KEY_WS_CONNECTED
            ),
          }
        : null
      state.getMeStatus = 'succeeded'
    })
    builder.addCase(tradesAsyncActions.GetMeTC.rejected, (state, action) => {
      state.getMeStatus = 'failed'
    })

    //Tickers
    builder.addCase(tradesAsyncActions.GetTickers.fulfilled, (state, action) => {
      state.tickers = action.payload ?? ['']
      state.tickersStatus = 'succeeded'
    })
    builder.addCase(tradesAsyncActions.GetTickers.pending, (state, action) => {
      state.tickersStatus = 'loading'
    })
    builder.addCase(tradesAsyncActions.GetTickers.rejected, (state, action) => {
      state.tickersStatus = 'failed'
    })

    //Init
    builder.addCase(tradesAsyncActions.TradesUserInitTC.fulfilled, (state, action) => {
      state.tradesUserInitStatus = 'succeeded'
    })
    builder.addCase(tradesAsyncActions.TradesUserInitTC.pending, (state, action) => {
      state.tradesUserInitStatus = 'loading'
    })
    builder.addCase(tradesAsyncActions.TradesUserInitTC.rejected, (state, action) => {
      state.tradesUserInitStatus = 'failed'
    })

    //Exchange Keys
    builder.addCase(tradesAsyncActions.PostTradesAvailableExchangeTC.fulfilled, (state, action) => {
      state.availableExchangeStatus = 'succeeded'
      state.availableExchangeKeys = action.payload
      state.isTradesLoading = false
    })
    builder.addCase(tradesAsyncActions.PostTradesAvailableExchangeTC.pending, (state, action) => {
      state.availableExchangeStatus = 'loading'
      state.isTradesLoading = true
    })
    builder.addCase(tradesAsyncActions.PostTradesAvailableExchangeTC.rejected, (state, action) => {
      state.availableExchangeStatus = 'failed'
      state.isTradesLoading = false
    })

    // Get ExchangeKeys
    builder.addCase(tradesAsyncActions.GetTradesAvailableExchangeTC.fulfilled, (state, action) => {
      state.getExchangeAvailableStatus = 'succeeded'
      state.getAvailableExchangeKeys = action.payload
    })
    builder.addCase(tradesAsyncActions.GetTradesAvailableExchangeTC.pending, (state, action) => {
      state.getExchangeAvailableStatus = 'loading'
    })
    builder.addCase(tradesAsyncActions.GetTradesAvailableExchangeTC.rejected, (state, action) => {
      state.availableExchangeStatus = 'failed'
    })

    //Repair key
    builder.addCase(tradesAsyncActions.RepairApiKeyTC.fulfilled, (state, action) => {
      state.repairApiKeyStatus = 'succeeded'
    })
    builder.addCase(tradesAsyncActions.RepairApiKeyTC.pending, (state, action) => {
      state.repairApiKeyStatus = 'loading'
    })
    builder.addCase(tradesAsyncActions.RepairApiKeyTC.rejected, (state, action) => {
      state.repairApiKeyStatus = 'failed'
    })

    //Users Available
    builder.addCase(tradesAsyncActions.TradesAvailableUsersTC.fulfilled, (state, action) => {
      state.availableUsersTradingStatus = 'succeeded'
      state.availableUsersTrading = action.payload
    })
    builder.addCase(tradesAsyncActions.TradesAvailableUsersTC.pending, (state, action) => {
      state.availableUsersTradingStatus = 'loading'
    })
    builder.addCase(tradesAsyncActions.TradesAvailableUsersTC.rejected, (state, action) => {
      state.availableUsersTradingStatus = 'failed'
    })

    //Analyzer week-list
    builder.addCase(tradesAsyncActions.GetAnalyzerWeek.fulfilled, (state, action) => {
      const { to, from } = extractDates(action.meta.arg.openBetween)

      const containsDeals = action.payload.data !== null
      if (
        Object.keys(action.meta.arg).includes('api_key_id') &&
        (action.meta.arg.api_key_id === undefined || state.diaryWeekListFilters?.api_key_id?.length === 0) &&
        state.diaryExchangeFilterValue !== E_TableFilterExchangeType.All
      ) {
        state.isApiKeyExistWithEmptyArray = true
      } else {
        state.isApiKeyExistWithEmptyArray = false
      }
      if (containsDeals) {
        const weekYearsData = mapBackendDataToWeeks(from, to, action.payload.data)
        const groupedData = groupWeeksByMonth(weekYearsData)
        const mergedData = [...state.diaryWeekList, ...groupedData]
        state.diaryWeekList = clubArray(mergedData)
      }
      state.diaryWeekStatus = 'succeeded'
      if (containsDeals) {
        state.consecutiveEmptyResponses = 0
      } else {
        state.consecutiveEmptyResponses = (state.consecutiveEmptyResponses || 0) + 1
      }

      if (state.consecutiveEmptyResponses >= 2) {
        state.diaryWeekListDataReachedEnd = true
      }
    })
    builder.addCase(tradesAsyncActions.GetAnalyzerWeek.rejected, (state, action) => {
      state.diaryWeekStatus = 'failed'
    })
    builder.addCase(tradesAsyncActions.GetAnalyzerWeek.pending, (state, action) => {
      state.diaryWeekStatus = 'loading'
    })
    //Analyzer week-list
    builder.addCase(tradesAsyncActions.GetAnalyzerTC.fulfilled, (state, action) => {
      const { to, from } = extractDates(action.meta.arg.openBetween)
      const groupData = generateWeekDataWithDays(from, to, action.payload.data !== null ? action.payload.data : [])
      const resultForWeek: DayDiary =
        action.payload.data !== null ? action.payload.data.find((el: DayDiary) => el.dateValue === '') : []
      if (
        Object.keys(action.meta.arg).includes('api_key_id') &&
        (action.meta.arg.api_key_id === undefined || state.diaryWeekFilter?.api_key_id?.length === 0) &&
        state.diaryWeekExchangeFilterValue !== E_TableFilterExchangeType.All
      ) {
        state.isApiKeyExistWithEmptyArray = true
      } else {
        state.isApiKeyExistWithEmptyArray = false
      }
      state.diaryAnalyzerList = {
        ...groupData,
        weekResult: resultForWeek,
      }

      if (action.meta.arg.type) {
        const foundedMonth = action.payload?.data?.find((el: DayDiary) => !el.symbol)
        const itemsWithSymbol = action.payload?.data?.filter((el: DayDiary) => el.symbol)
        const [startDate, endDate] = action.meta.arg.openBetween.split(',')
        state.analyzerMonthData = {
          data: foundedMonth ?? [],
          startMonth: startDate,
          endMonth: endDate,
          itemsWithSymbol,
        }
        state.isMonthAnalyzerDataFetched = true
      } else {
        state.isDayAnalyzerDataFetched = true
      }
      state.diaryAnalyzerStatus = 'succeeded'
    })
    builder.addCase(tradesAsyncActions.GetAnalyzerTC.rejected, (state, action) => {
      state.diaryAnalyzerStatus = 'failed'
    })
    builder.addCase(tradesAsyncActions.GetAnalyzerTC.pending, (state, action) => {
      state.diaryAnalyzerStatus = 'loading'
    })
    //Analyzer order day
    builder.addCase(tradesAsyncActions.GetTradesDayOrdersTC.fulfilled, (state, action) => {
      state.analyzeDayOrders = null
      state.analyzeDayOrdersStatus = 'succeeded'
      state.analyzeDayOrders = action.payload
    })
    builder.addCase(tradesAsyncActions.GetTradesDayOrdersTC.rejected, (state, action) => {
      state.analyzeDayOrdersStatus = 'failed'
    })
    builder.addCase(tradesAsyncActions.GetTradesDayOrdersTC.pending, (state, action) => {
      state.analyzeDayOrdersStatus = 'loading'
    })
  },
})

export const tradesActions = {
  ...slice.actions,
  ...tradesAsyncActions,
}

export const tradesReducer = slice.reducer
