import { FC } from 'react'
import styles from './style.module.scss'
import { t } from '@lingui/macro'
import { Loader } from '@/ui/kit/Loader'

export const WalletSetupExchange: FC = () => {
  return (
    <div className={styles.container}>
      <Loader className={styles.loader} />
      <div className={styles.header}>{t({ message: 'Please wait', id: 'core.exchangeSetup.title' })}</div>
      <div className={styles.text}>
        {t({ message: 'We are setting your trading account up', id: 'core.exchangeSetup.subtitle' })}
      </div>
    </div>
  )
}
