import { t } from '@lingui/macro'

export const getCryproErrors = (): Record<string, string> => {
  return {
    '40002': t({
      id: 'CryproErrors.address',
      comment: 'Wallet with this address already exists',
    }),
    '40003': t({
      id: 'CryproErrors.nameExists',
      comment: 'Wallet with this name already exists',
    }),
    '40004': t({
      id: 'CryproErrors.nameId',
      comment: 'Wallet user id cannot be changed',
    }),
    '40005': t({
      id: 'CryproErrors.nameExists',
      comment: 'Wallet with this name already exists',
    }),
    '40006': t({
      id: 'CryproErrors.amount',
      comment: 'Withdraw amount not allowed',
    }),
    '40007': t({
      id: 'CryproErrors.amountPresent',
      comment: 'Withdraw amount must be present',
    }),
    '40008': t({
      id: 'CryproErrors.network',
      comment: 'Coin and network are forbidden',
    }),
    '40009': t({
      id: 'CryproErrors.googleCode',
      comment: 'Google code invalid',
    }),
    '40012': t({
      id: 'CryproErrors.withdraw',
      comment: 'Withdraw allowed only on wallet addresses',
    }),
  }
}
