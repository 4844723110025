export interface ViewportSize {
  width: number
  height: number
}

export function getViewportSize(): ViewportSize {
  return {
    width: visualViewport?.width || window.innerWidth,
    height: visualViewport?.height || window.innerHeight,
  }
}
