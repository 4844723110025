import { isArray } from 'lodash-es'

export const parseBooleanEnv = (envValue: any): boolean => {
  let value = false

  try {
    value = !!JSON.parse(envValue)
  } finally {
    return value
  }
}

export const parseArrayEnv = <T extends any[] = string[]>(envValue: any): T => {
  let value = [] as unknown as T

  try {
    const possibleArr: T = JSON.parse(envValue)

    if (isArray(possibleArr)) {
      value = possibleArr
    }
  } finally {
    return value
  }
}
