export const CloudDown = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 15.6075C24 18.2673 21.747 20.4048 18.864 20.4048H5.71837C2.45346 20.4048 0 18.0158 0 15.1724C0 12.8124 1.34606 10.8587 3.50357 10.5105C3.55131 7.80243 6.10978 5.99381 8.45822 6.69017C9.60381 4.99761 11.4463 3.5952 14.0334 3.5952C17.9379 3.5952 21.0024 6.70951 20.9832 11.0231C22.8448 11.8162 24 13.5765 24 15.6075ZM12.611 17.7663C12.4201 17.9597 12.2578 18.0275 12.0669 18.0275C11.8664 18.0275 11.7041 17.9597 11.5131 17.7663L8.28643 14.6133C8.14324 14.4683 8.05732 14.3135 8.05732 14.1008C8.05732 13.7138 8.34371 13.4237 8.74466 13.4237C8.92605 13.4237 9.13607 13.5011 9.26972 13.6558L10.7208 15.2227L11.3604 15.9093L11.3031 14.4586V10.292C11.3031 9.88573 11.6563 9.53751 12.0669 9.53751C12.4774 9.53751 12.821 9.88573 12.821 10.292V14.4586L12.7637 15.8997L13.4033 15.2227L14.8544 13.6558C14.9881 13.5011 15.1886 13.4237 15.37 13.4237C15.7613 13.4237 16.0668 13.7138 16.0668 14.1008C16.0668 14.3135 15.981 14.4683 15.8378 14.6133L12.611 17.7663Z"
        fill="currentColor"
      />
    </svg>
  )
}
