import { DiaryWeekResultItem } from '@/ui/molecules'
import { Trans } from '@lingui/react'
import style from './style.module.scss'
import {
  DEFAULT_LEVERAGE_VALUE,
  DEFAULT_VALUE_PERCENT_SYMBOL,
  DEFAULT_VALUE_WITH_SYMBOL,
  DEFAULT_VALUE_WITHOUT_SYMBOL,
  handleRound,
} from '@/ui/organisms/DiaryRightBar/components/WeekCard/WeekCard'
import {
  calculateMonthlyCommissionSum,
  calculateMonthlyInversePercentage,
  calculateMonthlyLongPercentage,
  calculateMonthlyLossPerFailedTradesPercentage,
  calculateMonthlyNetProfit,
  calculateMonthlyProfitLossRatio,
  calculateMonthlyVolumeSum,
  calculateMonthlyWinCountPercentage,
  calculateMonthlyWinPercentage,
  checkStringLength,
} from '../../utils'
import clsx from 'clsx'

import { t } from '@lingui/macro'
import { FC } from 'react'
import {
  calculatePercentage,
  formatLeverage,
  getPercentage,
  getRatio,
} from '@/ui/organisms/DiaryWeekInfo/components/WeekResult/utils'
import { E_SwitchItems } from '../DiaryWidgetResult/options'
import { DayDiary } from '@/utils/types/contracts.types'

type DiaryResultProps = {
  type: E_SwitchItems
  monthData?: DayDiary
  day?: DayDiary
}

export const DiaryResult: FC<DiaryResultProps> = ({ type, monthData, day }) => {
  const showData = () => {
    if (monthData && type === E_SwitchItems.MONTH) {
      return (
        <>
          <div className={style.header}>
            <div className={style.profit}>
              <DiaryWeekResultItem
                className={clsx({
                  [style.fontSmall]: checkStringLength(
                    calculateMonthlyNetProfit(monthData.net_profit).totalNetProfitRounded ?? ''
                  ),
                })}
                isCountPercent
                sourceValue={Number(calculateMonthlyNetProfit(monthData.net_profit).netProfitTotal)}
                value={calculateMonthlyNetProfit(monthData.net_profit).totalNetProfitRounded + ' $'}
                text={t({
                  id: 'trades.summary.netProfit',
                })}
              />
            </div>
            <DiaryWeekResultItem
              value={
                monthData.win_count
                  ? calculateMonthlyWinCountPercentage(monthData.win_count, monthData.count)
                  : '0.00 %'
              }
              text={t({
                id: 'trades.summary.weekWin',
              })}
            />
          </div>
          <div className={style.resultContainer}>
            <div className={style.leftBlock}>
              <DiaryWeekResultItem
                className={style.countSize}
                value={calculateMonthlyLongPercentage(monthData.long_count, monthData.count)}
                text={
                  <Trans
                    id="trades.summary.weekResultShort"
                    components={{
                      br: <br />,
                    }}
                    values={{
                      value: calculateMonthlyInversePercentage(monthData.long_count, monthData.count),
                    }}
                  />
                }
              />
              <DiaryWeekResultItem
                className={style.countSize}
                textClassName={style.line}
                value={calculateMonthlyProfitLossRatio(monthData.avg_win, monthData.avg_loss)}
                text={
                  <Trans
                    id="trades.summary.weekProfitRatio"
                    components={{
                      br: <br />,
                    }}
                    values={{
                      profit: monthData.avg_win
                        ? calculateMonthlyWinPercentage(monthData.avg_win)
                        : DEFAULT_VALUE_WITH_SYMBOL,
                      lesion: monthData.avg_loss
                        ? calculateMonthlyLossPerFailedTradesPercentage(monthData.avg_loss)
                        : DEFAULT_VALUE_WITH_SYMBOL,
                    }}
                  />
                }
              />
            </div>
            <div className={style.rightBlock}>
              <DiaryWeekResultItem
                value={monthData.leverage ? formatLeverage(monthData.leverage) : DEFAULT_LEVERAGE_VALUE}
                className={style.countSize}
                text={t({
                  id: 'trades.summary.weekLeverage',
                })}
              />
              <DiaryWeekResultItem
                className={style.countSize}
                value={`${calculateMonthlyCommissionSum(monthData.commission).commission} $`}
                text={t({
                  id: 'trades.summary.weekCommission',
                })}
              />
              <DiaryWeekResultItem
                className={style.countSize}
                value={calculateMonthlyVolumeSum(monthData.volume).volume + ' $'}
                text={t({
                  id: 'trades.summary.volume',
                })}
              />
            </div>
          </div>
        </>
      )
    } else if (day && E_SwitchItems.DAY) {
      return (
        <>
          <div className={style.header}>
            <div className={style.profit}>
              <DiaryWeekResultItem
                isCountPercent
                className={clsx({
                  [style.fontSmall]: checkStringLength(handleRound(day.net_profit) ?? ''),
                })}
                sourceValue={Number(day.net_profit)}
                value={day.net_profit ? handleRound(day.net_profit) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
                text={t({
                  id: 'trades.summary.netProfit',
                })}
              />
            </div>
            <DiaryWeekResultItem
              value={getPercentage(day.win_count, day.count)}
              text={t({
                id: 'trades.summary.weekWin',
              })}
            />
          </div>
          <div className={style.resultContainer}>
            <div className={style.leftBlock}>
              <DiaryWeekResultItem
                className={style.countSize}
                value={getPercentage(day.long_count, day.count)}
                text={
                  <Trans
                    id="trades.summary.weekResultShort"
                    components={{
                      br: <br />,
                    }}
                    values={{
                      value: calculatePercentage(day.long_count, day.count),
                    }}
                  />
                }
              />
              <DiaryWeekResultItem
                className={style.countSize}
                textClassName={style.line}
                value={getRatio(day.avg_win, day.avg_loss)}
                text={
                  <Trans
                    id="trades.summary.weekProfitRatio"
                    components={{
                      br: <br />,
                    }}
                    values={{
                      profit: Number(day.avg_win) ? `${handleRound(day.avg_win)} $` : DEFAULT_VALUE_WITH_SYMBOL,
                      lesion: Number(day.avg_loss) ? `${handleRound(day.avg_loss)} $` : DEFAULT_VALUE_WITH_SYMBOL,
                    }}
                  />
                }
              />
            </div>
            <div className={style.rightBlock}>
              <DiaryWeekResultItem
                value={day.leverage ? formatLeverage(day.leverage) : DEFAULT_LEVERAGE_VALUE}
                className={style.countSize}
                text={t({
                  id: 'trades.summary.weekLeverage',
                })}
              />
              <DiaryWeekResultItem
                className={style.countSize}
                value={day.commission ? handleRound(day.commission) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
                text={t({
                  id: 'trades.summary.weekCommission',
                })}
              />
              <DiaryWeekResultItem
                className={style.countSize}
                value={day.volume ? handleRound(day.volume) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
                text={t({
                  id: 'trades.summary.volume',
                })}
              />
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className={style.header}>
            <div className={style.profit}>
              <DiaryWeekResultItem
                isCountPercent
                value={DEFAULT_VALUE_WITH_SYMBOL}
                text={t({
                  id: 'trades.summary.netProfit',
                })}
              />
            </div>
            <DiaryWeekResultItem
              value={DEFAULT_VALUE_PERCENT_SYMBOL}
              text={t({
                id: 'trades.summary.weekWin',
              })}
            />
          </div>
          <div className={style.resultContainer}>
            <div className={style.leftBlock}>
              <DiaryWeekResultItem
                className={style.countSize}
                value={DEFAULT_VALUE_PERCENT_SYMBOL}
                text={
                  <Trans
                    id="trades.summary.weekResultShort"
                    components={{
                      br: <br />,
                    }}
                    values={{
                      value: DEFAULT_VALUE_PERCENT_SYMBOL,
                    }}
                  />
                }
              />
              <DiaryWeekResultItem
                className={style.countSize}
                textClassName={style.line}
                value={DEFAULT_VALUE_WITHOUT_SYMBOL}
                text={
                  <Trans
                    id="trades.summary.weekProfitRatio"
                    components={{
                      br: <br />,
                    }}
                    values={{
                      profit: DEFAULT_VALUE_WITH_SYMBOL,
                      lesion: DEFAULT_VALUE_WITH_SYMBOL,
                    }}
                  />
                }
              />
            </div>
            <div className={style.rightBlock}>
              <DiaryWeekResultItem
                value={DEFAULT_LEVERAGE_VALUE}
                className={style.countSize}
                text={t({
                  id: 'trades.summary.weekLeverage',
                })}
              />{' '}
              <DiaryWeekResultItem
                className={style.countSize}
                value={DEFAULT_VALUE_WITH_SYMBOL}
                text={t({
                  id: 'trades.summary.weekCommission',
                })}
              />
              <DiaryWeekResultItem
                className={style.countSize}
                value={DEFAULT_VALUE_WITH_SYMBOL}
                text={t({
                  id: 'trades.summary.volume',
                })}
              />
            </div>
          </div>
        </>
      )
    }
  }
  return <div className={style.root}>{showData()}</div>
}
