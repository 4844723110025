import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { RiskEvent } from '@/redux/riskManager/riskManager.types'
import style from './style.module.scss'
import { EventModal } from './EventModal/EventModal'
import { useReasonMessage } from './EventModal/hooks/useReasonMessage'

type RiskManagerEventProps = {
  event: RiskEvent
}

const RiskManagerEvent: FC<RiskManagerEventProps> = ({ event }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const openModal = useCallback(() => setModalOpen(true), [])
  const closeModal = useCallback(() => setModalOpen(false), [])

  const reasonMessage = useReasonMessage(event)

  return (
    <>
      <div onClick={openModal} className={style.history}>
        <div className={style.item}>
          <div className={style.amount}>{event.eventTimeFormatted}</div>
          <div className={clsx(style.date, style.text)}>
            {t({ id: 'riskManager.eventDate', comment: 'Дата срабатывания' })}
          </div>
        </div>
        <div className={clsx(style.item, style.reason)}>
          <div className={style.amount}>{reasonMessage}</div>
          <div className={style.text}>
            {reasonMessage &&
              t({
                id: 'riskManager.eventReason',
                comment: 'Причина',
              })}
          </div>
        </div>
        <div className={style.item}>
          <div className={style.amount}>
            {event.targetBalance.formattedValue} {event.targetBalance.symbol}
          </div>
          <div className={style.text}>
            {t({
              id: 'riskManager.eventBalanceStart',
              comment: 'Баланс на начало дня',
            })}
          </div>
        </div>
        <div className={style.item}>
          <div className={style.amount}>
            {event.actualBalance.formattedValue} {event.actualBalance.symbol}
          </div>
          <div className={style.text}>
            {t({
              id: 'riskManager.eventBalanceMoment',
              comment: 'Баланс в момент срабатывания',
            })}
          </div>
        </div>
      </div>

      <EventModal onClose={closeModal} isOpen={isModalOpen} event={event} />
    </>
  )
}

export default memo(RiskManagerEvent)
