import { FC, memo, useEffect } from 'react'
import EmptyList from '@/ui/molecules/EmptyList'
import HistoryItem from './HistoryItem'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { SkeletonList } from '@/ui/kit'
import { InfiniteScrollCustom } from '@/ui/atoms/InfiniteScrollCustom'
import { useActions, useAppSelector } from '@/utils'
import { okxAffiliateActions } from '@/redux/okxAffiliate/okxAffiliate.slice'
import { HistoryItemType } from '@/redux/okxAffiliate/okxAffiliate.types'

const HistoryContent: FC = () => {
  const { GetHistoryOperations } = useActions(okxAffiliateActions)
  const { isLoading, isLoadingHistory, historyOperations } = useAppSelector(state => state.okxAffiliate)

  const historyData = historyOperations.data

  function scrollCallback() {
    if (!isLoadingHistory && historyData.length && historyOperations.total > historyData.length) {
      GetHistoryOperations({ page: historyOperations.page + 1 })
    }
  }

  useEffect(() => {
    GetHistoryOperations({ page: 0 })
  }, [])

  return (
    <div className={style.historyContent}>
      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          {historyData && historyData?.length >= 1 ? (
            <InfiniteScrollCustom scrollCallback={scrollCallback}>
              {historyData.map((item: HistoryItemType) => (
                <HistoryItem key={item.id} data={item} />
              ))}
            </InfiniteScrollCustom>
          ) : (
            <EmptyList
              label={t({
                id: 'okxAffiliate.historyEmpty',
                message: 'History of your saved commissions will be displayed here',
              })}
            />
          )}
        </>
      )}
    </div>
  )
}

export default memo(HistoryContent)
