import { FC, useMemo } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { Button, InteractiveModalParts } from '@/ui/kit'
import { getCompoundTransactionAmount } from '@/utils'
import { useForm } from 'react-hook-form'
import { GoogleCodeVerification } from '@/ui/organisms/GoogleCodeVerification'
import { useActions, useAppSelector, useGoogleAuthCode } from '@/utils/hooks'
import { okxAffiliateActions } from '@/redux/okxAffiliate/okxAffiliate.slice'
import { OKX_AFFILIATE_CURRENCY } from '@/pages/OKXAfiliate/consts'
import { ResultForm } from '../../ResultForm'

type Props = {
  onClose: () => void
}

export const FormWithdraw: FC<Props> = props => {
  const { userStatus, responseDetails, errors } = useAppSelector(state => state.okxAffiliate)
  const googleAuthCode = useGoogleAuthCode()
  const { PostWithdrawal, clearErrors, GetUserStatus, clearResponseDetails, GetHistoryOperations } =
    useActions(okxAffiliateActions)

  const {
    handleSubmit,
    formState: {},
  } = useForm({
    defaultValues: {
      amount: 0,
    },
  })

  const errorsMapped = useMemo<Record<string, string>>(() => {
    return errors.reduce((acc: any, error: any) => {
      return {
        ...acc,
        [error.field]: error.message,
      }
    }, {})
  }, [errors])

  function closePopup() {
    clearResponseDetails()
    clearErrors()
    props.onClose()
  }

  function successClosePopup() {
    GetUserStatus()
    GetHistoryOperations({ page: 0 })
    closePopup()
  }

  function onSubmit() {
    PostWithdrawal({
      googleCode: googleAuthCode.code.join(''),
    })
  }

  if (responseDetails.isCompleted) {
    return (
      <>
        {responseDetails.isSuccess ? (
          <ResultForm
            title={t({
              id: 'okxAffiliate.withdrawPopup.successTitle',
              comment: 'successTitle',
            })}
            description={t({
              id: 'okxAffiliate.withdrawPopup.successDescription',
              comment: 'successDescription',
            })}
            onClose={successClosePopup}
            isSuccess
          />
        ) : (
          <ResultForm
            title={t({
              id: 'okxAffiliate.withdrawPopup.rejectTitle',
              comment: 'Error',
            })}
            description={t({
              id: 'okxAffiliate.withdrawPopup.rejectDescription',
              comment: 'Failed',
            })}
            onClose={closePopup}
            isSuccess={false}
          />
        )}
      </>
    )
  }

  const isDisableButton = userStatus?.cashbackBalance && userStatus?.cashbackBalance >= 10 ? true : false
  const userBalance = userStatus?.cashbackBalance ? userStatus?.cashbackBalance : 0

  return (
    <form className={style.wrapper}>
      <div className={style.feeWrap}>
        <span className={style.fee}>
          {t({
            id: 'okxAffiliate.popupWithdrawal.fee',
            comment: ' Tiger.com fee:',
          })}
        </span>
      </div>
      <div className={style.accountWrap}>
        <span className={style.accountName}>
          {t({
            id: 'okxAffiliate.popupWithdrawal.toAccount',
            comment: 'To the account',
          })}
        </span>
        <span className={style.account}>
          {t({
            id: 'okxAffiliate.popupWithdrawal.spotAccount',
            comment: 'Spot account',
          })}
        </span>
      </div>
      <GoogleCodeVerification
        header={t({
          id: 'withdrawConfirmation.confirmGoogleAuth.enterCode',
          comment: 'Enter Google Authenticator code',
        })}
        text={`${t({
          id: 'withdrawConfirmation.confirmGoogleAuth.contactSupport',
          comment: 'If you’re having issues, please contact support:',
        })} ${t({
          id: 'core.supportEmail',
          comment: 'broker.support@tiger.trade',
        })}`}
        code={googleAuthCode.code}
        setCode={googleAuthCode.setCode}
        errorMessage={errorsMapped.googleCode}
      />
      <div className={style.btnWrapper}>
        <InteractiveModalParts.Button
          text={`
           ${t({ id: 'core.send', comment: 'Send' })}
           ${getCompoundTransactionAmount(`${userBalance}`, OKX_AFFILIATE_CURRENCY)}`}
          variant={Button.Variant.White}
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isDisableButton}
        />
        <InteractiveModalParts.Button
          text={t({ id: 'core.close', comment: 'Close' })}
          variant={Button.Variant.Clean}
          onClick={closePopup}
        />
      </div>
    </form>
  )
}
