import { TGetReferralEarnings } from '@/backend/models/ReferralsDTO'
import {
  EARNINGS_PAGE_SIZE_DEFAULT,
  EARNING_PAGE_FROM_DEFAULT,
  EARNING_PAGE_TO_DEFAULT,
} from '@/core/constants/referrals'
import { BN_ZERO } from '@/core/constants/common'
import { referralsAsyncActions } from '@/redux/referrals/referrals.actions'
import { useActions, useAppSelector } from '@/utils'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { useCallback, useMemo, useRef, useState } from 'react'
import { t } from '@lingui/macro'
import { TTableAccessor } from '@/ui/molecules/Table/Table'
import { formatNumber } from '@/utils'
import BigNumber from 'bignumber.js'
import styles from '../style.module.scss'
import { SVG } from '@/assets/svg'
import { Input, InputSize } from '@/ui/kit'
import { useSearchInput } from './useSearchInput'

export const useReferralEarnings = (
  dateFrom: string = formatDate(EARNING_PAGE_FROM_DEFAULT, DATE_FORMAT.FORMATTED),
  dateTo: string = formatDate(EARNING_PAGE_TO_DEFAULT, DATE_FORMAT.FORMATTED),
  pageSize: number = EARNINGS_PAGE_SIZE_DEFAULT
) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { GetReferralEarningsTC } = useActions(referralsAsyncActions)
  const {
    earnings: { earningsStatus, referralEarnings, earningsPage, earningReachedEnd, earningUser, earningNoSearchResult },
  } = useAppSelector(state => state.referrals)

  const {
    metadata: { firstSesstionTrackedTimestamp },
  } = useAppSelector(state => state.profile)

  const [startDate, setStartDate] = useState(
    firstSesstionTrackedTimestamp
      ? formatDate(new Date(Number(firstSesstionTrackedTimestamp)), DATE_FORMAT.FORMATTED)
      : dateFrom
  )
  const [endDate, setEndDate] = useState(dateTo)

  const getReferralEarnings = useCallback(
    (page: number = 0, options?: { userId?: string; from?: string; to?: string }) => {
      GetReferralEarningsTC({
        filterParams: {
          dateFrom: options?.from ?? dateFrom,
          dateTo: options?.to ?? dateTo,
          ...(options?.userId !== undefined ? { userId: options.userId } : {}),
        },
        page,
        size: pageSize,
      })
    },
    [startDate, endDate, dateFrom, dateTo, pageSize]
  )

  const changeDates = (dateStart: string, dateEnd: string) => {
    setStartDate(dateStart)
    setEndDate(dateEnd)
  }

  const { searchInputHandler, searchValueError, searchValueInput } = useSearchInput(
    startDate,
    endDate,
    (value: string | undefined, start: string, end: string) => {
      getReferralEarnings(0, { userId: value, from: start, to: end })
    }
  )

  const translations = useMemo<Record<keyof TGetReferralEarnings, string>>(() => {
    return {
      exchangeSection: t({
        id: 'referralEarnings.exchangeSection',
        message: 'Trading instrument',
      }),
      partnerEarnings: t({
        id: 'referralEarnings.partnerEarnings',
        message: 'Your income',
      }),
      tradingDay: t({
        id: 'referralEarnings.tradingDay',
        message: 'Trading day',
      }),
      userCommission: t({
        id: 'referralEarnings.userCommission',
        message: 'User commission',
      }),
      userId: t({
        id: 'referralEarnings.userId',
        message: 'User id',
      }),
      userJoined: t({
        id: 'referralEarnings.userJoined',
        message: 'User joined date',
      }),
      userVolume: t({
        id: 'referralEarnings.userVolume',
        message: 'User trade volume',
      }),
    }
  }, [])

  const SearchInput = () => {
    return (
      <Input
        setValue={searchInputHandler}
        value={searchValueInput}
        size={InputSize.ExtraSmall}
        errorMessage={searchValueError}
        isError={searchValueError.length > 0}
        icon={<SVG.Additional.Search />}
        placeholder={t({
          id: 'referralEarnings.userId',
          message: 'User id',
        })}
        containerClassName={styles.inputContainer}
        errorClassName={styles.inputError}
        ref={inputRef}
        autofocus={inputRef.current === document.activeElement}
      />
    )
  }

  const tableAccessors = useMemo<TTableAccessor[]>(() => {
    if (referralEarnings.length === 0) return []
    return Object.keys(referralEarnings[0]).map((key, idx) => {
      return {
        accessor: key,
        header: <>{idx === 0 ? <SearchInput /> : translations[key as keyof TGetReferralEarnings]}</>,
        meta: {
          className: [styles.td],
        },
      }
    })
  }, [referralEarnings, translations, searchInputHandler, searchValueInput, searchValueError])

  const sumColumnFormatter = useMemo(() => {
    if (referralEarnings.length === 0) return

    const { partnerEarnings, userCommission, userVolume } = referralEarnings.reduce(
      (acc, { partnerEarnings, userCommission, userVolume }) => ({
        partnerEarnings: acc.partnerEarnings.plus(new BigNumber(partnerEarnings)),
        userCommission: acc.userCommission.plus(new BigNumber(userCommission)),
        userVolume: acc.userVolume.plus(new BigNumber(userVolume)),
      }),
      { partnerEarnings: BN_ZERO, userCommission: BN_ZERO, userVolume: BN_ZERO }
    )

    const userId = t({
      id: 'referralEarnings.total',
      message: 'Total',
    })

    return {
      partnerEarnings: { value: formatNumber(partnerEarnings.toNumber()), className: styles.highligth },
      userCommission: { value: formatNumber(userCommission.toNumber()) },
      userVolume: { value: formatNumber(userVolume.toNumber()) },
      userId: {
        value: userId,
      },
    }
  }, [referralEarnings])

  return {
    getReferralEarnings,
    earningsStatus,
    referralEarnings,
    earningsPage,
    earningReachedEnd,
    tableAccessors,
    earningUser,
    earningNoSearchResult,
    startDate,
    endDate,
    changeDates,
    sumColumnFormatter,
    searchInputHandler,
    searchValueError,
    searchValueInput,
  }
}
