import clsx from 'clsx'
import { memo } from 'react'
import style from './style.module.scss'

type TabProps = {
  /** tab text */
  text: string

  /** tab id */
  tabId: number

  /** active tab id setter */
  setActiveTab: () => void

  /** active tab id  */
  activeTab: number

  /** data test id */
  dataTestId?: string
}

/** One tab in tab buttons */
export const ListTab = memo<TabProps>(({ text, tabId, setActiveTab, activeTab, dataTestId }) => {
  return (
    <div onClick={setActiveTab} className={style.tab} data-testid={dataTestId}>
      <span className={clsx(style.text, activeTab === tabId && style.activeText)}>{text}</span>
    </div>
  )
})
