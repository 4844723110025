import { FC, useMemo } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { Button, InteractiveModalParts } from '@/ui/kit'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { GoogleCodeVerification } from '@/ui/organisms/GoogleCodeVerification'
import { useActions, useAppSelector, useGoogleAuthCode } from '@/utils/hooks'
import { useForm } from 'react-hook-form'
import { okxAffiliateActions } from '@/redux/okxAffiliate/okxAffiliate.slice'
import { ResultForm } from '../../../ResultForm'
import { errorTranslations } from '@/core/constants/errors/errorsTranslations'

type Props = {
  onClose: () => void
}

export const FormRemoveKey: FC<Props> = props => {
  const { errors, responseDetails } = useAppSelector(state => state.okxAffiliate)
  const { DeleteKey, clearErrors, clearResponseDetails, GetUserStatus } = useActions(okxAffiliateActions)
  const googleAuthCode = useGoogleAuthCode()
  const errorsTrans = errorTranslations()

  const {
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {},
  })

  const errorsMapped = useMemo<Record<string, string>>(() => {
    return errors.reduce((acc: any, error: any) => {
      return {
        ...acc,
        [error.field]: error.message,
      }
    }, {})
  }, [errors])

  function closePopup() {
    clearResponseDetails()
    clearErrors()
    props.onClose()
  }

  function successClosePopup() {
    GetUserStatus()
    closePopup()
  }

  function onSubmit() {
    const googleCode = googleAuthCode.code.join('')
    DeleteKey({ googleCode })
  }

  const isFormValid = isValid && googleAuthCode.isCorrectCodeLength

  if (responseDetails.isCompleted) {
    return (
      <>
        {responseDetails.isSuccess ? (
          <ResultForm
            title={t({
              id: 'okxAffiliate.deleteKeyPopup.successTitle',
              comment: 'The key has been deleted',
            })}
            description={t({
              id: 'okxAffiliate.deleteKeyPopup.successDescription',
              comment: 'You have successfully deleted the keys, you can link new keys whenever you want.',
            })}
            onClose={successClosePopup}
            isSuccess
          />
        ) : (
          <ResultForm
            title={t({
              id: 'okxAffiliate.deleteKeyPopup.rejectTitle',
              comment: 'Key deletion error',
            })}
            description={t({
              id: 'okxAffiliate.deleteKeyPopup.rejectDescription',
              comment: 'Failed to delete keys',
            })}
            onClose={closePopup}
            isSuccess={false}
          />
        )}
      </>
    )
  }

  return (
    <form className={style.wrapper}>
      <TitleH3
        className={style.title}
        label={t({
          id: 'okxAffiliate.deleteKeyPopup.title',
          comment: 'Delete OKX keys?',
        })}
      />
      <div className={style.description}>
        <span>
          {t({
            id: 'okxAffiliate.deleteKeyPopup.description',
            comment: 'Deleting keys is final and irrevocable',
          })}
        </span>
      </div>
      <GoogleCodeVerification
        header={t({
          id: 'withdrawConfirmation.confirmGoogleAuth.enterCode',
          comment: 'Enter Google Authenticator code',
        })}
        text={`${t({
          id: 'withdrawConfirmation.confirmGoogleAuth.contactSupport',
          comment: 'If you’re having issues, please contact support:',
        })} ${t({
          id: 'core.supportEmail',
          comment: 'broker.support@tiger.trade',
        })}`}
        code={googleAuthCode.code}
        setCode={googleAuthCode.setCode}
        errorMessage={errorsMapped.googleCode ? errorsTrans.googleCode : undefined}
      />
      <div className={style.btnWrapper}>
        <InteractiveModalParts.Button
          text={t({ id: 'okxAffiliate.deleteKeyPopup.deleteButton', comment: 'Delete keys' })}
          variant={Button.Variant.White}
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isFormValid || false}
        />
        <InteractiveModalParts.Button
          text={t({ id: 'core.close', comment: 'Close' })}
          variant={Button.Variant.Clean}
          onClick={closePopup}
        />
      </div>
    </form>
  )
}
