interface IAppleProps {
  className?: string
}

export const Apple = (props: IAppleProps) => {
  const { className } = props

  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88336 1.73309C10.6179 0.872692 11.8469 0.242047 12.8714 0.20032C12.9555 0.197005 13.0302 0.26005 13.0396 0.344934C13.1584 1.42814 12.7656 2.63743 11.9889 3.57973C11.2509 4.47211 10.1702 5.02632 9.16842 5.02632C9.0981 5.02625 9.02751 5.02352 8.95836 5.01813C8.88186 5.01221 8.81998 4.95352 8.80991 4.87748C8.64924 3.66208 9.25909 2.45629 9.88336 1.73309ZM2.97723 17.4843C1.12396 14.8083 0.0585666 10.395 1.73446 7.48679C2.61579 5.95396 4.2144 4.98605 5.90622 4.96116C5.92305 4.96084 5.94015 4.96064 5.9575 4.96064C6.68512 4.96064 7.37244 5.23336 7.97884 5.47403L7.97935 5.47424C8.4326 5.65406 8.82402 5.80934 9.12567 5.80934C9.39423 5.80934 9.78362 5.65582 10.2344 5.47806C10.8875 5.22055 11.7004 4.90006 12.549 4.90006C12.6576 4.90006 12.7658 4.90533 12.8707 4.91579C13.5932 4.94686 15.3877 5.20509 16.5585 6.91848C16.5832 6.95462 16.5923 6.99933 16.5836 7.04229C16.5749 7.08532 16.5492 7.12295 16.5123 7.14668L16.4965 7.15656C16.1563 7.36701 14.4601 8.52814 14.4818 10.7208C14.5053 13.4197 16.7374 14.4099 16.9918 14.5139L17.0037 14.519C17.0792 14.5536 17.1163 14.6395 17.09 14.7183L17.0844 14.7359C16.9462 15.1787 16.5365 16.3377 15.7197 17.5313L15.7195 17.5315C14.9368 18.6746 14.0497 19.9704 12.6048 19.9973C11.9313 20.0099 11.4748 19.8124 11.0322 19.6209L11.0285 19.6193L11.0282 19.6192C10.578 19.4244 10.1124 19.2229 9.38293 19.2229C8.61599 19.2229 8.12703 19.4312 7.65426 19.6327L7.65374 19.6329C7.2328 19.8122 6.79738 19.9976 6.19493 20.0216C6.1701 20.0226 6.14586 20.0231 6.12168 20.0231C4.83887 20.0231 3.9023 18.8222 2.97723 17.4843Z"
        fill="currentColor"
      />
    </svg>
  )
}
