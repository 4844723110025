import { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { formatNumber } from '@/utils'
import s from './style.module.scss'
import { useBenefitsData } from '@/utils/hooks/data/useBenefitsData'
import { useCalculatorSources } from '@/utils/hooks/useCalculatorSources'

interface IReferralHowTableProps {
  value: string
}

const ReferralHowTable: FC<IReferralHowTableProps> = props => {
  const { referralIncomePercent, userIncomePercent } = useBenefitsData()
  const calculatorSources = useCalculatorSources(userIncomePercent)

  const HEADERS = [
    t({ id: 'referralHowItWorks.tableHeader.userReduction', message: 'User reduction rate, %' }),
    t({ id: 'referralHowItWorks.tableHeader.userFee', message: 'User fee reward, USDT' }),
    t({ id: 'referralHowItWorks.tableHeader.yourRewardPercent', message: 'Your reward, %' }),
    t({ id: 'referralHowItWorks.tableHeader.yourRewardUsdt', message: 'Your reward, USDT' }),
  ]

  const { value } = props
  const [rows, setRows] = useState<Array<number | string>[]>([])

  useEffect(() => {
    const rows = []

    for (
      let i = calculatorSources.REF_START_PERCENT;
      i < calculatorSources.REF_END_PERCENT;
      i += calculatorSources.REF_PERCENT_STEP
    ) {
      const referrralCommission = (Number(value) / 100) * i
      const masterPercent = calculatorSources.HOST_START_WITH_REF - i
      const masterCommission = (referrralCommission / 100) * masterPercent
      rows.push([i, formatNumber(referrralCommission), masterPercent, formatNumber(masterCommission)])
    }

    setRows(rows)
  }, [
    value,
    calculatorSources.REF_START_PERCENT,
    calculatorSources.REF_END_PERCENT,
    calculatorSources.REF_PERCENT_STEP,
    calculatorSources.HOST_START_WITH_REF,
  ])

  return (
    <div className={s.root}>
      <div className={s.row}>
        {HEADERS.map(header => (
          <div key={header} className={s.header}>
            {header}
          </div>
        ))}
      </div>
      {rows.map((row, index) => (
        <div key={index} className={s.row}>
          {row.map((cell, j) => (
            <div key={j} className={s.cell}>
              {cell}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export { ReferralHowTable }
