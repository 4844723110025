import { FC } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import clsx from 'clsx'
import { AccountOverviewPositionsView } from '@/backend/models/OverviewDTO'
import { DangerWithTooltip } from '@/ui/molecules'
import { formatNumberByRules } from '@/ui/molecules/FormatNumberDotWithTooltip/utils'

type Props = {
  onChangeItem: (item: AccountOverviewPositionsView) => void
  data: AccountOverviewPositionsView
}

export const MarginItem: FC<Props> = ({ data, onChangeItem }) => {
  function handleSettings() {
    onChangeItem(data)
  }

  const isolatedMargin = data.isolatedMargin ? data.isolatedMargin : data.positionBalance
  const isMarginNegative = isolatedMargin ? parseFloat(isolatedMargin) < 0 : false

  return (
    <>
      <div className={style.wrapper}>
        <div className={clsx(style.symbol, { [style.negative]: isMarginNegative })}>
          <span>{data.symbol}</span>
          {isMarginNegative && (
            <DangerWithTooltip
              text={t({
                id: 'isolatedMargin.marginItem.dangerTooltip',
                comment: 'Danger Tooltip',
              })}
            />
          )}
        </div>
        <div className={style.amount}>
          <span>
            {data.positionAmt || data.size} {data.symbol}
          </span>
        </div>
        <div className={clsx(style.margin, { [style.negative]: isMarginNegative })}>
          {isMarginNegative && ''}
          {isolatedMargin ? formatNumberByRules(isolatedMargin) : '-'} {data.quoteAsset}
        </div>
        <div className={style.setting} onClick={handleSettings}>
          <SVG.OtherIcons.Settings />
        </div>
      </div>
    </>
  )
}
