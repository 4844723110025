import {
  GetLimitsTC,
  GetAvailableLimitsTC,
  GetCountriesTC,
  GetFormKycTC,
  GetUserKycTC,
  GetUserKycLinkTC,
  GetUserKycLinkStatusTC,
  GetWorkflowTC,
  InitWorkflowTC,
  UpdateBeneficialOwnerStatusTC,
  UpdateFormKycTC,
  GetSumsubTokenTC,
} from './kyc.thunks'

export const kycAsyncActions = {
  GetUserKycTC,
  GetUserKycLinkTC,
  GetUserKycLinkStatusTC,
  GetFormKycTC,
  UpdateFormKycTC,
  UpdateBeneficialOwnerStatusTC,
  GetCountriesTC,
  InitWorkflowTC,
  GetWorkflowTC,
  GetLimitsTC,
  GetAvailableLimitsTC,
  GetSumsubTokenTC,
}
