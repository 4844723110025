import { FC } from 'react'
import styles from './style.module.scss'
import NotifiactionCard from './components/NotifiactionCard/NotifiactionCard'
import { useSystemNotifications } from '@/ui/molecules/SystemNotifications/hooks/useSystemNotifications'
import { useMobileSizeDetect } from '@/utils'
import clsx from 'clsx'

export const SystemNotifications: FC = () => {
  const [isMobile] = useMobileSizeDetect()
  const notifications = useSystemNotifications()

  if (!notifications.length) {
    return null
  }

  return (
    <div className={clsx(isMobile && styles.mobile, styles.root)}>
      {notifications.map(props => (
        <NotifiactionCard key={props.id} {...props} isMobile={isMobile} />
      ))}
    </div>
  )
}
