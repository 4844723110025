import { FieldErrorType } from '@/types'

export enum OperationType {
  cashback = 'CASHBACK',
  payment = 'PAYMENT',
}

export type InitialState = {
  historyOperations: historyOperationsType
  userStatus: userStatus | null
  responseDetails: {
    isSuccess: boolean
    isCompleted: boolean
    errorCode?: number | null
  }
  isOfferPopup: boolean
  isOpenAddKeyPopup: boolean
  isLoadingHistory: boolean
  isLoading: boolean
  errors: FieldErrorType[]
  config: {
    okxPercent: number
  }
}

export type userStatus = {
  apiKey?: {
    key: string
    status: string
  } | null
  cashbackBalance: number
  cashbackPercent: number
  isAffiliate: boolean
  referralUrl: string
  userId: string
}

export type historyOperationsType = {
  data: HistoryItemType[]
  page: number
  size: number | null
  total: number
}

export type HistoryItemType = {
  id: number
  type: OperationType
  time: Date
  status?: 'FAILED' | 'PENDING' | 'SUCCESS'
  amount: number
  details: {
    cashbackPercent?: number
    fee?: number
    address?: string
    network?: string
    transactionId?: string
    externalId?: string
  }
  settlements?: any[]
}
