import { ECashbackCalculationRule } from '@/backend/api/cashbackAPI'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { percentFromString } from '@/utils/lib/numbers'
import BigNumber from 'bignumber.js'
import { useEffect, useMemo } from 'react'
import { CashbackTermsType, CashbackType } from '../../../backend/api'
import { IApiKeyStatisticsDTO } from '../../../backend/models/ApiKeyDTO'
import { formatNumber } from '../../lib'
import { PERCENT_DECIMALS_MULTIPLIER } from '../../lib/constants'
import { useAppSelector } from '../useAppSelector'
import { useActions } from '../useActions'
import { cashbackActions } from '@/redux/cashback/cashback.slice'
import { useExchageType } from '@/utils/hooks/useExchangeType'

export type TUseCashbackData = {
  isActualCashbackGainedMaximumAmount: boolean
  isActualCashbackGainedMaximumPercent: boolean
  cashbackVolumeLast30Days: number
  cashbackVolumeLast30DaysFormatted: string
  cashbackVolumeLast30DaysRounded: number
  cashbackVolumeLast30DaysRoundedFormatted: string
  cashbackTodayValue: number
  cashbackTodayValueFormatted: string
  cashbackTodayValueRounded: number
  cashbackTodayValueRoundedFormatted: string
  cashbackNextVolume: number
  cashbackNextVolumeFormatted: string
  cashbackLeftToNext: number
  cashbackLeftToNextFormatted: string
  cashbackLeftToNextRounded: number
  cashbackLeftToNextRoundedFormatted: string
  cashbackNextPercentFormatted: string
  cashbackCurrentPercent: number
  cashbackPercentToday: number
  cashbackPercentTodayFormatted: string
  cashbackTopLimit: number
  cashbackTopLimitFormatted: string
  cashbackView: ECashbackTradeView
  allocationMax: number
  allocationMaxFormatted: string
  calculationRule: ECashbackCalculationRule
  expirationDate: Date
  expirationDateFormatted: string
}

const PERCENT_MAJOR_TRESHOLD = 90

export enum ECashbackTradeView {
  NEVER_TRADE,
  CASHBACK_PERCENT_NULL,
  CASHBACK_PERCENT_POSITIVE_MINOR,
  CASHBACK_PERCENT_POSITIVE_MAJOR,
  CASHBACK_PERCENT_MAX,
  CASHBACK_VOLUME_MAX,
}

const getCurrentTerm = (cashbackPercentToday: number, cashbackTerm: CashbackTermsType) => {
  return cashbackPercentToday === percentFromString(cashbackTerm.binanceCashbackPercent)
}

const cashbackDataReducer = (
  cashback: CashbackType,
  cashbackTerms: Record<ECashbackCalculationRule, CashbackTermsType[]>,
  apiKeysStatistics: IApiKeyStatisticsDTO
): TUseCashbackData => {
  const cashbackTermsArray = cashbackTerms.tradeVolume
  const cashbackVolumeLast30Days = cashback.volumeLast30Days || 0
  const cashbackVolumeLast30DaysFormatted = formatNumber(cashbackVolumeLast30Days) + ' USDT'
  const cashbackVolumeLast30DaysRounded = new BigNumber(cashback.volumeLast30Days || 0).dp(2).toNumber()
  const cashbackVolumeLast30DaysRoundedFormatted = formatNumber(cashbackVolumeLast30DaysRounded) + ' USDT'
  const cashbackNextVolume = Number(cashback.nextVolume || 0)
  const cashbackNextVolumeFormatted = formatNumber(cashbackNextVolume) + ' USDT'
  const cashbackNextPercentFormatted =
    formatNumber(cashback.nextCashbackPercent || 0, { maximumFractionDigits: 2 }) + '%'
  const cashbackCurrentPercent =
    cashbackNextVolume === 0
      ? 0
      : Math.min(
          new BigNumber(cashbackVolumeLast30Days)
            .dividedBy(cashbackNextVolume)
            .multipliedBy(PERCENT_DECIMALS_MULTIPLIER)
            .toNumber(),
          100
        )
  const cashbackPercentToday = Number(cashback.cashbackPercentToday)
  const cashbackPercentTodayFormatted = cashback.cashbackPercentToday + '%'
  const currentTerm = cashbackTermsArray.find(term => getCurrentTerm(cashbackPercentToday, term))
  const cashbackLeftToNext = currentTerm ? Math.max(Number(currentTerm.toExclusive) - cashbackVolumeLast30Days, 0) : 0
  const cashbackLeftToNextFormatted = formatNumber(cashbackLeftToNext) + ' USDT'
  const cashbackLeftToNextRounded = new BigNumber(cashbackLeftToNext).dp(2, 0).toNumber()
  const cashbackLeftToNextRoundedFormatted = formatNumber(cashbackLeftToNextRounded) + ' USDT'
  const isActualCashbackGainedMaximumAmount = Boolean(cashbackTermsArray.length)
    ? cashbackVolumeLast30Days >= Number(cashbackTermsArray[cashbackTermsArray.length - 1].fromInclusive) //last term doesn't have toExclusive field
    : false
  const isActualCashbackGainedMaximumPercent = Boolean(cashbackTermsArray.length)
    ? cashbackPercentToday >=
      percentFromString(cashbackTermsArray[cashbackTermsArray.length - 1].binanceCashbackPercent)
    : false
  const cashbackTopLimit = Boolean(cashbackTermsArray.length)
    ? Number(cashbackTermsArray[cashbackTermsArray.length - 1].fromInclusive)
    : 0
  const cashbackTopLimitFormatted = `${cashbackTopLimit} USDT`
  const allocationMax = Boolean(cashbackTerms.allocationBalance.length)
    ? percentFromString(
        cashbackTerms.allocationBalance[cashbackTerms.allocationBalance.length - 1].binanceCashbackPercent
      )
    : 30
  const allocationMaxFormatted = allocationMax + '%'
  const cashbackTodayValue = cashback.cashbackToday
  const cashbackTodayValueFormatted = cashback.cashbackToday + ' USDT'
  const cashbackTodayValueRounded = new BigNumber(cashback.cashbackToday).dp(2, 1).toNumber()
  const cashbackTodayValueRoundedFormatted = formatNumber(cashbackTodayValueRounded) + ' USDT'
  const cashbackView = !Boolean(apiKeysStatistics.apiKeyCount)
    ? ECashbackTradeView.NEVER_TRADE
    : isActualCashbackGainedMaximumPercent
    ? isActualCashbackGainedMaximumAmount
      ? ECashbackTradeView.CASHBACK_VOLUME_MAX
      : ECashbackTradeView.CASHBACK_PERCENT_MAX
    : cashbackCurrentPercent === 0
    ? ECashbackTradeView.CASHBACK_PERCENT_NULL
    : cashbackCurrentPercent >= PERCENT_MAJOR_TRESHOLD
    ? ECashbackTradeView.CASHBACK_PERCENT_POSITIVE_MAJOR
    : ECashbackTradeView.CASHBACK_PERCENT_POSITIVE_MINOR

  const expirationDate = cashback.expirationDate ? new Date(cashback.expirationDate) : new Date()

  return {
    isActualCashbackGainedMaximumAmount,
    isActualCashbackGainedMaximumPercent,
    cashbackVolumeLast30Days,
    cashbackVolumeLast30DaysFormatted,
    cashbackNextVolume,
    cashbackNextVolumeFormatted,
    cashbackNextPercentFormatted,
    cashbackCurrentPercent,
    cashbackPercentToday: cashback.cashbackPercentToday,
    cashbackPercentTodayFormatted,
    cashbackLeftToNext,
    cashbackLeftToNextFormatted,
    cashbackTopLimit,
    cashbackTopLimitFormatted,
    cashbackView,
    allocationMax,
    allocationMaxFormatted,
    cashbackTodayValue,
    cashbackTodayValueFormatted,
    cashbackTodayValueRounded,
    cashbackTodayValueRoundedFormatted,
    cashbackLeftToNextRounded,
    cashbackLeftToNextRoundedFormatted,
    cashbackVolumeLast30DaysRounded,
    cashbackVolumeLast30DaysRoundedFormatted,
    calculationRule: cashback.calculationRule,
    expirationDate,
    expirationDateFormatted: formatDate(expirationDate, DATE_FORMAT.DAY_AND_YEAR),
  }
}

export const useCashbackData = (): TUseCashbackData => {
  const { cashback, cashbackTerms, status, cashbackTermsStatus } = useAppSelector(state => state.cashback)
  const { statistics } = useAppSelector(state => state.apiKeys)
  const { GetCashbackTC, GetCashbackTermsTC } = useActions(cashbackActions)
  const { exchangeType } = useExchageType()

  const cashbackData = useMemo(
    () => cashbackDataReducer(cashback, cashbackTerms, statistics),
    [cashback, cashbackTerms, statistics]
  )

  useEffect(() => {
    if (status === 'idle' && exchangeType !== 'BINANCE_VIP') GetCashbackTC()
    if (cashbackTermsStatus === 'idle' && exchangeType !== 'BINANCE_VIP') GetCashbackTermsTC()
  }, [status, cashbackTermsStatus])

  return cashbackData
}
