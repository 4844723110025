import { FC, memo, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { Button } from '@/ui/kit'
import style from './style.module.scss'
import { useAppSelector } from '@/utils'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { EXCHANGES_AVAILABLE } from '@/core/constants'
import { isExchangeBinance } from '@/utils/lib/exchange'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'

const MainTabButtons: FC = () => {
  const { instrumentType, exchangeType } = useAppSelector(state => state.overview)

  const { isDepositEnabled, isWithdrawEnabled } = useExchangesDisabled()

  const navigate = useNavigate()
  const BUTTONS = [
    {
      id: 1,
      label: t({ message: `Deposit`, id: 'core.deposit' }),
      icon: <SVG.Actions.Deposit />,
      href: '/transactions',
      btnType: 'deposit',
      dataTestId: DataTestIds.WalletTabDepositButton,
      exchangeType: EXCHANGES_AVAILABLE,
      disabled: !isDepositEnabled,
    },
    {
      id: 2,
      label: t({ message: `Transfer`, id: 'core.transfer' }),
      icon: <SVG.Actions.Transfer />,
      href: '/transactions',
      btnType: 'transfer',
      dataTestId: DataTestIds.WalletTabTransferButton,
      exchangeType: ['BINANCE_BROKER_FUTURE', 'BINANCE_VIP'],
    },
    {
      id: 3,
      label: t({ message: 'Withdraw', id: 'core.withdraw' }),
      icon: <SVG.Actions.Withdraw />,
      href: '/transactions',
      btnType: 'withdraw',
      dataTestId: DataTestIds.WalletTabWithdrawButton,
      exchangeType: EXCHANGES_AVAILABLE,
      disabled: !isWithdrawEnabled,
    },
  ]

  const onClickButton = (href: string, type: string) => {
    localStorage.setItem('transactionType', type)
    navigate(href)
  }

  const renderButtons = () => {
    if (instrumentType === 'SPOT' || instrumentType === 'spot') {
      return (
        <>
          {BUTTONS.map(item => {
            if (item.exchangeType.includes(exchangeType))
              return (
                <Button.Primary
                  key={item.id}
                  label={item.label}
                  disabled={item.disabled}
                  rightIcon={item.icon}
                  onClick={() => onClickButton(item.href, item.btnType)}
                  q
                  dataTestId={item.dataTestId}
                />
              )
            else return null
          })}
        </>
      )
    }
    if (isExchangeBinance(exchangeType))
      return (
        <Button.Primary
          onClick={() => onClickButton('/transactions', 'transfer')}
          label={t({ message: 'Transfer', id: 'core.transfer' })}
          rightIcon={<SVG.Actions.Transfer />}
          dataTestId={DataTestIds.WalletTabTransferButton}
        />
      )
  }
  return <div className={style.mainTabButtons}>{renderButtons()}</div>
}

export default memo(MainTabButtons)
