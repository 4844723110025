import React from 'react'

export const Brilliant = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 28L29.5 17.6571M20 28L10.5 17.6571M20 28L15.8333 17.6571M20 28L24.1667 17.6571M29.5 17.6571L25.7671 12.7838C25.3886 12.2897 24.8017 12 24.1793 12H22.5833M29.5 17.6571H24.1667M10.5 17.6571L14.2329 12.7838C14.6114 12.2897 15.1983 12 15.8207 12H17.4167M10.5 17.6571H15.8333M17.4167 12L15.8333 17.6571M17.4167 12H22.5833M22.5833 12L24.1667 17.6571M15.8333 17.6571H24.1667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
