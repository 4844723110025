import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'
import { KycAPI } from '@/backend/api'
import {
  CountriesKYC,
  ResponseFormKYC,
  KYCLimitsDTO,
  KYCAvailableLimitsDTO,
  IdentityVerificationTokenKYC,
  UpdateFormPayloadKYC,
  UserKYC,
  WorkflowKYC,
  TUserKYCLinkDTO,
  UserKYCLinkStatusDTO,
  TUserKYCLinkStatus,
} from '@/backend/models/KycDTO'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'

export const GetUserKycTC = createAsyncThunk<UserKYC, void, ThunkError>(
  nameof(KycAPI.getUserKYC),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.getUserKYC, payload }, thunkAPI)
  }
)

export const GetUserKycLinkTC = createAsyncThunk<TUserKYCLinkDTO, void, ThunkError>(
  nameof(KycAPI.getUserKYCLink),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.getUserKYCLink, payload }, thunkAPI)
  }
)

export const GetUserKycLinkStatusTC = createAsyncThunk<UserKYCLinkStatusDTO, void, ThunkError>(
  nameof(KycAPI.getUserKYCLinkStatus),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.getUserKYCLinkStatus, payload }, thunkAPI)
  }
)

export const GetFormKycTC = createAsyncThunk<ResponseFormKYC, void, ThunkError>(
  nameof(KycAPI.getFormKYC),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.getFormKYC, payload }, thunkAPI)
  }
)

export const UpdateFormKycTC = createAsyncThunk<ResponseFormKYC, UpdateFormPayloadKYC, ThunkError>(
  nameof(KycAPI.updateFormKYC),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.updateFormKYC, payload }, thunkAPI)
  }
)

export const UpdateBeneficialOwnerStatusTC = createAsyncThunk<ResponseFormKYC, boolean, ThunkError>(
  nameof(KycAPI.updateBeneficialOwnerStatusKYC),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.updateBeneficialOwnerStatusKYC, payload }, thunkAPI)
  }
)

export const GetCountriesTC = createAsyncThunk<CountriesKYC, void, ThunkError>(
  nameof(KycAPI.getCountriesKYC),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.getCountriesKYC, payload }, thunkAPI)
  }
)

export const InitWorkflowTC = createAsyncThunk<IdentityVerificationTokenKYC, void, ThunkError>(
  nameof(KycAPI.initWorkflowKYC),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.initWorkflowKYC, payload }, thunkAPI)
  }
)

export const GetLimitsTC = createAsyncThunk<KYCLimitsDTO, string | undefined, ThunkError>(
  nameof(KycAPI.getLimitsKYC),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.getLimitsKYC, payload }, thunkAPI)
  }
)

export const GetAvailableLimitsTC = createAsyncThunk<KYCAvailableLimitsDTO, string | undefined, ThunkError>(
  nameof(KycAPI.getAvailableLimitsKYC),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.getAvailableLimitsKYC, payload }, thunkAPI)
  }
)

export const GetWorkflowTC = createAsyncThunk<WorkflowKYC, void, ThunkError>(
  nameof(KycAPI.getWorkflowKYC),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.getWorkflowKYC, payload }, thunkAPI)
  }
)

export const GetSumsubTokenTC = createAsyncThunk<IdentityVerificationTokenKYC, void, ThunkError>(
  nameof(KycAPI.getSumsubToken),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: KycAPI.getSumsubToken, payload }, thunkAPI)
  }
)
