import { i18n } from '@lingui/core'
import { Locale, format } from 'date-fns'
import { ru, uk, enGB } from 'date-fns/locale'
import { capitalize } from '@/utils/lib/capitalize'
import { TLanguage } from '@/core/constants'

export enum DATE_FORMAT {
  DEFAULT = 'dd MMM yyyy HH:mm',
  FORMATTED = 'yyyy-MM-dd',
  DAY_AND_YEAR_SEMI = 'MMM dd, yyyy',
  DAY = 'dd',
  MONTH_AND_YEAR = 'MMM, yyyy',
  WITH_SECONDS = 'dd MMM yyyy HH:mm:ss',
  DAY_AND_YEAR = 'dd MMM yyyy',
  MONTH_FULL_AND_YEAR = 'LLLL, yyyy',
  WITH_TIME = 'yyyy-MM-dd HH:mm:ss',
  MONTH_SHORT = 'LLL',
  MONTH_LONG = 'LLLL',
  DAY_OF_WEEK = 'EEEE',
  WITH_TIME_RU = 'dd-MM-yyyy HH:mm:ss',
  DAY_HOUR_WITHOUT_SECONDS = 'dd-MM-yy HH:mm',
  YEAR_HOUR_WITHOUT_SECONDS = 'yyyy-MM-dd HH:mm',
}

const DATEPICKER_LOCALE_BY_APP_LOCALE: Record<TLanguage, Locale> = {
  en: enGB,
  ru: ru,
  uk: uk,
}

export const formatDate = (date?: Date | string | number | null, formatStr: DATE_FORMAT = DATE_FORMAT.DEFAULT) => {
  if (date === null || date === undefined) {
    return ''
  }

  const locale = i18n.locale as TLanguage
  let formattedDate = format(new Date(date), formatStr, { locale: DATEPICKER_LOCALE_BY_APP_LOCALE[locale] })

  formattedDate = formattedDate.replace(/(\p{L}+)/gu, match => capitalize(match)).replace(/\./g, '')

  return formattedDate
}

export const getMonthName = (month: string, formatStr: DATE_FORMAT = DATE_FORMAT.MONTH_LONG) => {
  const locale = i18n.locale as TLanguage

  return format(new Date(month), formatStr, { locale: DATEPICKER_LOCALE_BY_APP_LOCALE[locale] })
}
