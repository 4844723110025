import { FC } from 'react'

export const Cryptosquad: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="50"
      height="50"
      viewBox="0 0 1000 1000"
    >
      <defs>
        <linearGradient id="linear-gradient" y1="136.729" x2="1000" y2="863.271" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#000004" />
          <stop offset="0.748" stop-color="#000004" />
          <stop offset="1" stop-color="#14261a" />
        </linearGradient>
      </defs>
      <circle id="Эллипс_1" data-name="Эллипс 1" cx="500" cy="500" r="500" />
      <text id="C" transform="translate(209.627 793.778) scale(2.313 2.35)">
        C
      </text>
      <text id="s" transform="translate(350.992 670.114) scale(1.738 1.897)">
        s
      </text>
    </svg>
  )
}
