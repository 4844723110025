import { useInfiniteScroll } from '@/utils/hooks/useInfiniteScroll'
import { FC } from 'react'

export const InfiniteScrollPlate: FC<{
  className?: string
  scrollCallback: IntersectionObserverCallback
}> = props => {
  const { className, scrollCallback } = props
  const { refCallback } = useInfiniteScroll(scrollCallback)

  return (
    <div
      className={className}
      ref={newRef => {
        refCallback(newRef)
      }}
    />
  )
}
