import { ECashbackCalculationRule } from '@/backend/api/cashbackAPI'
import { ITableProps } from '@/ui/atoms/Table'
import { useAppSelector } from '@/utils'
import { useCashbackData } from '@/utils/hooks/data/useCashbackData'
import { t } from '@lingui/macro'
import { useMemo } from 'react'
import style from '../style.module.scss'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { getCashbackLeftBarTranslations } from '../CashbackLeftBar.Translations'
import { isExchangeBinance, isExchangeOkx } from '@/utils/lib/exchange'
import styles from '@/ui/molecules/SystemNotifications/presets/style.module.scss'
import { Trans } from '@lingui/react'

export const useContent = () => {
  const { cashback } = useAppSelector(state => state.cashback)
  const { exchangeType } = useExchageType()
  const cashbackData = useCashbackData()

  const { feesHeading, feesSubheading } = useMemo(() => {
    if (exchangeType === undefined)
      return {
        feesHeading: '',
        feesSubheading: '',
      }
    return getCashbackLeftBarTranslations()[exchangeType]
  }, [exchangeType])

  const balanceSheetData = useMemo(
    () => [
      ...(isExchangeBinance(exchangeType)
        ? [
            {
              label: t({
                id: 'cashbackPage.leftBar.tradingVolume',
                message: 'Trading volume today',
              }),
              amount: cashback.volumeToday,
              symbol: 'USDT',
            },
            {
              label: t({
                id: 'cashbackPage.leftBar.paidComission',
                message: 'Paid commission today',
              }),
              amount: cashback.commissionToday,
              symbol: 'USDT',
            },
          ]
        : []),
      ...(cashback.calculationRule === ECashbackCalculationRule.customReferralCode
        ? [
            {
              label: (
                <>
                  {t({
                    id: 'cashbackPage.leftBar.tradingVolumeMonthly',
                    message: 'Traded volume during the',
                  })}{' '}
                  <span className={style._nowrap}>
                    {t({
                      id: 'cashbackPage.last30Days',
                      message: 'last 30 days',
                    })}
                  </span>
                </>
              ),
              amount: cashbackData.cashbackVolumeLast30DaysRounded,
              symbol: 'USDT',
            },
          ]
        : []),
    ],
    [cashback, cashbackData, exchangeType]
  )

  const commissionTableData = useMemo<
    Array<{
      heading: string
      table: ITableProps
    }>
  >(() => {
    const { makerMsg, takerMsg, msg } = {
      makerMsg: t({
        id: 'core.commissions.maker',
        message: 'Maker',
      }),
      takerMsg: t({
        id: 'core.commissions.taker',
        message: 'Taker',
      }),
      msg: '',
    }

    const columns = [
      {
        id: 'maker',
        element: makerMsg,
      },
      {
        id: 'taker',
        element: takerMsg,
      },
    ]
    /*
      cashbackData.calculationRule !== ECashbackCalculationRule.bybitPro
        ? [
            {
              id: 'maker',
              element: makerMsg,
            },
            {
              id: 'taker',
              element: takerMsg,
            },
          ]
        : [
            {
              id: 'commissionType',
              element: msg,
            },
            {
              id: 'taker',
              element: takerMsg,
            },
            {
              id: 'maker',
              element: makerMsg,
            },
          ]
          */

    return isExchangeBinance(exchangeType)
      ? [
          {
            heading: t({
              id: 'core.accounts.spot',
              message: 'Spot',
            }),
            table: {
              content: {
                columns: columns,
                rows: [
                  {
                    maker: t({
                      id: 'commissions.spot.makerValue',
                      message: '0.1%',
                    }),
                    taker: t({
                      id: 'commissions.spot.takerValue',
                      message: '0.1%',
                    }),
                  },
                ],
              },
            },
          },
          {
            heading: t({
              id: 'core.accounts.coinm',
              message: 'Futures COIN-M',
            }),
            table: {
              content: {
                columns: columns,
                rows: [
                  {
                    maker: t({
                      id: 'commissions.coinm.makerValue',
                      message: '0.1%',
                    }),
                    taker: t({
                      id: 'commissions.coinm.takerValue',
                      message: '0.1%',
                    }),
                  },
                ],
              },
            },
          },
          {
            heading: t({
              id: 'core.accounts.usdm',
              message: 'Futures USDM',
            }),
            table: {
              content: {
                columns: columns,
                rows: [
                  {
                    maker: t({
                      id: 'commissions.usdm.makerValue',
                      message: '0.1%',
                    }),
                    taker: t({
                      id: 'commissions.usdm.takerValue',
                      message: '0.1%',
                    }),
                  },
                ],
              },
            },
          },
        ]
      : isExchangeOkx(exchangeType)
      ? [
          {
            heading: t({
              id: 'core.accounts.spot',
              message: 'Spot',
            }),
            table: {
              content: {
                columns: columns,
                rows: [
                  {
                    maker: t({
                      id: 'commissions.okx.spot.makerValue',
                      message: '0.08%',
                    }),
                    taker: t({
                      id: 'commissions.okx.spot.takerValue',
                      message: '0.1%',
                    }),
                  },
                ],
              },
            },
          },
          {
            heading: t({
              id: 'core.accounts.swap',
              message: 'Swap',
            }),
            table: {
              content: {
                columns: columns,
                rows: [
                  {
                    maker: t({
                      id: 'commissions.okx.swap.makerValue',
                      message: '0.02%',
                    }),
                    taker: t({
                      id: 'commissions.okx.swap.takerValue',
                      message: '0.05%',
                    }),
                  },
                ],
              },
            },
          },
        ]
      : [
          {
            heading: t({
              id: 'core.accounts.spot',
              message: 'Spot',
            }),
            table: {
              content: {
                columns: columns,
                rows: [
                  {
                    maker: (
                      <Trans
                        id="commissions.bybit.spot.makerValue"
                        message={'0.03%'}
                        components={{
                          colored: <span className={styles.colored}></span>,
                        }}
                      />
                    ),
                    taker: (
                      <Trans
                        id="commissions.bybit.spot.takerValue"
                        message={'0.045%'}
                        components={{
                          colored: <span className={styles.colored}></span>,
                        }}
                      />
                    ),
                  },
                ],
              },
            },
          },
          {
            heading: t({
              id: 'core.accounts.derivatives',
              message: 'Derivatives',
            }),
            table: {
              content: {
                columns: columns,
                rows: [
                  {
                    maker: (
                      <Trans
                        id="commissions.bybit.derivatives.makerValue"
                        message={'0.03%'}
                        components={{
                          colored: <span className={styles.colored}></span>,
                        }}
                      />
                    ),
                    taker: (
                      <Trans
                        id="commissions.bybit.derivatives.takerValue"
                        message={'0.045%'}
                        components={{
                          colored: <span className={styles.colored}></span>,
                        }}
                      />
                    ),
                  },
                ],
              },
            },
          },
        ]
  }, [exchangeType, cashbackData.calculationRule])

  const a = [
    {
      maker: t({
        id: 'commissions.bybit.spot.makerValueStandard',
        message: '0.10%',
      }),
      taker: t({
        id: 'commissions.bybit.spot.takerValueStandard',
        message: '0.10%',
      }),
      commissionType: t({
        id: 'commissions.bybit.spot.standardCommission',
        message: 'Обычный аккаунт Bybit',
      }),
      commissionTypeM: t({
        id: 'commissions.bybit.spot.myCommission',
        message: 'Моя комиссия',
      }),
    },
    {
      maker: (
        <Trans
          id="commissions.bybit.derivatives.makerValueDerivatives"
          message={'0.03%'}
          components={{
            colored: <span className={styles.colored}></span>,
          }}
        />
      ),
      taker: (
        <Trans
          id="commissions.bybit.derivatives.takerValueDerivatives"
          message={'0.055%'}
          components={{
            colored: <span className={styles.colored}></span>,
          }}
        />
      ),

      commissionType: t({
        id: 'commissions.bybit.spot.standardCommission',
        message: 'Обычный аккаунт Bybit',
      }),
      commissionTypeM: t({
        id: 'commissions.bybit.spot.myCommission',
        message: 'Моя комиссия',
      }),
    },
  ]

  return {
    balanceSheetData,
    commissionTableData,
    feesHeading,
    feesSubheading,
  }
}
