import { FC } from 'react'
import { t } from '@lingui/macro'
import { Link } from 'react-router-dom'
import { Button } from '@/ui/kit'
import { urls } from '@/router/urls'
import { formatNumber, useAppSelector } from '@/utils'
import s from './style.module.scss'
import { ReferralCodes } from '@/ui/organisms/ReferralCodes'
import { ReferralCode } from '@/ui/molecules/ReferralCode'
import { useBenefitsData } from '@/utils/hooks/data/useBenefitsData'

const ReferralRightBar: FC = () => {
  const { overview } = useAppSelector(state => state.referrals)
  const { userCode, profitTotal, profitMonth, referralVolumeMonth, referralVolumeTotal } = overview

  const format = (value: number) => {
    return formatNumber(value, { maximumFractionDigits: 2 })
  }

  const {
    referralIncomePercent,
    referralCashbackPercent,
    referralIncomePercentExample,
    referralCashbackPercentExample,
  } = useBenefitsData()

  return (
    <div className={s.root}>
      <div className={s.top}>
        <div>
          <div className={s.tradingHeading}>
            {t({ id: 'referralPage.totalTrading', message: 'Total Referral trading volume' })}
          </div>
          <div className={s.tradingAmount}>{format(referralVolumeTotal)} USDT</div>
          <div className={s.tradingMonth}>
            <div className={s.tradingMonthHeading}>{t({ id: 'referralPage.perMonth', message: 'Per month' })}</div>
            <div className={s.tradingMonthAmount}>
              {referralVolumeMonth > 0 && '+ '}
              {format(referralVolumeMonth)} USDT
            </div>
          </div>
        </div>
        <div>
          <div className={s.tradingHeading}>{t({ id: 'referralPage.totalProfit', message: 'Your Total profit' })}</div>
          <div className={s.tradingAmount}>{format(profitTotal)} USDT</div>
          <div className={s.tradingMonth}>
            <div className={s.tradingMonthHeading}>{t({ id: 'referralPage.perMonth', message: 'Per month' })}</div>
            <div className={s.tradingMonthAmount}>
              {profitMonth > 0 && '+ '}
              {format(profitMonth)} USDT
            </div>
          </div>
        </div>
        <div className={s.lastTopColumn}>
          <Link to={urls.howReferral} className={s.howLink}>
            <Button.Primary label={t({ id: 'referralPage.howItWorks', message: 'How it works?' })} />
          </Link>
          <div className={s.upToMsg}>
            {t({ id: 'referralPage.upToMsg-1', message: 'You get up to' })}
            <span className={s.accent}> {referralIncomePercent}% </span>
            {t({ id: 'referralPage.upToMsg-2', message: 'from users’ paid commission' })}
          </div>
        </div>
      </div>

      <div className={s.info}>
        {t({
          id: 'referralPage.example',
          message:
            'For example, you’ll receive 5% from the invitee if their commission reduction rate is 25%. If they increase it to 27%, you’ll receive 3%.',
          values: {
            masterPercent0: `${referralIncomePercent}%`,
            referralPercent0: `${referralCashbackPercent}%`,
            masterPercent1: `${referralIncomePercentExample}%`,
            referralPercent1: `${referralCashbackPercentExample}%`,
          },
        })}
      </div>

      <ReferralCode
        code={{
          code: userCode,
          bonusBinanceCashbackPercent: 0.35,
          bonusBybitCashbackPercent: 0.1,
          bonusPeriodDays: 0,
          bonusCommissionPercent: 0.25,
        }}
        disabled
      />

      <ReferralCodes />
    </div>
  )
}

export { ReferralRightBar }
