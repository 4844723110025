import { SVG } from '@/assets/svg'
import style from '../style.module.scss'
import { Trans } from '@lingui/react'
import clsx from 'clsx'
import { t } from '@lingui/macro'

const KycStatusPending = () => {
  return (
    <div className={style.container}>
      <SVG.Kyc.Pending />
      <div className={style.title}>
        {t({
          id: 'kyc.pendingTitle',
          comment: 'Verification under review',
        })}
      </div>
      <div className={style.text}>
        <Trans
          id="kyc.pendingDescription"
          components={{
            br: <br />,
            span: <span className={style.textPosition} />,
          }}
        />
      </div>
      <div className={clsx(style.text, style.colorGradient, style.pendingInfo)}>
        <Trans
          id="kyc.pendingInform"
          components={{
            br: <br />,
            span: <span className={style.textPosition} />,
          }}
        />
      </div>
    </div>
  )
}
export { KycStatusPending }
