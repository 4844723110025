export type IdentityVerificationTokenKYC = {
  token: string
}

// Basic: name and etc
// Standart: identification of passort + salefy
// Standart Plus/Advanced: address confirmation
// Advanced plus: bank reveniew confirmation
export enum KYCTier {
  Blocked = 'BLOCKED',
  Unverified = 'UNVERIFIED',
  Basic = 'BASIC',
  Standard = 'STANDARD',
  StandardPlus = 'STANDARD_PLUS',
  Advanced = 'ADVANCED',
  AdvancedPlus = 'ADVANCED_PLUS',
}
export enum KYCActivity {
  None = 'NONE',
  WorkflowInProgress = 'WORKFLOW_IN_PROGRESS',
  WorkflowError = 'WORKFLOW_ERROR',
}

export enum KYCWorkflowStatus {
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  Success = 'SUCCESS',
  Canceled = 'CANCELLED',
  Expired = 'EXPIRED',
}

export enum ProviderTypeKYC {
  Jumio = 'JUMIO',
  Sumsub = 'SUMSUB',
}

export enum KYCError {
  Blocked = 'BLOCKED',
  CountryForbidden = 'COUNTRY_FORBIDDEN',
  DataNotExtracted = 'DATA_NOT_EXTRACTED',
  DocumentExpired = 'DOCUMENT_EXPIRED',
  InvalidData = 'INVALID_DATA',
  NeedRetry = 'NEED_RETRY',
  NotPassed = 'NOT_PASSED',
  TooYoung = 'TOO_YOUNG',
}

/** UserKYC **/
export type UserKYC = {
  activity: KYCActivity
  isBlocked: boolean
  isUsaMode: boolean
  kycFormVersion: number
  kycTier: KYCTier
  nextTier: KYCTier | null
  updatedAt: string
  userId: string
}

/** User Binance KYC-Link **/
export type TUserKYCLinkStatus =
  | 'PENDING'
  | 'SUCCESS'
  | 'REJECT'
  | 'NEED_MAIN_KYC'
  | 'INIT'
  | 'KYC_AUTOMATIC_CHECK'
  | 'FINAL_REJECT'

export type UserKYCLinkStatusDTO = {
  status: TUserKYCLinkStatus | undefined
}

export type TUserKYCLinkDTO = UserKYCLinkStatusDTO & {
  url?: string
}

/** FormKYC **/
export type ResidentialFullAddress = {
  buildingName?: string
  buildingNumber?: string
  city?: string
  country: string | null
  flatNumber?: string
  lineFirst?: string
  postalCode?: string
  stateOrProvince?: string
  street?: string
}

export type UpdateFormPayloadKYC = {
  beneficialOwnerStatus: boolean
  birthDate: string
  firstName: string
  lastName: string
  contactEmail: string
  nationalities: [string | null]
  residentialAddress: string
  residentialAddressFull?: ResidentialFullAddress
}

export type ResponseFormKYC = {
  beneficialOwnerStatus: boolean
  birthDate: string
  canUpdate: boolean
  createdAt: string
  contactEmail: string
  firstName: string
  lastName: string
  id: string
  nationalities: string[]
  residentialAddress: string
  residentialAddressFull: ResidentialFullAddress
  updatedAt: string
  userId: string
  version: number
}

/** Workflow **/
export type WorkflowKYC = {
  error: KYCError
  invalidFields: string[]
  kycProviderType: ProviderTypeKYC
  kycTier: KYCTier
  startedAt: string
  status: KYCWorkflowStatus
  userId: string
  workflowId: string
}

/** Countries **/
export type CountriesKYC = {
  entries: CountryKYC[]
  total: number
}
export type CountryKYC = {
  callingCode?: string
  id: string
  iso3: string
  name: string
}

/** Limits **/
export enum KYCLimitPeriod {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR',
  Total = 'TOTAL',
  Transaction = 'TRANSACTION',
  Forbidden = 'FORBIDDEN',
}

export type LimitType = 'DEPOSIT' | 'WITHDRAW'

export type TierLimit = {
  currency: string
  period: KYCLimitPeriod
  side: LimitType
  tier: KYCTier
  value: number
}

export type KYCLimit = {
  tier: KYCTier
  depositLimits: TierLimit[]
  withdrawalLimits: TierLimit[]
}

export type KYCLimitsDTO = KYCLimit[]

export type KYCAvailableLimitsDTO = {
  tier: KYCTier
  userId: string
  depositLimit: OperationLimitKYC
  withdrawalLimit: OperationLimitKYC
}

export type OperationLimitKYC = {
  available: number
  currency: string
  limit: CurrentOperationLimitKYC
  side: LimitType
  spent: number
}

export type CurrentOperationLimitKYC = {
  currency: string
  period: KYCLimitPeriod
  tier: KYCTier
  value: number
  side: LimitType
}

export type CurrentLevelLimit = {
  deposit: OperationLimitKYC
  withdrawal: OperationLimitKYC
}
