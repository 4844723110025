/** Main */
export function objectToQueryString(payload: Record<string, unknown>) {
  const params = new URLSearchParams({})

  try {
    for (let [key, value] of Object.entries(payload)) {
      params.append(key, String(value))
    }
  } catch (e) {
    console.warn(e)
  }

  return params.toString()
}

/** TMM Integration */
type QueryParamsType = Record<string | number, any>

function encodeQueryParam(key: string, value: any) {
  const encodedKey = encodeURIComponent(key)
  return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
}

function addQueryParam(query: QueryParamsType, key: string) {
  return encodeQueryParam(key, query[key])
}

function addArrayQueryParam(query: QueryParamsType, key: string) {
  const value = query[key]
  return value.map((v: any) => encodeQueryParam(key, v)).join('&')
}

export function toQueryString(rawQuery?: QueryParamsType): string {
  const query = rawQuery || {}
  const keys = Object.keys(query).filter(key => query[key] !== undefined && query[key] !== null)

  return keys
    .map(key => {
      if (Array.isArray(query[key])) {
        return addArrayQueryParam(query, key)
      } else {
        return addQueryParam(query, key)
      }
    })
    .join('&')
}
