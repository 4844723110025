import { FC } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { LeaderItem } from './LeaderItem/LeaderItem'
import { TypeLeaderList } from '../../helpers'

type Props = {
  title: string
  leaderList: TypeLeaderList
  interval: string
}

export const LeaderList: FC<Props> = ({ title, leaderList, interval }) => {
  return (
    <div className={style.container}>
      <div className={style.titleWrapp}>
        <div className={style.title}>
          {title} {t({ id: 'leaderBoard.leaderList.leaders', comment: 'Leaders' })}
        </div>
        <div className={style.range}>{interval}</div>
      </div>

      <div className={style.content}>
        <div className={style.header}>
          <div className={style.place}>{t({ id: 'leaderBoard.leaderList.place', comment: 'place' })}</div>
          <div className={style.user}>{t({ id: 'leaderBoard.leaderList.user', comment: 'user' })}</div>
          <div className={style.result}>{t({ id: 'leaderBoard.leaderList.result', comment: 'result' })}</div>
        </div>

        {leaderList.leaders && leaderList.leaders.length < 1 && (
          <div className={style.emptyData}>
            {t({ id: 'leaderBoard.leaderList.emptyData', comment: 'There is no date yet' })}
          </div>
        )}

        {leaderList.leaders.map(leader => {
          return <LeaderItem key={leader.name} item={leader} isActive={leader.isUser} />
        })}

        {leaderList.userLeader && (
          <LeaderItem key={leaderList.userLeader.name} item={leaderList.userLeader} isActive={true} />
        )}
      </div>
    </div>
  )
}
