import { SVG } from '@/assets/svg'
import style from '../style.module.scss'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import { Button, ButtonVariant } from '@/ui/kit'
import { FC } from 'react'

type KycStatusNewUserRejectProps = {
  handleNavigateToChooseExchange: () => void
}

const KycStatusNewUserReject: FC<KycStatusNewUserRejectProps> = ({ handleNavigateToChooseExchange }) => (
  <div className={style.container}>
    <div className={style.title}>{t({ id: 'binanceLink.heading', message: 'Верификация Binance' })}</div>
    <div className={style.textRed}>
      <SVG.Status.Failed className={style.kycLinkStatusIcon} />
      <Trans
        id="binanceLink.yourAccountHasNotPassedBinaceVerification"
        message="К сожалению, ваш аккаунт не прошел верификацию Binace."
      />
    </div>
    <div className={style.text}>
      <Trans
        id="binanceLink.thisMeansTradingOnBinanceExchangeNotAvailableToYou"
        message="Это значит, что вам недоступна торговля на бирже Binance"
      />
    </div>
    <div className={style.chooseExchangeBlock}>
      <div>
        <SVG.OtherIcons.Lightning />
        <div className={style.textWhite}>
          <Trans
            id="binanceLink.tradingOnOKXAndByBitAvailableInFull"
            message="Торговля на OKX и ByBit доступна в полном объеме!"
          />
        </div>
      </div>
      <Button
        className={style.chooseExchangeButton}
        variant={ButtonVariant.AccentDark}
        onClick={handleNavigateToChooseExchange}
      >
        {t({ id: 'binanceLink.chooseExchange', message: 'Выбрать ByBit или OKX' })}
      </Button>
    </div>
  </div>
)

export { KycStatusNewUserReject }
