import clsx from 'clsx'
import style from './style.module.scss'
import { FC } from 'react'

type DiaryDayCardItemProps = {
  value?: string | number
  text?: string | React.ReactNode
  isCountPercent?: boolean
  sourceValue?: number
}

export const DiaryDayCardItem: FC<DiaryDayCardItemProps> = ({ value, text, isCountPercent, sourceValue = 0 }) => {
  let percentClassName
  if (isCountPercent) {
    percentClassName = clsx({
      [style.positive]: sourceValue > 0,
      [style.negative]: sourceValue < 0,
      [style.withoutPercentValue]: !sourceValue,
    })
  }
  return (
    <>
      <div className={style.cardItem}>
        <div className={clsx(style.value, percentClassName && { [percentClassName]: isCountPercent })}>{value}</div>
        <div className={style.text}>{text}</div>
      </div>
    </>
  )
}
