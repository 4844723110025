import { TitleH1 } from '@/ui/atoms/TitleH1'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import style from './style.module.scss'
import { AddressForm } from '../AddressForm'
import { Button } from '@/ui/kit'
import { useNavigate } from 'react-router-dom'
import { SVG } from '@/assets/svg'

export const AddressCreate = () => {
  const navigate = useNavigate()
  return (
    <div className={clsx(style.content)}>
      <div className={style.container}>
        <TitleH1
          label={t({
            id: 'createAddressPage.create.title',
            message: `Add Withdrawal Address`,
          })}
          className={style.content_title}
        />
        <Button.Primary
          onClick={() => navigate(-1)}
          className={style.arrowBackWrap}
          leftIcon={<SVG.Arrows.ArrowLeft className={style.arrow} />}
        />
      </div>
      <AddressForm />
    </div>
  )
}
