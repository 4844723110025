import { FC, memo } from 'react'
import MainTabBalance from '@/ui/molecules/MainTabBalance'
import MainTabCryptocurrency from '@/ui/molecules/MainTabCryptocurrency'
import style from './style.module.scss'

const MainTabBalanceAndCrypto: FC = () => {
  return (
    <div className={style.mainTabBalanceAndCrypto}>
      <MainTabBalance />
      <MainTabCryptocurrency />
    </div>
  )
}

export default memo(MainTabBalanceAndCrypto)
