import { Button, InteractiveModal } from '@/ui/kit'
import style from './style.module.scss'
import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'
import { FC, useEffect, useMemo, useState } from 'react'
import { BinanceLinkModal } from '@/ui/organisms/BinanceLinkModal'
import { Trans } from '@lingui/react'
import { storage } from '@/utils/lib/storage'
import { useAppSelector } from '@/utils'
import { useLocation } from 'react-router-dom'
import { urls } from '@/router/urls'

type BinanceLinkAlertProps = {
  onCloseBinanceLink: () => void
}

export const BinanceLinkAlert: FC<BinanceLinkAlertProps> = ({ onCloseBinanceLink }) => {
  const { userKycLinkState } = useAppSelector(state => state.kyc)

  const { pathname } = useLocation()

  const [isOpen, setIsOpen] = useState(false)
  const handleModalOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const isBinanceValid = userKycLinkState.status !== 'FINAL_REJECT'

  const linkAlertText = useMemo(() => {
    if (userKycLinkState.status === 'REJECT') {
      return (
        <Trans
          id="binanceLink.reject"
          message="Binance еще проверяет ваш аккаунт. Продолжайте торговать на OKX или Bybit без ограничений."
          components={{
            colorGradient: <span className={style.color} />,
          }}
        />
      )
    }

    if (isBinanceValid) {
      return (
        <Trans
          id="binanceLink.mainTitle"
          message="Пройдите дополнительную верификацию Binance Link до 20 Марта 2024, чтобы продолжать торговать. Торговля на OKX или ByBit доступна без ограничений."
          components={{
            colorGradient: <span className={style.color} />,
          }}
        />
      )
    }
    if (!isBinanceValid) {
      if (pathname === urls.api) {
        return (
          <Trans
            id="binanceLink.finalRejectApiKeysPage"
            message="Создание новых API-ключей и использование старых недоступно для вашего аккаунта. <br/> Переведите средства на OKX или ByBit, чтобы продолжить торговать."
            components={{
              br: <br />,
            }}
          />
        )
      }
      if (pathname === urls.leverage) {
        return (
          <Trans
            id="binanceLink.finalRejectleveragePage"
            message="Настройка плеч недоступно для вашего аккаунта. <br/> Переведите средства на OKX или ByBit, чтобы продолжить торговать."
            components={{
              br: <br />,
            }}
          />
        )
      }

      return (
        <Trans
          id="binanceLink.titleFinalReject"
          message="Торговля на Binance недоступна для вашего аккаунта. <br/> Переведите средства на OKX или ByBit, чтобы продолжить торговать."
          components={{
            colorGradient: <span className={style.color} />,
            br: <br />,
          }}
        />
      )
    }
  }, [pathname, userKycLinkState.status])

  useEffect(() => {
    const shouldShowBinanceLinkAlert = !storage.getShownBinanceLinkOnce()
    if (shouldShowBinanceLinkAlert) {
      setIsOpen(true)
      storage.setShownBinanceLinkOnce('true')
    }
  }, [])

  return (
    <>
      <div className={style.root}>
        <div className={style.wrapper}>
          <div className={style.title}>
            {userKycLinkState.status === 'REJECT' ? <SVG.OtherIcons.HourglassTwo /> : <SVG.OtherIcons.Warning />}
            {linkAlertText}
          </div>
          <div className={style.buttonBlock}>
            <Button.Accent
              onClick={handleModalOpen}
              label={t({
                id: 'binanceLink.showModal',
                message: 'More details',
              })}
            />
          </div>
        </div>
      </div>
      <InteractiveModal isOpen={isOpen} className={style.binanceLinkModal}>
        <BinanceLinkModal onClose={onClose} />
      </InteractiveModal>
    </>
  )
}
