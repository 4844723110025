import { Link } from 'react-router-dom'
import { HeaderUserPopup } from '@/ui/molecules/HeaderUserPopup'
import { UserAvatar } from '@/ui/atoms/UserAvatar'
import { urls } from '@/router/urls'
import style from './style.module.scss'

/** Logged In user profile */
export const HeaderUserButton = () => {
  return (
    <div className={style.root}>
      <Link to={urls.profile} className={style.wrap}>
        <UserAvatar size={36} bordered className={style.avatar} />
      </Link>

      <HeaderUserPopup className={style.popup} />
    </div>
  )
}
