import { FC, memo, useCallback, useMemo } from 'react'
import { TabBar, TabBarPropsType } from '@/ui/molecules/TabBar'
import { WalletOrderAndPositionCounter } from '@/ui/molecules/WalletOrderAndPositionCounter'
import Content from '../Content'
import { useActions, useAppSelector } from '@/utils/hooks'
import style from './style.module.scss'
import { instrumentNames } from '@/utils'
import { overviewActions } from '@/redux/overview/overview.slice'
import { TInstrumentType } from '@/core/types/overview'
import { OVERVIEW_INSTRUMENTS } from '@/core/constants/overview'

export type TabBarType = {
  id: TInstrumentType
  text: string
}

const WalletRightBar: FC = () => {
  const { instrumentType, exchangeInstruments } = useAppSelector(state => state.overview)
  const { setInstrumentType } = useActions(overviewActions)

  const tabsHandler = useCallback((tab: string) => {
    if (OVERVIEW_INSTRUMENTS.includes(tab as TInstrumentType)) setInstrumentType(tab as TInstrumentType)
  }, [])

  const tabs = useMemo<TabBarPropsType['tabs']>(
    () =>
      exchangeInstruments.map(instrument => {
        return {
          id: instrument,
          text: instrumentNames[instrument].name,
        }
      }),
    [exchangeInstruments]
  )

  return (
    <div className={style.rightBar}>
      <div className={style.top}>
        <TabBar tabs={tabs} activeTab={instrumentType} setActiveTab={tabsHandler} />
        <WalletOrderAndPositionCounter />
      </div>

      <Content />
    </div>
  )
}

export default memo(WalletRightBar)
