import { ECashbackCalculationRule } from '@/backend/api/cashbackAPI'
import { useAppSelector } from '@/utils'
import { ECashbackTradeView, useCashbackData } from '@/utils/hooks/data/useCashbackData'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useMemo } from 'react'
import { ICashbackWidgetContentProps, ICashbackWidgetItem, ICashbackWidgetProps } from '../CashbackWidget.types'
import { t } from '@lingui/macro'
import style from '../style.module.scss'
import clsx from 'clsx'
import styles from '@/ui/molecules/SystemNotifications/presets/style.module.scss'
import { Trans } from '@lingui/react'
import { useExchageType } from '@/utils/hooks/useExchangeType'

export const useCashbackWidget = (params: ICashbackWidgetProps): ICashbackWidgetContentProps => {
  const { customContent: customTexts } = params
  const { cashback } = useAppSelector(state => state.cashback)
  const cashbackData = useCashbackData()

  const { exchangeType } = useExchageType()

  const comissionInfoProps = useMemo<ICashbackWidgetContentProps>(() => {
    if (customTexts) return customTexts

    const {
      cashbackLeftToNextRoundedFormatted,
      cashbackNextPercentFormatted,
      cashbackTopLimitFormatted,
      cashbackView,
      cashbackTodayValue,
      cashbackTodayValueRoundedFormatted,
      cashbackPercentToday,
      cashbackPercentTodayFormatted,
      allocationMaxFormatted,
    } = cashbackData

    let heading = {
      text: (
        <>
          {t({
            id: 'comissionInfo.reduction',
            message: 'Current reduction rate',
          })}
          &nbsp;
          {cashbackData.cashbackPercentTodayFormatted}
        </>
      ),
    }

    let volumeItem: ICashbackWidgetItem = {
      heading: {
        text: t({
          id: `comissionInfo.trading.title`,
          message: 'Trading',
        }),
      },
      text: <></>,
    }

    if (exchangeType === 'BINANCE_VIP') {
      return {
        theme: 'single',
        heading: {
          text: t({
            id: 'reducedCommission.title',
            message: 'Текущая комиссия',
          }),
        },
        items: [
          {
            text: (
              <span className={style.description}>
                <Trans
                  id="reducedCommission.descriptionBinanceVip"
                  message="Trading commission for you is reduced to <colored>0.02%</colored> (Taker) and <colored>0.002%</colored> (Maker) on futures and to <colored>0.03%</colored> (Taker) and <colored>0.018%</colored> (Maker) on spot."
                  components={{
                    colored: <span className={styles.colored}></span>,
                  }}
                />
              </span>
            ),
          },
        ],
      }
    }

    if (cashback.calculationRule === ECashbackCalculationRule.allocationBalance) {
      return {
        theme: 'single',
        heading: {
          text:
            cashbackTodayValue === 0 ? (
              <span className={style._gray}>
                {t({
                  id: 'todayCashback.title',
                  message: 'Commission reduced',
                })}
              </span>
            ) : (
              <>
                <span className={style._gray}>{t({ message: 'saved', id: 'core.saved' })}</span>{' '}
                <span className={style.value}>{cashbackTodayValueRoundedFormatted}</span>
              </>
            ),
        },
        items: [
          {
            text:
              cashbackPercentToday !== 0 ? (
                <span className={style.description}>
                  {t({
                    id: 'todayCashback.reduced.msg-1',
                    message: 'Your trading commissions have been reduced',
                  })}
                  &nbsp;
                  {t({
                    id: 'todayCashback.reduced.msg-2',
                    message: 'by',
                  })}
                  &nbsp;
                  <strong className={style._white}>{cashbackPercentTodayFormatted}</strong>
                </span>
              ) : (
                <span className={style.descriptionEmpty}>
                  {t({
                    id: 'todayCashback.allocate.msg-1',
                    message: 'Allocate funds for TIGR token purchase and get',
                  })}
                  &nbsp;
                  {t({
                    id: 'todayCashback.allocate.msg-2',
                    message: 'a',
                  })}
                  &nbsp;
                  <strong>{allocationMaxFormatted}</strong>{' '}
                  {t({
                    id: 'todayCashback.allocate.msg-3',
                    message: 'reduction on trading commissions',
                  })}
                </span>
              ),
          },
        ],
      }
    }

    if (cashback.calculationRule === ECashbackCalculationRule.priorityReferralCode) {
      return {
        theme: 'single',
        heading,
        items: [
          {
            text: (
              <span className={style.description}>
                {t({
                  id: 'todayCashback.reduced.msg-1',
                  message: 'Your trading commissions have been reduced',
                })}
                &nbsp;
                {t({
                  id: 'todayCashback.reduced.msg-2',
                  message: 'by',
                })}
                &nbsp;
                <strong className={style._white}>{cashbackPercentTodayFormatted}</strong>
                &nbsp;
                {t({
                  id: 'todayCashback.reduced.dueToRefcode',
                  message: 'due to your referral code',
                })}
                &nbsp;
              </span>
            ),
            dataTestId: DataTestIds.AccountInfoCashbackExpirationDate,
          },
        ],
      }
    }

    if (cashback.calculationRule === ECashbackCalculationRule.customReferralCode) {
      return {
        theme: 'single',
        heading,
        items: [
          {
            text: (
              <>
                {t({
                  id: 'comissionInfo.validUntil',
                  message: 'Your referral code is valid until',
                })}{' '}
                <strong className={clsx(style._white, style._nowrap)}>{cashbackData.expirationDateFormatted}</strong>
              </>
            ),
            dataTestId: DataTestIds.AccountInfoCashbackExpirationDate,
          },
        ],
      }
    }

    if (cashback.calculationRule === ECashbackCalculationRule.promocode) {
      return {
        theme: 'single',
        heading: {
          text: t({
            id: 'todayCashback.title',
            message: 'Commission reduced',
          }),
        },
        items: [
          {
            text: (
              <span className={style.description}>
                {t({
                  id: 'todayCashback.reduced.msg-1',
                  message: 'Your trading commissions have been reduced',
                })}
                &nbsp;
                {t({
                  id: 'todayCashback.reduced.msg-2',
                  message: 'by',
                })}
                &nbsp;
                <strong className={style._white}>{cashbackPercentTodayFormatted}</strong>
              </span>
            ),
          },
        ],
      }
    }

    if (cashback.calculationRule === ECashbackCalculationRule.bybitPro) {
      return {
        theme: 'single',
        heading: {
          text: t({
            id: 'reducedCommission.title',
            message: 'Текущая комиссия',
          }),
        },
        items: [
          {
            text: (
              <span className={style.description}>
                <Trans
                  id="reducedCommission.description"
                  message={'Торговая комиссия для вас снижена до 0.03%/0.00% на фьючерсах и до 0.04%/0.02% на споте.'}
                  components={{
                    colored: <span className={styles.colored}></span>,
                  }}
                />
              </span>
            ),
          },
        ],
      }
    }

    return {
      theme: 'single',
      heading,
      items: [
        {
          text: (
            <>
              {t({
                id: 'comissionInfo.trading.default.message-1',
                message: 'Your commission reduction level is',
              })}{' '}
              {cashbackPercentTodayFormatted}.
            </>
          ),
        },
      ],
    }

    if (
      cashbackView === ECashbackTradeView.NEVER_TRADE ||
      cashbackView === ECashbackTradeView.CASHBACK_PERCENT_NULL ||
      cashbackView === ECashbackTradeView.CASHBACK_PERCENT_POSITIVE_MINOR
    )
      volumeItem.text = (
        <>
          {t({
            id: 'comissionInfo.trading.minor.message-1',
            message: 'Increase your trading volume by',
          })}{' '}
          {cashbackLeftToNextRoundedFormatted}{' '}
          {t({
            id: 'comissionInfo.trading.minor.message-2',
            message: 'to save',
          })}{' '}
          <strong data-testid={DataTestIds.AccountInfoCashbackNextPercent}>{cashbackNextPercentFormatted}</strong>{' '}
          {t({
            id: 'comissionInfo.trading.minor.message-3',
            message: 'from trading commissions',
          })}
        </>
      )
    else if (cashbackView === ECashbackTradeView.CASHBACK_PERCENT_POSITIVE_MAJOR)
      volumeItem.text = (
        <>
          {t({
            id: 'comissionInfo.trading.major.message-1',
            message: 'Just',
          })}{' '}
          {cashbackLeftToNextRoundedFormatted}{' '}
          {t({
            id: 'comissionInfo.trading.major.message-2',
            message: 'left to trade today to save',
          })}{' '}
          <strong data-testid={DataTestIds.AccountInfoCashbackNextPercent}>{cashbackNextPercentFormatted}</strong>{' '}
          {t({
            id: 'comissionInfo.trading.major.message-3',
            message: 'from trading commissions',
          })}
        </>
      )
    else if (cashbackView === ECashbackTradeView.CASHBACK_PERCENT_MAX)
      volumeItem.text = (
        <>
          {t({
            id: 'comissionInfo.trading.percentMax.message-1',
            message:
              'You’ve reached the maximum commission reduction level. To sustain this rate, your trading volume today must be at least',
          })}{' '}
          <strong data-testid={DataTestIds.AccountInfoCashbackLeftToNext}>{cashbackLeftToNextRoundedFormatted}</strong>
        </>
      )
    else if (cashbackView === ECashbackTradeView.CASHBACK_VOLUME_MAX)
      volumeItem.text = (
        <>
          {t({
            id: 'comissionInfo.trading.volumeMax.message-1',
            message: 'Keep your trading volume above',
          })}{' '}
          {cashbackTopLimitFormatted}{' '}
          {t({
            id: 'comissionInfo.trading.volumeMax.message-2',
            message: 'per month to sustain this rate',
          })}
        </>
      )

    return {
      theme: 'single',
      heading,
      items: [{ text: volumeItem.text }],
    }
  }, [customTexts, cashbackData, cashback])

  return comissionInfoProps
}
