import {
  E_RISK_MANAGER_DRAWDOWN_TYPE,
  E_RISK_MANAGER_TYPE,
  TRiskManagerSettings,
  TRiskManagerTradingStatus,
} from './riskManager.types'

export const RISK_MANAGER_DATA_DEFAULT: TRiskManagerSettings = {
  drawdownType: E_RISK_MANAGER_DRAWDOWN_TYPE.percent,
  drawdownValue: 0,
  canEdit: false,
  enabled: false,
  permissions: [],
  type: E_RISK_MANAGER_TYPE.internal,
}

export const TRADING_STATUS_DEFAULT: TRiskManagerTradingStatus = {
  tradingEnabled: true,
  canDisableTrading: false,
  canDisableVoluntaryBlock: false,
  until: '',
}
