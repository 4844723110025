import { DiaryWeekResultItem } from '@/ui/molecules'
import style from './style.module.scss'
import { DayDiary } from '@/utils/types/contracts.types'
import { FC } from 'react'
import {
  DEFAULT_LEVERAGE_VALUE,
  DEFAULT_VALUE_WITH_SYMBOL,
  handleRound,
} from '@/ui/organisms/DiaryRightBar/components/WeekCard/WeekCard'
import { Trans } from '@lingui/react'
import clsx from 'clsx'
import BigNumber from 'bignumber.js'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { calculatePercentage, getPercentage, getRatio } from './utils'
import { WeekExchangeFilter } from '../WeekExchangeFilter/WeekExchangeFilter'
import { IconWithHint } from '../IconWithHint/IconWithHint'

type DiaryHeaderResultProps = {
  weekData: DayDiary
  firstDayOfWeek: string
  lastDayOfWeek: string
  nextDataHandler: () => void
  prevDataHandler: () => void
  closeHandler: () => void
  currentDateOrBig: boolean
  nextIconHintText?: string
  prevIconHintText?: string
  closeIconHintText?: string
  disablePrevSwitch?: boolean
  title: string
}

export const DiaryHeaderResult: FC<DiaryHeaderResultProps> = ({
  weekData,
  firstDayOfWeek,
  lastDayOfWeek,
  nextDataHandler,
  prevDataHandler,
  currentDateOrBig,
  closeHandler,
  nextIconHintText,
  prevIconHintText,
  closeIconHintText,
  disablePrevSwitch,
  title,
}) => {
  const formattedFirstDayOfWeek = formatDate(firstDayOfWeek, DATE_FORMAT.DAY_AND_YEAR)
  const formattedLastDayOfWeek = formatDate(lastDayOfWeek, DATE_FORMAT.DAY_AND_YEAR)
  const percent = Number(weekData.net_profit)
  const percentClassName = clsx({
    [style.positive]: percent > 0,
    [style.negative]: percent < 0,
    [style.without]: !percent,
  })

  return (
    <div className={style.weekResultBlock}>
      <div className={style.weekResultContainer}>
        <div className={style.headerWeek}>
          <div className={clsx(style.weekBorder, percentClassName)}></div>
          <div className={style.weekResult}>{title}</div>
          <div className={style.week}>{`${formattedFirstDayOfWeek} - ${formattedLastDayOfWeek}`}</div>
        </div>
        <div className={style.btnBlock}>
          <div className={style.switchBlock}>
            <div className={style.filter}>
              <WeekExchangeFilter />
            </div>
            {prevIconHintText ? (
              <IconWithHint
                clickHandler={prevDataHandler}
                className={clsx(style.arrowLeft, {
                  [style.btnDisabled]: disablePrevSwitch,
                })}
                arrowComponent={<SVG.Arrows.ArrowLeft className={style.arrow} />}
                hintClassName={style.leftArrowHint}
                hintText={prevIconHintText}
              />
            ) : (
              <div
                onClick={prevDataHandler}
                className={clsx(style.arrowLeft, {
                  [style.btnDisabled]: disablePrevSwitch,
                })}
              >
                <SVG.Arrows.ArrowLeft className={style.arrow} />
              </div>
            )}
            {nextIconHintText ? (
              <IconWithHint
                clickHandler={nextDataHandler}
                className={clsx(style.arrowRight, {
                  [style.btnDisabled]: currentDateOrBig,
                })}
                arrowComponent={<SVG.Arrows.ArrowRight className={style.arrow} />}
                hintClassName={style.rightArrowHint}
                hintText={nextIconHintText}
              />
            ) : (
              <div
                onClick={nextDataHandler}
                className={clsx(style.arrowRight, {
                  [style.btnDisabled]: currentDateOrBig,
                })}
              >
                <SVG.Arrows.ArrowRight className={style.arrow} />
              </div>
            )}
            {closeIconHintText ? (
              <IconWithHint
                clickHandler={closeHandler}
                className={style.close}
                arrowComponent={<SVG.Additional.Close width="11px" height="11px" />}
                hintClassName={style.closeHint}
                hintText={closeIconHintText}
              />
            ) : (
              <div onClick={closeHandler} className={style.close}>
                <SVG.Additional.Close width="11px" height="11px" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={style.weekData}>
        <DiaryWeekResultItem
          value={getPercentage(weekData.win_count, weekData.count)}
          text={t({
            id: 'trades.summary.weekWin',
          })}
        />
        <DiaryWeekResultItem
          isCountPercent
          sourceValue={Number(weekData.net_profit)}
          value={weekData.net_profit ? handleRound(weekData.net_profit) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
          text={
            <Trans
              id="trades.summary.weekProfit"
              components={{
                br: <br />,
              }}
              values={{
                value: weekData.volume ? `${handleRound(weekData.volume)} $` : DEFAULT_VALUE_WITH_SYMBOL,
              }}
            />
          }
        />
        <DiaryWeekResultItem
          value={getRatio(weekData.avg_win, weekData.avg_loss)}
          text={
            <Trans
              id="trades.summary.weekProfitRatio"
              components={{
                br: <br />,
              }}
              values={{
                profit: Number(weekData.avg_win) ? `${handleRound(weekData.avg_win)} $` : DEFAULT_VALUE_WITH_SYMBOL,
                lesion: Number(weekData.avg_loss) ? `${handleRound(weekData.avg_loss)} $` : DEFAULT_VALUE_WITH_SYMBOL,
              }}
            />
          }
        />
        <DiaryWeekResultItem
          value={getPercentage(weekData.long_count, weekData.count)}
          text={
            <Trans
              id="trades.summary.weekResultShort"
              values={{
                value: calculatePercentage(weekData.long_count, weekData.count),
              }}
            />
          }
        />
        <DiaryWeekResultItem
          value={weekData.leverage ? 'x' + new BigNumber(weekData.leverage).toFixed(2) : DEFAULT_LEVERAGE_VALUE}
          text={t({
            id: 'trades.summary.weekLeverage',
          })}
        />
        <DiaryWeekResultItem
          value={weekData.commission ? handleRound(weekData.commission) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
          text={t({
            id: 'trades.summary.weekCommission',
          })}
        />
      </div>
    </div>
  )
}
