import { TradingPlatforms } from '@/redux/trades/trades.types'
import { t } from '@lingui/macro'
import { FC } from 'react'
import style from './style.module.scss'

interface LegendNameProps {
  rawLegendName: string
  legendName: string
}

export const LegendName: FC<LegendNameProps> = ({ legendName, rawLegendName }) => {
  switch (rawLegendName) {
    case TradingPlatforms.BINANCE_VIP_FUTURE:
      return (
        <>
          <span className={style.white}>{t({ id: 'trades.dashboard.vip', comment: 'vip' })}</span>&nbsp;
          {t({ id: 'trades.dashboard.future', comment: 'future' })}
        </>
      )

    case TradingPlatforms.BINANCE_VIP_SPOT:
      return (
        <>
          <span className={style.white}>{t({ id: 'trades.dashboard.vip', comment: 'vip' })}</span>&nbsp;
          {t({ id: 'trades.dashboard.spot', comment: 'spot' })}
        </>
      )

    case TradingPlatforms.BINANCE_FUTURE:
    case TradingPlatforms.BYBIT_LINEAR:
    case TradingPlatforms.OKX_SWAP:
      return <>{t({ id: 'trades.dashboard.future', comment: 'spot' })}</>

    case TradingPlatforms.OKX_SPOT:
    case TradingPlatforms.BINANCE_SPOT:
    case TradingPlatforms.BYBIT_SPOT:
      return <>{t({ id: 'trades.dashboard.spot', comment: 'spot' })}</>

    default: {
      return <>{legendName}</>
    }
  }
}
