import { handleAsyncServerAppError, handleAsyncServerNetworkError, ThunkAPIType } from './AppStatusHandlers'
import { AxiosResponse } from 'axios'
import { appActions } from '@/redux/app/app.slice'
import { HTTP_STATUS_RANGE } from '@/core/constants/common'
import { tradesActions } from '@/redux/trades/trades.slice'

type ApiMethod = (param: any) => any

type ThunkCreatorType = {
  payload?: any
  apiMethod: ApiMethod | ApiMethod[]
  status?: number
  errorCallback?: () => void
}

const statusesInRange = (statuses: number[], range: number) => {
  return statuses.every(status => {
    return status >= range && status < range + HTTP_STATUS_RANGE
  })
}

export const ThunkCreator = async <ResponseType = any>(
  creator: ThunkCreatorType,
  thunkAPI: ThunkAPIType
): Promise<any> => {
  creator.status = creator.status ? creator.status : 200

  try {
    if (Array.isArray(creator.apiMethod)) {
      return await Promise.all(creator.apiMethod.map((method, index) => method(creator.payload[index])))
        .then((payload: AxiosResponse<ResponseType>[]) => {
          return payload.map(p => p.data)
        })
        .catch(errors => {
          creator.errorCallback && creator.errorCallback()
          return handleAsyncServerAppError(errors, thunkAPI)
        })
    } else {
      const res: AxiosResponse<ResponseType> = await creator.apiMethod(creator.payload)
      if (res.status === creator.status || statusesInRange([res.status, creator.status], 200)) {
        return res.data
      } else {
        creator.errorCallback && creator.errorCallback()
        // @ts-ignore
        return handleAsyncServerAppError(res.data, thunkAPI)
      }
    }
  } catch (error: any) {
    if (error?.response?.status === 401) {
      try {
        const token = (error.config.headers['Authorization'] || '')?.replace('Bearer ', '')
        const exp = JSON.parse(atob(token?.split('.')?.[1]))?.exp

        if (exp && new Date(exp * 1000) < new Date()) {
          thunkAPI.dispatch(appActions.setSetup(false))
        }
      } catch (e) {}
    }
    if (error?.response?.status === 429) {
      try {
        const limit = error.response.headers['x-ratelimit-reset']
        thunkAPI.dispatch(tradesActions.setRequestTradesLimit(limit))
      } catch (e) {}
    }

    creator.errorCallback && creator.errorCallback()
    return handleAsyncServerNetworkError(error, thunkAPI)
  }
}
