import { type ViewportSize, getViewportSize } from './helpers/getViewportSize'

type SubscribeFunction = (viewportSize: ViewportSize) => void

const subscribers = new Set<SubscribeFunction>()

function onResize() {
  const newSize = getViewportSize()
  subscribers.forEach(callback => callback(newSize))
}

export function subscribeToViewprotSize(callback: SubscribeFunction): () => void {
  if (subscribers.size === 0) {
    if (!visualViewport) {
      window.addEventListener('resize', onResize)
    } else {
      visualViewport.addEventListener('resize', onResize)
    }
  }

  subscribers.add(callback)

  return () => {
    subscribers.delete(callback)

    if (subscribers.size === 0) {
      if (!visualViewport) {
        window.removeEventListener('resize', onResize)
      } else {
        visualViewport.removeEventListener('resize', onResize)
      }
    }
  }
}
