import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, memo } from 'react'
import { TExchangesAvailable } from '@/core/constants'
import style from './style.module.scss'
import { useAppSelector } from '@/utils'

interface Props {
  indicator: number
  smallSize?: boolean
}

type IndicationType = 'default' | 'warning' | 'danger'

const IndicationLines: Record<TExchangesAvailable, { min: number; max: number }> = {
  BINANCE_BROKER_FUTURE: { min: 0, max: 4 },
  OKX_BROKER: { min: 0, max: 5 },
  BYBIT_BROKER: { min: 0, max: 5 },
  BINANCE_VIP: { min: 0, max: 4 },
  OKX_AFFILIATE: { min: 0, max: 5 },
}

const ADL: FC<Props> = memo(({ indicator, smallSize = false }) => {
  const { exchangeType } = useAppSelector(state => state.overview)
  const { min, max } = IndicationLines[exchangeType] || { min: 0, max: 4 }
  const linesCount = max - min

  if (smallSize) {
    return (
      <div className={style.adl}>
        <a
          className={clsx(style.linesWrapper, style.small)}
          href={getAdlLinkByExchangeType(exchangeType)}
          target="_blank"
          rel="noreferrer"
        >
          {Array.from({ length: linesCount }, (_, i) => {
            const type = getIdicationLineType(i, linesCount)
            const isActive = i + 1 <= indicator - min
            return <div key={i} className={clsx(style.line, isActive && style.active, style[type])} />
          })}
        </a>
      </div>
    )
  }

  return (
    <div className={style.adl}>
      <div className={style.linesWrapper}>
        {Array.from({ length: linesCount }, (_, i) => {
          const type = getIdicationLineType(i, linesCount)
          const isActive = i + 1 <= indicator - min
          return <div key={i} className={clsx(style.line, isActive && style.active, style[type])} />
        })}
      </div>

      <a className={style.link} target="_blank" rel="noreferrer" href={getAdlLinkByExchangeType(exchangeType)}>
        {t({ id: 'core.adl', comment: 'ADL' })}
      </a>
    </div>
  )
})

const getAdlLinkByExchangeType = (exchangeType: TExchangesAvailable) => {
  const links: Record<TExchangesAvailable, string> = {
    BINANCE_BROKER_FUTURE: t({
      id: 'adl.docs.binance',
      comment: 'https://www.binance.com/en/support/faq/what-is-auto-deleveraging-adl-and-how-does-it-work-360033525471',
    }),
    OKX_BROKER: t({
      id: 'adl.docs.okx',
      comment: 'https://www.okx.com/help/iv-introduction-to-auto-deleveraging-adl?ysclid=lsxlk4fvx5762872164',
    }),
    BYBIT_BROKER: t({
      id: 'adl.docs.bybit',
      comment: 'https://www.bybit.com/en/help-center/article/What-is-Auto-Deleveraging-ADL',
    }),
    BINANCE_VIP: t({
      id: 'adl.docs.binance',
      comment: 'https://www.binance.com/en/support/faq/what-is-auto-deleveraging-adl-and-how-does-it-work-360033525471',
    }),
    OKX_AFFILIATE: t({
      id: 'adl.docs.okx',
      comment: 'https://www.okx.com/help/iv-introduction-to-auto-deleveraging-adl?ysclid=lsxlk4fvx5762872164',
    }),
  }

  return links[exchangeType] ?? ''
}

function getIdicationLineType(positionIndex: number, linesCount: number): IndicationType {
  if (positionIndex >= linesCount - 1) {
    return 'danger'
  }

  if (positionIndex >= linesCount - 2) {
    return 'warning'
  }

  return 'default'
}

export default ADL
