import { AxiosError } from 'axios'
import { Dispatch } from 'redux'
import { ResponseType } from '@/types'

export type ThunkAPIType = {
  dispatch: Dispatch
  rejectWithValue: Function
}

export const handleAsyncServerAppError = <D>(data: ResponseType<D>, thunkAPI: ThunkAPIType) => {
  return thunkAPI.rejectWithValue({
    codes: [data.ResultCode],
    errors: [data.message],
    fieldsErrors: undefined,
  })
}

export const handleAsyncServerNetworkError = (error: AxiosError<Record<string, any>>, thunkAPI: ThunkAPIType) => {
  return thunkAPI.rejectWithValue({
    codes: [error.request?.status || error.response?.data?.code || error.code],
    errors: [error.response?.data?.message || error.message],
    fieldsErrors: error.response?.data?.details,
  })
}
