import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { appActions } from '@/redux/app/app.slice'
import { SYSTEM_ALERT_API_FILTER } from '@/core/constants/common'
import { getTradeSuspendedAlert } from '../presets/getTradeSuspendedAlert'
import { normalizeNotifications } from './utils'
import { getBinanceTechnicalWorkAlert } from '../presets/getBinanceTechnicalWorkAlert'
import { useActions, useAppSelector, useMobileSizeDetect } from '@/utils'
import { PUBLIC_ROUTES, urls } from '@/router/urls'
import { getCashbackDelayAlert } from '../presets/getCashbackDelayAlert'
import { getOkxSuspensionAlert } from '../presets/getOkxSuspensionAlert'
import { getOKXAffiliateCashbackAlert } from '../presets/getOKXAffiliateCashbackAlert'
import { getTechWorkBybitAlert } from '../presets/getTechWorkBybitAlert'

export function useSystemNotifications() {
  const [isMobile] = useMobileSizeDetect()
  const { userStatus } = useAppSelector(state => state.okxAffiliate)
  const { closedNotifications, exchangeType } = useAppSelector(state => state.app)
  const {
    metadata: { firstSesstionTrackedTimestamp },
  } = useAppSelector(state => state.profile)
  const { patchClosedNotifications } = useActions(appActions)

  const { tradingStatus } = useAppSelector(state => state.team)
  const { pathname } = useLocation()

  const isApiKeyEnabled = userStatus?.apiKey && userStatus?.apiKey.status === 'ENABLED'
  const isShowOKXAlert = isApiKeyEnabled || pathname !== urls.okxAffiliate ? false : true

  /** OKX Suspension */
  const okxSuspensionAlert = useMemo(() => {
    return getOkxSuspensionAlert({ isMobile, firstSesstionTrackedTimestamp })
  }, [isMobile])

  /** Binance Technical Work */
  const binanceTechnicalWorkBadge = useMemo(() => {
    return getBinanceTechnicalWorkAlert({ isMobile })
  }, [isMobile])

  /** Trade Suspended Alert */
  const tradeSuspendedAlert = useMemo(() => {
    return getTradeSuspendedAlert({ pathname, tradingStatus })
  }, [pathname, tradingStatus])

  const cashbackDelayedAlert = useMemo(() => {
    return getCashbackDelayAlert({ isMobile, firstSesstionTrackedTimestamp })
  }, [])

  const techWorkBybitAlert = useMemo(() => {
    return getTechWorkBybitAlert({ isMobile, firstSesstionTrackedTimestamp })
  }, [])

  const okxAffiliateDelayedAlert = useMemo(() => {
    return getOKXAffiliateCashbackAlert({ isMobile, isShowOKXAlert })
  }, [isShowOKXAlert])

  useEffect(() => {
    if (SYSTEM_ALERT_API_FILTER.includes(pathname)) {
      patchClosedNotifications({ [tradeSuspendedAlert.id]: false })
    }
  }, [pathname])

  if (PUBLIC_ROUTES.some(route => pathname.startsWith(route))) {
    return []
  }

  return normalizeNotifications({
    currentExchange: exchangeType,
    closedNotifications: closedNotifications,
    patchAction: patchClosedNotifications,
    notifications: [
      binanceTechnicalWorkBadge,
      okxSuspensionAlert,
      tradeSuspendedAlert,
      cashbackDelayedAlert,
      okxAffiliateDelayedAlert,
      techWorkBybitAlert,
    ],
  })
}
