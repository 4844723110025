import { Hint, usePopperRef } from '@/ui/kit'
import { Portal } from '@/ui/utils/Portal'
import { useFlag } from '@/utils'
import { FC, ReactNode } from 'react'

type IconWithHintProps = {
  clickHandler: () => void
  className?: string
  arrowComponent: ReactNode
  hintText: string
  hintClassName: string
}
export const IconWithHint: FC<IconWithHintProps> = ({
  clickHandler,
  className,
  arrowComponent,
  hintText,

  hintClassName,
}) => {
  const [popperRef, setPopperRef] = usePopperRef() // Example hook for popper reference
  const [isVisibleHint, , enableHint, disableHint] = useFlag() // Example hook for visibility flag

  return (
    <div
      onClick={clickHandler}
      className={className}
      ref={setPopperRef}
      onMouseOver={enableHint}
      onMouseOut={disableHint}
    >
      {arrowComponent}
      {hintText && isVisibleHint && (
        <Portal>
          <Hint targetRef={popperRef} placement={'bottom'} text={hintText} className={hintClassName} />
        </Portal>
      )}
    </div>
  )
}
