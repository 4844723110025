import { Button, Input, InputSize, InteractiveModalParts } from '@/ui/kit'
import { t } from '@lingui/macro'
import { Controller, useForm } from 'react-hook-form'
import styles from '../style.module.scss'
import { FC, useEffect, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import { VALIDATION_SCHEMA } from '../TeamForm.Utils/validation/cashback.schema'
import { useActions, useAppSelector } from '@/utils'
import { Loader } from '@/ui/kit/Loader'
import { useErrorsParser } from '@/utils/hooks/useErrorsParser'
import { getMemberCashbackError } from '@/backend/services/team'
import { teamActions } from '@/redux/team/team.slice'
import clsx from 'clsx'

type ITeamFormCashbackProps = {
  userId: string
  label: string
  onClose: () => void
}

type OnsubmitData = {
  okx: number
  binance: number
  bybit: number
  label: string
}

type CashbackUpdateForm = OnsubmitData & {
  globalError: any
}

export const TeamFormCashback: FC<ITeamFormCashbackProps> = props => {
  const { onClose, userId, label: initialLabelValue } = props
  const { ChangeMemberCashbackTC, GetMemberCashbackTC, setErrors, setChangeCashbackRequestReset } =
    useActions(teamActions)
  const {
    teamManagerOwnCashback: { binanceCashbackPercent, okxCashbackPercent, bybitCashbackPercent },
    teamManagerOwnCashbackStatus,
    changeCashbackRequestStatus,
    errors: cashbackError,
  } = useAppSelector(state => state.team)

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<CashbackUpdateForm>({
    defaultValues: {
      okx: 0,
      binance: 0,
      bybit: 0,
      label: '',
    },
    resolver: yupResolver(VALIDATION_SCHEMA()),
  })
  const getCashbackErrors = getMemberCashbackError()
  useErrorsParser(cashbackError, setError, getCashbackErrors)
  const fieldsErrors = !!Object.keys(errors).filter(key => key !== 'globalError').length

  const memoizedValues = useMemo(
    () => ({
      okxCashbackPercent,
      binanceCashbackPercent,
      bybitCashbackPercent,
      initialLabelValue,
    }),
    [okxCashbackPercent, binanceCashbackPercent, bybitCashbackPercent, initialLabelValue]
  )

  useEffect(() => {
    if (userId) {
      GetMemberCashbackTC(userId)
    }
  }, [userId, GetMemberCashbackTC])

  useEffect(() => {
    if (userId && Object.values(memoizedValues).some(value => value !== undefined)) {
      setValue('okx', memoizedValues.okxCashbackPercent)
      setValue('binance', memoizedValues.binanceCashbackPercent)
      setValue('bybit', memoizedValues.bybitCashbackPercent)
      setValue('label', memoizedValues.initialLabelValue)
    }
  }, [userId, setValue, memoizedValues])

  const globalErrorMessage = errors?.globalError?.message

  const onSubmit = ({ okx, binance, bybit, label }: OnsubmitData) => {
    ChangeMemberCashbackTC({
      userId,
      okxCashbackPercent: okx,
      binanceCashbackPercent: binance,
      bybitCashbackPercent: bybit,
      label,
    })
  }

  useEffect(() => {
    return () => {
      setErrors()
      setChangeCashbackRequestReset()
    }
  }, [])

  useEffect(() => {
    if (changeCashbackRequestStatus === 'succeeded') {
      onClose()
    }
  }, [changeCashbackRequestStatus, onClose])

  if (teamManagerOwnCashbackStatus === 'loading') return <Loader />

  return (
    <form className={styles.formCashback}>
      <Controller
        control={control}
        name="label"
        render={({ field }) => (
          <Input
            size={InputSize.Small}
            placeholder={t({
              id: 'teamMembers.placeholder.label',
              message: 'label',
            })}
            value={field.value}
            setValue={value => {
              field.onChange(value)
            }}
            inputAttrs={{ type: 'string', maxLength: 120 }}
            errorMessage={errors.label?.message}
            label={t({
              id: 'teamMembers.label',
              message: 'label',
            })}
          />
        )}
      />
      <Input
        value={userId}
        setValue={() => {}}
        disabled
        size={InputSize.Small}
        placeholder={t({
          id: 'teamMembers.Cashback.userId.placeholder',
          message: 'userId',
        })}
        inputAttrs={{ className: styles.inputEllipsis }}
        label={t({
          id: 'teamMembers.Cashback.userId.label',
          message: 'userId',
        })}
      />
      <div className={styles.cashbackInputContainer}>
        <Controller
          control={control}
          name="binance"
          render={({ field }) => (
            <Input
              size={InputSize.Small}
              containerClassName={styles.cashbackInput}
              placeholder={t({
                id: 'teamMembers.Cashback.Binance.placeholder',
                message: 'percent',
              })}
              value={field.value}
              setValue={value => {
                field.onChange(value)
              }}
              inputAttrs={{ type: 'number' }}
              label={t({
                id: 'teamMembers.Cashback.Binance.label',
                message: 'Binance',
              })}
            />
          )}
        />
        <Controller
          control={control}
          name="bybit"
          render={({ field }) => (
            <Input
              size={InputSize.Small}
              containerClassName={styles.cashbackInput}
              placeholder={t({
                id: 'teamMembers.Cashback.Bybit.placeholder',
                message: 'bybit',
              })}
              value={field.value}
              setValue={value => {
                field.onChange(value)
              }}
              inputAttrs={{ type: 'number' }}
              label={t({
                id: 'teamMembers.Cashback.Bybit.label',
                message: 'Bybit',
              })}
            />
          )}
        />
        <Controller
          control={control}
          name="okx"
          render={({ field }) => (
            <Input
              size={InputSize.Small}
              containerClassName={styles.cashbackInput}
              placeholder={t({
                id: 'teamMembers.Cashback.OKX.placeholder',
                message: 'Okx',
              })}
              value={field.value}
              setValue={value => {
                field.onChange(value)
              }}
              inputAttrs={{ type: 'number' }}
              label={t({
                id: 'teamMembers.Cashback.OKX.label',
                message: 'OKX',
              })}
            />
          )}
        />
      </div>
      {fieldsErrors && (
        <div className={styles.errorMessage}>
          <div>{errors.binance?.message}</div>
          <div>{errors.bybit?.message}</div>
          <div>{errors.okx?.message}</div>
        </div>
      )}
      {globalErrorMessage && <div className={styles.globalErrorCashback}>{globalErrorMessage}</div>}
      <div className={styles.btnWrapper}>
        <InteractiveModalParts.Button
          text={t({ id: 'teamMembers.submit', message: 'Save' })}
          variant={Button.Variant.White}
          onClick={handleSubmit(onSubmit)}
          className={clsx({
            [styles.btnSubmitWithMargin]: fieldsErrors && !globalErrorMessage,
            [styles.btnSubmit]: !fieldsErrors || globalErrorMessage,
          })}
        />
        <InteractiveModalParts.Button
          text={t({ id: 'teamMembers.button.close', message: 'Close' })}
          variant={Button.Variant.Primary}
          onClick={onClose}
        />
      </div>
    </form>
  )
}
