import { CategoryListV5, CategoryV5 } from 'bybit-api'

export enum EBybitInstruments {
  'spot' = 'spot',
  'linear' = 'linear',
}

export interface IBybitParamsCategory {
  category: EBybitInstruments
  symbol?: string
}

export type ByBitTransactionHistory = {
  side: string
  funding: string
  orderLinkId: string
  orderId: string
  fee: string
  change: string
  cashFlow: string
  transactionTime: string
  type: string
  feeRate: string
  bonusChange: string
  size: string
  qty: string
  cashBalance: string
  currency: string
  id: string
  category: string
  tradePrice: string
  tradeId: string
  symbol: string
}

export type HistoryLogPayload = {
  result: {
    list: ByBitTransactionHistory[]
  }
}

export type CategoryListV5Internal<T extends unknown[], TCategory extends CategoryV5> = CategoryListV5<T, TCategory> & {
  nextPageCursor: string
}
