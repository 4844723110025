import { FC, ReactNode } from 'react'
import { useReferralHandler } from './hooks/useReferralHandler'
import { useKeitaroHandler } from './hooks/useKeitaroHandlers'

interface IDataProviderProps {
  children: ReactNode
}

const UrlParamsProvider: FC<IDataProviderProps> = props => {
  // Обработка реферального кода
  useReferralHandler()

  // Обработка Keitaro ID
  useKeitaroHandler()

  return <>{props.children}</>
}

export { UrlParamsProvider }
