import { FC } from 'react'
interface IIconProps {
  className?: string
}

export const ShieldEmpty: FC<IIconProps> = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      className={className}
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 21.5C9 21.5 17 17.5 17 11.5V4.5L9 1.5L1 4.5V11.5C1 17.5 9 21.5 9 21.5Z"
        stroke="#676573"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
