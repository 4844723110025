import React, { FC } from 'react'

export const Mark: FC = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="10.25" stroke="white" strokeWidth="1.5" />
      <path d="M11 6.06934L11 10.6211" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="10.9993" cy="14.7932" r="1.13793" fill="white" />
    </svg>
  )
}
