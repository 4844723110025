import React, { FC } from 'react'

export const Mark2: FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="15" fill="url(#paint0_linear_5956_2727)" />
      <circle cx="20" cy="20" r="10.25" stroke="white" strokeWidth="1.5" />
      <path d="M20 15.0688L20 19.6206" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="20.0002" cy="23.7932" r="1.13793" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_5956_2727"
          x1="0"
          y1="0"
          x2="41.3635"
          y2="1.46347"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F37E9A" />
          <stop offset="1" stopColor="#FF8933" />
        </linearGradient>
      </defs>
    </svg>
  )
}
