import { tradesActions } from '@/redux/trades/trades.slice'
import { useActions, useAppSelector } from '@/utils'
import { FC, lazy, Suspense, useEffect } from 'react'
import style from './style.module.scss'
import { DiaryHeaderResult } from '../DiaryWeekInfo/components/WeekResult/DiaryHeaderResult'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { Loader } from '@/ui/kit/Loader'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import { EmptyList } from '@/ui/molecules'
import { getCurrentMonth } from './utils'
import { Pairs } from './components/Pairs/Pairs'
import { useExchangeById } from '../HistoryTrades/hooks/useTMMTable/useExchangeById'
import { tableFilterExchangeIds, tableFilterMarketTypeIds } from '../HistoryTrades/components/TableFilters/utils'

type DiaryMonthInfoProps = {
  openBetween?: string
}
const START_DATE_LIMIT = '2023-01-01'

const MultiChart = lazy(() => import('@/ui/organisms/Chart/MultiChart'))

export const DiaryMonthInfo: FC<DiaryMonthInfoProps> = ({ openBetween }) => {
  const { GetAnalyzerTC, resetDiaryAnalyzer, GetTradesDayOrdersTC, GetMeTC, GetTickers, resetAnalyzerData } =
    useActions(tradesActions)
  const {
    analyzerMonthData,
    diaryAnalyzerStatus,
    isApiKeyExistWithEmptyArray,
    diaryWeekFilter,
    analyzeDayOrders,
    availableUsersTrading,
    analyzerMonthTradeTicker,
    me,
    tickers,
    tickersStatus,
  } = useAppSelector(state => state.trades)
  const navigate = useNavigate()
  const isCurrentMonth = getCurrentMonth(analyzerMonthData?.endMonth ?? '')
  const isIncorrectUrl = openBetween?.includes('undefined')
  const isLimitForPrevSwitchDate = START_DATE_LIMIT === formatDate(analyzerMonthData?.startMonth, DATE_FORMAT.FORMATTED)
  const getExchangeById = useExchangeById(me, tableFilterExchangeIds, tableFilterMarketTypeIds)
  const exchangeItem = getExchangeById(analyzerMonthTradeTicker?.api_key_id)
  const formattedChartDate = formatDate(analyzerMonthTradeTicker?.first_trade, DATE_FORMAT.FORMATTED)
  const isTickersLoading = tickersStatus === 'idle' || tickersStatus === 'loading'

  useEffect(() => {
    if (!analyzerMonthTradeTicker) return

    const timeoutId = setTimeout(() => {
      GetTradesDayOrdersTC({
        api_key_id: analyzerMonthTradeTicker.api_key_id,
        date: openBetween ?? '',
        symbol: analyzerMonthTradeTicker.symbol,
      })

      resetAnalyzerData()
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [analyzerMonthTradeTicker?.api_key_id, analyzerMonthTradeTicker?.symbol])

  useEffect(() => {
    if (isIncorrectUrl) return
    GetAnalyzerTC({
      ...diaryWeekFilter,
      openBetween: openBetween,
      type: 'month',
    })
    return () => {
      resetDiaryAnalyzer()
    }
  }, [openBetween, diaryWeekFilter])

  useEffect(() => {
    if (isIncorrectUrl) return
    GetMeTC()
    GetTickers()
  }, [])

  const getNextMonthDates = () => {
    if (isCurrentMonth) return
    const nextMonthDate = new Date(analyzerMonthData?.startMonth ?? '')
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1, 1)
    const lastDayOfCurrentMonth = new Date(nextMonthDate.getFullYear(), nextMonthDate.getMonth() + 1, 0)
    const startDateString = formatDate(nextMonthDate, DATE_FORMAT.FORMATTED)
    const endDateString = formatDate(lastDayOfCurrentMonth, DATE_FORMAT.FORMATTED)
    navigate(`${urls.summaryAnalyze}/${startDateString}-${endDateString}`)
  }

  const getPreviousMonthDates = () => {
    if (isLimitForPrevSwitchDate) return
    const currentDate = new Date(analyzerMonthData?.startMonth ?? '')
    const previousMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    const lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
    const startDateString = formatDate(previousMonthDate, DATE_FORMAT.FORMATTED)
    const endDateString = formatDate(lastDayOfPreviousMonth, DATE_FORMAT.FORMATTED)
    navigate(`${urls.summaryAnalyze}/${startDateString}-${endDateString}`)
  }
  const closeHandler = () => {
    navigate(urls.summary)
  }
  if (diaryAnalyzerStatus === 'loading') return <Loader className={style.loader} />

  if (diaryAnalyzerStatus === 'failed') {
    return (
      <ErrorBase
        className={style.errorBlock}
        customHeader={t({
          id: 'trades.summary.errorHeaderText',
        })}
        customText={
          <Trans
            id="trades.summary.errorDescription"
            components={{
              br: <br />,
            }}
          />
        }
        isRefreshButtonVisible
      />
    )
  }
  if (isIncorrectUrl || isApiKeyExistWithEmptyArray)
    return (
      <EmptyList
        className={style.emptyList}
        label={t({
          id: 'historyTrades.emptyList',
          comment: 'emptyList',
        })}
      />
    )

  return (
    <div className={style.root}>
      {analyzerMonthData?.data && (
        <DiaryHeaderResult
          weekData={analyzerMonthData.data}
          firstDayOfWeek={analyzerMonthData.startMonth}
          lastDayOfWeek={analyzerMonthData.endMonth}
          currentDateOrBig={isCurrentMonth}
          nextDataHandler={getNextMonthDates}
          prevDataHandler={getPreviousMonthDates}
          closeHandler={closeHandler}
          disablePrevSwitch={isLimitForPrevSwitchDate}
          title={t({
            id: 'trades.summary.month.title',
          })}
        />
      )}
      <div className={style.line} />
      <div className={style.wrapper}>
        {analyzerMonthData?.itemsWithSymbol && <Pairs trades={analyzerMonthData.itemsWithSymbol} />}
        {analyzeDayOrders &&
          !isTickersLoading &&
          availableUsersTrading &&
          analyzeDayOrders.data.length &&
          analyzerMonthTradeTicker &&
          exchangeItem &&
          analyzerMonthData?.itemsWithSymbol && (
            <div className={style.chartWrapper}>
              <Suspense
                fallback={
                  <div className={style.chartLoader}>
                    <Loader />
                  </div>
                }
              >
                <MultiChart
                  orders={analyzeDayOrders.data}
                  exchangeID={exchangeItem.exchangeId}
                  date={formattedChartDate}
                  trades={analyzeDayOrders.trades}
                  userID={Number(availableUsersTrading?.externalUserId)}
                  analyzerMonthTradeTicker={analyzerMonthTradeTicker}
                  tickers={tickers}
                />
              </Suspense>
            </div>
          )}
      </div>
    </div>
  )
}
