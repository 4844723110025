import { useCallback } from 'react'
import { useActions } from './useActions'
import { overviewActions } from '@/redux/overview/overview.slice'

export const useExchangeFallbackLoad = () => {
  const { setOverviewStatus } = useActions(overviewActions)
  const setIsLoadedOverviewFallback = useCallback(() => {
    setOverviewStatus('succeeded')
    return
  }, [])
  return { setIsLoadedOverviewFallback }
}
