import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'
import {
  HistoryAPI,
  HistoryCashback,
  HistoryCashbackPayload,
  HistoryTransactions,
  HistoryTransactionsPayload,
} from '../../backend/api'
import { ThunkError } from '../../types'
import { ThunkCreator } from '../../utils/lib/ThunkCreator'

export const GetHistoryCashbackTC = createAsyncThunk<HistoryCashback, HistoryCashbackPayload, ThunkError>(
  nameof(HistoryAPI.getHistoryCashback),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: HistoryAPI.getHistoryCashback, payload }, thunkAPI)
  }
)
export const GetHistoryTC = createAsyncThunk<HistoryTransactions, HistoryTransactionsPayload, ThunkError>(
  nameof(HistoryAPI.getHistoryTransactions),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: HistoryAPI.getHistoryTransactions, payload }, thunkAPI)
  }
)
