import React from 'react'

const Phone = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_11247_33687)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2725 15.0455C12.2725 12.5351 14.3075 10.5 16.8179 10.5H23.1816C25.6919 10.5 27.727 12.5351 27.727 15.0455V25.9545C27.727 28.4649 25.6919 30.5 23.1816 30.5H16.8179C14.3075 30.5 12.2725 28.4649 12.2725 25.9545V15.0455ZM21.1361 15.2729C21.1361 15.9005 20.6273 16.4093 19.9997 16.4093C19.3721 16.4093 18.8633 15.9005 18.8633 15.2729C18.8633 14.6453 19.3721 14.1365 19.9997 14.1365C20.6273 14.1365 21.1361 14.6453 21.1361 15.2729ZM18.1816 24.9543C17.6293 24.9543 17.1816 25.402 17.1816 25.9543C17.1816 26.5066 17.6293 26.9543 18.1816 26.9543H21.818C22.3702 26.9543 22.818 26.5066 22.818 25.9543C22.818 25.402 22.3702 24.9543 21.818 24.9543H18.1816Z"
        fill="#FAFAFA"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11247_33687"
          x1="0"
          y1="40.5"
          x2="30.3512"
          y2="-5.82211"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C8CAFB" />
          <stop offset="1" stopColor="#E7569A" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { Phone }
