import * as yup from 'yup'
import { E_RISK_MANAGER_DRAWDOWN_TYPE } from '@/redux/team/team.types'
import { VALIDATION_RULES } from '@/core/config/validation'
import { t } from '@lingui/macro'
import { IRiskManagerUpdate } from '@/ui/molecules/TeamForm/TeamForm.Types'

export const VALIDATION_SCHEMA = (): yup.ObjectSchema<Omit<IRiskManagerUpdate, 'userHandle'>> =>
  yup.object({
    drawdownValue: yup
      .number()
      .typeError(
        t({
          id: 'teamRiskSubscriptionForm.errors.drawdownValue.required',
        })
      )
      .required(
        t({
          id: 'teamRiskSubscriptionForm.errors.drawdownValue.required',
        })
      )
      .when(['drawdownType'], ([drawdownType], schema) => {
        if (drawdownType === E_RISK_MANAGER_DRAWDOWN_TYPE.percent)
          return schema.test(
            'drawdownValue',
            t({
              id: 'teamRiskSubscriptionForm.errors.drawdownValue.percent',
            }),
            value => {
              if (!value) return false
              return value > VALIDATION_RULES.drawdownPercentage.min && value <= VALIDATION_RULES.drawdownPercentage.max
            }
          )
        else if (drawdownType === E_RISK_MANAGER_DRAWDOWN_TYPE.usdt)
          return schema.test(
            'drawdownValue',
            t({
              id: 'teamRiskSubscriptionForm.errors.drawdownValue.usdt',
            }),
            value => {
              if (!value) return false
              return value > VALIDATION_RULES.drawdownFixed.min && value <= VALIDATION_RULES.drawdownFixed.max
            }
          )
        return schema.test(
          'drawdownValue',
          t({
            id: 'teamRiskSubscriptionForm.errors.drawdownValue.required',
          }),
          () => {
            return false
          }
        )
      }),
    drawdownType: yup.mixed<E_RISK_MANAGER_DRAWDOWN_TYPE>().required(
      t({
        id: 'teamRiskSubscriptionForm.errors.drawdownType',
      })
    ),
  })
