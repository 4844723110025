import { useExchageType } from '@/utils/hooks/useExchangeType'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import style from '../../style.module.scss'
import { SVG } from '@/assets/svg'
import { Toggle } from '@/ui/kit'
import { t } from '@lingui/macro'
import { useActions, useAppSelector } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'
import { TAccountType } from '@/core/types/overview'
import { enqueueSnackbar } from 'notistack'
import { TBooleanString } from '@/backend/models/OverviewDTO'
import { FieldErrorType } from '@/types'
import { getProfileRightBarTranslations } from '../../ProfileRightBar.translations'
import { isExchangeBinance } from '@/utils/lib/exchange'

interface Props {
  disabled?: boolean
}

export const SetPositionMode: FC<Props> = ({ disabled }) => {
  const { exchangeType } = useExchageType()
  const { GetBinancePositionModeTC, SetPositionModeTC } = useActions(profileActions)
  const { binanceHedge } = useAppSelector(state => state.profile)
  const { isExchangeProviderInitialized } = useAppSelector(state => state.app)

  const translations = useMemo(() => {
    return getProfileRightBarTranslations()
  }, [])

  useEffect(() => {
    if (!isExchangeProviderInitialized) return
    GetBinancePositionModeTC([])
  }, [GetBinancePositionModeTC, isExchangeProviderInitialized])

  const [usdmActive, setUsdmActive] = useState(binanceHedge.BINANCE_FUTURE?.dualSidePosition)
  const [coinActive, setCoinActive] = useState(binanceHedge.BINANCE_COIN?.dualSidePosition)

  useEffect(() => {
    if (typeof usdmActive === 'undefined' && typeof binanceHedge.BINANCE_FUTURE !== 'undefined') {
      setUsdmActive(binanceHedge.BINANCE_FUTURE.dualSidePosition)
    }
  }, [usdmActive, binanceHedge.BINANCE_FUTURE])

  useEffect(() => {
    if (typeof coinActive === 'undefined' && typeof binanceHedge.BINANCE_COIN !== 'undefined') {
      setCoinActive(binanceHedge.BINANCE_COIN.dualSidePosition)
    }
  }, [coinActive, binanceHedge.BINANCE_COIN])

  const handleSetPositionModeChange = useCallback(async (accountType: TAccountType, active: boolean) => {
    switch (accountType) {
      case 'BINANCE_FUTURE':
        setUsdmActive(active)
        break
      case 'BINANCE_FUTURE_COIN':
        setCoinActive(active)
        break
      default:
    }
    const response = await SetPositionModeTC({
      accountType,
      dualSidePosition: active.toString() as TBooleanString,
    })

    if ('error' in response) {
      switch (accountType) {
        case 'BINANCE_FUTURE':
          setUsdmActive(!active)
          break
        case 'BINANCE_FUTURE_COIN':
          setCoinActive(!active)
          break
        default:
      }

      try {
        // @ts-ignore
        response?.payload?.fieldsErrors?.forEach((error: FieldErrorType) => {
          enqueueSnackbar(
            translations.setPositionModeErrors[error.code as keyof typeof translations.setPositionModeErrors],
            {
              variant: 'error',
            }
          )
        })
      } catch {}
    } else {
      enqueueSnackbar(t({ id: 'profile.setPositionMode.success', message: 'Position mode changed' }), {
        variant: 'success',
      })
    }
  }, [])

  if (isExchangeBinance(exchangeType))
    return (
      <div className={style.setting}>
        {disabled && <div className={style.disabled} />}
        <SVG.Settings.Hedge className={style.sectionIcon} />
        <div>
          <div className={style.settingTitle}>
            {t({ id: 'profile.setPositionMode.title', message: `Turn on the Hedge Mode` })}
          </div>
          <div className={style.settingStatus}>
            {t({
              id: 'profile.setPositionMode.description',
              message:
                'Hedge mode allows simultaneously opening two opposite positionsfor the same symbol on the Futures market: a long and a short.',
            })}
          </div>
        </div>
        <div className={style.settingActions}>
          {typeof usdmActive !== 'undefined' && (
            <Toggle
              active={usdmActive}
              onChange={active => {
                handleSetPositionModeChange('BINANCE_FUTURE', active)
              }}
              text={'USDⓈ-M'}
              className={style.nowrap}
            />
          )}
          {typeof coinActive !== 'undefined' && (
            <Toggle
              active={coinActive}
              onChange={active => {
                handleSetPositionModeChange('BINANCE_FUTURE_COIN', active)
              }}
              text={'COIN-M'}
              className={style.nowrap}
            />
          )}
        </div>
      </div>
    )

  return <></>
}
