import { DiaryMonthInfo } from '@/ui/organisms'
import style from './style.module.scss'
import { useParams } from 'react-router-dom'

export const DiaryMonthAnalyzePage = () => {
  const { tradeDiaryMonth } = useParams()
  let formattedTradeDiaryWeek

  if (tradeDiaryMonth) {
    const [startDate, endDate] = tradeDiaryMonth.split(/-(?=\d{4}-\d{2}-\d{2}$)/)
    formattedTradeDiaryWeek = `${startDate},${endDate}`
  }

  return (
    <div className={style.container}>
      <div className={style.root}>
        <DiaryMonthInfo openBetween={formattedTradeDiaryWeek} />
      </div>
    </div>
  )
}
