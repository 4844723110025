/** Adds an event listener to an element, and returns a function to remove it. */
export function addEvent<K extends keyof GlobalEventHandlersEventMap>(
  target: EventTarget,
  event: K,
  handler: (this: Document, ev: GlobalEventHandlersEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): () => void {
  target.addEventListener(event, handler as any, options)

  return () => {
    target.removeEventListener(event, handler as any, options)
  }
}
