import { FC, useMemo } from 'react'
import clsx from 'clsx'
import { t } from '@lingui/macro'
import { TLanguage, getLanguageDescriptions } from '@/core/constants'
import { useLanguageContext } from '@/providers/LanguageProvider'
import s from './style.module.scss'

const HeaderLanguage: FC = () => {
  const { locale, setLocale } = useLanguageContext()

  const LANGUAGES_DESCRIPTIONS = useMemo(() => {
    return getLanguageDescriptions()
  }, [])

  return (
    <div className={s.root}>
      <button className={s.button}>{LANGUAGES_DESCRIPTIONS[locale].shortenedName}</button>
      <div className={s.popup}>
        <div className={s.popupContent}>
          {Object.keys(LANGUAGES_DESCRIPTIONS).map(key => (
            <div
              key={key}
              className={clsx(s.language, { [s.active]: key === locale })}
              onClick={setLocale.bind(this, key as TLanguage)}
            >
              {LANGUAGES_DESCRIPTIONS[key as keyof typeof LANGUAGES_DESCRIPTIONS].name}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export { HeaderLanguage }
