import clsx from 'clsx'
import { CSSProperties, Dispatch, FC, ReactNode, SetStateAction, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { SVG } from '../../../assets/svg'
import style from './style.module.scss'
import { isNumber } from '@/ui/organisms/Chart/KLineChartFork/common/utils/typeChecks'

export type HintProps = {
  /** place to display hint */
  placement: 'right' | 'top' | 'bottom' | 'left'

  /** text inside hint */
  text: ReactNode

  /** className */
  className?: string

  /** styling variant */
  variant?: 'success' | 'error'

  /** Ref of the Element to show Tooltip for. Create it only with «usePopperRef» */
  targetRef: HTMLElement | null

  dataTestId?: string

  /** hint sizeSmall for small box  */
  hitSizeSmall?: boolean

  maxWidth?: number
  minWidth?: number
  maxHeight?: number
  minHeight?: number
}

/** Hint. Use it only with «usePopperRef» */
export const Hint: FC<HintProps> = ({
  text,
  placement,
  className,
  variant,
  targetRef,
  dataTestId,
  hitSizeSmall,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
}) => {
  const [popperRef, setPopperRef] = usePopperRef<HTMLDivElement>()
  const [arrowRef, setArrowRef] = usePopperRef<HTMLDivElement>()

  const { styles, attributes } = usePopper(targetRef, popperRef, {
    placement,
    strategy: 'absolute',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  })

  const styleProps: CSSProperties = useMemo(() => {
    const customStyles: CSSProperties = {}

    if (isNumber(maxHeight)) {
      customStyles.maxHeight = `${maxHeight}px`
      customStyles.minHeight = `${0}px`
    }

    if (isNumber(minHeight)) {
      customStyles.minHeight = `${minHeight}px`
    }

    if (isNumber(maxWidth)) {
      customStyles.maxWidth = `${maxWidth}px`
      customStyles.minWidth = `${0}px`
    }

    if (isNumber(minWidth)) {
      customStyles.minWidth = `${minWidth}px`
    }

    return customStyles
  }, [maxHeight, maxWidth, minHeight, minWidth])

  return (
    <div
      className={clsx(style.hint, className, {
        [style.smallSize]: hitSizeSmall,
      })}
      role="tooltip"
      {...attributes.popper}
      style={{
        ...styles.popper,
        ...styleProps,
      }}
      ref={setPopperRef}
      data-testid={dataTestId}
    >
      <div className={style.hintText}>
        {variant === 'success' && (
          <div className={style.variantIcon}>
            <SVG.Status.Completed />
          </div>
        )}
        {variant === 'error' && (
          <div className={style.variantIcon}>
            <SVG.Status.Failed />
          </div>
        )}
        <div>{text}</div>
      </div>
      <div ref={setArrowRef} style={styles.arrow} className={style.hintArrow} />
    </div>
  )
}

/** hook for getting refs specially for Popper. Simple «useRef» doesn't work properly */
export const usePopperRef = <T extends HTMLElement>(): [T | null, Dispatch<SetStateAction<T | null>>] => {
  const [ref, setRef] = useState<T | null>(null)

  return [ref, setRef]
}
