import { IAppStateType } from '@/backend/models/AppDTO'
import { getClosedNotifications } from '@/ui/molecules/SystemNotifications/libs/alertsSessionStorage'
import { getExchangeTypeFromSession } from '@/utils/lib/getExchangeTypeFromSession'

const initialState: IAppStateType = {
  status: 'idle',
  isMinimized: false,
  isMobile: false,
  isSetup: false,
  isRestClientInitialized: false,
  exchangeType: getExchangeTypeFromSession(),
  exchangeStatus: 'loading', // if exchange provider initialized and can display data (timeout for Bybit exchange)
  isExchangeProviderInitialized: false, // if exchange provider initialized correctly and app can load next data
  closedNotifications: getClosedNotifications(),
}

export default initialState
