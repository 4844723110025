import { Button } from '@/ui/kit'
import { t } from '@lingui/macro'
import styles from '../style.module.scss'
import { FC } from 'react'
import { useActions } from '@/utils'
import { teamAsyncActions } from '@/redux/team/team.actions'
import clsx from 'clsx'

type ITeamFormMemberRemoveProps = {
  userId: string
  onClose: () => void
}

export const TeamFormMemberRemove: FC<ITeamFormMemberRemoveProps> = props => {
  const { onClose, userId } = props
  const { DeleteMemberTC } = useActions(teamAsyncActions)

  const onSubmit = () => {
    DeleteMemberTC(userId)
    onClose()
  }

  return (
    <div className={styles.removeMemberBlock}>
      <div className={styles.removeMemberContainer}>
        <div>
          {t({
            id: 'teamMembers.remove.title',
            message: `You are removing a member`,
          })}
        </div>
        <div className={styles.removeMemberId}>{userId}</div>
      </div>
      <div className={styles.btnContainer}>
        <Button.Primary
          label={t({
            id: 'teamMembers.remove.apply',
            message: 'Remove',
          })}
          className={clsx(styles.btnWide, styles.redText)}
          onClick={onSubmit}
        />
        <Button.Primary
          label={t({
            id: 'core.button.cancel',
            message: `Cancel`,
          })}
          className={styles.btnWide}
          onClick={onClose}
        />
      </div>
    </div>
  )
}
