import { E_BINANCE_VIP_CONSENT_PERMISSIONS } from '@/ui/molecules/BinanceVipConsentForm/BinanceVipConsentForm.types'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import { ReactNode } from 'react'

export const getBinanceVipConsentTranslations = (): Record<E_BINANCE_VIP_CONSENT_PERMISSIONS, ReactNode> => {
  return {
    isNdaAccepted: (
      <Trans
        id="binanceVipConsentPermissions.isNdaAccepted"
        components={{
          a: <a target="_blank" href="http://cdn-gl.tiger.trade/download/TigerX_NDA.pdf" rel="noreferrer"></a>,
        }}
      />
    ),
    isRiskAccepted: (
      <Trans
        id="binanceVipConsentPermissions.isRiskAccepted"
        components={{
          a: <a target="_blank" href="http://cdn-gl.tiger.trade/download/TigerX_Risk_Warning.pdf" rel="noreferrer"></a>,
        }}
      />
    ),
    isTermsAccepted: (
      <Trans
        id="binanceVipConsentPermissions.isTermsAccepted"
        components={{
          a: (
            <a
              target="_blank"
              href="http://cdn-gl.tiger.trade/download/TigerX_VIP_Terms_of_Use.pdf"
              rel="noreferrer"
            ></a>
          ),
        }}
      />
    ),
  }
}
