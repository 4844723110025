import { FC, useEffect, useRef } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { Button, InteractiveModalParts } from '@/ui/kit'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { SVG } from '@/assets/svg'
import { CopyToClipboard } from '@/pages/TradeInfo/TradeInfoView/TradeShare/TradeSharing/CopyToClipboard/CopyToClipboard'
import { toPng } from 'html-to-image'
import { useActions, useAppSelector } from '@/utils'
import { referralsActions } from '@/redux/referrals/referrals.slice'

type Props = {
  onClose: () => void
  img: string
}

export const Share: FC<Props> = props => {
  const { GetReferralOverviewTC } = useActions(referralsActions)
  const { overview } = useAppSelector(state => state.referrals)
  const contentRef = useRef(null)

  useEffect(() => {
    GetReferralOverviewTC()
  }, [])

  function closePopup() {
    props.onClose()
  }

  async function handleDownload() {
    if (contentRef.current === null) {
      console.error('handleDownload Share')
      return
    }

    try {
      // Генерируем PNG изображение из элемента, на который указывает реф
      const dataUrl = await toPng(contentRef.current)

      // Создаём ссылку для скачивания
      const link = document.createElement('a')
      link.href = dataUrl
      link.download = 'tiger-result.png' // Название файла для скачивания

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Ошибка при генерации изображения:', error)
    }
  }

  return (
    <>
      <div className={style.wrapper}>
        <TitleH3
          className={style.title}
          label={t({
            id: 'leaderBoard.share.popup.title',
            comment: 'Ваши результаты',
          })}
        />
        <div className={style.content} ref={contentRef}>
          <div className={style.background}>
            <SVG.LeaderBoard.TigerBackground />
          </div>
          <div className={style.visible}>
            <img src={props.img} className={style.img} alt="tiger statistic" />
            <div className={style.logo}>
              <SVG.OtherIcons.Logo />
            </div>
          </div>
        </div>
        <div className={style.btnWrapper}>
          <CopyToClipboard valueToCopy={`https://broker.tiger.com?referral=${overview.userCode}`} />
          <InteractiveModalParts.Button
            text={t({ id: 'core.download', comment: 'Close' })}
            variant={Button.Variant.Primary}
            onClick={handleDownload}
          />
          <InteractiveModalParts.Button
            text={t({ id: 'core.close', comment: 'Close' })}
            variant={Button.Variant.Primary}
            onClick={closePopup}
          />
        </div>
      </div>
    </>
  )
}
