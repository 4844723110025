import BigNumber from 'bignumber.js'

export const getMaximalSellingValue = (balanceInAccount: number, minStep: number) => {
  const balanceInAccountBig = new BigNumber(balanceInAccount)
  const minStepBig = new BigNumber(minStep)

  if (minStep === 0) {
    return balanceInAccountBig.toNumber()
  }

  return balanceInAccountBig.dividedToIntegerBy(minStepBig).multipliedBy(minStepBig).toNumber()
}
