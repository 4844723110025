import { FC } from 'react'
import { useMobileSizeDetect } from '@/utils'
import { AssetsMobile } from './AssetsMobile'
import { AssetsPC } from './AssetsPC'

export const Assets: FC = () => {
  const [isMobile] = useMobileSizeDetect()

  return isMobile ? <AssetsMobile /> : <AssetsPC />
}
