import { SVG } from '@/assets/svg'
import { E_PositionSide } from '@/redux/riskManager/riskManager.types'
import { FC } from 'react'
import style from './style.module.scss'
import { TExchangesAvailable } from '@/core/constants'

interface PositionTickerProps {
  exchange?: TExchangesAvailable | null
  side: E_PositionSide
  symbolName: string
}

const getExchangeIcon = (exchange?: TExchangesAvailable | null) => {
  switch (exchange) {
    case 'BINANCE_BROKER_FUTURE':
      return <SVG.Settings.BinanceSquare />
    case 'BINANCE_VIP':
      return <SVG.Settings.BinanceSquare />
    case 'BYBIT_BROKER':
    case 'OKX_BROKER':
    default:
      return null
  }
}

export const PositionTicker: FC<PositionTickerProps> = ({ exchange, side, symbolName }) => {
  const sideIcon = side === E_PositionSide.Short ? <SVG.Tmm.LowSide /> : <SVG.Tmm.HighSide />

  return (
    <div className={style.ticker}>
      {getExchangeIcon(exchange)}
      <div className={style.symbolName}>{symbolName}</div>
      {sideIcon}
    </div>
  )
}
