import { FC } from 'react'
import style from './style.module.scss'

type DiaryCalendarCardProps = {
  month?: string
  day?: string
}
export const DiaryCalendarCard: FC<DiaryCalendarCardProps> = ({ month, day }) => {
  return (
    <div className={style.root}>
      <div className={style.month}>{month}</div>
      <div className={style.day}>{day}</div>
    </div>
  )
}
