import { t } from '@lingui/macro'
import { ApiKeyInfoBlocksItem } from '../ApiKeyInfoBlocksItem'
import { SVG } from '@/assets/svg'

import styles from './styles.module.scss'

export const ApiKeyInfoBlocks = () => {
  const BLOCKS = [
    {
      icon: <SVG.Api.Key />,
      text: t({
        id: 'apiPage.infoBlock.upTo',
        message: 'Up to 19 API keys per account. Don’t share your key to avoid losing assets',
      }),
    },
    {
      icon: <SVG.Api.Lock />,
      text: t({
        id: 'apiPage.infoBlock.security',
        message: 'You can increase the security of your account by linking IP address to API key',
      }),
    },
    {
      icon: <SVG.Api.Scan />,
      text: t({
        id: 'apiPage.infoBlock.exposed',
        message: 'Be aware that if you log in to a third-party platform, your API key may be exposed',
      }),
    },
    {
      icon: <SVG.Api.User />,
      text: t({
        id: 'apiPage.infoBlock.kyc',
        message: 'API key creation will only be available after you complete KYC',
      }),
    },
  ]
  return (
    <div className={styles.root}>
      {BLOCKS.map(block => (
        <ApiKeyInfoBlocksItem key={block.text} icon={block.icon} text={block.text} />
      ))}
    </div>
  )
}
