import { FC, Fragment } from 'react'
import style from './style.module.scss'
import { DiaryCalendarCard, DiaryWeekCardItem } from '@/ui/molecules'
import { WeekInfo } from '@/utils/types/contracts.types'
import { DATE_FORMAT, getMonthName } from '@/utils/lib/formatDate'
import { addSeparators, customRound } from '@/utils/lib/formatNumber'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { removeTrailingPeriod } from '../../utils/removeTrailingPeriod'
import {
  calculateWeekWinPercentage,
  getPercentageDivided,
} from '@/ui/organisms/DiaryWeekInfo/components/WeekResult/utils'

type WeekCardProps = {
  data: WeekInfo
}
export const DEFAULT_VALUE_WITH_SYMBOL = '0.00 $'
export const DEFAULT_VALUE_PERCENT_SYMBOL = '0.00 %'
export const DEFAULT_VALUE_WITHOUT_SYMBOL = '0.00'
export const DEFAULT_LEVERAGE_VALUE = 'x0.00'
export const DEFAULT_VALUE_WITH_ZERO = '0'

export const handleRound = (value: string | number) => {
  const roundedValue = customRound(value)
  return addSeparators(roundedValue)
}

export const WeekCard: FC<WeekCardProps> = ({ data: { firstDayOfWeek, data, lastDayOfWeek } }) => {
  const monthStart = removeTrailingPeriod(getMonthName(firstDayOfWeek, DATE_FORMAT.MONTH_SHORT))
  const monthEnd = removeTrailingPeriod(getMonthName(lastDayOfWeek, DATE_FORMAT.MONTH_SHORT))
  const WeekStart = new Date(firstDayOfWeek).getDate().toString()
  const WeekEnd = new Date(lastDayOfWeek).getDate().toString()
  const navigate = useNavigate()

  const weekListPageHandler = () => {
    navigate(`${urls.summary}/${firstDayOfWeek}-${lastDayOfWeek}`)
  }
  const showData = () => {
    if (data.length) {
      return data.map((cardItem, index) => (
        <div key={index} onClick={weekListPageHandler} className={style.clickable}>
          <div className={style.calendar}>
            <DiaryCalendarCard month={monthStart} day={WeekStart} />
            <div className={style.to}>
              {t({
                id: 'trades.summary.to',
                comment: 'to',
              })}
            </div>
            <DiaryCalendarCard month={monthEnd} day={WeekEnd} />
          </div>
          <div className={clsx(style.cardItem)}>
            <DiaryWeekCardItem
              isCountPercent
              sourceValue={Number(cardItem.net_profit)}
              value={cardItem.net_profit ? handleRound(cardItem.net_profit) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
              text={t({
                id: 'trades.summary.netProfit',
                comment: 'netProfitTitle',
              })}
            />
            <DiaryWeekCardItem
              value={cardItem.count ?? DEFAULT_VALUE_WITH_ZERO}
              text={t({
                id: 'trades.summary.transactions',
                comment: 'transactions',
              })}
            />
            <DiaryWeekCardItem
              value={cardItem.volume ? handleRound(cardItem.volume) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
              text={t({
                id: 'trades.summary.volume',
                comment: 'volume',
              })}
            />
            <DiaryWeekCardItem
              value={calculateWeekWinPercentage(cardItem.count, cardItem.win_count)}
              text={t({
                id: 'trades.summary.winPercentage',
                comment: 'winPercentage',
              })}
            />
          </div>
        </div>
      ))
    } else {
      return (
        <div className={style.emptyWeekCard}>
          <div className={style.calendar}>
            <DiaryCalendarCard month={monthStart} day={WeekStart} />
            <div className={style.to}>
              {t({
                id: 'trades.summary.to',
                comment: 'to',
              })}
            </div>
            <DiaryCalendarCard month={monthEnd} day={WeekEnd} />
          </div>
          <div className={style.cardItem}>
            <DiaryWeekCardItem
              isCountPercent
              value={0}
              text={t({
                id: 'trades.summary.netProfit',
                comment: 'netProfitTitle',
              })}
            />
            <DiaryWeekCardItem
              value={DEFAULT_VALUE_WITH_ZERO}
              text={t({
                id: 'trades.summary.transactions',
                comment: 'transactions',
              })}
            />
            <DiaryWeekCardItem
              value={DEFAULT_VALUE_WITH_SYMBOL}
              text={t({
                id: 'trades.summary.volume',
                comment: 'volume',
              })}
            />
            <DiaryWeekCardItem
              value={DEFAULT_VALUE_PERCENT_SYMBOL}
              text={t({
                id: 'trades.summary.winPercentage',
                comment: 'winPercentage',
              })}
            />
          </div>
        </div>
      )
    }
  }
  return <>{showData()}</>
}
