import { FC } from 'react'
import Main from '../Main'
import style from './style.module.scss'

interface IProps {
  enableHeaderAnimation?: boolean
  children?: React.ReactNode
}

const Blocked: FC<IProps> = ({ children, enableHeaderAnimation = false }) => {
  return (
    <Main
      enableHeaderAnimation={enableHeaderAnimation}
      isPopupMode={true}
      isBackButtonHidden={true}
      isTopMenuHidden={true}
      isBackgroundHidden={false}
    >
      <main className={style.content}>{children}</main>
    </Main>
  )
}

export default Blocked
