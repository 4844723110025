import React from 'react'

interface MultiAssetProps {
  className?: string
}

const MultiAsset: React.FC<MultiAssetProps> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_12884_10864)" />
      <path
        d="M28 15.5002C28 16.9729 24.4183 18.1668 20 18.1668C15.5817 18.1668 12 16.9729 12 15.5002C12 14.0274 15.5817 12.8335 20 12.8335C24.4183 12.8335 28 14.0274 28 15.5002Z"
        fill="white"
      />
      <path
        d="M20 19.5002C24.6667 19.5002 28 18.1668 28 16.8335L28 18.8335C28 20.1668 24.6667 21.5002 20 21.5002C15.3333 21.5002 12 20.1668 12 18.8335V16.8335C12 18.1668 15.3333 19.5002 20 19.5002Z"
        fill="white"
      />
      <path
        d="M20 22.8335C24.6667 22.8335 28 21.5002 28 20.1668L28 22.1668C28 23.5002 24.6667 24.8335 20 24.8335C15.3333 24.8335 12 23.5002 12 22.1668L12 20.1668C12 21.5002 15.3333 22.8335 20 22.8335Z"
        fill="white"
      />
      <path
        d="M20 26.1668C24.6667 26.1668 28 24.8335 28 23.5002L28 25.5002C28 26.8335 24.6667 28.1668 20 28.1668C15.3333 28.1668 12 26.8335 12 25.5002V23.5002C12 24.8335 15.3333 26.1668 20 26.1668Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_12884_10864" x1="40" y1="40.5" x2="0" y2="0.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9027" />
          <stop offset="1" stopColor="#FFD35F" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { MultiAsset }
