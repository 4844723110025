import { FC } from 'react'
import clsx from 'clsx'
import { Input, InputProps } from '@/ui/kit'
import { SVG } from '@/assets/svg'
import style from './style.module.scss'

interface InputSearchProps extends InputProps {
  sizeSearch?: 'medium' | 'small'
}

const InputSearch: FC<InputSearchProps> = props => {
  const { sizeSearch = 'medium', containerClassName, ...rest } = props

  return (
    <div className={clsx(style.root, style[sizeSearch])}>
      <Input
        {...rest}
        icon={<SVG.OtherIcons.Lens className={style.icon} />}
        containerClassName={clsx(style.container, containerClassName)}
      />
    </div>
  )
}

export { InputSearch }
