import { InitialState } from './partner.types'

export const initialState: InitialState = {
  partners: [],
  partner: undefined,
  partnersByAwardKey: {},
  hasPartnerAccess: undefined,
  achievements: [],
  awardClaimed: undefined,
  tradingVolume: undefined,
  achievementsUnlockedCount: undefined,
  achievementPending: undefined,
  unlockedAll: undefined,
  listStatus: 'idle',
  dataStatus: 'idle',
  claimStatus: 'idle',
}
