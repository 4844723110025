import { FC, memo, useMemo } from 'react'
import { t } from '@lingui/macro'
import { formatNumber, useAppSelector } from '@/utils'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { truncateNumber } from '@/utils/lib/truncateNumber'
import { OVEVIEW_EXCHANGE_SYMBOLS_MAP } from '@/redux/overview/overview.defaults'

const AccountBalance: FC = () => {
  const { totalBalance } = useAppSelector(state => state.overview)
  const { exchangeType } = useAppSelector(state => state.app)
  const accountBalance = useMemo(() => {
    const balanceNumber = Number(totalBalance)
    return balanceNumber < 1 ? truncateNumber(balanceNumber) : truncateNumber(balanceNumber, 0)
  }, [totalBalance])

  return (
    <div className={style.accountBalance}>
      <div className={style.label}>{t({ id: 'mainPage.accountBalance.title', message: 'Account balance' })}</div>
      {Number(accountBalance) === 0 ? (
        <span className={style.balance} data-testid={DataTestIds.AccountBalanceNoCoins}>
          {t({ id: 'mainPage.accountBalance.noCoins', message: 'No coins' })}
        </span>
      ) : (
        <>
          <span className={style.balance} data-testid={DataTestIds.AccountBalanceValue}>
            ≈ {formatNumber(accountBalance)}{' '}
          </span>
          <span className={style.currency}>{exchangeType && OVEVIEW_EXCHANGE_SYMBOLS_MAP[exchangeType].symbol}</span>
        </>
      )}
    </div>
  )
}

export default memo(AccountBalance)
