import style from './style.module.scss'
import { useState, useCallback, FC, useMemo, useEffect } from 'react'
import { t } from '@lingui/macro'
import {
  checkIsDecimalsQuantityCorrect,
  formatNumber,
  instrumentNames,
  useActions,
  useAppDispatch,
  useAppSelector,
} from '@/utils'
import { EAccountType } from '@tigertrade/binance-ts'
import { v1 } from 'uuid'
import { transactionsActions } from '@/redux/transactions/transactions.slice'
import { transactionsAsyncActions } from '@/redux/transactions/transactions.actions'
import { SVG } from '@/assets/svg'
import { Button, ButtonSize, ButtonVariant, Input, InputSize, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { BalanceAsset, TransactionAssets } from '@/backend/api'
import { useAssetsForTransactionsPolling } from '@/utils/hooks'
import { enqueueSnackbar } from 'notistack'

const MAX_DECIMALS = 8

const StatisticsTransfer: FC = () => {
  useAssetsForTransactionsPolling(5000)

  const [amount, setAmount] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [accountFrom, setAccountFrom] = useState<keyof AccountConfig>(EAccountType.USDT_FUTURE)
  const [accountTo, setAccountTo] = useState<keyof AccountConfig>(EAccountType.SPOT)
  const [isTransferAttemptDetected, setIsTransferAttemptDetected] = useState(false)
  const [fetchingError, setFetchingError] = useState('')

  const { assetsForTransactions, transactionSendResult } = useAppSelector(state => state.transactions)
  const { TransferAssetTC } = useActions(transactionsAsyncActions)

  const dispatch = useAppDispatch()

  const accountConfig = useMemo<AccountConfig>(
    () => makeAccountConfig(assetsForTransactions.assets, 'USDT'),
    [assetsForTransactions]
  )

  const itemFrom = useMemo(() => {
    return accountConfig[accountFrom]
  }, [accountConfig, accountFrom])

  const getAccountNameByType = (type: keyof AccountConfig) => {
    return accountConfig[type].name
  }

  const getErrorMessage = () => {
    if (amount === '' || Number(amount) <= 0) {
      return t({ message: `Incorrect amount`, id: 'transferTransaction.messages.inccorectAmount' })
    }

    if (Number(amount) > Number(itemFrom.balance)) {
      return t({ message: 'Insufficient funds in the account', id: 'transferTransaction.messages.insufFunds' })
    }

    if (!checkIsDecimalsQuantityCorrect(amount, MAX_DECIMALS)) {
      return `${t({
        message: 'Only',
        id: 'transferTransaction.messages.onlyDec-1',
      })} ${MAX_DECIMALS} ${t({
        message: 'decimal places are allowed',
        id: 'transferTransaction.messages.onlyDec-2',
      })}`
    }

    return ''
  }

  const errorMessage = getErrorMessage()
  const isTransferAvailable = errorMessage === ''

  const fetchingErrorCode = assetsForTransactions.assets.errors?.[0]?.code
  const { status = 'idle' } = transactionSendResult?.[transactionId] || {}
  const isPending = status === 'loading'
  const isDisabledTransferBtn = (isTransferAttemptDetected && !isTransferAvailable) || transactionId.length > 0

  const transferAsset = useCallback(async () => {
    setIsTransferAttemptDetected(true)

    if (!isTransferAvailable) {
      return
    }

    const transactionId = v1()
    dispatch(transactionsActions.initTransaction(transactionId))
    setTransactionId(transactionId)

    TransferAssetTC({
      accountFrom,
      accountTo,
      amount: Number(amount),
      asset: itemFrom.symbol,
      transactionId,
    })
  }, [TransferAssetTC, accountFrom, accountTo, amount, dispatch, itemFrom, isTransferAvailable])

  const toggleAccounts = useCallback(() => {
    setAccountFrom(accountTo)
    setAccountTo(accountFrom)
    setAmount('')
  }, [accountFrom, accountTo])

  const closeModal = useCallback(() => {
    setFetchingError('')
  }, [])

  const errorText = useMemo(() => {
    return t({
      message: 'Transfer error',
      id: 'transferTransaction.messages.requestError',
    })
  }, [])

  useEffect(() => {
    if (status === 'succeeded') {
      const successMessage = t({
        message: 'Transfer success',
        id: 'transferTransaction.messages.requestSuccess',
      })

      enqueueSnackbar(successMessage, { variant: 'success' })
      setAmount('')
      setTransactionId('')
      setIsTransferAttemptDetected(false)
    }

    if (status === 'failed') {
      setFetchingError(errorText)
      setTransactionId('')
      setIsTransferAttemptDetected(false)
    }
  }, [status, errorText])

  return (
    <div className={style.transfer}>
      <div className={style.info}>
        <span className={style.instrument}>{getAccountNameByType(accountFrom)}</span>

        <Button.Clean
          size={ButtonSize.ExtraSmall}
          onClick={toggleAccounts}
          className={style.toggleBtn}
          leftIcon={<SVG.Actions.Transfer />}
          dataTestId={DataTestIds.TransferSwitchButton}
        ></Button.Clean>

        <span className={style.instrument}>{getAccountNameByType(accountTo)}</span>
      </div>

      <Input
        setValue={setAmount}
        size={InputSize.ExtraSmall}
        value={amount}
        dataTestId={DataTestIds.AmountInput}
        containerClassName={style.amount}
        inputAttrs={{ type: 'number' }}
        errorMessage={isTransferAttemptDetected ? errorMessage : ''}
        placeholder="0"
        disabled={isPending}
      >
        <Button.Secondary
          size={ButtonSize.ExtraSmall}
          label={t({ message: 'Transfer', id: 'core.transfer' })}
          onClick={transferAsset}
          disabled={isDisabledTransferBtn}
          dataTestId={DataTestIds.TransferConfirmButton}
        />
      </Input>

      <div className={style.available}>
        {t({ message: 'Available', id: 'core.transaction.available' })}:{' '}
        <span className={style.available} data-testid={DataTestIds.AmountAvailableField}>
          {itemFrom.balance} {itemFrom.symbol}
        </span>
      </div>

      <InteractiveModal isOpen={!!fetchingError}>
        <InteractiveModalParts.Icon icon="transfer" />
        <InteractiveModalParts.Header text={t({ message: 'Transfer', id: 'core.transfer' })} />
        <InteractiveModalParts.MainInfo text={fetchingError} />
        <InteractiveModalParts.Button
          text={t({ id: 'core.close', message: `Close` })}
          variant={ButtonVariant.White}
          onClick={closeModal}
        />
      </InteractiveModal>
    </div>
  )
}

type AccountItem = {
  name: string
  symbol: string
  balance: string
}

interface AccountConfig {
  [EAccountType.USDT_FUTURE]: AccountItem
  [EAccountType.SPOT]: AccountItem
}

type AvailableSymbols = 'USDT'

function makeAccountConfig(
  { spotAccountBalance, futuresAccountBalance }: TransactionAssets,
  symbol: AvailableSymbols
): AccountConfig {
  return {
    SPOT: {
      name: instrumentNames[EAccountType.SPOT].name,
      symbol,
      balance: getFormattedBalance(spotAccountBalance.assets, symbol),
    },
    USDT_FUTURE: {
      name: instrumentNames[EAccountType.USDT_FUTURE].name,
      symbol,
      balance: getFormattedBalance(futuresAccountBalance.assets, symbol),
    },
  }
}

function getFormattedBalance(assets: BalanceAsset[], symbol: AvailableSymbols): string {
  const asset: BalanceAsset | undefined = assets.find(item => {
    return item.asset === symbol
  })

  return formatNumber(asset?.balance ?? 0)
}

export { StatisticsTransfer }
