import { FC, useMemo } from 'react'
import { KYCActivity, KYCTier, KYCWorkflowStatus } from '@/backend/models/KycDTO'
import { Button } from '@/ui/kit'
import { useAppSelector } from '@/utils'
import { t } from '@lingui/macro'
import style from './styles.module.scss'
import clsx from 'clsx'
import { useKYC } from '@/utils/hooks'
import { DataTestIds } from '@/utils/lib/dataTestIds'

export const LevelUpgradeButton: FC<{
  className?: string
  classNameWrap?: string
  handleUpgrade?: (...args: any[]) => any
}> = props => {
  const { className, handleUpgrade = () => undefined, classNameWrap } = props
  const { workflow, userKYC } = useAppSelector(state => state.kyc)
  const { isShowKycVerified, kycNextTier, isTierUpperBasic } = useKYC()

  const btn = useMemo(() => {
    const upgradeText = isShowKycVerified
      ? userKYC.activity === KYCActivity.WorkflowInProgress && isTierUpperBasic
        ? t({ message: 'Check my status', id: 'core.checkMyStatus' })
        : t({ message: 'Upgrade verification', id: 'kycLevels.upgrade' })
      : userKYC.kycTier === KYCTier.Unverified
      ? t({ id: 'core.button.verify', message: `Verify me` })
      : t({ id: 'core.button.addData', message: `Add data` })

    switch (true) {
      case userKYC.activity === KYCActivity.WorkflowInProgress && !isTierUpperBasic:
        // return <Button.WhiteBlured label={upgradeText} className={clsx(className, classNameWrap, style.btn)} disabled />
        return (
          <Button.White
            label={t({ message: 'Check my status', id: 'core.checkMyStatus' })}
            className={clsx(className, classNameWrap, style.btn)}
            onClick={handleUpgrade}
          />
        )
      case userKYC.activity === KYCActivity.WorkflowInProgress && isTierUpperBasic:
        return (
          <Button.Accent
            label={upgradeText}
            className={clsx(className, classNameWrap, style.btn)}
            onClick={handleUpgrade}
            dataTestId={DataTestIds.ProfileKycButton}
          />
        )
      case workflow.status === KYCWorkflowStatus.Rejected:
        return (
          <Button.White
            label={t({ message: 'Try again now', id: 'kycLevels.tryAgain' })}
            className={clsx(className, classNameWrap, style.btn)}
            onClick={handleUpgrade}
            dataTestId={DataTestIds.ProfileKycButton}
          />
        )
      case kycNextTier === null:
        return null
      case !isShowKycVerified:
        return (
          <Button.White
            label={upgradeText}
            className={clsx(className, classNameWrap, style.btn)}
            onClick={handleUpgrade}
            dataTestId={DataTestIds.ProfileKycButton}
          />
        )
      default:
        return (
          <Button.Accent
            label={upgradeText}
            className={clsx(className, classNameWrap, style.btn)}
            onClick={handleUpgrade}
            dataTestId={DataTestIds.ProfileKycButton}
          />
        )
    }
  }, [
    workflow.status,
    className,
    handleUpgrade,
    isShowKycVerified,
    userKYC.activity,
    userKYC.kycTier,
    classNameWrap,
    isTierUpperBasic,
    kycNextTier,
  ])

  return btn
}
