import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import style from './style.module.scss'
import { useNavigate } from 'react-router-dom'
import { navigateToEditAddresses } from '@/router/urls'
import { Button } from '@/ui/kit'
import { SVG } from '@/assets/svg'
import { Popup } from '@/ui/molecules'
import { AddressRemove } from '@/ui/organisms/AddressRemove'
import { useActions, useAppSelector } from '@/utils'
import { addressAsyncActions } from '@/redux/address/address.actions'
import { TAddressView } from '@/redux/address/address.types'
import { addressActions } from '@/redux/address/address.slice'
import clsx from 'clsx'

type AddressBarProps = TAddressView
type RemoveAddress = {
  id: string
  name: string
}
const AddressBar: FC<AddressBarProps> = props => {
  const { id, currency, address, networkName, name } = props
  const navigate = useNavigate()
  const { deleteAddressTC } = useActions(addressAsyncActions)
  const { addressRemoveStatus } = useAppSelector(state => state.address)
  const { setCleanRemoveStatus } = useActions(addressActions)

  const [removeAddress, setRemoveAddress] = useState<RemoveAddress | null>(null)
  const isOpen = Boolean(removeAddress)
  const handleClickRemoveCancel = useCallback(() => setRemoveAddress(null), [])

  const handleEdit = (id: string) => {
    navigate(navigateToEditAddresses(id))
  }

  const handleClickRemoveConfirm = useCallback(
    (googleCode: string) => {
      if (!removeAddress) return
      deleteAddressTC({
        googleCode,
        addressId: id,
      })
    },
    [removeAddress, deleteAddressTC, id]
  )

  useEffect(() => {
    if (addressRemoveStatus === 'succeeded') {
      setRemoveAddress(null)
    }
    return () => {
      setCleanRemoveStatus()
    }
  }, [addressRemoveStatus, setCleanRemoveStatus])

  const isRemoveSubmitDisabled = useMemo(() => addressRemoveStatus === 'loading', [addressRemoveStatus])

  return (
    <>
      <Popup isOpen={isOpen}>
        <AddressRemove
          apiKey={removeAddress?.id!}
          name={removeAddress?.name!}
          onCancel={handleClickRemoveCancel}
          onSubmit={handleClickRemoveConfirm}
          isSubmitDisabled={isRemoveSubmitDisabled}
        />
      </Popup>
      <div className={style.list}>
        <div className={style.cryptocurrency}>{currency}</div>
        <div className={style.network}>{networkName}</div>
        <div className={clsx(style.ellipsis, style.note)}>{name}</div>
        <div className={clsx(style.ellipsis, style.address)}>{address}</div>
        <div className={style.edit}>
          <Button.Primary
            onClick={() => handleEdit(id)}
            className={style.closeButton}
            leftIcon={<SVG.Additional.Gear width="18px" height="18px" />}
          />
          <Button.Primary
            onClick={() => setRemoveAddress({ name, id })}
            className={style.closeButton}
            leftIcon={<SVG.Additional.Close width="10px" height="10px" />}
          />
        </div>
      </div>
    </>
  )
}

export { AddressBar }
