import { FC } from 'react'

type BybitIconType = {
  className?: string
  size?: number
}

export const BybitIcon: FC<BybitIconType> = ({ className, size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <rect width={24} height={24} fill="#1C1A24" rx={6.72} />
    <path fill="#F7A600" d="M15.83 13.626V9h.93v4.626h-.93Z" />
    <path
      fill="#fff"
      d="M4.993 15H3v-4.626h1.913c.93 0 1.472.508 1.472 1.3 0 .514-.348.844-.59.955.288.13.656.422.656 1.04 0 .863-.609 1.33-1.458 1.33Zm-.153-3.82h-.91v1.066h.91c.395 0 .616-.215.616-.533 0-.318-.221-.532-.616-.532Zm.06 1.878h-.97v1.136h.969c.422 0 .622-.26.622-.571 0-.312-.2-.565-.621-.565Zm4.387.044V15h-.923v-1.898l-1.431-2.728h1.01l.89 1.865.876-1.865h1.01l-1.432 2.728ZM13.354 15h-1.993v-4.626h1.913c.93 0 1.472.508 1.472 1.3 0 .514-.348.844-.59.955.288.13.656.422.656 1.04 0 .863-.608 1.33-1.458 1.33Zm-.153-3.82h-.91v1.066h.91c.394 0 .615-.215.615-.533 0-.318-.221-.532-.615-.532Zm.06 1.878h-.97v1.136h.97c.421 0 .622-.26.622-.571 0-.312-.201-.565-.623-.565Zm6.495-1.878V15h-.93v-3.82h-1.244v-.806H21v.807h-1.244Z"
    />
  </svg>
)
