import { FC } from 'react'

export const Volume: FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="url(#paint0_linear_15434_25360)" />
      <path
        d="M23.5 14H20M16.5 26H20M20 14H19C17.3431 14 16 15.3431 16 17V17C16 18.6569 17.3431 20 19 20H21C22.6569 20 24 21.3431 24 23V23C24 24.6569 22.6569 26 21 26H20M20 14V11M20 26V29"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15434_25360"
          x1="6.43533e-07"
          y1="40"
          x2="22.3945"
          y2="-8.18273"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#24C8DE" />
          <stop offset="1" stopColor="#4EED34" />
        </linearGradient>
      </defs>
    </svg>
  )
}
