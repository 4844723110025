import { useState, useCallback } from 'react'
import { useActions } from '@/utils'
import { voucherActions } from '@/redux/voucher/voucher.slice'
import { cashbackActions } from '@/redux/cashback/cashback.slice'
import { Voucher } from '@/backend/models/VoucherDTO'

export const useApi = () => {
  const { ApplyVoucherCodeTC, GetVoucherOverviewTC } = useActions(voucherActions)
  const { GetCashbackTC } = useActions(cashbackActions)

  const [voucher, setVoucher] = useState<Voucher>()
  const [errorCode, setErrorCode] = useState<string>()

  const applyVoucherCode = useCallback(async (code: string) => {
    if (code.length < 3) return

    const response = await ApplyVoucherCodeTC({ code })

    if ('error' in response) {
      // @ts-ignore
      setErrorCode(response?.payload?.codes?.[0])
      setVoucher(undefined)
    } else {
      // @ts-ignore
      setVoucher(response?.payload)
      setErrorCode(undefined)
      GetVoucherOverviewTC()
      GetCashbackTC()
    }
  }, [])

  const reset = useCallback(() => {
    setErrorCode(undefined)
    setVoucher(undefined)
  }, [])

  return {
    applyVoucherCode,
    errorCode,
    voucher,
    reset,
  }
}
