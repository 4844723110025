import { createSlice } from '@reduxjs/toolkit'
import { teamAsyncActions } from './team.actions'
import { initialState } from './team.state'
import { E_TEAM_USER_MODE, TTeamManagerInfo, TTeamUserInfo } from './team.types'
import { DEFAULT_ERROR_OBJECT } from '@/core/constants/common'
import { RISK_MANAGER_DATA_DEFAULT, TEAM_USER_DATA_DEFAULT, TRADING_STATUS_DEFAULT } from './team.defaults'
import { enqueueSnackbar } from 'notistack'

export const slice = createSlice({
  name: `[Tiger Trade Account team]`,
  initialState,
  reducers: {
    setRequestedTeamReset: state => {
      state.teamRequestedRequestStatus = 'idle'
      state.teamRequested = undefined
      state.teamRequestedErrors = DEFAULT_ERROR_OBJECT
    },
    setSearch(state, { payload }: { payload: string }) {
      state.managerMembersListSearch = payload
    },
    setTeamLeaveReset: state => {
      state.teamLeaveRequestStatus = 'idle'
    },
    setTeamJoinReset: state => {
      state.teamJoinRequestStatus = 'idle'
    },
    setSetTeamRiskManagerReset: state => {
      state.rmSetRequestStatus = 'idle'
    },
    setUpdateTeamRiskManagerReset: state => {
      state.rmUpdateRequestStatus = 'idle'
    },
    setDeleteTeamRiskManagerReset: state => {
      state.rmDeleteRequestStatus = 'idle'
    },
    setCashbackStatusReset: state => {
      state.changeCashbackRequestStatus = 'idle'
    },
    setTeamCancelJoinReset: state => {
      state.teamCancelJoinRequestStatus = 'idle'
    },
    setGetTeamRiskManagerReset: state => {
      state.rmGetRequestStatus = 'idle'
      state.riskManagerSettings = RISK_MANAGER_DATA_DEFAULT
    },
    setErrors: state => {
      state.errors = []
    },
    setChangeCashbackRequestReset: state => {
      state.changeCashbackRequestStatus = 'idle'
    },
    setChangeTeamMemberDefaultCashbackRequestReset: state => {
      state.changeManagerDefaultCashbackStatus = 'idle'
    },
    setTeamInfoRequestReset: state => {
      state.teamInfoRequestInitialStatus = 'idle'
    },
  },
  extraReducers: builder => {
    builder
      .addCase(teamAsyncActions.GetRequestedTeamTC.pending, (state, action) => {
        state.teamRequestedRequestStatus = 'loading'
        state.teamRequestedErrors = DEFAULT_ERROR_OBJECT
      })
      .addCase(teamAsyncActions.GetRequestedTeamTC.rejected, (state, action) => {
        state.teamRequestedRequestStatus = 'failed'
        state.teamRequestedErrors = action.payload || DEFAULT_ERROR_OBJECT
      })
      .addCase(teamAsyncActions.GetRequestedTeamTC.fulfilled, (state, action) => {
        state.teamRequestedRequestStatus = 'succeeded'
        state.teamRequested = action.payload
      })
      .addCase(teamAsyncActions.GetUserTeamInfoTC.pending, (state, action) => {
        state.teamInfoRequestStatus = 'loading'
        if (state.teamInfoRequestInitialStatus === 'idle') {
          state.teamInfoRequestInitialStatus = 'loading'
        }
      })
      .addCase(teamAsyncActions.GetUserTeamInfoTC.rejected, (state, action) => {
        state.teamInfoRequestStatus = 'failed'
        if (state.teamInfoRequestInitialStatus === 'loading') {
          state.teamInfoRequestInitialStatus = 'failed'
        }
      })
      .addCase(teamAsyncActions.GetUserTeamInfoTC.fulfilled, (state, action) => {
        state.teamInfoRequestStatus = 'succeeded'
        state.teamInfoRequestInitialStatus = 'succeeded'
        state.userMode = action.payload.mode
        if (action.payload.mode === E_TEAM_USER_MODE.user || action.payload.mode === E_TEAM_USER_MODE.noTeam) {
          state.teamUserInfo = { ...TEAM_USER_DATA_DEFAULT, ...(action.payload.data as TTeamUserInfo) }
        } else if (action.payload.mode === E_TEAM_USER_MODE.manager) {
          state.teamManagerInfo = action.payload.data as TTeamManagerInfo
        }
      })
      .addCase(teamAsyncActions.GetManagerMembersTC.pending, (state, action) => {
        state.managerMembersListRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.GetManagerMembersTC.rejected, (state, action) => {
        state.managerMembersListRequestStatus = 'failed'
        enqueueSnackbar('Error, try again', { variant: 'error' })
      })
      .addCase(teamAsyncActions.GetManagerMembersTC.fulfilled, (state, action) => {
        if (action.payload.page === 0) state.managerMembersList = action.payload
        else
          state.managerMembersList = {
            ...action.payload,
            members: [...state.managerMembersList.members, ...action.payload.members],
          }
        if (action.payload.total / action.payload.size < action.payload.page)
          state.managerMembersList.managerDataReachedEnd = true
        else state.managerMembersList.managerDataReachedEnd = false

        state.managerMembersListRequestStatus = 'succeeded'
      })
      .addCase(teamAsyncActions.GetManagerRequestsTC.pending, (state, action) => {
        state.managerRequestsListRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.GetManagerRequestsTC.rejected, (state, action) => {
        state.managerRequestsListRequestStatus = 'failed'
        enqueueSnackbar('Error, try again', { variant: 'error' })
      })
      .addCase(teamAsyncActions.GetManagerRequestsTC.fulfilled, (state, action) => {
        if (action.payload.page === 0) state.managerRequestsList = action.payload
        else
          state.managerRequestsList = {
            ...action.payload,
            requests: [...state.managerRequestsList.requests, ...action.payload.requests],
          }

        if (action.payload.total / action.payload.size < action.payload.page)
          state.managerRequestsList.managerDataReachedEnd = true
        else state.managerMembersList.managerDataReachedEnd = false

        state.managerRequestsListRequestStatus = 'succeeded'
      })
      .addCase(teamAsyncActions.HandleManagerRequests.pending, (state, action) => {
        state.managerRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.HandleManagerRequests.rejected, (state, action) => {
        state.managerRequestStatus = 'failed'
        enqueueSnackbar('Error, try again', { variant: 'error' })
      })
      .addCase(teamAsyncActions.HandleManagerRequests.fulfilled, (state, action) => {
        state.managerRequestStatus = 'succeeded'
      })
      .addCase(teamAsyncActions.TeamJoinTC.pending, (state, action) => {
        state.teamJoinRequestStatus = 'loading'
        state.teamJoinErrors = DEFAULT_ERROR_OBJECT
      })
      .addCase(teamAsyncActions.TeamJoinTC.rejected, (state, action) => {
        state.teamJoinRequestStatus = 'failed'
        state.teamJoinErrors = action.payload || DEFAULT_ERROR_OBJECT
      })
      .addCase(teamAsyncActions.TeamJoinTC.fulfilled, (state, action) => {
        state.teamJoinRequestStatus = 'succeeded'
      })
      .addCase(teamAsyncActions.TeamCancelJoinTC.pending, (state, action) => {
        state.teamCancelJoinRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.TeamCancelJoinTC.rejected, (state, action) => {
        state.teamCancelJoinRequestStatus = 'failed'
      })
      .addCase(teamAsyncActions.TeamCancelJoinTC.fulfilled, (state, action) => {
        state.teamCancelJoinRequestStatus = 'succeeded'
      })
      .addCase(teamAsyncActions.TeamLeaveTC.pending, (state, action) => {
        state.teamLeaveRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.TeamLeaveTC.rejected, (state, action) => {
        state.teamLeaveRequestStatus = 'failed'
      })
      .addCase(teamAsyncActions.TeamLeaveTC.fulfilled, (state, action) => {
        state.teamLeaveRequestStatus = 'succeeded'
      })
      .addCase(teamAsyncActions.GetTeamRiskManagerTC.pending, (state, action) => {
        state.rmGetRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.GetTeamRiskManagerTC.rejected, (state, action) => {
        state.rmGetRequestStatus = 'failed'
      })
      .addCase(teamAsyncActions.GetTeamRiskManagerTC.fulfilled, (state, action) => {
        state.rmGetRequestStatus = 'succeeded'
        state.riskManagerSettings = {
          ...RISK_MANAGER_DATA_DEFAULT,
          ...action.payload,
        }
      })
      .addCase(teamAsyncActions.SetTeamRiskManagerTC.pending, (state, action) => {
        state.rmSetRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.SetTeamRiskManagerTC.rejected, (state, action) => {
        state.rmSetRequestStatus = 'failed'
        state.errors = action.payload?.fieldsErrors! || []
      })
      .addCase(teamAsyncActions.SetTeamRiskManagerTC.fulfilled, (state, action) => {
        state.rmSetRequestStatus = 'succeeded'
      })
      .addCase(teamAsyncActions.UpdateTeamRiskManagerTC.pending, (state, action) => {
        state.rmUpdateRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.UpdateTeamRiskManagerTC.rejected, (state, action) => {
        state.rmUpdateRequestStatus = 'failed'
        state.errors = action.payload?.fieldsErrors! || []
      })
      .addCase(teamAsyncActions.UpdateTeamRiskManagerTC.fulfilled, (state, action) => {
        state.rmUpdateRequestStatus = 'succeeded'
        state.riskManagerSettings = {
          ...state.riskManagerSettings!,
          ...action.meta.arg,
        }
      })
      .addCase(teamAsyncActions.DeleteTeamRiskManagerTC.pending, (state, action) => {
        state.rmDeleteRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.DeleteTeamRiskManagerTC.rejected, (state, action) => {
        state.rmDeleteRequestStatus = 'failed'
      })
      .addCase(teamAsyncActions.DeleteTeamRiskManagerTC.fulfilled, (state, action) => {
        state.rmDeleteRequestStatus = 'succeeded'
        state.riskManagerSettings = {
          ...state.riskManagerSettings!,
          enabled: false,
        }
        if (state.teamUserInfo) state.teamUserInfo.riskManagerEnabled = false
      })
      .addCase(teamAsyncActions.GetTeamRiskManagerTradingStatusTC.pending, (state, action) => {
        state.tradingStatusRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.GetTeamRiskManagerTradingStatusTC.rejected, (state, action) => {
        state.tradingStatusRequestStatus = 'failed'
      })
      .addCase(teamAsyncActions.GetTeamRiskManagerTradingStatusTC.fulfilled, (state, action) => {
        state.tradingStatusRequestStatus = 'succeeded'
        state.tradingStatus = {
          ...TRADING_STATUS_DEFAULT,
          ...action.payload,
        }
      })
      .addCase(teamAsyncActions.ChangeMemberCashbackTC.pending, (state, action) => {
        state.changeCashbackRequestStatus = 'loading'
      })
      .addCase(teamAsyncActions.ChangeMemberCashbackTC.rejected, (state, action) => {
        state.changeCashbackRequestStatus = 'failed'
        state.errors = action.payload?.fieldsErrors! || []
      })
      .addCase(teamAsyncActions.ChangeMemberCashbackTC.fulfilled, (state, action) => {
        state.changeCashbackRequestStatus = 'succeeded'
      })

      .addCase(teamAsyncActions.ChangeTeamManagerDefaultCashbackTC.pending, (state, action) => {
        state.changeManagerDefaultCashbackStatus = 'loading'
      })
      .addCase(teamAsyncActions.ChangeTeamManagerDefaultCashbackTC.rejected, (state, action) => {
        state.changeManagerDefaultCashbackStatus = 'failed'
        state.errors = action.payload?.fieldsErrors! || []
      })
      .addCase(teamAsyncActions.ChangeTeamManagerDefaultCashbackTC.fulfilled, (state, action) => {
        state.changeManagerDefaultCashbackStatus = 'succeeded'
      })

      .addCase(teamAsyncActions.DeleteMemberTC.pending, (state, action) => {
        state.deleteMemberStatus = 'loading'
      })
      .addCase(teamAsyncActions.DeleteMemberTC.rejected, (state, action) => {
        state.deleteMemberStatus = 'failed'
      })
      .addCase(teamAsyncActions.DeleteMemberTC.fulfilled, (state, action) => {
        state.deleteMemberStatus = 'succeeded'
      })

      .addCase(teamAsyncActions.GetMemberCashbackTC.pending, (state, action) => {
        state.teamManagerOwnCashbackStatus = 'loading'
      })
      .addCase(teamAsyncActions.GetMemberCashbackTC.rejected, (state, action) => {
        state.teamManagerOwnCashbackStatus = 'failed'
      })
      .addCase(teamAsyncActions.GetMemberCashbackTC.fulfilled, (state, action) => {
        state.teamManagerOwnCashbackStatus = 'succeeded'
        state.teamManagerOwnCashback = action.payload
      })
  },
})

export const teamActions = {
  ...slice.actions,
  ...teamAsyncActions,
}

export const teamReducer = slice.reducer
