import { TradesMonthAnalyzerPairSort } from '@/redux/trades/trades.types'
import { SelectOption } from '@/ui/kit'
import { t } from '@lingui/macro'

export const sortOptions = () => {
  const profitabilityOptions: SelectOption<TradesMonthAnalyzerPairSort>[] = [
    {
      label: t({
        id: 'trades.summary.month.sortSymbol',
      }),
      value: TradesMonthAnalyzerPairSort.SYMBOL,
    },
    {
      label: t({
        id: 'trades.summary.month.sortCount',
      }),
      value: TradesMonthAnalyzerPairSort.COUNT,
    },
    {
      label: t({
        id: 'trades.summary.month.sortWinCount',
      }),
      value: TradesMonthAnalyzerPairSort.WIN_COUNT,
    },
    {
      label: t({
        id: 'trades.summary.month.sortNetProfit',
      }),
      value: TradesMonthAnalyzerPairSort.NET_PROFIT,
    },
    {
      label: t({
        id: 'trades.summary.month.sortLeverage',
      }),
      value: TradesMonthAnalyzerPairSort.LEVERAGE,
    },
  ]
  return profitabilityOptions
}
