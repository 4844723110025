import { DiaryWeekInfo } from '@/ui/organisms'
import style from './style.module.scss'
import { useParams } from 'react-router-dom'
import { TableError } from '@/ui/organisms/HistoryTrades/components/TableError/TableError'
import { useAppSelector } from '@/utils'

export const DiaryWeekPage = () => {
  const { availableUsersTradingStatus, availableUsersTrading } = useAppSelector(state => state.trades)
  const { tradeDiaryWeek } = useParams()
  let formattedTradeDiaryWeek

  if (tradeDiaryWeek) {
    const [startDate, endDate] = tradeDiaryWeek.split(/-(?=\d{4}-\d{2}-\d{2}$)/)
    formattedTradeDiaryWeek = `${startDate},${endDate}`
  }

  if (availableUsersTradingStatus === 'succeeded' && !availableUsersTrading?.isStatisticAvailable)
    return <TableError technicalError={true} />

  return (
    <div className={style.root}>
      <DiaryWeekInfo openBetween={formattedTradeDiaryWeek} />
    </div>
  )
}
