import { t } from '@lingui/macro'
import React, { FC } from 'react'
import { SVG } from '../../../assets/svg'
import style from './style.module.scss'

export const KYCVerificationInfo: FC = () => {
  return (
    <div className={style.info}>
      <div className={style.title}>
        {t({
          id: 'kycPage.info.title',
          message: 'Why do I need to complete verification?',
        })}
      </div>
      <div className={style.text}>
        {t({
          id: 'kycPage.info.message-1',
          message:
            'Verification of all users is our legal requirement to comply with the Financial Action Task Force (FATF) Regulations.',
        })}
        <br />
        <br />
        {t({
          id: 'kycPage.info.message-2',
          message:
            'User verification helps eliminate the risks of fraud, money laundering, and other illegal activities, and ensures the two-way security for users and the business.',
        })}
      </div>
      <div className={style.qIcon}>
        <SVG.OtherIcons.QuestionWithBorder />
      </div>
      <div className={style.pIcon}>
        <SVG.OtherIcons.Person />
      </div>
    </div>
  )
}
