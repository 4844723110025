import React from 'react'

enum ResultCodeEnum {
  ERROR,
  SUCCESS,
  NOT_FOUND,
}

export type ResponseType<D> = {
  data: D
  ResultCode: ResultCodeEnum

  message: string
}

export type ISO = string

export type RequestStatusType = 'idle' | 'loading' | 'succeeded' | 'failed'

export enum AvailableScreenSize {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Auto = 'auto',
}

export type FieldErrorType = {
  code: number | string
  field: string
  message: string
}

export type ThunkErrorValue = {
  codes: (string | number)[]
  errors: string[]
  fieldsErrors?: FieldErrorType[]
}

export type ThunkError = {
  rejectValue: ThunkErrorValue
}

export type RouterComponentType = {
  path: string
  component: null | React.ReactElement
  onlyAvailable?: AvailableScreenSize
  exact?: boolean
}

interface IDeepPartialArray<T> extends Array<DeepPartial<T>> {}

type DeepPartialObject<T> = {
  [Key in keyof T]?: DeepPartial<T[Key]>
}

export type DeepPartial<T> = T extends Function
  ? T
  : T extends Array<infer U>
  ? IDeepPartialArray<U>
  : T extends object
  ? DeepPartialObject<T>
  : T | undefined

export interface RejectedWithValueAction<ThunkArg> {
  type: string
  payload: ThunkErrorValue
  error: { message: 'Rejected' }
  meta: {
    requestId: string
    arg: ThunkArg
    aborted: boolean
  }
}

export type PartialK<T, K extends PropertyKey = PropertyKey> = Partial<Pick<T, Extract<keyof T, K>>> &
  Omit<T, K> extends infer O
  ? { [P in keyof O]: O[P] }
  : never

export interface ILoadCallback {
  loadCallback: (isLoaded: boolean) => void
}

export interface ICacheManager {
  set: (key: string, value: any) => Promise<boolean>
  get: (key: string) => Promise<any>
  del: (key: string) => Promise<boolean>
}
