import { useCallback, useRef } from 'react'
import { useIntersectionObserver } from './useIntersectionObserver'

export const useInfiniteScroll = (scrollCallback: IntersectionObserverCallback) => {
  const tableRef = useRef<HTMLDivElement | null>(null)

  const refCallback = useCallback((newRef: HTMLDivElement | null) => {
    tableRef.current = newRef
  }, [])

  useIntersectionObserver(tableRef, scrollCallback, {
    threshold: 0,
  })

  return {
    tableRef,
    refCallback,
  }
}
