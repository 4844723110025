import { FC, memo } from 'react'
import EmptyList from '@/ui/molecules/EmptyList'
import CashbackHistoryItem from '../CashbackHistoryItem'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { useAppSelector } from '@/utils'
import { SkeletonList } from '@/ui/kit'
import { Loader } from '@/ui/kit/Loader'

const CashbackRightBarList: FC = () => {
  const { historyCashback, historyCashbackStatus } = useAppSelector(state => state.history)

  return (
    <div className={style.cashbackRightBarList}>
      {/*<div className={style.cashbackRightBarListItems}>*/}
      {historyCashbackStatus === 'loading' && historyCashback.cashback.length === 0 ? (
        <SkeletonList />
      ) : (
        <>
          {historyCashback.cashback.map(item => (
            <CashbackHistoryItem key={item.id} {...item} />
          ))}

          {historyCashbackStatus === 'loading' && historyCashback.cashback.length > 0 && <Loader />}

          {!Boolean(historyCashback.cashback.length) && (
            <EmptyList
              label={t({
                id: 'cashbackPage.historyEmpty',
                message: 'History of your saved commissions will be displayed here',
              })}
            />
          )}
        </>
      )}
    </div>
  )
}

export default memo(CashbackRightBarList)
