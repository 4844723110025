import { SVG } from '@/assets/svg'
import style from '../style.module.scss'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import { Button, ButtonVariant } from '@/ui/kit'
import { FC } from 'react'

type KycStatusNewUserInitProps = {
  handleNavigateToKyc: () => void
  handleNavigateToChooseExchange: () => void
}

const KycStatusNewUserInit: FC<KycStatusNewUserInitProps> = ({
  handleNavigateToKyc,
  handleNavigateToChooseExchange,
}) => {
  return (
    <div className={style.container}>
      <div className={style.title}>
        {t({ id: 'binanceLink.reverificationBinance', message: 'Повторная верификация Binance' })}
      </div>
      <div className={style.content}>
        <div className={style.subTitle}>
          {t({
            id: 'binanceLink.whatItMeanTitle',
            message: 'What does it mean?',
          })}
        </div>
        <div>
          <Trans
            id="binanceLink.requiredInformationForVerifyingYourAccountIncomplete"
            message="Требуемая информация для верификации вашего аккаунта оказалось неполной или произошла ошибка. Подготовьте еще раз ваш ID для фото и селфи."
          />
        </div>
      </div>
      <Button variant={ButtonVariant.Accent} onClick={handleNavigateToKyc} className={style.verifyButton}>
        {t({ id: 'kycLinkStatus.completeVerification', message: 'Complete verification' })}
      </Button>

      <div className={style.chooseExchangeBlock}>
        <div>
          <SVG.OtherIcons.Lightning />
          <div className={style.textWhite}>
            <Trans
              id="binanceLink.youCanTradeWithoutBinanceVerify"
              message="Вы уже можете торговать на OKX и ByBit независимо от верификации Binance!"
            />
          </div>
        </div>

        <Button
          className={style.chooseExchangeButton}
          variant={ButtonVariant.AccentDark}
          onClick={handleNavigateToChooseExchange}
        >
          {t({ id: 'binanceLink.chooseExchange', message: 'Выбрать ByBit или OKX' })}
        </Button>
      </div>
    </div>
  )
}
export { KycStatusNewUserInit }
