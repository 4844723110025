/** Allow to copy string to clipboard */

function oldSchoolCopy(text: string | number) {
  const textArea = document.createElement('textarea')
  textArea.style.opacity = '0'
  textArea.style.position = 'absolute'
  textArea.value = String(text)
  document.body.appendChild(textArea)
  textArea.select()
  document.execCommand('copy', false, undefined)
  document.body.removeChild(textArea)
}

export const copyToClipboard = async (text: string | number) => {
  try {
    if (navigator?.clipboard?.writeText) {
      await navigator.clipboard.writeText(String(text))
    } else {
      oldSchoolCopy(text)
    }
  } catch (e) {
    oldSchoolCopy(text)
  }
}
