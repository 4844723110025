import { t } from '@lingui/macro'
import clsx from 'clsx'
import { type FC, memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { SVG } from '@/assets/svg'
import { EXCHANGES_AVAILABLE } from '@/core/constants'
import { urls } from '@/router/urls'
import { Button, ButtonSize } from '@/ui/kit'
import { useAppSelector } from '@/utils'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import s from './style.module.scss'
import { isExchangeBinance } from '@/utils/lib/exchange'

interface Props {
  className?: string
}

export const MobileTabButtons: FC<Props> = memo(({ className }) => {
  const { instrumentType, exchangeType, statusBalances } = useAppSelector(state => state.overview)
  const navigate = useNavigate()

  const BUTTONS = [
    {
      id: 1,
      label: t({ message: `Deposit`, id: 'core.deposit' }),
      icon: <SVG.Actions.Deposit />,
      href: urls.transactions,
      btnType: 'deposit',
      dataTestId: DataTestIds.WalletTabDepositButton,
      exchangeType: EXCHANGES_AVAILABLE,
    },
    {
      id: 2,
      label: t({ message: 'Withdraw', id: 'core.withdraw' }),
      icon: <SVG.Actions.Withdraw />,
      href: urls.transactions,
      btnType: 'withdraw',
      dataTestId: DataTestIds.WalletTabWithdrawButton,
      exchangeType: EXCHANGES_AVAILABLE,
    },
    {
      id: 3,
      label: t({ message: `Transfer`, id: 'core.transfer' }),
      icon: <SVG.Actions.Transfer />,
      href: urls.transactions,
      btnType: 'transfer',
      dataTestId: DataTestIds.WalletTabTransferButton,
      exchangeType: ['BINANCE_BROKER_FUTURE'],
    },
  ]

  const onClickButton = useCallback(
    (href: string, type: string) => {
      localStorage.setItem('transactionType', type)
      navigate(href)
    },
    [navigate]
  )

  const renderItem = useCallback(
    (item: (typeof BUTTONS)[number]) => {
      if (!item.exchangeType.includes(exchangeType)) {
        return null
      }

      return (
        <Button.Primary
          key={item.id}
          label={item.label}
          rightIcon={item.icon}
          onClick={() => onClickButton(item.href, item.btnType)}
          dataTestId={item.dataTestId}
          size={ButtonSize.Medium}
        />
      )
    },
    [onClickButton, exchangeType]
  )

  if (statusBalances === 'loading') {
    return (
      <div className={clsx(s.wrapper, className)}>
        {BUTTONS.map((_, index) => (
          <div key={index} className={s.skeleton} />
        ))}
      </div>
    )
  }

  if (isExchangeBinance(exchangeType)) {
    if (instrumentType === 'SPOT' || instrumentType === 'spot') {
      return <div className={clsx(s.wrapper, className)}>{BUTTONS.map(renderItem)}</div>
    }

    return (
      <div className={clsx(s.wrapper, className)}>
        <Button.Primary
          onClick={() => onClickButton(urls.transactions, 'transfer')}
          label={t({ message: 'Transfer', id: 'core.transfer' })}
          rightIcon={<SVG.Actions.Transfer />}
          dataTestId={DataTestIds.WalletTabTransferButton}
          size={ButtonSize.Medium}
        />
      </div>
    )
  }

  return <div className={clsx(s.wrapper, className)}>{BUTTONS.map(renderItem)}</div>
})
