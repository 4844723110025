import * as yup from 'yup'
import { E_TEAM_PERMISSIONS } from '@/redux/team/team.types'
import { t } from '@lingui/macro'
import { ITeamFormSubscription } from '../../TeamForm.Types'

export const VALIDATION_SCHEMA = (): yup.ObjectSchema<ITeamFormSubscription> =>
  yup.object({
    teamCode: yup.string().required(t({ id: 'teamSubscriptionForm.errors.teamCode' })),
    permissions: yup
      .array()
      .default([])
      .test('permissions', t({ id: 'teamSubscriptionForm.errors.permissions' }), value => {
        const referencePermissions = Object.values(E_TEAM_PERMISSIONS)
        return (
          value.length === referencePermissions.length &&
          referencePermissions.every(permission => {
            return value.includes(permission)
          })
        )
      }),
  })
