export const QuestionWithBG = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="7" fill="#1C1A24" />
      <path
        d="M10.1137 11.4819C10.4279 11.4819 10.585 11.2817 10.6312 10.9596C10.6681 10.5154 10.816 10.2811 11.4213 9.91009C12.0497 9.5147 12.4008 9.00215 12.4008 8.25531C12.4008 7.15212 11.5414 6.40039 10.2893 6.40039C9.33744 6.40039 8.61665 6.81042 8.33018 7.47429C8.24239 7.65978 8.20081 7.84039 8.20081 8.05029C8.20081 8.37734 8.39024 8.58236 8.70906 8.58236C8.96318 8.58236 9.13414 8.46032 9.23579 8.15768C9.38364 7.66466 9.72556 7.39619 10.2384 7.39619C10.8067 7.39619 11.1995 7.76717 11.1995 8.29924C11.1995 8.76785 11.0054 9.03632 10.4325 9.40242C9.85493 9.76364 9.56846 10.1639 9.56846 10.7692V10.8619C9.56846 11.2183 9.76252 11.4819 10.1137 11.4819ZM10.1229 13.6004C10.4972 13.6004 10.7929 13.2929 10.7929 12.9072C10.7929 12.5216 10.4972 12.219 10.1229 12.219C9.74866 12.219 9.44833 12.5216 9.44833 12.9072C9.44833 13.2929 9.74866 13.6004 10.1229 13.6004Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
