import { FC, useEffect, useRef, useState } from 'react'
import style from './style.module.scss'
import { AnalyzerMonthTickerItem } from '@/ui/molecules'
import { DayDiary } from '@/utils/types/contracts.types'
import { useActions, useAppSelector } from '@/utils'
import { tradesActions } from '@/redux/trades/trades.slice'
import { InputSearch, InputSize, Select } from '@/ui/kit'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { TradesMonthAnalyzerPairSort } from '@/redux/trades/trades.types'
import { sortOptions } from './sortOptions'

type PairsProps = {
  trades: DayDiary[]
}

type SortType = {
  value: TradesMonthAnalyzerPairSort
  sortDesc: boolean
}

export const Pairs: FC<PairsProps> = ({ trades }) => {
  const { setAnalyzerMonthTradeTicker } = useActions(tradesActions)
  const [search, setSearch] = useState('')
  const [filteredTrades, setFilteredTrades] = useState<DayDiary[]>(trades)
  const [activeTradeKey, setActiveTradeKey] = useState<string | null>(null)
  const { language } = useAppSelector(state => state.profile)
  const [selectValue, setSelectValue] = useState<SortType>({
    value: TradesMonthAnalyzerPairSort.SYMBOL,
    sortDesc: false,
  })
  const isEllipsis =
    language !== 'en' &&
    (selectValue.value === TradesMonthAnalyzerPairSort.WIN_COUNT ||
      selectValue.value === TradesMonthAnalyzerPairSort.NET_PROFIT)

  const generateTradeKey = (trade: DayDiary) => `${trade.symbol}-${trade.first_trade}`
  const isFirstRender = useRef(true)

  const handleItemClick = (tradeKey: string) => {
    setActiveTradeKey(tradeKey)
    const selectedTrade = filteredTrades.find(trade => generateTradeKey(trade) === tradeKey)
    if (selectedTrade) {
      setAnalyzerMonthTradeTicker(selectedTrade)
    }
  }

  useEffect(() => {
    const filtered = trades.filter(trade => trade.symbol.toLowerCase().includes(search.toLowerCase()))

    const sorted = filtered.sort((a: any, b: any) => {
      const aValue = a[selectValue.value]
      const bValue = b[selectValue.value]

      if (aValue < bValue) return selectValue.sortDesc ? 1 : -1
      if (aValue > bValue) return selectValue.sortDesc ? -1 : 1
      return 0
    })

    setFilteredTrades(sorted)
    if (isFirstRender.current) {
      isFirstRender.current = false
      if (filteredTrades.length > 0) {
        const firstTradeKey = generateTradeKey(sorted[0])
        setActiveTradeKey(firstTradeKey)
        setAnalyzerMonthTradeTicker(sorted[0])
      }
    }
  }, [search, trades, selectValue])

  const changeHandler = (value: TradesMonthAnalyzerPairSort) => {
    setSelectValue(prev => ({
      value,
      sortDesc: prev.value === value ? !prev.sortDesc : false,
    }))
  }

  return (
    <div className={style.root}>
      <div className={style.filterContainer}>
        <InputSearch
          value={search}
          setValue={setSearch}
          sizeSearch="small"
          size={InputSize.Small}
          containerClassName={style.search}
          placeholder={t({ comment: 'Ticker', id: 'trades.summary.month.tickerSearch' })}
        />
        <div className={style.sortContainer}>
          <div
            className={clsx(style.sortTitle, {
              [style.elips]: isEllipsis,
            })}
          >
            {t({ comment: 'sort by', id: 'trades.summary.month.sortByTitle' })}
          </div>
          <div>
            <Select
              onChange={changeHandler}
              size={Select.Size.Small}
              value={selectValue.value}
              options={sortOptions()}
              variant={Select.Variant.Raw}
              className={clsx(style.select, style.selectContainer)}
            />
          </div>
        </div>
      </div>
      <div className={style.tickerContainer}>
        {filteredTrades.map(el => (
          <AnalyzerMonthTickerItem
            trade={el}
            key={generateTradeKey(el)}
            isActive={activeTradeKey === generateTradeKey(el)}
            tickerHandler={() => handleItemClick(generateTradeKey(el))}
          />
        ))}
      </div>
    </div>
  )
}
