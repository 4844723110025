import React from 'react'

interface IArrowUpProps {
  className?: string
}

export const ArrowUp = (props: IArrowUpProps) => {
  const { className } = props

  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00837 2.56205L10.2311 7.63941C10.5843 8.06403 11.2148 8.12197 11.6394 7.76882C12.064 7.41567 12.122 6.78516 11.7688 6.36055L6.77873 0.360571C6.3794 -0.119572 5.64255 -0.120288 5.24229 0.359076L0.232425 6.35905C-0.12155 6.78298 -0.064839 7.4136 0.359092 7.76757C0.783023 8.12155 1.41364 8.06484 1.76762 7.64091L6.00837 2.56205Z"
        fill="white"
      />
    </svg>
  )
}
