import { Button, Input, InputSize, InteractiveModalParts } from '@/ui/kit'
import { t } from '@lingui/macro'
import { Controller, useForm } from 'react-hook-form'
import styles from '../style.module.scss'
import { FC, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { VALIDATION_SCHEMA } from '../TeamForm.Utils/validation/cashback.schema'
import { useActions, useAppSelector } from '@/utils'
import { useErrorsParser } from '@/utils/hooks/useErrorsParser'
import { getMemberCashbackError } from '@/backend/services/team'
import { teamActions } from '@/redux/team/team.slice'
import { TManagerCashback } from '@/redux/team/team.types'
import clsx from 'clsx'

type ITeamFormDefaultCashbackProps = {
  onClose: () => void
  cashbackDefaultPercent: Partial<TManagerCashback>
}

type OnsubmitData = {
  okx: number
  binance: number
  bybit: number
}

type CashbackUpdateForm = OnsubmitData & {
  globalError: any
}

export const TeamFormDefaultCashback: FC<ITeamFormDefaultCashbackProps> = props => {
  const {
    onClose,
    cashbackDefaultPercent: { binanceCashbackPercent, bybitCashbackPercent, okxCashbackPercent },
  } = props

  const {
    setErrors,
    ChangeTeamManagerDefaultCashbackTC,
    setChangeTeamMemberDefaultCashbackRequestReset,
    setTeamInfoRequestReset,
  } = useActions(teamActions)
  const { changeManagerDefaultCashbackStatus, errors: cashbackError } = useAppSelector(state => state.team)

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<CashbackUpdateForm>({
    defaultValues: {
      okx: okxCashbackPercent ?? 0,
      binance: binanceCashbackPercent ?? 0,
      bybit: bybitCashbackPercent ?? 0,
    },
    resolver: yupResolver(VALIDATION_SCHEMA()),
  })
  const getCashbackErrors = getMemberCashbackError()
  useErrorsParser(cashbackError, setError, getCashbackErrors)

  const globalErrorMessage = errors?.globalError?.message
  const fieldsErrors = !!Object.keys(errors).filter(key => key !== 'globalError').length

  const onSubmit = ({ okx, binance, bybit }: OnsubmitData) => {
    ChangeTeamManagerDefaultCashbackTC({
      okxCashbackPercent: okx,
      binanceCashbackPercent: binance,
      bybitCashbackPercent: bybit,
    })
  }

  useEffect(() => {
    return () => {
      setErrors()
      setChangeTeamMemberDefaultCashbackRequestReset()
      setTeamInfoRequestReset()
    }
  }, [])

  useEffect(() => {
    if (changeManagerDefaultCashbackStatus === 'succeeded') {
      onClose()
    }
  }, [changeManagerDefaultCashbackStatus, onClose])

  return (
    <form className={styles.formCashback}>
      <div className={styles.cashbackInputContainer}>
        <Controller
          control={control}
          name="binance"
          render={({ field }) => (
            <Input
              size={InputSize.Small}
              containerClassName={styles.cashbackInput}
              placeholder={t({
                id: 'teamMembers.cashbackPercent',
                message: 'percent',
              })}
              value={field.value}
              setValue={value => {
                field.onChange(value)
              }}
              inputAttrs={{ type: 'number' }}
              label={t({
                id: 'teamPage.defaultBinanceTitle',
                message: 'Binance',
              })}
            />
          )}
        />
        <Controller
          control={control}
          name="bybit"
          render={({ field }) => (
            <Input
              size={InputSize.Small}
              containerClassName={styles.cashbackInput}
              placeholder={t({
                id: 'teamMembers.cashbackPercent',
                message: 'percent',
              })}
              value={field.value}
              setValue={value => {
                field.onChange(value)
              }}
              inputAttrs={{ type: 'number' }}
              label={t({
                id: 'teamPage.defaultBybitTitle',
                message: 'Bybit',
              })}
            />
          )}
        />
        <Controller
          control={control}
          name="okx"
          render={({ field }) => (
            <Input
              size={InputSize.Small}
              containerClassName={styles.cashbackInput}
              placeholder={t({
                id: 'teamMembers.cashbackPercent',
                message: 'percent',
              })}
              value={field.value}
              setValue={value => {
                field.onChange(value)
              }}
              inputAttrs={{ type: 'number' }}
              label={t({
                id: 'teamPage.defaultOkxTitle',
                message: 'OKX',
              })}
            />
          )}
        />
      </div>
      {fieldsErrors && (
        <div className={styles.errorMessage}>
          <div>{errors.binance?.message}</div>
          <div>{errors.bybit?.message}</div>
          <div>{errors.okx?.message}</div>
        </div>
      )}
      {globalErrorMessage && <div className={styles.globalErrorCashback}>{globalErrorMessage}</div>}

      <div className={styles.btnWrapper}>
        <InteractiveModalParts.Button
          text={t({ id: 'teamMembers.DefaultCashback.submit', message: 'Save' })}
          variant={Button.Variant.White}
          onClick={handleSubmit(onSubmit)}
          className={clsx({
            [styles.btnSubmitWithMargin]: fieldsErrors && !globalErrorMessage,
            [styles.btnSubmit]: !fieldsErrors || globalErrorMessage,
          })}
        />
        <InteractiveModalParts.Button
          text={t({ id: 'teamMembers.DefaultCashback.close', message: 'Close' })}
          variant={Button.Variant.Primary}
          onClick={onClose}
        />
      </div>
    </form>
  )
}
