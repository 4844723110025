import BigNumber from 'bignumber.js'

/**
 * format incoming string or number to en-US integer format.
 * examples:
 * 1 → 1
 * 12345 → 12,345
 * '12345,1' → 12,345.1
 * 1.123456789 → 1.12345678 // 8 symbols after dot by default
 */
export const formatNumber = (
  input: string | number | null | void,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 8 }
): string => {
  if (typeof input !== 'number' && typeof input !== 'string') {
    console.warn(`Can not format value for input «${input}»`)
    return ''
  }

  const inputWithDotDivider = String(input).replace(',', '.')

  if (Number.isNaN(Number(inputWithDotDivider))) {
    return String(input)
  }

  return new Intl.NumberFormat('en-US', options).format(Number(inputWithDotDivider))
}

export const floorNumber = (input: string | number | null | void, fraction: number = 2): string => {
  if ((typeof input !== 'number' && typeof input !== 'string') || Number.isNaN(Number(input))) {
    console.warn(`Can not format value for input «${input}»`)
    return ''
  }

  const multiplier = Math.pow(10, fraction)
  const number = Math.floor(Number(input) * multiplier) / multiplier

  return formatNumber(number)
}

export const customRound = (value: string | number): string => {
  const val = Number(value)
  let roundedVal: string

  if (val >= 0.0995 || Math.abs(val) >= 0.0995) {
    roundedVal = new BigNumber(val).dp(2, BigNumber.ROUND_HALF_UP).toFixed(2)
  } else if ((val >= 0.0005 && val < 0.0995) || (Math.abs(val) >= 0.0005 && Math.abs(val) < 0.0995)) {
    roundedVal = new BigNumber(val).dp(3, BigNumber.ROUND_HALF_UP).toFixed(3)
  } else if (val !== 0) {
    const absVal = Math.abs(val)
    const decimalPlaces = absVal.toString().split('.')[1]?.length || 0
    if (decimalPlaces > 3) {
      roundedVal = new BigNumber(val).dp(4, BigNumber.ROUND_HALF_UP).toFixed(4)
    } else {
      roundedVal = new BigNumber(val).toFixed(decimalPlaces)
    }
  } else {
    roundedVal = new BigNumber(val).toFixed(0)
  }
  return roundedVal
}

export const formatQuantity = (num: string | number): string => {
  let value = Number(num)

  return Number.isInteger(value) ? value.toFixed(2) : new BigNumber(value).toString()
}

export const addSeparators = (value: string): string => {
  const [integerPart, decimalPart] = value.split('.')
  let formattedIntegerPart = ''
  for (let i = integerPart.length - 1, j = 0; i >= 0; i--, j++) {
    if (j !== 0 && j % 3 === 0) {
      formattedIntegerPart = ' ' + formattedIntegerPart
    }
    formattedIntegerPart = integerPart[i] + formattedIntegerPart
  }
  return decimalPart ? formattedIntegerPart + '.' + decimalPart : formattedIntegerPart
}
