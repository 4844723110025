import { FC, memo } from 'react'
import ContentListItems from '../ContentListItems'
import style from './style.module.scss'
import ListTabBar from '@/ui/molecules/ListTabBar'
import Sorting from '@/ui/molecules/Sorting'

export type ContentListTabsType = {
  id: number
  text: string
}

const ContentList: FC = () => {
  return (
    <div className={style.root}>
      <div className={style.header}>
        <ListTabBar />
        <Sorting />
      </div>

      <ContentListItems />
    </div>
  )
}

export default memo(ContentList)
