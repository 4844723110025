import React from 'react'

interface IProps {
  className?: string
}

export const Verify = (props: IProps) => {
  const { className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <path
        d="M6.5625 8.625L8.4375 10.5L11.4375 7.125M9 16.5C9 16.5 15 13.5 15 9V3.75L9 1.5L3 3.75V9C3 13.5 9 16.5 9 16.5Z"
        stroke="#1ACC81"
      />
    </svg>
  )
}
