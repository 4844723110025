import { FC } from 'react'

type SortArrowDownProps = {
  className?: string
}

export const SortArrowDown: FC<SortArrowDownProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={7} height={4} fill="none" className={className}>
    <path
      fill="#676573"
      d="M3.565 3.491a.607.607 0 0 0 .749 0L6.74 1.548c.4-.32.155-.928-.374-.928H1.512c-.529 0-.774.608-.374.928l2.427 1.943Z"
    />
  </svg>
)
