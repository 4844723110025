import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react'
import { SVG } from '../../../assets/svg'
import { Button } from '../Button'
import { Chip } from '../Chips'
import { Input, InputSize } from '../Input'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

export interface InputAndChipsProps {
  values?: string[]
  inputLabel?: string
  inputLabelHintText?: ReactNode
  inputPlaceholder?: string
  inputValue?: string
  onInputChange: (values: string[]) => void
  inputError?: string
  validate?: (value: string) => string | null
  dataTestId?: string
}

interface State {
  value: string
  error?: string
  values: string[]
}

const InputAndChipsComponent = (props: InputAndChipsProps) => {
  const {
    values = [],
    inputLabel,
    inputValue = '',
    onInputChange,
    inputError,
    inputPlaceholder,
    validate,
    inputLabelHintText,
    dataTestId,
  } = props

  const [state, setState] = useState<State>({
    value: inputValue,
    values,
    error: inputError,
  })

  const setValue = (val: string) => setState(prev => ({ ...prev, value: val }))
  const setError = (val: string) => setState(prev => ({ ...prev, error: val }))
  const setValues = (val: string) =>
    setState(prev => {
      const nextValues = [...prev.values]
      nextValues.push(val)
      return { ...prev, values: nextValues }
    })

  useEffect(() => {
    onInputChange(state.values)
  }, [onInputChange, state.values, state.values.length])

  const handleApplyValueFromInput = useCallback(() => {
    /**
     * Show validation error if needed
     */
    const validationError = validate?.(state.value)
    if (validationError) return setError(validationError)

    setError('')
    setValue('')
    /**
     * Do nothing if value already has been added
     */
    if (state.values.includes(state.value)) return

    setValues(state.value)
  }, [state.value, state.values, validate])

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleApplyValueFromInput()
      }
    },
    [handleApplyValueFromInput]
  )

  const handleChipRemove = useCallback(
    (value: ReactNode | unknown) =>
      setState(prev => {
        const nextValues = [...prev.values]
        const idx = nextValues.findIndex(val => val === value)
        if (idx === -1) return prev

        nextValues.splice(idx, 1)
        return { ...prev, values: nextValues }
      }),
    []
  )

  return (
    <div className={style.component}>
      <label>
        <Input
          size={InputSize.Medium}
          label={inputLabel}
          labelHintText={inputLabelHintText}
          value={state.value}
          setValue={setValue}
          errorMessage={state.error}
          placeholder={inputPlaceholder}
          inputAttrs={{ onKeyDown: handleInputKeyDown }}
          containerClassName={style.input}
          button={
            <Button.Primary
              onClick={handleApplyValueFromInput}
              leftIcon={<SVG.OtherIcons.Plus width="8" height="8" />}
              className={style.plusBtn}
              dataTestId={`${dataTestId}${DataTestIds.AddBtnSuffix}`}
            />
          }
          dataTestId={dataTestId}
        />
      </label>

      {Boolean(state.values.length) && (
        <div className={style.chips}>
          {state.values.map((value, idx) => (
            <Chip key={idx} value={value} onRemove={handleChipRemove} />
          ))}
        </div>
      )}
    </div>
  )
}

export const InputAndChips = memo(InputAndChipsComponent)
