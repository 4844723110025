import clsx from 'clsx'
import { FC, memo } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'

export const SkeletonCashback = memo(() => {
  return (
    <div className={style.skeletonCashback}>
      <div className={style.skeletonCashbackLeftBar}>
        <span className={style.label}>
          {t({
            id: 'cashbackPage.leftBar.title',
            message: 'Summary',
          })}
        </span>
        <div className={style.wrap}>
          <div className={style.items}>
            <div className={style.itemWrap}>
              <div className={style.text}>
                {t({
                  id: 'cashbackPage.leftBar.tradingVolume',
                  message: 'Trading volume today',
                })}
              </div>
            </div>
            <div className={style.itemWrap}>
              <div className={clsx(style.item, style.animatable)} />
            </div>
            <div className={style.itemWrap}>
              <div className={style.text}>
                {t({
                  id: 'cashbackPage.leftBar.paidComission',
                  message: 'Paid commission today',
                })}
              </div>
            </div>
            <div className={style.itemWrap}>
              <div className={clsx(style.item, style.animatable)} />
            </div>
          </div>
          {/* <div className={style.itemWrapVolume}>
            <div className={style.text}>
              {t({
                id: 'cashbackPage.leftBar.tradingVolume30Days',
                message: 'Trading volume in the last 30 days',
              })}
            </div>
          </div> */}
          <div className={style.itemWrapBottom}>
            <div className={clsx(style.item, style.animatable)} />
          </div>
        </div>
        <div className={style.bottomBlock} />
      </div>
      <div className={style.between} />
      <div className={style.skeletonCashbackRightBar}>
        <div className={style.top}>
          <div className={style.topItems}>
            <div className={style.leftPart}>
              <div className={style.itemWrapper}>
                <div className={clsx(style.item, style.animatable)} />
              </div>
              <div className={style.itemWrapper}>
                <div className={clsx(style.item, style.animatable)} />
              </div>
            </div>
            <div className={style.center} />
            <div className={style.rightPart}>
              <div className={style.itemWrap}>
                <div className={clsx(style.item, style.animatable)} />
              </div>
            </div>
          </div>
        </div>
        <div className={style.middle} />
        <div className={style.bottom}>
          <div className={style.title}>{t({ id: 'cashbackPage.rightBar.tableTitle', message: 'History' })}</div>
          <BorromContent />
          <BorromContent />
          <BorromContent />
        </div>
      </div>
    </div>
  )
})

const BottomContentItemWrap: FC = () => {
  return (
    <div className={style.itemWrap}>
      <div className={clsx(style.item, style.animatable)} />
      <div className={clsx(style.item, style.animatable)} />
    </div>
  )
}

const BorromContent: FC = () => {
  return (
    <div className={style.bottomContent}>
      <div className={style.blockWrap}>
        <div className={style.left}>
          <div className={clsx(style.circle, style.animatable)} />
          <div className={style.itemWrapper}>
            <div className={clsx(style.item, style.animatable)} />
            <div className={clsx(style.item, style.animatable)} />
          </div>
        </div>
        <BottomContentItemWrap />
        <BottomContentItemWrap />
        <BottomContentItemWrap />
      </div>
    </div>
  )
}
