import clsx from 'clsx'
import { memo } from 'react'
import { QuestionButton } from '../../atoms/QuestionButton/QuestionButton'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { t } from '@lingui/macro'

interface NoCoinsProps {
  /** if shouldn't be accented */
  isDisabled?: boolean

  /** name of «account FROM, if provided will be displayed */
  fromAccount?: string

  /** name of «account TO, if provided will be displayed */
  toAccount?: string

  /** Fully custom text to display instead of predefined settings */
  customText?: string
}

/** Display insufficient funds of coins */
export const NoCoins = memo<NoCoinsProps>(({ isDisabled, fromAccount, toAccount, customText }) => {
  const getText = () => {
    if (customText) {
      return customText
    }

    const initialText = t({
      comment: 'Insufficient funds in the account. ',
      id: 'transferTransaction.messages.insufficientFunds',
    })

    if (!fromAccount && !toAccount) {
      return initialText
    }

    if (fromAccount && toAccount) {
      return (
        initialText +
        ` ${t({
          comment: 'Transfer funds from',
          id: 'transferTransaction.messages.transferFundsFrom',
        })} ${fromAccount} ${t({
          comment: 'account to',
          id: 'transferTransaction.messages.accountTo',
        })} ${toAccount}.`
      )
    }

    if (fromAccount) {
      return (
        initialText +
        ` ${t({
          comment: 'Transfer funds from',
          id: 'transferTransaction.messages.transferFundsFrom',
        })} ${fromAccount} ${t({
          comment: 'account',
          id: 'transferTransaction.messages.fromAccount',
        })}`
      )
    }

    return (
      initialText +
      ` ${t({
        comment: 'Transfer funds to',
        id: 'transferTransaction.messages.transferFundsTo',
      })} ${toAccount} ${t({
        comment: 'account',
        id: 'transferTransaction.messages.toAccount',
      })}`
    )
  }

  return (
    <div className={clsx(style.noCoins, isDisabled && style.noCoinsDisabled)} data-testid={DataTestIds.NoCoinsField}>
      <span className={style.label}>No coins</span>
      <QuestionButton buttonClassName={style.questionButton} hintClassName={style.hint} text={getText()} />
    </div>
  )
})
