import { FC } from 'react'

type RejectDotProps = {
  className?: string
}

export const RejectDot: FC<RejectDotProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={8} height={9} fill="none" className={className}>
    <circle cx={4} cy={4.5} r={4} fill="#F97052" />
  </svg>
)
