import React, { FC, ReactNode } from 'react'
import style from './style.module.scss'
import clsx from 'clsx'

type TQuestion = {
  question: ReactNode
  text: ReactNode
}

interface HowItWorksQuestionsProps {
  questions: TQuestion[]
}

export const HowItWorksQuestions: FC<HowItWorksQuestionsProps> = props => {
  const { questions } = props

  return (
    <div className={style.howItWorksQuestions}>
      {questions.map((item, index) => (
        <div key={index} className={style.itemWrap}>
          <div className={style.question}>{item.question}</div>
          <div
            className={clsx(style.text, {
              [style._small]: Number(item.text?.toString()?.length) > 60,
            })}
          >
            {item.text}
          </div>
        </div>
      ))}
    </div>
  )
}
