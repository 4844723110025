import { FiltersType, SummaryReqType } from '@/redux/notification/notification.types'
import { axiosMainService } from '@/services/axios'

const notifacationAPIURL = 'cashback-announcements/protected/api'

export const notificationAPI = {
  async getUserNotifications(payload: FiltersType) {
    const { unreadOnly, page, size, type } = payload

    const params: Record<string, any> = {
      unreadOnly,
      page,
      size,
    }

    if (type) {
      params.type = type
    }

    return await axiosMainService.get(`${notifacationAPIURL}/v1/user/notifications`, { params })
  },
  async getNotifySummary(payload: SummaryReqType) {
    const { firstLoginTime } = payload

    let params: Record<string, any> = {}

    if (firstLoginTime) {
      params.firstLoginTime = firstLoginTime
    }
    return await axiosMainService.get(`${notifacationAPIURL}/v1/user/summary`, { params })
  },
  async postReadNotification(payload: ReadNotificationType) {
    return await axiosMainService.post(`${notifacationAPIURL}/v1/user/notifications/${payload.id}/read`, payload)
  },
  async postReadNotifications() {
    return await axiosMainService.post(`${notifacationAPIURL}/v1/user/notifications/read`)
  },
}

export type ReadNotificationType = {
  id: string
}
