import { forwardRef } from 'react'
import { useCarousel } from '../../hooks/useCarousel'
import clsx from 'clsx'
import s from './style.module.scss'

export const Item = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
  // Просто чекнуть контекст
  useCarousel()

  return <div ref={ref} role="group" aria-roledescription="slide" className={clsx(s.item, className)} {...props} />
})
