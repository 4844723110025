import { RestBinanceSpotClient, RestBinanceUsdmClient, RestBinanceCoinmClient } from '@tigertrade/binance-ts'

export const binanceRestClients: {
  spot: RestBinanceSpotClient | undefined
  usdm: RestBinanceUsdmClient | undefined
  coinm: RestBinanceCoinmClient | undefined
} = {
  spot: undefined,
  usdm: undefined,
  coinm: undefined,
}
