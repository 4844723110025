import { YOUTUBE_EMBED_URL } from '@/core/constants/common'
import { FC } from 'react'

export const YoutubePlayer: FC<{ videoId: string }> = props => {
  const { videoId } = props
  return (
    <iframe
      src={`${YOUTUBE_EMBED_URL}${videoId}?rel=0&modestbranding=1`}
      width={960}
      height={480}
      frameBorder={0}
    ></iframe>
  )
}
