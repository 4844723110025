import { RenderPath } from '@/ui/organisms/Dashboard/components/WidgetDistribution/utils/RenderPath'
import { FC } from 'react'

type DistributionWidgetProps = {
  short: number
  coord1: number[]
  coord2: number[]
}
export const DistributionWidget: FC<DistributionWidgetProps> = ({ short, coord1, coord2 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76">
      <defs>
        <linearGradient id="tiger-widget-distribution-gradient1">
          <stop offset="0%" stopColor="#FFB951"></stop>
          <stop offset="50%" stopColor="#A3EA90"></stop>
          <stop offset="100%" stopColor="#75E6FF"></stop>
        </linearGradient>
        <linearGradient id="tiger-widget-distribution-gradient2">
          <stop offset="0%" stopColor="#FCC4ED"></stop>
          <stop offset="50%" stopColor="#FEA471"></stop>
          <stop offset="100%" stopColor="#FF627E"></stop>
        </linearGradient>
      </defs>
      {RenderPath(short, coord1, coord2)}
    </svg>
  )
}
