import { FC, useState } from 'react'
import { t } from '@lingui/macro'
import { Button, Input, InputSize, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import s from './style.module.scss'
import { useActions } from '@/utils'
import { referralsActions } from '@/redux/referrals/referrals.slice'
import { UpdateReferralCodeRequestDTO } from '@/backend/models/ReferralsDTO'
import { unwrapResult } from '@reduxjs/toolkit'

type Props = {
  isOpen: boolean
  onClose: () => void
  code: string
  isCustom?: boolean
}

export const PopupEditRefCode: FC<Props> = props => {
  const { isOpen, onClose } = props
  const [refCode, setRefCode] = useState(props.code)
  const { UpdateReferralCodeTC, GetReferralOverviewTC, UpdateCustomReferralCodeTC } = useActions(referralsActions)

  const [error, setError] = useState('')

  function handleChangeCode(value: string) {
    setRefCode(value)
    setError('')
  }

  function validateCode(code: string): boolean {
    const isValid = /^[a-z0-9-]{1,50}$/.test(code)
    if (!isValid) {
      setError(
        t({
          id: 'referralPage.editForm.invalidCode',
          comment:
            'Referral code can only contain lowercase Latin letters, numbers, hyphens, and must be up to 50 characters long.',
        })
      )
    } else {
      setError('')
    }
    return isValid
  }
  const handleSubmit = () => {
    const isValid = validateCode(refCode)
    if (!isValid) return

    updateRefCode({
      code: refCode,
    })
  }

  async function updateRefCode(refCode: UpdateReferralCodeRequestDTO) {
    try {
      if (props.isCustom) {
        const response = await UpdateCustomReferralCodeTC({
          newCode: refCode.code,
          oldCode: props.code,
        })
        unwrapResult(response as any)
      } else {
        const response = await UpdateReferralCodeTC(refCode)
        unwrapResult(response as any)
      }

      cleanUp()
      await GetReferralOverviewTC()
    } catch (error: any) {
      if (error?.errors) {
        setError(error?.errors[0])
      }
    }
  }

  const cleanUp = () => {
    setError('')
    setRefCode(props.code)
  }

  const handleClose = () => {
    onClose()
    cleanUp()
  }

  return (
    <InteractiveModal isOpen={isOpen} className={s.root}>
      <InteractiveModalParts.SubHeader
        text={t({ id: 'referralPage.editForm.heading', comment: 'Edit referral code' })}
      />
      <div className={s.inputContainer}>
        <Input
          label={t({ id: 'referralPage.editForm.code', comment: 'Referral code' })}
          size={InputSize.Large}
          value={refCode}
          setValue={handleChangeCode}
          containerClassName={s.input}
          labelClassName={s.inputLabel}
          errorClassName={s.inputErrorClassName}
          errorMessage={error}
        />
      </div>
      <InteractiveModalParts.Button
        text={t({ id: 'core.button.save', comment: 'Save' })}
        variant={Button.Variant.White}
        isDisabled={!!error}
        onClick={handleSubmit}
      />
      <InteractiveModalParts.Button
        text={t({ id: 'core.button.close', comment: 'Close' })}
        variant={Button.Variant.Primary}
        onClick={handleClose}
      />
    </InteractiveModal>
  )
}
