import { FC, useEffect, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import {
  Button,
  Checkbox,
  Input,
  InputSize,
  InteractiveModal,
  InteractiveModalParts,
  Select,
  SelectOption,
} from '@/ui/kit'
import { useApi } from './hooks/useApi'
import s from './style.module.scss'
import { ReferralCodePercent } from '@/backend/models/ReferralsDTO'

type Rates = {
  [ReferralCodePercent.Binance]: string
  [ReferralCodePercent.Bybit]: string
}
interface IReferralCodeAddFormProps {
  isOpen: boolean
  onClose: () => void
}

const MIN_PERIOD = 1
const MAX_PERIOD = 30

const ReferralCodeAddForm: FC<IReferralCodeAddFormProps> = props => {
  const { isOpen, onClose } = props

  const { createCode } = useApi()
  const minRateValue = '25'
  const minRateValueBybit = '5'
  const rateOptions: SelectOption[] = useMemo(() => {
    const rates = []

    for (let r = 25; r <= 35; r += 0.5) {
      const option: SelectOption = {
        label: `${r}%`,
        value: String(r),
      }

      rates.push(option)
    }

    return rates
  }, [])
  const rateOptionsBybit: SelectOption[] = useMemo(() => {
    const rates = []

    for (let r = 5; r <= 10; r += 0.5) {
      const option: SelectOption = {
        label: `${r}%`,
        value: String(r),
      }

      rates.push(option)
    }

    return rates
  }, [])

  const [isPermanent, setIsPermanent] = useState(false)
  const [rates, setRates] = useState<Rates>({
    binance: rateOptions[0]?.value,
    bybit: rateOptionsBybit[0]?.value,
  })

  const [period, setPeriod] = useState('7')
  const [periodError, setPeriodError] = useState('')
  const handleRateChange = (select: ReferralCodePercent, value: string) => {
    setRates(prevRates => ({
      ...prevRates,
      [select]: value,
    }))
  }

  const handleChangePeriod = (v: string) => {
    const value = v.replace(/\D/g, '')

    if (Number(value) < MIN_PERIOD) {
      const message = `${t({ id: 'referralPage.addForm.errorMin-1', message: 'Minimum period is' })} ${MIN_PERIOD} ${t({
        id: 'referralPage.addForm.errorMin-2',
        message: ' day',
      })}`
      setPeriodError(message)
    } else if (Number(value) > MAX_PERIOD) {
      const message = `${t({ id: 'referralPage.addForm.errorMax-1', message: 'Maximum period is' })} ${MAX_PERIOD} ${t({
        id: 'referralPage.addForm.errorMax-2',
        message: ' days',
      })}`
      setPeriodError(message)
    } else {
      setPeriodError('')
    }

    setPeriod(value)
  }

  const handleSubmit = async () => {
    const payload = {
      bonusBinanceCashbackPercent: rates.binance,
      bonusBybitCashbackPercent: rates.bybit,
      isPermanent: isPermanent,
      bonusPeriodDays: isPermanent ? undefined : period,
    }

    createCode(payload, handleClose)
  }

  const cleanUp = () => {
    setPeriodError('')
    setPeriod('7')
    setRates({
      binance: minRateValue,
      bybit: minRateValueBybit,
    })
  }

  const handleClose = () => {
    onClose()
    cleanUp()
  }

  return (
    <InteractiveModal isOpen={isOpen} className={s.root}>
      <InteractiveModalParts.SubHeader text={t({ id: 'referralPage.addForm.heading', message: 'Add new code' })} />
      <span className={s.selectLabel}>
        {t({ id: 'referralPage.addForm.rateLabel', message: "Select user's commission reduction rate" })}
      </span>
      <div className={s.selectContainer}>
        <Select
          label={t({ id: 'referralPage.addForm.rateLabel.binance', message: 'Binance' })}
          options={rateOptions}
          value={rates.binance}
          size={Select.Size.Small}
          onChange={(value: string) => handleRateChange(ReferralCodePercent.Binance, value)}
          className={s.select}
          dropdownClassName={s.dropdown}
        />
        <Select
          label={t({ id: 'referralPage.addForm.rateLabel.bybit', message: 'Bybit' })}
          options={rateOptionsBybit}
          value={rates.bybit}
          size={Select.Size.Small}
          onChange={(value: string) => handleRateChange(ReferralCodePercent.Bybit, value)}
          className={s.select}
          dropdownClassName={s.dropdown}
        />
      </div>
      <div className={s.checkBoxContainer}>
        <Checkbox
          variant="square"
          checked={isPermanent}
          className={s.checkboxItem}
          onChange={() => setIsPermanent(prev => !prev)}
        >
          <span>{t({ id: 'referralPage.addForm.permanentCode', comment: 'Permanent code' })}</span>
        </Checkbox>
      </div>
      {!isPermanent && (
        <div className={s.inputContainer}>
          <Input
            label={t({ id: 'referralPage.addForm.periodLabel', message: 'Select period, days' })}
            size={InputSize.Large}
            value={period}
            setValue={handleChangePeriod}
            containerClassName={s.input}
            labelClassName={s.inputLabel}
            errorMessage={periodError}
          />
        </div>
      )}
      <InteractiveModalParts.Button
        text={t({ id: 'referralPage.addForm.submit', message: 'Add code' })}
        variant={Button.Variant.White}
        isDisabled={!!periodError}
        onClick={handleSubmit}
      />
      <InteractiveModalParts.Button
        text={t({ id: 'core.button.close', message: 'Close' })}
        variant={Button.Variant.Primary}
        onClick={handleClose}
      />
    </InteractiveModal>
  )
}

export { ReferralCodeAddForm }
