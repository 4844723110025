import { axiosMainService } from '@/services/axios'

const keitaroAPIURL = 'marketing-integration/protected/api'

export const KeitaroAPI = {
  async postAddKeitaroLead(payload: KeitaroType) {
    return await axiosMainService.post(`${keitaroAPIURL}/v1/keitaro/leads`, payload)
  },
}

export type KeitaroType = {
  leadId: string
}
