import { FC, useLayoutEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { Button, ButtonSize, ButtonVariant } from '@/ui/kit'
import { ru, uk, enGB } from 'date-fns/locale'
import { i18n } from '@lingui/core'
import style from './style.module.scss'
import clsx from 'clsx'
import { isNumber } from 'lodash-es'

type RefreshTradesRequestErrorProps = {
  initialTime?: number | null
  refreshHandler: () => void
  description: string
  title: string
}

const DATE_LOCALES = {
  ru,
  uk,
  enGB,
}

export const ErrorWithRefreshPage: FC<RefreshTradesRequestErrorProps> = ({
  initialTime,
  refreshHandler,
  description,
  title,
}) => {
  const extractSecondsFromTimestamp = isNumber(initialTime) ? initialTime % 60 : null
  const [timeLeft, setTimeLeft] = useState<number | null>(extractSecondsFromTimestamp)
  const [isButtonActive, setIsButtonActive] = useState(false)
  const locale = i18n.locale as keyof typeof DATE_LOCALES

  const formattedStrings: Record<string, string[]> = {
    ru: ['с'],
    uk: ['с'],
    en: ['s'],
  }
  const formateSecond = formattedStrings[locale]
  const errorMessageSeconds = t({
    id: 'history.trades.errorSeconds',
    comment: 'refresh page',
  })

  const errorMessageExpired = t({
    id: 'history.trades.errorButtonExpired',
    comment: 'refresh page',
  })

  const buttonTime = timeLeft ? ` ${timeLeft}${formateSecond}` : ''
  const buttonLabel = isButtonActive ? errorMessageExpired : `${errorMessageSeconds}${buttonTime}`

  useLayoutEffect(() => {
    if (timeLeft !== null && timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(prevTime => prevTime! - 1)
      }, 1000)

      return () => clearInterval(timerId)
    } else {
      setIsButtonActive(true)
    }
  }, [timeLeft])

  return (
    <div className={style.containerRefreshTrades}>
      <div className={style.tradesRequestTittle}>{title}</div>
      <div className={style.description}>{description}</div>
      <Button
        variant={isButtonActive ? ButtonVariant.Accent : ButtonVariant.Primary}
        size={ButtonSize.Medium}
        label={buttonLabel}
        disabled={!isButtonActive}
        onClick={refreshHandler}
        className={clsx(style.button, {
          [style.buttonInitialColor]: isButtonActive,
        })}
      />
    </div>
  )
}
