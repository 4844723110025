import { t } from '@lingui/macro'
import { FC } from 'react'
import { SVG } from '@/assets/svg'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import clsx from 'clsx'

type StatusProps = {
  status: 'SUCCESS' | 'PENDING' | 'FAILED'
}

export const Status: FC<StatusProps> = props => {
  const statusVariants: any = {
    PENDING: {
      icon: <SVG.Status.Pending />,
      name: t({
        id: 'core.status.pending',
        message: `Pending`,
      }),
    },
    SUCCESS: {
      icon: <SVG.Status.Completed />,
      name: t({
        id: 'core.status.completed',
        message: `Completed`,
      }),
    },
    FAILED: {
      icon: <SVG.Status.Failed />,
      name: t({
        id: 'core.status.failed',
        message: `Failed`,
      }),
    },
  }

  return (
    <div className={clsx(style.status, style[props.status])}>
      {statusVariants[props.status].icon}
      <span className={style.statusName} data-testid={DataTestIds.HistoryModalStatus}>
        {statusVariants[props.status].name}
      </span>
    </div>
  )
}
