import { IMetadata, MetadataRequestDTO } from '@/backend/models/ProfileDTO'
import { MapHandler } from './types'
import { EXCHANGES_AVAILABLE, ROOT_PAGES, TExchangesAvailable } from '@/core/constants'
import { urls } from '@/router/urls'

export function redefineKey<T extends keyof IMetadata | keyof MetadataRequestDTO>(
  newPropertyKey: T,
  handler: MapHandler
): MapHandler {
  return (_key, value) => handler(newPropertyKey, value)
}

export function identity<T>(key: string, value: T) {
  return { [key]: value }
}

export function convertToBoolean(key: string, value: string) {
  const safeValue = value?.toLowerCase() ?? null
  return { [key]: safeValue ? safeValue === 'true' : false }
}

export function createParseHandler<T>(defaultValue: T): MapHandler {
  return (key, value) => {
    if (!value) return { [key]: defaultValue }

    try {
      return { [key]: JSON.parse(value) }
    } catch (e) {
      return { [key]: defaultValue }
    }
  }
}

export function rootPageHandler(key: string, value: string) {
  if (value && ROOT_PAGES.includes(value as (typeof ROOT_PAGES)[number])) {
    return { [key]: urls[value as keyof typeof urls] || urls.wallet }
  }
}

export function exchangeTypeHandler(key: string, value: string) {
  if (value && EXCHANGES_AVAILABLE.includes(value as TExchangesAvailable)) {
    return { [key]: value }
  }
}

export function booleanToString(key: string, value: boolean) {
  return { [key]: value ? 'true' : 'false' }
}

export function stringifyUniqueList(key: string, value: any) {
  const uniqList = new Set(value)
  return { [key]: JSON.stringify(Array.from(uniqList)) }
}

export function stringify(key: string, value: any) {
  return { [key]: JSON.stringify(value) }
}
