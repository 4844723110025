import clsx from 'clsx'
import React, { FC } from 'react'
import { TCreateApiKeyPayloadUnion } from '@/backend/api/apiKeyAPI'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { ApiKeyCreateForm } from '../ApiKeyCreateForm'
import style from './style.module.scss'
import { t } from '@lingui/macro'

interface ApiKeyCreateProps {
  onSubmit?: (params: TCreateApiKeyPayloadUnion) => void
  onCancel?: () => void
  isSubmitDisabled?: boolean
}

export const ApiKeyCreate: FC<ApiKeyCreateProps> = props => {
  const { onSubmit, onCancel, isSubmitDisabled } = props

  return (
    <div className={clsx(style.content)}>
      <TitleH1
        label={t({
          id: 'apiPage.create.title',
          message: `Create new API key`,
        })}
        className={style.content_title}
      />
      <ApiKeyCreateForm onSubmit={onSubmit} onCancel={onCancel} isSubmitDisabled={isSubmitDisabled} />
    </div>
  )
}
