import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { Button, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import style from './style.module.scss'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { ResultForm } from '@/pages/OKXAfiliate/components/LeftBar/components/ResultForm'
import { useActions, useAppSelector } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'

type PopupReset2FAProps = {
  isOpen: boolean
  onClose: () => void
  onCancel?: () => void
}

export const PopupReset2FA = (props: PopupReset2FAProps) => {
  const { resetMailStatus } = useAppSelector(state => state.profile)
  const { resetTwoFATC, GetProfileTC, resetEmailStatus } = useActions(profileActions)

  async function handleReset() {
    try {
      await resetTwoFATC()
      await GetProfileTC()
    } catch (error) {
      console.log('error', error)
    }
  }

  function closeSuccessResultForm() {
    if (props.onCancel) {
      props.onCancel()
    }

    props.onClose()
  }

  function goBack() {
    resetEmailStatus()
  }

  if (resetMailStatus.isResetLoaded) {
    return (
      <>
        <InteractiveModal isOpen={props.isOpen}>
          {resetMailStatus.isResetSuccess ? (
            <ResultForm
              title={t({
                id: 'popup.reset2fa.resultPopup.successTitle',
                comment: 'Email send success',
              })}
              description={t({
                id: 'popup.reset2fa.resultPopup.successDescription',
                comment: 'Emails Send success ',
              })}
              onClose={closeSuccessResultForm}
              isSuccess
            />
          ) : (
            <ResultForm
              title={t({
                id: 'popup.reset2fa.resultPopup.errorTitle',
                comment: 'Email not sended',
              })}
              description={t({
                id: 'popup.reset2fa.resultPopup.error.unknown',
                comment: 'Email not sended',
              })}
              onClose={props.onClose}
              onClickBackButton={goBack}
              titleBackButton={t({ id: 'popup.reset2fa.resultPopup.backToForm', comment: 'Back' })}
              isSuccess={false}
              isShowBackButton
            />
          )}
        </InteractiveModal>
      </>
    )
  }

  return (
    <>
      <InteractiveModal isOpen={props.isOpen}>
        <div>
          <SVG.OtherIcons.BigLock />
        </div>
        <br />
        <TitleH3
          label={t({
            id: 'popup.reset2fa.title',
            comment: 'title',
          })}
        />
        <div className={style.content}>
          {t({
            id: 'popup.reset2fa.description',
            comment: 'description',
          })}
        </div>
        <div className={style.buttons}>
          <InteractiveModalParts.Button
            text={t({ id: 'core.reset2fa.sendLink', comment: 'Send Link' })}
            variant={Button.Variant.Accent}
            onClick={handleReset}
          />
          <InteractiveModalParts.Button
            text={t({ id: 'core.close', comment: 'Close' })}
            variant={Button.Variant.Primary}
            onClick={props.onClose}
          />
        </div>
      </InteractiveModal>
    </>
  )
}
