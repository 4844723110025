import { useActions, useAppSelector } from '@/utils'
import style from './style.module.scss'
import { useEffect, useState } from 'react'
import { notificationActions } from '@/redux/notification/notification.slice'
import { NotificationSidebar } from '../NotificationSidebar/NotificationSidebar'
import { SVG } from '@/assets/svg'

export const HeaderNotification = () => {
  const [showNotificationContent, setShowNotificationyContent] = useState(false)
  const { summary } = useAppSelector(state => state.notification)
  const { metadata } = useAppSelector(state => state.profile)
  const { GetNotifySummary } = useActions(notificationActions)

  useEffect(() => {
    GetNotifySummary({ firstLoginTime: metadata.firstSesstionTrackedTimestamp })
  }, [])

  const isHasAnnouncements = summary.announcements && summary.announcements > 0 ? true : false
  const isHasUpdates = summary.updates && summary.updates > 0 ? true : false
  const ishasIndicator = isHasAnnouncements || isHasUpdates

  function openNotificationContent() {
    setShowNotificationyContent(true)
  }

  function closeNotificationContent() {
    setShowNotificationyContent(false)
  }

  return (
    <>
      <div className={style.button} onClick={openNotificationContent}>
        <SVG.OtherIcons.Notify />
        {ishasIndicator && <div className={style.indicator}></div>}
      </div>
      {showNotificationContent && <NotificationSidebar onClose={closeNotificationContent} />}
    </>
  )
}
