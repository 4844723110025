import React from 'react'

const Withdraw = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_9953_75978)" />
      <path
        d="M17.9093 16.1903C17.9093 16.1903 21.81 16.1903 24.3094 16.1903M24.3094 16.1903C24.3094 18.6897 24.3094 22.5904 24.3094 22.5904M24.3094 16.1903C20.7389 19.7608 15.6907 24.809 15.6907 24.809"
        stroke="#FAFAFA"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9953_75978"
          x1="0"
          y1="40.5"
          x2="30.3512"
          y2="-5.82211"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BFA8FF" />
          <stop offset="0.471642" stopColor="#FF51BC" />
          <stop offset="1" stopColor="#FC4F49" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { Withdraw }
