import React, { SVGProps } from 'react'
import { DataTestIds } from '@/utils/lib/dataTestIds'

interface CloseProps extends SVGProps<SVGSVGElement> {
  width?: string
  height?: string
  className?: string
  dataTestId?: string
}

export const Close = (props: CloseProps) => {
  const { height = '12', width = '12', className, dataTestId } = props

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={`${dataTestId}${DataTestIds.CloseBtnSuffix}`}
    >
      <path
        d="M10.7143 1.28577L1.28572 10.7143"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7143 10.7143L1.28572 1.28577"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
