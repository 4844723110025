export const coinSorter = <T>(options: T[], keyName: keyof T, keyNumber: keyof T): T[] => {
  return options
    .sort((a, b) => {
      return a[keyNumber] > b[keyNumber] ? -1 : a[keyNumber] < b[keyNumber] ? 1 : 0
    })
    .sort((a, b) => {
      if (
        (a[keyName] < b[keyName] && Number(a[keyNumber]) > 0) ||
        (a[keyName] < b[keyName] && a[keyNumber] === b[keyNumber])
      )
        return -1
      return 0
    })
}
