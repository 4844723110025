import { ServicesUserWithRelations } from '@/utils/types/contracts.types'

export const getIdsByApiKeyList = (
  data: ServicesUserWithRelations | undefined,
  exchangeIds: number[]
): number[] | undefined => {
  if (data) {
    const filtered = data.api_keys_list
      ? data.api_keys_list
          .filter(el => exchangeIds.includes(Number(el.exchange_id)))
          .map(el => el.id || [])
          .flat()
      : undefined
    return filtered && filtered.length ? filtered : undefined
  }
}
