export const exampleDataDatetimeSimple = {
  '2024-03-19': {
    value: '12',
    counter: '3',
    value2: '2',
    dateValue: '2024-03-19',
  },
  '2024-03-20': {
    value: '6',
    counter: '5',
    value2: '1',
    dateValue: '2024-03-20',
  },
  '2024-03-21': {
    value: '2',
    counter: '12',
    value2: '0.1',
    dateValue: '2024-03-21',
  },
  '2024-03-22': {
    value: '5',
    counter: '2',
    value2: '0.8',
    dateValue: '2024-03-22',
  },
  '2024-03-23': {
    value: '8',
    counter: '39',
    value2: '3.6',
    dateValue: '2024-03-23',
  },
}

export const exampleDataCandleStick = {
  '2024-01-08': {
    value: '46110',
    value2: '44748.67',
    value3: '47972',
    dateValue: '2024-01-08',
  },
  '2024-01-09': {
    value: '46653.99',
    value2: '44300.36',
    value3: '47695.93',
    dateValue: '2024-01-09',
  },
  '2024-01-10': {
    value: '46339.16',
    value2: '45606.06',
    value3: '48969.48',
    dateValue: '2024-01-10',
  },
  '2024-01-11': {
    value: '42782.73',
    value2: '41500',
    value3: '46515.53',
    dateValue: '2024-01-11',
  },
  '2024-01-12': {
    value: '42847.99',
    value2: '42436.12',
    value3: '43257',
    dateValue: '2024-01-12',
  },
  '2024-01-13': {
    value: '41732.35',
    value2: '41720',
    value3: '43079',
    dateValue: '2024-01-13',
  },
  '2024-01-14': {
    value: '42511.1',
    value2: '41718.05',
    value3: '43400.43',
    dateValue: '2024-01-14',
  },
  '2024-01-15': {
    value: '43137.95',
    value2: '42050',
    value3: '43578.01',
    dateValue: '2024-01-15',
  },
  '2024-01-16': {
    value: '42776.1',
    value2: '42200.69',
    value3: '43198',
    dateValue: '2024-01-16',
  },
  '2024-01-17': {
    value: '41327.5',
    value2: '40683.28',
    value3: '42930',
    dateValue: '2024-01-17',
  },
  '2024-01-18': {
    value: '41659.03',
    value2: '40280',
    value3: '42196.86',
    dateValue: '2024-01-18',
  },
}

export const exampleDataDatetimeSimpleCumulative = {
  '2024-03-19': {
    value: '12',
    counter: '3',
    value2: '2',
    dateValue: '2024-03-19',
  },
  '2024-03-20': {
    value: '18',
    counter: '8',
    value2: '3',
    dateValue: '2024-03-20',
  },
  '2024-03-21': {
    value: '24',
    counter: '15',
    value2: '4',
    dateValue: '2024-03-21',
  },
  '2024-03-22': {
    value: '30',
    counter: '27',
    value2: '5',
    dateValue: '2024-03-22',
  },
  '2024-03-23': {
    value: '36',
    counter: '44',
    value2: '6',
    dateValue: '2024-03-23',
  },
}

export const exampleDataDatetimeSimpleNegative = {
  '2024-03-19': {
    value: '-12',
    counter: '3',
    value2: '2',
    dateValue: '2024-03-19',
  },
  '2024-03-20': {
    value: '-6',
    counter: '5',
    value2: '1',
    dateValue: '2024-03-20',
  },
  '2024-03-21': {
    value: '-2',
    counter: '12',
    value2: '0.1',
    dateValue: '2024-03-21',
  },
  '2024-03-22': {
    value: '-5',
    counter: '2',
    value2: '0.8',
    dateValue: '2024-03-22',
  },
  '2024-03-23': {
    value: '-8',
    counter: '39',
    value2: '3.6',
    dateValue: '2024-03-23',
  },
}

export const exampleDataPieSymbol = {
  '0': {
    name: 'BTCUSDT',
    counter: 518,
    value: '5180',
  },
  '1': {
    name: 'ETHUSDT',
    counter: 466,
    value: '4660',
  },
  '10': {
    name: 'LINKUSDT',
    counter: 235,
    value: '2350',
  },
  '100': {
    name: 'SOLUSDT',
    counter: 131,
    value: '1310',
  },
}

export const exampleDataNegativeOverDuration = {
  '0': {
    name: '1 sec / 10 mins',
    counter: 182,
    value: '-0.227182',
  },
  '1': {
    name: '10 mins / 1 hour 50 mins',
    counter: 138,
    value: '-2.28597042',
  },
  '2': {
    name: '4 hours / 8 hours',
    counter: 23,
    value: '-3.8740136',
  },
  '3': {
    name: '3 days / 7 days',
    counter: 14,
    value: '-4.1107351875',
  },
}

export const exampleDataPositiveOverDuration = {
  '0': {
    name: '1 sec / 10 mins',
    counter: 182,
    value: '0.227182',
  },
  '1': {
    name: '10 mins / 1 hour 50 mins',
    counter: 138,
    value: '2.28597042',
  },
  '2': {
    name: '4 hours / 8 hours',
    counter: 23,
    value: '3.8740136',
  },
  '3': {
    name: '3 days / 7 days',
    counter: 14,
    value: '4.1107351875',
  },
}

export const exampleDataStdDevMAEMMFE = {
  '0': {
    name: '-0.273 / -0.010',
    counter: 66,
    value: '66',
  },
  '1': {
    name: '-0.010 / 0.223',
    counter: 245,
    value: '245',
  },
  '3': {
    name: '0.424 / 0.595',
    counter: 90,
    value: '90',
  },
  '4': {
    name: '0.595 / 0.734',
    counter: 58,
    value: '58',
  },
  '5': {
    name: '0.734 / 0.843',
    counter: 25,
    value: '25',
  },
  '7': {
    name: '0.921 / 0.967',
    counter: 10,
    value: '10',
  },
  '8': {
    name: '0.967 / 0.983',
    counter: 2,
    value: '2',
  },
  '9': {
    name: '0.988 / 1.039',
    counter: 8,
    value: '8',
  },
  '10': {
    name: '1.039 / 1.320',
    counter: 44,
    value: '44',
  },
  '11': {
    name: '1.320 / 2.434',
    counter: 69,
    value: '69',
  },
  '12': {
    name: '2.434 / 5.965',
    counter: 39,
    value: '39',
  },
}

export const tagProfit = {
  '0': {
    name: 'Level retest',
    counter: 20,
    value: '14',
  },
  '1': {
    name: 'News',
    counter: 11,
    value: '32',
  },
  '2': {
    name: 'Breakout',
    counter: 11,
    value: '52',
  },
  '3': {
    name: 'Divergence',
    counter: 11,
    value: '72',
  },
  '4': {
    name: 'Volume Spike',
    counter: 11,
    value: '80',
  },
}

export const profitByDayOfWeek = {
  '0': {
    name: 'Monday',
    counter: 3949,
    value: '36.0091',
    value2: '1422',
    value3: '2527',
  },
  '1': {
    name: 'Tuesday',
    counter: 4394,
    value: '46.3905',
    value2: '1599',
    value3: '2795',
  },
  '2': {
    name: 'Wednesday',
    counter: 4103,
    value: '58.2403',
    value2: '1569',
    value3: '2534',
  },
  '3': {
    name: 'Thursday',
    counter: 4076,
    value: '65.2306',
    value2: '1436',
    value3: '2640',
  },
  '4': {
    name: 'Friday',
    counter: 2667,
    value: '58.5827',
    value2: '1029',
    value3: '1638',
  },
  '5': {
    name: 'Saturday',
    counter: 1773,
    value: '48.6351',
    value2: '685',
    value3: '1088',
  },
  '6': {
    name: 'Sunday',
    counter: 1569,
    value: '7.5398',
    value2: '589',
    value3: '980',
  },
}

export const profitByTimeOfDay = {
  '0': {
    name: '0',
    counter: 1505,
    value: '32.691',
    value2: '492',
    value3: '1013',
  },
  '1': {
    name: '4',
    counter: 2863,
    value: '33.2169',
    value2: '951',
    value3: '1912',
  },
  '2': {
    name: '2',
    counter: 1728,
    value: '34.8958',
    value2: '603',
    value3: '1125',
  },
  '3': {
    name: '5',
    counter: 3096,
    value: '38.3372',
    value2: '1125',
    value3: '1971',
  },
  '4': {
    name: '1',
    counter: 1754,
    value: '39.9441',
    value2: '648',
    value3: '1106',
  },
  '5': {
    name: '7',
    counter: 1798,
    value: '40.2636',
    value2: '670',
    value3: '1128',
  },
  '6': {
    name: '8',
    counter: 1505,
    value: '45.4751',
    value2: '564',
    value3: '941',
  },
  '7': {
    name: '6',
    counter: 2404,
    value: '48.812',
    value2: '909',
    value3: '1495',
  },
  '8': {
    name: '9',
    counter: 1559,
    value: '59.6786',
    value2: '603',
    value3: '956',
  },
  '9': {
    name: '3',
    counter: 2180,
    value: '32.7156',
    value2: '844',
    value3: '1336',
  },
  '10': {
    name: '11',
    counter: 860,
    value: '42.7907',
    value2: '368',
    value3: '492',
  },
  '11': {
    name: '10',
    counter: 1279,
    value: '43.1587',
    value2: '552',
    value3: '727',
  },
}

export const tagProfitCombinations = {
  '0': {
    name: 'Level retest, News',
    counter: 20,
    value: '18',
  },
  '2': {
    name: 'Breakout, Divergence, Volume Spike',
    counter: 11,
    value: '52',
  },
  '3': {
    name: 'Divergence, Volume Spike',
    counter: 11,
    value: '72',
  },
  '4': {
    name: 'Volume Spike, News',
    counter: 11,
    value: '80',
  },
}

export const apiKeyProfit = {
  '0': {
    name: 'Binance Futures',
    counter: 20,
    value: '96',
  },
  '1': {
    name: 'Binance Spot',
    counter: 11,
    value: '56',
  },
  '2': {
    name: 'Bybit',
    counter: 11,
    value: '40',
  },
  '3': {
    name: 'Bitget',
    counter: 11,
    value: '20',
  },
}

export const categoryProfit = {
  '0': {
    name: 'Category 1',
    counter: 20,
    value: '14',
  },
  '1': {
    name: 'Category 2',
    counter: 11,
    value: '20',
  },
  '2': {
    name: 'Category 3',
    counter: 11,
    value: '45',
  },
  '3': {
    name: 'Category 4',
    counter: 11,
    value: '60',
  },
  '4': {
    name: 'Category 5',
    counter: 11,
    value: '75',
  },
}

export const accumulative = {
  '2020-04-30': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '46.36811102',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-04-30',
    },
  },
  '2020-07-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-12526.16865447',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-07-08',
    },
  },
  '2020-07-10': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-17217.82532797',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-07-10',
    },
  },
  '2020-07-12': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-16822.39459973',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-07-12',
    },
  },
  '2020-07-18': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-17911.65727089',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-07-18',
    },
  },
  '2020-08-15': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-17666.45887095',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-08-15',
    },
  },
  '2020-08-16': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-15877.77450356',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-08-16',
    },
  },
  '2020-08-18': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-15742.15481484',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-08-18',
    },
  },
  '2020-09-07': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-16389.37300884',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-09-07',
    },
  },
  '2020-09-16': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-16454.72264036',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-09-16',
    },
  },
  '2020-09-20': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-14687.02916594',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-09-20',
    },
  },
  '2020-09-25': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-14856.81230674',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-09-25',
    },
  },
  '2020-09-30': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-15557.67550293',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-09-30',
    },
  },
  '2020-11-06': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-15750.53935188',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-11-06',
    },
  },
  '2020-11-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-15604.46786307',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-11-08',
    },
  },
  '2020-11-12': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-33168.55714313',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2020-11-12',
    },
  },
  '2021-04-09': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-33160.35613379',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-04-09',
    },
  },
  '2021-04-10': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-33162.13504239',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-04-10',
    },
  },
  '2021-04-14': {
    binance: {
      name: 'binance',
      counter: 9,
      value: '-31641.92672761',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-04-14',
    },
  },
  '2021-04-15': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-31739.17236951',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-04-15',
    },
  },
  '2021-04-16': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-31593.1016877',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-04-16',
    },
  },
  '2021-04-19': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-31625.71018909',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-04-19',
    },
  },
  '2021-04-20': {
    binance: {
      name: 'binance',
      counter: 6,
      value: '-31650.07230418',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-04-20',
    },
  },
  '2021-04-21': {
    binance: {
      name: 'binance',
      counter: 7,
      value: '-31746.35250392',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-04-21',
    },
  },
  '2021-04-22': {
    binance: {
      name: 'binance',
      counter: 9,
      value: '-30620.52642652',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-04-22',
    },
  },
  '2021-05-10': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-30366.16400915',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-10',
    },
  },
  '2021-05-19': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30369.05650902',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-19',
    },
  },
  '2021-05-20': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30398.23401915',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-20',
    },
  },
  '2021-05-22': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30397.69130955',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-22',
    },
  },
  '2021-05-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30403.36240893',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-24',
    },
  },
  '2021-05-25': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30388.22334123',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-25',
    },
  },
  '2021-05-27': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30400.19652753',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-27',
    },
  },
  '2021-05-28': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30392.62264902',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-28',
    },
  },
  '2021-05-29': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30375.88414151',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-29',
    },
  },
  '2021-05-30': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30084.40534116',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-30',
    },
  },
  '2021-05-31': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30084.08838892',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-05-31',
    },
  },
  '2021-06-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30085.36553328',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-01',
    },
  },
  '2021-06-02': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30076.61383166',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-02',
    },
  },
  '2021-06-03': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30082.09429201',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-03',
    },
  },
  '2021-06-04': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30089.47501972',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-04',
    },
  },
  '2021-06-05': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-30061.83739436',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-05',
    },
  },
  '2021-06-07': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30093.65276368',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-07',
    },
  },
  '2021-06-08': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-30043.21246729',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-08',
    },
  },
  '2021-06-11': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30037.16311568',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-11',
    },
  },
  '2021-06-12': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30036.90672992',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-12',
    },
  },
  '2021-06-13': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29984.70358809',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-13',
    },
  },
  '2021-06-15': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29955.62898476',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-15',
    },
  },
  '2021-06-16': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29965.85664376',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-16',
    },
  },
  '2021-06-17': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29961.13592043',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-17',
    },
  },
  '2021-06-18': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29956.72749893',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-18',
    },
  },
  '2021-06-20': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29973.13572478',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-20',
    },
  },
  '2021-06-29': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29974.84951324',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-29',
    },
  },
  '2021-06-30': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29891.15412302',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-06-30',
    },
  },
  '2021-07-01': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29894.77148749',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-01',
    },
  },
  '2021-07-07': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29914.79980268',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-07',
    },
  },
  '2021-07-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29911.09742472',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-08',
    },
  },
  '2021-07-09': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29880.3659121',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-09',
    },
  },
  '2021-07-17': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29891.11208657',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-17',
    },
  },
  '2021-07-18': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29891.59390154',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-18',
    },
  },
  '2021-07-19': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-30071.74556287',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-19',
    },
  },
  '2021-07-20': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30072.58723676',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-20',
    },
  },
  '2021-07-21': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30146.09975693',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-21',
    },
  },
  '2021-07-23': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30099.86030426',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-23',
    },
  },
  '2021-07-26': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30120.48652635',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-26',
    },
  },
  '2021-07-27': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30132.09906629',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-27',
    },
  },
  '2021-07-29': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-30196.86060496',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-29',
    },
  },
  '2021-07-30': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30215.01746711',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-30',
    },
  },
  '2021-07-31': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30225.33182044',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-07-31',
    },
  },
  '2021-08-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30250.47051478',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-01',
    },
  },
  '2021-08-03': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30253.35236869',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-03',
    },
  },
  '2021-08-04': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30137.25282449',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-04',
    },
  },
  '2021-08-05': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30162.32987693',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-05',
    },
  },
  '2021-08-08': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30176.33122505',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-08',
    },
  },
  '2021-08-09': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-30528.31560365',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-09',
    },
  },
  '2021-08-10': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30846.34790934',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-10',
    },
  },
  '2021-08-12': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30928.63790233',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-12',
    },
  },
  '2021-08-15': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30925.45863877',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-15',
    },
  },
  '2021-08-18': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30953.00653794',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-18',
    },
  },
  '2021-08-22': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30940.90688525',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-22',
    },
  },
  '2021-08-23': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30987.11036005',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-23',
    },
  },
  '2021-08-27': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30984.45321079',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-27',
    },
  },
  '2021-08-31': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30992.18392719',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-08-31',
    },
  },
  '2021-09-02': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30971.63584632',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-02',
    },
  },
  '2021-09-03': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30971.15155377',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-03',
    },
  },
  '2021-09-05': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-30970.69066376',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-05',
    },
  },
  '2021-09-06': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30924.55892827',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-06',
    },
  },
  '2021-09-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30926.23260689',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-08',
    },
  },
  '2021-09-11': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30945.61830596',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-11',
    },
  },
  '2021-09-13': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30947.64177979',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-13',
    },
  },
  '2021-09-14': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30941.23117385',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-14',
    },
  },
  '2021-09-16': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30934.26769728',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-16',
    },
  },
  '2021-09-17': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30959.60177155',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-17',
    },
  },
  '2021-09-19': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30938.19344136',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-19',
    },
  },
  '2021-09-25': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30944.01331069',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-25',
    },
  },
  '2021-09-26': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30934.85584738',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-26',
    },
  },
  '2021-09-27': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30923.51477505',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-27',
    },
  },
  '2021-09-28': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-31066.538761',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-28',
    },
  },
  '2021-09-30': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31073.61177114',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-09-30',
    },
  },
  '2021-10-02': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30919.8016597',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-02',
    },
  },
  '2021-10-09': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30971.90869229',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-09',
    },
  },
  '2021-10-13': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30962.00575023',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-13',
    },
  },
  '2021-10-14': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31139.3842545',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-14',
    },
  },
  '2021-10-15': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-31112.54477255',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-15',
    },
  },
  '2021-10-20': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-31131.6963229',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-20',
    },
  },
  '2021-10-21': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31135.39862668',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-21',
    },
  },
  '2021-10-22': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-31212.2795049',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-22',
    },
  },
  '2021-10-23': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-31224.09051747',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-23',
    },
  },
  '2021-10-24': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-31216.54081987',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-24',
    },
  },
  '2021-10-28': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31256.49419958',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-28',
    },
  },
  '2021-10-30': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31272.93885039',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-30',
    },
  },
  '2021-10-31': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-31315.32642438',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-10-31',
    },
  },
  '2021-11-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31321.190504',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-01',
    },
  },
  '2021-11-03': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31322.10431877',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-03',
    },
  },
  '2021-11-04': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31342.59249927',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-04',
    },
  },
  '2021-11-05': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31329.4045774',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-05',
    },
  },
  '2021-11-06': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31313.03178506',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-06',
    },
  },
  '2021-11-14': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31312.37931186',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-14',
    },
  },
  '2021-11-15': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-31013.18862964',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-15',
    },
  },
  '2021-11-16': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-31024.50703453',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-16',
    },
  },
  '2021-11-17': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30924.82295514',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-17',
    },
  },
  '2021-11-21': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30930.73457028',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-21',
    },
  },
  '2021-11-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30943.15070584',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-24',
    },
  },
  '2021-11-25': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30954.85001856',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-25',
    },
  },
  '2021-11-26': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30807.54260923',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-26',
    },
  },
  '2021-11-29': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30828.62607128',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-11-29',
    },
  },
  '2021-12-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30820.54074388',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-01',
    },
  },
  '2021-12-03': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30809.80013521',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-03',
    },
  },
  '2021-12-05': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30804.65890917',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-05',
    },
  },
  '2021-12-09': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30789.37778204',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-09',
    },
  },
  '2021-12-12': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30801.03572882',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-12',
    },
  },
  '2021-12-13': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30642.16903125',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-13',
    },
  },
  '2021-12-17': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30611.21221144',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-17',
    },
  },
  '2021-12-23': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30631.45622017',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-23',
    },
  },
  '2021-12-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30625.11386373',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-24',
    },
  },
  '2021-12-27': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30630.48679178',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-27',
    },
  },
  '2021-12-28': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30424.16390534',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-28',
    },
  },
  '2021-12-29': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30526.33523441',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2021-12-29',
    },
  },
  '2022-01-04': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30525.60646146',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-01-04',
    },
  },
  '2022-01-05': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30471.34417674',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-01-05',
    },
  },
  '2022-01-06': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30480.17897363',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-01-06',
    },
  },
  '2022-01-07': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30444.0659506',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-01-07',
    },
  },
  '2022-01-13': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30460.80504502',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-01-13',
    },
  },
  '2022-01-15': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30435.19466158',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-01-15',
    },
  },
  '2022-01-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30442.38755692',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-01-24',
    },
  },
  '2022-01-28': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30532.17207271',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-01-28',
    },
  },
  '2022-01-31': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30528.37446407',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-01-31',
    },
  },
  '2022-02-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30596.775855',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-01',
    },
  },
  '2022-02-06': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30610.87808155',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-06',
    },
  },
  '2022-02-07': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30626.14550479',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-07',
    },
  },
  '2022-02-08': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30656.38631435',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-08',
    },
  },
  '2022-02-09': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30655.67704446',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-09',
    },
  },
  '2022-02-10': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30631.52615139',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-10',
    },
  },
  '2022-02-11': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30653.74676025',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-11',
    },
  },
  '2022-02-12': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30673.53992264',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-12',
    },
  },
  '2022-02-13': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30698.59076675',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-13',
    },
  },
  '2022-02-14': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30780.91814109',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-14',
    },
  },
  '2022-02-16': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30776.33255722',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-16',
    },
  },
  '2022-02-17': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30624.53238706',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-17',
    },
  },
  '2022-02-18': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30591.60455967',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-18',
    },
  },
  '2022-02-20': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30581.36173249',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-20',
    },
  },
  '2022-02-21': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30609.44115884',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-21',
    },
  },
  '2022-02-22': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30620.49921351',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-22',
    },
  },
  '2022-02-23': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30647.06322881',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-23',
    },
  },
  '2022-02-24': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30643.01083346',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-24',
    },
  },
  '2022-02-26': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30649.35580703',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-26',
    },
  },
  '2022-02-27': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30641.18071344',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-27',
    },
  },
  '2022-02-28': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30638.04211758',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-02-28',
    },
  },
  '2022-03-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30613.09335615',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-01',
    },
  },
  '2022-03-05': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30615.00994991',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-05',
    },
  },
  '2022-03-07': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30638.54667313',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-07',
    },
  },
  '2022-03-08': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30647.58157764',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-08',
    },
  },
  '2022-03-09': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30637.84286535',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-09',
    },
  },
  '2022-03-18': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30630.74743554',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-18',
    },
  },
  '2022-03-19': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-30572.19536767',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-19',
    },
  },
  '2022-03-21': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30594.63565861',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-21',
    },
  },
  '2022-03-22': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30659.87357295',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-22',
    },
  },
  '2022-03-23': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30669.93970236',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-23',
    },
  },
  '2022-03-24': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-30626.89917216',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-24',
    },
  },
  '2022-03-26': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30638.03423792',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-26',
    },
  },
  '2022-03-27': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30716.84652188',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-27',
    },
  },
  '2022-03-28': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30725.1380362',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-28',
    },
  },
  '2022-03-29': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-30721.06780271',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-29',
    },
  },
  '2022-03-30': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30509.16023771',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-30',
    },
  },
  '2022-03-31': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30461.37946701',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-03-31',
    },
  },
  '2022-04-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30494.52533122',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-01',
    },
  },
  '2022-04-02': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30499.87161965',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-02',
    },
  },
  '2022-04-06': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30517.71338627',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-06',
    },
  },
  '2022-04-07': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30389.51393623',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-07',
    },
  },
  '2022-04-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30383.33834227',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-08',
    },
  },
  '2022-04-10': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30396.00440462',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-10',
    },
  },
  '2022-04-11': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-30468.02684483',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-11',
    },
  },
  '2022-04-12': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30676.44349166',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-12',
    },
  },
  '2022-04-17': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30688.51158073',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-17',
    },
  },
  '2022-04-18': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30794.10193232',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-18',
    },
  },
  '2022-04-20': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-30806.07449169',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-20',
    },
  },
  '2022-04-21': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-30800.73184571',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-21',
    },
  },
  '2022-04-22': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-30700.38932072',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-22',
    },
  },
  '2022-04-25': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29930.47350872',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-25',
    },
  },
  '2022-04-26': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29960.69283117',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-26',
    },
  },
  '2022-04-27': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29695.06727211',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-27',
    },
  },
  '2022-04-29': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29695.73184611',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-29',
    },
  },
  '2022-04-30': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29379.39054027',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-04-30',
    },
  },
  '2022-05-04': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29374.14090574',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-04',
    },
  },
  '2022-05-05': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29384.09831122',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-05',
    },
  },
  '2022-05-06': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29395.00227566',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-06',
    },
  },
  '2022-05-07': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29305.33178735',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-07',
    },
  },
  '2022-05-09': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29306.84584657',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-09',
    },
  },
  '2022-05-10': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29269.27583012',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-10',
    },
  },
  '2022-05-13': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29279.24941805',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-13',
    },
  },
  '2022-05-14': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-29372.53238905',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-14',
    },
  },
  '2022-05-15': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29396.77277174',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-15',
    },
  },
  '2022-05-16': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29389.09097638',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-16',
    },
  },
  '2022-05-17': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29402.64419582',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-17',
    },
  },
  '2022-05-18': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29409.78344582',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-18',
    },
  },
  '2022-05-19': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29395.3300694',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-19',
    },
  },
  '2022-05-20': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29405.97732108',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-20',
    },
  },
  '2022-05-21': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29396.88443108',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-21',
    },
  },
  '2022-05-22': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29407.51492403',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-22',
    },
  },
  '2022-05-23': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29410.79388954',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-23',
    },
  },
  '2022-05-24': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29410.47859384',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-24',
    },
  },
  '2022-05-25': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29444.75015757',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-25',
    },
  },
  '2022-05-26': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29470.86550769',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-26',
    },
  },
  '2022-05-27': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29553.37609215',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-27',
    },
  },
  '2022-05-30': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29598.09352749',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-30',
    },
  },
  '2022-05-31': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29636.02285336',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-05-31',
    },
  },
  '2022-06-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29612.89615563',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-01',
    },
  },
  '2022-06-02': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29623.99036854',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-02',
    },
  },
  '2022-06-03': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29586.42019022',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-03',
    },
  },
  '2022-06-04': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29586.52738612',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-04',
    },
  },
  '2022-06-05': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29629.48357787',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-05',
    },
  },
  '2022-06-06': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29616.90135439',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-06',
    },
  },
  '2022-06-07': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29621.83713762',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-07',
    },
  },
  '2022-06-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29620.87556066',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-08',
    },
  },
  '2022-06-09': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29616.83171787',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-09',
    },
  },
  '2022-06-10': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29600.67670643',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-10',
    },
  },
  '2022-06-11': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-29715.91466646',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-11',
    },
  },
  '2022-06-12': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29689.40978797',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-12',
    },
  },
  '2022-06-13': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29643.84902158',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-06-13',
    },
  },
  '2022-08-08': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29560.02155128',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-08',
    },
  },
  '2022-08-09': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29553.70536715',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-09',
    },
  },
  '2022-08-10': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-29453.01160372',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-10',
    },
  },
  '2022-08-14': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29471.5225142',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-14',
    },
  },
  '2022-08-15': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29496.28611956',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-15',
    },
  },
  '2022-08-18': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29524.00484052',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-18',
    },
  },
  '2022-08-19': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29506.50249317',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-19',
    },
  },
  '2022-08-21': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29510.24423429',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-21',
    },
  },
  '2022-08-23': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29526.73559384',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-23',
    },
  },
  '2022-08-24': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29538.9881126',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-24',
    },
  },
  '2022-08-25': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29545.19345918',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-25',
    },
  },
  '2022-08-26': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29543.70119484',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-26',
    },
  },
  '2022-08-27': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29540.53443429',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-27',
    },
  },
  '2022-08-29': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29539.51716316',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-29',
    },
  },
  '2022-08-30': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29584.63838684',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-30',
    },
  },
  '2022-08-31': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29596.5209438',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-08-31',
    },
  },
  '2022-09-02': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29575.9194707',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-02',
    },
  },
  '2022-09-03': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29592.40544472',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-03',
    },
  },
  '2022-09-06': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29595.1565952',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-06',
    },
  },
  '2022-09-07': {
    binance: {
      name: 'binance',
      counter: 8,
      value: '-29566.02414073',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-07',
    },
  },
  '2022-09-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29564.17286275',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-08',
    },
  },
  '2022-09-09': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29617.83062699',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-09',
    },
  },
  '2022-09-10': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29614.67064245',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-10',
    },
  },
  '2022-09-12': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29612.84266355',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-12',
    },
  },
  '2022-09-13': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29599.21213161',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-13',
    },
  },
  '2022-09-14': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29542.63584464',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-14',
    },
  },
  '2022-09-15': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29545.83657844',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-15',
    },
  },
  '2022-09-18': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29530.99681409',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-18',
    },
  },
  '2022-09-19': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29122.46045187',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-19',
    },
  },
  '2022-09-21': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29115.85991623',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-21',
    },
  },
  '2022-09-22': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29049.93236342',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-22',
    },
  },
  '2022-09-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29066.67490402',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-24',
    },
  },
  '2022-09-26': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-29101.79251235',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-26',
    },
  },
  '2022-09-28': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29149.787466',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-28',
    },
  },
  '2022-09-29': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29170.75515767',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-09-29',
    },
  },
  '2022-10-04': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29184.00456286',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-04',
    },
  },
  '2022-10-06': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29215.25436692',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-06',
    },
  },
  '2022-10-07': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29181.59222625',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-07',
    },
  },
  '2022-10-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29184.1995536',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-08',
    },
  },
  '2022-10-09': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29218.19396807',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-09',
    },
  },
  '2022-10-10': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29216.63190622',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-10',
    },
  },
  '2022-10-11': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29298.8282617',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-11',
    },
  },
  '2022-10-12': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29296.59253873',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-12',
    },
  },
  '2022-10-13': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29320.61131961',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-13',
    },
  },
  '2022-10-14': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29249.4731566',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-14',
    },
  },
  '2022-10-15': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29183.4200019',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-15',
    },
  },
  '2022-10-16': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29171.75574317',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-16',
    },
  },
  '2022-10-18': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29128.92055601',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-18',
    },
  },
  '2022-10-19': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29128.43687749',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-19',
    },
  },
  '2022-10-20': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29122.78597395',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-20',
    },
  },
  '2022-10-21': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29116.5669657',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-21',
    },
  },
  '2022-10-23': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-29117.44175959',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-23',
    },
  },
  '2022-10-24': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29041.66921866',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-24',
    },
  },
  '2022-10-25': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-28801.36823513',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-25',
    },
  },
  '2022-10-26': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28849.6379081',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-26',
    },
  },
  '2022-10-28': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-28996.70195246',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-28',
    },
  },
  '2022-10-29': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-29071.91157883',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-29',
    },
  },
  '2022-10-31': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29077.66806208',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-10-31',
    },
  },
  '2022-11-01': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29115.22501155',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-01',
    },
  },
  '2022-11-02': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29001.95530837',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-02',
    },
  },
  '2022-11-03': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29005.47264555',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-03',
    },
  },
  '2022-11-04': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29182.32759568',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-04',
    },
  },
  '2022-11-05': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-28847.6479007',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-05',
    },
  },
  '2022-11-06': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28858.71258834',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-06',
    },
  },
  '2022-11-07': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28859.16315282',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-07',
    },
  },
  '2022-11-08': {
    binance: {
      name: 'binance',
      counter: 6,
      value: '-28935.03673395',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-08',
    },
  },
  '2022-11-09': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-28891.88549263',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-09',
    },
  },
  '2022-11-10': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-28899.60895352',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-10',
    },
  },
  '2022-11-11': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28926.5678907',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-11',
    },
  },
  '2022-11-13': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29004.98038011',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-13',
    },
  },
  '2022-11-15': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29015.38351543',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-15',
    },
  },
  '2022-11-16': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29033.52484339',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-16',
    },
  },
  '2022-11-18': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-29081.70520784',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-18',
    },
  },
  '2022-11-19': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29038.03755628',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-19',
    },
  },
  '2022-11-20': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29067.77011928',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-20',
    },
  },
  '2022-11-21': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29099.35339444',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-21',
    },
  },
  '2022-11-22': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29128.33083492',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-22',
    },
  },
  '2022-11-23': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29107.53170253',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-23',
    },
  },
  '2022-11-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29106.95303377',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-24',
    },
  },
  '2022-11-25': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29195.35328835',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-25',
    },
  },
  '2022-11-26': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29203.48283045',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-26',
    },
  },
  '2022-11-27': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29200.32451436',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-27',
    },
  },
  '2022-11-28': {
    binance: {
      name: 'binance',
      counter: 6,
      value: '-29295.67624643',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-28',
    },
  },
  '2022-11-29': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29287.20920077',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-29',
    },
  },
  '2022-11-30': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29283.82779664',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-11-30',
    },
  },
  '2022-12-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29275.52869501',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-01',
    },
  },
  '2022-12-02': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29326.71601847',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-02',
    },
  },
  '2022-12-03': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29332.62925106',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-03',
    },
  },
  '2022-12-05': {
    binance: {
      name: 'binance',
      counter: 8,
      value: '-29488.89099244',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-05',
    },
  },
  '2022-12-06': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29488.56536898',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-06',
    },
  },
  '2022-12-07': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29509.91767275',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-07',
    },
  },
  '2022-12-08': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-29570.46203808',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-08',
    },
  },
  '2022-12-09': {
    binance: {
      name: 'binance',
      counter: 6,
      value: '-29639.87056968',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-09',
    },
  },
  '2022-12-10': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29654.06006492',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-10',
    },
  },
  '2022-12-11': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29668.87281532',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-11',
    },
  },
  '2022-12-12': {
    binance: {
      name: 'binance',
      counter: 6,
      value: '-29630.50515584',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-12',
    },
  },
  '2022-12-13': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29634.5636076',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-13',
    },
  },
  '2022-12-14': {
    binance: {
      name: 'binance',
      counter: 6,
      value: '-29588.3884432',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-14',
    },
  },
  '2022-12-15': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29615.68936442',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-15',
    },
  },
  '2022-12-16': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29610.60836359',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-16',
    },
  },
  '2022-12-17': {
    binance: {
      name: 'binance',
      counter: 6,
      value: '-29620.71971129',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-17',
    },
  },
  '2022-12-18': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29673.81688058',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-18',
    },
  },
  '2022-12-19': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29682.57576032',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-19',
    },
  },
  '2022-12-20': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29609.45756069',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-20',
    },
  },
  '2022-12-21': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29610.38713529',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-21',
    },
  },
  '2022-12-22': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29636.00134536',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-22',
    },
  },
  '2022-12-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29636.52956344',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-24',
    },
  },
  '2022-12-25': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29679.89761404',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-25',
    },
  },
  '2022-12-26': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29729.38883145',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-26',
    },
  },
  '2022-12-27': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29733.75613717',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-27',
    },
  },
  '2022-12-28': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-29728.43983632',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-28',
    },
  },
  '2022-12-29': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-29765.38548628',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-29',
    },
  },
  '2022-12-30': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29798.10482844',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2022-12-30',
    },
  },
  '2023-01-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-29696.83149111',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-01',
    },
  },
  '2023-01-02': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-28939.77015206',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-02',
    },
  },
  '2023-01-04': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-28929.23371434',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-04',
    },
  },
  '2023-01-05': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28931.45888714',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-05',
    },
  },
  '2023-01-06': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28932.35382312',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-06',
    },
  },
  '2023-01-07': {
    binance: {
      name: 'binance',
      counter: 5,
      value: '-29028.20432122',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-07',
    },
  },
  '2023-01-08': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-28813.43901052',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-08',
    },
  },
  '2023-01-09': {
    binance: {
      name: 'binance',
      counter: 9,
      value: '-28127.37202046',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-09',
    },
  },
  '2023-01-10': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28177.16243174',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-10',
    },
  },
  '2023-01-11': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28213.48578339',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-11',
    },
  },
  '2023-01-12': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28249.74207068',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-12',
    },
  },
  '2023-01-13': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28170.24440365',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-13',
    },
  },
  '2023-01-15': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28171.62133556',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-15',
    },
  },
  '2023-01-17': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28184.2153354',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-17',
    },
  },
  '2023-01-18': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28189.14951236',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-18',
    },
  },
  '2023-01-20': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28273.32747734',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-20',
    },
  },
  '2023-01-21': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28243.60745159',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-21',
    },
  },
  '2023-01-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28237.68968582',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-24',
    },
  },
  '2023-01-25': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28247.54758955',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-25',
    },
  },
  '2023-01-26': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28254.99418982',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-26',
    },
  },
  '2023-01-27': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28249.6251065',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-27',
    },
  },
  '2023-01-29': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28237.57126003',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-29',
    },
  },
  '2023-01-31': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28246.82511996',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-01-31',
    },
  },
  '2023-02-04': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28261.92248549',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-04',
    },
  },
  '2023-02-09': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28171.10097326',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-09',
    },
  },
  '2023-02-10': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28167.63024157',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-10',
    },
  },
  '2023-02-11': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-28184.27462534',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-11',
    },
  },
  '2023-02-14': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28190.70690354',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-14',
    },
  },
  '2023-02-15': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-28191.77223787',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-15',
    },
  },
  '2023-02-16': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28203.67537963',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-16',
    },
  },
  '2023-02-20': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28236.58905125',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-20',
    },
  },
  '2023-02-21': {
    binance: {
      name: 'binance',
      counter: 4,
      value: '-28264.60330643',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-21',
    },
  },
  '2023-02-22': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28247.32760491',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-22',
    },
  },
  '2023-02-25': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28246.69546649',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-25',
    },
  },
  '2023-02-26': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28251.69073179',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-26',
    },
  },
  '2023-02-28': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28301.95208113',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-02-28',
    },
  },
  '2023-03-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28298.9025874',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-01',
    },
  },
  '2023-03-02': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28310.77223244',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-02',
    },
  },
  '2023-03-03': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28311.20374836',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-03',
    },
  },
  '2023-03-05': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28309.0820506',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-05',
    },
  },
  '2023-03-06': {
    binance: {
      name: 'binance',
      counter: 3,
      value: '-28322.93217768',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-06',
    },
  },
  '2023-03-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28322.97422338',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-08',
    },
  },
  '2023-03-09': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28341.81879432',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-09',
    },
  },
  '2023-03-16': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28366.29282943',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-16',
    },
  },
  '2023-03-17': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28366.47223135',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-17',
    },
  },
  '2023-03-19': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28369.89438054',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-19',
    },
  },
  '2023-03-22': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28378.46000103',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-22',
    },
  },
  '2023-03-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28374.22643239',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-24',
    },
  },
  '2023-03-26': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28354.6952427',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-26',
    },
  },
  '2023-03-27': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28354.75805473',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-27',
    },
  },
  '2023-03-30': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28362.95363653',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-03-30',
    },
  },
  '2023-04-06': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28373.8141614',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-04-06',
    },
  },
  '2023-04-07': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28374.31497068',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-04-07',
    },
  },
  '2023-04-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28397.32572168',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-04-08',
    },
  },
  '2023-04-10': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28371.66480216',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-04-10',
    },
  },
  '2023-04-11': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28371.69851292',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-04-11',
    },
  },
  '2023-04-22': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28371.74629562',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-04-22',
    },
  },
  '2023-04-27': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28368.39196701',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-04-27',
    },
  },
  '2023-04-28': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28367.79014886',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-04-28',
    },
  },
  '2023-04-29': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28356.51384004',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-04-29',
    },
  },
  '2023-05-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28361.98581184',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-05-08',
    },
  },
  '2023-05-09': {
    binance: {
      name: 'binance',
      counter: 2,
      value: '-28362.59354862',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-05-09',
    },
  },
  '2023-05-13': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28362.36412911',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-05-13',
    },
  },
  '2023-05-14': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28364.25674633',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-05-14',
    },
  },
  '2023-05-17': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28361.05324122',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-05-17',
    },
  },
  '2023-05-18': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28360.34332467',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-05-18',
    },
  },
  '2023-05-19': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28362.1747187',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-05-19',
    },
  },
  '2023-06-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28312.16604193',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2023-06-01',
    },
  },
  '2024-03-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28312.22576573',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-03-24',
    },
  },
  '2024-04-08': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28312.11236262',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-04-08',
    },
  },
  '2024-04-16': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28311.38918339',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-04-16',
    },
  },
  '2024-04-24': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28309.45206519',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-04-24',
    },
  },
  '2024-04-29': {
    binance: {
      name: 'binance',
      value: '-28309.45206519',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-04-29',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 3,
      value: '-0.02319492',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-04-29',
    },
  },
  '2024-04-30': {
    binance: {
      name: 'binance',
      value: '-28309.45206519',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-04-30',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 18,
      value: '-0.37320086',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-04-30',
    },
  },
  '2024-05-01': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28308.04851572',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-01',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 1,
      value: '-0.30660286',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-01',
    },
  },
  '2024-05-02': {
    binance: {
      name: 'binance',
      value: '-28308.04851572',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-02',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 3,
      value: '-0.32246585',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-02',
    },
  },
  '2024-05-07': {
    binance: {
      name: 'binance',
      value: '-28308.04851572',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-07',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 1,
      value: '-0.44786585',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-07',
    },
  },
  '2024-05-08': {
    binance: {
      name: 'binance',
      value: '-28308.04851572',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-08',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 2,
      value: '-0.36360684',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-08',
    },
  },
  '2024-05-09': {
    binance: {
      name: 'binance',
      value: '-28308.04851572',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-09',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 9,
      value: '-0.81499425',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-09',
    },
  },
  '2024-05-13': {
    binance: {
      name: 'binance',
      value: '-28308.04851572',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-13',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 12,
      value: '-1.03522048',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-13',
    },
  },
  '2024-05-14': {
    binance: {
      name: 'binance',
      counter: 1,
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-14',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 10,
      value: '-1.04587101',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-14',
    },
  },
  '2024-05-21': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-21',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 3,
      value: '-1.09057102',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-21',
    },
  },
  '2024-05-23': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-23',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 3,
      value: '-1.18232102',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-23',
    },
  },
  '2024-05-24': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-24',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 7,
      value: '-1.53913075',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-24',
    },
  },
  '2024-05-25': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-25',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 9,
      value: '-1.75615074',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-25',
    },
  },
  '2024-05-26': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-26',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 10,
      value: '-2.03059318',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-26',
    },
  },
  '2024-05-27': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-27',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 3,
      value: '-2.05058232',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-05-27',
    },
  },
  '2024-06-04': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-04',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 2,
      value: '-2.07122796',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-04',
    },
  },
  '2024-06-10': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-10',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 2,
      value: '-2.54240751',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-10',
    },
  },
  '2024-06-11': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-11',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 8,
      value: '-3.73673706',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-11',
    },
  },
  '2024-06-13': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-13',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 1,
      value: '-3.74225406',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-13',
    },
  },
  '2024-06-26': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-26',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 7,
      value: '-4.0396455',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-26',
    },
  },
  '2024-06-28': {
    binance: {
      name: 'binance',
      value: '-28308.10461868',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-28',
    },
    'бинанс ж': {
      name: 'бинанс ж',
      counter: 3,
      value: '-4.07344801',
      value2: '0',
      value3: '0',
      percent: '0',
      dateValue: '2024-06-28',
    },
  },
}
