import { FC, memo } from 'react'
import { TitleH1 } from '../../atoms/TitleH1'
import AssetsAmountList from '../AssetsAmountList'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { Button } from '@/ui/kit'
import { SVG } from '@/assets/svg'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'

const AssetsLeftBar: FC = () => {
  const { exchangeType } = useExchageType()
  const navigate = useNavigate()

  function handleConvert() {
    navigate(urls.binanceConvert)
  }

  function handleConvertHistory() {
    navigate(urls.historyConvert)
  }

  return (
    <div className={style.leftBar}>
      <TitleH1 label={t({ message: 'Your assets', id: 'assts.yourAssetsTitle' })} />
      <AssetsAmountList />
      {(exchangeType === EXCHANGES_TYPE.BINANCE_BROKER_FUTURE || exchangeType === EXCHANGES_TYPE.BINANCE_VIP) && (
        <div className={style.buttonWrapp}>
          <Button.Accent
            rightIcon={<SVG.OtherIcons.Transfer />}
            onClick={handleConvert}
            label={t({ id: 'core.binance.conver', comment: 'Binance convert' })}
            className={style.button}
          />
          <Button.Primary
            onClick={handleConvertHistory}
            label={t({ id: 'core.binance.converHistory', comment: 'Binance convert history' })}
            className={style.button}
          />
        </div>
      )}
    </div>
  )
}

export default memo(AssetsLeftBar)
