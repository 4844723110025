import {
  GetReferralOverviewTC,
  ApplyReferralCodeTC,
  CreateReferralCodeTC,
  DeleteReferralCodeTC,
  GetReferralEarningsTC,
  GetReferralBenefitsTC,
  UpdateReferralCodeTC,
  UpdateCustomReferralCodeTC,
  GetReferralCodeInfoTC,
} from './referrals.thunk'

export const referralsAsyncActions = {
  GetReferralOverviewTC,
  ApplyReferralCodeTC,
  CreateReferralCodeTC,
  DeleteReferralCodeTC,
  GetReferralEarningsTC,
  GetReferralBenefitsTC,
  UpdateReferralCodeTC,
  UpdateCustomReferralCodeTC,
  GetReferralCodeInfoTC,
}
