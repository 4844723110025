import {
  GetTradesTC,
  GetTradeByIdTC,
  GetCategoriesTC,
  GetTagsTC,
  GetMeTC,
  GetTickers,
  GetUIDataTC,
  SetUIDataTC,
  TradesUserInitTC,
  PostTradesAvailableExchangeTC,
  TradesAvailableUsersTC,
  EditTagsTC,
  DeleteTagTC,
  EditDescriptionTC,
  GetAnalyzerWeek,
  GetAnalyzerTC,
  GetTradesAvailableExchangeTC,
  RepairApiKeyTC,
  RepairKeyTC,
  GetTradesDayOrdersTC,
} from './trades.thunk'

export const tradesAsyncActions = {
  GetUIDataTC,
  SetUIDataTC,
  GetTradesTC,
  GetTradeByIdTC,
  GetCategoriesTC,
  GetTagsTC,
  EditTagsTC,
  EditDescriptionTC,
  DeleteTagTC,
  GetMeTC,
  GetTickers,
  TradesUserInitTC,
  PostTradesAvailableExchangeTC,
  TradesAvailableUsersTC,
  GetAnalyzerWeek,
  GetAnalyzerTC,
  GetTradesAvailableExchangeTC,
  RepairApiKeyTC,
  RepairKeyTC,
  GetTradesDayOrdersTC,
}
