import clsx from 'clsx'
import { FC, memo } from 'react'
import { formatNumber } from '../../../utils'
import style from './style.module.scss'
import { Trans, t } from '@lingui/macro'
import { DataTestIds } from '@/utils/lib/dataTestIds'

type CashbackRightBarListHeaderProps = {
  totalCashback: number
  total: number
}

const CashbackRightBarListHeader: FC<CashbackRightBarListHeaderProps> = props => {
  return (
    <div className={clsx(style.cashbackRightBarListHeader, props?.total === 0 && style.empty)}>
      <span className={style.label}>{t({ id: 'cashbackPage.rightBar.tableTitle', message: 'History' })}</span>
      <span className={style.total}>
        {t({ id: 'cashbackPage.rightBar.total', message: 'Total commission returned: ' })}
        <strong data-testid={DataTestIds.CashbackTotal}>{formatNumber(props?.totalCashback || 0)} USDT</strong>
      </span>
    </div>
  )
}

export default memo(CashbackRightBarListHeader)
