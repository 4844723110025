import { HISTORY_TRANSACTIONS_SIZE } from '@/core/config/history'
import { HistoryState } from './history.types'

const initialState: HistoryState = {
  historyCashback: {
    cashback: [],
    page: 0,
    size: 0,
    total: 0,
    totalCashback: 0,
  },
  historyCashbackStatus: 'idle',
  historyCashbackReachedEnd: false,
  historyData: {
    history: [],
    page: 0,
    size: HISTORY_TRANSACTIONS_SIZE,
    total: 0,
  },
  historyDataStatus: 'idle',
  historyDataReachedEnd: false,
}

export default initialState
