import { t } from '@lingui/macro'
import React, { FC, useEffect } from 'react'
import { Loader } from '@/ui/kit/Loader'
import { EmptyList } from '@/ui/molecules'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { VoucherActiveList } from '@/ui/molecules/VoucherActiveList'
import { VoucherHistoryList } from '@/ui/molecules/VoucherHistoryList'
import { usePageState } from '@/ui/organisms/VoucherRightBar/hooks/usePageState'
import { voucherActions } from '@/redux/voucher/voucher.slice'
import { useActions } from '@/utils'
import s from './style.module.scss'

interface IVoucherRightBarProps {}

const VoucherRightBar: FC<IVoucherRightBarProps> = props => {
  const { GetVoucherOverviewTC } = useActions(voucherActions)
  const { isLoading, isEmpty, isError } = usePageState()

  useEffect(() => {
    GetVoucherOverviewTC()
  }, [])

  if (isLoading) {
    return <Loader className={s.loader} />
  }

  if (isEmpty) {
    return (
      <EmptyList
        title={t({
          id: 'voucher.notFound.title',
          message: `Nothing found`,
        })}
        label={t({
          id: 'voucher.notFound.subtitle',
          message: 'You don`t have any vouchers',
        })}
        className={s.empty}
      />
    )
  }

  if (isError) {
    return (
      <ErrorBase
        customHeader={t({
          id: 'voucher.error.unload',
          message: `Couldn't load vouchers`,
        })}
        className={s.error}
        isRefreshButtonVisible
      />
    )
  }

  return (
    <div className={s.root}>
      <VoucherActiveList />
      <VoucherHistoryList />
    </div>
  )
}

export { VoucherRightBar }
