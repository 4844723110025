import { FC, ReactNode, useMemo } from 'react'
import { Button, ButtonVariant } from '@/ui/kit'
import s from './style.module.scss'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'

interface Props {
  children: ReactNode
}

// Only for Mobile version!!
export const PolicyTermsWrapper: FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const route = {
    isPolicy: pathname === urls.policy,
    isTerms: pathname === urls.terms,
  }

  const text = useMemo(() => {
    return {
      back: t({
        id: 'core.back',
        message: `Back`,
      }),
    }
  }, [])

  return (
    <div className={s.container}>
      <div className={s.top}>
        <button className={s.backButton} type="button" onClick={() => navigate(-1)}>
          <SVG.Arrows.ArrowLeft className={s.arrow} />
          <span>{text.back}</span>
        </button>

        <div className={s.tabsButton}>
          <Button
            className={s.button}
            variant={route.isTerms ? ButtonVariant.White : ButtonVariant.Primary}
            onClick={() => !route.isTerms && navigate(urls.terms, { replace: true })}
            label="Term of Use"
          />

          <Button
            className={s.button}
            variant={route.isPolicy ? ButtonVariant.White : ButtonVariant.Primary}
            onClick={() => !route.isPolicy && navigate(urls.policy, { replace: true })}
            label="Privacy Policy"
          />
        </div>
      </div>

      {children}
    </div>
  )
}
