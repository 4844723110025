import { useNavigate } from 'react-router-dom'
import { t } from '@lingui/macro'
import { KYCTier, KYCWorkflowStatus } from '@/backend/models/KycDTO'
import { ProfileKYCStatus } from '@/ui/molecules/ProfileKYCStatus'
import { useActions, useKYC } from '@/utils/hooks'
import { useAppSelector } from '@/utils'
import { Button } from '@/ui/kit'
import { SVG } from '@/assets/svg'
import style from './style.module.scss'
import { urls } from '@/router/urls'
import { LevelUpgradeButton } from '@/ui/molecules/LevelUpgradeButton'
import { useCallback, useEffect, useMemo } from 'react'
import { kycAsyncActions } from '@/redux/kyc/kyc.actions'

const ProfileLevel = () => {
  const navigate = useNavigate()
  const { kycLevelTitle, handleKycUpgrade, currentTierLimits, nextTierLimits, isLoading } = useKYC()
  const { userKYC, workflow } = useAppSelector(state => state.kyc)
  const { GetUserKycTC } = useActions(kycAsyncActions)

  useEffect(() => {
    GetUserKycTC()
  }, [GetUserKycTC])

  const kycLimits = useMemo(() => {
    const textUpTo = t({ id: 'limits.upTo', message: 'up to' })
    const textUnavailable = t({ id: 'limits.unavailable', message: 'unavailable' })

    return {
      deposit: Boolean(currentTierLimits?.deposit?.raw)
        ? `${textUpTo} ${currentTierLimits?.deposit?.formatted}`
        : textUnavailable,
      withdraw: Boolean(currentTierLimits?.withdraw?.raw)
        ? `${textUpTo} ${currentTierLimits?.withdraw?.formatted}`
        : textUnavailable,
    }
  }, [currentTierLimits])

  const statusInfo = useMemo(() => {
    switch (true) {
      case userKYC.kycTier === KYCTier.Unverified:
      case userKYC.kycTier === KYCTier.Basic:
        return Boolean(nextTierLimits?.deposit?.raw)
          ? `${t({ id: 'profile.verify', message: 'Verify to increase deposit and withdrawal limits up to' })} ${
              nextTierLimits?.deposit?.formatted
            }`
          : null
      case workflow.status === KYCWorkflowStatus.InProgress:
        return t({
          id: 'profile.kycInProgress',
          message: `We’ll inform you about the outcome of the verification soon. You can add personal data if you have not completed all the steps.`,
        })
      case workflow.status === KYCWorkflowStatus.Rejected:
        return t({
          id: 'profile.kycRejected',
          message: `We weren’t able to verify you`,
        })
      default:
        return null
    }
  }, [userKYC.kycTier, workflow.status, nextTierLimits])

  const handleLevelsClick = useCallback(() => {
    navigate(urls.kycLevels)
  }, [navigate])

  return (
    <section className={style.level}>
      <h3 className={style.levelTitle}>{kycLevelTitle}</h3>

      <ProfileKYCStatus />

      <div className={style.restrictions}>
        <div className={style.restriction}>
          <div>
            <div className={style.restrictionName}>{t({ id: 'profile.deposit', message: `Deposit` })}</div>
            <div className={style.restrictionValue}>{kycLimits?.deposit}</div>
          </div>
          <SVG.Settings.Deposit />
        </div>
        <div className={style.restriction}>
          <div>
            <div className={style.restrictionName}>{t({ id: 'profile.withdraw', message: `Withdraw` })}</div>
            <div className={style.restrictionValue}>{kycLimits?.withdraw}</div>
          </div>
          <SVG.Settings.Withdraw />
        </div>
      </div>

      {statusInfo && <div className={style.statusInfo}>{statusInfo}</div>}

      <div className={style.upgradeButtons}>
        <LevelUpgradeButton handleUpgrade={handleKycUpgrade} classNameWrap={style.upgradeButtonsItem} />

        <div className={style.upgradeButtonsItem}>
          <Button.Primary
            label={t({ message: 'About KYC levels', id: 'kycLevels.about' })}
            className={style.button}
            onClick={handleLevelsClick}
          />
        </div>
      </div>
    </section>
  )
}

export { ProfileLevel }
