import { SVG } from '@/assets/svg'
import { TitleH2 } from '@/ui/atoms/TitleH2'
import { Button } from '@/ui/kit'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { t } from '@lingui/macro'
import style from './style.module.scss'
import { AddressList } from '@/ui/organisms/AddressList'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { useActions, useAppSelector } from '@/utils'
import { useEffect } from 'react'
import { addressActions } from '@/redux/address/address.slice'
import { Whitelist } from '@/ui/organisms/Whitelist'

export const AddressesPage = () => {
  const navigate = useNavigate()
  const { getWhitelistTC, getAddressesTC } = useActions(addressActions)
  const navigateAddressCreatePage = () => navigate(urls.createAddresses)
  const { whitelistEnabled, whitelistGetStatus } = useAppSelector(state => state.address)
  useEffect(() => {
    getWhitelistTC()
  }, [getWhitelistTC])

  useEffect(() => {
    getAddressesTC()
  }, [getAddressesTC])

  return (
    <div className={style.address}>
      <div className={style.address_title}>
        <TitleH2
          label={t({
            id: 'addressesPage.heading',
            message: 'Address Management',
          })}
        />

        <Button.Accent
          label={t({
            id: 'addressesPage.create',
            message: 'Add Address',
          })}
          onClick={navigateAddressCreatePage}
          rightIcon={<SVG.OtherIcons.Plus />}
          dataTestId={DataTestIds.ApiPageCreateApiKeyButton}
        />
      </div>
      <div className={style.address_searchBlock}>
        {/* 
          TODO
        <InputSearch
          value={''}
          setValue={() => {}}
          size={InputSize.Small}
          placeholder={t({
            id: 'core.search',
            message: 'Search',
          })}
          dataTestId={DataTestIds.LeveragePageSearchField}
        /> */}
      </div>
      {whitelistGetStatus === 'succeeded' && <Whitelist whitelistEnabled={whitelistEnabled} />}
      <AddressList />
    </div>
  )
}
