import { InputDropdownOption } from './InputDropdown.types'

export const convertOptionsToObject = <T extends string>(options: InputDropdownOption<T>[]) => {
  return options.reduce<Record<string, InputDropdownOption<T>>>((accumulator, current) => {
    if (current.value) {
      accumulator[current.value] = current
    }

    return accumulator
  }, {})
}
