import * as yup from 'yup'
import { IBinanceVipConsentForm } from '../BinanceVipConsentForm.types'
import { t } from '@lingui/macro'
import { checkMetadataBinanceVipPermisions } from '@/redux/profile/profile.utils'

export const VALIDATION_SCHEMA = (): yup.ObjectSchema<IBinanceVipConsentForm> => {
  return yup.object({
    permissions: yup
      .array()
      .default([])
      .test('permissions', t({ id: 'binanceVipConsentForm.errors.permissions' }), value => {
        return checkMetadataBinanceVipPermisions(value)
      }),
  })
}
