import { FC } from 'react'

export const Kyc: FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="url(#paint0_radial_15433_3418)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9011 10.4723C17.5177 10.4723 15.5789 12.4111 15.5789 14.7945C15.5789 17.1192 17.4149 19.042 19.7378 19.1162C19.7495 19.1165 19.7612 19.1167 19.7729 19.1167H20.011C20.0227 19.1167 20.0344 19.1165 20.046 19.1162C22.3669 19.0421 24.199 17.1421 24.2233 14.806V14.7945C24.2233 12.4111 22.2845 10.4723 19.9011 10.4723ZM15.7565 22.243L15.7545 22.2443C14.3855 23.157 13.2555 25.9589 14.3538 27.4535C15.633 29.1943 18.0056 29.5274 19.9995 29.5274C21.993 29.5274 24.3827 29.1938 25.6539 27.4424C26.7389 25.9474 25.6117 23.1572 24.2474 22.2463C23.0379 21.4298 21.4973 21.057 20.0132 21.057C18.5271 21.057 16.9805 21.4307 15.7565 22.243Z"
        fill="#FAFAFA"
      />
      <defs>
        <radialGradient
          id="paint0_radial_15433_3418"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(40.8151) scale(52.8525 698.452)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
