import { EXCHANGE_REST_HEADER_NAME, TExchangesAvailable } from '@/core/constants'
import { axiosMainService } from '@/services/axios'
import { logoutInterceptorCreator } from '../interceptors/logoutInterceptor'
import { AxiosError } from 'axios'

interface ISetupAxiosParams {
  accessToken?: string
  exchangeHeader?: TExchangesAvailable
  logoutCallback?: () => any
  errorInterceptor?: (error: AxiosError) => any
}

const setupAxiosMainService = (params: ISetupAxiosParams) => {
  if (params.accessToken) axiosMainService.defaults.headers['Authorization'] = `Bearer ${params.accessToken}`
  if (params.exchangeHeader) axiosMainService.defaults.headers[EXCHANGE_REST_HEADER_NAME] = params.exchangeHeader
  if (params.logoutCallback)
    axiosMainService.interceptors.response.use(undefined, logoutInterceptorCreator(params.logoutCallback))
  if (params.errorInterceptor) axiosMainService.interceptors.response.use(undefined, params.errorInterceptor)
}

export { setupAxiosMainService }
