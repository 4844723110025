import { FC } from 'react'
import clsx from 'clsx'
import { t } from '@lingui/macro'
import { enqueueSnackbar } from 'notistack'
import { SVG } from '@/assets/svg'
import { ReferralCustomCode } from '@/backend/models/ReferralsDTO'
import { Button, ButtonVariant, CopyIcon, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { copyToClipboard, formatNumber, useToggle } from '@/utils'
import { useApi } from './hooks/useApi'
import s from './style.module.scss'
import { referralLinkCreator } from '@/router/urls'
import { PopupEditRefCode } from './PopupEditRefCode/PopupEditRefCode'

interface IReferralCodeProps {
  code: ReferralCustomCode
  disabled?: boolean
  className?: string
  isCustom?: boolean
}

const ReferralCode: FC<IReferralCodeProps> = props => {
  const {
    code: { code, bonusBinanceCashbackPercent, bonusBybitCashbackPercent, bonusPeriodDays },
    disabled,
    className,
  } = props

  const { deleteCode } = useApi()
  const [showDeleteModal, toggleModal] = useToggle(false)
  const [showEditModal, toggleEditModal] = useToggle(false)

  const handleCopyLink = () => {
    copyToClipboard(referralLinkCreator(encodeURIComponent(code)))

    enqueueSnackbar(t({ id: 'referralPage.linkCopyMessage', message: 'Referral link copied to clipboard' }), {
      variant: 'success',
    })
  }

  const handleDelete = () => {
    deleteCode(code)
  }

  return (
    <div className={clsx(s.root, { [s.disabled]: disabled }, className)}>
      <div className={s.head}>
        <div>
          <div className={s.text}>
            <div className={s.code}>{code}</div>
            <CopyIcon text={code} className={s.copy} />
          </div>
          <div className={s.info}>{t({ id: 'referralPage.referralCode', message: 'Referral code' })}</div>
        </div>
        <div className={s.editBtn} onClick={toggleEditModal}>
          <SVG.OtherIcons.Edit />
        </div>
      </div>
      <div>
        <div className={s.text}>
          {bonusPeriodDays
            ? `${bonusPeriodDays} ${
                bonusPeriodDays > 1
                  ? t({ id: 'referralPage.days', message: 'days' })
                  : t({ id: 'referralPage.day', message: 'day' })
              }`
            : t({ id: 'referralPage.unlimited', message: 'Unlimited' })}
        </div>
        <div className={s.info}>{t({ id: 'referralPage.validityPeriod', message: 'Validity period' })}</div>
      </div>
      <div>
        <div className={s.cashbackBlock}>
          <div className={s.text}>
            {formatNumber(bonusBinanceCashbackPercent * 100, { maximumFractionDigits: 1 })}%/
          </div>
          <div className={s.text}>{formatNumber(bonusBybitCashbackPercent * 100, { maximumFractionDigits: 1 })}%</div>
          {/* <div className={s.text}>{formatNumber(bonusOkxCashbackPercent * 100, { maximumFractionDigits: 1 })}%</div> */}
        </div>
        <div className={s.info}>
          {t({ id: 'referralPage.reductionRate.block', message: 'Reduction rates (Binance / Bybit / OKX)' })}
        </div>
      </div>
      <Button.Primary
        label={t({ id: 'core.button.copyLink', message: 'Copy link' })}
        onClick={handleCopyLink}
        className={s.copyBtn}
      />
      <button onClick={toggleModal} className={s.deleteBtn}>
        <SVG.Additional.Close height="8px" width="8px" />
      </button>

      <InteractiveModal isOpen={showDeleteModal}>
        <InteractiveModalParts.SubHeader text={t({ id: 'referralPage.deleteHeading', message: 'Deactivate code' })} />
        <InteractiveModalParts.SubExplanation
          text={t({ id: 'referralPage.deleteInfo', message: 'Confirm code deactication' })}
        />
        <InteractiveModalParts.Button
          text={t({ id: 'core.button.confirm', message: 'Confirm' })}
          variant={ButtonVariant.Accent}
          onClick={handleDelete}
        />
        <InteractiveModalParts.Button
          text={t({ id: 'core.button.cancel', message: 'Cancel' })}
          variant={ButtonVariant.Primary}
          onClick={toggleModal}
        />
      </InteractiveModal>
      <PopupEditRefCode isOpen={showEditModal} onClose={toggleEditModal} isCustom={props.isCustom} code={code} />
    </div>
  )
}

export { ReferralCode }
