import { SVG } from '@/assets/svg'

/** Icons, often used in App */
export const commonIcons = {
  transfer: <SVG.CryptoIcons.Transfer />,
  cashback: <SVG.CryptoIcons.TT />,
  deposit: <SVG.CryptoIcons.Deposit />,
  withdrawal: <SVG.CryptoIcons.Withdraw />,
  success: <SVG.OtherIcons.SuccessFill />,
  expired: <SVG.OtherIcons.ExpiredFill />,
  lightning: <SVG.OtherIcons.Lightning />,
  tiger: <SVG.OtherIcons.Tiger />,
  failed: <SVG.Status.Failed />,
}
