import { FC } from 'react'
import { Tab } from '@/ui/atoms/Tab'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

export type TabSetter = (tab: string) => undefined | void

export type TabBarPropsType = {
  className?: string
  tabs: Array<{
    id: string
    text: string
  }>
  activeTab?: string
  setActiveTab: TabSetter
}

const TabBar: FC<TabBarPropsType> = props => {
  const { className, tabs, activeTab, setActiveTab } = props

  return (
    <div className={style.tabs}>
      {tabs.map(({ text, id }) => (
        <Tab
          key={id}
          id={id}
          text={text}
          isActive={activeTab === id}
          setActiveTab={setActiveTab}
          className={className}
          dataTestId={`${DataTestIds.WalletTab}-${id}`}
        />
      ))}
    </div>
  )
}

export { TabBar }
