import { exchangeApi } from '@/backend/api/exchange'
import { BINANCE_AUTH_HEADER_NAME, BINANCE_PARAMS_FOR_SIGNATURE } from '@/core/constants'
import { InternalAxiosRequestConfig } from 'axios'

export const signWithReadOnlyInterceptor = async (config: InternalAxiosRequestConfig) => {
  try {
    const { url } = config

    const query = new URLSearchParams(config.params)

    const res = await exchangeApi.postSignWithReadOnly({
      queryString: query.toString() || '',
      requestBody: config.data?.toString(),
      requestMethod: config.method || 'GET',
      requestPath: url || '',
    })

    config.headers = config.headers ?? {}

    Object.entries(res.data.headers).forEach(([key, value]) => {
      config.headers[key] = value
    })

    config.params = config.params ?? {}

    if (config.headers[BINANCE_AUTH_HEADER_NAME] !== undefined) {
      // special rules for binance
      BINANCE_PARAMS_FOR_SIGNATURE.forEach(key => {
        config.params[key] = res.data.parameters[key]
      })
    }

    Object.entries(res.data.parameters).forEach(([key, value]) => {
      config.params[key] = value
    })
  } catch (e) {
    const controller = new AbortController()
    controller.abort()

    return {
      ...config,
      signal: controller.signal,
    }
  }

  return config
}
