import { FC } from 'react'
type SortArrowUpProps = {
  className?: string
}

export const SortArrowUp: FC<SortArrowUpProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={6} height={6} fill="none" className={className}>
    <path
      fill="#FAFAFA"
      d="M3.374 1.129a.607.607 0 0 0-.748 0L.199 3.072c-.4.32-.155.928.374.928h4.854c.53 0 .775-.608.374-.928L3.374 1.13Z"
    />
  </svg>
)
