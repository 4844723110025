import clsx from 'clsx'
import { FC, memo } from 'react'
import { AccountOverviewAssets } from '@/backend/models/OverviewDTO'
import style from './style.module.scss'
import { IconFactory } from '@/assets/svg/cryptoIcons/IconFactory'
import { useAppSelector } from '@/utils/hooks/useAppSelector'
import { OVERVIEW_ACCOUNT_SYMBOLS_MAP } from '@/redux/overview/overview.defaults'
import { OVERVIEW_MAX_SMALL_ASSETS_IN_USDT } from '@/core/constants/overview'

type CryptocurrencyType = {
  cryptocurrency: AccountOverviewAssets[]
  isBigSize?: boolean
}

const Cryptocurrency: FC<CryptocurrencyType> = ({ cryptocurrency, isBigSize }) => {
  const { isHiddenSmallAssets, accountType } = useAppSelector(state => state.overview)

  return (
    <div className={clsx(style.cryptocurrency, isBigSize && style.cryptocurrencyBig)}>
      {cryptocurrency
        ?.map(item => {
          if (isHiddenSmallAssets) {
            const currency = accountType ? OVERVIEW_ACCOUNT_SYMBOLS_MAP[accountType].selectorOutput : null

            if (currency && item.quoteBalance[currency] < OVERVIEW_MAX_SMALL_ASSETS_IN_USDT) {
              return null
            }
          }

          return (
            <div key={item?.asset} className={style.icon}>
              <IconFactory type={item.asset} />
            </div>
          )
        })
        .splice(0, 3)}
      {cryptocurrency?.length > 3 && <div className={clsx(style.coinsCount)}>+{cryptocurrency.length - 3}</div>}
    </div>
  )
}

export default memo(Cryptocurrency)
