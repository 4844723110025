import { TExchangesAvailable } from '@/core/constants'
import { tableFilterExchangeIds } from '../components/TableFilters/utils'
import { E_TableFilterExchangeType } from '@/redux/trades/trades.types'

export const getIdsAndNameForExchange = (exchangeType: TExchangesAvailable, availableKey: string[] | undefined) => {
  const extractedExchangeName = exchangeType.split('_')[0].toLowerCase()
  const formattedExchangeName =
    exchangeType === 'BINANCE_VIP' && !availableKey?.includes(exchangeType)
      ? E_TableFilterExchangeType.Binance
      : exchangeType === 'BINANCE_VIP'
      ? E_TableFilterExchangeType.BinanceVip
      : extractedExchangeName.charAt(0).toUpperCase() + extractedExchangeName.slice(1)
  const formattedName = formattedExchangeName === 'Okx' ? 'OKX' : formattedExchangeName
  const exchangeEntry = tableFilterExchangeIds.find(entry => entry.name.toLowerCase() === extractedExchangeName)
  if (exchangeEntry) {
    return { name: formattedName, ids: exchangeEntry.ids }
  } else {
    return { name: formattedName, ids: [] }
  }
}
