import { ComponentType, FC, ReactNode, useState } from 'react'
import { Control, useController } from 'react-hook-form'
import clsx from 'clsx'

import styles from './style.module.scss'

export type TChangePayload = {
  value: any
  checked: boolean
}

export type TArrayedOption<T = any> = {
  value: T
  description: ReactNode
}

export const ArrayedInputsGroup: FC<{
  component: ComponentType<any>
  componentProps?: Record<string, any>
  options: Array<TArrayedOption>
  control: Control<any>
  name: string
  className?: string
  wrapClassname?: string
  errorMessage?: ReactNode
}> = props => {
  const { component: Component, options, control, name, componentProps, wrapClassname, errorMessage, className } = props
  const { field } = useController({
    control,
    name,
  })
  const [value, setValue] = useState<Array<string | number>>(field.value || [])
  return (
    <div className={clsx(styles.root, className)}>
      {options.map((option, index) => {
        return (
          <div className={wrapClassname} key={option.value}>
            <Component
              {...componentProps}
              checked={value.includes(option.value)}
              value={option.value}
              onChange={(e: TChangePayload) => {
                const valueObject = value.reduce((acc, val) => {
                  return {
                    ...acc,
                    [val]: val,
                  }
                }, {} as Record<string, string | number>)

                if (!e.checked) delete valueObject[e.value]
                else valueObject[e.value] = e.value

                const arrayValues = Object.values(valueObject)

                // send data to react hook form
                field.onChange(arrayValues)

                // update local state
                setValue(arrayValues)
              }}
            >
              {option.description}
            </Component>
          </div>
        )
      })}
      <div className={styles.error}>{errorMessage}</div>
    </div>
  )
}
