import { microtask } from './microtask'

export function setStyle(element: HTMLElement, style: keyof CSSStyleDeclaration, value: string): () => void {
  let isRemoved = false
  let prevStyle = element.style[style]

  /** Needed to divide style reading and assignment into 2 stages (performance) */
  microtask(() => {
    if (isRemoved) {
      return
    }

    element.style[style as any] = value
  })

  return () => {
    isRemoved = true
    element.style[style as any] = prevStyle as any
  }
}
