import { E_TableFilterExchangeType, E_TableFilterMarketValue } from '@/redux/trades/trades.types'
import { ServicesApiKey, ServicesExchangeID, ServicesUserWithRelations } from '@/utils/types/contracts.types'

export const tableFilterExchangeIds = [
  {
    name: E_TableFilterExchangeType.Binance,
    ids: [ServicesExchangeID.EXCHANGE_BINANCE_FUTURES, ServicesExchangeID.EXCHANGE_BINANCE_SPOT],
  },
  {
    name: E_TableFilterExchangeType.BinanceVip,
    ids: [ServicesExchangeID.EXCHANGE_BINANCE_FUTURES, ServicesExchangeID.EXCHANGE_BINANCE_SPOT],
  },
  {
    name: E_TableFilterExchangeType.Bybit,
    ids: [ServicesExchangeID.EXCHANGE_BYBIT_LINEAR, ServicesExchangeID.EXCHANGE_BYBIT_SPOT],
  },
  {
    name: E_TableFilterExchangeType.OKX,
    ids: [ServicesExchangeID.EXCHANGE_OKX_PERP_SWAPS, ServicesExchangeID.EXCHANGE_OKX_SPOT],
  },
]

export const tableFilterMarketTypeIds = [
  {
    name: E_TableFilterMarketValue.Spot,
    ids: [
      ServicesExchangeID.EXCHANGE_BINANCE_SPOT,
      ServicesExchangeID.EXCHANGE_BYBIT_SPOT,
      ServicesExchangeID.EXCHANGE_OKX_SPOT,
    ],
  },
  {
    name: E_TableFilterMarketValue.Futures,
    ids: [
      ServicesExchangeID.EXCHANGE_BINANCE_FUTURES,
      ServicesExchangeID.EXCHANGE_BYBIT_LINEAR,
      ServicesExchangeID.EXCHANGE_OKX_PERP_SWAPS,
    ],
  },
]

export const getFilteredIdsByType = (type: E_TableFilterExchangeType | E_TableFilterMarketValue) => {
  if (type in E_TableFilterExchangeType) {
    const exchange = tableFilterExchangeIds.find(ex => ex.name === type)
    if (exchange) {
      return exchange.ids
    }
  } else if (type in E_TableFilterMarketValue) {
    const marketType = tableFilterMarketTypeIds.find(mt => mt.name === type)
    if (marketType) {
      return marketType.ids
    }
  }
  return []
}

export const getOwnApiKeyIdsWithType = (
  type: E_TableFilterExchangeType | E_TableFilterMarketValue,
  apiKeyList: ServicesApiKey[] | undefined
) => {
  const typeIds = getFilteredIdsByType(type)
  const apiKeyIds = apiKeyList?.map(el => el.exchange_id) as number[]
  const filteredIds = apiKeyIds?.filter(id => typeIds.includes(id))
  return filteredIds
}

export const binanceTableFilterExchangeIds = (
  me: ServicesUserWithRelations | null,
  type: E_TableFilterExchangeType
): number[] => {
  const binanceExchangeId =
    type === E_TableFilterExchangeType.BinanceVip
      ? ['BINANCE_VIP_FUTURE', 'BINANCE_VIP_SPOT']
      : ['BINANCE_SPOT', 'BINANCE_FUTURE']

  return (
    me?.api_keys_list
      ?.filter(el => typeof el?.name === 'string' && binanceExchangeId.includes(el.name))
      .map(el => el?.id)
      .filter((id): id is number => typeof id === 'number') || []
  )
}
