import { RefObject, useEffect } from 'react'

export const useIntersectionObserver = (
  observerTarget: RefObject<HTMLElement>,
  callback: IntersectionObserverCallback,
  options: IntersectionObserverInit
) => {
  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current)
      }
    }
  }, [observerTarget, callback, options])

  return undefined
}
