import React from 'react'

export const Okey = () => {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.79347 1.30501C7.35273 0.872288 6.6466 0.872288 6.20585 1.30501L5.81638 1.68739C5.5465 1.95236 5.1622 2.0652 4.7919 1.9882L4.25753 1.87709C3.6528 1.75135 3.05877 2.13311 2.92194 2.73542L2.80102 3.26766C2.71724 3.63648 2.45495 3.93917 2.10181 4.0746L1.5922 4.27003C1.01548 4.49119 0.72215 5.1335 0.932674 5.71418L1.1187 6.2273C1.24761 6.58287 1.19061 6.97931 0.96675 7.28416L0.643693 7.72409C0.2781 8.22193 0.378592 8.92087 0.869635 9.29555L1.30355 9.62664C1.60423 9.85607 1.77061 10.2204 1.7471 10.5979L1.71317 11.1426C1.67477 11.7591 2.13718 12.2927 2.75284 12.3425L3.29687 12.3864C3.67386 12.4169 4.0108 12.6334 4.1951 12.9637L4.46107 13.4403C4.76205 13.9796 5.43957 14.1786 5.98438 13.8876L6.4658 13.6304C6.7994 13.4522 7.19993 13.4522 7.53353 13.6304L8.01495 13.8876C8.55976 14.1786 9.23728 13.9796 9.53826 13.4403L9.80423 12.9637C9.98853 12.6334 10.3255 12.4169 10.7025 12.3864L11.2465 12.3425C11.8622 12.2927 12.3246 11.7591 12.2862 11.1426L12.2522 10.5979C12.2287 10.2204 12.3951 9.85607 12.6958 9.62664L13.1297 9.29555C13.6207 8.92087 13.7212 8.22193 13.3556 7.72409L13.0326 7.28416C12.8087 6.97931 12.7517 6.58287 12.8806 6.2273L13.0667 5.71418C13.2772 5.1335 12.9838 4.49119 12.4071 4.27003L11.8975 4.0746C11.5444 3.93917 11.2821 3.63648 11.1983 3.26766L11.0774 2.73542C10.9406 2.13311 10.3465 1.75135 9.7418 1.87709L9.20743 1.9882C8.83713 2.0652 8.45283 1.95236 8.18295 1.68739L7.79347 1.30501ZM9.79987 5.52341C9.47232 5.21405 8.956 5.2288 8.64665 5.55636L6.30164 8.03931L5.64207 7.24376C5.35451 6.89692 4.84023 6.84886 4.49338 7.13642C4.14654 7.42398 4.09848 7.93826 4.38604 8.28511L5.63387 9.79019C5.78298 9.97004 6.00203 10.0773 6.23553 10.0849C6.46903 10.0924 6.69456 9.9995 6.85497 9.82965L9.83282 6.67663C10.1422 6.34907 10.1274 5.83276 9.79987 5.52341Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
