import { Actions } from './actions'
import { Additional } from './additional'
import { Arrows } from './arrows'
import { CryptoIcons } from './cryptoIcons'
import { Loaders } from './loaders'
import { OtherIcons } from './otherIcons'
import { Status } from './status'
import { Api } from './api'
import { Profile } from './profile'
import { Settings } from './settings'
import { Social } from './social'
import { Partner } from './partner'
import { Achievements } from './achievements'
import { Team } from './team'
import { Tmm } from './tmm'
import { Kyc } from './kyc'
import { Dashboard } from './dashboard'
import { Chart } from './chart'
import { LeaderBoard } from './leaderBoard'

export const SVG = {
  OtherIcons,
  CryptoIcons,
  Arrows,
  Actions,
  Additional,
  Status,
  Loaders,
  LeaderBoard,
  Api,
  Profile,
  Settings,
  Social,
  Partner,
  Achievements,
  Team,
  Tmm,
  Chart,
  Kyc,
  Dashboard,
}
