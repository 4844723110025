import { TEAM_PAGINATION_SIZE } from '@/core/config/team'
import { InitialState } from './team.types'
import { DEFAULT_ERROR_OBJECT } from '@/core/constants/common'
import { ECashbackCalculationRule } from '@/backend/api/cashbackAPI'

export const initialState: InitialState = {
  teamRequested: undefined,
  teamRequestedErrors: DEFAULT_ERROR_OBJECT,
  teamRequestedRequestStatus: 'idle',
  teamInfoRequestInitialStatus: 'idle',
  userMode: undefined,
  teamUserInfo: undefined,
  teamManagerInfo: undefined,
  teamInfoRequestStatus: 'idle',
  teamJoinRequestStatus: 'idle',
  changeCashbackRequestStatus: 'idle',
  teamJoinErrors: DEFAULT_ERROR_OBJECT,
  teamCancelJoinRequestStatus: 'idle',
  teamLeaveRequestStatus: 'idle',
  changeManagerDefaultCashbackStatus: 'idle',
  riskManagerSettings: undefined,
  rmGetRequestStatus: 'idle',
  rmSetRequestStatus: 'idle',
  rmUpdateRequestStatus: 'idle',
  rmDeleteRequestStatus: 'idle',
  tradingStatus: undefined,
  tradingStatusRequestStatus: 'idle',
  deleteMemberStatus: 'idle',
  teamManagerOwnCashback: {
    binanceCalculationRule: ECashbackCalculationRule.allocationBalance,
    binanceCashbackPercent: 0,
    bybitCalculationRule: ECashbackCalculationRule.allocationBalance,
    bybitCashbackPercent: 0,
    okxCalculationRule: ECashbackCalculationRule.allocationBalance,
    okxCashbackPercent: 0,
  },
  teamManagerOwnCashbackStatus: 'idle',
  usersList: {
    users: [],
    page: 0,
    size: TEAM_PAGINATION_SIZE,
    total: 0,
  },
  managerMembersList: {
    members: [],
    page: 0,
    size: TEAM_PAGINATION_SIZE,
    total: 0,
    managerDataReachedEnd: false,
  },
  managerMembersListSearch: '',
  managerMembersListRequestStatus: 'idle',
  managerRequestsList: {
    requests: [],
    page: 0,
    size: TEAM_PAGINATION_SIZE,
    total: 0,
    managerDataReachedEnd: false,
  },
  managerRequestsListRequestStatus: 'idle',
  managerRequestStatus: 'idle',

  usersListRequestStatus: 'idle',
  userRequestsList: {
    requests: [],
    page: 0,
    size: TEAM_PAGINATION_SIZE,
    total: 0,
  },
  userRequestsListRequestStatus: 'idle',
  errors: [],
}
