import React from 'react'

interface SingleAssetProps {
  className?: string
}

const SingleAsset: React.FC<SingleAssetProps> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_12884_10851)" />
      <path
        d="M28 18.8332C28 20.3059 24.4183 21.4998 20 21.4998C15.5817 21.4998 12 20.3059 12 18.8332C12 17.3604 15.5817 16.1665 20 16.1665C24.4183 16.1665 28 17.3604 28 18.8332Z"
        fill="white"
      />
      <path
        d="M20 22.8332C24.6667 22.8332 28 21.4998 28 20.1665L28 22.1665C28 23.4998 24.6667 24.8332 20 24.8332C15.3333 24.8332 12 23.4998 12 22.1665V20.1665C12 21.4998 15.3333 22.8332 20 22.8332Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_12884_10851" x1="40" y1="40.5" x2="0" y2="0.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9027" />
          <stop offset="1" stopColor="#FFD35F" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { SingleAsset }
