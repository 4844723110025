import { FC, ReactElement } from 'react'
import { SVG } from '@/assets/svg'
import clsx from 'clsx'

import styles from './style.module.scss'

export interface IListItem {
  id: string
  text: ReactElement | string
  done?: boolean
}

interface IListProps {
  marker?: ReactElement
  list: IListItem[]
  className?: string
}

const List: FC<IListProps> = props => {
  const { marker, list, className } = props
  return (
    <div className={clsx(styles.root, className)}>
      {list.map(listItem => {
        return (
          <div
            className={clsx(styles.item, {
              [styles.active]: listItem.done,
            })}
            key={listItem.id}
          >
            {marker && <div className={styles.marker}>{marker}</div>}
            {listItem.done !== undefined && (
              <div className={styles.checklist}>{listItem.done && <SVG.OtherIcons.ListCheckbox />}</div>
            )}
            <div>{listItem.text}</div>
          </div>
        )
      })}
    </div>
  )
}

export { List }
