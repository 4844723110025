import { Key } from './Key'
import { Lock } from './Lock'
import { Scan } from './Scan'
import { User } from './User'

export const Api = {
  Key,
  Lock,
  Scan,
  User,
}
