import { FC, useMemo } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { Button, Input, InputSize, InteractiveModalParts } from '@/ui/kit'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { GoogleCodeVerification } from '@/ui/organisms/GoogleCodeVerification'
import { useActions, useAppSelector, useGoogleAuthCode, useGoogleAuthSettings } from '@/utils/hooks'
import { okxAffiliateActions } from '@/redux/okxAffiliate/okxAffiliate.slice'
import { ResultForm } from '../../../ResultForm'
import * as yup from 'yup'
import { PageSetupGoogleAuth } from '@/ui/organisms/WalletSetupGoogleAuth'
import { errorTranslations } from '@/core/constants/errors/errorsTranslations'

type Props = {
  onClose: () => void
}

type OnsubmitData = {
  apiKey: string
  secretKey: string
  passhprase: string
}

type AddKeyForm = OnsubmitData & {
  globalError: {
    type: string
    message: string
  }
}

const validationSchema = yup.object().shape({
  apiKey: yup.string().required('API key is required'),
  secretKey: yup.string().required('Secret key is required'),
  passhprase: yup.string().required('Pass phrase is required'),
})

export const FormAddKey: FC<Props> = props => {
  const { google } = useAppSelector(state => state.profile)
  const { errors, responseDetails } = useAppSelector(state => state.okxAffiliate)
  const { PostAddKey, clearErrors, clearResponseDetails, GetUserStatus } = useActions(okxAffiliateActions)
  const googleAuthCode = useGoogleAuthCode()
  const errorsTrans = errorTranslations()

  const googleAuthSettings = useGoogleAuthSettings()

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<AddKeyForm>({
    defaultValues: {
      apiKey: '',
      secretKey: '',
      passhprase: '',
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const errorsMapped = useMemo<Record<string, string>>(() => {
    return errors.reduce((acc: any, error: any) => {
      return {
        ...acc,
        [error.field]: error.message,
      }
    }, {})
  }, [errors])

  function closePopup() {
    clearResponseDetails()
    clearErrors()
    props.onClose()
  }

  function backToForm() {
    googleAuthCode.setCode([])
    clearResponseDetails()
    clearErrors()
  }

  function successClosePopup() {
    GetUserStatus()
    closePopup()
  }

  function onSubmit(data: OnsubmitData) {
    const { apiKey, secretKey, passhprase } = data
    const googleCode = googleAuthCode.code.join('')

    PostAddKey({
      apiKey,
      secretKey,
      passhprase,
      googleCode,
    })
  }

  function errorFormContentByCode(code: number | null | undefined) {
    switch (code) {
      case 40003:
        return (
          <ResultForm
            title={t({
              id: 'okxAffiliate.addKeyPopup.errorTitle',
              comment: 'Key binding error',
            })}
            description={t({
              id: 'okxAffiliate.addKeyPopup.error.40003',
              comment: 'Passed API key belongs to an already registered exchange account.',
            })}
            onClose={closePopup}
            onClickBackButton={backToForm}
            titleBackButton={t({ id: 'okxAffiliate.resultForm.backToForm', comment: 'Back to adding keys' })}
            isSuccess={false}
            isShowBackButton
          />
        )
      case 40004:
        return (
          <ResultForm
            title={t({
              id: 'okxAffiliate.addKeyPopup.errorTitle',
              comment: 'Key binding error',
            })}
            description={t({
              id: 'okxAffiliate.addKeyPopup.error.40004',
              comment: 'Passed API key does not belong to a suitable invitee affiliate account.',
            })}
            onClose={closePopup}
            onClickBackButton={backToForm}
            titleBackButton={t({ id: 'okxAffiliate.resultForm.backToForm', comment: 'Back to adding keys' })}
            isSuccess={false}
            isShowBackButton
          />
        )
      default:
        return (
          <ResultForm
            title={t({
              id: 'okxAffiliate.addKeyPopup.errorTitle',
              comment: 'Key binding error',
            })}
            description={t({
              id: 'okxAffiliate.addKeyPopup.error.unknown',
              comment: 'A key binding error has occurred.',
            })}
            onClose={closePopup}
            onClickBackButton={backToForm}
            titleBackButton={t({ id: 'okxAffiliate.resultForm.backToForm', comment: 'Back to adding keys' })}
            isSuccess={false}
            isShowBackButton
          />
        )
    }
  }

  const isFormValid = isValid && googleAuthCode.isCorrectCodeLength

  if (responseDetails.isCompleted) {
    return (
      <>
        {responseDetails.isSuccess ? (
          <ResultForm
            title={t({
              id: 'okxAffiliate.addKeyPopup.successTitle',
              comment: 'Key binding error',
            })}
            description={t({
              id: 'okxAffiliate.addKeyPopup.successDescription',
              comment: 'You have successfully linked the key and will receive 20% cashback',
            })}
            onClose={successClosePopup}
            isSuccess
          />
        ) : (
          errorFormContentByCode(responseDetails?.errorCode)
        )}
      </>
    )
  }

  return (
    <>
      <form className={style.wrapper}>
        <TitleH3
          className={style.title}
          label={t({
            id: 'okxAffiliate.addKeyPopup.title',
            comment: 'Adding API Keys',
          })}
        />
        <div className={style.description}>
          <span>
            {t({
              id: 'okxAffiliate.addKeyPopup.description',
              comment: 'For security reasons, do not share your APIs with anyone.',
            })}
          </span>
        </div>
        <Controller
          control={control}
          name="apiKey"
          render={({ field }) => (
            <Input
              size={InputSize.Small}
              value={field.value}
              placeholder={t({
                id: 'okxAffiliate.addKeyPopup.apiKey.placeholder',
                comment: 'Enter Key here',
              })}
              setValue={field.onChange}
              inputAttrs={{ type: 'string', maxLength: 120 }}
              label={t({
                id: 'okxAffiliate.addKeyPopup.apiKey.label',
                comment: 'API key',
              })}
              errorMessage={errorsMapped.apiKey ? errorsTrans.invalidField : undefined}
            />
          )}
        />
        <Controller
          control={control}
          name="secretKey"
          render={({ field }) => (
            <Input
              size={InputSize.Small}
              value={field.value}
              placeholder={t({
                id: 'okxAffiliate.addKeyPopup.secretKey.placeholder',
                comment: 'Enter Secret Key here',
              })}
              setValue={field.onChange}
              inputAttrs={{ type: 'string', maxLength: 120 }}
              label={t({
                id: 'okxAffiliate.addKeyPopup.secretKey.label',
                comment: 'Secret Key',
              })}
              errorMessage={errorsMapped.secretKey ? errorsTrans.invalidField : undefined}
            />
          )}
        />
        <Controller
          control={control}
          name="passhprase"
          render={({ field }) => (
            <Input
              size={InputSize.Small}
              value={field.value}
              placeholder={t({
                id: 'okxAffiliate.addKeyPopup.passphrase.placeholder',
                comment: 'Enter Passphrase here',
              })}
              setValue={field.onChange}
              inputAttrs={{ type: 'string', maxLength: 120 }}
              label={t({
                id: 'okxAffiliate.addKeyPopup.passphrase.label',
                comment: 'Passphrase',
              })}
              errorMessage={errorsMapped.passhprase ? errorsTrans.invalidField : undefined}
            />
          )}
        />
        {!google.enabled ? (
          <PageSetupGoogleAuth
            googleAuthSettings={googleAuthSettings}
            googleAuthCode={googleAuthCode}
            className={style.wrapperGoogle}
            isDisabledNavigate
          />
        ) : (
          <GoogleCodeVerification
            header={t({
              comment: 'Enter Google Authenticator code',
              id: 'withdrawConfirmation.confirmGoogleAuth.enterCode',
            })}
            text={`${t({
              comment: 'If you’re having issues, please contact support:',
              id: 'withdrawConfirmation.confirmGoogleAuth.contactSupport',
            })} ${t({
              id: 'core.supportEmail',
              comment: 'broker.support@tiger.trade',
            })}`}
            code={googleAuthCode.code}
            setCode={googleAuthCode.setCode}
            errorMessage={errorsMapped.googleCode ? errorsTrans.googleCode : undefined}
          />
        )}
        <div className={style.btnWrapper}>
          {google.enabled && (
            <InteractiveModalParts.Button
              text={t({ id: 'teamMembers.submit', comment: 'Save' })}
              variant={Button.Variant.White}
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isFormValid || false}
            />
          )}
          <InteractiveModalParts.Button
            text={t({ id: 'core.close', comment: 'Close' })}
            variant={Button.Variant.Clean}
            onClick={closePopup}
          />
        </div>
      </form>
    </>
  )
}
