export function isScrollable(node: Element, checkForOverflow?: boolean): boolean {
  const style = window.getComputedStyle(node)

  const isScrollable = /(auto|scroll)/i.test(style.overflow + style.overflowX + style.overflowY)

  if (isScrollable && checkForOverflow) {
    return node.scrollHeight !== node.clientHeight || node.scrollWidth !== node.clientWidth
  }

  return isScrollable
}
