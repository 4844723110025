import { urls } from '@/router/urls'
import { ThunkErrorValue } from '@/types'
import BigNumber from 'bignumber.js'

export const UID_REGEX = new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)
export const YOUTUBE_EMBED_URL = 'https://youtube.com/embed/'
export const BN_ZERO = new BigNumber(0)

export const DEFAULT_ERROR_OBJECT: ThunkErrorValue = {
  codes: [],
  errors: [],
  fieldsErrors: [],
}

export const HTTP_STATUS_RANGE = 100 // 200-299, 300-399, etc

export const SYSTEM_ALERT_API_FILTER = [urls.api]
