import { t } from '@lingui/macro'
import style from './style.module.scss'
import { useActions, useAppSelector } from '@/utils'
import { EmptyList } from '@/ui/molecules'
import { Loader } from '@/ui/kit/Loader'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { AddressBar } from '@/ui/molecules/AddressBar'
import { useEffect, useMemo } from 'react'
import { addressActions } from '@/redux/address/address.slice'
import { seedAddressesWithNetwork } from '@/utils/lib/seedAddressesWithNetwork'

const getHeadersTranslations = () => [
  t({
    id: 'addressesPage.header.coins',
    message: 'Coins',
  }),
  t({
    id: 'addressesPage.header.network',
    message: 'Network',
  }),
  t({
    id: 'addressesPage.header.note',
    message: 'Note',
  }),
  t({
    id: 'addressesPage.header.wallet',
    message: 'Wallet Address',
  }),
  t({
    id: 'addressesPage.header.edit',
    message: 'Edit / Delete',
  }),
]

const AddressList = () => {
  const { getAddressesTC, getAssetsForAddressTC } = useActions(addressActions)
  const { addresses, status, addressRemoveStatus, assetsForAddresses } = useAppSelector(state => state.address)

  const isError = status === 'failed'
  const isLoading = status === 'loading'

  const emptyListLabel = t({
    message: 'Your withdrawal address will appear here',
    id: 'addressesPage.notFound.subtitle',
  })

  useEffect(() => {}, [getAssetsForAddressTC])

  useEffect(() => {
    if (assetsForAddresses.assetsStatus === 'idle') getAssetsForAddressTC()
    if (status === 'idle') getAddressesTC()
  }, [getAddressesTC, getAssetsForAddressTC, assetsForAddresses, status])

  useEffect(() => {
    if (addressRemoveStatus === 'succeeded') {
      getAddressesTC()
    }
  }, [addressRemoveStatus, getAddressesTC])

  const HEADERS = useMemo(() => {
    return getHeadersTranslations()
  }, [])

  const addressesForView = useMemo(() => {
    return seedAddressesWithNetwork(addresses, Object.values(assetsForAddresses.assets).flat())
  }, [addresses, assetsForAddresses.assets])

  return (
    <div className={style.root}>
      <div className={style.header}>
        {HEADERS.map(header => (
          <div key={header}>{header}</div>
        ))}
      </div>
      {addressesForView?.map((addressItem, index) => (
        <AddressBar {...addressItem} key={index} />
      ))}
      {!addresses.length && <EmptyList label={emptyListLabel} />}
      {isLoading && <Loader className={style.loader} />}
      {isError && (
        <ErrorBase
          customHeader={t({
            id: 'addressesPage.error.unload',
            message: `Couldn't load addresses`,
          })}
          className={style.error}
          isRefreshButtonVisible
        />
      )}
    </div>
  )
}

export { AddressList }
