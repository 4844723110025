import { useEffect, useState, useCallback } from 'react'
import { useActions, useAppSelector } from '@/utils'
import { transactionsActions } from '@/redux/transactions/transactions.slice'
import { StatusKey, statuses, tableHeadersConvert } from './consts'
import React from 'react'
import { getConvertHistoryKeysTranslations } from '@/ui/organisms/HistoryTrade/HistoryTrade.translations/binanceHistoryDictionary'
import { t } from '@lingui/macro'
import { TradeFlowItemType } from '@/redux/transactions/transactions.types'

type HistoryOutput = {
  date: string
  pair: string
  from: string
  to: string
  price: string
  status: {
    icon: React.ReactNode
    name: string
    style: string
  }
}

export const useConvertHistory = () => {
  const { GetHistoryConvertInfoTC } = useActions(transactionsActions)
  const { historyConvert } = useAppSelector(state => state.transactions)
  const [historyOutput, setHistoryOutput] = useState<HistoryOutput[]>([])
  const [tableColumns, setTableColumns] = useState<any[]>([])

  const formatHistoryConvert = useCallback((data: any): HistoryOutput[] => {
    if (!data?.list || !Array.isArray(data.list)) {
      return []
    }

    // Переводы статусов
    const statusTranslations = {
      SUCCESS: t({ id: 'core.status.completed', message: 'Completed' }),
      ACCEPT_SUCCESS: t({ id: 'core.status.completed', message: 'Completed' }),
      FAIL: t({ id: 'core.status.failed', message: 'Failed' }),
      PROCESS: t({ id: 'core.status.pending', message: 'Pending' }),
      UNKNOWN: t({ id: 'core.status.unknown', message: 'Unknown' }),
    }

    return data.list.map((item: TradeFlowItemType) => {
      const rawStatus = item.orderStatus?.toUpperCase() || 'UNKNOWN'
      const orderStatus: StatusKey = (rawStatus in statuses ? rawStatus : 'UNKNOWN') as StatusKey
      const status = statuses[orderStatus]

      return {
        date: new Date(item.createTime).toLocaleString(),
        pair: `${item.fromAsset}/${item.toAsset}`,
        from: `${item.fromAmount} ${item.fromAsset}`,
        to: `${item.toAmount} ${item.toAsset}`,
        price: `${item.fromAmount} ${item.fromAsset} = ${item.toAmount} ${item.toAsset}`,
        status: {
          ...status,
          name: statusTranslations[orderStatus] || statusTranslations.UNKNOWN,
        },
      }
    })
  }, [])

  useEffect(() => {
    const { filters } = historyConvert

    const startTime = filters.startTime ? Number(filters.startTime) : null
    const endTime = filters.endTime ? Number(filters.endTime) : null

    // Выполняем запрос только если оба значения корректны
    if (startTime && endTime) {
      GetHistoryConvertInfoTC({
        limit: filters.limit,
        startTime,
        endTime,
      })
    }
  }, [GetHistoryConvertInfoTC, historyConvert.filters.endTime, historyConvert.filters.startTime])

  useEffect(() => {
    if (historyConvert) {
      const formattedData = formatHistoryConvert(historyConvert)
      setHistoryOutput(formattedData)

      const translations = getConvertHistoryKeysTranslations()
      const updatedTableColumns = tableHeadersConvert.map(column => ({
        ...column,
        header: translations[column.accessor], // Применяем переводы
      }))

      setTableColumns(updatedTableColumns)
    }
  }, [historyConvert, formatHistoryConvert])

  // Локальное обновление данных при изменении фильтра coin
  useEffect(() => {
    const { filters } = historyConvert
    const { coin } = filters

    if (coin) {
      const filteredData = formatHistoryConvert(historyConvert).filter(item => item.pair.includes(coin))
      setHistoryOutput(filteredData)
    } else {
      const formattedData = formatHistoryConvert(historyConvert)
      setHistoryOutput(formattedData)
    }
  }, [historyConvert, historyConvert.filters.coin])

  return { historyOutput, tableColumns }
}
