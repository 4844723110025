import clsx from 'clsx'
import { memo } from 'react'
import style from './style.module.scss'

type TitleH2Props = {
  /** text */
  label?: string

  /** additional className */
  className?: string
}

/** H2 header */
export const TitleH2 = memo<TitleH2Props>(({ label, className }) => {
  return <h2 className={clsx(style.title, className)}>{label}</h2>
})
