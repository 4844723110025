import { FC, memo } from 'react'
import { KYCVerificationInfo } from '../../molecules/KYCVerificationInfo'
import style from './style.module.scss'

export const KYCLeftBar: FC = memo(() => {
  return (
    <div className={style.leftBar}>
      <KYCVerificationInfo />
    </div>
  )
})
