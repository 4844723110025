import { createSlice } from '@reduxjs/toolkit'
import initialState from './notification.state'
import { notificationAsyncActions } from './notification.actions'

export const slice = createSlice({
  name: `[Tiger Trade Notification]`,
  initialState,
  reducers: {
    updateFilterOnlyRead(state, action) {
      state.notificationData.filters.unreadOnly = action.payload
      state.notificationData.filters.page = 0
    },
    updateFilterType(state, action) {
      state.notificationData.filters.type = action.payload
      state.notificationData.filters.page = 0
    },
    updateFilterPagination(state) {
      state.notificationData.filters.page += 1
    },
    resetFilterPagination(state) {
      state.notificationData.filters.page = 0
    },
  },
  extraReducers: builder => {
    // GetNotifySummary
    builder.addCase(notificationAsyncActions.GetNotifySummary.pending, (state, action) => {})
    builder.addCase(notificationAsyncActions.GetNotifySummary.rejected, (state, action) => {})
    builder.addCase(notificationAsyncActions.GetNotifySummary.fulfilled, (state, action) => {
      state.summary = action.payload
    })

    // GetUserNotifications
    builder.addCase(notificationAsyncActions.GetUserNotifications.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(notificationAsyncActions.GetUserNotifications.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(notificationAsyncActions.GetUserNotifications.fulfilled, (state, action) => {
      state.isLoading = false
      if (action.payload.page === 0) {
        state.notificationData.notifications = action.payload.notifications
      } else {
        state.notificationData.notifications = [
          ...state.notificationData.notifications,
          ...action.payload.notifications,
        ]
      }
      state.notificationData.filters.page = action.payload.page
      state.notificationData.filters.total = action.payload.total
    })

    // PostReadNotificationById
    builder.addCase(notificationAsyncActions.PostReadNotificationById.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(notificationAsyncActions.PostReadNotificationById.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(notificationAsyncActions.PostReadNotificationById.fulfilled, (state, action) => {
      state.isLoading = false

      const { id, status } = action.payload

      const updatedList = state.notificationData.notifications.map(notification => {
        if (notification.id === id) {
          return {
            ...notification,
            status,
          }
        }
        return notification
      })

      state.notificationData.notifications = updatedList
    })
  },
})

export const notificationActions = {
  ...slice.actions,
  ...notificationAsyncActions,
}

export const notificationReducer = slice.reducer
