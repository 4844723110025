import { TExchangesAvailable } from '@/core/constants'
import { t } from '@lingui/macro'

export const getMessageByExchange = (): Record<TExchangesAvailable, string> => {
  return {
    BINANCE_BROKER_FUTURE: t({
      id: 'apiPage.howItWorks.trade',
      message: 'Trade, save commission, and get rewards in your Spot wallet',
    }),
    BYBIT_BROKER: t({
      id: 'apiPage.howItWorks.bybit.trade',
      message: 'Trade, save commission, and get rewards in your Spot wallet',
    }),
    OKX_BROKER: t({
      id: 'apiPage.howItWorks.okx.trade',
      message: 'Trade, save commission, and get rewards in your Spot wallet',
    }),
    BINANCE_VIP: t({
      id: 'apiPage.howItWorks.trade',
      message: 'Trade, save commission, and get rewards in your Spot wallet',
    }),
    OKX_AFFILIATE: t({
      id: 'apiPage.howItWorks.okx.trade',
      message: 'Trade, save commission, and get rewards in your Spot wallet',
    }),
  }
}
