import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialState } from '@/redux/referrals/referrals.state'
import { referralsAsyncActions } from '@/redux/referrals/referrals.actions'
import { Status } from '@/backend/types'
import { referralEarningsMapper } from '@/core/mappers/referralEarnings/referralEarningsMapper'

export const slice = createSlice({
  name: `[Tiger Trade Account Referrals]`,
  initialState,
  reducers: {
    setEarningsStatus(state, action: PayloadAction<Status>) {
      state.earnings.earningsStatus = action.payload
    },
    resetEarnings(state) {
      state.earnings.earningsStatus = 'idle'
      state.earnings.referralEarnings = []
      state.earnings.earningsPage = 0
    },
    removeIsLocked(state) {
      state.isLocked = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(referralsAsyncActions.GetReferralOverviewTC.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.overview = action.payload
      })
      .addCase(referralsAsyncActions.GetReferralOverviewTC.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(referralsAsyncActions.GetReferralOverviewTC.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(referralsAsyncActions.ApplyReferralCodeTC.pending, (state, action) => {
        state.statusApply = 'loading'
      })
      .addCase(referralsAsyncActions.ApplyReferralCodeTC.fulfilled, (state, action) => {
        const { referrerCode } = action.meta?.arg || {}
        state.statusApply = 'succeeded'
        state.overview.referrerCode = referrerCode
      })
      .addCase(referralsAsyncActions.CreateReferralCodeTC.fulfilled, (state, action) => {
        const { customCodes } = state.overview
        state.overview.customCodes = [action.payload, ...customCodes]
      })
      .addCase(referralsAsyncActions.DeleteReferralCodeTC.fulfilled, (state, action) => {
        const { customCodes } = state.overview
        state.overview.customCodes = customCodes.filter(c => c.code !== action.meta.arg)
      })
      .addCase(referralsAsyncActions.GetReferralEarningsTC.pending, (state, action) => {
        state.earnings.earningsStatus = 'loading'
        state.earnings.earningUser = action.meta.arg.filterParams.userId
        state.earnings.earningNoSearchResult = false
      })
      .addCase(referralsAsyncActions.GetReferralEarningsTC.rejected, (state, action) => {
        state.earnings.earningsStatus = 'failed'
        state.earnings.referralEarnings = []
      })
      .addCase(referralsAsyncActions.GetReferralEarningsTC.fulfilled, (state, action) => {
        state.earnings.earningsStatus = 'succeeded'

        const page = action.meta.arg.page
        state.earnings.earningsPage = page

        if (page === 0) {
          state.earnings.referralEarnings = referralEarningsMapper(action.payload.data)
        } else {
          state.earnings.referralEarnings = referralEarningsMapper([
            ...state.earnings.referralEarnings,
            ...action.payload.data,
          ])
        }

        if (action.payload.data.length < action.meta.arg.size) {
          state.earnings.earningReachedEnd = true
        } else {
          state.earnings.earningReachedEnd = false
        }

        if (action.payload.data.length === 0 && action.meta.arg.filterParams.userId && page === 0) {
          state.earnings.earningNoSearchResult = true
        }
      })
      .addCase(referralsAsyncActions.GetReferralBenefitsTC.pending, (state, action) => {
        state.benefits.benefitsStatus = 'loading'
      })
      .addCase(referralsAsyncActions.GetReferralBenefitsTC.fulfilled, (state, action) => {
        state.benefits.benefits = action.payload
        state.benefits.benefitsStatus = 'succeeded'
      })

      .addCase(referralsAsyncActions.UpdateReferralCodeTC.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(referralsAsyncActions.UpdateReferralCodeTC.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(referralsAsyncActions.UpdateReferralCodeTC.fulfilled, (state, action) => {
        state.isLoading = false
      })

      // GetReferralCodeInfoTC
      .addCase(referralsAsyncActions.GetReferralCodeInfoTC.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(referralsAsyncActions.GetReferralCodeInfoTC.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(referralsAsyncActions.GetReferralCodeInfoTC.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLocked = action.payload.locked
      })
  },
})

export const referralsActions = {
  ...slice.actions,
  ...referralsAsyncActions,
}

export const referralsReducer = slice.reducer
