import React, { useState, ReactElement, ReactNode } from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'

type TabProps = {
  label: ReactNode
  content: React.ReactNode
  counter?: number
  icon?: JSX.Element
  name: string
}

type TabMenuProps = {
  children: ReactElement<TabProps>[]
  extraContent?: JSX.Element
  onChange?: (name: string) => void
}

export const Tab: React.FC<TabProps> = () => {
  return null
}

const TabMenu: React.FC<TabMenuProps> = ({ children, extraContent, onChange }) => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabClick = (index: number) => {
    setActiveTab(index)
    if (onChange) {
      onChange(children[index].props.name) // вызываем onChange с именем активного таба
    }
  }

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabHeaders}>
        {children.map((tab, index) => (
          <div
            key={index}
            className={clsx(styles.tabButton, {
              [styles.active]: activeTab === index,
            })}
            onClick={() => handleTabClick(index)}
          >
            {tab.props.icon && tab.props.icon}
            <div>{tab.props.label}</div>
            <div className={styles.counterWrap}>
              {tab.props.counter && tab.props.counter > 0 ? (
                <div className={styles.counter}>{tab.props.counter}</div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.tabContent}>
        {extraContent && extraContent}
        {children[activeTab].props.content}
      </div>
    </div>
  )
}

export default TabMenu
