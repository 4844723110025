import React from 'react'

interface IProps {
  className?: string
}

export const Completed = (props: IProps) => {
  const { className } = props

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z"
        fill="var(--background, #1ACC81)"
      />
      <path
        d="M4.38773 5.85177C4.14024 5.55478 3.69884 5.51465 3.40185 5.76215C3.10485 6.00964 3.06473 6.45104 3.31222 6.74803L4.38773 5.85177ZM6.18331 9.0999L5.64555 9.54803C5.77369 9.70179 5.96152 9.79335 6.16158 9.79956C6.36164 9.80578 6.55479 9.72605 6.69222 9.58054L6.18331 9.0999ZM10.6589 5.38054C10.9243 5.09948 10.9117 4.65644 10.6306 4.39099C10.3495 4.12554 9.90651 4.1382 9.64107 4.41927L10.6589 5.38054ZM3.31222 6.74803L5.64555 9.54803L6.72106 8.65177L4.38773 5.85177L3.31222 6.74803ZM6.69222 9.58054L10.6589 5.38054L9.64107 4.41927L5.6744 8.61927L6.69222 9.58054Z"
        fill="var(--foreground, #12101A)"
      />
    </svg>
  )
}
