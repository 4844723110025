import WalletLeftBar from '@/ui/organisms/WalletLeftBar'
import WalletRightBar from '@/ui/organisms/WalletRightBar'
import { useGoogleAuthCode, useGoogleAuthSettings } from '@/utils/hooks'
import { PopupSequence } from '@/ui/organisms/PopupSequence'
import style from './style.module.scss'

export const Wallet = () => {
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()

  return (
    <>
      <PopupSequence
        googleAuthSettings={googleAuthSettings}
        googleAuthCode={googleAuthCode}
        allowedPopups={['kyc_success', 'promomessage']}
      />

      <div className={style.wallet}>
        <div className={style.content}>
          <WalletLeftBar />
          <WalletRightBar />
        </div>
      </div>
    </>
  )
}
