import { FC } from 'react'
import styles from './style.module.scss'
import { Trans } from '@lingui/macro'
import { useAppSelector } from '@/utils'

export const CashbackCard: FC = () => {
  const { config, userStatus } = useAppSelector(state => state.okxAffiliate)
  const tigerPercent = userStatus?.cashbackPercent || 0
  const totalPercent = config.okxPercent + tigerPercent

  return (
    <div className={styles.container}>
      <div className={styles.backgroundText}>{totalPercent}%</div>
      <div className={styles.content}>
        <div className={styles.percent}>{totalPercent}%</div>
        <div className={styles.description}>
          <Trans
            id="okxAffiliate.cashbackCard.description"
            values={{ okxPercent: config.okxPercent, tigerPercent: tigerPercent }}
          />
        </div>
      </div>
    </div>
  )
}
