import { Links } from './Links'
import { useHashNavigation } from '@/utils/hooks/useHashNavigation'
import style from './style.module.scss'
import { useMobileSizeDetect } from '@/utils'
import { PolicyTermsWrapper } from './components/PolicyTermsWrapper'
import React, { Suspense } from 'react'
import { Loader } from '@/ui/kit/Loader'
import { useLoadCallback } from '@/utils/hooks/useLoadCallback'

const MobilePrivacyPolicy = React.lazy(() => import('./components/tabs/MobilePrivacyPolicy'))
const DesktopPrivacyPolicy = React.lazy(() => import('./components/pages/DesktopPrivacyPolicy'))

const links = [
  { id: 'heading', title: 'Privacy Policy' },
  { id: 'intro', title: 'Introduction' },
  { id: 'definitions', title: 'Definitions' },
  { id: 'principles', title: 'Principles, terms, and conditions' },
  { id: 'reasons', title: 'Legal reasons' },
  { id: 'scope', title: 'Scope and purposes' },
  { id: 'procedures', title: 'Procedures, terms, and conditions' },
  { id: 'terms', title: 'Term and termination' },
  { id: 'data', title: 'Personal data rights' },
]

export const PrivacyPolicy = () => {
  const { isContentLoaded, setIsContentLoaded } = useLoadCallback()
  const [isMobile] = useMobileSizeDetect()
  const { active } = useHashNavigation(links, isContentLoaded)

  if (isMobile) {
    return (
      <PolicyTermsWrapper>
        <Suspense fallback={<Loader />}>
          <MobilePrivacyPolicy />
        </Suspense>
      </PolicyTermsWrapper>
    )
  }

  return (
    <div className={style.root}>
      <div className={style.left}>
        <Links links={links} active={active} />
      </div>
      <Suspense fallback={<Loader />}>
        <DesktopPrivacyPolicy loadCallback={setIsContentLoaded} />
      </Suspense>
    </div>
  )
}
