import { DayInfo, DayDiary, WeekDayInfo } from '@/utils/types/contracts.types'
import { formatDate } from './formatWeek'

const generateDaysInRange = (from: string, to: string): Date[] => {
  const days: Date[] = []
  const fromDate = new Date(from)
  const toDate = new Date(to)

  for (let d = new Date(fromDate); d <= toDate; d.setDate(d.getDate() + 1)) {
    days.push(new Date(d))
  }

  return days
}

export const mapBackendDataToDays = (from: string, to: string, backendData: DayDiary[]): DayInfo[] => {
  const days = generateDaysInRange(from, to)

  const dayInfoList: DayInfo[] = days.map(day => ({
    date: formatDate(day),
    data: [],
  }))
  backendData.forEach(data => {
    dayInfoList.forEach(dayInfo => {
      if (dayInfo.date === data.dateValue) {
        dayInfo.data.push(data)
      }
    })
  })
  return dayInfoList
}

export const generateWeekDataWithDays = (from: string, to: string, backendData: DayDiary[]) => {
  const days = mapBackendDataToDays(from, to, backendData)

  const weeks: WeekDayInfo[] = []
  let currentWeek: WeekDayInfo = {
    month: new Date(from).getMonth() + 1,
    firstDayOfWeek: formatDate(new Date(from)),
    lastDayOfWeek: '',
    days: [],
  }

  days.forEach(day => {
    const dayDate = new Date(day.date)
    const dayOfWeek = dayDate.getDay()
    const isSunday = dayOfWeek === 0

    if (currentWeek.days.length === 0) {
      currentWeek.firstDayOfWeek = day.date
    }

    currentWeek.days.push(day)

    if (isSunday || day.date === formatDate(new Date(to))) {
      currentWeek.lastDayOfWeek = day.date
      weeks.push(currentWeek)

      currentWeek = {
        month: dayDate.getMonth() + 1,
        firstDayOfWeek: '',
        lastDayOfWeek: '',
        days: [],
      }
    }
  })
  return weeks[0]
}
