import { createAsyncThunk } from '@reduxjs/toolkit'
import { TUserPositionSideMode } from '@tigertrade/binance-ts'
import nameof from 'ts-nameof.macro'
import { ProfileApi } from '@/backend/api'
import { TSetPositionModePayloadDTO, TSetPositionModeResponseDTO } from '@/backend/models/OverviewDTO'
import {
  BNBBurnStatusResponseDTO,
  DustStatusResponseDTO,
  LocaleRequestDTO,
  LoginWithTwoFaRequestDTO,
  LoginWithTwoFaResponseDTO,
  MetadataRequestDTO,
  MultiAssetMarginResponseDTO,
  ProfileResponseDTO,
  UpdateBNBBurnRequestDTO,
  UpdateDustStatusRequestDTO,
  UpdateMultiAssetMarginRequestDTO,
  VerifyTwoFaRequestDTO,
  VerifyTwoFaResponseDTO,
} from '@/backend/models/ProfileDTO'
import { InternalApi } from '@/services/rest/tigerGateway'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'

export const GetProfileTC = createAsyncThunk<ProfileResponseDTO, void, ThunkError>(
  nameof(ProfileApi.getProfile),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.getProfile, payload }, thunkAPI)
  }
)

export const resetTwoFATC = createAsyncThunk<any, void, ThunkError>(
  nameof(ProfileApi.resetTwoFA),
  async (payload: void, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.resetTwoFA, payload }, thunkAPI)
  }
)

export const UpdateLocale = createAsyncThunk<void, LocaleRequestDTO, ThunkError>(
  nameof(ProfileApi.updateLocale),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.updateLocale, payload }, thunkAPI)
  }
)

export const UpdateMetadata = createAsyncThunk<void, MetadataRequestDTO, ThunkError>(
  nameof(ProfileApi.updateMetadata),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.updateMetadata, payload }, thunkAPI)
  }
)

export const GetBNBBurnStatusTC = createAsyncThunk<BNBBurnStatusResponseDTO, void, ThunkError>(
  nameof(ProfileApi.getBNBBurnStatus),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.getBNBBurnStatus, payload }, thunkAPI)
  }
)

export const UpdateBNBBurnStatusTC = createAsyncThunk<BNBBurnStatusResponseDTO, UpdateBNBBurnRequestDTO, ThunkError>(
  nameof(ProfileApi.updateBNBBurn),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.updateBNBBurn, payload }, thunkAPI)
  }
)

export const GetMultiAssetMarginTC = createAsyncThunk<MultiAssetMarginResponseDTO, void, ThunkError>(
  nameof(ProfileApi.getMultiAssetMargin),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.getMultiAssetMargin, payload }, thunkAPI)
  }
)

export const UpdateMultiAssetMarginTC = createAsyncThunk<
  MultiAssetMarginResponseDTO,
  UpdateMultiAssetMarginRequestDTO,
  ThunkError
>(nameof(ProfileApi.updateMultiAssetMargin), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: ProfileApi.updateMultiAssetMargin, payload }, thunkAPI)
})

export const GetBNBDustTC = createAsyncThunk<DustStatusResponseDTO, void, ThunkError>(
  nameof(ProfileApi.getDustStatus),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.getDustStatus, payload }, thunkAPI)
  }
)

export const UpdateBNBDustTC = createAsyncThunk<DustStatusResponseDTO, UpdateDustStatusRequestDTO, ThunkError>(
  nameof(ProfileApi.updateDust),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.updateDust, payload }, thunkAPI)
  }
)

export const GetBinancePositionModeTC = createAsyncThunk<
  [TUserPositionSideMode, TUserPositionSideMode],
  undefined[],
  ThunkError
>('GetBinancePositionModeTC', async (payload = [], thunkAPI) => {
  return await ThunkCreator(
    {
      apiMethod: [ProfileApi.getUsdmPositionMode, ProfileApi.getCoinmPositionMode],
      payload,
    },
    thunkAPI
  )
})

export const SetPositionModeTC = createAsyncThunk<TSetPositionModeResponseDTO, TSetPositionModePayloadDTO, ThunkError>(
  nameof(InternalApi.methods.overview.setPositionMode),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: InternalApi.methods.overview.setPositionMode, payload }, thunkAPI)
  }
)

export const VerifyTwoFA = createAsyncThunk<VerifyTwoFaResponseDTO, VerifyTwoFaRequestDTO, ThunkError>(
  nameof(ProfileApi.verifyTwoFa),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.verifyTwoFa, payload }, thunkAPI)
  }
)

export const LoginWith2fa = createAsyncThunk<LoginWithTwoFaResponseDTO, LoginWithTwoFaRequestDTO, ThunkError>(
  nameof(ProfileApi.loginWith2fa),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ProfileApi.loginWith2fa, payload }, thunkAPI)
  }
)
