import { FC, useState, MouseEvent } from 'react'
import { t } from '@lingui/macro'
import { Input, InputSize, Button } from '@/ui/kit'
import s from './style.module.scss'

interface IReferralHowFormProps {
  value: string
  onChange: (value: string) => void
}

const ReferralHowForm: FC<IReferralHowFormProps> = props => {
  const { value: defaultValue, onChange } = props
  const [value, setValue] = useState(defaultValue)

  const handleChangeValue = (v: string) => {
    const value = v.replace(/\D/, '').slice(0, 20)
    setValue(value)
  }

  const handleApply = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (Number(value) > 0) {
      onChange(value)
    }
  }

  return (
    <div className={s.root}>
      <Input
        label={t({ id: 'referralHowItWorks.right.formLabel', message: 'Commission paid by user, USDT' })}
        value={value}
        setValue={handleChangeValue}
        size={InputSize.Small}
        inputAttrs={{ autoFocus: true }}
        labelClassName={s.label}
        containerClassName={s.input}
      />
      <Button.AccentDark label={t({ id: 'core.button.calculate', message: 'Calculate' })} onClick={handleApply} />
    </div>
  )
}

export { ReferralHowForm }
