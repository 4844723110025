import { without } from 'lodash-es'
import { UseGoogleAuthSettingsResult, UseKYCResult } from '@/utils/hooks'
import { Status } from '@/backend/types'
import { CashbackType } from '@/backend/api'

export interface DefineFlowStepParams {
  kYC: UseKYCResult
  googleAuthSettings: UseGoogleAuthSettingsResult
  allowedPopups?: DefineFlowStepResult[]
  shownPopupList?: string[]
  userEmail?: string
  exchangeStatus: Status
  messagesShownCashbackIncreased?: boolean
  messagesShownReducedCommission?: boolean
  cashback?: CashbackType
}

export type DefineFlowStepResult =
  | null
  | 'loading'
  | 'kyc_unverified'
  | 'kyc_in_progress_1'
  | 'kyc_in_progress_2'
  | 'kyc_failed'
  | 'kyc_success'
  | 'twoFA'
  | 'exchange_init'
  | 'promomessage'

export const defineFlowStep = (params: DefineFlowStepParams): DefineFlowStepResult => {
  const { kYC, googleAuthSettings, allowedPopups, shownPopupList = [], exchangeStatus } = params

  /**
   * Убираем со списка разрешенных попаплов те, которые уже были показаны и не требуют повторного показа
   */
  const adjustedAllowedPopups = without(allowedPopups, ...(shownPopupList as DefineFlowStepResult[]))

  const isLoading = kYC.isLoading || googleAuthSettings.isLoading || googleAuthSettings.isLoading === null

  if (isLoading) return 'loading'

  if (!kYC.isVerificationStarted && adjustedAllowedPopups?.includes('kyc_unverified')) return 'kyc_unverified'

  if (kYC.isVerificationInProgressAfterStep1 && adjustedAllowedPopups?.includes('kyc_in_progress_1'))
    return 'kyc_in_progress_1'

  if (kYC.isVerificationInProgressAfterStep2 && adjustedAllowedPopups?.includes('kyc_in_progress_2'))
    return 'kyc_in_progress_2'

  if (kYC.isVerificationError && !kYC.isTierUpperBasic && adjustedAllowedPopups?.includes('kyc_failed'))
    return 'kyc_failed'

  if (kYC.isVerificationSuccess && adjustedAllowedPopups?.includes('kyc_success')) return 'kyc_success'

  if (!googleAuthSettings.isAccepted && adjustedAllowedPopups?.includes('twoFA')) return 'twoFA'

  if (exchangeStatus !== 'succeeded' && adjustedAllowedPopups?.includes('exchange_init')) return 'exchange_init'

  if (adjustedAllowedPopups?.includes('promomessage')) {
    return 'promomessage'
  }

  return null
}
