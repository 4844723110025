import { FC } from 'react'

interface ZoomInProps {
  className?: string
  size?: number
}

export const ZoomIn: FC<ZoomInProps> = ({ size = 14, className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_2106_10571)">
        <path
          d="M0.5 5.92043C0.5 8.9091 2.93172 11.3408 5.92043 11.3408C7.10232 11.3408 8.18232 10.9605 9.07214 10.322L11.6603 12.8482C11.8166 13.0045 12.0204 13.0792 12.2377 13.0792C12.6996 13.0792 13.0188 12.7328 13.0188 12.2777C13.0188 12.0603 12.9373 11.8633 12.7947 11.7207L10.2269 9.20123C10.9265 8.29103 11.3408 7.15666 11.3408 5.92043C11.3408 2.93172 8.9091 0.5 5.92043 0.5C2.93172 0.5 0.5 2.93172 0.5 5.92043ZM1.66152 5.92043C1.66152 3.57021 3.57021 1.66152 5.92043 1.66152C8.27065 1.66152 10.1793 3.57021 10.1793 5.92043C10.1793 8.27065 8.27065 10.1793 5.92043 10.1793C3.57021 10.1793 1.66152 8.27065 1.66152 5.92043Z"
          fill="white"
        />
        <path
          d="M3.67773 5.92073C3.67773 6.19922 3.89509 6.41658 4.17359 6.41658H5.42341V7.66638C5.42341 7.94488 5.64077 8.16224 5.91927 8.16224C6.19776 8.16224 6.42191 7.94488 6.42191 7.66638V6.41658H7.66492C7.94342 6.41658 8.16078 6.19922 8.16078 5.92073C8.16078 5.64224 7.94342 5.41808 7.66492 5.41808H6.42191V4.17505C6.42191 3.89656 6.19776 3.6792 5.91927 3.6792C5.64077 3.6792 5.42341 3.89656 5.42341 4.17505V5.41808H4.17359C3.89509 5.41808 3.67773 5.64224 3.67773 5.92073Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2106_10571">
          <rect width={size} height={size} fill="currentColor" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
