import React from 'react'

interface IGoogleProps {
  className?: string
}

const Google = (props: IGoogleProps) => {
  const { className } = props

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_10008_67181)" />
      <path
        d="M12.005 20.5102C11.8451 16.3857 15.5326 12.5741 19.7466 12.5246C21.8944 12.3451 23.984 13.1623 25.6094 14.4951C24.9426 15.2133 24.2642 15.9233 23.5396 16.588C22.1087 15.7362 20.3858 15.0871 18.7135 15.6636C16.0161 16.4161 14.3826 19.5364 15.3808 22.1217C16.2074 24.8219 19.5595 26.3037 22.1867 25.1694C23.547 24.6922 24.4439 23.4625 24.8374 22.1411C23.2784 22.1107 21.719 22.1297 20.1599 22.0875C20.156 21.1787 20.1522 20.2733 20.156 19.3645C22.756 19.3607 25.3598 19.3531 27.9636 19.3759C28.1235 21.6026 27.7882 23.9857 26.3107 25.7729C24.2875 28.324 20.5496 29.0726 17.5088 28.0719C14.2816 27.0334 11.9348 23.848 12.005 20.5102Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10008_67181"
          x1="0"
          y1="40.5"
          x2="30.3512"
          y2="-5.82211"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#418DFF" />
          <stop offset="1" stopColor="#0050C8" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { Google }
