import { axiosMainService } from '@/services/axios'
import { appError } from '@/services/error'
import { PositionRiskUsdMRequestDTO, PositionRiskCoinMRequestDTO } from '../models/LeverageDTO'
import { AxiosResponse } from 'axios'
import { binanceRestClients } from '@/services/axios/instances/binance_clients'

export const LeverageApi = {
  /* Get current USD-M position information. */
  async getUsdMPositionRisk(payload?: PositionRiskUsdMRequestDTO) {
    try {
      return await binanceRestClients.usdm?.getPositionInformationRaw(payload || {})
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* USD-M Notional and Leverage Brackets  */
  async getUsdMLeverageBracket() {
    try {
      return await binanceRestClients.usdm?.getLeverageBracket()
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* USD-M Exchange Info  */
  async getUsdMExchangeInfo() {
    try {
      return await binanceRestClients.usdm?.getExchangeInfoRaw()
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Get current Coin-M position information. */
  async getCoinMPositionRisk(payload?: PositionRiskCoinMRequestDTO) {
    try {
      return await binanceRestClients.coinm?.getPositionInformationRaw(payload || {})
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Coin-M Notional and Leverage Brackets  */
  async getCoinMLeverageBracket() {
    try {
      return await binanceRestClients.coinm?.getLeverageBracket()
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Coin-M Exchange Info  */
  async getCoinMExchangeInfo() {
    try {
      return await binanceRestClients.coinm?.getExchangeInfoRaw()
    } catch (err: any) {
      throw appError(err)
    }
  },

  async setLeverage<T, S>(payload: T): Promise<AxiosResponse<S>> {
    return await axiosMainService.post<S>('/overview/protected/api/v2/leverage', payload)
  },

  async setMargin<T, S>(payload: T): Promise<AxiosResponse<S>> {
    return await axiosMainService.post<S>('/overview/protected/api/v2/marginType', payload)
  },
}
