import { FC } from 'react'

type ArrowLeftProps = {
  className?: string
}

export const ArrowLeft: FC<ArrowLeftProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={6} height={8} fill="none" className={className}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m2.182 3.8 2.892 2.667a.68.68 0 0 1 .074.89.532.532 0 0 1-.803.081L.928 4.286a.677.677 0 0 1-.001-.97L4.345.15a.532.532 0 0 1 .802.08.68.68 0 0 1-.072.89L2.182 3.799Z"
      clipRule="evenodd"
    />
  </svg>
)
