import { FC } from 'react'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

interface IProps {
  size: number
  className?: string
  teamUrl: string
}

const TeamAvatar: FC<IProps> = props => {
  const { size, className, teamUrl } = props

  return (
    <div className={className} data-testid={DataTestIds.TeamAvatar}>
      <img src={teamUrl} width={size} height={size} alt="Avatar" className={style.image} />
    </div>
  )
}

export { TeamAvatar }
