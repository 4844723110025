type Colors = {
  greenColor: string
  redColor: string
  greenSteps: string[]
  redSteps: string[]
}

// const lightColors: Colors = {
//   greenColor: "#6edb96",
//   redColor: "#fa8a84",
//
//   greenSteps: [
//     "#27302b",
//     "#263a2e",
//     "#244331",
//     "#214d34",
//     "#1e5735",
//     "#1a6136",
//     "#156b37",
//     "#0e7536",
//     "#077f35",
//     "#008932",
//   ],
//
//   redSteps: [
//     "#382929",
//     "#482b2b",
//     "#582b2b",
//     "#672c2c",
//     "#772c2b",
//     "#862b2a",
//     "#952a29",
//     "#a42826",
//     "#b32623",
//     "#c2241e",
//   ],
// };

const lightColors: Colors = {
  greenColor: '#6edb96',
  redColor: '#fa8a84',

  greenSteps: [
    '#b0e3c4',
    '#a4e3bc',
    '#98e2b3',
    '#8ce2ab',
    '#7fe1a1',
    '#72e098',
    '#64e08e',
    '#55de83',
    '#44dd78',
    '#2edc6d',
  ],

  redSteps: [
    '#f4e2e1',
    '#f8d4d2',
    '#fac7c4',
    '#fcb9b5',
    '#fdaba7',
    '#fe9d98',
    '#fd8e8a',
    '#fc7f7b',
    '#fb706d',
    '#f95f5f',
  ],
}

const darkColors: Colors = {
  greenColor: '#047857',
  redColor: '#8c3c38',
  greenSteps: [
    '#27302b',
    '#28372f',
    '#283f33',
    '#274638',
    '#264e3c',
    '#245641',
    '#215e46',
    '#1c664b',
    '#146e51',
    '#047656',
  ],
  redSteps: [
    '#382929',
    '#412b2b',
    '#4a2d2d',
    '#542f2f',
    '#5d3131',
    '#663332',
    '#6f3534',
    '#783735',
    '#823936',
    '#8b3b37',
  ],
}

export { lightColors, darkColors, type Colors }
