import { SVG } from '@/assets/svg'
import { E_TableRowExchange } from '@/redux/trades/trades.types'

export const getExchangeIcon = (exchange: E_TableRowExchange, iconClassName?: string, isSquareIcon?: boolean) => {
  switch (exchange) {
    case E_TableRowExchange.Binance:
      return isSquareIcon ? (
        <SVG.Tmm.BinanceIcon className={iconClassName} />
      ) : (
        <SVG.Settings.Binance className={iconClassName} />
      )
    case E_TableRowExchange.ByBit:
      return isSquareIcon ? (
        <SVG.Tmm.BybitIcon className={iconClassName} />
      ) : (
        <SVG.Settings.Bybit className={iconClassName} />
      )
    case E_TableRowExchange.Okx:
      return isSquareIcon ? (
        <SVG.Tmm.OkxIcon className={iconClassName} />
      ) : (
        <SVG.Settings.Okx className={iconClassName} />
      )
    default:
      return null
  }
}
