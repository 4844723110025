import React, { FC } from 'react'

interface IArrowLeftProps {
  className?: string
  width?: string | number
  height?: string | number
}

export const ArrowLeft: FC<IArrowLeftProps> = ({ className, height = '12', width = '8' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.56205 5.99163L7.63941 1.76886C8.06403 1.41571 8.12197 0.785208 7.76882 0.360589C7.41567 -0.0640306 6.78516 -0.121968 6.36054 0.231181L0.36057 5.22127C-0.119572 5.6206 -0.120288 6.35745 0.359075 6.75771L6.35905 11.7676C6.78298 12.1216 7.4136 12.0648 7.76757 11.6409C8.12155 11.217 8.06484 10.5864 7.64091 10.2324L2.56205 5.99163Z"
      fill="white"
    />
  </svg>
)
