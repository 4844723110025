import { FC } from 'react'
import { t } from '@lingui/macro'
import { Button, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import styles from './style.module.scss'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

export const PopupNotifyRefCode: FC<Props> = props => {
  return (
    <InteractiveModal isOpen={props.isOpen} className={styles.root}>
      <InteractiveModalParts.SubHeader text={t({ id: 'popupNotifyRefCode.title', comment: 'Title' })} />
      <InteractiveModalParts.Explanation text={t({ id: 'popupNotifyRefCode.description', comment: 'Description' })} />
      <InteractiveModalParts.Button
        text={t({ id: 'core.button.revoke', comment: 'Revoke' })}
        variant={Button.Variant.White}
        onClick={props.onClose}
      />
      <InteractiveModalParts.Button
        text={t({ id: 'core.button.confirm', comment: 'Сonfirm' })}
        variant={Button.Variant.Primary}
        onClick={props.onSubmit}
      />
    </InteractiveModal>
  )
}
