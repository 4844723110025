import clsx from 'clsx'
import React, { ReactNode, useCallback, useMemo } from 'react'
import { SVG } from '@/assets/svg'
import s from './style.module.scss'
import { TChangePayload } from './Checkbox.Components/ArrayedInputsGroup'

type Variant = 'square' | 'circle'

export enum ECheckboxStyleVariant {
  DEFAULT = 'DEFAULT',
  LIGHTER = 'LIGHTER',
  LIGTER_INVERSE = 'LIGTER_INVERSE',
  DARK = 'DARK',
}

export interface CheckboxProps {
  checked?: boolean
  disabled?: boolean
  readonly?: boolean
  variant?: Variant
  children?: ReactNode
  className?: string
  onChange?: (payload: TChangePayload) => void
  dataTestId?: string
  value?: string
  styleVariant?: ECheckboxStyleVariant
  size?: 's' | 'm' | 'l'
  withoutBorder?: boolean
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    disabled,
    readonly,
    children,
    checked = false,
    variant = 'square',
    className,
    onChange,
    value,
    styleVariant: textVariant,
    size = 'l',
  } = props

  const input = useMemo(() => {
    switch (variant) {
      case 'square':
        return (
          <span
            className={clsx(s.inputVisual, s.square, {
              [s.medium]: size === 'm',
              [s.small]: size === 's',
              [s.large]: size === 'l',
            })}
          >
            <SVG.OtherIcons.Check className={s.checkmark} />
          </span>
        )
      case 'circle':
        return (
          <span className={clsx(s.inputVisual, s.circle)}>
            <SVG.OtherIcons.Check className={s.checkmark} />
          </span>
        )
      default:
        return ''
    }
  }, [variant, size])

  const handleChecked = useCallback(
    (payload: TChangePayload) => {
      if (readonly) return
      onChange?.(payload)
    },
    [onChange, readonly]
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChecked({
        checked: e.target.checked,
        value,
      })
    },
    [handleChecked, value]
  )

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return
      handleChecked({
        checked: !checked,
        value,
      })
    },
    [handleChecked, checked, value]
  )

  return (
    <label
      className={clsx(
        s.root,
        {
          [s.disabled]: disabled,
          [s.checked]: checked,
          [s.styled]: textVariant === ECheckboxStyleVariant.DEFAULT,
          [s.dark]: textVariant === ECheckboxStyleVariant.DARK,
          [s.lighter]: textVariant === ECheckboxStyleVariant.LIGHTER,
          [s.lighterInverse]: textVariant === ECheckboxStyleVariant.LIGTER_INVERSE,
        },
        className
      )}
      data-testid={props.dataTestId}
    >
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        readOnly={readonly}
        className={s.input}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        value={value}
      />
      {input}
      {children}
    </label>
  )
}
