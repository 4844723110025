import React from 'react'

interface AddressProps {
  className?: string
}

const Shield: React.FC<AddressProps> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.5" width="40" height="40" rx="20" fill="#1C1A24" />
      <path
        d="M29 19.5C29 25.05 25.16 30.24 20 31.5C14.84 30.24 11 25.05 11 19.5V13.5L20 9.5L29 13.5V19.5ZM20 29.5C23.75 28.5 27 24.04 27 19.72V14.8L20 11.68V29.5Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}

export { Shield }
