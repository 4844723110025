import { SVG } from '@/assets/svg'
import style from './style.module.scss'
import clsx from 'clsx'
import { DayDiary } from '@/utils/types/contracts.types'
import { FC } from 'react'
import {
  DEFAULT_LEVERAGE_VALUE,
  DEFAULT_VALUE_WITH_SYMBOL,
  DEFAULT_VALUE_WITH_ZERO,
  handleRound,
} from '@/ui/organisms/DiaryRightBar/components/WeekCard/WeekCard'
import { calculateWeekWinPercentage } from '@/ui/organisms/DiaryWeekInfo/components/WeekResult/utils'
import { useExchangeById } from '@/ui/organisms/HistoryTrades/hooks/useTMMTable/useExchangeById'
import {
  tableFilterExchangeIds,
  tableFilterMarketTypeIds,
} from '@/ui/organisms/HistoryTrades/components/TableFilters/utils'
import { useAppSelector } from '@/utils'
import { E_TableRowMarketType } from '@/redux/trades/trades.types'
import { getExchangeIcon } from '@/ui/organisms/HistoryTrades/helpers/getExchangeIcon'
import { t } from '@lingui/macro'

type AnalyzerMonthTickerItemProps = {
  trade: DayDiary
  isActive: boolean
  tickerHandler: () => void
}

export const AnalyzerMonthTickerItem: FC<AnalyzerMonthTickerItemProps> = ({ trade, isActive, tickerHandler }) => {
  const { me } = useAppSelector(state => state.trades)

  const exchangeById = useExchangeById(me, tableFilterExchangeIds, tableFilterMarketTypeIds)
  const exchange = exchangeById(trade.api_key_id)
  const marketType =
    exchange && exchange.marketType === E_TableRowMarketType.Futures
      ? E_TableRowMarketType.Futures
      : E_TableRowMarketType.Spot

  const exchangeIcon = exchange && getExchangeIcon(exchange?.exchangeName, style.icon, true)
  return (
    <div
      className={clsx(style.tickerList, {
        [style.activeTicker]: isActive,
      })}
      onClick={tickerHandler}
    >
      <div className={style.tickerListContainer}>
        <div className={style.ticker}>
          <div
            className={clsx(style.tickerName, {
              [style.active]: isActive,
            })}
          >
            {trade.symbol}
          </div>
          <div className={style.exchange}>
            {exchangeIcon}
            <div className={style.market}>{marketType}</div>
          </div>
        </div>
        <div className={clsx(style.deals, style.firstWrapper)}>
          <div className={style.items}>
            <div className={style.sum}>{trade.count ? trade.count : DEFAULT_VALUE_WITH_ZERO}</div>
            <div className={style.sumTitle}>
              {t({
                id: 'trades.summary.transactions',
                comment: 'transactions',
              })}
            </div>
          </div>
          <div className={style.items}>
            <div className={style.sum}>
              {trade.net_profit ? handleRound(trade.net_profit) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
            </div>
            <div className={style.sumTitle}>
              {t({
                id: 'trades.summary.netProfit',
                comment: 'netProfitTitle',
              })}
            </div>
          </div>
        </div>
        <div className={style.deals}>
          <div className={style.items}>
            <div className={style.sum}>{calculateWeekWinPercentage(trade.count, trade.win_count)}</div>
            <div className={style.sumTitle}>
              {t({
                id: 'trades.summary.winPercentage',
                comment: 'winPercentage',
              })}
            </div>
          </div>
          <div className={style.items}>
            <div className={style.sum}>
              {trade.leverage ? 'x' + handleRound(trade.leverage) : DEFAULT_LEVERAGE_VALUE}
            </div>
            <div className={style.sumTitle}>
              {t({
                id: 'trades.summary.weekLeverage',
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
