import {
  INotificationConfig,
  ModalComponent,
  NotififcationPresets,
  SystemNotifiactionIcon,
} from '@/ui/molecules/SystemNotifications/notifications.type'
import { t } from '@lingui/macro'
import style from './style.module.scss'
import { Button, ButtonSize, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { EInteractiveModalVariant } from '@/ui/kit/Modals/InteractiveModal/InteractiveModal'
import { Control } from '@/ui/atoms/Control'
import { Link } from 'react-router-dom'
import { urls } from '@/router/urls'
import { parseBooleanEnv } from '@/utils/lib/parseEnvVariable'
import { Trans } from '@lingui/react'
import { SVG } from '@/assets/svg'
import clsx from 'clsx'
import { memo } from 'react'

function getModalComponent({ isMobile }: { isMobile: boolean }): ModalComponent {
  return memo(({ isOpen, onClose }) => (
    <InteractiveModal isOpen={isOpen} className={style.modal} variant={EInteractiveModalVariant.MEDIUM}>
      <div className={style.header}>
        <InteractiveModalParts.SubHeaderL2
          text={t({ id: 'core.systemAlert.binanceTechnicalWorks.title', comment: 'About technical work' })}
          className={style.title}
        />

        {!isMobile && <Control.Close onClick={onClose} className={style.close} />}
      </div>

      <div className={style.description}>
        {t({
          id: 'core.systemAlert.binanceTechnicalWorks.description-p1',
          message: 'Description part 1',
        })}

        <span className={style.gradient}>
          <Trans
            id="core.systemAlert.binanceTechnicalWorks.description-p2"
            components={{
              linkToOrders: <Link className={style.link} to={urls.orders} onClick={onClose} />,
            }}
          />
        </span>

        <div className={style.additional}>
          <div className={style.additionalImage}>
            <SVG.OtherIcons.Smiley />
          </div>
          <div className={style.additionalText}>
            {t({
              id: 'core.systemAlert.binanceTechnicalWorks.description-p3',
            })}
          </div>
        </div>
      </div>

      <Button.Primary
        size={ButtonSize.Medium}
        className={style.button}
        label={t({ id: 'core.systemAlert.binanceTechnicalWorks.notified', message: 'I have been notified' })}
        onClick={onClose}
      />
    </InteractiveModal>
  ))
}

interface IBinanceTechnicalWorkAlertConfig {
  isMobile: boolean
}

export function getBinanceTechnicalWorkAlert({ isMobile }: IBinanceTechnicalWorkAlertConfig): INotificationConfig {
  const now = new Date().getTime()
  // const startDate = new Date('2024-08-29').setUTCHours(0, 0, 0, 0)
  const endDate = new Date('2024-10-17').setUTCHours(14, 0, 0, 0)

  const preset: INotificationConfig = {
    id: NotififcationPresets.BinanceTechnicalWork,
    icon: SystemNotifiactionIcon.Alert,
    heading: (
      <span className={clsx(isMobile && style.mobile, style.label)}>
        <Trans
          id={'core.systemAlert.binanceTechnicalWorks.label'}
          message="Binance's technical work"
          components={{
            gradientText: <span className={style.gradient} />,
          }}
        />
      </span>
    ),
    description: <Trans id="core.systemAlert.binanceTechnicalWorks.description" />,
    // Modal: getModalComponent({ isMobile }),
    shouldShown: now < endDate, //parseBooleanEnv(process.env.REACT_APP_BINANCE_TECHNICAL_WORKS),
    // shouldShown: false,
    availableExchange: ['BINANCE_BROKER_FUTURE'],
    isClosable: true,
    link: {
      uri: 'https://www.binance.com/en/support/announcement/7e4e2c0c841c4eefa1fcdeb3340c23a2',
    },
  }

  return preset
}
