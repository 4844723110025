import {
  GetRequestedTeamTC,
  GetUserTeamInfoTC,
  GetManagerMembersTC,
  GetManagerRequestsTC,
  HandleManagerRequests,
  TeamJoinTC,
  TeamCancelJoinTC,
  TeamLeaveTC,
  GetTeamRiskManagerTC,
  SetTeamRiskManagerTC,
  UpdateTeamRiskManagerTC,
  DeleteTeamRiskManagerTC,
  ChangeMemberCashbackTC,
  GetTeamRiskManagerTradingStatusTC,
  DeleteMemberTC,
  GetMemberCashbackTC,
  ChangeTeamManagerDefaultCashbackTC,
} from './team.thunk'

export const teamAsyncActions = {
  GetRequestedTeamTC,
  GetUserTeamInfoTC,
  GetManagerMembersTC,
  GetManagerRequestsTC,
  HandleManagerRequests,
  TeamJoinTC,
  TeamCancelJoinTC,
  TeamLeaveTC,
  GetTeamRiskManagerTC,
  SetTeamRiskManagerTC,
  UpdateTeamRiskManagerTC,
  DeleteTeamRiskManagerTC,
  ChangeMemberCashbackTC,
  GetTeamRiskManagerTradingStatusTC,
  DeleteMemberTC,
  GetMemberCashbackTC,
  ChangeTeamManagerDefaultCashbackTC,
}
