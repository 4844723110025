import { axiosMainService } from '@/services/axios'
import { appError } from '@/services/error'
import { VoucherOverviewResponseDTO, ApplyVoucherCodeRequestDTO, Voucher } from '@/backend/models/VoucherDTO'

export const VoucherApi = {
  /* Get voucher overview */
  async getVoucherOverview() {
    try {
      return await axiosMainService.get<VoucherOverviewResponseDTO>(`/cashback/protected/api/v1/userpromocode`)
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Apply voucher code */
  async applyVoucherCode(payload: ApplyVoucherCodeRequestDTO) {
    try {
      return await axiosMainService.post<Voucher>(`/cashback/protected/api/v1/userpromocode`, payload)
    } catch (err: any) {
      throw appError(err)
    }
  },
}
