import { t } from '@lingui/macro'

export enum E_SwitchItems {
  MONTH = 'month',
  DAY = 'day',
}

export const getSwitchItems = () => {
  return [
    {
      label: t({
        id: 'trades.summary.month',
      }),
      value: E_SwitchItems.MONTH,
    },
    {
      label: t({
        id: 'trades.summary.day',
      }),
      value: E_SwitchItems.DAY,
    },
  ]
}
