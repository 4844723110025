import { t } from '@lingui/macro'
import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TExchangesAvailable } from '@/core/constants'
import { appActions } from '@/redux/app/app.slice'
import { urls } from '@/router/urls'
import { MobileTabs } from '@/ui/atoms/MobileTabs/MobileTabs'
import { Button, ButtonSize } from '@/ui/kit'
import { DropDownItem } from '@/ui/kit/Dropdown/Dropdown'
import { MobileProfile } from '@/ui/organisms/MobileProfile/MobileProfile'
import { useActions } from '@/utils'
import { useExchangesSource } from '@/utils/hooks/useExchangesSource'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { MobileDeals } from './components/MobileDeals/MobileDeals'
import s from './style.module.scss'

type TOptionExchange = DropDownItem<TExchangesAvailable>

const OrdersAndPositionsMobile: FC = () => {
  const { exchangeType } = useExchageType()
  const { setExchangeType } = useActions(appActions)
  const navigate = useNavigate()

  const exchanges = useExchangesSource()

  const [selectedOption, setSelectedOption] = useState<TOptionExchange | null>(
    exchanges.find(exchange => {
      return exchange.value === exchangeType
    }) || null
  )

  const setSelectedCallback = useCallback((value: TOptionExchange) => {
    if (value.value === exchangeType) return
    setSelectedOption(value)
    setExchangeType(value.value)
  }, [])

  const goToAssets = useCallback(() => {
    navigate(urls.assets)
  }, [])

  return (
    <div className={s.mobileRoot}>
      <div className={s.tabs}>
        <MobileTabs options={exchanges} selectedOption={selectedOption} setSelectedOption={setSelectedCallback} />
      </div>

      <MobileProfile />

      <Button.AccentDark
        onClick={goToAssets}
        className={s.assetsBtn}
        size={ButtonSize.Medium}
        label={t({ message: 'View assets', id: 'mainTabCryptocurrency.viewAssets' })}
      ></Button.AccentDark>

      <MobileDeals />
    </div>
  )
}

export { OrdersAndPositionsMobile }
