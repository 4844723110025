import { Arrow } from './Arrow'
import { Away } from './Away'
import { Close } from './Close'
import { Copy } from './Copy'
import { Load } from './Load'
import { Options } from './Options'
import { Support } from './Support'
import { Gear } from './Gear'
import { Warning } from './Warning'
import { Union } from './Union'
import { Search } from './Search'
import { Draggable } from './Draggable'
import { CopySmall } from './CopySmall'
import { Danger } from './Danger'

export const Additional = {
  Arrow,
  Close,
  Load,
  Options,
  Support,
  Copy,
  CopySmall,
  Gear,
  Away,
  Warning,
  Union,
  Search,
  Draggable,
  Danger,
}
