import { useEffect, useRef, useState } from 'react'
export enum E_ScrollDirection {
  LEFT = 'left',
  RIGHT = 'right',
}
const useHorizontalScroll = () => {
  const tableRef = useRef<HTMLDivElement | null>(null)
  const [isScrollableLeft, setIsScrollableLeft] = useState(false)
  const [isScrollableRight, setIsScrollableRight] = useState(false)
  useEffect(() => {
    const handleScroll = (resize?: boolean) => {
      if (tableRef.current) {
        const scrollableLeft = tableRef.current.scrollLeft > 0
        const scrollableRight = resize
          ? tableRef.current.scrollWidth > tableRef.current.clientWidth + tableRef.current.scrollLeft
          : tableRef.current.scrollWidth > tableRef.current.clientWidth + (tableRef.current.scrollLeft + 20)
        setIsScrollableLeft(scrollableLeft)
        setIsScrollableRight(scrollableRight)
      }
    }
    const handleResize = () => {
      handleScroll(true)
    }
    const tableRefCurrent = tableRef.current
    if (tableRefCurrent) {
      handleScroll()
      tableRefCurrent.addEventListener('scroll', () => handleScroll())
      window.addEventListener('resize', handleResize)
    }
    return () => {
      if (tableRefCurrent) {
        tableRefCurrent.removeEventListener('scroll', () => handleScroll)
      }
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const handleHorizontalScroll = (direction: E_ScrollDirection) => {
    if (tableRef.current) {
      let scrollDistance = 2000

      if (direction === E_ScrollDirection.LEFT) {
        scrollDistance = -scrollDistance
      }

      tableRef.current.scrollTo({
        left: tableRef.current.scrollLeft + scrollDistance,
        behavior: 'smooth',
      })
    }
  }
  return {
    tableRef,
    isScrollableLeft,
    isScrollableRight,
    handleHorizontalScroll,
  }
}
export default useHorizontalScroll
