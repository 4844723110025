import React from 'react'

export const Support = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4939 10.3434C18.6597 5.86697 15.4267 2.0537 11.0332 1.55632C5.89355 0.976042 1.5 4.9551 1.5 10.0118C1.5 15.483 5.97644 19.9595 11.4476 19.9595C10.7845 19.0476 11.2819 17.7213 12.3595 17.4726C15.7583 16.6436 18.3281 14.0738 18.4939 10.3434Z"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.21457 7.85657L11.7791 7.85657"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.21976 11.3381L12.7739 11.3381"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
