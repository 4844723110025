import { EChartsType } from 'echarts'

interface ApplyZoomOptions {
  echart?: EChartsType | null
  type: 'zoomIn' | 'zoomOut' | 'reset'
  stepSize?: number
  currentleftPosition?: number
  currentRightPosition?: number
}

export function applyZoom({
  echart,
  type,
  stepSize = 1,
  currentleftPosition = 0,
  currentRightPosition = 0,
}: ApplyZoomOptions): void {
  if (!echart) return

  if (type === 'reset') {
    echart.dispatchAction({
      type: 'dataZoom',
      start: 0,
      end: 100,
    })
    return
  }

  const pointer = type === 'zoomIn' ? 1 : -1

  let newLeft = Number((currentleftPosition + stepSize * pointer).toFixed(2))
  let newRight = Number((currentRightPosition - stepSize * pointer).toFixed(2))

  if (newLeft < 0) {
    const diff = Math.abs(newLeft)
    newLeft = 0
    newRight += diff
  }

  if (newRight > 100) {
    const diff = newRight - 100
    newRight = 100
    newLeft -= diff
  }

  echart.dispatchAction({
    type: 'dataZoom',
    start: newLeft,
    end: newRight,
  })
}
