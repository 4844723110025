import { exchangeApi } from '@/backend/api/exchange'
import { InternalAxiosRequestConfig } from 'axios'

export const OKXSignWithReadOnlyInterceptor = async (config: InternalAxiosRequestConfig) => {
  try {
    const { url } = config

    const query = new URLSearchParams(config.params)

    const res = await exchangeApi.postSignWithReadOnlyOKX({
      queryString: query.toString() || '',
      requestBody: config.data?.toString(),
      requestMethod: config.method || 'GET',
      requestPath: url || '',
    })

    config.headers = config.headers ?? {}

    Object.entries(res.data.headers).forEach(([key, value]) => {
      config.headers[key] = value
    })

    config.params = config.params ?? {}

    Object.entries(res.data.parameters).forEach(([key, value]) => {
      config.params[key] = value
    })
  } catch (e) {
    const controller = new AbortController()
    controller.abort()

    return {
      ...config,
      signal: controller.signal,
    }
  }

  return config
}
