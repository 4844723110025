import { ServicesTradeGroupBy, TradeDurationGranularity } from '@/utils/types/contracts.types'
import { DashboardFilterType, InitialState } from './dashboard.types'
import { TradeDurationType } from '@/backend/types'

const initialDashboardFilter: DashboardFilterType = {
  durationType: (localStorage.getItem('dashboard_durationType') as TradeDurationType) || '30d',
  groupBy: getGroupByFromLocalStorage(),
}

function getGroupByFromLocalStorage(): ServicesTradeGroupBy | undefined {
  const storedGroupBy = localStorage.getItem('dashboard_groupBy')

  if (storedGroupBy === null) {
    return TradeDurationGranularity['30d']
  }

  if (storedGroupBy === 'undefined') {
    return undefined
  }

  return storedGroupBy as ServicesTradeGroupBy
}

export const initialState: InitialState = {
  dashboard: [],
  dashboardStatus: 'idle',
  dashboardFilter: initialDashboardFilter,
}
