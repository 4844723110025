import React, { useEffect } from 'react'
import { registerLocale } from 'react-datepicker'
import { enGB, ru, uk } from 'date-fns/locale'
import { i18n } from '@lingui/core'
import { I18nProvider, useLingui } from '@lingui/react'
import { en as enPlurals, uk as ukPlurals, ru as ruPlurals } from 'make-plural/plurals'
import { TLanguage } from '@/core/constants'
import { LanguageContext } from './LanguageContext'
import { useActions, useAppSelector } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'

import { messages as enMessages } from '@/locales/en/messages'
import { messages as ruMessages } from '@/locales/ru/messages'
import { messages as ukMessages } from '@/locales/uk/messages'

registerLocale('ru', ru)
registerLocale('uk', uk)
registerLocale('en-US', enGB)

const messages = {
  en: enMessages,
  ru: ruMessages,
  uk: ukMessages,
}

export interface ILanguageProviderProps {
  children: React.ReactNode
}

const LanguageProviderInner: React.FC<ILanguageProviderProps> = props => {
  const { i18n } = useLingui()
  const { isSetup } = useAppSelector(state => state.app)
  const { language } = useAppSelector(state => state.profile)
  const { UpdateLocale } = useActions(profileActions)

  const { locale } = i18n

  useEffect(() => {
    if (locale !== language && isSetup) {
      i18n.activate(language)
    }
  }, [language, locale, isSetup])

  const handleChangeLocale = (locale: TLanguage) => {
    UpdateLocale({ locale })
  }

  return (
    <LanguageContext.Provider value={{ locale: language as TLanguage, setLocale: handleChangeLocale }}>
      {props.children}
    </LanguageContext.Provider>
  )
}

const LanguageProvider: React.FC<ILanguageProviderProps> = props => {
  const { language } = useAppSelector(state => state.profile)

  useEffect(() => {
    i18n.loadLocaleData('uk', { plurals: ukPlurals })
    i18n.loadLocaleData('ru', { plurals: ruPlurals })
    i18n.loadLocaleData('en', { plurals: enPlurals })
    i18n.load(messages)
    i18n.activate(language)
  }, [])

  return (
    <I18nProvider i18n={i18n}>
      <LanguageProviderInner>{props.children}</LanguageProviderInner>
    </I18nProvider>
  )
}

export { LanguageProvider }
