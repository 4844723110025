import { FC, memo } from 'react'
import { HistoryCashback } from '../../../backend/api'
import CashbackRightBarListHeader from '../../molecules/CashbackRightBarListHeader'
import CashbackRightBarList from '../CashbackRightBarList'
import style from './style.module.scss'

type CashbackRightBarContentProps = {
  historyCashback: HistoryCashback
}

const CashbackRightBarContent: FC<CashbackRightBarContentProps> = props => {
  return (
    <div className={style.cashbackRightBarContent}>
      <CashbackRightBarListHeader
        totalCashback={props.historyCashback.totalCashback}
        total={props.historyCashback.total}
      />
      <CashbackRightBarList />
    </div>
  )
}

export default memo(CashbackRightBarContent)
