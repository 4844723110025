import axios, { AxiosResponse } from 'axios'
import {
  AccountInfoV5,
  APIResponseV3WithTime,
  CategoryCursorListV5,
  ContractHistoricOrder,
  LinearInverseInstrumentInfoV5,
  PositionV5,
  RiskLimitV5,
  SpotInstrumentInfoV5,
  WalletBalanceV5,
} from 'bybit-api'
import { BYBIT_API_URL } from '@/core/config/api'
import { ByBitTransactionHistory, CategoryListV5Internal, EBybitInstruments } from './types'
import { signWithReadOnlyInterceptor } from '../axios/interceptors/signWithReadOnlyInterceptor'
import { BYBIT_EXTENDED_REQUEST_TIMEOUT, GLOBAL_REQUEST_TIMEOUT } from '@/core/config/exchange'

const bybitAxiosClient = axios.create({
  baseURL: BYBIT_API_URL,
  timeout: GLOBAL_REQUEST_TIMEOUT,
})
bybitAxiosClient.interceptors.request.use(signWithReadOnlyInterceptor)

export const bybitRestService = {
  async getPositions(params: {
    category: EBybitInstruments
    symbol?: string
    limit?: string
    settleCoin?: string
  }): Promise<AxiosResponse<APIResponseV3WithTime<CategoryCursorListV5<PositionV5[]>>>> {
    return await bybitAxiosClient.get('/v5/position/list', {
      params: {
        ...params,
        limit: '200',
      },
    })
  },
  async getPositionsRecursive(
    params: {
      category: EBybitInstruments
      symbol?: string
      limit?: string
      settleCoin?: string
    },
    positions: PositionV5[] = []
  ): Promise<PositionV5[]> {
    const response = await bybitRestService.getPositions(params)

    const currentPagePositions = response.data.result.list || []

    positions.push(...currentPagePositions)

    if (response.data.result && response.data.result.nextPageCursor) {
      const nextParams = { ...params, cursor: response.data.result.nextPageCursor }
      return await bybitRestService.getPositionsRecursive(nextParams, positions)
    }

    return positions
  },
  async getOrders(params: {
    category: EBybitInstruments
    limit?: string
    settleCoin?: string
  }): Promise<
    AxiosResponse<
      APIResponseV3WithTime<
        Omit<CategoryCursorListV5<ContractHistoricOrder[]>, 'list'> & { list?: ContractHistoricOrder[] }
      >
    >
  > {
    return await bybitAxiosClient.get('/v5/order/realtime', {
      params: { ...params, limit: '50' },
    })
  },
  async getOrdersRecursive(
    params: {
      category: EBybitInstruments
      limit?: string
      settleCoin?: string
      cursor?: string
    },
    orders: ContractHistoricOrder[] = []
  ): Promise<ContractHistoricOrder[]> {
    const response = await bybitRestService.getOrders(params)

    const currentPageOrders = response.data.result.list || []

    orders.push(...currentPageOrders)
    if (typeof response.data.result.nextPageCursor === 'string' && response.data.result.nextPageCursor !== '') {
      const nextParams = { ...params, cursor: response.data.result.nextPageCursor }
      return await bybitRestService.getOrdersRecursive(nextParams, orders)
    }

    return orders
  },
  async getWalletBalance(params: {
    accountType: string
  }): Promise<AxiosResponse<APIResponseV3WithTime<{ list?: WalletBalanceV5[] }>>> {
    return await bybitAxiosClient.get('/v5/account/wallet-balance', {
      params,
    })
  },
  async getInstrumentsInfo<T extends unknown[] = LinearInverseInstrumentInfoV5[] | SpotInstrumentInfoV5[]>(params: {
    category: EBybitInstruments
  }): Promise<AxiosResponse<APIResponseV3WithTime<CategoryCursorListV5<T>>>> {
    return await bybitAxiosClient.get('/v5/market/instruments-info', {
      params,
      timeout: BYBIT_EXTENDED_REQUEST_TIMEOUT,
    })
  },
  async getRiskLimit(params: {
    category: EBybitInstruments
    cursor?: string
  }): Promise<AxiosResponse<APIResponseV3WithTime<CategoryListV5Internal<RiskLimitV5[], 'inverse' | 'linear'>>>> {
    return await bybitAxiosClient.get('/v5/market/risk-limit', {
      params,
    })
  },
  async getMarketTickers(params: { category: EBybitInstruments }): Promise<any> {
    return await bybitAxiosClient.get('/v5/market/tickers', {
      params,
    })
  },
  async getRiskLimitBySymbol(
    params: {
      category: EBybitInstruments
      symbol: string
    },
    tickers: RiskLimitV5[] = []
  ): Promise<{ data: RiskLimitV5[]; status: number }> {
    const response = await bybitRestService.getRiskLimit(params)

    const currentPageTickers = response.data.result.list || []

    tickers.push(...currentPageTickers)

    return {
      data: tickers,
      status: 200,
    }
  },
  async getAccountInfo(): Promise<AxiosResponse<APIResponseV3WithTime<AccountInfoV5>>> {
    return await bybitAxiosClient.get('/v5/account/info')
  },
  async getHistories(
    params: Record<string, string>
  ): Promise<AxiosResponse<APIResponseV3WithTime<ByBitTransactionHistory>>> {
    return await bybitAxiosClient.get('/v5/account/transaction-log', {
      params,
    })
  },
}
