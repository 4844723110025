import { FC } from 'react'

interface IArrowRightProps {
  className?: string
  width?: string | number
  height?: string | number
}

export const ArrowRight: FC<IArrowRightProps> = ({ className, height = '12', width = '8' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.43795 6.00837L0.360588 10.2311C-0.0640312 10.5843 -0.121969 11.2148 0.23118 11.6394C0.58433 12.064 1.21484 12.122 1.63945 11.7688L7.63943 6.77873C8.11957 6.3794 8.12029 5.64255 7.64092 5.24229L1.64095 0.232425C1.21702 -0.12155 0.586401 -0.064839 0.232426 0.359092C-0.121549 0.783023 -0.064838 1.41364 0.359093 1.76762L5.43795 6.00837Z"
      fill="white"
    />
  </svg>
)
