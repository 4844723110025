import { Loader } from '@/ui/kit/Loader'
import { TableError } from '@/ui/organisms/HistoryTrades/components/TableError/TableError'
import { useAppSelector } from '@/utils'
import React, { FC, Suspense } from 'react'
import { useParams } from 'react-router-dom'

const TradeInfoView = React.lazy(() => import('./TradeInfoView/TradeInfoView'))

export const TradeInfo: FC = () => {
  const { availableUsersTradingStatus, availableUsersTrading } = useAppSelector(state => state.trades)
  const { tradeId } = useParams()
  const safeId = Number(tradeId)

  if (availableUsersTradingStatus === 'succeeded' && !availableUsersTrading?.isStatisticAvailable)
    return <TableError technicalError={true} />

  if (availableUsersTradingStatus !== 'succeeded' || Number.isNaN(safeId)) return <Loader />

  return (
    <Suspense fallback={<Loader />}>
      <TradeInfoView tradeId={safeId} />
    </Suspense>
  )
}
