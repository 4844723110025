import style from './style.module.scss'
import { LevelItem } from './components/Level.item'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { t } from '@lingui/macro'
import { useKYCLevelsVM } from '@/pages/KYCLevels/hooks/useKYCLevelsVM'
import clsx from 'clsx'
import { useActions } from '@/utils'
import { kycAsyncActions } from '@/redux/kyc/kyc.actions'
import { useEffect } from 'react'

export const KycLevelsPage = () => {
  const { levels } = useKYCLevelsVM()
  const { GetUserKycTC } = useActions(kycAsyncActions)

  useEffect(() => {
    GetUserKycTC()
  }, [GetUserKycTC])

  return (
    <div className={style.container}>
      <TitleH1 label={t({ message: 'KYC levels', id: 'kyclevels.header' })} />
      <div className={style.levelsWrapper}>
        {levels.map(level => (
          <div
            key={level.tierName}
            className={clsx(style.levelsWrapperItem, {
              [style._active]: level.isActive,
            })}
          >
            <LevelItem {...level} />
          </div>
        ))}
      </div>
    </div>
  )
}
