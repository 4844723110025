import { SVG } from '@/assets/svg'
import { TradingPlatforms } from '@/redux/trades/trades.types'
import { FC } from 'react'
import style from './style.module.scss'

interface LegendIconProps {
  rawLegendName: string
}

export const LegendIcon: FC<LegendIconProps> = ({ rawLegendName }) => {
  switch (rawLegendName) {
    case TradingPlatforms.BINANCE_FUTURE:
    case TradingPlatforms.BINANCE_SPOT:
    case TradingPlatforms.BINANCE_VIP_FUTURE:
    case TradingPlatforms.BINANCE_VIP_SPOT:
      return <SVG.Tmm.BinanceIcon size={10} />

    case TradingPlatforms.BYBIT_LINEAR:
    case TradingPlatforms.BYBIT_SPOT:
      return <SVG.Tmm.BybitIcon size={10} className={style.bybit} />

    case TradingPlatforms.OKX_SPOT:
    case TradingPlatforms.OKX_SWAP:
      return <SVG.Tmm.OkxIcon size={10} />

    default:
      return null
  }
}
