import { Dashboard } from '@/ui/organisms/Dashboard/Dashboard'
import { TableError } from '@/ui/organisms/HistoryTrades/components/TableError/TableError'
import { useAppSelector } from '@/utils'
import { FC } from 'react'

export const DashboardPage: FC = () => {
  const DASHBOARD_ID = 1
  const { availableUsersTradingStatus, availableUsersTrading } = useAppSelector(state => state.trades)

  if (availableUsersTradingStatus === 'succeeded' && !availableUsersTrading?.isStatisticAvailable)
    return <TableError technicalError={true} />

  return <Dashboard dashboardId={DASHBOARD_ID} />
}
