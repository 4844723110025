import { OKX_POLLING_INTERVAL } from '@/core/constants'
import { exchangeApi } from '@/backend/api/exchange'
import { appActions } from '@/redux/app/app.slice'
import { leverageActions } from '@/redux/leverage/leverage.slice'
import { overviewActions } from '@/redux/overview/overview.slice'
import { urls } from '@/router/urls'
import {
  EOkxInstruments,
  EOkxOrderType,
  OkxInstruments,
  okxDataMappers,
  okxReducers,
  okxRestService,
} from '@/services/okx'
import { useActions } from '@/utils'
import { setBalancesTimeout } from '@/utils/lib/setBalancesTimeout'
import { EAccountType, TQuoteBalance } from '@tigertrade/binance-ts'
import { AccountPosition, AlgoOrderListItem, OrderDetails } from 'okx-api'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useLocation } from 'react-router-dom'
import { NEED_POLING_ROUTES, OKX_SOCKET_RECONNECT_LIMIT } from '@/core/config/common'
import { TOrderUpdateTuple } from '@/redux/overview/overview.types'

const LIMIT = '100'

export const OkxProvider: FC = ({ children }) => {
  const { setPositionsUpdate, setBalanceUpdate, setInstrumentInfoOkx, setOrdersUpdate } = useActions(overviewActions)
  const { setIsExchangeProviderInitialized, setExchangeStatus, setRequestStatus } = useActions(appActions)
  const { setLeverageListOkx } = useActions(leverageActions)

  const reconnectCount = useRef(0)

  const balancesReducedRef = useRef<{
    totalMarginBalance: string
    totalAssetsOutput: TQuoteBalance
  }>()

  const pollingInterval = useRef<ReturnType<typeof setInterval>>()
  const balancesLoadRef = useRef<boolean>(false)
  const okxInstrumentProvider = new OkxInstruments(okxRestService.getInstruments)

  const socketClient = useRef<WebSocket | undefined>()

  const [isInstrumentsLoaded, setIsInstrumentsLoaded] = useState(false)

  const isFirstApiCall = useRef(true)

  // workaround to handle choose exchange page, TODO: remove and remake
  const location = useLocation()
  const isChooseExchange = useMemo(() => {
    return location.pathname.includes(urls.chooseExchange)
  }, [location.pathname])

  const shouldPausePoling = useMemo(() => {
    return !NEED_POLING_ROUTES.includes(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    const load = async () => {
      if (
        okxInstrumentProvider.instrumentsByType.get(EOkxInstruments.SPOT) === undefined &&
        okxInstrumentProvider.instrumentsByType.get(EOkxInstruments.SWAP) === undefined &&
        okxInstrumentProvider.isInteracted
      ) {
        return
      }

      if (
        okxInstrumentProvider.instrumentsByType.get(EOkxInstruments.SPOT) === undefined &&
        okxInstrumentProvider.instrumentsByType.get(EOkxInstruments.SWAP) === undefined &&
        !okxInstrumentProvider.isInteracted
      ) {
        try {
          await Promise.all([
            okxInstrumentProvider.loadInstruments(EOkxInstruments.SPOT),
            okxInstrumentProvider.loadInstruments(EOkxInstruments.SWAP),
          ])

          setIsInstrumentsLoaded(true)
          setInstrumentInfoOkx(okxInstrumentProvider.instrumentsByType.get(EOkxInstruments.SPOT) || [])

          setLeverageListOkx(
            okxInstrumentProvider.instrumentsByType.get(EOkxInstruments.SWAP)?.map(inst => {
              return inst.instId
            }) || []
          )
        } catch {
          if (pollingInterval.current !== undefined) clearInterval(pollingInterval.current)
          setRequestStatus('failed')
          return
        }

        setIsExchangeProviderInitialized(true)
      }
    }

    load()
  }, [])

  const apiCall = useCallback(async () => {
    let ordersDataSpot: OrderDetails[],
      ordersDataSwap: OrderDetails[],
      algoCond: AlgoOrderListItem[],
      algoOco: AlgoOrderListItem[],
      algoTrigger: AlgoOrderListItem[],
      algoMove: AlgoOrderListItem[]

    const results = await Promise.allSettled([
      okxRestService.getOrdersRecursive({ instType: EOkxInstruments.SPOT, limit: LIMIT }),
      okxRestService.getOrdersRecursive({ instType: EOkxInstruments.SWAP, limit: LIMIT }),
      okxRestService.getAlgoOrdersRecursive({ ordType: EOkxOrderType.conditional, limit: LIMIT }),
      okxRestService.getAlgoOrdersRecursive({ ordType: EOkxOrderType.oco, limit: LIMIT }),
      okxRestService.getAlgoOrdersRecursive({ ordType: EOkxOrderType.trigger, limit: LIMIT }),
      okxRestService.getAlgoOrdersRecursive({ ordType: EOkxOrderType.move_order_stop, limit: LIMIT }),
    ])

    ordersDataSpot = results[0].status === 'fulfilled' ? results[0].value : []
    ordersDataSwap = results[1].status === 'fulfilled' ? results[1].value : []
    algoCond = results[2].status === 'fulfilled' ? results[2].value : []
    algoOco = results[3].status === 'fulfilled' ? results[3].value : []
    algoTrigger = results[4].status === 'fulfilled' ? results[4].value : []
    algoMove = results[5].status === 'fulfilled' ? results[5].value : []

    const shouldUpdateSwap = isFirstApiCall.current || results[0].status === 'fulfilled'
    const shouldUpdateSpot = isFirstApiCall.current || results[1].status === 'fulfilled'

    const [spoAlgoOrders, swapAlgoOrders] = [...algoCond, ...algoOco, ...algoTrigger, ...algoMove].reduce(
      (acc, order) => {
        if (order.instType === EOkxInstruments.SPOT) {
          return [[...acc[0], order], acc[1]]
        } else if (order.instType === EOkxInstruments.SWAP) {
          return [acc[0], [...acc[1], order]]
        } else return [...acc]
      },
      [[], []] as [AlgoOrderListItem[], AlgoOrderListItem[]]
    )

    const updatedOrders: TOrderUpdateTuple[] = []

    if (shouldUpdateSpot) {
      updatedOrders.push([
        EAccountType.SPOT,
        {
          ordersArray: [
            ...ordersDataSpot.map(order => {
              return okxDataMappers.oprderMapper(order, EAccountType.SPOT)
            }),
            ...spoAlgoOrders.map(order => {
              return okxDataMappers.algoOrderMapper(order, EAccountType.SPOT)
            }),
          ],
        },
      ])
    }

    if (shouldUpdateSwap) {
      updatedOrders.push([
        EOkxInstruments.SWAP,
        {
          ordersArray: [
            ...ordersDataSwap.map(order => {
              return okxDataMappers.oprderMapper(order, EOkxInstruments.SWAP)
            }),
            ...swapAlgoOrders.map(order => {
              return okxDataMappers.algoOrderMapper(order, EOkxInstruments.SWAP)
            }),
          ],
        },
      ])
    }

    setOrdersUpdate(updatedOrders)
    isFirstApiCall.current = false
  }, [])

  const start = () => {
    apiCall()
    pollingInterval.current = setInterval(() => {
      apiCall()
    }, OKX_POLLING_INTERVAL)
  }

  const pause = () => {
    clearInterval(pollingInterval.current)
  }

  useEffect(() => {
    if (isChooseExchange) return

    if (shouldPausePoling) {
      pause()
      setIsExchangeProviderInitialized(true)
      return
    }

    if ((pollingInterval.current === undefined || !shouldPausePoling) && isInstrumentsLoaded) {
      start()
    }

    // wsClient.subscribe({
    //   channel: 'instruments',
    //   instType: 'FUTURES',
    // })
    // wsClient.subscribe({
    //   channel: 'balance_and_position',
    // })

    return () => {
      // TODO: close websocket
    }
  }, [apiCall, isChooseExchange, shouldPausePoling, isInstrumentsLoaded])

  useEffect(() => {
    if (reconnectCount.current <= OKX_SOCKET_RECONNECT_LIMIT || socketClient.current === undefined) {
      const wsClient = new WebSocket('wss://ws.okx.com:8443/ws/v5/private')

      const login = async () => {
        const signatureResponse = await exchangeApi.postSignWithReadOnly({
          queryString: '',
          requestBody: '',
          requestMethod: 'GET',
          requestPath: '/users/self/verify',
          timestampFormat: 'epochSeconds',
        })

        const timestampOriginalString = signatureResponse.data.headers['OK-ACCESS-TIMESTAMP']

        const timestampOriginal = new Date(Number(timestampOriginalString) * 1000).getTime()
        const timestampRounded = new BigNumber(timestampOriginal).dividedBy(1000).dp(0, 3)

        const loginMessage = {
          op: 'login',
          args: [
            {
              apiKey: signatureResponse.data.headers['OK-ACCESS-KEY'],
              passphrase: signatureResponse.data.headers['OK-ACCESS-PASSPHRASE'],
              timestamp: timestampOriginalString,
              sign: signatureResponse.data.headers['OK-ACCESS-SIGN'],
            },
          ],
        }
        wsClient.onmessage = handlerLogin
        wsClient.send(JSON.stringify(loginMessage))

        socketClient.current = wsClient
      }

      wsClient.onopen = ev => {
        login()
      }

      wsClient.onclose = () => {
        reconnectCount.current += 1
      }

      wsClient.onerror = () => {
        reconnectCount.current += 1
      }
    }

    if (reconnectCount.current > OKX_SOCKET_RECONNECT_LIMIT) {
      setBalanceUpdate({
        blancesData: [
          [
            EOkxInstruments.SWAP,
            {
              assetsDataArray: [],
              totalAssetsOutput: { USD: 0 },
            },
          ],
        ],
        skipUnknownAssets: balancesLoadRef.current,
      })

      setPositionsUpdate([
        [
          EOkxInstruments.SWAP,
          {
            positionsArray: [],
            unrealizedPnl: '0',
            totalMarginBalance: balancesReducedRef.current?.totalMarginBalance,
          },
        ],
      ])
    }

    return () => {
      socketClient.current?.close()
    }
  }, [])

  const handlerLogin = (event: MessageEvent<any>) => {
    const eventData = JSON.parse(event.data)
    if (eventData.event === 'login') {
      if (!socketClient.current) return

      socketClient.current.onmessage = event => {
        handleUpdateData(JSON.parse(event.data))
      }

      subscribePositions()
    }
  }

  const handleUpdateData = (data: any) => {
    if (data?.arg?.channel === 'account' && data?.data) {
      balancesReducedRef.current = data.data?.[0]?.details
        ? okxReducers.balanceReducer(data.data[0].details)
        : { totalAssetsOutput: { USD: 0 }, totalMarginBalance: '' }

      setBalanceUpdate({
        blancesData: [
          [
            EOkxInstruments.SWAP,
            {
              assetsDataArray: (data.data?.[0]?.details || []).map(okxDataMappers.balanceMapper),
              totalAssetsOutput: balancesReducedRef.current.totalAssetsOutput,
            },
          ],
        ],
        skipUnknownAssets: balancesLoadRef.current,
      })

      setPositionsUpdate([
        [
          EOkxInstruments.SWAP,
          {
            totalMarginBalance: balancesReducedRef.current?.totalMarginBalance,
          },
        ],
      ])
    }
    if (data?.arg?.channel === 'positions' && data?.data) {
      // @ts-ignore
      const filteredData: AccountPosition[] = data.data?.filter((position: AccountPosition) => position.pnl) || []
      setPositionsUpdate([
        [
          EOkxInstruments.SWAP,
          {
            positionsArray: filteredData.map((position: AccountPosition) => {
              return okxDataMappers.positionMapper(position, okxInstrumentProvider.instrumentsById)
            }),
            unrealizedPnl: okxReducers.positionsReducer(filteredData).unrealizedPnl,
            totalMarginBalance: balancesReducedRef.current?.totalMarginBalance,
          },
        ],
      ])
    }
  }

  // subscribe for updates (account, positions)
  const subscribePositions = () => {
    if (socketClient.current)
      socketClient.current.send(
        JSON.stringify({
          op: 'subscribe',
          args: [
            {
              channel: 'account',
            },
            {
              channel: 'positions',
              instType: 'SWAP',
            },
          ],
        })
      )
  }

  useEffect(() => {
    setExchangeStatus('succeeded')

    const balancesTimeout = setBalancesTimeout(balancesLoadRef)

    return () => {
      clearInterval(pollingInterval.current)
      clearTimeout(balancesTimeout)
    }
  }, [])

  return <>{children}</>
}
