import { memo } from 'react'
import { ErrorCode } from '@/backend/models/ErrorCodesDTO'
import { ErrorBase, ErrorBaseProps } from './ErrorBase/ErrorBase'
import { ErrorNotification, ErrorNotificationProps } from './ErrorNotification'

export type ErrorViewProps = Pick<
  ErrorBaseProps,
  | 'className'
  | 'account'
  | 'customHeader'
  | 'customText'
  | 'customComponent'
  | 'anotherAttemptButtonText'
  | 'onClickAnotherAttemptButton'
> &
  Pick<ErrorNotificationProps, 'onCloseClick'> & {
    /** Error code from backend */
    code: ErrorCode

    /**
     * show error as notification. @default = false
     * Applied only for 1001, 1002, 1003 error codes
     * */
    isNotification?: boolean
  }

/** Displays Error and solution variants */
export const ErrorView = memo<ErrorViewProps>(({ code, isNotification, ...props }) => {
  switch (code) {
    case 1001:
    case 1002:
    case 1003:
      return (
        <>
          {isNotification ? (
            <ErrorNotification {...props} isRefreshButtonVisible={true} />
          ) : (
            <ErrorBase {...props} isRefreshButtonVisible={true} />
          )}
        </>
      )

    case 2001:
    case 2002:
    case 3001:
    case 3002:
      return (
        <ErrorBase {...props} isAnotherAttemptButtonVisible={true} isCloseWindowVisible={true} isFullScreen={true} />
      )

    case 4001:
    case 4002:
    case 4003:
      return <ErrorNotification {...props} isRefreshButtonVisible={true} />

    case 5002:
    case 5003:
      return <ErrorNotification {...props} isClosable={true} />

    default:
      return null
  }
})
