export const handleMouseupWithoutSelection = () => {
  const selection =
    (window.getSelection
      ? window.getSelection()?.toString()
      : document.getSelection
      ? document.getSelection()?.toString()
      : '') || ''

  return !selection.trim()
}
