import {
  APIResponseV3WithTime,
  CategoryCursorListV5,
  LinearInverseInstrumentInfoV5,
  SpotInstrumentInfoV5,
} from 'bybit-api'
import { EBybitInstruments } from './types'

export type TBybitInstrumentByType = {
  [EBybitInstruments.spot]: SpotInstrumentInfoV5[]
  [EBybitInstruments.linear]: LinearInverseInstrumentInfoV5[]
}

export type TBybitInstrumentById = {
  [EBybitInstruments.spot]: Record<string, SpotInstrumentInfoV5>
  [EBybitInstruments.linear]: Record<string, LinearInverseInstrumentInfoV5>
}

export interface IBybitInstrumentsLoader<
  T extends unknown[] = LinearInverseInstrumentInfoV5[] | SpotInstrumentInfoV5[]
> {
  (params: { category: EBybitInstruments }): Promise<APIResponseV3WithTime<CategoryCursorListV5<T>>>
}

export class BybitInstruments {
  instrumentsByType: TBybitInstrumentByType = {
    spot: [],
    linear: [],
  }
  instrumentsById: TBybitInstrumentById = {
    spot: {},
    linear: {},
  }
  protected load: IBybitInstrumentsLoader

  constructor(load: IBybitInstrumentsLoader) {
    this.load = load
  }

  async loadInstruments() {
    const instruments = await Promise.all([
      this.load({ category: EBybitInstruments.spot }),
      this.load({ category: EBybitInstruments.linear }),
    ])

    const instrumentsSpot = instruments[0] as APIResponseV3WithTime<CategoryCursorListV5<SpotInstrumentInfoV5[]>>
    const instrumentsLinear = instruments[1] as APIResponseV3WithTime<
      CategoryCursorListV5<LinearInverseInstrumentInfoV5[]>
    >

    this.instrumentsByType['spot'] = instrumentsSpot.result.list as SpotInstrumentInfoV5[]
    this.instrumentsByType['linear'] = instrumentsLinear.result.list as LinearInverseInstrumentInfoV5[]

    instrumentsSpot.result.list.forEach(instrument => {
      this.instrumentsById['spot'][instrument.symbol] = instrument
    })

    instrumentsLinear.result.list.forEach(instrument => {
      this.instrumentsById['linear'][instrument.symbol] = instrument
    })

    return instruments
  }
}
