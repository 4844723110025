import React from 'react'

interface IUnionProps {
  className?: string
}

export const Union = (props: IUnionProps) => {
  const { className } = props

  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.95224 9.59619L9.36646 8.18198L6.18448 5L9.36646 1.81802L7.95224 0.403809L4.77026 3.58578L1.58829 0.403809L0.174073 1.81802L3.35605 5L0.174072 8.18198L1.58829 9.59619L4.77026 6.41422L7.95224 9.59619Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
