import { overviewActions } from '@/redux/overview/overview.slice'
import { useActions } from '@/utils'
import { EAccountType, EOrderControllerEvents, IAccountDataGrouped } from '@tigertrade/binance-ts'
import { useCallback, useEffect, useRef } from 'react'
import { TBinanceContextData } from '../BinanceProvider'
import type {
  TBalanceUpdateTuple,
  TOrderUpdateTuple,
  TPositionsUpdateTuple,
  TSetBalanceUpdatePayload,
  TSetOrderUpdatePayload,
  TSetPositionsUpdatePayload,
} from '@/redux/overview/overview.types'
import { setBalancesTimeout } from '@/utils/lib/setBalancesTimeout'
import { BINANCE_REQUEST_TIMEOUT } from '@/core/config/common'

interface IBinanceUtils {
  handleSpotUpdate: (data: IAccountDataGrouped) => void
  handleUsdmUpdate: (data: IAccountDataGrouped) => void
  handleCoinmUpdate: (data: IAccountDataGrouped) => void
  handleError: (controllers: TBinanceContextData) => void
}

const MAX_REINIT_ATTEMPTS = 3

export const useBinanceProviderUtils = (setBinanceHandlersInitialized: (value: boolean) => any): IBinanceUtils => {
  const { setPositionsUpdate, setBalanceUpdate, setOrdersUpdate, setOverviewStatus } = useActions(overviewActions)

  const positionsRef = useRef<TPositionsUpdateTuple[]>([])
  const ordersRef = useRef<TOrderUpdateTuple[]>([])
  const balancesRef = useRef<TBalanceUpdateTuple[]>([])
  const balancesLoadRef = useRef<boolean>(false)
  const reinitAttemptsCounter = useRef<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (positionsRef.current.length > 0) {
        setPositionsUpdate(positionsRef.current)
        positionsRef.current = []
      }

      if (ordersRef.current.length > 0) {
        setOrdersUpdate(ordersRef.current)
        ordersRef.current = []
      }

      if (balancesRef.current.length > 0) {
        setBalanceUpdate({
          blancesData: balancesRef.current,
          skipUnknownAssets: balancesLoadRef.current,
        })
        balancesRef.current = []
      }
    }, 100)

    const balancesTimeout = setBalancesTimeout(balancesLoadRef)

    return () => {
      clearInterval(interval)
      clearTimeout(balancesTimeout)
    }
  }, [])

  const handleDataUpdate = useCallback((account: EAccountType, data: IAccountDataGrouped) => {
    const balanceEvent: TSetBalanceUpdatePayload | undefined = data.changedState.balances
      ? {
          totalAssetsOutput: data.changedState.balances.totalAssetsOutput,
          assetsDataArray: data.changedState.balances.assetsDataArray,
        }
      : undefined

    const ordersEvent: TSetOrderUpdatePayload | undefined = data.changedState.ordersArray
      ? {
          ordersArray: data.changedState.ordersArray.map(order => {
            return {
              ...order,
              instrumentType: account,
              isAlgo: false,
              orderTypeOutput: order.type,
            }
          }),
        }
      : undefined

    let positionsEvent: TSetPositionsUpdatePayload | undefined = {
      ...(data.changedState.positionsArray
        ? {
            positionsArray: data.changedState.positionsArray,
          }
        : {}),
      ...(data.changedState.unrealizedPnl
        ? {
            unrealizedPnl: data.changedState.unrealizedPnl,
          }
        : {}),
      ...(data.changedState.totalMarginBalance
        ? {
            totalMarginBalance: data.changedState.totalMarginBalance,
          }
        : {}),
    }
    if (Object.keys(positionsEvent).length === 0) positionsEvent = undefined

    if (positionsEvent) positionsRef.current = [...positionsRef.current, [account, positionsEvent]]
    if (ordersEvent) ordersRef.current = [...ordersRef.current, [account, ordersEvent]]
    if (balanceEvent) balancesRef.current = [...balancesRef.current, [account, balanceEvent]]
  }, [])

  const handleSpotUpdate = useCallback(
    (data: IAccountDataGrouped) => {
      handleDataUpdate(EAccountType.SPOT, data)
    },
    [handleDataUpdate]
  )

  const handleUsdmUpdate = useCallback(
    (data: IAccountDataGrouped) => {
      handleDataUpdate(EAccountType.USDT_FUTURE, data)
    },
    [handleDataUpdate]
  )

  const handleCoinmUpdate = useCallback(
    (data: IAccountDataGrouped) => {
      handleDataUpdate(EAccountType.COIN_FUTURE, data)
    },
    [handleDataUpdate]
  )

  const handleError = useCallback(
    (controllers: TBinanceContextData) => {
      controllers.SPOT.off(EOrderControllerEvents.UPDATE, handleSpotUpdate)
      controllers.USDT_FUTURE.off(EOrderControllerEvents.UPDATE, handleUsdmUpdate)
      controllers.COIN_FUTURE.off(EOrderControllerEvents.UPDATE, handleCoinmUpdate)
      controllers.SPOT.off(EOrderControllerEvents.ERROR, handleError)
      controllers.USDT_FUTURE.off(EOrderControllerEvents.ERROR, handleError)
      controllers.COIN_FUTURE.off(EOrderControllerEvents.ERROR, handleError)

      controllers.SPOT.stop()
      controllers.USDT_FUTURE.stop()
      controllers.COIN_FUTURE.stop()

      setOverviewStatus('loading')

      if (reinitAttemptsCounter.current >= MAX_REINIT_ATTEMPTS) return

      reinitAttemptsCounter.current += 1

      setTimeout(() => {
        setBinanceHandlersInitialized(false)
        controllers.SPOT.init()
        controllers.USDT_FUTURE.init()
        controllers.COIN_FUTURE.init()
      }, BINANCE_REQUEST_TIMEOUT)
    },
    [handleSpotUpdate, handleUsdmUpdate, handleCoinmUpdate]
  )

  return {
    handleSpotUpdate,
    handleUsdmUpdate,
    handleCoinmUpdate,
    handleError,
  }
}
