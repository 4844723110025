import { FC, useCallback, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { Input, InputSize, Button } from '@/ui/kit'
import { VoucherAddSuccess } from './components/VoucherAddSuccess'
import { VoucherAddError } from './components/VoucherAddError'
import { useApi } from '@/ui/molecules/VoucherAddForm/hooks/useApi'
import s from './style.module.scss'

const VoucherAddForm: FC = () => {
  const { errorCode, voucher, applyVoucherCode, reset } = useApi()

  const [code, setCode] = useState('')

  useEffect(() => {
    if (voucher) {
      setCode('')
    }
  }, [voucher])

  const handleApplyCode = useCallback(async () => {
    await applyVoucherCode(code)
  }, [applyVoucherCode, code])

  return (
    <>
      <div className={s.root}>
        <TitleH3 label={t({ message: 'Add voucher', id: 'voucher.form.heading' })} />
        <div className={s.text}>
          {t({
            message: 'Vouchers give you access to various',
            id: 'voucher.form.description-1',
          })}
          <br />
          {t({
            message: 'bonuses at Tiger.Trade Broker',
            id: 'voucher.form.description-2',
          })}
        </div>
        <Input
          size={InputSize.Small}
          label={t({ message: 'Enter voucher code', id: 'voucher.form.label' })}
          value={code}
          setValue={setCode}
          containerClassName={s.input}
        />
        <Button.Accent
          size={Button.Size.Medium}
          label={t({ message: 'Apply', id: 'core.button.apply' })}
          disabled={code.length < 3}
          onClick={handleApplyCode}
          className={s.button}
        />
      </div>
      <VoucherAddSuccess isOpen={!!voucher} voucher={voucher} onClose={reset} />
      <VoucherAddError isOpen={!!errorCode} errorCode={errorCode} onClose={reset} />
    </>
  )
}

export { VoucherAddForm }
