import React, { FC, Suspense, memo } from 'react'
import { CoinVariant } from '@/backend/types'
import { SVG } from '@/assets/svg'

interface IconFactoryProps {
  type: string
  width?: string
  height?: string
  className?: string
  skipDefaultIcon?: string
}

const IconPlaceholder: FC<Omit<IconFactoryProps, 'type'>> = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" />
  )
}

export const IconFactory: FC<IconFactoryProps> = memo(props => {
  const { type, skipDefaultIcon, ...args } = props

  switch (type as CoinVariant) {
    case 'AAVE':
      const AAVE = React.lazy(() => import('./Aave'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <AAVE {...args} />
        </Suspense>
      )
    case 'AKRO':
      const AKRO = React.lazy(() => import('./Akropolis'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <AKRO {...args} />
        </Suspense>
      )
    case 'BNB':
      const BNB = React.lazy(() => import('./Bnb'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <BNB {...args} />
        </Suspense>
      )
    case 'XRP':
      const XRP = React.lazy(() => import('./XRP'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <XRP {...args} />
        </Suspense>
      )
    case 'USDT':
      const USDT = React.lazy(() => import('./Tether'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <USDT {...args} />
        </Suspense>
      )
    case 'USDC':
      const USDC = React.lazy(() => import('./USDC'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <USDC {...args} />
        </Suspense>
      )
    case 'BUSD':
      const BUSD = React.lazy(() => import('./Busd'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <BUSD {...args} />
        </Suspense>
      )
    case 'BTC':
      const BTC = React.lazy(() => import('./WrappedBitcoin'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <BTC {...args} />
        </Suspense>
      )
    case 'FIDA':
      const FIDA = React.lazy(() => import('./Bonfida'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <FIDA {...args} />
        </Suspense>
      )
    case 'ETH':
      const ETH = React.lazy(() => import('./Ethereum'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <ETH {...args} />
        </Suspense>
      )
    case 'LINK':
      const LINK = React.lazy(() => import('./Chainlink'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <LINK {...args} />
        </Suspense>
      )
    case 'TRX':
      const TRX = React.lazy(() => import('./Trx'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <TRX {...args} />
        </Suspense>
      )
    case 'CRV':
      const CRV = React.lazy(() => import('./Crv'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <CRV {...args} />
        </Suspense>
      )
    case 'AXS':
      const AXS = React.lazy(() => import('./Axs'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <AXS {...args} />
        </Suspense>
      )
    case 'EOS':
      const EOS = React.lazy(() => import('./Eos'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <EOS {...args} />
        </Suspense>
      )
    case 'LTC':
      const LTC = React.lazy(() => import('./Ltc'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <LTC {...args} />
        </Suspense>
      )
    case 'XTZ':
      const XTZ = React.lazy(() => import('./Xtz'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <XTZ {...args} />
        </Suspense>
      )
    case 'BCH':
      const BCH = React.lazy(() => import('./Bch'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <BCH {...args} />
        </Suspense>
      )
    case 'ICX':
      const ICX = React.lazy(() => import('./Icx'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <ICX {...args} />
        </Suspense>
      )
    case 'ALGO':
      const ALGO = React.lazy(() => import('./Algo'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <ALGO {...args} />
        </Suspense>
      )
    case 'ETC':
      const ETC = React.lazy(() => import('./Etc'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <ETC {...args} />
        </Suspense>
      )
    case 'RUNE':
      const RUNE = React.lazy(() => import('./Rune'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <RUNE {...args} />
        </Suspense>
      )
    case 'FIL':
      const FIL = React.lazy(() => import('./Fil'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <FIL {...args} />
        </Suspense>
      )
    case 'APE':
      const APE = React.lazy(() => import('./Ape'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <APE {...args} />
        </Suspense>
      )
    case 'VET':
      const VET = React.lazy(() => import('./Vet'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <VET {...args} />
        </Suspense>
      )
    case 'ZIL':
      const ZIL = React.lazy(() => import('./Zil'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <ZIL {...args} />
        </Suspense>
      )
    case 'KNC':
      const KNC = React.lazy(() => import('./Knc'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <KNC {...args} />
        </Suspense>
      )
    case 'XMR':
      const XMR = React.lazy(() => import('./Xmr'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <XMR {...args} />
        </Suspense>
      )
    case 'EGLD':
      const EGLD = React.lazy(() => import('./Egld'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <EGLD {...args} />
        </Suspense>
      )
    case 'OP':
      const OP = React.lazy(() => import('./Op'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <OP {...args} />
        </Suspense>
      )
    case 'CHZ':
      const CHZ = React.lazy(() => import('./Chz'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <CHZ {...args} />
        </Suspense>
      )
    case 'DOGE':
      const DOGE = React.lazy(() => import('./Doge'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <DOGE {...args} />
        </Suspense>
      )
    case 'APT':
      const APT = React.lazy(() => import('./Apt'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <APT {...args} />
        </Suspense>
      )
    case 'UNI':
      const UNI = React.lazy(() => import('./Uni'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <UNI {...args} />
        </Suspense>
      )
    case 'THETA':
      const THETA = React.lazy(() => import('./Theta'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <THETA {...args} />
        </Suspense>
      )
    case 'XLM':
      const XLM = React.lazy(() => import('./Xlm'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <XLM {...args} />
        </Suspense>
      )
    case 'SOL':
      const SOL = React.lazy(() => import('./Solana'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <SOL {...args} />
        </Suspense>
      )
    case 'LUNA':
      const LUNA = React.lazy(() => import('./Luna'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <LUNA {...args} />
        </Suspense>
      )
    case 'FTM':
      const FTM = React.lazy(() => import('./Ftm'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <FTM {...args} />
        </Suspense>
      )
    case 'SAND':
      const SAND = React.lazy(() => import('./Sand'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <SAND {...args} />
        </Suspense>
      )
    case 'MANA':
      const MANA = React.lazy(() => import('./Mana'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <MANA {...args} />
        </Suspense>
      )
    case 'AVAX':
      const AVAX = React.lazy(() => import('./Avax'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <AVAX {...args} />
        </Suspense>
      )
    case 'GALA':
      const GALA = React.lazy(() => import('./Gala'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <GALA {...args} />
        </Suspense>
      )
    case 'MATIC':
      const MATIC = React.lazy(() => import('./Matic'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <MATIC {...args} />
        </Suspense>
      )
    case 'NEAR':
      const NEAR = React.lazy(() => import('./Near'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <NEAR {...args} />
        </Suspense>
      )
    case 'ATOM':
      const ATOM = React.lazy(() => import('./Atom'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <ATOM {...args} />
        </Suspense>
      )
    case 'ADA':
      const ADA = React.lazy(() => import('./Ada'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <ADA {...args} />
        </Suspense>
      )
    case 'ROSE':
      const ROSE = React.lazy(() => import('./Rose'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <ROSE {...args} />
        </Suspense>
      )
    case 'DOT':
      const DOT = React.lazy(() => import('./Dot'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <DOT {...args} />
        </Suspense>
      )
    case 'GMT':
      const GMT = React.lazy(() => import('./Gmt'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <GMT {...args} />
        </Suspense>
      )
    case 'ENS':
      const ENS = React.lazy(() => import('./Ens'))
      return (
        <Suspense fallback={<IconPlaceholder />}>
          <ENS {...args} />
        </Suspense>
      )
    default:
      return skipDefaultIcon ? null : <SVG.CryptoIcons.TT {...args} />
  }
})
