import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { TExchangesAvailable } from '@/core/constants'
import { appActions } from '@/redux/app/app.slice'
import { overviewActions } from '@/redux/overview/overview.slice'
import { MobileTabs } from '@/ui/atoms/MobileTabs/MobileTabs'
import { DropDownItem } from '@/ui/kit/Dropdown/Dropdown'
import { MobileProfile } from '@/ui/organisms/MobileProfile/MobileProfile'
import { useActions } from '@/utils'
import { useExchangesSource } from '@/utils/hooks/useExchangesSource'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { useOverview } from '@/utils/hooks/useOverview'
import { MobileAssets } from './components/MobileAssets/MobileAssets'
import { MobileTabButtons } from './components/MobileTabButtons/MobileTabButtons'
import s from './style.module.scss'

type TOptionExchange = DropDownItem<TExchangesAvailable>

export const AssetsMobile: FC = () => {
  const { accountTypeForInstrument } = useOverview()
  const { exchangeType } = useExchageType()
  const { setExchangeType } = useActions(appActions)
  const { setAccountType } = useActions(overviewActions)

  const exchanges = useExchangesSource()

  const [selectedOption, setSelectedOption] = useState<TOptionExchange | null>(
    exchanges.find(exchange => {
      return exchange.value === exchangeType
    }) || null
  )

  const setSelectedCallback = useCallback((value: TOptionExchange) => {
    if (value.value === exchangeType) return
    setSelectedOption(value)
    setExchangeType(value.value)
  }, [])

  useEffect(() => {
    if (accountTypeForInstrument) setAccountType(accountTypeForInstrument)
  }, [])

  return (
    <div className={s.mobileRoot}>
      <div className={s.tabs}>
        <MobileTabs options={exchanges} selectedOption={selectedOption} setSelectedOption={setSelectedCallback} />
      </div>

      <MobileProfile />
      <MobileTabButtons className={s.tabButtons} />
      <MobileAssets />
    </div>
  )
}
