export enum DataTestIds {
  // common
  TableCell = 'table-cell',
  CashbackTrxPercentage = 'cashback-trx-percentage',
  CashbackTrxDate = 'cashback-trx-date',
  CashbackTotal = 'cashback-total',
  CashbackTrx = 'cashback-trx',
  CashbackTrxAmount = 'cashback-trx-amount',
  CashbackTrxTradingVolume = 'cashback-trx-trading-volume',
  CashbackTrxCommission = 'cashback-trx-commission',
  CashbackToday = 'cashback-today',
  AddressCopyButton = 'address-copy-btn',
  DropDownFilter = 'drop-down-filter',
  LoaderPositions = 'loader-positions',
  LoaderOrders = 'loader-orders',
  BtnSuffix = '-btn',
  AddBtnSuffix = '-add-btn',
  ChipsSuffix = '-chips',
  ChipSuffix = '-chip',
  CloseBtnSuffix = '-close-btn',
  DecreaseSuffix = '-dec',
  IncreaseSuffix = '-inc',
  HintSuffix = '-hint',
  QButtonSuffix = '-q-b',
  PopupFull = 'full-popup',
  InputAlert = 'input-alert',

  //header
  HeaderWallet = 'h-wallet',
  HeaderOrdersAndPositions = 'h-orders-positions',
  HeaderCashback = 'h-cashback',
  HeaderLeverage = 'h-lev',
  HeaderApi = 'h-api',
  HeaderHistory = 'h-his',
  HeaderTrades = 'h-tr',
  HeaderDashboard = 'h-dash',
  HeaderSummary = 'h-su',
  HeaderTigerToken = 'h-tiger-token',
  HeaderDocs = 'h-docs',
  HeaderChangeVersion = 'header-change-version',
  KycStatus = 'kyc-status',
  KycLevel = 'kyc-level',
  UserAvatar = 'user-avatar',
  TeamAvatar = 'team-avatar',
  LogoutBtn = 'logout-btn',
  LinkProfile = 'link-profile',
  LinkReferral = 'link-referral',

  // SheetDialog
  CloseSheet = 'close-sheet',

  // burger menu
  BurgerAssetsBtn = 'burger-assets',

  //api key page
  ApiPageCreateApiKeyButton = 'create-api-key-btn',
  ApiKeyItem = 'api-key-item',

  ApiKeyItemRemoveApiKeyButton = 'remove-api-key-btn',
  ApiKeyRemoveSubmit = 'api-key-remove-submit',
  ApiKeyRemoveCancel = 'api-key-remove-cancel',

  CheckboxRead = 'checkbox-read',
  CheckboxSpot = 'checkbox-spot',
  CheckboxFutures = 'checkbox-futures',
  ApiKeyNameTitle = 'api-key-name-title',
  ApiKeyNameTextField = 'api-key-name-text-field',
  ApiKeyKeyTextField = 'api-key-key-text-field',
  ApiKeyKeyCopyButton = 'api-key-key-copy-btn',
  ApiKeySecretKeyTextField = 'api-key-secret-text-field',
  ApiKeySecretCopyButton = 'api-key-secret-copy-btn',
  ApiKeyIpAddressesTextField = 'api-key-ip-addresses-text',
  ApiKeyItemAccessesTextField = 'api-key-accesses-text',
  ApiKeyItemEditApiKeyButton = 'edit-api-key-btn',
  ApiKeyItemKeyWildcard = 'api-key-wildcard',
  ApiKeyItemCopyButton = 'api-key-item-copy-btn',
  ApiKeyItemDate = 'api-key-item-date',
  ApiKeyDoneButton = 'api-key-done-btn',
  ApiCopyAndSaveMessage = 'api-copy-and-save-msg',

  CreateApiCreateButton = 'create-api-create-btn',
  CreateApiCancelButton = 'create-api-cancel-btn',
  EditApiKeyDoneButton = 'edit-api-key-done-btn',
  EditApiKeyCancelButton = 'edit-api-key-cancel-btn',

  //leverage page
  LeveragePageSearchField = 'lp-search-field',
  LeveragePageSearchDropdown = 'lp-search-dropdown',

  LeveragePageSelectAllButton = 'lp-select-all-btn',
  LeveragePageAllLeverageInput = 'lp-all-lev-input',
  LeveragePageAllMarginDropdown = 'lp-all-margin-dropdown',
  LeveragePageAllLeverageApplyButton = 'lp-all-lev-apply-btn',
  LeveragePageDeselectAllButton = 'lp-deselect-all-btn',
  LeveragePageBusyState = 'lp-busy-state',
  LeveragePageMaxLeverageBusyState = 'lp-max-leverage-state',

  LeveragePageAssetItem = 'lpi',
  LeveragePageAssetName = 'lpi-name',
  LeveragePageAssetSettings = 'lpi-settings',
  LeveragePageAssetLeverage = 'lpi-lev',
  LeveragePageAssetLeverageMax = 'lpi-lev-max',
  LeveragePageAssetPositionMax = 'lpi-position-max',
  LeveragePageAssetMarginDropdown = 'lpi-margin-toggle',
  LeveragePageAssetMaxLevelWarning = 'lpi-ml-warning',
  EmptySearchResult = 'empty-search-result',

  //wallet page
  AccountBalanceNoCoins = 'balance-no-coins',
  AccountBalanceValue = 'balance-value',
  AccountInfoHistoryButton = 'info-his-btn',
  AccountInfoBalance = 'info-balance',

  AccountInfoCashbackPercent = 'info-cashback-today',
  AccountInfoCashbackNextPercent = 'next-percent',
  AccountInfoCashbackLeftToNext = 'left-to-next',
  AccountInfoCashbackExpirationDate = 'exp-date',
  // wallet right part
  WalletTab = 'wallet-tab',
  WalletEstimatedBalance = 'wallet-est-bln',
  WalletEstimatedBalanceText = 'wallet-est-balance-text',
  WalletViewAssetsButton = 'wallet-view-assets-btn',
  WalletTabDepositButton = 'wallet-tab-deposit-btn',
  WalletTabTransferButton = 'wallet-tab-transfer-btn',
  WalletTabWithdrawButton = 'wallet-tab-withdraw-btn',

  WalletUnrealizedPnl = 'wallet-upnl',
  WalletUnrealizedPnlText = 'wallet-upnl-text',

  WalletMarginBalance = 'margin-bln',
  WalletMarginBalanceText = 'margin-bln-text',
  //header
  PositionsTab = 'positions-tab',
  OrdersTab = 'orders-tab',
  SortDropdown = 'sort-dropdown',
  SortButton = 'sort-btn',
  CloseAllButton = 'close-all-btn',

  PositionListItem = 'pos-list-item',
  OrderListItem = 'order-list-item',
  //positions
  PositionSymbol = 'pos-symbol',
  PositionSide = 'pos-side',
  PositionDate = 'pos-date',
  PositionEntryPrice = 'entry-price',
  PositionAmount = 'pos-amount',
  PositionLiquidationPrice = 'pos-liq-price',
  PositionUnrealizedPnl = 'pos-upnl',
  ClosePositionButton = 'close-pos-btn',
  //orders
  OrderSymbol = 'order-symbol',
  OrderSide = 'order-side',
  OrderType = 'order-type',
  OrderUpdateTime = 'order-update-time',
  OrderPrice = 'order-price',
  OrderAmount = 'order-amount',
  OrderStatus = 'order-status',
  CloseOrderButton = 'close-order-btn',
  //assets
  AssetLeftItem = 'asset-li',
  AssetLeftItemCount = 'asset-li-count',
  AssetLeftItemAmount = 'asset-li-amount',
  TopUpTransfer = 'asset-top-up-transfer',
  TopUpDeposit = 'asset-top-up-deposit',
  AssetItem = 'asset-item',
  AssetItemCurrency = 'asset-item-currency',
  AssetItemCurrencyFull = 'asset-item-currency-full',
  AssetItemAmount = 'asset-item-amount',
  AssetItemAmountUsdt = 'asset-item-amount-usdt',
  AssetItemSellButton = 'asset-item-sell-btn',
  AssetItemProgressBar = 'asset-item-progress-bar',
  AssetItemPercent = 'asset-item-percent',

  SellAssetPopupClose = 'sell-asset-popup-close',
  SellAssetPopupSell = 'sell-asset-popup-sell',
  SellAssetPopupContinueCancel = 'sell-asset-popup-cc',

  //history page
  HistoryModalHeader = 'hm-header',
  HistoryModalAmount = 'hm-amount',
  HistoryModalStatus = 'hm-status',
  HistoryModalDate = 'hm-date',
  HistoryModalDatePeriod = 'hm-date-period',
  HistoryModalTrxId = 'hm-trx-id',
  HistoryModalTigerTrxId = 'hm-tigr-trx-id',
  HistoryModalTrxTo = 'hm-to',
  HistoryModalAddrTo = 'hm-addr-to',
  HistoryModalTrxFrom = 'hm-from',
  HistoryModalAddrFrom = 'hm-addr-from',
  HistoryModalNetwork = 'hm-network',
  HistoryModalClose = 'hm-close',

  HistoryModalWindow = 'hm-window',
  HistoryModalIcon = 'hm-icon',
  HistoryModalFrom = 'hm-from',
  HistoryModalAddrToCopyButton = 'hm-addr-to-copy-btn',

  HistoryTrx = 'h-trx',
  HistoryTrxType = 'h-trx-type',
  HistoryTrxStatus = 'h-trx-status',
  HistoryTrxDate = 'h-trx-date',
  HistoryTrxAmount = 'h-trx-amount',

  //kyc page
  KYCFormFirstNameInput = 'kf-fname-input',
  KYCFormLastNameInput = 'kf-lname-input',
  KYCFormBirthDateInput = 'kf-bd-input',
  KYCFormNationalitiesSelect = 'kf-nat-select',
  KYCFormCountrySelect = 'kf-c-select',
  KYCFormStateOrProvinceInput = 'kf-state-input',
  KYCFormCityInput = 'kf-city-input',
  KYCFormResidentialAddressInput = 'kf-address-input',
  KYCFormPostalCodeInput = 'kf-pc-input',
  KYCFormSubmitButton = 'kf-submit-btn',
  //profile page
  ProfileKycButton = 'kyc-btn',

  //team page
  CopyUrlButton = 'copy-url-button',
  MembersPageNavigation = 'members-page-navigation',
  MembersPageSearchField = 'members-page-search-navigation',
  MemberCashbackPercentSet = 'members-cashback-percent-set',

  //orders and positions
  OPCloseAllButton = 'op-close-all-btn',
  OPCloseAllPositionsButton = 'op-close-all-pos-btn',
  OPCloseAllOrdersButton = 'op-close-all-orders-btn',
  OPClosingOrders = 'op-closing-orders',
  OPClosingPositions = 'op-closing-positions',
  OPEmptyOrders = 'op-empty-orders',
  OPEmptyPositions = 'op-empty-positions',
  OPPositionListItem = 'op-p-li',
  OPPositionCloseButton = 'op-p-close-btn',
  OPOrderListItem = 'op-o-li',
  OPOrderCloseButton = 'op-o-close-btn',

  //transaction page
  TransactionTab = 'transaction-tab',

  CoinSelector = 'coin-toggle',
  SelectorDropdown = 'selector-dropdown',
  SelectorDropdownCell = 'selector-dropdown-cell',
  CoinSearchInput = 'coin-toggle-search',
  CoinSearchInputAlert = 'coin-search-input-alert',
  NetworkSelector = 'network-toggle',
  NetworkSelectorError = 'network-toggle-error',
  AmountInput = 'withdraw-amount-input',
  AmountAvailableField = 'amount-available-field',
  AmountMaxButton = 'amount-max-btn',

  DepositAlertDescription = 'dp-alert-description',
  DepositInfoDescription = 'dp-info-description',
  DepositAddressSection = 'dp-address-section',
  DepositAddressTitle = 'dp-address-title',
  DepositAddress = 'dp-address',
  DepositAddressQRButton = 'dp-address-qr-btn',
  DepositAddressQRImage = 'dp-address-qr-image',
  DepositAddressPopupQRCloseButton = 'dp-address-qr-close-btn',

  WithdrawAddressInput = 'withdraw-address-input',
  WithdrawConfirmButton = 'withdraw-confirm-input',
  WithdrawSendingAmount = 'withdraw-sending-amount',
  WithdrawReceiveAmount = 'withdraw-receive-amount',
  WithdrawFeeAmount = 'withdraw-fee-amount',
  ModalConfirmButton = 'modal-confirm-btn',
  ModalConfirmTitle = 'modal-confirm-title',
  ModalConfirmInfo = 'modal-confirm-info',
  ModalCancelButton = 'modal-cancel-btn',
  ModalTwoFAInput = 'modal-two-fa-input',
  ModalTwoFAAlert = 'modal-two-fa-alert',
  ModalTigerId = 'modal-id',
  ModalReturnButton = 'modal-return-btn',

  TransferAccountTypeSectionFrom = 'transfer-type-section-from',
  TransferAccountTypeSectionTo = 'transfer-type-section-to',
  TransferConfirmButton = 'transfer-confirm-btn',
  TransferSwitchButton = 'transfer-switch-btn',
  InformationWarningMessage = 'information-warning-msg',
  NoCoinsField = 'no-coins-field',
  ExplanationField = 'explanation-field',

  LimitWidget = 'limit-popup',
  LimitWidgetButton = 'limit-btn',
  LimitFullPopup = 'limit-full-popup',
  LimitPopupCloseButton = 'limit-close-btn',
  LimitPopupTitle = 'limit-title',
  LimitPopupProgressBar = 'limit-progress-bar',
  LimitPopupListDescriptions = 'limit-list-items',

  // user
  LogoutButton = 'logout-btn',

  //addresses page
  ConfirmAddressFormButton = 'confirm-address-form-button',
}
