import { createSlice } from '@reduxjs/toolkit'
import { partnerAsyncActions } from './partner.actions'
import { initialState } from './partner.state'
import { resetPartnerData, partnerDataReducer, awardKeysFromPartners } from './partner.utils'
import { EPartners } from './partner.types'

export const slice = createSlice({
  name: `[Tiger Trade Account partner]`,
  initialState,
  reducers: {
    resetPartnerData(state) {
      state = Object.assign(state, resetPartnerData(state))
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerAsyncActions.GetPartnersListTC.pending, (state, action) => {
        state.listStatus = 'loading'
      })
      .addCase(partnerAsyncActions.GetPartnersListTC.rejected, (state, action) => {
        state.listStatus = 'failed'
      })
      .addCase(partnerAsyncActions.GetPartnersListTC.fulfilled, (state, action) => {
        state.listStatus = 'succeeded'
        state.partners = action.payload
        state.partnersByAwardKey = awardKeysFromPartners(state.partners)
      })
      .addCase(partnerAsyncActions.GetPartnerDataTC.pending, (state, action) => {
        state = resetPartnerData(state)
        state.dataStatus = 'loading'
      })
      .addCase(partnerAsyncActions.GetPartnerDataTC.rejected, (state, action) => {
        state.dataStatus = 'failed'
        state.hasPartnerAccess = false
      })
      .addCase(partnerAsyncActions.GetPartnerDataTC.fulfilled, (state, action) => {
        state.dataStatus = 'succeeded'
        state = Object.assign(state, partnerDataReducer(state, action.payload, action.meta.arg as EPartners))
      })
      .addCase(partnerAsyncActions.ClaimPartnerRewardTC.pending, (state, action) => {
        state.claimStatus = 'loading'
      })
      .addCase(partnerAsyncActions.ClaimPartnerRewardTC.rejected, (state, action) => {
        state.claimStatus = 'failed'
      })
      .addCase(partnerAsyncActions.ClaimPartnerRewardTC.fulfilled, (state, action) => {
        state.claimStatus = 'succeeded'
        state.awardClaimed = true
      })
  },
})

export const partnerActions = {
  ...slice.actions,
  ...partnerAsyncActions,
}

export const partnerReducer = slice.reducer
