import React from 'react'

export const Withdraw = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <linearGradient id="linear-gradient">
        <stop offset="0%" stopColor="#E571FF" />
        <stop offset="100%" stopColor="#FF8933" />
      </linearGradient>
      <path
        d="M4.21238 1.25415C4.21238 1.25415 9.41331 1.25415 12.7458 1.25415M12.7458 1.25415C12.7458 4.58667 12.7458 9.78759 12.7458 9.78759M12.7458 1.25415C7.98509 6.01489 1.25415 12.7458 1.25415 12.7458"
        stroke={'url(#linear-gradient)'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
