export interface LinkedListNode<TValue = unknown> {
  value: TValue | null
  next: null | LinkedListNode
  prev: null | LinkedListNode
}

/*==== HELPERS AREA =====*/
//todo: add compare callback
/**
 * Поиск ноды по значению
 * @param targetValue {Tvalue} - Искомое значение
 * @param head {LinkedListNode} - root нода
 */
const findNode = <TValue = unknown>(targetValue: TValue, head: LinkedListNode): LinkedListNode | null => {
  if (head.value === targetValue) return head
  let currentNode = head
  let founddedNode = null
  while (currentNode.next) {
    if (currentNode.next.value === targetValue) {
      founddedNode = currentNode.next
      break
    } else {
      currentNode = currentNode.next
    }
  }
  return founddedNode
}

/**
 * Получить последнюю ноду
 * @param head {LinkedListNode} - root нода
 */
const getLastNode = (head: LinkedListNode): LinkedListNode => {
  let targetNode = head
  while (targetNode.next) {
    targetNode = targetNode.next
  }
  return targetNode
}

/**
 * Удаление ноды по ее значению
 * @param value {Tvalue} - значение ноды
 * @param head {LinkedListNode} - root нода
 */
const removeNode = <TValue = unknown>(value: TValue, head: LinkedListNode) => {
  if (head.value === value && head.next) {
    head.value = head.next.value
    head.next = head.next.next
  }
  if (head.value === value && !head.next) {
    head = { value: null, next: null, prev: null }
  }

  const foundNode = findNode(value, head)
  if (!foundNode?.next && foundNode?.prev) {
    foundNode.prev.next = null
  }

  if (foundNode?.prev && foundNode?.next) {
    foundNode.prev.next = foundNode.next
    foundNode.next.prev = foundNode.prev
  }
  return head
}
/*==== HELPERS AREA  END =====*/

/**
 * Двувязанный список
 */
export class DoublyLinkedList<TValue = unknown> {
  head: LinkedListNode<TValue> = { value: null, next: null, prev: null }

  constructor(value?: TValue | TValue[]) {
    if (value) {
      if (Array.isArray(value)) {
        this.head = { value: value[0], prev: null, next: null }
        value.slice(1).forEach(value => {
          this.appendNode(value)
        })
      } else {
        this.head = { value, next: null, prev: null }
      }
    }
  }

  /**
   * Добавление элементов в список
   * @param value {TValue} - Значение элемента
   */
  appendNode<TValue = unknown>(value: TValue) {
    const lastNode = getLastNode(this.head)
    lastNode.next = { value: value, next: null, prev: lastNode }
  }

  /**
   * Поиск по значению
   * @param value {TValue} - искомое значение
   */
  findNodeByValue<TValue = unknown>(value: TValue) {
    return findNode(value, this.head)
  }

  /**
   * Удаление ноды по значению
   * @param value {TValue} - значение ноды которое нужно удалить
   */
  removeNodeByValue<TValue = unknown>(value: TValue) {
    return removeNode(value, this.head)
  }
}
