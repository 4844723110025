import React, { SVGProps } from 'react'

interface PlusProps extends SVGProps<SVGSVGElement> {
  width?: string
  height?: string
  className?: string
}

export const Plus = (props: PlusProps) => {
  const { height = '14', width = '14', ...other } = props

  return (
    <svg width={width} height={height} {...other} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99963 1.34315L6.99963 12.6569"
        stroke="#FAFAFA"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6565 7L1.34277 7"
        stroke="#FAFAFA"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
