import { FC } from 'react'
import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'
import style from './style.module.scss'
import { useMobileSizeDetect } from '@/utils'
import clsx from 'clsx'

const LoaderContent: FC = () => {
  return (
    <div className={style.loaderWrapper}>
      <SVG.Loaders.Default className={style.loader} width={29} height={29} />
      <div className={style.msg}>{t({ message: 'We’re loading data.', id: 'core.weareLoadingData' })}</div>
      <div className={style.msg}>{t({ message: 'Stay with us', id: 'core.staywithUs' })}</div>
    </div>
  )
}

const LoaderOverlay: FC = props => {
  const [isMobile] = useMobileSizeDetect()

  return (
    <div
      className={clsx({
        [style.overlay]: !isMobile,
        [style.overlayMobile]: isMobile,
      })}
    >
      {props.children}
    </div>
  )
}

export const Loader = {
  Overlay: LoaderOverlay,
  Content: LoaderContent,
}
