import { TradesQueryParams } from '@/backend/types'
import { isArray, isObject } from '@/ui/organisms/Chart/KLineChartFork/common/utils/typeChecks'

export function getDefaultTradesFilters(): TradesQueryParams {
  return {
    page: 1,
    sortDesc: true,
    itemsPerPage: 20,
    trade_time: 'open_time',
    state: 2,
    durationType: '30d',
  }
}

export type TableColumnValues = {
  label: string
  hintText?: string
}

export type TableColumnKeys =
  | 'api_key_id'
  | 'symbol'
  | 'category_id'
  | 'extra_info'
  | 'tags'
  | 'open_time'
  | 'leverage'
  | 'max_win_percent'
  | 'max_loose_percent'
  | 'avg_price_entry'
  | 'close_time'
  | 'avg_price_exit'
  | 'duration'
  | 'side'
  | 'percent'
  | 'profit_deposit'
  | 'funding'
  | 'realized_pnl'
  | 'net_profit'
  | 'qty'
  | 'peak_qty'
  | 'volume'
  | 'commission'

export interface UIDataContent {
  filters?: TradesQueryParams
}

export const prepareUiData = (uidata: UIDataContent): Partial<Record<keyof UIDataContent, unknown>> => {
  const keys = Object.keys(uidata) as (keyof UIDataContent)[]
  const data: Partial<Record<keyof UIDataContent, unknown>> = {}

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]

    if (uidata[key] === undefined) {
      continue
    }

    if (isObject(uidata[key]) && !isArray(uidata[key])) {
      data[key] = prepareUiData(uidata[key] as {})
      continue
    }

    data[key] = uidata[key]
  }

  return data
}
