import { t } from '@lingui/macro'
import s from './style.module.scss'
import { FC } from 'react'
import { Button, ButtonSize, CopyIcon } from '@/ui/kit'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { copyToClipboard } from '@/utils'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { referralLinkCreator, urls } from '@/router/urls'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { TitleH4 } from '@/ui/atoms/TitleH4'

const TeamWidgetManager: FC = () => {
  const {
    userData: { teamManagerInfo },
  } = useTeamData()

  const { gainByMonth, numberOfMembers, teamCode, teamTitle, referralCode } = teamManagerInfo
  const navigate = useNavigate()

  const handleCopyLink = () => {
    copyToClipboard(referralLinkCreator(encodeURIComponent(referralCode)))

    enqueueSnackbar(t({ id: 'teamPage.linkCopyMessage', message: 'Referral link copied' }), {
      variant: 'success',
    })
  }
  return (
    <div className={s.root}>
      <div className={s.teamTitle}>
        <TitleH4 label={t({ id: 'teamPage.title', message: 'Team' })} /> <TitleH4 label={teamTitle} />
      </div>
      <div className={s.membersCount}>{t({ id: 'teamPage.count', message: 'Count of members' })}</div>
      <span className={s.members}>{numberOfMembers}</span>
      {gainByMonth > 0 && (
        <span className={s.count}>
          +{gainByMonth} {t({ id: 'teamPage.month', message: 'this month' })}
        </span>
      )}
      <div className={s.codeBlock}>
        <div className={s.code}>
          {t({
            id: 'teamPage.code',
            message: 'Team Code',
          })}
        </div>
        <div className={s.refCode}>
          <span className={s.count}>{teamCode}</span>
          <CopyIcon disabled={!teamCode} text={teamCode} className={s.copy} />
        </div>
      </div>
      {referralCode && (
        <>
          <div className={s.referral}>{t({ id: 'teamPage.url', message: 'Referral Url' })}</div>
          <div className={s.referralUrl}>{referralCode}</div>
          <Button.Primary
            size={ButtonSize.Medium}
            label={t({ id: 'core.button.copyLink', message: 'Copy link' })}
            className={s.button}
            onClick={handleCopyLink}
            dataTestId={DataTestIds.CopyUrlButton}
          />
        </>
      )}

      <Button.Primary
        size={ButtonSize.Medium}
        label={t({
          id: 'teamPage.manageMembers',
          message: `Manage Members`,
        })}
        className={s.button}
        onClick={() => navigate(urls.teamMembers)}
        dataTestId={DataTestIds.MembersPageNavigation}
      />
    </div>
  )
}

export { TeamWidgetManager }
