import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from '@/backend/types'
import initialState from './app.state'
import { TExchangesAvailable } from '@/core/constants'
import { isExchangeBybit } from '@/utils/lib/exchange'

export const slice = createSlice({
  name: `[Tiger Trade Account - App]`,
  initialState,
  reducers: {
    setSetup(state, action: PayloadAction<boolean>) {
      state.isSetup = action.payload
    },
    setIsMinimized(state, action: PayloadAction<boolean>) {
      state.isMinimized = action.payload
    },
    setIsMobile(state, action: PayloadAction<boolean>) {
      state.isMobile = action.payload
    },
    setRequestStatus(state, action: PayloadAction<Status>) {
      state.status = action.payload
    },
    setRestClientInitialized(state, action: PayloadAction<boolean>) {
      state.isRestClientInitialized = action.payload
    },
    setExchangeType(state, action: PayloadAction<TExchangesAvailable>) {
      state.exchangeType = action.payload
      if (!isExchangeBybit(action.payload)) state.exchangeStatus = 'succeeded'
    },
    setExchangeStatus(state, action: PayloadAction<Status>) {
      state.exchangeStatus = action.payload
    },
    setIsExchangeProviderInitialized(state, action: PayloadAction<boolean>) {
      state.isExchangeProviderInitialized = action.payload
    },
    patchClosedNotifications(state, action: PayloadAction<Record<string, boolean>>) {
      state.closedNotifications = {
        ...state.closedNotifications,
        ...action.payload,
      }
    },
  },
})

export const appActions = {
  ...slice.actions,
}

export const appReducer = slice.reducer
