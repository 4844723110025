import type { FC } from 'react'
import { useEffect } from 'react'
import { overviewActions } from '@/redux/overview/overview.slice'
import { AssetsLeftBar, AssetsRightBar } from '@/ui/organisms'
import { useActions } from '@/utils'
import { useOverview } from '@/utils/hooks/useOverview'
import style from './style.module.scss'

export const AssetsPC: FC = () => {
  const { accountTypeForInstrument } = useOverview()
  const { setAccountType } = useActions(overviewActions)

  useEffect(() => {
    if (accountTypeForInstrument) setAccountType(accountTypeForInstrument)
  }, [])

  return (
    <div className={style.wallet}>
      <div className={style.content}>
        <AssetsLeftBar />
        <AssetsRightBar />
      </div>
    </div>
  )
}
