import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, useState, MouseEventHandler, useRef, useEffect } from 'react'
import { copyToClipboard } from '../../../utils'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

export type CopyIconProps = {
  /** text to copy onClick */
  text: string

  /** text to copy onClick */
  className?: string

  dataTestId?: string

  /** disabled to copy onClick */
  disabled?: boolean
}

/** Allows to make copy of the string to the buffer */
export const CopyIcon: FC<CopyIconProps> = ({ text, className, dataTestId, disabled }) => {
  const [isActive, setIsActive] = useState(false)

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const onClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
    e.stopPropagation()

    copyToClipboard(text)
    setIsActive(true)

    timeoutRef.current = setTimeout(() => setIsActive(false), 3000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={clsx(style.copyButton, className)}
      title={t({
        id: 'core.copy',
        message: 'Click to copy',
      })}
      data-testid={DataTestIds.AddressCopyButton}
    >
      <div className={style.paper1} />
      <div className={style.paper2} />
      <Mark isActive={isActive} />
    </button>
  )
}

export const Mark: FC<{ isActive?: boolean }> = ({ isActive }) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(style.mark, isActive && style.markActive)}
  >
    <rect width="21" height="21" className={style.markRect} />
    <path
      d="M8.54198 10.2109C8.31978 9.9116 7.89699 9.84908 7.59766 10.0713C7.29833 10.2935 7.23581 10.7163 7.45802 11.0156L8.54198 10.2109ZM9.77178 13L9.22979 13.4023C9.35265 13.5678 9.54438 13.6681 9.75039 13.6747C9.9564 13.6812 10.1541 13.5932 10.2872 13.4359L9.77178 13ZM14.5154 8.43586C14.7561 8.1512 14.7205 7.7253 14.4359 7.48458C14.1512 7.24387 13.7253 7.27949 13.4846 7.56414L14.5154 8.43586ZM7.45802 11.0156L9.22979 13.4023L10.3138 12.5977L8.54198 10.2109L7.45802 11.0156ZM10.2872 13.4359L14.5154 8.43586L13.4846 7.56414L9.25636 12.5641L10.2872 13.4359Z"
      fill="white"
    />
  </svg>
)
