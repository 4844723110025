// @ts-nocheck
import type { EChartsOption, ECharts } from 'echarts'
import { ServicesWidgetValueType, WidgetRowServerData, WidgetSettingType, WidgetSettingsList } from '../settings'
import { DatasetOption } from 'echarts/types/dist/shared'
import { Theme, colors } from '../themes'
import { currencyFormatters, decimalFormatters, dateTimeFormatters } from '../formatters'
import { EChartsGeneratorConfig, timeDiff, updateModel } from './index'
import { i18nFunc, _i18n, i18nWidgetList } from '../i18n'
import { arrayToCSV } from './export'
import { Colors, lightColors, darkColors } from './colors'
import { ServicesTradeGroupBy, ServicesWidget, ServicesWidgetType2 } from '@/utils/types/contracts.types'
import { TLanguage } from '@/core/constants'
import { isArray } from 'lodash-es'
import { customRound } from '@/utils/lib/formatNumber'
import { TradingPlatforms } from '@/redux/trades/trades.types'
import { t } from '@lingui/macro'

type WidgetGroupedServerData = {
  [key: string]: WidgetRowServerData[]
}

interface ChartLegend {
  raw: string
  name: string
}

export interface Legend extends ChartLegend {
  color: string
}

export interface LegendSelectChangeEvent {
  type: 'legendselectchanged'
  // change legend name
  name: string
  // table of all legend selecting states
  selected: {
    [name: string]: boolean
  }
}

// Colors
const LINE_COLOR_1 = '#3d2bbc'
const LINE_COLOR_2 = '#ffbf44'
const LINE_COLOR_3 = '#00f9ff'
const LINE_COLOR_4 = '#f96b58'
const LINE_COLOR_5 = '#29ff46'
const LINE_COLOR_6 = '#624dff'
const LINE_COLOR_7 = '#e8ff56'
const LINE_COLOR_8 = '#62befb'
const LINE_COLOR_9 = '#ff9130'

const SECONDARY = 'rgba(255,255,255,0.1)'

interface EChartsGeneratorOptions {
  widget: ServicesWidget
  data: WidgetRowServerData[] | null
  ref: () => ECharts
  config: EChartsGeneratorConfig
  onLegendSelectChanged?: (params: LegendSelectChangeEvent) => void
  onLegendsDefined?: (legends: Legend[]) => void
}

export class EChartsGenerator {
  /*
   * widget data from backend
   * */
  private w: ServicesWidget

  /*
   * translation function
   * is it not required to be passed
   * library contain default translations
   * */
  private $t: i18nFunc = (key: keyof i18nWidgetList) => key

  /*
   * echarts instance reference
   * */
  private ref: () => ECharts

  /*
   * global locale used to determine correct
   * number formatting and date formatting
   * */
  private locale: TLanguage = 'en'

  /*
   * echarts can disable automaticly for each chart
   * but if we want to disable it for all charts
   * we can set it here */
  private disabledAnimation = false

  /* color for positive values. Used in candlestick */
  private profitColor: string = '#16a34a'

  /* color for negative values. Used in candlestick */
  private lossColor: string = '#dc2626'

  /* dark theme switcher */
  private darkTheme: boolean = false

  private fontFamily: string = 'Gilroy'

  private colors: Colors = lightColors

  /*
   * theme switcher
   * default - use default colors from light or dark theme depending on darkTheme
   * custom - use themeColor as main color, use other colors from default theme
   * */
  private theme: 'default' | 'custom' | Theme = 'default'

  /* color for custom theme */
  private themeColor: string | null = null

  /*
   * tiny mode switcher
   * if true, axis will be hidden to save space
   * */
  private isTiny: boolean = false

  /*
   * mobile mode switcher
   * if true, zoom will be disabled
   * */
  private mobile: boolean = false

  /*
   * user name to display on chart
   * only for saving screenshot
   * */
  private userName: string = ''

  /*
   * update model
   * firing on legend click
   * */
  private updateModel: updateModel = function () {}

  /*
   * convert timestamp milliseconds
   * to human readable duration string
   * */
  private timeDiff: timeDiff = (value: number) => value.toString()

  /*
   * example mode switcher
   * if true, will use predifined example data
   * */
  private exampleMode: boolean = false

  //internal
  private serverData: WidgetRowServerData[] = []
  private data: any[] = []
  private ma_count = 0
  private dataMaPeriod: number[] = []
  private widgetSettings: WidgetSettingType
  private privateData: boolean = false
  private onLegendSelectChanged = (_params: LegendSelectChangeEvent) => {}
  private onLegendsDefined = (_legends: Legend[]) => {}

  private labels: string[] = []

  private dataset: DatasetOption = {
    dimensions: [],
    source: [],
  }

  // internal exposed
  public series: any[][] = []
  public chartOptions: EChartsOption = {}

  private legends: ChartLegend[] = []

  constructor({ widget, data, ref, config, onLegendSelectChanged, onLegendsDefined }: EChartsGeneratorOptions) {
    this.w = widget
    this.ref = ref
    this.ma_count = this.w.filters?.ma || 0

    if (onLegendSelectChanged) {
      this.onLegendSelectChanged = onLegendSelectChanged
    }

    if (onLegendsDefined) {
      this.onLegendsDefined = onLegendsDefined
    }

    if (!this.w.source) {
      throw new Error('Widget source must be provided')
    }

    this.widgetSettings = WidgetSettingsList[this.w.source]

    if (!this.widgetSettings) {
      throw new Error('Widget settings not found')
    }

    this.serverData = data ?? []
    this.applyConfig(config)
  }

  private applyConfig(config: EChartsGeneratorConfig) {
    this.darkTheme = config.darkTheme ?? this.darkTheme
    this.theme = config.theme ?? this.theme
    this.themeColor = config.themeColor ?? this.themeColor
    this.locale = config.locale ?? this.locale
    this.profitColor = config.profitColor ?? this.profitColor
    this.lossColor = config.lossColor ?? this.lossColor
    this.disabledAnimation = config.disabledAnimation ?? this.disabledAnimation
    this.isTiny = config.tiny ?? this.isTiny
    this.userName = config.userName ?? this.userName
    this.mobile = config.mobile ?? this.mobile
    this.updateModel = config.updateModel ?? this.updateModel
    this.privateData = config.private ?? this.privateData
    this.timeDiff = config.timeDiff ?? this.timeDiff
    this.$t = config.i18nFunc ?? _i18n(this.locale)
    this.fontFamily = config.fontFamily ?? this.fontFamily
    this.colors = config.colors ?? (this.darkTheme ? darkColors : lightColors)
    this.exampleMode = config.exampleMode ?? this.exampleMode

    if (this.exampleMode) {
      this.disabledAnimation = true
    }
  }

  public getLegends(): Legend[] {
    const colors = isArray(this.chartOptions.color) ? this.chartOptions.color : []

    return this.legends.map((legend, idx) => ({
      ...legend,
      color: colors[idx] ?? SECONDARY,
    }))
  }

  public types(): ServicesWidgetType2[] {
    return this.widgetSettings?.type2 ?? []
  }

  public onRendered() {
    this.enableDataZoom()
  }

  public init() {
    this.reset()
    this.populate()
    this.draw()
    this.onLegendsDefined(this.getLegends())
    this.cleanup()

    // Костыль для корректной отрисовки кастомных легенд в чарте
    setTimeout(() => {
      this.ref().setOption(this.chartOptions)
      this.ref().on('legendselectchanged', this.onLegendSelectChanged.bind(this))
    }, 16)

    return this
  }

  public chain(callback: (generator: this) => void): this {
    callback(this)
    return this
  }

  public exportCSV() {
    let maxCount = 0
    const data = []

    if (!this.serverData) {
      return
    }

    if (this.widgetSettings.series && this.widgetSettings.series.length > 0) {
      for (const key in this.serverData) {
        const row = [key]

        for (const value of this.widgetSettings.series) {
          if (this.serverData[key][value.name]) {
            row.push(this.serverData![key][value.name][value.valueField] ?? '0')
          } else {
            row.push(this.serverData[key][value.valueField] ?? '0')
          }
        }

        if (row.length > maxCount) {
          maxCount = row.length
        }

        data.push(row)
      }
    } else if (this.widgetSettings.stacked) {
      for (const key in this.serverData) {
        const row = [key]
        for (const [, v] of Object.entries(this.serverData[key]) as any) {
          row.push(v.value)
        }

        if (row.length > maxCount) {
          maxCount = row.length
        }

        data.push(row)
      }
    } else if (this.widgetSettings.xAxis) {
      data.push(['name', 'value'])
      for (const key in this.serverData) {
        if (this.serverData.hasOwnProperty(key)) {
          const value = this.serverData[key]
          data.push([value.name, value.value])
        }
      }
    } else {
      data.push(['date', 'value'])
      for (const key in this.serverData) {
        if (this.serverData.hasOwnProperty(key)) {
          const value = this.serverData[key]
          data.push([value.dateValue, value.value])
        }
      }
    }

    arrayToCSV(data, maxCount)
  }

  public GetScreenshot(): { data: string; title: string } {
    this.screenshotSwitch(true)

    const data = this.ref().getDataURL({
      pixelRatio: 2,
      backgroundColor: this.darkTheme ? '#12101A' : '#fff',
    })

    this.screenshotSwitch(false)
    const title = this.$t(this.w.source).replace(/[^\p{L}\p{N}\-_ .,()]+/gu, '')

    return {
      data: data,
      title: title + '.png',
    }
  }

  private cleanup() {
    if (!Array.isArray(this.chartOptions.series) || this.chartOptions.series.length < 2) {
      delete this.chartOptions.legend

      if (this.chartOptions.grid) {
        this.chartOptions.grid.bottom = 5
      }
    }

    if (this.theme && this.theme !== 'default') {
      if (this.theme === 'custom' && this.themeColor) {
        this.chartOptions.color = [this.themeColor]
      } else if (colors.hasOwnProperty(this.theme)) {
        this.chartOptions.color = colors[this.theme as Theme]
      }
    }
  }

  private draw() {
    if (this.widgetSettings.series && this.widgetSettings.series.length > 0) {
      if (!this.widgetSettings.stacked) {
        this.drawColumnMultiSeriesChart()
        return
      }
    }

    if (this.w.source === 'income_usdt_bar' && this.w.type === 'chart') {
      this.drawColumnChartProfitByDay()
      return
    }

    if (this.widgetSettings.xAxis) {
      this.drawCategoryChart()
      return
    }

    this.drawTimelineChart()
  }

  private defaultChartOptions(): EChartsOption {
    const options: EChartsOption = {
      color: [
        LINE_COLOR_1,
        LINE_COLOR_2,
        LINE_COLOR_3,
        LINE_COLOR_4,
        LINE_COLOR_5,
        LINE_COLOR_6,
        LINE_COLOR_7,
        LINE_COLOR_8,
        LINE_COLOR_9,
      ],
      backgroundColor: 'transparent',
      textStyle: {
        fontFamily: this.fontFamily,
      },
      dataset: this.dataset,
      legend: {
        show: false,
      },
      graphic: [
        {
          type: 'text',
          top: 10,
          left: 20,
          z: 0,
          style: {
            opacity: 0,
            fill: this.darkTheme ? '#fff' : '#000',
            text: this.$t(this.w.source).replace(/[^\p{L}\p{N}\-_ .,()]+/gu, ''),
            font: 'bold 18px ' + this.fontFamily,
          },
        },
      ],
      tooltip: {
        show: true,
        showContent: !this.privateData,
        confine: true,
        trigger: 'axis',
        axisPointer: { type: 'cross' },
        backgroundColor: 'rgba(28, 26, 36, 0.8)',
        borderRadius: 10,
        borderColor: 'rgba(103, 101, 115, 0.4)',
        textStyle: {
          color: '#FAFAFA',
          fontFamily: this.fontFamily,
          fontSize: 12,
          lineHeight: 17,
          width: 600,
        },
        padding: 15,
        valueFormatter: v => {
          if (v === null || v === undefined) {
            return '-'
          }

          return customRound(v)
        },
      },
      axisPointer: {
        link: [
          {
            xAxisIndex: 'all',
          },
        ],
      },
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: [0, 1],
          throttle: 50,
          filterMode: 'none',
          minSpan: 3,
          zoomLock: true,
          preventDefaultMouseMove: false,
          zoomOnMouseWheel: false,
        },
      ],
      grid: {
        containLabel: true,
        left: 10,
        top: 10,
        right: 10,
        bottom: 10,
      },
    }

    if (this.exampleMode) {
      options.graphic = {
        type: 'text',
        top: 10,
        right: 10,
        z: 9999,
        rotation: -Math.PI / 4,
        style: {
          opacity: 10,
          fill: this.darkTheme ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
          text: this.$t('example').toUpperCase(),
          font: 'bold 14px ' + this.fontFamily,
        },
      }
      delete options.dataZoom
      delete options.toolbox
    }

    return options
  }

  private formatTimestamp(timestamp: number | string): string {
    const date = new Date(timestamp)
    const granularity = this.w.filters?.filter?.groupBy || ServicesTradeGroupBy.TradeGroupByDate
    const formatter =
      dateTimeFormatters[this.locale][granularity] || dateTimeFormatters.en[ServicesTradeGroupBy.TradeGroupByDate]

    try {
      return formatter.format(date)
    } catch (e) {
      return timestamp.toString()
    }
  }

  private drawTimelineChart() {
    // @ts-ignore
    const seriesName = this.$t(this.w.source)

    const valueFormatter = this.getNumberFormatter()
    const t = this

    this.chartOptions = {
      ...this.defaultChartOptions(),
      xAxis: [
        {
          type: 'time',
          boundaryGap: [0, 0],
          animation: false,
          inverse: false,
          axisPointer: {
            label: {
              formatter: params => {
                return t.formatTimestamp(params.value as number)
              },
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#262430',
            },
          },
        },
      ],
      yAxis: [
        !this.isTiny && !this.privateData
          ? {
              type: 'value',
              animation: false,
              show: true,
              splitLine: {
                lineStyle: {
                  type: 'dashed',
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: '#262430',
                },
              },
              axisLabel:
                this.widgetSettings.type === ServicesWidgetValueType.duration
                  ? {
                      formatter: (value: number) => {
                        try {
                          return valueFormatter.format(value)
                        } catch (e) {
                          return value + ''
                        }
                      },
                    }
                  : {
                      formatter: this.axisLabelFormatter as any,
                    },
            }
          : {
              type: 'value',
              animation: false,
              splitLine: {
                lineStyle: {
                  type: 'dashed',
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: '#262430',
                },
              },
              axisLabel: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisPointer: {
                show: false,
              },
              show: false,
            },
      ],
      series: [],
    }

    this.chartOptions.series = []

    if (this.widgetSettings.stacked) {
      let type = 'line'
      let areaStyle: Record<string, any> | undefined = {}
      let step = undefined

      if (this.w.type2 === ServicesWidgetType2.WidgetType2Column) {
        type = 'bar'
      } else if (this.w.type2 === ServicesWidgetType2.WidgetType2LineSimple) {
        areaStyle = undefined
      } else if (this.w.type2 === ServicesWidgetType2.WidgetType2LineStep) {
        step = 'middle'
      }

      if (this.widgetSettings.series && this.widgetSettings.series.length > 0) {
        this.chartOptions.series = this.widgetSettings.series?.map(s => {
          return {
            type: type as any,
            step,
            name: this.$t(s.name as any),
            smooth: 0.5,
            smoothMonotone: 'x',
            areaStyle,
            animation: !this.disabledAnimation,
            symbolSize: 0,
            stack: 'Total',
            lineStyle: {
              width: 1,
            },
            encode: {
              x: 'date',
              y: s.name,
            },
          }
        })
        return
      }

      const dimensions = this.dataset.dimensions ?? []
      const newLegends: ChartLegend[] = []

      for (let i = 1; i < dimensions.length; i++) {
        newLegends.push({ name: dimensions[i] as any, raw: dimensions[i] as any })

        this.chartOptions.series.push({
          type: type as any,
          name: dimensions[i] as any,
          smooth: true,
          smoothMonotone: 'x',
          areaStyle,
          step,
          stack: 'Total',
          animation: !this.disabledAnimation,
          symbolSize: 0,
          lineStyle: {
            width: 1,
          },
          encode: {
            x: 'date',
            y: dimensions[i] as string,
          },
        })
      }

      newLegends.forEach(legend => {
        if (!this.legends.some(l => l.name === legend.name)) {
          this.legends.push(legend)
        }
      })

      return
    }

    switch (this.w.type2) {
      case ServicesWidgetType2.WidgetType2CandleStick:
        this.chartOptions.xAxis[0].type = 'category'
        this.chartOptions.xAxis[0].scale = true
        this.chartOptions.yAxis[0].scale = true
        this.chartOptions.tooltip = {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        }

        // @ts-ignore
        if (this.chartOptions.dataZoom && this.chartOptions.dataZoom[0]) {
          // @ts-ignore
          this.chartOptions.dataZoom[0].filterMode = 'weakFilter'
        }

        this.chartOptions.series.push({
          name: seriesName,
          type: 'candlestick',
          animation: !this.disabledAnimation,
          emphasis: {
            disabled: true,
          },
          itemStyle: {
            color: this.profitColor,
            color0: this.lossColor,
            borderColor: this.profitColor,
            borderColor0: this.lossColor,
          },
          encode: {
            tooltip: ['open', 'close', 'low', 'high'],
          },
        })
        break
      case ServicesWidgetType2.WidgetType2LineSimple:
        this.chartOptions.series.push({
          name: seriesName,
          type: 'line',
          smooth: true,
          animation: !this.disabledAnimation,
          symbolSize: 2,
          emphasis: {
            scale: 6,
            itemStyle: {
              borderWidth: 1,
              borderColor: this.darkTheme ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)',
            },
          },
          encode: {
            x: 'date',
            y: 'value',
          },
        })
        break
      case ServicesWidgetType2.WidgetType2LineStep:
        this.chartOptions.series.push({
          name: seriesName,
          type: 'line',
          step: 'middle',
          animation: !this.disabledAnimation,
          symbolSize: 0,
          lineStyle: {
            width: 0,
          },
          areaStyle: {},
          encode: {
            x: 'date',
            y: 'value',
          },
        })
        break
      case ServicesWidgetType2.WidgetType2Line:
        this.chartOptions.series.push({
          name: seriesName,
          type: 'line',
          smooth: 0.5,
          smoothMonotone: 'x',
          areaStyle: {
            opacity: 0.3,
          },
          animation: !this.disabledAnimation,
          symbolSize: 0,
          lineStyle: {
            width: 1,
          },
          encode: {
            x: 'date',
            y: 'value',
          },
        })
        break
      case ServicesWidgetType2.WidgetType2Column:
        this.chartOptions.series.push({
          name: seriesName,
          type: 'bar',
          animation: !this.disabledAnimation,
          encode: {
            x: 'date',
            y: 'value',
          },
          barGap: '-100%',
        })
        break
    }

    this.addCounterSeries()
    this.addMovingAverageSeries()
  }

  private drawScatterChart() {
    // @ts-ignore
    const seriesName = this.$t(this.w.source)

    this.chartOptions = {
      ...this.defaultChartOptions(),
      xAxis: [
        {
          type: 'value',
          animation: !this.disabledAnimation,
        },
      ],
      yAxis: [
        {
          type: 'value',
          animation: !this.disabledAnimation,
        },
      ],
      tooltip: {
        show: true,
        trigger: 'item',
      },
      visualMap: [
        {
          show: false,
          dimension: 2,
          inRange: {
            symbolSize: [3, 30],
          },
        },
      ],
      series: [
        {
          name: seriesName,
          type: 'scatter',
          animation: !this.disabledAnimation,
          emphasis: {
            label: {
              show: true,
              formatter: function (param: any) {
                return param.data[0]
              },
              position: 'top',
            },
          },
          label: {
            show: true,
            formatter: function (param: any) {
              return param.data[0]
            },
            position: 'top',
          },
          labelLayout: {
            hideOverlap: true,
          },
          encode: {
            x: 'value',
            y: 'counter',
            tooltip: ['name', 'value', 'counter'],
          },
        },
      ],
    }
  }

  private getNumberFormatter(): Intl.NumberFormat {
    const locale = this.locale === 'ru' ? 'ru' : 'en'
    switch (this.widgetSettings.type) {
      case ServicesWidgetValueType.money:
        return currencyFormatters[locale] || currencyFormatters['en']
      case ServicesWidgetValueType.percent:
        // @ts-ignore
        return {
          format: (value: number) => {
            try {
              return decimalFormatters[locale].format(value) + '%'
            } catch (e) {
              return value + '%'
            }
          },
        }
      case ServicesWidgetValueType.duration:
        return {
          format: (value: number) => {
            return this.timeDiff(value * 60 * 1000)
          },
        } as any
      default:
        return decimalFormatters[locale] || decimalFormatters['en']
    }
  }

  private drawColumnMultiSeriesChart() {
    this.chartOptions = {
      ...this.defaultChartOptions(),
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: [
        {
          type: 'category',
          animation: !this.disabledAnimation,
          inverse: this.widgetSettings.inverted || false,
        },
      ],
      yAxis: [
        {
          animation: !this.disabledAnimation,
        },
      ],
      series: this.widgetSettings.series?.map(s => {
        return {
          type: 'bar',
          name: this.$t(s.name as any),
          animation: !this.disabledAnimation,
          itemStyle: {
            borderRadius: 4,
          },
        }
      }),
    }

    delete this.chartOptions.dataZoom
  }

  private barLabelStyleGetter() {
    let sideWithMostData = 'right'
    if (
      Math.abs(this.maxNegative ?? 0) > (this.maxPositive ?? 0) ||
      (this.maxPositive === undefined && this.maxNegative)
    ) {
      sideWithMostData = 'left'
    }

    const inverted = this.widgetSettings.inverted || false
    const darkTheme = this.darkTheme
    const colors = this.colors

    const t = this
    return function (value: number) {
      const itemStyle = {
        color: value < 0 ? colors.redColor : colors.greenColor,
      }

      let label: {
        position?: string
        color?: string
        backgroundColor?: string
        padding?: number[]
      } = {}

      switch (sideWithMostData) {
        case 'right':
          label.position = 'right'
          if (t.maxPositive && value > t.maxPositive / 2) {
            label.position = 'inside'
            label.color = darkTheme ? 'rgba(255,255,255,0.9)' : 'black'
          }
          break
        case 'left':
          label.position = 'left'
          if (t.maxNegative && value < -t.maxNegative / 2) {
            label.position = 'inside'
            label.color = darkTheme ? 'rgba(255,255,255,0.9)' : 'black'
          }
          break
      }

      if (inverted && label.position !== 'inside') {
        label.position = label.position === 'right' ? 'left' : 'right'
      }

      return {
        label,
        itemStyle,
      }
    }
  }

  private drawColumnChart() {
    // @ts-ignore
    const seriesName = this.$t(this.w.source)
    const hideCounter = this.widgetSettings.hideCounter
    const formatter = this.getNumberFormatter()
    const labelStyle = this.barLabelStyleGetter()

    const seriesData = []

    for (const value of this.dataset.source as any[]) {
      seriesData.push({
        value: value[1],
        counter: value[2],
        ...labelStyle(value[1]),
      })
    }

    this.chartOptions = {
      ...this.defaultChartOptions(),
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        extraCssText: 'width:auto; white-space:pre-wrap;',
      },
      xAxis: [
        {
          type: 'value',
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: this.axisLabelFormatter as any,
          },
          animation: !this.disabledAnimation,
          inverse: this.widgetSettings.inverted || false,
        },
      ],
      yAxis: [
        {
          data: this.labels,
          type: 'category',
          animation: !this.disabledAnimation,
        },
      ],
      // @ts-ignore
      series: [
        {
          name: seriesName,
          type: 'bar',
          roundCap: true,
          barCategoryGap: '10%',
          animation: !this.disabledAnimation,
          itemStyle: {
            borderRadius: 2,
          },
          label: {
            show: true,
            formatter: function (params: any) {
              try {
                if (hideCounter) {
                  return formatter.format(params.data.value)
                }
                return `${formatter.format(params.data.value)} (${params.data.counter})`
              } catch (e) {
                return params.data.value
              }
            },
            position: 'right',
          },
          labelLayout: {
            hideOverlap: true,
          },
          data: seriesData,
        },
      ],
    }

    delete this.chartOptions.dataset
    delete this.chartOptions.dataZoom
    delete this.chartOptions.legend
    ;(this.chartOptions.grid as any).bottom = 5
  }

  ////////////////// drawColumnChartProfitByDay
  private drawColumnChartProfitByDay() {
    // Название серии, полученное из виджета
    const seriesName = this.$t(this.w.source)
    const formatter = this.getNumberFormatter()

    // Проверка и преобразование данных из exampleData или фактических данных
    const data = this.serverData || this.dataset.source || []
    const seriesData = []
    const categories = [] // Массив для хранения меток оси X (даты)

    // Обработка данных для графика
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key]
        const date = key // Используем ключ как дату
        const profitValue = parseFloat(value.value)
        const counter = value.counter || 0

        // Добавляем дату в категории (ось X)
        categories.push(date)

        // Добавляем значения в данные серии
        seriesData.push({
          value: profitValue,
          counter: counter,
          itemStyle: {
            color: profitValue >= 0 ? '#1ACC81' : '#F97052', // Зеленый для положительных и красный для отрицательных значений
            borderRadius: [5, 5, 5, 5], // Закругление верхних углов
          },
        })
      }
    }

    this.chartOptions = {
      backgroundColor: 'transparent', // Прозрачный фон для графика
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: '#1C1A24CC', // Прозрачный фон для tooltip
        borderColor: '#67657366', // Полупрозрачная рамка
        borderRadius: 10,
        textStyle: {
          color: '#FFFFFF', // Цвет текста
        },
        formatter: (params: any) => {
          const date = params[0].axisValueLabel
          const value = formatter.format(params[0].value)
          const counter = params[0].data.counter ?? '-'

          return `${date}<br/>${this.$t('profit')}: ${value}<br/>${this.$t('trades_count')}: ${counter}`
        },
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '10%',
        top: '15%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category', // Категориальная ось X
          data: categories, // Устанавливаем метки оси X
          axisLine: {
            show: false, // Линия оси X скрыта
          },
          axisTick: {
            show: false, // Отметки оси X скрыты
          },
          axisLabel: {
            color: '#A0A0A0', // Цвет текста меток оси X
            fontSize: 12,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: '#2D2D2D', // Цвет линий сетки
              type: 'dashed',
            },
          },
          axisLine: {
            show: false, // Линия оси Y скрыта
          },
          axisTick: {
            show: false, // Отметки оси Y скрыты
          },
          axisLabel: {
            color: '#A0A0A0', // Цвет текста меток оси Y
            fontSize: 12,
            formatter: value => {
              return formatter.format(value)
            },
          },
        },
      ],
      series: [
        {
          name: seriesName,
          type: 'bar',
          data: seriesData, // Данные для столбцов
          barWidth: '50%',
          label: {
            show: false, // Скрыть подписи на самих столбцах
          },
          itemStyle: {
            borderRadius: [5, 5, 5, 5], // Закругление верхних углов столбцов
          },
        },
      ],
    }

    // Удаление ненужных опций для улучшения отображения
    delete this.chartOptions.dataset
    delete this.chartOptions.dataZoom
    delete this.chartOptions.legend
    this.chartOptions.grid.bottom = 5 // Установка нижнего отступа сетки
  }

  private axisLabelFormatter(value: number) {
    if (Math.abs(value) >= 1000000) {
      return parseFloat((value / 1000000).toFixed(1)) + 'm'
    }

    if (Math.abs(value) >= 1000) {
      return parseFloat((value / 1000).toFixed(1)) + 'k'
    }

    return value as any
  }

  private drawCategoryChart() {
    switch (this.w.type2) {
      case ServicesWidgetType2.WidgetType2Pie:
        this.drawPieChart()
        break
      case ServicesWidgetType2.WidgetType2Bubble:
        this.drawScatterChart()
        break
      case ServicesWidgetType2.WidgetType2TreeMap:
        this.drawTreemapChart()
        break
      case ServicesWidgetType2.WidgetType2Column:
        this.drawColumnChart()
        break
    }
  }

  private drawTreemapChart() {
    // @ts-ignore
    const seriesName = this.$t(this.w.source)
    const hideCounter = this.widgetSettings.hideCounter
    const formatter = this.getNumberFormatter()

    this.chartOptions = {
      ...this.defaultChartOptions(),
      tooltip: {
        formatter: function (info: any) {
          let s = `<b class="tooltip-title">${info.data.name}</b>: ${formatter.format(info.data.value[1])}`
          if (!hideCounter) {
            s += ` (${info.data.value[2]})`
          }
          return s
        },
      },
      xAxis: [
        {
          type: 'value',
          animation: !this.disabledAnimation,
          inverse: this.widgetSettings.inverted || false,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#262430',
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          animation: !this.disabledAnimation,
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#262430',
            },
          },
        },
      ],
      animation: false,
      series: [
        {
          name: seriesName + ' ' + this.$t('profit'),
          colorSaturation: [0.1, 0.37],
          labelLayout: function (params) {
            if (params.rect.width < 5 || params.rect.height < 5) {
              return { fontSize: 0 }
            }
            return {
              fontSize: Math.min(Math.ceil(Math.sqrt(params.rect.width * params.rect.height) / 6), 20),
            }
          },
          levels: [
            {
              colorMappingBy: 'value',
            },
          ],
          itemStyle: {
            borderWidth: 0.5,
            borderColorSaturation: this.darkTheme ? 0.3 : 0.7,
          },
          data: this.dataset.source as any,
          label: {
            show: true,
            position: 'inside',
            color: this.darkTheme ? 'rgba(255,255,255,0.9)' : 'black',
            formatter: (params: any) => {
              return `${params.name}\n${params.value[1] < 0 ? '-' : ''}${params.value[0]}`
            },
          },
          type: 'treemap',
          width: '100%',
          height: '100%',
          breadcrumb: { show: false },
        },
      ],
    }

    this.dataset = {}

    delete this.chartOptions.dataset
    delete this.chartOptions.legend
  }

  private drawPieChart() {
    // @ts-ignore
    const seriesName = this.$t(this.w.source)
    const formatter = this.getNumberFormatter()

    this.chartOptions = {
      ...this.defaultChartOptions(),
      tooltip: {
        show: true,
        trigger: 'item',
        formatter: function (params: any) {
          return `${params.name}: 
              ${formatter.format(params.data[1])} 
              (${params.percent}%)`
        },
      },
      series: [
        {
          name: seriesName,
          type: 'pie',
          radius: ['40%', '60%'],
          avoidLabelOverlap: false,
          minShowLabelAngle: this.isTiny ? 8 : 3,
          itemStyle: {},
          label: {
            overflow: 'breakAll',
          },
          labelLayout: {
            hideOverlap: true,
          },
          animation: !this.disabledAnimation,
          top: this.isTiny ? '0%' : '-10%',
          left: this.isTiny ? '-20%' : '0%',
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    }

    delete this.chartOptions.legend
    delete this.chartOptions.dataZoom
    delete this.chartOptions.xAxis
    delete this.chartOptions.yAxis
  }

  private addMovingAverageSeries() {
    if (this.ma_count === 0 || !Array.isArray(this.chartOptions.yAxis) || !Array.isArray(this.chartOptions.series)) {
      return
    }

    this.chartOptions.series.push({
      name: 'Moving average (' + this.ma_count + ')',
      type: 'line',
      animation: !this.disabledAnimation,
      emphasis: {
        disabled: true,
      },
      lineStyle: {
        opacity: 0.5,
      },
      smooth: true,
      showSymbol: false,
      encode: {
        x: 'date',
        y: 'ma',
      },
    })
  }

  private addCounterSeries(inverse = true) {
    if (
      this.w.filters?.disabledCounter ||
      !Array.isArray(this.chartOptions.yAxis) ||
      !Array.isArray(this.chartOptions.series) ||
      this.w.type2 === ServicesWidgetType2.WidgetType2CandleStick ||
      this.widgetSettings.hideCounter
    ) {
      return
    }

    this.chartOptions.yAxis[0].min = ({ min }) => {
      if (min > -1 && min < 0) {
        return min.toFixed(2)
      }

      if (min < 0) {
        if (min > -0.5) {
          return min.toFixed(1)
        }

        return Math.floor(min)
      }

      if (min > 0) {
        return 0
      }

      return Math.ceil(min)
    }
    this.chartOptions.yAxis[0].max = ({ max }) => {
      if (max > 0 && max < 1) {
        return (max * 1.5).toFixed(2)
      }

      if (max <= 0) {
        return 0
      }

      return Math.floor(max * 1.5)
    }

    this.chartOptions.yAxis.push({
      type: 'value',
      inverse: inverse,
      max: ({ max }) => {
        return max * 3
      },
      position: 'right',
      animation: !this.disabledAnimation,
      tooltip: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisPointer: {
        show: false,
      },
      show: false,
    })

    const length = this.chartOptions.series.push({
      name: this.$t('trades_count'),
      type: 'bar',
      yAxisIndex: 1,
      animation: !this.disabledAnimation,
      emphasis: {
        disabled: true,
      },
      barMaxWidth: 10,
      encode: {
        x: 'date',
        y: 'counter',
      },
      itemStyle: {
        color: SECONDARY,
      },
    })

    this.chartOptions.color[length - 1] = SECONDARY

    this.legends = this.chartOptions.series.map(data => ({
      name: data.name,
      raw: data.name,
    }))
  }

  private populate(): boolean {
    if (this.exampleMode && this.widgetSettings.exampleData) {
      this.serverData = this.widgetSettings.exampleData
      // @ts-ignore
      this.w.type2 = this.w.type2 ?? this.widgetSettings.type2[0]
    }

    if (!this.serverData) {
      return false
    }

    if (this.widgetSettings.grouped) {
      return this.populateGroupedSeries()
    }

    if (this.widgetSettings.series && this.widgetSettings.series.length > 0) {
      return this.populateCategoryDataMultiSeries()
    }

    const data = Object.values(this.serverData)
    if (this.w.type2 === ServicesWidgetType2.WidgetType2TreeMap) {
      return this.populateTreeMapData(data)
    } else if (this.w.type2 === ServicesWidgetType2.WidgetType2CandleStick) {
      return this.populateCandlestickData(data)
    }

    if (this.widgetSettings.xAxis) {
      return this.populateCategoryData(data)
    }

    return this.populateTimelineData(data)
  }

  private populateTimelineData(data: WidgetRowServerData[]): boolean {
    const valueGetter = this.valueGetter()

    for (const value of data) {
      if (!value.dateValue) {
        continue
      }

      const v = valueGetter(value) as number
      const item: {
        date: number | string
        value: number
        counter?: number
        ma?: string
      } = {
        date: value.dateValue,
        value: v,
        counter: value.counter ?? 0,
      }

      if (this.ma_count > 0) {
        this.dataMaPeriod.unshift(v)
        if (this.dataMaPeriod.length === this.ma_count) {
          item.ma = (this.dataMaPeriod.reduce((a, b) => a + b, 0) / this.ma_count).toFixed(2)
          this.dataMaPeriod.length = this.ma_count - 1
        }
      }

      this.data.push(item)
    }

    this.dataset = {
      dimensions: ['date', 'value', 'counter'],
      source: this.data,
    }

    if (this.ma_count > 0) {
      this.dataset?.dimensions?.push('ma')
    }

    return this.data.length > 0
  }

  private populateCandlestickData(data: WidgetRowServerData[]): boolean {
    const result = []
    let open = '0'
    for (const value of data) {
      if (!value.dateValue || !value.value) {
        continue
      }

      if (open === '0') {
        open = value.value
      }

      const item = [
        this.formatTimestamp(value.dateValue),
        parseFloat(open),
        parseFloat(value.value),
        // @ts-ignore
        parseFloat(value.value2),
        // @ts-ignore
        parseFloat(value.value3),
        undefined,
      ]
      if (this.ma_count > 0) {
        this.dataMaPeriod.unshift(parseFloat(value.value))
        if (this.dataMaPeriod.length === this.ma_count) {
          item[5] = (this.dataMaPeriod.reduce((a, b) => a + b, 0) / this.ma_count).toFixed(2)
          item[5] = parseFloat(item[5])
          this.dataMaPeriod.length = this.ma_count - 1
        }
      }

      result.push(item)
      open = value.value
    }

    this.dataset = {
      dimensions: ['date', 'open', 'close', 'low', 'high', 'ma'],
      source: result,
    }

    return true
  }

  private getColorForValue(profit: boolean, value: number, min: number, max: number, total: number) {
    let colors = profit ? this.colors.greenSteps.map(x => x) : this.colors.redSteps.map(x => x)

    if (total < 5) {
      colors.pop()
      colors.pop()
    }

    // Ensure the value is within the bounds
    value = Math.max(min, Math.min(max, value))

    if (min === max) {
      return colors[0]
    }

    // Calculate the index for the color array
    const index = Math.floor(((value - min) / (max - min)) * (colors.length - 1))

    // Return the color at the calculated index
    return colors[index]
  }

  private populateTreeMapData(data: WidgetRowServerData[]): boolean {
    const valueGetter = this.valueGetter()

    for (const value of data) {
      if (!value.value) {
        continue
      }
      this.setMinMaxValue(parseFloat(value.value))
    }

    const resultPositive = []
    const resultNegative = []
    for (const value of data) {
      if (!value.name || !value.value) {
        continue
      }

      const v = parseFloat(value.value)

      if (v > 0) {
        resultPositive.push({
          name: value.name,
          value: [valueGetter(value) as number, value.value, value.counter ?? 0],
          negative: false,
          itemStyle: this.widgetSettings.monochrome
            ? {}
            : {
                // @ts-ignore
                color: this.getColorForValue(true, v, this.minPositive, this.maxPositive, data.length),
              },
        })
        continue
      }

      resultNegative.push({
        name: value.name,
        value: [valueGetter(value) as number, value.value, value.counter ?? 0],
        negative: true,
        itemStyle: this.widgetSettings.monochrome
          ? {}
          : {
              // @ts-ignore
              color: this.getColorForValue(false, Math.abs(v), this.minNegative, this.maxNegative, data.length),
            },
      })
    }

    const result = []

    result.push({
      name: this.$t('profit'),
      children: resultPositive ?? [{ name: 'No data', value: 0, negative: false }],
    })

    result.push({
      name: this.$t('loss'),
      color: [this.lossColor],
      children: resultNegative ?? [{ name: 'No data', value: 0, negative: false }],
    })

    this.dataset = {
      dimensions: ['name', 'value', 'negative'],
      source: result,
    }

    return true
  }

  private populateGroupedSeries(): boolean {
    if (!this.serverData) {
      return false
    }

    const serverData = this.serverData as any as WidgetGroupedServerData

    const dates = Object.keys(serverData)

    if (dates.length === 0) {
      return false
    }

    let series = []
    let legends: ChartLegend[] = []

    const valueGetter = this.valueGetter()

    for (const date of dates) {
      const groups = Object.keys(serverData[date])

      const seria = {
        date,
      }

      for (const l of legends) {
        seria[l.name] = 0
      }

      for (const group of groups) {
        const value = serverData[date][group]
        const name = this.getExchangeLocale(value.name)

        if (!value.dateValue) {
          continue
        }

        const v = valueGetter(value) as number
        seria[name] = v

        if (!legends.some(legend => legend.raw === value.name)) {
          legends.push({ raw: value.name, name: name })
        }
      }

      series.push(seria)
    }

    this.legends = [...legends]

    this.dataset = {
      dimensions: ['date', ...this.legends.map(legend => legend.name)],
      source: series,
    }

    return true
  }

  private populateCategoryDataMultiSeries(): boolean {
    if (!this.serverData) {
      return false
    }

    const dates = Object.keys(this.serverData)

    if (!this.widgetSettings.series || this.widgetSettings.series.length === 0) {
      return false
    }

    let series = []

    for (let i = 0; i < dates.length; i++) {
      let obj = {
        date: this.widgetSettings.xAxis || this.widgetSettings.stacked ? dates[i] : this.formatTimestamp(dates[i]),
      }
      for (let j = 0; j < this.widgetSettings.series.length; j++) {
        const seriaName = this.widgetSettings.series[j].name
        const valueField = this.widgetSettings.series[j].valueField
        // @ts-ignore
        if (this.serverData[dates[i]].hasOwnProperty(seriaName)) {
          obj[this.widgetSettings.series[j].name as keyof typeof obj] =
            // @ts-ignore
            this.serverData[dates[i]][seriaName][valueField] ?? 0
          continue
        }

        obj[this.widgetSettings.series[j].name as keyof typeof obj] =
          // @ts-ignore
          this.serverData[dates[i]][valueField] ?? 0
      }
      series.push(obj)
    }

    this.legends = this.widgetSettings.series.map(s => ({
      raw: s.name,
      name: s.name,
    }))

    this.dataset = {
      dimensions: ['date', ...this.legends.map(legend => legend.name)],
      source: series,
    }

    return true
  }

  private sortData(data: string | number[]): void {
    const sorting = this.w.filters?.simpleSortBy

    if (!sorting || !Array.isArray(data)) {
      return
    }

    switch (sorting) {
      case 'value_asc':
        data.sort(function (a: any, b: any) {
          return a[1] - b[1]
        })
        break
      case 'key_asc':
        data.sort(function (a: any, b: any) {
          return a[0].toLowerCase().localeCompare(b[0].toLowerCase())
        })
        break
      case 'key_desc':
        data.sort(function (a: any, b: any) {
          return b[0].toLowerCase().localeCompare(a[0].toLowerCase())
        })
        break
      case 'value_desc':
        data.sort(function (a: any, b: any) {
          return b[1] - a[1]
        })
        break
      default:
        if (!this.widgetSettings.disableDefaultSorting) {
          data.sort(function (a: any, b: any) {
            return b[1] - a[1]
          })
        }
    }
  }

  private minPositive: number | undefined
  private maxPositive: number | undefined
  private minNegative: number | undefined
  private maxNegative: number | undefined
  private setMinMaxValue(value: number): void {
    if (value > 0) {
      if (this.maxPositive === undefined || value > this.maxPositive) {
        this.maxPositive = value
      }

      if (this.minPositive === undefined || value < this.minPositive) {
        this.minPositive = value
      }
      return
    }

    value = Math.abs(value)
    if (this.maxNegative === undefined || value > this.maxNegative) {
      this.maxNegative = value
    }

    if (this.minNegative === undefined || value < this.minNegative) {
      this.minNegative = value
    }
  }

  private populateCategoryData(data: WidgetRowServerData[]): boolean {
    const valueGetter = this.valueGetter()
    const count = data.length
    const maxOthersPercent = this.w.type2 === ServicesWidgetType2.WidgetType2Column ? 10 : 40
    const othersCountThreshold = 100

    const source = this.widgetSettings.source
    let customLabel = false
    switch (source) {
      case 'weekdays':
      case 'hourly_intervals':
        customLabel = true
        break
    }

    let sum = 0
    this.dataset = {
      dimensions: ['name', 'value', 'counter'],
      source: data.map(value => {
        this.setMinMaxValue(valueGetter(value) as number)
        let label = value.name
        if (customLabel) {
          label = this.$t(`${source}_${value.name}` as any)
        }

        let v = valueGetter(value) as number
        sum += v

        return [label, v, value.counter ?? 0]
      }),
    }

    if (count > othersCountThreshold) {
      const minPercent = 1
      const thresholdValue = Math.abs(sum) * (minPercent / 100)
      let othersSum = 0
      let othersCount = 0
      let othersPercent = 0
      let othersAmount = 0
      let newSource = []
      for (var i = count - 1; i >= 0; i--) {
        // @ts-ignore
        const v = this.dataset.source[i] as any[]
        if (Math.abs(v[1]) > thresholdValue) {
          newSource.push(v)
          continue
        }

        const percent = (v[1] / sum) * 100
        if (othersPercent + percent > maxOthersPercent) {
          newSource.push(v)
          continue
        }

        othersAmount += 1
        othersSum += v[1]
        othersCount += v[2]
        othersPercent += percent
      }

      if (Math.abs(othersSum) > 0) {
        const e = [`${this.$t('others')} (${othersAmount})`, othersSum, othersCount]

        if (this.w.type2 === ServicesWidgetType2.WidgetType2Column) {
          newSource.unshift(e)
        } else {
          newSource.push(e)
        }
      }
      this.dataset.source = newSource
    }

    if (this.w.type2 !== ServicesWidgetType2.WidgetType2Pie) {
      this.sortData(this.dataset.source as any)
    }

    const cutoff = this.mobile ? 20 : 30
    const t = this

    // Добавляем проверку на наличие данных в `this.dataset.source`
    if (Array.isArray(this.dataset.source)) {
      this.labels = this.dataset.source.map(function (value: any) {
        // Проверяем, что `value` определен и является массивом с как минимум одним элементом
        if (Array.isArray(value) && value[0] !== undefined) {
          let v = value[0]
          // Проверяем, что `v` определен и является строкой перед доступом к свойству `length`
          if (typeof v === 'string' && v.length > cutoff) {
            v = t.formatLongString(v, cutoff)
          }
          return v
        } else {
          // Обработка случая, если `value` не соответствует ожидаемому формату
          return ''
        }
      })
    }

    return true
  }

  private formatLongString(str: string, n: number): string {
    if (str.length <= n + 10) {
      return str
    }

    let result = ''
    let lineLength = 0

    for (let i = 0, line = 1; i < str.length && line <= 3; i++) {
      result += str[i]
      lineLength++

      // If we're at a space and close to the n limit, or at the n limit, attempt to break
      if ((str[i] === ' ' && lineLength >= (n * 4) / 5) || lineLength === n) {
        if (line < 3) {
          // Add a new line if not yet at the 3rd line
          result += '\n'
          line++
          lineLength = 0
        } else if (line === 3 && lineLength === n) {
          // Stop if we reach the end of the 3rd line
          result += '...'
          break
        }
      }
    }

    return result.trim() // Trim in case we added a newline at the end
  }
  private valueGetter(): (value: WidgetRowServerData) => number | string {
    if (this.widgetSettings.type === 'duration') {
      return (value: WidgetRowServerData) => Math.round(parseFloat(value.value || '0') / 1000) / 60
    }

    if (
      this.w.type2 === ServicesWidgetType2.WidgetType2TreeMap ||
      this.w.type2 === ServicesWidgetType2.WidgetType2Pie
    ) {
      return (value: WidgetRowServerData) => {
        const v = Number(value.value)
        return Math.abs(Number(v.toFixed(this.decimals(v))))
      }
    }

    return (value: WidgetRowServerData) => {
      const v = Number(value.value)
      return Number(v.toFixed(this.decimals(v)))
    }
  }

  private reset() {
    this.dataset = {}
    this.labels = []
    this.minPositive = undefined
    this.maxPositive = undefined
    this.minNegative = undefined
    this.maxNegative = undefined
    this.data = []
    this.dataMaPeriod = []
    this.series = []
    this.chartOptions = {}
  }

  private decimals(v: number): number {
    if (this.widgetSettings.maxDecimals !== undefined) {
      return this.widgetSettings.maxDecimals
    }

    if (v < 1 && v > -1) {
      return 4
    }

    if (v > 100 || v < -100) {
      return 0
    }

    return 2
  }

  private screenshotSwitch(enable: boolean): void {
    const options = this.ref().getOption()

    if (!options) {
      return
    }

    const graphic = (options.graphic[0] || {}) as any

    for (let e in graphic.elements) {
      graphic.elements[e].z = enable ? 100 : 0
      graphic.elements[e].style.opacity = enable ? 0.5 : 0
    }

    this.ref().setOption({
      graphic: graphic,
      legend: {
        show: enable,
        type: 'scroll',
        bottom: 25,
        right: 10,
        icon: 'circle',
        itemWidth: 8,
        itemHeight: 8,
        padding: [0, 5, 5, 5],
      },
    })
  }

  private enableDataZoom() {
    if (this.mobile || this.exampleMode) {
      return
    }

    if (this.w.type2 === ServicesWidgetType2.WidgetType2TreeMap) {
      return
    }

    if (this.w.type2 === ServicesWidgetType2.WidgetType2Pie) {
      return
    }

    if (this.w.type2 === ServicesWidgetType2.WidgetType2Column && this.widgetSettings.xAxis) {
      return
    }

    this.ref().dispatchAction({
      type: 'takeGlobalCursor',
      key: 'dataZoomSelect',
      dataZoomSelectActive: true,
    })
  }

  private getExchangeLocale(value: string): string {
    switch (value) {
      case TradingPlatforms.BINANCE_FUTURE:
        return t({ id: 'trades.dashboard.binanceFuture', comment: 'binance future' })

      case TradingPlatforms.BINANCE_SPOT:
        return t({ id: 'trades.dashboard.binanceSpot', comment: 'binance spot' })

      case TradingPlatforms.BINANCE_VIP_FUTURE:
        return t({ id: 'trades.dashboard.binanceVipFuture', comment: 'binance vip future' })

      case TradingPlatforms.BINANCE_VIP_SPOT:
        return t({ id: 'trades.dashboard.binanceVipSpot', comment: 'binance vip spot' })

      case TradingPlatforms.BYBIT_LINEAR:
        return t({ id: 'trades.dashboard.bybitLinear', comment: 'bybit linear' })

      case TradingPlatforms.BYBIT_SPOT:
        return t({ id: 'trades.dashboard.bybitSpot', comment: 'bybit spot' })

      case TradingPlatforms.OKX_SPOT:
        return t({ id: 'trades.dashboard.okxSpot', comment: 'okx spot' })

      case TradingPlatforms.OKX_SWAP:
        return t({ id: 'trades.dashboard.okxSwap', comment: 'okx linear' })

      default:
        return value
    }
  }
}
