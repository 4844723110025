export const Search = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14867_146500)">
        <path
          d="M15.05 23C19.4683 23 23.05 19.4183 23.05 15C23.05 10.5817 19.4683 7 15.05 7C10.6318 7 7.05005 10.5817 7.05005 15C7.05005 19.4183 10.6318 23 15.05 23Z"
          stroke="#37363E"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3501 21.3999L22.5501 23.5999"
          stroke="#37363E"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14867_146500">
          <rect width="17.5" height="18.2" fill="white" transform="translate(6.25 6.19995)" />
        </clipPath>
      </defs>
    </svg>
  )
}
