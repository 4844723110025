import React from 'react'

interface IProps {
  className?: string
}

export const Failed = (props: IProps) => {
  const { className } = props

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z"
        fill="var(--background, #F97052)"
      />
      <path
        d="M9.79995 4.19995L4.19995 9.79995"
        stroke="var(--foreground, #12101A)"
        strokeWidth="1.26"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.79995 9.79995L4.19995 4.19995"
        stroke="var(--foreground, #12101A)"
        strokeWidth="1.26"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
