import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SVG } from '@/assets/svg'
import { RemoveApiKeysPayload } from '@/backend/api/apiKeyAPI'
import { Status } from '@/backend/types'
import { ApiKeyType } from '@/redux/apiKey/apiKey.types'
import { navigateToApiEdit, urls } from '@/router/urls'
import { TitleH2 } from '@/ui/atoms/TitleH2'
import { Button } from '@/ui/kit'
import { Popup } from '@/ui/molecules'
import { SkeletonButton } from '@/ui/kit'
import { ApiKeyInfoBlocks } from '@/ui/organisms/ApiKeyInfoBlocks'
import { ApiKeyListItems } from '@/ui/organisms/ApiKeyListItems'
import { ApiKeyRemove } from '@/ui/organisms/ApiKeyRemove'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useAppSelector, useGoogleAuthCode, useGoogleAuthSettings } from '@/utils/hooks'
import { PageSetupGoogleAuth } from '../WalletSetupGoogleAuth'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'

const ITEMS_LIMIT = 30

export interface ApiRightBarProps {
  data: ApiKeyType[]
  status: Status
  limit?: number
  onRemove: (payload: RemoveApiKeysPayload) => void
  apiKeyStatusRemove: Status
}

const ApiRightBar: FC<ApiRightBarProps> = props => {
  const { data, status, limit = ITEMS_LIMIT, onRemove, apiKeyStatusRemove } = props
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()
  const { tradingStatus } = useAppSelector(state => state.team)
  const { exchangeType } = useAppSelector(state => state.overview)

  const { isApiKeyEnabled } = useExchangesDisabled()

  const [removeCandidate, setRemoveCandidate] = useState<ApiKeyType | null>(null)

  const navigate = useNavigate()

  const handleClickAdd = useCallback(() => {
    navigate(urls.apiCreate)
  }, [navigate])

  const handleClickEdit = useCallback(
    (originalApiKeyDTO: ApiKeyType) => {
      navigate(navigateToApiEdit(originalApiKeyDTO.id))
    },
    [navigate]
  )

  const isAddBtnDisabled = data.length > limit || status !== 'succeeded'

  const handleClickRemoveCancel = useCallback(() => setRemoveCandidate(null), [])
  const handleClickRemoveConfirm = useCallback(
    (googleCode: string) => {
      if (!removeCandidate) return
      onRemove({
        ...removeCandidate,
        googleCode,
      })
    },
    [removeCandidate, onRemove]
  )

  const isRemoveSubmitDisabled = useMemo(() => apiKeyStatusRemove === 'loading', [apiKeyStatusRemove])

  useEffect(() => {
    if (apiKeyStatusRemove === 'succeeded') {
      setRemoveCandidate(null)
    }
  }, [apiKeyStatusRemove])

  const isOpen = Boolean(removeCandidate)

  if (!googleAuthSettings.settings.enabled) {
    return (
      <div className={style.rightBar}>
        <PageSetupGoogleAuth
          googleAuthSettings={googleAuthSettings}
          googleAuthCode={googleAuthCode}
          backRoute={urls.api}
        />
      </div>
    )
  }

  return (
    <>
      <Popup isOpen={isOpen}>
        <ApiKeyRemove
          apiKey={removeCandidate?.key!}
          name={removeCandidate?.name!}
          onCancel={handleClickRemoveCancel}
          onSubmit={handleClickRemoveConfirm}
          isSubmitDisabled={isRemoveSubmitDisabled}
        />
      </Popup>
      <div className={style.rightBar}>
        <div className={style.top}>
          <TitleH2
            label={t({
              id: 'apiPage.rightBar.heading',
              message: 'API Management',
            })}
          />
          {status === 'loading' ? (
            <SkeletonButton />
          ) : (
            tradingStatus?.tradingEnabled !== false && (
              <Button.Accent
                onClick={handleClickAdd}
                label={t({
                  id: 'apiPage.create',
                  message: 'Create API key',
                })}
                rightIcon={<SVG.OtherIcons.Plus />}
                disabled={isAddBtnDisabled || !isApiKeyEnabled}
                dataTestId={DataTestIds.ApiPageCreateApiKeyButton}
              />
            )
          )}
        </div>
        <ApiKeyInfoBlocks />
        <ApiKeyListItems
          data={data}
          disabled={!isApiKeyEnabled}
          status={status}
          onRemove={setRemoveCandidate}
          onEdit={handleClickEdit}
        />
      </div>
    </>
  )
}

export default memo(ApiRightBar)
