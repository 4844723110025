import clsx from 'clsx'
import { noop } from 'lodash-es'
import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { Ellipsis } from '@/ui/atoms/Ellipsis'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { Button, ButtonSize, Checkbox, CopyIcon, Input, InputSize } from '@/ui/kit'
import { Chip } from '@/ui/kit/Chips'
import { formatAccess } from '../ApiKeyListItem/utils'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { ApiKeyCreationDTO } from '@/backend/models/ApiKeyDTO'

interface ApiKeyCreateDoneProps {
  onSubmit?: () => void
  apiKeyData: ApiKeyCreationDTO
}

export const ApiKeyCreateDone: FC<ApiKeyCreateDoneProps> = props => {
  const {
    apiKeyData: { name, key, secret, spotEnabled, futuresEnabled, restrictionIps = [], passphrase },
    onSubmit,
  } = props

  return (
    <div className={clsx(style.content)}>
      <TitleH1
        label={<Ellipsis label={name} />}
        className={style.content_title}
        dataTestId={DataTestIds.ApiKeyNameTitle}
      />

      <div>
        <div className={style.checkboxesWrapper}>
          <Checkbox checked readonly variant="circle" dataTestId={DataTestIds.CheckboxRead}>
            {formatAccess('read')}
          </Checkbox>
          {spotEnabled && (
            <Checkbox checked readonly variant="circle" dataTestId={DataTestIds.CheckboxSpot}>
              {formatAccess('spot')}
            </Checkbox>
          )}
        </div>

        {restrictionIps.length && (
          <div className={style.ips}>
            {restrictionIps.map((value, idx) => (
              <Chip className={style.ip} key={idx} value={value} />
            ))}
          </div>
        )}

        <Input
          containerClassName={style.input}
          label={t({
            id: 'apiPage.apiKey.title',
            message: `API key`,
          })}
          value={key}
          readOnly
          setValue={noop}
          button={<CopyIcon text={key} dataTestId={DataTestIds.ApiKeyKeyCopyButton} />}
          size={InputSize.Small}
          dataTestId={DataTestIds.ApiKeyKeyTextField}
        />

        {passphrase && (
          <Input
            containerClassName={style.input}
            label={t({
              id: 'apiPage.passphrase.title',
              message: 'Passphrase',
            })}
            labelHintText={
              <>
                {t({
                  id: 'apiPage.passphrase.message-1',
                  message: 'For security reasons, Passphrase',
                })}
                <br />
                {t({
                  id: 'apiPage.passphrase.message-2',
                  message: 'can only be shown once. Copy',
                })}
                <br />
                {t({
                  id: 'apiPage.passphrase.message-3',
                  message: 'and save it somewhere safe',
                })}
              </>
            }
            value={passphrase}
            readOnly
            setValue={noop}
            button={<CopyIcon text={passphrase} dataTestId={DataTestIds.ApiKeySecretCopyButton} />}
            size={InputSize.Small}
          />
        )}

        <Input
          containerClassName={style.input}
          label={t({
            id: 'apiPage.sectetKey.title',
            message: 'Secret Key',
          })}
          labelHintText={
            <>
              {t({
                id: 'apiPage.sectetKey.message-1',
                message: 'For security reasons, Secret key',
              })}
              <br />
              {t({
                id: 'apiPage.sectetKey.message-2',
                message: 'can only be shown once. Copy',
              })}
              <br />
              {t({
                id: 'apiPage.sectetKey.message-3',
                message: 'and save it somewhere safe',
              })}
            </>
          }
          value={secret}
          readOnly
          setValue={noop}
          button={<CopyIcon text={secret} dataTestId={DataTestIds.ApiKeySecretCopyButton} />}
          size={InputSize.Small}
          dataTestId={DataTestIds.ApiKeySecretKeyTextField}
        />

        <div className={style.message}>
          <div data-testid={DataTestIds.ApiCopyAndSaveMessage}>
            {passphrase &&
              t({
                id: 'apiPage.secretKeyPassphrase.copyAndSave',
                message:
                  'Copy and save the Secret Key and Passphrase in a safe place, as part of security, we can only show it once',
              })}
            {!passphrase &&
              t({
                id: 'apiPage.secretKey.copyAndSave',
                message: 'Copy and save the Secret Key in a safe place, as part of security, we can only show it once',
              })}
          </div>
          <SVG.OtherIcons.Mark2 />
        </div>

        <div className={style.buttonsWrapper}>
          <Button.Accent
            size={ButtonSize.Medium}
            label={t({
              id: 'core.done',
              message: `Done`,
            })}
            className={style.button}
            onClick={onSubmit}
            dataTestId={DataTestIds.ApiKeyDoneButton}
          />
        </div>
      </div>
    </div>
  )
}
