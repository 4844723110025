import { ProfileLeftBar } from '@/ui/organisms/ProfileLeftBar'
import { ProfileRightBar } from '@/ui/organisms/ProfileRightBar'
import { TwoColumnPage } from '@/layouts/TwoColumnPage'
import style from './style.module.scss'

const ProfilePage = () => {
  return (
    <TwoColumnPage className={style.root}>
      <ProfileLeftBar />
      <ProfileRightBar />
    </TwoColumnPage>
  )
}

export { ProfilePage }
