import axios, { AxiosError } from 'axios'

type ErrorDetail = {
  code: number
  message: string
}

type ApiError = {
  code: number
  message: string
  details?: ErrorDetail[]
}

class AppError extends Error {
  data?: ApiError
  details: ErrorDetail[] = []

  constructor(error: Error | AxiosError<Record<string, any>>) {
    super()

    if (axios.isAxiosError(error) && error.response?.data) {
      this.data = error.response.data as ApiError
      this.details = error.response.data?.details
    }
  }

  get code(): number {
    if (this.details) {
      return this.details?.[0]?.code
    } else if (this.data) {
      return this.data?.code
    } else {
      return 0
    }
  }

  get message(): string {
    if (this.details) {
      return this.details?.map(d => d.message).join(' ')
    } else if (this.data) {
      return this.data?.message
    } else {
      return 'Undefined error'
    }
  }
}

const appError = (error: Error | AxiosError) => {
  return new AppError(error)
}

export { AppError, appError }
