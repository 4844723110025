import { forwardRef } from 'react'
import { useCarousel } from '../../hooks/useCarousel'
import clsx from 'clsx'
import s from './style.module.scss'

type Props = {
  wrapperClassName?: string
} & React.HTMLAttributes<HTMLDivElement>

export const Content = forwardRef<HTMLDivElement, Props>(({ className, wrapperClassName, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel()

  return (
    <div ref={carouselRef} className={clsx(s.wrapper, wrapperClassName)}>
      <div ref={ref} className={clsx(s.content, { [s.vertical]: orientation === 'vertical' }, className)} {...props} />
    </div>
  )
})
