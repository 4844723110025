import { ButtonHTMLAttributes, ElementType, FC, ReactElement, ReactNode, MouseEvent } from 'react'

export enum ButtonVariant {
  Accent = 'accent',
  AccentDark = 'accentDark',
  Primary = 'primary',
  PrimaryGreen = 'primaryGreen',
  Secondary = 'secondary',
  White = 'white',
  WhiteBlured = 'whiteBlured',
  Clean = 'clean',
}

export enum ButtonSize {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** Design variant */
  variant: ButtonVariant

  /** Size **/
  size?: ButtonSize

  /** SVG icon **/
  leftIcon?: ReactElement

  /** SVG icon **/
  rightIcon?: ReactElement

  /** Main text **/
  label?: ReactNode

  /** Additional text **/
  description?: ReactNode

  /** Is loading **/
  loading?: boolean

  /** ClassName for the button wrap */
  className?: string

  /** Callback function **/
  onClick?: (event: MouseEvent<HTMLButtonElement>) => any

  /** Test indication id **/
  dataTestId?: string

  /** Element tag for render */
  as?: keyof HTMLElementTagNameMap

  /** Other props */
  [key: string]: any
}

export type TButtonVariants = FC<IButtonProps> & {
  [key in keyof typeof ButtonVariant]: ElementType<Omit<IButtonProps, 'variant' | 'size'> & { size?: ButtonSize }>
}

export type TButtonResult = TButtonVariants & { Variant: typeof ButtonVariant } & { Size: typeof ButtonSize }
