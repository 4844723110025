import React from 'react'

export const User = () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.3679 14.1784C28.3679 21.5157 23.0413 28.3878 15.764 30.3984C15.2689 30.5335 14.7287 30.5335 14.2335 30.3984C6.95627 28.3878 1.62964 21.5157 1.62964 14.1784V7.59138C1.62964 6.361 2.55995 4.96557 3.7153 4.50043L12.0728 1.07942C13.9484 0.314184 16.0641 0.314184 17.9397 1.07942L26.2972 4.50043C27.4376 4.96557 28.3829 6.361 28.3829 7.59138L28.3679 14.1784Z"
        fill="url(#paint0_linear_1370_2307)"
        stroke="url(#paint1_linear_1370_2307)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter0_d_1370_2307)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1074 8.96436C13.6008 8.96436 12.3753 10.1899 12.3753 11.6964C12.3753 13.1658 13.5359 14.3813 15.0042 14.4281C15.0115 14.4284 15.0189 14.4285 15.0263 14.4285H15.1768C15.1842 14.4285 15.1916 14.4284 15.199 14.4281C16.666 14.3813 17.8241 13.1803 17.8394 11.7037V11.6964C17.8394 10.1899 16.6139 8.96436 15.1074 8.96436ZM18.9962 18.85C18.9962 18.0769 18.5548 16.8656 17.8551 16.3984C17.0905 15.8823 16.1167 15.6467 15.1786 15.6467C14.2392 15.6467 13.2617 15.8829 12.4879 16.3964L12.4867 16.3972C11.7859 16.8644 11.3441 18.0762 11.3441 18.85C11.3441 19.6233 11.7854 19.964 12.4855 20.4312C13.2548 20.9464 14.2308 21.0008 15.1699 21.0008C16.1088 21.0008 17.0846 20.9465 17.8538 20.4316C18.5542 19.9644 18.9962 19.6235 18.9962 18.85Z"
          fill="url(#paint2_linear_1370_2307)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1370_2307"
          x="3.9327"
          y="3.40577"
          width="22.475"
          height="26.8594"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.85286" />
          <feGaussianBlur stdDeviation="3.70573" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.737255 0 0 0 0 0.941176 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1370_2307" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1370_2307" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1370_2307"
          x1="24.9943"
          y1="3.16682"
          x2="2.98934"
          y2="26.6494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#252137" />
          <stop offset="1" stopColor="#12101A" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1370_2307"
          x1="-2.13299"
          y1="31.8853"
          x2="39.1288"
          y2="-13.7937"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.254793" stopColor="#12101A" />
          <stop offset="0.966484" stopColor="#453D63" />
          <stop offset="0.977282" stopColor="#100E17" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1370_2307"
          x1="11.3441"
          y1="21.0007"
          x2="21.8932"
          y2="12.6038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC4ED" />
          <stop offset="0.482059" stopColor="#FEA471" />
          <stop offset="1" stopColor="#FF627E" />
        </linearGradient>
      </defs>
    </svg>
  )
}
