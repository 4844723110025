import { FC, useEffect } from 'react'
import { TwoColumnPage } from '@/layouts/TwoColumnPage'
import { ReferralHowLeftBar } from '@/ui/molecules/ReferralHowLeftBar'
import { ReferralHowRightBar } from '@/ui/molecules/ReferralHowRightBar'
import s from './style.module.scss'
import { useActions, useAppSelector } from '@/utils'
import { referralsActions } from '@/redux/referrals/referrals.slice'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const ReferralHowItWorks: FC = () => {
  const { GetReferralBenefitsTC } = useActions(referralsActions)
  const {
    benefits: { benefitsStatus },
  } = useAppSelector(state => state.referrals)
  const { exchangeType } = useExchageType()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (exchangeType === undefined || benefitsStatus !== 'idle') return
    GetReferralBenefitsTC(exchangeType)
  }, [GetReferralBenefitsTC, exchangeType, benefitsStatus])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const exchangeTypeParam = params.get('exchangeType')

    if (exchangeType === EXCHANGES_TYPE.OKX_BROKER || exchangeTypeParam === EXCHANGES_TYPE.OKX_BROKER) {
      navigate('/orders')
    }
  }, [])

  return (
    <TwoColumnPage className={s.root}>
      <ReferralHowLeftBar />
      <ReferralHowRightBar />
    </TwoColumnPage>
  )
}

export { ReferralHowItWorks }
