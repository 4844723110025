import * as yup from 'yup'
import { t } from '@lingui/macro'

export const VALIDATION_SCHEMA = (min: number) =>
  yup.object({
    amount: yup
      .number()
      .typeError(t({ message: 'Incorrect field', id: 'allocateForm.validation.amount.incorrect' }))
      .min(min, `${t({ message: 'Deposit at least', id: 'allocateForm.validation.amount.min' })} ${min} USDⓈ`)
      .required(t({ message: 'Amount is required!', id: 'allocateForm.validation.amount.required' })),
    asset: yup.string().required(t({ message: 'Please select asset', id: 'allocateForm.validation.asset.required' })),
  })
