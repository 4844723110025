import React, { FC } from 'react'

interface CalendarProp {
  className?: string
}

export const Calendar: FC<CalendarProp> = props => {
  const { className } = props

  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.899902 7.3V15.4C0.899902 17.3882 2.51168 19 4.4999 19H13.4999C15.4881 19 17.0999 17.3882 17.0999 15.4V7.3M0.899902 7.3V5.95C0.899902 3.96177 2.51168 2.35 4.4999 2.35H13.4999C15.4881 2.35 17.0999 3.96177 17.0999 5.95V7.3M0.899902 7.3H17.0999M12.5999 1V3.7M5.3999 1V3.7"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
