import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { makeXLString } from '@/utils'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { Button, ButtonSize } from '@/ui/kit'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useActions, useAppSelector, useGoogleAuthCode } from '@/utils/hooks'
import { GoogleCodeVerification } from '../GoogleCodeVerification'
import { FieldErrorType } from '@/types'
import { addressActions } from '@/redux/address/address.slice'
import { errorTranslations } from '@/core/constants/errors/errorsTranslations'

interface AddressRemoveProps {
  onSubmit?: (googleCode: string) => void
  onCancel?: () => void
  isSubmitDisabled?: boolean
  name: string
  apiKey: string
}

export const AddressRemove: FC<AddressRemoveProps> = props => {
  const { onSubmit, onCancel, isSubmitDisabled, apiKey, name } = props
  const googleCode = useGoogleAuthCode()
  const { errors } = useAppSelector(state => state.address)
  const { setErrors } = useActions(addressActions)
  const errorsTrans = errorTranslations()

  const errosMapped = useMemo<Record<string, string>>(() => {
    return errors.reduce((acc: any, error: any) => {
      return {
        ...acc,
        [error.field]: error.message,
      }
    }, {})
  }, [errors])

  const submitHandler = useCallback(() => {
    const errors: FieldErrorType[] = []
    if (!googleCode.isCorrectCodeLength) {
      errors.push({
        code: '',
        field: 'googleCode',
        message: t({
          id: 'services.gAuth.enter.title',
          message: 'Enter the 6-digit code below',
        }),
      })
    }

    if (errors.length > 0) {
      setErrors(errors)
      return
    }

    setErrors([])

    onSubmit!(googleCode.code.join(''))
  }, [googleCode.isCorrectCodeLength, googleCode.code, onSubmit, setErrors])

  useEffect(() => {
    return () => {
      setErrors([])
    }
  }, [])

  return (
    <div className={style.component}>
      <TitleH3
        className={style.title}
        label={t({
          id: 'addressPage.delete.title',
          message: `Would you like to delete`,
        })}
      />
      <TitleH3 className={style.title} label={`${makeXLString(name)}?`} />

      <div className={style.msg}>{apiKey}</div>

      <div className={style.label}>
        <GoogleCodeVerification
          header={t({
            id: 'services.gAuth.enter.title',
            message: `Enter the 6-digit code below`,
          })}
          text={t({
            id: 'services.gAuth.enter.subtitle',
            message: `Input code from Google Authenticator`,
          })}
          code={googleCode.code}
          setCode={googleCode.setCode}
          isDisabled={isSubmitDisabled}
          errorMessage={errosMapped.googleCode ? errorsTrans.googleCode : undefined}
          successMessage={''}
        />
      </div>

      <div className={style.buttonsWrapper}>
        <Button.White
          size={ButtonSize.Medium}
          label={t({
            id: 'core.return',
            message: `Return`,
          })}
          className={style.button}
          onClick={onCancel}
          dataTestId={DataTestIds.ApiKeyRemoveCancel}
        />
        <Button.Primary
          size={ButtonSize.Medium}
          label={t({
            id: 'addressPage.delete.confirm',
            message: `Delete address`,
          })}
          className={style.button}
          onClick={submitHandler}
          disabled={isSubmitDisabled}
          dataTestId={DataTestIds.ApiKeyRemoveSubmit}
        />
      </div>
    </div>
  )
}
