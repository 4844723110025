export enum E_TEAM_USER_VIEWS {
  DEFAULT = 'DEFAULT',
  DISABLED = 'DISABLED',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
}

export enum E_TEAM_MEMBER_VIEWS {
  NONE = 'NONE',
  RM_ACTIVATE = 'ACTIVATE',
  RM_AFTER_ACTIVATION = 'AFTER_ACTIVATION',
  RM_SETTINGS = 'SETTINGS',
  RM_DISABLE = 'DISABLE',
  T_LEAVE = 'T_LEAVE',
  ERROR = 'ERROR',
}
