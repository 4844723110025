import { SelectOption } from './Select.types'

export const convertOptionsToObject = <T extends string>(options: SelectOption<T>[]) => {
  return options.reduce<Record<string, SelectOption<T>>>((accumulator, current) => {
    if (current.value) {
      accumulator[current.value] = current
    }

    return accumulator
  }, {})
}
