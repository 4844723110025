import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SVG } from '@/assets/svg'
import { TLanguage, getLanguageDescriptions } from '@/core/constants'
import { useLanguageContext } from '@/providers/LanguageProvider'
import { getTtLinksByLocale } from '@/router/links'
import { urls } from '@/router/urls'
import { AvailableScreenSize } from '@/types'
import { HeaderActionButton } from '@/ui/atoms/HeaderActionButton/HeaderActionButton'
import { HeaderMenuLink } from '@/ui/atoms/HeaderMenuLink'
import { HeaderMenuLinkProps } from '@/ui/atoms/HeaderMenuLink/HeaderMenuLink'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { useMobileSizeDetect } from '@/utils'
import { useLogout } from '@/utils/hooks/useLogout'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import style from './style.module.scss'

interface IProps {
  isNavigationVisible?: boolean
}

export const HeaderBurger: FC<IProps> = ({ isNavigationVisible = true }) => {
  const { pathname } = useLocation()
  const [isOpen, setOpen] = useState(false)
  const [isMobile, setMode] = useMobileSizeDetect()

  const LANGUAGES_DESCRIPTIONS = useMemo(() => {
    return getLanguageDescriptions()
  }, [])

  // Logout
  const logoutWrapper = useLogout()
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false)

  const logoutUser = () => {
    logoutWrapper({ logoutParams: { returnTo: window.location.origin } })
  }

  const handleLogout: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    setLogoutModalOpen(true)
  }

  // Langauge
  const { locale, setLocale } = useLanguageContext()

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  const LINKS = useMemo<HeaderMenuLinkProps[]>(() => {
    return [
      {
        item: {
          href: urls.orders,
          text: t({ id: 'header.orders', message: `Orders & positions` }),
          dataTestId: DataTestIds.HeaderOrdersAndPositions,
        },
      },
      {
        item: {
          href: urls.assets,
          text: t({ message: 'View assets', id: 'mainTabCryptocurrency.viewAssets' }),
          dataTestId: DataTestIds.BurgerAssetsBtn,
        },
      },
    ]
  }, [])

  const BUTTONS = useMemo(() => {
    return [
      {
        onClick: handleLogout,
        text: t({ id: 'core.logout', message: `Log out` }),
        dataTestId: DataTestIds.LogoutButton,
      },
      {
        onClick: () => setMode(AvailableScreenSize.Desktop),
        text: isMobile
          ? t({ id: 'core.fullVersion', message: 'Open full version' })
          : t({ id: 'core.mobileVersion', message: 'Open mobile version' }),
        dataTestId: DataTestIds.HeaderChangeVersion,
      },
    ]
  }, [isMobile])

  const SOCIAL_LIST = useMemo(() => {
    return [
      {
        icon: <SVG.Social.Twitter />,
        href: getTtLinksByLocale('twitter', locale),
      },
      {
        icon: <SVG.Social.Telegram />,
        href: getTtLinksByLocale('telegram', locale),
      },
      {
        icon: <SVG.Social.Discord />,
        href: getTtLinksByLocale('discord', locale),
      },
    ]
  }, [locale])

  return (
    <>
      <button
        onClick={() => setOpen(prev => !prev)}
        className={clsx(style.burger, {
          [style.open]: isOpen,
        })}
      >
        <div className={style.lines}></div>
      </button>

      {isOpen && (
        <div className={style.menu}>
          <div className={style.wrapper}>
            {isNavigationVisible && (
              <ol className={style.verticalList}>
                {LINKS.map((link, index) => (
                  <li key={index + (link.item?.text ?? '')}>
                    <HeaderMenuLink {...link} />
                  </li>
                ))}
              </ol>
            )}

            <ol className={style.verticalList}>
              {BUTTONS.map(btn => (
                <li key={btn.text}>
                  <HeaderActionButton {...btn} />
                </li>
              ))}
            </ol>

            <ol className={style.horizontalList}>
              {Object.keys(LANGUAGES_DESCRIPTIONS).map(key => (
                <div
                  key={key}
                  className={clsx(style.language, { [style.active]: key === locale })}
                  onClick={setLocale.bind(this, key as TLanguage)}
                >
                  {LANGUAGES_DESCRIPTIONS[key as keyof typeof LANGUAGES_DESCRIPTIONS].name}
                </div>
              ))}
            </ol>

            {/*
            <section className={style.socialWrapper}>
              <span className={style.socialTitle}>{t({ id: 'social.follow', message: 'follow as on' })}</span>
              <ol className={style.socialList}>
                {SOCIAL_LIST.map((social, id) => (
                  <a className={style.socialButton} href={social.href} target="_blank" rel="noreferrer" key={id}>
                    {social.icon}
                  </a>
                ))}
              </ol>
            </section>
            */}
          </div>
        </div>
      )}

      <InteractiveModal isOpen={isLogoutModalOpen}>
        <div className={style.logoutPopup}>
          <InteractiveModalParts.SubHeader
            text={t({ id: 'core.modal.logoutTitle', message: 'Are you sure you want to log out?' })}
          />
          <InteractiveModalParts.Button
            text={t({ id: 'core.button.logout', message: `Log out` })}
            variant={ButtonVariant.White}
            onClick={logoutUser}
          />
          <InteractiveModalParts.Button
            text={t({ id: 'core.button.cancel', message: `Cancel` })}
            variant={ButtonVariant.Primary}
            onClick={() => {
              setLogoutModalOpen(false)
            }}
          />
        </div>
      </InteractiveModal>
    </>
  )
}
