import React from 'react'

export const Withdraw = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#1C1A24"
      />
      <path
        d="M17.2124 14.2541C17.2124 14.2541 22.4133 14.2541 25.7458 14.2541M25.7458 14.2541C25.7458 17.5867 25.7458 22.7876 25.7458 22.7876M25.7458 14.2541C20.9851 19.0149 14.2542 25.7458 14.2542 25.7458"
        stroke="url(#paint0_radial_1045_2466)"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1045_2466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.4791 9.98743) rotate(94.3102) scale(18.5104 244.458)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
