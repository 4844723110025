import { FC, useCallback, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { E_BINANCE_VIP_CONSENT_PERMISSIONS, IBinanceVipConsentForm } from './BinanceVipConsentForm.types'
import { yupResolver } from '@hookform/resolvers/yup'
import { VALIDATION_SCHEMA } from './BinanceVipConsentForm.utils/BinanceVipConsentForm.schema'
import { ArrayedInputsGroup, TArrayedOption } from '@/ui/kit/Checkbox/Checkbox.Components/ArrayedInputsGroup'
import { Button, Checkbox } from '@/ui/kit'
import { getBinanceVipConsentTranslations } from '@/translations/consent/permissionTranslations'
import { CheckboxProps, ECheckboxStyleVariant } from '@/ui/kit/Checkbox/Checkbox'
import { t } from '@lingui/macro'

import styles from './style.module.scss'
import { ReadMore } from '../ReadMore'
import { Trans } from '@lingui/react'
import { useActions } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'
import { defineMetadata } from '@/utils/lib/metadata/mapper'

export const BinanceVipConsentForm: FC = () => {
  const {
    handleSubmit,
    control,
    formState,
    clearErrors,
    formState: { errors },
  } = useForm<IBinanceVipConsentForm>({
    mode: 'all',
    resolver: yupResolver(VALIDATION_SCHEMA()),
    defaultValues: {
      permissions: [],
    },
  })
  const { UpdateMetadata } = useActions(profileActions)

  const onSubmit = useCallback<SubmitHandler<IBinanceVipConsentForm>>((data: IBinanceVipConsentForm) => {
    clearErrors()

    UpdateMetadata(
      defineMetadata({
        binanceVipConsent: data.permissions,
      })
    )
  }, [])

  const optionsPermissions = useMemo<TArrayedOption<E_BINANCE_VIP_CONSENT_PERMISSIONS>[]>(() => {
    const permissionTranslations = getBinanceVipConsentTranslations()
    return Object.values(E_BINANCE_VIP_CONSENT_PERMISSIONS).map(permission => {
      return {
        description: permissionTranslations[permission],
        value: permission,
      }
    })
  }, [])

  const checkboxProperties = useMemo<Partial<CheckboxProps>>(() => {
    return {
      className: styles.checkboxItem,
      styleVariant: ECheckboxStyleVariant.DEFAULT,
    }
  }, [])

  const isSubmitEnabled = useMemo(() => {
    return formState.isValid
  }, [formState])
  return (
    <div className={styles.root}>
      <ReadMore
        heading=""
        content={
          <Trans
            id="binanceVipConsentForm.readMore.content"
            components={{
              br: <br />,
            }}
          />
        }
      />

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <ArrayedInputsGroup
          component={Checkbox}
          control={control}
          name="permissions"
          options={optionsPermissions}
          componentProps={checkboxProperties}
          className={styles.formItem}
          wrapClassname={styles.formItemSmall}
          errorMessage={formState.isSubmitted && errors.permissions?.message}
        />

        <div className={styles.submitLine}>
          <Button.Accent
            label={t({ id: 'binanceVipConsentForm.submit.apply', message: 'Accept and continue' })}
            className={styles.submitBtn}
            type={'submit'}
            disabled={!isSubmitEnabled}
          />
        </div>
      </form>
    </div>
  )
}
