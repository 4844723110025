import { t } from '@lingui/macro'
import clsx from 'clsx'
import React, { Fragment, memo, Suspense, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import QRCode from 'react-qr-code'
import { SVG } from '@/assets/svg'
import { Button, ButtonVariant, CopyIcon, InteractiveModalParts } from '@/ui/kit'
import { Loader } from '@/ui/kit/Loader'
import { Popup } from '@/ui/molecules'
import { GoogleCodeVerification, GoogleCodeVerificationProps } from '@/ui/organisms/GoogleCodeVerification'
import { Portal } from '@/ui/utils/Portal'
import { useAppSelector, useMobileSizeDetect, useToggle } from '@/utils'
import styles from './style.module.scss'
import * as amplitude from '@amplitude/analytics-browser'
import { E_AmplitudeEventName } from '@/redux/profile/profile.types'

const GooglePlayQR = React.lazy(() => import('./GooglePlayQR'))
const AppStoreQR = React.lazy(() => import('./AppStoreQR'))

export type GoogleAuthProps = Pick<
  GoogleCodeVerificationProps,
  'header' | 'code' | 'setCode' | 'errorMessage' | 'successMessage' | 'isDisabled' | 'isValid'
> & {
  /** is Modal visible */
  isOpen?: boolean

  /** secret to generate QR code, 32 symbols length */
  secret: string

  /** handler to fire when user clicks CLOSE button */
  onCloseClick?: () => void
}

/** Component for registering in Google Authenticator app */
export const GoogleAuth = memo<GoogleAuthProps>(props => {
  const { header, isOpen = false, secret, onCloseClick, ...restProps } = props

  const [isMobile] = useMobileSizeDetect()
  const [mobileStep, setMobileStep] = useState(1)
  const { userEmail } = useAppSelector(state => state.profile)
  const [isQrCodeTabActive, toggleQrCodeTabActive] = useToggle(true)

  /*
  if (correctSecretLength !== 32) {
    console.error(
      `${t({
        id: 'services.gAuth.incorrectLength',
        message: `incorrect secret length:`,
      })} ${secret}`
    )
  }
  */

  const secretWords = secret.match(everyFourSymbolsSplitter)

  const hostName = process.env.REACT_APP_2FA_NAME || window.location.hostname

  const qrValue = useMemo(() => {
    return encodeURI(`otpauth://totp/${hostName} (${userEmail})?secret=${secret}`)
  }, [hostName, userEmail, secret])

  useLayoutEffect(() => {
    if (isMobile && isQrCodeTabActive) {
      toggleQrCodeTabActive()
    }
  }, [isMobile, isQrCodeTabActive, toggleQrCodeTabActive])

  useEffect(() => {
    if (isQrCodeTabActive) {
      amplitude.track(E_AmplitudeEventName.TwoFAQRViewed)
    }
  }, [isQrCodeTabActive])

  return (
    <Portal>
      <Popup isOpen={isOpen} className={clsx(isMobile && styles.mobilePopup)}>
        <div className={clsx(isMobile && styles.mobile, styles.root)}>
          {(!isMobile || (isMobile && mobileStep === 1)) && (
            <div className={styles.left}>
              <div className={styles.header}>{header}</div>

              {!isMobile && (
                <div className={styles.instructions}>
                  {t({
                    id: 'services.gAuth.scan.msg-01',
                    message: `Scan the QR code or enter a setup key in the`,
                  })}
                  &nbsp;
                  <span className={styles.gradient}>
                    {t({
                      id: 'services.gAuth.scan.msg-02',
                      message: `Google Authentication app`,
                    })}
                  </span>
                  ,&nbsp;
                  {t({
                    id: 'services.gAuth.scan.msg-3',
                    message: `then enter the verification code that the app automatically generates`,
                  })}
                </div>
              )}

              {!isMobile && (
                <div className={styles.helpText}>
                  {t({ message: 'Download and install Google Authenticator', id: 'services.gAuth.downloadAndInstall' })}
                </div>
              )}

              {isMobile && (
                <div className={styles.helpText}>
                  <span>
                    {t({
                      message: 'Download and install',
                      id: 'services.downloadAndInstall',
                    })}
                  </span>
                  &nbsp;
                  <span className={styles.gradient}>
                    {t({
                      id: 'services.gAuth.scan.msg-2',
                      message: 'Google Authenticator',
                    })}
                  </span>
                </div>
              )}

              <div className={styles.getApps}>
                <a
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.app}
                  title={`${t({
                    id: 'services.gAuth.appStore.msg-1',
                    message: `Download on the`,
                  })} ${t({
                    id: 'services.gAuth.appStore.msg-2',
                    message: `App Store`,
                  })}`}
                >
                  {!isMobile && (
                    <div className={styles.storeQRWrapper}>
                      <Suspense fallback={<Loader />}>
                        <AppStoreQR className={styles.storeQR} />
                      </Suspense>
                    </div>
                  )}

                  <div className={styles.appIcon}>
                    <SVG.OtherIcons.Apple />
                  </div>

                  <div className={styles.appText}>
                    <div className={styles.appText1}>
                      {t({
                        id: 'services.gAuth.appStore.msg-1',
                        message: `Download on the`,
                      })}
                    </div>
                    <div className={styles.appText2}>
                      {t({
                        id: 'services.gAuth.appStore.msg-2',
                        message: `App Store`,
                      })}
                    </div>
                  </div>
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.app}
                  title={`${t({
                    id: 'services.gAuth.downloadGooglePlay',
                    message: 'Get it on',
                  })} ${t({
                    id: 'services.gAuth.gPlay.msg-2',
                    message: `Google Play`,
                  })}`}
                >
                  {!isMobile && (
                    <div className={styles.storeQRWrapper}>
                      <Suspense fallback={<Loader />}>
                        <GooglePlayQR className={styles.storeQR} />
                      </Suspense>
                    </div>
                  )}

                  <div className={styles.appIcon}>
                    <SVG.OtherIcons.GooglePlay />
                  </div>

                  <div className={styles.appText}>
                    <div className={styles.appText1}>
                      {t({
                        id: 'services.gAuth.gPlay.msg-1',
                        message: `Get it on`,
                      })}
                    </div>
                    <div className={styles.appText2}>
                      {t({
                        id: 'services.gAuth.gPlay.msg-2',
                        message: `Google Play`,
                      })}
                    </div>
                  </div>
                </a>
              </div>

              {isMobile && (
                <div className={styles.actionButtons}>
                  <InteractiveModalParts.Button
                    text={t({
                      message: 'Next',
                      id: 'core.next',
                    })}
                    variant={ButtonVariant.White}
                    onClick={() => setMobileStep(2)}
                  />

                  <InteractiveModalParts.Button
                    text={t({ message: 'Cancel', id: 'core.cancel' })}
                    variant={ButtonVariant.Clean}
                    onClick={onCloseClick}
                  />
                </div>
              )}
            </div>
          )}

          {((isMobile && mobileStep === 2) || !isMobile) && (
            <div className={styles.right}>
              {isMobile && (
                <Fragment>
                  <div className={styles.header}>{header}</div>
                  <div className={styles.instructions}>
                    {t({
                      id: 'services.gAuth.scan.mob-msg-01',
                      message: 'Enter a setup key in the',
                    })}
                    &nbsp;
                    <span className={styles.gradient}>
                      {t({
                        id: 'services.gAuth.scan.msg-02',
                        message: 'Google Authentication app',
                      })}
                    </span>
                    ,&nbsp;
                    {t({
                      id: 'services.gAuth.scan.msg-3',
                      message: 'then enter the verification code that the app automatically generates',
                    })}
                  </div>
                </Fragment>
              )}

              {!isMobile && (
                <div className={styles.tabs} onClick={toggleQrCodeTabActive}>
                  <button className={clsx(styles.tab, isQrCodeTabActive && styles.tabActive)}>
                    <SVG.OtherIcons.QRCode />
                    {t({
                      id: 'services.gAuth.qr',
                      message: `QR code`,
                    })}
                  </button>
                  <button className={clsx(styles.tab, !isQrCodeTabActive && styles.tabActive)}>
                    <SVG.OtherIcons.Key />
                    {t({
                      id: 'services.gAuth.setup',
                      message: `Setup key`,
                    })}
                  </button>
                </div>
              )}

              <div className={styles.qrWrapper}>
                {isQrCodeTabActive ? (
                  <>
                    <div className={styles.qr}>
                      <QRCode
                        value={qrValue}
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                        size={130}
                        viewBox={`0 0 130 130`}
                        level={'M'}
                      />
                    </div>
                    <div className={styles.helpText}>
                      {t({
                        id: 'services.gAuth.scan.msg-1',
                        message: 'Scan QR code with',
                      })}
                      <wbr />
                      {t({
                        id: 'services.gAuth.scan.msg-2',
                        message: 'Google Authenticator',
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.helpText}>
                      {t({
                        id: 'services.gAuth.acc.msg-1',
                        message: 'Name your account and',
                      })}
                      <wbr />
                      {t({
                        id: 'services.gAuth.acc.msg-2',
                        message: 'enter the setup key shown below',
                      })}
                    </div>

                    <div className={styles.codeWords}>
                      {secretWords?.map(word => (
                        <div className={styles.codeWord} key={word}>
                          {word}
                        </div>
                      ))}

                      <CopyIcon text={secret} className={styles.copyButton} />
                    </div>
                  </>
                )}
              </div>

              <GoogleCodeVerification
                header={t({
                  id: 'services.gAuth.enter.title',
                  message: `Enter the 6-digit code below`,
                })}
                text={
                  isMobile
                    ? `${t({
                        message: 'If you’re having issues, please contact support:',
                        id: 'withdrawConfirmation.confirmGoogleAuth.contactSupport',
                      })} ${t({
                        id: 'core.supportEmail',
                        message: 'broker.support@tiger.trade',
                      })}`
                    : t({
                        id: 'services.gAuth.enter.subtitle',
                        message: `Input code from Google Authenticator`,
                      })
                }
                isDarken={!isMobile}
                {...restProps}
              />

              {isMobile && (
                <div className={styles.actionButtons}>
                  <InteractiveModalParts.Button
                    text={t({ message: 'Cancel', id: 'core.cancel' })}
                    variant={ButtonVariant.Clean}
                    onClick={onCloseClick}
                  />
                </div>
              )}
            </div>
          )}

          {!isMobile && (
            <Button.Primary
              className={styles.backButtonWrapper}
              leftIcon={<SVG.Additional.Close className={styles.backButton} />}
              title={t({
                id: 'core.close',
                message: 'Close',
              })}
              onClick={onCloseClick}
            />
          )}
        </div>
      </Popup>
    </Portal>
  )
})

const everyFourSymbolsSplitter = /.{1,4}/g

const correctSecretLength = 32
