import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  RowData,
  CellContext,
  ColumnDefTemplate,
} from '@tanstack/react-table'
import { FC, ReactElement, ReactNode, useMemo, MouseEvent, Fragment } from 'react'
import styles from './style.module.scss'
import clsx from 'clsx'

import '@tanstack/react-table'
import { SVG } from '@/assets/svg'
import { SortedColumns, TmmFilterSortType } from '@/ui/organisms/HistoryTrades/HistoryTrades'
import { TableColumnKeys } from '@/redux/trades/trades.utils'

// redeclare meta  structure
declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    className?: string[]
  }
}
interface ITableRow {
  [key: string]: any
}

export type TTableAccessor = {
  accessor: string
  header?: ReactElement
  cell?: ColumnDefTemplate<CellContext<ITableRow, any>>
  meta?: {
    className?: string[]
  }
}

type TTableProps = {
  data: ITableRow[]
  columnsSource?: ColumnDef<any, any>[]
  accessors: TTableAccessor[]
  rowCallback?: (...args: any[]) => any
  cellCallback?: (...args: any[]) => any
  rowClassname?: string
  rowHoverClassname?: string
  tdClassName?: string
  thClassName?: string
  trClassName?: string
  theadClassName?: string
  tbodyClassName?: string
  tableClassName?: string
  gap?: boolean
  tmmFilterSort?: TmmFilterSortType
  getHint?: (key: TableColumnKeys, hint?: string, containerClass?: string, iconClassName?: string) => ReactNode
  handleMouseEnter?: (id: TableColumnKeys) => void
  getDeskById?: (id: TableColumnKeys) => boolean | undefined
  handleMouseLeave?: (id: TableColumnKeys) => void
  handleClick?: (id: TableColumnKeys, sortIsDesk: boolean) => void
  actives?: TableColumnKeys[]
  sortedColumns?: SortedColumns[]
  sumColumns?: Record<string, { value: string; className?: string }>
  showFooter?: boolean
  tfootClassName?: string
}

const renderCell = (ctx: CellContext<ITableRow, any>) => ctx.renderValue()

export const Table: FC<TTableProps> = props => {
  const {
    data,
    columnsSource,
    accessors,
    rowCallback,
    cellCallback,
    rowHoverClassname,
    rowClassname,
    tdClassName,
    thClassName,
    theadClassName,
    tbodyClassName,
    tmmFilterSort,
    tableClassName,
    trClassName,
    gap,
    getHint,
    handleMouseEnter,
    handleMouseLeave,
    getDeskById,
    handleClick,
    actives,
    sortedColumns,
    sumColumns,
    showFooter,
    tfootClassName,
  } = props

  const columns = useMemo(() => {
    if (columnsSource) return columnsSource

    const columnHelper = createColumnHelper<ITableRow>()

    return accessors.map(accessor => {
      return columnHelper.accessor(accessor.accessor, {
        header: () => accessor.header,
        cell: accessor.cell ?? renderCell,
        meta: accessor.meta,
      })
    })
  }, [columnsSource, data, accessors])

  const handleCellClick = (event: MouseEvent, cell: Partial<{ column: { id: string } }>) => {
    if (cell?.column?.id === 'tags') {
      event.stopPropagation()
    }
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })
  return (
    <table className={clsx(styles.table, tableClassName)}>
      <thead className={theadClassName}>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id} className={clsx(styles.tr, trClassName)}>
            {headerGroup.headers.map((header, index) => (
              <th key={header.id} className={clsx(styles.th, header.column.columnDef.meta?.className, thClassName)}>
                {!tmmFilterSort ? (
                  header.isPlaceholder ? null : (
                    flexRender(header.column.columnDef.header, header.getContext())
                  )
                ) : (
                  <>
                    {header.isPlaceholder ? null : (
                      <div className={styles.container}>
                        <div
                          className={clsx(styles.headerBlock, {
                            [styles.isActive]: actives?.includes(header.id as TableColumnKeys),
                          })}
                          onMouseEnter={() => header.id !== 'tags' && handleMouseEnter?.(header.id as TableColumnKeys)}
                          onMouseLeave={() => header.id !== 'tags' && handleMouseLeave?.(header.id as TableColumnKeys)}
                          onClick={() =>
                            header.id !== 'tags' &&
                            handleClick?.(
                              header.id as TableColumnKeys,
                              (getDeskById?.(header.id as TableColumnKeys) as boolean) ?? true
                            )
                          }
                        >
                          <div className={styles.sortParent}>
                            {actives?.includes(header.id as TableColumnKeys) &&
                              tmmFilterSort &&
                              tmmFilterSort.sortedItemsList.includes(header.id as TableColumnKeys) && (
                                <div className={styles.sort}>
                                  {!getDeskById?.(header.id as TableColumnKeys) && actives ? (
                                    <SVG.OtherIcons.SortArrowDown
                                      className={clsx(styles.defaultColor, {
                                        [styles.selectedSort]: !!sortedColumns?.find(el => el.id === header.id),
                                      })}
                                    />
                                  ) : (
                                    <SVG.OtherIcons.SortArrowUp />
                                  )}
                                </div>
                              )}
                          </div>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                        <div className={styles.hintBlock}>
                          {getHint?.(
                            header.id as TableColumnKeys,
                            index === 1 ? styles.hint : '',
                            styles.hintContainer,
                            styles.iconClassName
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </th>
            ))}
          </tr>
        ))}
        {gap && <tr className={styles.gap}></tr>}
      </thead>
      <tbody className={tbodyClassName}>
        {table.getRowModel().rows.map(row => (
          <Fragment key={row.id}>
            <tr
              key={row.id}
              id={row.original.id}
              className={clsx(styles.tr, rowClassname, rowHoverClassname, trClassName)}
              onClick={() => {
                if (typeof rowCallback === 'function') rowCallback(row.original)
              }}
            >
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  onClick={event => handleCellClick(event, cell)}
                  className={clsx(styles.td, cell.column.columnDef.meta?.className, tdClassName)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          </Fragment>
        ))}
      </tbody>
      {showFooter && sumColumns && (
        <tfoot className={tfootClassName}>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id} className={clsx(styles.tr, trClassName)}>
              {footerGroup.headers.map(header => (
                <td
                  key={header.id}
                  className={clsx(
                    styles.td,
                    header.column.columnDef.meta?.className,
                    tdClassName,
                    sumColumns[header.id]?.className
                  )}
                >
                  {!header.isPlaceholder && <div>{sumColumns[header.id]?.value}</div>}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      )}
    </table>
  )
}
