import { useCallback, useEffect } from 'react'

const useBeforeunload = (when: boolean) => {
  const handler = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!when) return

      event.preventDefault()
      event.returnValue = ''
    },
    [when]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handler, { capture: true })

    return () => {
      window.removeEventListener('beforeunload', handler, { capture: true })
    }
  }, [handler])
}

export { useBeforeunload }
