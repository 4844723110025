import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { apiKeyAsyncActions } from './apiKey.actions'
import { initialState } from './apiKey.state'
import { FieldErrorType } from '@/types'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'

export const slice = createSlice({
  name: `[Tiger Trade Account Api Key]`,
  initialState,
  reducers: {
    setErrors(state, action: PayloadAction<FieldErrorType[]>) {
      state.errors = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(apiKeyAsyncActions.GetApiKeysTC.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(apiKeyAsyncActions.GetApiKeysTC.fulfilled, (state, action) => {
        state.apiKeys = action.payload
        state.status = 'succeeded'
        state.statistics.apiKeyCount = action.payload.length
      })
      .addCase(apiKeyAsyncActions.GetApiKeysTC.rejected, (state, action) => {
        state.status = 'failed'
      })

      .addCase(apiKeyAsyncActions.GetApiKeysStatisticsTC.pending, (state, action) => {
        state.statusStatistics = 'loading'
      })
      .addCase(apiKeyAsyncActions.GetApiKeysStatisticsTC.rejected, (state, action) => {
        state.statusStatistics = 'failed'
      })
      .addCase(apiKeyAsyncActions.GetApiKeysStatisticsTC.fulfilled, (state, action) => {
        state.statusStatistics = 'succeeded'
        state.statistics = action.payload
      })

      .addCase(apiKeyAsyncActions.GetApiKeyByIdTC.pending, (state, action) => {
        state.apiKeyByIdStatus = 'loading'
      })
      .addCase(apiKeyAsyncActions.GetApiKeyByIdTC.fulfilled, (state, action) => {
        state.apiKeyById = action.payload
        state.apiKeyByIdStatus = 'succeeded'
      })
      .addCase(apiKeyAsyncActions.GetApiKeyByIdTC.rejected, (state, action) => {
        state.apiKeyByIdStatus = 'failed'
      })

      .addCase(apiKeyAsyncActions.CleanGetApiByIdKeysTC.fulfilled, (state, action) => {
        state.apiKeyById = undefined
        state.apiKeyByIdStatus = 'idle'
      })

      .addCase(apiKeyAsyncActions.CreateApiKeyTC.pending, (state, action) => {
        state.createdApiKeyStatus = 'loading'
      })
      .addCase(apiKeyAsyncActions.CreateApiKeyTC.rejected, (state, action) => {
        state.errors = action.payload?.fieldsErrors || []
        state.createdApiKeyStatus = 'failed'
      })
      .addCase(apiKeyAsyncActions.CreateApiKeyTC.fulfilled, (state, action) => {
        state.createdApiKeyResult = action.payload
        state.createdApiKeyStatus = 'succeeded'
      })

      .addCase(apiKeyAsyncActions.UpdateApiKeyTC.pending, (state, action) => {
        state.updatedApiKeyStatus = 'loading'
      })
      .addCase(apiKeyAsyncActions.UpdateApiKeyTC.rejected, (state, action) => {
        state.errors = action.payload?.fieldsErrors!
        state.updatedApiKeyStatus = 'failed'
      })
      .addCase(apiKeyAsyncActions.UpdateApiKeyTC.fulfilled, (state, action) => {
        state.updatedApiKeyStatus = 'succeeded'
      })

      .addCase(apiKeyAsyncActions.CleanUpdateApiKeyTC.fulfilled, (state, action) => {
        state.updatedApiKeyStatus = 'idle'
      })

      .addCase(apiKeyAsyncActions.CreateMasterApiKeysTC.pending, (state, action) => {
        state.createdMasterApiKeyStatus = 'loading'
      })
      .addCase(apiKeyAsyncActions.CreateMasterApiKeysTC.fulfilled, (state, action) => {
        state.createdMasterApiKeyStatus = 'succeeded'
      })
      .addCase(apiKeyAsyncActions.CreateMasterApiKeysTC.rejected, (state, action) => {
        state.createdMasterApiKeyStatus = 'failed'
      })

      .addCase(apiKeyAsyncActions.GetReadOnlyKeyStatus.pending, (state, action) => {
        state.createdApiReadOnlyStatus = 'loading'
        if (!isStatusFinal(state.createdApiReadOnlyStatusInitial)) state.createdApiReadOnlyStatusInitial = 'loading'
      })
      .addCase(apiKeyAsyncActions.GetReadOnlyKeyStatus.rejected, (state, action) => {
        state.createdApiReadOnlyStatus = 'failed'
        state.createdApiReadOnlyStatusInitial = 'failed'
      })
      .addCase(apiKeyAsyncActions.GetReadOnlyKeyStatus.fulfilled, (state, action) => {
        state.createdApiReadOnlyStatus = 'succeeded'
        state.createdApiReadOnlyStatusInitial = 'succeeded'
      })

      .addCase(apiKeyAsyncActions.CleanCreatedApiKeysTC.fulfilled, (state, action) => {
        state.createdApiKeyResult = undefined
        state.createdApiKeyStatus = 'idle'
      })

      .addCase(apiKeyAsyncActions.RemoveApiKeyTC.pending, (state, action) => {
        state.apiKeyStatusRemove = 'loading'
      })
      .addCase(apiKeyAsyncActions.RemoveApiKeyTC.fulfilled, (state, action) => {
        state.apiKeyStatusRemove = 'succeeded'
      })
      .addCase(apiKeyAsyncActions.RemoveApiKeyTC.rejected, (state, action) => {
        state.errors = action.payload?.fieldsErrors!
        state.apiKeyStatusRemove = 'failed'
      })
    // todo add and start to use CleanRemoveApiKeyTC
  },
})

export const apiKeyActions = {
  ...slice.actions,
  ...apiKeyAsyncActions,
}

export const apiKeyReducer = slice.reducer
