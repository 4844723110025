import clsx from 'clsx'
import { memo, ReactNode } from 'react'
import { Popup } from '@/ui/molecules'
import { Portal } from '@/ui/utils/Portal'
import style from './style.module.scss'
import { useMobileSizeDetect } from '@/utils'

export enum EInteractiveModalVariant {
  DEFAULT = 'DEFAULT',
  MEDIUM = 'MEDIUM',
  WIDE = 'WIDE',
}

export type InteractiveModalProps = {
  /** is Modal visible */
  isOpen?: boolean

  /** callback to call on outsideClick */
  onClose?: () => void

  /** components from InteractiveModalParts or any custom components */
  children: ReactNode

  /** Wrapper classname */
  className?: string

  /** Container (BG) */
  bgClassName?: string

  variant?: EInteractiveModalVariant
}

export const InteractiveModal = memo<InteractiveModalProps>(
  ({ isOpen = false, onClose, children, className, variant = EInteractiveModalVariant.DEFAULT, bgClassName }) => {
    const [isMobile] = useMobileSizeDetect()

    return (
      <Portal>
        <Popup
          onClose={onClose}
          bgClassName={bgClassName}
          className={clsx(isMobile && style.mobile, style.popup, className, {
            [style.wide]: variant === EInteractiveModalVariant.WIDE,
            [style.medium]: variant === EInteractiveModalVariant.MEDIUM,
          })}
          isOpen={isOpen}
        >
          {children}
        </Popup>
      </Portal>
    )
  }
)
