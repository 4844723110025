import { memo } from 'react'
import style from './style.module.scss'
import { t, Trans } from '@lingui/macro'

/** Copyrights at the bottom of the page */
export const Rights = memo(() => {
  const year = new Date().getFullYear()

  return (
    <span className={style.text}>
      {t({ id: 'footer.rights', message: 'All rights reserved.' })} © {year}
      {/* 
      Dev Team LLP
      <br />
      {t({ id: 'footer.address', message: 'Republic of Kazakhstan, Z05H9K3, Astana, 16 Dostyk street' })}
       */}
    </span>
  )
})
