import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './dashboard.state'
import { dashboardAsyncActions } from './dashboard.actions'
import { isArray } from 'lodash-es'
import { DashboardFilterType } from './dashboard.types'
import { TradeDurationGranularity } from '@/utils/types/contracts.types'

const slice = createSlice({
  name: '[Tiger Trade Dashboard]',
  initialState,
  reducers: {
    resetBoard(state) {
      state.dashboardStatus = 'idle'
      state.dashboard = []
    },
    setBoardFilters(state, action: PayloadAction<DashboardFilterType>) {
      const { durationType } = action.payload

      const additionalGranularity = durationType ? TradeDurationGranularity[durationType] : undefined

      const groupBy = action.payload.groupBy !== undefined ? action.payload.groupBy : additionalGranularity

      if (durationType) {
        localStorage.setItem('dashboard_durationType', durationType)
      }

      localStorage.setItem('dashboard_groupBy', groupBy !== undefined ? groupBy : 'undefined')

      state.dashboardFilter = {
        ...action.payload,
        groupBy,
      }
    },
  },
  extraReducers: builder => {
    // Board
    builder
      .addCase(dashboardAsyncActions.GetBoardTC.pending, (state, action) => {
        state.dashboardStatus = 'loading'
      })
      .addCase(dashboardAsyncActions.GetBoardTC.rejected, (state, action) => {
        state.dashboardStatus = 'failed'
      })
      .addCase(dashboardAsyncActions.GetBoardTC.fulfilled, (state, action) => {
        const dashboard = action.payload
        state.dashboard = isArray(dashboard) ? dashboard : []
        state.dashboardStatus = 'succeeded'
      })
  },
})

export const dashboardActions = {
  ...slice.actions,
  ...dashboardAsyncActions,
}

export const dashboardReducer = slice.reducer
