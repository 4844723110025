import { FC } from 'react'
import { t } from '@lingui/macro'
import { HowItWorksQuestions } from '@/ui/molecules'
import s from './style.module.scss'

const ReferralHowLeftBar: FC = () => {
  const QUESTIONS = [
    {
      question: t({
        id: 'referralHowItWorks.left.question-1',
        message: `When will I get my referral payment?`,
      }),
      text: t({
        id: 'core.cashbackTimeTerms',
        message: `On the following day after 05:00 (UTC+0) for Binance and OKX, on the following day after 08:00 (UTC+0) for Bybit`,
      }),
    },
    {
      question: t({
        id: 'referralHowItWorks.left.question-2',
        message: `Where will it be credited?`,
      }),
      text: t({
        id: 'referralHowItWorks.left.answer-2',
        message: `Your Spot wallet balance`,
      }),
    },
    {
      question: t({
        id: 'referralHowItWorks.left.question-3',
        message: `Can I change the referral code of my inviter?`,
      }),
      text: t({
        id: 'referralHowItWorks.left.answer-3',
        message: `Yes, within three days after registration`,
      }),
    },
  ]

  return (
    <div className={s.root}>
      <HowItWorksQuestions questions={QUESTIONS} />
    </div>
  )
}

export { ReferralHowLeftBar }
