import { ALLOW_LANGUAGES_CODES, TLanguage } from '@/core/constants'
import { storage } from '@/utils/lib/storage'
import { TableColumnKeys } from '../trades/trades.utils'
import { E_BINANCE_VIP_CONSENT_PERMISSIONS } from '@/ui/molecules/BinanceVipConsentForm/BinanceVipConsentForm.types'

export const getSystemLocale = (): TLanguage => {
  const navigatorLocale = navigator.language.split('-')[0] as TLanguage
  return ALLOW_LANGUAGES_CODES?.includes(navigatorLocale) ? navigatorLocale : 'en'
}

export const getUserLocale = () => {
  const storedLocale = storage.getLocale() as TLanguage
  return ALLOW_LANGUAGES_CODES?.includes(storedLocale) ? storedLocale : getSystemLocale()
}

export type TradesTableItem = {
  isHidden: boolean
  sortable: boolean
  value: TableColumnKeys
  colorBadge: boolean | null
  privacy: boolean
}

export function getDefaultTradesTablePreset(): TradesTableItem[] {
  return [
    {
      isHidden: false,
      sortable: true,
      value: 'symbol',
      colorBadge: null,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: false,
      value: 'tags',
      colorBadge: null,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'avg_price_entry',
      colorBadge: null,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'avg_price_exit',
      colorBadge: null,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'open_time',
      colorBadge: null,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'close_time',
      colorBadge: null,
      privacy: false,
    },

    {
      isHidden: false,
      sortable: true,
      value: 'commission',
      colorBadge: null,
      privacy: true,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'duration',
      colorBadge: null,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'funding',
      colorBadge: false,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'leverage',
      colorBadge: null,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'max_loose_percent',
      colorBadge: null,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'max_win_percent',
      colorBadge: null,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'net_profit',
      colorBadge: false,
      privacy: true,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'peak_qty',
      colorBadge: null,
      privacy: true,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'percent',
      colorBadge: true,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'profit_deposit',
      colorBadge: false,
      privacy: false,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'qty',
      colorBadge: null,
      privacy: true,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'realized_pnl',
      colorBadge: false,
      privacy: true,
    },
    {
      isHidden: false,
      sortable: true,
      value: 'volume',
      colorBadge: null,
      privacy: true,
    },
  ]
}

export const checkMetadataBinanceVipPermisions = (permissionsToTest: E_BINANCE_VIP_CONSENT_PERMISSIONS[]) => {
  const referencePermissions = Object.values(E_BINANCE_VIP_CONSENT_PERMISSIONS)
  return (
    permissionsToTest.length === referencePermissions.length &&
    referencePermissions.every(permission => {
      return permissionsToTest.includes(permission)
    })
  )
}
