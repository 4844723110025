import ReactDOM from 'react-dom'
import { init as SentryInit } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import './styles/index.scss'
import './styles/normalize.css'
// import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production') {
  SentryInit({
    dsn: 'https://b77a0752c65d4db48553e28a20e21aa1@o4504519198703616.ingest.sentry.io/4504519499055104',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    tracesSampleRate: 1.0,
  })
}

const container = document.getElementById('root')

navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister()
  }
})

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  container
)

// reportWebVitals()
