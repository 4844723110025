import { useEffect } from 'react'
import { storage } from '@/utils/lib/storage'
import { useAuth0 } from '@auth0/auth0-react'
import { KeitaroAPI } from '@/backend/api/keitaroAPI'

export const useKeitaroHandler = () => {
  const { isLoading, isAuthenticated } = useAuth0()

  useEffect(() => {
    const storageKeitaroCode = storage.getKeitaroCode()

    if (!storageKeitaroCode || !storageKeitaroCode.keitaroCode) {
      return
    }

    const sendKeitaroLead = async (keitaroCode: string) => {
      try {
        const response = await KeitaroAPI.postAddKeitaroLead({ leadId: keitaroCode })

        if (response.status === 201 || response.status === 200) {
          storage.clearKeitaroCode()
        }
      } catch (error: any) {
        if (error.response && error.response.data) {
          const errorData = error.response.data

          if (errorData.details && errorData.details.some((detail: any) => detail.code === '400')) {
            storage.clearKeitaroCode()
          }
        } else {
          console.error('Произошла ошибка при отправке данных в Keitaro API:', error)
        }
      }
    }

    if (!isLoading && isAuthenticated) {
      sendKeitaroLead(storageKeitaroCode.keitaroCode)
    }
  }, [isLoading, isAuthenticated])
}
