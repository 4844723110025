import { FC, useEffect, useMemo, useState } from 'react'
import style from './style.module.scss'
import clsx from 'clsx'
import { Plural, plural, t } from '@lingui/macro'

type WidgetPercentProps = {
  value: string | number
  deals: number | string
  className?: string
}

export const WidgetPercent: FC<WidgetPercentProps> = ({ value, deals, className }) => {
  const dataValue = Number(value)
  const [widgetClass, setWidgetClass] = useState(style.tigerWidgetPercent)
  const [circleActiveIndex, setCircleActiveIndex] = useState(0)

  useEffect(() => {
    const getColorClass = (value: number) => {
      if (value >= 0 && value < 25) return style.tigerWidgetPercentColor1
      if (value >= 25 && value < 50) return style.tigerWidgetPercentColor2
      if (value >= 50 && value < 75) return style.tigerWidgetPercentColor3
      if (value >= 75 && value <= 100) return style.tigerWidgetPercentColor4
      return ''
    }

    setWidgetClass(clsx(style.tigerWidgetPercent, getColorClass(dataValue), style.tigerWidgetPercentReady))

    const dots = 29
    setCircleActiveIndex(Math.round((dataValue * dots) / 100))
  }, [dataValue])

  return (
    <div className={clsx(widgetClass, className)}>
      <div className={style.tigerWidgetPercentValue}>{dataValue}%</div>
      <div className={style.tigerWidgetPercentNum}>
        <Plural id="trades.dashboard.WidgetPercentDeals" value={deals} />
      </div>
      <div className={style.tigerWidgetPercentText}>
        %{' '}
        {t({
          id: 'trades.dashboard.WidgetPercentPlusDeals',
        })}
      </div>
      <div className={style.tigerWidgetPercentCircle}>
        {[...Array(29)].map((_, index) => (
          <div
            key={index}
            style={{
              transform: `rotate(${-112.5 + 8.08 * index}deg)`,
              transitionDelay: `${0.1 * index}s`,
            }}
            className={index === circleActiveIndex ? clsx(style.tigerWidgetPercentCircleActive) : ''}
          ></div>
        ))}
      </div>
    </div>
  )
}
