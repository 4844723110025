const Mail = () => (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10857_27261)">
      <rect x="0.0166016" y="0.686523" width="13.9663" height="9.62695" rx="1" fill="#C374D2" />
      <g filter="url(#filter0_d_10857_27261)">
        <path
          d="M7.40514 4.20558L13.5711 9.94757C13.9034 10.257 13.6845 10.8135 13.2304 10.8135L0.786262 10.8135C0.329983 10.8135 0.11212 10.2525 0.448814 9.94452L6.72694 4.20254C6.91925 4.02665 7.21441 4.02797 7.40514 4.20558Z"
          fill="#DC82E2"
        />
      </g>
      <g filter="url(#filter1_d_10857_27261)">
        <path
          d="M6.58793 7.03041L-0.00984621 0.789008C-0.33833 0.478268 -0.118412 -0.0742188 0.333761 -0.0742188H13.6493C14.1037 -0.0742188 14.3225 0.482819 13.9896 0.792085L7.27187 7.03348C7.07873 7.21292 6.77945 7.21158 6.58793 7.03041Z"
          fill="#D788E6"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_10857_27261"
        x="-3.71484"
        y="1.07129"
        width="21.4463"
        height="14.7422"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10857_27261" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10857_27261" result="shape" />
      </filter>
      <filter
        id="filter1_d_10857_27261"
        x="-4.16748"
        y="-3.07422"
        width="22.3179"
        height="15.2412"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10857_27261" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10857_27261" result="shape" />
      </filter>
      <clipPath id="clip0_10857_27261">
        <rect x="0.0166016" y="0.686523" width="13.9663" height="9.62695" rx="1" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export { Mail }
