import { FC } from 'react'
import { t } from '@lingui/macro'
import { ReferralCodeApplyForm } from '@/ui/molecules/ReferralCodeApplyForm'
import s from './style.module.scss'
import { useAppSelector } from '@/utils'

type ReferralInviteByType = {
  handleUserInfo?: () => void
}

const ReferralInvitedBy: FC<ReferralInviteByType> = props => {
  const { handleUserInfo } = props
  const {
    overview: { referrerLocked, referrerCode },
  } = useAppSelector(state => state.referrals)

  return (
    <div className={s.root}>
      <div className={s.heading}>
        {!referrerLocked
          ? t({ id: 'referralPage.invitedBy', message: 'Invited by' })
          : t({ id: 'referralPage.refCode', comment: 'refCode' })}
      </div>
      {referrerLocked ? (
        <div className={s.info}>
          {t({ id: 'referralPage.enteredCodeMessage', message: 'You were invited by user with referral code' })}
          <span className={s.infoAccent}> {referrerCode}</span>
          <br />
          <br />
          {t({ id: 'referralPage.changeCode', comment: 'You were invited by user with referral code' })}
          <br />
          <span className={s.infoAccent}> broker.support@tiger.com</span>
        </div>
      ) : (
        <div className={s.info}>
          {t({
            id: 'referralPage.enterCodeMessage',
            message: 'Enter the referral code of the user that had invited you. You can change it before',
          })}
        </div>
      )}
      {!referrerLocked && <ReferralCodeApplyForm handleUserInfo={handleUserInfo} />}
    </div>
  )
}

export { ReferralInvitedBy }
