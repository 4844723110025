import React from 'react'

interface BybitProps {
  className?: string
}

const BybitSmall: React.FC<BybitProps> = props => {
  const { className } = props

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M17.1058 14.1678V7.99976H18.3458V14.1678H17.1058Z" fill="#F7A600" />
      <path
        d="M2.6576 15.9994H0V9.83228H2.55098C3.78998 9.83228 4.51321 10.5084 4.51321 11.5649C4.51321 12.2498 4.0493 12.6906 3.72755 12.8385C4.11077 13.0114 4.60157 13.4013 4.60157 14.2245C4.60157 15.3751 3.78998 15.9994 2.6576 15.9994ZM2.45302 10.907H1.23899V12.3276H2.45302C2.97935 12.3276 3.27421 12.0413 3.27421 11.6168C3.27421 11.1923 2.97935 10.907 2.45302 10.907ZM2.53274 13.41H1.23899V14.9256H2.53178C3.09461 14.9256 3.36161 14.5789 3.36161 14.163C3.36161 13.7471 3.09365 13.41 2.53274 13.41Z"
        fill="white"
      />
      <path
        d="M8.38291 13.4695V15.9994H7.1516V13.4695L5.24316 9.83228H6.59069L7.7759 12.3179L8.94478 9.83228H10.2913L8.38291 13.4695Z"
        fill="white"
      />
      <path
        d="M13.8057 15.9994H11.1481V9.83228H13.6991C14.9381 9.83228 15.6613 10.5084 15.6613 11.5649C15.6613 12.2498 15.1974 12.6906 14.8756 12.8385C15.2588 13.0114 15.7496 13.4013 15.7496 14.2245C15.7496 15.3751 14.939 15.9994 13.8057 15.9994ZM13.6011 10.907H12.388V12.3276H13.6011C14.1274 12.3276 14.4223 12.0413 14.4223 11.6168C14.4213 11.1923 14.1265 10.907 13.6011 10.907ZM13.6808 13.41H12.388V14.9256H13.6808C14.2436 14.9256 14.5106 14.5789 14.5106 14.163C14.5106 13.7471 14.2427 13.41 13.6808 13.41Z"
        fill="white"
      />
      <path d="M22.3413 10.907V16.0004H21.1013V10.907H19.4426V9.83228H24V10.907H22.3413Z" fill="white" />
    </svg>
  )
}

export { BybitSmall }
