import { t } from '@lingui/macro'

export enum ActionMargin {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export const TOGGLE_DATA = {
  ADD: {
    label: t({
      id: 'isolatedMargin.handleMargin.toggle.add',
      comment: 'Add margin',
    }),
    value: ActionMargin.ADD,
  },
  REMOVE: {
    label: t({
      id: 'isolatedMargin.handleMargin.toggle.remove',
      comment: 'Remove margin',
    }),
    value: ActionMargin.REMOVE,
  },
}
