import {
  EPositionSide,
  ESpotOrderType,
  IBalanceAsset,
  TMarginType,
  TOrderSide,
  TQuoteBalance,
} from '@tigertrade/binance-ts'
import BigNumber from 'bignumber.js'
import {
  AccountBalance,
  AccountBalanceDetail as AccountBalanceDetailOriginal,
  AccountConfiguration,
  AccountLeverage,
  AccountPosition,
  AlgoOrderListItem,
  APIResponse,
  HistoricAccountPosition,
  Instrument,
  OrderDetails,
} from 'okx-api'

import { exchangeApi } from '@/backend/api/exchange'
import { PositionSideHedgeMode, TOpenOrder } from '@/backend/models/OverviewDTO'
import { BN_ZERO } from '@/core/constants/common'
import { TInstrumentType } from '@/core/types/overview'
import { TOkxInstrumentById } from './instruments'
import {
  AccountBill,
  EOkxInstruments,
  EOkxOrderType,
  EOkxPositionSide,
  TGetOkxMarkPricePayload,
  TGetOkxMarkPriceResponse,
  TOkxLeverageEstimatedInfo,
  TOkxMargin,
  TOkxPosition,
} from './types'

interface AccountBalanceDetail extends AccountBalanceDetailOriginal {
  coinUsdPrice: string
}

export interface IOkxGetLeverageParams {
  instId: string
  mgnMode: TOkxMargin
}

export interface IOkxGetLeverageEstimatedInfoParams extends IOkxGetLeverageParams {
  lever: string
  instType: EOkxInstruments.SWAP
  posSide: EOkxPositionSide
}

export const okxRestService = {
  async getBalance(): Promise<{ data: AccountBalance[] }> {
    return await exchangeApi.okxProxyGet('/api/v5/account/balance', {})
  },
  async getPositions(params: { instType?: EOkxInstruments }): Promise<APIResponse<AccountPosition[]>> {
    return await exchangeApi.okxProxyGet('/api/v5/account/positions', {
      ...params,
    })
  },
  async getOrders(params: { instType?: EOkxInstruments }): Promise<APIResponse<OrderDetails[]>> {
    return await exchangeApi.okxProxyGet('/api/v5/trade/orders-pending', {
      ...params,
    })
  },
  async getAlgoOrders(params: { ordType: EOkxOrderType }): Promise<APIResponse<AlgoOrderListItem[]>> {
    return await exchangeApi.okxProxyGet('/api/v5/trade/orders-algo-pending', {
      ...params,
    })
  },
  async getAlgoOrdersRecursive(
    params: {
      limit?: string
      after?: string
      ordType: EOkxOrderType
    },
    algoOrders: AlgoOrderListItem[] = []
  ): Promise<AlgoOrderListItem[]> {
    const response = await okxRestService.getAlgoOrders(params)
    const currentPageOrders = response.data || []
    algoOrders.push(...currentPageOrders)
    if (Number(params.limit) === currentPageOrders.length) {
      const nextParams = { ...params, after: currentPageOrders[currentPageOrders.length - 1].algoId }
      return await okxRestService.getAlgoOrdersRecursive(nextParams, algoOrders)
    }

    return algoOrders
  },

  async getOrdersRecursive(
    params: {
      limit?: string
      after?: string
      instType?: EOkxInstruments
    },
    orders: OrderDetails[] = []
  ): Promise<OrderDetails[]> {
    const response = await okxRestService.getOrders(params)
    const currentPageOrders = response.data || []
    orders.push(...currentPageOrders)
    if (Number(params.limit) === currentPageOrders.length) {
      const nextParams = { ...params, after: currentPageOrders[currentPageOrders.length - 1].ordId }
      return await okxRestService.getOrdersRecursive(nextParams, orders)
    }

    return orders
  },

  async getInstruments(params: { instType: EOkxInstruments }): Promise<APIResponse<Instrument[]>> {
    return await exchangeApi.okxProxyGet('/api/v5/public/instruments', {
      ...params,
    })
  },
  async getLeverage(params: IOkxGetLeverageParams): Promise<APIResponse<AccountLeverage[]>> {
    return await exchangeApi.okxProxyGet('/api/v5/account/leverage-info', {
      ...params,
    })
  },
  async getLeverageEstimatedInfo(
    params: IOkxGetLeverageEstimatedInfoParams
  ): Promise<APIResponse<TOkxLeverageEstimatedInfo>> {
    return await exchangeApi.okxProxyGet('/api/v5/account/adjust-leverage-info', {
      ...params,
    })
  },
  async getAccountConfiguration(): Promise<APIResponse<AccountConfiguration>> {
    return await exchangeApi.okxProxyGet('/api/v5/account/config', {})
  },
  async getOkxMarkPrice(params: TGetOkxMarkPricePayload): Promise<APIResponse<TGetOkxMarkPriceResponse>> {
    return await exchangeApi.okxProxyGet('/api/v5/public/mark-price', params)
  },
  async getAccountHistory(params: Record<string, string>): Promise<APIResponse<HistoricAccountPosition[]>> {
    return await exchangeApi.okxProxyGet('/api/v5/account/positions-history', params)
  },
  async getAccountBills(params: Record<string, string>): Promise<APIResponse<AccountBill[]>> {
    return await exchangeApi.okxProxyGet('/api/v5/account/bills-archive', params)
  },
}

const positionSideMapper = {
  [EOkxPositionSide.long]: EPositionSide.LONG,
  [EOkxPositionSide.short]: EPositionSide.SHORT,
  [EOkxPositionSide.net]: EPositionSide.BOTH,
}

const orderSideMapper: Record<string, TOrderSide> = {
  buy: 'BUY',
  sell: 'SELL',
}

export const okxDataMappers = {
  positionMapper: (position: AccountPosition, instruments: TOkxInstrumentById): TOkxPosition => {
    const instrument = instruments.get(position.instId)
    const positionSide = positionSideMapper[position.posSide as EOkxPositionSide]
    const positionSideOutput =
      position.posSide === EOkxPositionSide.net
        ? Number(position.pos) >= 0
          ? EPositionSide.LONG
          : EPositionSide.SHORT
        : positionSideMapper[position.posSide as EOkxPositionSide]
    const uid = position.instId + positionSideOutput
    const unPnlOutput = new BigNumber(position.upl).dp(2, 1).toString()
    const unPnlPercentOutput = new BigNumber(position.uplRatio).multipliedBy(100).dp(2, 1).toString()
    const liquidationPriceOutput = position.liqPx ? new BigNumber(position.liqPx).dp(2, 1).toString() : ''

    /** "1" to "5" (string) */
    const adl = position.adl ? Number(position.adl) : 1

    return {
      adl,
      baseAsset: instrument?.settCcy || '',
      quoteAsset: instrument?.quoteCcy || '',
      uid: uid,
      entryPrice: position.avgPx,
      positionAmt: position.pos,
      liquidationPrice: liquidationPriceOutput,
      unrealizedPnl: unPnlOutput,
      unrealizedPnlPercent: unPnlPercentOutput,
      positionSide: positionSide,
      positionSideOutput: positionSideOutput as PositionSideHedgeMode,
      symbol: position.instId,
      updateTime: Number(position.uTime),
      marginType: position.mgnMode as Lowercase<TMarginType>,
      ccy: position.ccy,
    }
  },
  oprderMapper: (order: OrderDetails, instrumentType: TInstrumentType): TOpenOrder => {
    const side = orderSideMapper[order.side]
    return {
      executedQty: order.accFillSz,
      orderId: order.ordId,
      origQty: order.sz,
      price: order.px,
      priceOutput: order.px,
      side: side,
      status: order.state,
      stopPrice: '',
      symbol: order.instId,
      time: Number(order.cTime),
      updateTime: Number(order.uTime),
      type: order.ordType,
      orderTypeOutput: order.ordType.toUpperCase(),
      instrumentType: instrumentType,
      isAlgo: false,
    }
  },
  algoOrderMapper: (order: AlgoOrderListItem, instrumentType: TInstrumentType): TOpenOrder => {
    const side = orderSideMapper[order.side]
    const orderType =
      Number(order.tpOrdPx) > 0 || Number(order.slOrdPx) > 0
        ? 'STOP_LIMIT'
        : Number(order.tpTriggerPx) > 0
        ? ESpotOrderType.TAKE_PROFIT
        : Number(order.slTriggerPx) > 0
        ? ESpotOrderType.STOP_LOSS
        : order.ordType
    return {
      executedQty: '',
      orderId: order.algoId,
      origQty: order.sz,
      price: order.ordPx,
      priceOutput: order.slTriggerPx || order.tpTriggerPx,
      side: side,
      status: order.state,
      stopPrice: order.slTriggerPx || order.tpTriggerPx,
      symbol: order.instId,
      time: Number(order.cTime),
      updateTime: Number(order.cTime),
      type: order.ordType,
      orderTypeOutput: orderType.toUpperCase(),
      instrumentType: instrumentType,
      isAlgo: true,
    }
  },
  balanceMapper: (balance: AccountBalanceDetail): IBalanceAsset => {
    return {
      assetId: balance.ccy,
      assetBalance: Number(balance.cashBal),
      quoteBalance: {
        USD: Number(balance.eqUsd),
      },
    }
  },
}

interface IBalanceReducerInterface {
  totalMarginBalance: BigNumber
  totalAssetsUsd: BigNumber
}

export const okxReducers = {
  balanceReducer: (
    balances: AccountBalanceDetail[]
  ): {
    totalMarginBalance: string
    totalAssetsOutput: TQuoteBalance
  } => {
    const { totalMarginBalance, totalAssetsUsd } = balances.reduce<IBalanceReducerInterface>(
      (acc, balance) => {
        return {
          ...acc,
          totalAssetsUsd: acc.totalAssetsUsd.plus(balance.eqUsd),
          totalMarginBalance: acc.totalMarginBalance.plus(
            new BigNumber(balance.availEq).multipliedBy(balance.coinUsdPrice)
          ),
        }
      },
      {
        totalMarginBalance: BN_ZERO,
        totalAssetsUsd: BN_ZERO,
      } as IBalanceReducerInterface
    )

    return {
      totalMarginBalance: totalMarginBalance.toString(),
      totalAssetsOutput: {
        USD: totalAssetsUsd.toNumber(),
      },
    }
  },
  positionsReducer: (
    positions: AccountPosition[]
  ): {
    unrealizedPnl: string
  } => {
    const unPnl = positions.reduce((acc, position) => {
      return acc.plus(position.upl)
    }, BN_ZERO)

    return {
      unrealizedPnl: unPnl.toString(),
    }
  },
}
