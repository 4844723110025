import { EXCHANGE_REST_HEADER_NAME } from '@/core/constants'
import { axiosOKXMainService } from '../instances/okx'

const setupOKXAxiosService = (params: { accessToken?: string }) => {
  if (params.accessToken) {
    axiosOKXMainService.defaults.headers['Authorization'] = `Bearer ${params.accessToken}`
  }

  axiosOKXMainService.defaults.headers[EXCHANGE_REST_HEADER_NAME] = 'OKX_BROKER'
}

export { setupOKXAxiosService }
