import { FC } from 'react'

interface IndicatorsProps {
  className?: string
  size?: number
}

export const Indicators: FC<IndicatorsProps> = ({ size = 16, className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.81427 13.5255C9.81427 13.4359 9.85554 13.343 9.92158 13.2799L11.036 12.0782L9.89269 10.8897C9.82665 10.8267 9.78125 10.7237 9.78125 10.6341C9.78125 10.4449 9.96699 10.2988 10.1981 10.2988C10.388 10.2988 10.5242 10.3719 10.6645 10.5478L11.5395 11.5935H11.5891L12.46 10.5478C12.6044 10.3719 12.7324 10.3021 12.9264 10.3021C13.1575 10.3021 13.335 10.4416 13.335 10.6408C13.335 10.7271 13.2978 10.8101 13.2277 10.8764L12.0926 12.0782L13.2153 13.2566C13.2978 13.333 13.3432 13.4259 13.3432 13.5189C13.3432 13.7114 13.1575 13.8608 12.9346 13.8608C12.7406 13.8608 12.5962 13.7845 12.4558 13.6119L11.5891 12.5662H11.5395L10.6893 13.6085C10.5448 13.7878 10.4128 13.8608 10.227 13.8608C10 13.8608 9.81427 13.7148 9.81427 13.5255Z"
        fill="currentColor"
      />
      <path
        d="M4.88606 14C4.559 14 4.25423 13.9727 3.97176 13.9182C3.69425 13.8687 3.46877 13.7795 3.29532 13.6506C3.12188 13.5218 3.03516 13.3458 3.03516 13.1228C3.03516 12.9048 3.09958 12.7338 3.22842 12.6099C3.36222 12.4811 3.53319 12.4167 3.74133 12.4167C3.88999 12.4167 4.04857 12.4315 4.21706 12.4613C4.39051 12.491 4.64324 12.5059 4.97526 12.5059C5.40144 12.5059 5.73595 12.3795 5.97877 12.1268C6.22655 11.8691 6.39256 11.4379 6.4768 10.8334L7.34651 4.6042C7.48526 3.63786 7.82224 2.91187 8.35745 2.42622C8.8976 1.94058 9.59138 1.69775 10.4388 1.69775C10.7609 1.69775 11.0632 1.72501 11.3457 1.77952C11.6281 1.82908 11.8561 1.91828 12.0295 2.04712C12.203 2.17101 12.2897 2.34446 12.2897 2.56746C12.2897 2.79046 12.2228 2.96638 12.089 3.09523C11.9601 3.21912 11.7892 3.28106 11.5761 3.28106C11.4324 3.28106 11.2738 3.26619 11.1004 3.23646C10.9319 3.20673 10.6816 3.19186 10.3496 3.19186C9.91845 3.19186 9.579 3.32071 9.33122 3.5784C9.08839 3.83113 8.92486 4.25731 8.84061 4.85693L7.97091 11.0935C7.83711 12.0599 7.50013 12.7859 6.95997 13.2715C6.42477 13.7571 5.73347 14 4.88606 14ZM4.52183 8.12018C4.29883 8.12018 4.11299 8.04833 3.96433 7.90461C3.81566 7.7609 3.74133 7.5825 3.74133 7.36941C3.74133 7.15137 3.81318 6.97297 3.95689 6.83421C4.10556 6.6905 4.29139 6.61864 4.5144 6.61864H11.3605C11.5786 6.61864 11.7619 6.6905 11.9106 6.83421C12.0593 6.97792 12.1336 7.15632 12.1336 7.36941C12.1336 7.5825 12.0593 7.7609 11.9106 7.90461C11.7669 8.04833 11.586 8.12018 11.368 8.12018H4.52183Z"
        fill="currentColor"
      />
    </svg>
  )
}
