interface IIconProps {
  className?: string
}

export const Settings = (props: IIconProps) => {
  const { className } = props

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 9.66004V8.34004C1.5 7.56004 2.1375 6.91504 2.925 6.91504C4.2825 6.91504 4.8375 5.95504 4.155 4.77754C3.765 4.10254 3.9975 3.22504 4.68 2.83504L5.9775 2.09254C6.57 1.74004 7.335 1.95004 7.6875 2.54254L7.77 2.68504C8.445 3.86254 9.555 3.86254 10.2375 2.68504L10.32 2.54254C10.6725 1.95004 11.4375 1.74004 12.03 2.09254L13.3275 2.83504C14.01 3.22504 14.2425 4.10254 13.8525 4.77754C13.17 5.95504 13.725 6.91504 15.0825 6.91504C15.8625 6.91504 16.5075 7.55254 16.5075 8.34004V9.66004C16.5075 10.44 15.87 11.085 15.0825 11.085C13.725 11.085 13.17 12.045 13.8525 13.2225C14.2425 13.905 14.01 14.775 13.3275 15.165L12.03 15.9075C11.4375 16.26 10.6725 16.05 10.32 15.4575L10.2375 15.315C9.5625 14.1375 8.4525 14.1375 7.77 15.315L7.6875 15.4575C7.335 16.05 6.57 16.26 5.9775 15.9075L4.68 15.165C3.9975 14.775 3.765 13.8975 4.155 13.2225C4.8375 12.045 4.2825 11.085 2.925 11.085C2.1375 11.085 1.5 10.44 1.5 9.66004Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
