import { TRiskManagerTradingStatus } from '@/redux/riskManager/riskManager.types'
import { urls } from '@/router/urls'
import {
  INotificationConfig,
  NotififcationPresets,
  SystemNotifiactionIcon,
} from '@/ui/molecules/SystemNotifications/notifications.type'
import { formatDate } from '@/utils/lib/formatDate'
import { t } from '@lingui/macro'

interface TradingAlertProps {
  pathname: string
  tradingStatus?: TRiskManagerTradingStatus
}

export function getTradeSuspendedAlert({ pathname, tradingStatus }: TradingAlertProps): INotificationConfig {
  const preset: INotificationConfig = {
    id: NotififcationPresets.TradeSuspendedAlert,
    icon: SystemNotifiactionIcon.Alert,
    heading: '',
    description: '',
    shouldShown: tradingStatus?.tradingEnabled === false,
    availableExchange: ['BINANCE_BROKER_FUTURE'],
    isClosable: true,
    link: {
      uri: `/risk-manager`,
    },
  }

  let tradingSuspendedHeading: string = t({
    id: 'core.systemAlert.tradingSuspended.heading',
    message: 'Trading has been suspended',
  })

  if (tradingStatus?.until) {
    const remaining = t({
      id: 'core.systemAlert.tradingSuspended.until',
      message: 'until',
    })

    const date = formatDate(new Date(tradingStatus.until))

    tradingSuspendedHeading += ` ${remaining} ${date}.`
  } else {
    tradingSuspendedHeading += '.'
  }

  if (pathname === urls.api) {
    preset.heading = tradingSuspendedHeading
    preset.description = t({
      id: 'core.systemAlert.keysDisabled.description',
      message: 'You can not create new API-keys or edit existing while trading is suspended.',
    })
  } else {
    preset.heading = tradingSuspendedHeading
    preset.description = t({
      id: 'core.systemAlert.tradingSuspended.description',
      message: 'Trading has been suspended due to reaching the daily drawdown in the system Taip Risk.',
    })

    // disabled for VoluntaryBlock
    if (tradingStatus?.until) {
      preset.description = ''
    }
  }

  return preset
}
