import { DashboardWidgetItem } from '@/ui/molecules'
import style from './style.module.scss'
import { useAppSelector } from '@/utils'
import { findDashboardWidgetValue, percentageLong, percentageShort } from '../../utils'
import {
  DEFAULT_LEVERAGE_VALUE,
  DEFAULT_VALUE_PERCENT_SYMBOL,
  DEFAULT_VALUE_WITH_SYMBOL,
  DEFAULT_VALUE_WITH_ZERO,
  DEFAULT_VALUE_WITHOUT_SYMBOL,
  handleRound,
} from '@/ui/organisms/DiaryRightBar/components/WeekCard/WeekCard'
import { t } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { WidgetPercent } from '../Widget/WidgetPercent'
import { WidgetDistribution } from '../WidgetDistribution/WidgetDistribution'
import { ECharts } from '@/ui/molecules/ECharts/ECharts'
import { FC } from 'react'
import { ProfitByReason } from '@/ui/molecules/ECharts/widgets/ProfitByReason/ProfitByReason'

export const DashboardWidget: FC = () => {
  const { dashboard } = useAppSelector(state => state.dashboard)

  const {
    volume,
    commissionUsdt,
    incomeUsdt,
    payOffRatio,
    averageLeverage,
    averageLeverageMaxLoosePercentage,
    averageWinningPercent,
    averageLoosingPercent,
    winningPercent,
    pieSide,
    incomeUsdtAccumulative,
    incomePercentBalance,
    profitByDays,
    profitByReasons,
  } = findDashboardWidgetValue(dashboard)

  const short = percentageShort(pieSide.LONG ?? '', pieSide.SHORT ?? '')
  const long = percentageLong(pieSide.LONG ?? '', pieSide.SHORT ?? 0)

  return (
    <div className={style.grid}>
      <WidgetPercent
        className={style.mediumBox}
        value={winningPercent.value ? handleRound(winningPercent.value) : 0}
        deals={winningPercent.counter ? winningPercent.counter : DEFAULT_VALUE_WITH_ZERO}
      />

      <DashboardWidgetItem
        className={style.smallBox}
        value={volume ? handleRound(volume) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
        text={t({ id: 'trades.dashboard.volume' })}
      />

      <DashboardWidgetItem
        className={style.smallBox}
        sourceValue={Number(incomeUsdt)}
        value={incomeUsdt ? handleRound(incomeUsdt) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
        text={t({ id: 'trades.dashboard.incomeUsdt' })}
        isCountPercent
      />

      <WidgetDistribution className={style.mediumBox} short={short} long={long} />

      <DashboardWidgetItem
        className={style.smallBox}
        value={averageLeverage ? 'x' + new BigNumber(averageLeverage).toFixed(2) : DEFAULT_LEVERAGE_VALUE}
        text={t({ id: 'trades.dashboard.leverage' })}
        hintText={t({ id: 'trades.dashboard.leverageHint' })}
      />

      <DashboardWidgetItem
        className={style.smallBox}
        value={averageWinningPercent ? handleRound(averageWinningPercent) + ' %' : DEFAULT_VALUE_PERCENT_SYMBOL}
        text={t({ id: 'trades.dashboard.averageWiningPercent' })}
      />

      <DashboardWidgetItem
        className={style.smallBox}
        value={commissionUsdt ? handleRound(commissionUsdt) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}
        text={t({ id: 'trades.dashboard.commission' })}
      />

      <DashboardWidgetItem
        className={style.smallBox}
        value={payOffRatio ? handleRound(payOffRatio) : DEFAULT_VALUE_WITHOUT_SYMBOL}
        text={t({ id: 'trades.dashboard.profitRatio' })}
        hintText={t({ id: 'trades.dashboard.profitRatioHint' })}
      />

      <DashboardWidgetItem
        className={style.smallBox}
        value={
          averageLeverageMaxLoosePercentage
            ? handleRound(averageLeverageMaxLoosePercentage) + ' %'
            : DEFAULT_VALUE_PERCENT_SYMBOL
        }
        text={t({ id: 'trades.dashboard.maxLowPercent' })}
        hintText={t({ id: 'trades.dashboard.maxLowPercentHint' })}
      />

      <DashboardWidgetItem
        className={style.smallBox}
        value={averageLoosingPercent ? handleRound(averageLoosingPercent) + ' %' : DEFAULT_VALUE_PERCENT_SYMBOL}
        text={t({ id: 'trades.dashboard.averageLosingPercent' })}
      />

      {!!incomePercentBalance.widget && (
        <ECharts
          widget={incomePercentBalance.widget}
          serverData={incomePercentBalance.serverData}
          className={style.chartBox}
        />
      )}
      {!!incomeUsdtAccumulative.widget && (
        <ECharts
          widget={incomeUsdtAccumulative.widget}
          serverData={incomeUsdtAccumulative.serverData}
          className={style.chartBox}
        />
      )}

      {profitByReasons && <ProfitByReason serverData={profitByReasons.serverData} />}

      {!!profitByDays.widget && (
        <ECharts
          widget={profitByDays.widget}
          serverData={profitByDays.serverData}
          className={style.chartBox}
          hideLegends
          hideToolbar
        />
      )}
    </div>
  )
}
