import { TGetReferralEarnings } from '@/backend/models/ReferralsDTO'

export const referralEarningsMapper = (earnings: TGetReferralEarnings[]): TGetReferralEarnings[] => {
  return earnings.map(
    ({ partnerEarnings, userVolume, userId, userCommission, userJoined, exchangeSection, tradingDay }) => ({
      userId: userId || '',
      exchangeSection: exchangeSection,
      partnerEarnings: partnerEarnings || 0,
      tradingDay: tradingDay || '',
      userCommission: userCommission || 0,
      userJoined: userJoined || '',
      userVolume: userVolume || 0,
    })
  )
}
