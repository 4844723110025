import { ArrowDown } from './ArrowDown'
import { ArrowLeft } from './ArrowLeft'
import { ArrowRight } from './ArrowRight'
import { ArrowUp } from './ArrowUp'
import { ArrowSmallLeft } from './ArrowSmallLeft'
import { LongArrowDown } from './LongArrowDown'
import { LongArrowRight } from './LongArrowRight'

export const Arrows = {
  ArrowRight,
  ArrowDown,
  ArrowLeft,
  ArrowUp,
  ArrowSmallLeft,
  LongArrowDown,
  LongArrowRight,
}
