import { FC, useMemo } from 'react'
import { t } from '@lingui/macro'
import { Order } from '@/ui/organisms/Order'
import { Loader } from '@/ui/kit/Loader'
import { useAppSelector } from '@/utils'
import { useOverview } from '@/utils/hooks/useOverview'
import s from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

interface IOrdersTableProps {}

const OrdersTable: FC<IOrdersTableProps> = () => {
  const { statusOrders } = useAppSelector(state => state.overview)
  const { allOpenOrders } = useOverview()

  const HEADERS = useMemo(
    () => [
      t({ message: 'Ticker', id: 'core.ticker' }),
      t({ message: 'Price', id: 'core.price' }),
      t({ message: 'Size', id: 'core.size' }),
      t({ message: 'Status', id: 'core.status' }),
      '',
    ],
    []
  )

  if (statusOrders === 'loading') {
    return <Loader className={s.loader} dataTestId={DataTestIds.LoaderOrders} />
  }

  if (!allOpenOrders.length) {
    return (
      <div>
        <div className={s.empty} data-testid={DataTestIds.OPEmptyOrders}>
          {t({ message: 'You have no submitted orders', id: 'orders.noOrders' })}
        </div>
      </div>
    )
  }

  return (
    <div className={s.root}>
      <div className={s.header}>
        {HEADERS.map(header => (
          <div key={header}>{header}</div>
        ))}
      </div>

      {allOpenOrders?.map(order => (
        <Order.Table key={order.orderId} {...order} />
      ))}
    </div>
  )
}

export { OrdersTable }
