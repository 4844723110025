import { CashbackType } from '@/backend/api'
import { InitialState } from './cashback.types'

export const initialState: InitialState = {
  cashback: {} as CashbackType,
  cashbackTerms: {
    allocationBalance: [],
    tradeVolume: [],
    customReferralCode: [],
    promocode: [],
    priorityReferralCode: [],
    teamMembership: [],
    userReferralCode: [],
    bybitPro: [],
  },
  cashbackTermsStatus: 'idle',
  nextCalculationTime: undefined,
  prevCalculationTime: undefined,
  status: 'idle',
}

export default initialState
