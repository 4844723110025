import { useNavigate, useParams } from 'react-router-dom'
import style from './style.module.scss'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import clsx from 'clsx'
import { t } from '@lingui/macro'
import { AddressForm } from '@/ui/organisms/AddressForm'
import { useActions, useAppSelector } from '@/utils'
import { addressActions } from '@/redux/address/address.slice'
import { useEffect } from 'react'
import { PageSetupGoogleAuth } from '@/ui/organisms/WalletSetupGoogleAuth'
import { useGoogleAuthCode, useGoogleAuthSettings } from '@/utils/hooks'
import { urls } from '@/router/urls'
import { AddressDTO } from '@/backend/models/AddressDTO'
import { SVG } from '@/assets/svg'
import { Button } from '@/ui/kit'

export const EditAddressesPage = () => {
  const { id } = useParams()
  const addressId = id ?? ''
  const navigate = useNavigate()
  const { getAddressesTC, getAddressByIdTC, setCleanUpdateStatus, cleanAddressById } = useActions(addressActions)
  const { addressById, addressByIdStatus } = useAppSelector(state => state.address)
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()
  const status = useAppSelector(state => state.address.updatedAddressStatus)
  const isSuccess = status === 'succeeded'
  const isFetchedAddressStatusSuccess = addressByIdStatus === 'succeeded'

  useEffect(() => {
    if (addressByIdStatus === 'failed') navigate(-1)
  }, [addressByIdStatus, navigate])

  useEffect(() => {
    if (isSuccess) navigate(-1)

    return () => {
      setCleanUpdateStatus()
    }
  }, [isSuccess, navigate, setCleanUpdateStatus])

  useEffect(() => {
    getAddressesTC()
    getAddressByIdTC({ id: addressId })

    return () => {
      cleanAddressById()
    }
  }, [getAddressesTC, addressId, getAddressByIdTC, cleanAddressById])

  if (!googleAuthSettings.settings.enabled) {
    return (
      <div className={style.content}>
        <PageSetupGoogleAuth
          googleAuthSettings={googleAuthSettings}
          googleAuthCode={googleAuthCode}
          backRoute={urls.addresses}
        />
      </div>
    )
  }

  return (
    <div className={clsx(style.content)}>
      <div className={style.container}>
        <TitleH1
          label={t({
            id: 'createAddressPage.edit.title',
            message: `Edit Withdrawal Address`,
          })}
          className={style.content_title}
        />
        <Button.Primary
          onClick={() => navigate(-1)}
          className={style.arrowBackWrap}
          leftIcon={<SVG.Arrows.ArrowLeft className={style.arrow} />}
        />
      </div>
      {isFetchedAddressStatusSuccess && <AddressForm addressDTO={addressById as AddressDTO} id={id} />}
    </div>
  )
}
