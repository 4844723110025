import { ZoomIn } from './ZoomIn'
import { ZoomOut } from './ZoomOut'
import { FullScreen } from './FullScreen'
import { Indicators } from './Indicators'
import { ScreenShot } from './ScreenShot'
import { ZoomReset } from './ZoomReset'
// import { Settings } from '../settings'

export const Chart = {
  ZoomIn,
  ZoomOut,
  ZoomReset,
  FullScreen,
  Indicators,
  ScreenShot,
  // Settings,
}
