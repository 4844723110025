import clsx from 'clsx'
import { memo } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { useMobileSizeDetect } from '@/utils'
import { SVG } from '@/assets/svg'

type PromptProps = {
  /** token symbol */
  coin?: string | null

  /** network symbol */
  networkSymbol?: string | number

  /** network type or full name */
  networkType?: string | number | null

  /** prompt type **/
  promptType: 'kyc' | 'transaction' | 'balanceAsset'

  /** style for description **/
  descriptionClassName?: string
}

/** Warning to user about using correct coin + network */
export const Prompt = memo<PromptProps>(({ coin, networkSymbol, networkType, descriptionClassName, promptType }) => {
  const [isMobile] = useMobileSizeDetect()
  const isWarningSpecified = coin && networkSymbol && networkType

  return (
    <div className={clsx(style.prompt, isMobile && style.mobile)}>
      {promptType === 'balanceAsset' && (
        <div className={clsx(style.description, descriptionClassName)}>
          {t({
            id: 'prompt.balanceAsset',
            message: 'No balance for the required asset',
          })}
        </div>
      )}
      {promptType === 'kyc' && (
        <div className={clsx(style.description, descriptionClassName)}>
          {t({
            id: 'prompt.kyc',
            message:
              'Enter the information in accordance with your government ID in Latin letters. Provide the address where you live, as you may be asked to confirm it with a utility bill, bank statement, or an alternative method.',
          })}
        </div>
      )}
      {promptType === 'transaction' && (
        <div className={clsx(style.description, descriptionClassName)}>
          {isWarningSpecified ? (
            <>
              {t({
                id: 'prompt.transaction.network.message-1',
                message: 'Make sure to send only',
              })}{' '}
              <span className={style.coin}>{coin}</span>{' '}
              {t({
                id: 'prompt.transaction.network.message-2',
                message: 'using the selected network',
              })}
              <span className={style.networkSymbol}>{networkSymbol}</span>{' '}
              <span className={style.networkType}>{networkType}</span>{' '}
              {t({
                id: 'prompt.transaction.network.message-3',
                message: 'to this address. Otherwise, assets may be lost.',
              })}
            </>
          ) : (
            t({
              id: 'prompt.transaction.network.default',
              message:
                'Make sure the network you select for input matches the network for output. Otherwise, assets may be lost.',
            })
          )}
        </div>
      )}
      <div className={style.mark}>
        <SVG.OtherIcons.Mark />
      </div>
    </div>
  )
})
