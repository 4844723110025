import axios from 'axios'
import { BINANCE_REQUEST_TIMEOUT } from '@/core/config/common'
import { getCoinmParamsSource } from '@/services/binance/config/coinm'
import { TBinanceAccountsConfig } from '@/services/binance/config/index'
import { getSpotParamsSource } from '@/services/binance/config/spot'
import { getUsdmParamsSource } from '@/services/binance/config/usdm'
import { EBinanceApiType, BINANCE_PING_API } from './constants'

export const getBinanceActivity = async (api: EBinanceApiType) => {
  try {
    const abortController = new AbortController()
    setTimeout(() => abortController.abort(), BINANCE_REQUEST_TIMEOUT)

    await axios.get(BINANCE_PING_API[api], {
      timeout: BINANCE_REQUEST_TIMEOUT,
      signal: abortController.signal,
    })
    return true
  } catch (err) {
    return Promise.reject(false)
  }
}

export const getBinanceConfig = (
  apiActivity: { api: boolean; fapi: boolean; dapi: boolean },
  accessToken: string,
  isVip?: boolean
): TBinanceAccountsConfig => {
  const spotParams = getSpotParamsSource(apiActivity.api, accessToken, isVip)
  const usdmParams = getUsdmParamsSource(apiActivity.fapi, accessToken, isVip)
  const coinmParams = getCoinmParamsSource(apiActivity.dapi, accessToken, isVip)

  return {
    SPOT: spotParams,
    USDT_FUTURE: usdmParams,
    COIN_FUTURE: coinmParams,
  }
}
