import BigNumber from 'bignumber.js'
BigNumber.config({ EXPONENTIAL_AT: [-20, 20] })

/** Get decimals from stringified number and check its length */
export const checkIsDecimalsQuantityCorrect = (amount: string, maximumDecimalsQuantity: number | void) => {
  if (typeof maximumDecimalsQuantity !== 'number' || !amount) {
    return false
  }

  return getDecimals(amount).length <= maximumDecimalsQuantity
}

/** Get decimals from stringified number. Works good with exponential values, f.e. «1e-8» */
export const getDecimals = (amount: string | number) => {
  const stringifiedAmount = typeof amount === 'string' ? amount : new BigNumber(amount).toString()

  return stringifiedAmount.replace(',', '.').split('.')[1] ?? ''
}
