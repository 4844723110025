import clsx from 'clsx'
import { t } from '@lingui/macro'
import { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SVG } from '@/assets/svg'
import { Button, Input, InputSearch, InputSize } from '@/ui/kit'
import { typedMemo, useOutsideClick } from '@/utils'
import { InputDropdownOption, InputDropdownProps, InputDropdownSize, InputDropdownVariant } from './InputDropdown.types'
import { convertOptionsToObject } from './InputDropdown.utils'
import s from './style.module.scss'

type TOption<T extends string> = {
  active: boolean
  handleClick: (option: InputDropdownOption<T>) => MouseEventHandler<HTMLDivElement> | undefined
  option: InputDropdownOption<T>
  disabled?: boolean
  rightIcon?: React.ReactNode
}

const Option = typedMemo(<T extends string>(props: TOption<T>) => {
  const { handleClick, option, active } = props
  return (
    <div
      key={option.id}
      onClick={handleClick(option)}
      className={clsx(s.option, { [s.active]: active, [s.disabled]: option.disabled })}
    >
      <div className={s.optionLabel}>
        <div>{option.label}</div>
        <div className={s.addressEllipsis}>
          <span className={s.address}>{option.value}</span>
        </div>
      </div>
      <div className={s.rightIcon}>{option.rightIcon}</div>
      {!option.disabled && (
        <div>
          <SVG.OtherIcons.Ok className={s.optionChevron} />
        </div>
      )}
    </div>
  )
})

const InputDropdownComponent = typedMemo(<T extends string>(props: InputDropdownProps<T>) => {
  const {
    label,
    size = InputDropdownSize.Medium,
    variant = InputDropdownVariant.Lined,
    placeholder,
    labelHintText,
    options: allOptions,
    value,
    description,
    error,
    className,
    classNameDropdown,
    optionClick,
    handleButtonClick,
    onChange,
    dataTestId,
    Icon,
    iconClick,
    handleCleanChange,
    disabled,
    group,
    groupTitles,
  } = props
  const inputRef = useRef<any>()

  const optionsAsObject = useMemo(() => convertOptionsToObject(allOptions), [allOptions])
  const [options, setOptions] = useState<InputDropdownOption<T>[]>(allOptions)
  const [selectedOption, setSelectedOption] = useState<InputDropdownOption<T> | null>(
    value ? optionsAsObject[value] : null
  )
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (selectedOption?.value !== value) {
      setSelectedOption(value ? optionsAsObject[value] : null)
    }
  }, [selectedOption, optionsAsObject, value])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onFocus = () => setIsOpen(true)

  useEffect(() => {
    const filteredOptions = allOptions.filter(
      option =>
        (option.search || '').toLowerCase().includes(search.toLowerCase()) ||
        (option.value || '').toLowerCase().includes(search.toLowerCase())
    )
    setOptions(filteredOptions)
  }, [search, allOptions])

  const ref = useOutsideClick<HTMLDivElement>(handleClose)

  const handleClick = useCallback(
    (item: InputDropdownOption<T>) => () => {
      if (item.disabled) return
      setSelectedOption(item)
      optionClick(item)
      onChange(item.value)
      handleClose()
    },
    [onChange, handleClose, optionClick]
  )

  const handelInputChange = (value: string) => {
    handleCleanChange?.()
    onChange(value)
  }

  const firstGroupedOptions = options.filter(option => option.group === true)
  const lastGroupedOptions = options.filter(option => option.group === false)

  const groupedOptions = groupTitles.map((group, index) => {
    const options = index === 0 ? firstGroupedOptions : lastGroupedOptions
    return {
      title: group.element,
      options,
    }
  })

  return (
    <div ref={ref} className={clsx(s.root, s[size], s[variant], { [s.open]: isOpen, [s.error]: error }, className)}>
      <div className={s.toggle}>
        <div className={s.labelContent} onClick={() => onFocus()}>
          <Input
            size={InputSize.Medium}
            ref={inputRef}
            label={label as string}
            placeholder={placeholder}
            value={value as string}
            setValue={handelInputChange}
            labelHintText={labelHintText}
            dataTestId={dataTestId}
            disabled={disabled}
          />
          {disabled && <div className={s.hideFocusInput} onClick={() => onFocus()}></div>}
        </div>
        {Icon && (
          <div className={s.addressIcon} onClick={iconClick}>
            {Icon}
          </div>
        )}
      </div>
      {isOpen && (
        <div className={clsx(s.dropdown, classNameDropdown)}>
          {
            <InputSearch
              value={search}
              setValue={setSearch}
              sizeSearch="small"
              size={InputSize.Large}
              placeholder={t({ message: 'Search', id: 'core.search' })}
              containerClassName={s.search}
            />
          }
          {!options.length && <div className={s.noOptions}>{t({ message: 'No options', id: 'core.noOptions' })}</div>}
          <div className={s.optionsContainer}>
            {group ? (
              <>
                {groupedOptions?.map((groupOption, index) => (
                  <div key={index}>
                    {groupOption.options.length ? <h3 className={s.groupTitle}>{groupOption.title}</h3> : null}
                    {groupOption.options.map(option => {
                      const active = selectedOption?.id === option.id

                      return <Option key={option.id} active={active} handleClick={handleClick} option={option} />
                    })}
                  </div>
                ))}
              </>
            ) : (
              <>
                {options.map(option => {
                  const active = selectedOption?.id === option.id
                  return <Option key={option.id} active={active} handleClick={handleClick} option={option} />
                })}
              </>
            )}
          </div>
          <div className={s.addButton}>
            <Button.Clean
              onClick={handleButtonClick}
              label={t({
                id: 'inputDropdown.addButton',
                message: 'Add a withdrawal address',
              })}
              className={s.addButton}
              rightIcon={<SVG.OtherIcons.Plus />}
            />
          </div>
        </div>
      )}
      {(error || description) && (
        <div className={s.info}>
          {error && <div className={s.errorMessage}>{error}</div>}
          {description && <div className={s.description}>{description}</div>}
        </div>
      )}
    </div>
  )
})

const InputDropdown = Object.assign(InputDropdownComponent, {
  Size: InputDropdownSize,
  Variant: InputDropdownVariant,
})

export { InputDropdown }
