import { FC, useMemo } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { ToggleButton } from './ToggleButton/ToggleButton'
import { Button, ButtonSize, Input, InputSize, InteractiveModalParts } from '@/ui/kit'
import clsx from 'clsx'
import { Loader } from '@/ui/kit/Loader'
import { trimTo12Decimals } from '@/ui/molecules/FormatNumberDotWithTooltip/utils'
import { ActionMargin } from './consts'
import { AccountOverviewPositionsView } from '@/backend/models/OverviewDTO'
import { useHandleMargin } from './useHandleMargin'

type Props = {
  position: AccountOverviewPositionsView
  onPreviousStep: () => void
}

export const HandleForm: FC<Props> = ({ position, onPreviousStep }) => {
  const { isBinance, maxAmount, amount, setAmount, option, setOption, submitHandler, isLoadingMargin } =
    useHandleMargin(position)

  const currentPosition = isBinance ? position.isolatedMargin : position.positionBalance

  const isDisabledConfirm =
    !maxAmount || // Нет максимальной суммы
    !amount || // Нет введенной суммы
    isNaN(Number(amount)) || // Сумма не является числом
    Number(amount) <= 0 || // Сумма меньше или равна нулю
    Number(amount) > maxAmount || // Сумма больше, чем максимальная допустимая
    isLoadingMargin || // Идет загрузка данных
    !currentPosition // отсутсвует маржа по  позиции

  const formatAmount = maxAmount !== null && maxAmount !== undefined ? trimTo12Decimals(maxAmount) : 0
  const amountText = t({ message: `Amount`, id: 'core.amount' })

  if (isLoadingMargin) {
    return (
      <div className={style.loadWrapper}>
        <Loader />
      </div>
    )
  }

  const TOGGLE_DATA_MENU = {
    ADD: {
      label: t({
        id: 'isolatedMargin.handleMargin.toggle.add',
        comment: 'Add margin',
      }),
      value: ActionMargin.ADD,
    },
    REMOVE: {
      label: t({
        id: 'isolatedMargin.handleMargin.toggle.remove',
        comment: 'Remove margin',
      }),
      value: ActionMargin.REMOVE,
    },
  }

  return (
    <div className={style.wrapper}>
      <ToggleButton
        option1={TOGGLE_DATA_MENU.ADD}
        option2={TOGGLE_DATA_MENU.REMOVE}
        defaultSelected={option}
        onChange={setOption}
      />
      <div className={style.content}>
        <Input
          size={InputSize.Medium}
          label={`${amountText} USDT`}
          value={amount}
          info={`Max: ${formatAmount} USDT`}
          setValue={setAmount}
          placeholder={t({
            id: 'isolatedMargin.handle.inputPlaceholder',
            comment: 'Type the amount',
          })}
          infoClassName={style.infoWrapp}
        />
        <InteractiveModalParts.Table
          left={t({
            id: 'isolatedMargin.handle.currentMargin',
            comment: 'Current Margin',
          })}
          right={currentPosition ? `${trimTo12Decimals(currentPosition)} USDT` : '-'}
        />
        {option.value === ActionMargin.ADD ? (
          <InteractiveModalParts.Table
            left={t({
              id: 'isolatedMargin.handle.availableBalance',
              comment: 'Available Balance',
            })}
            right={maxAmount !== null && maxAmount !== undefined ? `${formatAmount} USDT` : '-'}
          />
        ) : (
          <InteractiveModalParts.Table
            left={t({
              id: 'isolatedMargin.handle.maxRemovable',
              comment: 'Max Removable',
            })}
            right={maxAmount !== null && maxAmount !== undefined ? `${formatAmount} USDT` : '-'}
          />
        )}
      </div>
      <div className={style.buttonsWrapper}>
        <Button.White
          size={ButtonSize.Medium}
          label={t({
            id: 'profile.whitelist.confirm',
            comment: 'Confirm',
          })}
          className={clsx(style.button, style.confirmButton)}
          onClick={submitHandler}
          disabled={isDisabledConfirm}
        />
        <Button.Primary
          size={ButtonSize.Medium}
          label={t({
            id: 'core.back',
            comment: 'back',
          })}
          className={style.button}
          onClick={onPreviousStep}
        />
      </div>
    </div>
  )
}
