import { TransactionsAPI, WithdrawAndDepositAssets } from '@/backend/api'
import {
  AddressApi,
  DeleteAddressPayload,
  EditPayload,
  GetAddressByIdPayload,
  GetWhitelistPayload,
  ToggleWhitelistPermissionPayload,
} from '@/backend/api/addressAPI'
import { AddressDTO, AddressCreateDTO } from '@/backend/models/AddressDTO'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'

export const getAddressesTC = createAsyncThunk<AddressDTO[], void, ThunkError>(
  nameof(AddressApi.getAddresses),
  async (_, thunkAPI) => {
    return await ThunkCreator({ apiMethod: AddressApi.getAddresses }, thunkAPI)
  }
)

export const getAddressByIdTC = createAsyncThunk<AddressDTO, GetAddressByIdPayload, ThunkError>(
  nameof(AddressApi.getAddressById),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: AddressApi.getAddressById, payload }, thunkAPI)
  }
)

export const deleteAddressTC = createAsyncThunk<any, DeleteAddressPayload, ThunkError>(
  nameof(AddressApi.deleteAddress),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: AddressApi.deleteAddress, payload }, thunkAPI)
  }
)

export const editAddressTC = createAsyncThunk<any, EditPayload, ThunkError>(
  nameof(AddressApi.editAddress),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: AddressApi.editAddress, payload }, thunkAPI)
  }
)

export const createAddressTC = createAsyncThunk<any, AddressCreateDTO, ThunkError>(
  nameof(AddressApi.createAddress),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: AddressApi.createAddress, payload }, thunkAPI)
  }
)

export const getAssetsForAddressTC = createAsyncThunk<WithdrawAndDepositAssets, void, ThunkError>(
  nameof(TransactionsAPI.getAssetsForWithdrawAndDeposit),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TransactionsAPI.getAssetsForWithdrawAndDeposit, payload }, thunkAPI)
  }
)
export const getWhitelistTC = createAsyncThunk<GetWhitelistPayload, void, ThunkError>(
  nameof(AddressApi.getWhiteList),
  async (_, thunkAPI) => {
    return await ThunkCreator({ apiMethod: AddressApi.getWhiteList }, thunkAPI)
  }
)

export const postToggleWhitelistPermissionTC = createAsyncThunk<any, ToggleWhitelistPermissionPayload, ThunkError>(
  nameof(AddressApi.postToggleWhitelistPermission),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: AddressApi.postToggleWhitelistPermission, payload }, thunkAPI)
  }
)
