import axios, { AxiosResponse } from 'axios'
import { sentryErrorInterceptor, traceRequestInterceptor } from '../../interceptors'

const axiosOKXMainService = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_IP,
})

axiosOKXMainService.interceptors.response.use((response: AxiosResponse) => response, sentryErrorInterceptor)
axiosOKXMainService.interceptors.request.use(traceRequestInterceptor)

export { axiosOKXMainService }
