import {
  INotificationConfig,
  ModalComponent,
  NotififcationPresets,
  SystemNotifiactionIcon,
} from '@/ui/molecules/SystemNotifications/notifications.type'
import { t } from '@lingui/macro'
import style from './style.module.scss'
import { Button, ButtonSize, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { EInteractiveModalVariant } from '@/ui/kit/Modals/InteractiveModal/InteractiveModal'
import { Control } from '@/ui/atoms/Control'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { Trans } from '@lingui/react'
import { SVG } from '@/assets/svg'
import clsx from 'clsx'
import { memo } from 'react'
import { E_OKX_SUSPENSION_WIDGET_STYLE, OkxSuspensionWidget } from '../../OkxSuspensionWidget'
import { OKX_DISABLE_DATE } from '@/core/constants'
import { IMetadata } from '@/backend/models/ProfileDTO'

function getModalComponent({ isMobile }: { isMobile: boolean }): ModalComponent {
  return memo(({ isOpen, onClose }) => {
    const navigate = useNavigate()

    return (
      <InteractiveModal
        isOpen={isOpen}
        className={clsx(style.modal, style.modalOkx)}
        variant={EInteractiveModalVariant.MEDIUM}
      >
        <div className={style.header}>
          <InteractiveModalParts.SubHeaderL2 text={t({ id: 'okxSuspension.modal.heading' })} className={style.title} />

          <Control.Close onClick={onClose} className={style.close} />
        </div>

        <div className={style.description}>
          <div>
            <Trans
              id="okxSuspension.modal.description"
              components={{
                em: <span className={style.whiteText} />,
              }}
            />
          </div>

          <div className={style.subDescription}>{t({ id: 'okxSuspension.modal.subDescription' })}</div>

          <div className={style.cta}>
            <Button.Primary
              size={ButtonSize.Medium}
              className={style.button}
              label={t({ id: 'okxSuspension.modal.cta' })}
              onClick={() => {
                navigate(urls.orders)
                onClose()
              }}
            />
          </div>

          <div className={style.additional}>
            <OkxSuspensionWidget
              classNameContent={style.widgetOkx}
              showHeading={false}
              preContent={<SVG.OtherIcons.SmileyGrayscale className={style.widgetOkxIcon} />}
              styleVariant={E_OKX_SUSPENSION_WIDGET_STYLE.DEFAULT}
            />
          </div>
        </div>
      </InteractiveModal>
    )
  })
}

interface IOkxSuspensionAlertConfig {
  isMobile: boolean
  firstSesstionTrackedTimestamp: IMetadata['firstSesstionTrackedTimestamp']
}

export function getOkxSuspensionAlert({
  isMobile,
  firstSesstionTrackedTimestamp,
}: IOkxSuspensionAlertConfig): INotificationConfig {
  const preset: INotificationConfig = {
    id: NotififcationPresets.OkxSuspensionAlert,
    icon: SystemNotifiactionIcon.Alert,
    heading: '',
    Modal: getModalComponent({ isMobile }),
    modalBtnClassname: style.modalButtonOkx,
    shouldShown: Number(firstSesstionTrackedTimestamp) < OKX_DISABLE_DATE.getTime(),
    availableExchange: ['OKX_BROKER'],
    isClosable: isMobile,
  }

  preset.heading = (
    <span className={clsx(isMobile && style.mobile, style.label)}>
      {t({ id: 'okxSuspension.alert.heading' })}
      <span className={style.subheading}>
        <Trans id="okxSuspension.alert.description" components={{ em: <span className={style.gradientText} /> }} />
      </span>
    </span>
  )

  return preset
}
