import { FC, ReactNode } from 'react'
import { SnackbarProvider } from 'notistack'
import { Notistack } from '@/ui/kit'
import style from './style.module.scss'

interface ISnackBarProviderProps {
  children: ReactNode
}

const SnackBarProvider: FC<ISnackBarProviderProps> = props => {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{
        containerRoot: style.container,
      }}
      Components={{
        default: Notistack,
        error: Notistack,
        success: Notistack,
      }}
    >
      {props.children}
    </SnackbarProvider>
  )
}

export { SnackBarProvider }
