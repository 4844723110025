import { FC, forwardRef } from 'react'

import styles from './style.module.scss'
import { TControlProps } from './Control.Types'
import { SVG } from '@/assets/svg'
import clsx from 'clsx'

const ControlComponent = forwardRef<HTMLDivElement, TControlProps>((props, ref) => {
  const { onClick, className, disabled, ...other } = props
  return (
    <div
      ref={ref}
      className={clsx(styles.root, className, { [styles.disabled]: disabled })}
      onClick={onClick}
      aria-disabled={disabled}
      {...other}
    >
      {props.children}
    </div>
  )
})

const Close = forwardRef<HTMLDivElement, TControlProps>((props, ref) => {
  return (
    <ControlComponent {...props} ref={ref}>
      <SVG.Additional.Close />
    </ControlComponent>
  )
})

const Share = forwardRef<HTMLDivElement, TControlProps>((props, ref) => {
  return (
    <ControlComponent {...props} ref={ref}>
      <SVG.Actions.Share />
    </ControlComponent>
  )
})

const ArrowRight = forwardRef<HTMLDivElement, TControlProps>((props, ref) => {
  return (
    <ControlComponent {...props} ref={ref}>
      <SVG.Arrows.ArrowRight />
    </ControlComponent>
  )
})

const ArrowLeft = forwardRef<HTMLDivElement, TControlProps>((props, ref) => {
  return (
    <ControlComponent {...props} ref={ref}>
      <SVG.Arrows.ArrowLeft />
    </ControlComponent>
  )
})

const ArrowUp = forwardRef<HTMLDivElement, TControlProps>((props, ref) => {
  return (
    <ControlComponent {...props} ref={ref}>
      <SVG.Arrows.ArrowUp />
    </ControlComponent>
  )
})

const ArrowDown = forwardRef<HTMLDivElement, TControlProps>((props, ref) => {
  return (
    <ControlComponent {...props} ref={ref}>
      <SVG.Arrows.ArrowDown />
    </ControlComponent>
  )
})

export const Control = {
  Close: Close,
  Share: Share,
  ArrowRight: ArrowRight,
  ArrowLeft: ArrowLeft,
  ArrowUp: ArrowUp,
  ArrowDown: ArrowDown,
}
