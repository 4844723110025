export const Danger = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1333 13.7667L12.8 4.16666C12.0833 2.875 11.0916 2.16666 9.99998 2.16666C8.90831 2.16666 7.91665 2.875 7.19998 4.16666L1.86665 13.7667C1.19165 14.9917 1.11665 16.1667 1.65831 17.0917C2.19998 18.0167 3.26665 18.525 4.66665 18.525H15.3333C16.7333 18.525 17.8 18.0167 18.3416 17.0917C18.8833 16.1667 18.8083 14.9833 18.1333 13.7667ZM9.37498 8C9.37498 7.65833 9.65831 7.375 9.99998 7.375C10.3416 7.375 10.625 7.65833 10.625 8V12.1667C10.625 12.5083 10.3416 12.7917 9.99998 12.7917C9.65831 12.7917 9.37498 12.5083 9.37498 12.1667V8ZM10.5916 15.2583C10.55 15.2917 10.5083 15.325 10.4666 15.3583C10.4166 15.3917 10.3666 15.4167 10.3166 15.4333C10.2666 15.4583 10.2166 15.475 10.1583 15.4833C10.1083 15.4917 10.05 15.5 9.99998 15.5C9.94998 15.5 9.89165 15.4917 9.83331 15.4833C9.78331 15.475 9.73331 15.4583 9.68331 15.4333C9.63331 15.4167 9.58331 15.3917 9.53331 15.3583C9.49165 15.325 9.44998 15.2917 9.40831 15.2583C9.25831 15.1 9.16665 14.8833 9.16665 14.6667C9.16665 14.45 9.25831 14.2333 9.40831 14.075C9.44998 14.0417 9.49165 14.0083 9.53331 13.975C9.58331 13.9417 9.63331 13.9167 9.68331 13.9C9.73331 13.875 9.78331 13.8583 9.83331 13.85C9.94165 13.825 10.0583 13.825 10.1583 13.85C10.2166 13.8583 10.2666 13.875 10.3166 13.9C10.3666 13.9167 10.4166 13.9417 10.4666 13.975C10.5083 14.0083 10.55 14.0417 10.5916 14.075C10.7416 14.2333 10.8333 14.45 10.8333 14.6667C10.8333 14.8833 10.7416 15.1 10.5916 15.2583Z"
        fill="#F97052"
      />
    </svg>
  )
}
