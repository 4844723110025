import {
  CountriesKYC,
  ResponseFormKYC,
  KYCAvailableLimitsDTO,
  IdentityVerificationTokenKYC,
  UserKYC,
  WorkflowKYC,
  KYCLimitsDTO,
  OperationLimitKYC,
  TUserKYCLinkDTO,
  KYCTier,
} from '@/backend/models/KycDTO'
import { Status } from '@/backend/types'

export type TKYCAvailableLimits = Omit<KYCAvailableLimitsDTO, 'depositLimit' | 'withdrawalLimit'> & {
  depositLimit: OperationLimitKYCExtended
  withdrawalLimit: OperationLimitKYCExtended
}

export type OperationLimitKYCExtended = OperationLimitKYC & {
  availableRounded: number
  availableFormatted: string
  limitRounded: number
  limitFormatted: string
}

export type InitialState = {
  isVerificationFailedPopupShown: boolean
  userKYC: UserKYC
  kycTierOnLoad: KYCTier | undefined
  responseFormKYC: ResponseFormKYC
  countries: CountriesKYC
  accessTokenForIdentityVerification: IdentityVerificationTokenKYC
  workflow: WorkflowKYC
  userKycStatus: Status
  updateFormKycStatus: Status
  initWorkflowStatus: Status
  workflowStatus: Status
  workflowStatusCode: string | number | undefined
  responseFormKycStatus: Status
  availableLimits: TKYCAvailableLimits
  userKycLinkState: TUserKYCLinkDTO
  userKycLinkRequestStatus: Status
  userKycLinkStatusRequestStatus: Status
  availableLimitsStatus: Status
  limits: KYCLimitsDTO
  limitsStatus: Status
}

export enum E_KYC_STATUS_USER_VIEW {
  UNVERIFIED = 'UNVERIFIED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  NEW_USER_PENDING = 'NEW_USER_PENDING',
  NEW_USER_REJECTED = 'NEW_USER_REJECTED',
  NEW_USER_INIT = 'NEW_USER_INIT',
}
