import { FC } from 'react'

export const Discord: FC = () => {
  return (
    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2414 15.0006C15.2414 15.0006 14.62 14.2584 14.1022 13.6024C16.3634 12.9637 17.2265 11.5483 17.2265 11.5483C16.5188 12.0143 15.8456 12.3423 15.2414 12.5667C14.3783 12.9292 13.5498 13.1709 12.7385 13.309C11.0814 13.6197 9.56232 13.5334 8.26769 13.2917C7.28377 13.1018 6.43795 12.8256 5.73022 12.5494C5.3332 12.3941 4.90165 12.2042 4.47011 11.9625C4.44423 11.9453 4.41835 11.9324 4.39247 11.9194L4.39243 11.9194C4.36654 11.9064 4.34065 11.8935 4.31475 11.8762C4.28023 11.859 4.26297 11.8417 4.24571 11.8245C3.935 11.6518 3.76238 11.531 3.76238 11.531C3.76238 11.531 4.59094 12.9119 6.78318 13.5679C6.26533 14.2238 5.62665 15.0006 5.62665 15.0006C1.8118 14.8798 0.361816 12.3768 0.361816 12.3768C0.361816 6.81855 2.84751 2.31324 2.84751 2.31324C5.3332 0.448968 7.69805 0.500754 7.69805 0.500754L7.87067 0.707895C4.76356 1.60551 3.33084 2.96918 3.33084 2.96918C3.33084 2.96918 3.71059 2.76204 4.34928 2.46859C6.19628 1.65729 7.66353 1.43289 8.26769 1.3811L8.30637 1.37456C8.39345 1.35973 8.47072 1.34658 8.56114 1.34658C9.61411 1.20849 10.8052 1.17396 12.048 1.31206C13.6879 1.50193 15.4486 1.98526 17.2438 2.96918C17.2438 2.96918 15.8801 1.67455 12.9456 0.776941L13.1873 0.500754C13.1873 0.500754 15.5521 0.448968 18.0378 2.31324C18.0378 2.31324 20.5235 6.81855 20.5235 12.3768C20.5235 12.3768 19.0563 14.8798 15.2414 15.0006ZM7.21482 6.93851C6.23091 6.93851 5.45413 7.8016 5.45413 8.85457C5.45413 9.90753 6.24817 10.7706 7.21482 10.7706C8.19874 10.7706 8.97552 9.90753 8.97552 8.85457C8.99278 7.8016 8.19874 6.93851 7.21482 6.93851ZM13.5154 6.93851C12.5314 6.93851 11.7547 7.8016 11.7547 8.85457C11.7547 9.90753 12.5487 10.7706 13.5154 10.7706C14.4993 10.7706 15.2761 9.90753 15.2761 8.85457C15.2761 7.8016 14.4993 6.93851 13.5154 6.93851Z"
        fill="white"
      />
    </svg>
  )
}
