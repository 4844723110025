import React from 'react'

interface IProps {
  className?: string
}

export const Block = (props: IProps) => {
  const { className } = props

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.175 1.5H6.825C6.315 1.5 5.595 1.8 5.235 2.16L2.16 5.23501C1.8 5.59501 1.5 6.315 1.5 6.825V11.175C1.5 11.685 1.8 12.405 2.16 12.765L5.235 15.84C5.595 16.2 6.315 16.5 6.825 16.5H11.175C11.685 16.5 12.405 16.2 12.765 15.84L15.84 12.765C16.2 12.405 16.5 11.685 16.5 11.175V6.825C16.5 6.315 16.2 5.59501 15.84 5.23501L12.765 2.16C12.405 1.8 11.685 1.5 11.175 1.5Z"
        stroke="#F97052"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.70508 14.3101L14.3101 3.70508"
        stroke="#F97052"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
