export enum EBinanceApiType {
  api = 'api',
  fapi = 'fapi',
  dapi = 'dapi',
}
export const BINANCE_PING_API: Record<EBinanceApiType, string> = {
  api: 'https://api.binance.com/api/v3/ping',
  fapi: 'https://fapi.binance.com/fapi/v1/ping',
  dapi: 'https://dapi.binance.com/dapi/v1/ping',
}
