import {
  GetBinancePositionModeTC,
  GetBNBBurnStatusTC,
  GetBNBDustTC,
  GetMultiAssetMarginTC,
  GetProfileTC,
  LoginWith2fa,
  SetPositionModeTC,
  UpdateBNBBurnStatusTC,
  UpdateBNBDustTC,
  UpdateLocale,
  UpdateMetadata,
  UpdateMultiAssetMarginTC,
  VerifyTwoFA,
  resetTwoFATC,
} from './profile.thunk'

export const profileAsyncActions = {
  GetProfileTC,
  UpdateLocale,
  UpdateMetadata,
  GetBNBBurnStatusTC,
  UpdateBNBBurnStatusTC,
  GetMultiAssetMarginTC,
  UpdateMultiAssetMarginTC,
  GetBNBDustTC,
  UpdateBNBDustTC,
  GetBinancePositionModeTC,
  SetPositionModeTC,
  VerifyTwoFA,
  LoginWith2fa,
  resetTwoFATC,
}
