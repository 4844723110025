import { FC, memo, useMemo } from 'react'
import TopBar from '../../molecules/TopBar'
import ContentList from '../ContentList'
import style from './style.module.scss'
import { useAPIKeys, useAppSelector, useGoogleAuthCode, useGoogleAuthSettings, useKYC } from '@/utils/hooks'
import { PageSetupGoogleAuth } from '../WalletSetupGoogleAuth'
import { getWalletPageState } from '@/pages/Wallet/utils'
import { WalletSetupApiKeys } from '../WalletSetupApiKeys'
import { WalletSetupKYC } from '../WalletSetupKYC'
import { WalletUnderReviewAfterStep1KYC } from '../WalletUnderReviewAfterStep1KYC'
import { WalletUnderReviewAfterStep2KYC } from '../WalletUnderReviewAfterStep2KYC'
import { urls } from '@/router/urls'
import { WalletSetupExchange } from '../WalletSetupExchange'
import { SkeletonWalletBottom, SkeletonWalletTop } from '@/ui/kit/Skeletons/SkeletonWallet/SkeletonWallet'

const Content: FC = () => {
  const { statusOrders, statusPositions, statusBalances } = useAppSelector(state => state.overview)
  const { exchangeStatus } = useAppSelector(state => state.app)

  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()
  const aPIKeys = useAPIKeys()
  const kYC = useKYC()

  const pageState = useMemo(() => {
    return getWalletPageState({
      twoFA: googleAuthSettings,
      kYC,
      aPIKeys,
      exchangeStatus,
    })
  }, [googleAuthSettings, aPIKeys, kYC, exchangeStatus])

  return (
    <div className={style.content}>
      {statusBalances !== 'succeeded' && <SkeletonWalletTop />}
      {statusBalances === 'succeeded' && <TopBar />}
      {(() => {
        if (pageState === 'loading' || statusOrders !== 'succeeded' || statusPositions !== 'succeeded')
          return <SkeletonWalletBottom />

        if (pageState === 'add_api_key') return <WalletSetupApiKeys />

        if (pageState === 'kyc_not_started') return <WalletSetupKYC />

        if (pageState === 'kyc_in_progress_1') return <WalletUnderReviewAfterStep1KYC />

        if (pageState === 'kyc_in_progress_2') return <WalletUnderReviewAfterStep2KYC />

        if (pageState === '2fa')
          return (
            <PageSetupGoogleAuth
              googleAuthSettings={googleAuthSettings}
              googleAuthCode={googleAuthCode}
              backRoute={urls.wallet}
            />
          )

        if (pageState === 'exchange_init') return <WalletSetupExchange />

        return <ContentList />
      })()}
    </div>
  )
}

export default memo(Content)
