import { AvailableScreenSize } from '@/types'
import { debounce } from 'lodash-es'
import { useCallback, useLayoutEffect, useState } from 'react'
import { MobileSizeTable } from '../../styles/breakpoints'
import { useUnmountedRef } from './useUnmountedRef'
import { isMobile as isMobileDevice } from '../lib/platform'

type StateDispatcher = (mode: boolean) => void

class WindowSizeController {
  private subscribers: Set<StateDispatcher> = new Set()
  private mode!: AvailableScreenSize

  private isMobile = false

  constructor(initialMode: AvailableScreenSize = AvailableScreenSize.Auto) {
    this.setMode(initialMode)

    const handleResize = debounce(() => {
      this.updateStateIfNeed()
    }, 100)

    window.addEventListener('resize', handleResize)
  }

  public get value() {
    return this.isMobile
  }

  private get windowWidth(): number {
    return window.innerWidth
  }

  public subscribe(dispatch: StateDispatcher) {
    this.subscribers.add(dispatch)
  }

  public unsubscribe(dispatch: StateDispatcher) {
    this.subscribers.delete(dispatch)
  }

  public setMode(mode: AvailableScreenSize) {
    if (this.mode !== mode) {
      this.mode = mode
      this.updateStateIfNeed()
    }
  }

  private emit(isMobile: boolean) {
    this.subscribers.forEach(dispatch => dispatch(isMobile))
  }

  private updateStateIfNeed() {
    let isMobile = false

    switch (this.mode) {
      case AvailableScreenSize.Auto: {
        if (isMobileDevice()) {
          isMobile = true
          break
        }

        const width = this.windowWidth

        if (width <= MobileSizeTable.tablet) {
          isMobile = true
        } else {
          isMobile = false
        }

        break
      }

      case AvailableScreenSize.Mobile: {
        isMobile = true
        break
      }

      case AvailableScreenSize.Desktop: {
        isMobile = false
        break
      }
    }

    if (this.isMobile !== isMobile) {
      this.isMobile = isMobile
      this.emit(isMobile)
    }
  }
}

export const sizeController = new WindowSizeController(AvailableScreenSize.Auto)

export function useMobileSizeDetect(): [boolean, (mode: AvailableScreenSize) => void] {
  const [isMobile, setSize] = useState(sizeController.value)
  const isUnmountedRef = useUnmountedRef()

  const changeMode = useCallback(
    (isMobile: boolean) => {
      if (!isUnmountedRef.current) {
        setSize(isMobile)
      }
    },
    [isUnmountedRef]
  )

  useLayoutEffect(() => {
    sizeController.subscribe(changeMode)
    return () => sizeController.unsubscribe(changeMode)
  }, [setSize])

  const changeModeHandler = useCallback((mode: AvailableScreenSize) => sizeController.setMode(mode), [])

  return [isMobile, changeModeHandler]
}
