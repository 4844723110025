import React from 'react'

export const Unlock = () => {
  return (
    <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.765625 8.58785C0.765625 8.00215 1.24043 7.52734 1.82613 7.52734H10.1742C10.7599 7.52734 11.2347 8.00215 11.2347 8.58785V11.366C11.2347 14.2569 8.8911 16.6005 6.00015 16.6005C3.1092 16.6005 0.765625 14.2569 0.765625 11.366V8.58785ZM6.73792 11.616C7.08137 11.3864 7.30753 10.9951 7.30753 10.551C7.30753 9.84388 6.73433 9.27068 6.02726 9.27068C5.32018 9.27068 4.74698 9.84388 4.74698 10.551C4.74698 10.9709 4.94916 11.3436 5.26149 11.5771V13.1233C5.26149 13.531 5.592 13.8615 5.9997 13.8615C6.40741 13.8615 6.73792 13.531 6.73792 13.1233V11.616Z"
        fill="#FAFAFA"
      />
      <path
        d="M9.33102 8.05367V4.72215C9.33102 2.8822 7.83944 1.39062 5.99949 1.39062V1.39062C4.15954 1.39062 2.66797 2.8822 2.66797 4.72215V4.72215"
        stroke="#FAFAFA"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  )
}
