import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { IAllocateTigerToken } from '@/core/infra/v1/TigerToken/AllocateTigerToken.interface'
import { assetsToOptionsMapper } from '@/core/mappers/assets/assetsToOptions.mapper'
import { ITigerTokenPageContent } from '../../../types'
import { yupResolver } from '@hookform/resolvers/yup'
import { VALIDATION_SCHEMA } from '../constants/Validation.schema'

export interface IFormControlData {
  assets: ITigerTokenPageContent['spotAssets']
  tigetTokenInformation: ITigerTokenPageContent['information']
}

export const useControlForm = (formControlData: IFormControlData) => {
  const { assets, tigetTokenInformation } = formControlData

  const form = useForm<IAllocateTigerToken>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(VALIDATION_SCHEMA(tigetTokenInformation.minAmount)),
    defaultValues: {
      amount: tigetTokenInformation.minAmount,
    },
  })

  const assetsOptions = useMemo(() => {
    if (!formControlData.assets.assets.length) {
      return []
    }
    return assetsToOptionsMapper(formControlData.assets).filter(assetOption => {
      return tigetTokenInformation.assets.includes(assetOption.label as string)
    })
  }, [formControlData.assets, tigetTokenInformation.assets])

  const vlidateBySubmit = useCallback(
    (callback: (data: IAllocateTigerToken) => void) => {
      return (data: IAllocateTigerToken) => {
        const targetAsset = assets.assets.find(assetItem => assetItem.asset === data.asset) ?? {
          balance: null,
        }

        if (targetAsset?.balance) {
          if (targetAsset.balance < data.amount) {
            form.setError('amount', { message: 'Insufficient funds' })
            return
          }
        }
        callback(data)
      }
    },
    [assets.assets, form]
  )

  return { form, assetsOptions, vlidateBySubmit }
}
