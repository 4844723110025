import { FC, useCallback, useEffect, useMemo } from 'react'
import { t } from '@lingui/macro'
import { ReadMore } from '../../ReadMore'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ITeamFormSubscription, ITeamFormUserSubscriptionProps } from '../TeamForm.Types'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { Button, Checkbox } from '@/ui/kit'
import { E_TEAM_PERMISSIONS } from '@/redux/team/team.types'
import { ArrayedInputsGroup, TArrayedOption } from '@/ui/kit/Checkbox/Checkbox.Components/ArrayedInputsGroup'
import { HorizontalControls } from './TeamForm.Utils'
import { useActions, useAppSelector } from '@/utils'
import { teamAsyncActions } from '@/redux/team/team.actions'
import { Loader } from '@/ui/kit/Loader'
import { teamActions } from '@/redux/team/team.slice'
import { CheckboxProps, ECheckboxStyleVariant } from '@/ui/kit/Checkbox/Checkbox'
import { getTeamPermissionTranslations } from '@/translations/team'
import { yupResolver } from '@hookform/resolvers/yup'

import styles from '../style.module.scss'
import { VALIDATION_SCHEMA } from '../TeamForm.Utils/validation/subscription.schema'
import clsx from 'clsx'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'
import { Control } from '@/ui/atoms/Control'
import { Trans } from '@lingui/react'

export const TeamFormSubscription: FC<ITeamFormUserSubscriptionProps> = props => {
  return <Form {...props} />
}

const Form: FC<ITeamFormUserSubscriptionProps> = props => {
  const { TeamJoinTC } = useActions(teamAsyncActions)
  const { setTeamJoinReset } = useActions(teamActions)
  const { teamJoinRequestStatus, teamJoinErrors } = useAppSelector(state => state.team)
  const { handleBack, handleBackSuccess, handleClose, showCloseControl } = props

  const {
    teamRequested: { permissions, teamCode, teamTitle },
  } = useTeamData()

  const {
    handleSubmit,
    control,
    formState,
    formState: { errors },
  } = useForm<ITeamFormSubscription>({
    mode: 'all',
    resolver: yupResolver(VALIDATION_SCHEMA()),
    defaultValues: {
      teamCode: teamCode,
      permissions: [],
    },
  })

  const onSubmit = useCallback<SubmitHandler<ITeamFormSubscription>>(
    (data: ITeamFormSubscription) => {
      TeamJoinTC(data)
    },
    [TeamJoinTC]
  )

  const optionsPermissions = useMemo<TArrayedOption<E_TEAM_PERMISSIONS>[]>(() => {
    const permissionTranslations = getTeamPermissionTranslations()
    return permissions.map(permission => {
      return {
        description: permissionTranslations[permission],
        value: permission,
      }
    })
  }, [permissions])

  const checkboxProperties = useMemo<Partial<CheckboxProps>>(() => {
    return {
      className: styles.checkboxItem,
      styleVariant: ECheckboxStyleVariant.DEFAULT,
    }
  }, [])

  const isSubmitEnabled = useMemo(() => {
    return formState.isValid
  }, [formState])

  useEffect(() => {
    if (isStatusFinal(teamJoinRequestStatus)) setTeamJoinReset()
    if (teamJoinRequestStatus === 'succeeded') {
      handleBackSuccess && handleBackSuccess()
    }
  }, [teamJoinRequestStatus, handleBackSuccess, setTeamJoinReset])

  const isLoadingState = useMemo(() => {
    return teamJoinRequestStatus === 'loading'
  }, [teamJoinRequestStatus])

  if (isLoadingState) return <Loader />

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        {t({
          id: 'teamSubscriptionForm.heading',
        })}{' '}
        {teamTitle}
        {showCloseControl && <Control.Close onClick={handleClose} className={styles.closeControl} />}
      </div>

      <ReadMore
        heading={t({ id: 'teamSubscriptionForm.readMore.heading' })}
        content={
          <Trans
            id="teamSubscriptionForm.readMore.content"
            components={{
              br: <br />,
            }}
          />
        }
      />

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.subheading}>
          {t({
            id: 'teamSubscriptionForm.permissions.heading',
          })}
        </div>
        <ArrayedInputsGroup
          component={Checkbox}
          control={control}
          name="permissions"
          options={optionsPermissions}
          componentProps={checkboxProperties}
          className={styles.formItem}
          wrapClassname={styles.formItemSmall}
          errorMessage={formState.isSubmitted && errors.permissions?.message}
        />

        {teamJoinErrors.fieldsErrors && (
          <div className={clsx(styles.formItem, styles.error)}>
            {teamJoinErrors.fieldsErrors.map((error, key) => {
              return (
                <div className={styles.formItemSmall} key={key}>
                  {error.message}
                </div>
              )
            })}
          </div>
        )}

        <Controller
          control={control}
          name={'teamCode'}
          render={({ field }) => {
            return <input type="hidden" value={field.value} />
          }}
        />

        <HorizontalControls className={styles.submitLine}>
          {handleBack && (
            <Button.Primary
              label={t({ id: 'core.button.close', message: 'Close' })}
              className={styles.submitBtn}
              onClick={handleBack}
            />
          )}
          <Button.Accent
            label={t({ id: 'teamWidgetForm.codeApplyBtn', message: 'Send request' })}
            className={styles.submitBtn}
            type={'submit'}
            disabled={!isSubmitEnabled}
          />
        </HorizontalControls>
      </form>
    </div>
  )
}
