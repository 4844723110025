import { useCallback, useEffect, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import { kycActions } from '@/redux/kyc/kyc.slice'
import { Status } from '@/backend/types'
import { KYCActivity, KYCLimitPeriod, KYCTier, KYCWorkflowStatus, TierLimit } from '@/backend/models/KycDTO'
import { KYCLevelService } from '@/services/KYCLevelService/KYCLevelService'
import { formatNumber, useActions, useAppSelector } from '@/utils'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { useExchangeBlockedByKycTier } from './useExchangeBlocked'

type TKYCLimit = {
  deposit: {
    raw: number
    formatted: string
  }
  withdraw: {
    raw: number
    formatted: string
  }
}

const tiersAllowedForTransfer = [KYCTier.Standard, KYCTier.StandardPlus, KYCTier.Advanced]

interface IHandleKycUpgrade {
  (): void
}
export interface UseKYCResult {
  kycLoadStatus: Status
  isLoading: boolean
  isVerificationStarted: boolean
  /**
   *   Этот статус обозначает, что юзер отправил данные о себе, но еще не отправлял паспорт и своё селфи с паспортом
   */
  isVerificationInProgressAfterStep1: boolean
  /**
   *   Этот статус обозначает, что пользователь отправил и данные о себе и паспорт и своё селфи с паспортом
   */
  isVerificationInProgressAfterStep2: boolean
  isVerificationSuccess: boolean
  isVerificationError: boolean
  isKycNeedWorkflow: boolean | undefined
  kycLevelTitle: string
  getKYCLimitsByTier: (tier: KYCTier) => TKYCLimit
  isShowKycVerified: boolean
  handleKycUpgrade: IHandleKycUpgrade
  kycNextTier: KYCTier | null
  isTierUpperBasic: boolean
  nextTierLimits: TKYCLimit | null
  currentTierLimits: TKYCLimit | null
}

const KYC_EXCLUDE_TIERS = [KYCTier.AdvancedPlus]

export const useKYC = (): UseKYCResult => {
  const navigate = useNavigate()
  const { setAccessTokenForIdentityVerification, InitWorkflowTC } = useActions(kycActions)
  const isBlockedTier = useExchangeBlockedByKycTier()

  const { userKYC, userKycStatus, workflowStatus, workflow, limits, availableLimitsStatus, initWorkflowStatus } =
    useAppSelector(state => state.kyc)

  const isTierUpperBasic = useMemo(() => {
    return KYCLevelService.compareTier(userKYC.kycTier, KYCTier.Basic) === 'prev'
  }, [userKYC.kycTier])

  const kycNextTier = useMemo<KYCTier | null>(() => {
    return KYC_EXCLUDE_TIERS.find(excludeTier => excludeTier === userKYC.nextTier) === undefined
      ? userKYC.nextTier
      : null
  }, [userKYC.nextTier])

  const isKycNeedWorkflow = useMemo(
    () =>
      workflowStatus === 'idle' &&
      userKycStatus === 'succeeded' &&
      KYCLevelService.compareTier(userKYC.kycTier, KYCTier.Unverified) === 'prev',
    [userKycStatus, workflowStatus, workflowStatus, userKYC.activity]
  )

  const kycLoadStatus = useMemo(() => {
    const statuses = [userKycStatus, workflowStatus, availableLimitsStatus]

    if (statuses.some(s => s === 'failed')) {
      return 'failed'
    }

    if (statuses.every(s => s === 'idle') || statuses.some(s => s === 'loading') || isKycNeedWorkflow) {
      return 'loading'
    }

    return 'succeeded'
  }, [userKycStatus, workflowStatus, availableLimitsStatus, isKycNeedWorkflow])

  const isVerificationStarted = userKYC.kycTier !== KYCTier.Unverified
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (kycLoadStatus !== 'loading') setIsLoading(false)
  }, [kycLoadStatus])
  const isVerificationInProgressAfterStep1 = userKYC.kycTier === KYCTier.Basic && userKYC.activity === KYCActivity.None
  const isVerificationInProgressAfterStep2 =
    (workflow.status === KYCWorkflowStatus.InProgress || userKYC.activity === KYCActivity.WorkflowInProgress) &&
    !tiersAllowedForTransfer.includes(userKYC.kycTier)
  const isVerificationError = userKYC.activity === KYCActivity.WorkflowError
  const isVerificationSuccess =
    (userKYC.kycTier !== KYCTier.Unverified &&
      userKYC.kycTier !== KYCTier.Basic &&
      userKYC.activity === KYCActivity.None) ||
    workflow.status === KYCWorkflowStatus.Success

  useEffect(() => {
    if (isVerificationError || isVerificationSuccess) {
      setAccessTokenForIdentityVerification('')
    }
  }, [isVerificationError, isVerificationSuccess, setAccessTokenForIdentityVerification])

  const kycLevelTitle = useMemo(() => {
    const tier = isBlockedTier ? KYCTier.Unverified : userKYC.kycTier

    switch (tier) {
      case KYCTier.Unverified:
      case KYCTier.Basic:
        return t({ id: 'core.kyc.notVerified', message: `Not verified` })
      case KYCTier.Standard:
        return t({ id: 'core.kyc.standard', message: `Standard level` })
      case KYCTier.StandardPlus:
        return t({ id: 'core.kyc.standardPlus', message: `Standard Plus level` })
      case KYCTier.Advanced:
        return t({ id: 'core.kyc.advanced', message: `Advanced level` })
      case KYCTier.AdvancedPlus:
        return t({ id: 'core.kyc.advancedPlus', message: `Advanced Plus level` })
      default:
        return ''
    }
  }, [userKYC.kycTier, isBlockedTier])

  const findLimit = useCallback((limits: TierLimit[] = []) => {
    return limits?.find(limit => limit.period === KYCLimitPeriod.Total)?.value || 0
  }, [])

  const formatLimitValue = useCallback((limitValue: number | undefined) => {
    if (!limitValue) return ''
    if (limitValue === 999999999900) return t({ id: 'core.kyc.limit.unlimited', comment: 'Unlimited' })
    return `${formatNumber(limitValue / 100, { maximumFractionDigits: 3 })} USDT`
  }, [])

  const getKYCLimitsByTier = useCallback(
    (tier: KYCTier): TKYCLimit => {
      const tierLimits = limits.find(limit => limit.tier === tier)
      const depositLimit = findLimit(tierLimits?.depositLimits)
      const withdrawLimit = findLimit(tierLimits?.withdrawalLimits)

      return {
        deposit: {
          raw: depositLimit,
          formatted: formatLimitValue(depositLimit),
        },
        withdraw: {
          raw: withdrawLimit,
          formatted: formatLimitValue(withdrawLimit),
        },
      }
    },
    [limits, findLimit, formatLimitValue]
  )

  const isShowKycVerified = useMemo(() => {
    return isTierUpperBasic
  }, [isTierUpperBasic])

  const handleKycUpgrade = useCallback<IHandleKycUpgrade>(() => {
    navigate(urls.kyc)
  }, [])

  const currentTierLimits = useMemo(() => {
    return userKYC.kycTier === null ? null : getKYCLimitsByTier(userKYC.kycTier)
  }, [kycNextTier, isTierUpperBasic, limits])

  const nextTierLimits = useMemo(() => {
    const nextTierName = !isTierUpperBasic ? KYCTier.Standard : kycNextTier
    return nextTierName ? getKYCLimitsByTier(nextTierName) : null
  }, [kycNextTier, isTierUpperBasic, limits])

  return {
    kycLoadStatus,
    isLoading,
    isVerificationStarted,
    isVerificationError,
    isVerificationInProgressAfterStep1,
    isVerificationInProgressAfterStep2,
    isVerificationSuccess,
    isKycNeedWorkflow,
    kycLevelTitle,
    getKYCLimitsByTier,
    isShowKycVerified,
    handleKycUpgrade,
    kycNextTier,
    isTierUpperBasic,
    currentTierLimits,
    nextTierLimits,
  }
}
