import { FC } from 'react'

type VideoLinkIconProps = {
  className?: string
}

export const VideoLinkIcon: FC<VideoLinkIconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" className={className}>
    <path
      fill="#676573"
      d="M18 9c0 4.915-4.076 9-9 9-4.915 0-9-4.085-9-9 0-4.924 4.077-9 8.991-9 4.924 0 9.01 4.076 9.01 9Zm-13.72.68v3.273c0 .565.282.785.776.785h3.265c.37 0 .662-.3.662-.67a.655.655 0 0 0-.662-.662h-.459l-1.43.088 2.1-2.003a.72.72 0 0 0 .01-1.006.688.688 0 0 0-1.006.009l-2.021 2.118.097-1.474V9.68c0-.37-.3-.662-.67-.662a.655.655 0 0 0-.662.662Zm5.409-5.4a.67.67 0 0 0-.671.67c0 .37.3.661.67.661h.46l1.429-.088-2.109 2.003a.72.72 0 0 0-.009 1.006.699.699 0 0 0 1.015-.009l2.012-2.117-.088 1.473v.46c0 .37.29.66.661.66.37 0 .67-.29.67-.66V5.063c0-.564-.29-.785-.784-.785H9.689Z"
    />
  </svg>
)
