import { FC } from 'react'

export const Youtube: FC = () => {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3203 1.71999C18.6194 2.01449 18.8349 2.38099 18.9453 2.78301C19.5814 5.30442 19.4344 9.28661 18.9577 11.9053C18.8472 12.3073 18.6317 12.6738 18.3327 12.9683C18.0336 13.2628 17.6614 13.475 17.2532 13.5838C15.7586 13.9852 9.74344 13.9852 9.74344 13.9852C9.74344 13.9852 3.72825 13.9852 2.23372 13.5838C1.82547 13.475 1.45329 13.2628 1.15423 12.9683C0.855176 12.6738 0.639682 12.3073 0.529212 11.9053C-0.110597 9.39486 0.0647947 5.41024 0.51686 2.79517C0.627331 2.39315 0.842824 2.02665 1.14188 1.73215C1.44094 1.43766 1.81312 1.22545 2.22137 1.11667C3.7159 0.715288 9.73109 0.703125 9.73109 0.703125C9.73109 0.703125 15.7463 0.703125 17.2408 1.10451C17.6491 1.21329 18.0212 1.4255 18.3203 1.71999ZM8.10255 10.496C7.76925 10.6834 7.35742 10.4426 7.35742 10.0602V4.62836C7.35742 4.24595 7.76925 4.00509 8.10255 4.19257L12.9308 6.90847C13.2706 7.09963 13.2706 7.5889 12.9308 7.78005L8.10255 10.496Z"
        fill="white"
      />
    </svg>
  )
}
