import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v1 } from 'uuid'
import { SVG } from '@/assets/svg'
import { TransactionAssets } from '@/backend/api'
import { transactionsAsyncActions } from '@/redux/transactions/transactions.actions'
import { transactionsActions } from '@/redux/transactions/transactions.slice'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import {
  instrumentNames,
  checkIsDecimalsQuantityCorrect,
  formatNumber,
  getCompoundTransactionAmount,
  getKeys,
  useActions,
  useAppDispatch,
  useAppSelector,
  useMobileSizeDetect,
} from '@/utils'
import {
  Button,
  ButtonSize,
  ButtonVariant,
  ErrorView,
  Input,
  InputSize,
  InteractiveModal,
  InteractiveModalParts,
  Select,
  SelectOption,
} from '@/ui/kit'
import { NoCoins } from '@/ui/molecules'
import clsx from 'clsx'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { coinSorter } from '@/utils/lib/transactions'
import { NetworkSelectOption } from '@/ui/atoms/NetworkSelectOption'
import { CoinSelectOption } from '@/ui/atoms/CoinSelectOption'
import { EAccountType } from '@tigertrade/binance-ts'
import { urls } from '@/router/urls'
import { useScrollToError } from '@/utils/hooks'

export const TransferTransaction = memo(() => {
  const [isMobile] = useMobileSizeDetect()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { assetsForTransactions, transactionSendResult } = useAppSelector(state => state.transactions)
  const { tigerTradeId } = useAppSelector(state => state.profile)
  const { instrumentType } = useAppSelector(state => state.overview)
  const scrollToError = useScrollToError()

  const [accountFrom, setAccountFrom] = useState<EAccountType>(EAccountType.SPOT)
  const [accountTo, setAccountTo] = useState<EAccountType>(
    Object.values(EAccountType).includes(instrumentType as EAccountType)
      ? instrumentType === EAccountType.SPOT
        ? EAccountType.USDT_FUTURE
        : (instrumentType as EAccountType) || EAccountType.SPOT
      : EAccountType.SPOT
  )
  const [transactionId, setTransactionId] = useState('')
  const [isTransferAttemptDetected, setIsTransferAttemptDetected] = useState(false)

  const accountConfig = useMemo<AccountConfig>(
    () => makeAccountConfig(assetsForTransactions.assets),
    [assetsForTransactions]
  )

  const options = useMemo<SelectOption<EAccountType>[]>(() => {
    const result = getKeys(accountConfig).map(key => {
      const item = accountConfig[key]
      return {
        value: key,
        label: <NetworkSelectOption symbol={item.prefix} name={item.name} />,
        selected: item.name,
      }
    })

    return result
  }, [accountConfig])

  const optionsForAccountTo = useMemo(() => {
    return getOptionsForAccountTo(options, accountConfig, accountFrom)
  }, [accountFrom, accountConfig, options])

  useEffect(() => {
    const accountToValues = optionsForAccountTo.map(option => {
      return option.value
    })
    const defaultIndex = accountToValues.indexOf(EAccountType.USDT_FUTURE)

    const newAccountTo = optionsForAccountTo.some(item => item.value === accountTo)
      ? accountTo
      : defaultIndex >= 0
      ? optionsForAccountTo[defaultIndex].value
      : optionsForAccountTo[0].value

    if (newAccountTo !== accountTo) {
      setAccountTo(newAccountTo)
    }
  }, [optionsForAccountTo, accountTo])

  const [amount, setAmount] = useState<string>('')
  const [selectedAsset, setSelectedAsset] = useState<null | Asset>(null)

  const changeSelectedAsset = useCallback(
    (value: string) => {
      const selectedAssetNew = accountConfig[accountFrom].assetsOptions.find(item => item.value === value)

      if (selectedAssetNew) {
        setSelectedAsset({
          asset: selectedAssetNew.value,
          balance: selectedAssetNew.balance,
          balanceFormatted: selectedAssetNew.balanceFormatted,
        })
        setAmount('0')
      }
    },
    [accountConfig, accountFrom]
  )

  const [isModalOpen, setIsOpenModal] = useState(false)

  const { TransferAssetTC } = useActions(transactionsAsyncActions)

  const toggleAccounts = useCallback(() => {
    setAccountFrom(accountTo)
    setAccountTo(accountFrom)
    setAmount('')
  }, [accountFrom, accountTo])

  const getErrorMessage = () => {
    if (accountFrom === accountTo) {
      return t({ message: `Source and destination accounts are equal`, id: 'transferTransaction.messages.source' })
    }

    if (!selectedAsset?.asset) {
      return t({ message: `No coin selected`, id: 'transferTransaction.messages.noCoinsSelected' })
    }

    if (amount === '') {
      return t({ message: 'Enter amount', id: 'transferTransaction.messages.noCoinsSelect' })
    }

    if (Number(amount) <= 0) {
      return t({ message: `Incorrect amount`, id: 'transferTransaction.messages.inccorectAmount' })
    }

    if (Number(amount) > selectedAsset.balance) {
      return t({ message: 'Insufficient funds in the account', id: 'transferTransaction.messages.insufFunds' })
    }

    if (!checkIsDecimalsQuantityCorrect(amount, maximumDecimalsQuantity)) {
      return `${t({
        message: 'Only',
        id: 'transferTransaction.messages.onlyDec-1',
      })} ${maximumDecimalsQuantity} ${t({
        message: 'decimal places are allowed',
        id: 'transferTransaction.messages.onlyDec-2',
      })}`
    }

    return ''
  }

  const errorMessage = getErrorMessage()
  const isTransferAvailable = errorMessage === ''

  const transferAsset = useCallback(() => {
    setIsTransferAttemptDetected(true)

    if (!isTransferAvailable || selectedAsset === null) {
      scrollToError()
      return
    }

    const transactionId = v1()
    dispatch(transactionsActions.initTransaction(transactionId))
    setTransactionId(transactionId)

    TransferAssetTC({
      accountFrom,
      accountTo,
      amount: Number(amount),
      asset: selectedAsset?.asset || '',
      transactionId,
    })

    setIsOpenModal(true)
    scrollToError()
  }, [TransferAssetTC, accountFrom, accountTo, amount, dispatch, isTransferAvailable, selectedAsset, scrollToError])

  const fetchingError = assetsForTransactions.assets.errors?.[0]?.code

  const { status = 'loading', date } = transactionSendResult?.[transactionId] || {}

  const allowCoins = useMemo(() => {
    let items = [] as AssetOption[]
    if (accountTo === 'SPOT') {
      items = accountConfig[accountFrom].assetsOptions
    } else if (accountConfig[accountFrom].assetsOptions.length) {
      items = getAllowCoins(accountConfig[accountFrom].assetsOptions, accountConfig[accountTo].assetsOptions)
    }
    return coinSorter(items, 'name', 'balance')
  }, [accountConfig, accountFrom, accountTo])

  useEffect(() => {
    // set default asset on account change
    const usdtAsset = allowCoins.find(asset => {
      return asset.value === 'USDT'
    })
    const defaultAsset = usdtAsset || allowCoins[0]
    setSelectedAsset(
      defaultAsset
        ? { asset: defaultAsset.value, balance: defaultAsset.balance, balanceFormatted: defaultAsset.balanceFormatted }
        : null
    )
  }, [allowCoins])

  const handleSetMax = useCallback(() => {
    setAmount(String(selectedAsset?.balance ?? 0))
  }, [selectedAsset])

  if (fetchingError) {
    return <ErrorView code={fetchingError} />
  }

  return (
    <>
      <div className={clsx(isMobile && style.mobile, style.transfer)}>
        <div className={style.top}>
          <Select<EAccountType>
            size={isMobile ? Select.Size.Medium : Select.Size.Large}
            options={options}
            label={t({ message: 'From', id: 'core.transaction.from' })}
            value={accountFrom}
            onChange={setAccountFrom}
            dataTestId={DataTestIds.TransferAccountTypeSectionFrom}
          />

          <Button
            variant={isMobile ? Button.Variant.Primary : Button.Variant.Clean}
            onClick={toggleAccounts}
            className={style.buttonWrap}
            leftIcon={<SVG.Actions.Transfer />}
            dataTestId={DataTestIds.TransferSwitchButton}
          />
          <Select<EAccountType>
            size={isMobile ? Select.Size.Medium : Select.Size.Large}
            options={optionsForAccountTo}
            label={t({ message: 'To', id: 'core.transaction.to' })}
            value={accountTo}
            onChange={setAccountTo}
            dataTestId={DataTestIds.TransferAccountTypeSectionTo}
          />
        </div>

        <div className={clsx(style.coins, { [style.wihtoutMargin]: accountTo === 'COIN_FUTURE' })}>
          <Input
            size={isMobile ? InputSize.Medium : InputSize.Large}
            setValue={setAmount}
            value={amount}
            placeholder="0"
            dataTestId={DataTestIds.AmountInput}
            info={
              selectedAsset && (
                <>
                  {t({ message: 'Available', id: 'core.transaction.available' })}
                  <span className={style.available} data-testid={DataTestIds.AmountAvailableField}>
                    {' '}
                    {selectedAsset.balanceFormatted} {selectedAsset.asset}
                  </span>
                </>
              )
            }
            errorMessage={isTransferAttemptDetected ? errorMessage : ''}
            inputAttrs={{ type: 'number' }}
            containerClassName={style.input}
          >
            {allowCoins.length ? (
              <div className={style.inputAside}>
                {selectedAsset && (
                  <Button.Primary
                    label="Max"
                    className={style.maxBtn}
                    onClick={handleSetMax}
                    dataTestId={DataTestIds.AmountMaxButton}
                  />
                )}
                <Select
                  size={Select.Size.Medium}
                  onChange={changeSelectedAsset}
                  value={selectedAsset?.asset || null}
                  className={style.coinSelect}
                  options={allowCoins}
                  variant={Select.Variant.Raw}
                  dataTestId={DataTestIds.CoinSelector}
                />
              </div>
            ) : (
              <NoCoins fromAccount={accountConfig[accountFrom].name} toAccount={accountConfig[accountTo].name} />
            )}
          </Input>
        </div>

        {accountTo === 'COIN_FUTURE' && (
          <div className={style.informationBanner}>
            <div className={style.infromationWarningMessage} data-testid={DataTestIds.InformationWarningMessage}>
              <p>
                {t({ message: 'You can transfer all cryptocurrencies to', id: 'transferTransaction.desc-1' })}{' '}
                <span className={style.warningWalletText}>Futures COIN-M</span>,&nbsp;
                {t({ message: 'except', id: 'transferTransaction.desc-2' })}{' '}
                <span className={style.warningCoinsText}>
                  {t({ message: 'stablecoins', id: 'transferTransaction.desc-3' })}
                </span>
              </p>

              <div>
                <SVG.OtherIcons.Mark2 />
              </div>
            </div>
          </div>
        )}
        <Button.Accent
          size={isMobile ? ButtonSize.Medium : ButtonSize.Large}
          onClick={transferAsset}
          label={t({ message: 'Transfer', id: 'core.transfer' })}
          description={t({ id: 'core.transaction.withoutCommission', message: `Without commission` })}
          disabled={transactionId.length > 0}
          dataTestId={DataTestIds.TransferConfirmButton}
          className={style.actionButton}
        />
      </div>

      <InteractiveModal isOpen={isModalOpen} className={clsx(isMobile && style.mobile)}>
        <InteractiveModalParts.Icon icon="transfer" />
        <InteractiveModalParts.Header text="Transfer" />
        <InteractiveModalParts.MainInfo
          text={getCompoundTransactionAmount(amount, selectedAsset?.asset)}
          className={style.mainInfo}
        />
        <InteractiveModalParts.Explanation
          text={t({ id: 'core.transaction.withoutCommission', message: `Without commission` })}
          className={style.explanation}
        />
        <InteractiveModalParts.Status
          status={status === 'loading' || status === 'succeeded' ? 'loading' : 'failed'}
          date={date}
        />
        <InteractiveModalParts.Table
          left={t({ message: 'From', id: 'core.transaction.from' })}
          right={accountConfig[accountFrom].name}
          dataTestId={DataTestIds.HistoryModalFrom}
        />
        <InteractiveModalParts.Table
          left={t({ message: 'To', id: 'core.transaction.to' })}
          right={accountConfig[accountTo].name}
          dataTestId={DataTestIds.HistoryModalAddrTo}
        />
        <InteractiveModalParts.TableCopy
          left={t({ id: 'core.modal.id', message: `Tiger.Trade ID` })}
          right={tigerTradeId || ''}
          isShortenView
          dataTestId={DataTestIds.ModalTigerId}
        />
        <InteractiveModalParts.Button
          text={t({ message: 'Go back to main page', id: 'core.gobackMainPage' })}
          variant={ButtonVariant.White}
          onClick={() => navigate(urls.root)}
          dataTestId={DataTestIds.ModalReturnButton}
        />
      </InteractiveModal>
    </>
  )
})

type Assets = TransactionAssets['coinAccountBalance']['assets']
type Asset = Assets[number] & {
  balanceFormatted: string
}

type AssetOption = SelectOption & {
  name: string
  balance: number
  balanceFormatted: string
}

type AccountItem = {
  name: string
  prefix: string
  assetsOptions: Array<AssetOption>
  allowedAccountsToTransfer: Array<EAccountType>
}

type AccountConfig = Record<EAccountType, AccountItem>

const makeAccountConfig = ({
  coinAccountBalance,
  spotAccountBalance,
  futuresAccountBalance,
}: TransactionAssets): AccountConfig => {
  return {
    COIN_FUTURE: {
      name: instrumentNames['COIN_FUTURE'].name,
      assetsOptions: makeAssetOptions(coinAccountBalance.assets),
      prefix: getBalanceAndTokenSymbol(coinAccountBalance),
      allowedAccountsToTransfer: [EAccountType.SPOT],
    },
    SPOT: {
      name: instrumentNames['SPOT'].name,
      assetsOptions: makeAssetOptions(spotAccountBalance.assets),
      prefix: getBalanceAndTokenSymbol(spotAccountBalance),
      allowedAccountsToTransfer: [EAccountType.COIN_FUTURE, EAccountType.USDT_FUTURE],
    },
    USDT_FUTURE: {
      name: instrumentNames['USDT_FUTURE'].name,
      assetsOptions: makeAssetOptions(futuresAccountBalance.assets),
      prefix: getBalanceAndTokenSymbol(futuresAccountBalance),
      allowedAccountsToTransfer: [EAccountType.SPOT],
    },
  }
}

const getBalanceAndTokenSymbol = (asset: TransactionAssets['coinAccountBalance']): string => {
  const formattedBalance = formatNumber(asset?.balance)

  return `${formattedBalance} ${asset?.symbol || ''}`
}

const makeAssetOptions = (assets: Assets): Array<AssetOption> => {
  return assets.map<AssetOption>(item => ({
    name: item.asset,
    value: item.asset,
    label: <CoinSelectOption asset={item.asset} balance={formatNumber(item.balance)} />,
    selected: <CoinSelectOption asset={item.asset} large />,
    balance: item.balance,
    balanceFormatted: formatNumber(item.balance),
  }))
}

const maximumDecimalsQuantity = 8

const getOptionsForAccountTo = (
  options: SelectOption<EAccountType>[],
  accountConfig: AccountConfig,
  accountTo: EAccountType
) => options.filter(item => accountConfig[accountTo].allowedAccountsToTransfer.includes(item.value))

const getAllowCoins = <T extends SelectOption>(target: T[], allowOptions: T[]): T[] => {
  const allowValuesOptions = allowOptions.map(option => option.value)
  return target.filter(option => allowValuesOptions.includes(option.value))
}
