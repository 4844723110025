export const defaultAmount: amountConvert = {
  from: {
    value: '',
    symbol: null,
    balance: '0',
  },
  to: {
    value: '',
    symbol: null,
    balance: '0',
  },
}

export const VALID_TIME = '1m'

export type amountConvert = {
  from: {
    value: string
    symbol: string | null
    balance: string
    placeholder?: string
    minAmount?: number
    maxAmount?: number
  }
  to: {
    value: string
    symbol: string | null
    balance: string
    placeholder?: string
  }
}

export type PreviewData = {
  fromAsset: string
  toAsset: string
  fromAmount: string
  validTime: '1m' | '10s' | '30s'
}

export const CONVERT_TIMER = 60
