import { Loader } from '@/ui/kit/Loader'
import React, { FC, Suspense } from 'react'

const TigerTokenPageInner = React.lazy(() => import('./components/TigerTokenPageInner/TigerTokenPageInner'))

export const TigetTokenPage: FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <TigerTokenPageInner />
    </Suspense>
  )
}
