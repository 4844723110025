import { TExchangesAvailable } from '@/core/constants'
import { INotificationConfig, INotificationRenderProps } from '@/ui/molecules/SystemNotifications/notifications.type'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { isBoolean } from 'lodash-es'
import { saveClosedNotificationToSessionStorage } from '../libs/alertsSessionStorage'

interface INormalizeNotificationConfig {
  currentExchange: TExchangesAvailable | undefined
  closedNotifications: Record<string, boolean>
  patchAction: ActionCreatorWithPayload<Record<string, boolean>, string>
  notifications: INotificationConfig[]
}

const cache: any = {
  exchange: null,
  closedNotifications: {},
  notifications: [],
  value: [],
}

function isCachedValue(
  exchange: TExchangesAvailable,
  notifications: INotificationConfig[],
  closedNotifications: Record<string, boolean>
): boolean {
  if (
    cache.exchange !== exchange ||
    !Object.is(closedNotifications, cache.closedNotifications) ||
    cache.notifications.length !== notifications.length ||
    !notifications.every((n, i) => Object.is(n, cache.value[i]))
  ) {
    return false
  }

  return true
}

function isPresent<T>(value?: T | null): value is T {
  return value !== null && value !== undefined
}

export function normalizeNotifications({
  currentExchange,
  closedNotifications,
  patchAction,
  notifications,
}: INormalizeNotificationConfig): INotificationRenderProps[] {
  if (!currentExchange) return []

  if (isCachedValue(currentExchange, notifications, closedNotifications)) {
    return cache.value
  }

  const notificationsForRender = notifications.map(({ availableExchange, shouldShown, isClosable, ...props }) => {
    // Checking whether the notification can be displayed on the current exchange
    if (availableExchange && !availableExchange.includes(currentExchange)) {
      return null
    }

    // Checking that the user has closed the notification
    if (closedNotifications[props.id]) {
      return null
    }

    // Checking for a unique condition for displaying a notification
    if (isBoolean(shouldShown) && !shouldShown) {
      return null
    }

    const handlers = {
      ...(isClosable && {
        onClose: () => {
          saveClosedNotificationToSessionStorage(props.id)
          patchAction({ [props.id]: true })
        },
      }),
    }

    return {
      ...props,
      isClosable,
      ...handlers,
    }
  })

  cache.exchange = currentExchange
  cache.closedNotifications = closedNotifications
  cache.notifications = notifications
  cache.value = notificationsForRender.filter(isPresent)

  return cache.value
}
