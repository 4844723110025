import { FC, memo } from 'react'
import { TitleH1 } from '../../atoms/TitleH1'
import HowItWorks from '../../molecules/HowItWorks'
import { ApiKeyTradeBanner } from '../ApiKeyTradeBanner'
import style from './style.module.scss'
import { t } from '@lingui/macro'

const ApiLeftBar: FC = () => {
  return (
    <div className={style.leftBar}>
      <TitleH1
        label={
          <>
            {t({
              id: 'apiPage.title.message-1',
              message: 'Add API keys',
            })}
            <br />
            {t({
              id: 'apiPage.title.message-2',
              message: 'to platform',
            })}
          </>
        }
      />
      <HowItWorks />
      <ApiKeyTradeBanner />
    </div>
  )
}

export default memo(ApiLeftBar)
