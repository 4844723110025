import {
  DEFAULT_LEVERAGE_VALUE,
  DEFAULT_VALUE_PERCENT_SYMBOL,
  DEFAULT_VALUE_WITHOUT_SYMBOL,
} from '@/ui/organisms/DiaryRightBar/components/WeekCard/WeekCard'
import BigNumber from 'bignumber.js'

export const getPercentage = (winCount: number, count: number) => {
  if (
    winCount === null ||
    winCount === undefined ||
    count === null ||
    count === undefined ||
    count === 0 ||
    winCount === 0
  ) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const winCountBN = new BigNumber(winCount)
  const countBN = new BigNumber(count)

  if (countBN.isZero()) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const percentage = winCountBN.dividedBy(countBN).multipliedBy(100).toFixed(2)
  return `${parseFloat(percentage)} %`
}

export const getRatio = (avgWin: string | number, avgLoss: string | number) => {
  if (
    avgWin === null ||
    avgWin === undefined ||
    avgLoss === null ||
    avgLoss === undefined ||
    Number(avgLoss) === 0 ||
    Number(avgWin) === 0
  ) {
    return DEFAULT_VALUE_WITHOUT_SYMBOL
  }

  const avgWinBN = new BigNumber(avgWin)
  const avgLossBN = new BigNumber(avgLoss)

  if (avgLossBN.isZero()) {
    return DEFAULT_VALUE_WITHOUT_SYMBOL
  }

  const ratio = avgWinBN.dividedBy(avgLossBN).toFixed(2)
  return parseFloat(ratio)
}

export const calculatePercentage = (longCount: string | number, totalCount: string | number) => {
  if (
    longCount === null ||
    longCount === undefined ||
    totalCount === null ||
    totalCount === undefined ||
    Number(totalCount) === 0 ||
    Number(longCount) === 0
  ) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const longCountBN = new BigNumber(longCount)
  const totalCountBN = new BigNumber(totalCount)

  if (totalCountBN.isZero()) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const percentage = new BigNumber(100).minus(longCountBN.dividedBy(totalCountBN).multipliedBy(100)).toFixed(2)
  return percentage === DEFAULT_VALUE_WITHOUT_SYMBOL ? DEFAULT_VALUE_PERCENT_SYMBOL : `${parseFloat(percentage)} %`
}

export const calculateLongPercentage = (long_count: number, count: number) => {
  if (
    count === 0 ||
    long_count === 0 ||
    count === null ||
    null === undefined ||
    long_count === undefined ||
    long_count === null
  ) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const longCount = new BigNumber(long_count)
  const countValue = new BigNumber(count)
  const percentage = longCount.dividedBy(countValue).multipliedBy(100)
  const formattedPercentage = percentage.toFixed(2)

  return formattedPercentage + ' %'
}

export const calculateWinPercentage = (count: number, win_count: number) => {
  if (
    count === 0 ||
    win_count === 0 ||
    count === null ||
    count === undefined ||
    win_count === undefined ||
    win_count === null
  ) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const winCount = new BigNumber(win_count)
  const totalCount = new BigNumber(count)

  const percentage = winCount.dividedBy(totalCount).multipliedBy(100)
  const formattedPercentage = percentage.toFixed(2)

  return formattedPercentage + ' %'
}

export const calculateInversePercentage = (count: number, long_count: number) => {
  if (
    count === 0 ||
    long_count === 0 ||
    count === null ||
    null === undefined ||
    long_count === undefined ||
    long_count === null
  ) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const longCount = new BigNumber(long_count)
  const countValue = new BigNumber(count)

  const percentage = longCount.dividedBy(countValue).multipliedBy(100)
  const inversePercentage = new BigNumber(100).minus(percentage)
  const formattedInversePercentage = inversePercentage.toFixed(2)

  return formattedInversePercentage + ' %'
}

export const formatLeverage = (leverage: string) => {
  if (leverage) {
    const leverageValue = new BigNumber(leverage)
    const formattedLeverage = 'x' + leverageValue.toFixed(2)
    return formattedLeverage
  } else {
    return DEFAULT_LEVERAGE_VALUE
  }
}

export const calculateWeekWinPercentage = (count: number, win_count: number) => {
  if (count === 0) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const winCount = new BigNumber(win_count)
  const totalCount = new BigNumber(count)

  const percentage = winCount.dividedBy(totalCount).multipliedBy(100)
  const formattedPercentage = percentage.toFixed(2)

  return formattedPercentage + ' %'
}

export const getPercentageDivided = (winCount: number, count: number) => {
  if (
    winCount === null ||
    winCount === undefined ||
    count === null ||
    count === undefined ||
    count === 0 ||
    winCount === 0
  ) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const winCountBN = new BigNumber(winCount)
  const countBN = new BigNumber(count)

  if (countBN.isZero()) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const percentage = winCountBN.dividedBy(countBN).toFixed(2)
  return `${parseFloat(percentage)} %`
}
