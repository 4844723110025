import React from 'react'

export const Eye = () => {
  return (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.600375 4.05654C1.48969 2.52977 3.67392 0.152344 7.0008 0.152344C10.3277 0.152344 12.5119 2.52977 13.4012 4.05654C13.563 4.33419 13.5639 4.67516 13.403 4.95333C12.5225 6.47628 10.3483 8.84555 7.0008 8.84555C3.65332 8.84555 1.47909 6.47628 0.598578 4.95334C0.437748 4.67516 0.438648 4.33419 0.600375 4.05654ZM9.0894 4.50405C9.0894 5.65508 8.15631 6.58817 7.00528 6.58817C6.2163 6.58817 5.52972 6.14976 5.17586 5.50326C5.62451 5.30483 5.98848 4.88083 5.98848 4.51491C5.98848 4.08678 5.65321 3.68985 5.17884 3.49943C5.53356 2.8559 6.21851 2.41993 7.00528 2.41993C8.15631 2.41993 9.0894 3.35303 9.0894 4.50405Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
