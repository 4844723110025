import React from 'react'

export const Crown = () => {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_6882_1297)">
        <path
          d="M2.87499 9.5944L0.836926 3.04673C0.724769 2.6864 1.12816 2.38545 1.44161 2.59561L4.64365 4.7425C4.93602 4.93853 5.33355 4.84193 5.50336 4.53359L7.64962 0.636251C7.80164 0.360192 8.19836 0.360192 8.35038 0.636251L10.4966 4.53359C10.6664 4.84193 11.064 4.93853 11.3563 4.7425L14.5584 2.59561C14.8718 2.38545 15.2752 2.6864 15.1631 3.04672L13.125 9.5944C12.8648 10.4304 12.091 11 11.2154 11H4.78462C3.90902 11 3.13522 10.4304 2.87499 9.5944Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_6882_1297"
          x="0.818359"
          y="-0.570312"
          width="14.3633"
          height="11.5703"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.366602 0 0 0 0 0.0748453 0 0 0 0 0.0748453 0 0 0 0.22 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6882_1297" />
        </filter>
      </defs>
    </svg>
  )
}
