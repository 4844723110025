import { useEffect, useState } from 'react'
import { useAppSelector } from './useAppSelector'
import { KYCTier } from '@/backend/models/KycDTO'
import { KYCLevelService } from '@/services/KYCLevelService/KYCLevelService'
import { isExchangeBinance } from '../lib/exchange'
import { TExchangesAvailable } from '@/core/constants'
import { useMemo } from 'react'
import { parseArrayEnv, parseBooleanEnv } from '../lib/parseEnvVariable'
import { useLocation } from 'react-router-dom'
import { urls } from '@/router/urls'

export const useExchangeBlockedByFirstSession = () => {
  const {
    metadata: { isFirstSessionTracked },
  } = useAppSelector(state => state.profile)

  return !isFirstSessionTracked
}

export const useExchangeBlockedByReadOnlyKey = (): boolean => {
  const { createdApiReadOnlyStatus } = useAppSelector(state => state.apiKeys)

  const isKeyReady = useMemo(() => {
    return createdApiReadOnlyStatus === 'succeeded'
  }, [createdApiReadOnlyStatus])

  return !isKeyReady
}

const BLOCKED_URLS: Record<TExchangesAvailable, string[]> = {
  BINANCE_BROKER_FUTURE: [
    urls.transactions,
    urls.orders,
    urls.wallet,
    urls.assets,
    urls.leverage,
    urls.historyRoot,
    urls.api,
  ],
  BINANCE_VIP: [urls.transactions, urls.orders, urls.wallet, urls.assets, urls.leverage, urls.historyRoot, urls.api],
  BYBIT_BROKER: [],
  OKX_BROKER: [],
  OKX_AFFILIATE: [],
}

interface OverlayInfo {
  isExchangeBlockedByOverlay: boolean
  shouldShowBlockingOverlay: boolean
}

const QA_HACKS = parseBooleanEnv(localStorage.getItem('QA_OVERLAY_HACKS'))

export function useExchangeBlockedOverlay<T extends TExchangesAvailable | undefined>(exchangeType: T): OverlayInfo {
  const { pathname } = useLocation()

  const isExchangeBlocked = useMemo(() => {
    if (QA_HACKS) return false
    return parseArrayEnv<Array<T>>(process.env.REACT_APP_EXCHANGES_WITH_BLOCKING_OVERLAY).includes(exchangeType)
  }, [exchangeType])

  const shouldShowBlockingOverlay = useMemo(() => {
    if (!isExchangeBlocked || !exchangeType) return false

    return BLOCKED_URLS[exchangeType!].some(route => pathname.startsWith(route))
  }, [isExchangeBlocked, exchangeType, pathname])

  return {
    isExchangeBlockedByOverlay: isExchangeBlocked,
    shouldShowBlockingOverlay,
  }
}

export function useExchangeBlockedByKycTier() {
  const { userKYC } = useAppSelector(state => state.kyc)
  const { exchangeType } = useAppSelector(state => state.app)
  const { createdApiReadOnlyStatusInitial } = useAppSelector(state => state.apiKeys)

  const isExchangeProviderInitializationBlocked = useMemo(() => {
    if (userKYC.isBlocked || userKYC.isUsaMode) {
      return true
    }

    if (isExchangeBinance(exchangeType)) {
      return (
        KYCLevelService.compareTier(KYCTier.Standard, userKYC.kycTier) === 'prev' ||
        createdApiReadOnlyStatusInitial !== 'succeeded'
      )
    }

    return false
  }, [userKYC, exchangeType, createdApiReadOnlyStatusInitial])

  return isExchangeProviderInitializationBlocked
}
