import { FC, memo } from 'react'
import { t } from '@lingui/macro'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/ui/kit'
import AccountBalance from '@/ui/molecules/AccountBalance'
import BalanceSheet from '@/ui/molecules/BalanceSheet'
import { useOverview } from '@/utils/hooks/useOverview'
import { urls } from '@/router/urls'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

const AccountInfo: FC = () => {
  const navigate = useNavigate()
  const { balancesArray } = useOverview()

  return (
    <div className={style.accountInfo}>
      <AccountBalance />
      <Button.Secondary
        onClick={() => navigate(urls.historyTransactions)}
        label={t({ id: 'core.history', message: 'History' })}
        className={style.button}
        dataTestId={DataTestIds.AccountInfoHistoryButton}
      />
      <BalanceSheet balances={balancesArray} />
    </div>
  )
}

export default memo(AccountInfo)
