import clsx from 'clsx'
import { memo, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { Button, ButtonVariant } from '@/ui/kit'
import style from './style.module.scss'
import { useAppSelector, useMobileSizeDetect } from '@/utils'
import { KYCLevelService } from '@/services/KYCLevelService/KYCLevelService'
import { t } from '@lingui/macro'

export type SuccessVerificationProps = {
  /** callback to emit on button click */
  onClickDepositButton?: () => void

  /** callback to emit on button click */
  onClickReturnButton?: () => void

  /** style fo AnotherAttemptButton */
  variantDepositButton?: ButtonVariant

  isUnlimited?: boolean
}

export const SuccessVerification = memo<SuccessVerificationProps>(
  ({ onClickReturnButton, isUnlimited, onClickDepositButton }) => {
    const navigate = useNavigate()
    const [isMobile] = useMobileSizeDetect()

    const { userEmail } = useAppSelector(state => state.profile)

    const { userKYC, availableLimits } = useAppSelector(state => state.kyc)

    const redirectToDeposit = useCallback(async () => {
      onClickDepositButton?.()
      localStorage.setItem('transactionType', 'deposit')
      navigate(urls.transactions)
    }, [navigate, onClickDepositButton])

    const handleClose = useCallback(async () => {
      onClickReturnButton?.()
    }, [onClickReturnButton])

    useEffect(() => {
      if (userEmail) {
        KYCLevelService.setLastSavedTier(userEmail, userKYC.kycTier)
      }
    }, [userEmail, userKYC])

    const verificationComponent = (
      <div className={clsx(isMobile && style.mobile, style.root)}>
        {isUnlimited ? (
          <div className={clsx(style.header, style.headerUnlimited)}>
            {t({
              id: 'popupSequence.verified.unlimited.message-1',
              message: 'Your deposits & withdrawals are now',
            })}{' '}
            <span className={style.gradientText}>
              {t({
                id: 'popupSequence.verified.unlimited.message-2',
                message: 'unlimited',
              })}
            </span>
          </div>
        ) : (
          <>
            <div className={style.header}>
              {t({
                id: 'popupSequence.verified.title',
                message: 'Verification success',
              })}
            </div>
            <div className={style.text}>
              {t({
                id: 'popupSequence.verified.limits.message-1',
                message: 'You can now',
              })}{' '}
              <span className={style.gradientText}>
                {t({
                  id: 'popupSequence.verified.limits.message-2',
                  message: 'deposit up to',
                })}{' '}
                {availableLimits.depositLimit.limitFormatted}
              </span>{' '}
              {t({
                id: 'popupSequence.verified.limits.message-3',
                message: 'and',
              })}{' '}
              <span className={style.gradientText}>
                {t({
                  id: 'popupSequence.verified.limits.message-4',
                  message: 'withdraw up to',
                })}{' '}
                {availableLimits.withdrawalLimit.limitFormatted}
              </span>
            </div>
          </>
        )}
        <Button.Accent
          onClick={redirectToDeposit}
          label={t({ id: 'core.operation.deposit', message: 'Deposit' })}
          className={style.button}
        />
        <Button.Primary
          onClick={handleClose}
          label={t({
            id: 'core.back',
            message: `Back`,
          })}
          className={clsx(style.button, style.returnButton)}
        />
      </div>
    )

    return <>{verificationComponent}</>
  }
)
