import { FC, memo, useMemo } from 'react'
import { t } from '@lingui/macro'
import { ListTab } from '@/ui/atoms/ListTab'
import { TabIds } from '@/redux/overview/overview.types'
import { useOverview } from '@/utils/hooks/useOverview'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { EAccountType } from '@tigertrade/binance-ts'
import { EBybitInstruments } from '@/services/bybit'

const ListTabBar: FC = () => {
  const { instrumentType, tabId, setTabId } = useOverview()

  const tabs = useMemo(() => {
    const tabs = [
      { id: 1 as TabIds, text: t({ message: `Orders`, id: 'core.orders' }), dataTestId: DataTestIds.OrdersTab },
    ]

    if (instrumentType !== EAccountType.SPOT && instrumentType !== EBybitInstruments.spot) {
      tabs.push({
        id: 2 as TabIds,
        text: t({ message: `Positions`, id: 'core.positions' }),
        dataTestId: DataTestIds.PositionsTab,
      })
    }

    return tabs
  }, [instrumentType])

  const handleSelectTab = (id: TabIds) => () => {
    setTabId(id)
  }

  return (
    <div className={style.tabs}>
      {tabs.map(({ text, id, dataTestId }) => (
        <ListTab
          key={id}
          text={text}
          tabId={id}
          activeTab={tabId}
          setActiveTab={handleSelectTab(id)}
          dataTestId={dataTestId}
        />
      ))}
    </div>
  )
}

export default memo(ListTabBar)
