import { t } from '@lingui/macro'
import { useMemo } from 'react'
import article from '@/assets/jpg/article.jpg'
import articleVideo from '@/assets/jpg/article_video.jpg'
import video_0 from '@/assets/jpg/content/proscalping_0.jpg'
import video_1 from '@/assets/jpg/content/proscalping_1.jpg'
import video_2 from '@/assets/jpg/content/proscalping_2.jpg'
import { ACHIEVEMENTS_CONTENT, PARTNER_TRADING_VOLUME } from '@/core/config/partner'
import { EPartnerAchievements, EPartners } from '@/redux/partner/partner.types'
import { getTtLinksByLocale } from '@/router/links'
import { getPartnerTranslations } from '@/translations/partner'
import { TAchievementSouce } from '@/ui/molecules/Achievements/Achievements'
import { PlayBtn } from '@/ui/molecules/VideoPlayer/PlayBtn'
import { formatNumber, useAppSelector } from '@/utils'
import { TArticle } from '../../Articles/Articles.types'
import { TPartnerAchievementTranslationRecord } from '../PartnerRightBar.types'
import { useLanguageContext } from '@/providers/LanguageProvider'

export const useAchievementsData = (
  showPlayer: Function,
  partnerType?: string
): {
  achievementsSource: TAchievementSouce[]
  differentSource: TArticle[]
  videoSource: TArticle[]
  studySource: TArticle[]
} => {
  const { locale } = useLanguageContext()
  const { achievements, achievementPending, tradingVolume } = useAppSelector(state => state.partner)
  const translations = useMemo(() => {
    return getPartnerTranslations()
  }, [])

  const achievementsSource = useMemo<TAchievementSouce[]>(() => {
    return achievements.map(achievement => {
      const type = achievement.achievementType
      const content = ACHIEVEMENTS_CONTENT[type]
      const translation: TPartnerAchievementTranslationRecord = translations.achievementsVocabulary[type]
      const tradingVolumeShown =
        tradingVolume && tradingVolume > PARTNER_TRADING_VOLUME ? PARTNER_TRADING_VOLUME : tradingVolume
      return {
        id: type,
        icon: content.icon,
        link: content.link,
        text: {
          ...translation,
          description: (
            <>
              {translation.description}
              {type === EPartnerAchievements.tradingVolume10K && (
                <>
                  <br />
                  {formatNumber(tradingVolumeShown, {
                    maximumFractionDigits: 2,
                  })}{' '}
                  / {formatNumber(PARTNER_TRADING_VOLUME)}
                </>
              )}
            </>
          ),
        },
        unlocked: achievement.unlocked,
        pending: achievementPending?.achievementType === achievement.achievementType,
      }
    })
  }, [achievements, translations, achievementPending, tradingVolume])

  const differentSource = useMemo<TArticle[]>(() => {
    switch (partnerType) {
      case EPartners.proscalping:
        return [
          {
            date: '09.11.23',
            tags: ['#terminal'],
            heading: 'Файл конфигурации',
            text: 'Файл конфигурации терминала Tider.Trade',
            linkText: t({ id: 'core.download' }),
            image: article,
            callback: () => {
              window.open(getTtLinksByLocale('tt_cdn_proscalping', locale))
            },
          },
        ]

      case EPartners.cryptosquad:
        return [
          {
            date: '06.12.24',
            tags: ['#terminal'],
            heading: 'Файл конфигурации',
            text: 'Файл конфигурации KCScalp',
            linkText: t({ id: 'core.download' }),
            image: article,
            callback: () => {
              window.open(getTtLinksByLocale('files_KCScalp', locale))
            },
          },
          {
            date: '06.12.24',
            tags: ['#terminal'],
            heading: 'Файл конфигурации',
            text: 'Файл конфигурации Аристотель',
            linkText: t({ id: 'core.download' }),
            image: article,
            callback: () => {
              window.open(getTtLinksByLocale('files_Aristotel', locale))
            },
          },
          {
            date: '06.12.24',
            tags: ['#terminal'],
            heading: 'Файл конфигурации',
            text: 'Файл конфигурации Crypto-Бобр',
            linkText: t({ id: 'core.download' }),
            image: article,
            callback: () => {
              window.open(getTtLinksByLocale('files_Crypto_Bobr', locale))
            },
          },
          {
            date: '06.12.24',
            tags: ['#terminal'],
            heading: 'Файл конфигурации',
            text: 'Файл конфигурации Nekit',
            linkText: t({ id: 'core.download' }),
            image: article,
            callback: () => {
              window.open(getTtLinksByLocale('files_Nekit', locale))
            },
          },
        ]

      default:
        return []
    }
  }, [])

  const videoSource = useMemo<TArticle[]>(() => {
    const volumeAchievement = achievementsSource.find(acievementItem => {
      return acievementItem.id === EPartnerAchievements.tradingVolume10K
    })

    switch (partnerType) {
      case EPartners.proscalping:
        return [
          {
            date: '06.11.23',
            tags: ['#trading'],
            heading: 'Закрытый разбор',
            text: 'Большой разбор сделок за рабочий день',
            image: video_0,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('Fpd7FT6QsLA')
            },
          },
          {
            date: '06.02.24',
            tags: ['#trading'],
            heading: 'СКАЛЬПИНГ ПО СТАКАНУ',
            text: 'Скальпинг по стакану',
            image: video_1,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('Tnfc4H0k9co')
            },
            disabled: !volumeAchievement?.unlocked,
            tradingVolume,
          },
          {
            date: '18.09.24',
            tags: ['#trading'],
            heading: 'ОДИН ДЕНЬ ЖИВОЙ ТОРГОВЛИ',
            text: 'Торговый день',
            image: video_2,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('xpG80DzMTDE')
            },
            disabled: !volumeAchievement?.unlocked,
            tradingVolume,
          },
        ]

      case EPartners.cryptosquad:
        return [
          {
            date: '06.12.24',
            tags: ['#trading'],
            heading: 'Настройка терминала',
            text: 'Пошаговая настройка терминала',
            image: articleVideo,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('G9X7dHrkZd0')
            },
          },
          {
            date: '06.12.24',
            tags: ['#trading'],
            heading: 'Приложение Tiger Exchange',
            text: 'Мобильное приложение для трейдинга',
            image: articleVideo,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('uEMfpX3i3-4')
            },
            disabled: !volumeAchievement?.unlocked,
            tradingVolume,
          },
          {
            date: '06.12.24',
            tags: ['#trading'],
            heading: 'Плеер',
            text: 'Торговля ситуаций в прошлом',
            image: articleVideo,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('pvzh627mNbk')
            },
            disabled: !volumeAchievement?.unlocked,
            tradingVolume,
          },
          {
            date: '06.12.24',
            tags: ['#trading'],
            heading: 'Важные настройки стакана и ленты',
            text: 'Настройка стаканов, ленты и важных настроек терминала. Конфигурация.',
            image: articleVideo,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('jvMOzW2ZRag')
            },
            disabled: !volumeAchievement?.unlocked,
            tradingVolume,
          },
          {
            date: '06.12.24',
            tags: ['#trading'],
            heading: 'Фишки терминала',
            text: 'Важные настройки и фишки терминала',
            image: articleVideo,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('j4aDY69LFOA')
            },
            disabled: !volumeAchievement?.unlocked,
            tradingVolume,
          },
        ]

      default:
        return []
    }
  }, [tradingVolume, showPlayer, achievementsSource])

  const studySource = useMemo<TArticle[]>(() => {
    const volumeAchievement = achievementsSource.find(acievementItem => {
      return acievementItem.id === EPartnerAchievements.tradingVolume10K
    })

    switch (partnerType) {
      case EPartners.cryptosquad:
        return [
          {
            date: '06.12.24',
            tags: ['#trading'],
            heading: 'Торговля отскоков от плотности',
            text: 'Как торговать отскок от плотности? Обучение по скальпингу',
            image: articleVideo,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('XLrMPBgaSbA')
            },
          },
          {
            date: '06.12.24',
            tags: ['#trading'],
            heading: 'Стратегия на пробой уровня',
            text: 'Обучение по скальпингу (Часть 1) - торговая стратегия: пробой уровня',
            image: articleVideo,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('eeGsrT_LoXE')
            },
            disabled: !volumeAchievement?.unlocked,
            tradingVolume,
          },
          {
            date: '06.12.24',
            tags: ['#trading'],
            heading: 'Как торговать пробои',
            text: 'Обучение по скальпингу (Часть 2) - торговая стратегия: пробой уровня',
            image: articleVideo,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('C-_dH_ZBzPM')
            },
            disabled: !volumeAchievement?.unlocked,
            tradingVolume,
          },
          {
            date: '06.12.24',
            tags: ['#trading'],
            heading: 'Пробой уровня WIF от Cryptoblog',
            text: 'Пробой уровня WIFUSDT на +3432$! Сделка Саши (cryptOblog)',
            image: articleVideo,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('rH9dCMe2Sko')
            },
            disabled: !volumeAchievement?.unlocked,
            tradingVolume,
          },
          {
            date: '06.12.24',
            tags: ['#trading'],
            heading: 'Скринеры и сервисы для торговли',
            text: 'Скринеры и сервисы для скальпинга',
            image: articleVideo,
            customLink: ({ onClick }) => {
              return <PlayBtn onClick={onClick} title={t({ id: 'player.play' })} />
            },
            linkToImage: true,
            callback: () => {
              showPlayer('um_Mex39DZQ')
            },
            disabled: !volumeAchievement?.unlocked,
            tradingVolume,
          },
        ]

      default:
        return []
    }
  }, [tradingVolume, showPlayer, achievementsSource])

  return {
    achievementsSource,
    differentSource,
    videoSource,
    studySource,
  }
}
