import clsx from 'clsx'
import React, { FC, useCallback } from 'react'
import { SVG } from '../../../assets/svg'
import { ApiKeyType } from '../../../redux/apiKey/apiKey.types'
import { ISO } from '../../../types'
import { DATE_FORMAT, formatDate } from '../../../utils/lib/formatDate'
import { Ellipsis } from '../../atoms/Ellipsis'
import { Button, CopyIcon } from '../../kit'
import style from './style.module.scss'
import { buildAccessArray, formatAccessList, wildcardApiKey } from './utils'
import { t } from '@lingui/macro'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useExchageType } from '@/utils/hooks/useExchangeType'

export type ApiKeyAccess = 'read' | 'spot' | 'futures'

interface ApiKeyListItemProps {
  name: string
  id: string
  date: ISO
  apiKeyValue: string
  spotEnabled: boolean
  futuresEnabled: boolean
  onRemove: (originalApiKeyDTO: ApiKeyType) => void
  onEdit: ((originalApiKeyDTO: ApiKeyType) => void) | undefined
  originalApiKeyDTO: ApiKeyType
  disabled?: boolean
}

export const ApiKeyListItem: FC<ApiKeyListItemProps> = props => {
  const { onRemove, onEdit, apiKeyValue, spotEnabled, disabled, futuresEnabled, date, name, originalApiKeyDTO } = props

  const handleClickRemove = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onRemove(originalApiKeyDTO)
    },
    [onRemove, originalApiKeyDTO]
  )

  const handleClickEdit = useCallback(() => {
    !disabled && onEdit && onEdit(originalApiKeyDTO)
  }, [onEdit, originalApiKeyDTO])

  return (
    <div
      className={clsx(style.component, { [style.disabledEdit]: onEdit === undefined, [style.disabled]: disabled })}
      onClick={handleClickEdit}
      data-testid={`${DataTestIds.ApiKeyItem}-${name}`}
    >
      <div className={style.item}>
        <div className={clsx(style.primary, style.apiKeyName)}>
          <Ellipsis label={name} />
          {onEdit && (
            <Button.Clean
              onClick={handleClickEdit}
              className={style.editButton}
              leftIcon={<SVG.Additional.Gear />}
              dataTestId={`${DataTestIds.ApiKeyItemEditApiKeyButton}-${name}`}
            />
          )}
        </div>
        <div className={style.secondary} data-testid={`${DataTestIds.ApiKeyItemDate}-${name}`}>
          {formatDate(date, DATE_FORMAT.DAY_AND_YEAR)}
        </div>
      </div>
      <div className={style.item}>
        <div className={style.primary} data-testid={`${DataTestIds.ApiKeyItemKeyWildcard}-${name}`}>
          {wildcardApiKey(apiKeyValue)}
          <CopyIcon
            text={apiKeyValue}
            className={style.copyButton}
            dataTestId={`${DataTestIds.ApiKeyItemCopyButton}-${name}`}
          />
        </div>
        <div className={style.secondary}>
          {t({
            id: 'apiPage.apiKey.title',
            message: `API key`,
          })}
        </div>
      </div>
      <div className={style.item}>
        <div className={style.primary} data-testid={`${DataTestIds.ApiKeyItemAccessesTextField}-${name}`}>
          {formatAccessList(buildAccessArray(spotEnabled, futuresEnabled))}
        </div>
        <div className={style.secondary}>
          {t({
            id: 'apiPage.access.title',
            message: `Access`,
          })}
        </div>
      </div>
      <div className={style.actions}>
        <Button.Primary
          onClick={handleClickRemove}
          disabled={disabled}
          className={style.closeButton}
          dataTestId={`${DataTestIds.ApiKeyItemRemoveApiKeyButton}-${name}`}
          leftIcon={<SVG.Additional.Close width={'10px'} height={'10px'} />}
        />
      </div>
    </div>
  )
}
