import { FC, Fragment, useCallback, useState } from 'react'
import { SVG } from '@/assets/svg'

import styles from './style.module.scss'
import { Control } from '@/ui/atoms/Control'
import { INotificationRenderProps, NotififcationPresets, SystemNotifiactionIcon } from '../../notifications.type'
import { Button, ButtonSize } from '@/ui/kit'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { useActions } from '@/utils'
import { okxAffiliateActions } from '@/redux/okxAffiliate/okxAffiliate.slice'

const NotificationIcon: FC<{ icon?: SystemNotifiactionIcon }> = ({ icon }) => {
  switch (icon) {
    case SystemNotifiactionIcon.Alert:
      return <SVG.OtherIcons.Alert className={styles.icon} />

    default:
      return null
  }
}

const NotifiactionCard: FC<INotificationRenderProps> = ({
  id,
  icon,
  description,
  heading,
  Modal,
  onClose,
  isMobile = false,
  modalBtnClassname,
  link,
  isClosable,
  isUseHandleButton,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [isShowContent, setIsShowContent] = useState(false)

  const { openOfferPopup } = useActions(okxAffiliateActions)

  const handleClickButton = (id: NotififcationPresets) => {
    switch (id) {
      case NotififcationPresets.OKXAffiliateAlert:
        openOfferPopup()
        break
      default:
        break
    }
  }

  function hideContent() {
    setIsShowContent(true)
  }

  function showContent() {
    setIsShowContent(false)
  }

  const closeHandler = useCallback(() => setOpen(false), [])
  const openHandler = useCallback(() => setOpen(true), [])

  if (isMobile) {
    return (
      <Fragment>
        <div className={clsx(styles.mobile, styles.root, { [styles.showContent]: isShowContent })}>
          <div className={styles.inner}>
            <NotificationIcon icon={icon} />

            <div className={styles.content}>
              {!!heading && !isShowContent && <div className={styles.heading}>{heading}</div>}
              {!!description && !isShowContent && <div>{description}</div>}
            </div>

            {!!onClose && (
              <div className={styles.controlsWrapper}>
                {!!onClose && <Control.Close onClick={onClose} className={styles.control} />}
              </div>
            )}

            {!isClosable && (
              <div className={styles.controlsIcon}>
                {!isShowContent ? (
                  <Control.ArrowUp onClick={hideContent} className={styles.control} />
                ) : (
                  <Control.ArrowDown onClick={showContent} className={styles.control} />
                )}
              </div>
            )}
          </div>

          {!!Modal && !isShowContent && (
            <div className={styles.actions}>
              <Button.Accent
                className={clsx(styles.modalButton, modalBtnClassname)}
                size={ButtonSize.Small}
                label={t({ id: 'core.systemAlert.moreDetails', comment: 'More details' })}
                onClick={openHandler}
              />
            </div>
          )}

          {!Modal && !!link && !isShowContent && (
            <div className={styles.actions}>
              <Button.Accent
                className={clsx(styles.modalButton, modalBtnClassname)}
                size={ButtonSize.Small}
                label={t({ id: 'core.systemAlert.moreDetails', comment: 'More details' })}
                onClick={() => {
                  window.open(link.uri, link.external ? '_blank' : '_self')
                }}
              />
            </div>
          )}

          {isUseHandleButton && !isShowContent && (
            <div className={styles.actions}>
              <Button.Accent
                className={clsx(styles.modalButton, modalBtnClassname)}
                size={ButtonSize.Small}
                label={t({ id: 'core.systemAlert.moreDetails', comment: 'More details' })}
                onClick={() => handleClickButton(id)}
              />
            </div>
          )}
        </div>

        {!!Modal && <Modal isOpen={isOpen} onClose={closeHandler} />}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className={styles.root}>
        <div className={styles.inner}>
          <NotificationIcon icon={icon} />

          <div className={styles.content}>
            {!!heading && <div className={styles.heading}>{heading}</div>}
            {!!description && <div className={styles.description}>{description}</div>}
          </div>
        </div>

        <div className={styles.actions}>
          {!!Modal && (
            <Button.Accent
              size={ButtonSize.Small}
              label={t({ id: 'core.systemAlert.moreDetails', comment: 'More details' })}
              onClick={openHandler}
              className={modalBtnClassname}
            />
          )}

          {!Modal && !!link && (
            <Button.Accent
              className={clsx(styles.modalButton, modalBtnClassname)}
              size={ButtonSize.Small}
              label={t({ id: 'core.systemAlert.moreDetails', comment: 'More details' })}
              onClick={() => {
                window.open(link.uri, link.external ? '_blank' : '_self')
              }}
            />
          )}

          {isUseHandleButton && (
            <Button.Accent
              className={clsx(styles.modalButton, modalBtnClassname)}
              size={ButtonSize.Small}
              label={t({ id: 'core.systemAlert.moreDetails', comment: 'More details' })}
              onClick={() => handleClickButton(id)}
            />
          )}

          {!!onClose && <Control.Close onClick={onClose} className={styles.control} />}
        </div>
      </div>

      {!!Modal && <Modal isOpen={isOpen} onClose={closeHandler} />}
    </Fragment>
  )
}

export default NotifiactionCard
