import {
  CancelAllOverviewOpenOrdersTC,
  CancelOverviewOpenOrderTC,
  CloseAllOverviewPositionsTC,
  CloseOverviewPositionTC,
  GetAllCointsTC,
  GetStatisticsSinceMomentTC,
  GetAccountInformationV3TC,
  GetBinanceConvertExchangeInfoTC,
  GetConvertQuoteTC,
  PostConvertTC,
  SellAsset,
  SetPositionMarginTC,
} from './overview.thunks'

const overviewAsyncActions = {
  CancelOverviewOpenOrderTC,
  CancelAllOverviewOpenOrdersTC,
  CloseOverviewPositionTC,
  CloseAllOverviewPositionsTC,
  GetAllCointsTC,
  GetStatisticsSinceMomentTC,
  GetAccountInformationV3TC,
  GetBinanceConvertExchangeInfoTC,
  GetConvertQuoteTC,
  PostConvertTC,
  SellAsset,
  SetPositionMarginTC,
}

export default overviewAsyncActions
