import { FC, memo } from 'react'
import { urls } from '@/router/urls'
import style from './style.module.scss'
import { t } from '@lingui/macro'

const FooterLinks: FC = () => {
  const FOOTER_LINKS = [
    {
      id: 1,
      href: urls.terms,
      name: t({ id: 'footer.terms', message: `Terms and conditions` }),
    },
    {
      id: 2,
      href: urls.policy,
      name: t({ id: 'footer.policy', message: `Privacy Policy` }),
    },
    {
      id: 3,
      href: t({
        id: 'externalLink.docs',
        message: 'https://broker-docs.tiger.trade/',
      }),
      name: t({ id: 'header.docs', message: `Docs` }),
      external: true,
    },
  ]

  return (
    <div className={style.footerLinks}>
      {FOOTER_LINKS.map(link => {
        if (link.external) {
          return (
            <a key={link.id} href={link.href} target="_blank" rel="noreferrer">
              {link.name}
            </a>
          )
        }

        return (
          <a key={link.id} href={link.href}>
            {link.name}
          </a>
        )
      })}
    </div>
  )
}

export default memo(FooterLinks)
