import { CopyIcon } from '@/ui/kit'
import { FC } from 'react'
import style from './style.module.scss'

type CopyToClipboardProps = {
  valueToCopy: string
  snackbarMessage?: string
}

export const CopyToClipboard: FC<CopyToClipboardProps> = ({ valueToCopy }) => {
  return (
    <div className={style.container}>
      <div className={style.text}>{valueToCopy}</div>
      <CopyIcon text={String(valueToCopy)} className={style.copyButton} />
    </div>
  )
}
