import { t } from '@lingui/macro'
import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { SVG } from '@/assets/svg'
import { useLanguageContext } from '@/providers/LanguageProvider'
import { getTtLinksByLocale } from '@/router/links'
import { urls } from '@/router/urls'
import { Rights } from '@/ui/atoms/Rights'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import s from './style.module.scss'
import { MobileFooterLink } from '../MobileFooterLink/MobileFooterLink'

export const MobileFooter: FC = () => {
  const { locale } = useLanguageContext()

  const SOCIAL_LIST = useMemo(() => {
    return [
      {
        icon: <SVG.Social.Twitter />,
        href: getTtLinksByLocale('twitter', locale),
      },
      {
        icon: <SVG.Social.Telegram />,
        href: getTtLinksByLocale('telegram', locale),
      },
      {
        icon: <SVG.Social.Discord />,
        href: getTtLinksByLocale('discord', locale),
      },
    ]
  }, [locale])

  const TIGERTRADE_LIST = useMemo(() => {
    return [
      {
        href: urls.orders,
        text: t({ id: 'header.orders', message: `Orders & positions` }),
        dataTestId: DataTestIds.HeaderOrdersAndPositions,
      },
      {
        href: urls.assets,
        text: t({ message: 'View assets', id: 'mainTabCryptocurrency.viewAssets' }),
        dataTestId: DataTestIds.BurgerAssetsBtn,
      },
    ]
  }, [])

  const PRODUCTS_LIST = useMemo(() => {
    return [
      {
        text: 'Tiger.com Copy',
        href: getTtLinksByLocale('tt_copy', locale),
        external: true,
      },
      {
        text: 'Tiger.com Windows',
        href: getTtLinksByLocale('tt_windows', locale),
        external: true,
      },
      {
        text: 'Tiger.com macOS',
        href: getTtLinksByLocale('tt_macos', locale),
        external: true,
      },
    ]
  }, [locale])

  const DOCS_LIST = useMemo(() => {
    return [
      {
        href: urls.policy,
        text: t({ id: 'footer.policy', message: `Privacy Policy` }),
      },
      {
        href: urls.terms,
        text: t({ id: 'footer.terms', message: `Terms and conditions` }),
      },
      {
        href: t({
          id: 'externalLink.docs',
          message: 'https://broker-docs.tiger.trade/',
        }),
        text: t({ id: 'header.docs', message: `Docs` }),
        external: true,
      },
    ]
  }, [])

  return (
    <div className={s.footer}>
      <div className={s.top}>
        <SVG.OtherIcons.Logo className={s.logo} />

        {/* 
        <section className={s.section}>
          <span className={s.sectionTitle}>{t({ id: 'social.follow', message: 'follow as on' })}</span>
          <ol className={s.socialList}>
            {SOCIAL_LIST.map((social, id) => (
              <a className={s.socialButton} href={social.href} target="_blank" rel="noreferrer" key={id}>
                {social.icon}
              </a>
            ))}
          </ol>
        </section>
        */}

        <section className={s.section}>
          <span className={s.sectionTitle}>
            {t({
              id: 'core.TigerTrade',
              message: 'Tiger.Trade',
            })}
          </span>
          <ol className={s.linkList}>
            {TIGERTRADE_LIST.map((link, id) => (
              <MobileFooterLink key={id} {...link} />
            ))}
          </ol>
        </section>

        {/*}
        <section className={s.section}>
          <span className={s.sectionTitle}>{t({ id: 'products', message: 'products' })}</span>
          <ol className={s.linkList}>
            {PRODUCTS_LIST.map((link, id) => (
              <MobileFooterLink key={id} {...link} />
            ))}
          </ol>
        </section>
        */}
      </div>

      <div className={s.bottom}>
        <Rights />
        {/* <div className={s.bottomSection}>
          <span className={s.bottomTitle}>
            {t({ id: 'footer.title', message: 'Trading leveraged products carries a high risk of loss' })}
          </span>
          <span
            className={s.bottomDescription}
            dangerouslySetInnerHTML={{
              __html: t({
                id: 'footer.rightsReserved',
                message: 'All rights reserved. © 2022 Tiger Trade Capital AG.\nBahnhofstrasse 8B 6340 Baar',
              }),
            }}
          ></span>
        </div>

        <span
          className={s.bottomDescription}
          dangerouslySetInnerHTML={{
            __html: t({
              id: 'footer.services',
              message:
                'Tiger.Trade Broker services are not available in the United\xa0States or to US citizens or residents.',
            }),
          }}
        ></span> */}

        <ol className={s.bottomLinkList}>
          {DOCS_LIST.map((doc, id) => {
            if (doc.external) {
              return (
                <a className={s.bottomLink} key={id} href={doc.href} target="_blank" rel="noreferrer">
                  {doc.text}
                </a>
              )
            }

            return (
              <Link className={s.bottomLink} key={id} to={doc.href}>
                {doc.text}
              </Link>
            )
          })}
        </ol>
      </div>
    </div>
  )
}
