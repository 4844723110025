import { FC } from 'react'
import { t } from '@lingui/macro'
import { EXCHANGE_ICON, TExchangesAvailable } from '@/core/constants'
import { DropDownItem } from '@/ui/kit/Dropdown/Dropdown'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import s from '../../style.module.scss'

type TOptionExchange = DropDownItem<TExchangesAvailable>

interface IConsentPopup {
  open: boolean
  exchange?: TOptionExchange
  onAgree?: () => void
  onCancel?: () => void
}

const ConsentPopup: FC<IConsentPopup> = props => {
  const { open, exchange, onAgree, onCancel } = props

  const ExchangeIcon = exchange?.value ? EXCHANGE_ICON[exchange.value] : ''

  return (
    <InteractiveModal isOpen={open}>
      <InteractiveModalParts.HeaderIcon>
        <ExchangeIcon />
      </InteractiveModalParts.HeaderIcon>
      <InteractiveModalParts.SubHeader
        text={`${t({ id: 'profile.exchangeType.consent.title', message: 'Switch to' })} ${exchange?.label}?`}
      />
      <InteractiveModalParts.Explanation
        text={t({ id: 'profile.exchangeType.consent.text', message: 'Your data will be loaded in a moment' })}
        className={s.consentText}
      />
      <InteractiveModalParts.Button
        text={`${t({ id: 'profile.exchangeType.consent.title', message: 'Switch to' })} ${exchange?.label}`}
        variant={ButtonVariant.White}
        onClick={onAgree}
      />
      <InteractiveModalParts.Button
        text={t({ id: 'core.button.cancel', message: 'Cancel' })}
        variant={ButtonVariant.Primary}
        onClick={onCancel}
      />
    </InteractiveModal>
  )
}

export { ConsentPopup }
