import { AddressCreate } from '@/ui/organisms/AddressCreate'
import style from './style.module.scss'
import { useActions, useAppSelector, useGoogleAuthCode, useGoogleAuthSettings } from '@/utils/hooks'
import { PageSetupGoogleAuth } from '@/ui/organisms/WalletSetupGoogleAuth'
import { urls } from '@/router/urls'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { addressActions } from '@/redux/address/address.slice'

export const CreateAddressesPage = () => {
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()
  const navigate = useNavigate()
  const status = useAppSelector(state => state.address.createdAddressStatus)
  const { setCleanCreateStatus } = useActions(addressActions)
  const isSuccess = status === 'succeeded'
  useEffect(() => {
    if (isSuccess) navigate(-1)

    return () => {
      setCleanCreateStatus()
    }
  }, [isSuccess, navigate, setCleanCreateStatus])

  if (!googleAuthSettings.settings.enabled) {
    return (
      <div className={style.component}>
        <PageSetupGoogleAuth
          googleAuthSettings={googleAuthSettings}
          googleAuthCode={googleAuthCode}
          backRoute={urls.addresses}
        />
      </div>
    )
  }

  return (
    <div className={style.component}>
      <div className={style.content}>
        <AddressCreate />
      </div>
    </div>
  )
}
