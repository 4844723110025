import { useEffect, useRef, useState } from 'react'
import { ANIMATION_TIME } from '../constants/animationTime'

interface MountOptions {
  opened: boolean
}

export function useAnimatedMount({ opened }: MountOptions): boolean {
  const [mounted, setMounted] = useState(false)
  const timeoutRef = useRef<number | null>(null)

  useEffect(() => {
    if (opened && !mounted) {
      setMounted(true)
      return
    }

    if (!opened && mounted) {
      timeoutRef.current = setTimeout(setMounted, ANIMATION_TIME, false)

      return () => {
        if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current)
          timeoutRef.current = null
        }
      }
    }
  }, [opened])

  return mounted
}
