import { FC } from 'react'

export const DoneCheckbox: FC = () => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.97941 4.13593L4.35394 7L10.0206 1"
        stroke="#1ACC81"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
