import { useMemo } from 'react'
import { useExchageType } from './useExchangeType'
import { isExchangeBybit } from '../lib/exchange'

type TReferralCalcularorSources = {
  REF_START_PERCENT: number
  REF_END_DEFAULT_PERCENT: number
  REF_END_DEFAULT_PERCENT_INCREASED: number
  REF_PERCENT_STEP: number
  HOST_START_WITH_REF: number
  REF_END_PERCENT: number
}

export const useCalculatorSources = (userIncomePercent: number): TReferralCalcularorSources => {
  const { exchangeType } = useExchageType()

  const calculatorSources = useMemo(() => {
    const REF_START_PERCENT = isExchangeBybit(exchangeType) ? 5 : 25
    const REF_END_DEFAULT_PERCENT = isExchangeBybit(exchangeType) ? 8 : 30
    const REF_END_DEFAULT_PERCENT_INCREASED = isExchangeBybit(exchangeType) ? 8 : 30.5
    const REF_PERCENT_STEP = 0.5
    const HOST_START_WITH_REF = userIncomePercent
    const REF_END_PERCENT =
      HOST_START_WITH_REF > REF_END_DEFAULT_PERCENT ? REF_END_DEFAULT_PERCENT_INCREASED : REF_END_DEFAULT_PERCENT

    return {
      REF_START_PERCENT,
      REF_END_DEFAULT_PERCENT,
      REF_END_DEFAULT_PERCENT_INCREASED,
      REF_PERCENT_STEP,
      HOST_START_WITH_REF,
      REF_END_PERCENT: REF_END_PERCENT,
    }
  }, [userIncomePercent, exchangeType])

  return calculatorSources
}
