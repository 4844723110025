import { E_TableFilterExchangeType, E_TableFilterMarketValue, InitialState } from './trades.types'
import { getDefaultTradesFilters } from './trades.utils'

export const initialState: InitialState = {
  trades: null,
  tradesById: null,
  tradesFilters: getDefaultTradesFilters(),
  categories: null,
  tags: [],
  activeTagEditorID: null,
  tagEditorPosition: null,
  deleteTagStatus: 'idle',
  me: null,
  isTradesLoading: true,
  isTradesByIdLoading: true,
  getMeStatus: 'idle',
  tradesStatus: 'idle',
  tradesByIdStatus: 'idle',
  tickers: null,
  tickersStatus: 'idle',
  isApiKeyExistWithEmptyArray: false,
  exchangeFilterValue: E_TableFilterExchangeType.All,
  marketTypeFilterValue: E_TableFilterMarketValue.All,
  diaryExchangeFilterValue: E_TableFilterExchangeType.All,
  diaryWeekListFilters: null,
  diaryWeekExchangeFilterValue: E_TableFilterExchangeType.All,
  diaryWeekFilter: null,
  tradesUserInitStatus: 'idle',
  availableExchangeStatus: 'idle',
  availableExchangeKeys: null,
  availableUsersTradingStatus: 'idle',
  availableUsersTrading: null,
  requestTimeStep: null,
  editDescriptionStatus: 'idle',
  diaryWeekList: [],
  diaryWeekStatus: 'idle',
  diaryWeekListDataReachedEnd: false,
  consecutiveEmptyResponses: 0,
  diaryAnalyzerStatus: 'idle',
  diaryAnalyzerList: null,
  uiDataStatus: 'idle',
  isDayAnalyzerDataFetched: false,
  isMonthAnalyzerDataFetched: false,
  analyzerMonthData: undefined,
  analyzeDayOrders: null,
  analyzeDayOrdersStatus: 'idle',
  getAvailableExchangeKeys: null,
  getExchangeAvailableStatus: 'idle',
  repairApiKeyStatus: 'idle',
  analyzerMonthTradeTicker: null,
}
