import { useMemo } from 'react'
import { AccountOverviewPositionsView } from '@/backend/models/OverviewDTO'
import { useOverview } from '@/utils/hooks/useOverview'
import { formatNumber, useAppSelector } from '@/utils'

const usePosition = (position: AccountOverviewPositionsView) => {
  const { unrealizedPnl, unrealizedPnlPercent, quoteAsset, entryPrice, uid, instrumentType, liquidationPrice } =
    position

  const { deletingPositions } = useAppSelector(state => state.overview)

  const liquidationPriceOutput = useMemo(() => {
    return liquidationPrice === '' ? '—' : liquidationPrice
  }, [liquidationPrice])

  const deleting = useMemo(() => {
    return deletingPositions[instrumentType][uid]?.isDeleting
  }, [uid, deletingPositions, instrumentType])

  const { cancelPosition } = useOverview()

  const positionId = useMemo(() => {
    return uid
  }, [uid])

  const unPnl = useMemo(() => {
    const unPnlNumber = Number(unrealizedPnl)
    const unPnlPercentNumber =
      unPnlNumber < 0 ? Math.abs(Number(unrealizedPnlPercent)) * -1 : Math.abs(Number(unrealizedPnlPercent))
    const unPnlNumberFormatted =
      (unPnlNumber > 0 ? '+' : '') + formatNumber(unrealizedPnl, { maximumFractionDigits: 2 }) + ` ${quoteAsset}`
    const unPnlPercentFromatted = (unPnlNumber > 0 ? '+' : '') + unPnlPercentNumber + '%'
    return {
      unPnlNumber,
      unPnlPercentNumber,
      unPnlNumberFormatted,
      unPnlPercentFromatted,
    }
  }, [unrealizedPnlPercent, unrealizedPnl, quoteAsset])

  const closePositionHandler = async () => cancelPosition(position)

  return {
    positionId,
    deleting,
    unrealizedPnl: unPnl,
    entryPrice,
    closePositionHandler,
    liquidationPriceOutput,
  }
}

export { usePosition }
