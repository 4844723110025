import React, { FC, Suspense } from 'react'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { Loader } from '@/ui/kit/Loader'
import { isExchangeBinance, isExchangeBybit, isExchangeOkx } from '@/utils/lib/exchange'

const LeveragePageBinance = React.lazy(() => import('./LeveragePage.Binance'))
const LeveragePageOkx = React.lazy(() => import('./LeveragePage.Okx'))
const LeveragePageBybit = React.lazy(() => import('./LeveragePage.Bybit'))

export const LeveragePage: FC = () => {
  const { exchangeType } = useExchageType()

  return (
    <>
      {isExchangeBinance(exchangeType) && (
        <Suspense fallback={<Loader />}>
          <LeveragePageBinance />
        </Suspense>
      )}
      {isExchangeOkx(exchangeType) && (
        <Suspense fallback={<Loader />}>
          <LeveragePageOkx />
        </Suspense>
      )}
      {isExchangeBybit(exchangeType) && (
        <Suspense fallback={<Loader />}>
          <LeveragePageBybit />
        </Suspense>
      )}
    </>
  )
}
