import { TExchangesAvailable } from '@/core/constants'
import { DiaryWeekListQuery, E_TableFilterExchangeType } from '@/redux/trades/trades.types'
import { useEffect } from 'react'
import { getIdsAndNameForExchange } from '../helpers/getIdsAndNameForExchange'
import { useActions, useAppSelector } from '@/utils'
import { binanceTableFilterExchangeIds } from '../components/TableFilters/utils'
import { getIdsByApiKeyList } from '../helpers/getIdsByApiKeyList'
import { TradesQueryParams } from '@/backend/types'
import { tradesActions } from '@/redux/trades/trades.slice'
import { ServicesUserWithRelations } from '@/utils/types/contracts.types'

type UseFetchTradesDataT = {
  isReturned: boolean
  exchangeType?: TExchangesAvailable
  exchangeTypeExist: string | boolean | undefined
  hasApiKey: boolean | null
  me: ServicesUserWithRelations | null
  apiKey?: number[]
  filters: TypeFilter
  setExchangeType: (value: E_TableFilterExchangeType) => void
  setIsTradesLoading?: (value: boolean) => void
  handlerExchangeTypeExist: (filterWithExchangeId: any) => void
  handlerWithoutExchangeType: () => void
  reset: () => void
  trades?: boolean
}

type TypeFilter = DiaryWeekListQuery | TradesQueryParams

const useFetchTradesData = ({
  isReturned,
  exchangeType,
  hasApiKey,
  me,
  apiKey,
  filters,
  setExchangeType,
  setIsTradesLoading,
  handlerExchangeTypeExist,
  handlerWithoutExchangeType,
  reset,
  trades,
  exchangeTypeExist,
}: UseFetchTradesDataT) => {
  const { availableExchangeKeys, isApiKeyExistWithEmptyArray } = useAppSelector(state => state.trades)
  const { resetTrades, setIsApiKeyExistWithEmptyArray } = useActions(tradesActions)
  useEffect(() => {
    if (isReturned) return

    if (exchangeTypeExist) {
      const exchangeIdsWithName = getIdsAndNameForExchange(
        exchangeType as TExchangesAvailable,
        availableExchangeKeys?.activeExchangeAccounts.map(record => record.exchangeType)
      )
      const binanceExchangeIds = binanceTableFilterExchangeIds(
        me,
        exchangeIdsWithName.name as E_TableFilterExchangeType
      )
      const isExchangeTypeBinanceOrVip = exchangeType === 'BINANCE_VIP' || exchangeType === 'BINANCE_BROKER_FUTURE'

      const filterWithExchangeId: TypeFilter = {
        ...filters,
        api_key_id: hasApiKey
          ? apiKey
          : isExchangeTypeBinanceOrVip
          ? binanceExchangeIds
          : getIdsByApiKeyList(me ?? undefined, exchangeIdsWithName.ids),
      }

      if (!hasApiKey) {
        setExchangeType(exchangeIdsWithName.name as E_TableFilterExchangeType)
      }
      const isHasKeysForDefaultBinance = binanceExchangeIds.length > 0
      if (isExchangeTypeBinanceOrVip && !isHasKeysForDefaultBinance && !hasApiKey) {
        resetTrades()
        setIsApiKeyExistWithEmptyArray(true)
        setIsTradesLoading?.(false)
        return
      }

      if (!isApiKeyExistWithEmptyArray) {
        handlerExchangeTypeExist(filterWithExchangeId)
      }
    } else {
      handlerWithoutExchangeType()
      if (!hasApiKey) {
        setExchangeType(E_TableFilterExchangeType.All)
      }
    }

    return () => {
      reset()
    }
  }, [
    isReturned,
    exchangeType,
    hasApiKey,
    me,
    apiKey,
    exchangeTypeExist,
    trades && filters,
    isApiKeyExistWithEmptyArray,
  ])
}

export default useFetchTradesData
