import { FC, memo, useCallback, useEffect } from 'react'
import { CashbackType, HistoryCashback } from '../../../backend/api'
import CashbackRightBarContent from '../CashbackRightBarContent'
import CashbackRightBarHeader from '../CashbackRightBarHeader'
import style from './style.module.scss'
import { InfiniteScrollPlate } from '@/ui/atoms/InfiniteScrollPlate'
import { useActions, useAppSelector } from '@/utils'
import historyAsyncActions from '@/redux/history/history.actions'
import { CASHBACK_TRANSACTIONS_SIZE, CASHBACK_TRANSACTIONS_SORTING } from '@/core/config/cashback'

type CashbackRightBarProps = {
  cashback: CashbackType
  historyCashback: HistoryCashback
}

const CashbackRightBar: FC<CashbackRightBarProps> = props => {
  const {
    historyCashback,
    historyCashbackStatus: historyStatus,
    historyCashbackReachedEnd,
  } = useAppSelector(state => state.history)
  const { GetHistoryCashbackTC } = useActions(historyAsyncActions)

  const getHistory = useCallback(
    (page: number, size = CASHBACK_TRANSACTIONS_SIZE) => {
      GetHistoryCashbackTC({
        page: page,
        size: size,
        sortParams: CASHBACK_TRANSACTIONS_SORTING,
      })
    },
    [GetHistoryCashbackTC]
  )

  useEffect(() => {
    getHistory(0)
  }, [])

  const scrollCallback = useCallback<IntersectionObserverCallback>(
    ([entry]) => {
      if (historyCashbackReachedEnd) return
      if (historyStatus !== 'loading' && historyStatus !== 'failed' && entry.isIntersecting) {
        getHistory(historyCashback.page + 1)
      }
    },
    [historyStatus, historyCashback.page, historyCashbackReachedEnd]
  )

  return (
    <div className={style.cashbackRightBar}>
      <CashbackRightBarHeader cashback={props.cashback} />
      <CashbackRightBarContent historyCashback={props.historyCashback} />
      <InfiniteScrollPlate className={style.infiniteScrollPlate} scrollCallback={scrollCallback} />
    </div>
  )
}

export default memo(CashbackRightBar)
