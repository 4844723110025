import { FC } from 'react'

export const Deposit: FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="url(#paint0_linear_15433_3596)" />
      <path
        d="M22.7877 25.7459C22.7877 25.7459 17.5867 25.7459 14.2542 25.7459M14.2542 25.7459C14.2542 22.4133 14.2542 17.2124 14.2542 17.2124M14.2542 25.7459C19.015 20.9851 25.7459 14.2542 25.7459 14.2542"
        stroke="#FAFAFA"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15433_3596"
          x1="6.43533e-07"
          y1="40"
          x2="22.3945"
          y2="-8.18273"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#24C8DE" />
          <stop offset="1" stopColor="#4EED34" />
        </linearGradient>
      </defs>
    </svg>
  )
}
