import { FC, useState, MouseEvent, useEffect } from 'react'
import { t } from '@lingui/macro'
import { Input, InputSize, Button } from '@/ui/kit'
import { useActions, useAppSelector, useToggle } from '@/utils'
import { referralsActions } from '@/redux/referrals/referrals.slice'
import s from './style.module.scss'
import { enqueueSnackbar } from 'notistack'
import { PopupNotifyRefCode } from './PopupNotifyRefCode/PopupNotifyRefCode'

type ReferralCodeApplyFormType = {
  handleUserInfo?: () => void
}

const ReferralCodeApplyForm: FC<ReferralCodeApplyFormType> = props => {
  const { handleUserInfo } = props
  const { ApplyReferralCodeTC, GetReferralCodeInfoTC, removeIsLocked, GetReferralOverviewTC } =
    useActions(referralsActions)
  const { overview, isLocked } = useAppSelector(state => state.referrals)
  const { referrerCode = '' } = overview

  const [code, setCode] = useState(referrerCode)
  const [isOpenPopup, onTogglePopup] = useToggle(false)

  useEffect(() => {
    if (!code && referrerCode) {
      setCode(referrerCode)
    }
  }, [referrerCode]) // no need watch for code in this case

  const handleCodeChange = (code: string) => {
    setCode(code)
  }

  const handleUpdateCode = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!code || code === referrerCode) return

    try {
      await GetReferralCodeInfoTC(code)
      await GetReferralOverviewTC()
    } catch (error) {
      enqueueSnackbar({
        message: t({ id: 'referralPage.unexpectedError', message: 'Unexpected error occurred' }),
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (isLocked === true) {
      onTogglePopup()
    } else if (isLocked === false) {
      updateCode()
      removeIsLocked()
    }
  }, [isLocked])

  function handleSubmit() {
    updateCode()
    handleClosePopup()
  }

  function handleClosePopup() {
    onTogglePopup()
    removeIsLocked()
  }

  const updateCode = async () => {
    const response = await ApplyReferralCodeTC({ referrerCode: code })

    if ('error' in response) {
      // @ts-ignore
      let errorCode = response?.payload?.codes?.[0]
      let errorMessage = ''

      switch (Number(errorCode)) {
        case 40001:
          errorMessage = t({ id: 'referralPage.codeSaveError-40001', message: 'Referrer code does not exist' })
          break
        case 40002:
          errorMessage = t({ id: 'referralPage.codeSaveError-40002', message: 'Referrer code is locked' })
          break
        case 40003:
          errorMessage = t({
            id: 'referralPage.codeSaveError-40003',
            message: 'Could not set the same user as a referrer',
          })
          break
        case 40004:
          errorMessage = t({
            id: 'referralPage.codeSaveError-40004',
            message: "Referrer registered after user - can't assign referrer",
          })
          break
        case 40011:
          errorMessage = t({
            id: 'referralPage.codeSaveError-40011',
            message: 'Referral code locked',
          })
          break
        case 40012:
          errorMessage = t({
            id: 'referralPage.codeSaveError-40012',
            message: 'Contact customer support service to activate this code',
          })
          break
        case 40014:
          errorMessage = t({
            id: 'referralPage.codeSaveError-40014',
            message: 'Referral code activation loop detected',
          })
          break
        case 40015:
          errorMessage = t({
            id: 'referralPage.codeSaveError-40015',
            message: 'Group member cannot activate another refcode',
          })
          break
        case 40016:
          errorMessage = t({
            id: 'referralPage.codeSaveError-40016',
            message: 'Referral code cannot be changed twice in the same day',
          })
          break
      }

      enqueueSnackbar({
        message: `${t({ id: 'referralPage.codeApplyError', message: 'Error applying code' })}. ${errorMessage}`,
        variant: 'error',
      })
    } else {
      await GetReferralOverviewTC()

      enqueueSnackbar({
        message: t({ id: 'referralPage.codeApplied', message: 'Inviter’s referral code applied' }),
        variant: 'success',
      })
      handleUserInfo?.()
    }
  }

  return (
    <>
      <form className={s.root}>
        <Input
          label={t({ id: 'referralPage.codeInputLabel', message: 'Inviter’s referral code' })}
          size={InputSize.Medium}
          placeholder={t({ id: 'referralPage.codeInputPlaceholder', message: 'Enter code' })}
          value={code}
          setValue={handleCodeChange}
          containerClassName={s.input}
          labelClassName={s.label}
        />
        <Button.AccentDark
          label={t({ id: 'referralPage.codeApplyBtn', message: 'Apply' })}
          className={s.button}
          onClick={handleUpdateCode}
        />
      </form>
      <PopupNotifyRefCode isOpen={isOpenPopup} onClose={handleClosePopup} onSubmit={handleSubmit} />
    </>
  )
}

export { ReferralCodeApplyForm }
