import { FC } from 'react'

type OkxIconIconType = {
  size?: number
  className?: string
}

export const OkxIcon: FC<OkxIconIconType> = ({ className, size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <rect width={24} height={24} fill="#FAFAFA" rx={6.72} />
    <path
      fill="#12101A"
      d="M6.96 6.96h3.36v3.36H6.96zm3.36 3.36h3.36v3.36h-3.36zm3.36-3.36h3.36v3.36h-3.36zm-6.72 6.72h3.36v3.36H6.96zm6.72 0h3.36v3.36h-3.36z"
    />
  </svg>
)
