import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import { FC } from 'react'
import { MessageWithIcon } from '../MessageWithIcon'
import { SVG } from '@/assets/svg'
import { TitleH3 } from '@/ui/atoms/TitleH3'

import styles from './style.module.scss'
import clsx from 'clsx'

export const PromoMessage: FC = () => {
  return (
    <div className={styles.promoMessage}>
      <TitleH3
        label={t({
          id: 'promomessage.reducedCommission.heading',
          message: 'Торговые комиссии стали еще ниже',
        })}
      />
      <br />
      <div>
        <Trans
          id="promomessage.reducedCommission.message-1"
          components={{
            em: <span className={styles.coloredMagenta} />,
          }}
        />
      </div>
      <br />
      <div>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            <div className={styles.td}>{t({ id: 'promomessage.table.th1' })}</div>
            <div className={styles.td}>
              {t({ id: 'promomessage.table.th2' })}
              <br />
              Taker / Maker
            </div>
            <div className={clsx(styles.td, styles.colorWhite)}>
              {t({ id: 'promomessage.table.th3' })}
              <br />
              Taker / Maker
            </div>
          </div>

          <div className={styles.tableRow}>
            <div className={styles.td}>{t({ id: 'promomessage.table.rh2' })}</div>
            <div className={styles.td}>{t({ id: 'promomessage.table.spotBefore' })}</div>
            <div className={styles.td}>
              <span className={styles.colorGradient}>0.045% / 0.020%</span>
            </div>
          </div>

          <div className={styles.tableRow}>
            <div className={styles.td}>{t({ id: 'promomessage.table.rh3' })}</div>
            <div className={styles.td}>{t({ id: 'promomessage.table.futuresBefore' })}</div>
            <div className={styles.td}>
              <span className={styles.colorGradient}>0.035% / 0.014%</span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className={styles.heroValue}>
        <span className={styles.value}>{t({ id: 'promomessage.cashback.value' })}</span>
        <Trans
          id="promomessage.reducedCommission.message-2"
          message={'Ваша торговая комиссия на ByBit снижена до 0.00% на фьючерсах и до 0.03% на споте.'}
          components={{
            br: <br />,
          }}
        />
      </div>
      <br />
      <MessageWithIcon
        icon={<SVG.OtherIcons.LightningSquare />}
        classNameRoot={styles.messageWithIcon}
        message={
          <Trans
            id="promomessage.reducedCommission.message-3"
            message={'Ранее вы экономили 30%,  а теперь — до 100%'}
            components={{
              colored: <span className={styles.colorGradient} />,
            }}
          />
        }
      />
    </div>
  )
}
