import { DatePicker, InteractiveModal, Select } from '@/ui/kit'
import { useActions, useAppSelector } from '@/utils'
import { t } from '@lingui/macro'
import { FC, useEffect, useState } from 'react'
import style from './style.module.scss'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { SVG } from '@/assets/svg'
import clsx from 'clsx'
import { transactionsActions } from '@/redux/transactions/transactions.slice'

type SelectOption = {
  label: string
  value: string
}

export const ConvertHistoryFilter: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [coinOptions, setCoinOptions] = useState<SelectOption[]>([])
  const { updateConvertStartTime, updateConvertEndTime, updateConvertCoin, resetConvertFilters } =
    useActions(transactionsActions)
  const { endTime, startTime, coin } = useAppSelector(state => state.transactions.historyConvert.filters)
  const { list, moreData } = useAppSelector(state => state.transactions.historyConvert)

  useEffect(() => {
    if (list && Array.isArray(list)) {
      const options = list.map(item => ({
        label: `${item.fromAsset}/${item.toAsset}`,
        value: `${item.fromAsset}/${item.toAsset}`,
      }))

      setCoinOptions(options)
    }
  }, [list])

  const handleChangeDateRangeAndTime = (start: string, end: string, startTime?: string, endTime?: string) => {
    // Объединяем дату и время
    const combineDateAndTime = (date: string, time: string | undefined) => {
      const [hours, minutes, seconds] = (time || '00:00:00').split(':').map(Number)
      const dateObj = new Date(date) // Создаем объект Date из строки даты
      dateObj.setHours(hours || 0, minutes || 0, seconds || 0) // Устанавливаем время
      return dateObj.getTime() // Возвращаем таймстамп
    }

    const startDate = combineDateAndTime(start, startTime)
    const endDate = combineDateAndTime(end, endTime)

    if (isNaN(startDate) || isNaN(endDate)) {
      console.error('Invalid date values:', { startDate, endDate })
      return
    }

    updateConvertStartTime(startDate.toString())
    updateConvertEndTime(endDate.toString())

    setIsOpen(false)
  }

  const handleResetDates = () => {
    resetConvertFilters()
  }

  const handleCoin = (value: string) => {
    if (coin === value) {
      updateConvertCoin(null) // Если выбран уже выбранный coin, очищаем фильтр
    } else {
      updateConvertCoin(value)
    }
  }

  const safeDate = (value: string | number | null | undefined): Date | null => {
    if (!value) return null
    const parsed = new Date(Number(value))
    return isNaN(parsed.getTime()) ? null : parsed
  }

  const rangeDefaultStart = safeDate(startTime)
  const rangeDefaultEnd = safeDate(endTime)

  const defaultStart = rangeDefaultStart ? formatDate(rangeDefaultStart, DATE_FORMAT.DAY_AND_YEAR_SEMI) : 'Invalid Date'
  const defaultEnd = rangeDefaultEnd ? formatDate(rangeDefaultEnd, DATE_FORMAT.DAY_AND_YEAR_SEMI) : 'Invalid Date'

  const showStartTime = new Date(Number(startTime)).toLocaleTimeString('ru-RU')
  const showEndTime = new Date(Number(endTime)).toLocaleTimeString('ru-RU')

  return (
    <>
      <div className={style.filterContainer}>
        <div className={style.filterContent}>
          <div className={style.filterName}>{t({ message: 'Date range', id: 'historyTrade.dateRange' })}</div>
          <div className={style.datePickerBlock} onClick={() => setIsOpen(true)}>
            <div className={style.date}>
              <span>{defaultStart}</span>
              <span className={style.date}>{showStartTime}</span>
              <span className={style.line}>-</span>
              <span>{defaultEnd}</span>
              <span className={style.date}>{showEndTime}</span>
            </div>
            <div
              className={clsx(style.toggleChevron, {
                [style.open]: isOpen,
              })}
            >
              <SVG.Arrows.ArrowDown className={style.toggleChevron} />
            </div>
          </div>
          {moreData && (
            <div className={style.moreData}>{t({ comment: 'moreData', id: 'historyConvert.moreData' })}</div>
          )}
        </div>
        <div className={style.select}>
          <span className={style.filterName}>{t({ message: 'Coin', id: 'historyTrade.coin' })}</span>
          <Select
            placeholder={t({ message: 'Select Coin', id: 'historyTrade.coinSelect' })}
            onChange={handleCoin}
            size={Select.Size.Small}
            value={coin}
            options={coinOptions}
            variant={Select.Variant.Raw}
            className={style.selectContent}
            withSearch
          />
        </div>
        <div onClick={handleResetDates} className={style.resetButton}>
          {t({ message: 'Reset', id: 'historyTrade.reset' })}
        </div>
      </div>
      <InteractiveModal isOpen={isOpen}>
        <DatePicker
          startDate={rangeDefaultStart || new Date()}
          endDate={rangeDefaultEnd || new Date()}
          onApply={handleChangeDateRangeAndTime}
          onClose={() => setIsOpen(false)}
          timePickerData={{
            start: showStartTime,
            end: showEndTime,
          }}
        />
      </InteractiveModal>
    </>
  )
}
