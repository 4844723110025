import {
  EPartners,
  InitialState,
  TPartnerAchievement,
  TPartnerAwardRecord,
  TPartnerDataDTO,
  TPartnerEntityDTO,
} from './partner.types'
import { initialState } from './partner.state'

export const resetPartnerData = (state: InitialState): InitialState => {
  return {
    ...initialState,
    partners: state.partners,
  }
}

export const checkHasPartnerAccess = (partners: TPartnerEntityDTO, partner: EPartners): boolean => {
  return !!partners.find(source => {
    return source.partnerKey === partner
  })
}

export const awardKeysFromPartners = (partners: TPartnerEntityDTO): TPartnerAwardRecord => {
  return partners.reduce((acc, item) => {
    return {
      ...acc,
      [item.awardKey]: item,
    }
  }, {})
}

export const partnerDataReducer = (
  state: InitialState,
  payload: TPartnerDataDTO,
  partner: EPartners
): Partial<InitialState> => {
  const achievementsUnlockedCount = payload.achievements.filter(achievement => {
    return achievement.unlocked
  }).length
  const unlockedAll = achievementsUnlockedCount === payload.achievements.length
  return {
    ...payload,
    partner: partner,
    unlockedAll: achievementsUnlockedCount === payload.achievements.length,
    achievementPending: unlockedAll
      ? undefined
      : payload.achievements.reduce<TPartnerAchievement | undefined>((acc, item) => {
          if (unlockedAll) return undefined
          if (acc !== undefined) return acc
          return item.unlocked ? undefined : item
        }, undefined),
    achievementsUnlockedCount,
    hasPartnerAccess: checkHasPartnerAccess(state.partners, partner),
  }
}
