import { FC, RefObject, createContext, useState } from 'react'

export type ScrollableItemsMap = Map<RefObject<Element>, boolean>

export const ScrollToErrorContext = createContext<ScrollableItemsMap | null>(null)
const Provider = ScrollToErrorContext.Provider

export const ScrollToErrorProvider: FC = ({ children }) => {
  const [scrollableItems] = useState<ScrollableItemsMap>(() => new Map())

  return <Provider value={scrollableItems}>{children}</Provider>
}
