import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  EAccountType,
  IFuturesCoinmOrdersControllerPublicParams,
  IFuturesUsdmOrdersControllerPublicParams,
  ISpotOrdersControllerPublicParams,
} from '@tigertrade/binance-ts'
import { getBinanceActivity, getBinanceConfig } from './getBinanceConfig'
import { EBinanceApiType } from '@/services/binance/config/constants'
import { useAppSelector } from '@/utils'

export type TBinanceAccountsConfig = {
  [EAccountType.SPOT]: ISpotOrdersControllerPublicParams
  [EAccountType.USDT_FUTURE]: IFuturesUsdmOrdersControllerPublicParams
  [EAccountType.COIN_FUTURE]: IFuturesCoinmOrdersControllerPublicParams
}

export const useBinanceAccountsConfig = (isVip: boolean): undefined | TBinanceAccountsConfig => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    metadata: { flags },
  } = useAppSelector(state => state.profile)

  const [config, setConfig] = useState<undefined | TBinanceAccountsConfig>()
  const [isConfigLoading, setIsConfigLoading] = useState(false)

  useEffect(() => {
    if (config !== undefined || isConfigLoading) return

    const init = async () => {
      const accessToken = await getAccessTokenSilently()

      let apiIsActive: PromiseSettledResult<boolean>,
        fapiIsActive: PromiseSettledResult<boolean>,
        dapiIsActive: PromiseSettledResult<boolean>

      let binanceActivity: {
        api: boolean
        dapi: boolean
        fapi: boolean
      }
      ;[apiIsActive, fapiIsActive, dapiIsActive] = await Promise.allSettled([
        getBinanceActivity(EBinanceApiType.api),
        getBinanceActivity(EBinanceApiType.fapi),
        getBinanceActivity(EBinanceApiType.dapi),
      ])

      binanceActivity = {
        api: apiIsActive.status === 'fulfilled',
        dapi: dapiIsActive.status === 'fulfilled',
        fapi: fapiIsActive.status === 'fulfilled',
      }

      const config = getBinanceConfig(
        { api: binanceActivity.api, dapi: binanceActivity.dapi, fapi: binanceActivity.fapi },
        accessToken,
        isVip
      )

      setConfig(config)
    }

    setIsConfigLoading(true)
    init()
  }, [getAccessTokenSilently, config, isConfigLoading, isVip, flags])

  return config
}
