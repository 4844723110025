import { useEffect } from 'react'
import historyAsyncActions from '@/redux/history/history.actions'
import { SkeletonCashback } from '@/ui/kit'
import CashbackLeftBar from '@/ui/organisms/CashbackLeftBar'
import CashbackRightBar from '@/ui/organisms/CashbackRightBar'
import { useActions } from '@/utils'
import { useAppSelector } from '@/utils'
import style from './style.module.scss'
import { cashbackActions } from '@/redux/cashback/cashback.slice'

export const Cashback = () => {
  const { cashback, status } = useAppSelector(state => state.cashback)
  const { historyCashback } = useAppSelector(state => state.history)
  const { GetCashbackTC } = useActions(cashbackActions)

  useEffect(() => {
    if (status === 'idle') GetCashbackTC()
  }, [status])

  return (
    <div className={style.cashback}>
      {status !== 'succeeded' ? (
        <SkeletonCashback />
      ) : (
        <div className={style.content}>
          <CashbackLeftBar />
          <CashbackRightBar cashback={cashback} historyCashback={historyCashback} />
        </div>
      )}
    </div>
  )
}
