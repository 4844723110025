import { FC } from 'react'

type TradesType = {
  className?: string
}

export const Trades: FC<TradesType> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" className={className}>
    <path
      fill="#fff"
      stroke="#12101A"
      strokeWidth={0.3}
      d="M15.85 3v10A2.85 2.85 0 0 1 13 15.85H3A2.85 2.85 0 0 1 .15 13V3A2.85 2.85 0 0 1 3 .15h10A2.85 2.85 0 0 1 15.85 3Zm-14 0v10A1.15 1.15 0 0 0 3 14.15h10A1.15 1.15 0 0 0 14.15 13V3A1.15 1.15 0 0 0 13 1.85H3A1.15 1.15 0 0 0 1.85 3Zm16.549 1.399A.85.85 0 0 1 19.85 5v10A4.85 4.85 0 0 1 15 19.85H5a.85.85 0 0 1 0-1.7h10A3.15 3.15 0 0 0 18.15 15V5a.85.85 0 0 1 .249-.601Z"
    />
    <path stroke="#fff" strokeLinecap="round" strokeWidth={1.5} d="M4 5.5h8M4 9h4" />
  </svg>
)
