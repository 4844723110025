import { SVG } from '@/assets/svg'
import style from './style.module.scss'
import { ServicesTrade, ServicesTradeSide } from '@/utils/types/contracts.types'
import { FC } from 'react'
import {
  DEFAULT_VALUE_PERCENT_SYMBOL,
  DEFAULT_VALUE_WITH_SYMBOL,
  handleRound,
} from '@/ui/organisms/DiaryRightBar/components/WeekCard/WeekCard'
import { useAppSelector } from '@/utils'
import clsx from 'clsx'

type AnalyzeTableOrdersItemProps = {
  trade: ServicesTrade
  sum: string
  onHover: (trade: ServicesTrade) => void
  onLeave: () => void
}

export const AnalyzeTableOrdersItem: FC<AnalyzeTableOrdersItemProps> = ({ trade, sum, onHover, onLeave }) => {
  const { language } = useAppSelector(state => state.profile)
  const sideIcon = trade.side === ServicesTradeSide.TradeShort ? <SVG.Tmm.LowSide /> : <SVG.Tmm.HighSide />
  const isNotEngLanguage = language !== 'en'

  return (
    <div
      className={clsx(style.root, {
        [style.wrapper]: isNotEngLanguage,
      })}
      onMouseOver={() => onHover(trade)}
      onMouseOut={onLeave}
    >
      <div>{sideIcon}</div>
      <div>{trade.net_profit ? handleRound(trade.net_profit) + ' $' : DEFAULT_VALUE_WITH_SYMBOL}</div>
      <div>{trade.percent ? handleRound(trade.percent) + ' %' : DEFAULT_VALUE_PERCENT_SYMBOL}</div>
      <div>{sum}</div>
    </div>
  )
}
