import React from 'react'

interface SingleAssetProps {
  className?: string
}

const Whitelist: React.FC<SingleAssetProps> = props => {
  const { className } = props

  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <rect width="40" height="40" rx="20" fill="url(#a)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 12a2 2 0 0 1 2-2h4a2 2 0 1 1 0 4h-4a2 2 0 0 1-2-2Zm-1.5.031a4 4 0 0 0-3.5 3.97v10a4 4 0 0 0 4 4h10a4 4 0 0 0 4-4V16a4 4 0 0 0-3.5-3.97 3.5 3.5 0 0 1-3.5 3.47h-4a3.5 3.5 0 0 1-3.5-3.47Zm9.064 8.463a.75.75 0 0 0-1.128-.988l-2.87 3.28a.25.25 0 0 1-.345.03l-1.753-1.402a.75.75 0 1 0-.936 1.172l1.752 1.402a1.75 1.75 0 0 0 2.41-.214l2.87-3.28Z"
        fill="#FAFAFA"
      />
      <defs>
        <linearGradient id="a" x1="0" y1="40" x2="30.351" y2="-6.322" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BFA8FF" />
          <stop offset=".472" stopColor="#FF51BC" />
          <stop offset="1" stopColor="#FC4F49" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { Whitelist }
