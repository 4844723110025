import React from 'react'
import { SnackbarContent, CustomContentProps } from 'notistack'
import { SVG } from '@/assets/svg'
import style from './style.module.scss'

const Notistack = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const {
    // Notistack props and options 👇🏼
    id,
    message,
    // custom props 👇🏼
    ...params
  } = props

  return (
    <SnackbarContent ref={ref} role="alert">
      <div className={style.root}>
        {params.variant === 'error' ? <SVG.Status.Failed className={style.icon} /> : undefined}
        {params.variant === 'success' ? <SVG.Status.Completed className={style.icon} /> : undefined}
        {message}
      </div>
    </SnackbarContent>
  )
})

export { Notistack }
