import clsx from 'clsx'
import { ReactNode, forwardRef } from 'react'
import style from './style.module.scss'
import { Loader } from '@/ui/kit/Loader'

type DefaultButtonProps = JSX.IntrinsicElements['button']

interface IconButtonProps extends DefaultButtonProps {
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  isLoading?: boolean
  isActive?: boolean
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const {
    leftIcon,
    rightIcon,
    children,
    type = 'button',
    isLoading = false,
    isActive = false,
    className,
    ...btnProps
  } = props

  const hasContent = !!leftIcon || !!children || !!rightIcon

  return (
    <button ref={ref} type={type} className={clsx(style.button, isActive && style.active, className)} {...btnProps}>
      {hasContent && (
        <div className={clsx(style.content, !!isLoading && style.hidden)}>
          {!!leftIcon && leftIcon}
          {!!children && children}
          {!!rightIcon && leftIcon}
        </div>
      )}
      {!!isLoading && <Loader size={14} className={style.lodaer} />}
    </button>
  )
})
