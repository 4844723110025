import { ChangeEvent, FC } from 'react'
import clsx from 'clsx'
import s from './style.module.scss'

interface IRadioProps {
  name: string
  value: string | number
  checked: boolean
  onChange?: (value: string | number) => void
  className?: string
}

const Radio: FC<IRadioProps> = props => {
  const { name, children, value, checked, onChange, className } = props

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target

    if (onChange && checked) {
      onChange(value)
    }
  }

  return (
    <label className={clsx(s.root, className)}>
      {children}
      <input type="radio" name={name} value={value} checked={checked} className={s.input} onChange={handleChange} />
      <div className={s.icon} />
    </label>
  )
}

export { Radio }
