import { useMemo, useState } from 'react'

/**
 * Hook for easy managing of boolean state
 * @returns [isFlagEnabled, setIsFlagEnabled, enableFlag, disableFlag, toggleFlag]
 */
export const useFlag = (isFlagInitiallyEnabled: boolean = false) => {
  const [isFlagEnabled, setIsFlagEnabled] = useState(isFlagInitiallyEnabled)

  const methods = useMemo(
    () =>
      [
        isFlagEnabled,
        setIsFlagEnabled,
        () => setIsFlagEnabled(true),
        () => setIsFlagEnabled(false),
        () => setIsFlagEnabled(v => !v),
      ] as const,
    [isFlagEnabled]
  )

  return methods
}
