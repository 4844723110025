import { QuestionButton } from '@/ui/atoms/QuestionButton'
import style from './style.module.scss'
import { useEffect, useState } from 'react'
import clsx from 'clsx'

import { DiaryResult } from '../DiaryResult/DiaryResult'
import { useActions, useAppSelector } from '@/utils'
import { tradesActions } from '@/redux/trades/trades.slice'
import { Loader } from '@/ui/kit/Loader'
import { E_SwitchItems, getSwitchItems } from './options'
import { t } from '@lingui/macro'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { getCurrentMonthRange } from '../../utils'
import { ServicesApiKey } from '@/utils/types/contracts.types'

export const DiaryWidgetResult = () => {
  const {
    diaryWeekList,
    diaryAnalyzerList,
    diaryAnalyzerStatus,
    diaryWeekStatus,
    isMonthAnalyzerDataFetched,
    isDayAnalyzerDataFetched,
    diaryWeekListFilters: filters,
    isApiKeyExistWithEmptyArray,
    diaryExchangeFilterValue,
    availableExchangeStatus,
    getMeStatus,
    analyzerMonthData,
    me,
  } = useAppSelector(state => state.trades)
  const { GetAnalyzerTC } = useActions(tradesActions)
  const isLoading = diaryAnalyzerStatus === 'loading'

  const currentDate = new Date()
  const formattedCurrentDate = formatDate(currentDate, DATE_FORMAT.FORMATTED)

  const foundDayInfo = diaryAnalyzerList?.days.find(dayInfo => dayInfo.date === formattedCurrentDate)?.data[0]
  const [activeItem, setActiveItem] = useState<E_SwitchItems>(E_SwitchItems.MONTH)
  const handleSwitchItemClick = (item: E_SwitchItems) => {
    setActiveItem(item)
  }
  const isFailed = diaryWeekStatus === 'failed' || availableExchangeStatus === 'failed' || getMeStatus === 'failed'
  useEffect(() => {
    const isEmptyData = diaryAnalyzerStatus !== 'loading' && diaryWeekStatus !== 'loading'
    if (activeItem === E_SwitchItems.DAY) {
      if (isApiKeyExistWithEmptyArray || (isEmptyData && !diaryWeekList.length)) return
      const dateRange = `${formattedCurrentDate},${formattedCurrentDate}`

      const apiKeys = filterApiKeysByName(me?.api_keys, diaryExchangeFilterValue)

      GetAnalyzerTC({
        openBetween: dateRange,
        api_key_id: apiKeys,
      })
    } else {
      if (isApiKeyExistWithEmptyArray) return

      const apiKeys = filterApiKeysByName(me?.api_keys, diaryExchangeFilterValue)

      GetAnalyzerTC({
        openBetween: getCurrentMonthRange(),
        type: 'month',
        api_key_id: apiKeys,
      })
    }
  }, [activeItem, diaryExchangeFilterValue])

  function filterApiKeysByName(apiKeys: ServicesApiKey[] | undefined, filterValue: string): number[] | undefined {
    if (!apiKeys || apiKeys.length === 0) {
      return undefined
    }

    const filteredKeys = apiKeys
      .filter(key => key.name && key.name.toLowerCase().includes(filterValue.toLowerCase()))
      .map(key => key.id)
      .filter((id): id is number => id !== undefined)

    return filteredKeys.length > 0 ? filteredKeys : undefined
  }

  useEffect(() => {
    setActiveItem(E_SwitchItems.MONTH)
  }, [filters])

  if (
    isLoading &&
    ((!isMonthAnalyzerDataFetched && activeItem === E_SwitchItems.MONTH) ||
      (!isDayAnalyzerDataFetched && activeItem === E_SwitchItems.DAY))
  )
    return (
      <div className={style.loaderBlock}>
        <Loader />
      </div>
    )

  if (isFailed || isApiKeyExistWithEmptyArray) return <></>

  return (
    <div className={style.root}>
      <div className={style.header}>
        <div>
          {t({
            id: 'trades.summary.resultHeader',
          })}
        </div>
        <QuestionButton
          buttonClassName={style.hint}
          text={t({
            id: 'trades.summary.resultHint',
          })}
          placement="right"
        />
      </div>
      <div className={style.switchContainer}>
        {getSwitchItems().map(item => (
          <div
            key={item.value}
            className={clsx(style.switchItem, { [style.active]: activeItem === item.value })}
            onClick={() => handleSwitchItemClick(item.value)}
          >
            {item.label}
          </div>
        ))}
      </div>
      {activeItem === E_SwitchItems.MONTH ? (
        <DiaryResult monthData={analyzerMonthData?.data} type={activeItem} />
      ) : (
        <DiaryResult day={foundDayInfo} type={activeItem} />
      )}
    </div>
  )
}
