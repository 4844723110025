export const EyeClose = () => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.56818 1.84529C8.0375 1.73544 8.518 1.68053 9 1.68166C13.7727 1.68166 16.5 7.1362 16.5 7.1362C16.0861 7.91048 15.5925 8.63943 15.0273 9.3112M10.4455 8.58166C10.2582 8.78262 10.0324 8.94381 9.78147 9.0556C9.53056 9.1674 9.25971 9.22751 8.98506 9.23236C8.71042 9.23721 8.43761 9.18668 8.18292 9.08381C7.92822 8.98093 7.69686 8.82781 7.50262 8.63358C7.30839 8.43935 7.15527 8.20798 7.05239 7.95329C6.94952 7.69859 6.899 7.42578 6.90384 7.15114C6.90869 6.8765 6.9688 6.60564 7.0806 6.35473C7.19239 6.10383 7.35358 5.87801 7.55455 5.69075M13.05 11.1862C11.8845 12.0746 10.4653 12.5668 9 12.5907C4.22727 12.5907 1.5 7.1362 1.5 7.1362C2.34811 5.55568 3.52441 4.1748 4.95 3.0862L13.05 11.1862Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.86328 1L15.136 13.2727"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
