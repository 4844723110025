import {
  GetNotifySummary,
  GetUserNotifications,
  PostReadNotificationById,
  PostReadNotifications,
} from './notification.thunks'

export const notificationAsyncActions = {
  GetNotifySummary,
  GetUserNotifications,
  PostReadNotifications,
  PostReadNotificationById,
}
