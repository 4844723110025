import { sentryErrorInterceptor } from '../../interceptors'
import { OKXSignWithReadOnlyInterceptor } from '../../interceptors/okxSignWithReadOnlyInterceptor'
import axios, { AxiosResponse } from 'axios'

const axiosOKXService = axios.create({
  baseURL: 'https://www.okx.com/',
})

axiosOKXService.interceptors.response.use((response: AxiosResponse) => response, sentryErrorInterceptor)
axiosOKXService.interceptors.request.use(OKXSignWithReadOnlyInterceptor)

export { axiosOKXService }
