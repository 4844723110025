import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import Cryptocurrency from '../Cryptocurrency'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { useOverview } from '@/utils/hooks/useOverview'
import { DataTestIds } from '@/utils/lib/dataTestIds'

const MainTabCryptocurrency: FC = () => {
  const navigate = useNavigate()
  const {
    balanceByInstrument: { assets },
  } = useOverview()

  return (
    <div className={style.mainTabCryptocurrency}>
      <Cryptocurrency cryptocurrency={assets} />
      {assets?.length > 0 && (
        <span
          onClick={() => navigate('/assets')}
          className={style.viewAssets}
          data-testid={DataTestIds.WalletViewAssetsButton}
        >
          {t({ message: 'View assets', id: 'mainTabCryptocurrency.viewAssets' })}
        </span>
      )}
    </div>
  )
}

export default memo(MainTabCryptocurrency)
