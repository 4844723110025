import { FC, ReactNode } from 'react'
import { SVG } from '@/assets/svg'
import s from './style.module.scss'

interface IWaitingProps {
  label: ReactNode
  className?: string
  dataTestId?: string
}

const Waiting: FC<IWaitingProps> = props => {
  const { label, dataTestId } = props
  return (
    <div className={s.root} data-testid={dataTestId}>
      {label}
      <SVG.Status.Pending className={s.icon} />
    </div>
  )
}

export { Waiting }
