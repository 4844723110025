export const Rejected = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
    <rect width={61.5} height={61.5} x={1.25} y={1.25} stroke="url(#a)" strokeWidth={1.5} rx={20.75} />
    <circle cx={31.5} cy={32} r={11} stroke="url(#b)" strokeWidth={1.5} />
    <path stroke="url(#c)" strokeLinecap="round" strokeWidth={1.5} d="M31.5 27.069v4.552" />
    <circle cx={31.5} cy={35.793} r={1.138} fill="url(#d)" />
    <defs>
      <linearGradient id="a" x1={2} x2={54.626} y1={62} y2={-3.89} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FCC4ED" />
        <stop offset={0.482} stopColor="#FEA471" />
        <stop offset={1} stopColor="#FF627E" />
      </linearGradient>
      <linearGradient id="b" x1={20.5} x2={39.796} y1={43} y2={18.84} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FCC4ED" />
        <stop offset={0.482} stopColor="#FEA471" />
        <stop offset={1} stopColor="#FF627E" />
      </linearGradient>
      <linearGradient id="c" x1={31.5} x2={33.594} y1={31.621} y2={31.045} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FCC4ED" />
        <stop offset={0.482} stopColor="#FEA471" />
        <stop offset={1} stopColor="#FF627E" />
      </linearGradient>
      <linearGradient id="d" x1={30.362} x2={32.358} y1={36.931} y2={34.432} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FCC4ED" />
        <stop offset={0.482} stopColor="#FEA471" />
        <stop offset={1} stopColor="#FF627E" />
      </linearGradient>
    </defs>
  </svg>
)
