import { FC, useMemo } from 'react'
import clsx from 'clsx'
import { SVG } from '@/assets/svg'
import { KYCTier, KYCActivity, KYCWorkflowStatus } from '@/backend/models/KycDTO'
import { useAppSelector } from '@/utils'

import style from './style.module.scss'
import { t } from '@lingui/macro'

export interface ProfileKYCStatusProps {
  className?: string
}

interface StatusElProps {
  className?: string[]
}

const StatusElBase: FC<StatusElProps> = props => {
  const { className } = props

  return <div className={clsx(style.root, className)}>{props.children}</div>
}

const StatusElPending: FC<StatusElProps> = () => {
  return (
    <StatusElBase>
      <SVG.Status.Pending className={style.statusIcon} />
      {t({ id: 'profile.kyc.review', message: `Verification under review` })}
    </StatusElBase>
  )
}
const StatusElFailed: FC<StatusElProps> = () => {
  return (
    <StatusElBase>
      <SVG.Status.Failed className={style.statusIcon} />
      {t({ id: 'profile.kyc.failed', message: `Verification failed` })}
    </StatusElBase>
  )
}

const StatusElCancelled: FC<StatusElProps> = () => {
  return (
    <StatusElBase>
      <SVG.Status.Failed className={style.statusIcon} />
      {t({ id: 'profile.kyc.canceled', message: `Verification cancelled` })}
    </StatusElBase>
  )
}
const StatusElExpired: FC<StatusElProps> = () => {
  return (
    <StatusElBase>
      <SVG.Status.Failed className={style.statusIcon} />
      {t({ id: 'profile.kyc.expired', message: `Verification expired` })}
    </StatusElBase>
  )
}
const StatusElVerified: FC<StatusElProps> = () => {
  return (
    <StatusElBase>
      <SVG.Status.Completed className={style.statusIcon} />
      {t({ id: 'profile.kyc.verified', message: `Verified` })}
    </StatusElBase>
  )
}

export const StatusEl = {
  Pending: StatusElPending,
  Failed: StatusElFailed,
  Cancelled: StatusElCancelled,
  Expired: StatusElExpired,
  Verified: StatusElVerified,
}

const ProfileKYCStatus: FC<ProfileKYCStatusProps> = props => {
  const { userKYC, workflow } = useAppSelector(state => state.kyc)

  const kycStatus = useMemo(() => {
    switch (true) {
      case userKYC.activity === KYCActivity.WorkflowInProgress && workflow.status === KYCWorkflowStatus.InProgress:
        return <StatusEl.Pending />
      case userKYC.activity === KYCActivity.WorkflowError || workflow.status === KYCWorkflowStatus.Rejected:
        return <StatusEl.Failed />
      case userKYC.activity === KYCActivity.WorkflowError && workflow.status === KYCWorkflowStatus.Canceled:
        return <StatusEl.Cancelled />
      case userKYC.activity === KYCActivity.WorkflowError && workflow.status === KYCWorkflowStatus.Expired:
        return <StatusEl.Expired />
      case userKYC.kycTier === KYCTier.Unverified:
      case userKYC.kycTier === KYCTier.Basic:
        return null
      case workflow.status === KYCWorkflowStatus.Success:
      case userKYC.activity === KYCActivity.None:
        return <StatusEl.Verified />
      default:
        return null
    }
  }, [userKYC.activity, userKYC.kycTier, workflow.status])

  if (!kycStatus) return null

  return <div className={clsx(style.root, props?.className)}>{kycStatus}</div>
}

export { ProfileKYCStatus }
