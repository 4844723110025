import { FC, useEffect, useState } from 'react'
import reactDOM from 'react-dom'

export const Portal: FC = ({ children }) => {
  const [container] = useState(() => {
    const el = document.createElement('div')
    el.setAttribute('style', 'position: realtive; z-index: 9999;')
    return el
  })

  useEffect(() => {
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  }, [container])

  return reactDOM.createPortal(children, container)
}
