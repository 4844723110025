import { Status } from '@/backend/types'
import { UseGoogleAuthSettingsResult } from '../../utils/hooks'
import { UseKYCResult, UseAPIKeysResult } from '../../utils/hooks'

export interface ConvertToGetWalletPageStateResultParamsParams {
  googleAuthSettings: UseGoogleAuthSettingsResult
  kYC: UseKYCResult
  aPIKeys: UseAPIKeysResult
}

export const convertToGetWalletPageStateResultParams = (
  params: ConvertToGetWalletPageStateResultParamsParams
): GetWalletPageStateResultParams => ({
  twoFA: {
    ...params.googleAuthSettings,
  },
  aPIKeys: {
    ...params.aPIKeys,
  },
  kYC: {
    ...params.kYC,
  },
  exchangeStatus: 'loading',
})

export interface GetWalletPageStateResultParams {
  twoFA: {
    isLoading: boolean | null
    isAccepted: boolean | null
  }
  aPIKeys: {
    isLoading: boolean
    isEmpty: boolean
  }
  kYC: {
    isLoading: boolean
    isVerificationStarted: boolean
    isVerificationInProgressAfterStep1: boolean
    isVerificationInProgressAfterStep2: boolean
    isVerificationSuccess: boolean
  }
  exchangeStatus: Status
}

export type GetWalletPageStateResult =
  | 'loading'
  | 'order_list'
  | 'kyc_not_started'
  | 'kyc_in_progress_1'
  | 'kyc_in_progress_2'
  | '2fa'
  | 'add_api_key'
  | 'exchange_init'

export const getWalletPageState = (params: GetWalletPageStateResultParams): GetWalletPageStateResult => {
  const { twoFA, kYC, aPIKeys, exchangeStatus } = params

  const isLoading = kYC.isLoading || aPIKeys.isLoading || twoFA.isLoading

  if (isLoading) return 'loading'

  if (!twoFA.isAccepted) return '2fa'

  if (aPIKeys.isEmpty && !kYC.isVerificationStarted) return 'add_api_key'

  if (!kYC.isVerificationStarted) return 'kyc_not_started'

  if (kYC.isVerificationInProgressAfterStep1 && twoFA.isAccepted) return 'kyc_in_progress_1'

  if (kYC.isVerificationInProgressAfterStep2 && twoFA.isAccepted) return 'kyc_in_progress_2'

  if (aPIKeys.isEmpty) return 'add_api_key'

  if (exchangeStatus !== 'succeeded') return 'exchange_init'

  return 'order_list'
}
