import { FC, useMemo, useState } from 'react'
import { Legend } from '../wrapper/generator'
import style from './style.module.scss'
import { LegendButton } from '../LegendButton/LegendButton'
import { chunk } from 'lodash-es'
import { Button } from '@/ui/kit'
import { SVG } from '@/assets/svg'
import useHorizontalScroll, { E_ScrollDirection } from '@/ui/organisms/HistoryTrades/hooks/useHorizontalScroll'

export interface LegendWithState extends Legend {
  selected: boolean
}

interface ScrollableLegndsProps {
  legends: LegendWithState[]
  onLegendToggle: (name: string) => void
}

export const ScrollableLegnds: FC<ScrollableLegndsProps> = ({ legends, onLegendToggle }) => {
  const { handleHorizontalScroll, isScrollableLeft, isScrollableRight, tableRef } = useHorizontalScroll()

  const isDisabledLegend = useMemo(() => {
    const info = legends.reduce(
      (acc, legend) => {
        if (legend.selected) {
          if (acc.hasActiveItem) acc.hasSomeActiveItems = true
          acc.hasActiveItem = true
        }

        return acc
      },
      { hasActiveItem: false, hasSomeActiveItems: false }
    )

    return info.hasActiveItem && !info.hasSomeActiveItems
  }, [legends])

  return (
    <div className={style.container}>
      <Button
        className={style.button}
        variant={Button.Variant.Primary}
        onClick={() => handleHorizontalScroll(E_ScrollDirection.LEFT)}
        leftIcon={<SVG.Arrows.ArrowLeft width={5} height={8} />}
        size={Button.Size.ExtraSmall}
        disabled={!isScrollableLeft}
      />

      <div className={style.legends} ref={tableRef}>
        <div className={style.scrollable}>
          {legends.map(({ name, raw, color, selected }) => (
            <LegendButton
              disabled={selected && isDisabledLegend}
              key={name}
              color={color}
              isActive={selected}
              onClick={() => onLegendToggle(name)}
              legendName={name}
              rawLegendName={raw}
            />
          ))}
        </div>
      </div>

      <Button
        className={style.button}
        variant={Button.Variant.Primary}
        onClick={() => handleHorizontalScroll(E_ScrollDirection.RIGHT)}
        leftIcon={<SVG.Arrows.ArrowRight width={5} height={8} />}
        size={Button.Size.ExtraSmall}
        disabled={!isScrollableRight}
      />
    </div>
  )
}
