import { FC, memo } from 'react'
import { useOverview } from '@/utils/hooks/useOverview'
import style from './style.module.scss'
import AssetItem from '../../molecules/AssetItem'
import TopUpAccount from '../TopUpAccount'

const AssetsContent: FC = () => {
  const {
    balanceByAccount: { assets },
  } = useOverview()

  return (
    <div className={style.content}>
      {assets.length > 0 ? assets?.map(item => <AssetItem key={item.asset} asset={item} />) : <TopUpAccount />}
      {assets.length > 0 && (
        <div className={style.bottomGradientWrap}>
          <div className={style.bottomGradient} />
        </div>
      )}
    </div>
  )
}

export default memo(AssetsContent)
