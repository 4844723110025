import { FC, ReactNode } from 'react'

import styles from './style.module.scss'
import clsx from 'clsx'

export const ReadMore: FC<{
  className?: string
  content: ReactNode
  heading: string
}> = props => {
  const { content, heading, className } = props
  return (
    <div className={clsx(styles.readMore, className)}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.content}>{content}</div>
    </div>
  )
}
