import { TwoColumnPage } from '@/layouts/TwoColumnPage'
import style from './style.module.scss'
import { RiskManagerLeftBar } from '@/ui/organisms/RiskManagerLeftBar'
import { RiskManagerRightBar } from '@/ui/molecules/RiskManagerRightBar'
import { FC } from 'react'
import { useAppSelector } from '@/utils'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'

const RiskManager: FC = () => {
  const { isRmAvailable } = useAppSelector(state => state.riskManager)
  const navigate = useNavigate()

  if (!isRmAvailable) {
    navigate(urls.wallet)
  }

  return (
    <TwoColumnPage className={style.root}>
      <RiskManagerLeftBar />
      <RiskManagerRightBar />
    </TwoColumnPage>
  )
}

export { RiskManager }
