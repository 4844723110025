import { AxiosError } from 'axios'
import { captureException, withScope, setContext } from '@sentry/react'

const READ_ONLY_KEY_PATH = '/exchange-gtw/protected/api/v2/apiKey/readOnly'

const sentryErrorInterceptor = (axiosError: AxiosError) => {
  // Temprorary exception for 401 error
  if (axiosError.request?.status === 401) {
    return Promise.reject(axiosError)
  }

  // Temporary exception for 403 errors specific to GetReadOnlyKeyStatusTC
  if (axiosError.request?.status === 403) {
    const urlString = axiosError.config?.url
    let pathname = ''

    if (urlString) {
      if (urlString.startsWith('http://') || urlString.startsWith('https://')) {
        // Извлекаем pathname из полного URL
        pathname = new URL(urlString).pathname
      } else {
        // Проверка,  что это pathname
        if (urlString.startsWith('/')) {
          pathname = urlString
        } else {
          console.error('Invalid pathname:', urlString)
          return Promise.reject(axiosError)
        }
      }
    }

    const isGetReadOnlyKeyStatusError = pathname === READ_ONLY_KEY_PATH

    if (isGetReadOnlyKeyStatusError) {
      return Promise.reject(axiosError)
    }
  }

  withScope(function (scope) {
    if (axiosError.request?.status && axiosError.request?.status >= 500) scope.setLevel('fatal')
    setContext('network_error', {
      url: axiosError.config?.url,
      status: axiosError.request?.status,
    })
    captureException(axiosError)
  })
  return Promise.reject(axiosError)
}

export { sentryErrorInterceptor }
