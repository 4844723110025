import {
  ControllersCategoriesListResponse,
  ControllersDescriptionResponse,
  ControllersMeSuccessResponse,
  ControllersTagsResponse,
  ControllersTradeChartDrawingsResponse,
  ControllersTickersListResponse,
  ServicesTradesListPagination,
  UIDataResponse,
  ControllersOrdersListResponse,
} from '@/utils/types/contracts.types'
import { toQueryString } from '@/utils/lib/objectToQueryString'
import { TradesQueryParams } from '../types'
import { axiosMainService } from '@/services/axios'
import { TExchangesAvailable } from '@/core/constants'
import { TAccountType } from '@/core/types/overview'
import { UIDataContent, getDefaultTradesFilters, prepareUiData } from '@/redux/trades/trades.utils'
import { TradesAvailableKeysStatus, TradesDayOrdersQuery, TradingPlatforms } from '@/redux/trades/trades.types'
import { enqueueSnackbar } from 'notistack'
import { t } from '@lingui/macro'

const API_URL = 'statistics-gtw/protected/api/v1/statistics'
const TMM_URL = '/proxy/api/v2'

export const TradesAPI = {
  async tradesUserInit() {
    return await axiosMainService.post<TakeUserInit>(`${API_URL}/init`)
  },
  async tradesAvailableExchange() {
    return await axiosMainService.post<TradesAvailableExchange>(
      'statistics-gtw/protected/api/v2/statistics/exchanges/available'
    )
  },
  async getTradesAvailableExchange() {
    return await axiosMainService.get<TradesAvailableExchange>(
      'statistics-gtw/protected/api/v2/statistics/exchanges/available'
    )
  },
  async repairApiKey(exchange: TExchangesAvailable) {
    try {
      return await axiosMainService.post<TradesAvailableExchange>(
        `statistics-gtw/protected/api/v2/statistics/exchanges/${exchange}/repair`
      )
    } catch (e: any) {
      const { status }: { status: number } = e.response.data

      if (status) {
        enqueueSnackbar(
          t({
            id: 'profile.apiRepair.updateError',
          }),
          { variant: 'error' }
        )
        throw e
      } else {
        throw e
      }
    }
  },
  async tradesAvailableUsers() {
    return await axiosMainService.get<TradesAvailableUsers>(`${API_URL}/users/available`)
  },
  async getTrades(queryParams: TradesQueryParams) {
    const query = toQueryString(queryParams)
    return await axiosMainService.get<ServicesTradesListPagination>(`${API_URL}${TMM_URL}/trades?${query}`)
  },

  async GetTradeById(tradeId: number) {
    const query = toQueryString({
      ...getDefaultTradesFilters(),
      durationType: undefined,
      id: tradeId,
    })

    return await axiosMainService.get<ServicesTradesListPagination>(`${API_URL}${TMM_URL}/trades?${query}`)
  },

  async getCategories() {
    return await axiosMainService.get<ControllersCategoriesListResponse>(`${API_URL}${TMM_URL}/trades/categories`)
  },

  async getTags() {
    return await axiosMainService.get<ControllersTagsResponse>(`${API_URL}${TMM_URL}/trades/tags`)
  },

  async addTag(payload: AddTradesTagsParams) {
    return await axiosMainService.post<ControllersTagsResponse>(
      `${API_URL}${TMM_URL}/trades/${payload.trade_id}/tags`,
      payload
    )
  },

  async editDescription(payload: EditTradeDescriptionParams) {
    return await axiosMainService.post<ControllersDescriptionResponse>(
      `${API_URL}${TMM_URL}/trades/${payload.trade_id}/update`,
      payload
    )
  },

  async deleteTag(payload: { tagId: number }) {
    return await axiosMainService.delete<ControllersTradeChartDrawingsResponse>(
      `${API_URL}${TMM_URL}/trades/tags/${payload.tagId}`
    )
  },

  async getMe() {
    return await axiosMainService.get<ControllersMeSuccessResponse>(`${API_URL}${TMM_URL}/auth/me`)
  },

  async getTickers() {
    return await axiosMainService.get<ControllersTickersListResponse>(`${API_URL}${TMM_URL}/exchanges/tickers`)
  },
  async getAnalyzerWeek(data: any) {
    const query = toQueryString(data)
    return await axiosMainService.get<ControllersTickersListResponse>(
      `${API_URL}${TMM_URL}/analyzer/week-list?${query}`
    )
  },
  async getAnalyzer(data: any) {
    const query = toQueryString(data)
    return await axiosMainService.get<ControllersTickersListResponse>(`${API_URL}${TMM_URL}/analyzer?${query}`)
  },
  async getTradesOrders(data: TradesDayOrdersQuery) {
    const params = new URLSearchParams(Object.entries(data).map(([key, value]) => [key, String(value)]))
    return await axiosMainService.get<ControllersOrdersListResponse>(`${API_URL}${TMM_URL}/trades/day/orders`, {
      params,
    })
  },

  async putUIData(payload: UIDataContent) {
    return await axiosMainService.put<void>(`${API_URL}${TMM_URL}/ui-data`, {
      data: JSON.stringify(prepareUiData(payload)),
    })
  },

  async getUIData() {
    return await axiosMainService.get<UIDataResponse>(`${API_URL}${TMM_URL}/ui-data`)
  },

  async postRepair(apiKeyId: number) {
    return await axiosMainService.post<{
      data: string
      status: string
    }>(`${API_URL}${TMM_URL}/api-key/${apiKeyId}/repair`)
  },
}

export type TakeUserInit = {
  userId: string
  dashboardId: number
}

export type TradesAvailableExchange = {
  userId: string
  activeExchangeAccounts: {
    exchangeType: TExchangesAvailable
    accountType: TAccountType
  }[]
  exchangeKeys: ExchangeKey[]
}

type ExchangeKey = {
  exchangeKeyType: TradingPlatforms
  id: string
  status: TradesAvailableKeysStatus
  userId?: string
}

export type GetTradesExchangeAvailableType = {
  userId: string
  exchangeRepairStatuses: {
    exchangeType: TExchangesAvailable
    enabled: boolean
    updatedAt: string
  }[]
  exchangeKeys: DetailedExchangeKey[]
}

type DetailedExchangeKey = ExchangeKey & {
  externalId: number
  updatedAt: string
}

export type AddTradesTagsParams = {
  tags: {
    id: number
    name: string
  }[]
  trade_id: number
}

export type EditTradeDescriptionParams = {
  conclusion?: string
  description: string
  video_link?: string
  trade_id: number
}

export type TradesAvailableUsers = {
  userId: string
  isStatisticAvailable: boolean
  externalUserId: string
}
