import clsx from 'clsx'
import style from './style.module.scss'
import { useCommonHoverEffect } from '../hooks/useCommonHoverEffect'
import { FC, useCallback } from 'react'
import { Button, ButtonSize } from '@/ui/kit'
import { Lighting } from '@/assets/svg/settings/Lighting'
import { ICard } from '../hooks/useExchangeCardsData'
import { useActions } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'
import { urls } from '@/router/urls'
import * as amplitude from '@amplitude/analytics-browser'
import { E_AmplitudeEventName } from '@/redux/profile/profile.types'
import { EXCHANGE_TYPE_SELECTOR, EXCHANGES_AVAILABLE, EXCHANGES_TYPE } from '@/core/constants'

export const ExchangeCard: FC<ICard> = ({
  name,
  columns,
  commission,
  header,
  rows,
  button,
  isAccent,
  isDisabled,
  description,
}) => {
  const { UpdateMetadata } = useActions(profileActions)
  const { isHovered, ...hoverEffects } = useCommonHoverEffect({
    isHovered: !!isAccent,
    disabled: !!isDisabled,
  })
  const IconComponent = header.icon
  const ButtonComponent = isAccent ? Button.Accent : Button.Primary

  const chooseExchange = useCallback(async () => {
    if (!button.data) return
    const event = {
      event_type: E_AmplitudeEventName.ExchangeSelected,
      event_properties: {
        exchangeType: button.data,
      },
    }
    amplitude.track(event)
    await UpdateMetadata({
      exchangeType: button.data,
    })
    sessionStorage.removeItem(EXCHANGE_TYPE_SELECTOR)

    if (EXCHANGES_AVAILABLE.includes(button.data)) {
      if (button.data === EXCHANGES_TYPE.OKX_AFFILIATE) {
        window.location.href = urls.okxAffiliate
        return
      }
      window.location.href = urls.root
    }
  }, [button])

  return (
    <div
      className={clsx(style.exchangeCard, {
        [style.hovered]: isHovered,
      })}
      {...hoverEffects}
    >
      {name === EXCHANGES_TYPE.OKX_AFFILIATE && (
        <div className={style.gradient}>
          <div className={style.backgroundTextWrapper}>
            <div className={style.backgroundText}>45%</div>
          </div>
        </div>
      )}
      <div className={style.cardHeader}>
        <div className={style.cardIconWrapper}>
          {!!isAccent && <Lighting className={style.cardAccentIcon} />}
          <IconComponent />
        </div>
        <span className={style.cardTitle}>{header.exchange}</span>
      </div>

      <div className={style.cardContent}>
        <div className={style.comissionWrapper}>
          {commission && (
            <>
              <span className={clsx(style.comissionPercent, { [style.comissionPercentAccent]: isAccent })}>
                {commission.percent}
                {typeof commission.percent !== 'string' && '%'}
              </span>
              <span className={clsx(style.fontSemiboldMedium, style.colorGray)}>{commission.title}</span>
            </>
          )}
        </div>

        {description ? (
          <div className={style.description}>
            <span className={style.fontBoldMedium}>{description}</span>
          </div>
        ) : (
          <>
            {columns && (
              <div className={style.item}>
                <span className={style.fontBoldMedium}>{columns[0]}</span>
                <span className={clsx(style.fontSemiboldSmall, style.colorGray)}>{columns[1]}</span>
              </div>
            )}
            {rows?.map((row, index) => (
              <div className={clsx(style.item, style.rowItem)} key={index}>
                <span className={style.fontSemiboldSmall}>{row[0]}</span>
                <span className={style.fontBoldMedium}>{row[1]}</span>
              </div>
            ))}
          </>
        )}
      </div>

      <ButtonComponent
        className={style.button}
        disabled={!!isDisabled}
        onClick={chooseExchange}
        label={button.title}
        size={ButtonSize.Medium}
      />
    </div>
  )
}
