import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useHashNavigation = (links: Record<string, string>[], isContentLoaded: boolean) => {
  const location = useLocation()

  const [active, setActive] = useState(links[0]?.id)
  const intersectionRatios = useRef({} as Record<string, number>)

  useEffect(() => {
    if (!isContentLoaded) return
    document.body.style.scrollBehavior = 'smooth'
    const disconnectObserver = connectObserver()

    return () => {
      document.body.style.scrollBehavior = ''
      disconnectObserver()
    }
  }, [isContentLoaded])

  useEffect(() => {
    const id = location.hash.replace('#', '')
    scrollTo(id)
  }, [location.hash])

  const connectObserver = () => {
    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '-80px 0px -100px 0px',
      threshold: [0.1, 0.5, 1],
    })

    links.map(({ id }) => document.getElementById(id)).map(el => el && observer.observe(el))

    return observer.disconnect
  }

  const handleIntersect: IntersectionObserverCallback = entries => {
    entries.forEach(entry => {
      const id = entry.target.id

      if (intersectionRatios.current) {
        intersectionRatios.current[id] = entry.isIntersecting ? entry.intersectionRatio : 0
      }
    })

    const active = Object.entries(intersectionRatios.current).reduce(
      (max, [id, ratio]) => {
        if (max[1] >= ratio) return max
        return [id, ratio]
      },
      ['', 0]
    )

    setActive(active[0])
  }

  const scrollTo = (id: string) => {
    const el = document.getElementById(id)

    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }

  return { active, setActive }
}
