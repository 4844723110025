import clsx from 'clsx'
import React, { FC, memo, useMemo } from 'react'
import { useMobileSizeDetect, useOutsideClick } from '@/utils'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { usePreventScroll, useViewportSize } from '@/utils/hooks'
import { isIOS } from '@/utils/lib/platform'

type PopupPropsType = {
  isOpen: boolean
  className?: string
  children?: React.ReactNode
  onClose?: () => void
  bgClassName?: string
}

interface PopupContentProps {
  children: React.ReactNode
  className?: string
  bgClassName?: string
  onClose: () => void
}

const PopupContent: FC<PopupContentProps> = ({ children, bgClassName, className, onClose }) => {
  usePreventScroll()

  const isIOSPlatform = useMemo(() => isIOS(), [])
  const [isMobile] = useMobileSizeDetect()

  const viewport = useViewportSize({ isDisabled: !isIOSPlatform })
  const ref = useOutsideClick<HTMLDivElement>(onClose)

  return (
    <div
      className={clsx(isMobile && style.mobile, style.popupBackgroundWrapper, bgClassName)}
      style={{ ...(isMobile && isIOSPlatform && { height: viewport.height + 'px' }) }}
    >
      <div ref={ref} className={clsx(style.popupContent, className)} data-testid={DataTestIds.PopupFull}>
        {children}
      </div>
    </div>
  )
}

export const Popup = memo<PopupPropsType>(({ children, className, isOpen, onClose = () => void 0, bgClassName }) => {
  if (!isOpen) {
    return null
  }

  return (
    <PopupContent onClose={onClose} bgClassName={bgClassName} className={className}>
      {children}
    </PopupContent>
  )
})
