import { AddressCreateDTO } from './../models/AddressDTO'
import { axiosMainService } from '@/services/axios'
import { AddressDTO } from '../models/AddressDTO'
import { enqueueSnackbar } from 'notistack'
import { ErrorCode } from '../models/ErrorCodesDTO'

export type DeleteAddressPayload = {
  addressId: string
  googleCode: string
}

export type EditPayload = {
  addressId: string
  data: AddressCreateDTO
}

export type GetAddressByIdPayload = {
  id: string
}
export type ToggleWhitelistPermissionPayload = {
  googleCode: string
  enabled: boolean
}

export type GetWhitelistPayload = {
  enabled: boolean
}

const ADDRESS_URL = 'binance-broker-crypto/protected/api/v1'

export const AddressApi = {
  async getAddresses() {
    return await axiosMainService.get<AddressDTO[]>(`${ADDRESS_URL}/wallets`)
  },

  async getAddressById({ id }: GetAddressByIdPayload) {
    try {
      return await axiosMainService.get<AddressDTO[]>(`${ADDRESS_URL}/wallets/${id}`)
    } catch (e: any) {
      const { code }: { code: ErrorCode } = e.response.data
      if (code === 5002 || code === 5003) {
        enqueueSnackbar('Error, try again', { variant: 'error' })
        throw e
      } else {
        throw e
      }
    }
  },

  async createAddress(data: AddressCreateDTO) {
    try {
      return await axiosMainService.post(`${ADDRESS_URL}/wallets`, {
        ...data,
      })
    } catch (e: any) {
      const { code }: { code: ErrorCode } = e.response.data
      if (code === 5002 || code === 5003) {
        enqueueSnackbar('Error, try again', { variant: 'error' })
        throw e
      } else {
        throw e
      }
    }
  },

  async deleteAddress({ addressId, googleCode }: DeleteAddressPayload) {
    return await axiosMainService.delete(`${ADDRESS_URL}/wallets/${addressId}`, {
      data: {
        googleCode,
      },
    })
  },

  async editAddress({ addressId, data }: EditPayload) {
    try {
      return await axiosMainService.put(`${ADDRESS_URL}/wallets/${addressId}`, {
        ...data,
      })
    } catch (e: any) {
      const { code }: { code: ErrorCode } = e.response.data
      if (code === 5002 || code === 5003) {
        enqueueSnackbar('Error, try again', { variant: 'error' })
        throw e
      } else {
        throw e
      }
    }
  },

  async getWhiteList() {
    return await axiosMainService.get<GetWhitelistPayload>(`${ADDRESS_URL}/wallets/whitelist`)
  },

  async postToggleWhitelistPermission(payload: ToggleWhitelistPermissionPayload) {
    try {
      return await axiosMainService.post(`${ADDRESS_URL}/wallets/whitelist`, {
        ...payload,
      })
    } catch (e: any) {
      const { code }: { code: ErrorCode } = e.response.data
      if (code) {
        enqueueSnackbar('Error, try again', { variant: 'error' })
        throw e
      } else {
        throw e
      }
    }
  },
}
