import { ALLOW_LANGUAGES_CODES, ROOT_PAGES, TExchangesAvailable } from '@/core/constants'
import { TradesTableItem } from '@/redux/profile/profile.utils'
import type { urls } from '@/router/urls'
import { E_BINANCE_VIP_CONSENT_PERMISSIONS } from '@/ui/molecules/BinanceVipConsentForm/BinanceVipConsentForm.types'

export type ProfileResponseDTO = {
  locale?: (typeof ALLOW_LANGUAGES_CODES)[number]
  metadata?: MetadataRequestDTO
  google: {
    enabled: boolean
    secret?: string
  }
  sms: {
    enabled: boolean
  }
  features: {
    smsEnabled: boolean
    googleEnabled: boolean
  }
  loginTwoFa: boolean
  vipAccess: boolean
  twoFa: {
    resetState: TReset2FA
  }
}

export enum Reset2FA {
  READY = 'READY',
  IN_PROGRESS = 'IN_PROGRESS',
  USED = 'USED',
}

export type TReset2FA = Reset2FA.READY | Reset2FA.IN_PROGRESS | Reset2FA.USED | string

export type LocaleRequestDTO = {
  locale: (typeof ALLOW_LANGUAGES_CODES)[number]
}

export type MetadataRequestDTO = {
  rootPage?: (typeof ROOT_PAGES)[number]
  exchangeType?: TExchangesAvailable
  messagesShownCashbackIncreased?: 'true' | 'false'
  messagesShownReducedCommission?: 'true' | 'false'
  isFirstSessionTracked?: 'true' | 'false'
  statisticsStartMoment?: string
  firstSesstionTrackedTimestamp?: string
  isOrdersPageBalancesEnabled?: 'true' | 'false'
  acceptedOferta?: string
  shownPopupList?: string // JSON.stringify(arr)
  tradesTablePreset?: string // JSON.stringify(arr)
  isCreatedMasterApiKeyStatusSuccess?: 'true' | 'false'
  binanceVipConsent?: string // JSON.stringify(arr)
  isStatisticsInit?: 'true' | 'false'
  isHasOKXApiKey?: 'true' | 'false'
  flags?: string // JSON.stringify(obj)
  journalRestoredKeys?: string // JSON.stringify(arr)
}

interface IFeatureFlag<T = string> {
  name: T
  enabled: boolean
  availabilityAfter?: string // time to turn feature on
}

export interface IFeatureRecord {
  exchangeProxy?: IFeatureFlag<'exchangeProxy'>
  tradingJournal?: IFeatureFlag<'tradingJournal'>
}

export interface IMetadata {
  redirectTo: (typeof urls)[keyof typeof urls]
  exchangeType: TExchangesAvailable | null
  messagesShownCashbackIncreased: boolean
  messagesShownReducedCommission: boolean
  isFirstSessionTracked: boolean
  isCreatedMasterApiKeyStatusSuccess: boolean
  isStatisticsInit: boolean
  isHasOKXApiKey?: boolean
  statisticsStartMoment: string | null
  firstSesstionTrackedTimestamp: string | null
  isOrdersPageBalancesEnabled: boolean
  acceptedOferta: boolean
  shownPopupList: string[]
  tradesTablePreset: TradesTableItem[]
  binanceVipConsent: E_BINANCE_VIP_CONSENT_PERMISSIONS[]
  flags: IFeatureRecord
  journalRestoredKeys?: number[]
}

export type BNBBurnStatusResponseDTO = {
  spotBNBBurn: boolean
}

export type UpdateBNBBurnRequestDTO = {
  spotBNBBurn: boolean
}

export type MultiAssetMarginResponseDTO = {
  multiAssetsMargin: boolean
}

export type UpdateMultiAssetMarginRequestDTO = {
  multiAssetsMargin: boolean
}

export type DustStatusResponseDTO = {
  enabled: boolean
  lastConversion: string
}

export type UpdateDustStatusRequestDTO = {
  enabled: boolean
}

export type VerifyTwoFaRequestDTO = {
  sms: string
  google: string
}

export type VerifyTwoFaResponseDTO = {
  smsValid: boolean
  googleValid: boolean
  valid: boolean
}

export type LoginWithTwoFaRequestDTO = {
  codes?: {
    sms: string
    google: string
  }
  enabled: boolean
}

export type LoginWithTwoFaResponseDTO = {
  smsValid: boolean
  googleValid: boolean
  valid: boolean
}

export type TradesTableConfig = []
