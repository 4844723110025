import { FC, useState } from 'react'
import { t } from '@lingui/macro'
import { ReferralHowForm } from '@/ui/molecules/ReferralHowForm'
import { ReferralHowTable } from '@/ui/molecules/ReferralHowTable'
import s from './style.module.scss'

const ReferralHowRightBar: FC = () => {
  const [value, setValue] = useState('1000')

  return (
    <div className={s.root}>
      <div className={s.headingWrap}>
        <h1 className={s.heading}>{t({ id: 'referralHowItWorks.heading', message: 'Calculate your reward' })}</h1>
        <ReferralHowForm value={value} onChange={setValue} />
      </div>
      <ReferralHowTable value={value} />
    </div>
  )
}

export { ReferralHowRightBar }
