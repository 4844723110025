interface AddressProps {
  className?: string
}

export const Transfer = (props: AddressProps) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.95 7.5C4.95 7.5 3.15205 5.67157 2 4.5M2 4.5C3.15205 3.32843 4.95 1.5 4.95 1.5M2 4.5C5.29156 4.5 14 4.5 14 4.5"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.05 14.5C12.05 14.5 13.848 12.6716 15 11.5M15 11.5C13.848 10.3284 12.05 8.5 12.05 8.5M15 11.5C11.7084 11.5 3 11.5 3 11.5"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
