import { FC } from 'react'

type HighSideProps = {
  className?: string
}

export const HighSide: FC<HighSideProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={21} fill="none" className={className}>
    <path
      fill="#1ACC81"
      fillRule="evenodd"
      d="M4.794 6.632a.75.75 0 1 0 0 1.5h2.195L2.661 12.46a.75.75 0 0 0 1.06 1.06L8.05 9.193v2.196a.75.75 0 0 0 1.5 0V7.382a.747.747 0 0 0-.75-.75H4.794Z"
      clipRule="evenodd"
    />
  </svg>
)
