import { SVG } from '@/assets/svg'
import { EPartnerAchievements, EPartners } from '@/redux/partner/partner.types'
import { urls } from '@/router/urls'
import { ReactElement } from 'react'
import cryptoSquad50 from 'src/assets/png/partners/cryptoSquad50.png'
import volkov from 'src/assets/png/volkov.png'

export const ACHIEVEMENTS_CONTENT: Record<
  EPartnerAchievements,
  {
    icon: ReactElement
    link: string
  }
> = {
  kycPassed: {
    icon: <SVG.Achievements.Kyc />,
    link: urls.kyc,
  },
  depositMade: {
    icon: <SVG.Achievements.Deposit />,
    link: urls.wallet,
  },
  apiKeyCreated: {
    icon: <SVG.Achievements.Key />,
    link: urls.api,
  },
  tradeMade: {
    icon: <SVG.Achievements.Deal />,
    link: urls.orders,
  },
  tradingVolume10K: {
    icon: <SVG.Achievements.Volume />,
    link: urls.orders,
  },
}

export const PARTNERS_CONTENT: Record<EPartners, { icon: ReactElement | null; png?: any }> = {
  proscalping: {
    icon: <SVG.Partner.Proscalping />,
  },
  cryptosquad: {
    icon: null,
    png: cryptoSquad50,
  },
  volkov: {
    icon: null,
    png: volkov,
  },
}

export const PARTNER_TRADING_VOLUME = 10000
