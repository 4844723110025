import clsx from 'clsx'
import React, { KeyboardEventHandler, memo } from 'react'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

export interface InputCodeProps {
  type?: string

  /** value to display inside Input */
  value: string | number

  /** placeholder */
  placeholder?: string

  disabled?: boolean

  /** colorize input based on this property */
  valid?: boolean

  /** background of the Input is darker if true */
  darken?: boolean

  /** onChange handler */
  onChange: (value: string) => void

  onFocus?: () => void

  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
}

const InputCodeComponent = React.forwardRef<any, InputCodeProps>((props, ref) => {
  const { type = 'tel', value, placeholder, disabled, valid, darken, onChange, onFocus, onKeyDown } = props

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  return (
    <input
      ref={ref}
      type={type}
      value={String(value).substring(0, 1)}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      className={clsx(style.input, {
        [style.valid]: valid,
        [style.invalid]: valid === false,
        [style.darken]: darken,
      })}
      data-testid={DataTestIds.ModalTwoFAInput}
    />
  )
})

/** Input for digits to enter the code */
export const InputCode = memo(InputCodeComponent)
