import { FC, ReactNode, useCallback, useMemo } from 'react'
import { t } from '@lingui/macro'
import { useNavigate } from 'react-router-dom'

import styles from './style.module.scss'
import { useActions, useAppSelector } from '@/utils'
import { SVG } from '@/assets/svg'
import { Button, ButtonVariant } from '@/ui/kit'
import { kycActions } from '@/redux/kyc/kyc.slice'
import { Trans } from '@lingui/react'
import clsx from 'clsx'
import { urls } from '@/router/urls'
import { isExchangeBinance } from '@/utils/lib/exchange'

export type TDescription = {
  title: ReactNode
  description: ReactNode
}

export const KYCLinkStatus: FC<{ customDescription?: TDescription }> = props => {
  const { customDescription } = props
  const { userKycLinkState } = useAppSelector(state => state.kyc)
  const { exchangeType } = useAppSelector(state => state.app)
  const { GetUserKycLinkTC } = useActions(kycActions)
  const navigate = useNavigate()

  const handleRedirectToVerification = useCallback(async () => {
    const response = await GetUserKycLinkTC()

    // @ts-ignore
    if ('payload' in response && response?.payload.url) {
      // @ts-ignore
      window.location.href = response.payload.url
    }
  }, [GetUserKycLinkTC])

  const navigateChooseExchangePage = () => {
    navigate(`${urls.chooseExchange}?binance=false`)
  }

  const kycLinkStatus = useMemo(() => {
    if (!userKycLinkState.status || !isExchangeBinance(exchangeType)) {
      return null
    }

    switch (userKycLinkState.status) {
      case 'FINAL_REJECT':
        return (
          <>
            <SVG.Status.Failed className={styles.kycLinkStatusIcon} />
            <div className={styles.textWrapper}>
              <div className={styles.statusText}>
                <Trans
                  id="kycLinkStatus.reject"
                  message={'Additional Binance verification is failed. You can trade on ByBit or OKX.'}
                  components={{
                    colored: <span className={styles.statusTextYellow} />,
                  }}
                />
              </div>
            </div>
            <Button variant={ButtonVariant.Accent} onClick={navigateChooseExchangePage}>
              {t({ id: 'binanceLink.chooseExchange', message: 'Выбрать ByBit или OKX' })}
            </Button>
          </>
        )
      case 'REJECT':
        return (
          <>
            <SVG.OtherIcons.Hourglass />
            <div className={styles.textWrapperColumn}>
              <p className={styles.statusText}>
                {t({
                  id: 'kycLinkStatus.notFinalReject',
                  comment: 'Additional Binance verification is under review. Trading on Binance is limited.',
                })}
              </p>
              <p className={clsx(styles.statusText, styles.statusTextGreen)}>
                {t({
                  id: 'kycLinkStatus.inTheMeantime',
                  comment: 'In the meantime, continue trading on OKX and Bybit with full access.',
                })}
              </p>
            </div>
          </>
        )
      case 'INIT':
        return (
          <>
            <SVG.OtherIcons.Alert className={styles.kycLinkStatusIcon} />
            <div className={styles.textWrapperColumn}>
              <p className={styles.statusText}>
                <Trans
                  id={'kycLinkStatus.verificationIsRequiredReachedLimit'}
                  message="Additional Binance verification is required. Please compite it to keep trading on Binance."
                  components={{
                    colored: <span className={styles.statusTextYellow} />,
                  }}
                />
              </p>
            </div>
            <Button variant={ButtonVariant.Accent} onClick={handleRedirectToVerification}>
              {t({ id: 'kycLinkStatus.completeVerification', message: 'Complete verification' })}
            </Button>
          </>
        )
      case 'PENDING':
        return (
          <>
            <SVG.OtherIcons.Hourglass />
            <div className={styles.textWrapperColumn}>
              <p className={styles.statusText}>
                {t({
                  id: 'kycLinkStatus.pending',
                  comment: 'Additional Binance verification is under review.',
                })}
              </p>
              <p className={clsx(styles.statusText, styles.statusTextGreen)}>
                {t({
                  id: 'kycLinkStatus.grantsTradingAccess',
                  comment: 'Your current verification status grants you trading access.',
                })}
              </p>
            </div>
            <Button variant={ButtonVariant.Accent} onClick={handleRedirectToVerification}>
              {t({ id: 'kycLinkStatus.completeVerification', message: 'Complete verification' })}
            </Button>
          </>
        )
      default:
        return (
          <>
            <SVG.Status.Completed className={styles.kycLinkStatusIcon} />
            <div className={styles.textWrapper}>
              <p className={styles.statusText}>
                <Trans
                  id="kycLinkStatus.verificationSuccess"
                  message="Your verification status Standart. You can trade without problems!"
                  components={{
                    colored: <span className={styles.statusTextGreen} />,
                  }}
                />
              </p>
            </div>
          </>
        )
    }
  }, [userKycLinkState.status, exchangeType])

  return (
    <div className={styles.kycLinkStatusRoot}>
      {kycLinkStatus && <div className={styles.kycLinkStatusWrapper}>{kycLinkStatus}</div>}
      {customDescription && (
        <div className={styles.descWrapper}>
          <div className={styles.descTextWhite}>{customDescription.title}</div>
          <div className={styles.descTextGrey}>{customDescription.description}</div>
        </div>
      )}
    </div>
  )
}
