import React, { memo } from 'react'
import { IAllocateTigerToken } from '@/core/infra/v1/TigerToken/AllocateTigerToken.interface'
import { TNullable } from '@/core/infra/common'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { Status as StatusVariant } from '@/backend/types'
import { t } from '@lingui/macro'

//todo : move to history when it will be done
export interface ITransactionModalProps {
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  allocate: TNullable<IAllocateTigerToken>
  date?: string
  status?: StatusVariant
}

export const TransactionModal: React.FC<ITransactionModalProps> = memo(props => {
  const textFrom = t({ id: 'core.transaction.from', message: 'From' })
  const textTo = t({ id: 'core.transaction.to', message: 'To' })
  const textAllocation = t({ id: 'core.operation.allocation', message: `Allocation` })
  const textWithoutCommission = t({ id: 'core.transaction.withoutCommission', message: `Without commission` })
  const textSpot = t({ id: 'core.transaction.spot', message: `Spot` })
  const textBalance = t({ id: 'core.transaction.balance', message: 'Allocated balance' })

  return (
    <InteractiveModal isOpen={props.isOpen}>
      <InteractiveModalParts.Icon icon={'transfer'} />
      <InteractiveModalParts.Header text={textAllocation} />
      <InteractiveModalParts.MainInfo text={`${String(props.allocate?.amount)} ${props.allocate?.asset}` || ''} />
      <InteractiveModalParts.Explanation text={textWithoutCommission} />
      <InteractiveModalParts.Status status={props.status || 'loading'} date={props.date} />
      <InteractiveModalParts.Table left={textFrom} right={textSpot} />
      <InteractiveModalParts.Table left={textTo} right={textBalance} />
      <InteractiveModalParts.Button
        text={t({ id: 'core.close', message: `Close` })}
        variant={ButtonVariant.White}
        onClick={() => props.setIsOpen(false)}
      />
    </InteractiveModal>
  )
})
