import { FC } from 'react'

export const ListCheckbox: FC = () => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.979492 4.13593L3.35403 7L9.02068 1"
        stroke="#FAFAFA"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
