import React, { FC, Suspense, memo, useState, useEffect, useCallback, useMemo } from 'react'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { Loader } from '@/ui/kit/Loader'
import clsx from 'clsx'
import { LevelItem } from '@/pages/KYCLevels/components/Level.item'
import { useKYCLevelsVM } from '@/pages/KYCLevels/hooks/useKYCLevelsVM'
import { useActions, useAppSelector } from '@/utils'
import { kycActions } from '@/redux/kyc/kyc.slice'
import { KYCLinkStatus, TDescription } from './KYCRightBar.Components/'
import { isExchangeBinance } from '@/utils/lib/exchange'
import { KYCLevelService } from '@/services/KYCLevelService/KYCLevelService'
import { KYCActivity, KYCTier } from '@/backend/models/KycDTO'
import { apiKeyActions } from '@/redux/apiKey/apiKey.slice'
import { TPollingConditionalFunc, usePolling } from '@/utils/hooks/usePolling'
import {
  KYC_LINK_READ_ONLY_POLLING_INTERVAL,
  KYC_LINK_STATUS_POLLING_INTERVAL,
  KYC_STATUS_POLLING_INTERVAL,
} from '@/core/config/kyc'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { urls } from '@/router/urls'
import { useNavigate } from 'react-router-dom'
import { useKYC } from '@/utils/hooks'
import NotifiactionCard from '@/ui/molecules/SystemNotifications/components/NotifiactionCard/NotifiactionCard'

const KYCForm = React.lazy(() => import('../KYCForm'))

export const KYCRightBar: FC = memo(() => {
  const navigate = useNavigate()
  const { exchangeType } = useExchageType()
  const { userKycLinkState, userKYC, initWorkflowStatus, userKycStatus, kycTierOnLoad } = useAppSelector(
    state => state.kyc
  )
  const { metadata } = useAppSelector(state => state.profile)
  const { createdApiReadOnlyStatus, createdApiReadOnlyStatusInitial } = useAppSelector(state => state.apiKeys)
  const { currentTierLimits, nextTierLimits } = useKYC()

  const { GetUserKycLinkStatusTC, setUserKycLinkStatusRequestStatus, InitWorkflowTC, GetUserKycTC } =
    useActions(kycActions)
  const { GetReadOnlyKeyStatus } = useActions(apiKeyActions)
  const [isShowKYCForm, setShowKYCForm] = useState(
    KYCLevelService.compareTier(KYCTier.Standard, userKYC.kycTier) === 'prev'
  )
  const [lastTierReceived, setLastTierReceived] = useState(kycTierOnLoad)
  const [isForcedInit, setIsForcedInit] = useState(false)

  const pageTitle = useMemo(() => {
    return isShowKYCForm && KYCLevelService.compareTier(KYCTier.Standard, userKYC.kycTier) !== 'next'
      ? t({
          id: 'kycPage.title',
          message: 'Personal data',
        })
      : t({
          id: 'kycPage.verificationTitle',
          message: 'Verification status',
        })
  }, [isShowKYCForm, userKYC.kycTier])

  const statusDescription = useMemo<TDescription | undefined>(() => {
    return (!isExchangeBinance(exchangeType) && isShowKYCForm) ||
      (isShowKYCForm && isExchangeBinance(exchangeType) && createdApiReadOnlyStatusInitial === 'succeeded')
      ? {
          description: t({
            id: 'kyc.upgrade.description',
            values: {
              depositCurrent: currentTierLimits?.deposit.formatted,
              depositNext: nextTierLimits?.deposit.formatted,
              withdrawCurrent: currentTierLimits?.withdraw.formatted,
              withdrawNext: nextTierLimits?.withdraw.formatted,
            },
          }),
          title: t({
            id: 'kyc.upgrade.subtitle',
            comment: 'You are now upgrading limits',
          }),
        }
      : undefined
  }, [isShowKYCForm, currentTierLimits, nextTierLimits, createdApiReadOnlyStatusInitial, exchangeType])

  const isLinkStatusShown = useMemo(() => {
    return KYCLevelService.compareTier(KYCTier.Standard, userKYC.kycTier) !== 'prev'
  }, [userKYC.kycTier])

  const handleShowKycForm = () => {
    setShowKYCForm(true)
  }

  const handleTierUpgradeCallback = useCallback(() => {
    setIsForcedInit(true)
    handleShowKycForm()
  }, [initWorkflowStatus, userKYC.activity])

  const { levels } = useKYCLevelsVM(handleTierUpgradeCallback)

  const handleTierReceivedCallback = useCallback(
    (newTier: KYCTier) => {
      if (newTier !== kycTierOnLoad && newTier !== lastTierReceived) {
        setShowKYCForm(false)
        setLastTierReceived(newTier)
        userKycPollingStart()
      }
      // if (
      //   KYCLevelService.compareTier(KYCTier.Standard, userKYC.kycTier) !== 'prev' &&
      //   kycStatusOnLoad !== userKYC.kycTier
      // ) {
      // setShowKYCForm(false)
      // }
    },
    [kycTierOnLoad, lastTierReceived]
  )

  // polling for main kyc status check
  const userKycPollingCheckFunction = useCallback<TPollingConditionalFunc>(() => {
    if (userKycStatus === 'loading') return false
    return true
  }, [userKycStatus])

  const { startPolling: userKycPollingStart, stopPolling: userKycPollingStop } = usePolling(
    GetUserKycTC,
    userKycPollingCheckFunction,
    KYC_STATUS_POLLING_INTERVAL
  )

  // polling for kyc-link status check
  // const kycLinkStatusPollingCheckFunction = useCallback<TPollingConditionalFunc>(() => {
  //   if (createdApiReadOnlyStatus === 'loading') return false
  //   return true
  // }, [createdApiReadOnlyStatus])

  // const { isPollingActive: kycLinkStatusPollingStatus, startPolling: kycLinkStatusPollingStart } = usePolling(
  //   GetUserKycLinkStatusTC,
  //   kycLinkStatusPollingCheckFunction,
  //   KYC_LINK_STATUS_POLLING_INTERVAL
  // )

  // polling for subaccounts check
  const readOnlyKeyPollingCheckFunction = useCallback<TPollingConditionalFunc>(() => {
    if (createdApiReadOnlyStatus === 'loading') return false
    return true
  }, [createdApiReadOnlyStatus])

  const { isPollingActive: readOnlyPollingStatus, startPolling: readOnlyPollingStart } = usePolling(
    GetReadOnlyKeyStatus,
    readOnlyKeyPollingCheckFunction,
    KYC_LINK_READ_ONLY_POLLING_INTERVAL
  )

  //Binance: when page loads check kyc-link creation if not success yet
  // useEffect(() => {
  //   if (!isExchangeBinance(exchangeType)) return

  //   if (
  //     !isShowKYCForm &&
  //     (userKycLinkState.status === 'PENDING' || userKycLinkState.status === 'KYC_AUTOMATIC_CHECK') &&
  //     kycLinkStatusPollingStatus === false
  //   ) {
  //     kycLinkStatusPollingStart()
  //   }
  // }, [kycLinkStatusPollingStatus, kycLinkStatusPollingStart, userKycLinkState.status, isShowKYCForm, exchangeType])

  //Binance: check subaccounts creation if not created yet, when page loads or after kyc-link update
  useEffect(() => {
    if (!isExchangeBinance(exchangeType)) return

    if (
      !isShowKYCForm &&
      KYCLevelService.compareTier(KYCTier.Standard, userKYC.kycTier) !== 'prev' &&
      // userKycLinkState.status === 'SUCCESS' &&
      createdApiReadOnlyStatusInitial === 'failed' &&
      readOnlyPollingStatus === false
    ) {
      readOnlyPollingStart()
    }
  }, [
    createdApiReadOnlyStatusInitial,
    readOnlyPollingStart,
    exchangeType,
    isShowKYCForm,
    readOnlyPollingStatus,
    // userKycLinkState.status,
    userKYC.kycTier,
  ])

  // initial data load and status reset
  useEffect(() => {
    if (isExchangeBinance(exchangeType)) GetUserKycLinkStatusTC()
  }, [exchangeType])

  useEffect(() => {
    if (lastTierReceived === userKYC.kycTier) userKycPollingStop()
  }, [lastTierReceived, userKYC.kycTier])

  useEffect(() => {
    if (
      userKYC.kycTier === KYCTier.Standard &&
      userKYC.kycTier !== kycTierOnLoad &&
      createdApiReadOnlyStatusInitial === 'succeeded' &&
      !metadata.shownPopupList.includes('kyc_success') &&
      !isShowKYCForm
    ) {
      navigate(urls.root)
    }
  }, [userKYC.kycTier, kycTierOnLoad, createdApiReadOnlyStatusInitial, metadata, isShowKYCForm])

  useEffect(() => {
    return () => {
      setUserKycLinkStatusRequestStatus()
    }
  }, [])

  return (
    <div className={style.rightBar}>
      <TitleH1 label={pageTitle} />

      {isLinkStatusShown && <KYCLinkStatus customDescription={statusDescription} />}

      <Suspense fallback={<Loader />}>
        {isShowKYCForm ? (
          <KYCForm handleTierReceivedCallback={handleTierReceivedCallback} isForcedInit={isForcedInit} />
        ) : (
          <>
            <div className={style.levelsWrapper}>
              {levels
                .filter(level => ![KYCTier.Unverified, KYCTier.Basic].includes(level.tierId))
                .map(level => (
                  <div
                    key={level.tierName}
                    className={clsx(style.levelsWrapperItem, {
                      [style._active]: level.isActive,
                    })}
                  >
                    <LevelItem {...level} />
                  </div>
                ))}
            </div>
          </>
        )}
      </Suspense>
    </div>
  )
})
