import { AccountOverviewAssets } from '@/backend/models/OverviewDTO'
import { EAccountType, IBalanceAsset, ISpotAllCoinsInfo, TQuoteBalance } from '@tigertrade/binance-ts'
import { ACCOUNTS_BY_INSTRUMENTS, INCOME_DEFAULT, OVERVIEW_ACCOUNT_SYMBOLS_MAP } from './overview.defaults'
import { TAccountType } from '@/core/types/overview'
import BigNumber from 'bignumber.js'
import { InitialState, TIncomeStatistics, TMarketDataInternal } from './overview.types'
import { TExchangesAvailable } from '@/core/constants'
import { TInstrumentType } from '@/core/types/overview'
import { EBybitInstruments } from '@/services/bybit'
import { TUsdmIncomeHistory } from '@tigertrade/binance-ts'
import { storage } from '@/utils/lib/storage'
import { IMarketData } from '@tigertrade/binance-ts'
import { BN_ZERO } from '@/core/constants/common'

const accountAssetToOverviewAsset = (asset: IBalanceAsset): AccountOverviewAssets => {
  return {
    ...asset,
    balance: String(asset.assetBalance),
    asset: asset.assetId,
    assetFullName: asset.assetId,
  }
}

export const seedCoinsNames = (
  assets: AccountOverviewAssets[],
  allCoins: ISpotAllCoinsInfo[]
): AccountOverviewAssets[] => {
  return assets.map(asset => {
    const coin = allCoins.find(coinFind => {
      return coinFind.coin === asset.asset
    })
    return {
      ...asset,
      assetFullName: coin?.name || asset.asset,
    }
  })
}

const calcAccountAssets = (
  assets: AccountOverviewAssets[],
  accountBalance: number | undefined,
  quoteSelector: string
): AccountOverviewAssets[] => {
  return assets.map(asset => {
    if (asset.quoteBalance[quoteSelector] === undefined || accountBalance === undefined) return asset

    return {
      ...asset,
      percentage: new BigNumber(asset.quoteBalance[quoteSelector] || 0)
        .dividedBy(accountBalance)
        .multipliedBy(100)
        .dp(2)
        .toString(),
    }
  })
}

export const calcAssets = (
  type: TAccountType,
  allCoins: ISpotAllCoinsInfo[],
  assets: IBalanceAsset[],
  totalAssetsOutput: TQuoteBalance
): AccountOverviewAssets[] => {
  const selectorStable = OVERVIEW_ACCOUNT_SYMBOLS_MAP[type].selectorStable

  const sortAssets = assets.sort((a, b) => b.quoteBalance[selectorStable] - a.quoteBalance[selectorStable])

  return seedCoinsNames(
    calcAccountAssets(sortAssets.map(accountAssetToOverviewAsset), totalAssetsOutput[selectorStable], selectorStable),
    allCoins
  )
}

export const calcKnownAssetsSum = (type: TAccountType, assets: IBalanceAsset[]): TQuoteBalance => {
  const selectorOutput = OVERVIEW_ACCOUNT_SYMBOLS_MAP[type].selectorOutput
  const sum = assets.reduce((acc, asset) => {
    return acc.plus(asset.quoteBalance[selectorOutput] || 0)
  }, BN_ZERO)
  return {
    [selectorOutput]: sum.toNumber(),
  }
}

export const isAllBalancesLoaded = (state: InitialState): boolean => {
  return state.exchangeAccounts.reduce((acc, account) => {
    if (acc === false) return false

    return state.balances[account].isDefault ? false : true
  }, true)
}

export const isAllOrdersLoaded = (state: InitialState): boolean => {
  return state.exchangeInstruments.reduce((acc, inst) => {
    if (acc === false) return false

    return state.openOrders[inst].isDefault ? false : true
  }, true)
}

export const isAllPositionsLoaded = (state: InitialState): boolean => {
  return state.exchangeInstruments.reduce((acc, inst) => {
    if (acc === false) return false

    return state.positions[inst].isDefault && inst !== EAccountType.SPOT && inst !== EBybitInstruments.spot
      ? false
      : true
  }, true)
}

export const isFullLoaded = (state: InitialState): boolean => {
  if (
    state.statusOrders === 'succeeded' &&
    state.statusPositions === 'succeeded' &&
    state.statusBalances === 'succeeded'
  )
    return true
  return false
}

export const getAccountTypeForInstrument = (
  instrument: TInstrumentType,
  exchageType: TExchangesAvailable
): TAccountType | undefined => {
  return ACCOUNTS_BY_INSTRUMENTS[exchageType][instrument]
}

export const createUsdmHistoryUid = (historyItem: TUsdmIncomeHistory): string => {
  return `${historyItem.time}${historyItem.incomeType}${historyItem.tranId}`
}

export const createUsdmHistoryUidArray = (history: TUsdmIncomeHistory[]): string[] => {
  return history.map(createUsdmHistoryUid)
}

export const inValidateStatisticsCache = (exchangeType: TExchangesAvailable) => {
  const nonActualHistory = storage.getStatisticsCache(exchangeType).find(historyItem => {
    return !historyItem.saved
  })
  if (!!nonActualHistory) storage.clearStatisticsCache()
}

export const getHistoryStatisticsFromCache = (
  startMoment: number,
  exchangeType: TExchangesAvailable
): TIncomeStatistics => {
  const actualHistory = storage.getStatisticsCache(exchangeType).filter(historyItem => {
    return Number(historyItem.time) >= startMoment
  })

  if (actualHistory.length === 0)
    return {
      ...INCOME_DEFAULT,
      startMoment: startMoment.toString(),
    }

  return {
    history: actualHistory,
    dailyIncome: actualHistory
      .reduce((acc, historyItem) => {
        return acc.plus(historyItem.delta)
      }, BN_ZERO)
      .toString(),
    status: 'succeeded',
    startMoment: startMoment.toString(),
  }
}

export const marketDataMapToRecord = (source: IMarketData['marketAssets']): TMarketDataInternal => {
  return Array.from(source).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    }
  }, {})
}
