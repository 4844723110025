import { InitialState } from './leverage.types'

export const initialState: InitialState = {
  usdMStatus: 'idle',
  coinMStatus: 'idle',
  search: '',
  leverageType: 'usdM',
  tickers: {
    usdM: {},
    coinM: {},
  },
  brackets: {
    usdM: {},
    coinM: {},
  },
  groupEditing: {
    active: [],
    busy: false,
    maxLeverage: 1,
    leverage: 1,
    margin: undefined,
    leverageBusy: false,
    positionBusy: false,
  },
  tickersOkx: {
    swap: {},
  },
  bybitRiskLimitBySymbol: {
    linear: {},
  },
  bybitMarginType: undefined,
  tickersBybit: {
    linear: {},
  },
  positionMode: undefined,
}
