import React, { FC, memo } from 'react'

interface PropsType {}

export const SuccessFill: FC<PropsType> = memo(() => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="49.9999" height="49.9999" rx="24.9999" fill="url(#paint0_linear_12944_139812)" />
      <path
        d="M18.5381 25.2183L22.3543 29.8213L31.4614 20.1785"
        stroke="white"
        strokeWidth="3.21428"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12944_139812"
          x1="8.04414e-07"
          y1="49.9999"
          x2="27.9931"
          y2="-10.2284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#24C8DE" />
          <stop offset="1" stopColor="#4EED34" />
        </linearGradient>
      </defs>
    </svg>
  )
})
