import { Futures } from './Futures'
import { HighSide } from './HighSide'
import { LowSide } from './LowSide'
import { Spot } from './Spot'
import { ArrowLeft } from './ArrowLeft'
import { ArrowRight } from './ArrowRight'
import { BinanceIcon } from './BinanceIcon'
import { BybitIcon } from './BybitIcon'
import { OkxIcon } from './OkxIcon'
import { TmmHintIcon } from './TmmHintIcon'
import { SuccessDot } from './SuccessDot'
import { RejectDot } from './RejectDot'
import { VideoLinkIcon } from './VideoLinkIcon'

export const Tmm = {
  Spot: Spot,
  LowSide: LowSide,
  HighSide: HighSide,
  Futures: Futures,
  ArrowLeft: ArrowLeft,
  ArrowRight: ArrowRight,
  BinanceIcon: BinanceIcon,
  BybitIcon: BybitIcon,
  OkxIcon: OkxIcon,
  TmmHintIcon: TmmHintIcon,
  SuccessDot: SuccessDot,
  RejectDot: RejectDot,
  VideoLinkIcon: VideoLinkIcon,
}
