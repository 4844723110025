import { useCallback, useEffect } from 'react'
import { useActions, useAppSelector } from '@/utils'
import { apiKeyAsyncActions } from '@/redux/apiKey/apiKey.actions'
import { RemoveApiKeysPayload } from '@/backend/api/apiKeyAPI'
import { InitialState } from '@/redux/apiKey/apiKey.state'

export interface UseAPIKeysResult {
  data: InitialState
  isLoading: boolean
  isEmpty: boolean
  remove: (payload: RemoveApiKeysPayload) => void
}

export const useAPIKeys = (): UseAPIKeysResult => {
  const data = useAppSelector(state => state.apiKeys)
  const { GetApiKeysTC, RemoveApiKeyTC } = useActions(apiKeyAsyncActions)

  const isLoading = data.statusStatistics === 'loading' || data.status === 'loading'
  const isEmpty = data.statistics.apiKeyCount === 0

  const remove = useCallback(
    (payload: RemoveApiKeysPayload) => {
      RemoveApiKeyTC(payload)
    },
    [RemoveApiKeyTC]
  )

  useEffect(() => {
    if (data.apiKeyStatusRemove === 'succeeded') {
      GetApiKeysTC()
    }
  }, [data.apiKeyStatusRemove, GetApiKeysTC])

  return {
    data,
    isEmpty,
    isLoading,
    remove,
  }
}
