import { FC } from 'react'
interface IIconProps {
  className?: string
}

export const ShieldWhite: FC<IIconProps> = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4902 10.2301L13.5002 12.1101C12.3502 12.5401 11.4102 13.9001 11.4102 15.1201V22.5501C11.4102 23.7301 12.1902 25.2801 13.1402 25.9901L17.4402 29.2001C18.8502 30.2601 21.1702 30.2601 22.5802 29.2001L26.8802 25.9901C27.8302 25.2801 28.6102 23.7301 28.6102 22.5501V15.1201C28.6102 13.8901 27.6702 12.5301 26.5202 12.1001L21.5302 10.2301C20.6802 9.92006 19.3202 9.92006 18.4902 10.2301Z"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0498 19.8699L18.6598 21.4799L22.9598 17.1799"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
