import { axiosMainService } from '@/services/axios'
import { AccountBill, okxRestService } from '@/services/okx'
import { EAccountType } from '@tigertrade/binance-ts'
import { ExchangeHistoryItemInterface } from '@/redux/transactions/transactions.types'
import { TExchangesAvailable } from '@/core/constants'

const historyAPIURL = 'binance-broker-history/protected/api/v1/cashback/history/'

export const HistoryAPI = {
  async getHistoryCashback(payload: HistoryCashbackPayload) {
    return await axiosMainService.post<HistoryCashback>(`${historyAPIURL}payout/search`, payload)
  },
  async getHistoryTransactions(payload: HistoryTransactionsPayload) {
    return await axiosMainService.post<HistoryTransactions>(`${historyAPIURL}search`, payload)
  },
  async getExchangeHistory(
    payload: Record<string, string>,
    exchangeType: TExchangesAvailable
  ): Promise<AccountBill[] | ExchangeHistoryItemInterface[]> {
    switch (exchangeType) {
      case 'OKX_BROKER':
        return (await okxRestService.getAccountBills(payload)) as unknown as AccountBill[]
      case 'BINANCE_BROKER_FUTURE':
        return []
      default:
        return []
    }
  },
}

/** All history transactions DTO */
export type HistoryTransactions = {
  history: TransactionHistory[]
  page: number
  size: number
  total: number
}

/** Detailed history cashback DTO */
export type HistoryCashback = {
  cashback: HistoryCashbackItem[]
  page: number
  size: number
  total: number
  totalCashback: number
}

type HistoryCashbackItem = {
  cashbackDate: string
  id: string
  percentage: number
  quantity: number
  symbol: string
  time: string
  totalCommissionAmount: number
  totalTradeAmount: number
}

export type HistoryStatus = 'COMPLETED' | 'FAILED' | 'PENDING'
export type HistoryOperationType = 'CASHBACK' | 'DEPOSIT' | 'TRANSFER' | 'WITHDRAWAL' | 'TIGR_ALLOCATION' | 'AWARD'

type HistoryCommonFields = {
  fee?: number
  id: string
  itemStatus: HistoryStatus
  itemType: HistoryOperationType
  quantity: number
  quantityUsdt: number
  sourceAccountType: EAccountType
  symbol: string
  time: string
}

type CashbackHistoryOwnFields = {
  itemType: 'CASHBACK'
  details: {
    percentage: number
    cashbackDate: string
  }
}

type DepositHistoryOwnFields = {
  itemType: 'DEPOSIT'
  details: {
    destinationAddress: string
    sourceAddress: string
    externalId: string
    network: string
  }
}

type TransferHistoryOwnFields = {
  itemType: 'TRANSFER'
  details: {
    accountFrom: EAccountType
    accountTo: EAccountType
  }
}

type AwardHistoryOwnFields = {
  itemType: 'AWARD'
  details: {
    awardKey: string
  }
}

type WithdrawalHistoryOwnFields = {
  itemType: 'WITHDRAWAL'
  details: {
    destinationAddress: string
    network: string
    sourceAddress: string
    externalId?: string
  }
}

type AllocateHostoryFields = {
  itemType: 'TIGR_ALLOCATION'
  details: {
    destinationAddress: string
    network: string
    sourceAddress: string
  }
}

export type TransactionHistory = HistoryCommonFields &
  (
    | CashbackHistoryOwnFields
    | DepositHistoryOwnFields
    | TransferHistoryOwnFields
    | WithdrawalHistoryOwnFields
    | AllocateHostoryFields
    | AwardHistoryOwnFields
  )

export type HistoryCashbackPayload = {
  page: number
  size: number
  sortParams: {
    direction?: 'asc' | 'desc'
    sortBy?: 'quantity' | 'time'
  }
}

export type HistoryTransactionsPayload = HistoryCashbackPayload & {
  filterParams?: {
    historyItemStatus?: HistoryStatus
    historyItemType?: HistoryOperationType
    symbol?: string
  }
}
