function arrayToCSV(twoDiArray: any[][], minColumnsCount: number = 0) {
  const csvRows = []

  for (var i = 0; i < twoDiArray.length; ++i) {
    for (var j = 0; j < twoDiArray[i].length; ++j) {
      twoDiArray[i][j] = '"' + twoDiArray[i][j] + '"' // Handle elements that contain commas
    }

    if (twoDiArray[i].length < minColumnsCount) {
      const diff = minColumnsCount - twoDiArray[i].length

      for (let k = 0; k < diff; k++) {
        twoDiArray[i].push('""')
      }
    }

    csvRows.push(twoDiArray[i].join(','))
  }

  const csvString = csvRows.join('\r\n')
  const a = document.createElement('a')

  a.href = 'data:attachment/csv,' + csvString
  a.target = '_blank'
  a.download = 'export.csv'

  document.body.appendChild(a)
  a.click()
  a.remove()
}

export { arrayToCSV }
