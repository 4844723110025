export enum ASSET_ITEM_MODAL_VIEWS {
  NOT_ENOUGH = 'NOT_ENOUGH',
  SUCCEEDED = 'SUCCEEDED',
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
}

export type SoldCoin = {
  value: number
  date: string
}
