import clsx from 'clsx'
import { memo, ReactNode } from 'react'
import { SVG } from '../../../assets/svg'
import { useFlag } from '../../../utils'
import { Hint, usePopperRef } from '../../kit'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

type QuestionButtonProps = {
  /** text */
  text: ReactNode

  /** hintClassName */
  hintClassName?: string

  /** buttonClassName */
  buttonClassName?: string

  dataTestId?: string

  /** for wrapping html element  */
  children?: ReactNode

  /** for replace hint  */
  placement?: 'right' | 'top' | 'bottom' | 'left'

  /** hint className for Table  */
  hintTableClassName?: boolean

  /** hint sizeSmall for small box  */
  hitSizeSmall?: boolean

  maxWidth?: number
  maxHeight?: number

  /** hint icon render instead of default icon*/
  tmmHintIcon?: boolean
}

/** Help button to provide info on hover */
export const QuestionButton = memo<QuestionButtonProps>(
  ({
    text,
    hintClassName,
    buttonClassName,
    dataTestId,
    children,
    placement,
    hintTableClassName,
    hitSizeSmall,
    maxWidth,
    maxHeight,
    tmmHintIcon,
  }) => {
    const [ref, setRef] = usePopperRef()
    const [isVisible, , enableHint, disableHint] = useFlag()

    return (
      <>
        <div
          className={clsx(style.questionButton, {
            [style.hintClassForTable]: hintTableClassName,
          })}
          ref={setRef}
          onMouseOver={enableHint}
          onFocus={enableHint}
          onMouseOut={disableHint}
          onBlur={disableHint}
          data-testid={`${dataTestId}${DataTestIds.QButtonSuffix}`}
        >
          {children ? (
            children
          ) : tmmHintIcon ? (
            <SVG.Tmm.TmmHintIcon className={style.tmmHint} />
          ) : (
            <div className={clsx(style.icon, buttonClassName)}>
              <SVG.OtherIcons.Question />
            </div>
          )}
        </div>
        {isVisible && (
          <Hint
            placement={placement ? placement : 'right'}
            text={text}
            className={hintClassName}
            hitSizeSmall={hitSizeSmall}
            targetRef={ref}
            dataTestId={`${dataTestId}${DataTestIds.HintSuffix}`}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
          />
        )}
      </>
    )
  }
)
