import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from './useAppSelector'
import { useActions } from './useActions'
import { profileActions } from '@/redux/profile/profile.slice'
import { InitialState as ProfileInitialState } from '@/redux/profile/profile.types'

export interface UseGoogleAuthSettingsResult {
  fetchGoogleAuthSettings: () => void
  settings: ProfileInitialState['google']
  isAccepted: boolean | null
  isLoading: boolean | null
  isLoaded: boolean | null
}

/**
 * Loads auth settings from google
 * F.e is 2FA enabled and etc
 */
// TODO: remove and replace with info from profile
export const useGoogleAuthSettings = (): UseGoogleAuthSettingsResult => {
  const { google, status } = useAppSelector(state => state.profile)
  const isLoading = useMemo(() => {
    return status !== 'succeeded'
  }, [status])
  const isLoaded = useMemo(() => {
    return status === 'succeeded'
  }, [status])
  const userGoogleAuthSettings = useMemo<ProfileInitialState['google']>(() => {
    return google
  }, [google])
  const { GetProfileTC } = useActions(profileActions)

  const fetchGoogleAuthSettings = useCallback(() => {
    GetProfileTC()
  }, [])

  return {
    fetchGoogleAuthSettings,
    settings: userGoogleAuthSettings,
    isAccepted: userGoogleAuthSettings.enabled || false,
    isLoading,
    isLoaded,
  }
}
