import { useActions, useAppSelector } from '@/utils'
import style from './style.module.scss'
import { notificationActions } from '@/redux/notification/notification.slice'
import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'
import { NotificationStatus, NotificationType, TypeNotification } from '@/redux/notification/notification.types'
import { useState } from 'react'
import clsx from 'clsx'
import { formatDate } from '@/utils/lib/formatDate'

type Props = {
  notification: TypeNotification
}

export const Notification = ({ notification }: Props) => {
  const { PostReadNotificationById, GetNotifySummary } = useActions(notificationActions)
  const { metadata } = useAppSelector(state => state.profile)
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded)
  }

  async function handleReadNotify() {
    try {
      if (notification.status === NotificationStatus.NEW) {
        await PostReadNotificationById({ id: notification.id })
        await GetNotifySummary({ firstLoginTime: metadata.firstSesstionTrackedTimestamp })
      }
    } catch (error) {
      console.error('Failed to mark notification as read:', error)
    }
  }

  const trToday = t({ id: 'notification.sideBar.card.today', comment: 'Today' })

  function handleFormatDate(createdAt: any) {
    const date = new Date(createdAt)
    const today = new Date()

    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()

    if (isToday) {
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      return `${trToday} ${hours}:${minutes}`
    } else {
      return formatDate(date)
    }
  }

  const isLongDescription = notification.description.length > 200
  const shortDesciption = isLongDescription
    ? `${notification.description.substring(0, 200)}...`
    : notification.description

  const displayedDescription = isExpanded && isLongDescription ? notification.description : shortDesciption

  return (
    <div className={style.wrapper} onClick={handleReadNotify}>
      {notification.status === NotificationStatus.NEW && <div className={style.indicator}></div>}
      <div className={style.headerWrap}>
        <div className={style.header}>
          {notification.type === NotificationType.ANNOUNCEMENT ? (
            <SVG.OtherIcons.MailNew />
          ) : (
            <SVG.OtherIcons.CloudDown />
          )}
          <span className={style.title}>{notification.title}</span>
        </div>
        <span className={style.date}>{handleFormatDate(notification.createdAt)}</span>
      </div>
      <span
        className={clsx(style.description, {
          [style.expanded]: isExpanded,
        })}
      >
        {displayedDescription}
      </span>
      {isLongDescription && (
        <div className={style.toggleButton} onClick={toggleExpansion}>
          {isExpanded
            ? t({ id: 'notification.sideBar.card.hide', comment: 'Hide' })
            : t({ id: 'notification.sideBar.card.detailed', comment: 'Detailed' })}
        </div>
      )}
    </div>
  )
}
