import { FC } from 'react'
import { t } from '@lingui/macro'
import { Button } from '@/ui/kit'

import style from '../../style.module.scss'
import { SVG } from '@/assets/svg'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'

export const Address: FC = () => {
  const navigate = useNavigate()

  return (
    <div className={style.setting}>
      <SVG.Settings.Address className={style.sectionIcon} />
      <div>
        <div className={style.settingTitle}>
          {t({ id: 'profile.address.titleDefault', message: `Withdrawal Address` })}
        </div>
        <div className={style.settingStatus}>
          {t({
            id: 'profile.address.descriptionDefault',
            message: 'Easily access your saved addresses for withdrawals.',
          })}
        </div>
      </div>
      <div className={style.settingActions}>
        <Button.Primary
          label={t({ message: 'Manage', id: 'profile.address.getAddress' })}
          onClick={() => navigate(urls.addresses)}
        />
      </div>
    </div>
  )
}
