import clsx from 'clsx'
import React, { memo, ReactNode, useCallback } from 'react'
import { SVG } from '../../../assets/svg/'
import style from './style.module.scss'

export interface ChipProps {
  className?: string
  value: ReactNode
  origin?: unknown
  onRemove?: (value: ReactNode | unknown) => void
}

const ChipComponent = (props: ChipProps) => {
  const { value, origin, onRemove, className } = props

  const handleClickRemove = useCallback(() => {
    onRemove?.(origin || value)
  }, [onRemove, origin, value])

  return (
    <div className={clsx(className, style.component)}>
      <div className={style.inner}>
        {value}
        {onRemove && (
          <span onClick={handleClickRemove}>
            <SVG.Additional.Close className={style.close} height="8" width="8" />
          </span>
        )}
      </div>
    </div>
  )
}

export const Chip = memo(ChipComponent)
