import { Trans } from '@lingui/react'
import style from '../style.module.scss'
import { Button, ButtonSize } from '@/ui/kit'
import { t } from '@lingui/macro'
import { FC, useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import { E_AmplitudeEventName } from '@/redux/profile/profile.types'

type KycStatusUnverifiedProps = {
  handleNavigateToKyc: () => void
}
const KycStatusUnverified: FC<KycStatusUnverifiedProps> = ({ handleNavigateToKyc }) => {
  useEffect(() => {
    amplitude.track(E_AmplitudeEventName.RetryPopupViewed)
  }, [])

  return (
    <div>
      <div className={style.title}>
        <Trans
          id="kyc.redirectTitle"
          components={{
            gradientBlock: <span className={style.colorGradient} />,
          }}
        />
      </div>
      <div className={style.description}>
        <Trans
          id="kyc.redirectDescription"
          components={{
            br: <br />,
          }}
        />
      </div>
      <Button.Accent
        label={t({ id: 'kyc.verify', comment: 'Verify me' })}
        onClick={handleNavigateToKyc}
        className={style.btn}
        size={ButtonSize.Large}
      />
    </div>
  )
}
export { KycStatusUnverified }
