import { Discord } from './Discord'
import { Telegram } from './Telegram'
import { Twitter } from './Twitter'
import { Youtube } from './Youtube'

export const Social = {
  Discord: Discord,
  Twitter: Twitter,
  Telegram: Telegram,
  Youtube: Youtube,
}
