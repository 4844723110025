import { useLayoutEffect } from 'react'
import { PreventScrollOptions, preventScroll } from '../lib/preventScroll/preventScroll'

const DEFAULT_PREVENT_SCROLL_OPTIONS: PreventScrollOptions = {
  isDisabled: true,
}

export function usePreventScroll(options: PreventScrollOptions = DEFAULT_PREVENT_SCROLL_OPTIONS): void {
  const { isDisabled } = options

  useLayoutEffect(() => {
    if (!isDisabled) {
      return
    }

    const restore = preventScroll(options)
    return () => restore()
  }, [isDisabled])
}
