import { axiosMainService } from '@/services/axios'
import {
  UserKYC,
  ResponseFormKYC,
  UpdateFormPayloadKYC,
  CountriesKYC,
  KYCLimitsDTO,
  KYCAvailableLimitsDTO,
  WorkflowKYC,
  IdentityVerificationTokenKYC,
  TUserKYCLinkDTO,
  UserKYCLinkStatusDTO,
} from '@/backend/models/KycDTO'

const kycProtectedBaseUrl = 'broker-kyc/protected/api'

export const KycAPI = {
  async getUserKYC() {
    return await axiosMainService.get<UserKYC>(`${kycProtectedBaseUrl}/v3/kyc`)
  },

  async getUserKYCLink() {
    return await axiosMainService.get<TUserKYCLinkDTO>(`${kycProtectedBaseUrl}/v3/exchange/kyc`)
  },

  async getUserKYCLinkStatus() {
    return await axiosMainService.get<UserKYCLinkStatusDTO>(`${kycProtectedBaseUrl}/v3/exchange/kyc/status`)
  },

  async updateFormKYC(payload: UpdateFormPayloadKYC) {
    return await axiosMainService.post<ResponseFormKYC>(`${kycProtectedBaseUrl}/v2/forms`, payload)
  },

  async getFormKYC() {
    return await axiosMainService.get<ResponseFormKYC>(`${kycProtectedBaseUrl}/v1/forms`)
  },

  async updateBeneficialOwnerStatusKYC(payload: boolean) {
    return await axiosMainService.put<ResponseFormKYC>(`${kycProtectedBaseUrl}/v1/forms/owner_status?status=${payload}`)
  },

  async getCountriesKYC() {
    return await axiosMainService.get<CountriesKYC>(`${kycProtectedBaseUrl}/v1/dictionaries/countries`)
  },

  async getLimitsKYC(payload?: string) {
    return await axiosMainService.get<KYCLimitsDTO>(
      `${kycProtectedBaseUrl}/v1/limits${payload ? '?tier=' + payload : ''}`
    )
  },

  async getAvailableLimitsKYC(payload?: string) {
    return await axiosMainService.get<KYCAvailableLimitsDTO>(
      `binance-broker-transfer/protected/api/v1/limits/available?currency=${payload || 'CHF'}`
    )
  },

  async getWorkflowKYC() {
    return await axiosMainService.get<WorkflowKYC>(`${kycProtectedBaseUrl}/v1/workflow`)
  },

  async initWorkflowKYC() {
    return await axiosMainService.post<IdentityVerificationTokenKYC>(`${kycProtectedBaseUrl}/v3/workflow/init`)
  },

  async getSumsubToken() {
    return await axiosMainService.get<{
      token: string | null
    }>(`${kycProtectedBaseUrl}/v2/sumsub/token`)
  },
}
