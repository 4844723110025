import { RiskEvent } from '@/redux/riskManager/riskManager.types'
import { t } from '@lingui/macro'
import { ReactNode, useMemo } from 'react'
import style from '../style.module.scss'

export const useReasonMessage = (event: RiskEvent): ReactNode => {
  const reasonMessage = useMemo<ReactNode>(() => {
    if (!event.reason) return ''
    const postfix = (
      <span className={style.nowrap}>
        {event.reason.drawdownValueFormatted} {event.reason.drawdownType === 'percent' ? '%' : 'USDT'}
      </span>
    )

    let text =
      event.reason.sign === 'less'
        ? t({ id: 'riskManager.event.signLess', comment: 'Дневная просадка' })
        : event.reason.sign === 'greater'
        ? t({ id: 'riskManager.event.signGreater', comment: 'Дневная просадка' })
        : t({ id: 'riskManager.event.signEqual', comment: 'Дневная просадка' })

    return (
      <>
        {text} {postfix}
      </>
    )
  }, [event])
  return reasonMessage
}
