import { Decline } from './Decline'
import { Deposit } from './Deposit'
import { TT } from './TT'
import { TTSquare } from './TTSquare'
import { Transfer } from './Transfer'
import { Withdraw } from './Withdraw'

export const CryptoIcons = {
  Deposit,
  Withdraw,
  Transfer,
  Decline,
  TT,
  TTSquare,
}
