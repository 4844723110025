import { FC } from 'react'

interface BinanceSquareProps {
  className?: string
  width?: number
  height?: number
}

export const BinanceSquare: FC<BinanceSquareProps> = ({ className, width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="0.5" y="0.000854492" width="20" height="20" rx="6" fill="#FFCE51" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6982 7.36392L8.75634 9.22284L7.62643 8.14115L10.6982 5.20038L13.771 8.14213L12.6411 9.22382L10.6982 7.36392ZM11.8463 9.98659H11.8467L10.7002 8.88894L9.85283 9.70012H9.85275L9.75546 9.79334L9.55462 9.98561L9.55304 9.98712L9.55462 9.98871L10.7002 11.0854L11.8467 9.98772L11.8473 9.98712L11.8463 9.98659ZM14.5689 11.0688L15.6989 9.9871L14.569 8.90543L13.439 9.98716L14.5689 11.0688ZM10.699 12.6098L8.75711 10.7508L7.62555 11.8311L7.62713 11.8326L10.699 14.7733L13.7718 11.8316L13.7723 11.831L12.6418 10.7499L10.699 12.6098ZM7.95961 9.98691L6.82972 8.90523L5.69977 9.98696L6.82966 11.0686L7.95961 9.98691Z"
        fill="#011119"
      />
    </svg>
  )
}
