import nameof from 'ts-nameof.macro'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import {
  ApiKeysAPI,
  TGetRiskManagerDTO,
  TRiskManagerAvailableDTO,
  TSetRiskManagerDTO,
  TUpdateRiskManagerDTO,
} from '@/backend/api/apiKeyAPI'
import {
  RiskEventByIdDTO,
  TRiskManagerEventsDTO,
  TRiskManagerTradingStatus,
  TVoluntaryBlock,
} from '@/redux/riskManager/riskManager.types'

export const GetRiskManagerTC = createAsyncThunk<TGetRiskManagerDTO, void, ThunkError>(
  nameof(ApiKeysAPI.getRiskManagerSettings),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.getRiskManagerSettings, payload }, thunkAPI)
  }
)

export const SetRiskManagerTC = createAsyncThunk<void, TSetRiskManagerDTO, ThunkError>(
  nameof(ApiKeysAPI.postEnsableRiskManager),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.postEnsableRiskManager, payload }, thunkAPI)
  }
)

export const UpdateRiskManagerTC = createAsyncThunk<void, TUpdateRiskManagerDTO, ThunkError>(
  nameof(ApiKeysAPI.putUpdateRiskManagerSettings),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.putUpdateRiskManagerSettings, payload }, thunkAPI)
  }
)

export const DeleteRiskManagerTC = createAsyncThunk<void, void, ThunkError>(
  nameof(ApiKeysAPI.deleteDisableRiskManager),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.deleteDisableRiskManager, payload }, thunkAPI)
  }
)

export const GetRiskManagerTradingStatusTC = createAsyncThunk<TRiskManagerTradingStatus, void, ThunkError>(
  nameof(ApiKeysAPI.getRiskManagerTradingStatus),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.getRiskManagerTradingStatus, payload }, thunkAPI)
  }
)

export const GetRiskManagerEventsTC = createAsyncThunk<
  TRiskManagerEventsDTO,
  { page: number; size: number },
  ThunkError
>(nameof(ApiKeysAPI.getRiskManagerEvents), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: ApiKeysAPI.getRiskManagerEvents, payload }, thunkAPI)
})

export const GetRiskManagerAvailableTC = createAsyncThunk<TRiskManagerAvailableDTO, void, ThunkError>(
  nameof(ApiKeysAPI.getRiskManagerAvailabe),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.getRiskManagerAvailabe, payload }, thunkAPI)
  }
)

export const GetRiskManagerEventByIdTC = createAsyncThunk<RiskEventByIdDTO, string, ThunkError>(
  nameof(ApiKeysAPI.getRiskManagerEventById),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.getRiskManagerEventById, payload }, thunkAPI)
  }
)

export const GetVoluntaryBlockTC = createAsyncThunk<any, TVoluntaryBlock, ThunkError>(
  nameof(ApiKeysAPI.getVoluntaryBlock),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.getVoluntaryBlock, payload }, thunkAPI)
  }
)
