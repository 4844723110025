import React from 'react'

export const BackArrow = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7382 14.9927L18.1925 11.2882C18.565 10.9784 18.6158 10.4253 18.306 10.0528C17.9962 9.68025 17.443 9.62942 17.0705 9.93923L11.8069 14.3169C11.3857 14.6672 11.3851 15.3136 11.8056 15.6648L17.0692 20.0598C17.4411 20.3703 17.9944 20.3206 18.3049 19.9487C18.6154 19.5767 18.5657 19.0235 18.1938 18.713L13.7382 14.9927Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
