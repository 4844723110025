import React from 'react'

export const User = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0002 10.501C17.5606 10.501 15.583 12.4786 15.583 14.9181C15.583 17.3576 17.5606 19.3352 20.0002 19.3352C22.4397 19.3352 24.4173 17.3576 24.4173 14.9181C24.4173 12.4786 22.4397 10.501 20.0002 10.501ZM17.083 14.9181C17.083 13.307 18.3891 12.001 20.0002 12.001C21.6112 12.001 22.9173 13.307 22.9173 14.9181C22.9173 16.5292 21.6112 17.8352 20.0002 17.8352C18.3891 17.8352 17.083 16.5292 17.083 14.9181Z"
        fill="currentColor"
      />
      <path
        d="M19.9484 21.4227C17.6864 21.4228 15.8415 22.229 14.4574 23.5884C13.0857 24.9355 12.2026 26.7884 11.7665 28.8446C11.6805 29.2498 11.9393 29.6479 12.3445 29.7339C12.7497 29.8198 13.1479 29.561 13.2338 29.1558C13.6253 27.3105 14.3973 25.7498 15.5084 24.6585C16.6071 23.5796 18.0725 22.9228 19.9484 22.9227C21.8244 22.9227 23.2898 23.5795 24.3884 24.6585C25.4995 25.7497 26.2716 27.3105 26.663 29.1559C26.749 29.5611 27.1471 29.8199 27.5523 29.7339C27.9575 29.648 28.2163 29.2498 28.1304 28.8446C27.6942 26.7884 26.8111 24.9354 25.4395 23.5883C24.0553 22.2289 22.2104 21.4227 19.9484 21.4227Z"
        fill="currentColor"
      />
    </svg>
  )
}
