import React from 'react'

export const QuestionWithBorder = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1025 19.1034C16.6381 19.1034 16.9059 18.7622 16.9846 18.2131C17.0476 17.4559 17.2997 17.0565 18.3314 16.4241C19.4026 15.7501 20.0012 14.8764 20.0012 13.6033C20.0012 11.7228 18.5362 10.4414 16.4018 10.4414C14.7793 10.4414 13.5506 11.1404 13.0623 12.272C12.9127 12.5882 12.8418 12.896 12.8418 13.2538C12.8418 13.8113 13.1647 14.1608 13.7082 14.1608C14.1414 14.1608 14.4328 13.9528 14.606 13.4369C14.8581 12.5965 15.4409 12.1389 16.3152 12.1389C17.2839 12.1389 17.9534 12.7712 17.9534 13.6782C17.9534 14.477 17.6226 14.9347 16.646 15.5587C15.6614 16.1745 15.1731 16.8568 15.1731 17.8885V18.0466C15.1731 18.6541 15.5039 19.1034 16.1025 19.1034ZM16.1183 22.7146C16.7562 22.7146 17.2603 22.1904 17.2603 21.5331C17.2603 20.8757 16.7562 20.3598 16.1183 20.3598C15.4803 20.3598 14.9683 20.8757 14.9683 21.5331C14.9683 22.1904 15.4803 22.7146 16.1183 22.7146Z"
        fill="#37363E"
        stroke="#37363E"
        strokeWidth="0.5"
      />
      <rect
        x="1"
        y="1.15625"
        width="30.8432"
        height="30.8432"
        rx="10"
        stroke="url(#paint0_linear_6255_314342)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <rect
        x="1"
        y="1.15625"
        width="30.8432"
        height="30.8432"
        rx="10"
        stroke="#A39BC3"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6255_314342"
          x1="-3.33785"
          y1="33.4243"
          x2="38.3351"
          y2="-18.2987"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.254793" stopColor="#12101A" />
          <stop offset="0.966484" stopColor="#453D63" />
          <stop offset="0.977282" stopColor="#100E17" />
        </linearGradient>
      </defs>
    </svg>
  )
}
