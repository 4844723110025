import { memo } from 'react'
import { Link } from 'react-router-dom'
import s from './style.module.scss'

interface Props {
  /** text */
  text: string

  /** url */
  href: string

  dataTestId?: string
  external?: boolean
}

export const MobileFooterLink = memo<Props>(({ text, href, dataTestId, external = false }) => {
  if (external)
    return (
      <a className={s.link} href={href} target="_blank" rel="noreferrer" data-testid={dataTestId}>
        <span>{text}</span>
      </a>
    )

  return (
    <Link className={s.link} to={href} data-testid={dataTestId}>
      <span>{text}</span>
    </Link>
  )
})
