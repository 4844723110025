import { getScrollParent } from './getScrollParent'

export function scrollIntoView(target: Element): void {
  let root = document.scrollingElement || document.documentElement

  while (target && target !== root) {
    let scrollable = getScrollParent(target)

    if (scrollable !== document.documentElement && scrollable !== document.body && scrollable !== target) {
      let scrollableTop = scrollable.getBoundingClientRect().top
      let targetTop = target.getBoundingClientRect().top

      if (targetTop > scrollableTop + target.clientHeight) {
        scrollable.scrollTop += targetTop - scrollableTop
      }
    }

    target = scrollable.parentElement!
  }
}
