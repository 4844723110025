import { FC } from 'react'
import { t } from '@lingui/macro'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { VoucherAddForm } from '@/ui/molecules/VoucherAddForm'
import { CashbackWidget } from '@/ui/organisms/CashbackWidget'
import { urls } from '@/router/urls'
import s from './style.module.scss'
import { useExchageType } from '@/utils/hooks/useExchangeType'

interface IVoucherLeftBarProps {}

const VoucherLeftBar: FC<IVoucherLeftBarProps> = props => {
  const { exchangeType } = useExchageType()

  return (
    <div className={s.root}>
      <TitleH1 label={t({ message: 'Voucher', id: 'voucher.heading' })} />
      <VoucherAddForm />
      <CashbackWidget
        customOptions={
          exchangeType !== 'BINANCE_VIP'
            ? {
                button: {
                  text: t({ id: 'tigerToken.cashbackDetails', message: 'Cashback details' }),
                  link: urls.cashback,
                },
              }
            : undefined
        }
      />
    </div>
  )
}

export { VoucherLeftBar }
