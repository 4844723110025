import React from 'react'

interface BybitProps {
  className?: string
}

const Bybit: React.FC<BybitProps> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="40" height="40" rx="20" fill="#1C1A24" />
      <path d="M25.1058 22.1678V15.9997H26.3458V22.1678H25.1058Z" fill="#F7A600" />
      <path
        d="M10.6576 23.9994H8V17.8322H10.551C11.79 17.8322 12.5132 18.5084 12.5132 19.5649C12.5132 20.2497 12.0493 20.6906 11.7275 20.8385C12.1108 21.0114 12.6016 21.4013 12.6016 22.2244C12.6016 23.3751 11.79 23.9994 10.6576 23.9994ZM10.453 18.907H9.23899V20.3275H10.453C10.9794 20.3275 11.2742 20.0413 11.2742 19.6168C11.2742 19.1923 10.9794 18.907 10.453 18.907ZM10.5327 21.41H9.23899V22.9256H10.5318C11.0946 22.9256 11.3616 22.5788 11.3616 22.163C11.3616 21.7471 11.0936 21.41 10.5327 21.41Z"
        fill="white"
      />
      <path
        d="M16.3829 21.4695V23.9994H15.1516V21.4695L13.2432 17.8322H14.5907L15.7759 20.3179L16.9448 17.8322H18.2913L16.3829 21.4695Z"
        fill="white"
      />
      <path
        d="M21.8057 23.9994H19.1481V17.8322H21.6991C22.9381 17.8322 23.6613 18.5084 23.6613 19.5649C23.6613 20.2497 23.1974 20.6906 22.8756 20.8385C23.2588 21.0114 23.7496 21.4013 23.7496 22.2244C23.7496 23.3751 22.939 23.9994 21.8057 23.9994ZM21.6011 18.907H20.388V20.3275H21.6011C22.1274 20.3275 22.4223 20.0413 22.4223 19.6168C22.4213 19.1923 22.1265 18.907 21.6011 18.907ZM21.6808 21.41H20.388V22.9256H21.6808C22.2436 22.9256 22.5106 22.5788 22.5106 22.163C22.5106 21.7471 22.2427 21.41 21.6808 21.41Z"
        fill="white"
      />
      <path d="M30.3413 18.907V24.0003H29.1013V18.907H27.4426V17.8322H32V18.907H30.3413Z" fill="white" />
    </svg>
  )
}

export { Bybit }
