import React from 'react'
import clsx from 'clsx'
import s from './style.module.scss'

//todo: add tp stprybook
export interface ICardWrapperProps {
  /*Children node*/
  children: React.ReactNode
  /*Class for wrapper*/
  className?: string
}

export const CardWrapper: React.FC<ICardWrapperProps> = props => {
  const { children, className } = props
  return <div className={clsx(s.wrapper, className)}>{children}</div>
}

CardWrapper.displayName = 'CardWrapper'
