import { t } from '@lingui/macro'
import clsx from 'clsx'
import { SVG } from '@/assets/svg'
import { getTtLinksByLocale } from '@/router/links'
import style from './styles.module.scss'
import { Button } from '../../kit'
import { FC } from 'react'
import { useLanguageContext } from '@/providers/LanguageProvider'

const ApiKeyTradeBanner: FC = () => {
  const { locale } = useLanguageContext()
  return (
    <div className={style.root}>
      <div className={style.title}>
        {t({
          id: 'tradeBanner.title',
          message: 'Trade all assets with one tool',
        })}
      </div>
      <a href={getTtLinksByLocale('tt_windows', locale)} target="_blank" rel="noreferrer">
        <Button.White
          leftIcon={<SVG.OtherIcons.Windows round className={clsx(style.windows, style.icon)} />}
          label={t({
            id: 'tradeBanner.windows',
            message: 'About Windows platform',
          })}
          className={style.button}
        />
      </a>
      <a href={getTtLinksByLocale('tt_macos', locale)} target="_blank" rel="noreferrer">
        <Button.White
          leftIcon={<SVG.OtherIcons.Apple className={style.icon} />}
          label={t({
            id: 'tradeBanner.macos',
            message: 'About macOS platform',
          })}
          className={style.button}
        />
      </a>
    </div>
  )
}

export { ApiKeyTradeBanner }
