import { FC } from 'react'
import style from './style.module.scss'
import clsx from 'clsx'
import { QuestionButton } from '@/ui/atoms/QuestionButton'

type DashboardWidgetItemProps = {
  value?: string | number
  text?: string
  sourceValue?: number
  isCountPercent?: boolean
  hintText?: string
  className?: string
}

export const DashboardWidgetItem: FC<DashboardWidgetItemProps> = ({
  isCountPercent,
  value,
  sourceValue = 0,
  text,
  hintText,
  className,
}) => {
  let percentClassName
  if (isCountPercent) {
    percentClassName = clsx({
      [style.positive]: sourceValue > 0,
      [style.negative]: sourceValue < 0,
      [style.withoutPercentValue]: !sourceValue,
    })
  }
  return (
    <div className={clsx(style.widgetItem, className)}>
      <div className={clsx(style.result, percentClassName && { [percentClassName]: isCountPercent })}>{value}</div>
      <div className={style.text}>
        {text}
        {hintText && <QuestionButton text={hintText} />}
      </div>
    </div>
  )
}
