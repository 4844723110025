import clsx from 'clsx'
import { useCallback, useState, MouseEvent, FC } from 'react'
import { SVG } from '@/assets/svg'
import { typedMemo, useOutsideClick } from '@/utils'
import style from './style.module.scss'

export type DropDownItem<T extends string = string> = {
  icon?: FC<{ [key: string]: any }>
  label: string
  value: T
}

type DropDownProps<T extends string = string> = {
  /** initially selectedOption */
  selectedOption: DropDownItem<T> | null

  /** items to display */
  options: DropDownItem<T>[]

  /** onchange handler */
  setSelectedOption: (option: Omit<DropDownItem<T>, 'icon'>) => void

  size?: 'small' | 'medium' | 'large'

  variant?: 'dark' | 'light'

  placeholder?: string

  hideArrow?: boolean

  showSelectedItemIcon?: boolean

  showDropdownItemIcon?: boolean

  position?: 'right' | 'left' | 'center'

  /** CSS classname */
  className?: string

  classNameToggle?: string

  dataTestId?: string
}

/** Dropdown, f.e. can act as Select for filters */
export const Dropdown = typedMemo(<T extends string = string>(props: DropDownProps<T>) => {
  const {
    options,
    className,
    setSelectedOption,
    selectedOption,
    size = 'medium',
    variant = 'dark',
    dataTestId,
    placeholder = '',
    hideArrow = false,
    position = 'left',
    showSelectedItemIcon = false,
    showDropdownItemIcon = false,
    classNameToggle,
  } = props

  const [isOpen, setIsOpen] = useState(false)

  const ref = useOutsideClick<HTMLDivElement>(() => setIsOpen(false))

  const toggle = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setIsOpen(!isOpen)
    },
    [isOpen]
  )

  const handleItemClick = useCallback(
    (item: Omit<DropDownItem<T>, 'icon'>) => (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setSelectedOption(item)
      setIsOpen(false)
    },
    [setSelectedOption]
  )

  // Иконка выбранного элемента
  const SelectedIcon = selectedOption?.icon

  return (
    <div
      className={clsx(style.root, style[size], style[variant], className, {
        [style.open]: isOpen,
        [style.left]: position === 'left',
        [style.right]: position === 'right',
        [style.center]: position === 'center',
      })}
      ref={ref}
      data-testid={dataTestId}
    >
      <div onClick={toggle} className={clsx(style.toggle, classNameToggle)}>
        <div className={style.label}>
          {showSelectedItemIcon && !!SelectedIcon && <SelectedIcon />}
          <span>{selectedOption?.label ?? placeholder}</span>
        </div>

        {!hideArrow && <SVG.Arrows.ArrowDown className={style.arrow} />}
      </div>

      <div className={style.dropdown}>
        {options.map(({ icon: Icon, ...item }, index) => (
          <div
            key={item.label}
            onClick={handleItemClick(item)}
            className={clsx(style.item, { [style.active]: selectedOption?.label === item.label })}
            data-testid={`${dataTestId}-${index}`}
          >
            {showDropdownItemIcon && !!Icon && <Icon />}
            <span>{item.label}</span>
          </div>
        ))}
      </div>
    </div>
  )
})
