import React, { FC, Suspense } from 'react'
import { Loader } from '@/ui/kit/Loader'
import { useAppSelector } from '@/utils'
import { TableError } from './components/TableError/TableError'

const HistoryTradesPage = React.lazy(() => import('./HistoryTrades'))

export const HistoryTradesLoader: FC = () => {
  const { availableUsersTradingStatus, availableUsersTrading } = useAppSelector(state => state.trades)
  if (availableUsersTradingStatus === 'succeeded' && !availableUsersTrading?.isStatisticAvailable)
    return <TableError technicalError={true} />
  if (availableUsersTradingStatus !== 'succeeded') return <Loader />
  return (
    <Suspense fallback={<Loader />}>
      <HistoryTradesPage />
    </Suspense>
  )
}
