import { FC, useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { t } from '@lingui/macro'
import { Toggle } from '@/ui/kit'
import { useActions, useAppSelector } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'
import style from '../../style.module.scss'
import { IconFactory } from '@/assets/svg/cryptoIcons/IconFactory'

interface Props {
  disabled?: boolean
}

const BNBConvert: FC<Props> = ({ disabled }) => {
  const { GetBNBDustTC, UpdateBNBDustTC } = useActions(profileActions)
  const { bnbDustEnabled } = useAppSelector(state => state.profile)

  const [active, setActive] = useState(bnbDustEnabled)

  useEffect(() => {
    GetBNBDustTC()
  }, [GetBNBDustTC])

  useEffect(() => {
    if (typeof active === 'undefined' && typeof bnbDustEnabled !== 'undefined') {
      setActive(bnbDustEnabled)
    }
  }, [active, bnbDustEnabled])

  const handleBNBBurnChange = async (active: boolean) => {
    setActive(active)

    const response = await UpdateBNBDustTC({ enabled: active })

    if ('error' in response) {
      setActive(!active)

      enqueueSnackbar(t({ id: 'profile.bnbDustError', message: 'Error saving setting' }), { variant: 'error' })
    } else {
      enqueueSnackbar(t({ id: 'profile.bnbDustSuccess', message: 'Convert Small Balance to BNB setting changed' }), {
        variant: 'success',
      })
    }
  }

  return (
    <div className={style.setting}>
      {disabled && <div className={style.disabled} />}
      <IconFactory type="BNB" width="40" height="40" className={style.sectionIcon} />
      <div>
        <div className={style.settingTitle}>
          {t({ id: 'profile.bnbConvert', message: `Convert Small Balance to BNB` })}
        </div>
        <div className={style.settingStatus}>
          {t({
            id: 'profile.bnbConvertDescription',
            message:
              'You can convert balances with a valuation below 0.0012 BTC to BNB once every 6 hours. It is not currently possible to convert delisted coins.',
          })}
        </div>
      </div>
      <div className={style.settingActions}>
        {typeof active !== 'undefined' && <Toggle active={active} onChange={handleBNBBurnChange} />}
      </div>
    </div>
  )
}

export { BNBConvert }
