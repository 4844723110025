import React, { useState } from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'

export type LabelOption = {
  label: string
  value: string
}

type ToggleButtonProps = {
  option1: LabelOption
  option2: LabelOption
  defaultSelected: LabelOption
  onChange: (selectedValue: LabelOption) => void
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ option1, option2, defaultSelected, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelected ? defaultSelected : option1)

  const handleToggle = (selectedOption: LabelOption) => {
    setSelectedValue(selectedOption)
    if (onChange) {
      onChange(selectedOption)
    }
  }

  return (
    <div className={styles.container}>
      <button
        className={clsx(styles.toggleButton, { [styles.active]: selectedValue.value === option1.value })}
        onClick={() => handleToggle(option1)}
      >
        {option1.label}
      </button>
      <button
        className={clsx(styles.toggleButton, { [styles.active]: selectedValue.value === option2.value })}
        onClick={() => handleToggle(option2)}
      >
        {option2.label}
      </button>
    </div>
  )
}
