import { TAccountType, TInstrumentType } from '@/core/types/overview'
import { EBybitInstruments } from '@/services/bybit/types'
import { EOkxInstruments } from '@/services/okx'
import { EAccountType } from '@tigertrade/binance-ts'

/** Helper to have all names of accounts in one place */
export const instrumentNames: Record<TInstrumentType, { name: string; id: TInstrumentType }> = {
  USDT_FUTURE: {
    id: EAccountType.USDT_FUTURE,
    name: 'Futures USDⓈ-M',
  },
  COIN_FUTURE: {
    id: EAccountType.COIN_FUTURE,
    name: 'Futures COIN-M',
  },
  SPOT: {
    id: EAccountType.SPOT,
    name: 'Spot',
  },
  SWAP: {
    id: EOkxInstruments.SWAP,
    name: 'Swap',
  },
  spot: {
    id: EBybitInstruments.spot,
    name: 'Spot',
  },
  linear: {
    id: EBybitInstruments.linear,
    name: 'Perpetual USDⓈ',
  },
}

export const accountNames: Record<TAccountType, { name: string; id: TAccountType }> = {
  BINANCE_SPOT: {
    id: 'BINANCE_SPOT',
    name: 'Spot',
  },
  BINANCE_FUTURE: {
    id: 'BINANCE_FUTURE',
    name: 'Futures USDⓈ-M',
  },
  BINANCE_FUTURE_COIN: {
    id: 'BINANCE_FUTURE_COIN',
    name: 'Futures COIN-M',
  },
  OKX_TRADING: {
    id: 'OKX_TRADING',
    name: 'Trading',
  },
  BYBIT_UNIFIED: {
    id: 'BYBIT_UNIFIED',
    name: 'Unified Trading Account',
  },
}
