import { FC } from 'react'

export const TigerBackground = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 572.5 408.2"
    className="enable-background:new 0 0 572.5 408.2;"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g className="st0">
      <path
        d="M570.2,343.8c-1.5-1.3-3.1-2.5-4.4-4c-3.8-4.4-8.4-7.6-14-9.5c-7.3-2.4-13.6-6.8-20.2-10.4c-1.5-0.8-2.7-2-4-3.2
		c-7.1-7-15.1-12.4-25.3-13.8c-1.8-0.3-3.5-0.9-5.3-1.3c-0.3-0.1-0.6-0.2-0.9-0.5c6.3,0.8,12.3-1.4,18.4-2
		c7.1-0.7,14.4-0.4,21.6-0.7c2.3,0,2.9-0.4,2.3-2.6c-0.6-2.4-1.4-5-2.9-6.8c-3.4-3.8-7.1-7.5-11.2-10.6
		c-7.3-5.6-14.4-11.3-20.2-18.5c-0.9-1.1-1.7-2.1-2.4-3.3c-2.5-4.8-6.4-7.9-11.1-10.5c-2.8-1.6-5.4-3.4-8-5.3
		c-6.8-5-14.3-8.4-22.5-10.1c-4.9-1-9.6-2.5-14-4.9c-5.2-2.7-10.9-3.6-16.6-4.6c-6.4-1.2-12.4-1.1-18.7,0.6c-3.9,1.1-8.1,1.1-12,2
		c-2.8,0.7-5.5,1.9-8,3.2c-1.3,0.6-2.2,2-3.6,3.3c2.4,1.2,4.4,2.4,6.6,3.1c6.9,2.3,14,4.4,20.9,6.7c3.3,1.1,6.5,2.3,9.7,3.6
		c5.1,2.1,10,4.4,15.1,6.6c4.8,2,9.1,4.7,12.2,8.9c4,5.2,9.1,8.9,15.4,10.9c5.1,1.6,9.1,4.9,11.3,9.8c3.1,6.8,5.8,13.8,8.7,20.9
		c-5.5-5.1-10.5-10.1-15.9-14.7c-6.8-5.8-14.4-10.4-22.3-14.6c-5.2-2.8-10.9-3.6-16.7-4.2c-3.8-0.4-7.3-1.6-10.3-4
		c-2.1-1.7-4.6-2.6-7.3-2.6c-2.7,0-5.2-1-7.3-2.7c-3.1-2.5-6.6-3.6-10.5-3.9c-2.7-0.2-5.4-0.9-7.8-2c-3.6-1.8-7.4-2.6-11.4-2.7
		c-11.1-0.2-21.6,2.9-31.8,7.1c-7.7,3.2-15.5,5.8-23.6,7.5c-3.9,0.8-7.9,2.3-11.3,4.4c-19.6,12.1-39.6,23.6-58.1,37.4
		c-6.2,4.7-12.1,9.7-18.5,14.1c-5.8,4-12.1,7.6-18.2,11.1c-2,1.1-4.4,1.8-6.7,2.3c-4.2,0.8-8,2.5-11.1,5.3
		c-5.7,5.1-12.4,8.3-19.9,9.5c-5.1,0.9-9.5,3.3-13.1,7c-3.1,3.3-6.6,6.3-9.6,9.7c-3.7,4.2-8.4,6.4-12.5,8.5
		c5.8-8.3,12-17.1,18.2-25.9c5.8-8,13.2-13.5,23.3-14.9c4.4-0.6,8.1-2.7,11.1-6c2.3-2.6,5.3-4,8.8-4.3c4.1-0.3,7.6-1.9,10.9-4.5
		c3.2-2.6,6.7-4.8,10.3-7c11.6-7.1,21.8-15.8,29.4-27.2c8.5-12.9,20.4-21.3,34.3-27.5c11.1-5,21.8-10.6,32.8-15.9
		c5.3-2.6,10.8-4.7,16.2-7.3c4.9-2.3,9.1-5.4,12.4-9.8c2.9-3.8,6.7-6.1,11.2-7.3c2.6-0.7,5.2-1.4,7.8-2.2c4.9-1.4,9.3-3.8,12.5-8
		c5.7-7.5,14.3-6.9,20-2.5c6.8,5.3,14.7,6,22.8,7c13.7,1.7,27.4,3.3,40.6,7.6c6.3,2,11.8,5.3,17,9.4c3.7,2.9,7.5,5.6,11.2,8.4
		c1.7,1.3,3.5,2.7,4.6,4.5c4,7.1,10,12.1,17,15.8c8.5,4.4,17.3,8.2,26,12.1c8.1,3.6,15.8,7.8,22.5,13.8c2.2,2,4,4.4,5.9,6.6
		C570.2,306.8,570.2,325.3,570.2,343.8z"
      />
      <path
        d="M-1.1,290.4c1.6,0,3.2,0,4.9-0.1c10.2-0.4,19.9,2.2,29.5,5.1c6.5,2,13.2,3.8,19.9,5.1c6.5,1.3,13.1,0.6,19.3-1.8
		c9.1-3.5,18.6-4.6,28.3-5.2c7.2-0.4,14.3-1.3,21.5-2.4c7.5-1,13.5-4.8,17.9-11c5.1-7.4,11.4-13.6,19-18.6
		c4.4-2.9,7.8-6.7,10.2-11.5c2.4-4.9,6.1-8.7,10.7-11.5c5.8-3.6,10.4-8.4,15.3-13.1c11.2-10.6,19.6-23.4,29.2-35.3
		c3.3-4.2,7.1-8,10.8-12c5.9-6.5,11.8-12.9,16.4-20.6c-0.7,2-1.5,4-2.2,6c-3.8,9.7-7.7,19.4-11.4,29.2c-2.4,6.4-4.2,13.1-6.9,19.3
		c-1.8,4.4-4.6,8.5-6.9,12.7c-2.8,5.1-4.2,10.6-4,16.4c0.3,7.5-0.6,14.9-3.2,22c-2.3,6.2-5.4,11.8-10.2,16.4
		c-5.5,5.4-10.8,11.1-16.2,16.6c-2.2,2.2-4.6,4.5-7.1,6.3c-6.2,4.3-11.2,9.6-15.8,15.5c-4.3,5.6-8.7,11.1-12.8,16.9
		c-3.8,5.2-8.5,9.3-13.6,13.1c-7.9,5.7-14.1,12.9-18.7,21.5c-5.9,10.9-14.9,18.9-25.3,25.1c-7.3,4.4-15,7.8-22.6,11.7
		c-0.8,0.4-1.7,0.9-2.5,1.3c-12.5,0-25,0-37.5,0c0.1-0.2,0.2-0.5,0.4-0.7c4.7-3.5,8.3-8,11.6-12.8c2.6-3.8,6-6.8,10.2-8.9
		c4.7-2.4,9.3-4.8,14-7.1c9.6-4.6,18.4-10.1,25.3-18.4c4-4.8,8.8-8.7,14.1-12c9.3-5.9,18.7-11.8,28.1-17.7
		c7.3-4.6,13.5-10.3,17.1-18.3c3-6.7,7.8-11.8,13.6-16.2c7.2-5.5,13.5-12,18.5-19.6c1.4-2.1,2.5-4.6,2.9-7.1
		c1.1-7.5,4-14.3,7.7-20.9c2-3.4,3.4-7.1,5.1-10.6c0.4-0.8,0.5-1.6,0.8-2.8c-1.9,3.1-3.5,5.9-5.3,8.5c-3.3,4.7-6.8,9.2-10,13.9
		c-6.7,9.5-14.8,17.5-24.4,24c-9.7,6.5-19,13.3-26.5,22.5c-3.4,4.2-7.8,7-13.4,7.4c-6.8,0.5-13.2,2.4-19.3,5.5
		c-11.6,6-24.2,6.9-36.9,6.6c-9.1-0.3-18.2-1.8-27.4-2.4c-7.1-0.4-14-1.6-20.7-3.9c-1.4-0.5-2.9-0.8-4.4-0.9
		c-5.5-0.3-11.1-0.5-16.7-0.8C-1.1,307-1.1,298.7-1.1,290.4z"
      />
      <path
        d="M570.2,251.2c-3.9-3.3-7.2-7-9.6-11.5c-3-5.9-7.6-10-13.3-13.4c-7.2-4.3-14.3-8.6-22.5-10.7c-0.1,0-0.2-0.1-0.4-0.2
		c-9.8-5.4-20.8-7.5-31.3-10.7c-4.2-1.3-8.4-2.8-12.4-4.7c-7.1-3.5-14.6-4.8-22.4-5.5c-5.3-0.5-10.7-1.1-16-1.9
		c-6.5-1-12.4-3.7-17.3-8.2c-5.9-5.4-12.6-6.6-19.8-3.5c-20.3,8.9-41.4,16.2-60,28.6c-7.1,4.7-14,9.6-21.2,14.1
		c-3.3,2.1-7.1,3.6-10.7,5.4c-0.7,0.3-1.3,0.4-2.4,0.8c3.7-6.7,7.7-12.6,12.9-17.5c5.1-4.8,10.6-9.1,15.9-13.7
		c7.1-6.2,13.2-13.3,18.3-21.3c1.4-2.2,2.4-4.9,3.2-7.5c1.7-5.3,5-8.9,9.9-11.6c12.6-6.9,26.2-10.8,40.4-12.4
		c3.8-0.4,7.5-1.1,11.2-2.2c7.5-2.2,15-2.8,22.7-1.4c2.4,0.4,5.3-0.1,7.7-1c4-1.6,8-1.8,12-0.5c9.6,3.1,19.5,3.8,29.5,4.8
		c4.9,0.5,9.7,1.2,14.5,2.2c2.7,0.5,5.5,1.4,7.8,2.9c4.4,2.8,9,4.4,14.1,5c8.3,0.9,14.7,5.1,20.1,11.3c5,5.7,10.1,11.4,15.3,17
		c1.1,1.2,2.5,2,3.8,2.9c0,3.7,0,7.3,0,11c-2-0.5-3.9-1.2-5.9-1.5c-5.4-0.9-9.7-3.6-13.7-7.2c-2.7-2.4-5.6-4.5-8.7-6.4
		c-3.8-2.3-7.8-4.2-11.6-6.4c-2.5-1.4-5.2-2.1-8-1.6c-6.4,1.1-11.7-0.7-16.4-5.1c-7.4-6.9-16.4-9.9-26.4-10.3
		c-7.5-0.3-15.1-0.4-22.6-0.4c-6.6,0-13.2-0.2-19.8,0.2c-7.3,0.4-14.1,3.3-20.9,5.8c-2.2,0.8-4.5,1.6-6.8,2.4c2.2,0.2,4.3,0,6.3-0.3
		c6.1-0.9,12.1-2,18.4-2c9.2-0.1,17.9,1.6,26.4,4.9c8,3.2,16.4,5.2,24.9,6.7c5,0.8,9.9,2.2,14.8,3.7c3.7,1.1,7.2,2.8,10.9,4
		c4.9,1.6,9.1,4,12.9,7.6c7.6,7.3,16.4,12.7,26.2,16.6c3,1.2,6.1,2.2,9.2,3.3c5.4,1.9,8.7,5.6,9.6,11.3c0.2,1.4,0.8,2.8,1.2,4.2
		C570.2,235.2,570.2,243.2,570.2,251.2z"
      />
      <path
        d="M131.1,407.9c13.5-14.6,28.9-26.7,46.1-36.6c7.5-4.4,15.4-8.3,21.6-14.5c8.7-8.8,19-14.7,30.3-19
		c14.7-5.6,26.9-14.8,38.2-25.5c6.6-6.2,13.5-12.2,20.6-17.9c9.6-7.5,20.8-11.5,32.7-13.8c8.4-1.6,16.4-4.2,24-8.1
		c6.5-3.3,13.3-5.8,20.7-6.2c3.8-0.2,7.3,0.7,10.8,2.4c2.6,1.3,5.4,2.2,8.2,3c5.1,1.4,10.2,2.6,15.3,3.9c0,0.2,0,0.4-0.1,0.6
		c-3.2,0-6.4,0-9.6,0c-10.2-0.1-20,1.6-29.7,4.4c-4.3,1.2-8.1,3.1-11.2,6.4c-4,4.3-9.1,6.6-14.8,7.5c-6,0.9-11.6,3.1-17.2,5.5
		c-8.1,3.3-16.4,6.2-24.6,9.1c-6.8,2.4-12.8,6-17.7,11.3c-9.1,9.7-19.7,17.2-31.5,23.2c-10,5-19.1,11.1-27.2,18.9
		c-5.2,5-9.4,10.7-11.7,17.6c-1,3.1-2.1,6.1-3.4,9.7c1-0.6,1.6-0.9,2.1-1.3c3.2-2.4,6.5-4.7,9.6-7.3c6.5-5.5,14.1-8.5,22.2-10.5
		c6-1.5,11.9-3.7,17.8-5.8c6.5-2.4,11.1-7.5,15.8-12.3c5.6-5.7,10.4-12,14.8-18.6c6.1-9.2,14.6-15.8,24.2-21.1
		c5.3-2.9,11.4-3.5,17.3-4c7.9-0.6,15.6-1.7,22.7-5.6c5.3-2.9,11.3-2.8,17.4-2.4c-2.1,1-4.2,1.9-6.2,3c-5.8,3.3-11.6,6.8-17.4,10.1
		c-5.1,2.9-10.4,5.5-15.6,8.2c-4,2.2-8.2,4.2-12.1,6.7c-5.6,3.5-11,7.3-16.4,10.9c-8.5,5.7-15.5,12.7-20.2,22
		c-1.6,3.2-4.2,6-6.9,8.4c-12.9,11.4-26.1,22.4-40.6,31.7c-2.7,1.7-4.9,4-7.3,6.1C191.8,407.9,161.4,407.9,131.1,407.9z"
      />
      <path
        d="M-1.1,211.2c7.9,3.4,15.9,6.7,23.6,10.4c5,2.4,9.9,3.7,15.5,3.4c4.6-0.2,9.2,0.8,13.8,1.5c7.6,1,14.9,0.2,21.3-4.3
		c9.8-6.9,20.5-11.8,31.8-15.8c14.4-5.1,25.2-15.2,34.6-27c1.8-2.3,3.7-4.5,5.5-6.8c0.4-0.4,0.9-0.8,1.8-1.5
		c-0.3,0.9-0.3,1.4-0.6,1.8c-1.2,2.1-2.2,4.2-3.6,6.1c-3.6,4.9-6.6,10.1-8.6,15.9c-1.4,4.1-4.2,7.3-7.3,10.2
		c-3.3,3.2-6.6,6.3-10,9.3c-5,4.4-10.9,7.3-17.1,9.7c-4.7,1.8-9.4,3.9-13.8,6.3c-8.6,4.7-17.7,7-27.3,7c-8.4,0-16.7-0.5-25.1-0.8
		c-2.6-0.1-5.1,0-8.3,0c1.1,1.2,1.8,2,2.5,2.7c7.5,6.3,16.4,9.8,26,11.3c6,0.9,11.8,0.6,17.8-1c2.8-0.8,6-0.8,8.8-0.2
		c5.4,1.1,10.5,0,15.6-1.3c7.2-1.9,13.2-5.9,18.6-10.9c4.7-4.3,9.9-7.8,15.3-11.1c8.6-5.3,16.8-11.1,25.2-16.6
		c4.1-2.7,8.1-5.6,12.4-7.8c14.3-7.5,23.6-19.3,29.1-34.3c1.4-3.9,2.8-7.8,4.1-11.7c2.6-7.9,7.3-13.9,14.9-17.4
		c6.6-3.1,12.4-7.3,17.3-12.7c0.5-0.6,1.2-1.1,2.2-2c-0.1,1-0.1,1.6-0.3,2c-2.4,7.3-4.4,14.9-7.3,22c-1.6,4-4,7.9-7,10.9
		c-5.3,5.2-9.5,11.1-13.5,17.3c-4,6.1-7.5,12.4-11.8,18.3c-2.7,3.8-6.4,6.9-9.6,10.4c-2.4,2.7-5.1,5.3-7,8.4
		c-4.3,7.2-10,12.8-16.8,17.5c-12,8.3-23.8,16.9-34.2,27.3c-7.3,7-16.4,10.4-25.7,13.7c-9.1,3.2-18.2,4.4-27.9,3.2
		c-6-0.7-12.1-1-18.1-1.1c-3.1,0-6.2,0.8-9.2,1.3c-1.9,0.3-3.7,0.8-5.6,1.1c-5.4,0.8-9.8-1-13.1-5.3c-1.6-2-2.9-4.3-4.3-6.4
		c-0.9-1.3-1.8-2.7-2.9-4c-3-3.7-6.8-4.9-11.5-3.7c-3.3,0.9-6.8,1.4-10.2,2.1C-1.1,243-1.1,227.1-1.1,211.2z"
      />
      <path
        d="M303.3,407.9c1.7-2.7,3.3-5.5,5.1-8.1c3.6-5.3,9-7.9,15-9.4c5-1.2,10.1-2.1,15.1-3.3c7.4-1.7,13.1-5.7,16.8-12.4
		c1.2-2.3,2.7-4.4,4-6.7c4.9-8.6,12-14.7,21-18.9c5.1-2.4,9.9-5.3,14.9-7.9c2.4-1.2,4.9-2.4,7.4-3.2c3-0.9,5.7-2.2,7.9-4.4
		c4.4-4.6,10-5.5,16.1-5.4c7.5,0.2,14.9,1.1,22.3,1.9c4.9,0.6,10.1-0.4,15.1-1.2c6.8-1,13.1,0,18.9,3.9c4.2,2.9,8.5,6,13.8,6.7
		c4.1,0.6,8.2,0.6,12.4,0.8c8.2,0.3,15.8,2.7,23.1,6.1c2.7,1.3,4.9,3.6,7.3,5.5c2.8,2.2,5.3,4.6,8.3,6.6c7.4,5,15,9.8,22.5,14.7
		c0,2.6,0,5.2,0,7.8c-7.3-1.7-14.6-3.3-22-5.1c-4.9-1.2-9.9-2.1-14.5-4c-9.1-3.7-18.1-7.5-26.7-12.1c-11.8-6.2-24.5-7.7-37.5-7.6
		c-7.2,0-14.4,1-21.5-0.7c-6.2-1.5-12.3-0.9-18.4,1.1c-3.9,1.3-8.1,1.6-12.2,2.3c-2.7,0.5-5.5,0.6-8,1.5c-11,4.1-20.9,9.9-27.9,19.6
		c-3.3,4.6-6.7,9.1-10.1,13.6c2.3-0.5,4.4-1.2,6.2-2.2c9.4-5.1,19-9.7,28.8-13.8c6.7-2.8,13.5-4.6,20.7-4.9
		c6.8-0.3,13.6-0.2,20.4-0.5c8.6-0.3,17.1-0.8,25.7-1.2c4-0.2,7.4,1.1,10.9,2.9c4,2.2,8.2,3.9,12.3,5.9c0.6,0.3,1.2,0.5,1.8,0.7
		c-1.5,0.4-2.9,0.7-4.3,0.7c-6.9-0.2-13.9-0.3-20.8-0.7c-7.4-0.4-14.8-0.4-22,2c-7,2.3-14.2,3.6-21.6,3.3
		c-6.2-0.2-12.1,1.2-17.8,3.8c-2.7,1.2-5.4,2.2-8.2,2.9c-3.8,0.9-7.1,2.5-9.7,5.3c-4.5,4.7-10,7.6-15.8,10c-2.9,1.2-5.7,2.7-8.6,4.1
		C347.4,407.9,325.3,407.9,303.3,407.9z"
      />
      <path
        d="M350.6,8c-2.2,0.7-4.4,1.5-6.7,2.2c-18.3,5.9-35.8,13.8-53.4,21.5c-7.8,3.4-15.7,6.9-23.3,10.7c-11,5.4-20.3,13.1-28,22.7
		c-8.7,10.8-17.5,21.5-27.5,31.2c-5.9,5.8-10.5,12.7-13.6,20.4c-4.4,10.8-11.5,20-17.6,29.7c-4,6.2-8.2,12.3-11.8,18.8
		c-2,3.6-2.9,7.9-4.2,11.8c-0.2,0.5-0.3,1-0.5,1.5c-0.2,0-0.3,0-0.5,0c1-10.7,2.1-21.4,2.9-32.2c0.7-10.2,4.5-19.3,9.2-28.2
		c2.7-5.3,6.6-9.6,10.9-13.7c4.3-4.1,8.1-8.6,10.8-13.9c1.1-2.3,2.8-4.3,4.3-6.4c2.4-3.4,5.1-6.6,7.1-10.2
		c5-9.2,12.1-16.5,20.4-22.7c3.5-2.7,7-5.3,10.9-8.2c-0.9-0.2-1.4-0.3-1.9-0.3c-19.5-1-37.2,4.5-53.3,15.2
		c-10,6.6-19.5,14-27.6,22.8c-3.9,4.2-7,9.3-10.3,14.1c-3.7,5.3-7.4,10.6-10.9,16c-2.3,3.5-4.2,7.3-6.2,10.9c-1,1.8-1.9,3.7-2.9,5.5
		c-0.2,0-0.4-0.1-0.7-0.2c0.1-1.1,0-2.4,0.2-3.4c2.2-8,4.2-16,6.7-23.9c1.8-5.7,5.3-10.4,9.3-14.9c2.7-3,5-6.2,7-9.7
		c2.8-4.9,6-9.3,10.8-12.5c3.6-2.4,6-5.9,7.3-10c0.4-1.3,0.9-2.7,1.5-4c4.1-10.2,11.5-15.3,22.6-15.4c4,0,7.9,0.1,11.8,0.3
		c6.5,0.2,12.5-1.4,17.9-5.1c4.8-3.3,10-5.4,16-5.2c4.6,0.2,9.2,0.6,13.8,0.7c4.1,0,8.3,0,12.4-0.5c5.3-0.7,9.5-3.5,12.6-7.9
		c2-2.9,4.2-5.5,7.3-7.2C305.7,8,328.2,8,350.6,8z"
      />
      <path
        d="M119.7,8c-1,5.7-2.1,11.3-3.1,17c-4.2,23.2-13.8,43.7-30.6,60.5c-13.6,13.7-28,26.5-43.5,38.1c-4.2,3.1-9.1,5.3-13.7,8
		c-0.4,0.2-0.8,0.4-1.6,0.8c3,0,5.5,0.1,7.9,0c8.8-0.3,17.5-0.5,26.3-1.2c4.3-0.3,8.6-1.2,12.8-2.4c7.4-2,13.1-6.6,16.9-13.3
		c2.8-5,5.4-10,7.6-15.3c4-9.6,9.4-18.2,17-25.4c2.9-2.8,5.5-6,8-9.3c4.5-5.9,10.8-9.4,17.1-12.9c1.2-0.7,2.8-0.7,4.2-1
		c0.1,0.2,0.2,0.3,0.2,0.5c-0.9,1-2,2-2.6,3.1c-1.8,3.1-3.6,6.2-5.2,9.4c-2.1,4.3-4.9,8-8.9,10.6c-5.5,3.5-9.1,8.3-11.3,14.5
		c-3,8.6-6.9,16.8-12,24.4c-2.2,3.3-3.6,7-4,11c-0.5,5.8-3.8,10.1-7.8,13.9c-6,5.7-13.1,8.9-21.5,8.6c-5.5-0.2-10.8,0.9-15.5,4
		c-3.2,2.1-6.9,2.9-10.7,3.1c-6.7,0.4-13.3,0.9-20,1.1c-4.4,0.2-8.4-1-12.2-3.2c-4.5-2.7-9.4-4.4-14.7-5.3c0-15.8,0-31.5,0-47.3
		c1.8,0.4,3.6,0.8,5.3,1.4c15,5.1,28.5,2.1,40.7-7.4c4.5-3.5,8.8-7.3,12.9-11.2c8.1-7.8,16.4-15.3,26.3-20.8
		c2.2-1.2,4.2-2.8,6.2-4.3c6.7-5.3,11.5-11.8,12.9-20.5c0.3-2,1-4,1.7-5.8c2.4-7.1,6.6-13.2,10.5-19.5c0.8-1.3,1.5-2.7,2.2-4
		C118.3,8,119,8,119.7,8z"
      />
      <path
        d="M246.2,407.9c3.1-3.2,7-4.9,11.1-6c5.3-1.5,9.7-4.4,13.8-8c3.3-2.9,6.8-5.6,10.4-8.1c3.8-2.5,6.9-5.5,8.7-9.7
		c6-14.2,16.7-24,29.7-31.6c7.6-4.4,15.3-8.9,21.5-15.3c10-10.2,21.9-17.3,35.2-22.2c7.6-2.9,15.2-5.8,22.8-8.9
		c7.4-3,15.1-2.9,22.9-2.2c12.7,1.1,24.5,5.9,36.5,9.7c7.4,2.3,14,5.8,19.8,11c1.6,1.4,3.5,2.5,5.3,4.2c-3-0.3-6.1-0.4-9.1-0.9
		c-6.2-1-12.3-2.4-18.5-3.2c-11.7-1.4-23.5-2.4-35.3-3.5c-4.3-0.4-8.4,0.7-12.6,2c-4.1,1.3-8.2,2.2-12.4,3.1
		c-7.8,1.5-14.5,5.4-21.5,8.5c-11.4,5.1-22.3,10.9-32.3,18.4c-2.7,2-5.8,3.7-8.8,5.1c-8.7,4.1-14.8,10.6-19.2,19
		c-6.2,11.9-14,22.6-24,31.7c-2.8,2.5-6,4.6-9.1,6.9C269.5,407.9,257.9,407.9,246.2,407.9z"
      />
      <path
        d="M-1.1,174.1c1,0.4,2,0.8,2.9,1.3c6.8,3.3,14,4.6,21.4,5.6c4.7,0.7,9.4,1.4,14.1,2c7.8,1,15.3-0.5,22.4-3.8
		c13.7-6.4,27-13.4,38.4-23.6c6.3-5.6,13.4-9.7,21.3-12.4c2.7-0.9,5.3-2,8-3c10.5-3.9,17.8-11.5,23.8-20.6c2.4-3.6,4.7-7.3,7.1-10.9
		c0.4-0.7,0.9-1.4,1.3-2c-5.4,20-15,37.7-30.2,52c-2.6,2.4-5.4,4.6-8.3,6.5c-8.4,5.3-16.2,11.3-23.5,18.2c-5.3,5-11.2,9.3-18.6,10.5
		c-5.3,0.9-10.7,0.7-16,0.7c-6.3,0-12.4,0.5-18.4,2.9c-2.4,1-5.3,1.2-7.9,1.5c-3,0.3-6,0.3-8.9,0.4c-6.2,0.3-12-1-17.7-3.3
		c-3.6-1.5-7.5-2.4-11.2-3.5C-1.1,186.3-1.1,180.2-1.1,174.1z"
      />
      <path
        d="M-1.1,346.3c5.6-1.4,11.3-2.7,16.9-4.3c8.6-2.4,17.3-3.4,26.2-3.7c7.7-0.2,15.3,0,22.8,2.2c4.2,1.2,8.4,0.3,12.7-0.7
		c7.6-1.8,15.3-3,22.8-5.1c3.6-1,6.9-3.2,10.1-5.1c4.5-2.7,8.9-5.5,13.3-8.3c2.9-1.8,5.7-3.5,8.9-5.1c-2.8,3.7-5.5,7.5-8.4,11.1
		c-6.3,8-14.2,13.3-24.1,16.2c-6.4,1.9-12.5,4.4-18.7,6.9c-8.4,3.3-17.1,4.9-26.2,5.2c-6.5,0.2-13,0.8-19.4,1.8
		c-9.9,1.6-17.4,7.4-24.1,14.5c-3.9,4.1-8.4,7.6-12.7,11.4C-1.1,371-1.1,358.6-1.1,346.3z"
      />
      <path
        d="M-1.1,71.7c14.9,0,28.6-3.7,40.2-13.2c6.5-5.4,13.3-10.4,20.5-14.6c5.4-3.2,9.5-7.8,13.3-12.7C78.5,23.9,83.3,16.3,87,8
		c1.9,0,3.8,0,5.7,0c-0.9,5.2-1.6,10.4-2.6,15.6c-1.6,8.9-6.4,15.9-12.5,22.2c-12.4,12.7-26,24.1-40.9,33.7
		c-11.1,7.1-22.9,9.1-35.6,6.2c-0.7-0.2-1.5-0.2-2.2-0.2C-1.1,80.9-1.1,76.3-1.1,71.7z"
      />
      <path
        d="M424.1,407.9c4.1-3.7,8.7-6.5,14.2-7.3c4.7-0.7,8.8-2.5,12.5-5.5c5.3-4.4,11.9-5.1,18.4-5.8c4.9-0.6,9.6-0.1,14.3,1.2
		c6.2,1.8,12.6,2,19,1.7c6.7-0.2,13.5-0.7,20.1,1.2c9.2,2.7,18.1,6.3,26.4,11.1c1.6,0.9,2.8,2.3,4.2,3.5
		C510.1,407.9,467.1,407.9,424.1,407.9z"
      />
      <path
        d="M570.2,141.9c-8.8-7.3-15.8-16-21.7-25.7c-4.1-6.8-9.8-11.8-17.2-15.1c-3.5-1.6-6.8-3.7-9.9-6c-6-4.4-12.7-7.6-19.6-10.4
		c-8.3-3.3-16.2-7.2-23.7-12.1c-3.8-2.4-8-4.3-11.5-6.2c2.2,0.3,4.9,0.9,7.7,1.1c4.2,0.3,8.4,0.6,12.6,0.2c4.8-0.3,9.5-0.1,14.2,0.8
		c3.6,0.7,7.3,1,10.9,1.3c6.1,0.4,11.1,2.9,14.9,7.8c5.1,6.6,10.3,13.1,15.5,19.7c3.3,4.3,7.4,7.4,12.7,9.1
		c5.9,1.9,10.2,6.4,14.4,10.7c0.3,0.3,0.6,0.6,0.9,0.9C570.2,126.1,570.2,134,570.2,141.9z"
      />
      <path
        d="M570.2,57c-4.7-3.5-9.6-6.9-14.2-10.6c-4.9-4-10.3-6.5-16.6-6.9c-12.4-0.7-23.5-4.9-33.5-12.4
		c-12.7-9.5-27.3-12.4-42.7-13.1c-4.5-0.2-9.1-0.5-13.5-1.2c-3.3-0.5-6.3-2.4-8.8-4.8c15,0,29.9,0,44.9,0c6.3,1.9,12.4,4.6,18.8,5.6
		c10.9,1.7,21.3,3.9,30.6,10.2c1.8,1.2,3.5,2.4,5.4,3.3c2.8,1.3,5.7,2.9,8.7,3.1c8,0.6,15,2.9,20.8,8.5c0,0,0.1,0,0.2,0
		C570.2,44.7,570.2,50.9,570.2,57z"
      />
      <path
        d="M570.2,274.9c-3.1-3.1-6.8-5.4-10.8-7.2c-5-2.2-9.3-5.5-12.3-10.1c-4.8-7.5-11.9-10.9-20.2-12.4c-5.7-1-9.9-3.8-12.8-8.7
		c-2.9-4.9-6.6-9.1-11.7-11.9c-2.4-1.3-4.5-3.3-6.7-5c-3.1-2.5-6.6-4.3-10.6-4.9c-1.2-0.2-2.3-0.6-3.5-0.9c0-0.3,0.1-0.5,0.1-0.8
		c2.4,0.3,5,0.3,7.3,0.9c3.2,0.9,6.3,2.1,9.4,3.2c3.1,1.1,6.1,2.3,9.2,3.2c3.5,1,6.4,2.9,8.4,6c0.5,0.7,1.1,1.4,1.6,2.1
		c2.4,3.3,5.7,4.8,9.7,4.4c5.4-0.5,9.8,1.2,14,4.7c4.7,3.8,9.8,7,15,10.1c3.3,2,5.9,4.4,8.2,7.5c1.5,2.1,3.7,3.9,5.5,5.8
		C570.2,265.6,570.2,270.3,570.2,274.9z"
      />
      <path
        d="M-1.1,36.2C2.1,36,5.4,36,8.6,35.7c1.9-0.2,3.8-0.5,5.5-1.3c14.2-7.1,27.3-15.8,39.7-25.7C54,8.5,54.2,8.2,54.3,8
		c1.2,0,2.4,0,3.7,0c-1.3,8-5.4,14.5-11,20.2c-4.2,4.3-8.3,8.6-12.4,12.9c-3.1,3.2-6.8,5.5-11,6.8C16.1,50.1,8.3,52,0.7,54
		c-0.6,0.2-1.2,0.1-1.8,0.1C-1.1,48.1-1.1,42.2-1.1,36.2z"
      />
      <path
        d="M236.4,8c-4.5,6-10.9,7.2-17.8,6.8c-8.4-0.6-16.8-1.4-25.1-2c-6.8-0.4-12.7,1.5-17.5,6.6c-1.2,1.3-2.5,2.5-3.7,3.8
		c-5,5.6-11.1,9.6-18.3,11.7c-7.9,2.2-15.1,6.1-22.3,9.8c-2.2,1.1-4.5,2-6.9,2.8c2.5-4.7,5.1-9.1,9.8-11.9
		c8-4.9,15.8-10.2,22.6-16.7c3.5-3.4,6.6-7.2,9.9-10.8C190.1,8,213.2,8,236.4,8z"
      />
      <path
        d="M570.2,91.3c-3.1-1.3-6.1-2.9-9.3-4c-8.2-3.1-15.5-7.4-20-15.2c-4.7-8.2-12.1-12.8-20-17.1c-1.2-0.7-2.4-1.3-3.6-2.2
		c9.6-0.5,18.4,2.9,27.4,5.2c9.1,2.3,17.9,5.5,25.5,11.3C570.2,76.6,570.2,83.9,570.2,91.3z"
      />
      <path
        d="M570.2,23.1c-1.1-0.5-2.1-1-3.1-1.4c-11-4.2-22-8.4-33.1-12.6c-0.7-0.3-1.4-0.7-2.2-1.1c9.5,0,19.1,0,28.6,0
		c1.9,1.4,3.8,2.7,5.7,4.2c1.4,1.1,2.7,2.4,4.1,3.6C570.2,18.2,570.2,20.7,570.2,23.1z"
      />
      <path
        d="M445.4,62.2c-3.8-0.7-7.2-1.5-10.7-2c-9.3-1.1-18.4-0.3-27.6,1.3c-5.9,1-11.9,1.4-17.8,2.2c-14.8,2.1-28.6,7.1-42.2,13.3
		c-4.9,2.2-9.9,4.1-14.8,6.3c-4.1,1.8-7.8,4.1-10.5,7.8c-0.6,0.8-1.1,1.7-1.9,3.1c1.8-0.7,2.9-1,4.1-1.5c7.6-3.2,15.1-6.5,22.6-9.8
		c1.9-0.9,3.8-1.7,5.7-2.8c3.9-2.2,8-3.3,12.4-3.5c10.5-0.6,21-2,31.5-2.1c22.2-0.3,44,2.9,65.3,9.2c7.9,2.3,15.9,4,23.8,6.2
		c5.5,1.5,10.2,4.4,14.1,8.7c3.1,3.5,6.7,6.4,10.9,8.5c8.4,4,14.3,10.5,18.5,18.6c2.9,5.6,5.5,11.3,8.3,17c-0.2,0.1-0.4,0.2-0.5,0.4
		c-2-2.2-4-4.4-6-6.6c-5.4-6-11.7-10.6-18.5-14.9c-6.6-4.1-13-8.7-19.3-13.2c-3.2-2.3-6.5-4.4-10.4-5.5c-6.8-2-13.4-4.4-20.3-6
		c-4.6-1.1-9.4-1.3-14.2-1.8c-6.7-0.7-13.1-2.5-19.2-5.2c-14.2-6.3-27.7-3.5-41.1,2.7c-0.4,0.2-0.7,0.4-1,0.7
		c-8.3,7.1-18.2,8.8-28.6,8.2c-9.6-0.5-18.6,1.8-27.3,5.6c-6,2.6-10.3,7.2-14.2,12.2c-8.7,11.3-18.2,22-28.3,32.1
		c-4.8,4.8-10,9.3-14.9,13.9c-7,6.5-13,13.7-16.8,22.6c-0.1,0.2-0.3,0.4-0.6,0.8c1.3-9.7,4.6-18.7,7.8-27.7c0.7-2,1.5-4.1,2.1-6.2
		c3.8-12.2,11.8-21.2,21.1-29.4c3.7-3.3,7.3-6.6,11.1-10c-0.1-0.2-0.2-0.5-0.2-0.8c-2.8,0.3-5.7,0.3-8.5,0.9
		c-8.2,1.8-14.9,6.4-21.1,11.8c-5.7,4.9-11.3,10-16.9,15.1c-0.7,0.6-1.3,1.1-2,1.7c-0.1-0.2-0.2-0.3-0.2-0.3
		c5.3-9.6,10.5-19.3,16.1-28.8c2.1-3.5,5-6.7,7.9-9.5c2.2-2.2,4.9-4.2,7.8-5.4c11.4-5.2,21.6-12.1,30.3-21.1
		c8.9-9.2,20.1-13.9,32-17.4c7.1-2.1,14.4-2.9,21.7-3.7c1.3-0.2,2.7-0.7,4-1.1c0-0.2-0.1-0.3-0.1-0.5c-3.5-0.2-7-0.4-10.5-0.8
		c-2.2-0.2-4.5-0.5-6.5-1.4c-6-2.6-12.3-3.1-18.8-3.2c-11.1-0.2-22,1-32.9,2.3c-1.1,0.1-2.3,0.2-3.6,0c1-0.5,1.9-1,2.9-1.4
		c14.4-7,29.6-11.5,45.5-13.4c3.2-0.4,6.4-0.9,9.7-1.3c6.2-0.6,12.3,1,18.4,2.2c14.9,3,29.8,6,44.7,9.1c5.5,1.1,10.5,3.6,15.3,6.5
		C436.9,57.1,441.1,59.6,445.4,62.2z"
      />
      <path
        d="M457.3,122.3c-2.4,0-4.9,0.2-7.2,0c-7.3-0.9-14.7-0.4-22,0.4c-11.3,1.3-22.6,2.5-33.7,4.4c-14.2,2.5-27.5,7.8-39.9,15.3
		c-0.2,0.1-0.4,0.3-0.7,0.4c-7.9,3.3-12.4,9.5-15.3,17.3c-1.1,3-2.6,5.8-3.8,8.8c-2.9,7.4-8.1,12.8-14.9,16.7
		c-2.6,1.5-5.2,3-7.8,4.4c-16.4,8.7-28.9,21.7-39.7,36.6c-3.4,4.8-7,9.3-11.2,13.5c-4.7,4.7-7.9,10.3-9.3,16.9
		c-2.3,10.7-8.1,19.1-17.1,25.3c-5.5,3.8-11.1,7.7-16.2,12c-3.1,2.6-5.6,6-8.5,9.2c1.2-5.5,3.1-10.5,6.4-14.8
		c5-6.3,10-12.6,15.1-18.7c6.9-8.2,12.8-16.9,15.6-27.4c1.4-5.3,1.9-10.6,0.7-15.9c-1.7-7.1,0.5-13,5.8-17.6
		c3.5-3.1,7.5-5.8,11.4-8.5c2.2-1.6,4.5-3,6.7-4.6c4.1-3,7.3-6.8,8.8-11.7c6-19.7,19.4-33.2,36.7-43.5c15.5-9.3,32.2-15.5,50.2-18.3
		c5.8-0.9,11.5-2.9,16.8-5.3c16.5-7.5,33.3-7.8,50.3-2.7c7.7,2.3,15.2,4.8,22.9,7.2C457.3,121.9,457.3,122.1,457.3,122.3z"
      />
      <path
        d="M378.7,24.9c2.8-1,5.5-2.3,8.4-3c8.9-2.2,17.9-1.9,26.9-0.7c1.8,0.2,3.6,0.3,5.3,0.7c3.7,0.9,7.6,1.4,10.8,3.2
		c5.1,2.9,10.5,4.5,16.3,5.4c10.1,1.6,20.1,3.2,30.2,4.9c8.3,1.4,14.9,6.1,20.5,12.1c1.5,1.6,2.9,3.4,4.4,5.1c0,0.2-0.1,0.4-0.2,0.6
		c-1.8-0.2-3.5-0.1-5.2-0.6c-4.9-1.3-9.6-2.8-14.4-4.2c-7.5-2.2-15.1-2.7-22.8-1.8c-4.4,0.5-8.7-0.4-12.7-2.1
		c-6.4-2.6-13.1-4-20-3.9c-4,0-7.5-1.3-10.3-4.1c-2.7-2.7-5.9-4.2-9.9-3.7c-2.3,0.3-4.4-0.4-5.9-2.2c-3.2-4.1-7.6-4.9-12.4-4.9
		c-2.9,0-5.8,0-8.7,0C378.8,25.5,378.7,25.2,378.7,24.9z"
      />
      <path
        d="M279.5,63.8c-5.7,5.3-8.9,12-12.4,18.6c-2.7,4.9-6,9.3-10.2,13.1c-5.3,4.9-10.2,10.1-15.4,15.1c-0.9,0.9-2,1.8-3.6,2.5
		c0-3-0.3-6,0-9c1.1-10.7,5.3-20,13.4-27.3c2.3-2.1,5.1-4,8-5.2C266,68.8,272.8,66.4,279.5,63.8z"
      />
    </g>
  </svg>
)
