import { memo, ReactNode } from 'react'
import { SVG } from '@/assets/svg'
import { Button } from '@/ui/kit/Button'
import style from './style.module.scss'

export type ErrorNotificationProps = {
  /** If true user will have ability to close this notification. @default = false */
  isClosable?: boolean

  /** if refresh button is visible. @default = false */
  isRefreshButtonVisible?: boolean

  /** error header  */
  customHeader?: ReactNode

  /** minor text with more details  */
  customText?: ReactNode

  /** handler to control visibility */
  onCloseClick?: () => void
}

/** Error as a small notification to extend for concrete errors. Shouldn't be used directly */
export const ErrorNotification = memo<ErrorNotificationProps>(
  ({ isClosable, customHeader = '', customText = '', isRefreshButtonVisible, onCloseClick }) => {
    return (
      <div className={style.root}>
        <div>
          <span className={style.header}>{customHeader}</span>
          <span className={style.text}>{customText}</span>
        </div>

        {isRefreshButtonVisible && (
          <Button.Accent onClick={() => document.location.reload()} label="Refresh page" className={style.button} />
        )}

        {isClosable && (
          <button className={style.close} onClick={onCloseClick}>
            <SVG.Additional.Close />
          </button>
        )}
      </div>
    )
  }
)
