import { t } from '@lingui/macro'

import { addressAsyncActions } from '@/redux/address/address.actions'
import { AssetsAddresses } from '@/redux/address/address.types'
import { CoinSelectOption } from '@/ui/atoms/CoinSelectOption'
import { NetworkSelectOption } from '@/ui/atoms/NetworkSelectOption'
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Input,
  InputSize,
  InteractiveModal,
  InteractiveModalParts,
  Select,
  SelectOption,
  SelectProps,
} from '@/ui/kit'
import { useActions, useAppSelector } from '@/utils'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import style from './style.module.scss'
import { GoogleCodeVerification } from '../GoogleCodeVerification'
import { useGoogleAuthCode, useToggle } from '@/utils/hooks'
import { AddressCreateDTO, AddressDTO } from '@/backend/models/AddressDTO'
import { FieldErrorType } from '@/types'
import { addressActions } from '@/redux/address/address.slice'
import { EditPayload } from '@/backend/api/addressAPI'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useNavigate } from 'react-router-dom'
import { Loader } from '@/ui/kit/Loader'
import { errorTranslations } from '@/core/constants/errors/errorsTranslations'

export type NetworkOption = SelectOption & { name: string }
type Props = {
  addressDTO?: AddressDTO
  id?: string
}
type State = {
  address: string
  googleCode: string
  name: string
}

export const AddressForm: FC<Props> = ({ addressDTO, id }) => {
  const initialState = useMemo<State>(
    () => ({
      address: addressDTO?.address ?? '',
      googleCode: '',
      name: addressDTO?.name ?? '',
    }),
    [addressDTO]
  )
  const [state, setState] = useState<State>(initialState)
  const { getAssetsForAddressTC, editAddressTC, createAddressTC } = useActions(addressAsyncActions)
  const { setErrors } = useActions(addressActions)
  const { errors } = useAppSelector(state => state.address)
  const navigate = useNavigate()
  const [modalIsOpen, toggleModal] = useToggle(false)
  const [selectedCoin, setSelectedCoin] = useState<string | null>('')
  const [networkName, setNetworkName] = useState<string | null>('')
  const { assets } = useAppSelector(state => state.address.assetsForAddresses)
  const coins = useMemo(() => getCoins(assets), [assets])
  const networks = useMemo(() => getNetworks(assets, selectedCoin), [assets, selectedCoin])
  const googleCode = useGoogleAuthCode()
  const errorsTrans = errorTranslations()
  const errorsMapped = useMemo<Record<string, string>>(() => {
    return errors.reduce((acc, error) => {
      return {
        ...acc,
        [error.field]: error.message,
      }
    }, {})
  }, [errors])

  const handleFieldChange = useCallback(
    (name: string) => (value: string | boolean) => {
      setState(prev => ({ ...prev, [name]: value }))
    },
    []
  )
  const selectedNetworkInfo = useMemo(
    () => getSelectedNetworkInfo(assets, selectedCoin || null, networkName),
    [assets, selectedCoin, networkName]
  )

  useEffect(() => {
    getAssetsForAddressTC()
  }, [getAssetsForAddressTC])

  useEffect(() => {
    handleFieldChange('googleCode')(googleCode.code.join(''))
  }, [googleCode.code, handleFieldChange])

  const onCoinChange = useCallback(value => {
    setSelectedCoin(value)
    setNetworkName(null)
  }, [])

  useEffect(() => {
    if (addressDTO?.id) {
      setState(prev => ({
        ...prev,
        address: addressDTO.address,
        name: addressDTO.name,
        alias: addressDTO.alias,
        currency: addressDTO.currency,
      }))
      setSelectedCoin(addressDTO.currency)
      setNetworkName(addressDTO.alias)
    }
  }, [addressDTO])

  useEffect(() => {
    return () => {
      setErrors([])
    }
  }, [setErrors])

  const onNetworkChange = useCallback<SelectProps<string>['onChange']>(value => {
    setNetworkName(value)
  }, [])

  const isDirty = useMemo(() => {
    return JSON.stringify(initialState) !== JSON.stringify(state)
  }, [initialState, state])

  const onCancel = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleCancel = useCallback(() => {
    if (isDirty) {
      toggleModal()
    } else {
      return onCancel()
    }
  }, [isDirty, toggleModal, onCancel])

  const submit = useCallback(() => {
    const errors: FieldErrorType[] = []
    const isAddressCorrect = checkIsAddressCorrect(state.address, selectedNetworkInfo?.addressRegex)

    if (!isAddressCorrect || !networkName) {
      errors.push({
        code: '',
        field: 'address',
        message: t({
          id: 'addressForm.spotAddress',
          message: `Incorrect Spot address`,
        }),
      })
    }

    if (!googleCode.isCorrectCodeLength) {
      errors.push({
        code: '',
        field: 'googleCode',
        message: t({
          id: 'services.gAuth.enter.title',
          message: 'Enter the 6-digit code below',
        }),
      })
    }

    if (!state.name) {
      errors.push({
        code: '',
        field: 'name',
        message: t({
          id: 'addressForm.formFields.note.error',
          message: `Note is required`,
        }),
      })
    }

    if (!selectedCoin) {
      errors.push({
        code: '',
        field: 'coin',
        message: t({
          id: 'addressForm.formFields.coin.error',
          message: `Coin is required`,
        }),
      })
    }

    if (!networkName) {
      errors.push({
        code: '',
        field: 'network',
        message: t({
          id: 'addressForm.formFields.network.error',
          message: `Network is required`,
        }),
      })
    }

    if (!state.address) {
      errors.push({
        code: '',
        field: 'address',
        message: t({
          id: 'addressForm.formFields.address.error',
          message: `Address is required`,
        }),
      })
    }

    if (errors.length > 0) {
      setErrors(errors)
      return
    }

    setErrors([])

    const payload = {
      googleCode: state.googleCode,
      name: state.name,
      currency: selectedCoin,
      alias: networkName,
      address: state.address,
    }
    id ? editAddressTC({ data: payload, addressId: id } as EditPayload) : createAddressTC(payload as AddressCreateDTO)
  }, [
    networkName,
    id,
    selectedCoin,
    setErrors,
    state.address,
    state.name,
    googleCode.isCorrectCodeLength,
    editAddressTC,
    createAddressTC,
    state.googleCode,
    selectedNetworkInfo?.addressRegex,
  ])

  const modalCancelText = addressDTO?.id
    ? t({
        id: 'addressForm.wouldYouCancelEdit',
        message: `Would you like to close editing a Address?`,
      })
    : t({
        id: 'addressForm.wouldYouCancel',
        message: `Would you like to close creating a Address?`,
      })

  if (id && !addressDTO?.id) return <Loader className={style.loader} />

  return (
    <div className={style.createAddress}>
      <div className={style.top}>
        <Select
          options={coins}
          value={selectedCoin}
          size={Select.Size.Large}
          onChange={onCoinChange}
          label={t({ message: 'Coin', id: 'addressForm.coin' })}
          placeholder={t({ message: 'Select coin', id: 'addressForm.selectCoin' })}
          className={style.coinSelect}
          errorMessage={errorsMapped.coin}
          withSearch
        />
        <Select
          options={networks}
          value={networkName}
          size={Select.Size.Large}
          onChange={onNetworkChange}
          label={t({ message: 'Network', id: 'addressForm.network' })}
          placeholder={t({ message: 'Select Network', id: 'addressForm.selectNetwork' })}
          disabled={!selectedCoin}
          className={style.networkSelect}
          errorMessage={errorsMapped.network}
        />
      </div>
      <div className={style.inputAndChipsWrapper}>
        <Input
          size={InputSize.Large}
          label={t({ message: `Wallet Address`, id: 'addressForm.wallet' })}
          placeholder={t({ message: 'Input your withdrawal wallet address', id: 'addressForm.enterAddress' })}
          value={state.address}
          setValue={handleFieldChange('address')}
          labelHintText={
            <>
              {t({
                id: 'addressForm.wallet.message-1',
                message: 'TO DO',
              })}
            </>
          }
          errorMessage={errorsMapped.address}
        />
        <Input
          size={InputSize.Large}
          label={t({ message: `Note`, id: 'addressForm.note' })}
          placeholder={t({ message: 'Add a note', id: 'addressForm.enterNote' })}
          value={state.name}
          setValue={handleFieldChange('name')}
          inputAttrs={{ maxLength: 100 }}
          labelHintText={
            <>
              {t({
                id: 'addressForm.note.message-1',
                message: 'TODO',
              })}
            </>
          }
          errorMessage={errorsMapped.name}
        />

        <label>
          <GoogleCodeVerification
            header={t({
              id: 'services.gAuth.enter.title',
              message: `Enter the 6-digit code below`,
            })}
            text={t({
              id: 'services.gAuth.enter.subtitle',
              message: `Input code from Google Authenticator`,
            })}
            code={googleCode.code}
            setCode={googleCode.setCode}
            errorMessage={errorsMapped.googleCode ? errorsTrans.googleCode : undefined}
            successMessage={''}
          />
        </label>

        <div className={style.buttonsWrapper}>
          <Button.Accent
            size={ButtonSize.Medium}
            label={t({
              id: 'addressForm.confirm',
              message: `Confirm`,
            })}
            className={style.button}
            onClick={submit}
            dataTestId={DataTestIds.ConfirmAddressFormButton}
          />
          <Button.Clean
            label={t({
              id: 'addressForm.cancel',
              message: `Cancel`,
            })}
            className={style.button}
            onClick={handleCancel}
            dataTestId={DataTestIds.CreateApiCancelButton}
          />
        </div>
      </div>
      <InteractiveModal isOpen={modalIsOpen}>
        <InteractiveModalParts.SubHeader text={modalCancelText} />
        <InteractiveModalParts.Explanation
          text={t({
            id: 'core.dataLost',
            message: `Data will be lost`,
          })}
        />
        <InteractiveModalParts.Button
          text={t({
            id: 'core.return',
            message: `Return`,
          })}
          variant={ButtonVariant.White}
          onClick={toggleModal}
        />
        <InteractiveModalParts.Button
          text={t({
            id: 'apiPage.create.close',
            message: `Close creating`,
          })}
          variant={ButtonVariant.Primary}
          onClick={onCancel}
        />
      </InteractiveModal>
    </div>
  )
}

const getCoins = (assets: AssetsAddresses['assets']): SelectOption[] => {
  const coinKeys = Object.keys(assets)
  const coinsWithAllowedNetworks = coinKeys.filter(coin => assets[coin].some(network => network.depositEnable))

  const result = coinsWithAllowedNetworks.sort().map(key => ({
    value: key,
    search: key,
    label: <CoinSelectOption asset={key} />,
    selected: key,
  }))

  return result
}

const getNetworks = (assets: AssetsAddresses['assets'], coin: string | null) => {
  if (!coin) {
    return []
  }
  const networksByCoin = assets[coin]

  if (!networksByCoin) {
    return []
  }

  const allowedNetworks = networksByCoin.filter(item => item.depositEnable)
  const result = allowedNetworks.map<NetworkOption>(({ alias, name, tokenStandard, network }) => {
    const desc = `${name}${tokenStandard ? ` (${tokenStandard})` : ''}`
    return {
      value: alias,
      name: desc,
      label: <NetworkSelectOption symbol={name} name={network} />,
      selected: <NetworkSelectOption symbol={name} name={network} large />,
    }
  })

  return result
}

const getSelectedNetworkInfo = (assets: AssetsAddresses['assets'], coin: string | null, networkName: string | null) => {
  if (!coin || !networkName || !assets[coin]) {
    return
  }

  const selectedNetworkInfo = assets[coin].find(item => item.alias === networkName)

  return selectedNetworkInfo
}
const checkIsAddressCorrect = (address: string, addressRegex?: string): boolean => {
  if (!addressRegex && address.length > 0) return true

  if (!address) {
    return false
  }

  if (addressRegex) {
    const regex = new RegExp(addressRegex)

    return regex.test(address)
  }

  return false
}
