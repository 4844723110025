import { FC } from 'react'
interface IIconProps {
  className?: string
}

export const Shield: FC<IIconProps> = (props: IIconProps) => {
  const { className } = props
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 11.5L11.25 14L15.25 9.5M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z"
        stroke="#1ACC81"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
