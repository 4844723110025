import { memo, useCallback, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import { BalanceAsset, NetworkByCoin } from '@/backend/api'
import { KYCTier } from '@/backend/models/KycDTO'
import { AssetsForWithdrawAndDeposit } from '@/redux/transactions/transactions.types'
import { checkIsDecimalsQuantityCorrect, getDecimals, useAppSelector } from '@/utils'
import { Button, Input, InputSize, Select, SelectOption, SelectProps } from '@/ui/kit'
import { Prompt } from '@/ui/molecules'
import { WithdrawUpPopup } from '../WithdrawUpPopup'
import { WithdrawConfirmation } from './WithdrawConfirmation'
import style from './style.module.scss'
import { coinSorter } from '@/utils/lib/transactions'
import { useKYC, useMobileSizeDetect, useScrollToError } from '@/utils/hooks'
import { NetworkSelectOption } from '@/ui/atoms/NetworkSelectOption'
import { CoinSelectOption } from '@/ui/atoms/CoinSelectOption'
import { truncateNumber } from '@/utils/lib/truncateNumber'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import BigNumber from 'bignumber.js'
import { InputDropdown, InputDropdownSize } from '@/ui/kit/InputDropdown'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { AddressDTO } from '@/backend/models/AddressDTO'
import { SVG } from '@/assets/svg'
import { Whitelist } from '@/ui/organisms/Whitelist'
import clsx from 'clsx'
import { Normalizers } from '@/utils/lib/Normalizers'
import { capitalize } from '@/utils/lib/capitalize'
import { isExchangeBinance } from '@/utils/lib/exchange'
import { Trans } from '@lingui/react'
import { DangerWithTooltip } from '@/ui/molecules'

export type PropsType = {
  tier: KYCTier
}

export type NetworkOption = SelectOption & {
  name: string
  fee: number
}

type GetElementType<T extends any[]> = T extends (infer U)[] ? U : never

const MIN_WITHDRAW_AMOUNT = 10
const BROKER = 'Broker'

export const WithdrawTransaction = memo<PropsType>(({ tier }) => {
  const [isMobile] = useMobileSizeDetect()
  const scrollToError = useScrollToError()
  const { isTierUpperBasic } = useKYC()
  const [address, setAddress] = useState('')
  const [walletId, setWalletId] = useState<string>('')
  const [amount, setAmount] = useState('')
  const [coin, setCoin] = useState<AssetOption | undefined>()
  const [networkName, setNetworkName] = useState<string | null>('')
  const [networkOption, setNetworkOption] = useState<NetworkOption | null>(null)
  const { assets } = useAppSelector(state => state.transactions.assetsForWithdrawAndDeposit)
  const { addresses } = useAppSelector(state => state.transactions.withdrawAddresses)
  const { balanceAssets } = useAppSelector(state => state.transactions.coinBalance)
  const [isWithdrawAttemptDetected, setIsWithdrawAttemptDetected] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isBalanceEmpty, setIsBalanceEmpty] = useState(false)
  const navigate = useNavigate()
  const coins = useMemo(() => coinSorter(getCoins(assets, balanceAssets), 'value', 'balance'), [assets, balanceAssets])
  const networks = useMemo(() => getNetworks(assets, coin?.value || null, isMobile), [assets, coin, isMobile])
  const withdrawAddresses = useMemo(() => getAddresses(addresses, assets, isMobile), [addresses, assets, isMobile])
  const { exchangeType } = useExchageType()
  const { whitelistEnabled } = useAppSelector(state => state.address)
  const groupTitles = [
    {
      id: 'wallet',
      element: <Trans id="core.transaction.myWallet" />,
    },
    {
      id: 'address',
      element: t({ message: 'Address book', id: 'core.transaction.addressBook' }),
    },
  ]

  const mobileWithdrawAddresses = useMemo(() => {
    return withdrawAddresses.map(item => {
      const group = item.group ? groupTitles[0].id : groupTitles[1].id
      const element = item.group ? groupTitles[0].element : groupTitles[1].element

      return { ...item, group, element }
    })
  }, [withdrawAddresses])

  const selectedNetworkInfo = useMemo(
    () => getSelectedNetworkInfo(assets, coin?.value || null, networkName),
    [assets, coin, networkName]
  )
  const receiveAmount = useMemo(() => {
    const value = Number(amount) || 0
    const { fee = 0 } = networkOption || {}

    if (value <= fee) {
      return 0
    } else {
      return new BigNumber(value).minus(fee).toNumber()
    }
  }, [amount, networkOption])

  const onCoinChange = useCallback(
    (value: string) => {
      setCoin(coins.find(coin => coin.value === value))
      setNetworkName(null)
      setNetworkOption(null)
    },
    [coins]
  )

  const onNetworkChange = useCallback<SelectProps<string>['onChange']>(
    value => {
      setNetworkName(value)
      const network = networks.find(item => item.value === value)
      if (network) {
        setNetworkOption(network)
      }
    },
    [networks]
  )
  const onAddressOptionClick = useCallback(
    item => {
      const networks = getNetworks(assets, item.currency)
      const network = networks.find(network => network.value === item.network)

      if (network) {
        setNetworkOption(network)
      }

      setNetworkName(item.network)
      const findCoin: AssetOption | undefined = coins.find(coin => coin.value === item.currency)
      if (findCoin?.value) {
        setIsBalanceEmpty(false)
        setCoin(findCoin)
      } else {
        setIsBalanceEmpty(true)
      }

      setWalletId(item.id)
    },
    [coins, assets]
  )
  const handleAddressChange = (value: string) => {
    setAddress(value)
  }

  const handleCleanChange = () => {
    setWalletId('')
  }

  const isAddressCorrect = checkIsAddressCorrect(address, selectedNetworkInfo?.addressRegex)
  const allowedDecimals: number | undefined = useMemo(() => {
    if (isExchangeBinance(exchangeType)) {
      // Binance has decimals in '0.xxx1' format, need to count decimals
      return selectedNetworkInfo?.withdrawIntegerMultiple
        ? getDecimals(selectedNetworkInfo.withdrawIntegerMultiple).length
        : 0
    } else {
      // other exchanges have quantity of decimals
      return selectedNetworkInfo?.withdrawIntegerMultiple ?? 0
    }
  }, [selectedNetworkInfo, exchangeType])

  const handleSetMax = useCallback(() => {
    const maxBalance = coin?.balance ?? 0
    setAmount(String(allowedDecimals ? truncateNumber(maxBalance, allowedDecimals) : maxBalance))
  }, [coin, allowedDecimals])

  const handleAmountChange = useCallback(
    (str: string) => {
      const val = isMobile ? Normalizers.toSum(str) : str
      setAmount(val)
    },
    [isMobile, setAmount]
  )

  const getAmountErrorMessage = () => {
    if (amount === '') {
      return t({ message: 'Enter amount', id: 'withdrawConfirmation.messages.enterAmount' })
    }

    if (Number(amount) > Number(coin?.balance)) {
      return t({ message: 'Insufficient funds', id: 'withdrawConfirmation.messages.isufFunds' })
    }

    if (selectedNetworkInfo?.withdrawMax && Number(amount) > selectedNetworkInfo.withdrawMax) {
      return `${t({
        message: 'Maximum amount is',
        id: 'withdrawConfirmation.messages.maxIs',
      })} ${selectedNetworkInfo?.withdrawMax}`
    }

    const minAmount = selectedNetworkInfo?.withdrawMin ?? MIN_WITHDRAW_AMOUNT

    if (Number(amount) < minAmount) {
      return `${t({ message: 'Minimum amount is', id: 'withdrawConfirmation.messages.minIs' })} ${minAmount}`
    }

    if (!checkIsDecimalsQuantityCorrect(amount, allowedDecimals)) {
      return `${t({
        message: 'Only ',
        id: 'withdrawConfirmation.messages.onlyDec-1',
      })} ${allowedDecimals} ${t({
        message: 'decimal places are allowed',
        id: 'withdrawConfirmation.messages.onlyDec-2',
      })}`
    }

    return ''
  }

  const amountErrorMessage = getAmountErrorMessage()
  const isWithdrawAvailable = Boolean(amountErrorMessage === '' && isAddressCorrect && networkName && coin)
  const isAddressInvalid = isWithdrawAttemptDetected && !!networkName && !isAddressCorrect
  const isNetworkInvalid = isWithdrawAttemptDetected && !networkName

  const amountError = useMemo(() => {
    const error = [
      isWithdrawAttemptDetected && !coin && t({ message: 'Select Coin', id: 'withdrawConfirmation.selectCoin' }),
      isWithdrawAttemptDetected && !!networkName && amountErrorMessage,
    ]
      .filter(Boolean)
      .join(` ${t({ message: 'and', id: 'core.and' })} `)

    return capitalize(error.toLowerCase())
  }, [isWithdrawAttemptDetected, coin, networkName, amountErrorMessage])

  const processForm = useCallback(() => {
    setIsWithdrawAttemptDetected(true)
    setIsModalVisible(isWithdrawAvailable)
    scrollToError()
  }, [isWithdrawAvailable, scrollToError])

  const addressError =
    isAddressInvalid && t({ message: `Incorrect Spot address`, id: 'withdrawConfirmation.inccorectSpotAddress' })

  const handleButtonClick = () => navigate(urls.createAddresses)
  const addressNavigate = () => navigate(urls.addresses)

  const whiteListTrackBy = useCallback((option: GetElementType<typeof withdrawAddresses>) => {
    return option.id
  }, [])

  const showEmptyBalanceAlert = !balanceAssets.length || isBalanceEmpty

  return (
    <div className={clsx(isMobile && style.mobile, style.withdraw)}>
      {!isMobile && (
        <InputDropdown
          value={address}
          size={InputDropdownSize.Medium}
          label={t({ message: `Spot`, id: 'core.spot' })}
          placeholder={t({ message: 'Enter address', id: 'withdrawConfirmation.enterAddress' })}
          onChange={handleAddressChange}
          group
          groupTitles={groupTitles}
          options={withdrawAddresses}
          error={addressError as string}
          className={style.networkSelect}
          optionClick={onAddressOptionClick}
          handleButtonClick={handleButtonClick}
          handleCleanChange={handleCleanChange}
          dataTestId={DataTestIds.WithdrawAddressInput}
          Icon={<SVG.OtherIcons.Address />}
          iconClick={addressNavigate}
          disabled={whitelistEnabled}
        />
      )}

      {isMobile && (
        <Input
          size={InputSize.Large}
          label={t({ message: `Spot`, id: 'core.spot' })}
          placeholder={t({ message: 'Enter address', id: 'withdrawConfirmation.enterAddress' })}
          value={address}
          setValue={setAddress}
          errorMessage={addressError}
          dataTestId={DataTestIds.WithdrawAddressInput}
          disabled={whitelistEnabled}
        >
          <Select
            size={Select.Size.Medium}
            variant={Select.Variant.Raw}
            value={address}
            options={mobileWithdrawAddresses}
            placeholder={t({ message: 'Select address', id: 'withdrawConfirmation.selectAddress' })}
            className={style.coinSelect}
            onChange={setAddress}
            onSelect={onAddressOptionClick}
            trackBy={whiteListTrackBy}
            withSearch
            buttonMode
          />
        </Input>
      )}
      {whitelistEnabled && (
        <Whitelist whitelistEnabled={whitelistEnabled} className={clsx(isMobile && style.whiteList)} />
      )}
      {showEmptyBalanceAlert && (
        <div className={clsx(style.promptWrap, style.assetPrompt)}>
          <Prompt promptType={'balanceAsset'} />
        </div>
      )}
      <div className={style.coins}>
        <Input
          size={isMobile ? InputSize.Medium : InputSize.Large}
          setValue={handleAmountChange}
          value={amount}
          placeholder="0"
          errorMessage={amountError}
          inputAttrs={{ type: isMobile ? 'text' : 'number' }}
          containerClassName={style.input}
          dataTestId={DataTestIds.AmountInput}
          info={
            coin && (
              <>
                {t({ message: 'Available', id: 'core.transaction.available' })}{' '}
                <span className={style.available} data-testid={DataTestIds.AmountAvailableField}>
                  {new BigNumber(coin.balance).toString()} {coin.value}
                </span>
              </>
            )
          }
        >
          <div className={style.inputAside}>
            {coin && (
              <Button.Primary
                label="Max"
                className={style.maxBtn}
                onClick={handleSetMax}
                dataTestId={DataTestIds.AmountMaxButton}
              />
            )}
            <Select
              size={Select.Size.Medium}
              variant={Select.Variant.Raw}
              value={coin?.value}
              options={coins}
              placeholder={t({ message: 'Select Coin', id: 'withdrawConfirmation.selectCoin' })}
              className={style.coinSelect}
              onChange={onCoinChange}
              dataTestId={DataTestIds.CoinSelector}
              disabled={whitelistEnabled}
            />
          </div>
        </Input>

        <Select
          size={Select.Size.Medium}
          options={networks}
          value={networkName}
          onChange={onNetworkChange}
          placeholder={t({ message: 'Select Network', id: 'withdrawConfirmation.selectNetwork' })}
          disabled={!coin || whitelistEnabled}
          errorMessage={isNetworkInvalid && t({ message: 'Select Network', id: 'withdrawConfirmation.selectNetwork' })}
          dataTestId={DataTestIds.NetworkSelector}
        />
      </div>

      <div className={style.promptWrap} data-testid={DataTestIds.DepositAlertDescription}>
        <Prompt
          promptType={'transaction'}
          coin={coin?.value}
          networkSymbol={networkOption?.value}
          networkType={networkOption?.name}
        />
        {isTierUpperBasic && <WithdrawUpPopup tier={tier} />}
      </div>

      {coin && (
        <div className={style.willReceiveWrap}>
          <div className={style.receive} data-testid={DataTestIds.WithdrawSendingAmount}>
            <div className={style.receiveDescription}>
              {t({ message: 'You will receive', id: 'withdrawConfirmation.willReceive' })}
            </div>
            <div className={style.receiveValue}>
              {receiveAmount} {coin.value}
            </div>
          </div>
          <div className={style.fee} data-testid={DataTestIds.WithdrawFeeAmount}>
            <div>{t({ message: 'Fee network', id: 'withdrawConfirmation.fee' })}</div>
            <div>
              {networkOption?.fee ?? 0} {coin.value}
            </div>
          </div>
        </div>
      )}

      <Button.Accent
        size={isMobile ? Button.Size.Medium : Button.Size.Large}
        className={style.button}
        label={t({ message: 'Confirm with Google Authenticator', id: 'withdrawConfirmation.confirmGoogleAuth' })}
        onClick={processForm}
        dataTestId={DataTestIds.WithdrawConfirmButton}
      />

      {isModalVisible && (
        <WithdrawConfirmation
          amount={amount}
          network={networkName || ''}
          tokenSymbol={coin?.value || ''}
          walletAddress={address}
          closeModal={() => setIsModalVisible(false)}
          walletId={walletId}
        />
      )}
    </div>
  )
})

type AssetOption = SelectOption & {
  balance: number
}

const getCoins = (assets: AssetsForWithdrawAndDeposit['assets'], balanceAssets: BalanceAsset[]): AssetOption[] => {
  const nonZeroBalanceAssets = balanceAssets.filter(item => item.balance > 0)

  const nonZeroBalanceAssetsStorage = new Map(nonZeroBalanceAssets.map(item => [item.asset, item.balance]))

  const coinKeys = Object.keys(assets)
  const nonZeroBalanceCoinKeys = coinKeys.filter(item => nonZeroBalanceAssetsStorage.has(item))

  const coinsWithAllowedNetworks = nonZeroBalanceCoinKeys.filter(coin =>
    assets[coin].some(network => network.withdrawEnable)
  )

  const result = coinsWithAllowedNetworks.sort().map<AssetOption>(key => ({
    value: key,
    balance: nonZeroBalanceAssetsStorage.get(key) || 0,
    label: (
      <CoinSelectOption asset={key} balance={new BigNumber(nonZeroBalanceAssetsStorage.get(key) || 0).toString()} />
    ),
    selected: <CoinSelectOption asset={key} large />,
  }))

  return result
}

const getNetworks = (assets: AssetsForWithdrawAndDeposit['assets'], coin: string | null, isMobile: boolean = false) => {
  if (!coin) {
    return []
  }

  const allowedNetworks = assets[coin]
  const result = allowedNetworks.map<NetworkOption>(item => {
    const fee = `${t({ message: 'Fee:', id: 'core.fee' })} ${item.withdrawFee} ${coin}`

    return {
      value: item.network,
      name: item.name,
      fee: item.withdrawFee,
      label: (
        <NetworkSelectOption
          symbol={item.name}
          name={item.alias}
          fee={
            isMobile && !item.withdrawEnable
              ? t({ comment: 'Network suspended', id: 'withdrawOption.networkSuspended' })
              : fee
          }
          disabled={!item.withdrawEnable}
        />
      ),
      selected: <NetworkSelectOption symbol={item.name} name={item.alias} large />,
      rightIcon: !item.withdrawEnable && (
        <DangerWithTooltip text={t({ comment: 'Network suspended', id: 'withdrawOption.networkSuspended' })} />
      ),
      disabled: !item.withdrawEnable,
    }
  })

  return result
}

const getAddresses = (addresses: AddressDTO[], assets: AssetsForWithdrawAndDeposit['assets'], isMobile: boolean) => {
  const networks = ([] as NetworkByCoin[]).concat(...Object.values(assets))

  const filteredAddress = addresses.filter(address => networks.some(networks => networks.network === address.network))

  const result = filteredAddress.map(item => {
    const filteredNetwork = networks.find(networkSource => {
      return networkSource.alias === item.alias
    })

    return {
      id: item.id,
      value: item.address,
      currency: item.currency,
      name: item.name,
      search: item.name,
      network: item.network,
      address: item.address,
      group: item.internal,
      disabled: !filteredNetwork?.withdrawEnable,
      rightIcon: !filteredNetwork?.withdrawEnable && (
        <DangerWithTooltip text={t({ comment: 'Network suspended', id: 'withdrawOption.networkSuspended' })} />
      ),
      label: (
        <NetworkSelectOption
          symbol={`${item.name} ${item.internal ? BROKER : ''}`}
          className={style.symbolEllipsis}
          name={filteredNetwork?.name}
          {...(isMobile && {
            fee: !filteredNetwork?.withdrawEnable
              ? t({ comment: 'Network suspended', id: 'withdrawOption.networkSuspended' })
              : item.address,
          })}
          disabled={!filteredNetwork?.withdrawEnable}
        />
      ),
      selected: <NetworkSelectOption symbol={item.name} name={filteredNetwork?.name} large />,
    }
  })

  return result
}

const getSelectedNetworkInfo = (
  assets: AssetsForWithdrawAndDeposit['assets'],
  coin: string | null,
  networkName: string | null
) => {
  if (!coin || !networkName) {
    return
  }

  const selectedNetworkInfo = assets[coin].find(item => item.network === networkName)

  return selectedNetworkInfo
}

const checkIsAddressCorrect = (address: string, addressRegex?: string): boolean => {
  if (!addressRegex && address.length > 0) return true

  if (!address) {
    return false
  }

  if (addressRegex) {
    const regex = new RegExp(addressRegex)

    return regex.test(address)
  }

  return false
}
