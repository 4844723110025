import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, memo, useMemo } from 'react'
import { SVG } from '@/assets/svg'
import { HistoryState } from '@/redux/history/history.types'
import { formatNumber, useAppSelector, useToggle, getFormattedUTCDate } from '@/utils'
import { formatDate } from '@/utils/lib/formatDate'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

type HistoryItem = HistoryState['historyCashback']['cashback'][number]

const CashbackHistoryItem: FC<HistoryItem> = props => {
  const { tigerTradeId } = useAppSelector(state => state.profile)
  const quantity = formatNumber(props.quantity)
  const quantityNumber = Number(quantity)
  const [isModalOpen, toggleModalOpen] = useToggle(false)
  const messageFor = t({
    id: 'core.cashback.for',
    message: 'For the period',
  })
  const dateOutputList = useMemo(() => {
    return getFormattedUTCDate(new Date(props.cashbackDate))
  }, [props.cashbackDate])

  const dateOutputTransaction = useMemo(() => {
    return formatDate(props.time)
  }, [props.time])

  return (
    <>
      <div onClick={() => toggleModalOpen()} className={style.history} data-testid={DataTestIds.CashbackTrx}>
        <div className={style.left}>
          <SVG.CryptoIcons.TT />
          <div className={style.currencyWrap}>
            <div className={style.currency}>
              <span className={style.baseCurrency}>
                {t({
                  id: 'cashbackPage.history.title',
                  message: 'Saved commission',
                })}
              </span>{' '}
              •{' '}
              <span className={style.percent} data-testid={DataTestIds.CashbackTrxPercentage}>
                {props.percentage}%
              </span>
            </div>
            <div className={style.date} data-testid={DataTestIds.CashbackTrxDate}>
              {messageFor} {dateOutputList}
            </div>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.amount} data-testid={DataTestIds.CashbackTrxTradingVolume}>
            {formatNumber(props.totalTradeAmount)} {props.symbol}
          </div>
          <div className={style.text}>
            {t({
              id: 'cashbackPage.history.tradingVolume',
              message: 'Trading volume',
            })}
          </div>
        </div>
        <div className={style.item}>
          <div className={style.amount} data-testid={DataTestIds.CashbackTrxCommission}>
            {formatNumber(props.totalCommissionAmount)} {props.symbol}
          </div>
          <div className={style.text}>
            {t({
              id: 'cashbackPage.history.comission',
              message: 'Commission',
            })}
          </div>
        </div>
        <div className={style.item} data-testid={DataTestIds.CashbackTrxAmount}>
          <span className={clsx(style.new, { [style.newMinus]: quantityNumber < 0 })}>
            {quantityNumber > 0 && '+'} {quantity}&nbsp;{props.symbol}
          </span>
        </div>
      </div>

      <InteractiveModal isOpen={isModalOpen}>
        <InteractiveModalParts.HeaderIcon>
          <SVG.CryptoIcons.TT />
        </InteractiveModalParts.HeaderIcon>
        <InteractiveModalParts.SingleValue
          label={t({
            id: 'history.savedCommission',
            message: 'Saved commission',
          })}
          value={`${props.percentage} %`}
        />
        <InteractiveModalParts.MainInfo text={`+ ${quantity} ${props.symbol}`} />

        <InteractiveModalParts.Status status={'succeeded'} date={dateOutputTransaction} />
        <InteractiveModalParts.Table
          left={t({
            id: 'core.accountTo',
            message: 'To',
          })}
          right={t({
            id: 'core.spotAccount',
            message: 'Spot account',
          })}
        />
        <InteractiveModalParts.Table left={messageFor} right={getFormattedUTCDate(new Date(props.cashbackDate))} />
        <InteractiveModalParts.TableCopy
          left={t({
            id: 'core.ttId',
            message: 'Tiger.Trade ID',
          })}
          right={tigerTradeId || ''}
          isShortenView={true}
        />
        <InteractiveModalParts.Button
          text={t({
            id: 'core.close',
            message: 'Close',
          })}
          variant={ButtonVariant.White}
          onClick={() => toggleModalOpen()}
        />
      </InteractiveModal>
    </>
  )
}

export default memo(CashbackHistoryItem)
