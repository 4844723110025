import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { DAY_IN_MILLISECONDS } from '@/utils/lib/constants'

export const getLastPeriodDateRange = (period: 'week' | 'month' | 'year'): [string, string] => {
  let pastDay
  const currentDay = formatDate(new Date(), DATE_FORMAT.FORMATTED)

  if (period === 'week') {
    pastDay = new Date().getTime() - 7 * DAY_IN_MILLISECONDS
  } else if (period === 'month') {
    pastDay = new Date().getTime() - 30 * DAY_IN_MILLISECONDS
  } else {
    pastDay = new Date().getTime() - 365 * DAY_IN_MILLISECONDS
  }

  const pastDayFormatted = formatDate(pastDay, DATE_FORMAT.FORMATTED)

  return [pastDayFormatted, currentDay]
}
export const calculateDateInSevenDays = (startDate: Date): Date => {
  return new Date(startDate.getTime() + 7 * DAY_IN_MILLISECONDS)
}

export const getDateWithTimeString = (originalDate: Date, timeString: string): Date => {
  const [hour, minute, second] = timeString.split(':').map(Number)
  return new Date(originalDate.getFullYear(), originalDate.getMonth(), originalDate.getDate(), hour, minute, second)
}

export const capitalizeFirstLetter = (month: string) => {
  return month.charAt(0).toUpperCase() + month.slice(1)
}

export const isEndTimeBeforeStartTime = (startTime: string, endTime: string): boolean => {
  const [startHour, startMinute, startSecond] = startTime.split(':').map(Number)
  const [endHour, endMinute, endSecond] = endTime.split(':').map(Number)
  const startTimeInSeconds = startHour * 3600 + startMinute * 60 + startSecond
  const endTimeInSeconds = endHour * 3600 + endMinute * 60 + endSecond
  return endTimeInSeconds < startTimeInSeconds
}
