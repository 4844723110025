import { RiskEventById } from '@/redux/riskManager/riskManager.types'
import { PositionTicker } from '../components/PositionTicker/PositionTicker'
import { FormatNumberDotWithTooltip } from '@/ui/molecules/FormatNumberDotWithTooltip/FormatNumberWithTooltip'
import { ColorizedValue } from '../components/ColorizedValue/ColorizedValue'
import { CurrencyVolume } from '../components/CurrencyVolume/CurrencyVolume'
import style from '../style.module.scss'

interface EventPositionsTable {
  event?: RiskEventById | null
}

export function useEventPositionsTable({ event }: EventPositionsTable) {
  const positions = event?.positions ?? []

  return positions.map(position => ({
    ticker: (
      <PositionTicker exchange={event?.exchange} symbolName={position.symbol.symbolName} side={position.symbol.side} />
    ),
    openingPrice: <FormatNumberDotWithTooltip value={position.entryPrice.toString()} />,
    volume: (
      <CurrencyVolume
        baseCurrencySymbol={position.volume.baseCurrency.symbol}
        baseCurrencyVolume={position.volume.baseCurrency.formattedValue}
        quoteCurrencySymbol={position.volume.quotationCurrency.symbol}
        quoteCurrencyVolume={position.volume.quotationCurrency.formattedValue}
        quoteClassName={style.quote}
      />
    ),
    price: <FormatNumberDotWithTooltip value={position.price.toString()} />,
    pnl: <ColorizedValue value={position.pnl.value} symbol={position.pnl.symbol} />,
  }))
}
