import { FC } from 'react'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { E_TEAM_USER_MODE } from '@/redux/team/team.types'
import { TeamWidgetManager, TeamWidgetUser } from './TeamWidget.Components'
import clsx from 'clsx'
import { CardWrapper } from '@/ui/kit/CardWrapper'
import { Loader } from '@/ui/kit/Loader'

import styles from './style.module.scss'

interface ITeamWidgetProps {
  className?: string
}

const TeamWidget: FC<ITeamWidgetProps> = props => {
  const { className } = props
  const { userData } = useTeamData()

  if (userData.status !== 'succeeded')
    return (
      <CardWrapper className={clsx(styles.root, className)}>
        <Loader />
      </CardWrapper>
    )

  return (
    <div className={clsx(styles.root, className)}>
      {userData.userMode === E_TEAM_USER_MODE.user || userData.userMode === E_TEAM_USER_MODE.noTeam ? (
        <TeamWidgetUser />
      ) : userData.userMode === E_TEAM_USER_MODE.manager ? (
        <TeamWidgetManager />
      ) : null}
    </div>
  )
}

export { TeamWidget }
