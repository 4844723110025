import { t } from '@lingui/macro'
import { FC, useMemo } from 'react'
import { Loader } from '@/ui/kit/Loader'
import { Position } from '@/ui/organisms'
import { useAppSelector } from '@/utils'
import { useOverview } from '@/utils/hooks/useOverview'
import s from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

interface IPositionsTableProps {}

const PositionsTable: FC<IPositionsTableProps> = props => {
  const { statusPositions } = useAppSelector(state => state.overview)
  const { allPositions } = useOverview()

  const HEADERS = useMemo(
    () => [
      t({ message: 'Ticker', id: 'core.ticker' }),
      t({ message: 'Open price', id: 'core.openPrice' }),
      t({ message: 'Size', id: 'core.size' }),
      t({ message: 'Liquidation price', id: 'core.liquidationPrice' }),
      // t({ message: '% of margin', id: 'core.percentOfMargin' }),
      '', // ADL
      t({ message: 'PNL', id: 'core.pnl' }),
      '',
    ],
    []
  )

  if (statusPositions === 'loading') {
    return <Loader className={s.loader} dataTestId={DataTestIds.LoaderPositions} />
  }

  if (!allPositions.length) {
    return (
      <div className={s.empty} data-testid={DataTestIds.OPEmptyPositions}>
        {t({ message: 'You have no submitted positions', id: 'orders.noPositions' })}
      </div>
    )
  }

  return (
    <div className={s.root}>
      <div className={s.header}>
        {HEADERS.map(header => (
          <div key={header}>{header}</div>
        ))}
      </div>
      {allPositions?.map(position => (
        <Position.Table key={position.uid + position.updateTime} position={position} />
      ))}
    </div>
  )
}

export { PositionsTable }
