import { Status } from '@/backend/types'
import {
  E_TEAM_USER_MODE,
  E_TEAM_USER_STATUS,
  TRiskManagerSettings,
  TTeamInfo,
  TTeamManagerInfo,
  TTeamUserInfo,
} from '@/redux/team/team.types'
import { useMemo } from 'react'
import { useAppSelector } from './useAppSelector'
import { E_TEAM_USER_VIEWS } from '@/ui/molecules/TeamWidget/TeamWidget.Types'
import {
  RISK_MANAGER_DATA_DEFAULT,
  TEAM_MANAGER_DATA_DEFAULT,
  TEAM_REQUESTED_DATA_DEFAULT,
  TEAM_USER_DATA_DEFAULT,
} from '@/redux/team/team.defaults'

type TUseTeamDataReturnType = {
  userData: {
    status: Status
    userMode: E_TEAM_USER_MODE
    teamUserInfo: TTeamUserInfo
    teamManagerInfo: TTeamManagerInfo
  }
  userView: E_TEAM_USER_VIEWS
  teamRequested: TTeamInfo
  riskManagerSettings: TRiskManagerSettings
}

const getUserViewByStatus = (
  userStatus: E_TEAM_USER_STATUS,
  rmEnabled: boolean,
  refcode: string | undefined,
  teamCode: string | undefined
): E_TEAM_USER_VIEWS => {
  if (
    !refcode ||
    (userStatus !== E_TEAM_USER_STATUS.submitted && userStatus !== E_TEAM_USER_STATUS.approved && !teamCode)
  ) {
    return E_TEAM_USER_VIEWS.DISABLED
  } else if (userStatus === E_TEAM_USER_STATUS.approved) return E_TEAM_USER_VIEWS.APPROVED
  else if (userStatus === E_TEAM_USER_STATUS.submitted) return E_TEAM_USER_VIEWS.SUBMITTED
  return E_TEAM_USER_VIEWS.DEFAULT
}

// TODO: cache
export const useTeamData = (): TUseTeamDataReturnType => {
  const {
    teamInfoRequestInitialStatus,
    userMode,
    teamUserInfo,
    teamManagerInfo,
    teamRequested: teamRequestedSource,
    riskManagerSettings: riskManagerSettingsSource,
  } = useAppSelector(state => state.team)
  const {
    overview: { referrerCode },
  } = useAppSelector(state => state.referrals)

  const userData = useMemo<TUseTeamDataReturnType['userData']>(() => {
    return {
      status: teamInfoRequestInitialStatus,
      userMode: userMode || E_TEAM_USER_MODE.user,
      teamUserInfo: teamUserInfo || TEAM_USER_DATA_DEFAULT,
      teamManagerInfo: teamManagerInfo || TEAM_MANAGER_DATA_DEFAULT,
    }
  }, [teamInfoRequestInitialStatus, userMode, teamUserInfo, teamManagerInfo])

  const userView = useMemo<E_TEAM_USER_VIEWS>(() => {
    return getUserViewByStatus(
      userData.teamUserInfo.applicationStatus,
      userData.teamUserInfo.riskManagerEnabled || false,
      referrerCode,
      userData.teamUserInfo.teamCode
    )
  }, [userData, referrerCode])

  const teamRequested = useMemo<TTeamInfo>(() => {
    return teamRequestedSource || TEAM_REQUESTED_DATA_DEFAULT
  }, [teamRequestedSource])

  const riskManagerSettings = useMemo(() => {
    return riskManagerSettingsSource || RISK_MANAGER_DATA_DEFAULT
  }, [riskManagerSettingsSource])

  return {
    userData,
    userView,
    teamRequested,
    riskManagerSettings,
  }
}
