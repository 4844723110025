import clsx from 'clsx'
import { memo, ReactElement, useMemo, useState } from 'react'
import { HistoryOperationType, HistoryStatus, TransactionHistory } from '@/backend/api'
import { instrumentNames, formatNumber, makeShortString, useAppSelector, getFormattedUTCDate } from '@/utils'
import { formatDate } from '@/utils/lib/formatDate'
import { ButtonVariant, commonIcons, IconProps, InteractiveModal, InteractiveModalParts, StatusProps } from '@/ui/kit'
import { TransactionModal as AllocateTransactionModal } from '@/pages/TigetToken/components'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { Status as TStatus } from '@/backend/types'
import { SVG } from '@/assets/svg'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { getPartnerTranslations } from '@/translations/partner'
import { handleMouseupWithoutSelection } from '@/utils/lib/handleMouseupWithoutSelection'

type HistoryTransactionProps = {
  history: TransactionHistory
}

/** One transaction details. Also can be opened as Popup for full information */
export const HistoryTransaction = memo<HistoryTransactionProps>(({ history }) => {
  //todo: remove it after fix lingui
  const statusVariants: Record<TStatus, { icon: ReactElement; name: string }> = {
    loading: {
      icon: <SVG.Status.Pending />,
      name: t({
        id: 'core.status.pending',
        message: `Pending`,
      }),
    },
    succeeded: {
      icon: <SVG.Status.Completed />,
      name: t({
        id: 'core.status.completed',
        message: `Completed`,
      }),
    },
    failed: {
      icon: <SVG.Status.Failed />,
      name: t({
        id: 'core.status.failed',
        message: `Failed`,
      }),
    },
    idle: {
      icon: <>add icon if sometimes needed</>,
      name: t({
        id: 'core.status.idle',
        message: `Idle`,
      }),
    },
  }

  const { partnersByAwardKey } = useAppSelector(state => state.partner)
  const partnerTranslations = useMemo(() => {
    return getPartnerTranslations()
  }, [])

  //todo: remove it after fix lingui
  const statuses: Record<HistoryStatus, { icon: StatusProps['status']; style: string }> = {
    COMPLETED: { icon: 'succeeded', style: style.statusCompleted },
    FAILED: { icon: 'failed', style: style.statusFailed },
    PENDING: { icon: 'loading', style: style.statusPending },
  }
  //todo: remove it after fix lingui
  const operations: Record<HistoryOperationType, { name: string; icon: IconProps['icon'] }> = {
    CASHBACK: { name: t({ id: 'core.operation.commission', message: 'Saved commission' }), icon: 'cashback' },
    DEPOSIT: { name: t({ id: 'core.operation.deposit', message: 'Deposit' }), icon: 'deposit' },
    TRANSFER: { name: t({ id: 'core.operation.transfer', message: 'Transfer' }), icon: 'transfer' },
    WITHDRAWAL: { name: t({ id: 'core.operation.withdrawal', message: 'Withdrawal' }), icon: 'withdrawal' },
    TIGR_ALLOCATION: { name: t({ id: 'core.operation.allocation', message: 'Allocation' }), icon: 'transfer' },
    AWARD: { name: t({ id: 'core.operation.award', message: 'Partner award' }), icon: 'cashback' },
  }

  const { id, details, itemStatus, itemType, quantity, quantityUsdt, symbol, time, fee } = history

  const [isOpen, setIsOpen] = useState(false)
  const amount = `${getSign(quantity, itemType)}${formatNumber(quantity)} ${symbol}`
  const date = formatDate(time)
  const operation = operations[itemType]
  const operationIconKey = operation.icon
  const status = statuses[itemStatus]
  const statusText = statusVariants[status.icon].name
  const color = getColor(history)

  const messageFrom = t({ id: 'core.transaction.from', message: 'From' })
  const messageTo = t({ id: 'core.transaction.to', message: 'To' })
  const messageNetwork = t({ id: 'core.transaction.network', message: 'Network' })
  const messageAccount = t({ id: 'core.transaction.account', message: 'account' })
  const messageFor = t({
    id: 'core.cashback.for',
    message: 'For the period',
  })

  const handleModalOpen = () => {
    if (handleMouseupWithoutSelection()) {
      setIsOpen(true)
    }
  }

  const valuesToDisplayBasedOnItemType = useMemo(() => {
    switch (itemType) {
      case 'CASHBACK': {
        const { cashbackDate } = details
        return (
          <>
            <InteractiveModalParts.Table
              left={messageTo}
              right={`${instrumentNames['SPOT'].name} ${messageAccount}`}
              dataTestId={DataTestIds.HistoryModalTrxTo}
            />
            <InteractiveModalParts.Table
              left={messageFor}
              right={getFormattedUTCDate(new Date(cashbackDate))}
              dataTestId={DataTestIds.HistoryModalDatePeriod}
            />
          </>
        )
      }

      case 'DEPOSIT': {
        const { destinationAddress, externalId, network, sourceAddress } = details

        return (
          <>
            <InteractiveModalParts.TableCopy
              left={messageFrom}
              right={sourceAddress}
              isShortenView
              dataTestId={DataTestIds.HistoryModalAddrFrom}
            />
            <InteractiveModalParts.TableCopy
              left={messageTo}
              right={destinationAddress}
              isShortenView
              dataTestId={DataTestIds.HistoryModalAddrTo}
            />
            <InteractiveModalParts.Table
              left={messageNetwork}
              right={network}
              dataTestId={DataTestIds.HistoryModalNetwork}
            />
            <InteractiveModalParts.Table
              left="TxID"
              right={externalId.startsWith('Internal') ? externalId : makeShortString(externalId)}
              dataTestId={DataTestIds.HistoryModalTrxId}
            />
          </>
        )
      }

      case 'TRANSFER': {
        const { accountFrom, accountTo } = details

        return (
          <>
            <InteractiveModalParts.Table
              left={messageFrom}
              right={instrumentNames[accountFrom].name}
              dataTestId={DataTestIds.HistoryModalTrxFrom}
            />
            <InteractiveModalParts.Table
              left={messageTo}
              right={instrumentNames[accountTo].name}
              dataTestId={DataTestIds.HistoryModalTrxTo}
            />
          </>
        )
      }

      case 'WITHDRAWAL': {
        const { destinationAddress, network, externalId } = details

        return (
          <>
            <InteractiveModalParts.Table
              left={messageFrom}
              right={instrumentNames['SPOT'].name}
              dataTestId={DataTestIds.HistoryModalTrxFrom}
            />
            <InteractiveModalParts.TableCopy
              left={messageTo}
              right={destinationAddress}
              isShortenView
              dataTestId={DataTestIds.HistoryModalAddrTo}
            />
            <InteractiveModalParts.Table
              left={messageNetwork}
              right={network}
              dataTestId={DataTestIds.HistoryModalNetwork}
            />
            {externalId && (
              <InteractiveModalParts.TableCopy
                left="TxID"
                isShortenView
                right={externalId}
                dataTestId={DataTestIds.HistoryModalTrxId}
              />
            )}
          </>
        )
      }

      case 'AWARD': {
        const { awardKey } = details
        const partner = partnersByAwardKey[awardKey]
        if (partner) {
          const partnerName = partnerTranslations.brands[partner.partnerKey].title

          return (
            <>
              <InteractiveModalParts.Table
                left={messageFrom}
                right={partnerName}
                dataTestId={DataTestIds.HistoryModalTrxFrom}
              />
            </>
          )
        }
        return <></>
      }
    }
  }, [itemType, details, partnersByAwardKey])

  return (
    <>
      <div onClick={() => handleModalOpen()} className={style.historyTransaction} data-testid={DataTestIds.HistoryTrx}>
        <div className={style.left}>
          {operationIconKey && commonIcons[operationIconKey]}
          <div className={style.currencyWrap} data-testid={DataTestIds.HistoryTrxType}>
            <div className={style.currency}>
              <span className={style.baseCurrency}>{operation.name}</span>{' '}
              {itemType === 'CASHBACK' && (
                <>
                  • <span className={style.percent}>{details.percentage}%</span>
                </>
              )}
            </div>
            {itemType === 'TRANSFER' && (
              <div className={style.type}>
                {instrumentNames[details.accountFrom].name} → {instrumentNames[details.accountTo].name}
              </div>
            )}
          </div>
        </div>
        <div className={style.status} data-testid={DataTestIds.HistoryTrxStatus}>
          {statusVariants[status.icon].icon}
          <div className={status.style}>{statusText}</div>
        </div>
        <div className={style.date} data-testid={DataTestIds.HistoryTrxDate}>
          {date}
        </div>
        <div className={style.item}>
          <span className={clsx(style.amount, style[color])} data-testid={DataTestIds.HistoryTrxAmount}>
            {amount}
          </span>
          {quantityUsdt && (
            <span
              className={clsx(style.total, {
                [style.sameTransaction]: itemStatus === 'FAILED',
              })}
            >
              ≈ {quantityUsdt} USDT
            </span>
          )}
        </div>
      </div>
      {itemType === 'TIGR_ALLOCATION' ? (
        <AllocateTransactionModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          allocate={{ asset: history.symbol, amount: history.quantity }}
          status={status.icon}
          date={date}
        />
      ) : (
        <InteractiveModal isOpen={isOpen}>
          <InteractiveModalParts.Icon icon={operation.icon} />
          <InteractiveModalParts.Header
            text={`${operation.name} ${itemType === 'CASHBACK' ? ` ${details.percentage}%` : ''}`}
          />
          <InteractiveModalParts.MainInfo text={amount} />
          {itemType === 'TRANSFER' && (
            <InteractiveModalParts.Explanation
              text={t({ id: 'core.transaction.withoutCommission', message: `Without commission` })}
            />
          )}
          {fee && (
            <InteractiveModalParts.Explanation
              text={t({ id: 'core.transaction.includingFee', message: `Including network fee:` }) + ` ${fee}`}
            />
          )}

          <InteractiveModalParts.Status status={status.icon} date={date} />
          {valuesToDisplayBasedOnItemType}
          <InteractiveModalParts.TableCopy
            isShortenView
            left={t({ id: 'core.modal.id', message: `Tiger.Trade ID` })}
            right={id}
            dataTestId={DataTestIds.HistoryModalTigerTrxId}
          />
          <InteractiveModalParts.Button
            text={t({ id: 'core.close', message: `Close` })}
            variant={ButtonVariant.White}
            onClick={() => setIsOpen(false)}
            dataTestId={DataTestIds.HistoryModalClose}
          />
        </InteractiveModal>
      )}
    </>
  )
})

const getSign = (quantity: number, itemType: TransactionHistory['itemType']) => {
  if (itemType === 'TRANSFER') {
    return ''
  }

  if (itemType === 'WITHDRAWAL' || itemType === 'TIGR_ALLOCATION') {
    return '-'
  }

  if (quantity > 0) {
    return '+'
  }

  return ''
}

const getColor = (history: TransactionHistory) => {
  const { quantity, itemStatus, itemType } = history

  if (itemStatus === 'FAILED') {
    return 'gray'
  }

  if (quantity < 0 || itemType === 'TIGR_ALLOCATION' || itemType === 'WITHDRAWAL') {
    return 'red'
  }

  return 'green'
}
