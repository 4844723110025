import { isScrollable } from './isScrollable'

export function getScrollParent(node: Element, checkForOverflow?: boolean): Element {
  let mutableNode: Element | null = node

  if (isScrollable(node, checkForOverflow)) {
    mutableNode = mutableNode.parentElement
  }

  while (mutableNode && !isScrollable(mutableNode, checkForOverflow)) {
    mutableNode = mutableNode.parentElement
  }

  return mutableNode || document.scrollingElement || document.documentElement
}
