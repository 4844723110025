import { FC, useCallback, useState } from 'react'
import style from '../../style.module.scss'
import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'
import { Toggle } from '@/ui/kit'
import { useActions, useAppSelector } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'
import { enqueueSnackbar } from 'notistack'
import { defineMetadata } from '@/utils/lib/metadata/mapper'

const AdditionalInfo: FC = () => {
  const {
    metadata: { isOrdersPageBalancesEnabled },
  } = useAppSelector(state => state.profile)
  const { UpdateMetadata } = useActions(profileActions)

  const [active, setActive] = useState(isOrdersPageBalancesEnabled)

  const handleChange = useCallback(
    async (active: boolean) => {
      setActive(active)

      const response = await UpdateMetadata(
        defineMetadata({
          isOrdersPageBalancesEnabled: active,
        })
      )

      if ('error' in response) {
        setActive(!active)
        enqueueSnackbar(t({ id: 'profile.additionalInfoError', message: 'Error user’s balance sheets are disabled' }), {
          variant: 'error',
        })
      } else {
        enqueueSnackbar(
          active
            ? t({
                id: 'profile.additionalInfoSuccess.enabled',
                message: `User’s balance sheets are enabled`,
              })
            : t({
                id: 'profile.additionalInfoSuccess.disabled',
                message: `User’s balance sheets are disabled`,
              }),
          {
            variant: 'success',
          }
        )
      }
    },
    [UpdateMetadata]
  )

  return (
    <div className={style.setting}>
      <SVG.Profile.AdditionalInfo className={style.sectionIcon} />
      <div>
        <div className={style.settingTitle}>
          {t({ id: 'profile.additionalInfo', message: `Additional information on Order & Position page` })}
        </div>
        <div className={style.settingStatus}>
          {t({
            id: 'profile.additionalInfoDescription',
            message: 'Display your balances on Order & Position page',
          })}
        </div>
      </div>
      <div className={style.settingActions}>
        <Toggle active={active} onChange={handleChange} />
      </div>
    </div>
  )
}

export { AdditionalInfo }
