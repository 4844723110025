import { FC } from 'react'
import style from './style.module.scss'
import { Trans, t } from '@lingui/macro'
import { Button } from '@/ui/kit'
import { TitleH4 } from '@/ui/atoms/TitleH4'
import { CopyToClipboard } from '@/pages/TradeInfo/TradeInfoView/TradeShare/TradeSharing/CopyToClipboard/CopyToClipboard'
import { SVG } from '@/assets/svg'
import { useActions, useAppSelector } from '@/utils'
import { okxAffiliateActions } from '@/redux/okxAffiliate/okxAffiliate.slice'

type Props = {
  onClose: () => void
  link?: string
}

export const Offer: FC<Props> = props => {
  const { config, userStatus } = useAppSelector(state => state.okxAffiliate)
  const { openAddKeyPopup } = useActions(okxAffiliateActions)

  const link = props.link ? props.link : ''

  function closePopup() {
    props.onClose()
  }

  function handleAddKeyPopup() {
    closePopup()
    openAddKeyPopup()
  }

  function navigateToOkx() {
    window.open(link, '_blank')
  }

  return (
    <div className={style.wrapper}>
      <div className={style.iconWrap}>
        <SVG.OtherIcons.Offer />
      </div>
      <TitleH4
        className={style.title}
        label={t({
          id: 'okxAffiliate.offerPopup.title',
          comment: 'Trade directly on OKX and get increased cashback!',
        })}
      />
      <div className={style.description}>
        <span>
          <Trans
            id="okxAffiliate.offerPopup.description"
            values={{ okxPercent: config.okxPercent, tigerPercent: userStatus?.cashbackPercent }}
          />
        </span>
      </div>
      <div className={style.item}>
        <div className={style.numberWrap}>
          <div className={style.number}>1</div>
          <span className={style.itemText}>
            <Trans id="okxAffiliate.offerPopup.step1" values={{ okxPercent: config.okxPercent }} />
          </span>
        </div>
        <Button.Accent
          label={t({
            id: 'okxAffiliate.offerPopup.buttonSignUp',
            comment: 'SignUp on OKX',
          })}
          onClick={navigateToOkx}
        />
        <div className={style.copyTextWrap}>
          <div className={style.line}></div>
          <span className={style.copyText}>
            {t({
              id: 'okxAffiliate.offerPopup.copyLink',
              comment: 'or copy the link',
            })}
          </span>
          <div className={style.line}></div>
        </div>
        <CopyToClipboard valueToCopy={link} />
      </div>
      <div className={style.item}>
        <div className={style.numberWrap}>
          <div className={style.number}>2</div>
          <span className={style.itemText}>
            <Trans id="okxAffiliate.offerPopup.step2" values={{ tigerPercent: userStatus?.cashbackPercent }} />
          </span>
        </div>
        <Button.Accent
          label={t({
            id: 'okxAffiliate.offerPopup.buttonAddKeys',
            comment: 'Add keys',
          })}
          onClick={handleAddKeyPopup}
        />
      </div>
      <Button.Primary className={style.btnWrap} onClick={closePopup}>
        {t({ id: 'okxAffiliate.offerPopup.buttonClose', comment: 'Close' })}
      </Button.Primary>
    </div>
  )
}
