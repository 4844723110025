import React from 'react'

export const Key = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="30" height="30" rx="10" fill="url(#paint0_linear_9603_2370)" />
      <g filter="url(#filter0_d_9603_2370)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.676 17.7602C19.4415 18.9948 17.6716 19.3772 16.1093 18.8855L14.7738 20.2158L13.8458 19.2769C13.5913 19.0194 13.1763 19.017 12.9188 19.2715C12.6613 19.526 12.6589 19.941 12.9134 20.1985L13.842 21.1381L13.845 21.1411L13.2796 21.7042C13.083 21.9118 12.6788 22.0429 12.3838 21.9992L11.0728 21.8244C10.6357 21.7698 10.2424 21.3546 10.1769 20.9286L10.0021 19.6175C9.95838 19.3335 10.1004 18.9292 10.2971 18.7217L13.1158 15.903C12.6351 14.3406 13.0065 12.5708 14.2411 11.3362C16.011 9.56632 18.8952 9.56632 20.676 11.3362C22.4459 13.0952 22.4459 15.9794 20.676 17.7602ZM17.6316 12.7414C16.7385 12.7414 16.0145 13.4654 16.0145 14.3585C16.0145 15.2517 16.7385 15.9757 17.6316 15.9757H17.6558C18.5489 15.9757 19.2729 15.2517 19.2729 14.3585C19.2729 13.4654 18.5489 12.7414 17.6558 12.7414H17.6316Z"
          fill="url(#paint1_linear_9603_2370)"
        />
      </g>
      <rect
        x="1"
        y="1"
        width="30"
        height="30"
        rx="10"
        stroke="url(#paint2_linear_9603_2370)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_9603_2370"
          x="1.99414"
          y="4.00879"
          width="28.0098"
          height="27.999"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.733333 0 0 0 0 0.619608 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9603_2370" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9603_2370" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_9603_2370"
          x1="34.6936"
          y1="-5.05416"
          x2="5.49445"
          y2="29.6312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#252137" />
          <stop offset="0.522549" stopColor="#1B1828" stopOpacity="0.477451" />
          <stop offset="1" stopColor="#12101A" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9603_2370"
          x1="9.99414"
          y1="22.0076"
          x2="19.0954"
          y2="8.10506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF7A4" />
          <stop offset="1" stopColor="#F9C1FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9603_2370"
          x1="-3.21925"
          y1="32.3859"
          x2="47.7497"
          y2="-22.5119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12101A" />
          <stop offset="0.20722" stopColor="#12101A" />
          <stop offset="0.358276" stopColor="#111D03" />
          <stop offset="0.558955" stopColor="#322D48" />
          <stop offset="0.599175" stopColor="#191624" />
          <stop offset="0.900469" stopColor="#100E17" />
        </linearGradient>
      </defs>
    </svg>
  )
}
