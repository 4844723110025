import { useMemo } from 'react'
import { useAppSelector } from '../useAppSelector'
import { Status } from '@/backend/types'
import BigNumber from 'bignumber.js'
import { PERCENT_DECIMALS_MULTIPLIER } from '@/utils/lib/constants'

export const useBenefitsData = (): {
  userIncomePercent: number
  referralIncomePercent: number
  referralIncomePercentExample: number
  referralCashbackPercent: number
  referralCashbackPercentExample: number
  benefitsStatus: Status
} => {
  const {
    benefits: { benefits, benefitsStatus },
  } = useAppSelector(state => state.referrals)

  const {
    referralIncomePercent,
    userIncomePercent,
    referralCashbackPercent,
    referralIncomePercentExample,
    referralCashbackPercentExample,
  } = useMemo(() => {
    const referralIncomePercent = BigNumber(benefits?.maxReferrerCommissionPercent || 0)
      .multipliedBy(PERCENT_DECIMALS_MULTIPLIER)
      .toNumber()
    const userIncomePercent = BigNumber(benefits?.maxCommissionPercent || 0)
      .multipliedBy(PERCENT_DECIMALS_MULTIPLIER)
      .toNumber()

    const referralCashbackPercent = userIncomePercent - referralIncomePercent

    const deltaDefault = 5
    const deltaFallback = 2
    const delta =
      referralIncomePercent > deltaDefault
        ? deltaDefault
        : referralIncomePercent - deltaFallback > 0
        ? deltaFallback
        : new BigNumber(referralIncomePercent).minus(new BigNumber(referralIncomePercent).dividedBy(10)).toNumber()

    return {
      referralIncomePercent,
      userIncomePercent,
      referralCashbackPercent,
      referralIncomePercentExample: new BigNumber(referralIncomePercent).minus(delta).toNumber(),
      referralCashbackPercentExample: new BigNumber(referralCashbackPercent).plus(delta).toNumber(),
    }
  }, [benefits])

  return {
    userIncomePercent,
    referralIncomePercent,
    referralIncomePercentExample,
    referralCashbackPercent,
    referralCashbackPercentExample,
    benefitsStatus,
  }
}
