import React from 'react'

export const Voucher = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 20L19 21.5L22.5 17M28 29V15.8C28 13.149 25.851 11 23.2 11H16.8C14.149 11 12 13.149 12 15.8V29L13.7627 27.718C14.1163 27.4609 14.2931 27.3323 14.4856 27.2856C14.6555 27.2445 14.8332 27.2486 15.001 27.2975C15.1911 27.3529 15.3619 27.4895 15.7033 27.7626L17.25 29L19.0589 27.6844C19.3971 27.4385 19.5662 27.3155 19.7511 27.268C19.9144 27.226 20.0856 27.226 20.2489 27.268C20.4338 27.3155 20.6029 27.4385 20.9411 27.6844L22.75 29L24.2967 27.7626C24.6381 27.4895 24.8089 27.3529 24.999 27.2975C25.1668 27.2486 25.3445 27.2445 25.5144 27.2856C25.7069 27.3323 25.8837 27.4609 26.2373 27.718L28 29Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
