import { WidgetRowServerData } from '@/ui/molecules/ECharts/wrapper/settings'
import {
  BoardChunkedResponse,
  ServicesWidget,
  ServicesWidgetSource,
  ServicesWidgetType,
  ServicesWidgetType2,
} from '@/utils/types/contracts.types'
import { t } from '@lingui/macro'
import BigNumber from 'bignumber.js'

type DashboardWidgetValues = {
  volume: string | null
  winningPercent: {
    value: string | null
    counter: number | null
  }
  pieSide: {
    LONG: string | null
    SHORT: string | null
  }
  commissionUsdt: string | null
  incomeUsdt: string | null
  payOffRatio: string | null
  averageLeverage: string | null
  averageLeverageMaxLoosePercentage: string | null
  averageWinningPercent: string | null
  averageLoosingPercent: string | null
  incomeUsdtAccumulative: {
    widget: ServicesWidget
    serverData: WidgetRowServerData[] | null
  }
  incomePercentBalance: {
    widget: ServicesWidget
    serverData: WidgetRowServerData[] | null
  }
  profitByReasons: {
    widget: ServicesWidget
    serverData: WidgetRowServerData[] | null
  }
  profitByDays: {
    widget: ServicesWidget
    serverData: WidgetRowServerData[] | null
  }
}

const getDefaultAccumulativeWidget = () => ({
  title: t({
    id: 'trades.dashboard.income_usdt_accumulative_by_api_key',
    comment: 'Accumulative',
  }),
  source: ServicesWidgetSource.WidgetSourceIncomeAccumulativeByApiKey,
  type: ServicesWidgetType.WidgetTypeChart,
  type2: ServicesWidgetType2.WidgetType2Line,
  type3: '',
})

const getDefaultBalanceCumulativeWidget = () => ({
  title: t({
    id: 'trades.dashboard.income_percent_balance_cumulative',
    comment: 'Accumulative profit %',
  }),
  source: ServicesWidgetSource.WidgetSourcePercentToDepositCumulative,
  type: ServicesWidgetType.WidgetTypeChart,
  type2: ServicesWidgetType2.WidgetType2Line,
  type3: '',
})

const getDefaultProfitByDaysWidget = () => ({
  title: t({
    id: 'trades.dashboard.income_usdt_profit_by_day',
    comment: 'Profit By Day %',
  }),
  source: ServicesWidgetSource.WidgetSourceProfitByDay,
  type: ServicesWidgetType.WidgetTypeChart,
  type2: ServicesWidgetType2.WidgetType2Column,
  type3: '',
})

export const findDashboardWidgetValue = (dashboard: BoardChunkedResponse[]) => {
  const values: DashboardWidgetValues = {
    volume: null,
    winningPercent: {
      counter: null,
      value: null,
    },
    pieSide: {
      LONG: null,
      SHORT: null,
    },
    commissionUsdt: null,
    incomeUsdt: null,
    payOffRatio: null,
    averageLeverage: null,
    averageLeverageMaxLoosePercentage: null,
    averageWinningPercent: null,
    averageLoosingPercent: null,
    incomeUsdtAccumulative: {
      widget: {
        ...getDefaultAccumulativeWidget(),
        id: 1,
        status: 1,
      },
      serverData: null,
    },
    incomePercentBalance: {
      widget: {
        ...getDefaultBalanceCumulativeWidget(),
        id: 2,
        status: 1,
      },
      serverData: null,
    },
    profitByReasons: {
      widget: {},
      serverData: null,
    },
    profitByDays: {
      widget: {
        ...getDefaultProfitByDaysWidget(),
        id: 3,
        status: 1,
      },
      serverData: null,
    },
  }

  dashboard?.forEach(el => {
    switch (el.widget.source) {
      case ServicesWidgetSource.WidgetSourceVolume:
        values.volume = el.serverData?.value ?? null
        break
      case ServicesWidgetSource.WidgetSourceCommission:
        values.commissionUsdt = el.serverData?.value ?? null
        break
      case ServicesWidgetSource.WidgetSourceWinPercent:
        values.commissionUsdt = el.serverData?.value ?? null
        values.winningPercent.value = el.serverData?.value ?? null
        values.winningPercent.counter = typeof el.serverData?.counter === 'number' ? el.serverData.counter : null
        break
      case ServicesWidgetSource.WidgetSourceLongShort:
        let keys = Object.keys(el?.serverData ?? {}) ?? null

        values.pieSide.LONG =
          keys && keys.length > 0 ? (el?.serverData as Record<string, any>)?.[keys[0]]?.value ?? null : null

        values.pieSide.SHORT =
          keys && keys.length > 1 ? (el?.serverData as Record<string, any>)?.[keys[1]]?.value ?? null : null

        break
      case ServicesWidgetSource.WidgetSourceNetProfit:
        if (el.widget.type === 'number') {
          values.incomeUsdt = el.serverData?.value ?? null
        }

        if (el.widget.type === 'chart') {
          values.profitByDays.serverData = el.serverData ?? null
          values.profitByDays.widget = {
            ...el.widget,
            ...getDefaultProfitByDaysWidget(),
          }
        }
        break
      case ServicesWidgetSource.WidgetSourcePayOffRatio:
        values.payOffRatio = el.serverData?.value ?? null
        break
      case ServicesWidgetSource.WidgetSourceAvgLeverage:
        values.averageLeverage = el.serverData?.value ?? null
        break
      case ServicesWidgetSource.WidgetSourceAvgMAE:
        values.averageLeverageMaxLoosePercentage = el.serverData?.value ?? null
        break
      case ServicesWidgetSource.WidgetSourceAvgWinPercent:
        values.averageWinningPercent = el.serverData?.value ?? null
        break
      case ServicesWidgetSource.WidgetSourceAvgLossPercent:
        values.averageLoosingPercent = el.serverData?.value ?? null
        break
      case ServicesWidgetSource.WidgetSourceIncomeAccumulativeByApiKey:
        values.incomeUsdtAccumulative.widget = {
          ...el.widget,
          ...getDefaultAccumulativeWidget(),
        }
        values.incomeUsdtAccumulative.serverData = el.serverData ?? null
        break
      case ServicesWidgetSource.WidgetSourcePercentToDepositCumulative:
        values.incomePercentBalance.serverData = el.serverData ?? null
        values.incomePercentBalance.widget = {
          ...el.widget,
          ...getDefaultBalanceCumulativeWidget(),
        }
        break
      case ServicesWidgetSource.WidgetSourceProfitByReason:
        values.profitByReasons.serverData = { ...el.serverData }
        values.profitByReasons.widget = { ...el.widget }
        break
      case ServicesWidgetSource.WidgetSourceProfitByDay:
        values.profitByDays.serverData = el.serverData ?? null
        values.profitByDays.widget = {
          ...el.widget,
          ...getDefaultProfitByDaysWidget(),
        }
        break
    }
  })

  return values
}

export function percentageLong(long: string, short: string | number) {
  if (long === null || long === '' || short === '' || short === undefined || long === undefined || short === null) {
    return 0
  }
  const longCount = new BigNumber(long)
  const shortCount = new BigNumber(short)
  return longCount.dividedBy(longCount.plus(shortCount)).times(100).toNumber()
}

export function percentageShort(long: string, short: string) {
  if (long === null || long === '' || short === '' || short === undefined || long === undefined || short === null) {
    return 0
  }
  const longCount = new BigNumber(long)
  const shortCount = new BigNumber(short)
  return shortCount.dividedBy(longCount.plus(shortCount)).times(100).toNumber()
}
