import { FC } from 'react'
import style from './style.module.scss'
import clsx from 'clsx'

type DiaryWeekCardItemProps = {
  isCountPercent?: boolean
  value?: string | number
  text?: string
  sourceValue?: number
}
export const DiaryWeekCardItem: FC<DiaryWeekCardItemProps> = ({ value, text, isCountPercent, sourceValue = 0 }) => {
  let percentClassName
  if (isCountPercent) {
    percentClassName = clsx({
      [style.positive]: sourceValue > 0,
      [style.negative]: sourceValue < 0,
      [style.withoutPercentValue]: !sourceValue,
    })
  }

  return (
    <div className={style.root}>
      <div className={clsx(style.count, percentClassName && { [percentClassName]: isCountPercent })}>{value}</div>
      <div className={style.text}>{text ?? 'text'}</div>
    </div>
  )
}
