import { TExchangesAvailable } from '@/core/constants'

interface IExchangeCheckFunc {
  (exchange: TExchangesAvailable | undefined): boolean
}

export const isExchangeBinance: IExchangeCheckFunc = exchange => {
  const BINANCES: TExchangesAvailable[] = ['BINANCE_BROKER_FUTURE', 'BINANCE_VIP']
  return exchange !== undefined && BINANCES.includes(exchange)
}

export const isExchangeOkx: IExchangeCheckFunc = exchange => {
  const OKXS: TExchangesAvailable[] = ['OKX_BROKER']
  return exchange !== undefined && OKXS.includes(exchange)
}

export const isExchangeBybit: IExchangeCheckFunc = exchange => {
  const BYBITES: TExchangesAvailable[] = ['BYBIT_BROKER']
  return exchange !== undefined && BYBITES.includes(exchange)
}

export const isExchangeOkxAffiliate: IExchangeCheckFunc = exchange => {
  const OKX_AFFILIATE: TExchangesAvailable[] = ['OKX_AFFILIATE']
  return exchange !== undefined && OKX_AFFILIATE.includes(exchange)
}
