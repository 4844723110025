import { t } from '@lingui/macro'
import { FC } from 'react'
import { Loader } from '@/ui/kit/Loader'
import { EmptyList } from '@/ui/molecules'
import { Table } from '@/ui/molecules/Table'
import styles from './style.module.scss'
import { useConvertHistory } from './useConvertHistory'
import { ConvertHistoryFilter } from './ConvertHistoryFilter/ConvertHistoryFilter'
import { useAppSelector } from '@/utils'

export const ConvertHistory: FC = () => {
  const { isLoading } = useAppSelector(state => state.transactions.historyConvert)
  const { historyOutput, tableColumns } = useConvertHistory()

  return (
    <>
      <ConvertHistoryFilter />
      {isLoading && (
        <div className={styles.wrapLoader}>
          <Loader className={styles.loader} />
        </div>
      )}
      <div>
        {historyOutput.length === 0 && !isLoading && (
          <EmptyList
            label={t({
              id: 'historyConvert.table.empty',
              comment: 'History convert',
            })}
          />
        )}
        {historyOutput.length > 0 && !isLoading && <Table data={historyOutput} accessors={tableColumns} />}
      </div>
    </>
  )
}
