import { createSlice } from '@reduxjs/toolkit'
import initialState from './leaderboard.state'
import { leaderboardAsyncActions } from './leaderboard.actions'

export const slice = createSlice({
  name: `[Tiger Trade Leaderboard]`,
  initialState,
  reducers: {
    updatePeriod(state, action) {
      state.period = action.payload
    },
  },
  extraReducers: builder => {
    // GetSpotLeaderboardTC
    builder.addCase(leaderboardAsyncActions.GetSpotLeaderboardTC.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(leaderboardAsyncActions.GetSpotLeaderboardTC.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(leaderboardAsyncActions.GetSpotLeaderboardTC.fulfilled, (state, action) => {
      state.isLoading = false
      state.spotLeaders = action.payload.data
    })

    // GetFuturesLeaderboardTC
    builder.addCase(leaderboardAsyncActions.GetFuturesLeaderboardTC.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(leaderboardAsyncActions.GetFuturesLeaderboardTC.rejected, (state, action) => {})
    builder.addCase(leaderboardAsyncActions.GetFuturesLeaderboardTC.fulfilled, (state, action) => {
      state.futureLeaders = action.payload.data
    })

    // GetPnlLeaderboardTC
    builder.addCase(leaderboardAsyncActions.GetPnlLeaderboardTC.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(leaderboardAsyncActions.GetPnlLeaderboardTC.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(leaderboardAsyncActions.GetPnlLeaderboardTC.fulfilled, (state, action) => {
      state.isLoading = false
      state.pnlLeaders = action.payload.data
    })
  },
})

export const leaderboardActions = {
  ...slice.actions,
  ...leaderboardAsyncActions,
}

export const leaderboardReducer = slice.reducer
