import { IconFactory } from '@/assets/svg/cryptoIcons/IconFactory'
import { AccountOverviewAssets } from '@/backend/models/OverviewDTO'
import { overviewActions } from '@/redux/overview/overview.slice'
import { Button, ButtonVariant } from '@/ui/kit'
import { useActions, useAppSelector, useMobileSizeDetect, useToggle } from '@/utils'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, memo } from 'react'
import AssetProgressBar from '../AssetProgressBar'
import AssetItemSaleModal from './AssetItemSaleModal'
import { useSellAsset } from './hooks/useSellAsset'

import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { OVERVIEW_MAX_SMALL_ASSETS_IN_USDT } from '@/core/constants/overview'

type ContentPropsType = {
  asset: AccountOverviewAssets
}

const AssetItem: FC<ContentPropsType> = ({ asset }) => {
  const [isMobile] = useMobileSizeDetect()
  const { accountType, isHiddenSmallAssets } = useAppSelector(state => state.overview)

  const { setSellAssetStatusReset } = useActions(overviewActions)

  const [sellingModalIsOpen, toggle] = useToggle(false)

  const {
    isNotEnoughCoin,
    quantity,
    canSellAsset,
    maximumSellingValue,
    balanceInAccountCoin,
    balance,
    equivalentValue,
    symbol,
    notional,
    stableCoinSelector,
  } = useSellAsset(asset)

  const handleCloseModal = () => {
    setSellAssetStatusReset()
    toggle()
  }

  if (isHiddenSmallAssets && equivalentValue < OVERVIEW_MAX_SMALL_ASSETS_IN_USDT) {
    return null
  }

  if (isMobile) {
    return (
      <div className={clsx(isMobile && style.mobile, style.assetItem)}>
        <div className={style.itemWrapper}>
          <div className={style.left}>
            <IconFactory type={asset.asset} />

            <div className={style.currencyWrap}>
              <div className={style.currency}>{asset.asset}</div>
              <div className={style.desc}>{asset.assetFullName}</div>
            </div>
          </div>

          <div className={style.item}>
            <div className={style.quantity}>
              {balance} {asset.asset}
            </div>
            <div className={style.quantityUsdt}>
              ≈ {balanceInAccountCoin} {stableCoinSelector}
            </div>
          </div>
        </div>

        <AssetProgressBar percent={asset.percentage} className={style.barLine} />

        {accountType !== 'BINANCE_FUTURE_COIN' && accountType !== 'BINANCE_FUTURE' && asset.asset !== 'USDT' && (
          <Button
            variant={ButtonVariant.Secondary}
            className={style.sellButton}
            disabled={!canSellAsset}
            onClick={toggle}
          >
            {t({ id: 'core.sell', comment: 'Sell' })}
          </Button>
        )}

        <AssetItemSaleModal
          asset={asset}
          open={sellingModalIsOpen}
          onClose={handleCloseModal}
          symbol={symbol}
          notional={notional}
          quantity={quantity}
          isNotEnoughCoin={isNotEnoughCoin}
          equivalentValue={equivalentValue}
          maximumSellingValue={maximumSellingValue}
        />
      </div>
    )
  }

  return (
    <div
      className={clsx(style.assetItem, {
        [style.spotAsset]: accountType !== 'BINANCE_FUTURE_COIN' && accountType !== 'BINANCE_FUTURE',
      })}
      data-testid={DataTestIds.AssetItem}
    >
      <div className={style.left}>
        <IconFactory type={asset.asset} />

        <div className={style.currencyWrap}>
          <div className={style.currency} data-testid={DataTestIds.AssetItemCurrency}>
            {asset.asset}
          </div>
          <div className={style.desc} data-testid={DataTestIds.AssetItemCurrencyFull}>
            {asset.assetFullName}
          </div>
        </div>
      </div>

      <div className={style.item}>
        <div className={style.quantity} data-testid={DataTestIds.AssetItemAmount}>
          {balance} {asset.asset}
        </div>
        <div className={style.quantityUsdt} data-testid={DataTestIds.AssetItemAmountUsdt}>
          {accountType === 'BINANCE_FUTURE_COIN' ? '' : `≈ ${balanceInAccountCoin} ${stableCoinSelector}`}
        </div>
      </div>

      <AssetProgressBar percent={asset.percentage} className={style.barLine} />

      {accountType !== 'BINANCE_FUTURE_COIN' && accountType !== 'BINANCE_FUTURE' && asset.asset !== 'USDT' && (
        <Button
          variant={ButtonVariant.Secondary}
          className={style.sellButton}
          disabled={!canSellAsset}
          onClick={toggle}
          dataTestId={DataTestIds.AssetItemSellButton}
        >
          {t({ id: 'core.sell', comment: 'Sell' })}
        </Button>
      )}

      <AssetItemSaleModal
        asset={asset}
        open={sellingModalIsOpen}
        onClose={handleCloseModal}
        symbol={symbol}
        notional={notional}
        quantity={quantity}
        isNotEnoughCoin={isNotEnoughCoin}
        equivalentValue={equivalentValue}
        maximumSellingValue={maximumSellingValue}
      />
    </div>
  )
}

export default memo(AssetItem)
