import clsx from 'clsx'
import React, { FC } from 'react'
import style from './style.module.scss'

type TitleH4PropsType = {
  label?: string
  className?: string
}

export const TitleH4: FC<TitleH4PropsType> = ({ label, className }) => {
  return <h3 className={clsx(style.title, className)}>{label}</h3>
}
