import React from 'react'

export const Language = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1997 11.0498C16.6139 11.0498 16.9497 11.3856 16.9497 11.7998V14.9608H19.6228C19.8321 14.9608 20.0318 15.0482 20.1738 15.2019C20.3157 15.3556 20.387 15.5616 20.3705 15.7702C20.199 17.9314 19.28 20.1605 17.8091 21.8588C17.6562 22.0352 17.497 22.2064 17.3317 22.3714C17.8763 22.6351 18.4874 22.7829 19.133 22.7829C19.5472 22.7829 19.883 23.1187 19.883 23.5329C19.883 23.9471 19.5472 24.2829 19.133 24.2829C18.0172 24.2829 16.9771 23.9588 16.1017 23.3997C14.8769 24.2463 13.4235 24.7718 11.7998 24.7718C11.3856 24.7718 11.0498 24.436 11.0498 24.0218C11.0498 23.6076 11.3856 23.2718 11.7998 23.2718C12.937 23.2718 13.9862 22.9462 14.9129 22.3841C14.0302 21.3888 13.4942 20.079 13.4942 18.6441C13.4942 18.2299 13.83 17.8941 14.2442 17.8941C14.6584 17.8941 14.9942 18.2299 14.9942 18.6441C14.9942 19.7362 15.4171 20.7294 16.1082 21.469C16.3056 21.2829 16.4948 21.085 16.6752 20.8768C17.7526 19.6328 18.488 18.0457 18.7706 16.4608H16.1997H11.8008C11.3866 16.4608 11.0508 16.125 11.0508 15.7108C11.0508 15.2966 11.3866 14.9608 11.8008 14.9608H15.4497V11.7998C15.4497 11.3856 15.7855 11.0498 16.1997 11.0498ZM24.5107 16.9164C24.8294 16.9164 25.1133 17.1178 25.2185 17.4186L28.6407 27.1962C28.7775 27.5871 28.5715 28.015 28.1806 28.1518C27.7896 28.2887 27.3617 28.0826 27.2249 27.6917L26.7163 26.2384L22.305 26.2384L21.7964 27.6917C21.6596 28.0826 21.2317 28.2887 20.8407 28.1518C20.4498 28.015 20.2438 27.5871 20.3806 27.1962L23.8028 17.4186C23.908 17.1178 24.1919 16.9164 24.5107 16.9164ZM26.1913 24.7384L24.5107 19.9367L22.83 24.7384L26.1913 24.7384Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
