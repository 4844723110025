import { Button, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import style from './style.module.scss'
import { FC } from 'react'
import {
  KycStatusPending,
  KycStatusRejected,
  KycStatusUnverified,
  KycStatusNewUserPending,
  KycStatusNewUserReject,
  KycStatusNewUserInit,
} from './KycStatus.Components'
import { E_KYC_STATUS_USER_VIEW } from '@/redux/kyc/kyc.types'
import { t } from '@lingui/macro'

type KycRedirectionModalProps = {
  isOpen: boolean
  handleNavigateToKyc: () => void
  handleNavigateToChooseExchange: () => void
  kycStatusUserView: E_KYC_STATUS_USER_VIEW
  onClose: () => void
}

const KycStatusModal: FC<KycRedirectionModalProps> = ({
  isOpen,
  handleNavigateToKyc,
  kycStatusUserView,
  handleNavigateToChooseExchange,
  onClose,
}) => {
  function closePopup() {
    onClose()
  }

  return (
    <InteractiveModal className={style.root} isOpen={isOpen}>
      <>
        {kycStatusUserView === E_KYC_STATUS_USER_VIEW.UNVERIFIED && (
          <KycStatusUnverified handleNavigateToKyc={handleNavigateToKyc} />
        )}
        {kycStatusUserView === E_KYC_STATUS_USER_VIEW.PENDING && <KycStatusPending />}
        {kycStatusUserView === E_KYC_STATUS_USER_VIEW.REJECTED && <KycStatusRejected />}
        {kycStatusUserView === E_KYC_STATUS_USER_VIEW.NEW_USER_PENDING && (
          <KycStatusNewUserPending handleNavigateToChooseExchange={handleNavigateToChooseExchange} />
        )}
        {kycStatusUserView === E_KYC_STATUS_USER_VIEW.NEW_USER_REJECTED && (
          <KycStatusNewUserReject handleNavigateToChooseExchange={handleNavigateToChooseExchange} />
        )}
        {kycStatusUserView === E_KYC_STATUS_USER_VIEW.NEW_USER_INIT && (
          <KycStatusNewUserInit
            handleNavigateToKyc={handleNavigateToKyc}
            handleNavigateToChooseExchange={handleNavigateToChooseExchange}
          />
        )}
        <InteractiveModalParts.Button
          text={t({ id: 'core.close', comment: 'Close' })}
          variant={Button.Variant.Clean}
          onClick={closePopup}
        />
      </>
    </InteractiveModal>
  )
}
export { KycStatusModal }
