import { TLanguage } from '@/core/constants'

type LocalizedFormatters = {
  [key in TLanguage]: Intl.NumberFormat
}

const currencyFormatters: LocalizedFormatters = {
  en: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }),
  ru: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'USD' }),
  uk: new Intl.NumberFormat('uk-UA', { style: 'currency', currency: 'USD' }),
}

const decimalFormatters: LocalizedFormatters = {
  en: new Intl.NumberFormat('en-US', { style: 'decimal' }),
  ru: new Intl.NumberFormat('ru-RU', { style: 'decimal' }),
  uk: new Intl.NumberFormat('uk-UA', { style: 'decimal' }),
}

export { currencyFormatters, decimalFormatters }
