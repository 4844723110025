import { SVG } from '@/assets/svg'
import { profileActions } from '@/redux/profile/profile.slice'
import { Button, Toggle } from '@/ui/kit'
import { Popup } from '@/ui/molecules'
import { GoogleAuthenticatorForm } from '@/ui/organisms/GoogleAuthenticatorForm'
import { Popup2FAWithIntro } from '@/ui/organisms/Popup2FAWithIntro'
import { useActions, useAppSelector, useGoogleAuthCode, useGoogleAuthSettings, useToggle } from '@/utils/hooks'
import { t } from '@lingui/macro'
import { FC, useEffect } from 'react'
import style from '../../style.module.scss'
import { Reset2FA } from '@/backend/models/ProfileDTO'
import { PopupReset2FA } from './PopupReset2FA/PopupReset2FA'
import clsx from 'clsx'
import { QuestionButton } from '@/ui/atoms/QuestionButton'

const TwoFA: FC = () => {
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()
  const [isOpen, toggle] = useToggle()
  const [isPopupReset2FA, togglePopupReset2FA] = useToggle()

  const { loginTwoFa, errors, loginTwoFaToggleStatus, resetStateTwoFa } = useAppSelector(state => state.profile)

  const [twoFAModalIsOpen, toggleTwoFAModal] = useToggle()

  const { LoginWith2fa, setErrors, clearLoginTwoFaToggleStatus } = useActions(profileActions)

  useEffect(() => {
    if (loginTwoFaToggleStatus === 'succeeded') {
      toggleTwoFAModal()
    }
    return () => {
      clearLoginTwoFaToggleStatus()
    }
  }, [clearLoginTwoFaToggleStatus, loginTwoFaToggleStatus, toggleTwoFAModal])

  const handleLoginWith2fa = async (value: string) => {
    LoginWith2fa({ codes: { google: value, sms: '' }, enabled: !loginTwoFa })
  }

  function openPopupReset2fa() {
    togglePopupReset2FA()
  }

  function getTranslation(state: string | null): any {
    switch (state) {
      case Reset2FA.READY:
        return t({
          id: 'profile.reset2fa.description.available',
          comment: 'available reset 2fa',
        })
      case Reset2FA.IN_PROGRESS:
        return t({
          id: 'profile.reset2fa.description.notAvailable',
          comment: 'notAvailable reset 2fa',
        })
      case Reset2FA.USED:
        return t({
          id: 'profile.reset2fa.description.used',
          comment: 'used reset 2fa',
        })
      default:
        return 'Service not available'
    }
  }

  const isResetAvailable =
    resetStateTwoFa === Reset2FA.READY && googleAuthSettings.isLoaded && googleAuthSettings.isAccepted

  return (
    <>
      <section className={style.section}>
        <h6 className={style.sectionHeading}>{t({ id: 'profile.2fa', message: `Two-factor authentication (2FA)` })}</h6>

        <div className={style.setting}>
          <SVG.OtherIcons.Google className={style.sectionIcon} />
          <div>
            <div className={style.settingTitle}>
              {t({ id: 'profile.googleAuth', message: `Google Authentication` })}
            </div>
            <div className={style.settingStatus}>
              {googleAuthSettings.isLoaded && googleAuthSettings.isAccepted ? (
                <>
                  <SVG.Status.Completed className={style.completedIcon} />
                  {t({ id: 'core.linked', message: `Linked` })}
                </>
              ) : null}
              {googleAuthSettings.isLoaded && !googleAuthSettings.isAccepted ? (
                <>
                  <SVG.Status.Failed className={style.crossIcon} />
                  {t({ id: 'core.notLinked', message: `Not linked` })}
                </>
              ) : null}
            </div>
          </div>
          <div className={style.settingActions}>
            {googleAuthSettings.isLoaded && !googleAuthSettings.isAccepted ? (
              <Button.Primary label={t({ id: 'core.button.enable', message: `Enable` })} onClick={toggle} />
            ) : null}
          </div>
        </div>

        {/* Reser2fa */}
        <div className={style.setting}>
          <SVG.OtherIcons.BigLock />
          <div>
            <div className={style.settingWrapper}>
              <div className={style.titleReset}>
                {t({
                  id: 'profile.reset2fa.title',
                  comment: 'Use 2FA to login',
                })}
              </div>
              {resetStateTwoFa !== Reset2FA.READY && (
                <QuestionButton
                  buttonClassName={style.tooltip}
                  text={t({
                    id: 'profile.reset2fa.tooltip',
                    comment: 'Use 2FA to login',
                  })}
                  placement="right"
                />
              )}
            </div>
            <div
              className={clsx(style.settingStatus, { [style.settingAccent]: resetStateTwoFa === Reset2FA.IN_PROGRESS })}
            >
              {getTranslation(resetStateTwoFa)}
            </div>
          </div>
          <div className={style.settingActions}>
            <Button.Primary
              label={
                resetStateTwoFa === Reset2FA.READY
                  ? t({ id: 'core.reset2fa.sendLink', comment: 'Send Link' })
                  : t({ id: 'core.reset2fa.mailSended', comment: 'Mail is sended' })
              }
              onClick={openPopupReset2fa}
              disabled={!isResetAvailable}
            />
          </div>
        </div>

        {googleAuthSettings.isLoaded && googleAuthSettings.isAccepted && (
          <div className={style.setting}>
            <SVG.OtherIcons.TwoFA className={style.sectionIcon} />
            <div>
              <div className={style.settingTitle}>
                {t({
                  id: 'profile.use2FAToLogin',
                  comment: 'Use 2FA to login',
                })}
              </div>
              <div className={style.settingStatus}>
                {t({
                  id: 'profile.2faToLoginDescription',
                  comment:
                    'When you sign in, you’ll be required to use the security code provided by Google Authenticator.',
                })}
              </div>
            </div>

            <div className={style.settingActions}>
              {googleAuthSettings.isLoaded && !googleAuthSettings.isAccepted ? (
                <Button.Primary label={t({ id: 'core.button.enable', comment: 'Enable' })} onClick={toggle} />
              ) : null}
              <div className={style.settingActions}>
                <Toggle active={loginTwoFa} onChange={toggleTwoFAModal} />
              </div>
            </div>
          </div>
        )}
      </section>

      <Popup2FAWithIntro
        isOpen={isOpen}
        isIgnoreIntro
        header={
          <>
            {t({ id: 'profile.google2faTitle1', comment: 'Enable Google' })}
            <br />
            {t({ id: 'profile.google2faTitle2', comment: 'Authenticator' })}
          </>
        }
        googleAuthSettings={googleAuthSettings}
        googleAuthCode={googleAuthCode}
        onSuccess={toggle}
        onClose={toggle}
      />

      <PopupReset2FA isOpen={isPopupReset2FA} onClose={togglePopupReset2FA} />

      <Popup isOpen={twoFAModalIsOpen}>
        <GoogleAuthenticatorForm
          onCancel={toggleTwoFAModal}
          onSubmit={handleLoginWith2fa}
          errors={errors}
          setErrors={setErrors}
          isSubmitDisabled={loginTwoFaToggleStatus === 'loading'}
        />
      </Popup>
    </>
  )
}

export { TwoFA }
