import { FC, useCallback, useEffect, useMemo } from 'react'
import { ITeamFormUserSubscriptionProps } from '../TeamForm.Types'
import { Button } from '@/ui/kit'
import { useActions, useAppSelector } from '@/utils'
import { Loader } from '@/ui/kit/Loader'
import { teamActions } from '@/redux/team/team.slice'
import clsx from 'clsx'

import styles from '../style.module.scss'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'
import { t } from '@lingui/macro'

export const TeamFormLeave: FC<ITeamFormUserSubscriptionProps> = props => {
  const { handleBack, handleBackSuccess } = props
  const { teamLeaveRequestStatus } = useAppSelector(state => state.team)
  const { TeamLeaveTC, setDeleteTeamRiskManagerReset } = useActions(teamActions)

  useEffect(() => {
    if (isStatusFinal(teamLeaveRequestStatus)) setDeleteTeamRiskManagerReset()
    if (teamLeaveRequestStatus === 'succeeded') {
      handleBackSuccess && handleBackSuccess()
    }
  }, [handleBackSuccess, setDeleteTeamRiskManagerReset, teamLeaveRequestStatus])

  const onSubmit = useCallback(() => {
    TeamLeaveTC()
  }, [TeamLeaveTC])

  const isLoadingState = useMemo(() => {
    return teamLeaveRequestStatus === 'loading'
  }, [teamLeaveRequestStatus])

  if (isLoadingState) return <Loader />

  return (
    <div className={styles.root}>
      <div className={clsx(styles.formItemMedium, styles._withMargin)}>
        <Button.Primary
          label={t({
            id: 'teamLeaveForm.submit.apply',
            message: 'Leave the team',
          })}
          className={clsx(styles.btnWide, styles.redText)}
          onClick={onSubmit}
        />
      </div>
      <div className={styles.formItemMedium}>
        <Button.Primary
          label={t({
            id: 'core.cancel',
            message: `Cancel`,
          })}
          className={styles.btnWide}
          onClick={handleBack}
        />
      </div>
    </div>
  )
}
