import { FirstPlace } from './FirstPlace'
import { SecondPlace } from './SecondPlace'
import { ThirdPlace } from './ThirdPlace'
import { TigerBackground } from './TigerBackground'

export const LeaderBoard = {
  FirstPlace: FirstPlace,
  SecondPlace: SecondPlace,
  ThirdPlace: ThirdPlace,
  TigerBackground: TigerBackground,
}
