import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { googleAuthAPI } from '@/backend/api'
import { noop } from 'lodash-es'
import { CODE_LENGTH } from '@/core/config/googleAuth'

export interface UseGoogleAuthCodeResult {
  verify: () => void
  cleanCode: () => void
  code: string[]
  setCode: Dispatch<SetStateAction<string[]>>
  isCorrectCodeLength: boolean | null
  isSending: boolean | null
  isSuccess: boolean | null
  isError: boolean | null
}

export const getDefaultUseGoogleAuthCodeResult = (): UseGoogleAuthCodeResult => ({
  verify: noop,
  cleanCode: noop,
  setCode: noop,
  code: [],
  isCorrectCodeLength: null,
  isSending: null,
  isSuccess: null,
  isError: null,
})

interface State {
  isSending: UseGoogleAuthCodeResult['isSending']
  isSuccess: UseGoogleAuthCodeResult['isSuccess']
  isError: UseGoogleAuthCodeResult['isError']
}

const initialCodeArray: string[] = new Array(CODE_LENGTH).fill('')

export const useGoogleAuthCode = (): UseGoogleAuthCodeResult => {
  const [state, setStateInt] = useState<State>({
    isError: null,
    isSending: null,
    isSuccess: null,
  })
  const setState = useCallback((nextState: Partial<State>) => setStateInt(prev => ({ ...prev, ...nextState })), [])

  const [code, setCodeInt] = useState([...initialCodeArray])
  const stringifiedCode = code.join('')
  const isCorrectCodeLength = stringifiedCode.length === CODE_LENGTH

  const setCode = useCallback(code => {
    setCodeInt(code)
    setState({ isError: false })
  }, [])

  const cleanCode = useCallback(() => {
    setCodeInt([...initialCodeArray])
  }, [])

  const verify = useCallback(() => {
    setState({ isSending: true })

    googleAuthAPI
      .verifyCode({
        codes: {
          google: stringifiedCode,
        },
        enabled: true,
      })
      .then(() => {
        setState({ isSuccess: true })
        setCode([...initialCodeArray])
      })
      .catch(() => setState({ isError: true }))
      .finally(() => setState({ isSending: false }))
  }, [stringifiedCode, isCorrectCodeLength])

  return {
    verify,
    code,
    setCode,
    cleanCode,
    isCorrectCodeLength,
    ...state,
  }
}
