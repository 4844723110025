import { profileActions } from '@/redux/profile/profile.slice'
import { Loader } from '@/ui/molecules/Loader'
import { useActions, useAppSelector } from '@/utils'
import { customLocalStorage } from '@/utils/lib/customLocalStorage'
import { defineMetadata } from '@/utils/lib/metadata/mapper'
import { storage } from '@/utils/lib/storage'
import { isString } from 'lodash-es'
import { FC, useLayoutEffect, useMemo, useState } from 'react'

interface Props {
  children: React.ReactNode
}

const MigrateProvider: FC<Props> = ({ children }) => {
  const [isMigrated, setMigrated] = useState(false)

  /**
   * migrate "shownPopupList"
   * localStorage -> auth0 (metadata)
   */
  const { UpdateMetadata } = useActions(profileActions)
  const [popupList, setPopupList] = useState(() => storage.getShownPopupList())

  const migrateShownPopupList = async () => {
    if (!popupList.length) {
      return
    }

    Promise.resolve(popupList)
      .then(shownPopupList => UpdateMetadata(defineMetadata({ shownPopupList })))
      .then(() => {
        storage.cleanShownPopupList()
        setPopupList([])
      })
  }

  /**
   * migrate "accepted-offerta-${tigerTradeId}"
   * localStorage -> auth0 (metadata)
   */
  const { tigerTradeId } = useAppSelector(state => state.profile)
  const storageOffertaKey = `accepted-offerta-${tigerTradeId}`
  const [acceptedOferta, setAcceptedOferta] = useState(() => customLocalStorage.get(storageOffertaKey))

  const migrateAcceptedOferta = async () => {
    if (!isString(acceptedOferta)) {
      return
    }

    Promise.resolve(!!Number(acceptedOferta))
      .then(isAccepted => UpdateMetadata(defineMetadata({ acceptedOferta: isAccepted })))
      .then(() => {
        customLocalStorage.remove(storageOffertaKey)
        setAcceptedOferta(true)
      })
  }

  /**
   * migrate "isKycDoneNotified"
   * localStorage -> auth0 (metadata)
   */
  const { userEmail, metadata } = useAppSelector(state => state.profile)
  const isKycDoneNotified = useMemo(() => {
    return userEmail ? storage.getIsKycDoneNotified(userEmail) : null
  }, [userEmail])

  const migrateIsKycDoneNotified = async () => {
    if (!isKycDoneNotified) {
      return
    }

    Promise.resolve(metadata.shownPopupList)
      .then(shownPopupList =>
        UpdateMetadata(
          defineMetadata({
            shownPopupList: [...shownPopupList, 'kyc_success'],
          })
        )
      )
      .then(() => {
        storage.removeIsKycDoneNotified(userEmail!)
      })
  }

  const migrate = async () => {
    await migrateShownPopupList()
    await migrateAcceptedOferta()
    await migrateIsKycDoneNotified()
  }

  useLayoutEffect(() => {
    migrate().then(() => setMigrated(true))
  }, [])

  if (!isMigrated) {
    return (
      <Loader.Overlay>
        <Loader.Content />
      </Loader.Overlay>
    )
  }

  return <>{children}</>
}

export default MigrateProvider
