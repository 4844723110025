import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from '@/backend/types'
import { kycAsyncActions } from './kyc.actions'
import { initialState } from './kyc.state'
import { enqueueSnackbar } from 'notistack'
import { kycLimitsReducer } from './kyc.utils'

export const slice = createSlice({
  name: `[Tiger Trade Account KYC]`,
  initialState,
  reducers: {
    setFormKycStatus(state, action: PayloadAction<Status>) {
      state.updateFormKycStatus = action.payload
    },
    setAccessTokenForIdentityVerification(state, action: PayloadAction<string>) {
      state.accessTokenForIdentityVerification.token = action.payload
    },
    setIsVerificationFailedPopupShown(state, action: PayloadAction<boolean>) {
      state.isVerificationFailedPopupShown = action.payload
    },
    setUserKycLinkStatusRequestStatus(state, action: PayloadAction<Status | undefined>) {
      state.userKycLinkStatusRequestStatus = action.payload ?? 'idle'
    },
  },
  extraReducers: builder => {
    builder
      .addCase(kycAsyncActions.GetUserKycTC.fulfilled, (state, action) => {
        state.userKYC = action.payload
        if (state.kycTierOnLoad === undefined) state.kycTierOnLoad = action.payload.kycTier
        state.userKycStatus = 'succeeded'
      })
      .addCase(kycAsyncActions.GetUserKycTC.pending, (state, action) => {
        state.userKycStatus = 'loading'
      })
      .addCase(kycAsyncActions.GetUserKycTC.rejected, (state, action) => {
        state.userKycStatus = 'failed'
      })
      .addCase(kycAsyncActions.GetFormKycTC.fulfilled, (state, action) => {
        state.responseFormKYC = action.payload
        state.responseFormKycStatus = 'succeeded'
      })
      .addCase(kycAsyncActions.GetFormKycTC.pending, (state, action) => {
        state.responseFormKycStatus = 'loading'
      })
      .addCase(kycAsyncActions.GetFormKycTC.rejected, (state, action) => {
        state.responseFormKycStatus = 'failed'
      })
      .addCase(kycAsyncActions.UpdateFormKycTC.fulfilled, (state, action) => {
        state.responseFormKYC = action.payload
        state.updateFormKycStatus = 'succeeded'
      })
      .addCase(kycAsyncActions.UpdateFormKycTC.rejected, (state, action) => {
        state.updateFormKycStatus = 'failed'
        enqueueSnackbar('Error, try again', { variant: 'error' })
      })
      .addCase(kycAsyncActions.UpdateFormKycTC.pending, (state, action) => {
        state.updateFormKycStatus = 'loading'
      })
      .addCase(kycAsyncActions.UpdateBeneficialOwnerStatusTC.fulfilled, (state, action) => {
        state.responseFormKYC = action.payload
      })
      .addCase(kycAsyncActions.GetCountriesTC.fulfilled, (state, action) => {
        state.countries = action.payload
      })
      .addCase(kycAsyncActions.InitWorkflowTC.fulfilled, (state, action) => {
        state.accessTokenForIdentityVerification = action.payload
        state.initWorkflowStatus = 'succeeded'
      })
      .addCase(kycAsyncActions.InitWorkflowTC.pending, (state, action) => {
        state.initWorkflowStatus = 'loading'
      })
      .addCase(kycAsyncActions.GetLimitsTC.pending, (state, action) => {
        state.limitsStatus = 'loading'
      })
      .addCase(kycAsyncActions.GetLimitsTC.rejected, (state, action) => {
        state.limitsStatus = 'failed'
      })
      .addCase(kycAsyncActions.GetLimitsTC.fulfilled, (state, action) => {
        state.limitsStatus = 'succeeded'
        state.limits = action.payload
      })
      .addCase(kycAsyncActions.GetAvailableLimitsTC.pending, (state, action) => {
        state.availableLimitsStatus = 'loading'
      })
      .addCase(kycAsyncActions.GetAvailableLimitsTC.fulfilled, (state, action) => {
        const { payload } = action

        // Dirty hack to change CHF to USD in front side (agreement with backend)
        if (payload.withdrawalLimit.currency === 'CHF') {
          payload.withdrawalLimit.currency = 'USD'
          payload.depositLimit.currency = 'USD'
        }

        if (payload?.withdrawalLimit?.limit?.currency === 'CHF') {
          payload.withdrawalLimit.limit.currency = 'USD'
        }

        if (payload?.depositLimit?.limit?.currency === 'CHF') {
          payload.depositLimit.limit.currency = 'USD'
        }

        state.availableLimits = kycLimitsReducer(payload)
        state.availableLimitsStatus = 'succeeded'
      })
      .addCase(kycAsyncActions.GetAvailableLimitsTC.rejected, (state, action) => {
        state.availableLimitsStatus = 'failed'
      })
      .addCase(kycAsyncActions.GetWorkflowTC.pending, (state, action) => {
        state.workflowStatus = 'loading'
      })
      .addCase(kycAsyncActions.GetWorkflowTC.fulfilled, (state, action) => {
        state.workflow = action.payload
        state.workflowStatus = 'succeeded'
        state.workflowStatusCode = undefined
      })
      .addCase(kycAsyncActions.GetWorkflowTC.rejected, (state, action) => {
        state.workflowStatus = 'failed'
        state.workflowStatusCode = action.payload?.codes[0]
      })
      .addCase(kycAsyncActions.GetSumsubTokenTC.rejected, state => {
        state.accessTokenForIdentityVerification.token = ''
      })
      .addCase(kycAsyncActions.GetSumsubTokenTC.fulfilled, (state, action) => {
        state.accessTokenForIdentityVerification = action.payload.token
          ? action.payload
          : {
              token: '',
            }
      })
      .addCase(kycAsyncActions.GetUserKycLinkTC.fulfilled, (state, action) => {
        state.userKycLinkState = action.payload
        state.userKycLinkRequestStatus = 'succeeded'
      })
      .addCase(kycAsyncActions.GetUserKycLinkTC.pending, (state, action) => {
        state.userKycLinkRequestStatus = 'loading'
      })
      .addCase(kycAsyncActions.GetUserKycLinkTC.rejected, (state, action) => {
        state.userKycLinkRequestStatus = 'failed'
      })
      .addCase(kycAsyncActions.GetUserKycLinkStatusTC.fulfilled, (state, action) => {
        state.userKycLinkState.status = action.payload.status
        if (action.payload.status === 'SUCCESS') state.userKycLinkState.url = undefined // if status succeeded there can be no sumsub url for user
        state.userKycLinkStatusRequestStatus = 'succeeded'
      })
      .addCase(kycAsyncActions.GetUserKycLinkStatusTC.pending, (state, action) => {
        state.userKycLinkStatusRequestStatus = 'loading'
      })
      .addCase(kycAsyncActions.GetUserKycLinkStatusTC.rejected, (state, action) => {
        state.userKycLinkStatusRequestStatus = 'failed'
      })
  },
})

export const kycActions = {
  ...slice.actions,
  ...kycAsyncActions,
}

export const kycReducer = slice.reducer
