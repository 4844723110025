import React from 'react'

export const Options = () => {
  return (
    <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 2.38889C4 3.43209 3.10457 4.27778 2 4.27778C0.89543 4.27778 0 3.43209 0 2.38889C0 1.34568 0.89543 0.5 2 0.5C3.10457 0.5 4 1.34568 4 2.38889Z"
        fill="white"
      />
      <path
        d="M4 15.6111C4 16.6543 3.10457 17.5 2 17.5C0.89543 17.5 0 16.6543 0 15.6111C0 14.5679 0.89543 13.7222 2 13.7222C3.10457 13.7222 4 14.5679 4 15.6111Z"
        fill="white"
      />
      <path
        d="M4 9C4 10.0432 3.10457 10.8889 2 10.8889C0.89543 10.8889 0 10.0432 0 9C0 7.9568 0.89543 7.11111 2 7.11111C3.10457 7.11111 4 7.9568 4 9Z"
        fill="white"
      />
    </svg>
  )
}
