import { FC } from 'react'

export const TigerColor: FC = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="35" height="35" rx="10" fill="white" />
      <rect width="35" height="35" rx="10" fill="url(#paint0_radial_15530_25567)" />
      <path
        d="M17.1455 14.4952L12.4219 11.375H22.5734L17.8495 14.4952C17.6367 14.6358 17.3583 14.6358 17.1455 14.4952Z"
        fill="white"
      />
      <path
        d="M25.375 19.0115V16.3167L23.2492 17.7214C22.9017 17.951 22.6933 18.335 22.6933 18.7459V21.4406L24.8192 20.036C25.1667 19.8063 25.375 19.4224 25.375 19.0115Z"
        fill="white"
      />
      <path
        d="M17.9455 22.4144L20.965 25.375H14.035L17.0545 22.4144C17.3006 22.1732 17.6994 22.1732 17.9455 22.4144Z"
        fill="white"
      />
      <path
        d="M12.3013 21.4374V18.7426C12.3013 18.3318 12.0929 17.9478 11.7454 17.7181L9.625 16.3171V19.0118C9.625 19.4227 9.83334 19.8067 10.1808 20.0363L12.3013 21.4374Z"
        fill="white"
      />
      <path
        d="M25.375 14.07V11.3753L19.1905 15.4617C18.6258 15.8349 18.2873 16.4589 18.2873 17.1265V19.7669C18.2873 20.0945 18.42 20.4088 18.6563 20.6404L21.1223 23.0583V22.7162H21.1213V18.3562C21.1213 17.8436 21.382 17.3643 21.8174 17.0768L24.8192 15.0945C25.1667 14.8649 25.375 14.4809 25.375 14.07Z"
        fill="white"
      />
      <path
        d="M16.7123 19.7674C16.7123 20.095 16.5795 20.4092 16.3432 20.6409L13.8773 23.0587V19.6283L13.8733 19.6257V19.1242L13.8773 19.1268V18.3567C13.8773 17.8442 13.6166 17.3651 13.1815 17.0775L10.1808 15.0948C9.83334 14.8652 9.625 14.4812 9.625 14.0704V11.3756L15.809 15.4617C16.3737 15.8349 16.7123 16.4589 16.7123 17.1265V19.7674Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_15530_25567"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(40.8151) scale(46.246 611.146)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
