import React from 'react'

export const TT = ({ width = '50', height = '50' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient
          id="radialGradient"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(41) scale(66 873)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>

      <path
        d="M0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25Z"
        fill="white"
      />
      <path
        d="M0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25Z"
        fill="url(#radialGradient)"
      />
      <path
        d="M24.342 19.3215L16.1181 13.7498H33.792L25.5677 19.3216C25.1972 19.5725 24.7125 19.5725 24.342 19.3215Z"
        fill="white"
      />
      <path
        d="M38.6695 27.3863V22.5743L34.9685 25.0826C34.3635 25.4926 34.0008 26.1783 34.0008 26.912V31.724L37.7018 29.2157C38.3068 28.8057 38.6695 28.12 38.6695 27.3863Z"
        fill="white"
      />
      <path
        d="M25.7347 33.463L30.9917 38.7498H18.9266L24.1836 33.463C24.6119 33.0323 25.3064 33.0323 25.7347 33.463Z"
        fill="white"
      />
      <path
        d="M15.9082 31.7183V26.9062C15.9082 26.1725 15.5455 25.4868 14.9404 25.0768L11.2488 22.5749V27.3869C11.2488 28.1206 11.6115 28.8063 12.2165 29.2163L15.9082 31.7183Z"
        fill="white"
      />
      <path
        d="M38.6695 18.5623V13.7503L27.9023 21.0475C26.9192 21.7138 26.3298 22.8281 26.3298 24.0203V28.7353C26.3298 29.3203 26.5609 29.8815 26.9723 30.2952L31.2655 34.6127V34.0018H31.2638V26.2162C31.2638 25.3007 31.7178 24.4449 32.4757 23.9315L37.7018 20.3918C38.3068 19.9817 38.6695 19.296 38.6695 18.5623Z"
        fill="white"
      />
      <path
        d="M23.5877 28.7361C23.5877 29.3212 23.3566 29.8823 22.9452 30.2961L18.652 34.6136V28.4878L18.6452 28.4831V27.5876L18.652 27.5922V26.2171C18.652 25.3019 18.1982 24.4463 17.4407 23.9329L12.2165 20.3923C11.6115 19.9823 11.2488 19.2966 11.2488 18.5629V13.7509L22.0151 21.0475C22.9983 21.7138 23.5877 22.8281 23.5877 24.0203V28.7361Z"
        fill="white"
      />
    </svg>
  )
}
