import { FC } from 'react'

interface ScreenShotProps {
  className?: string
  size?: number
}

export const ScreenShot: FC<ScreenShotProps> = ({ className, size = 14 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_2106_10563)">
        <path
          d="M0.561404 4.55933C0.933336 4.55933 1.12983 4.34493 1.12983 3.97332V2.28674C1.12983 1.54351 1.51579 1.16475 2.21755 1.16475H3.91579C4.28773 1.16475 4.49123 0.957494 4.49123 0.585879C4.49123 0.214262 4.28773 0.0141602 3.91579 0.0141602H2.20351C0.736843 0.0141602 0 0.750248 0 2.22243V3.97332C0 4.34493 0.203509 4.55933 0.561404 4.55933ZM13.172 4.55933C13.5439 4.55933 13.7404 4.34493 13.7404 3.97332V2.22243C13.7404 0.750248 13.0035 0.0141602 11.5369 0.0141602H9.81755C9.45264 0.0141602 9.24914 0.214262 9.24914 0.585879C9.24914 0.957494 9.45264 1.16475 9.81755 1.16475H11.5158C12.2105 1.16475 12.6105 1.54351 12.6105 2.28674V3.97332C12.6105 4.34493 12.814 4.55933 13.172 4.55933ZM2.20351 13.9999H3.91579C4.28773 13.9999 4.49123 13.7926 4.49123 13.4281C4.49123 13.0565 4.28773 12.8493 3.91579 12.8493H2.21755C1.51579 12.8493 1.12983 12.4705 1.12983 11.7273V10.0407C1.12983 9.6619 0.926315 9.45466 0.561404 9.45466C0.196492 9.45466 0 9.6619 0 10.0407V11.7844C0 13.2638 0.736843 13.9999 2.20351 13.9999ZM9.81755 13.9999H11.5369C13.0035 13.9999 13.7404 13.2566 13.7404 11.7844V10.0407C13.7404 9.6619 13.5369 9.45466 13.172 9.45466C12.8071 9.45466 12.6105 9.6619 12.6105 10.0407V11.7273C12.6105 12.4705 12.2105 12.8493 11.5158 12.8493H9.81755C9.45264 12.8493 9.24914 13.0565 9.24914 13.4281C9.24914 13.7926 9.45264 13.9999 9.81755 13.9999Z"
          fill="white"
        />
        <path
          d="M3.74079 10.2338H10.0075C10.7443 10.2338 11.1232 9.86222 11.1232 9.11893V5.34562C11.1232 4.58808 10.7443 4.21647 10.0075 4.21647H9.09515C8.82151 4.21647 8.73729 4.16644 8.57589 3.98063L8.28816 3.65904C8.11275 3.45894 7.9303 3.35889 7.57237 3.35889H6.15483C5.78992 3.35889 5.60746 3.45894 5.43202 3.65904L5.1443 3.98063C4.9829 4.15929 4.89869 4.21647 4.62501 4.21647H3.74079C2.99693 4.21647 2.625 4.58808 2.625 5.34562V9.11893C2.625 9.86222 2.99693 10.2338 3.74079 10.2338ZM6.88466 9.34052C5.71273 9.34052 4.77237 8.38999 4.77237 7.18941C4.77237 6.00309 5.71273 5.05261 6.88466 5.05261C8.04255 5.05261 8.97586 6.00309 8.97586 7.18941C8.97586 8.41143 8.04255 9.34052 6.88466 9.34052ZM6.87764 8.71878C7.6987 8.71878 8.37238 8.04699 8.37238 7.18941C8.37238 6.34613 7.6987 5.66721 6.87764 5.66721C6.04957 5.66721 5.37588 6.34613 5.37588 7.18941C5.37588 8.04699 6.04957 8.71878 6.87764 8.71878ZM9.67764 6.16746C9.41801 6.16746 9.20747 5.95307 9.20747 5.6815C9.20747 5.40993 9.41801 5.19554 9.67764 5.19554C9.93727 5.19554 10.1549 5.40993 10.1549 5.6815C10.1549 5.95307 9.93727 6.16746 9.67764 6.16746Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2106_10563">
          <rect width={size} height={size} fill="currentValue" />
        </clipPath>
      </defs>
    </svg>
  )
}
