import style from './style.module.scss'
import { ButtonVariant, InteractiveModalParts } from '../../kit'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../../router/urls'
import { t } from '@lingui/macro'

export const WalletSetupApiKeys = () => {
  const navigate = useNavigate()
  return (
    <div className={style.container}>
      <div className={style.header}>
        {t({ id: 'mainPage.apikeys.title', message: 'Start by creating API key and funding your wallet' })}
      </div>
      <div className={style.text}>
        {t({ id: 'mainPage.apikeys.subtitle', message: 'Trade with your Tiger.Trade account and get cashback' })}
      </div>
      <InteractiveModalParts.Button
        text={t({ id: 'core.apiKeys.createLabel', message: 'Create API key' })}
        variant={ButtonVariant.Accent}
        onClick={() => navigate(urls.apiCreate)}
      />
    </div>
  )
}
