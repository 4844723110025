import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useActions, useAppSelector } from '@/utils'
import clsx from 'clsx'
import { List } from '@/ui/molecules/List'
import { IListItem } from '@/ui/molecules/List/List'
import { getPartnerTranslations } from '@/translations/partner'
import { Button, ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { CardWrapper } from '@/ui/kit/CardWrapper'
import { SVG } from '@/assets/svg'
import { ACHIEVEMENTS_CONTENT } from '@/core/config/partner'
import { useNavigate } from 'react-router-dom'
import { partnerActions } from '@/redux/partner/partner.slice'
import { usePrevious } from '@/utils/hooks/usePrevious'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'
import { appActions } from '@/redux/app/app.slice'

import styles from '../style.module.scss'
import { urls } from '@/router/urls'

export const PartnerWidget: FC<{ callback?: Function }> = props => {
  const { callback } = props

  const { setExchangeType } = useActions(appActions)
  const { exchangeType } = useExchageType()

  const navigate = useNavigate()
  const {
    achievements,
    awardClaimed,
    unlockedAll,
    achievementsUnlockedCount,
    achievementPending,
    claimStatus,
    partner,
  } = useAppSelector(state => state.partner)
  const previousClaimStatus = usePrevious(claimStatus)
  const [modalOpen, setModalOpen] = useState(false)
  const translations = useMemo(() => {
    return getPartnerTranslations()
  }, [])
  const listOutput = useMemo<IListItem[]>(() => {
    return achievements.map(achievement => {
      return {
        id: achievement.achievementType,
        text: <>{translations.achievementsVocabulary[achievement.achievementType].titleShort}</>,
        done: achievement.unlocked,
      }
    })
  }, [achievements, translations])
  const listClaimed = useMemo<IListItem[]>(() => {
    return [
      {
        id: 'awardClaimed',
        text: translations.claim.widgetAfter,
        done: true,
      },
    ]
  }, [translations])

  const { ClaimPartnerRewardTC } = useActions(partnerActions)
  const handleClaim = useCallback(async () => {
    if (partner !== undefined) await ClaimPartnerRewardTC(partner)
    return
  }, [partner])
  const handleClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  useEffect(() => {
    if (claimStatus === 'succeeded' && previousClaimStatus !== 'succeeded') setModalOpen(true)
  }, [claimStatus, previousClaimStatus])

  function handleClick(link: string) {
    navigate(link)

    if (exchangeType !== EXCHANGES_TYPE.BINANCE_BROKER_FUTURE && link === urls.kyc) {
      setExchangeType(EXCHANGES_TYPE.BINANCE_BROKER_FUTURE)
    }
  }

  return (
    <>
      <CardWrapper className={styles.widget}>
        <div className={styles.header}>
          <div className={styles.headerText}>{translations.widget.title}</div>
          <div className={styles.headerIcon}>
            <SVG.OtherIcons.TigerColor />
          </div>
        </div>
        <div className={styles.perform}>
          <div className={styles.performTop}>
            <div className={styles.performText}>
              {translations.widget.perform.msg1}{' '}
              <span className={styles.gradientText}>{translations.widget.perform.msg2}</span>
            </div>
            <div className={styles.performCount}>
              {achievementsUnlockedCount}/{achievements.length}
            </div>
          </div>
          <div className={styles.indicator}>
            {achievements.map(achivement => {
              return (
                <div
                  className={clsx(styles.indicatorItem, {
                    [styles.active]: achivement.unlocked,
                  })}
                  style={{
                    flexBasis: `calc(${100 / achievements.length}% - 5px)`,
                  }}
                  key={achivement.achievementType}
                ></div>
              )
            })}
          </div>
        </div>
        {!awardClaimed && <List list={listOutput} className={styles.list} />}
        {awardClaimed && <List list={listClaimed} className={styles.list} />}
        <div className={styles.control}>
          {unlockedAll && !awardClaimed && (
            <Button.Accent className={styles.btn} label={translations.widget.recieve} onClick={handleClaim} />
          )}
          {achievementPending && (
            <Button.Accent
              className={styles.btn}
              label={translations.achievementsVocabulary[achievementPending.achievementType].todo}
              onClick={() => {
                handleClick(ACHIEVEMENTS_CONTENT[achievementPending?.achievementType].link)
              }}
            />
          )}
          {callback && (
            <Button.Primary
              className={styles.btn}
              label={translations.achievements.about}
              onClick={() => {
                callback()
              }}
            />
          )}
        </div>
      </CardWrapper>

      {claimStatus && (
        <InteractiveModal isOpen={modalOpen} onClose={handleClose}>
          <InteractiveModalParts.MainInfo text={translations.claim.title} />
          <InteractiveModalParts.Explanation text={translations.claim.subtitle} />
          <InteractiveModalParts.Button
            text={translations.claim.close}
            variant={ButtonVariant.White}
            onClick={handleClose}
          />
        </InteractiveModal>
      )}
    </>
  )
}
