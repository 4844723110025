import { FC, memo, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { Button, Dropdown, DropDownType, miniHintWrapperClassName, MiniHint } from '@/ui/kit'
import { overviewActions } from '@/redux/overview/overview.slice'
import { useActions } from '@/utils/hooks/useActions'
import { useOverview } from '@/utils/hooks/useOverview'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import style from './style.module.scss'

const Sorting: FC = () => {
  const dropDownOptionsForOrders: DropDownType<'updateTime' | 'status'>[] = [
    { label: t({ message: 'Date', id: 'core.date' }), value: 'updateTime' },
    { label: t({ message: `Status`, id: 'core.status' }), value: 'status' },
  ]

  const dropDownOptionsForPositions: DropDownType<'updateTime' | 'unrealizedPnl'>[] = [
    { label: t({ message: 'Date', id: 'core.date' }), value: 'updateTime' },
    { label: t({ message: 'PNL', id: 'core.pnl' }), value: 'unrealizedPnl' },
  ]

  const { tabId, instrumentType, shownOrdersOrPositionsLength, cancelAllOrders, cancelAllPositions } = useOverview()

  const { sortOrdersPositions } = useActions(overviewActions)

  const [isAscending, setIsAscending] = useState(true)
  const [sortingCondition, setSortingCondition] = useState<DropDownType>(dropDownOptionsForOrders[0])

  useEffect(() => {
    setSortingCondition(tabId === 1 ? dropDownOptionsForOrders[0] : dropDownOptionsForPositions[0])
  }, [tabId])

  const changeSortDirection = useCallback(() => {
    setIsAscending(value => !value)
    sortOrdersPositions({ type: sortingCondition.value, isAscending: !isAscending })
  }, [sortOrdersPositions, isAscending, sortingCondition])

  const handleChangeSortCondition = useCallback(
    (item: DropDownType) => {
      setSortingCondition(item)
      sortOrdersPositions({ type: item.value, isAscending })
    },
    [isAscending, sortOrdersPositions]
  )

  const handleCancelAll = useCallback(
    () => (tabId === 1 ? cancelAllOrders(instrumentType) : cancelAllPositions(instrumentType)),
    [tabId, instrumentType, cancelAllOrders, cancelAllPositions]
  )

  return (
    <div className={style.sorting}>
      <span className={style.label}>{t({ message: 'Sort by', id: 'core.sortBy' })}</span>
      <Dropdown
        options={tabId === 1 ? dropDownOptionsForOrders : dropDownOptionsForPositions}
        setSelectedOption={handleChangeSortCondition}
        selectedOption={sortingCondition}
        dataTestId={DataTestIds.SortDropdown}
      />
      <Button.Secondary
        onClick={changeSortDirection}
        className={clsx(style.sort, isAscending && style.activeSort)}
        leftIcon={<SVG.Additional.Arrow />}
        dataTestId={DataTestIds.SortButton}
      />
      <div className={clsx(style.closeHolder, miniHintWrapperClassName)}>
        {shownOrdersOrPositionsLength! > 0 && (
          <>
            <Button.Secondary
              onClick={handleCancelAll}
              label={
                tabId === 1
                  ? t({ message: 'Close all', id: 'orders.closeAll' })
                  : t({ comment: 'Close positions', id: 'positions.closeAll' })
              }
              rightIcon={<SVG.Additional.Close />}
              className={style.close}
              dataTestId={DataTestIds.CloseAllButton}
            />
            <MiniHint
              text={
                tabId === 1
                  ? t({ message: 'Cancel all orders', id: 'core.cancelAllOrders' })
                  : t({ message: `Close all position (market)`, id: 'core.closeAllPositionsMarket' })
              }
            />
          </>
        )}
      </div>
    </div>
  )
}

export default memo(Sorting)
