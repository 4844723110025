export const LogoQR = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.980469" y="0.814575" width="30.93" height="30.93" rx="8.80573" fill="#FAFAFA" />
    <path
      d="M16.5469 11.7645L10.8314 7.86389H23.1146L17.3988 11.7645C17.1413 11.9402 16.8044 11.9402 16.5469 11.7645Z"
      fill="#011119"
    />
    <path
      d="M26.5044 17.4103V14.0416L23.9322 15.7975C23.5118 16.0846 23.2597 16.5646 23.2597 17.0782V20.4469L25.8319 18.691C26.2523 18.4039 26.5044 17.9239 26.5044 17.4103Z"
      fill="#011119"
    />
    <path
      d="M17.5149 21.6643L21.1684 25.3654H12.7833L16.4368 21.6643C16.7345 21.3628 17.2172 21.3628 17.5149 21.6643Z"
      fill="#011119"
    />
    <path
      d="M10.6855 20.4429V17.0742C10.6855 16.5606 10.4334 16.0805 10.0129 15.7935L7.44727 14.042V17.4107C7.44727 17.9243 7.69935 18.4044 8.11983 18.6914L10.6855 20.4429Z"
      fill="#011119"
    />
    <path
      d="M26.5044 11.233V7.86427L19.0213 12.9728C18.338 13.4392 17.9284 14.2192 17.9284 15.0539V18.3546C17.9284 18.7642 18.089 19.157 18.3749 19.4467L21.3587 22.4692V22.0415H21.3575V16.5911C21.3575 15.9503 21.673 15.3511 22.1998 14.9917L25.8319 12.5137C26.2523 12.2266 26.5044 11.7466 26.5044 11.233Z"
      fill="#011119"
    />
    <path
      d="M16.0227 18.3552C16.0227 18.7648 15.8621 19.1576 15.5761 19.4473L12.5924 22.4698V18.1814L12.5877 18.1781V17.5512L12.5924 17.5544V16.5917C12.5924 15.9511 12.2771 15.3521 11.7506 14.9927L8.11983 12.5141C7.69935 12.227 7.44727 11.747 7.44727 11.2334V7.86468L14.9298 12.9728C15.6131 13.4392 16.0227 14.2192 16.0227 15.0539V18.3552Z"
      fill="#011119"
    />
  </svg>
)
