import axios, { AxiosResponse } from 'axios'
import { chunkedResponseInterceptor, sentryErrorInterceptor, traceRequestInterceptor } from '../interceptors'

const axiosMainService = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_IP,
})

axiosMainService.interceptors.response.use((response: AxiosResponse) => response, sentryErrorInterceptor)
axiosMainService.interceptors.response.use(chunkedResponseInterceptor)
axiosMainService.interceptors.request.use(traceRequestInterceptor)

export { axiosMainService }
