import { E_RISK_MANAGER_DRAWDOWN_TYPE } from '@/redux/team/team.types'
import { t } from '@lingui/macro'

export const getDrawdownTypes = (): Record<E_RISK_MANAGER_DRAWDOWN_TYPE, string> => {
  return {
    percent: '%',
    usdt: 'USDT',
  }
}

export const getDrawdownTypesText = (): Record<E_RISK_MANAGER_DRAWDOWN_TYPE, string> => {
  return {
    percent: t({ id: 'riskManager.drawdownType.percent' }),
    usdt: t({ id: 'riskManager.drawdownType.usdt' }),
  }
}
