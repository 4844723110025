import React from 'react'

interface IAdditionalInfoProps {
  className?: string
}

export const AdditionalInfo: React.FC<IAdditionalInfoProps> = ({ className }) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_16816_1836)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 15.1C11 13.1118 12.6118 11.5 14.6 11.5H25.4C27.3882 11.5 29 13.1118 29 15.1V16.225H20H11V15.1ZM11 17.575V25.9C11 27.8882 12.6118 29.5 14.6 29.5H19.325V17.575H11ZM20.675 29.5H25.4C27.3882 29.5 29 27.8882 29 25.9V17.575H20.675V29.5Z"
        fill="#FAFAFA"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16816_1836"
          x1="-5.27786e-07"
          y1="40.5"
          x2="35.084"
          y2="-3.42643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC4ED" />
          <stop offset="0.482059" stopColor="#FEA471" />
          <stop offset="1" stopColor="#FF627E" />
        </linearGradient>
      </defs>
    </svg>
  )
}
