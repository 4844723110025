import { Portal } from '@/ui/utils/Portal'
import { FC, ReactNode } from 'react'
import { useAnimatedMount } from './hooks/useAnimatedMount'
import { DialogLayout } from './DialogLayout'

interface Props {
  isOpened: boolean
  onClose: () => void
  staticContent?: ReactNode
  children?: ReactNode
  className?: string
  label?: ReactNode
}

// Simple/dumb implementation of sheet component
// P.S. can be improved if desired
export const SheetDialog: FC<Props> = ({ isOpened, onClose, children, label, className, staticContent }) => {
  const isMounted = useAnimatedMount({ opened: isOpened })

  if (!isMounted) {
    return null
  }

  return (
    <Portal>
      <DialogLayout
        label={label}
        onClose={onClose}
        isOpened={isOpened}
        className={className}
        staticContent={staticContent}
      >
        {children}
      </DialogLayout>
    </Portal>
  )
}
