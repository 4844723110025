import { InitialState } from './okxAffiliate.types'

export const initialState: InitialState = {
  historyOperations: {
    data: [],
    page: 0,
    size: 20,
    total: 0,
  },
  userStatus: null,
  responseDetails: {
    isSuccess: false,
    isCompleted: false,
    errorCode: null,
  },
  isLoading: false,
  isLoadingHistory: false,
  isOfferPopup: false,
  isOpenAddKeyPopup: false,
  errors: [],
  config: {
    okxPercent: 20,
  },
}

export default initialState
