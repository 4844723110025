import { FC, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import QRCode from 'react-qr-code'
import s from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import clsx from 'clsx'
import { useMobileSizeDetect } from '@/utils'

interface IQRCodeIconProps {
  text: string
  className?: string
}

const QRCodeIcon: FC<IQRCodeIconProps> = props => {
  const { text, className } = props

  const [isMobile] = useMobileSizeDetect()
  const [active, setActive] = useState(false)

  const qrCode = useMemo(() => {
    return encodeURI(text)
  }, [text])

  const handleActivate = () => {
    setActive(true)
  }

  const handleDeactivete = () => {
    setActive(false)
  }

  return (
    <>
      <button
        className={clsx(s.root, className)}
        onClick={handleActivate}
        data-testid={DataTestIds.DepositAddressQRButton}
      >
        <SVG.OtherIcons.ScanQRCode />
      </button>

      <InteractiveModal isOpen={active} className={s.popup}>
        <div className={s.qrWrap} data-testid={DataTestIds.DepositAddressQRImage}>
          <QRCode
            value={qrCode}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            level={'M'}
            size={146}
            viewBox={`0 0 146 146`}
          />
        </div>
        <InteractiveModalParts.SubHeader
          className={clsx(isMobile && [s.mobile, s.subHeader])}
          text={t({ id: 'wallet.scanQRCode', message: 'Scan QR' })}
        />
        <InteractiveModalParts.SubExplanation
          className={clsx(isMobile && [s.mobile, s.subExplanation])}
          text={t({ id: 'wallet.withApp', message: 'with your crypto wallet app' })}
        />
        <InteractiveModalParts.Button
          text={t({ id: 'core.button.close', message: 'Close' })}
          variant={ButtonVariant.Primary}
          onClick={handleDeactivete}
          dataTestId={DataTestIds.DepositAddressPopupQRCloseButton}
        />
      </InteractiveModal>
    </>
  )
}

export { QRCodeIcon }
