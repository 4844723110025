import style from './style.module.scss'
import { ButtonVariant, InteractiveModalParts } from '../../kit'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../../router/urls'
import { t } from '@lingui/macro'

export const WalletSetupKYC = () => {
  const navigate = useNavigate()
  return (
    <div className={style.container}>
      <div className={style.header}>
        {t({ id: 'mainPage.setupKYC.title', message: 'Verify your account to fund wallet' })}
      </div>
      <div className={style.text}>
        {t({
          id: 'mainPage.setupKYC.subTitle',
          message: 'You’ll be able to deposit funds after completing verification',
        })}
      </div>
      <InteractiveModalParts.Button
        text={t({ id: 'core.verifyMe', message: 'Verify me' })}
        variant={ButtonVariant.Accent}
        onClick={() => navigate(urls.kyc)}
      />
    </div>
  )
}
