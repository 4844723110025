import React from 'react'

interface IHome {
  className?: string
}

export const Home: React.FC<IHome> = props => {
  const { className } = props

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_13233_41444)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7961 30.489H24C23.3939 30.489 22.8981 29.9931 22.8981 29.3871V27.3044C22.8981 25.9601 21.5 24.5 20 24.5C18.5 24.5 17.1019 25.6626 17.1019 27.1612V29.3871C17.1019 29.9931 16.6061 30.489 16 30.489L12.2039 30.5C10.9917 30.5 10 29.5083 10 28.2962V18.7424C10 18.1143 10.2645 17.5192 10.7273 17.1005L18.5179 11.0702C19.3554 10.3099 20.6336 10.3099 21.4711 11.0702L29.2727 17.0895C29.7355 17.5082 30 18.1033 30 18.7314V28.2851C30 29.4972 29.0083 30.489 27.7961 30.489ZM22.0993 19.2167C22.0993 20.379 21.157 21.3213 19.9946 21.3213C18.8322 21.3213 17.8899 20.379 17.8899 19.2167C17.8899 18.0543 18.8322 17.112 19.9946 17.112C21.157 17.112 22.0993 18.0543 22.0993 19.2167Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13233_41444"
          x1="-5.27786e-07"
          y1="40.5"
          x2="35.084"
          y2="-3.42643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC4ED" />
          <stop offset="0.482059" stopColor="#FEA471" />
          <stop offset="1" stopColor="#FF627E" />
        </linearGradient>
      </defs>
    </svg>
  )
}
