import React from 'react'

interface BinanceSmallProps {
  className?: string
}

const BinanceSmall: React.FC<BinanceSmallProps> = props => {
  const { className } = props

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_14824_2398)">
        <g clipPath="url(#clip1_14824_2398)">
          <path
            d="M7.60207 10.085L12.2629 5.42436L16.9262 10.0875L19.6382 7.37547L12.2629 0L4.89008 7.37301L7.60207 10.085Z"
            fill="#F3BA2F"
          />
          <path d="M5.68688 11.9995L2.97493 9.2876L0.262851 11.9997L2.9748 14.7116L5.68688 11.9995Z" fill="#F3BA2F" />
          <path
            d="M7.60207 13.9151L12.2629 18.5758L16.926 13.9128L19.6395 16.6233L19.6382 16.6248L12.2629 24.0001L4.88988 16.6273L4.88609 16.6235L7.60207 13.9151Z"
            fill="#F3BA2F"
          />
          <path d="M21.5511 14.7128L24.2632 12.0007L21.5513 9.28879L18.8392 12.0009L21.5511 14.7128Z" fill="#F3BA2F" />
          <path
            d="M15.0137 11.9986H15.0149L12.2629 9.24658L10.2291 11.2803H10.229L9.99542 11.5141L9.51337 11.9961L9.50958 11.9999L9.51337 12.0039L12.2629 14.7534L15.0149 12.0014L15.0162 11.9999L15.0137 11.9986Z"
            fill="#F3BA2F"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_14824_2398">
          <rect width="24" height="24" fill="white" transform="translate(0.366486)" />
        </clipPath>
        <clipPath id="clip1_14824_2398">
          <rect width="24" height="24" fill="white" transform="translate(0.366486)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export { BinanceSmall }
