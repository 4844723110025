import { FC } from 'react'

export const Link: FC = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.27 12.5C2.48 11.55 2 10.33 2 9C2 5.98 4.47 3.5 7.5 3.5H12.5C15.52 3.5 18 5.98 18 9C18 12.02 15.53 14.5 12.5 14.5H10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.73 12.5C21.52 13.45 22 14.67 22 16C22 19.02 19.53 21.5 16.5 21.5H11.5C8.48 21.5 6 19.02 6 16C6 12.98 8.47 10.5 11.5 10.5H14"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
