import { TExchangesAvailable } from '@/core/constants'
import { t } from '@lingui/macro'

export const getCashbackLeftBarTranslations = (): Record<
  TExchangesAvailable,
  { feesHeading: string; feesSubheading: string }
> => {
  return {
    BINANCE_BROKER_FUTURE: {
      feesHeading: t({
        id: 'cashbackPage.fees.binance.heading',
        message: 'About Binance fees',
      }),
      feesSubheading: t({
        id: 'cashbackPage.fees.binance.subheading',
        message: 'Binance fees',
      }),
    },
    BINANCE_VIP: {
      feesHeading: t({
        id: 'cashbackPage.fees.binance.heading',
        message: 'About Binance fees',
      }),
      feesSubheading: t({
        id: 'cashbackPage.fees.binance.subheading',
        message: 'Binance fees',
      }),
    },
    OKX_BROKER: {
      feesHeading: t({
        id: 'cashbackPage.fees.okx.heading',
        message: 'About Okx fees',
      }),
      feesSubheading: t({
        id: 'cashbackPage.fees.okx.subheading',
        message: 'Okx fees',
      }),
    },
    BYBIT_BROKER: {
      feesHeading: t({
        id: 'cashbackPage.fees.bybit.heading',
        message: 'About Bybit fees',
      }),
      feesSubheading: t({
        id: 'cashbackPage.fees.bybit.subheading',
        message: 'Bybit fees',
      }),
    },
    OKX_AFFILIATE: {
      feesHeading: t({
        id: 'cashbackPage.fees.okx.heading',
        message: 'About Okx fees',
      }),
      feesSubheading: t({
        id: 'cashbackPage.fees.okx.subheading',
        message: 'Okx fees',
      }),
    },
  }
}
