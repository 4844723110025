import { tradesActions } from '@/redux/trades/trades.slice'
import { useActions, useAppSelector } from '@/utils'
import { useEffect } from 'react'

interface IUseTMMAccountInfoProps {
  isStatisticsAvailable: boolean
}

export function useTMMAccountInfo({ isStatisticsAvailable }: IUseTMMAccountInfoProps) {
  const { me, categories, tags, tickers, tickersStatus, uiDataStatus, getMeStatus } = useAppSelector(
    state => state.trades
  )
  const { GetCategoriesTC, GetTagsTC, GetMeTC, GetTickers, GetUIDataTC } = useActions(tradesActions)

  useEffect(() => {
    if (!isStatisticsAvailable) return

    GetMeTC()
    GetUIDataTC()
    GetCategoriesTC()
    GetTagsTC()
    GetTickers()
  }, [isStatisticsAvailable])

  return {
    me,
    categories,
    tags,
    tickers,
    isMeLoading: getMeStatus === 'idle' || getMeStatus === 'loading',
    isTickersLoading: tickersStatus === 'idle' || tickersStatus === 'loading',
    isUiDataLoading: uiDataStatus === 'idle' || uiDataStatus === 'loading',
  }
}
