import React from 'react'

const Deposit = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_9953_75971)" />
      <path
        d="M22.0907 24.8097C22.0907 24.8097 18.19 24.8097 15.6906 24.8097M15.6906 24.8097C15.6906 22.3103 15.6906 18.4096 15.6906 18.4096M15.6906 24.8097C19.2611 21.2392 24.3093 16.191 24.3093 16.191"
        stroke="#FAFAFA"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9953_75971"
          x1="6.43533e-07"
          y1="40.5"
          x2="22.3945"
          y2="-7.68273"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#24C8DE" />
          <stop offset="1" stopColor="#4EED34" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { Deposit }
