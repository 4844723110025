import { FC } from 'react'
import style from './style.module.scss'
import clsx from 'clsx'

type DiaryWeekResultItemProps = {
  value?: string | number
  text?: string | React.ReactNode
  isCountPercent?: boolean
  sourceValue?: number
  className?: string
  textClassName?: string
}

export const DiaryWeekResultItem: FC<DiaryWeekResultItemProps> = ({
  value,
  text,
  isCountPercent,
  className,
  textClassName,
  sourceValue = 0,
}) => {
  let percentClassName
  if (isCountPercent) {
    percentClassName = clsx({
      [style.positive]: sourceValue > 0,
      [style.negative]: sourceValue < 0,
      [style.withoutPercentValue]: !sourceValue,
    })
  }

  return (
    <div className={style.diaryWeekContainer}>
      <div className={clsx(style.result, className, percentClassName && { [percentClassName]: isCountPercent })}>
        {value}
      </div>
      <div className={clsx(style.text, textClassName)}>{text}</div>
    </div>
  )
}
