import { useEffect } from 'react'
import { InteractiveModal } from '@/ui/kit'
import { useActions } from '@/utils'
import { useAppSelector } from '@/utils'
import style from './style.module.scss'
import { okxAffiliateActions } from '@/redux/okxAffiliate/okxAffiliate.slice'
import LeftBar from './components/LeftBar'
import { RightBar } from './components/RightBar'
import { Offer } from './components/Offer'
import { EInteractiveModalVariant } from '@/ui/kit/Modals/InteractiveModal/InteractiveModal'

export const OKXAffiliate = () => {
  const { isOfferPopup, userStatus } = useAppSelector(state => state.okxAffiliate)
  const { GetUserStatus, closeOfferPopup } = useActions(okxAffiliateActions)

  useEffect(() => {
    GetUserStatus()
  }, [])

  function closePopup() {
    closeOfferPopup()
  }

  return (
    <>
      <div className={style.cashback}>
        <div className={style.content}>
          <LeftBar />
          <RightBar />
        </div>
      </div>
      {!userStatus?.isAffiliate && (
        <InteractiveModal isOpen={isOfferPopup} variant={EInteractiveModalVariant.MEDIUM}>
          <Offer onClose={closePopup} link={userStatus?.referralUrl} />
        </InteractiveModal>
      )}
    </>
  )
}
