import { FC } from 'react'

type NewProps = {
  className?: string
}

export const New: FC<NewProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={35} height={22} fill="none" className={className}>
    <rect width={34} height={22} x={0.07} fill="url(#a)" rx={11} />
    <path
      fill="#FAFAFA"
      d="M10.57 7h1.6v7h-1.2l-2.7-3.8V14h-1.6V7h1.2l2.7 3.8V7Zm4.4 5.46h2.8V14h-4.4V7h4.35v1.54h-2.75V9.7h2.5v1.52h-2.5v1.24ZM20.102 14l-1.93-7h1.68l1.21 4.9 1.35-4.9h1.3l1.35 4.9 1.21-4.9h1.68l-1.93 7h-1.82l-1.14-4.13-1.14 4.13h-1.82Z"
    />
    <defs>
      <linearGradient id="a" x1={0.07} x2={20.139} y1={22} y2={-9.015} gradientUnits="userSpaceOnUse">
        <stop stopColor="#D395FF" />
        <stop offset={0.446} stopColor="#EF32FF" />
        <stop offset={1} stopColor="#4062FF" />
      </linearGradient>
    </defs>
  </svg>
)
