import { AxiosResponse } from 'axios'
import { isString } from 'lodash-es'

const DIVIDER = '\x02\x03\x04END\x04\x03\x02'

const chunkedResponseInterceptor = (response: AxiosResponse) => {
  if (isString(response.data) && response.data.includes(DIVIDER)) {
    const responseData = response.data
    const chunks = responseData.split(DIVIDER)
    const parsedChunks: unknown[] = []

    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i].trim()

      if (!chunk) continue

      try {
        parsedChunks.push(JSON.parse(chunk))
      } catch (e) {
        console.warn(e)
      }
    }

    response.data = parsedChunks
  }

  return response
}

export { chunkedResponseInterceptor }
