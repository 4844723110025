interface PendingProp {
  className?: string
}

export const Pending = (props: PendingProp) => {
  const { className } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={18} fill="none" className={className}>
      <rect width={18} height={18} x={0.5} fill="#676573" rx={9} />
      <path stroke="#FAFAFA" strokeLinecap="round" strokeWidth={1.8} d="M9.05 4.5V9a.9.9 0 0 0 .9.9h2.7" />
    </svg>
  )
}
