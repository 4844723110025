import { useActions, useAppSelector } from '@/utils'
import style from './style.module.scss'
import { useEffect } from 'react'
import { notificationActions } from '@/redux/notification/notification.slice'
import { Notification } from './Notification/Notification'
import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { Checkbox } from '@/ui/kit'
import TabMenu, { Tab } from '../TabMenu/TabMenu'
import { NotificationType } from '@/redux/notification/notification.types'
import { TChangePayload } from '@/ui/kit/Checkbox/Checkbox.Components/ArrayedInputsGroup'
import { InfiniteScrollCustom } from '@/ui/atoms/InfiniteScrollCustom'
import { EmptyList } from '@/ui/molecules'

type Props = {
  onClose: () => void
}

export const NotificationSidebar = (props: Props) => {
  const { notificationData, summary, isLoading } = useAppSelector(state => state.notification)
  const { metadata } = useAppSelector(state => state.profile)
  const {
    GetUserNotifications,
    GetNotifySummary,
    PostReadNotifications,
    updateFilterOnlyRead,
    updateFilterType,
    updateFilterPagination,
    resetFilterPagination,
  } = useActions(notificationActions)

  useEffect(() => {
    GetUserNotifications(notificationData.filters)
  }, [notificationData.filters.page, notificationData.filters.unreadOnly, notificationData.filters.type])

  function closeContent() {
    resetFilterPagination()
    props.onClose()
  }

  async function handleReadAllNotifications() {
    try {
      await PostReadNotifications()
      GetNotifySummary({ firstLoginTime: metadata.firstSesstionTrackedTimestamp })

      if (notificationData.filters.page !== 0) {
        resetFilterPagination()
      } else {
        await GetUserNotifications(notificationData.filters)
      }
    } catch (error) {
      console.error('Failed to mark all notifications as read:', error)
    }
  }

  function handleShowOnlyRead(value: TChangePayload) {
    updateFilterOnlyRead(value.checked)
  }

  function handleChangeTab(tabName: string) {
    if (tabName === NotificationType.ALL) {
      return updateFilterType(null)
    }

    updateFilterType(tabName)
  }

  function handleChangeScroll() {
    if (
      !isLoading &&
      notificationData.notifications &&
      notificationData.filters.total > notificationData.notifications.length
    ) {
      updateFilterPagination()
    }
  }

  const allSummary = summary.announcements + summary.updates
  const isHasNotifications = notificationData.notifications && notificationData.notifications.length > 0

  return (
    <div className={style.modalWrapper}>
      <div className={style.wrapper}>
        <div className={style.header}>
          <div onClick={closeContent} className={style.closeBtn}>
            <SVG.Arrows.LongArrowRight />
          </div>
          <div className={style.titleWrapper}>
            <TitleH3 label={t({ id: 'notification.sideBar.title', comment: 'Уведомления' })} />
            <div className={style.readAll} onClick={handleReadAllNotifications}>
              {t({ id: 'notification.sideBar.readAll', comment: 'Отметить все прочитанные' })}
            </div>
          </div>
          <TabMenu
            onChange={handleChangeTab}
            extraContent={
              <Checkbox
                checked={notificationData.filters.unreadOnly}
                className={style.checkbox}
                onChange={handleShowOnlyRead}
              >
                {t({
                  id: 'notification.sideBar.filter.showOnlyUnread',
                  comment: 'Show only unread',
                })}
              </Checkbox>
            }
          >
            <Tab
              label={t({ id: 'notification.sideBar.filter.all', comment: 'All' })}
              counter={allSummary}
              name={NotificationType.ALL}
              content={
                isHasNotifications ? (
                  <InfiniteScrollCustom scrollCallback={handleChangeScroll} className={style.content} maxHeight="76vh">
                    {notificationData.notifications.map(item => (
                      <Notification key={item.id} notification={item} />
                    ))}
                  </InfiniteScrollCustom>
                ) : (
                  <EmptyList
                    label={t({
                      id: 'notification.sideBar.historyEmpty',
                      comment: 'History of your notifications will be displayed here',
                    })}
                  />
                )
              }
            />
            <Tab
              label={t({ id: 'notification.sideBar.filter.announcements', comment: 'Announcements' })}
              icon={<SVG.OtherIcons.MailNew />}
              counter={summary.announcements}
              name={NotificationType.ANNOUNCEMENT}
              content={
                isHasNotifications ? (
                  <InfiniteScrollCustom scrollCallback={handleChangeScroll} className={style.content} maxHeight="76vh">
                    {notificationData.notifications.map(item => (
                      <Notification key={item.id} notification={item} />
                    ))}
                  </InfiniteScrollCustom>
                ) : (
                  <EmptyList
                    label={t({
                      id: 'notification.sideBar.historyEmpty',
                      comment: 'History of your notifications will be displayed here',
                    })}
                  />
                )
              }
            />
            <Tab
              label={t({ id: 'notification.sideBar.filter.updates', comment: 'Updates' })}
              icon={<SVG.OtherIcons.CloudDown />}
              counter={summary.updates}
              name={NotificationType.UPDATE}
              content={
                isHasNotifications ? (
                  <InfiniteScrollCustom scrollCallback={handleChangeScroll} className={style.content} maxHeight="76vh">
                    {notificationData.notifications.map(item => (
                      <Notification key={item.id} notification={item} />
                    ))}
                  </InfiniteScrollCustom>
                ) : (
                  <EmptyList
                    label={t({
                      id: 'notification.sideBar.historyEmpty',
                      comment: 'History of your notifications will be displayed here',
                    })}
                  />
                )
              }
            />
          </TabMenu>
        </div>
      </div>
    </div>
  )
}
