import { E_TEAM_PERMISSIONS, E_RISK_MANAGER_PERMISSIONS } from '@/redux/team/team.types'
import { t } from '@lingui/macro'

export const getTeamPermissionTranslations = (): Record<E_TEAM_PERMISSIONS, string> => {
  return {
    controlCashbackPercent: t({
      id: 'teamPermissions.controlCashbackPercent',
    }),
    tradingDataAccess: t({
      id: 'teamPermissions.tradingDataAccess',
    }),
  }
}

export const getRiskManagerPermissionTranslations = (): Record<E_RISK_MANAGER_PERMISSIONS, string> => {
  return {
    closeOrdersPositions: t({
      id: 'teamPermissions.closeOrdersPositions',
    }),
    controlApiKeyCreation: t({
      id: 'teamPermissions.controlApiKeyCreation',
    }),
    controlApiKeyPermissions: t({
      id: 'teamPermissions.controlApiKeyPermissions',
    }),
  }
}
