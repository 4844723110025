import nameof from 'ts-nameof.macro'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { TManagerCashback, TTeamInfo, TeamManagerOwnCashback, TeamMembersCashbackEditPayload } from './team.types'
import {
  CashbackAPI,
  TTeamJoinDTO,
  TTeamManagerActionDTO,
  TTeamManagerMembersDTO,
  TTeamManagerRequestsDTO,
  TTeamUserInfoDTO,
  TeamManagerRequestReturnedDTO,
  TeamPayload,
} from '@/backend/api/cashbackAPI'
import {
  ApiKeysAPI,
  TGetRiskManagerDTO,
  TRiskManagerTradingStatusDTO,
  TSetRiskManagerDTO,
  TUpdateRiskManagerDTO,
} from '@/backend/api/apiKeyAPI'

export const GetRequestedTeamTC = createAsyncThunk<TTeamInfo, string, ThunkError>(
  nameof(CashbackAPI.getRequestedTeam),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.getRequestedTeam, payload }, thunkAPI)
  }
)
export const GetUserTeamInfoTC = createAsyncThunk<TTeamUserInfoDTO, void, ThunkError>(
  nameof(CashbackAPI.getUserTeamInfo),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.getUserTeamInfo, payload }, thunkAPI)
  }
)

export const GetManagerMembersTC = createAsyncThunk<TTeamManagerMembersDTO, TeamPayload, ThunkError>(
  nameof(CashbackAPI.getManagerMembers),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.getManagerMembers, payload }, thunkAPI)
  }
)

export const GetManagerRequestsTC = createAsyncThunk<TTeamManagerRequestsDTO, TeamPayload, ThunkError>(
  nameof(CashbackAPI.getManagerRequests),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.getManagerRequests, payload }, thunkAPI)
  }
)

export const HandleManagerRequests = createAsyncThunk<TeamManagerRequestReturnedDTO, TTeamManagerActionDTO, ThunkError>(
  nameof(CashbackAPI.sendManagerRequest),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.sendManagerRequest, payload }, thunkAPI)
  }
)

export const TeamJoinTC = createAsyncThunk<void, TTeamJoinDTO, ThunkError>(
  nameof(CashbackAPI.postTeamJoin),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.postTeamJoin, payload }, thunkAPI)
  }
)

export const TeamCancelJoinTC = createAsyncThunk<void, void, ThunkError>(
  nameof(CashbackAPI.deleteTeamJoin),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.deleteTeamJoin, payload }, thunkAPI)
  }
)

export const TeamLeaveTC = createAsyncThunk<void, void, ThunkError>(
  nameof(CashbackAPI.postTeamLeave),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.postTeamLeave, payload }, thunkAPI)
  }
)

export const GetTeamRiskManagerTC = createAsyncThunk<TGetRiskManagerDTO, void, ThunkError>(
  nameof(ApiKeysAPI.getRiskManagerSettings),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.getRiskManagerSettings, payload }, thunkAPI)
  }
)

export const SetTeamRiskManagerTC = createAsyncThunk<void, TSetRiskManagerDTO, ThunkError>(
  nameof(ApiKeysAPI.postEnsableRiskManager),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.postEnsableRiskManager, payload }, thunkAPI)
  }
)

export const UpdateTeamRiskManagerTC = createAsyncThunk<void, TUpdateRiskManagerDTO, ThunkError>(
  nameof(ApiKeysAPI.putUpdateRiskManagerSettings),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.putUpdateRiskManagerSettings, payload }, thunkAPI)
  }
)

export const DeleteTeamRiskManagerTC = createAsyncThunk<void, void, ThunkError>(
  nameof(ApiKeysAPI.deleteDisableRiskManager),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.deleteDisableRiskManager, payload }, thunkAPI)
  }
)

export const ChangeMemberCashbackTC = createAsyncThunk<void, TeamMembersCashbackEditPayload, ThunkError>(
  nameof(CashbackAPI.changeMemberCashback),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.changeMemberCashback, payload }, thunkAPI)
  }
)

export const DeleteMemberTC = createAsyncThunk<void, string, ThunkError>(
  nameof(CashbackAPI.removeMember),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.removeMember, payload }, thunkAPI)
  }
)

export const GetTeamRiskManagerTradingStatusTC = createAsyncThunk<TRiskManagerTradingStatusDTO, void, ThunkError>(
  nameof(ApiKeysAPI.getRiskManagerTradingStatus),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ApiKeysAPI.getRiskManagerTradingStatus, payload }, thunkAPI)
  }
)

export const GetMemberCashbackTC = createAsyncThunk<TeamManagerOwnCashback, string, ThunkError>(
  nameof(CashbackAPI.getMemberCashback),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.getMemberCashback, payload }, thunkAPI)
  }
)

export const ChangeTeamManagerDefaultCashbackTC = createAsyncThunk<void, TManagerCashback, ThunkError>(
  nameof(CashbackAPI.changeTeamManagerDefaultCashback),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.changeTeamManagerDefaultCashback, payload }, thunkAPI)
  }
)
