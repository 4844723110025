interface IScanQRCode {
  className?: string
}

export const Sell = (props: IScanQRCode) => {
  const { className } = props

  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="50" height="50" rx="25" fill="#1C1A24" />
      <path
        d="M22.3867 19.6133C22.3867 19.6133 27.2625 19.6133 30.3868 19.6133M30.3868 19.6133C30.3868 22.7375 30.3868 27.6134 30.3868 27.6134M30.3868 19.6133C25.9236 24.0765 19.6133 30.3867 19.6133 30.3867"
        stroke="url(#paint0_radial_18381_2128)"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_18381_2128"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.3867 15.6132) rotate(94.3102) scale(17.3535 229.179)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}
