import { FC, useEffect } from 'react'
import { t } from '@lingui/macro'
import style from './style.module.scss'
import { BlockTradingSettings } from '../BlockTradingSettings/BlockTradingSettings'
import { RiskManagerSettings } from '../RiskManagerSettings/RiskManagerSettings'
import { useActions, useAppSelector } from '@/utils'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'
import { Loader } from '@/ui/kit/Loader'

export const Settings: FC = () => {
  const { GetRiskManagerTC, GetRiskManagerTradingStatusTC, GetRiskManagerAvailableTC } = useActions(riskManagerActions)
  const { rmAvailableStatus, tradingStatusRequestStatus, isLoadingVoluntaryBlock } = useAppSelector(
    state => state.riskManager
  )

  useEffect(() => {
    GetRiskManagerTC()
    GetRiskManagerTradingStatusTC()
    GetRiskManagerAvailableTC()
  }, [])

  if (rmAvailableStatus === 'loading' || tradingStatusRequestStatus === 'loading' || isLoadingVoluntaryBlock) {
    return (
      <div className={style.wrapp}>
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <section className={style.section}>
        <h6 className={style.sectionHeading}>
          {t({ id: 'riskManager.settings.blockTrading', comment: 'Block Trading' })}
        </h6>
        <BlockTradingSettings />
      </section>
      <section className={style.section}>
        <h6 className={style.sectionHeading}>
          {t({ id: 'riskManager.settings.riskManager', comment: 'Settings risk Manager' })}
        </h6>
        <RiskManagerSettings />
      </section>
    </div>
  )
}
