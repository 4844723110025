import { ISpotOrdersControllerPublicParams } from '@tigertrade/binance-ts'
import {
  BINANCE_QUEUE_INTERVAL,
  binanceProxyRestUrls,
  binanceRestUrls,
  binanceWsProxyUrls,
  binanceWsUrls,
} from './shared'
import { cacheManager } from '@/services/cacheManager/cacheManager'
import { ESecurityType, exchangeApi } from '@/backend/api/exchange'

export const getSpotParamsSource = (
  isBinanceActive: boolean,
  accessToken: string,
  isVip?: boolean
): ISpotOrdersControllerPublicParams => {
  return {
    wsQueueInterval: BINANCE_QUEUE_INTERVAL,
    ordersListParams: {
      config: {
        removeInactive: true,
      },
    },
    restClientParams: {
      axiosPublicConfig: {
        baseURL: isBinanceActive ? binanceRestUrls.SPOT : binanceProxyRestUrls.SPOT,
        ...(!isBinanceActive && {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }),
      },
      axiosPrivateConfig: {
        baseURL: `${process.env.REACT_APP_BACKEND_IP}exchange-gtw/protected/api/v1/binance/spot/`,
        headers: {
          'X-Exchange-Type': isVip ? 'BINANCE_VIP' : 'BINANCE_BROKER_FUTURE',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    },
    wsParams: {
      baseUrl: isBinanceActive ? binanceWsUrls.SPOT : `${binanceWsProxyUrls.SPOT}`, //?access_token=${accessToken}
      refreshFunction: async (lk: string) => {
        const res = await exchangeApi.binanceProxyPut<{ status: number }>(
          '/spot/api/v3/userDataStream',
          { listenKey: lk },
          ESecurityType.USER_STREAM
        )
        return { status: res.status }
      },
      getListenKeyFunction: async () => {
        const res = await exchangeApi.binanceProxyPost<{ listenKey: string }>(
          '/spot/api/v3/userDataStream',
          {},
          ESecurityType.USER_STREAM
        )
        return { listenKey: res.listenKey }
      },
      _autoReconnect: false,
      cacheInstanceName: isVip ? '_SPOT-u-vip' : '_SPOT-u',
    },
    cacheManager: cacheManager,
  }
}
