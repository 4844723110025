import { axiosMainService } from '@/services/axios'
import { ErrorCode } from '../models/ErrorCodesDTO'
import { EAccountType } from '@tigertrade/binance-ts'
import { binanceRestClients } from '@/services/axios/instances/binance_clients'
import { appError } from '@/services/error'

const cryptoAPIURL = 'binance-broker-crypto/protected/api/v1/'
const universalAPIURL = 'binance-broker-universal/protected/api/v1/'

export const TransactionsAPI = {
  async getAssetsForWithdrawAndDeposit() {
    return await axiosMainService.get<WithdrawAndDepositAssets>(`${cryptoAPIURL}coins`)
  },

  async transferAsset(payload: TransferPayload) {
    return await axiosMainService.post(`${universalAPIURL}transfers`, payload)
  },

  async getAssetsForTransactions() {
    return await axiosMainService.get<TransactionAssets>(`${universalAPIURL}assets`)
  },

  async getDepositWalletAddress(payload: GetDepositWalletAddressPayload) {
    return await axiosMainService.get<DepositWalletAddressResult>(
      `${cryptoAPIURL}deposit/address?coin=${payload.coin}&network=${payload.network}`
    )
  },

  async getCoinsBalance() {
    return await axiosMainService.get<BalanceAsset[]>(`${cryptoAPIURL}assets/spot`)
  },

  async withdrawAsset(payload: WithdrawPayload) {
    return await axiosMainService.post(`${cryptoAPIURL}withdraw`, payload)
  },

  async getWithdrawAddress() {
    return await axiosMainService.get(`${cryptoAPIURL}wallets/withdraw`)
  },

  async getHistoryConvertInfo(payload: ConvertTradeFlowRequestParams) {
    try {
      return await binanceRestClients.spot?.getBinanceConvertTradeFlow(payload)
    } catch (err: any) {
      throw appError(err)
    }
  },
}

/** Answer from BE with deposit wallet address */
export type DepositWalletAddressResult = { address: string; coin: string; network: string; url: string }

/** Payload to get deposit wallet address */
export type GetDepositWalletAddressPayload = {
  coin: string
  network: string
}

/** Payload to send transaction to BE */
export type TransferPayload = {
  accountFrom: EAccountType | string
  accountTo: EAccountType | string
  amount: number
  asset: string
  transactionId: string
}

/** Incoming accounts with existing assets in each one */
export type TransactionAssets = {
  errors: AccountAssetError[]
  coinAccountBalance: AccountAssets
  futuresAccountBalance: AccountAssets
  spotAccountBalance: AccountAssets
}

/** Content of each account */
export type AccountAssets = {
  assets: BalanceAsset[]
  balance: number
  symbol: string
}

/** Information about balance for each asset in the account */
export type BalanceAsset = {
  asset: string
  balance: number
}

/** Message with errors while requesting data from accounts */
type AccountAssetError = {
  code: ErrorCode
  message: string
}

/** Incoming assets for deposit and withdraw */
export type WithdrawAndDepositAssets = Asset[]

/** Incoming information about one asset */
export type Asset = {
  coin: string
  networks: NetworkByCoin[]
}

/** Supported networks for one coin */
export type NetworkByCoin = {
  addressRegex?: string
  busy: boolean
  depositEnable: boolean
  coin: string
  name: string
  network: string
  tokenStandard: string
  withdrawEnable: boolean
  withdrawFee: number
  withdrawIntegerMultiple: number
  withdrawMax: number
  withdrawMin: number
  alias: string
}

/** Payload to send to BE for withdrawal */
export type WithdrawPayload = {
  address: string
  amount: number
  coin: string
  googleCode: string
  network: string
  type: 'CRYPTO'
  walletId?: string | null
  transactionId?: string | null
}

export type ConvertTradeFlowRequestParams = {
  startTime: number // Начальное время интервала в миллисекундах
  endTime: number // Конечное время интервала в миллисекундах
  limit?: number // Количество записей на странице (по умолчанию 100, максимум 1000)
  recvWindow?: number // Окно времени для запроса (например, 5000 миллисекунд)
}
