import { FC, useCallback } from 'react'
import { TArticle } from './Articles.types'
import { Button } from '@/ui/kit'
import { EmptyList } from '@/ui/molecules'
import empty from '@/assets/png/empty-list-articles.png'
import AssetProgressBar from '@/ui/molecules/AssetProgressBar'
import { PARTNER_TRADING_VOLUME } from '@/core/config/partner'
import { t } from '@lingui/macro'
import clsx from 'clsx'

import styles from './style.module.scss'
import { formatNumber } from '@/utils'

const Article: FC<{ article: TArticle }> = props => {
  const { article } = props

  const CustomLink = article.customLink
  const onClick = useCallback(() => {
    article.callback?.()
  }, [article.callback])

  return (
    <div className={styles.article}>
      <div
        className={clsx(styles.image, {
          [styles._link]: article.linkToImage && !article.disabled,
          [styles.blurImage]: article.disabled,
        })}
        onClick={() => {
          if (article.linkToImage && !article.disabled) onClick()
        }}
      >
        {article.disabled && (
          <div className={styles.disableTextWrapper}>
            <p>
              {t({
                id: 'core.tradingVolume.title',
                message: `Торговый оборот для открытия видео`,
              })}
            </p>

            <AssetProgressBar
              percent={`${((article.tradingVolume ?? 1) / PARTNER_TRADING_VOLUME) * 100}`}
              progressBarClassName={styles.barLine}
              showPercentText={false}
            />

            <div className={styles.volumes}>
              <p>
                {formatNumber(article.tradingVolume, {
                  maximumFractionDigits: 2,
                })}{' '}
                USDT
              </p>
              <p>{formatNumber(PARTNER_TRADING_VOLUME)} USDT</p>
            </div>
          </div>
        )}

        <img src={article.image} alt="" />
      </div>
      <div className={styles.textWrap}>
        <div className={styles.details}>
          <span className={styles.date}>{article.date}</span>
          {article.tags.length > 0 && (
            <>
              {' '}
              •{' '}
              <span className={styles.tags}>
                {article.tags.map((tag, index) => {
                  const tagEl = (
                    <span className={styles.tag} key={index}>
                      {tag}
                    </span>
                  )
                  if (index > 0) return ` ${tagEl}`
                  return tagEl
                })}
              </span>
            </>
          )}
        </div>
        <div className={styles.heading}>{article.heading}</div>
        <div className={styles.text}>{article.text}</div>
        <div className={styles.articleControl}>
          {article.linkText && <Button.Primary className={styles.btn} label={article.linkText} onClick={onClick} />}
          {CustomLink && !article.disabled && <CustomLink onClick={onClick} />}
        </div>
      </div>
    </div>
  )
}

export const Articles: FC<{ articles: TArticle[]; emptyProps?: { title: string; label: string } }> = props => {
  const {
    articles,
    emptyProps = {
      label: t({ id: 'core.emptyList.label' }),
      title: t({ id: 'core.emptyList.title' }),
    },
  } = props

  if (articles.length === 0)
    return <div className={styles.root}>{emptyProps && <EmptyList {...emptyProps} image={empty} />}</div>

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {articles.map((article, index) => {
          return <Article article={article} key={index} />
        })}
      </div>
    </div>
  )
}
