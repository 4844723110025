import { FC } from 'react'

export const Twitter: FC = () => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1676 2.29709C16.5479 2.58023 15.8874 2.7679 15.1989 2.85902C15.9072 2.41859 16.4479 1.72648 16.702 0.892258C16.0416 1.30232 15.3124 1.59196 14.5354 1.7536C13.9083 1.05823 13.0145 0.627563 12.0395 0.627563C10.1479 0.627563 8.62493 2.22658 8.62493 4.18683C8.62493 4.46889 8.64785 4.74009 8.7041 4.99827C5.86348 4.85399 3.34993 3.43614 1.66139 1.27628C1.3666 1.80892 1.19368 2.41859 1.19368 3.0749C1.19368 4.30725 1.80306 5.39965 2.71139 6.0321C2.16243 6.02125 1.62389 5.85528 1.16764 5.59384C1.16764 5.60468 1.16764 5.61879 1.16764 5.63289C1.16764 7.36208 2.35202 8.79837 3.90514 9.12924C3.62702 9.20843 3.32389 9.2464 3.00931 9.2464C2.79056 9.2464 2.56973 9.23338 2.36243 9.18565C2.80514 10.5948 4.06139 11.6308 5.55514 11.6644C4.39264 12.6115 2.9166 13.1821 1.31868 13.1821C1.03848 13.1821 0.769727 13.1691 0.500977 13.1333C2.01452 14.1498 3.80827 14.7301 5.74264 14.7301C12.0301 14.7301 15.4676 9.30606 15.4676 4.60449C15.4676 4.44719 15.4624 4.29532 15.4551 4.14453C16.1333 3.64334 16.7031 3.01741 17.1676 2.29709Z"
        fill="white"
      />
    </svg>
  )
}
