import { OVEVIEW_EXCHANGE_SYMBOLS_MAP } from '@/redux/overview/overview.defaults'
import { accountNames, useAppSelector } from '@/utils'
import { useOverview } from '@/utils/hooks/useOverview'
import { t } from '@lingui/macro'
import { useMemo } from 'react'

const options = { maximumFractionDigits: 2 }

export const useOrdersPage = () => {
  const { totalBalance } = useAppSelector(state => state.overview)
  const { balancesArray, accountType } = useOverview()
  const { exchangeType } = useAppSelector(state => state.app)

  const balanceInfo = useMemo(() => {
    const filteredBalanceArray = balancesArray.filter(el => {
      return el.id !== accountNames.BINANCE_FUTURE_COIN.id
    })

    return [
      {
        id: accountType,
        label: t({ message: 'Account balance', id: 'ordersAndPosition.additionalInfoBalance' }),
        amount: totalBalance ?? 0,
        symbol: exchangeType ? OVEVIEW_EXCHANGE_SYMBOLS_MAP[exchangeType].symbol : '',
      },
      ...filteredBalanceArray,
    ]
  }, [accountType, totalBalance, balancesArray])

  return { balanceInfo, options }
}
