import clsx from 'clsx'
import { FC } from 'react'
import style from './style.module.scss'
import { LegendIcon } from './LegendIcon'
import { LegendName } from './LegendName'

type DefaultButtonProps = JSX.IntrinsicElements['button']

interface LegendButtonProps extends DefaultButtonProps {
  color: string
  isActive?: boolean
  legendName: string
  rawLegendName: string
}

export const LegendButton: FC<LegendButtonProps> = ({
  type: _type,
  color,
  isActive,
  legendName,
  rawLegendName,
  className,
  disabled = false,
  onClick,
  ...btnProps
}) => (
  <button
    type="button"
    onClick={!disabled ? onClick : undefined}
    {...btnProps}
    className={clsx(style.button, className)}
    disabled={disabled}
  >
    <div
      style={{ backgroundColor: isActive ? color : 'transparent', borderColor: color }}
      className={clsx(disabled && style.disabled, style.circle)}
    />

    <div className={style.info}>
      <LegendIcon rawLegendName={rawLegendName} />
      <span className={style.text}>
        <LegendName rawLegendName={rawLegendName} legendName={legendName} />
      </span>
    </div>
  </button>
)
