import { FC } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { TitleH4 } from '@/ui/atoms/TitleH4'
import { ReasonItem } from './ReasonItem/ReasonItem'
import { SVG } from '@/assets/svg'

type Props = {
  onClose: () => void
  categories: { name: string; active: boolean }[]
  onToggleCategory: (name: string) => void
}

export const SettingForm: FC<Props> = props => {
  const sortCategories = props.categories.sort((a, b) => a.name.localeCompare(b.name))

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <TitleH4
          label={t({
            id: 'widget.profitByReason.popup.title',
            comment: 'Setting up the visibility of causes',
          })}
        />
        <div className={style.icon} onClick={() => props.onClose()}>
          <SVG.Additional.Close />
        </div>
      </div>
      <div className={style.description}>
        <span>
          {t({
            id: 'widget.profitByReason.popup.description',
            comment: 'You can remove or add columns at your discretion.',
          })}
        </span>
      </div>

      <div className={style.content}>
        {sortCategories.map(category => (
          <ReasonItem key={category.name} reason={category} onChange={() => props.onToggleCategory(category.name)} />
        ))}
      </div>
    </div>
  )
}
