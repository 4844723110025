import clsx from 'clsx'
import React, { FC } from 'react'
import style from './style.module.scss'

interface EllipsisProps {
  label?: string
  className?: string
}

export const Ellipsis: FC<EllipsisProps> = ({ label, className }) => {
  return (
    <div title={label} className={clsx(style.component, className)}>
      {label}
    </div>
  )
}
