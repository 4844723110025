import { Deal } from './Deal'
import { Deposit } from './Deposit'
import { Volume } from './Volume'
import { Key } from './Key'
import { Kyc } from './Kyc'
export const Achievements = {
  Deal,
  Deposit,
  Volume,
  Key,
  Kyc,
}
