import { CheckboxProps } from '@/ui/kit/Checkbox/Checkbox'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { ReactNode, useCallback, useMemo } from 'react'
import { formatAccess } from '../../ApiKeyListItem/utils'
import { State } from '../ApiKeyCreateForm.types'
import { useExchageType } from '@/utils/hooks/useExchangeType'

interface ICheckboxWithChildren {
  checkbox: CheckboxProps
  children: ReactNode
  selector: string | null
}

interface IFieldChange {
  (name: string): (value: string | boolean) => void
}

export const useCreateApiForm = (
  state: State,
  setState: React.Dispatch<React.SetStateAction<State>>
): {
  permissions: ICheckboxWithChildren[]
  handleFieldChange: IFieldChange
  isPassphraseValid: (value: string) => boolean
} => {
  const { exchangeType } = useExchageType()

  const handleFieldChange = useCallback<IFieldChange>(
    (name: string) => (value: string | boolean) => {
      setState(prev => ({ ...prev, [name]: value }))
    },
    []
  )

  const isPassphraseValid = useCallback((value: string) => {
    const reg = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=])(?=\S+$).{8,32}$/)
    return reg.test(value)
  }, [])

  const permissions = useMemo<ICheckboxWithChildren[]>(() => {
    const readCheckbox: ICheckboxWithChildren = {
      checkbox: {
        checked: true,
        disabled: true,
        variant: 'circle',
        dataTestId: DataTestIds.CheckboxRead,
      },
      children: formatAccess('read'),
      selector: null,
    }
    const tradeOrSpotCheckbox: ICheckboxWithChildren = {
      checkbox: {
        checked: state.spotEnabled,
        variant: 'circle',
        dataTestId: DataTestIds.CheckboxSpot,
        onChange: payload => handleFieldChange('spotEnabled')(payload.checked),
      },
      children: formatAccess('spot'),
      selector: 'spotEnabled',
    }
    return [readCheckbox, tradeOrSpotCheckbox]
  }, [exchangeType, state])

  return {
    permissions: permissions,
    handleFieldChange,
    isPassphraseValid,
  }
}
