import { GetTradesExchangeAvailableType, TradesAvailableExchange, TradesAvailableUsers } from '@/backend/api/trades.api'
import { Status, TradesQueryParams } from '@/backend/types'
import {
  tableFilterExchangeIds,
  tableFilterMarketTypeIds,
} from '@/ui/organisms/HistoryTrades/components/TableFilters/utils'
import {
  ControllersCategoryWithCounter,
  ControllersTickersListResponse,
  DayDiary,
  MonthInfo,
  ServicesOrder,
  ServicesTag,
  ServicesTrade,
  ServicesTradesListPagination,
  ServicesUserWithRelations,
  WeekDayInfo,
} from '@/utils/types/contracts.types'

export interface InitialState {
  trades: ServicesTradesListPagination | null
  tradesById: ServicesTradesListPagination | null
  tradesFilters: TradesQueryParams
  tradesStatistic?: ServicesTradesListPagination | null
  categories: ControllersCategoryWithCounter[] | null
  tags: ServicesTag[]
  activeTagEditorID: number | null
  tagEditorPosition: 'top' | 'bottom' | null
  deleteTagStatus: Status
  me: ServicesUserWithRelations | null
  isTradesLoading: boolean
  isTradesByIdLoading: boolean
  tickers: ControllersTickersListResponse | null
  tickersStatus: Status
  getMeStatus: Status
  tradesStatus: Status
  tradesByIdStatus: Status
  editDescriptionStatus: Status
  isApiKeyExistWithEmptyArray: boolean
  exchangeFilterValue: E_TableFilterExchangeType
  diaryWeekListFilters: DiaryWeekListQuery | null
  diaryExchangeFilterValue: E_TableFilterExchangeType
  diaryWeekExchangeFilterValue: E_TableFilterExchangeType
  diaryWeekFilter: DiaryWeekListQuery | null
  marketTypeFilterValue: E_TableFilterMarketValue
  tradesUserInitStatus: Status
  availableExchangeStatus: Status
  getExchangeAvailableStatus: Status
  availableExchangeKeys: TradesAvailableExchange | null
  getAvailableExchangeKeys: GetTradesExchangeAvailableType | null
  availableUsersTradingStatus: Status
  availableUsersTrading: TradesAvailableUsers | null
  requestTimeStep: number | null
  diaryWeekList: MonthInfo[]
  diaryWeekStatus: Status
  diaryWeekListDataReachedEnd: boolean
  consecutiveEmptyResponses: number
  diaryAnalyzerStatus: Status
  diaryAnalyzerList: WeekDayInfo | null
  uiDataStatus: Status
  isDayAnalyzerDataFetched: boolean
  isMonthAnalyzerDataFetched: boolean
  analyzerMonthData: AnalyzeMonthType | undefined
  analyzeDayOrders: TradesDayOrderType | null
  analyzeDayOrdersStatus: Status
  repairApiKeyStatus: Status
  analyzerMonthTradeTicker: DayDiary | null
}
export type AnalyzeMonthType = {
  data: DayDiary
  startMonth: string
  endMonth: string
  itemsWithSymbol: DayDiary[]
}

export enum E_TableFilterExchangeType {
  All = 'All',
  BinanceVip = 'BinanceVip',
  Binance = 'Binance',
  Bybit = 'Bybit',
  OKX = 'OKX',
}

export enum E_TableFilterMarketValue {
  All = 'All',
  Spot = 'Spot',
  Futures = 'Futures',
}

export enum E_TableFilter_Side {
  All = 'All',
  LONG = 'LONG',
  SHORT = 'SHORT',
}

export enum E_TableRowExchange {
  Binance = 'Binance',
  ByBit = 'Bybit',
  Okx = 'OKX',
}

export enum E_TableRowMarketType {
  Spot = 'Spot',
  Futures = 'Futures',
}

export type TableExchangeRow = {
  exchangeName: E_TableRowExchange
  marketType: E_TableRowMarketType
  exchangeId: number
}
//todo
export type DiaryWeekListQuery = {
  openBetween?: string
  api_key_id?: number[]
  type?: string
}

export type TradesDayOrdersQuery = {
  api_key_id: number
  date: string
  symbol: string
}

export type TradesDayOrderType = {
  data: ServicesOrder[]
  trades: ServicesTrade[]
}

export type TableExchangeValue = typeof tableFilterExchangeIds
export type TableMarketValue = typeof tableFilterMarketTypeIds

export enum E_TableFilterProfitability {
  All = 'All',
  PROFITABLE = '0.0001,10000000',
  UNPROFITABLE = '-0.0001,-10000000',
}

export enum TradingPlatforms {
  BINANCE_SPOT = 'BINANCE_SPOT',
  BINANCE_FUTURE = 'BINANCE_FUTURE',
  BINANCE_VIP_SPOT = 'BINANCE_VIP_SPOT',
  BINANCE_VIP_FUTURE = 'BINANCE_VIP_FUTURE',
  BYBIT_SPOT = 'BYBIT_SPOT',
  BYBIT_LINEAR = 'BYBIT_LINEAR',
  OKX_SPOT = 'OKX_SPOT',
  OKX_SWAP = 'OKX_SWAP',
}

export enum TradesAvailableKeysStatus {
  DELAYED = 'DELAYED',
  PENDING = 'PENDING',
  REGISTERED = 'REGISTERED',
  ERROR = 'ERROR',
}

export enum TradesMonthAnalyzerPairSort {
  SYMBOL = 'symbol',
  COUNT = 'count',
  WIN_COUNT = 'win_count',
  NET_PROFIT = 'net_profit',
  LEVERAGE = 'leverage',
}
