import { FC } from 'react'
import s from './style.module.scss'

export const MobileSkeletonDeal: FC = () => (
  <li className={s.card}>
    <div className={s.leftPart}>
      <div className={s.avatar} />
      <div className={s.currency} />
    </div>

    <div className={s.date} />
  </li>
)
