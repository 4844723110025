import { forwardRef } from 'react'

interface Props {
  className?: string
  width?: number
  height?: number
}

export const Logo = forwardRef<SVGSVGElement, Props>(({ className, width = 127, height = 24 }, ref) => {
  return (
    <svg
      ref={ref}
      width={width}
      height={height}
      viewBox="0 0 127 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.5695 5.34892L4.67456 0H21.6415L13.7461 5.34893C13.3905 5.58986 12.9251 5.58986 12.5695 5.34892Z"
        fill="white"
      />
      <path
        d="M26.3239 13.0911V8.47155L22.7709 10.8795C22.1901 11.2731 21.8419 11.9314 21.8419 12.6358V17.2553L25.3949 14.8474C25.9757 14.4537 26.3239 13.7955 26.3239 13.0911Z"
        fill="white"
      />
      <path
        d="M13.9065 18.9247L18.9532 24H7.3707L12.4174 18.9247C12.8286 18.5112 13.4953 18.5112 13.9065 18.9247Z"
        fill="white"
      />
      <path
        d="M4.47301 17.2498V12.6302C4.47301 11.9259 4.12481 11.2676 3.544 10.874L0 8.47211V13.0917C0 13.796 0.348207 14.4543 0.929017 14.8479L4.47301 17.2498Z"
        fill="white"
      />
      <path
        d="M26.3239 4.62007V0.000517048L15.9874 7.00585C15.0436 7.6455 14.4778 8.71517 14.4778 9.85975V14.3861C14.4778 14.9478 14.6996 15.4864 15.0946 15.8836L19.2161 20.0284V19.442H19.2144V11.9677C19.2144 11.0889 19.6502 10.2673 20.3779 9.77452L25.3949 6.37632C25.9757 5.98269 26.3239 5.32442 26.3239 4.62007Z"
        fill="white"
      />
      <path
        d="M11.8454 14.3869C11.8454 14.9486 11.6235 15.4873 11.2285 15.8844L7.10705 20.0293V14.1485L7.10052 14.1441V13.2843L7.10705 13.2887V11.9686C7.10705 11.0901 6.67147 10.2687 5.94421 9.7758L0.929017 6.37688C0.348207 5.98325 0 5.32498 0 4.62063V0.00107647L10.3357 7.00585C11.2795 7.6455 11.8454 8.71517 11.8454 9.85975V14.3869Z"
        fill="white"
      />
      <path
        d="M43.0384 8.97439H40.4687V13.948C40.4687 15.2743 41.3391 15.2536 43.0384 15.1707V17.1809C39.5983 17.5954 38.2305 16.6421 38.2305 13.948V8.97439H36.324V6.81914H38.2305V4.581L40.4687 3.91785V6.81914H43.0384V8.97439Z"
        fill="white"
      />
      <path
        d="M46.1874 5.26488C45.3999 5.26488 44.7575 4.60172 44.7575 3.83495C44.7575 3.04746 45.3999 2.40503 46.1874 2.40503C46.9749 2.40503 47.6173 3.04746 47.6173 3.83495C47.6173 4.60172 46.9749 5.26488 46.1874 5.26488ZM45.0683 17.1809V6.81914H47.3065V17.1809H45.0683Z"
        fill="white"
      />
      <path
        d="M58.1531 6.81914H60.3498V16.7043C60.3498 20.0408 57.7387 21.595 55.0239 21.595C52.7443 21.595 50.9828 20.7454 50.1124 19.0875L52.0397 17.9684C52.4956 18.8595 53.2624 19.5641 55.0653 19.5641C56.9926 19.5641 58.1531 18.5072 58.1531 16.7043V15.4401C57.3657 16.5592 56.143 17.2431 54.5473 17.2431C51.6252 17.2431 49.3249 14.8599 49.3249 11.8964C49.3249 8.93295 51.6252 6.54974 54.5473 6.54974C56.143 6.54974 57.3657 7.23361 58.1531 8.35269V6.81914ZM54.8581 15.15C56.7439 15.15 58.1531 13.7615 58.1531 11.8964C58.1531 10.0313 56.7439 8.64282 54.8581 8.64282C52.9723 8.64282 51.5631 10.0313 51.5631 11.8964C51.5631 13.7615 52.9723 15.15 54.8581 15.15Z"
        fill="white"
      />
      <path
        d="M64.7201 12.9533C65.0724 14.5697 66.378 15.3987 68.0358 15.3987C69.2793 15.3987 70.2118 14.8392 70.7092 14.1138L72.5536 15.1915C71.6003 16.6007 70.046 17.4503 68.0151 17.4503C64.5957 17.4503 62.3783 15.1086 62.3783 12C62.3783 8.93294 64.5957 6.54974 67.8493 6.54974C70.9371 6.54974 73.0509 9.03656 73.0509 12.0208C73.0509 12.3316 73.0095 12.6632 72.9681 12.9533H64.7201ZM64.6994 11.1296H70.7921C70.4812 9.34742 69.1756 8.58065 67.8286 8.58065C66.15 8.58065 65.0102 9.57538 64.6994 11.1296Z"
        fill="white"
      />
      <path
        d="M77.2585 8.55992C77.8802 7.15072 79.1443 6.63263 80.4499 6.63263V9.05729C78.9785 8.95367 77.2585 9.63755 77.2585 11.9586V17.1809H75.0203V6.81914H77.2585V8.55992Z"
        fill="white"
      />
      <path
        d="M84.0178 17.4296C83.1474 17.4296 82.4635 16.725 82.4635 15.8753C82.4635 15.0049 83.1474 14.3211 84.0178 14.3211C84.8675 14.3211 85.5721 15.0049 85.5721 15.8753C85.5721 16.725 84.8675 17.4296 84.0178 17.4296Z"
        fill="white"
      />
      <path
        d="M93.1418 17.4503C90.0126 17.4503 87.6708 15.0671 87.6708 12C87.6708 8.91222 90.0126 6.54974 93.1418 6.54974C95.1727 6.54974 96.9342 7.60664 97.7632 9.22307L95.8359 10.3421C95.38 9.36814 94.3645 8.74643 93.1211 8.74643C91.2974 8.74643 89.909 10.1349 89.909 12C89.909 13.8651 91.2974 15.2536 93.1211 15.2536C94.3645 15.2536 95.38 14.6112 95.8773 13.6579L97.8046 14.7563C96.9342 16.3934 95.1727 17.4503 93.1418 17.4503Z"
        fill="white"
      />
      <path
        d="M104.151 17.4503C101.126 17.4503 98.6802 15.0671 98.6802 12C98.6802 8.91222 101.126 6.54974 104.151 6.54974C107.198 6.54974 109.622 8.91222 109.622 12C109.622 15.0671 107.198 17.4503 104.151 17.4503ZM104.151 15.2743C105.975 15.2743 107.384 13.8859 107.384 12C107.384 10.1142 105.975 8.72571 104.151 8.72571C102.328 8.72571 100.918 10.1142 100.918 12C100.918 13.8859 102.328 15.2743 104.151 15.2743Z"
        fill="white"
      />
      <path
        d="M122.961 6.54974C125.303 6.54974 126.878 8.16617 126.878 10.7566V17.1809H124.64V10.9017C124.64 9.45103 123.852 8.64282 122.629 8.64282C121.303 8.64282 120.371 9.49248 120.371 11.3783V17.1809H118.132V10.9017C118.132 9.45103 117.407 8.64282 116.184 8.64282C114.92 8.64282 113.884 9.49248 113.884 11.3783V17.1809H111.646V6.81914H113.884V8.06256C114.547 7.0471 115.563 6.54974 116.848 6.54974C118.195 6.54974 119.189 7.15072 119.79 8.1869C120.474 7.10927 121.573 6.54974 122.961 6.54974Z"
        fill="white"
      />
    </svg>
  )
})
