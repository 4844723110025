import React from 'react'

export const Away = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0114 -0.000244141H15.1314C15.5354 -0.000244141 15.8628 0.32242 15.8628 0.720446V5.76528C15.8628 6.1633 15.5354 6.48596 15.1314 6.48596C14.7275 6.48596 14.4 6.1633 14.4 5.76528V2.46034L6.14005 10.599C5.85441 10.8805 5.39129 10.8805 5.10566 10.599C4.82001 10.3176 4.82001 9.86126 5.10566 9.57981L13.3656 1.44114H10.0114C9.60746 1.44114 9.27999 1.11847 9.27999 0.720446C9.27999 0.32242 9.60746 -0.000244141 10.0114 -0.000244141ZM4.7492 0.215004L4.71051 0.215002C4.37013 0.21498 4.15368 0.214965 3.96435 0.23153C1.86481 0.415215 0.200214 2.07981 0.016529 4.17935C-3.52605e-05 4.36868 -2.09554e-05 4.58513 1.63475e-06 4.92551L3.30368e-06 4.96421V8.10762L3.06526e-06 8.15757C-1.07034e-05 9.787 -2.15515e-05 11.0777 0.132308 12.0905C0.268412 13.1322 0.554921 13.979 1.202 14.6566C1.24811 14.7049 1.29533 14.7521 1.34362 14.7982C2.02126 15.4453 2.868 15.7318 3.90971 15.8679C4.92254 16.0003 6.21323 16.0002 7.84266 16.0002H7.89262H11.2604L11.2981 16.0002C11.6305 16.0003 11.8418 16.0003 12.0267 15.9845C14.1334 15.8045 15.8043 14.1336 15.9842 12.027C16 11.8421 16 11.6307 16 11.2984L16 11.2606C16 10.8819 15.693 10.5749 15.3143 10.5749C14.9356 10.5749 14.6286 10.8819 14.6286 11.2606C14.6286 11.6432 14.6279 11.7914 14.6178 11.9102C14.4947 13.3516 13.3514 14.4949 11.91 14.618C11.7911 14.6282 11.643 14.6288 11.2604 14.6288H7.89262C6.20204 14.6288 5.00104 14.6274 4.08738 14.5081C3.19228 14.3911 2.67312 14.1715 2.29075 13.8064C2.25771 13.7748 2.2254 13.7425 2.19385 13.7095L1.70192 14.1792L2.19385 13.7095C1.82872 13.3271 1.60913 12.8079 1.49218 11.9128C1.3728 10.9992 1.37143 9.79819 1.37143 8.10762V4.96421C1.37143 4.57236 1.37209 4.4206 1.38274 4.29888C1.50842 2.86235 2.64735 1.72342 4.08388 1.59774C4.2056 1.58709 4.35736 1.58643 4.7492 1.58643C5.12791 1.58643 5.43492 1.27943 5.43492 0.900717C5.43492 0.522008 5.12791 0.215004 4.7492 0.215004Z"
        fill="white"
      />
    </svg>
  )
}
