import { NetworkByCoin } from '@/backend/api'
import { AddressDTO } from '@/backend/models/AddressDTO'
import { TAddressView } from '@/redux/address/address.types'

export const seedAddressesWithNetwork = (addresses: AddressDTO[], networks: NetworkByCoin[]): TAddressView[] => {
  return addresses.map(address => {
    const network = networks.find(networkItem => {
      return networkItem.alias === address.alias
    })
    return {
      ...address,
      networkName: network?.name || address.alias,
    }
  })
}
