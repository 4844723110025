import nameof from 'ts-nameof.macro'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { VoucherApi } from '@/backend/api/voucher.api'
import { ApplyVoucherCodeRequestDTO, Voucher, VoucherOverviewResponseDTO } from '@/backend/models/VoucherDTO'

export const GetVoucherOverviewTC = createAsyncThunk<VoucherOverviewResponseDTO, void, ThunkError>(
  nameof(VoucherApi.getVoucherOverview),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: VoucherApi.getVoucherOverview, payload }, thunkAPI)
  }
)

export const ApplyVoucherCodeTC = createAsyncThunk<Voucher, ApplyVoucherCodeRequestDTO, ThunkError>(
  nameof(VoucherApi.applyVoucherCode),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: VoucherApi.applyVoucherCode, payload }, thunkAPI)
  }
)
