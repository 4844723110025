import { EPartners, TPartnerDataDTO, TPartnerEntityDTO } from '@/redux/partner/partner.types'
import { axiosMainService } from '@/services/axios'

export const PartnersApi = {
  getPartnersList: async () => {
    return axiosMainService.get<TPartnerEntityDTO>('/gamification/protected/api/v1/partners')
  },
  getPartnerData: async (partnerKey: EPartners) => {
    return axiosMainService.get<TPartnerDataDTO>(`/gamification/protected/api/v1/partners/${partnerKey}/content`)
  },
  postClaimPartnerReward: async (partnerKey: EPartners) => {
    return axiosMainService.post<TPartnerDataDTO>(`/gamification/protected/api/v1/partners/${partnerKey}/award`)
  },
}
