import React from 'react'

interface IArrowSmallLeftProps {
  className?: string
}

const ArrowSmallLeft = (props: IArrowSmallLeftProps) => {
  const { className } = props

  return (
    <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M3.75012 7.18581V0.829344C3.75012 0.558941 3.42049 0.426554 3.23346 0.621836L0.145942 3.84549C0.0336695 3.96271 0.0349928 4.14797 0.148928 4.26358L3.23645 7.3964C3.42479 7.58751 3.75012 7.45414 3.75012 7.18581Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { ArrowSmallLeft }
