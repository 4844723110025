import { axiosMainService } from '@/services/axios'
import { AxiosRequestConfig } from 'axios'

const okxAffiliateAPIURL = 'cashback-affiliate/protected/api'

export const OKXAffiliateAPI = {
  async getUserStatus() {
    return await axiosMainService.get(`${okxAffiliateAPIURL}/v1/status`)
  },
  async postAddKey(payload: AddKeyType) {
    return await axiosMainService.post(`${okxAffiliateAPIURL}/v1/apiKeys`, payload)
  },
  async deleteKey(payload: GoogleCodeType) {
    const config: AxiosRequestConfig = {
      data: payload,
    }

    return await axiosMainService.delete(`${okxAffiliateAPIURL}/v1/apiKeys`, config)
  },
  async postWithdrawal(payload: GoogleCodeType) {
    return await axiosMainService.post(`${okxAffiliateAPIURL}/v1/payments`, payload)
  },
  async getOKXHistoryCashback({ page, size = 20 }: HistoryPayload) {
    return await axiosMainService.get(`${okxAffiliateAPIURL}/v1/history?page=${page}&size=${size}`)
  },
}

export type AddKeyType = {
  apiKey: string
  secretKey: string
  passhprase: string
  googleCode: string
}

export type HistoryPayload = {
  page: number
  size?: number
}

export type GoogleCodeType = {
  googleCode: string
}
