import React from 'react'
import clsx from 'clsx'
import style from './style.module.scss'
import { StatusEl } from '@/ui/molecules/ProfileKYCStatus/ProfileKYCStatus'
import { t } from '@lingui/macro'
import { KYCTier } from '@/backend/models/KycDTO'

export interface ILevelItemProps {
  tierId: KYCTier
  isActive?: boolean
  isNextTier?: boolean
  tierName: string
  showStatus?: boolean
  description: {
    reqDocs?: string
    funding: string
    withdrawals: string
    upgradeText?: string
  }
  button?: JSX.Element
  handleUpgrade?: () => void
}

export const LevelItem: React.FC<ILevelItemProps> = props => {
  const { isActive, tierName, description, showStatus = true, button } = props

  return (
    <div className={clsx(style.wrapper, { [style.active]: isActive })}>
      <div className={clsx(style.innerBlock, { [style.active]: isActive })}>
        <h3 className={style.title}>{tierName}</h3>
        <div className={style.actionBlock}>
          {isActive && showStatus && <StatusEl.Verified className={[style.kycStatus]} />}
          {button}
          {description.upgradeText && <div className={style.actionText}>{description.upgradeText}</div>}
        </div>

        {description?.reqDocs && (
          <div className={clsx(style.descriptionItem, style.reqDocs)}>
            <p className={style.descriptionTitle}>
              {t({ message: 'Required documents', id: 'kycLevels.level.documents' })}
            </p>
            <p>{description.reqDocs}</p>
          </div>
        )}
        <div className={style.descriptionItem}>
          <p className={style.descriptionTitle}>{t({ message: 'Funding', id: 'kycLevels.level.funding' })}</p>
          <p>{description.funding}</p>
        </div>
        <div className={style.descriptionItem}>
          <p className={style.descriptionTitle}>{t({ message: 'Withdrawals', id: 'kycLevels.level.withdrawals' })}</p>
          <p>{description.withdrawals}</p>
        </div>
      </div>
    </div>
  )
}
