import styles from './style.module.scss'
import { LeaderCard } from './LeaderCard/LeaderCard'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import { PersonalResultUser } from '../../helpers'

type Props = {
  totalLeaders: PersonalResultUser[]
  userId: string
}

export const TotalLeaders = ({ totalLeaders, userId }: Props) => {
  const userFound = totalLeaders.find(user => user.name === userId)

  return (
    <div className={styles.leaderboard}>
      <span className={styles.title}>
        {userFound ? (
          <Trans
            id="leaderBoard.totalLeader.titleWinner"
            values={{ position: userFound.position }}
            components={{
              colored: <span className={styles.colorGold} />,
            }}
          />
        ) : (
          <Trans id="leaderBoard.totalLeader.title" />
        )}
      </span>
      <p className={styles.header}>
        {t({
          id: 'leaderBoard.totalLeader.desctiption',
          comment: 'PnL в рамках всех бирж',
        })}
      </p>
      <div className={styles.cardsContainer}>
        {totalLeaders.map(item => (
          <LeaderCard
            key={item.user_id}
            position={item.position}
            username={item.name}
            amount={item.value}
            isActive={item.name === userId}
            currency="PnL, USDT"
          />
        ))}
        {totalLeaders.length < 1 && <div className={styles.empty}>No data available</div>}
      </div>
    </div>
  )
}
