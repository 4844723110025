import { FC } from 'react'
import clsx from 'clsx'
import { Button } from '@/ui/kit'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'

import styles from './style.module.scss'

export enum E_OKX_SUSPENSION_WIDGET_LAYOUT {
  ROW = 'ROW',
  COLUMN = 'COLUMN',
}

export enum E_OKX_SUSPENSION_WIDGET_STYLE {
  DEFAULT = 'DEFAULT',
  BORDERED = 'BORDERED',
}

export const OkxSuspensionWidget: FC<{
  showHeading?: boolean
  preContent?: React.ReactNode
  postContent?: React.ReactNode
  layout?: E_OKX_SUSPENSION_WIDGET_LAYOUT
  classNameRoot?: string
  classNameContent?: string
  styleVariant?: E_OKX_SUSPENSION_WIDGET_STYLE
}> = props => {
  const {
    showHeading = true,
    preContent,
    postContent,
    layout = E_OKX_SUSPENSION_WIDGET_LAYOUT.COLUMN,
    classNameRoot,
    classNameContent,
    styleVariant = E_OKX_SUSPENSION_WIDGET_STYLE.BORDERED,
  } = props
  return (
    <div
      className={clsx(styles.okxSuspensionRoot, classNameRoot, {
        [styles.row]: E_OKX_SUSPENSION_WIDGET_LAYOUT.ROW === layout,
        [styles.column]: E_OKX_SUSPENSION_WIDGET_LAYOUT.COLUMN === layout,
        [styles.bordered]: E_OKX_SUSPENSION_WIDGET_STYLE.BORDERED === styleVariant,
      })}
    >
      <div className={clsx(styles.content, classNameContent)}>
        {preContent && <div className={styles.preContent}>{preContent}</div>}
        {showHeading && <div className={styles.heading}>{t({ id: 'okxSuspension.widget.heading' })}</div>}
        <div className={styles.description}>{t({ id: 'okxSuspension.widget.description' })}</div>
      </div>
      <Button.Primary
        label={t({ id: 'okxSuspension.widget.cta.text' })}
        onClick={() => {
          window.open(t({ id: 'okxSuspension.widget.cta.link' }))
        }}
        className={styles.cta}
        rightIcon={
          <div className={styles.ctaIcon}>
            <SVG.Settings.OkxSmall />
          </div>
        }
      />
      {postContent && <div className={styles.postContent}>{postContent}</div>}
    </div>
  )
}
