import React from 'react'

export const Logout = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5857 12H15C13.8954 12 13 12.8954 13 14V25.1714C13 26.276 13.8954 27.1714 15 27.1714H20.5857"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M24.8 17.0571C24.8 17.0571 26.598 18.8551 27.75 20.0071M27.75 20.0071C26.598 21.1592 24.8 22.9571 24.8 22.9571M27.75 20.0071C24.4584 20.0071 19.3214 20.0071 19.3214 20.0071"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
