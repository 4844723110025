import React, { FC } from 'react'
import { Provider } from 'react-redux'
import store from '@/redux/store'

export interface IReduxStoreProviderProps {
  children: React.ReactNode
}

const ReduxStoreProvider: FC<IReduxStoreProviderProps> = props => {
  return <Provider store={store}>{props.children}</Provider>
}

export { ReduxStoreProvider }
