import React from 'react'

interface LightingProps {
  className?: string
}

const Lighting: React.FC<LightingProps> = props => {
  const { className } = props

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="20" height="20" rx="10" fill="url(#paint0_radial_14642_6172)" />
      <path
        d="M10.3449 5.11916C10.3053 4.57341 9.5952 4.39002 9.2963 4.84835L5.71911 10.3334C5.47122 10.7135 5.74396 11.2169 6.19774 11.2169H8.28645C8.586 11.2169 8.83471 11.4483 8.85638 11.747L9.08372 14.8809C9.12331 15.4266 9.83338 15.61 10.1323 15.1517L13.7095 9.66667C13.9574 9.28657 13.6846 8.78309 13.2308 8.78309H11.1421C10.8426 8.78309 10.5939 8.55177 10.5722 8.253L10.3449 5.11916Z"
        fill="#FAFAFA"
      />
      <defs>
        <radialGradient
          id="paint0_radial_14642_6172"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(40.8151) scale(26.4263 349.226)"
        >
          <stop offset="0.0958607" stopColor="#E571FF" />
          <stop offset="1" stopColor="#FF8933" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export { Lighting }
