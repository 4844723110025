interface ILensProps {
  className?: string
}

const Lens = (props: ILensProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <circle cx="8.75" cy="9" r="5.5" stroke="currentColor" strokeWidth="2" />
    <path d="M13.25 13L15.75 15.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </svg>
)

export { Lens }
