import { FC } from 'react'

export const Telegram: FC = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.53468 11.006L7.21708 15.4732C7.67148 15.4732 7.86828 15.278 8.10428 15.0436L10.2347 13.0076L14.649 16.2404C15.4586 16.6916 16.029 16.454 16.2474 15.4956L19.145 1.91805L19.1458 1.91725C19.4026 0.720461 18.713 0.252463 17.9242 0.546061L0.892314 7.06683C-0.27008 7.51803 -0.25248 8.16602 0.694715 8.45962L5.04909 9.81401L15.1634 3.48525C15.6394 3.17005 16.0722 3.34445 15.7162 3.65965L7.53468 11.006Z"
        fill="white"
      />
    </svg>
  )
}
