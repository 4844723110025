import { RefObject, useLayoutEffect, useRef } from 'react'

export function useUnmountedRef(): RefObject<boolean> {
  const unmountedRef = useRef(false)

  useLayoutEffect(() => {
    unmountedRef.current = false

    return () => {
      unmountedRef.current = true
    }
  }, [])

  return unmountedRef
}
