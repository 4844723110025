export const EyeOpen = () => {
  return (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 6.20455C1.5 6.20455 4.22727 0.75 9 0.75C13.7727 0.75 16.5 6.20455 16.5 6.20455C16.5 6.20455 13.7727 11.6591 9 11.6591C4.22727 11.6591 1.5 6.20455 1.5 6.20455Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00004 8.25009C10.1297 8.25009 11.0455 7.33431 11.0455 6.20463C11.0455 5.07496 10.1297 4.15918 9.00004 4.15918C7.87037 4.15918 6.95459 5.07496 6.95459 6.20463C6.95459 7.33431 7.87037 8.25009 9.00004 8.25009Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
