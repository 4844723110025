import { IFeatureRecord, IMetadata, MetadataRequestDTO } from '@/backend/models/ProfileDTO'
import {
  convertToBoolean,
  exchangeTypeHandler,
  identity,
  createParseHandler,
  redefineKey,
  rootPageHandler,
  booleanToString,
  stringifyUniqueList,
  stringify,
} from './handlers'
import { EntriesMetadata, EntriesMetadataRequestDTO, MapperRecord, ReverseMapperRecord } from './types'
import { TradesTableItem, getDefaultTradesTablePreset } from '@/redux/profile/profile.utils'
import { E_BINANCE_VIP_CONSENT_PERMISSIONS } from '@/ui/molecules/BinanceVipConsentForm/BinanceVipConsentForm.types'

export const METADATA_ASYNC_KEYS: (keyof IMetadata)[] = ['isFirstSessionTracked']

// [Normalizers] UpdateMetadata -> State
const metadataMap: MapperRecord = {
  firstSesstionTrackedTimestamp: identity,
  statisticsStartMoment: identity,
  acceptedOferta: convertToBoolean,
  isFirstSessionTracked: convertToBoolean,
  isOrdersPageBalancesEnabled: convertToBoolean,
  isCreatedMasterApiKeyStatusSuccess: convertToBoolean,
  isStatisticsInit: convertToBoolean,
  isHasOKXApiKey: convertToBoolean,
  messagesShownCashbackIncreased: convertToBoolean,
  messagesShownReducedCommission: convertToBoolean,
  exchangeType: exchangeTypeHandler,
  rootPage: redefineKey('redirectTo', rootPageHandler),
  shownPopupList: createParseHandler<string[]>([]),
  tradesTablePreset: createParseHandler<TradesTableItem[]>(getDefaultTradesTablePreset()),
  binanceVipConsent: createParseHandler<E_BINANCE_VIP_CONSENT_PERMISSIONS[]>([]),
  flags: createParseHandler<IFeatureRecord>({}),
  journalRestoredKeys: createParseHandler<IMetadata['journalRestoredKeys']>([]),
}

/**
 * Function for converting metadata received from auth0 into the correct form
 */
export function normalizeMetadata(metadata: Partial<MetadataRequestDTO>): Partial<IMetadata> {
  return (Object.entries(metadata) as EntriesMetadataRequestDTO).reduce((acc, [key, value]) => {
    const handler = metadataMap[key]

    if (value === undefined || !handler) {
      return acc
    }

    return {
      ...acc,
      ...handler(key, value),
    }
  }, {} as IMetadata)
}

// filter keys that should not be updated synchronously
export const filterAsyncMetadataKeys = (metadata: Partial<IMetadata>): Partial<IMetadata> => {
  let metadataFiltered = { ...metadata }
  for (let metadataKkey of METADATA_ASYNC_KEYS) {
    delete metadataFiltered[metadataKkey]
  }
  return metadataFiltered
}

// [Normalizers] State -> UpdateMetadata
const metadataReverseMap: ReverseMapperRecord = {
  firstSesstionTrackedTimestamp: identity,
  statisticsStartMoment: identity,
  acceptedOferta: booleanToString,
  isFirstSessionTracked: booleanToString,
  isOrdersPageBalancesEnabled: booleanToString,
  isCreatedMasterApiKeyStatusSuccess: booleanToString,
  isStatisticsInit: booleanToString,
  isHasOKXApiKey: booleanToString,
  messagesShownCashbackIncreased: booleanToString,
  messagesShownReducedCommission: booleanToString,
  exchangeType: identity,
  redirectTo: redefineKey('rootPage', identity),
  shownPopupList: stringifyUniqueList,
  tradesTablePreset: stringify,
  binanceVipConsent: stringify,
  flags: stringify,
  journalRestoredKeys: stringify,
}

/**
 * [Optional] Not required for use
 * Typed function for converting data into the correct form
 * For sending via the "UdpateMetadata" function
 */
export function defineMetadata(metadata: Partial<IMetadata>): MetadataRequestDTO {
  return (Object.entries(metadata) as EntriesMetadata).reduce((acc, [key, value]) => {
    const handler = metadataReverseMap[key] ?? identity

    return {
      ...acc,
      ...handler(key, value),
    }
  }, {} as MetadataRequestDTO)
}
