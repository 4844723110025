import { memo, useState } from 'react'
import DefaultSVG from './Default.svg'

type DefaultProps = {
  width?: number
  height?: number
  className?: string
}

export const Default = memo<DefaultProps>(({ height = 20, width = 20, className }) => {
  const [loaded, setLoaded] = useState(false)
  return (
    <img
      style={{ visibility: loaded ? 'visible' : 'hidden' }}
      onLoad={() => setLoaded(true)}
      className={className}
      src={DefaultSVG}
      width={width}
      height={height}
      alt="Loading"
    />
  )
})
