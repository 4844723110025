import { Carousel as CarouselContainer } from './components/Carousel'
import { Content } from './components/Content'
import { Item } from './components/Item'

type CompoundCarousel = typeof CarouselContainer & {
  Content: typeof Content
  Item: typeof Item
}

// Compound Component
const Carousel = CarouselContainer as CompoundCarousel
Carousel.Content = Content
Carousel.Item = Item

export { Carousel }
