import { EXCHANGES_AVAILABLE, EXCHANGES_TYPE, EXCHANGE_NAME, EXCHANGE_SMALL_ICON } from '@/core/constants'
import { useAppSelector } from './useAppSelector'
import { EXCHANGES_DISABLED } from '@/core/config/exchange'

export const useExchangesSource = (icons: boolean = true) => {
  const { metadata, vipAccess } = useAppSelector(state => state.profile)
  const { firstSesstionTrackedTimestamp } = metadata

  return EXCHANGES_AVAILABLE.filter(exchange => {
    const disabledConfig = EXCHANGES_DISABLED[exchange]
    if (exchange === 'BINANCE_VIP' && !vipAccess) return false
    if (exchange === EXCHANGES_TYPE.OKX_BROKER) {
      // if (metadata.isHasOKXApiKey) {
      //   return true
      // }
      return false
    } // Temprorary hide okx affilliate
    if (disabledConfig === undefined) return true // exchange allowed
    else if (disabledConfig.allowedMembershipDateBefore === undefined)
      return false // disable for all independently of registration date, disallow
    else if (!firstSesstionTrackedTimestamp) return true // user registered before config created, allow
    else if (Number(firstSesstionTrackedTimestamp) >= Number(disabledConfig.allowedMembershipDateBefore)) return false // user registered after allowed date
  }).map(item => {
    return {
      ...(icons ? { icon: EXCHANGE_SMALL_ICON[item] } : {}),
      label: EXCHANGE_NAME[item],
      value: item,
    }
  }, [])
}
