export const formatText = (value: string, charsAfterEllipsis: number = 4) => {
  const num = Number(value)

  if (!isNaN(num) && Math.abs(num) < 1) {
    const decimalPart = value.split('.')[1]

    if (decimalPart && decimalPart.length >= 8) {
      return `${value[0]}.${decimalPart.slice(0, 1)}…${decimalPart.slice(-charsAfterEllipsis)}`
    }
    return value
  } else {
    if (value.length > 10) {
      return `${value.slice(0, 6)}…`
    }
    return value
  }
}

export function formatNumberByRules(input: number | string): string {
  // Преобразуем входные данные в число, если это возможно
  const num = parseFloat(input as string)

  // Если входные данные не могут быть преобразованы в число, возвращаем '-'
  if (isNaN(num)) {
    return '-'
  }

  const absNum = Math.abs(num)

  if (absNum >= 0.0995) {
    return num.toFixed(2) // Округление до 2 знаков
  } else if (absNum >= 0.0005 && absNum < 0.0995) {
    return num.toFixed(3) // Округление до 3 знаков
  } else {
    // Округление до первого значащего знака
    return num.toPrecision(1) // Округление до 1 значащего знака
  }
}

export function trimTo12Decimals(number: number | string) {
  const num = parseFloat(number as string)

  if (isNaN(num)) {
    return '-'
  }

  const parts = num.toString().split('.')
  if (parts.length === 1) return num
  return parseFloat(parts[0] + '.' + parts[1].substring(0, 12))
}

export function trimTo4Decimals(number: number | string) {
  const num = parseFloat(number as string)

  if (isNaN(num)) {
    return '-'
  }

  const parts = num.toString().split('.')
  if (parts.length === 1) return num
  return parseFloat(parts[0] + '.' + parts[1].substring(0, 4))
}

export function trimTo4DecimalsBybit(number: number | string) {
  const num = parseFloat(number as string)

  if (isNaN(num)) {
    return 0
  }

  const parts = num.toString().split('.')
  if (parts.length === 1) return num
  return parseFloat(parts[0] + '.' + parts[1].substring(0, 4))
}

export function trimTo8DecimalsBinance(number: number | string) {
  const num = parseFloat(number as string)

  if (isNaN(num)) {
    return 0
  }

  const parts = num.toString().split('.')
  if (parts.length === 1) return num
  return parseFloat(parts[0] + '.' + parts[1].substring(0, 8))
}
