import { APIResponse, Instrument } from 'okx-api'
import { EOkxInstruments } from './types'

export type TOkxInstrumentByType = Map<EOkxInstruments, Instrument[]>
export type TOkxInstrumentById = Map<string, Instrument>
export interface IOkxInstrumentsLoader {
  (params: { instType: EOkxInstruments }): Promise<APIResponse<Instrument[]>>
}

export class OkxInstruments {
  isInteracted: boolean = false
  instrumentsByType: TOkxInstrumentByType = new Map()
  instrumentsById: TOkxInstrumentById = new Map()
  protected load: IOkxInstrumentsLoader

  constructor(load: IOkxInstrumentsLoader) {
    this.load = load
  }

  async loadInstruments(instrument: EOkxInstruments) {
    this.isInteracted = true
    const instruments = await this.load({ instType: instrument })
    this.instrumentsByType.set(instrument, instruments.data)
    instruments.data.forEach(instrumentData => {
      this.instrumentsById.set(instrumentData.instId, instrumentData)
    })
  }
}
