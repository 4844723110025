import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { UpdateApiKeysPayload } from '../../../backend/api/apiKeyAPI'
import { isValidIP, useToggle } from '../../../utils'
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Checkbox,
  CopyIcon,
  Input,
  InputAndChips,
  InputSize,
  InteractiveModal,
  InteractiveModalParts,
} from '@/ui/kit'
import { formatAccess } from '@/ui/organisms/ApiKeyListItem/utils'
import style from './style.module.scss'
import { noop } from 'lodash-es'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { ApiKeyDTO } from '@/backend/models/ApiKeyDTO'
import { useActions, useAppSelector, useBeforeunload, useGoogleAuthCode } from '@/utils/hooks'
import { t } from '@lingui/macro'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { GoogleCodeVerification } from '@/ui/organisms/GoogleCodeVerification'
import { apiKeyActions } from '@/redux/apiKey/apiKey.slice'
import { FieldErrorType } from '@/types'
import { checkIpForAllowed } from '@/utils/lib/checkIpForAllowed'
import { DISALLOWED_IP_LIST } from '@/core/config/apiKey'
import { isExchangeBinance } from '@/utils/lib/exchange'
import { errorTranslations } from '@/core/constants/errors/errorsTranslations'

export interface ApiKeyEditFormProps {
  apiKeyDTO: ApiKeyDTO
  onSubmit?: (params: UpdateApiKeysPayload) => void
  onCancel?: () => void
  isSubmitDisabled?: boolean
}

interface State {
  name: string
  ipAddresses?: string[]
  spotEnabled: boolean
  marginEnabled: boolean
  futuresEnabled: boolean
  googleCode: string
}

export const ApiKeyEditForm: FC<ApiKeyEditFormProps> = props => {
  const { onSubmit, onCancel, isSubmitDisabled, apiKeyDTO } = props

  const initialState = useMemo<State>(
    () => ({
      name: apiKeyDTO.name,
      ipAddresses: apiKeyDTO.restrictionIps,
      spotEnabled: apiKeyDTO.spotEnabled,
      marginEnabled: apiKeyDTO.marginEnabled,
      futuresEnabled: apiKeyDTO.futuresEnabled,
      googleCode: '',
    }),
    [apiKeyDTO]
  )

  const { exchangeType } = useExchageType()
  const { errors } = useAppSelector(state => state.apiKeys)
  const { setErrors } = useActions(apiKeyActions)

  const [state, setState] = useState<State>(initialState)

  const [modalIsOpen, toggleModal] = useToggle(false)

  const errorsTrans = errorTranslations()

  const isDirty = useMemo(() => {
    return JSON.stringify(initialState) !== JSON.stringify(state)
  }, [initialState, state])

  useBeforeunload(isDirty)

  const handleFieldChange = useCallback(
    (name: string) => (value: string | boolean) => {
      setState(prev => ({ ...prev, [name]: value }))
    },
    []
  )

  const setIPAddressesValue = (val?: string[]) =>
    setState(prev => {
      if (val?.length === prev.ipAddresses?.length) return prev
      return { ...prev, ipAddresses: val }
    })

  const googleCode = useGoogleAuthCode()

  useEffect(() => {
    handleFieldChange('googleCode')(googleCode.code.join(''))
  }, [googleCode.code])

  useEffect(() => {
    return () => {
      setErrors([])
    }
  }, [])

  const submit = useCallback(() => {
    const errors: FieldErrorType[] = []

    if (!googleCode.isCorrectCodeLength) {
      errors.push({
        code: '',
        field: 'googleCode',
        message: t({
          id: 'services.gAuth.enter.title',
          message: 'Enter the 6-digit code below',
        }),
      })
    }

    if (errors.length > 0) {
      setErrors(errors)
      return
    }

    setErrors([])

    onSubmit?.({
      id: apiKeyDTO.id,
      name: state.name,
      spotEnabled: state.spotEnabled,
      marginEnabled: state.marginEnabled,
      futuresEnabled: state.spotEnabled,
      restrictionIps: state.ipAddresses || [],
      googleCode: state.googleCode,
    })
  }, [apiKeyDTO, state, googleCode.isCorrectCodeLength, onSubmit])

  const errosMapped = useMemo<Record<string, string>>(() => {
    return errors.reduce((acc, error) => {
      return {
        ...acc,
        [error.field]: error.message,
      }
    }, {})
  }, [errors])

  const handleValidate = useCallback((value: string) => {
    if (!checkIpForAllowed(value))
      return `${t({
        id: 'apiPage.formFields.ip.error.incompatible.msg-1',
        message: `Addresses`,
      })} ${DISALLOWED_IP_LIST.reduce<string>((acc, ip) => {
        return acc.length > 0 ? acc + `, ${ip}` : ip
      }, '')} ${t({
        id: 'apiPage.formFields.ip.error.incompatible.msg-2',
        message: `are not allowed`,
      })}`
    if (!isValidIP(value))
      return t({
        id: 'apiPage.formFields.ip.error.incorrect',
        message: `Incorrect IP address format`,
      })
    return null
  }, [])

  const handleCancel = useCallback(() => {
    if (isDirty) {
      toggleModal()
    } else {
      onCancel?.()
    }
  }, [isDirty, toggleModal, onCancel])

  return (
    <div>
      <TitleH1 label={state.name} className={style.contentTitle} dataTestId={DataTestIds.ApiKeyNameTitle} />

      <div className={style.checkboxesWrapper}>
        <Checkbox checked disabled variant="circle" dataTestId={DataTestIds.CheckboxRead}>
          {formatAccess('read')}
        </Checkbox>
        <Checkbox
          checked={state.spotEnabled}
          variant="circle"
          onChange={payload => handleFieldChange('spotEnabled')(payload.checked)}
          dataTestId={DataTestIds.CheckboxSpot}
        >
          {formatAccess('spot')}
        </Checkbox>
      </div>

      <div className={style.inputAndChipsWrapper}>
        <InputAndChips
          inputLabel={t({
            id: 'apiPage.formFields.ip.label',
            message: `IP addresses`,
          })}
          inputLabelHintText={
            <>
              {t({
                id: 'apiPage.formFields.ip.message-1',
                message: 'Add trusted IP addresses',
              })}
              <br />
              {t({
                id: 'apiPage.formFields.ip.message-2',
                message: 'for better security',
              })}
            </>
          }
          inputPlaceholder={t({
            id: 'apiPage.formFields.ip.add',
            message: `Add IP address (optional)`,
          })}
          values={state.ipAddresses}
          onInputChange={setIPAddressesValue}
          validate={handleValidate}
          dataTestId={DataTestIds.ApiKeyIpAddressesTextField}
        />
      </div>

      <label>
        <Input
          size={InputSize.Medium}
          label={t({
            id: 'apiPage.apiKey.title',
            message: `API key`,
          })}
          value={apiKeyDTO.key}
          setValue={noop}
          button={<CopyIcon text={apiKeyDTO.key} dataTestId={DataTestIds.ApiKeyKeyCopyButton} />}
          readOnly
          dataTestId={DataTestIds.ApiKeyKeyTextField}
        />
      </label>

      <label>
        <Input
          size={InputSize.Medium}
          label={t({
            id: 'apiPage.formFields.secretKey.label',
            message: `Secret Key`,
          })}
          labelHintText={
            <>
              {t({
                id: 'apiPage.formFields.secretKey.message-1',
                message: `If you forgot the Secret key`,
              })}
              <br />
              {t({
                id: 'apiPage.formFields.secretKey.message-2',
                message: `create a new API key`,
              })}
            </>
          }
          value="password"
          readOnly
          inputAttrs={{ type: 'password' }}
          setValue={noop}
          dataTestId={DataTestIds.ApiKeySecretKeyTextField}
        />
      </label>

      <label>
        <GoogleCodeVerification
          header={t({
            id: 'services.gAuth.enter.title',
            message: `Enter the 6-digit code below`,
          })}
          text={t({
            id: 'services.gAuth.enter.subtitle',
            message: `Input code from Google Authenticator`,
          })}
          code={googleCode.code}
          setCode={googleCode.setCode}
          isDisabled={isSubmitDisabled}
          errorMessage={errosMapped.googleCode ? errorsTrans.googleCode : undefined}
          successMessage={''}
        />
      </label>

      <div className={style.buttonsWrapper}>
        <Button.Accent
          size={ButtonSize.Medium}
          label={t({
            id: 'core.done',
            message: `Done`,
          })}
          className={style.button}
          onClick={submit}
          disabled={isSubmitDisabled}
          dataTestId={DataTestIds.EditApiKeyDoneButton}
        />
        <Button.Clean
          label={t({
            id: 'core.cancel',
            message: `Cancel`,
          })}
          className={style.buttonClean}
          onClick={handleCancel}
          dataTestId={DataTestIds.EditApiKeyCancelButton}
        />
      </div>

      <InteractiveModal isOpen={modalIsOpen}>
        <InteractiveModalParts.SubHeader
          text={`${t({
            id: 'apiPage.edit.wouldYouCancel',
            message: 'Would you like to cancel edit a',
          })} ${state.name}?`}
        />
        <InteractiveModalParts.Explanation
          text={t({
            id: 'core.dataLost',
            message: `Data will be lost`,
          })}
        />
        <InteractiveModalParts.Button
          text={t({
            id: 'core.return',
            message: `Return`,
          })}
          variant={ButtonVariant.White}
          onClick={toggleModal}
        />
        <InteractiveModalParts.Button
          text={t({
            id: 'apiPage.edit.close',
            message: `Close editing`,
          })}
          variant={ButtonVariant.Primary}
          onClick={onCancel}
        />
      </InteractiveModal>
    </div>
  )
}
