export type Theme = 'vintage' | 'shine' | 'roma' | 'infographic'

export type Themes = {
  [key in Theme]: string[]
}

export const colors: Themes = {
  vintage: [
    '#d87c7c',
    '#919e8b',
    '#d7ab82',
    '#6e7074',
    '#61a0a8',
    '#efa18d',
    '#787464',
    '#cc7e63',
    '#724e58',
    '#4b565b',
  ],
  shine: ['#c12e34', '#e6b600', '#0098d9', '#2b821d', '#005eaa', '#339ca8', '#cda819', '#32a487'],
  roma: ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50'],
  infographic: [
    '#27727b',
    '#c1232b',
    '#fcce10',
    '#e87c25',
    '#b5c334',
    '#fe8463',
    '#9bca63',
    '#fad860',
    '#f3a43b',
    '#60c0dd',
  ],
}
