import React from 'react'

export const Question = () => {
  return (
    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.11368 5.48164C2.42787 5.48164 2.58496 5.2815 2.63117 4.95933C2.66813 4.51513 2.81599 4.28082 3.42127 3.90984C4.04965 3.51445 4.40081 3.00191 4.40081 2.25506C4.40081 1.15188 3.5414 0.400146 2.28925 0.400146C1.33744 0.400146 0.616647 0.81018 0.330179 1.47404C0.24239 1.65954 0.200806 1.84015 0.200806 2.05004C0.200806 2.3771 0.390245 2.58211 0.709057 2.58211C0.963182 2.58211 1.13414 2.46008 1.23579 2.15743C1.38364 1.66442 1.72556 1.39594 2.23843 1.39594C2.80675 1.39594 3.19949 1.76693 3.19949 2.29899C3.19949 2.7676 3.00543 3.03608 2.43249 3.40218C1.85493 3.7634 1.56846 4.16367 1.56846 4.76896V4.86171C1.56846 5.21804 1.76252 5.48164 2.11368 5.48164ZM2.12292 7.60015C2.49718 7.60015 2.79289 7.29262 2.79289 6.90699C2.79289 6.52137 2.49718 6.21872 2.12292 6.21872C1.74866 6.21872 1.44833 6.52137 1.44833 6.90699C1.44833 7.29262 1.74866 7.60015 2.12292 7.60015Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
