import { EAccountType } from '@tigertrade/binance-ts'

export const BINANCE_QUEUE_INTERVAL = 1000

export const binanceWsUrls: Record<EAccountType, string> = {
  SPOT: process.env.REACT_APP_BINANCE_SPOT_SOCKET_URI,
  COIN_FUTURE: process.env.REACT_APP_BINANCE_FUTCOIN_SOCKET_URI,
  USDT_FUTURE: process.env.REACT_APP_BINANCE_FUTUSD_SOCKET_URI,
}

export const binanceRestUrls: Record<EAccountType, string> = {
  SPOT: process.env.REACT_APP_BINANCE_SAPI_URL,
  COIN_FUTURE: process.env.REACT_APP_BINANCE_DAPI_URL,
  USDT_FUTURE: process.env.REACT_APP_BINANCE_FAPI_URL,
}

export const binanceWsProxyUrls: Record<EAccountType, string> = {
  SPOT: process.env.REACT_APP_BINANCE_SPOT_SOCKET_URI_PROXY,
  COIN_FUTURE: process.env.REACT_APP_BINANCE_FUTCOIN_SOCKET_URI_PROXY,
  USDT_FUTURE: process.env.REACT_APP_BINANCE_FUTUSD_SOCKET_URI_PROXY,
}

export const binanceProxyRestUrls: Record<EAccountType, string> = {
  SPOT: process.env.REACT_APP_BINANCE_SAPI_URL_PROXY,
  COIN_FUTURE: process.env.REACT_APP_BINANCE_DAPI_URL_PROXY,
  USDT_FUTURE: process.env.REACT_APP_BINANCE_FAPI_URL_PROXY,
}
