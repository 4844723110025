import { Button, ButtonSize, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import styles from './style.module.scss'
import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { SVG } from '@/assets/svg'
import { useActions, useAppSelector } from '@/utils'
import { CONVERT_TIMER, PreviewData } from '../helpers'
import { ResultForm } from '@/pages/OKXAfiliate/components/LeftBar/components/ResultForm'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { Loader } from '@/ui/kit/Loader'
import { overviewActions } from '@/redux/overview/overview.slice'

type Props = {
  isOpen: boolean
  onClose: () => void
  previewData: PreviewData
}

export const BinanceConvertPopup = (props: Props) => {
  const { binanceConvert } = useAppSelector(state => state.overview)
  const { GetConvertQuoteTC, PostConvertTC, resetBinanceConvert } = useActions(overviewActions)
  const [timer, setTimer] = useState(CONVERT_TIMER)
  const navigate = useNavigate()

  function handleConvert() {
    if (binanceConvert.previewInfo?.quoteId) {
      PostConvertTC({
        quoteId: binanceConvert.previewInfo?.quoteId,
      })
    }
  }

  function handleRefresh() {
    GetConvertQuoteTC(props.previewData)
    setTimer(CONVERT_TIMER)
  }

  function closeSuccess() {
    resetBinanceConvert()
    navigate(urls.wallet)
  }

  function goToHistory() {
    resetBinanceConvert()
    navigate(urls.historyConvert)
  }

  function closeWithError() {
    resetBinanceConvert()
    props.onClose()
  }

  useEffect(() => {
    if (props.isOpen) {
      GetConvertQuoteTC(props.previewData)
      setTimer(CONVERT_TIMER)
    }
  }, [props.isOpen])

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer(prev => prev - 1)
      }, 1000)

      return () => clearInterval(countdown)
    }
  }, [timer])

  const getAmount = binanceConvert.previewInfo?.toAmount ? binanceConvert.previewInfo?.toAmount : '-'
  const fromAsset = `${props.previewData.fromAmount} ${props.previewData.fromAsset}`

  const ratio = `1 ${props.previewData.fromAsset} = ${binanceConvert.previewInfo?.ratio} ${props.previewData.toAsset}`
  const ratioInverse = `1 ${props.previewData.toAsset} = ${binanceConvert.previewInfo?.inverseRatio} ${props.previewData.fromAsset}`

  if (binanceConvert.isLoadingConvert || binanceConvert.isLoadingPreview) {
    return (
      <InteractiveModal isOpen={props.isOpen}>
        <Loader />
      </InteractiveModal>
    )
  }

  if (
    (binanceConvert.convertReq.isConvertReq && !binanceConvert.convertReq.isConvertSuccess) ||
    (binanceConvert.previewReq.isPreviewReq && !binanceConvert.previewReq.isPreviewSuccess)
  ) {
    return (
      <InteractiveModal isOpen={props.isOpen}>
        <ResultForm
          title={t({
            id: 'binance.convert.popup.failed.title',
            comment: 'Key binding error',
          })}
          description={binanceConvert.convertReq.error ? binanceConvert.convertReq.error : 'Error'}
          onClose={closeWithError}
          isShowSupport
          isSuccess={false}
        />
      </InteractiveModal>
    )
  }

  const isConvertSuccess = binanceConvert.convertReq.isConvertReq && binanceConvert.convertReq.isConvertSuccess

  return (
    <InteractiveModal isOpen={props.isOpen}>
      <InteractiveModalParts.Icon icon={isConvertSuccess ? 'success' : 'transfer'} />
      <InteractiveModalParts.Header
        text={
          isConvertSuccess
            ? t({ id: 'core.convertingSuccess', comment: 'Converting success' })
            : t({ id: 'core.converting', comment: 'Converting' })
        }
      />
      <div className={styles.contentWrapp}>
        <div>{fromAsset}</div>
        <div>
          <SVG.Arrows.LongArrowDown />
        </div>
        <div>
          {getAmount} {props.previewData.toAsset}
        </div>
      </div>
      <InteractiveModalParts.Table
        left={t({
          id: 'binance.convert.popup.rate',
          comment: 'Transaction time',
        })}
        right={ratio}
      />
      <InteractiveModalParts.Table
        left={t({
          id: 'binance.convert.popup.rateInverse',
          comment: 'Transaction time',
        })}
        right={ratioInverse}
      />
      <InteractiveModalParts.Table
        left={t({
          id: 'binance.convert.popup.wallet',
          comment: 'Transaction time',
        })}
        right={t({
          id: 'core.accounts.spot',
          comment: 'Spot',
        })}
      />
      <InteractiveModalParts.Table
        left={t({
          id: 'binance.convert.popup.fee',
          comment: 'Transaction time',
        })}
        right={t({
          id: 'binance.convert.popup.noFee',
          comment: 'no Fees',
        })}
      />
      <div className={styles.totalAmount}>
        <span>
          {isConvertSuccess
            ? t({
                id: 'binance.convert.popup.received',
                comment: 'You will received',
              })
            : t({
                id: 'binance.convert.popup.recieve',
                comment: 'You will recieve',
              })}
        </span>
        <div>
          {getAmount} {props.previewData.toAsset}
        </div>
      </div>
      <div className={styles.btnWrapp}>
        {!isConvertSuccess ? (
          <>
            <Button.White
              size={ButtonSize.Medium}
              onClick={handleConvert}
              label={`${t({ comment: 'Convert', id: 'binance.convert.popup.convert' })} (${timer})`}
              className={styles.btn}
              disabled={timer <= 0}
            />
            <Button.White
              size={ButtonSize.Medium}
              onClick={handleRefresh}
              label={t({ comment: 'Refreash', id: 'binance.convert.popup.refresh' })}
              className={styles.btn}
              disabled={timer > 0}
            />
            <Button.Primary
              size={ButtonSize.Medium}
              onClick={props.onClose}
              label={t({ comment: 'Cancel', id: 'core.cancel' })}
              className={styles.btn}
            />
          </>
        ) : (
          <>
            <Button.White
              size={ButtonSize.Medium}
              onClick={closeSuccess}
              label={t({ comment: 'Ok', id: 'binance.convert.popup.ok' })}
              className={styles.btn}
            />
            <Button.White
              size={ButtonSize.Medium}
              onClick={goToHistory}
              label={t({ comment: 'Ok', id: 'binance.convert.popup.history' })}
              className={styles.btn}
            />
          </>
        )}
      </div>
    </InteractiveModal>
  )
}
