import { TLanguage } from './AllowLanguagesCodes'
import { t } from '@lingui/macro'

export type LanguageConfig = Record<
  TLanguage,
  {
    name: string
    sumsubLocale: string
    shortenedName: string
  }
>

export const getLanguageDescriptions = (): LanguageConfig => {
  return {
    en: {
      name: t({ id: 'core.english', message: 'English' }),
      sumsubLocale: 'en',
      shortenedName: t({ id: 'core.english.shortened', message: 'EN' }),
    },
    ru: {
      name: t({ id: 'core.russian', message: 'Русский' }),
      sumsubLocale: 'ru',
      shortenedName: t({ id: 'core.russian.shortened', message: 'RU' }),
    },
    uk: {
      name: t({ id: 'core.ukrainian', message: 'Українська' }),
      sumsubLocale: 'uk',
      shortenedName: t({ id: 'core.ukrainian.shortened', message: 'UA' }),
    },
  }
}
