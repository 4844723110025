import { FC, useEffect } from 'react'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import style from './style.module.scss'
import clsx from 'clsx'
import { ExchangeCard } from './components/ExchangeCard'
import { useExchageCardsData } from './hooks/useExchangeCardsData'
import { t } from '@lingui/macro'
import { MessageWithIcon } from '@/ui/molecules/MessageWithIcon'
import { SVG } from '@/assets/svg'
import * as amplitude from '@amplitude/analytics-browser'
import { E_AmplitudeEventName } from '@/redux/profile/profile.types'

export const ChooseExchangePage: FC = () => {
  const cards = useExchageCardsData()
  const switchExchangeText = t({
    id: 'chooseExchange.switchExchange',
    message: 'You can switch between exchanges at any time and trade on all them at once',
  })

  useEffect(() => {
    amplitude.track(E_AmplitudeEventName.ExchangeSelectionScreenViewed)
  }, [])

  return (
    <div className={style.chooseExchange}>
      <TitleH1
        className={style.title}
        label={t({ id: 'chooseExchange.titleDefault', message: `Set default Exchange` })}
      ></TitleH1>

      {/* 
      <div className={style.message}>
        <MessageWithIcon icon={<SVG.OtherIcons.Mark />} message={switchExchangeText} />
      </div>
      */}

      <ol className={clsx(style.normalizeList, style.cardList)}>
        {cards.map((card, index) => (
          <ExchangeCard key={index} {...card} />
        ))}
      </ol>

      <span className={style.postScriptum}>
        {t({ id: 'chooseExchange.postScriptum', message: `You can change default exchange in settings.` })}
      </span>
    </div>
  )
}
