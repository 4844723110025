import { UniqId } from '../notifications.type'

const CLOSED_NOTIFICATIONS_KEY = 'closedNotifications'

export function getClosedNotifications(): Record<string, boolean> {
  const closedNotifications = sessionStorage.getItem(CLOSED_NOTIFICATIONS_KEY)

  if (!closedNotifications) return {}

  try {
    return JSON.parse(closedNotifications)
  } catch (_e) {
    return {}
  }
}

export function saveClosedNotificationToSessionStorage(alertsUniqId: UniqId): void {
  const closedNotifications = getClosedNotifications()
  closedNotifications[alertsUniqId] = true
  sessionStorage.setItem(CLOSED_NOTIFICATIONS_KEY, JSON.stringify(closedNotifications))
}
