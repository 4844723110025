import { AccountAssets } from '@/backend/api'
import { SelectOption } from '@/ui/kit'

export const assetsToOptionsMapper = (assetData?: AccountAssets): SelectOption[] => {
  if (!assetData || !assetData.assets.length) {
    return []
  }
  return assetData.assets.map(assetItem => ({
    label: assetItem.asset,
    value: assetItem.asset,
  }))
}
