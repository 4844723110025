import React, { useCallback, useEffect, useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { apiKeyAsyncActions } from '@/redux/apiKey/apiKey.actions'
import { urls } from '@/router/urls'
import { useActions, useAppSelector } from '@/utils'
import style from './style.module.scss'
import { ApiKeyEdit } from '@/ui/organisms/ApiKeyEdit'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'

export const ApiEditKeyPage = () => {
  const navigate = useNavigate()
  let { id } = useParams()

  const { apiKeys, apiKeyById, apiKeyByIdStatus } = useAppSelector(state => state.apiKeys)
  const { tradingStatus } = useAppSelector(state => state.team)
  const { GetApiKeyByIdTC, CleanGetApiByIdKeysTC } = useActions(apiKeyAsyncActions)

  const { isApiKeyEnabled } = useExchangesDisabled()

  useEffect(() => {
    if (tradingStatus?.tradingEnabled === false) navigate(urls.api)
  }, [tradingStatus])

  useEffect(() => {
    if (apiKeyByIdStatus === 'failed') navigate(urls.api)
  }, [apiKeyByIdStatus])

  const apiKeyDTO = useMemo(() => {
    const item = apiKeys.find(item => item.id === id)
    if (item) return item

    return apiKeyById
  }, [apiKeys, apiKeyById, id])

  useEffect(() => {
    if (id && !apiKeyDTO) {
      GetApiKeyByIdTC({ id })
    }
  }, [GetApiKeyByIdTC, id, apiKeys, apiKeyDTO])

  useEffect(() => {
    return () => {
      CleanGetApiByIdKeysTC()
    }
  }, [CleanGetApiByIdKeysTC])

  const { updatedApiKeyStatus } = useAppSelector(state => state.apiKeys)
  const { CleanUpdateApiKeyTC, UpdateApiKeyTC, GetApiKeysTC } = useActions(apiKeyAsyncActions)

  const gotoApiListPage = useCallback(() => {
    CleanUpdateApiKeyTC()
    navigate(urls.api)
  }, [navigate, CleanUpdateApiKeyTC])

  useEffect(() => {
    if (updatedApiKeyStatus === 'succeeded') gotoApiListPage()
  }, [gotoApiListPage, updatedApiKeyStatus])

  const handleSubmit = useCallback(
    async params => {
      await UpdateApiKeyTC(params)
      GetApiKeysTC()
    },
    [UpdateApiKeyTC]
  )

  if (!isApiKeyEnabled) return <Navigate to={urls.api} />

  return (
    <div className={style.component}>
      <div className={style.content}>
        <ApiKeyEdit
          onSubmit={handleSubmit}
          onCancel={gotoApiListPage}
          isSubmitDisabled={updatedApiKeyStatus === 'loading'}
          apiKeyDTO={apiKeyDTO}
        />
      </div>
    </div>
  )
}
