import {
  INotificationConfig,
  NotififcationPresets,
  SystemNotifiactionIcon,
} from '@/ui/molecules/SystemNotifications/notifications.type'
import { Trans } from '@lingui/react'
import styles from './style.module.scss'
import { t } from '@lingui/macro'

export function getOKXAffiliateCashbackAlert({
  isMobile,
  isShowOKXAlert,
}: {
  isMobile: boolean
  isShowOKXAlert: boolean
}): INotificationConfig {
  const header = t({
    id: 'core.systemAlert.okxAffiliateCashback.title',
    comment: 'Trade directly on OKX and get even more rewards!',
  })

  const preset: INotificationConfig = {
    id: NotififcationPresets.OKXAffiliateAlert,
    icon: SystemNotifiactionIcon.Alert,
    heading: header,
    shouldShown: isShowOKXAlert,
    description: (
      <Trans
        id="core.systemAlert.okxAffiliateCashback.description"
        components={{
          colored: <span className={styles.colored}></span>,
          gradient: <span className={styles.gradientText}></span>,
        }}
        values={{ okxPercent: '20', tigerPercent: '25' }}
      />
    ),
    availableExchange: ['OKX_AFFILIATE'],
    isUseHandleButton: true,
    isClosable: isMobile,
  }

  return preset
}
