import { FC, Fragment } from 'react'
import { formatText } from './utils'
import { Hint, usePopperRef } from '@/ui/kit'
import { useFlag } from '@/utils'
import { Portal } from '@/ui/utils/Portal'
import clsx from 'clsx'
import style from './style.module.scss'

type FormatNumberDotWithTooltipProps = {
  value: string | null
  symbol?: string
  className?: string
  hintClassName?: string
  qtyChars?: number
  isRoundRules?: boolean
}

export const FormatNumberDotWithTooltip: FC<FormatNumberDotWithTooltipProps> = ({
  value,
  symbol,
  className,
  hintClassName,
  qtyChars = 4,
  isRoundRules,
}) => {
  const [ref, setRef] = usePopperRef<HTMLDivElement>()
  const [isVisible, , enableHint, disableHint] = useFlag()

  if (value === null) return <div>"-"</div>

  // console.log('numberFormatted', numberFormatted)

  let numberFormatted: string

  const formatNumber = (num: number): string => {
    const absNum = Math.abs(num)

    // Применяем правила округления в зависимости от значения
    if (absNum >= 0.0995) {
      return num.toFixed(2)
    } else if (absNum >= 0.0005 && absNum < 0.0995) {
      return num.toFixed(3)
    } else {
      return num.toPrecision(1)
    }
  }

  // Если это десятичное число, обрабатываем вручную по новым правилам
  if (isRoundRules) {
    const parsedValue = parseFloat(value)
    numberFormatted = formatNumber(parsedValue)
  } else {
    // Если не десятичное, используем стандартное форматирование через formatText
    numberFormatted = formatText(value, qtyChars)
  }

  const isShowTooltip = numberFormatted.includes('…')

  // Temprorary disabled symbol in tooltip
  // const hintText = `${value} ${symbol ? symbol : ''}`

  return (
    <Fragment>
      <div
        className={className}
        ref={setRef}
        onMouseOver={enableHint}
        onFocus={enableHint}
        onMouseOut={disableHint}
        onBlur={disableHint}
      >
        {numberFormatted}
        {isVisible && isShowTooltip && (
          <Portal>
            <Hint targetRef={ref} placement="bottom" text={value} className={clsx(hintClassName, style.wrapper)} />
          </Portal>
        )}
      </div>
    </Fragment>
  )
}
