import { FC } from 'react'
import { t } from '@lingui/macro'
import { Button } from '@/ui/kit'
import style from './style.module.scss'
import { SVG } from '@/assets/svg'
import { useActions, useAppSelector, useToggle } from '@/utils'
import { PopupBlockTrading } from './PopupBlockTrading/PopupBlockTrading'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'
import { formatDate } from '@/utils/lib/formatDate'
import { TVoluntaryBlock } from '@/redux/riskManager/riskManager.types'

export const BlockTradingSettings: FC = () => {
  const { GetRiskManagerTradingStatusTC, GetVoluntaryBlockTC } = useActions(riskManagerActions)
  const { tradingStatus, riskManagerSettings } = useAppSelector(state => state.riskManager)

  const [isOpenPopup, togglePopup] = useToggle(false)

  function handleSuccessClose() {
    togglePopup()
    GetRiskManagerTradingStatusTC()
  }

  function handleDisableTrading() {
    const payload = {
      disableTrading: false,
      until: null,
    }

    updateVoluntaryBlock(payload)
  }

  async function updateVoluntaryBlock(payload: TVoluntaryBlock) {
    try {
      await GetVoluntaryBlockTC(payload)
      await GetRiskManagerTradingStatusTC()
    } catch (err) {
      console.log('Error', err)
    }
  }

  const isTradingBlockEnable = !riskManagerSettings?.enabled || !!tradingStatus?.until
  const isHaveUntil = tradingStatus?.until && tradingStatus.until !== ''

  return (
    <>
      <div className={style.setting}>
        <SVG.Settings.Block className={style.sectionIcon} />
        <div className={style.settingHeader}>
          <div className={style.settingTitle}>
            {t({ id: 'riskManager.settings.blockTrading.title', comment: 'Block Trading' })}
          </div>
          {!isHaveUntil && (
            <div className={style.settingStatus}>
              {t({
                id: 'riskManager.settings.blockTrading.description',
                comment: 'Block Trading description.',
              })}
            </div>
          )}
        </div>
        {isHaveUntil && (
          <div className={style.section}>
            <span>{tradingStatus?.until ? formatDate(tradingStatus.until) : '-'}</span>
            <span className={style.sectionTitle}>
              {t({ comment: 'Until', id: 'riskManager.settings.blockTrading.until' })}
            </span>
          </div>
        )}
        <div className={style.settingActions}>
          {isHaveUntil ? (
            <Button.Primary
              label={t({ id: 'riskManager.settings.riskSettings.buttonDisable', comment: 'Disabled' })}
              onClick={handleDisableTrading}
              disabled={!tradingStatus.canDisableVoluntaryBlock}
            />
          ) : (
            <Button.Primary
              label={t({ comment: 'Enable', id: 'riskManager.settings.blockTrading.button' })}
              onClick={togglePopup}
              disabled={isTradingBlockEnable}
            />
          )}
        </div>
      </div>
      <PopupBlockTrading isOpen={isOpenPopup} onClose={togglePopup} onSuccessClose={handleSuccessClose} />
    </>
  )
}
