import { EBybitInstruments } from '@/services/bybit/types'
import { EOkxInstruments } from '@/services/okx'
import { EAccountType, TUsdmIncomeType } from '@tigertrade/binance-ts'

export const OVERVIEW_SHOW_NEW = ['NEW', 'live', 'Created', 'New', 'Untriggered']
export const OVERVIEW_SHOW_TOP_LABELS = [
  EAccountType.USDT_FUTURE,
  EAccountType.COIN_FUTURE,
  EOkxInstruments.SWAP,
  EBybitInstruments.linear,
]
export const OVERVIEW_SHOW_TOTAL_MARGIN = [EAccountType.USDT_FUTURE, EOkxInstruments.SWAP, EBybitInstruments.linear]
export const STATISTICS_START_MOMENT = new Date().setUTCHours(0, 0, 0, 0)
export const STATISTICS_ALLOWED_TYPES: TUsdmIncomeType[] = [
  'REALIZED_PNL',
  'FUNDING_FEE',
  'COMMISSION',
  'INSURANCE_CLEAR',
]
