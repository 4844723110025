import { OVERVIEW_ACCOUNTS } from '@/core/constants/overview'
import { TAccountType } from '@/core/types/overview'
import { overviewActions } from '@/redux/overview/overview.slice'
import { TabBarPropsType } from '@/ui/molecules/TabBar'
import { accountNames, useActions, useAppSelector } from '@/utils'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { MobileAssetsList } from '../MobileAssetsList/MobileAssetsList'
import s from './style.module.scss'
import { Carousel } from '@/ui/kit/Carousel'
import { Checkbox } from '@/ui/kit'
import { useOverview } from '@/utils/hooks/useOverview'
import { t } from '@lingui/macro'

export const MobileAssets: FC = () => {
  const { accountType, exchangeAccounts, balances, statusBalances, isHiddenSmallAssets, accountLowBalanceLimit } =
    useAppSelector(state => state.overview)
  const { setAccountType, setIsHiddenSmallAssets } = useActions(overviewActions)

  const isLoading = statusBalances === 'loading'

  const {
    balanceByAccount: { assets },
  } = useOverview()

  const tabsHandler = (tab: string) => {
    if (OVERVIEW_ACCOUNTS.includes(tab as TAccountType) && accountType !== tab) {
      setAccountType(tab as TAccountType)
    }
  }

  function toggleHideAssets() {
    setIsHiddenSmallAssets(!isHiddenSmallAssets)
  }

  const tabs = useMemo<TabBarPropsType['tabs']>(() => {
    return exchangeAccounts.map(account => ({
      id: account,
      text: accountNames[account].name,
    }))
  }, [exchangeAccounts])

  const getBalanceByType = (type: string) => {
    return balances[type as TAccountType]?.assets ?? []
  }

  return (
    <>
      <section className={s.stickySection}>
        <Carousel className={clsx(s.gain, s.mobileTabs)} opts={{ align: 'start' }}>
          <Carousel.Content wrapperClassName={s.content}>
            {tabs.map(({ id, text }) => (
              <Carousel.Item key={id} className={s.item}>
                <button
                  className={clsx(s.tab, { [s.active]: accountType === id })}
                  type="button"
                  data-amount={getBalanceByType(id).length}
                  onClick={() => tabsHandler(id)}
                  disabled={isLoading}
                >
                  {text}
                </button>
              </Carousel.Item>
            ))}
          </Carousel.Content>
        </Carousel>
      </section>

      {isLoading && null}
      {!isLoading && (
        <>
          <Checkbox
            checked={isHiddenSmallAssets}
            className={s.checkbox}
            onChange={toggleHideAssets}
            disabled={assets.length < 1}
          >
            {t({
              id: 'assets.showLowBalance.checkbox',
              comment: 'Hide small assets',
            })}
            {` ${accountLowBalanceLimit}`}
          </Checkbox>
          <MobileAssetsList balances={getBalanceByType(accountType as string)} />
        </>
      )}
    </>
  )
}
