import { tradesActions } from '@/redux/trades/trades.slice'
import { useActions, useAppSelector } from '@/utils'
import { useCallback, useEffect, useState } from 'react'
import { subMonths } from 'date-fns'
import { WeekCardContainer } from './components/WeekCardContainer/WeekCardContainer'
import style from './style.module.scss'
import { Loader } from '@/ui/kit/Loader'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { InfiniteScrollPlate } from '@/ui/atoms/InfiniteScrollPlate'
import { useDebounceScroll } from './utils/useDebounceScroll'
import { t } from '@lingui/macro'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { Trans } from '@lingui/react'
import { EmptyList } from '@/ui/molecules'
import { DiaryExchangeFilter } from './components/DiaryExchangeFilter/DiaryExchangeFilter'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { DiaryWeekListQuery } from '@/redux/trades/trades.types'
import useFetchTradesData from '../HistoryTrades/hooks/useTrades'
import clsx from 'clsx'

const DiaryRightBar = () => {
  const {
    GetAnalyzerWeek,
    setResetWeekList,
    GetMeTC,
    PostTradesAvailableExchangeTC,
    setDiaryExchangeType,
    setIsApiKeyExistWithEmptyArray,
  } = useActions(tradesActions)
  const {
    diaryWeekList,
    diaryWeekStatus,
    diaryWeekListDataReachedEnd,
    diaryWeekListFilters,
    me,
    isTradesLoading,
    availableExchangeStatus,
    getMeStatus,
    isApiKeyExistWithEmptyArray,
  } = useAppSelector(state => state.trades)
  const currentDate = new Date()
  const pastDate = subMonths(currentDate, 6)
  const isEmptyData = diaryWeekStatus !== 'loading' && !diaryWeekList.length && !isTradesLoading
  const isFailed = diaryWeekStatus === 'failed' || availableExchangeStatus === 'failed' || getMeStatus === 'failed'
  const [startDate, setStartDate] = useState(pastDate)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const formattedCurrentDate = formatDate(currentDate, DATE_FORMAT.FORMATTED)
  const formattedPastDate = formatDate(pastDate, DATE_FORMAT.FORMATTED)
  const { exchangeType } = useExchageType()
  const [scrollFilter, setScrollFilter] = useState<DiaryWeekListQuery | null>()
  useEffect(() => {
    GetMeTC()
    PostTradesAvailableExchangeTC()
  }, [])

  const hasApiKey = diaryWeekListFilters && Object.keys(diaryWeekListFilters).includes('api_key_id')
  const dataAnalyze = {
    openBetween: `${formattedPastDate},${formattedCurrentDate}`,
  }

  const handlerExchangeTypeExist = (filterWithExchangeId: DiaryWeekListQuery) => {
    GetAnalyzerWeek(filterWithExchangeId)
    setScrollFilter(filterWithExchangeId)
  }
  const handlerWithoutExchangeType = () => {
    GetAnalyzerWeek({
      openBetween: dataAnalyze.openBetween,
    })
  }

  useFetchTradesData({
    isReturned: !me,
    exchangeType: exchangeType,
    exchangeTypeExist: exchangeType,
    hasApiKey: hasApiKey,
    me: me,
    apiKey: diaryWeekListFilters?.api_key_id,
    filters: { openBetween: dataAnalyze.openBetween },
    setExchangeType: setDiaryExchangeType,
    handlerExchangeTypeExist: handlerExchangeTypeExist,
    handlerWithoutExchangeType: handlerWithoutExchangeType,
    reset: setResetWeekList,
  })

  const scrollCallback = useCallback(
    ([entry]) => {
      if (diaryWeekListDataReachedEnd) return
      if (diaryWeekStatus !== 'loading' && !isTradesLoading && diaryWeekStatus !== 'failed' && entry.isIntersecting) {
        const newEndDate = new Date(startDate)
        const newStartDate = subMonths(newEndDate, 3)
        const formattedNewStartDate = formatDate(newStartDate, DATE_FORMAT.FORMATTED)
        const formattedNewEndDate = formatDate(newEndDate, DATE_FORMAT.FORMATTED)
        const dataAnalyze = {
          openBetween: `${formattedNewStartDate},${formattedNewEndDate}`,
        }

        setStartDate(newStartDate)
        if (scrollFilter) {
          GetAnalyzerWeek({
            api_key_id: scrollFilter.api_key_id,
            openBetween: dataAnalyze.openBetween,
          })
        } else {
          GetAnalyzerWeek({
            api_key_id: diaryWeekListFilters?.api_key_id,
            openBetween: dataAnalyze.openBetween,
          })
        }
      }
    },
    [diaryWeekListDataReachedEnd, diaryWeekStatus, startDate]
  )

  useEffect(() => {
    return () => {
      setIsApiKeyExistWithEmptyArray(false)
    }
  }, [])

  const debouncedVisibilityChange = useDebounceScroll((isVisible: boolean, year: number) => {
    if (isVisible && currentYear !== year) {
      setCurrentYear(year)
      onMonthVisibilityChange(isVisible, year)
    }
  }, 0)

  const onMonthVisibilityChange = (isVisible: boolean, year: number) => {
    if (diaryWeekStatus !== 'loading') {
      if (isVisible) {
        setCurrentYear(year)
      }
    }
  }
  diaryWeekStatus === 'loading' && diaryWeekList?.length > 0 && <Loader />

  if (isFailed) {
    return (
      <ErrorBase
        className={style.errorBlock}
        customHeader={t({
          id: 'trades.summary.errorHeaderText',
        })}
        customText={
          <Trans
            id="trades.summary.errorDescription"
            components={{
              br: <br />,
            }}
          />
        }
        isRefreshButtonVisible
      />
    )
  }
  return (
    <div className={style.root}>
      <div className={style.main_wrapper}>
        <div className={style.headerBlock}>
          <div className={style.year}>{!isEmptyData || !isApiKeyExistWithEmptyArray ? currentYear : ''}</div>
          <div>
            <DiaryExchangeFilter />
          </div>
        </div>
        <div
          className={clsx({
            [style.cards_wrapper]: !isEmptyData || !isApiKeyExistWithEmptyArray,
          })}
        >
          {isEmptyData || isApiKeyExistWithEmptyArray ? (
            <EmptyList
              className={style.emptyList}
              label={t({
                id: 'trades.summary.emptyList',
                comment: 'emptyList',
              })}
            />
          ) : (
            <div className={style.cardContainer}>
              {diaryWeekList?.map((el, index) => (
                <WeekCardContainer
                  onMonthVisibilityChange={debouncedVisibilityChange}
                  key={index}
                  month={el.monthKey}
                  data={el.weeks}
                />
              ))}
              <InfiniteScrollPlate className={style.infiniteScrollPlate} scrollCallback={scrollCallback} />
              {diaryWeekStatus === 'loading' && <Loader />}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export { DiaryRightBar }
