import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { t } from '@lingui/macro'
import { FC, Fragment } from 'react'

import style from './style.module.scss'

export const KYCVerificationError: FC = () => (
  <ErrorBase
    customHeader={t({ id: 'kyc.failed.title', comment: 'Verification failed' })}
    customText={
      <Fragment>
        {t({
          id: 'kyc.failed.subtitle-1',
          comment: 'We weren’t able to upgrade you to the',
        })}
        <br />
        {t({
          id: 'kyc.failed.subtitle-2',
          comment: 'Standard level verification',
        })}
      </Fragment>
    }
    customComponent={
      <a
        className={style.supportMessage}
        href={`mailto:${t({
          id: 'core.supportEmail',
          message: 'broker.support@tiger.trade',
        })}`}
      >
        <span>
          {t({
            id: 'kyc.failed.contactWithUs',
            comment: 'You can contact support:',
          })}
        </span>
        <span>
          {t({
            id: 'core.supportEmail',
            message: 'broker.support@tiger.trade',
          })}
        </span>
      </a>
    }
  />
)
