import {
  CoinmOrdersController,
  EAccountType,
  EOrderControllerEvents,
  FuturesUsdmOrdersController,
  SpotOrdersController,
} from '@tigertrade/binance-ts'
import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useBinanceProviderUtils } from './BinanceProvider.utils'
import { useActions } from '@/utils'
import overviewAsyncActions from '@/redux/overview/overview.actions'
import { appActions } from '@/redux/app/app.slice'
import { overviewActions } from '@/redux/overview/overview.slice'
import { marketDataMapToRecord } from '@/redux/overview/overview.utils'
import { NEED_POLING_ROUTES } from '@/core/config/common'
import { TBinanceAccountsConfig } from '@/services/binance/config'

export const BinanceContext = createContext<{ controllers?: TBinanceContextData } | undefined>(undefined)

export type TBinanceContextData = {
  [EAccountType.SPOT]: SpotOrdersController
  [EAccountType.USDT_FUTURE]: FuturesUsdmOrdersController
  [EAccountType.COIN_FUTURE]: CoinmOrdersController
}

interface IBinanceProviderProps {
  children: React.ReactNode
  config: TBinanceAccountsConfig | undefined
}

export const BinanceProvider: FC<IBinanceProviderProps> = ({ children, config }) => {
  const location = useLocation()
  const { GetAllCointsTC } = useActions(overviewAsyncActions)
  const { setIsExchangeProviderInitialized, setExchangeStatus } = useActions(appActions)
  const { setMarketData, setExchangeInfo, setExchangeInfoUsd } = useActions(overviewActions)
  const [controllers, setControllers] = useState<undefined | TBinanceContextData>()
  const isExchangeInfoLoaded = useRef<boolean>(false)
  const [binanceHandlersInitialized, setBinanceHandlersInitialized] = useState(false)

  const { handleCoinmUpdate, handleSpotUpdate, handleUsdmUpdate, handleError } =
    useBinanceProviderUtils(setBinanceHandlersInitialized)

  const shouldPausePoling = useMemo(() => {
    return !NEED_POLING_ROUTES.includes(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    setExchangeStatus('succeeded')
  }, [])

  useEffect(() => {
    if (controllers !== undefined || config === undefined) return

    const init = async () => {
      let controllersArray = []

      try {
        controllersArray = await Promise.all([
          SpotOrdersController.load(config.SPOT),
          FuturesUsdmOrdersController.load(config.USDT_FUTURE),
          CoinmOrdersController.load(config.COIN_FUTURE),
        ])
      } catch {
        // setRequestStatus('failed')
        return
      }

      handleSpotUpdate({
        changedState: controllersArray[0].data,
        actualState: {
          orders: new Map(),
          ordersArray: [],
        },
      })

      handleUsdmUpdate({
        changedState: controllersArray[1].data,
        actualState: {
          orders: new Map(),
          ordersArray: [],
        },
      })

      handleCoinmUpdate({
        changedState: controllersArray[2].data,
        actualState: {
          orders: new Map(),
          ordersArray: [],
        },
      })

      setMarketData([EAccountType.SPOT, marketDataMapToRecord(controllersArray[0].marketData.marketAssets)])
      setMarketData([EAccountType.USDT_FUTURE, marketDataMapToRecord(controllersArray[1].marketData.marketAssets)])
      setMarketData([EAccountType.COIN_FUTURE, marketDataMapToRecord(controllersArray[2].marketData.marketAssets)])

      setIsExchangeProviderInitialized(true)

      setControllers({
        SPOT: controllersArray[0],
        USDT_FUTURE: controllersArray[1],
        COIN_FUTURE: controllersArray[2],
      })
    }

    init()
  }, [config, controllers])

  useEffect(() => {
    return () => {
      if (controllers === undefined) return

      controllers.SPOT.off(EOrderControllerEvents.UPDATE, handleSpotUpdate)
      controllers.USDT_FUTURE.off(EOrderControllerEvents.UPDATE, handleUsdmUpdate)
      controllers.COIN_FUTURE.off(EOrderControllerEvents.UPDATE, handleCoinmUpdate)

      controllers.SPOT.stop()
      controllers.USDT_FUTURE.stop()
      controllers.COIN_FUTURE.stop()
    }
  }, [controllers])

  const initBinanceHandlers = useCallback(async () => {
    if (controllers === undefined) return

    controllers.SPOT.off(EOrderControllerEvents.UPDATE, handleSpotUpdate)
    controllers.USDT_FUTURE.off(EOrderControllerEvents.UPDATE, handleUsdmUpdate)
    controllers.COIN_FUTURE.off(EOrderControllerEvents.UPDATE, handleCoinmUpdate)

    controllers.SPOT.on(EOrderControllerEvents.UPDATE, handleSpotUpdate)
    controllers.USDT_FUTURE.on(EOrderControllerEvents.UPDATE, handleUsdmUpdate)
    controllers.COIN_FUTURE.on(EOrderControllerEvents.UPDATE, handleCoinmUpdate)

    controllers.SPOT.off(EOrderControllerEvents.ERROR, handleError)
    controllers.USDT_FUTURE.off(EOrderControllerEvents.ERROR, handleError)
    controllers.COIN_FUTURE.off(EOrderControllerEvents.ERROR, handleError)

    controllers.SPOT.on(EOrderControllerEvents.ERROR, handleError.bind(null, controllers))
    controllers.USDT_FUTURE.on(EOrderControllerEvents.ERROR, handleError.bind(null, controllers))
    controllers.COIN_FUTURE.on(EOrderControllerEvents.ERROR, handleError.bind(null, controllers))

    setBinanceHandlersInitialized(true)

    await Promise.all([controllers.SPOT.init(), controllers.USDT_FUTURE.init(), controllers.COIN_FUTURE.init()])
  }, [controllers, handleSpotUpdate, handleUsdmUpdate, handleCoinmUpdate, handleError])

  useEffect(() => {
    if (controllers === undefined) return

    if (!shouldPausePoling && !binanceHandlersInitialized) {
      initBinanceHandlers()
    }

    setTimeout(async () => {
      if (!isExchangeInfoLoaded.current) {
        isExchangeInfoLoaded.current = true

        await Promise.all([
          controllers['SPOT'].refreshCache(),
          controllers['USDT_FUTURE'].refreshCache(),
          controllers['COIN_FUTURE'].refreshCache(),
        ])

        setExchangeInfo(controllers['SPOT'].getExchangeInfo())
        setExchangeInfoUsd(controllers['USDT_FUTURE'].getExchangeInfo())

        GetAllCointsTC()
      }
    }, 1000)
  }, [controllers, initBinanceHandlers, shouldPausePoling, binanceHandlersInitialized])

  useEffect(() => {
    if (controllers && binanceHandlersInitialized) {
      if (shouldPausePoling) {
        controllers['SPOT'].pause()
        controllers['USDT_FUTURE'].pause()
        controllers['COIN_FUTURE'].pause()
      } else {
        controllers['SPOT'].continue()
        controllers['USDT_FUTURE'].continue()
        controllers['COIN_FUTURE'].continue()
      }
    }
  }, [controllers, shouldPausePoling, binanceHandlersInitialized])

  return <BinanceContext.Provider value={{ controllers }}>{children}</BinanceContext.Provider>
}
