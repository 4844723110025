import React, { FC } from 'react'
import { TwoColumnPage } from '@/layouts/TwoColumnPage'
import { VoucherLeftBar } from '@/ui/organisms/VoucherLeftBar'
import { VoucherRightBar } from '@/ui/organisms/VoucherRightBar'
import s from './style.module.scss'

const Voucher: FC = () => {
  return (
    <TwoColumnPage className={s.root}>
      <VoucherLeftBar />
      <VoucherRightBar />
    </TwoColumnPage>
  )
}

export { Voucher }
