import { FC } from 'react'

const Hedge: FC<{ className?: string }> = () => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_15523_96045)" />
      <path d="M25 30.5V24.5" stroke="#FAFAFA" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M25 12.5V10.5" stroke="#FAFAFA" strokeWidth="1.5" strokeLinecap="round" />
      <rect x="22" y="12.5" width="6" height="12" rx="2" fill="#FAFAFA" />
      <rect x="12.5" y="15" width="5" height="13" rx="1.5" fill="#FAFAFA" stroke="#FAFAFA" />
      <path d="M15 14.5V10.5" stroke="#FAFAFA" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M15 30.5V28.5" stroke="#FAFAFA" strokeWidth="1.5" strokeLinecap="round" />
      <defs>
        <linearGradient
          id="paint0_linear_15523_96045"
          x1="0"
          y1="40.5"
          x2="30.3512"
          y2="-5.82211"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C8CAFB" />
          <stop offset="1" stopColor="#E7569A" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { Hedge }
