interface AddressProps {
  className?: string
}

export const Address = (props: AddressProps) => {
  const { className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      className={className}
    >
      <path
        d="M4.40005 5.25C3.98583 5.25 3.65005 5.58579 3.65005 6C3.65005 6.41421 3.98583 6.75 4.40005 6.75V5.25ZM11.6 6.75C12.0143 6.75 12.35 6.41421 12.35 6C12.35 5.58579 12.0143 5.25 11.6 5.25V6.75ZM4.40005 8.85C3.98583 8.85 3.65005 9.18579 3.65005 9.6C3.65005 10.0142 3.98583 10.35 4.40005 10.35V8.85ZM8.00005 10.35C8.41426 10.35 8.75005 10.0142 8.75005 9.6C8.75005 9.18579 8.41426 8.85 8.00005 8.85V10.35ZM3.50005 14.85H15.2V13.35H3.50005V14.85ZM12.5 18.75H3.50005V20.25H12.5V18.75ZM3.50005 18.75C2.42309 18.75 1.55005 17.877 1.55005 16.8H0.0500488C0.0500488 18.7054 1.59467 20.25 3.50005 20.25V18.75ZM12.5 20.25C14.4054 20.25 15.95 18.7054 15.95 16.8H14.45C14.45 17.877 13.577 18.75 12.5 18.75V20.25ZM3.50005 13.35C1.59467 13.35 0.0500488 14.8946 0.0500488 16.8H1.55005C1.55005 15.723 2.42309 14.85 3.50005 14.85V13.35ZM3.50005 2.25H12.5V0.75H3.50005V2.25ZM14.45 4.2V16.8H15.95V4.2H14.45ZM1.55005 16.8V4.2H0.0500488V16.8H1.55005ZM12.5 2.25C13.577 2.25 14.45 3.12304 14.45 4.2H15.95C15.95 2.29462 14.4054 0.75 12.5 0.75V2.25ZM3.50005 0.75C1.59467 0.75 0.0500488 2.29462 0.0500488 4.2H1.55005C1.55005 3.12304 2.42309 2.25 3.50005 2.25V0.75ZM4.40005 6.75H11.6V5.25H4.40005V6.75ZM4.40005 10.35H8.00005V8.85H4.40005V10.35Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
