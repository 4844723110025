import { TExchangesAvailable } from '@/core/constants/exchanges'

export type TExchangeConfig = {
  profile: {
    bnbBurn: boolean
    convertSmallBalance: boolean
    assetMode: boolean
    positionMode: boolean
    additionalInfo: boolean
  }
}

export const EXCHANGE_CONFIG: Record<TExchangesAvailable, TExchangeConfig> = {
  BINANCE_BROKER_FUTURE: {
    profile: {
      bnbBurn: true,
      convertSmallBalance: true,
      assetMode: true,
      positionMode: true,
      additionalInfo: true,
    },
  },
  BINANCE_VIP: {
    profile: {
      bnbBurn: true,
      convertSmallBalance: true,
      assetMode: true,
      positionMode: true,
      additionalInfo: true,
    },
  },
  OKX_BROKER: {
    profile: {
      bnbBurn: false,
      convertSmallBalance: false,
      assetMode: false,
      positionMode: false,
      additionalInfo: true,
    },
  },
  BYBIT_BROKER: {
    profile: {
      bnbBurn: false,
      convertSmallBalance: false,
      assetMode: false,
      positionMode: false,
      additionalInfo: true,
    },
  },
  OKX_AFFILIATE: {
    profile: {
      bnbBurn: false,
      convertSmallBalance: false,
      assetMode: false,
      positionMode: false,
      additionalInfo: false,
    },
  },
}

export const EXCHANGES_DISABLED: Partial<Record<TExchangesAvailable, { allowedMembershipDateBefore?: string }>> = {}
export const GLOBAL_REQUEST_TIMEOUT = 5000
export const GLOBAL_REQUEST_TIMEOUT_EXTENDED = 15000
export const BYBIT_EXTENDED_REQUEST_TIMEOUT = 15000
