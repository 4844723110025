import { FC, MouseEvent, useMemo } from 'react'
import s from './style.module.scss'
import { AccountOverviewPositionsView } from '@/backend/models/OverviewDTO'
import { t } from '@lingui/macro'
import { usePosition } from '@/ui/organisms/Position/hooks/usePosition'
import { MobileDealCard } from '../MobileDealCard/MobileDealCard'
import { MobileSkeletonDeal } from '../MobileSkeletonDeal/MobileSkeletonDeal'
import { formatNumber, useAppSelector } from '@/utils'

interface PositionsProps {
  positions: AccountOverviewPositionsView[]
}

interface CardProps {
  position: AccountOverviewPositionsView
}

const PositionCard: FC<CardProps> = ({ position }) => {
  const { deleting, unrealizedPnl, entryPrice, closePositionHandler, liquidationPriceOutput } = usePosition(position)

  const text = useMemo(() => {
    return {
      closeBtn: t({ message: 'Close this position', id: 'position.closePosition' }),
    }
  }, [])

  const closeHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    closePositionHandler()
  }

  return (
    <>
      <MobileDealCard
        symbol={position.symbol}
        side={position.positionSideOutput}
        date={position.updateTime}
        unrealizedPnl={unrealizedPnl}
        volume={position.positionAmt}
        openPrice={formatNumber(entryPrice)}
        liquidationPrice={liquidationPriceOutput}
        isDeleting={deleting}
        closeHandler={closeHandler}
        closeBtnText={text.closeBtn}
      />
    </>
  )
}

export const MobilePositions: FC<PositionsProps> = ({ positions }) => {
  const { statusPositions } = useAppSelector(state => state.overview)

  if (statusPositions !== 'succeeded') {
    return (
      <ol className={s.list}>
        {Array.from({ length: 3 }).map((_: unknown, id: number) => (
          <MobileSkeletonDeal key={id} />
        ))}
      </ol>
    )
  }

  if (!positions?.length) {
    return <div className={s.empty}>{t({ message: 'You have no submitted positions', id: 'orders.noPositions' })}</div>
  }

  return (
    <ul className={s.list}>
      {positions.map(position => (
        <PositionCard key={position.updateTime + position.positionAmt} position={position} />
      ))}
    </ul>
  )
}
