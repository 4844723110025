import { ICacheManager } from '@/types'
import { get, set, del } from 'idb-keyval'

export const cacheManager: ICacheManager = {
  set: async (key: string, value: any) => {
    try {
      await set(key, value)
      return true
    } catch {
      return false
    }
  },
  get: async (key: string) => {
    try {
      return await get(key)
    } catch {
      return undefined
    }
  },
  del: async (key: string) => {
    try {
      await del(key)
      return true
    } catch {
      return false
    }
  },
}
