import { v1 } from 'uuid'
import { InternalAxiosRequestConfig } from 'axios'

const traceRequestInterceptor = (config: InternalAxiosRequestConfig) => {
  const random = v1()

  config.headers['X-Request-Id'] = random
  config.headers['Trace-Request-Id'] = random

  return config
}

export { traceRequestInterceptor }
