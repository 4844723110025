import { FC } from 'react'

export const Proscalping: FC = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" fill="#FAFAFA" />
      <path
        d="M25.8011 28.8963L9.67053 30.856L22.0322 39.7505L21.58 37.0312C40.1226 39.298 44.4944 18.7957 20.374 22.5644C36.0522 21.3585 34.394 35.2277 22.9368 31.6041L25.8011 28.8963Z"
        fill="#00B99E"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.374 22.5644C33.1714 21.58 34.418 30.6399 28.2131 32.0318C32.3018 32.1791 34.2229 31.2741 36.8525 27.5393C35.9553 23.8714 30.854 20.927 20.374 22.5644Z"
        fill="#008587"
      />
      <path
        d="M24.3343 20.6047L40.4648 18.6449L28.1031 9.75051L28.5554 12.4698C10.0128 10.2029 5.64099 30.7053 29.7614 26.9365C14.0831 28.1425 15.7414 14.2733 27.1986 17.8969L24.3343 20.6047Z"
        fill="#002B40"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7614 26.9365C16.9639 27.9209 15.7173 18.8611 21.9223 17.4692C17.8336 17.3219 15.9125 18.2269 13.2829 21.9617C14.1801 25.6296 19.2814 28.574 29.7614 26.9365Z"
        fill="#008587"
      />
    </svg>
  )
}
