import { FC, memo } from 'react'
import style from './style.module.scss'
import KeyContent from './components/KeyContent'
import { CashbackCard } from './components/CashbackCard'
import { Withdraw } from './components/Withdraw'

const LeftBar: FC = () => {
  return (
    <div className={style.leftBar}>
      <Withdraw />
      <KeyContent />
      <CashbackCard />
    </div>
  )
}

export default memo(LeftBar)
