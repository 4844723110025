import React from 'react'

export const Lock = () => {
  return (
    <svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.3681 14.6788C28.3681 22.0161 23.0415 28.8882 15.7643 30.8988C15.2691 31.0338 14.7289 31.0338 14.2338 30.8988C6.95652 28.8882 1.62988 22.0161 1.62988 14.6788V8.09174C1.62988 6.86136 2.56019 5.46594 3.71555 5.00079L12.0731 1.57979C13.9487 0.81455 16.0643 0.81455 17.9399 1.57979L26.2975 5.00079C27.4378 5.46594 28.3832 6.86136 28.3832 8.09174L28.3681 14.6788Z"
        fill="url(#paint0_linear_9603_2396)"
        stroke="url(#paint1_linear_9603_2396)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter0_d_9603_2396)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0014 10.3311C13.1356 10.3311 11.623 11.8436 11.623 13.7094C11.623 15.2577 12.6646 16.5627 14.0852 16.9621V21.3441C14.0852 21.8501 14.4954 22.2604 15.0014 22.2604C15.5074 22.2604 15.9176 21.8501 15.9176 21.3441V16.9621C17.3383 16.5627 18.3798 15.2577 18.3798 13.7094C18.3798 11.8436 16.8672 10.3311 15.0014 10.3311Z"
          fill="url(#paint2_linear_9603_2396)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9603_2396"
          x="1.85002"
          y="3.00128"
          width="26.3029"
          height="31.4757"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.44326" />
          <feGaussianBlur stdDeviation="4.88651" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.223529 0 0 0 0 0.858824 0 0 0 0 0.537255 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9603_2396" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9603_2396" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_9603_2396"
          x1="31.6771"
          y1="-5.04713"
          x2="2.09918"
          y2="26.2916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#252137" />
          <stop offset="0.522549" stopColor="#1B1828" stopOpacity="0.477451" />
          <stop offset="1" stopColor="#12101A" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9603_2396"
          x1="-2.13274"
          y1="32.3857"
          x2="48.9259"
          y2="-16.6664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12101A" />
          <stop offset="0.20722" stopColor="#12101A" />
          <stop offset="0.358276" stopColor="#042813" />
          <stop offset="0.558955" stopColor="#322D48" />
          <stop offset="0.599175" stopColor="#191624" />
          <stop offset="0.900469" stopColor="#100E17" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9603_2396"
          x1="11.623"
          y1="22.2604"
          x2="20.1919"
          y2="11.8181"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#24C8DE" />
          <stop offset="1" stopColor="#4EED34" />
        </linearGradient>
      </defs>
    </svg>
  )
}
