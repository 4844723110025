import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'
import { CashbackAPI, CashbackTermsType, CashbackType, CashbackCalculatedTimeDTO } from '@/backend/api'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'

export const GetCashbackTC = createAsyncThunk<CashbackType, void, ThunkError>(
  nameof(CashbackAPI.getCashback),
  async (_, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.getCashback }, thunkAPI)
  }
)
export const GetCashbackTermsTC = createAsyncThunk<CashbackTermsType[], void, ThunkError>(
  nameof(CashbackAPI.getCashbackTermsApi),
  async (_, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.getCashbackTermsApi }, thunkAPI)
  }
)
export const GetCashbackCalculatedTimeTC = createAsyncThunk<CashbackCalculatedTimeDTO, void, ThunkError>(
  nameof(CashbackAPI.getCashbackCalculationTimeApi),
  async (_, thunkAPI) => {
    return await ThunkCreator({ apiMethod: CashbackAPI.getCashbackCalculationTimeApi }, thunkAPI)
  }
)
