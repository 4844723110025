import { FC } from 'react'
import style from './style.module.scss'

interface ISpinnerProps {
  size?: number
}

const SPINNER_DEFAULT_SIZE = 100

const SpinnerItem: FC<ISpinnerProps> = ({ size = SPINNER_DEFAULT_SIZE }) => {
  const borderWidth = size / 10

  return <div className={style.ldsRingItem} style={{ borderWidth: `${borderWidth}px` }} />
}

export const Spinner: FC<ISpinnerProps> = ({ size = SPINNER_DEFAULT_SIZE }) => {
  return (
    <div
      className={style.root}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <div className={style.ldsRing}>
        <SpinnerItem size={size} />
        <SpinnerItem size={size} />
        <SpinnerItem size={size} />
        <SpinnerItem size={size} />
      </div>
    </div>
  )
}
